import { ResApi, ResApiErr } from '@src/configs/resApi.interface'
import { checkAuth, getStoredLang } from '@src/libs/localStorage'
import { HashtagData, HashtagQueryParams } from '@src/models'
import { notification } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import {
  createHashtag,
  deleteHashtag,
  getHashtagById,
  getListHashtag,
  updateHashtag,
} from '../api'
import { HASHTAG_KEY } from '../keys'

export const useQueryHashtag = (params: HashtagQueryParams) =>
  useQuery(
    [HASHTAG_KEY.LIST, params, getStoredLang()],
    () => getListHashtag(params),
    {
      keepPreviousData: true,
      retry: 2,
    },
  )

export const useCreateHashtag = () => {
  //   const accessToken = checkAuth()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useMutation((body: HashtagData) => createHashtag(body), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Create Success!' })
        queryClient.refetchQueries([HASHTAG_KEY.LIST])
        navigate(`/hashtag/${data?.data?._id}`)
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Create failure!' })
    },
  })
}

export const useHashtagById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [HASHTAG_KEY.DETAIL, id, getStoredLang()],
    () => getHashtagById(id),
    {
      enabled: !!(id && accessToken),
      retry: 1,
    },
  )
}

export const useUpdateHashtag = (id: string) => {
  //   const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((body: HashtagData) => updateHashtag(id, body), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Update Success!' })
        queryClient.refetchQueries([HASHTAG_KEY.LIST])
        queryClient.refetchQueries([HASHTAG_KEY.DETAIL, id, getStoredLang()])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Update failure!' })
    },
  })
}

export const useDeleteHashtag = () => {
  //   const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((id: string) => deleteHashtag(id), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Delete Success!' })
        queryClient.refetchQueries([HASHTAG_KEY.LIST])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Delete failure!' })
    },
  })
}
