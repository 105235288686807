import { PERMISSIONS } from '@src/configs/permissions'
import { INDUSTRIAL_PROJECT_URL } from '@src/configs/urlRouter'
import lazyLoading from '@src/libs/lazyLoading'

import { RouteConfigItemType } from './interface'

const ListIndustrial = lazyLoading(
  () => import('@pages/industrial-project/ListIndustrial'),
)
const CreateIndustrial = lazyLoading(
  () => import('@pages/industrial-project/CreateIndustrial'),
)
const DetailIndustrial = lazyLoading(
  () => import('@pages/industrial-project/DetailIndustrial'),
)

const industrialProjectRouter: RouteConfigItemType[] = [
  {
    path: INDUSTRIAL_PROJECT_URL.LIST,
    Element: ListIndustrial,
    key: PERMISSIONS.LIST_INDUSTRIAL_AREA,
  },
  {
    path: INDUSTRIAL_PROJECT_URL.CREATE,
    Element: CreateIndustrial,
    key: PERMISSIONS.CREATE_INDUSTRIAL_AREA,
  },
  {
    path: INDUSTRIAL_PROJECT_URL.DETAIL,
    Element: DetailIndustrial,
    key: PERMISSIONS.VIEW_INDUSTRIAL_AREA,
  },
]

export { industrialProjectRouter }
