import { BaseDataProps } from '@src/models'
import { DetailDataResponse } from '@src/models/queryParams'
import { useQuery } from 'react-query'

import {
  getIndustrialZone,
  getInfrastructure,
  getLanguage,
  getPropertyNewsType,
  getRegion,
  getSector,
} from '../api'
import { BASE_DATA_KEY } from '../keys/baseData'

/**
 * @method queryBaseData()
 * @returns
 */

export const QuerySector = () =>
  useQuery<DetailDataResponse<BaseDataProps[]>, Error>(
    [BASE_DATA_KEY.SECTOR],
    () => getSector(),
    {
      // enabled: !!accessToken,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    },
  )

export const QueryRegion = () =>
  useQuery<DetailDataResponse<BaseDataProps[]>, Error>(
    [BASE_DATA_KEY.REGION],
    () => getRegion(),
    {
      // enabled: !!accessToken,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    },
  )

export const QueryInfrastructure = () =>
  useQuery<DetailDataResponse<BaseDataProps[]>, Error>(
    [BASE_DATA_KEY.INFRASTRUCTURE],
    () => getInfrastructure(),
    {
      // enabled: !!accessToken,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    },
  )

export const QueryIndustrialZone = () =>
  useQuery<DetailDataResponse<BaseDataProps[]>, Error>(
    [BASE_DATA_KEY.INDUSTRIAL_ZONE],
    () => getIndustrialZone(),
    {
      // enabled: !!accessToken,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    },
  )

export const QueryPropertyNewsType = () =>
  useQuery<DetailDataResponse<BaseDataProps[]>, Error>(
    [BASE_DATA_KEY.INDUSTRIAL_ZONE],
    () => getPropertyNewsType(),
    {
      // enabled: !!accessToken,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    },
  )

export const QueryLanguage = () =>
  useQuery<any, Error>([BASE_DATA_KEY.LANGUAGE], () => getLanguage(), {
    // enabled: !!accessToken,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  })
