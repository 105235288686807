import { Ads, AdsQueryParams } from '@src/models'

import { request } from './config'

// ---- Fn
export const getAds = (params: AdsQueryParams, token: string) =>
  request({ url: 'ads-manager', method: 'GET', params }, { token })

export const createAds = (data: Ads, token: string) =>
  request(
    {
      url: 'ads-manager',
      method: 'POST',
      data: { ...data, systemType: '2KCN' },
    },
    { token },
  )

export const getAdsById = (id: string, token: string) =>
  request({ url: `ads-manager/${id}`, params: { isCms: true } }, { token })

export const updateAds = (id: string, data: Ads, token: string) =>
  request({ url: `ads-manager/${id}`, method: 'PUT', data }, { token })

export const delAdsById = (id: string, token: string) =>
  request({ url: `ads-manager/${id}`, method: 'DELETE' }, { token })

export const stopAllAds = (token: string) =>
  request({ url: `ads-manager/stop-all-ads`, method: 'PUT' }, { token })
