import { GET_LIST_PAYMENT, GET_PAYMENT_BY_ID } from '@queries/keys'
import { checkAuth } from '@src/libs/localStorage'
import { UserQueryParams } from '@src/models/user'
import { useQuery } from 'react-query'

import * as paymentApi from '../api/payment'

export const useQueryListPayment = (params: UserQueryParams) => {
  const accessToken = checkAuth()
  return useQuery(
    [GET_LIST_PAYMENT, params],
    () => paymentApi.getListPayment(accessToken, params),
    {
      enabled: !!accessToken,
      keepPreviousData: true,
      retry: 2,
    },
  )
}

export const useQueryPaymentById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [GET_PAYMENT_BY_ID, id],
    () => paymentApi.getPaymentById(accessToken, id),
    {
      enabled: !!(id && accessToken),
      retry: 1,
    },
  )
}
