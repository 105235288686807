import { OrderByEnum } from '@src/configs/common'

import { DefaultParams } from './queryParams'

export enum ThemeEnum {
  ENTERPRISE = 'enterprise',
  INVESTOR = 'investor',
  CITY_PROVINCE = 'cityProvince',
}
export enum KeyEnum {
  DESCRIPTION_WEB = 'DESCRIPTION_WEB',
  TITLE_WEB = 'TITLE_WEB',

  HOME_HEADER_BANNER = 'HOME_HEADER_BANNER',
  HOME_HEADER_TEXT = 'HOME_HEADER_TEXT',
  HOME_IMAGE = 'HOME_IMAGE',
  HOME_BOTTOM_BANNER = 'HOME_BOTTOM_BANNER',
  HOME_BOTTOM_TEXT = 'HOME_BOTTOM_TEXT',

  FOOTER_AGREEMENT = 'FOOTER_AGREEMENT',
  FOOTER_COMPLAINTS = 'FOOTER_COMPLAINTS',
  FOOTER_POLICY = 'FOOTER_POLICY',
  FOOTER_REGULATIONS_OPERATION = 'FOOTER_REGULATIONS_OPERATION',
  FOOTER_REGULATIONS_POST = 'FOOTER_REGULATIONS_POST',
  FOOTER_REPORT = 'FOOTER_REPORT',

  FOOTER_SUPPORT = 'FOOTER_SUPPORT',
  FOOTER_CONTACT = 'FOOTER_CONTACT',
  FOOTER_FREQUENTLY_QUESTIONS = 'FOOTER_FREQUENTLY_QUESTIONS',
  FOOTER_NOTIFY = 'FOOTER_NOTIFY',
  FOOTER_SITEMAP = 'FOOTER_SITEMAP',

  ENTERPRISE = 'ENTERPRISE',
  INVESTOR = 'INVESTOR',
  CITY_PROVINCE = 'CITY_PROVINCE',
}

export const ListHomeKey = [
  {
    key: ThemeEnum.ENTERPRISE,
  },
  {
    key: ThemeEnum.INVESTOR,
  },
  {
    key: ThemeEnum.CITY_PROVINCE,
  },
]

export type ThemeQueryParams = DefaultParams & {
  orderBy: OrderByEnum
  author?: string
}

export interface ThemeDataType {
  homeBottomText?: HomeBottomText
  footerText?: FooterText
  footerGuide?: FooterGuide
  footerRegulations?: FooterRegulations
  _id?: string
  title?: string
  homeHeadTheme?: string
  homeBottomTheme?: string
  homeHeadText?: string
  homeImage?: string
  banner?: Theme[]
  footerSocial?: FooterSocial
  author?: Author
  createdAt: Date
  updatedAt?: string
  descriptionWeb?: string
  titleWeb?: string
  __v?: number
  cityProvince?: show
  enterprise?: show
  investor?: show
  value?: string
  key?: KeyEnum
}

export interface show {
  show?: boolean
  value?: string[]
}

export interface Author {
  _id?: string
  fullName?: string
}

export interface Theme {
  show?: boolean
  key?: string
  value?: string[]
}

export interface FooterGuide {
  support?: string
  frequentlyQuestions?: string
  notify?: string
  contact?: string
  sitemap?: string
}

export interface FooterRegulations {
  regulationsPost?: string
  regulationsOperation?: string
  agreement?: string
  policy?: string
  complaints?: string
  report?: string
}

export interface FooterSocial {
  facebook?: string
  instagram?: string
  twitter?: string
}

export interface FooterText {
  logo?: string
  name?: string
  address?: string
}

export interface HomeBottomText {
  hotline?: string
  register?: string
  ads?: string
}

export enum KEY_THEME_ENUM {
  WEB = '2KCN_WEB',
  HOME = '2KCN_HOME',
  FOOTER = '2KCN_FOOTER',
  ENTERPRISE = '2KCN_ENTERPRISE',
  INVESTOR = '2KCN_INVESTOR',
  CITY_PROVINCE = '2KCN_CITY_PROVINCE',
  UP_VIP = 'UP_VIP',
  RENEW_VIP = 'RENEW_VIP',
  ADD_POINT = 'ADD_POINT',
  RANK_BONUS = 'RANK_BONUS',
  PARTNER = 'PARTNER',
  TRANG_CHO_THUE = 'TRANG_CHO_THUE',
  TRANG_CHUYEN_NHUONG = 'TRANG_CHUYEN_NHUONG',
  TRANG_DU_AN_KCN = 'TRANG_DU_AN_KCN',
  TRANG_MA = 'TRANG_M&A',
  TRANG_MUA_BAN = 'TRANG_MUA_BAN',
  TRANG_TIM_KIEM = 'TRANG_TIM_KIEM',
  TRANG_TIN_TUC = 'TRANG_TIN_TUC',
  TRANG_FAQ = 'FREQUENTLY_ASKED_QUESTIONS',
  TRANG_MEMBERSHIP_BENEFITS = 'MEMBERSHIP',
  PAYMENT = 'PAYMENT',
}

export const KEY_THEME_DATA = [
  KEY_THEME_ENUM.WEB,
  KEY_THEME_ENUM.HOME,
  KEY_THEME_ENUM.FOOTER,
  KEY_THEME_ENUM.ENTERPRISE,
  KEY_THEME_ENUM.INVESTOR,
  KEY_THEME_ENUM.CITY_PROVINCE,
]

export interface ThemeCreateDto {
  title: string
  key: KEY_THEME_ENUM
  value: any
}
