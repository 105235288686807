import {
  //   ContactItem,
  OrderByEnum,
  StatusDocumentEnum,
} from '@src/configs/common'

// import { AreaProps, InforBasicProps } from './basic'
import { DefaultParams } from './queryParams'

export type MediaQueryParams = DefaultParams & {
  orderBy?: OrderByEnum
  status?: StatusDocumentEnum
  s?: string
  author?: string
  isActive?: boolean
  title?: string
}

export interface MediaData
  extends Partial<{
    title: string
    listFolder?: any
    Enum: string[]
    thumbnail: string

    Media: string
    default: null
    value: number

    maxValue: number

    schedule: string
    users: string[]
    expiredTime: Date
    maxCount: number

    maxUsed: number

    minOrderValue: number
    code: string
    description: string
    guide: string
    isActive: boolean
    reuse: boolean
  }> {}
export enum MediaEnum {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  DOCUMENT = 'DOCUMENT',
}
