import { notification } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ThemeCreateDto, ThemeQueryParams } from '@src/models'
import { checkAuth, getStoredLang } from '@src/libs/localStorage'
import { ResApi, ResApiErr } from '@src/configs/resApi.interface'

import {
  createTheme,
  delThemeById,
  getTheme,
  getThemeById,
  updateTheme,
} from '../api'

const BANNER_KEY = {
  LIST: 'LIST_BANNER',
  DETAIL: 'DETAIL_BANNER',
}

// -- Fn
export const useQueryTheme = (params: ThemeQueryParams) => {
  const accessToken = checkAuth()
  return useQuery(
    [BANNER_KEY.LIST, params],
    () => getTheme(params, accessToken),
    {
      keepPreviousData: true,
      retry: 1,
    },
  )
}

export const useThemeById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [BANNER_KEY.DETAIL, id, getStoredLang()],
    () => getThemeById(id, accessToken),
    {
      enabled: !!(id && accessToken),
      retry: 1,
    },
  )
}

export const useCreateTheme = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((body: ThemeCreateDto) => createTheme(body, accessToken), {
    onSuccess(data: ResApi) {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Create Success!' })
        queryClient.refetchQueries([BANNER_KEY.LIST])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Create failure!' })
    },
  })
}

export const useUpdateTheme = (id: string) => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    (body: ThemeCreateDto) => updateTheme(id, body, accessToken),
    {
      onSuccess: (data: ResApi) => {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Update Success!' })
          queryClient.refetchQueries([BANNER_KEY.LIST])
          queryClient.refetchQueries([BANNER_KEY.DETAIL, id, getStoredLang()])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Update failure!' })
      },
    },
  )
}

export const useDeleteTheme = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((id: string) => delThemeById(id, accessToken), {
    onSuccess: (data: ResApi) => {
      notification.success({ message: data.message || 'Delete Success!' })
      queryClient.refetchQueries([BANNER_KEY.LIST])
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Delete failure!' })
    },
  })
}
