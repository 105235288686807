import { OrderByEnum } from '@src/configs/common'

import { DefaultParams } from './queryParams'

export enum EnumTypeRequest {
  VERIFY = 'Verify',
  ENTERPRISE = 'Enterprise',
}

export enum EnumStatusRequest {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  HIDE = 'HIDE',
  TRASH = 'TRASH',
}

export type UserRequestQueryParams = DefaultParams & {
  orderBy: OrderByEnum
  type?: EnumTypeRequest
  approvedStatus?: EnumStatusRequest
}

export interface RequestData {
  _id?: string
  type: EnumTypeRequest
  phoneFireBase?: string
  identityNumber: number
  taxCode: number
  identityCardImage: Array<string>
  representative: {
    name: string
    email: string
    phone: string
  }
  taxCodeEnterprise?: string
  enterpriseName?: string
  businessLicense?: Array<string>
  createdAt: Date
  approvedStatus: string
}

export interface RequestParams {
  description?: string
  status: EnumStatusRequest
}
