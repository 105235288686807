import { OrderByEnum } from '@src/configs/common'

import { DefaultParams } from './queryParams'

export type AdsQueryParams = DefaultParams & {
  orderBy: OrderByEnum
  status?: boolean
  author?: string
}

export enum AdsKeyEnum {
  HOME = 'HOME',
  HOME_NEWS = 'HOME_NEWS',

  NEWS_LIST_TOP = 'NEWS_LIST_TOP',
  NEWS_LIST_BOTTOM = 'NEWS_LIST_BOTTOM',
  NEWS_DETAIL = 'NEWS_DETAIL',

  NEWS_HASHTAG = 'NEWS_HASHTAG',
  NEWS_DETAIL_CONTENT = 'NEWS_DETAIL_CONTENT',

  INDUSTRIAL_PROJECT_LIST_TOP = 'INDUSTRIAL_PROJECT_LIST_TOP',
  INDUSTRIAL_PROJECT_LIST_BOTTOM = 'INDUSTRIAL_PROJECT_LIST_BOTTOM',
}

export enum OrderKeyEnum {
  FRIST = 1,
  SECOND = 2,

  THIRD = 3,
  FOURTH = 4,
  FIFTH = 5,
  SIXTH = 6,
  SEVENTH = 7,
  EIGHTH = 8,
}

export const ListAdsKey = [
  {
    key: AdsKeyEnum.HOME,
    value: 'Trang chủ',
  },
  {
    key: AdsKeyEnum.HOME_NEWS,
    value: 'Danh sách tin tức',
  },
  {
    key: AdsKeyEnum.NEWS_LIST_TOP,
    value: 'Chuyên mục tin tức ',
  },
  {
    key: AdsKeyEnum.NEWS_LIST_BOTTOM,
    value: 'Chi tiết tin tức',
  },
  {
    key: AdsKeyEnum.NEWS_DETAIL,
    value: 'Tin tức theo hashtag',
  },
  {
    key: AdsKeyEnum.NEWS_HASHTAG,
    value: 'Tin tức theo hashtag',
  },
  {
    key: AdsKeyEnum.NEWS_DETAIL_CONTENT,
    value: 'Tin tức theo hashtag',
  },
  {
    key: AdsKeyEnum.INDUSTRIAL_PROJECT_LIST_TOP,
    value: 'Chi tiết khu công nghiệp',
  },
  {
    key: AdsKeyEnum.INDUSTRIAL_PROJECT_LIST_BOTTOM,
    value: 'Chi tiết khu công nghiệp',
  },
]

export interface AdsManagerListProps {
  guide: string
  key: AdsKeyEnum
  show: boolean
  value: string
  mobile: string
}

export interface Ads {
  author: string
  exd: string
  schedule: string
  status: boolean
  title: string
  ads: AdsManagerListProps[]
}

export type TBenefitProps = Omit<AdsManagerListProps, 'value'> & {
  value: {
    benefits: {
      value: string
      title: string
      key: any
    }[]
  }
}

export const ListOrderKey = [
  {
    key: OrderKeyEnum.FRIST,
    value: 'Một',
  },
  {
    key: OrderKeyEnum.SECOND,
    value: 'Hai',
  },
  {
    key: OrderKeyEnum.THIRD,
    value: 'Ba',
  },
  {
    key: OrderKeyEnum.FOURTH,
    value: 'Bốn',
  },
  {
    key: OrderKeyEnum.FIFTH,
    value: 'Năm',
  },
  {
    key: OrderKeyEnum.SIXTH,
    value: 'Sáu',
  },
  {
    key: OrderKeyEnum.SEVENTH,
    value: 'Bảy',
  },
  {
    key: OrderKeyEnum.EIGHTH,
    value: 'Tám',
  },
]
