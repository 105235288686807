import { OrderByEnum } from '@src/configs/common'

import { InforBasicProps } from './basic'
import { DefaultParams } from './queryParams'

export interface RentCostProps {
  min?: number
  max?: number
}

interface hashtag {
  _id: string
  thumbnail?: string
  title: string
}

export enum enumStatusIndustrial {
  availableLease = 'Còn đất cho thuê',
  Filled = 'Đã lấp đầy',
  noInfrastructure = 'Chưa xây dựng CSHT',
  other = 'Khác',
}

export interface IndustrialData extends InforBasicProps {
  investor: string
  gallery: Array<string>
  price: number
  yearBuilt: number
  duration: number
  availableSpace: {
    unit: string
    value: number
  }
  infrastructure: Array<string>
  industrialStatus: enumStatusIndustrial
  totalArea: {
    unit: string
    value: number
  }
  location: {
    latitude: number
    longitude: number
  }
  description: string
  shortBio: string
  fillRate: number
  hashtag: Array<hashtag>
  consultant: {
    _id: string
    avatar: string
    email: string
    fullName: string
  }
}

export type IndustrialQueryParams = DefaultParams & {
  orderBy: OrderByEnum
  fillRateMin?: number
  fillRateMax?: number
  totalAreaMin?: number
  totalAreaMax?: number
}
