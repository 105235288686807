export const BASIC_LABEL = {
  LABEL: 'Nhãn',
  PEOPLE: 'Họ và Tên',
  PHONE: 'Số điện thoại',
  EMAIL: 'Email',
  ADDRESS: 'Địa chỉ',
}

export const BASIC_CONTENT = {
  NAME: 'Tên',
  CODE: 'Code',
  PHONE: 'Số điện thoại',
  ADDRESS: 'Địa chỉ',
  CREDIT_POINT: 'Credit Points',
  INTERNATIONAL_NAME: 'Tên quốc tế',
  ABBREVIATION_NAME: 'Tên viết tắt',
  TAX_CODE: 'Mã số thuế',
  ESTABLISHMENT_DATE: 'Establishment Date',
  LEGAL_REPRESENTATIVE: 'Người đại diện hợp pháp',
  TAX_ADDRESS: 'Địa chỉ thuế',
  NATIONALITY: 'Quốc tịch',
  MAIN_MARKETING: 'Thị trường chính',
  MAIN_JOB: 'Ngành nghề chính',
  FACTORY_ADDRESS: 'Địa chỉ nhà máy',
  STOCK_CODE: 'Mã chứng khoán',
  AUTHORIZED_CAPITAL: 'Vốn',
  TOTAL_INVESTMENT: 'Tổng đầu tư',
  SHORT_BIO: 'Giới thiệu',
  CONTACTS: 'Contacts',
  EMAIL: 'Email',
  MAIN_INFORMATION: 'Main Information',
  REGION_SELECT: 'Select Economic Region',
  TAXONOMIES: 'Taxonomies',
  COMPANY_ESTABLISHMENT_DATE: 'Ngày thành lập công ty',
  USERNAME: 'Tên đăng nhập',
  FULL_NAME: 'Họ và tên',
  PASSWORD: 'Mật khẩu',
  CONFIRM_PASSWORD: 'Xác nhận mật khẩu',
  ROLE: 'Vai trò',
  BIRTHDAY: 'Ngày sinh',
  GENDER: 'Giới tính',
  COUNTRY: 'Quốc gia',
  EXPIRED_AT_VIP_NG: 'ExpiredAt Vip NG',
  POINTS_NG: 'Points NG',
  POINTS_PROMOTION_NG: 'Points Promotion NG',
  EXPIRATION_DATE: 'Expiration Date',
  EXPIRED_AT_VIP_2KCN: 'ExpiredAt Vip 2KCN',
  POINTS_2KCN: 'Points 2KCN',
  POINTS_PROMOTION_2KCN: 'Points Promotion 2KCN',
}

export const BASIC_TABLE_HEADER = {
  THUMBNAIL: 'Ảnh',
  NAME: 'Tên',
  PHONE: 'Số điện thoại',
  EMAIL: 'Email',
  ADDRESS: 'Địa chỉ',
  CREATED_AT: 'Ngày tạo',
  STATUS: 'Tình trạng',
  MAIN_JOB: 'Ngành nghề chính',
  AVATAR: 'Ảnh',
  USERNAME: 'Tên đăng nhập',
  ROLE: 'Vai trò',
  DELETED_IP: 'IP xóa',
  DELETED_EMAIL: 'Email xóa',
  ACTION: 'Hành động',
  AMOUNT: 'Số lượng',
  TYPE: 'Loại',
}

export const BASIC_TABLE_CONTENT = {
  ACTIVE: 'Hoạt động',
  INACTIVE: 'Ngừng hoạt động',
  VERIFIED: 'Đã xác thực',
  UNVERIFIED: 'Chưa xác thực',
}

export const BASIC_COLLAPSE_HEADER = {
  OVERVIEW: 'Tổng quan',
  DETAIL: 'Chi tiết',
  CONTACT: 'Liên hệ',
  ACTION: 'Hành động',
  PROPS: 'Thuộc tính',
  THUMBNAIL: 'Ảnh thu nhỏ',
  AVATAR: 'Ảnh đại diện',
}
