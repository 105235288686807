import { PERMISSIONS } from '@src/configs/permissions'
import { COUPON_URL } from '@src/configs/urlRouter'
import lazyLoading from '@src/libs/lazyLoading'

import { RouteConfigItemType } from './interface'

const ListCoupon = lazyLoading(() => import('@src/pages/coupon/ListCoupon'))
const CreateCoupon = lazyLoading(() => import('@pages/coupon/CreateCoupon'))
const DetailCoupon = lazyLoading(() => import('@pages/coupon/DetailCoupon'))

const couponRouter: RouteConfigItemType[] = [
  {
    path: COUPON_URL.LIST,
    Element: ListCoupon,
    key: PERMISSIONS.LIST_COUPON,
  },
  {
    path: COUPON_URL.CREATE,
    Element: CreateCoupon,
    key: PERMISSIONS.CREATE_COUPON,
  },
  {
    path: COUPON_URL.DETAIL,
    Element: DetailCoupon,
    key: PERMISSIONS.VIEW_COUPON,
  },
]

export { couponRouter }
