import {
  AndroidOutlined,
  DashboardOutlined,
  DropboxOutlined,
  FileImageOutlined,
  FormOutlined,
  GlobalOutlined,
  PayCircleOutlined,
  ProjectOutlined,
  QuestionCircleOutlined,
  RadarChartOutlined,
  SafetyCertificateOutlined,
  SettingOutlined,
  SlackOutlined,
  SolutionOutlined,
  TagOutlined,
  TeamOutlined,
  VerifiedOutlined,
  WalletOutlined,
  UserOutlined,
} from '@ant-design/icons'
import logoApp from '@assets/images/logo.png'
import { PERMISSIONS } from '@src/configs/permissions'
import {
  CONSULTANT_URL,
  COUPON_URL,
  HASHTAG_URL,
  INDUSTRIAL_PROJECT_URL,
  MEDIA_URL,
  MEMBERSHIP_URL,
  NEWS_URL,
  PARTNER_URL,
  POST_URL,
  REGISTER_ADVISE,
  REQUEST_URL,
  TAXONOMY_URL,
  URBAN_AREA_URL,
  USER_OUTSTADINGS_URL,
} from '@src/configs/urlRouter'
import { usePermission } from '@src/hooks/usePermissions'
import { useQueryProfile } from '@src/queries/hooks'
import { onLinkNavigate } from '@src/utils'
import { Layout, Menu, MenuProps, Row, Space } from 'antd'
import React, { ReactNode } from 'react'
import { Link, Outlet } from 'react-router-dom'

import Breadcrumb from '../elements/Breadcrumb'
import NavigateAuth from '../widgets/NavigateAuth'

const { Header, Content, Footer, Sider } = Layout

interface PropsType {
  // eslint-disable-next-line react/require-default-props
  children?: ReactNode
}

type MenuItem = Required<MenuProps>['items'][number]

const rootSubmenuKeys = [
  '1',
  'user-1',
  'roles-1',
  `${TAXONOMY_URL.LIST}-1`,
  `${NEWS_URL.LIST}-1`,
  URBAN_AREA_URL.LIST,
  `${MEDIA_URL.LIST}-1`,
  'ads-1',
  'theme-1',
]

const LayoutApp: React.FC<PropsType> = ({ children }) => {
  const { data: profile } = useQueryProfile()
  const { permissions } = usePermission()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function getItem(
    label: React.ReactNode,
    key: React.Key,
    keyName: PERMISSIONS,
    icon?: React.ReactNode,
    children?: MenuItem[],
  ): MenuItem {
    if (!permissions.includes(keyName)) return null
    return {
      key,
      icon,
      children,
      label,
    } as MenuItem
  }

  const menuItems: MenuItem[] = [
    getItem(
      <Link to="/">Trang chủ</Link>,
      '1',
      PERMISSIONS.GENERAL,
      <DashboardOutlined />,
    ),

    // Industrial-project
    getItem(
      'Dự án KCN',
      `${INDUSTRIAL_PROJECT_URL.LIST}-wrap`,
      PERMISSIONS.LIST_INDUSTRIAL_AREA,
      <ProjectOutlined />,
      [
        getItem(
          <Link to={`/${INDUSTRIAL_PROJECT_URL.LIST}`}>
            Danh sách dự án KCN
          </Link>,
          INDUSTRIAL_PROJECT_URL.LIST,
          PERMISSIONS.LIST_INDUSTRIAL_AREA,
        ),
        getItem(
          <Link to={onLinkNavigate(INDUSTRIAL_PROJECT_URL.CREATE)}>
            Thêm dự án KCN
          </Link>,
          INDUSTRIAL_PROJECT_URL.CREATE,
          PERMISSIONS.CREATE_INDUSTRIAL_AREA,
        ),
      ],
    ),

    // News
    getItem(
      'Tin tức',
      `${NEWS_URL.LIST}-wrap`,
      PERMISSIONS.LIST_NEWS,
      <SolutionOutlined />,
      [
        getItem(
          <Link to={`/${NEWS_URL.LIST}`}>Danh sách tin</Link>,
          NEWS_URL.LIST,
          PERMISSIONS.LIST_NEWS,
        ),
        getItem(
          <Link to={onLinkNavigate(NEWS_URL.CREATE)}>Thêm tin</Link>,
          NEWS_URL.CREATE,
          PERMISSIONS.CREATE_NEWS,
        ),
      ],
    ),

    // Tin đăng
    getItem(
      'Tin đăng',
      `${POST_URL.POST}-wrap`,
      PERMISSIONS.LIST_NEWS,
      <FormOutlined />,
      [
        getItem(
          <Link to={`/${POST_URL.POST}`}>Danh sách tin đăng</Link>,
          POST_URL.POST,
          PERMISSIONS.LIST_NEWS,
        ),
      ],
    ),

    // Taxonomy
    getItem(
      'Chuyên mục',
      `${TAXONOMY_URL.LIST}-wrap`,
      PERMISSIONS.LIST_TAXONOMY,
      <SlackOutlined />,
      [
        getItem(
          <Link to={`/${TAXONOMY_URL.LIST}`}>Danh sách chuyên mục</Link>,
          TAXONOMY_URL.LIST,
          PERMISSIONS.LIST_TAXONOMY,
        ),
        getItem(
          <Link to={`/${TAXONOMY_URL.CREATE}`}>Thêm chuyên mục</Link>,
          TAXONOMY_URL.CREATE,
          PERMISSIONS.CREATE_TAXONOMY,
        ),
      ],
    ),

    // Hashtag
    getItem(
      'Hashtag',
      `${HASHTAG_URL.LIST}-wrap`,
      PERMISSIONS.LIST_MANAGEMENT,
      <TagOutlined />,
      [
        getItem(
          <Link to={`/${HASHTAG_URL.LIST}`}>Danh sách Hashtag</Link>,
          HASHTAG_URL.LIST,
          PERMISSIONS.LIST_MANAGEMENT,
        ),
        getItem(
          <Link to={`/${HASHTAG_URL.CREATE}`}>Thêm Hashtag</Link>,
          HASHTAG_URL.CREATE,
          PERMISSIONS.CREATE_MANAGEMENT,
        ),
      ],
    ),

    // Chuyên viên tư vấn
    getItem(
      'Chuyên viên',
      `${CONSULTANT_URL.LIST}-wrap`,
      PERMISSIONS.LIST_CONSULTANT,
      <AndroidOutlined />,
      [
        getItem(
          <Link to={`/${CONSULTANT_URL.LIST}`}>
            Danh sách chuyên chuyên viên
          </Link>,
          CONSULTANT_URL.LIST,
          PERMISSIONS.LIST_CONSULTANT,
        ),
        getItem(
          <Link to={`/${CONSULTANT_URL.CREATE}`}>Thêm chuyên viên</Link>,
          CONSULTANT_URL.CREATE,
          PERMISSIONS.UPLOAD_CONSULTANT,
        ),
      ],
    ),

    // Nhận tư vấn
    getItem(
      'Nhận tư vấn',
      `${REGISTER_ADVISE.LIST}-wrap`,
      PERMISSIONS.LIST_MANAGEMENT,
      <QuestionCircleOutlined />,
      [
        getItem(
          <Link to={`/${REGISTER_ADVISE.LIST}`}>Danh sách yêu cầu</Link>,
          REGISTER_ADVISE.LIST,
          PERMISSIONS.LIST_MANAGEMENT,
        ),
      ],
    ),

    // User
    getItem('Người dùng', 'user', PERMISSIONS.LIST_USERS, <TeamOutlined />, [
      getItem(
        <Link to="/users">Danh sách người dùng</Link>,
        'list-user',
        PERMISSIONS.LIST_USERS,
      ),
      getItem(
        <Link to="/admin">Danh sách quản trị</Link>,
        'list-admin',
        PERMISSIONS.LIST_USERS,
      ),
      getItem(
        <Link to="/user-create">Thêm người dùng</Link>,
        'user-create',
        PERMISSIONS.CREATE_USER,
      ),

      getItem(
        <Link to="/user-deleted">Người dùng bị xóa</Link>,
        'user-deleted',
        PERMISSIONS.LIST_DELETED_USER,
      ),
    ]),

    // Đối tác phát triển
    getItem(
      'Đối tác phát triển',
      `${PARTNER_URL.LIST}-wrap`,
      PERMISSIONS.LIST_PARTNER,
      <GlobalOutlined />,
      [
        getItem(
          <Link to={`/${PARTNER_URL.LIST}`}>Danh sách đối tác</Link>,
          PARTNER_URL.LIST,
          PERMISSIONS.LIST_PARTNER,
        ),
        getItem(
          <Link to={`/${PARTNER_URL.CREATE}`}>Thêm đối tác</Link>,
          PARTNER_URL.CREATE,
          PERMISSIONS.UPLOAD_PARTNER,
        ),
      ],
    ),

    /// media
    getItem('Media', 'media', PERMISSIONS.VIEW_MEDIA, <FileImageOutlined />, [
      getItem(
        <Link to="/media">Danh sách</Link>,
        'media-list',
        PERMISSIONS.LIST_MEDIA,
      ),
    ]),

    // Gói hội viên
    getItem(
      'Gói hội viên',
      `${MEMBERSHIP_URL.LIST}-wrap`,
      PERMISSIONS.LIST_MANAGEMENT,
      <DropboxOutlined />,
      [
        getItem(
          <Link to={`/${MEMBERSHIP_URL.LIST}`}>Danh sách gói hội viên</Link>,
          MEMBERSHIP_URL.LIST,
          PERMISSIONS.LIST_MANAGEMENT,
        ),
        getItem(
          <Link to={`/${MEMBERSHIP_URL.CREATE}`}>Thêm gói hội viên</Link>,
          MEMBERSHIP_URL.CREATE,
          PERMISSIONS.CREATE_MANAGEMENT,
        ),
        getItem(
          <Link to={`/${MEMBERSHIP_URL.EXCLUSIVE_RIGHT}`}>Đặc quyền</Link>,
          MEMBERSHIP_URL.EXCLUSIVE_RIGHT,
          PERMISSIONS.CREATE_MANAGEMENT,
        ),
        getItem(
          <Link to={`/${MEMBERSHIP_URL.FAQ}`}>FAQ</Link>,
          MEMBERSHIP_URL.FAQ,
          PERMISSIONS.CREATE_MANAGEMENT,
        ),
      ],
    ),

    // Danh sách hội viên
    getItem(
      'Hội viên',
      `${USER_OUTSTADINGS_URL.LIST}-wrap`,
      PERMISSIONS.LIST_MANAGEMENT,
      <UserOutlined />,
      [
        getItem(
          <Link to={`/${USER_OUTSTADINGS_URL.LIST}`}>Danh sách hội viên</Link>,
          USER_OUTSTADINGS_URL.LIST,
          PERMISSIONS.LIST_MANAGEMENT,
        ),
      ],
    ),

    // Coupon
    getItem('Voucher', `voucher`, PERMISSIONS.LIST_COUPON, <WalletOutlined />, [
      getItem(
        <Link to={`/${COUPON_URL.LIST}`}>Danh sách voucher</Link>,
        COUPON_URL.LIST,
        PERMISSIONS.LIST_COUPON,
      ),
      getItem(
        <Link to={`/${COUPON_URL.CREATE}`}>Thêm voucher</Link>,
        COUPON_URL.CREATE,
        PERMISSIONS.CREATE_COUPON,
      ),
    ]),

    // Payment
    getItem(
      'Thanh toán',
      'payment',
      PERMISSIONS.LIST_COUPON,
      <PayCircleOutlined />,
      [
        getItem(
          <Link to="/payment">Danh sách thanh toán</Link>,
          'list-payment',
          PERMISSIONS.LIST_COUPON,
        ),
      ],
    ),

    // User-request
    getItem(
      'Xác thực',
      'list-user-request',
      PERMISSIONS.LIST_USER_REQUEST,
      <VerifiedOutlined />,
      [
        getItem(
          <Link to={`/${REQUEST_URL.VERIFY}`}>Danh sách yêu cầu </Link>,
          'list-verify-request',
          PERMISSIONS.LIST_VERIFY_REQUEST,
        ),
      ],
    ),

    // Role
    getItem(
      'Vai trò',
      'roles-wrap',
      PERMISSIONS.LIST_ROLES,
      <SafetyCertificateOutlined />,
      [
        getItem(
          <Link to="/roles">Danh sách vai trò</Link>,
          'roles',
          PERMISSIONS.LIST_ROLES,
        ),
        getItem(
          <Link to="/role-create">Thêm vai trò</Link>,
          'role-create',
          PERMISSIONS.LIST_ROLES,
        ),
      ],
    ),
    getItem('Ads', 'ads-wrap', PERMISSIONS.LIST_ADS, <RadarChartOutlined />, [
      getItem(
        <Link to="/ads">Danh sách ads</Link>,
        'ads',
        PERMISSIONS.LIST_ADS,
      ),
      getItem(
        <Link to="/ads-create">Thêm Ads</Link>,
        'ads-create',
        PERMISSIONS.CREATE_ADS,
      ),
    ]),

    // Theme
    getItem('Cài đặt', 'theme', PERMISSIONS.LIST_THEME, <SettingOutlined />, [
      getItem(<Link to="/theme">ACF</Link>, 'theme-1', PERMISSIONS.LIST_THEME),
    ]),
  ]

  const [openKeys, setOpenKeys] = React.useState<string[]>([])
  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }
  return (
    <Layout style={{ minHeight: '100vh' }} hasSider>
      <Sider breakpoint="lg" collapsedWidth="0" style={{ zIndex: 1 }}>
        <div className="logo-app" style={{ textAlign: 'center' }}>
          <Link to="/">
            <img src={logoApp} alt="logo" />
          </Link>
        </div>
        <Menu
          theme="dark"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          mode="inline"
          items={menuItems}
        />
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <Row
            justify="end"
            align="middle"
            style={{
              marginLeft: 16,
              marginRight: 16,
              height: '100%',
            }}
          >
            <Space size={12}>
              <NavigateAuth profile={profile?.data || null} />
            </Space>
          </Row>
        </Header>
        <Content style={{ margin: '0 16px' }}>
          <div style={{ margin: '16px 0' }}>
            <Breadcrumb />
          </div>
          <div
            style={{
              padding: '0',
              // minHeight: 'calc(100vh - 190px)',
            }}
          >
            {children ?? <Outlet />}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          {'Designed and Developed by '}
          <a href="https://twinger.vn/" target="_blank" rel="noreferrer">
            Twinger
          </a>
        </Footer>
      </Layout>
    </Layout>
  )
}

export default LayoutApp
