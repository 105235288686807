import { PERMISSIONS } from '@src/configs/permissions'
import { CONSULTANT_URL } from '@src/configs/urlRouter'
import lazyLoading from '@src/libs/lazyLoading'

import { RouteConfigItemType } from './interface'

const ListConsultant = lazyLoading(
  () => import('@src/pages/consultant/ListConsultant'),
)
const CreateConsultant = lazyLoading(
  () => import('@pages/consultant/CreateConsultant'),
)
const DetailConsultant = lazyLoading(
  () => import('@pages/consultant/DetailConsultant'),
)

const consultantRouter: RouteConfigItemType[] = [
  {
    path: CONSULTANT_URL.LIST,
    Element: ListConsultant,
    key: PERMISSIONS.LIST_INDUSTRIAL_AREA,
  },
  {
    path: CONSULTANT_URL.CREATE,
    Element: CreateConsultant,
    key: PERMISSIONS.CREATE_INDUSTRIAL_AREA,
  },
  {
    path: CONSULTANT_URL.DETAIL,
    Element: DetailConsultant,
    key: PERMISSIONS.VIEW_INDUSTRIAL_AREA,
  },
]

export { consultantRouter }
