import { PERMISSIONS } from '@src/configs/permissions'
import lazyLoading from '@src/libs/lazyLoading'
import DetailDeletedUser from '@src/pages/users/DetailDeletedUser'
import ListDeletedUsers from '@src/pages/users/ListDeletedUser'
import React from 'react'

import {
  adsRouter,
  consultantRouter,
  industrialProjectRouter,
  mediaRouter,
  membershipRouter,
  newsRouter,
  outstandingRouter,
  partnerRouter,
  postRouter,
  registerAdviseRouter,
  requestRouter,
  taxonomyRouter,
  themeRouter,
} from './router'
import { couponRouter } from './router/coupon'
import { hashtagRouter } from './router/hashtag'
import { paymentRouter } from './router/payment'

export type RouteConfigItemType = {
  path: string
  Element: React.FC
  key: PERMISSIONS
}

const Dashboard = lazyLoading(() => import('@pages/dashboard'))
const ListRole = lazyLoading(() => import('@pages/roles/ListRole'))
const CreateRole = lazyLoading(() => import('@pages/roles/CreateRole'))
const DetailRole = lazyLoading(() => import('@pages/roles/DetailRole'))

// users
const ListUsers = lazyLoading(() => import('@pages/users/ListUsers'))
const ListAdmin = lazyLoading(() => import('@pages/users/ListAdmin'))
const CreateUser = lazyLoading(() => import('@pages/users/CreateUser'))
const DetailUser = lazyLoading(() => import('@pages/users/DetailUser'))

const routeConfigUser: RouteConfigItemType[] = [
  {
    path: '',
    Element: Dashboard,
    key: PERMISSIONS.GENERAL,
  },
  {
    path: 'users',
    Element: ListUsers,
    key: PERMISSIONS.LIST_USERS,
  },
  {
    path: 'admin',
    Element: ListAdmin,
    key: PERMISSIONS.LIST_USERS,
  },
  {
    path: 'users/:userId',
    Element: DetailUser,
    key: PERMISSIONS.UPDATE_USER,
  },
  {
    path: 'user-create',
    Element: CreateUser,
    key: PERMISSIONS.CREATE_USER,
  },
  {
    path: 'user-deleted',
    Element: ListDeletedUsers,
    key: PERMISSIONS.LIST_DELETED_USER,
  },
  {
    path: 'user-deleted/:userId',
    Element: DetailDeletedUser,
    key: PERMISSIONS.VIEW_DELETED_USER,
  },
  {
    path: 'roles',
    Element: ListRole,
    key: PERMISSIONS.LIST_ROLES,
  },
  {
    path: 'roles/:roleId',
    Element: DetailRole,
    key: PERMISSIONS.VIEW_ROLE,
  },
  {
    path: 'role-create',
    Element: CreateRole,
    key: PERMISSIONS.CREATE_ROLE,
  },
]

const routeConfig = routeConfigUser
  .concat(taxonomyRouter)
  .concat(themeRouter)
  .concat(newsRouter)
  .concat(requestRouter)
  .concat(mediaRouter)
  .concat(industrialProjectRouter)
  .concat(adsRouter)
  .concat(postRouter)
  .concat(paymentRouter)
  .concat(consultantRouter)
  .concat(hashtagRouter)
  .concat(registerAdviseRouter)
  .concat(couponRouter)
  .concat(membershipRouter)
  .concat(partnerRouter)
  .concat(outstandingRouter)

export default routeConfig
