import { checkAuth } from '@src/libs/localStorage'
import { DefaultParams, IMembership } from '@src/models'

import { request } from './config'

export const getListMembership = (params: DefaultParams) =>
  request({
    url: 'membership-package',
    method: 'GET',
    params: { ...params, systemType: '2KCN' },
  })

export const createMembership = (data: IMembership, token: string) =>
  request({ url: 'membership-package', method: 'POST', data }, { token })

export const getMembershipById = (id: string, token: string) =>
  request(
    { url: `membership-package/${id}`, params: { isCms: true } },
    { token },
  )

export const updateMembership = (
  id: string,
  data: IMembership,
  token: string,
) =>
  request({ url: `membership-package/${id}`, method: 'PUT', data }, { token })

export const delMembershipById = (id: string, token: string) =>
  request({ url: `membership-package/${id}`, method: 'DELETE' }, { token })
export const getListFAQ = (params: DefaultParams) =>
  request({
    url: 'faq',
    method: 'GET',
    params: { ...params },
  })

export const getDetailsTheme = (id: string) => {
  const token = checkAuth()
  return request({ url: `theme/${id}`, method: 'GET' }, { token })
}
