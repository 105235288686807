import { FileTextOutlined } from '@ant-design/icons'
import FallbackImage from '@src/components/elements/FallbackImage'
import checkTypeMedia from '@src/libs/checktypeMedia'
import { ThemeContext } from '@src/themeContext'
import { Button, Col, Image, message, Popover, Row } from 'antd'
import React from 'react'

const Gallery: React.FC<any> = ({
  data,
  setLocationMedia,
  listFileMedia,
  setListFileMedia,
  mutilple,
  locationMedia,
}) => {
  const context = React.useContext<any>(ThemeContext)
  const { dataRespone } = context

  // console.log('listFileMedia', listFileMedia)
  React.useEffect(() => {
    if (listFileMedia) {
      setLocationMedia(listFileMedia[0])
    }
  }, [dataRespone])

  const handleClickMedia = (value: any) => {
    if (!mutilple) {
      if (checkTypeMedia(value.location)) {
        if (locationMedia === value.location) {
          setLocationMedia('')
        } else {
          setLocationMedia(value.location)
          // message.success('setOK')
        }

        // message.success('ok')
      } else {
        message.error('You can only upload Image file!')
      }
    } else if (mutilple) {
      if (checkTypeMedia(value.location)) {
        const index = listFileMedia.findIndex(
          (val: any) => val === value.location,
        )

        if (index !== -1) {
          const newList = [...listFileMedia]

          newList.splice(index, 1)

          setListFileMedia(newList)
        } else {
          setListFileMedia([...listFileMedia, value.location])
        }

        // message.success('ok')
      } else {
        message.error('You can only upload Image file!')
      }
    }
  }
  return (
    <div className="_showGarllery">
      <Row>
        <Col span={24}>
          <Row
            className="_wrapGarllery"
            gutter={[16, 16]}
            style={{
              width: '100%',
              marginBottom: '25px',
            }}
          >
            {dataRespone &&
              [...dataRespone, ...data]?.map((value: any, key: any) => (
                <div
                  className="_blockMediaStyle"
                  key={key}
                  style={{
                    height: '40%',
                    overflow: 'hidden',
                    position: 'relative',
                    textAlign: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      boxShadow: '#e3e0e0 1px 0px 5px 1px',

                      border: ` 1px solid ${
                        mutilple
                          ? listFileMedia &&
                            listFileMedia.find(
                              (val: any) => val === value.location,
                            )
                            ? 'red'
                            : 'black'
                          : locationMedia && locationMedia === value.location
                          ? 'red'
                          : 'black'
                      }`,
                    }}
                    onClick={() => handleClickMedia(value)}
                  >
                    <Popover
                      content={
                        value?.location && checkTypeMedia(value?.location) ? (
                          <Image
                            loading="lazy"
                            src={value.location}
                            preview={false}
                            width="200px"
                          />
                        ) : (
                          <FallbackImage />
                        )
                      }
                      title={
                        <Row
                          gutter={[0, 5]}
                          style={{ flexDirection: 'column' }}
                        >
                          <Col>
                            Title: {value?.title?.substring(0, 20)}
                            {value?.title?.length > 20 ? '...' : ''}
                          </Col>
                          <Col>
                            Type: {value?.mimetype?.substring(0, 20)}{' '}
                            {value?.mimetype?.length > 20 ? '...' : ''}
                          </Col>
                        </Row>
                      }
                    >
                      {value.location && checkTypeMedia(value.location) ? (
                        <Image
                          loading="lazy"
                          src={
                            value.location ? value.location : <FallbackImage />
                          }
                          preview={false}
                          width="200px"
                          height="100%"
                          style={{
                            aspectRatio: '1/1',
                            objectFit: 'cover',
                          }}
                        />
                      ) : (
                        <FileTextOutlined style={{ fontSize: '80px' }} />
                      )}
                    </Popover>
                  </Button>
                </div>
              ))}
          </Row>
        </Col>
        <Col
          span={24}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* {data?.data?.total > 10 &&
      data?.data?.total > params.limit && (
        <Button
          style={{ width: '130px' }}
          onClick={handleLoadMoreChange}
        >
          + Load more
        </Button>
      )} */}
        </Col>
      </Row>
    </div>
  )
}

export default Gallery
