import { checkAuth } from '@src/libs/localStorage'
import { HashtagData, HashtagQueryParams } from '@src/models'

import { request } from './config'

export const getListHashtag = (params: HashtagQueryParams) =>
  request(
    { url: 'hashtag/find-all', method: 'GET', params },
    { token: checkAuth() },
  )

export const getHashtagById = (id: string) =>
  request({ url: `hashtag/${id}`, method: 'GET' }, { token: checkAuth() })

export const updateHashtag = (id: string, data: HashtagData) =>
  request({ url: `hashtag/${id}`, method: 'PUT', data }, { token: checkAuth() })

export const deleteHashtag = (id: string) =>
  request({ url: `hashtag/${id}`, method: 'DELETE' }, { token: checkAuth() })

export const createHashtag = (data: HashtagData) =>
  request(
    {
      url: 'hashtag/create',
      method: 'POST',
      data: { ...data, systemType: '2KCN' },
    },
    { token: checkAuth() },
  )
