/* eslint-disable no-unsafe-optional-chaining */
import { ResApi, ResApiErr } from '@configs/resApi.interface'
import * as userApi from '@queries/api/users'
import { queryClient } from '@queries/index'
import {
  GET_DETAIL_DELETED_USERS,
  GET_DETAIL_USERS,
  GET_LIST_DELETED_USERS,
  GET_LIST_USERS,
  RESTORE_DELETED_USERS,
  GET_LIST_USERS_OUTSTANDING,
} from '@queries/keys'
import { checkAuth } from '@src/libs/localStorage'
import { UserQueryParams } from '@src/models/user'
import { notification } from 'antd'
import { useInfiniteQuery, useMutation, useQuery } from 'react-query'
import { MembershipParams } from '@src/models'

import { TCreateUser } from '../../models/user'

export const useQueryListUser = (params: UserQueryParams) => {
  const accessToken = checkAuth()

  return useQuery(
    [GET_LIST_USERS, params],
    () => userApi.getListUser(accessToken, params),
    {
      enabled: !!accessToken,
      keepPreviousData: true,
      retry: 2,
    },
  )
}

export const useQueryListOutstanding = (params: MembershipParams) => {
  const accessToken = checkAuth()

  return useQuery(
    [GET_LIST_USERS_OUTSTANDING, params],
    () => userApi.getListUserOutstanding(accessToken, params),
    {
      enabled: !!accessToken,
      keepPreviousData: true,
      retry: 2,
    },
  )
}

export const useInfiniteListUser = (params: UserQueryParams) => {
  const accessToken = checkAuth()

  return useInfiniteQuery({
    queryKey: [GET_LIST_USERS, params],
    queryFn: ({ pageParam = 1 }) =>
      userApi.getListUser(accessToken, { ...params, page: pageParam }),
    getNextPageParam: (lastPage) =>
      lastPage?.data?.page <
      Math.ceil(lastPage?.data?.total / lastPage?.data?.limit)
        ? lastPage?.data?.page + 1
        : undefined,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  })
}

export const useQueryListDeletedUser = (params: UserQueryParams) => {
  const accessToken = checkAuth()

  return useQuery(
    [GET_LIST_DELETED_USERS, params],
    () => userApi.getListDeletedUser(accessToken, params),
    {
      enabled: !!accessToken,
      keepPreviousData: true,
      retry: 2,
    },
  )
}

export const useQueryDetailDeletedUserById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [GET_DETAIL_DELETED_USERS, id],
    () => userApi.getDetailDeletedUserById(accessToken, id),
    {
      enabled: !!(id && accessToken),
      retry: 1,
    },
  )
}
export const useQueryRestoreUserById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [RESTORE_DELETED_USERS, id],
    () => userApi.restoreDeletedUserById(accessToken, id),
    {
      enabled: !!(id && accessToken),
      retry: 1,
    },
  )
}

export const useQueryDetailUserById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [GET_DETAIL_USERS, id],
    () => userApi.getDetailUserById(accessToken, id),
    {
      enabled: !!(id && accessToken),
      retry: 1,
    },
  )
}

export const useQueryCreateUser = () => {
  const accessToken = checkAuth()
  return useMutation(
    (body: TCreateUser) => userApi.createUser(accessToken, body),
    {
      onSuccess: async (data: ResApi) => {
        if (data.statusCode === 201) {
          notification.success({ message: data.message || 'Create Success!' })
          queryClient.refetchQueries([GET_LIST_USERS])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Create failure!' })
      },
    },
  )
}

export const useQueryUpdateUserById = (id: string) => {
  const accessToken = checkAuth()
  return useMutation(
    (body: Partial<TCreateUser>) =>
      userApi.updateUserById(accessToken, id, body),
    {
      onSuccess: async (data: ResApi) => {
        if (data.statusCode === 200) {
          notification.success({ message: data.message || 'Update Success!' })
          queryClient.refetchQueries([GET_DETAIL_USERS, id])
          queryClient.refetchQueries([GET_LIST_USERS])
          queryClient.refetchQueries([GET_LIST_USERS_OUTSTANDING])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Update failure!' })
      },
    },
  )
}

export const useQueryDeleteUserById = () => {
  const accessToken = checkAuth()
  return useMutation((id: string) => userApi.deleteUserById(accessToken, id), {
    onSuccess: async (data: ResApi) => {
      if (data.statusCode === 200) {
        notification.success({ message: data.message || 'Delete Success!' })
        queryClient.refetchQueries([GET_LIST_DELETED_USERS])
        queryClient.refetchQueries([GET_LIST_USERS])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Delete failure!' })
    },
  })
}

export const useQueryRestoreDeletedUserById = () => {
  const accessToken = checkAuth()
  return useMutation(
    (id: string) => userApi.restoreDeletedUserById(accessToken, id),
    {
      onSuccess: async (data: ResApi) => {
        if (data.statusCode === 200) {
          notification.success({ message: data.message || 'Delete Success!' })
          queryClient.refetchQueries([GET_LIST_USERS])
          queryClient.refetchQueries([GET_LIST_DELETED_USERS])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Delete failure!' })
      },
    },
  )
}
