import { PERMISSIONS } from '@src/configs/permissions'
import { MEDIA_URL } from '@src/configs/urlRouter'
import lazyLoading from '@src/libs/lazyLoading'

import { RouteConfigItemType } from './interface'

const List = lazyLoading(() => import('@pages/media/List'))

const DetailFolder = lazyLoading(() => import('@pages/media/folder'))
const DetailMedia = lazyLoading(() => import('@pages/media/Detail'))

const mediaRouter: RouteConfigItemType[] = [
  {
    path: MEDIA_URL.LIST,
    Element: List,
    key: PERMISSIONS.LIST_MEDIA,
  },

  {
    path: MEDIA_URL.FOLDER_DETAIL,
    Element: DetailFolder,
    key: PERMISSIONS.VIEW_MEDIA,
  },

  {
    path: MEDIA_URL.DETAIL,
    Element: DetailMedia,
    key: PERMISSIONS.VIEW_MEDIA,
  },
]

export { mediaRouter }
