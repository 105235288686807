import { PERMISSIONS } from '@src/configs/permissions'
import lazyLoading from '@src/libs/lazyLoading'
import { HASHTAG_URL } from '@src/configs/urlRouter'

import { RouteConfigItemType } from './interface'

const List = lazyLoading(() => import('@pages/hashtag/ListHashtag'))
const Create = lazyLoading(() => import('@pages/hashtag/CreateHashtag'))
const Detail = lazyLoading(() => import('@pages/hashtag/DetailHashtag'))

const hashtagRouter: RouteConfigItemType[] = [
  {
    path: HASHTAG_URL.LIST,
    Element: List,
    key: PERMISSIONS.LIST_TAXONOMY,
  },
  {
    path: HASHTAG_URL.CREATE,
    Element: Create,
    key: PERMISSIONS.CREATE_TAXONOMY,
  },
  {
    path: HASHTAG_URL.DETAIL,
    Element: Detail,
    key: PERMISSIONS.VIEW_TAXONOMY,
  },
]

export { hashtagRouter }
