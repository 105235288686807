import { PERMISSIONS } from '@src/configs/permissions'
import { REGISTER_ADVISE } from '@src/configs/urlRouter'
import lazyLoading from '@src/libs/lazyLoading'

import { RouteConfigItemType } from './interface'

const ListRegisterAdvise = lazyLoading(
  () => import('@src/pages/register-advise/ListRegisterAdvise'),
)

const DetailRegisterAdvise = lazyLoading(
  () => import('@pages/register-advise/DetailRegisterAdvise'),
)

const registerAdviseRouter: RouteConfigItemType[] = [
  {
    path: REGISTER_ADVISE.LIST,
    Element: ListRegisterAdvise,
    key: PERMISSIONS.LIST_INDUSTRIAL_AREA,
  },
  {
    path: REGISTER_ADVISE.DETAIL,
    Element: DetailRegisterAdvise,
    key: PERMISSIONS.VIEW_INDUSTRIAL_AREA,
  },
]

export { registerAdviseRouter }
