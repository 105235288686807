import { OrderByEnum, OrderEnum } from '@src/configs/common'
// import { usePushQueryToUrl } from '@src/hooks'
import { FolderOutlined, MenuFoldOutlined } from '@ant-design/icons'
import FormAddFolder from '@src/components/widgets/FormAddFolder'
import { MediaQueryParams } from '@src/models'
import { useFileFolderById } from '@src/queries/hooks'
import { ThemeContext } from '@src/themeContext'
import { Alert, Button, Col, Popover, Row, Space, Spin, Tabs } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import FormUpdateFolder from '../ActionUpdateFolder'
import Gallery from '../GalleryMedia'
import UploadMediaDnD from '../UploadMediaDnD'

export interface ListMediaProps {
  setDataMedia?: any
  setListFile?: any
  mutilple: boolean
}

const ListMedia: React.FC<ListMediaProps> = ({ setDataMedia, mutilple }) => {
  // const { mutate, isLoading } = useCreateMedia()

  /// add media

  const [locationMedia, setLocationMedia] = useState([])

  const [listFileMedia, setListFileMedia] = useState<any>([])
  // console.log('listFileMedia', listFileMedia)
  const [activeKey, setActiveKey] = useState<string>('itemm-2')

  useEffect(() => {
    if (!mutilple) {
      setDataMedia(listFileMedia[0] || locationMedia)
    } else {
      setDataMedia(listFileMedia)
    }
  }, [locationMedia, listFileMedia])

  /// /
  const [setParamSeachFolder] = useState<string>()

  // const hasSelected = selectedRowKeys.length > 0

  const [searchParams] = useSearchParams()
  const paramSearchUrl = useMemo(
    () => Object.fromEntries(Array.from(searchParams)),
    [searchParams],
  )

  const baseParams = React.useMemo(
    () => ({
      page: 1,
      limit: 16,
      order: OrderEnum.DESC,
      orderBy: OrderByEnum.CREATE_AT,
      s: '',

      // isCms: true,
      ...paramSearchUrl,
    }),
    [paramSearchUrl],
  )
  const context: any = React.useContext(ThemeContext)
  const { select, setSelect } = context

  const [params] = useState<MediaQueryParams>(baseParams)

  const { data: listFolder, isLoading: loaddingFoler } = useFileFolderById(
    select ?? '',
    params,
  )

  const dataFolderParent = listFolder?.data?.folders || listFolder?.folders

  const data = listFolder?.data?.medias || listFolder?.medias
  if (loaddingFoler) {
    return (
      <Spin tip="Loading...">
        <Alert
          message="Alert message title"
          description="Further details about the context of this alert."
          type="info"
        />
      </Spin>
    )
  }
  /// /updaload file

  // const [loading, setLoading] = useState(false)

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <p>{select}</p>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col xxl={4} xs={24} xl={4}>
              <FormAddFolder
                showSeach
                setParamSeachFolder={setParamSeachFolder}
                select={select}
              />

              <Row gutter={[16, 16]}>
                {dataFolderParent?.map((value: any, key: any) => (
                  <Col span={24} key={key}>
                    <Row
                      gutter={[16, 16]}
                      justify="space-between"
                      align="middle"
                    >
                      <Col span={21}>
                        <Button
                          onClick={() => setSelect(value._id)}
                          style={{ width: '100%' }}
                        >
                          <Row style={{ width: '100%', flexWrap: 'nowrap' }}>
                            <Col>
                              <span>
                                {' '}
                                <FolderOutlined />
                              </span>{' '}
                            </Col>
                            <Col
                              style={{
                                marginLeft: '15px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {value?.name?.substring(0, 20)}
                              {value?.name?.length > 20 ? '...' : ''}
                            </Col>
                          </Row>
                        </Button>
                      </Col>

                      <Col span={3}>
                        <Popover
                          content={
                            <FormUpdateFolder
                              name={value.name}
                              id={value._id}
                              page="media"
                            />
                          }
                          placement="bottomLeft"
                        >
                          <MenuFoldOutlined />
                        </Popover>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Col>

            <Col xxl={20} xs={24} xl={20}>
              <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
              >
                <Col span={24}>
                  <Tabs
                    type="card"
                    defaultActiveKey={activeKey}
                    activeKey={activeKey}
                    onTabClick={(key) => setActiveKey(key)}
                  >
                    <Tabs.TabPane
                      tab="Upload files"
                      key="itemm-1"
                      className="_wrapUploadGarllery"
                    >
                      <UploadMediaDnD
                        select={select}
                        setActiveKey={setActiveKey}
                        setListFileMedia={setListFileMedia}
                        // setLocationMedia={locationMedia}
                        mutilple={mutilple}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Media Library" key="itemm-2">
                      <Gallery
                        data={data}
                        setLocationMedia={setLocationMedia}
                        listFileMedia={listFileMedia}
                        setListFileMedia={setListFileMedia}
                        mutilple={mutilple}
                        locationMedia={locationMedia}
                      />
                    </Tabs.TabPane>
                  </Tabs>
                </Col>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </Space>
  )
}

export default ListMedia
