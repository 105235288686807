import { PERMISSIONS } from '@src/configs/permissions'
import { REQUEST_URL } from '@src/configs/urlRouter'
import lazyLoading from '@src/libs/lazyLoading'

import { RouteConfigItemType } from './interface'

const Detail = lazyLoading(() => import('@pages/request/DetailRequest'))
const Verify = lazyLoading(() => import('@src/pages/request/ListRequest'))

const requestRouter: RouteConfigItemType[] = [
  {
    path: REQUEST_URL.VERIFY,
    Element: Verify,
    key: PERMISSIONS.LIST_VERIFY_REQUEST,
  },
  {
    path: REQUEST_URL.DETAIL,
    Element: Detail,
    key: PERMISSIONS.DETAIL_REQUEST,
  },
]

export { requestRouter }
