import HeadHtml from '@components/layout/HeadHtml'
import LoadingIndicator from '@src/components/elements/Loading'
import { baseParams } from '@src/configs/api'
import { OrderEnum } from '@src/configs/common'
import { UserQueryParams } from '@src/models/user'
import { useQueryListDeletedUser } from '@src/queries/hooks'
import { Col, Row, Table } from 'antd'
import { TablePaginationConfig } from 'antd/es/table'
import { debounce } from 'lodash'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ListHeader from './components/List.header'
import { columns } from './configs/table.config'

function ListDeletedUsers() {
  const [params, setParams] = useState<UserQueryParams>(baseParams)
  const navigate = useNavigate()
  const { data, isLoading, isFetching } = useQueryListDeletedUser(params)

  const handleChangeTable = (
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any,
  ) => {
    if (isLoading || isFetching) return <LoadingIndicator />

    return setParams({
      ...params,
      page: pagination.current || 1,
      limit: pagination.pageSize || 20,
      status: filters.status && filters.status[0],
      roles: filters.roles && filters.roles[0],
      order:
        !sorter?.order || sorter?.order === 'ascend'
          ? OrderEnum.DESC
          : OrderEnum.ASC,
    })
  }

  return (
    <>
      <HeadHtml title="Danh sách người dùng đã xóa" />
      <Row
        justify="space-between"
        align="bottom"
        style={{ marginBottom: 16 }}
        gutter={[16, 8]}
      >
        <Col sm={24} md={24} xs={24} className="row-header">
          <ListHeader
            title="Danh sách người dùng đã xóa"
            placeholder="Search"
            onChange={debounce(
              (e: React.ChangeEvent<HTMLInputElement>) =>
                setParams((preParams) => ({
                  ...preParams,
                  s: e.target.value,
                })),
              500,
            )}
          />
        </Col>
      </Row>
      <Table
        rowKey="_id"
        showSorterTooltip
        columns={columns}
        dataSource={data?.data?.data || []}
        pagination={{
          pageSize: data?.data?.limit || 10,
          total: data?.data?.total || 0,
          current: data?.data?.page || 1,
          pageSizeOptions: ['10', '20', '50', '100'],
          showSizeChanger: true,
        }}
        loading={isLoading || isFetching}
        onRow={(item) => ({
          onClick: () => navigate(`/user-deleted/${item._id}`),
        })}
        onChange={handleChangeTable}
        scroll={{ x: 992 }}
      />
    </>
  )
}

export default ListDeletedUsers
