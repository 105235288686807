import { useCreateFolderMedia } from '@src/queries/hooks'
import { Button, Col, Form, Input, Popover, Row } from 'antd'
import Search from 'antd/lib/input/Search'
import { useState } from 'react'

interface ActionCreateFolder {
  showSeach: boolean
  name?: string
  id?: string
  setParams?: any
  params?: any
  setParamSeachFolder?: any
  select?: any
}
const FormAddFolder: React.FC<ActionCreateFolder> = (props) => {
  const [open, setOpen] = useState(false)
  const Status = props

  const idParentFolder = Status.select || null

  const [form] = Form.useForm()
  const { mutate } = useCreateFolderMedia()

  const onFinish = (values: any) => {
    const dataFolder: any = {
      name: values.name,
      parentId: Status.id || idParentFolder,
    }

    mutate(dataFolder)
  }

  const handleSubmit = () => {
    form.submit()
  }
  const onSearch = (value: string) => {
    Status?.setParamSeachFolder(value)
  }
  const hide = () => {
    setOpen(false)
  }
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }
  return (
    <Form form={form} onFinish={onFinish}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Popover
            visible={open}
            trigger="click"
            onVisibleChange={handleOpenChange}
            content={
              <Row gutter={[10, 0]}>
                <Col span={24}>
                  <Form.Item name="name">
                    <Input placeholder="Enter Name" allowClear />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row gutter={[16, 16]}>
                    <Col>
                      <Button type="primary" onClick={handleSubmit}>
                        {' '}
                        + Add{' '}
                      </Button>
                    </Col>
                    <Col>
                      <Button onClick={hide}>Cancel </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            }
            title="Add folder"
          >
            <Row>
              <Button style={{ width: '100%' }}>
                {' '}
                + {Status.name || 'Add New folder'}{' '}
              </Button>
            </Row>
          </Popover>
        </Col>
        {Status.showSeach && (
          <Col span={24}>
            <Form.Item name="name">
              <Search placeholder="Enter Name" allowClear onSearch={onSearch} />
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  )
}

export default FormAddFolder
