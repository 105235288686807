import { PERMISSIONS } from '@src/configs/permissions'
import lazyLoading from '@src/libs/lazyLoading'
import { USER_OUTSTADINGS_URL } from '@src/configs/urlRouter'

import { RouteConfigItemType } from './interface'

const List = lazyLoading(() => import('@pages/user-outstanding/List'))

const Detail = lazyLoading(() => import('@pages/user-outstanding/Detail'))

const outstandingRouter: RouteConfigItemType[] = [
  {
    path: USER_OUTSTADINGS_URL.LIST,
    Element: List,
    key: PERMISSIONS.LIST_MANAGEMENT,
  },
  {
    path: USER_OUTSTADINGS_URL.DETAIL,
    Element: Detail,
    key: PERMISSIONS.LIST_MANAGEMENT,
  },
]

export { outstandingRouter }
