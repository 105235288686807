import { PERMISSIONS } from '@src/configs/permissions'
import { POST_URL } from '@src/configs/urlRouter'
import lazyLoading from '@src/libs/lazyLoading'

import { RouteConfigItemType } from './interface'

const ListPost = lazyLoading(() => import('@pages/post/ListPost'))
const DetailPost = lazyLoading(() => import('@pages/post/DetailPost'))

const postRouter: RouteConfigItemType[] = [
  {
    path: POST_URL.POST,
    Element: ListPost,
    key: PERMISSIONS.LIST_NEWS,
  },
  {
    path: POST_URL.DETAIL,
    Element: DetailPost,
    key: PERMISSIONS.VIEW_NEWS,
  },
  {
    path: POST_URL.CREATE,
    Element: DetailPost,
    key: PERMISSIONS.CREATE_NEWS,
  },
]

export { postRouter }
