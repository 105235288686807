import HeadHtml from '@src/components/layout/HeadHtml'
import SearchName, {
  SearchNameProps,
} from '@src/components/widgets/input/SearchName'
import { Col, PageHeader, Row } from 'antd'
import React from 'react'

export interface ListHeaderProps extends SearchNameProps {
  title?: string
}

const ListHeader: React.FC<ListHeaderProps> = ({ title, ...props }) => (
  <Row
    justify="space-between"
    align="bottom"
    style={{ marginBottom: 16 }}
    gutter={[16, 8]}
  >
    <Col sm={24} md={12} xs={24}>
      <HeadHtml title={title || 'title'} />
      <PageHeader
        title={title || 'title'}
        backIcon={false}
        className="page-header"
      />
    </Col>
    <Col sm={24} md={6} xs={24}>
      <SearchName {...props} />
    </Col>
  </Row>
)

export default ListHeader
