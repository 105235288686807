/* eslint-disable no-unused-vars */
import moment from 'moment'

export enum StatusInvestorEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DRAFT = 'DRAFT',
  NULL = '',
}

export enum StatusPendingEnum {
  PENDING = 'PENDING',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

export enum CurrencyUnitEnum {
  VND = 'vnd',
  USD = 'usd',
}

export enum LangEnum {
  VI = 'vi',
  EN = 'en',
  CN = 'cn',
}

export enum AreaUnitEnum {
  HA = 'ha',
  M = 'm',
  KM = 'km',
}

export enum OrderByEnum {
  CREATE_AT = 'createdAt',
  UPDATE_AT = 'updateAt',
  _ID = '_id',
  NAME = 'name',
  EMAIL = 'email',
  FUll_NAME = 'fullName',
  CODE = 'code',
}

export enum OrderEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum PronounsProps {
  MS = 'Ms',
  MRS = 'Mrs',
  MR = 'Mr',
  OTHER = 'other',
}

export enum PaymentTypeEnum {
  NG = 'NIEN_GIAM',
  '2KCN' = '2KCN',
}

export interface ContactData {
  label: string
  people: string
  phone: string
  email: string
  address: string
}

export interface InforExtendsProps {}

export interface ValueUnitProps {
  unit?: string
  value?: number
}

export interface CurrencyProps {
  unit?: CurrencyUnitEnum
  value?: number
}

export interface AreaProps {
  unit?: AreaUnitEnum
  value?: number
}
export interface LabelTextProps {
  label: string
  text: string
}

export interface InforEnterpriceProps {
  internationalName?: string
  abbreviationName?: string
  taxCode?: string
  establishmentDate?: Date | string | moment.Moment
  legalRepresentative?: string
  taxAddress?: string
  nationality?: string
  mainMarketing?: string
  stockCode?: string
  shortBio?: string
  representative?: boolean
  taxonomies?: string[]
  businessAreas?: {
    title: string
    excerpt: string
    thumbnail: string
  }[]
  mainJob: string
  factoryAddress: string
}

export interface InforBalanceProps {
  creditPointsPromotionNG?: number
  creditPointsNG?: number
  creditPoints2KCN?: number
  creditPointsPromotion2KCN?: number
}

export interface DataUser {
  day?: number
  month?: number
  year?: number
  amount_active?: number
  amount_inactive?: number
  type?: string
}

export interface InforBasicProps {
  [key: string]: any
  _id?: string
  name?: string
  slug?: string
  __v?: number
  phone?: string
  address?: string

  contacts?: ContactData[]
  thumbnail?: string
  createdAt?: Date | string
  updatedAt?: Date | string
  author?: string
  email?: string
}
