import { UserQueryParams } from '@src/models/user'

import { request } from './config'

export const getListPayment = (token: string, params: UserQueryParams) =>
  request(
    { url: '/user/payment/user/findAll', method: 'GET', params },
    { token },
  )

export const getPaymentById = (token: string, id: string) =>
  request({ url: `user/payment/user/${id}`, method: 'GET' }, { token })
