import NotFound from '@components/widgets/404'
import LoadingIndicator from '@src/components/elements/Loading'
import PagesHeaders from '@src/components/elements/PagesHeaders'
import HeadHtml from '@src/components/layout/HeadHtml'
import { useQueryPaymentById } from '@src/queries/hooks/payment'
import { Badge, Card, Col, Descriptions, Form, Row, Space } from 'antd'
import moment from 'moment'
import { useParams } from 'react-router-dom'

function DetailPayment() {
  const { id } = useParams()

  const { data: detail, isLoading, isFetching } = useQueryPaymentById(id ?? '')
  const detailPayment = detail?.data

  if (isLoading || isFetching) return <LoadingIndicator />

  if (!detail) return <NotFound />

  return (
    <>
      <HeadHtml title={detail.data.name || 'Chi tiết thanh toán'} />
      <Space style={{ width: '100%' }} size={16} direction="vertical">
        <Form scrollToFirstError>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={24}>
              <Card
                hoverable
                title={<PagesHeaders title="Chi tiết thanh toán" />}
              >
                <Space size={30} direction="vertical" style={{ width: '100%' }}>
                  <Descriptions column={1} title="Thông tin chi tiết" bordered>
                    <Descriptions.Item label="Loại hoá đơn">
                      {detailPayment?.postType}
                    </Descriptions.Item>
                    <Descriptions.Item label="Phương thức">
                      {detailPayment?.method}
                    </Descriptions.Item>
                    <Descriptions.Item label="Số tiền">
                      {detailPayment?.amount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Giá">
                      {detailPayment?.price}
                    </Descriptions.Item>
                    <Descriptions.Item label="Giảm giá">
                      {detailPayment?.priceDiscount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Trạng thái">
                      <Badge
                        color={detailPayment?.status ? 'green' : 'red'}
                        text={
                          detailPayment?.status
                            ? 'Đã hoàn thành'
                            : 'Chưa hoàn thành'
                        }
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Ngày tạo">
                      {moment(detailPayment?.createdAt).format('YYYY-MM-DD')}
                    </Descriptions.Item>
                    <Descriptions.Item label="Cập nhật gần nhất">
                      {moment(detailPayment?.updatedAt).format('YYYY-MM-DD')}
                    </Descriptions.Item>
                  </Descriptions>

                  <Descriptions column={1} title="VN Pay" bordered>
                    <Descriptions.Item label="Phiên bản">
                      {detailPayment?.vnpVersion}
                    </Descriptions.Item>
                    <Descriptions.Item label="Lệnh">
                      {detailPayment?.vnpCommand}
                    </Descriptions.Item>
                    <Descriptions.Item label="Số tiền">
                      {detailPayment?.amount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Đơn vị">
                      {detailPayment?.vnpCurrCode}
                    </Descriptions.Item>
                    <Descriptions.Item label="IP">
                      {detailPayment?.vnpIpAddr}
                    </Descriptions.Item>
                  </Descriptions>
                </Space>
              </Card>
            </Col>
            {/* <Col xs={24} xl={7} style={{ minHeight: '100%' }}>
              <Space
                style={{ position: 'sticky', top: 10 }}
                size={16}
                direction="vertical"
                className="space-event"
              >
                <Collapse
                  defaultActiveKey={['1']}
                  className="action"
                  expandIconPosition="end"
                >
                  <Panel header="Hành động" key="1">
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Space size={16} style={{ marginTop: 12 }}>
                          <Popconfirm
                            title="Chắc chắn chấp thuận?"
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button type="primary">Chấp nhận</Button>
                          </Popconfirm>
                          <Popconfirm
                            title="Chắc chắn để từ chối?"
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button type="primary">Từ chối</Button>
                          </Popconfirm>
                        </Space>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Space>
            </Col> */}
          </Row>
        </Form>
      </Space>
    </>
  )
}

export default DetailPayment
