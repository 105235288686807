/* eslint-disable no-unused-vars */
export enum PERMISSIONS {
  GENERAL = 'GENERAL',
  // User Module
  CREATE_USER = 'CREATE_USER',
  LIST_USERS = 'LIST_USERS',
  VIEW_USER = 'VIEW_USER',
  UPDATE_USER = 'UPDATE_USER',
  DELETE_USER = 'DELETE_USER',
  LIST_DELETED_USER = 'LIST_DELETED_USER',
  RESTORE_USER = 'RESTORE_USER',
  VIEW_DELETED_USER = 'VIEW_DELETED_USER',

  // User-request Module
  LIST_USER_REQUEST = 'LIST_USER_REQUEST',
  LIST_VERIFY_REQUEST = 'LIST_VERIFY_REQUEST',
  LIST_ENTERPRISE_REQUEST = 'LIST_ENTERPRISE_REQUEST',
  DETAIL_REQUEST = 'DETAIL_REQUEST',
  CREATE_REQUEST = 'CREATE_REQUEST',

  // Role Module
  LIST_ROLES = 'LIST_ROLES',
  CREATE_ROLE = 'CREATE_ROLE',
  VIEW_ROLE = 'VIEW_ROLE',
  UPDATE_ROLE = 'UPDATE_ROLE',
  DELETE_ROLE = 'DELETE_ROLE',

  // Mail module
  SEND_MAIL = 'SEND_MAIL',

  // Industrial
  LIST_INDUSTRIAL_AREA = 'LIST_INDUSTRIAL_AREA',
  VIEW_INDUSTRIAL_AREA = 'VIEW_INDUSTRIAL_AREA',
  DELETE_INDUSTRIAL_AREA = 'DELETE_INDUSTRIAL_AREA',
  UPDATE_INDUSTRIAL_AREA = 'UPDATE_INDUSTRIAL_AREA',
  CREATE_INDUSTRIAL_AREA = 'CREATE_INDUSTRIAL_AREA',

  // News
  CREATE_NEWS = 'CREATE_NEWS',
  LIST_NEWS = 'LIST_NEWS',
  VIEW_NEWS = 'VIEW_NEWS',
  UPDATE_NEWS = 'UPDATE_NEWS',
  DELETE_NEWS = 'DELETE_NEWS',

  // Taxonomy module
  CREATE_TAXONOMY = 'CREATE_TAXONOMY',
  LIST_TAXONOMY = 'LIST_TAXONOMY',
  VIEW_TAXONOMY = 'VIEW_TAXONOMY',
  UPDATE_TAXONOMY = 'UPDATE_TAXONOMY',
  DELETE_TAXONOMY = 'DELETE_TAXONOMY',

  // theme
  CREATE_THEME = 'CREATE_THEME',
  LIST_THEME = 'LIST_THEME',
  VIEW_THEME = 'VIEW_THEME',
  UPDATE_THEME = 'UPDATE_THEME',
  DELETE_THEME = 'DELETE_THEME',

  // ADS
  CREATE_ADS = 'CREATE_ADS',
  LIST_ADS = 'LIST_ADS',
  VIEW_ADS = 'VIEW_ADS',
  UPDATE_ADS = 'UPDATE_ADS',
  DELETE_ADS = 'DELETE_ADS',

  // Coupon
  CREATE_COUPON = 'CREATE_COUPON',
  LIST_COUPON = 'LIST_COUPON',
  VIEW_COUPON = 'VIEW_COUPON',
  UPDATE_COUPON = 'UPDATE_COUPON',
  DELETE_COUPON = 'DELETE_COUPON',

  // media
  LIST_MEDIA = 'LIST_MEDIA',
  VIEW_MEDIA = 'VIEW_MEDIA',
  UPDATE_MEDIA = 'UPDATE_MEDIA',
  DELETE_MEDIA = 'DELETE_MEDIA',
  UPLOAD_MEDIA = 'UPLOAD_MEDIA',
  CREATE_FOLDER = 'CREATE_FOLDER',
  DELETE_FOLDER = 'DELETE_FOLDER',
  LIST_FOLDER = 'LIST_FOLDER',
  VIEW_FOLDER = 'VIEW_FOLDER',
  VIEW_MEDIA_FOLDER = 'VIEW_MEDIA_FOLDER',

  // Management
  LIST_MANAGEMENT = 'LIST_MANAGEMENT',
  VIEW_MANAGEMENT = 'VIEW_MANAGEMENT',
  DELETE_MANAGEMENT = 'DELETE_MANAGEMENT',
  UPDATE_MANAGEMENT = 'UPDATE_MANAGEMENT',
  CREATE_MANAGEMENT = 'CREATE_MANAGEMENT',

  // Partner
  UPLOAD_PARTNER = 'UPLOAD_PARTNER',
  UPDATE_PARTNER = 'UPDATE_PARTNER',
  DELETE_PARTNER = 'DELETE_PARTNER',
  LIST_PARTNER = 'LIST_PARTNER',
  VIEW_PARTNER = 'VIEW_PARTNER',

  // Partner
  UPLOAD_CONSULTANT = 'UPLOAD_CONSULTANT',
  UPDATE_CONSULTANT = 'UPDATE_CONSULTANT',
  DELETE_CONSULTANT = 'DELETE_CONSULTANT',
  LIST_CONSULTANT = 'LIST_CONSULTANT',
  VIEW_CONSULTANT = 'VIEW_CONSULTANT',
}
