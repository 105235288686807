import { IndustrialData, IndustrialQueryParams } from '@src/models'

import { request } from './config'

export const getListIndustrial = (params: IndustrialQueryParams) =>
  request({
    url: 'industrial-project',
    method: 'GET',
    params: { ...params, systemType: '2KCN' },
  })

export const createIndustrialProject = (data: IndustrialData, token: string) =>
  request({ url: 'industrial-project', method: 'POST', data }, { token })

export const getIndustrialProjectById = (id: string, token: string) =>
  request(
    { url: `industrial-project/${id}`, params: { isCms: true } },
    { token },
  )

export const updateIndustrialProject = (
  id: string,
  data: IndustrialData,
  token: string,
) =>
  request({ url: `industrial-project/${id}`, method: 'PUT', data }, { token })

export const delIndustrialProjectById = (id: string, token: string) =>
  request({ url: `industrial-project/${id}`, method: 'DELETE' }, { token })
