import NotFound from '@components/widgets/404'
import { BASIC_CONTENT } from '@src/assets/contants'
import LoadingIndicator from '@src/components/elements/Loading'
import PagesHeaders from '@src/components/elements/PagesHeaders'
import HeadHtml from '@src/components/layout/HeadHtml'
import CustomFormUploadMedia from '@src/components/widgets/CustomUploadMedia'
import ActionPublish from '@src/pages/users/components/ActionPublish'
import {
  useQueryDetailDeletedUserById,
  useQueryRestoreDeletedUserById,
} from '@src/queries/hooks'
import { formatNumberWithDot } from '@src/utils'
import { Card, Col, Collapse, Divider, Form, Row, Space } from 'antd'
import moment from 'moment'
import { useNavigate, useParams } from 'react-router-dom'

import FormInput from './components/FormInput'

const { Panel } = Collapse

function DetailDeletedUser() {
  const [form] = Form.useForm()
  const { userId } = useParams()
  const navigate = useNavigate()
  const {
    data: detail,
    isLoading,
    isFetching,
  } = useQueryDetailDeletedUserById(userId || '')

  // const { mutate: del, isLoading: loadingDel } = useQueryRestoreUserById(userId)

  const { mutate: restore, isLoading: loadingDel } =
    useQueryRestoreDeletedUserById()

  const handleRestore = () => {
    if (userId) {
      restore(userId, {
        onSuccess: () => {
          navigate('/user-deleted')
        },
      })
    }
  }

  if (isLoading || isFetching) return <LoadingIndicator />

  if (!detail) return <NotFound />

  return (
    <>
      <HeadHtml title={detail.data.fullName || 'Detail Deleted User'} />
      <Form form={form} className="create-user" scrollToFirstError>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={17}>
            <Card
              hoverable
              title={<PagesHeaders title="Chi tiết người dùng đã xóa" />}
            >
              <FormInput form={form} data={detail?.data} />
            </Card>
          </Col>

          <Col xs={24} xl={7}>
            <Space size={16} direction="vertical" className="space-event">
              <Collapse expandIconPosition="end">
                <Panel header="Thông tin tín dụng" key="1">
                  <Row gutter={[0, 12]}>
                    <Col span={24}>
                      <Row justify="space-between">
                        <Col>{BASIC_CONTENT.EXPIRED_AT_VIP_NG}:</Col>
                        <Col>
                          {detail?.data.expiredAtVipNG
                            ? moment(detail.data.data.expiredAtVipNG).format(
                                'DD/MM/Y HH:mm:ss',
                              )
                            : 'N/A'}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between">
                        <Col>{BASIC_CONTENT.POINTS_NG}:</Col>
                        <Col>
                          {formatNumberWithDot(detail.data.creditPointsNG)}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between">
                        <Col>{BASIC_CONTENT.POINTS_PROMOTION_NG}:</Col>
                        <Col>
                          {formatNumberWithDot(
                            detail.data.creditPointsPromotionNG.points,
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between">
                        <Col>{BASIC_CONTENT.EXPIRATION_DATE}:</Col>
                        <Col>
                          {detail?.data?.creditPointsPromotionNG
                            ?.expirationDate &&
                          moment(
                            detail?.data?.creditPointsPromotionNG
                              ?.expirationDate,
                          ).unix() !== moment('01/01/1970 00:00:00').unix()
                            ? moment(
                                detail.data.creditPointsPromotionNG
                                  .expirationDate,
                              ).format('DD/MM/Y HH:mm:ss')
                            : 'N/A'}
                        </Col>
                      </Row>
                    </Col>
                    <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                    <Col span={24}>
                      <Row justify="space-between">
                        <Col>{BASIC_CONTENT.EXPIRED_AT_VIP_2KCN}:</Col>
                        <Col>
                          {detail.data.expiredAtVip2KCN
                            ? moment(detail.data.expiredAtVip2KCN).format(
                                'DD/MM/Y HH:mm:ss',
                              )
                            : 'N/A'}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between">
                        <Col>{BASIC_CONTENT.POINTS_2KCN}:</Col>
                        <Col>
                          {formatNumberWithDot(detail.data.creditPoints2KCN)}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between">
                        <Col>{BASIC_CONTENT.POINTS_PROMOTION_2KCN}:</Col>
                        <Col>
                          {formatNumberWithDot(
                            detail.data.creditPointsPromotion2KCN.points,
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between">
                        <Col>{BASIC_CONTENT.EXPIRATION_DATE}:</Col>
                        <Col>
                          {detail?.data?.creditPointsPromotion2KCN
                            ?.expirationDate &&
                          moment(
                            detail?.data?.creditPointsPromotion2KCN
                              ?.expirationDate,
                          ).unix() !== moment('01/01/1970 00:00:00').unix()
                            ? moment(
                                detail.data.creditPointsPromotion2KCN
                                  .expirationDate,
                              ).format('DD/MM/Y HH:mm:ss')
                            : 'N/A'}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
              <Collapse defaultActiveKey={['1']} expandIconPosition="end">
                <Panel header="Hành động" key="1">
                  <ActionPublish
                    isStatusBoolean
                    loadingDelete={loadingDel}
                    data={{
                      status: detail?.data?.status || false,
                      createdAt: detail?.data?.createdAt || new Date(),
                      emailVerify: detail?.data?.emailVerify,
                    }}
                    handleDelete={handleRestore}
                  />
                </Panel>
              </Collapse>
              <Collapse
                defaultActiveKey={['1']}
                className="action"
                expandIconPosition="end"
              >
                <Panel header="Ảnh đại diện" key="1">
                  <Row justify="center">
                    <CustomFormUploadMedia
                      defaultMedia={detail?.data?.avatar}
                      form={form}
                      name="avatar"
                    />
                  </Row>
                </Panel>
              </Collapse>
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default DetailDeletedUser
