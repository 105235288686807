export const TAXONOMY_URL = {
  LIST: 'taxonomy',
  CREATE: 'taxonomy-create',
  DETAIL: 'taxonomy/:id',
}

export const HASHTAG_URL = {
  LIST: 'hashtag',
  CREATE: 'hashtag-create',
  DETAIL: 'hashtag/:id',
}

export const POST_URL = {
  POST: 'post',
  CREATE: 'post-create',
  DETAIL: 'post/:id',
}

export const CONSULTANT_URL = {
  LIST: 'consultant',
  CREATE: 'consultant-create',
  DETAIL: 'consultant/:id',
}

export const MEMBERSHIP_URL = {
  LIST: 'membership',
  CREATE: 'membership-create',
  DETAIL: 'membership/:id',
  FAQ: 'membership-faq',
  EXCLUSIVE_RIGHT: 'membership-exclusive-right',
}

export const USER_OUTSTADINGS_URL = {
  LIST: 'user-outstading',
  DETAIL: 'user-outstading/:id',
}

export const PARTNER_URL = {
  LIST: 'partner',
  CREATE: 'partner-create',
  DETAIL: 'partner/:id',
}

export const COUPON_URL = {
  LIST: 'coupon',
  CREATE: 'coupon-create',
  DETAIL: 'coupon/:id',
}

export const REGISTER_ADVISE = {
  LIST: 'register-advise',
  CREATE: 'register-advise-create',
  DETAIL: 'register-advise/:id',
}

export const NEWS_URL = {
  LIST: 'news',
  CREATE: 'news-create',
  DETAIL: 'news/:id',
}

export const INDUSTRIAL_PROJECT_URL = {
  LIST: 'industrial-project',
  CREATE: 'industrial-project-create',
  DETAIL: 'industrial-project/:id',
}

export const URBAN_AREA_URL = {
  LIST: 'urban-area',
  CREATE: 'urban-area-create',
  DETAIL: 'urban-area/:id',
}

export const BASE_URL = {
  SECTOR: 'base-data/sector',
  REGION: 'base-data/region',
  INFRASTRUCTURE: 'base-data/industrial-project-infrastructure',
}

export const UPLOAD_URL = {
  UPLOAD: 'media/upload',
}

export const THEME_URL = {
  LIST: 'theme',
  CREATE: 'theme-create',
  DETAIL: 'theme/:id',
}

export const REQUEST_URL = {
  ENTERPRISE: 'enterprise-request',
  VERIFY: 'verify-request',
  DETAIL: ':type/:id',
  CREATE: 'verify-create',
}

export const ADS_URL = {
  LIST: 'ads',
  CREATE: 'ads-create',
  DETAIL: 'ads/:id',
}
export const MEDIA_URL = {
  LIST: 'media',
  CREATE: 'media-create',
  DETAIL: 'media/:id',
  FOLDER_DETAIL: 'media/folder/:id',
}
