import { TaxonomyData, TaxonomyQueryParams } from '@src/models'

import { request } from './config'

export const getListTaxonomy = (params: TaxonomyQueryParams, token: string) =>
  request(
    {
      url: 'taxonomy/find-all',
      method: 'GET',
      params: { ...params, systemType: '2KCN' },
    },
    { token },
  )

export const createTaxonomy = (data: TaxonomyData, token: string) =>
  request(
    {
      url: 'taxonomy/create',
      method: 'POST',
      data: { ...data, systemType: '2KCN' },
    },
    { token },
  )

export const getTaxonomyById = (id: string, token: string) =>
  request({ url: `taxonomy/${id}`, params: { isCms: true } }, { token })

export const updateTaxonomy = (id: string, data: TaxonomyData, token: string) =>
  request({ url: `taxonomy/${id}`, method: 'PUT', data }, { token })

export const delTaxonomyById = (id: string, token: string) =>
  request({ url: `taxonomy/${id}`, method: 'DELETE' }, { token })
