import { checkAuth } from '@src/libs/localStorage'
import { DefaultParams } from '@src/models'
import { ICoupon } from '@src/models/coupon'

import { request } from './config'

export const createCoupon = (data: ICoupon, token: string) =>
  request(
    {
      url: '/coupon',
      method: 'POST',
      data,
    },
    { token },
  )

export const getListCoupon = (params: DefaultParams) => {
  const token = checkAuth()
  return request(
    {
      url: '/coupon/findAll',
      method: 'GET',
      params: { ...params, systemType: '2KCN' },
    },
    { token },
  )
}

export const getCouponById = (id: string, token: string) =>
  request({ url: `/coupon/${id}`, params: { isCms: true } }, { token })

export const updateCoupon = (id: string, data: ICoupon, token: string) =>
  request({ url: `/coupon/${id}`, method: 'PUT', data }, { token })

export const deleteCoupon = (id: string, token: string) =>
  request({ url: `/coupon/${id}`, method: 'DELETE' }, { token })
