import { MEDIA_URL } from '@src/configs/urlRouter'
import { checkAuth } from '@src/libs/localStorage'
import { MediaData, MediaQueryParams } from '@src/models'
import { LangEnum, OrderByEnum, OrderEnum } from '@src/models/basic'

import { request } from './config'

class MediaApi {
  url: { LIST: string; CREATE: string; DETAIL: string }

  constructor() {
    this.url = MEDIA_URL
  }

  getSearch(s?: string) {
    const accessToken = checkAuth()
    const params = {
      page: 1,
      limit: 10,
      order: OrderEnum.ASC,
      orderBy: OrderByEnum.NAME,
      s: s || '',
    }
    return request(
      { url: MEDIA_URL.LIST, method: 'GET', params },
      { token: accessToken, lang: LangEnum.VI },
    )
  }
}

export const mediaApi = new MediaApi()
// --------FN----------
// export const getListmedia = (params: MediaQueryParamsParams) =>
//   request({ url: 'media', method: 'GET', params })

export const createMedia = (data: MediaData, token: string) =>
  request({ url: 'media/upload', method: 'POST', data }, { token })

export const getMediaById = (id: string, token: string) =>
  request({ url: `media/${id}`, params: { isCms: true } }, { token })

export const updateMedia = (id: string, data: MediaData, token: string) =>
  request({ url: `media/${id}`, method: 'PUT', data }, { token })

export const delMediaById = (id: string, token: string) =>
  request({ url: `media/${id}`, method: 'DELETE' }, { token })

// --------------------Fn---------------------
export const getListMedia = (params: MediaQueryParams, token: string) =>
  request(
    {
      url: 'media',
      method: 'GET',
      params: { ...params, systemType: '2KCN' },
    },
    { token },
  )

export const getDetailMedia = (id: string, token: string) =>
  request({ url: `media/${id}`, method: 'GET' }, { token })

/// folder
export const getListFolder = (params: MediaQueryParams, token: string) =>
  request({ url: 'media/folder', method: 'GET', params }, { token })

export const getFolderById = (id: string, token: string) =>
  request({ url: `media/folder/${id}`, params: { isCms: true } }, { token })

export const getFileFolderById = (
  id: string,
  token: string,
  params: MediaQueryParams,
) =>
  request(
    {
      url: `media/folder/file/${id}`,
      params: { ...params, systemType: '2KCN' },
    },
    { token },
  )

export const createFolderMedia = (data: MediaData, token: string) =>
  request({ url: 'media/folder', method: 'POST', data }, { token })

export const deleteFolderMediaByID = (id: string, token: string) =>
  request({ url: `media/folder/${id}`, method: 'DELETE' }, { token })

export const updateFolderMedia = (id: string, data: MediaData, token: string) =>
  request({ url: `media/folder/${id}`, method: 'PUT', data }, { token })
