import { ConsultantData, DefaultParams } from '@src/models'

import { request } from './config'

export const getListConsultant = (params: DefaultParams) =>
  request({
    url: 'consultant',
    method: 'GET',
    params: { ...params, systemType: '2KCN' },
  })

export const createConsultant = (data: ConsultantData, token: string) =>
  request({ url: 'consultant', method: 'POST', data }, { token })

export const getConsultantById = (id: string, token: string) =>
  request({ url: `consultant/${id}`, params: { isCms: true } }, { token })

export const updateConsultant = (
  id: string,
  data: ConsultantData,
  token: string,
) => request({ url: `consultant/${id}`, method: 'PUT', data }, { token })

export const delConsultantById = (id: string, token: string) =>
  request({ url: `consultant/${id}`, method: 'DELETE' }, { token })
