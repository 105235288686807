import { PERMISSIONS } from '@src/configs/permissions'
import { PARTNER_URL } from '@src/configs/urlRouter'
import lazyLoading from '@src/libs/lazyLoading'

import { RouteConfigItemType } from './interface'

const ListPartner = lazyLoading(() => import('@pages/partner/ListPartner'))
const CreatePartner = lazyLoading(() => import('@pages/partner/CreatePartner'))
const DetailPartner = lazyLoading(() => import('@pages/partner/DetailPartner'))

const partnerRouter: RouteConfigItemType[] = [
  {
    path: PARTNER_URL.LIST,
    Element: ListPartner,
    key: PERMISSIONS.LIST_PARTNER,
  },
  {
    path: PARTNER_URL.CREATE,
    Element: CreatePartner,
    key: PERMISSIONS.UPLOAD_PARTNER,
  },
  {
    path: PARTNER_URL.DETAIL,
    Element: DetailPartner,
    key: PERMISSIONS.VIEW_PARTNER,
  },
]

export { partnerRouter }
