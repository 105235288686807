import { PERMISSIONS } from '@src/configs/permissions'
import { MEMBERSHIP_URL } from '@src/configs/urlRouter'
import lazyLoading from '@src/libs/lazyLoading'
// import CreateEXCRight from '@src/pages/membership/exclusiveRight'

import { RouteConfigItemType } from './interface'

const ListMembership = lazyLoading(
  () => import('@pages/membership/ListMembership'),
)
const CreateMembership = lazyLoading(
  () => import('@pages/membership/CreateMembership'),
)
const DetailMembership = lazyLoading(
  () => import('@pages/membership/DetailMembership'),
)

const Faq = lazyLoading(() => import('@pages/membership/Faq'))
const CreateEXCRight = lazyLoading(
  () => import('@pages/membership/ExclusiveRight'),
)

const membershipRouter: RouteConfigItemType[] = [
  {
    path: MEMBERSHIP_URL.LIST,
    Element: ListMembership,
    key: PERMISSIONS.LIST_MANAGEMENT,
  },
  {
    path: MEMBERSHIP_URL.CREATE,
    Element: CreateMembership,
    key: PERMISSIONS.CREATE_MANAGEMENT,
  },
  {
    path: MEMBERSHIP_URL.DETAIL,
    Element: DetailMembership,
    key: PERMISSIONS.VIEW_MANAGEMENT,
  },
  {
    path: MEMBERSHIP_URL.FAQ,
    Element: Faq,
    key: PERMISSIONS.VIEW_MANAGEMENT,
  },
  {
    path: MEMBERSHIP_URL.EXCLUSIVE_RIGHT,
    Element: CreateEXCRight,
    key: PERMISSIONS.VIEW_MANAGEMENT,
  },
]

export { membershipRouter }
