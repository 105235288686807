export const BASE_DATA_KEY = {
  SECTOR: 'SECTOR',
  REGION: 'REGION',
  REGION_URBAN: 'REGION_URBAN',
  CURRENT_SITUATION: 'CURRENT_SITUATION',
  TAXONOMY: 'TAXONOMY_KEY',
  URBAN_AREA: 'URBAN_AREA',
  URBAN_AREA_TYPE: 'URBAN_AREA_TYPE',
  INFRASTRUCTURE: 'INFRASTRUCTURE',
  INDUSTRIAL_ZONE: 'INDUSTRIAL_ZONE',
  PROVINCE: 'PROVINCE',
  DISTRICT: 'DISTRICT',
  WARD: 'WARD',
  LANGUAGE: 'LANGUAGE',
}
