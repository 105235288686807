import {
  ApprovedStatusEnum,
  PostData,
  PostQueryParams,
  renewData,
  vipData,
} from '@src/models/post'

import { request } from './config'

export const getListPost = (params: PostQueryParams, token: string) =>
  request(
    {
      url: 'property-news',
      method: 'GET',
      params: { ...params, systemType: '2KCN' },
    },
    { token },
  )

export const getPostById = (id: string, token: string) =>
  request({ url: `property-news/admin/${id}` }, { token })

export const updatePost = (id: string, data: PostData, token: string) =>
  request({ url: `property-news/admin/${id}`, method: 'PUT', data }, { token })

export const approvedPost = (
  id: string,
  status: ApprovedStatusEnum,
  token: string,
) =>
  request(
    { url: `property-news/approved/${id}?status=${status}`, method: 'PATCH' },
    { token },
  )

export const delPostById = (id: string, token: string) =>
  request({ url: `property-news/${id}`, method: 'DELETE' }, { token })

export const trashPost = (id: string, token: string) =>
  request({ url: `property-news/trash/${id}`, method: 'PATCH' }, { token })

export const upVipPost = (id: string, data: vipData, token: string) =>
  request(
    {
      url: `property-news/up-vip/${id}`,
      method: 'PATCH',
      data,
    },
    { token },
  )

export const renewPost = (id: string, data: renewData, token: string) =>
  request(
    {
      url: `property-news/renew-news/${id}`,
      method: 'PATCH',
      data,
    },
    { token },
  )
