import { PERMISSIONS } from '@src/configs/permissions'
import lazyLoading from '@src/libs/lazyLoading'
import DetailPayment from '@src/pages/payment/DetailPayment'

import { RouteConfigItemType } from './interface'

const ListPayment = lazyLoading(() => import('@src/pages/payment/ListPayment'))

const paymentRouter: RouteConfigItemType[] = [
  {
    path: 'payment',
    Element: ListPayment,
    key: PERMISSIONS.LIST_COUPON,
  },
  {
    path: 'payment/:id',
    Element: DetailPayment,
    key: PERMISSIONS.VIEW_COUPON,
  },
]

export { paymentRouter }
