import './styles/styles.less'

import LoadingApp from '@components/widgets/LoaderApp'
import { queryClient } from '@queries/index'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { HashRouter } from 'react-router-dom'

import RouteApp from './route'
import { ThemeProvider } from './themeContext'

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <HelmetProvider>
          <LoadingApp loaded>
            <HashRouter>
              <RouteApp />
            </HashRouter>
          </LoadingApp>
        </HelmetProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  )
}

export default App
