import { InboxOutlined } from '@ant-design/icons'
import { BASE_URL } from '@src/configs/api'
import { checkAuth } from '@src/libs/localStorage'
import { ThemeContext } from '@src/themeContext'
import { message, notification, Upload, UploadProps } from 'antd'
import axios from 'axios'
import React from 'react'

const { Dragger } = Upload

const UploadMediaDnD: React.FC<any> = ({
  select,

  setActiveKey,
  setListFileMedia,
}) => {
  const dataRes: any = []

  const context = React.useContext<any>(ThemeContext)
  const { setDataRespone } = context

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    action: `${BASE_URL}/media/upload`,

    customRequest: (options: any) => {
      const data = new FormData()
      data.append('file', options.file)
      data.append('folder', select)
      data.append('systemType', '2KCN')
      const config = {
        headers: {
          Authorization: `Bearer ${checkAuth()}`,
        },
      }
      axios
        .post(options.action, data, config)
        .then((res: any) => {
          options.onSuccess(res.data, options.file)
        })
        .catch((err: Error) => {
          console.log(err)
        })
    },

    onChange(info) {
      const fileListUrl: string[] = []

      const { status } = info.file
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList)
      }

      info.fileList.forEach((file: any) => {
        if (file.status === 'done') {
          fileListUrl.unshift(file?.url || file?.response?.data.location)

          dataRes.unshift(file?.response.data)
        }
        if (!file.status) {
          // eslint-disable-next-line no-param-reassign
          file.status = 'error'
        }
      })
      setDataRespone(dataRes)
      setListFileMedia(fileListUrl)
      setActiveKey('itemm-2')
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`)

        // setTest(prevState + 1)

        // testState.setTestState(testState.testState + 1)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
    onRemove(options: any) {
      const config = {
        headers: {
          Authorization: `Bearer ${checkAuth()}`,
        },
      }
      axios
        .delete(`${BASE_URL}/media/${options.response.data._id}`, config)
        .then((res: any) => {
          notification.success({
            message: res.data.message || 'Delete Success!',
          })
        })
        .catch((err: Error) => {
          console.log(err)
        })
    },
  }

  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
      <p className="ant-upload-hint">
        Support for a single or bulk upload. Strictly prohibit from uploading
        company data or other band files
      </p>
    </Dragger>
  )
}

export default UploadMediaDnD
