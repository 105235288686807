import { OrderByEnum, OrderEnum } from './common'

export const { BASE_URL } = process.env
export const { GOOGLE_MAP_API_KEY } = process.env
export const { TINYMCE_API_KEY } = process.env
export const API_TIMEOUT = Number(process.env.API_TIMEOUT) || 30000

export const { VNP_TMCODE } = process.env
export const { VNP_HASHSECRET } = process.env
export const { VNP_URL } = process.env

export const baseParams = {
  page: 1,
  limit: 0,
  order: OrderEnum.DESC,
  orderBy: OrderByEnum.CREATE_AT,
  s: '',
  isCms: true,
  systemType: '2KCN',
}
