/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */

import { enumStatusIndustrial } from '@src/models'
import { ColProps } from 'antd'
import { FormLabelAlign } from 'antd/lib/form/interface'
import { PresetStatusColorType } from 'antd/lib/_util/colors'

export enum CurrencyUnitEnum {
  VND = 'vnd',
  USD = 'usd',
}

export const CURRENCY_UNIT: CurrencyUnitEnum[] = [
  CurrencyUnitEnum.USD,
  CurrencyUnitEnum.VND,
]

export enum LangEnum {
  VI = 'vi',
  EN = 'en',
  CN = 'cn',
}

export enum AreaUnitEnum {
  HA = 'ha',
  M = 'm',
  KM = 'km',
}

export const AREA_UNIT: AreaUnitEnum[] = [
  AreaUnitEnum.HA,
  AreaUnitEnum.M,
  AreaUnitEnum.KM,
]

export enum OrderByEnum {
  CREATE_AT = 'createdAt',
  UPDATE_AT = 'updateAt',
  _ID = '_id',
  NAME = 'name',
  TITLE = 'title',
  EMAIL = 'email',
  FUll_NAME = 'fullName',
  CODE = 'code',
  FILL_RATE = 'fillRate',
  TOTAL_AREA = 'totalArea',
  AMOUNT = 'amount',
  QUANTITY = 'quantity',
  VIEWER = 'viewer',
}

export enum FilterEnum {
  ROLE = 'role',
}

export enum OrderEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum PronounsEnum {
  MS = 'Ms',
  MRS = 'Mrs',
  MR = 'Mr',
  OTHER = 'other',
}

export const LANGUAGE = [LangEnum.VI, LangEnum.EN, LangEnum.CN]
export const LANGUAGE_DEFAULT = LangEnum.VI

export const SUPERADMINISTRATION = 'SUPERADMINISTRATION'
export const ADMINISTRATOR = 'ADMINISTRATOR'
export const AGENCY = 'AGENCY'
export const GUEST = 'GUEST'

export const ROLES = {
  ADMINISTRATOR: 'ADMINISTRATOR',
  AGENCY: 'AGENCY',
  GUEST: 'GUEST',
}

export enum GenderEnum {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
}

export const GENDER: GenderEnum[] = [
  GenderEnum.MALE,
  GenderEnum.FEMALE,
  GenderEnum.OTHER,
]

export const ROLES_COLORS: {
  [key: string]: string
} = {
  ADMINISTRATOR: 'volcano',
  SUPERADMINISTRATION: 'success',
  AGENCY: 'purple',
  GUEST: 'geekblue',
}

export interface ContactItem {
  label: string
  people: string
  phone: string
  email: string
  address: string
}

export const labelStyle: { labelAlign: FormLabelAlign; labelCol: ColProps } = {
  labelCol: { span: 6 },
  labelAlign: 'left',
}
export const labelStyleSpan10 = {
  ...labelStyle,
  labelCol: { span: 20 },
}

export const labelChildren: { labelAlign: FormLabelAlign; labelCol: ColProps } =
  {
    ...labelStyle,
    labelAlign: 'right',
  }
export enum StatusDocumentEnumBoolean {
  YES = 'Yes',
  NO = 'No',
}
export enum StatusDocumentEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DRAFT = 'DRAFT',
}

export const statusDocument: {
  key: number
  value: StatusDocumentEnum
  status: PresetStatusColorType
}[] = [
  { key: 1, value: StatusDocumentEnum.ACTIVE, status: 'success' },
  { key: 2, value: StatusDocumentEnum.INACTIVE, status: 'error' },
  { key: 3, value: StatusDocumentEnum.DRAFT, status: 'default' },
]

export const statusDocumentBoolean: {
  key: number
  label: StatusDocumentEnum
  status: PresetStatusColorType
  value: boolean
}[] = [
  { key: 1, value: true, label: StatusDocumentEnum.ACTIVE, status: 'success' },
  { key: 2, value: false, label: StatusDocumentEnum.INACTIVE, status: 'error' },
]

export const emailVerify: {
  key: number
  label: string
  status: PresetStatusColorType
  value: boolean
}[] = [
  { key: 1, value: true, label: 'verified', status: 'success' },
  { key: 2, value: false, label: 'unverified', status: 'error' },
]

export const TYPICAL: {
  key: number
  label: string
  status: PresetStatusColorType
  value: boolean
}[] = [
  { key: 1, value: true, label: 'typical', status: 'success' },
  { key: 2, value: false, label: 'not typical', status: 'default' },
]

export interface BaseData {
  id: string
  label: string
  translations: {
    vi: { label: string }
    en: { label: string }
    cn: { label: string }
  }
}

export const CURRENT_STATUS = [
  {
    vi: 'Đã lấp đầy, vui lòng chờ giai đoạn tiếp theo',
    en: 'Out of stock, please wait for the next phase',
    cn: '已满，请等待下一阶段',
  },
  {
    vi: 'Có sẵn, sẵn sàng cho thuê',
    en: 'In stock, available for rent',
    cn: '现货，可租出',
  },
  {
    vi: 'Đang giải phóng mặt bằng và san nền',
    en: 'In the process of clearance and ground filling',
    cn: '在解放般地面和整地',
  },
  {
    vi: 'Khác',
    en: 'Other',
    cn: '其他',
  },
]

export const statusIndustrial: {
  [key: string]: string
} = {
  availableLease: enumStatusIndustrial.availableLease,
  Filled: enumStatusIndustrial.Filled,
  noInfrastructure: enumStatusIndustrial.noInfrastructure,
  other: enumStatusIndustrial.other,
}
