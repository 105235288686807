import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { useFolderById } from '@src/queries/hooks'
import { ThemeContext } from '@src/themeContext'
import { Breadcrumb, Button, Col, Form, Image, message, Modal, Row } from 'antd'
import { FormInstance } from 'rc-field-form'
import React, { useMemo, useState } from 'react'

import ListMedia from '../media/List'

interface CustomFormUploadMedia {
  form: FormInstance<any>
  name?: string
  url?: string
  label?: string
  defaultMedia?: any
}

const CustomFormUploadMedia: React.FC<CustomFormUploadMedia> = ({
  form,
  defaultMedia,
  name = 'thumbnail',
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [loading] = useState(false)

  const [dataMedia, setDataMedia] = useState<any>()
  const context2 = React.useContext<any>(ThemeContext)
  const { setSelect, select } = context2
  const handleChangeCurentFolder = (data: any) => {
    setSelect(data)
  }
  const [curentLocationMedia, setCurentLocationMedia] = useState<string>(
    defaultMedia || null,
  )

  const { data: breadcumb } = useFolderById(select ?? '')
  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)

    // console.log('dataMedia', dataMedia)
    if (dataMedia) {
      setCurentLocationMedia(dataMedia)
    } else {
      message.error('Bạn chưa chọn ảnh !')
    }
  }

  const handleRemoveItem = () => {
    setCurentLocationMedia('')
    form.setFieldsValue({
      [name]: '',
    })
  }
  useMemo(
    () =>
      curentLocationMedia &&
      form.setFieldsValue({
        [name]: dataMedia,
      }),

    [curentLocationMedia, dataMedia, form],
  )

  const handleCancel = () => {
    setIsModalOpen(false)
  }
  return (
    <Form.Item valuePropName="file" name={name}>
      <Modal
        title={
          <Breadcrumb>
            {' '}
            {breadcumb?.data?.parent?.map((value: any, key: any) => (
              <Breadcrumb.Item
                key={key}
                className="_breadcrumb"
                onClick={() => {
                  handleChangeCurentFolder(value._id)
                }}
              >
                {value.name === 'root' ? 'Media Folder' : value.name}
              </Breadcrumb.Item>
            ))}{' '}
            <Breadcrumb.Item>
              {breadcumb?.data?.folder?.name === 'root'
                ? 'Media Folder'
                : breadcumb?.data?.folder?.name}
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{}}
        width="90%"
      >
        <ListMedia mutilple={false} setDataMedia={setDataMedia} />
      </Modal>
      <Row gutter={[16, 16]} align="middle" justify="center">
        <Col span={24}>
          <Button
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: `dashed 1px ${curentLocationMedia ? '#a7a7a7' : 'red'}`,
              width: '150px',
              height: '150px',
              background: '#c7c7c738',
              flexDirection: 'column',
              margin: '0 auto',
              overflow: 'hidden',
            }}
            onClick={showModal}
          >
            {curentLocationMedia ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Image src={curentLocationMedia} preview={false} />
              </div>
            ) : (
              <>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
              </>
            )}
          </Button>
        </Col>
        {curentLocationMedia ? (
          <Col>
            <Button icon={<DeleteOutlined />} onClick={handleRemoveItem} />
          </Col>
        ) : (
          ''
        )}
      </Row>
    </Form.Item>
  )
}

export default CustomFormUploadMedia
