import { ResApi, ResApiErr } from '@src/configs/resApi.interface'
import { checkAuth, getStoredLang } from '@src/libs/localStorage'
import {
  ApprovedStatusEnum,
  PostData,
  PostQueryParams,
  renewData,
  vipData,
} from '@src/models/post'
import { notification } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import {
  approvedPost,
  delPostById,
  getListPost,
  getPostById,
  renewPost,
  trashPost,
  updatePost,
  upVipPost,
} from '../api/post'
import { LIST_POST, VIEW_POST } from '../keys/post'

export const useQueryPost = (params: PostQueryParams) => {
  const accessToken = checkAuth()

  return useQuery(
    [LIST_POST, params, getStoredLang()],
    () => getListPost(params, accessToken),
    {
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    },
  )
}

export const usePostById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [VIEW_POST, id, getStoredLang()],
    () => getPostById(id, accessToken),
    {
      enabled: !!(id && accessToken),
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    },
  )
}

export const useUpdatePost = (id: string) => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((body: PostData) => updatePost(id, body, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Update Success!' })
        queryClient.invalidateQueries([VIEW_POST])
        queryClient.invalidateQueries([LIST_POST])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Update failure!' })
    },
  })
}

export const useDeletePost = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((id: string) => delPostById(id, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Delete Success!' })
        queryClient.invalidateQueries([LIST_POST])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Delete failure!' })
    },
  })
}

export const useApprovedPost = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, status }: { id: string; status: ApprovedStatusEnum }) =>
      approvedPost(id, status, accessToken),
    {
      onSuccess: (data: ResApi) => {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Success!' })
          queryClient.invalidateQueries([VIEW_POST])
          queryClient.invalidateQueries([LIST_POST])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Failure!' })
      },
    },
  )
}

export const useTrashPost = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((id: string) => trashPost(id, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Success!' })
        queryClient.invalidateQueries([VIEW_POST])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Failure!' })
    },
  })
}

export const useUpVipPost = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }: { id: string; data: vipData }) =>
      upVipPost(id, data, accessToken),
    {
      onSuccess: (data: ResApi) => {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Success!' })
          queryClient.invalidateQueries([VIEW_POST])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Failure!' })
      },
    },
  )
}

export const useRenewPost = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }: { id: string; data: renewData }) =>
      renewPost(id, data, accessToken),
    {
      onSuccess: (data: ResApi) => {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Success!' })
          queryClient.invalidateQueries([VIEW_POST])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Failure!' })
      },
    },
  )
}
