import { NewsData, NewsQueryParams } from '@src/models'

import { request } from './config'

export const getListNews = (params: NewsQueryParams) =>
  request({
    url: 'news',
    method: 'GET',
    params: { ...params, systemType: '2KCN' },
  })

export const createNews = (data: NewsData, token: string) =>
  request({ url: 'news/create', method: 'POST', data }, { token })

export const getNewsById = (id: string, token: string) =>
  request({ url: `news/${id}`, params: { isCms: true } }, { token })

export const updateNews = (id: string, data: NewsData, token: string) =>
  request({ url: `news/${id}`, method: 'PUT', data }, { token })

export const delNewsById = (id: string, token: string) =>
  request({ url: `news/${id}`, method: 'DELETE' }, { token })
