import { DeleteOutlined, EditOutlined, FolderOutlined } from '@ant-design/icons'
import { useDeleteFolderMedia, useUpdateFolderMedia } from '@src/queries/hooks'
import { Button, Col, Input, notification, Row } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

interface ActionCreateFolder {
  showSeach?: boolean
  name?: string
  id?: string
  parentID?: string
  page?: string
}
const FormUpdateFolder: React.FC<ActionCreateFolder> = (props) => {
  const { id: curentIDSlug } = useParams()

  const Status = props

  const idParentFolder = Status.parentID || null

  const idCurentFolder = Status.id || null

  const nameParentFolder = Status?.name || 'root'

  const [valueI, setValueI] = useState(nameParentFolder)

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {
    setValueI(nameParentFolder)
  }, [nameParentFolder])

  const navigate = useNavigate()

  const { mutate } = useUpdateFolderMedia(idCurentFolder || '')

  const onFinish = () => {
    const dataFolder: any = {
      name: valueI,
      parentId: idParentFolder,
    }

    mutate(dataFolder)
  }

  const { mutate: del } = useDeleteFolderMedia()

  const handleDel = () => {
    if (idCurentFolder && idCurentFolder !== curentIDSlug) {
      del(idCurentFolder, {
        onSuccess: () => {
          if (Status.page !== 'media') {
            navigate('/media')
          }
        },
      })
    } else {
      notification.error({ message: 'Thất bại' || 'Delete error!' })
    }
  }

  return (
    <Row gutter={[16, 16]} style={{ width: '300px' }}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Row>
              <Col span={20}>
                <Row style={{ width: '100%' }}>
                  <Col>
                    <span>
                      {' '}
                      <FolderOutlined />
                    </span>{' '}
                  </Col>
                  <Col style={{ marginLeft: '15px' }}>{nameParentFolder}</Col>
                </Row>
              </Col>
              <Col span={4}>
                <Button
                  onClick={handleDel}
                  icon={<DeleteOutlined />}
                  style={{ width: '100%' }}
                >
                  {' '}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={20}>
                {nameParentFolder && (
                  <>
                    {' '}
                    {/* <Row style={{ width: '100%' }}>
                      <Col>
                        <span>
                          {' '}
                          <FolderOutlined />
                        </span>{' '}
                      </Col>
                      <Col style={{ marginLeft: '15px' }}>
                        {nameParentFolder}
                      </Col>
                    </Row> */}
                    <Input
                      placeholder="name ..."
                      allowClear
                      value={valueI}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setValueI(e.target.value)
                      }
                    />
                  </>
                )}
              </Col>
              <Col span={4}>
                <Button
                  onClick={onFinish}
                  icon={<EditOutlined />}
                  style={{ width: '100%' }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default FormUpdateFolder
