import { DefaultParams, IPartner } from '@src/models'

import { request } from './config'

export const getListPartner = (params: DefaultParams) =>
  request({
    url: 'partner',
    method: 'GET',
    params: { ...params, systemType: '2KCN' },
  })

export const createPartner = (data: IPartner, token: string) =>
  request({ url: 'partner', method: 'POST', data }, { token })

export const getPartnerById = (id: string, token: string) =>
  request({ url: `partner/${id}`, params: { isCms: true } }, { token })

export const updatePartner = (id: string, data: IPartner, token: string) =>
  request({ url: `partner/${id}`, method: 'PUT', data }, { token })

export const delPartnerById = (id: string, token: string) =>
  request({ url: `partner/${id}`, method: 'DELETE' }, { token })
