import { BASE_URL as URL } from '@src/configs/urlRouter'

import { request } from './config'

export const getSector = () => request({ url: URL.SECTOR, method: 'GET' })

export const getRegion = () => request({ url: URL.REGION, method: 'GET' })

export const getInfrastructure = () =>
  request({ url: 'base-data/industrial-project-infrastructure', method: 'GET' })

export const getIndustrialZone = () =>
  request({ url: 'base-data/industrial-zone', method: 'GET' })

export const getPropertyNewsType = () =>
  request({ url: 'base-data/property-news-type', method: 'GET' })

export const getLanguage = () =>
  request({ url: 'base-data/language-consultant', method: 'GET' })
