export const CITY_PROVINCE = [
  {
    title: 'Thành phố Hà Nội',
    code: 1,
    value: 'thanh_pho_ha_noi',
    division_type: 'thành phố trung ương',
    phone_code: 24,
    children: [
      {
        title: 'Quận Ba Đình',
        code: 1,
        value: 'quan_ba_dinh',
        division_type: 'quận',
        short_codename: 'ba_dinh',
        children: [
          {
            title: 'Phường Phúc Xá',
            code: 1,
            value: 'phuong_phuc_xa',
            division_type: 'phường',
            short_codename: 'phuc_xa',
            parentId: 1,
          },
          {
            title: 'Phường Trúc Bạch',
            code: 4,
            value: 'phuong_truc_bach',
            division_type: 'phường',
            short_codename: 'truc_bach',
            parentId: 1,
          },
          {
            title: 'Phường Vĩnh Phúc',
            code: 6,
            value: 'phuong_vinh_phuc',
            division_type: 'phường',
            short_codename: 'vinh_phuc',
            parentId: 1,
          },
          {
            title: 'Phường Cống Vị',
            code: 7,
            value: 'phuong_cong_vi',
            division_type: 'phường',
            short_codename: 'cong_vi',
            parentId: 1,
          },
          {
            title: 'Phường Liễu Giai',
            code: 8,
            value: 'phuong_lieu_giai',
            division_type: 'phường',
            short_codename: 'lieu_giai',
            parentId: 1,
          },
          {
            title: 'Phường Nguyễn Trung Trực',
            code: 10,
            value: 'phuong_nguyen_trung_truc',
            division_type: 'phường',
            short_codename: 'nguyen_trung_truc',
            parentId: 1,
          },
          {
            title: 'Phường Quán Thánh',
            code: 13,
            value: 'phuong_quan_thanh',
            division_type: 'phường',
            short_codename: 'quan_thanh',
            parentId: 1,
          },
          {
            title: 'Phường Ngọc Hà',
            code: 16,
            value: 'phuong_ngoc_ha',
            division_type: 'phường',
            short_codename: 'ngoc_ha',
            parentId: 1,
          },
          {
            title: 'Phường Điện Biên',
            code: 19,
            value: 'phuong_dien_bien',
            division_type: 'phường',
            short_codename: 'dien_bien',
            parentId: 1,
          },
          {
            title: 'Phường Đội Cấn',
            code: 22,
            value: 'phuong_doi_can',
            division_type: 'phường',
            short_codename: 'doi_can',
            parentId: 1,
          },
          {
            title: 'Phường Ngọc Khánh',
            code: 25,
            value: 'phuong_ngoc_khanh',
            division_type: 'phường',
            short_codename: 'ngoc_khanh',
            parentId: 1,
          },
          {
            title: 'Phường Kim Mã',
            code: 28,
            value: 'phuong_kim_ma',
            division_type: 'phường',
            short_codename: 'kim_ma',
            parentId: 1,
          },
          {
            title: 'Phường Giảng Võ',
            code: 31,
            value: 'phuong_giang_vo',
            division_type: 'phường',
            short_codename: 'giang_vo',
            parentId: 1,
          },
          {
            title: 'Phường Thành Công',
            code: 34,
            value: 'phuong_thanh_cong',
            division_type: 'phường',
            short_codename: 'thanh_cong',
            parentId: 1,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Quận Hoàn Kiếm',
        code: 2,
        value: 'quan_hoan_kiem',
        division_type: 'quận',
        short_codename: 'hoan_kiem',
        children: [
          {
            title: 'Phường Phúc Tân',
            code: 37,
            value: 'phuong_phuc_tan',
            division_type: 'phường',
            short_codename: 'phuc_tan',
            parentId: 2,
          },
          {
            title: 'Phường Đồng Xuân',
            code: 40,
            value: 'phuong_dong_xuan',
            division_type: 'phường',
            short_codename: 'dong_xuan',
            parentId: 2,
          },
          {
            title: 'Phường Hàng Mã',
            code: 43,
            value: 'phuong_hang_ma',
            division_type: 'phường',
            short_codename: 'hang_ma',
            parentId: 2,
          },
          {
            title: 'Phường Hàng Buồm',
            code: 46,
            value: 'phuong_hang_buom',
            division_type: 'phường',
            short_codename: 'hang_buom',
            parentId: 2,
          },
          {
            title: 'Phường Hàng Đào',
            code: 49,
            value: 'phuong_hang_dao',
            division_type: 'phường',
            short_codename: 'hang_dao',
            parentId: 2,
          },
          {
            title: 'Phường Hàng Bồ',
            code: 52,
            value: 'phuong_hang_bo',
            division_type: 'phường',
            short_codename: 'hang_bo',
            parentId: 2,
          },
          {
            title: 'Phường Cửa Đông',
            code: 55,
            value: 'phuong_cua_dong',
            division_type: 'phường',
            short_codename: 'cua_dong',
            parentId: 2,
          },
          {
            title: 'Phường Lý Thái Tổ',
            code: 58,
            value: 'phuong_ly_thai_to',
            division_type: 'phường',
            short_codename: 'ly_thai_to',
            parentId: 2,
          },
          {
            title: 'Phường Hàng Bạc',
            code: 61,
            value: 'phuong_hang_bac',
            division_type: 'phường',
            short_codename: 'hang_bac',
            parentId: 2,
          },
          {
            title: 'Phường Hàng Gai',
            code: 64,
            value: 'phuong_hang_gai',
            division_type: 'phường',
            short_codename: 'hang_gai',
            parentId: 2,
          },
          {
            title: 'Phường Chương Dương',
            code: 67,
            value: 'phuong_chuong_duong',
            division_type: 'phường',
            short_codename: 'chuong_duong',
            parentId: 2,
          },
          {
            title: 'Phường Hàng Trống',
            code: 70,
            value: 'phuong_hang_trong',
            division_type: 'phường',
            short_codename: 'hang_trong',
            parentId: 2,
          },
          {
            title: 'Phường Cửa Nam',
            code: 73,
            value: 'phuong_cua_nam',
            division_type: 'phường',
            short_codename: 'cua_nam',
            parentId: 2,
          },
          {
            title: 'Phường Hàng Bông',
            code: 76,
            value: 'phuong_hang_bong',
            division_type: 'phường',
            short_codename: 'hang_bong',
            parentId: 2,
          },
          {
            title: 'Phường Tràng Tiền',
            code: 79,
            value: 'phuong_trang_tien',
            division_type: 'phường',
            short_codename: 'trang_tien',
            parentId: 2,
          },
          {
            title: 'Phường Trần Hưng Đạo',
            code: 82,
            value: 'phuong_tran_hung_dao',
            division_type: 'phường',
            short_codename: 'tran_hung_dao',
            parentId: 2,
          },
          {
            title: 'Phường Phan Chu Trinh',
            code: 85,
            value: 'phuong_phan_chu_trinh',
            division_type: 'phường',
            short_codename: 'phan_chu_trinh',
            parentId: 2,
          },
          {
            title: 'Phường Hàng Bài',
            code: 88,
            value: 'phuong_hang_bai',
            division_type: 'phường',
            short_codename: 'hang_bai',
            parentId: 2,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Quận Tây Hồ',
        code: 3,
        value: 'quan_tay_ho',
        division_type: 'quận',
        short_codename: 'tay_ho',
        children: [
          {
            title: 'Phường Phú Thượng',
            code: 91,
            value: 'phuong_phu_thuong',
            division_type: 'phường',
            short_codename: 'phu_thuong',
            parentId: 3,
          },
          {
            title: 'Phường Nhật Tân',
            code: 94,
            value: 'phuong_nhat_tan',
            division_type: 'phường',
            short_codename: 'nhat_tan',
            parentId: 3,
          },
          {
            title: 'Phường Tứ Liên',
            code: 97,
            value: 'phuong_tu_lien',
            division_type: 'phường',
            short_codename: 'tu_lien',
            parentId: 3,
          },
          {
            title: 'Phường Quảng An',
            code: 100,
            value: 'phuong_quang_an',
            division_type: 'phường',
            short_codename: 'quang_an',
            parentId: 3,
          },
          {
            title: 'Phường Xuân La',
            code: 103,
            value: 'phuong_xuan_la',
            division_type: 'phường',
            short_codename: 'xuan_la',
            parentId: 3,
          },
          {
            title: 'Phường Yên Phụ',
            code: 106,
            value: 'phuong_yen_phu',
            division_type: 'phường',
            short_codename: 'yen_phu',
            parentId: 3,
          },
          {
            title: 'Phường Bưởi',
            code: 109,
            value: 'phuong_buoi',
            division_type: 'phường',
            short_codename: 'buoi',
            parentId: 3,
          },
          {
            title: 'Phường Thụy Khuê',
            code: 112,
            value: 'phuong_thuy_khue',
            division_type: 'phường',
            short_codename: 'thuy_khue',
            parentId: 3,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Quận Long Biên',
        code: 4,
        value: 'quan_long_bien',
        division_type: 'quận',
        short_codename: 'long_bien',
        children: [
          {
            title: 'Phường Thượng Thanh',
            code: 115,
            value: 'phuong_thuong_thanh',
            division_type: 'phường',
            short_codename: 'thuong_thanh',
            parentId: 4,
          },
          {
            title: 'Phường Ngọc Thụy',
            code: 118,
            value: 'phuong_ngoc_thuy',
            division_type: 'phường',
            short_codename: 'ngoc_thuy',
            parentId: 4,
          },
          {
            title: 'Phường Giang Biên',
            code: 121,
            value: 'phuong_giang_bien',
            division_type: 'phường',
            short_codename: 'giang_bien',
            parentId: 4,
          },
          {
            title: 'Phường Đức Giang',
            code: 124,
            value: 'phuong_duc_giang',
            division_type: 'phường',
            short_codename: 'duc_giang',
            parentId: 4,
          },
          {
            title: 'Phường Việt Hưng',
            code: 127,
            value: 'phuong_viet_hung',
            division_type: 'phường',
            short_codename: 'viet_hung',
            parentId: 4,
          },
          {
            title: 'Phường Gia Thụy',
            code: 130,
            value: 'phuong_gia_thuy',
            division_type: 'phường',
            short_codename: 'gia_thuy',
            parentId: 4,
          },
          {
            title: 'Phường Ngọc Lâm',
            code: 133,
            value: 'phuong_ngoc_lam',
            division_type: 'phường',
            short_codename: 'ngoc_lam',
            parentId: 4,
          },
          {
            title: 'Phường Phúc Lợi',
            code: 136,
            value: 'phuong_phuc_loi',
            division_type: 'phường',
            short_codename: 'phuc_loi',
            parentId: 4,
          },
          {
            title: 'Phường Bồ Đề',
            code: 139,
            value: 'phuong_bo_de',
            division_type: 'phường',
            short_codename: 'bo_de',
            parentId: 4,
          },
          {
            title: 'Phường Sài Đồng',
            code: 142,
            value: 'phuong_sai_dong',
            division_type: 'phường',
            short_codename: 'sai_dong',
            parentId: 4,
          },
          {
            title: 'Phường Long Biên',
            code: 145,
            value: 'phuong_long_bien',
            division_type: 'phường',
            short_codename: 'long_bien',
            parentId: 4,
          },
          {
            title: 'Phường Thạch Bàn',
            code: 148,
            value: 'phuong_thach_ban',
            division_type: 'phường',
            short_codename: 'thach_ban',
            parentId: 4,
          },
          {
            title: 'Phường Phúc Đồng',
            code: 151,
            value: 'phuong_phuc_dong',
            division_type: 'phường',
            short_codename: 'phuc_dong',
            parentId: 4,
          },
          {
            title: 'Phường Cự Khối',
            code: 154,
            value: 'phuong_cu_khoi',
            division_type: 'phường',
            short_codename: 'cu_khoi',
            parentId: 4,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Quận Cầu Giấy',
        code: 5,
        value: 'quan_cau_giay',
        division_type: 'quận',
        short_codename: 'cau_giay',
        children: [
          {
            title: 'Phường Nghĩa Đô',
            code: 157,
            value: 'phuong_nghia_do',
            division_type: 'phường',
            short_codename: 'nghia_do',
            parentId: 5,
          },
          {
            title: 'Phường Nghĩa Tân',
            code: 160,
            value: 'phuong_nghia_tan',
            division_type: 'phường',
            short_codename: 'nghia_tan',
            parentId: 5,
          },
          {
            title: 'Phường Mai Dịch',
            code: 163,
            value: 'phuong_mai_dich',
            division_type: 'phường',
            short_codename: 'mai_dich',
            parentId: 5,
          },
          {
            title: 'Phường Dịch Vọng',
            code: 166,
            value: 'phuong_dich_vong',
            division_type: 'phường',
            short_codename: 'dich_vong',
            parentId: 5,
          },
          {
            title: 'Phường Dịch Vọng Hậu',
            code: 167,
            value: 'phuong_dich_vong_hau',
            division_type: 'phường',
            short_codename: 'dich_vong_hau',
            parentId: 5,
          },
          {
            title: 'Phường Quan Hoa',
            code: 169,
            value: 'phuong_quan_hoa',
            division_type: 'phường',
            short_codename: 'quan_hoa',
            parentId: 5,
          },
          {
            title: 'Phường Yên Hoà',
            code: 172,
            value: 'phuong_yen_hoa',
            division_type: 'phường',
            short_codename: 'yen_hoa',
            parentId: 5,
          },
          {
            title: 'Phường Trung Hoà',
            code: 175,
            value: 'phuong_trung_hoa',
            division_type: 'phường',
            short_codename: 'trung_hoa',
            parentId: 5,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Quận Đống Đa',
        code: 6,
        value: 'quan_dong_da',
        division_type: 'quận',
        short_codename: 'dong_da',
        children: [
          {
            title: 'Phường Cát Linh',
            code: 178,
            value: 'phuong_cat_linh',
            division_type: 'phường',
            short_codename: 'cat_linh',
            parentId: 6,
          },
          {
            title: 'Phường Văn Miếu',
            code: 181,
            value: 'phuong_van_mieu',
            division_type: 'phường',
            short_codename: 'van_mieu',
            parentId: 6,
          },
          {
            title: 'Phường Quốc Tử Giám',
            code: 184,
            value: 'phuong_quoc_tu_giam',
            division_type: 'phường',
            short_codename: 'quoc_tu_giam',
            parentId: 6,
          },
          {
            title: 'Phường Láng Thượng',
            code: 187,
            value: 'phuong_lang_thuong',
            division_type: 'phường',
            short_codename: 'lang_thuong',
            parentId: 6,
          },
          {
            title: 'Phường Ô Chợ Dừa',
            code: 190,
            value: 'phuong_o_cho_dua',
            division_type: 'phường',
            short_codename: 'o_cho_dua',
            parentId: 6,
          },
          {
            title: 'Phường Văn Chương',
            code: 193,
            value: 'phuong_van_chuong',
            division_type: 'phường',
            short_codename: 'van_chuong',
            parentId: 6,
          },
          {
            title: 'Phường Hàng Bột',
            code: 196,
            value: 'phuong_hang_bot',
            division_type: 'phường',
            short_codename: 'hang_bot',
            parentId: 6,
          },
          {
            title: 'Phường Láng Hạ',
            code: 199,
            value: 'phuong_lang_ha',
            division_type: 'phường',
            short_codename: 'lang_ha',
            parentId: 6,
          },
          {
            title: 'Phường Khâm Thiên',
            code: 202,
            value: 'phuong_kham_thien',
            division_type: 'phường',
            short_codename: 'kham_thien',
            parentId: 6,
          },
          {
            title: 'Phường Thổ Quan',
            code: 205,
            value: 'phuong_tho_quan',
            division_type: 'phường',
            short_codename: 'tho_quan',
            parentId: 6,
          },
          {
            title: 'Phường Nam Đồng',
            code: 208,
            value: 'phuong_nam_dong',
            division_type: 'phường',
            short_codename: 'nam_dong',
            parentId: 6,
          },
          {
            title: 'Phường Trung Phụng',
            code: 211,
            value: 'phuong_trung_phung',
            division_type: 'phường',
            short_codename: 'trung_phung',
            parentId: 6,
          },
          {
            title: 'Phường Quang Trung',
            code: 214,
            value: 'phuong_quang_trung',
            division_type: 'phường',
            short_codename: 'quang_trung',
            parentId: 6,
          },
          {
            title: 'Phường Trung Liệt',
            code: 217,
            value: 'phuong_trung_liet',
            division_type: 'phường',
            short_codename: 'trung_liet',
            parentId: 6,
          },
          {
            title: 'Phường Phương Liên',
            code: 220,
            value: 'phuong_phuong_lien',
            division_type: 'phường',
            short_codename: 'phuong_lien',
            parentId: 6,
          },
          {
            title: 'Phường Thịnh Quang',
            code: 223,
            value: 'phuong_thinh_quang',
            division_type: 'phường',
            short_codename: 'thinh_quang',
            parentId: 6,
          },
          {
            title: 'Phường Trung Tự',
            code: 226,
            value: 'phuong_trung_tu',
            division_type: 'phường',
            short_codename: 'trung_tu',
            parentId: 6,
          },
          {
            title: 'Phường Kim Liên',
            code: 229,
            value: 'phuong_kim_lien',
            division_type: 'phường',
            short_codename: 'kim_lien',
            parentId: 6,
          },
          {
            title: 'Phường Phương Mai',
            code: 232,
            value: 'phuong_phuong_mai',
            division_type: 'phường',
            short_codename: 'phuong_mai',
            parentId: 6,
          },
          {
            title: 'Phường Ngã Tư Sở',
            code: 235,
            value: 'phuong_nga_tu_so',
            division_type: 'phường',
            short_codename: 'nga_tu_so',
            parentId: 6,
          },
          {
            title: 'Phường Khương Thượng',
            code: 238,
            value: 'phuong_khuong_thuong',
            division_type: 'phường',
            short_codename: 'khuong_thuong',
            parentId: 6,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Quận Hai Bà Trưng',
        code: 7,
        value: 'quan_hai_ba_trung',
        division_type: 'quận',
        short_codename: 'hai_ba_trung',
        children: [
          {
            title: 'Phường Nguyễn Du',
            code: 241,
            value: 'phuong_nguyen_du',
            division_type: 'phường',
            short_codename: 'nguyen_du',
            parentId: 7,
          },
          {
            title: 'Phường Bạch Đằng',
            code: 244,
            value: 'phuong_bach_dang',
            division_type: 'phường',
            short_codename: 'bach_dang',
            parentId: 7,
          },
          {
            title: 'Phường Phạm Đình Hổ',
            code: 247,
            value: 'phuong_pham_dinh_ho',
            division_type: 'phường',
            short_codename: 'pham_dinh_ho',
            parentId: 7,
          },
          {
            title: 'Phường Lê Đại Hành',
            code: 256,
            value: 'phuong_le_dai_hanh',
            division_type: 'phường',
            short_codename: 'le_dai_hanh',
            parentId: 7,
          },
          {
            title: 'Phường Đồng Nhân',
            code: 259,
            value: 'phuong_dong_nhan',
            division_type: 'phường',
            short_codename: 'dong_nhan',
            parentId: 7,
          },
          {
            title: 'Phường Phố Huế',
            code: 262,
            value: 'phuong_pho_hue',
            division_type: 'phường',
            short_codename: 'pho_hue',
            parentId: 7,
          },
          {
            title: 'Phường Đống Mác',
            code: 265,
            value: 'phuong_dong_mac',
            division_type: 'phường',
            short_codename: 'dong_mac',
            parentId: 7,
          },
          {
            title: 'Phường Thanh Lương',
            code: 268,
            value: 'phuong_thanh_luong',
            division_type: 'phường',
            short_codename: 'thanh_luong',
            parentId: 7,
          },
          {
            title: 'Phường Thanh Nhàn',
            code: 271,
            value: 'phuong_thanh_nhan',
            division_type: 'phường',
            short_codename: 'thanh_nhan',
            parentId: 7,
          },
          {
            title: 'Phường Cầu Dền',
            code: 274,
            value: 'phuong_cau_den',
            division_type: 'phường',
            short_codename: 'cau_den',
            parentId: 7,
          },
          {
            title: 'Phường Bách Khoa',
            code: 277,
            value: 'phuong_bach_khoa',
            division_type: 'phường',
            short_codename: 'bach_khoa',
            parentId: 7,
          },
          {
            title: 'Phường Đồng Tâm',
            code: 280,
            value: 'phuong_dong_tam',
            division_type: 'phường',
            short_codename: 'dong_tam',
            parentId: 7,
          },
          {
            title: 'Phường Vĩnh Tuy',
            code: 283,
            value: 'phuong_vinh_tuy',
            division_type: 'phường',
            short_codename: 'vinh_tuy',
            parentId: 7,
          },
          {
            title: 'Phường Bạch Mai',
            code: 286,
            value: 'phuong_bach_mai',
            division_type: 'phường',
            short_codename: 'bach_mai',
            parentId: 7,
          },
          {
            title: 'Phường Quỳnh Mai',
            code: 289,
            value: 'phuong_quynh_mai',
            division_type: 'phường',
            short_codename: 'quynh_mai',
            parentId: 7,
          },
          {
            title: 'Phường Quỳnh Lôi',
            code: 292,
            value: 'phuong_quynh_loi',
            division_type: 'phường',
            short_codename: 'quynh_loi',
            parentId: 7,
          },
          {
            title: 'Phường Minh Khai',
            code: 295,
            value: 'phuong_minh_khai',
            division_type: 'phường',
            short_codename: 'minh_khai',
            parentId: 7,
          },
          {
            title: 'Phường Trương Định',
            code: 298,
            value: 'phuong_truong_dinh',
            division_type: 'phường',
            short_codename: 'truong_dinh',
            parentId: 7,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Quận Hoàng Mai',
        code: 8,
        value: 'quan_hoang_mai',
        division_type: 'quận',
        short_codename: 'hoang_mai',
        children: [
          {
            title: 'Phường Thanh Trì',
            code: 301,
            value: 'phuong_thanh_tri',
            division_type: 'phường',
            short_codename: 'thanh_tri',
            parentId: 8,
          },
          {
            title: 'Phường Vĩnh Hưng',
            code: 304,
            value: 'phuong_vinh_hung',
            division_type: 'phường',
            short_codename: 'vinh_hung',
            parentId: 8,
          },
          {
            title: 'Phường Định Công',
            code: 307,
            value: 'phuong_dinh_cong',
            division_type: 'phường',
            short_codename: 'dinh_cong',
            parentId: 8,
          },
          {
            title: 'Phường Mai Động',
            code: 310,
            value: 'phuong_mai_dong',
            division_type: 'phường',
            short_codename: 'mai_dong',
            parentId: 8,
          },
          {
            title: 'Phường Tương Mai',
            code: 313,
            value: 'phuong_tuong_mai',
            division_type: 'phường',
            short_codename: 'tuong_mai',
            parentId: 8,
          },
          {
            title: 'Phường Đại Kim',
            code: 316,
            value: 'phuong_dai_kim',
            division_type: 'phường',
            short_codename: 'dai_kim',
            parentId: 8,
          },
          {
            title: 'Phường Tân Mai',
            code: 319,
            value: 'phuong_tan_mai',
            division_type: 'phường',
            short_codename: 'tan_mai',
            parentId: 8,
          },
          {
            title: 'Phường Hoàng Văn Thụ',
            code: 322,
            value: 'phuong_hoang_van_thu',
            division_type: 'phường',
            short_codename: 'hoang_van_thu',
            parentId: 8,
          },
          {
            title: 'Phường Giáp Bát',
            code: 325,
            value: 'phuong_giap_bat',
            division_type: 'phường',
            short_codename: 'giap_bat',
            parentId: 8,
          },
          {
            title: 'Phường Lĩnh Nam',
            code: 328,
            value: 'phuong_linh_nam',
            division_type: 'phường',
            short_codename: 'linh_nam',
            parentId: 8,
          },
          {
            title: 'Phường Thịnh Liệt',
            code: 331,
            value: 'phuong_thinh_liet',
            division_type: 'phường',
            short_codename: 'thinh_liet',
            parentId: 8,
          },
          {
            title: 'Phường Trần Phú',
            code: 334,
            value: 'phuong_tran_phu',
            division_type: 'phường',
            short_codename: 'tran_phu',
            parentId: 8,
          },
          {
            title: 'Phường Hoàng Liệt',
            code: 337,
            value: 'phuong_hoang_liet',
            division_type: 'phường',
            short_codename: 'hoang_liet',
            parentId: 8,
          },
          {
            title: 'Phường Yên Sở',
            code: 340,
            value: 'phuong_yen_so',
            division_type: 'phường',
            short_codename: 'yen_so',
            parentId: 8,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Quận Thanh Xuân',
        code: 9,
        value: 'quan_thanh_xuan',
        division_type: 'quận',
        short_codename: 'thanh_xuan',
        children: [
          {
            title: 'Phường Nhân Chính',
            code: 343,
            value: 'phuong_nhan_chinh',
            division_type: 'phường',
            short_codename: 'nhan_chinh',
            parentId: 9,
          },
          {
            title: 'Phường Thượng Đình',
            code: 346,
            value: 'phuong_thuong_dinh',
            division_type: 'phường',
            short_codename: 'thuong_dinh',
            parentId: 9,
          },
          {
            title: 'Phường Khương Trung',
            code: 349,
            value: 'phuong_khuong_trung',
            division_type: 'phường',
            short_codename: 'khuong_trung',
            parentId: 9,
          },
          {
            title: 'Phường Khương Mai',
            code: 352,
            value: 'phuong_khuong_mai',
            division_type: 'phường',
            short_codename: 'khuong_mai',
            parentId: 9,
          },
          {
            title: 'Phường Thanh Xuân Trung',
            code: 355,
            value: 'phuong_thanh_xuan_trung',
            division_type: 'phường',
            short_codename: 'thanh_xuan_trung',
            parentId: 9,
          },
          {
            title: 'Phường Phương Liệt',
            code: 358,
            value: 'phuong_phuong_liet',
            division_type: 'phường',
            short_codename: 'phuong_liet',
            parentId: 9,
          },
          {
            title: 'Phường Hạ Đình',
            code: 361,
            value: 'phuong_ha_dinh',
            division_type: 'phường',
            short_codename: 'ha_dinh',
            parentId: 9,
          },
          {
            title: 'Phường Khương Đình',
            code: 364,
            value: 'phuong_khuong_dinh',
            division_type: 'phường',
            short_codename: 'khuong_dinh',
            parentId: 9,
          },
          {
            title: 'Phường Thanh Xuân Bắc',
            code: 367,
            value: 'phuong_thanh_xuan_bac',
            division_type: 'phường',
            short_codename: 'thanh_xuan_bac',
            parentId: 9,
          },
          {
            title: 'Phường Thanh Xuân Nam',
            code: 370,
            value: 'phuong_thanh_xuan_nam',
            division_type: 'phường',
            short_codename: 'thanh_xuan_nam',
            parentId: 9,
          },
          {
            title: 'Phường Kim Giang',
            code: 373,
            value: 'phuong_kim_giang',
            division_type: 'phường',
            short_codename: 'kim_giang',
            parentId: 9,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Huyện Sóc Sơn',
        code: 16,
        value: 'huyen_soc_son',
        division_type: 'huyện',
        short_codename: 'soc_son',
        children: [
          {
            title: 'Thị trấn Sóc Sơn',
            code: 376,
            value: 'thi_tran_soc_son',
            division_type: 'thị trấn',
            short_codename: 'soc_son',
            parentId: 16,
          },
          {
            title: 'Xã Bắc Sơn',
            code: 379,
            value: 'xa_bac_son',
            division_type: 'xã',
            short_codename: 'bac_son',
            parentId: 16,
          },
          {
            title: 'Xã Minh Trí',
            code: 382,
            value: 'xa_minh_tri',
            division_type: 'xã',
            short_codename: 'minh_tri',
            parentId: 16,
          },
          {
            title: 'Xã Hồng Kỳ',
            code: 385,
            value: 'xa_hong_ky',
            division_type: 'xã',
            short_codename: 'hong_ky',
            parentId: 16,
          },
          {
            title: 'Xã Nam Sơn',
            code: 388,
            value: 'xa_nam_son',
            division_type: 'xã',
            short_codename: 'nam_son',
            parentId: 16,
          },
          {
            title: 'Xã Trung Giã',
            code: 391,
            value: 'xa_trung_gia',
            division_type: 'xã',
            short_codename: 'trung_gia',
            parentId: 16,
          },
          {
            title: 'Xã Tân Hưng',
            code: 394,
            value: 'xa_tan_hung',
            division_type: 'xã',
            short_codename: 'tan_hung',
            parentId: 16,
          },
          {
            title: 'Xã Minh Phú',
            code: 397,
            value: 'xa_minh_phu',
            division_type: 'xã',
            short_codename: 'minh_phu',
            parentId: 16,
          },
          {
            title: 'Xã Phù Linh',
            code: 400,
            value: 'xa_phu_linh',
            division_type: 'xã',
            short_codename: 'phu_linh',
            parentId: 16,
          },
          {
            title: 'Xã Bắc Phú',
            code: 403,
            value: 'xa_bac_phu',
            division_type: 'xã',
            short_codename: 'bac_phu',
            parentId: 16,
          },
          {
            title: 'Xã Tân Minh',
            code: 406,
            value: 'xa_tan_minh',
            division_type: 'xã',
            short_codename: 'tan_minh',
            parentId: 16,
          },
          {
            title: 'Xã Quang Tiến',
            code: 409,
            value: 'xa_quang_tien',
            division_type: 'xã',
            short_codename: 'quang_tien',
            parentId: 16,
          },
          {
            title: 'Xã Hiền Ninh',
            code: 412,
            value: 'xa_hien_ninh',
            division_type: 'xã',
            short_codename: 'hien_ninh',
            parentId: 16,
          },
          {
            title: 'Xã Tân Dân',
            code: 415,
            value: 'xa_tan_dan',
            division_type: 'xã',
            short_codename: 'tan_dan',
            parentId: 16,
          },
          {
            title: 'Xã Tiên Dược',
            code: 418,
            value: 'xa_tien_duoc',
            division_type: 'xã',
            short_codename: 'tien_duoc',
            parentId: 16,
          },
          {
            title: 'Xã Việt Long',
            code: 421,
            value: 'xa_viet_long',
            division_type: 'xã',
            short_codename: 'viet_long',
            parentId: 16,
          },
          {
            title: 'Xã Xuân Giang',
            code: 424,
            value: 'xa_xuan_giang',
            division_type: 'xã',
            short_codename: 'xuan_giang',
            parentId: 16,
          },
          {
            title: 'Xã Mai Đình',
            code: 427,
            value: 'xa_mai_dinh',
            division_type: 'xã',
            short_codename: 'mai_dinh',
            parentId: 16,
          },
          {
            title: 'Xã Đức Hoà',
            code: 430,
            value: 'xa_duc_hoa',
            division_type: 'xã',
            short_codename: 'duc_hoa',
            parentId: 16,
          },
          {
            title: 'Xã Thanh Xuân',
            code: 433,
            value: 'xa_thanh_xuan',
            division_type: 'xã',
            short_codename: 'thanh_xuan',
            parentId: 16,
          },
          {
            title: 'Xã Đông Xuân',
            code: 436,
            value: 'xa_dong_xuan',
            division_type: 'xã',
            short_codename: 'dong_xuan',
            parentId: 16,
          },
          {
            title: 'Xã Kim Lũ',
            code: 439,
            value: 'xa_kim_lu',
            division_type: 'xã',
            short_codename: 'kim_lu',
            parentId: 16,
          },
          {
            title: 'Xã Phú Cường',
            code: 442,
            value: 'xa_phu_cuong',
            division_type: 'xã',
            short_codename: 'phu_cuong',
            parentId: 16,
          },
          {
            title: 'Xã Phú Minh',
            code: 445,
            value: 'xa_phu_minh',
            division_type: 'xã',
            short_codename: 'phu_minh',
            parentId: 16,
          },
          {
            title: 'Xã Phù Lỗ',
            code: 448,
            value: 'xa_phu_lo',
            division_type: 'xã',
            short_codename: 'phu_lo',
            parentId: 16,
          },
          {
            title: 'Xã Xuân Thu',
            code: 451,
            value: 'xa_xuan_thu',
            division_type: 'xã',
            short_codename: 'xuan_thu',
            parentId: 16,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Huyện Đông Anh',
        code: 17,
        value: 'huyen_dong_anh',
        division_type: 'huyện',
        short_codename: 'dong_anh',
        children: [
          {
            title: 'Thị trấn Đông Anh',
            code: 454,
            value: 'thi_tran_dong_anh',
            division_type: 'thị trấn',
            short_codename: 'dong_anh',
            parentId: 17,
          },
          {
            title: 'Xã Xuân Nộn',
            code: 457,
            value: 'xa_xuan_non',
            division_type: 'xã',
            short_codename: 'xuan_non',
            parentId: 17,
          },
          {
            title: 'Xã Thuỵ Lâm',
            code: 460,
            value: 'xa_thuy_lam',
            division_type: 'xã',
            short_codename: 'thuy_lam',
            parentId: 17,
          },
          {
            title: 'Xã Bắc Hồng',
            code: 463,
            value: 'xa_bac_hong',
            division_type: 'xã',
            short_codename: 'bac_hong',
            parentId: 17,
          },
          {
            title: 'Xã Nguyên Khê',
            code: 466,
            value: 'xa_nguyen_khe',
            division_type: 'xã',
            short_codename: 'nguyen_khe',
            parentId: 17,
          },
          {
            title: 'Xã Nam Hồng',
            code: 469,
            value: 'xa_nam_hong',
            division_type: 'xã',
            short_codename: 'nam_hong',
            parentId: 17,
          },
          {
            title: 'Xã Tiên Dương',
            code: 472,
            value: 'xa_tien_duong',
            division_type: 'xã',
            short_codename: 'tien_duong',
            parentId: 17,
          },
          {
            title: 'Xã Vân Hà',
            code: 475,
            value: 'xa_van_ha',
            division_type: 'xã',
            short_codename: 'van_ha',
            parentId: 17,
          },
          {
            title: 'Xã Uy Nỗ',
            code: 478,
            value: 'xa_uy_no',
            division_type: 'xã',
            short_codename: 'uy_no',
            parentId: 17,
          },
          {
            title: 'Xã Vân Nội',
            code: 481,
            value: 'xa_van_noi',
            division_type: 'xã',
            short_codename: 'van_noi',
            parentId: 17,
          },
          {
            title: 'Xã Liên Hà',
            code: 484,
            value: 'xa_lien_ha',
            division_type: 'xã',
            short_codename: 'lien_ha',
            parentId: 17,
          },
          {
            title: 'Xã Việt Hùng',
            code: 487,
            value: 'xa_viet_hung',
            division_type: 'xã',
            short_codename: 'viet_hung',
            parentId: 17,
          },
          {
            title: 'Xã Kim Nỗ',
            code: 490,
            value: 'xa_kim_no',
            division_type: 'xã',
            short_codename: 'kim_no',
            parentId: 17,
          },
          {
            title: 'Xã Kim Chung',
            code: 493,
            value: 'xa_kim_chung',
            division_type: 'xã',
            short_codename: 'kim_chung',
            parentId: 17,
          },
          {
            title: 'Xã Dục Tú',
            code: 496,
            value: 'xa_duc_tu',
            division_type: 'xã',
            short_codename: 'duc_tu',
            parentId: 17,
          },
          {
            title: 'Xã Đại Mạch',
            code: 499,
            value: 'xa_dai_mach',
            division_type: 'xã',
            short_codename: 'dai_mach',
            parentId: 17,
          },
          {
            title: 'Xã Vĩnh Ngọc',
            code: 502,
            value: 'xa_vinh_ngoc',
            division_type: 'xã',
            short_codename: 'vinh_ngoc',
            parentId: 17,
          },
          {
            title: 'Xã Cổ Loa',
            code: 505,
            value: 'xa_co_loa',
            division_type: 'xã',
            short_codename: 'co_loa',
            parentId: 17,
          },
          {
            title: 'Xã Hải Bối',
            code: 508,
            value: 'xa_hai_boi',
            division_type: 'xã',
            short_codename: 'hai_boi',
            parentId: 17,
          },
          {
            title: 'Xã Xuân Canh',
            code: 511,
            value: 'xa_xuan_canh',
            division_type: 'xã',
            short_codename: 'xuan_canh',
            parentId: 17,
          },
          {
            title: 'Xã Võng La',
            code: 514,
            value: 'xa_vong_la',
            division_type: 'xã',
            short_codename: 'vong_la',
            parentId: 17,
          },
          {
            title: 'Xã Tàm Xá',
            code: 517,
            value: 'xa_tam_xa',
            division_type: 'xã',
            short_codename: 'tam_xa',
            parentId: 17,
          },
          {
            title: 'Xã Mai Lâm',
            code: 520,
            value: 'xa_mai_lam',
            division_type: 'xã',
            short_codename: 'mai_lam',
            parentId: 17,
          },
          {
            title: 'Xã Đông Hội',
            code: 523,
            value: 'xa_dong_hoi',
            division_type: 'xã',
            short_codename: 'dong_hoi',
            parentId: 17,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Huyện Gia Lâm',
        code: 18,
        value: 'huyen_gia_lam',
        division_type: 'huyện',
        short_codename: 'gia_lam',
        children: [
          {
            title: 'Thị trấn Yên Viên',
            code: 526,
            value: 'thi_tran_yen_vien',
            division_type: 'thị trấn',
            short_codename: 'yen_vien',
            parentId: 18,
          },
          {
            title: 'Xã Yên Thường',
            code: 529,
            value: 'xa_yen_thuong',
            division_type: 'xã',
            short_codename: 'yen_thuong',
            parentId: 18,
          },
          {
            title: 'Xã Yên Viên',
            code: 532,
            value: 'xa_yen_vien',
            division_type: 'xã',
            short_codename: 'xa_yen_vien',
            parentId: 18,
          },
          {
            title: 'Xã Ninh Hiệp',
            code: 535,
            value: 'xa_ninh_hiep',
            division_type: 'xã',
            short_codename: 'ninh_hiep',
            parentId: 18,
          },
          {
            title: 'Xã Đình Xuyên',
            code: 538,
            value: 'xa_dinh_xuyen',
            division_type: 'xã',
            short_codename: 'dinh_xuyen',
            parentId: 18,
          },
          {
            title: 'Xã Dương Hà',
            code: 541,
            value: 'xa_duong_ha',
            division_type: 'xã',
            short_codename: 'duong_ha',
            parentId: 18,
          },
          {
            title: 'Xã Phù Đổng',
            code: 544,
            value: 'xa_phu_dong',
            division_type: 'xã',
            short_codename: 'phu_dong',
            parentId: 18,
          },
          {
            title: 'Xã Trung Mầu',
            code: 547,
            value: 'xa_trung_mau',
            division_type: 'xã',
            short_codename: 'trung_mau',
            parentId: 18,
          },
          {
            title: 'Xã Lệ Chi',
            code: 550,
            value: 'xa_le_chi',
            division_type: 'xã',
            short_codename: 'le_chi',
            parentId: 18,
          },
          {
            title: 'Xã Cổ Bi',
            code: 553,
            value: 'xa_co_bi',
            division_type: 'xã',
            short_codename: 'co_bi',
            parentId: 18,
          },
          {
            title: 'Xã Đặng Xá',
            code: 556,
            value: 'xa_dang_xa',
            division_type: 'xã',
            short_codename: 'dang_xa',
            parentId: 18,
          },
          {
            title: 'Xã Phú Thị',
            code: 559,
            value: 'xa_phu_thi',
            division_type: 'xã',
            short_codename: 'phu_thi',
            parentId: 18,
          },
          {
            title: 'Xã Kim Sơn',
            code: 562,
            value: 'xa_kim_son',
            division_type: 'xã',
            short_codename: 'kim_son',
            parentId: 18,
          },
          {
            title: 'Thị trấn Trâu Quỳ',
            code: 565,
            value: 'thi_tran_trau_quy',
            division_type: 'thị trấn',
            short_codename: 'trau_quy',
            parentId: 18,
          },
          {
            title: 'Xã Dương Quang',
            code: 568,
            value: 'xa_duong_quang',
            division_type: 'xã',
            short_codename: 'duong_quang',
            parentId: 18,
          },
          {
            title: 'Xã Dương Xá',
            code: 571,
            value: 'xa_duong_xa',
            division_type: 'xã',
            short_codename: 'duong_xa',
            parentId: 18,
          },
          {
            title: 'Xã Đông Dư',
            code: 574,
            value: 'xa_dong_du',
            division_type: 'xã',
            short_codename: 'dong_du',
            parentId: 18,
          },
          {
            title: 'Xã Đa Tốn',
            code: 577,
            value: 'xa_da_ton',
            division_type: 'xã',
            short_codename: 'da_ton',
            parentId: 18,
          },
          {
            title: 'Xã Kiêu Kỵ',
            code: 580,
            value: 'xa_kieu_ky',
            division_type: 'xã',
            short_codename: 'kieu_ky',
            parentId: 18,
          },
          {
            title: 'Xã Bát Tràng',
            code: 583,
            value: 'xa_bat_trang',
            division_type: 'xã',
            short_codename: 'bat_trang',
            parentId: 18,
          },
          {
            title: 'Xã Kim Lan',
            code: 586,
            value: 'xa_kim_lan',
            division_type: 'xã',
            short_codename: 'kim_lan',
            parentId: 18,
          },
          {
            title: 'Xã Văn Đức',
            code: 589,
            value: 'xa_van_duc',
            division_type: 'xã',
            short_codename: 'van_duc',
            parentId: 18,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Quận Nam Từ Liêm',
        code: 19,
        value: 'quan_nam_tu_liem',
        division_type: 'quận',
        short_codename: 'nam_tu_liem',
        children: [
          {
            title: 'Phường Cầu Diễn',
            code: 592,
            value: 'phuong_cau_dien',
            division_type: 'phường',
            short_codename: 'cau_dien',
            parentId: 19,
          },
          {
            title: 'Phường Xuân Phương',
            code: 622,
            value: 'phuong_xuan_phuong',
            division_type: 'phường',
            short_codename: 'xuan_phuong',
            parentId: 19,
          },
          {
            title: 'Phường Phương Canh',
            code: 623,
            value: 'phuong_phuong_canh',
            division_type: 'phường',
            short_codename: 'phuong_canh',
            parentId: 19,
          },
          {
            title: 'Phường Mỹ Đình 1',
            code: 625,
            value: 'phuong_my_dinh_1',
            division_type: 'phường',
            short_codename: 'my_dinh_1',
            parentId: 19,
          },
          {
            title: 'Phường Mỹ Đình 2',
            code: 626,
            value: 'phuong_my_dinh_2',
            division_type: 'phường',
            short_codename: 'my_dinh_2',
            parentId: 19,
          },
          {
            title: 'Phường Tây Mỗ',
            code: 628,
            value: 'phuong_tay_mo',
            division_type: 'phường',
            short_codename: 'tay_mo',
            parentId: 19,
          },
          {
            title: 'Phường Mễ Trì',
            code: 631,
            value: 'phuong_me_tri',
            division_type: 'phường',
            short_codename: 'me_tri',
            parentId: 19,
          },
          {
            title: 'Phường Phú Đô',
            code: 632,
            value: 'phuong_phu_do',
            division_type: 'phường',
            short_codename: 'phu_do',
            parentId: 19,
          },
          {
            title: 'Phường Đại Mỗ',
            code: 634,
            value: 'phuong_dai_mo',
            division_type: 'phường',
            short_codename: 'dai_mo',
            parentId: 19,
          },
          {
            title: 'Phường Trung Văn',
            code: 637,
            value: 'phuong_trung_van',
            division_type: 'phường',
            short_codename: 'trung_van',
            parentId: 19,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Huyện Thanh Trì',
        code: 20,
        value: 'huyen_thanh_tri',
        division_type: 'huyện',
        short_codename: 'thanh_tri',
        children: [
          {
            title: 'Thị trấn Văn Điển',
            code: 640,
            value: 'thi_tran_van_dien',
            division_type: 'thị trấn',
            short_codename: 'van_dien',
            parentId: 20,
          },
          {
            title: 'Xã Tân Triều',
            code: 643,
            value: 'xa_tan_trieu',
            division_type: 'xã',
            short_codename: 'tan_trieu',
            parentId: 20,
          },
          {
            title: 'Xã Thanh Liệt',
            code: 646,
            value: 'xa_thanh_liet',
            division_type: 'xã',
            short_codename: 'thanh_liet',
            parentId: 20,
          },
          {
            title: 'Xã Tả Thanh Oai',
            code: 649,
            value: 'xa_ta_thanh_oai',
            division_type: 'xã',
            short_codename: 'ta_thanh_oai',
            parentId: 20,
          },
          {
            title: 'Xã Hữu Hoà',
            code: 652,
            value: 'xa_huu_hoa',
            division_type: 'xã',
            short_codename: 'huu_hoa',
            parentId: 20,
          },
          {
            title: 'Xã Tam Hiệp',
            code: 655,
            value: 'xa_tam_hiep',
            division_type: 'xã',
            short_codename: 'tam_hiep',
            parentId: 20,
          },
          {
            title: 'Xã Tứ Hiệp',
            code: 658,
            value: 'xa_tu_hiep',
            division_type: 'xã',
            short_codename: 'tu_hiep',
            parentId: 20,
          },
          {
            title: 'Xã Yên Mỹ',
            code: 661,
            value: 'xa_yen_my',
            division_type: 'xã',
            short_codename: 'yen_my',
            parentId: 20,
          },
          {
            title: 'Xã Vĩnh Quỳnh',
            code: 664,
            value: 'xa_vinh_quynh',
            division_type: 'xã',
            short_codename: 'vinh_quynh',
            parentId: 20,
          },
          {
            title: 'Xã Ngũ Hiệp',
            code: 667,
            value: 'xa_ngu_hiep',
            division_type: 'xã',
            short_codename: 'ngu_hiep',
            parentId: 20,
          },
          {
            title: 'Xã Duyên Hà',
            code: 670,
            value: 'xa_duyen_ha',
            division_type: 'xã',
            short_codename: 'duyen_ha',
            parentId: 20,
          },
          {
            title: 'Xã Ngọc Hồi',
            code: 673,
            value: 'xa_ngoc_hoi',
            division_type: 'xã',
            short_codename: 'ngoc_hoi',
            parentId: 20,
          },
          {
            title: 'Xã Vạn Phúc',
            code: 676,
            value: 'xa_van_phuc',
            division_type: 'xã',
            short_codename: 'van_phuc',
            parentId: 20,
          },
          {
            title: 'Xã Đại áng',
            code: 679,
            value: 'xa_dai_ang',
            division_type: 'xã',
            short_codename: 'dai_ang',
            parentId: 20,
          },
          {
            title: 'Xã Liên Ninh',
            code: 682,
            value: 'xa_lien_ninh',
            division_type: 'xã',
            short_codename: 'lien_ninh',
            parentId: 20,
          },
          {
            title: 'Xã Đông Mỹ',
            code: 685,
            value: 'xa_dong_my',
            division_type: 'xã',
            short_codename: 'dong_my',
            parentId: 20,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Quận Bắc Từ Liêm',
        code: 21,
        value: 'quan_bac_tu_liem',
        division_type: 'quận',
        short_codename: 'bac_tu_liem',
        children: [
          {
            title: 'Phường Thượng Cát',
            code: 595,
            value: 'phuong_thuong_cat',
            division_type: 'phường',
            short_codename: 'thuong_cat',
            parentId: 21,
          },
          {
            title: 'Phường Liên Mạc',
            code: 598,
            value: 'phuong_lien_mac',
            division_type: 'phường',
            short_codename: 'lien_mac',
            parentId: 21,
          },
          {
            title: 'Phường Đông Ngạc',
            code: 601,
            value: 'phuong_dong_ngac',
            division_type: 'phường',
            short_codename: 'dong_ngac',
            parentId: 21,
          },
          {
            title: 'Phường Đức Thắng',
            code: 602,
            value: 'phuong_duc_thang',
            division_type: 'phường',
            short_codename: 'duc_thang',
            parentId: 21,
          },
          {
            title: 'Phường Thụy Phương',
            code: 604,
            value: 'phuong_thuy_phuong',
            division_type: 'phường',
            short_codename: 'thuy_phuong',
            parentId: 21,
          },
          {
            title: 'Phường Tây Tựu',
            code: 607,
            value: 'phuong_tay_tuu',
            division_type: 'phường',
            short_codename: 'tay_tuu',
            parentId: 21,
          },
          {
            title: 'Phường Xuân Đỉnh',
            code: 610,
            value: 'phuong_xuan_dinh',
            division_type: 'phường',
            short_codename: 'xuan_dinh',
            parentId: 21,
          },
          {
            title: 'Phường Xuân Tảo',
            code: 611,
            value: 'phuong_xuan_tao',
            division_type: 'phường',
            short_codename: 'xuan_tao',
            parentId: 21,
          },
          {
            title: 'Phường Minh Khai',
            code: 613,
            value: 'phuong_minh_khai',
            division_type: 'phường',
            short_codename: 'minh_khai',
            parentId: 21,
          },
          {
            title: 'Phường Cổ Nhuế 1',
            code: 616,
            value: 'phuong_co_nhue_1',
            division_type: 'phường',
            short_codename: 'co_nhue_1',
            parentId: 21,
          },
          {
            title: 'Phường Cổ Nhuế 2',
            code: 617,
            value: 'phuong_co_nhue_2',
            division_type: 'phường',
            short_codename: 'co_nhue_2',
            parentId: 21,
          },
          {
            title: 'Phường Phú Diễn',
            code: 619,
            value: 'phuong_phu_dien',
            division_type: 'phường',
            short_codename: 'phu_dien',
            parentId: 21,
          },
          {
            title: 'Phường Phúc Diễn',
            code: 620,
            value: 'phuong_phuc_dien',
            division_type: 'phường',
            short_codename: 'phuc_dien',
            parentId: 21,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Huyện Mê Linh',
        code: 250,
        value: 'huyen_me_linh',
        division_type: 'huyện',
        short_codename: 'me_linh',
        children: [
          {
            title: 'Thị trấn Chi Đông',
            code: 8973,
            value: 'thi_tran_chi_dong',
            division_type: 'thị trấn',
            short_codename: 'chi_dong',
            parentId: 250,
          },
          {
            title: 'Xã Đại Thịnh',
            code: 8974,
            value: 'xa_dai_thinh',
            division_type: 'xã',
            short_codename: 'dai_thinh',
            parentId: 250,
          },
          {
            title: 'Xã Kim Hoa',
            code: 8977,
            value: 'xa_kim_hoa',
            division_type: 'xã',
            short_codename: 'kim_hoa',
            parentId: 250,
          },
          {
            title: 'Xã Thạch Đà',
            code: 8980,
            value: 'xa_thach_da',
            division_type: 'xã',
            short_codename: 'thach_da',
            parentId: 250,
          },
          {
            title: 'Xã Tiến Thắng',
            code: 8983,
            value: 'xa_tien_thang',
            division_type: 'xã',
            short_codename: 'tien_thang',
            parentId: 250,
          },
          {
            title: 'Xã Tự Lập',
            code: 8986,
            value: 'xa_tu_lap',
            division_type: 'xã',
            short_codename: 'tu_lap',
            parentId: 250,
          },
          {
            title: 'Thị trấn Quang Minh',
            code: 8989,
            value: 'thi_tran_quang_minh',
            division_type: 'thị trấn',
            short_codename: 'quang_minh',
            parentId: 250,
          },
          {
            title: 'Xã Thanh Lâm',
            code: 8992,
            value: 'xa_thanh_lam',
            division_type: 'xã',
            short_codename: 'thanh_lam',
            parentId: 250,
          },
          {
            title: 'Xã Tam Đồng',
            code: 8995,
            value: 'xa_tam_dong',
            division_type: 'xã',
            short_codename: 'tam_dong',
            parentId: 250,
          },
          {
            title: 'Xã Liên Mạc',
            code: 8998,
            value: 'xa_lien_mac',
            division_type: 'xã',
            short_codename: 'lien_mac',
            parentId: 250,
          },
          {
            title: 'Xã Vạn Yên',
            code: 9001,
            value: 'xa_van_yen',
            division_type: 'xã',
            short_codename: 'van_yen',
            parentId: 250,
          },
          {
            title: 'Xã Chu Phan',
            code: 9004,
            value: 'xa_chu_phan',
            division_type: 'xã',
            short_codename: 'chu_phan',
            parentId: 250,
          },
          {
            title: 'Xã Tiến Thịnh',
            code: 9007,
            value: 'xa_tien_thinh',
            division_type: 'xã',
            short_codename: 'tien_thinh',
            parentId: 250,
          },
          {
            title: 'Xã Mê Linh',
            code: 9010,
            value: 'xa_me_linh',
            division_type: 'xã',
            short_codename: 'me_linh',
            parentId: 250,
          },
          {
            title: 'Xã Văn Khê',
            code: 9013,
            value: 'xa_van_khe',
            division_type: 'xã',
            short_codename: 'van_khe',
            parentId: 250,
          },
          {
            title: 'Xã Hoàng Kim',
            code: 9016,
            value: 'xa_hoang_kim',
            division_type: 'xã',
            short_codename: 'hoang_kim',
            parentId: 250,
          },
          {
            title: 'Xã Tiền Phong',
            code: 9019,
            value: 'xa_tien_phong',
            division_type: 'xã',
            short_codename: 'tien_phong',
            parentId: 250,
          },
          {
            title: 'Xã Tráng Việt',
            code: 9022,
            value: 'xa_trang_viet',
            division_type: 'xã',
            short_codename: 'trang_viet',
            parentId: 250,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Quận Hà Đông',
        code: 268,
        value: 'quan_ha_dong',
        division_type: 'quận',
        short_codename: 'ha_dong',
        children: [
          {
            title: 'Phường Nguyễn Trãi',
            code: 9538,
            value: 'phuong_nguyen_trai',
            division_type: 'phường',
            short_codename: 'nguyen_trai',
            parentId: 268,
          },
          {
            title: 'Phường Mộ Lao',
            code: 9541,
            value: 'phuong_mo_lao',
            division_type: 'phường',
            short_codename: 'mo_lao',
            parentId: 268,
          },
          {
            title: 'Phường Văn Quán',
            code: 9542,
            value: 'phuong_van_quan',
            division_type: 'phường',
            short_codename: 'van_quan',
            parentId: 268,
          },
          {
            title: 'Phường Vạn Phúc',
            code: 9544,
            value: 'phuong_van_phuc',
            division_type: 'phường',
            short_codename: 'van_phuc',
            parentId: 268,
          },
          {
            title: 'Phường Yết Kiêu',
            code: 9547,
            value: 'phuong_yet_kieu',
            division_type: 'phường',
            short_codename: 'yet_kieu',
            parentId: 268,
          },
          {
            title: 'Phường Quang Trung',
            code: 9550,
            value: 'phuong_quang_trung',
            division_type: 'phường',
            short_codename: 'quang_trung',
            parentId: 268,
          },
          {
            title: 'Phường La Khê',
            code: 9551,
            value: 'phuong_la_khe',
            division_type: 'phường',
            short_codename: 'la_khe',
            parentId: 268,
          },
          {
            title: 'Phường Phú La',
            code: 9552,
            value: 'phuong_phu_la',
            division_type: 'phường',
            short_codename: 'phu_la',
            parentId: 268,
          },
          {
            title: 'Phường Phúc La',
            code: 9553,
            value: 'phuong_phuc_la',
            division_type: 'phường',
            short_codename: 'phuc_la',
            parentId: 268,
          },
          {
            title: 'Phường Hà Cầu',
            code: 9556,
            value: 'phuong_ha_cau',
            division_type: 'phường',
            short_codename: 'ha_cau',
            parentId: 268,
          },
          {
            title: 'Phường Yên Nghĩa',
            code: 9562,
            value: 'phuong_yen_nghia',
            division_type: 'phường',
            short_codename: 'yen_nghia',
            parentId: 268,
          },
          {
            title: 'Phường Kiến Hưng',
            code: 9565,
            value: 'phuong_kien_hung',
            division_type: 'phường',
            short_codename: 'kien_hung',
            parentId: 268,
          },
          {
            title: 'Phường Phú Lãm',
            code: 9568,
            value: 'phuong_phu_lam',
            division_type: 'phường',
            short_codename: 'phu_lam',
            parentId: 268,
          },
          {
            title: 'Phường Phú Lương',
            code: 9571,
            value: 'phuong_phu_luong',
            division_type: 'phường',
            short_codename: 'phu_luong',
            parentId: 268,
          },
          {
            title: 'Phường Dương Nội',
            code: 9886,
            value: 'phuong_duong_noi',
            division_type: 'phường',
            short_codename: 'duong_noi',
            parentId: 268,
          },
          {
            title: 'Phường Đồng Mai',
            code: 10117,
            value: 'phuong_dong_mai',
            division_type: 'phường',
            short_codename: 'dong_mai',
            parentId: 268,
          },
          {
            title: 'Phường Biên Giang',
            code: 10123,
            value: 'phuong_bien_giang',
            division_type: 'phường',
            short_codename: 'bien_giang',
            parentId: 268,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Thị xã Sơn Tây',
        code: 269,
        value: 'thi_xa_son_tay',
        division_type: 'thị xã',
        short_codename: 'son_tay',
        children: [
          {
            title: 'Phường Lê Lợi',
            code: 9574,
            value: 'phuong_le_loi',
            division_type: 'phường',
            short_codename: 'le_loi',
            parentId: 269,
          },
          {
            title: 'Phường Phú Thịnh',
            code: 9577,
            value: 'phuong_phu_thinh',
            division_type: 'phường',
            short_codename: 'phu_thinh',
            parentId: 269,
          },
          {
            title: 'Phường Ngô Quyền',
            code: 9580,
            value: 'phuong_ngo_quyen',
            division_type: 'phường',
            short_codename: 'ngo_quyen',
            parentId: 269,
          },
          {
            title: 'Phường Quang Trung',
            code: 9583,
            value: 'phuong_quang_trung',
            division_type: 'phường',
            short_codename: 'quang_trung',
            parentId: 269,
          },
          {
            title: 'Phường Sơn Lộc',
            code: 9586,
            value: 'phuong_son_loc',
            division_type: 'phường',
            short_codename: 'son_loc',
            parentId: 269,
          },
          {
            title: 'Phường Xuân Khanh',
            code: 9589,
            value: 'phuong_xuan_khanh',
            division_type: 'phường',
            short_codename: 'xuan_khanh',
            parentId: 269,
          },
          {
            title: 'Xã Đường Lâm',
            code: 9592,
            value: 'xa_duong_lam',
            division_type: 'xã',
            short_codename: 'duong_lam',
            parentId: 269,
          },
          {
            title: 'Phường Viên Sơn',
            code: 9595,
            value: 'phuong_vien_son',
            division_type: 'phường',
            short_codename: 'vien_son',
            parentId: 269,
          },
          {
            title: 'Xã Xuân Sơn',
            code: 9598,
            value: 'xa_xuan_son',
            division_type: 'xã',
            short_codename: 'xuan_son',
            parentId: 269,
          },
          {
            title: 'Phường Trung Hưng',
            code: 9601,
            value: 'phuong_trung_hung',
            division_type: 'phường',
            short_codename: 'trung_hung',
            parentId: 269,
          },
          {
            title: 'Xã Thanh Mỹ',
            code: 9604,
            value: 'xa_thanh_my',
            division_type: 'xã',
            short_codename: 'thanh_my',
            parentId: 269,
          },
          {
            title: 'Phường Trung Sơn Trầm',
            code: 9607,
            value: 'phuong_trung_son_tram',
            division_type: 'phường',
            short_codename: 'trung_son_tram',
            parentId: 269,
          },
          {
            title: 'Xã Kim Sơn',
            code: 9610,
            value: 'xa_kim_son',
            division_type: 'xã',
            short_codename: 'kim_son',
            parentId: 269,
          },
          {
            title: 'Xã Sơn Đông',
            code: 9613,
            value: 'xa_son_dong',
            division_type: 'xã',
            short_codename: 'son_dong',
            parentId: 269,
          },
          {
            title: 'Xã Cổ Đông',
            code: 9616,
            value: 'xa_co_dong',
            division_type: 'xã',
            short_codename: 'co_dong',
            parentId: 269,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Huyện Ba Vì',
        code: 271,
        value: 'huyen_ba_vi',
        division_type: 'huyện',
        short_codename: 'ba_vi',
        children: [
          {
            title: 'Thị trấn Tây Đằng',
            code: 9619,
            value: 'thi_tran_tay_dang',
            division_type: 'thị trấn',
            short_codename: 'tay_dang',
            parentId: 271,
          },
          {
            title: 'Xã Phú Cường',
            code: 9625,
            value: 'xa_phu_cuong',
            division_type: 'xã',
            short_codename: 'phu_cuong',
            parentId: 271,
          },
          {
            title: 'Xã Cổ Đô',
            code: 9628,
            value: 'xa_co_do',
            division_type: 'xã',
            short_codename: 'co_do',
            parentId: 271,
          },
          {
            title: 'Xã Tản Hồng',
            code: 9631,
            value: 'xa_tan_hong',
            division_type: 'xã',
            short_codename: 'tan_hong',
            parentId: 271,
          },
          {
            title: 'Xã Vạn Thắng',
            code: 9634,
            value: 'xa_van_thang',
            division_type: 'xã',
            short_codename: 'van_thang',
            parentId: 271,
          },
          {
            title: 'Xã Châu Sơn',
            code: 9637,
            value: 'xa_chau_son',
            division_type: 'xã',
            short_codename: 'chau_son',
            parentId: 271,
          },
          {
            title: 'Xã Phong Vân',
            code: 9640,
            value: 'xa_phong_van',
            division_type: 'xã',
            short_codename: 'phong_van',
            parentId: 271,
          },
          {
            title: 'Xã Phú Đông',
            code: 9643,
            value: 'xa_phu_dong',
            division_type: 'xã',
            short_codename: 'phu_dong',
            parentId: 271,
          },
          {
            title: 'Xã Phú Phương',
            code: 9646,
            value: 'xa_phu_phuong',
            division_type: 'xã',
            short_codename: 'phu_phuong',
            parentId: 271,
          },
          {
            title: 'Xã Phú Châu',
            code: 9649,
            value: 'xa_phu_chau',
            division_type: 'xã',
            short_codename: 'phu_chau',
            parentId: 271,
          },
          {
            title: 'Xã Thái Hòa',
            code: 9652,
            value: 'xa_thai_hoa',
            division_type: 'xã',
            short_codename: 'thai_hoa',
            parentId: 271,
          },
          {
            title: 'Xã Đồng Thái',
            code: 9655,
            value: 'xa_dong_thai',
            division_type: 'xã',
            short_codename: 'dong_thai',
            parentId: 271,
          },
          {
            title: 'Xã Phú Sơn',
            code: 9658,
            value: 'xa_phu_son',
            division_type: 'xã',
            short_codename: 'phu_son',
            parentId: 271,
          },
          {
            title: 'Xã Minh Châu',
            code: 9661,
            value: 'xa_minh_chau',
            division_type: 'xã',
            short_codename: 'minh_chau',
            parentId: 271,
          },
          {
            title: 'Xã Vật Lại',
            code: 9664,
            value: 'xa_vat_lai',
            division_type: 'xã',
            short_codename: 'vat_lai',
            parentId: 271,
          },
          {
            title: 'Xã Chu Minh',
            code: 9667,
            value: 'xa_chu_minh',
            division_type: 'xã',
            short_codename: 'chu_minh',
            parentId: 271,
          },
          {
            title: 'Xã Tòng Bạt',
            code: 9670,
            value: 'xa_tong_bat',
            division_type: 'xã',
            short_codename: 'tong_bat',
            parentId: 271,
          },
          {
            title: 'Xã Cẩm Lĩnh',
            code: 9673,
            value: 'xa_cam_linh',
            division_type: 'xã',
            short_codename: 'cam_linh',
            parentId: 271,
          },
          {
            title: 'Xã Sơn Đà',
            code: 9676,
            value: 'xa_son_da',
            division_type: 'xã',
            short_codename: 'son_da',
            parentId: 271,
          },
          {
            title: 'Xã Đông Quang',
            code: 9679,
            value: 'xa_dong_quang',
            division_type: 'xã',
            short_codename: 'dong_quang',
            parentId: 271,
          },
          {
            title: 'Xã Tiên Phong',
            code: 9682,
            value: 'xa_tien_phong',
            division_type: 'xã',
            short_codename: 'tien_phong',
            parentId: 271,
          },
          {
            title: 'Xã Thụy An',
            code: 9685,
            value: 'xa_thuy_an',
            division_type: 'xã',
            short_codename: 'thuy_an',
            parentId: 271,
          },
          {
            title: 'Xã Cam Thượng',
            code: 9688,
            value: 'xa_cam_thuong',
            division_type: 'xã',
            short_codename: 'cam_thuong',
            parentId: 271,
          },
          {
            title: 'Xã Thuần Mỹ',
            code: 9691,
            value: 'xa_thuan_my',
            division_type: 'xã',
            short_codename: 'thuan_my',
            parentId: 271,
          },
          {
            title: 'Xã Tản Lĩnh',
            code: 9694,
            value: 'xa_tan_linh',
            division_type: 'xã',
            short_codename: 'tan_linh',
            parentId: 271,
          },
          {
            title: 'Xã Ba Trại',
            code: 9697,
            value: 'xa_ba_trai',
            division_type: 'xã',
            short_codename: 'ba_trai',
            parentId: 271,
          },
          {
            title: 'Xã Minh Quang',
            code: 9700,
            value: 'xa_minh_quang',
            division_type: 'xã',
            short_codename: 'minh_quang',
            parentId: 271,
          },
          {
            title: 'Xã Ba Vì',
            code: 9703,
            value: 'xa_ba_vi',
            division_type: 'xã',
            short_codename: 'ba_vi',
            parentId: 271,
          },
          {
            title: 'Xã Vân Hòa',
            code: 9706,
            value: 'xa_van_hoa',
            division_type: 'xã',
            short_codename: 'van_hoa',
            parentId: 271,
          },
          {
            title: 'Xã Yên Bài',
            code: 9709,
            value: 'xa_yen_bai',
            division_type: 'xã',
            short_codename: 'yen_bai',
            parentId: 271,
          },
          {
            title: 'Xã Khánh Thượng',
            code: 9712,
            value: 'xa_khanh_thuong',
            division_type: 'xã',
            short_codename: 'khanh_thuong',
            parentId: 271,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Huyện Phúc Thọ',
        code: 272,
        value: 'huyen_phuc_tho',
        division_type: 'huyện',
        short_codename: 'phuc_tho',
        children: [
          {
            title: 'Thị trấn Phúc Thọ',
            code: 9715,
            value: 'thi_tran_phuc_tho',
            division_type: 'thị trấn',
            short_codename: 'phuc_tho',
            parentId: 272,
          },
          {
            title: 'Xã Vân Hà',
            code: 9718,
            value: 'xa_van_ha',
            division_type: 'xã',
            short_codename: 'van_ha',
            parentId: 272,
          },
          {
            title: 'Xã Vân Phúc',
            code: 9721,
            value: 'xa_van_phuc',
            division_type: 'xã',
            short_codename: 'van_phuc',
            parentId: 272,
          },
          {
            title: 'Xã Vân Nam',
            code: 9724,
            value: 'xa_van_nam',
            division_type: 'xã',
            short_codename: 'van_nam',
            parentId: 272,
          },
          {
            title: 'Xã Xuân Đình',
            code: 9727,
            value: 'xa_xuan_dinh',
            division_type: 'xã',
            short_codename: 'xuan_dinh',
            parentId: 272,
          },
          {
            title: 'Xã Sen Phương',
            code: 9733,
            value: 'xa_sen_phuong',
            division_type: 'xã',
            short_codename: 'sen_phuong',
            parentId: 272,
          },
          {
            title: 'Xã Võng Xuyên',
            code: 9739,
            value: 'xa_vong_xuyen',
            division_type: 'xã',
            short_codename: 'vong_xuyen',
            parentId: 272,
          },
          {
            title: 'Xã Thọ Lộc',
            code: 9742,
            value: 'xa_tho_loc',
            division_type: 'xã',
            short_codename: 'tho_loc',
            parentId: 272,
          },
          {
            title: 'Xã Long Xuyên',
            code: 9745,
            value: 'xa_long_xuyen',
            division_type: 'xã',
            short_codename: 'long_xuyen',
            parentId: 272,
          },
          {
            title: 'Xã Thượng Cốc',
            code: 9748,
            value: 'xa_thuong_coc',
            division_type: 'xã',
            short_codename: 'thuong_coc',
            parentId: 272,
          },
          {
            title: 'Xã Hát Môn',
            code: 9751,
            value: 'xa_hat_mon',
            division_type: 'xã',
            short_codename: 'hat_mon',
            parentId: 272,
          },
          {
            title: 'Xã Tích Giang',
            code: 9754,
            value: 'xa_tich_giang',
            division_type: 'xã',
            short_codename: 'tich_giang',
            parentId: 272,
          },
          {
            title: 'Xã Thanh Đa',
            code: 9757,
            value: 'xa_thanh_da',
            division_type: 'xã',
            short_codename: 'thanh_da',
            parentId: 272,
          },
          {
            title: 'Xã Trạch Mỹ Lộc',
            code: 9760,
            value: 'xa_trach_my_loc',
            division_type: 'xã',
            short_codename: 'trach_my_loc',
            parentId: 272,
          },
          {
            title: 'Xã Phúc Hòa',
            code: 9763,
            value: 'xa_phuc_hoa',
            division_type: 'xã',
            short_codename: 'phuc_hoa',
            parentId: 272,
          },
          {
            title: 'Xã Ngọc Tảo',
            code: 9766,
            value: 'xa_ngoc_tao',
            division_type: 'xã',
            short_codename: 'ngoc_tao',
            parentId: 272,
          },
          {
            title: 'Xã Phụng Thượng',
            code: 9769,
            value: 'xa_phung_thuong',
            division_type: 'xã',
            short_codename: 'phung_thuong',
            parentId: 272,
          },
          {
            title: 'Xã Tam Thuấn',
            code: 9772,
            value: 'xa_tam_thuan',
            division_type: 'xã',
            short_codename: 'tam_thuan',
            parentId: 272,
          },
          {
            title: 'Xã Tam Hiệp',
            code: 9775,
            value: 'xa_tam_hiep',
            division_type: 'xã',
            short_codename: 'tam_hiep',
            parentId: 272,
          },
          {
            title: 'Xã Hiệp Thuận',
            code: 9778,
            value: 'xa_hiep_thuan',
            division_type: 'xã',
            short_codename: 'hiep_thuan',
            parentId: 272,
          },
          {
            title: 'Xã Liên Hiệp',
            code: 9781,
            value: 'xa_lien_hiep',
            division_type: 'xã',
            short_codename: 'lien_hiep',
            parentId: 272,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Huyện Đan Phượng',
        code: 273,
        value: 'huyen_dan_phuong',
        division_type: 'huyện',
        short_codename: 'dan_phuong',
        children: [
          {
            title: 'Thị trấn Phùng',
            code: 9784,
            value: 'thi_tran_phung',
            division_type: 'thị trấn',
            short_codename: 'phung',
            parentId: 273,
          },
          {
            title: 'Xã Trung Châu',
            code: 9787,
            value: 'xa_trung_chau',
            division_type: 'xã',
            short_codename: 'trung_chau',
            parentId: 273,
          },
          {
            title: 'Xã Thọ An',
            code: 9790,
            value: 'xa_tho_an',
            division_type: 'xã',
            short_codename: 'tho_an',
            parentId: 273,
          },
          {
            title: 'Xã Thọ Xuân',
            code: 9793,
            value: 'xa_tho_xuan',
            division_type: 'xã',
            short_codename: 'tho_xuan',
            parentId: 273,
          },
          {
            title: 'Xã Hồng Hà',
            code: 9796,
            value: 'xa_hong_ha',
            division_type: 'xã',
            short_codename: 'hong_ha',
            parentId: 273,
          },
          {
            title: 'Xã Liên Hồng',
            code: 9799,
            value: 'xa_lien_hong',
            division_type: 'xã',
            short_codename: 'lien_hong',
            parentId: 273,
          },
          {
            title: 'Xã Liên Hà',
            code: 9802,
            value: 'xa_lien_ha',
            division_type: 'xã',
            short_codename: 'lien_ha',
            parentId: 273,
          },
          {
            title: 'Xã Hạ Mỗ',
            code: 9805,
            value: 'xa_ha_mo',
            division_type: 'xã',
            short_codename: 'ha_mo',
            parentId: 273,
          },
          {
            title: 'Xã Liên Trung',
            code: 9808,
            value: 'xa_lien_trung',
            division_type: 'xã',
            short_codename: 'lien_trung',
            parentId: 273,
          },
          {
            title: 'Xã Phương Đình',
            code: 9811,
            value: 'xa_phuong_dinh',
            division_type: 'xã',
            short_codename: 'phuong_dinh',
            parentId: 273,
          },
          {
            title: 'Xã Thượng Mỗ',
            code: 9814,
            value: 'xa_thuong_mo',
            division_type: 'xã',
            short_codename: 'thuong_mo',
            parentId: 273,
          },
          {
            title: 'Xã Tân Hội',
            code: 9817,
            value: 'xa_tan_hoi',
            division_type: 'xã',
            short_codename: 'tan_hoi',
            parentId: 273,
          },
          {
            title: 'Xã Tân Lập',
            code: 9820,
            value: 'xa_tan_lap',
            division_type: 'xã',
            short_codename: 'tan_lap',
            parentId: 273,
          },
          {
            title: 'Xã Đan Phượng',
            code: 9823,
            value: 'xa_dan_phuong',
            division_type: 'xã',
            short_codename: 'dan_phuong',
            parentId: 273,
          },
          {
            title: 'Xã Đồng Tháp',
            code: 9826,
            value: 'xa_dong_thap',
            division_type: 'xã',
            short_codename: 'dong_thap',
            parentId: 273,
          },
          {
            title: 'Xã Song Phượng',
            code: 9829,
            value: 'xa_song_phuong',
            division_type: 'xã',
            short_codename: 'song_phuong',
            parentId: 273,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Huyện Hoài Đức',
        code: 274,
        value: 'huyen_hoai_duc',
        division_type: 'huyện',
        short_codename: 'hoai_duc',
        children: [
          {
            title: 'Thị trấn Trạm Trôi',
            code: 9832,
            value: 'thi_tran_tram_troi',
            division_type: 'thị trấn',
            short_codename: 'tram_troi',
            parentId: 274,
          },
          {
            title: 'Xã Đức Thượng',
            code: 9835,
            value: 'xa_duc_thuong',
            division_type: 'xã',
            short_codename: 'duc_thuong',
            parentId: 274,
          },
          {
            title: 'Xã Minh Khai',
            code: 9838,
            value: 'xa_minh_khai',
            division_type: 'xã',
            short_codename: 'minh_khai',
            parentId: 274,
          },
          {
            title: 'Xã Dương Liễu',
            code: 9841,
            value: 'xa_duong_lieu',
            division_type: 'xã',
            short_codename: 'duong_lieu',
            parentId: 274,
          },
          {
            title: 'Xã Di Trạch',
            code: 9844,
            value: 'xa_di_trach',
            division_type: 'xã',
            short_codename: 'di_trach',
            parentId: 274,
          },
          {
            title: 'Xã Đức Giang',
            code: 9847,
            value: 'xa_duc_giang',
            division_type: 'xã',
            short_codename: 'duc_giang',
            parentId: 274,
          },
          {
            title: 'Xã Cát Quế',
            code: 9850,
            value: 'xa_cat_que',
            division_type: 'xã',
            short_codename: 'cat_que',
            parentId: 274,
          },
          {
            title: 'Xã Kim Chung',
            code: 9853,
            value: 'xa_kim_chung',
            division_type: 'xã',
            short_codename: 'kim_chung',
            parentId: 274,
          },
          {
            title: 'Xã Yên Sở',
            code: 9856,
            value: 'xa_yen_so',
            division_type: 'xã',
            short_codename: 'yen_so',
            parentId: 274,
          },
          {
            title: 'Xã Sơn Đồng',
            code: 9859,
            value: 'xa_son_dong',
            division_type: 'xã',
            short_codename: 'son_dong',
            parentId: 274,
          },
          {
            title: 'Xã Vân Canh',
            code: 9862,
            value: 'xa_van_canh',
            division_type: 'xã',
            short_codename: 'van_canh',
            parentId: 274,
          },
          {
            title: 'Xã Đắc Sở',
            code: 9865,
            value: 'xa_dac_so',
            division_type: 'xã',
            short_codename: 'dac_so',
            parentId: 274,
          },
          {
            title: 'Xã Lại Yên',
            code: 9868,
            value: 'xa_lai_yen',
            division_type: 'xã',
            short_codename: 'lai_yen',
            parentId: 274,
          },
          {
            title: 'Xã Tiền Yên',
            code: 9871,
            value: 'xa_tien_yen',
            division_type: 'xã',
            short_codename: 'tien_yen',
            parentId: 274,
          },
          {
            title: 'Xã Song Phương',
            code: 9874,
            value: 'xa_song_phuong',
            division_type: 'xã',
            short_codename: 'song_phuong',
            parentId: 274,
          },
          {
            title: 'Xã An Khánh',
            code: 9877,
            value: 'xa_an_khanh',
            division_type: 'xã',
            short_codename: 'an_khanh',
            parentId: 274,
          },
          {
            title: 'Xã An Thượng',
            code: 9880,
            value: 'xa_an_thuong',
            division_type: 'xã',
            short_codename: 'an_thuong',
            parentId: 274,
          },
          {
            title: 'Xã Vân Côn',
            code: 9883,
            value: 'xa_van_con',
            division_type: 'xã',
            short_codename: 'van_con',
            parentId: 274,
          },
          {
            title: 'Xã La Phù',
            code: 9889,
            value: 'xa_la_phu',
            division_type: 'xã',
            short_codename: 'la_phu',
            parentId: 274,
          },
          {
            title: 'Xã Đông La',
            code: 9892,
            value: 'xa_dong_la',
            division_type: 'xã',
            short_codename: 'dong_la',
            parentId: 274,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Huyện Quốc Oai',
        code: 275,
        value: 'huyen_quoc_oai',
        division_type: 'huyện',
        short_codename: 'quoc_oai',
        children: [
          {
            title: 'Xã Đông Xuân',
            code: 4939,
            value: 'xa_dong_xuan',
            division_type: 'xã',
            short_codename: 'dong_xuan',
            parentId: 275,
          },
          {
            title: 'Thị trấn Quốc Oai',
            code: 9895,
            value: 'thi_tran_quoc_oai',
            division_type: 'thị trấn',
            short_codename: 'quoc_oai',
            parentId: 275,
          },
          {
            title: 'Xã Sài Sơn',
            code: 9898,
            value: 'xa_sai_son',
            division_type: 'xã',
            short_codename: 'sai_son',
            parentId: 275,
          },
          {
            title: 'Xã Phượng Cách',
            code: 9901,
            value: 'xa_phuong_cach',
            division_type: 'xã',
            short_codename: 'phuong_cach',
            parentId: 275,
          },
          {
            title: 'Xã Yên Sơn',
            code: 9904,
            value: 'xa_yen_son',
            division_type: 'xã',
            short_codename: 'yen_son',
            parentId: 275,
          },
          {
            title: 'Xã Ngọc Liệp',
            code: 9907,
            value: 'xa_ngoc_liep',
            division_type: 'xã',
            short_codename: 'ngoc_liep',
            parentId: 275,
          },
          {
            title: 'Xã Ngọc Mỹ',
            code: 9910,
            value: 'xa_ngoc_my',
            division_type: 'xã',
            short_codename: 'ngoc_my',
            parentId: 275,
          },
          {
            title: 'Xã Liệp Tuyết',
            code: 9913,
            value: 'xa_liep_tuyet',
            division_type: 'xã',
            short_codename: 'liep_tuyet',
            parentId: 275,
          },
          {
            title: 'Xã Thạch Thán',
            code: 9916,
            value: 'xa_thach_than',
            division_type: 'xã',
            short_codename: 'thach_than',
            parentId: 275,
          },
          {
            title: 'Xã Đồng Quang',
            code: 9919,
            value: 'xa_dong_quang',
            division_type: 'xã',
            short_codename: 'dong_quang',
            parentId: 275,
          },
          {
            title: 'Xã Phú Cát',
            code: 9922,
            value: 'xa_phu_cat',
            division_type: 'xã',
            short_codename: 'phu_cat',
            parentId: 275,
          },
          {
            title: 'Xã Tuyết Nghĩa',
            code: 9925,
            value: 'xa_tuyet_nghia',
            division_type: 'xã',
            short_codename: 'tuyet_nghia',
            parentId: 275,
          },
          {
            title: 'Xã Nghĩa Hương',
            code: 9928,
            value: 'xa_nghia_huong',
            division_type: 'xã',
            short_codename: 'nghia_huong',
            parentId: 275,
          },
          {
            title: 'Xã Cộng Hòa',
            code: 9931,
            value: 'xa_cong_hoa',
            division_type: 'xã',
            short_codename: 'cong_hoa',
            parentId: 275,
          },
          {
            title: 'Xã Tân Phú',
            code: 9934,
            value: 'xa_tan_phu',
            division_type: 'xã',
            short_codename: 'tan_phu',
            parentId: 275,
          },
          {
            title: 'Xã Đại Thành',
            code: 9937,
            value: 'xa_dai_thanh',
            division_type: 'xã',
            short_codename: 'dai_thanh',
            parentId: 275,
          },
          {
            title: 'Xã Phú Mãn',
            code: 9940,
            value: 'xa_phu_man',
            division_type: 'xã',
            short_codename: 'phu_man',
            parentId: 275,
          },
          {
            title: 'Xã Cấn Hữu',
            code: 9943,
            value: 'xa_can_huu',
            division_type: 'xã',
            short_codename: 'can_huu',
            parentId: 275,
          },
          {
            title: 'Xã Tân Hòa',
            code: 9946,
            value: 'xa_tan_hoa',
            division_type: 'xã',
            short_codename: 'tan_hoa',
            parentId: 275,
          },
          {
            title: 'Xã Hòa Thạch',
            code: 9949,
            value: 'xa_hoa_thach',
            division_type: 'xã',
            short_codename: 'hoa_thach',
            parentId: 275,
          },
          {
            title: 'Xã Đông Yên',
            code: 9952,
            value: 'xa_dong_yen',
            division_type: 'xã',
            short_codename: 'dong_yen',
            parentId: 275,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Huyện Thạch Thất',
        code: 276,
        value: 'huyen_thach_that',
        division_type: 'huyện',
        short_codename: 'thach_that',
        children: [
          {
            title: 'Xã Yên Trung',
            code: 4927,
            value: 'xa_yen_trung',
            division_type: 'xã',
            short_codename: 'yen_trung',
            parentId: 276,
          },
          {
            title: 'Xã Yên Bình',
            code: 4930,
            value: 'xa_yen_binh',
            division_type: 'xã',
            short_codename: 'yen_binh',
            parentId: 276,
          },
          {
            title: 'Xã Tiến Xuân',
            code: 4936,
            value: 'xa_tien_xuan',
            division_type: 'xã',
            short_codename: 'tien_xuan',
            parentId: 276,
          },
          {
            title: 'Thị trấn Liên Quan',
            code: 9955,
            value: 'thi_tran_lien_quan',
            division_type: 'thị trấn',
            short_codename: 'lien_quan',
            parentId: 276,
          },
          {
            title: 'Xã Đại Đồng',
            code: 9958,
            value: 'xa_dai_dong',
            division_type: 'xã',
            short_codename: 'dai_dong',
            parentId: 276,
          },
          {
            title: 'Xã Cẩm Yên',
            code: 9961,
            value: 'xa_cam_yen',
            division_type: 'xã',
            short_codename: 'cam_yen',
            parentId: 276,
          },
          {
            title: 'Xã Lại Thượng',
            code: 9964,
            value: 'xa_lai_thuong',
            division_type: 'xã',
            short_codename: 'lai_thuong',
            parentId: 276,
          },
          {
            title: 'Xã Phú Kim',
            code: 9967,
            value: 'xa_phu_kim',
            division_type: 'xã',
            short_codename: 'phu_kim',
            parentId: 276,
          },
          {
            title: 'Xã Hương Ngải',
            code: 9970,
            value: 'xa_huong_ngai',
            division_type: 'xã',
            short_codename: 'huong_ngai',
            parentId: 276,
          },
          {
            title: 'Xã Canh Nậu',
            code: 9973,
            value: 'xa_canh_nau',
            division_type: 'xã',
            short_codename: 'canh_nau',
            parentId: 276,
          },
          {
            title: 'Xã Kim Quan',
            code: 9976,
            value: 'xa_kim_quan',
            division_type: 'xã',
            short_codename: 'kim_quan',
            parentId: 276,
          },
          {
            title: 'Xã Dị Nậu',
            code: 9979,
            value: 'xa_di_nau',
            division_type: 'xã',
            short_codename: 'di_nau',
            parentId: 276,
          },
          {
            title: 'Xã Bình Yên',
            code: 9982,
            value: 'xa_binh_yen',
            division_type: 'xã',
            short_codename: 'binh_yen',
            parentId: 276,
          },
          {
            title: 'Xã Chàng Sơn',
            code: 9985,
            value: 'xa_chang_son',
            division_type: 'xã',
            short_codename: 'chang_son',
            parentId: 276,
          },
          {
            title: 'Xã Thạch Hoà',
            code: 9988,
            value: 'xa_thach_hoa',
            division_type: 'xã',
            short_codename: 'thach_hoa',
            parentId: 276,
          },
          {
            title: 'Xã Cần Kiệm',
            code: 9991,
            value: 'xa_can_kiem',
            division_type: 'xã',
            short_codename: 'can_kiem',
            parentId: 276,
          },
          {
            title: 'Xã Hữu Bằng',
            code: 9994,
            value: 'xa_huu_bang',
            division_type: 'xã',
            short_codename: 'huu_bang',
            parentId: 276,
          },
          {
            title: 'Xã Phùng Xá',
            code: 9997,
            value: 'xa_phung_xa',
            division_type: 'xã',
            short_codename: 'phung_xa',
            parentId: 276,
          },
          {
            title: 'Xã Tân Xã',
            code: 10000,
            value: 'xa_tan_xa',
            division_type: 'xã',
            short_codename: 'tan_xa',
            parentId: 276,
          },
          {
            title: 'Xã Thạch Xá',
            code: 10003,
            value: 'xa_thach_xa',
            division_type: 'xã',
            short_codename: 'thach_xa',
            parentId: 276,
          },
          {
            title: 'Xã Bình Phú',
            code: 10006,
            value: 'xa_binh_phu',
            division_type: 'xã',
            short_codename: 'binh_phu',
            parentId: 276,
          },
          {
            title: 'Xã Hạ Bằng',
            code: 10009,
            value: 'xa_ha_bang',
            division_type: 'xã',
            short_codename: 'ha_bang',
            parentId: 276,
          },
          {
            title: 'Xã Đồng Trúc',
            code: 10012,
            value: 'xa_dong_truc',
            division_type: 'xã',
            short_codename: 'dong_truc',
            parentId: 276,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Huyện Chương Mỹ',
        code: 277,
        value: 'huyen_chuong_my',
        division_type: 'huyện',
        short_codename: 'chuong_my',
        children: [
          {
            title: 'Thị trấn Chúc Sơn',
            code: 10015,
            value: 'thi_tran_chuc_son',
            division_type: 'thị trấn',
            short_codename: 'chuc_son',
            parentId: 277,
          },
          {
            title: 'Thị trấn Xuân Mai',
            code: 10018,
            value: 'thi_tran_xuan_mai',
            division_type: 'thị trấn',
            short_codename: 'xuan_mai',
            parentId: 277,
          },
          {
            title: 'Xã Phụng Châu',
            code: 10021,
            value: 'xa_phung_chau',
            division_type: 'xã',
            short_codename: 'phung_chau',
            parentId: 277,
          },
          {
            title: 'Xã Tiên Phương',
            code: 10024,
            value: 'xa_tien_phuong',
            division_type: 'xã',
            short_codename: 'tien_phuong',
            parentId: 277,
          },
          {
            title: 'Xã Đông Sơn',
            code: 10027,
            value: 'xa_dong_son',
            division_type: 'xã',
            short_codename: 'dong_son',
            parentId: 277,
          },
          {
            title: 'Xã Đông Phương Yên',
            code: 10030,
            value: 'xa_dong_phuong_yen',
            division_type: 'xã',
            short_codename: 'dong_phuong_yen',
            parentId: 277,
          },
          {
            title: 'Xã Phú Nghĩa',
            code: 10033,
            value: 'xa_phu_nghia',
            division_type: 'xã',
            short_codename: 'phu_nghia',
            parentId: 277,
          },
          {
            title: 'Xã Trường Yên',
            code: 10039,
            value: 'xa_truong_yen',
            division_type: 'xã',
            short_codename: 'truong_yen',
            parentId: 277,
          },
          {
            title: 'Xã Ngọc Hòa',
            code: 10042,
            value: 'xa_ngoc_hoa',
            division_type: 'xã',
            short_codename: 'ngoc_hoa',
            parentId: 277,
          },
          {
            title: 'Xã Thủy Xuân Tiên',
            code: 10045,
            value: 'xa_thuy_xuan_tien',
            division_type: 'xã',
            short_codename: 'thuy_xuan_tien',
            parentId: 277,
          },
          {
            title: 'Xã Thanh Bình',
            code: 10048,
            value: 'xa_thanh_binh',
            division_type: 'xã',
            short_codename: 'thanh_binh',
            parentId: 277,
          },
          {
            title: 'Xã Trung Hòa',
            code: 10051,
            value: 'xa_trung_hoa',
            division_type: 'xã',
            short_codename: 'trung_hoa',
            parentId: 277,
          },
          {
            title: 'Xã Đại Yên',
            code: 10054,
            value: 'xa_dai_yen',
            division_type: 'xã',
            short_codename: 'dai_yen',
            parentId: 277,
          },
          {
            title: 'Xã Thụy Hương',
            code: 10057,
            value: 'xa_thuy_huong',
            division_type: 'xã',
            short_codename: 'thuy_huong',
            parentId: 277,
          },
          {
            title: 'Xã Tốt Động',
            code: 10060,
            value: 'xa_tot_dong',
            division_type: 'xã',
            short_codename: 'tot_dong',
            parentId: 277,
          },
          {
            title: 'Xã Lam Điền',
            code: 10063,
            value: 'xa_lam_dien',
            division_type: 'xã',
            short_codename: 'lam_dien',
            parentId: 277,
          },
          {
            title: 'Xã Tân Tiến',
            code: 10066,
            value: 'xa_tan_tien',
            division_type: 'xã',
            short_codename: 'tan_tien',
            parentId: 277,
          },
          {
            title: 'Xã Nam Phương Tiến',
            code: 10069,
            value: 'xa_nam_phuong_tien',
            division_type: 'xã',
            short_codename: 'nam_phuong_tien',
            parentId: 277,
          },
          {
            title: 'Xã Hợp Đồng',
            code: 10072,
            value: 'xa_hop_dong',
            division_type: 'xã',
            short_codename: 'hop_dong',
            parentId: 277,
          },
          {
            title: 'Xã Hoàng Văn Thụ',
            code: 10075,
            value: 'xa_hoang_van_thu',
            division_type: 'xã',
            short_codename: 'hoang_van_thu',
            parentId: 277,
          },
          {
            title: 'Xã Hoàng Diệu',
            code: 10078,
            value: 'xa_hoang_dieu',
            division_type: 'xã',
            short_codename: 'hoang_dieu',
            parentId: 277,
          },
          {
            title: 'Xã Hữu Văn',
            code: 10081,
            value: 'xa_huu_van',
            division_type: 'xã',
            short_codename: 'huu_van',
            parentId: 277,
          },
          {
            title: 'Xã Quảng Bị',
            code: 10084,
            value: 'xa_quang_bi',
            division_type: 'xã',
            short_codename: 'quang_bi',
            parentId: 277,
          },
          {
            title: 'Xã Mỹ Lương',
            code: 10087,
            value: 'xa_my_luong',
            division_type: 'xã',
            short_codename: 'my_luong',
            parentId: 277,
          },
          {
            title: 'Xã Thượng Vực',
            code: 10090,
            value: 'xa_thuong_vuc',
            division_type: 'xã',
            short_codename: 'thuong_vuc',
            parentId: 277,
          },
          {
            title: 'Xã Hồng Phong',
            code: 10093,
            value: 'xa_hong_phong',
            division_type: 'xã',
            short_codename: 'hong_phong',
            parentId: 277,
          },
          {
            title: 'Xã Đồng Phú',
            code: 10096,
            value: 'xa_dong_phu',
            division_type: 'xã',
            short_codename: 'dong_phu',
            parentId: 277,
          },
          {
            title: 'Xã Trần Phú',
            code: 10099,
            value: 'xa_tran_phu',
            division_type: 'xã',
            short_codename: 'tran_phu',
            parentId: 277,
          },
          {
            title: 'Xã Văn Võ',
            code: 10102,
            value: 'xa_van_vo',
            division_type: 'xã',
            short_codename: 'van_vo',
            parentId: 277,
          },
          {
            title: 'Xã Đồng Lạc',
            code: 10105,
            value: 'xa_dong_lac',
            division_type: 'xã',
            short_codename: 'dong_lac',
            parentId: 277,
          },
          {
            title: 'Xã Hòa Chính',
            code: 10108,
            value: 'xa_hoa_chinh',
            division_type: 'xã',
            short_codename: 'hoa_chinh',
            parentId: 277,
          },
          {
            title: 'Xã Phú Nam An',
            code: 10111,
            value: 'xa_phu_nam_an',
            division_type: 'xã',
            short_codename: 'phu_nam_an',
            parentId: 277,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Huyện Thanh Oai',
        code: 278,
        value: 'huyen_thanh_oai',
        division_type: 'huyện',
        short_codename: 'thanh_oai',
        children: [
          {
            title: 'Thị trấn Kim Bài',
            code: 10114,
            value: 'thi_tran_kim_bai',
            division_type: 'thị trấn',
            short_codename: 'kim_bai',
            parentId: 278,
          },
          {
            title: 'Xã Cự Khê',
            code: 10120,
            value: 'xa_cu_khe',
            division_type: 'xã',
            short_codename: 'cu_khe',
            parentId: 278,
          },
          {
            title: 'Xã Bích Hòa',
            code: 10126,
            value: 'xa_bich_hoa',
            division_type: 'xã',
            short_codename: 'bich_hoa',
            parentId: 278,
          },
          {
            title: 'Xã Mỹ Hưng',
            code: 10129,
            value: 'xa_my_hung',
            division_type: 'xã',
            short_codename: 'my_hung',
            parentId: 278,
          },
          {
            title: 'Xã Cao Viên',
            code: 10132,
            value: 'xa_cao_vien',
            division_type: 'xã',
            short_codename: 'cao_vien',
            parentId: 278,
          },
          {
            title: 'Xã Bình Minh',
            code: 10135,
            value: 'xa_binh_minh',
            division_type: 'xã',
            short_codename: 'binh_minh',
            parentId: 278,
          },
          {
            title: 'Xã Tam Hưng',
            code: 10138,
            value: 'xa_tam_hung',
            division_type: 'xã',
            short_codename: 'tam_hung',
            parentId: 278,
          },
          {
            title: 'Xã Thanh Cao',
            code: 10141,
            value: 'xa_thanh_cao',
            division_type: 'xã',
            short_codename: 'thanh_cao',
            parentId: 278,
          },
          {
            title: 'Xã Thanh Thùy',
            code: 10144,
            value: 'xa_thanh_thuy',
            division_type: 'xã',
            short_codename: 'thanh_thuy',
            parentId: 278,
          },
          {
            title: 'Xã Thanh Mai',
            code: 10147,
            value: 'xa_thanh_mai',
            division_type: 'xã',
            short_codename: 'thanh_mai',
            parentId: 278,
          },
          {
            title: 'Xã Thanh Văn',
            code: 10150,
            value: 'xa_thanh_van',
            division_type: 'xã',
            short_codename: 'thanh_van',
            parentId: 278,
          },
          {
            title: 'Xã Đỗ Động',
            code: 10153,
            value: 'xa_do_dong',
            division_type: 'xã',
            short_codename: 'do_dong',
            parentId: 278,
          },
          {
            title: 'Xã Kim An',
            code: 10156,
            value: 'xa_kim_an',
            division_type: 'xã',
            short_codename: 'kim_an',
            parentId: 278,
          },
          {
            title: 'Xã Kim Thư',
            code: 10159,
            value: 'xa_kim_thu',
            division_type: 'xã',
            short_codename: 'kim_thu',
            parentId: 278,
          },
          {
            title: 'Xã Phương Trung',
            code: 10162,
            value: 'xa_phuong_trung',
            division_type: 'xã',
            short_codename: 'phuong_trung',
            parentId: 278,
          },
          {
            title: 'Xã Tân Ước',
            code: 10165,
            value: 'xa_tan_uoc',
            division_type: 'xã',
            short_codename: 'tan_uoc',
            parentId: 278,
          },
          {
            title: 'Xã Dân Hòa',
            code: 10168,
            value: 'xa_dan_hoa',
            division_type: 'xã',
            short_codename: 'dan_hoa',
            parentId: 278,
          },
          {
            title: 'Xã Liên Châu',
            code: 10171,
            value: 'xa_lien_chau',
            division_type: 'xã',
            short_codename: 'lien_chau',
            parentId: 278,
          },
          {
            title: 'Xã Cao Dương',
            code: 10174,
            value: 'xa_cao_duong',
            division_type: 'xã',
            short_codename: 'cao_duong',
            parentId: 278,
          },
          {
            title: 'Xã Xuân Dương',
            code: 10177,
            value: 'xa_xuan_duong',
            division_type: 'xã',
            short_codename: 'xuan_duong',
            parentId: 278,
          },
          {
            title: 'Xã Hồng Dương',
            code: 10180,
            value: 'xa_hong_duong',
            division_type: 'xã',
            short_codename: 'hong_duong',
            parentId: 278,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Huyện Thường Tín',
        code: 279,
        value: 'huyen_thuong_tin',
        division_type: 'huyện',
        short_codename: 'thuong_tin',
        children: [
          {
            title: 'Thị trấn Thường Tín',
            code: 10183,
            value: 'thi_tran_thuong_tin',
            division_type: 'thị trấn',
            short_codename: 'thuong_tin',
            parentId: 279,
          },
          {
            title: 'Xã Ninh Sở',
            code: 10186,
            value: 'xa_ninh_so',
            division_type: 'xã',
            short_codename: 'ninh_so',
            parentId: 279,
          },
          {
            title: 'Xã Nhị Khê',
            code: 10189,
            value: 'xa_nhi_khe',
            division_type: 'xã',
            short_codename: 'nhi_khe',
            parentId: 279,
          },
          {
            title: 'Xã Duyên Thái',
            code: 10192,
            value: 'xa_duyen_thai',
            division_type: 'xã',
            short_codename: 'duyen_thai',
            parentId: 279,
          },
          {
            title: 'Xã Khánh Hà',
            code: 10195,
            value: 'xa_khanh_ha',
            division_type: 'xã',
            short_codename: 'khanh_ha',
            parentId: 279,
          },
          {
            title: 'Xã Hòa Bình',
            code: 10198,
            value: 'xa_hoa_binh',
            division_type: 'xã',
            short_codename: 'hoa_binh',
            parentId: 279,
          },
          {
            title: 'Xã Văn Bình',
            code: 10201,
            value: 'xa_van_binh',
            division_type: 'xã',
            short_codename: 'van_binh',
            parentId: 279,
          },
          {
            title: 'Xã Hiền Giang',
            code: 10204,
            value: 'xa_hien_giang',
            division_type: 'xã',
            short_codename: 'hien_giang',
            parentId: 279,
          },
          {
            title: 'Xã Hồng Vân',
            code: 10207,
            value: 'xa_hong_van',
            division_type: 'xã',
            short_codename: 'hong_van',
            parentId: 279,
          },
          {
            title: 'Xã Vân Tảo',
            code: 10210,
            value: 'xa_van_tao',
            division_type: 'xã',
            short_codename: 'van_tao',
            parentId: 279,
          },
          {
            title: 'Xã Liên Phương',
            code: 10213,
            value: 'xa_lien_phuong',
            division_type: 'xã',
            short_codename: 'lien_phuong',
            parentId: 279,
          },
          {
            title: 'Xã Văn Phú',
            code: 10216,
            value: 'xa_van_phu',
            division_type: 'xã',
            short_codename: 'van_phu',
            parentId: 279,
          },
          {
            title: 'Xã Tự Nhiên',
            code: 10219,
            value: 'xa_tu_nhien',
            division_type: 'xã',
            short_codename: 'tu_nhien',
            parentId: 279,
          },
          {
            title: 'Xã Tiền Phong',
            code: 10222,
            value: 'xa_tien_phong',
            division_type: 'xã',
            short_codename: 'tien_phong',
            parentId: 279,
          },
          {
            title: 'Xã Hà Hồi',
            code: 10225,
            value: 'xa_ha_hoi',
            division_type: 'xã',
            short_codename: 'ha_hoi',
            parentId: 279,
          },
          {
            title: 'Xã Thư Phú',
            code: 10228,
            value: 'xa_thu_phu',
            division_type: 'xã',
            short_codename: 'thu_phu',
            parentId: 279,
          },
          {
            title: 'Xã Nguyễn Trãi',
            code: 10231,
            value: 'xa_nguyen_trai',
            division_type: 'xã',
            short_codename: 'nguyen_trai',
            parentId: 279,
          },
          {
            title: 'Xã Quất Động',
            code: 10234,
            value: 'xa_quat_dong',
            division_type: 'xã',
            short_codename: 'quat_dong',
            parentId: 279,
          },
          {
            title: 'Xã Chương Dương',
            code: 10237,
            value: 'xa_chuong_duong',
            division_type: 'xã',
            short_codename: 'chuong_duong',
            parentId: 279,
          },
          {
            title: 'Xã Tân Minh',
            code: 10240,
            value: 'xa_tan_minh',
            division_type: 'xã',
            short_codename: 'tan_minh',
            parentId: 279,
          },
          {
            title: 'Xã Lê Lợi',
            code: 10243,
            value: 'xa_le_loi',
            division_type: 'xã',
            short_codename: 'le_loi',
            parentId: 279,
          },
          {
            title: 'Xã Thắng Lợi',
            code: 10246,
            value: 'xa_thang_loi',
            division_type: 'xã',
            short_codename: 'thang_loi',
            parentId: 279,
          },
          {
            title: 'Xã Dũng Tiến',
            code: 10249,
            value: 'xa_dung_tien',
            division_type: 'xã',
            short_codename: 'dung_tien',
            parentId: 279,
          },
          {
            title: 'Xã Thống Nhất',
            code: 10252,
            value: 'xa_thong_nhat',
            division_type: 'xã',
            short_codename: 'thong_nhat',
            parentId: 279,
          },
          {
            title: 'Xã Nghiêm Xuyên',
            code: 10255,
            value: 'xa_nghiem_xuyen',
            division_type: 'xã',
            short_codename: 'nghiem_xuyen',
            parentId: 279,
          },
          {
            title: 'Xã Tô Hiệu',
            code: 10258,
            value: 'xa_to_hieu',
            division_type: 'xã',
            short_codename: 'to_hieu',
            parentId: 279,
          },
          {
            title: 'Xã Văn Tự',
            code: 10261,
            value: 'xa_van_tu',
            division_type: 'xã',
            short_codename: 'van_tu',
            parentId: 279,
          },
          {
            title: 'Xã Vạn Điểm',
            code: 10264,
            value: 'xa_van_diem',
            division_type: 'xã',
            short_codename: 'van_diem',
            parentId: 279,
          },
          {
            title: 'Xã Minh Cường',
            code: 10267,
            value: 'xa_minh_cuong',
            division_type: 'xã',
            short_codename: 'minh_cuong',
            parentId: 279,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Huyện Phú Xuyên',
        code: 280,
        value: 'huyen_phu_xuyen',
        division_type: 'huyện',
        short_codename: 'phu_xuyen',
        children: [
          {
            title: 'Thị trấn Phú Minh',
            code: 10270,
            value: 'thi_tran_phu_minh',
            division_type: 'thị trấn',
            short_codename: 'phu_minh',
            parentId: 280,
          },
          {
            title: 'Thị trấn Phú Xuyên',
            code: 10273,
            value: 'thi_tran_phu_xuyen',
            division_type: 'thị trấn',
            short_codename: 'phu_xuyen',
            parentId: 280,
          },
          {
            title: 'Xã Hồng Minh',
            code: 10276,
            value: 'xa_hong_minh',
            division_type: 'xã',
            short_codename: 'hong_minh',
            parentId: 280,
          },
          {
            title: 'Xã Phượng Dực',
            code: 10279,
            value: 'xa_phuong_duc',
            division_type: 'xã',
            short_codename: 'phuong_duc',
            parentId: 280,
          },
          {
            title: 'Xã Nam Tiến',
            code: 10282,
            value: 'xa_nam_tien',
            division_type: 'xã',
            short_codename: 'nam_tien',
            parentId: 280,
          },
          {
            title: 'Xã Tri Trung',
            code: 10288,
            value: 'xa_tri_trung',
            division_type: 'xã',
            short_codename: 'tri_trung',
            parentId: 280,
          },
          {
            title: 'Xã Đại Thắng',
            code: 10291,
            value: 'xa_dai_thang',
            division_type: 'xã',
            short_codename: 'dai_thang',
            parentId: 280,
          },
          {
            title: 'Xã Phú Túc',
            code: 10294,
            value: 'xa_phu_tuc',
            division_type: 'xã',
            short_codename: 'phu_tuc',
            parentId: 280,
          },
          {
            title: 'Xã Văn Hoàng',
            code: 10297,
            value: 'xa_van_hoang',
            division_type: 'xã',
            short_codename: 'van_hoang',
            parentId: 280,
          },
          {
            title: 'Xã Hồng Thái',
            code: 10300,
            value: 'xa_hong_thai',
            division_type: 'xã',
            short_codename: 'hong_thai',
            parentId: 280,
          },
          {
            title: 'Xã Hoàng Long',
            code: 10303,
            value: 'xa_hoang_long',
            division_type: 'xã',
            short_codename: 'hoang_long',
            parentId: 280,
          },
          {
            title: 'Xã Quang Trung',
            code: 10306,
            value: 'xa_quang_trung',
            division_type: 'xã',
            short_codename: 'quang_trung',
            parentId: 280,
          },
          {
            title: 'Xã Nam Phong',
            code: 10309,
            value: 'xa_nam_phong',
            division_type: 'xã',
            short_codename: 'nam_phong',
            parentId: 280,
          },
          {
            title: 'Xã Nam Triều',
            code: 10312,
            value: 'xa_nam_trieu',
            division_type: 'xã',
            short_codename: 'nam_trieu',
            parentId: 280,
          },
          {
            title: 'Xã Tân Dân',
            code: 10315,
            value: 'xa_tan_dan',
            division_type: 'xã',
            short_codename: 'tan_dan',
            parentId: 280,
          },
          {
            title: 'Xã Sơn Hà',
            code: 10318,
            value: 'xa_son_ha',
            division_type: 'xã',
            short_codename: 'son_ha',
            parentId: 280,
          },
          {
            title: 'Xã Chuyên Mỹ',
            code: 10321,
            value: 'xa_chuyen_my',
            division_type: 'xã',
            short_codename: 'chuyen_my',
            parentId: 280,
          },
          {
            title: 'Xã Khai Thái',
            code: 10324,
            value: 'xa_khai_thai',
            division_type: 'xã',
            short_codename: 'khai_thai',
            parentId: 280,
          },
          {
            title: 'Xã Phúc Tiến',
            code: 10327,
            value: 'xa_phuc_tien',
            division_type: 'xã',
            short_codename: 'phuc_tien',
            parentId: 280,
          },
          {
            title: 'Xã Vân Từ',
            code: 10330,
            value: 'xa_van_tu',
            division_type: 'xã',
            short_codename: 'van_tu',
            parentId: 280,
          },
          {
            title: 'Xã Tri Thủy',
            code: 10333,
            value: 'xa_tri_thuy',
            division_type: 'xã',
            short_codename: 'tri_thuy',
            parentId: 280,
          },
          {
            title: 'Xã Đại Xuyên',
            code: 10336,
            value: 'xa_dai_xuyen',
            division_type: 'xã',
            short_codename: 'dai_xuyen',
            parentId: 280,
          },
          {
            title: 'Xã Phú Yên',
            code: 10339,
            value: 'xa_phu_yen',
            division_type: 'xã',
            short_codename: 'phu_yen',
            parentId: 280,
          },
          {
            title: 'Xã Bạch Hạ',
            code: 10342,
            value: 'xa_bach_ha',
            division_type: 'xã',
            short_codename: 'bach_ha',
            parentId: 280,
          },
          {
            title: 'Xã Quang Lãng',
            code: 10345,
            value: 'xa_quang_lang',
            division_type: 'xã',
            short_codename: 'quang_lang',
            parentId: 280,
          },
          {
            title: 'Xã Châu Can',
            code: 10348,
            value: 'xa_chau_can',
            division_type: 'xã',
            short_codename: 'chau_can',
            parentId: 280,
          },
          {
            title: 'Xã Minh Tân',
            code: 10351,
            value: 'xa_minh_tan',
            division_type: 'xã',
            short_codename: 'minh_tan',
            parentId: 280,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Huyện Ứng Hòa',
        code: 281,
        value: 'huyen_ung_hoa',
        division_type: 'huyện',
        short_codename: 'ung_hoa',
        children: [
          {
            title: 'Thị trấn Vân Đình',
            code: 10354,
            value: 'thi_tran_van_dinh',
            division_type: 'thị trấn',
            short_codename: 'van_dinh',
            parentId: 281,
          },
          {
            title: 'Xã Viên An',
            code: 10357,
            value: 'xa_vien_an',
            division_type: 'xã',
            short_codename: 'vien_an',
            parentId: 281,
          },
          {
            title: 'Xã Viên Nội',
            code: 10360,
            value: 'xa_vien_noi',
            division_type: 'xã',
            short_codename: 'vien_noi',
            parentId: 281,
          },
          {
            title: 'Xã Hoa Sơn',
            code: 10363,
            value: 'xa_hoa_son',
            division_type: 'xã',
            short_codename: 'hoa_son',
            parentId: 281,
          },
          {
            title: 'Xã Quảng Phú Cầu',
            code: 10366,
            value: 'xa_quang_phu_cau',
            division_type: 'xã',
            short_codename: 'quang_phu_cau',
            parentId: 281,
          },
          {
            title: 'Xã Trường Thịnh',
            code: 10369,
            value: 'xa_truong_thinh',
            division_type: 'xã',
            short_codename: 'truong_thinh',
            parentId: 281,
          },
          {
            title: 'Xã Cao Thành',
            code: 10372,
            value: 'xa_cao_thanh',
            division_type: 'xã',
            short_codename: 'cao_thanh',
            parentId: 281,
          },
          {
            title: 'Xã Liên Bạt',
            code: 10375,
            value: 'xa_lien_bat',
            division_type: 'xã',
            short_codename: 'lien_bat',
            parentId: 281,
          },
          {
            title: 'Xã Sơn Công',
            code: 10378,
            value: 'xa_son_cong',
            division_type: 'xã',
            short_codename: 'son_cong',
            parentId: 281,
          },
          {
            title: 'Xã Đồng Tiến',
            code: 10381,
            value: 'xa_dong_tien',
            division_type: 'xã',
            short_codename: 'dong_tien',
            parentId: 281,
          },
          {
            title: 'Xã Phương Tú',
            code: 10384,
            value: 'xa_phuong_tu',
            division_type: 'xã',
            short_codename: 'phuong_tu',
            parentId: 281,
          },
          {
            title: 'Xã Trung Tú',
            code: 10387,
            value: 'xa_trung_tu',
            division_type: 'xã',
            short_codename: 'trung_tu',
            parentId: 281,
          },
          {
            title: 'Xã Đồng Tân',
            code: 10390,
            value: 'xa_dong_tan',
            division_type: 'xã',
            short_codename: 'dong_tan',
            parentId: 281,
          },
          {
            title: 'Xã Tảo Dương Văn',
            code: 10393,
            value: 'xa_tao_duong_van',
            division_type: 'xã',
            short_codename: 'tao_duong_van',
            parentId: 281,
          },
          {
            title: 'Xã Vạn Thái',
            code: 10396,
            value: 'xa_van_thai',
            division_type: 'xã',
            short_codename: 'van_thai',
            parentId: 281,
          },
          {
            title: 'Xã Minh Đức',
            code: 10399,
            value: 'xa_minh_duc',
            division_type: 'xã',
            short_codename: 'minh_duc',
            parentId: 281,
          },
          {
            title: 'Xã Hòa Lâm',
            code: 10402,
            value: 'xa_hoa_lam',
            division_type: 'xã',
            short_codename: 'hoa_lam',
            parentId: 281,
          },
          {
            title: 'Xã Hòa Xá',
            code: 10405,
            value: 'xa_hoa_xa',
            division_type: 'xã',
            short_codename: 'hoa_xa',
            parentId: 281,
          },
          {
            title: 'Xã Trầm Lộng',
            code: 10408,
            value: 'xa_tram_long',
            division_type: 'xã',
            short_codename: 'tram_long',
            parentId: 281,
          },
          {
            title: 'Xã Kim Đường',
            code: 10411,
            value: 'xa_kim_duong',
            division_type: 'xã',
            short_codename: 'kim_duong',
            parentId: 281,
          },
          {
            title: 'Xã Hòa Nam',
            code: 10414,
            value: 'xa_hoa_nam',
            division_type: 'xã',
            short_codename: 'hoa_nam',
            parentId: 281,
          },
          {
            title: 'Xã Hòa Phú',
            code: 10417,
            value: 'xa_hoa_phu',
            division_type: 'xã',
            short_codename: 'hoa_phu',
            parentId: 281,
          },
          {
            title: 'Xã Đội Bình',
            code: 10420,
            value: 'xa_doi_binh',
            division_type: 'xã',
            short_codename: 'doi_binh',
            parentId: 281,
          },
          {
            title: 'Xã Đại Hùng',
            code: 10423,
            value: 'xa_dai_hung',
            division_type: 'xã',
            short_codename: 'dai_hung',
            parentId: 281,
          },
          {
            title: 'Xã Đông Lỗ',
            code: 10426,
            value: 'xa_dong_lo',
            division_type: 'xã',
            short_codename: 'dong_lo',
            parentId: 281,
          },
          {
            title: 'Xã Phù Lưu',
            code: 10429,
            value: 'xa_phu_luu',
            division_type: 'xã',
            short_codename: 'phu_luu',
            parentId: 281,
          },
          {
            title: 'Xã Đại Cường',
            code: 10432,
            value: 'xa_dai_cuong',
            division_type: 'xã',
            short_codename: 'dai_cuong',
            parentId: 281,
          },
          {
            title: 'Xã Lưu Hoàng',
            code: 10435,
            value: 'xa_luu_hoang',
            division_type: 'xã',
            short_codename: 'luu_hoang',
            parentId: 281,
          },
          {
            title: 'Xã Hồng Quang',
            code: 10438,
            value: 'xa_hong_quang',
            division_type: 'xã',
            short_codename: 'hong_quang',
            parentId: 281,
          },
        ],
        parentId: 1,
      },
      {
        title: 'Huyện Mỹ Đức',
        code: 282,
        value: 'huyen_my_duc',
        division_type: 'huyện',
        short_codename: 'my_duc',
        children: [
          {
            title: 'Thị trấn Đại Nghĩa',
            code: 10441,
            value: 'thi_tran_dai_nghia',
            division_type: 'thị trấn',
            short_codename: 'dai_nghia',
            parentId: 282,
          },
          {
            title: 'Xã Đồng Tâm',
            code: 10444,
            value: 'xa_dong_tam',
            division_type: 'xã',
            short_codename: 'dong_tam',
            parentId: 282,
          },
          {
            title: 'Xã Thượng Lâm',
            code: 10447,
            value: 'xa_thuong_lam',
            division_type: 'xã',
            short_codename: 'thuong_lam',
            parentId: 282,
          },
          {
            title: 'Xã Tuy Lai',
            code: 10450,
            value: 'xa_tuy_lai',
            division_type: 'xã',
            short_codename: 'tuy_lai',
            parentId: 282,
          },
          {
            title: 'Xã Phúc Lâm',
            code: 10453,
            value: 'xa_phuc_lam',
            division_type: 'xã',
            short_codename: 'phuc_lam',
            parentId: 282,
          },
          {
            title: 'Xã Mỹ Thành',
            code: 10456,
            value: 'xa_my_thanh',
            division_type: 'xã',
            short_codename: 'my_thanh',
            parentId: 282,
          },
          {
            title: 'Xã Bột Xuyên',
            code: 10459,
            value: 'xa_bot_xuyen',
            division_type: 'xã',
            short_codename: 'bot_xuyen',
            parentId: 282,
          },
          {
            title: 'Xã An Mỹ',
            code: 10462,
            value: 'xa_an_my',
            division_type: 'xã',
            short_codename: 'an_my',
            parentId: 282,
          },
          {
            title: 'Xã Hồng Sơn',
            code: 10465,
            value: 'xa_hong_son',
            division_type: 'xã',
            short_codename: 'hong_son',
            parentId: 282,
          },
          {
            title: 'Xã Lê Thanh',
            code: 10468,
            value: 'xa_le_thanh',
            division_type: 'xã',
            short_codename: 'le_thanh',
            parentId: 282,
          },
          {
            title: 'Xã Xuy Xá',
            code: 10471,
            value: 'xa_xuy_xa',
            division_type: 'xã',
            short_codename: 'xuy_xa',
            parentId: 282,
          },
          {
            title: 'Xã Phùng Xá',
            code: 10474,
            value: 'xa_phung_xa',
            division_type: 'xã',
            short_codename: 'phung_xa',
            parentId: 282,
          },
          {
            title: 'Xã Phù Lưu Tế',
            code: 10477,
            value: 'xa_phu_luu_te',
            division_type: 'xã',
            short_codename: 'phu_luu_te',
            parentId: 282,
          },
          {
            title: 'Xã Đại Hưng',
            code: 10480,
            value: 'xa_dai_hung',
            division_type: 'xã',
            short_codename: 'dai_hung',
            parentId: 282,
          },
          {
            title: 'Xã Vạn Kim',
            code: 10483,
            value: 'xa_van_kim',
            division_type: 'xã',
            short_codename: 'van_kim',
            parentId: 282,
          },
          {
            title: 'Xã Đốc Tín',
            code: 10486,
            value: 'xa_doc_tin',
            division_type: 'xã',
            short_codename: 'doc_tin',
            parentId: 282,
          },
          {
            title: 'Xã Hương Sơn',
            code: 10489,
            value: 'xa_huong_son',
            division_type: 'xã',
            short_codename: 'huong_son',
            parentId: 282,
          },
          {
            title: 'Xã Hùng Tiến',
            code: 10492,
            value: 'xa_hung_tien',
            division_type: 'xã',
            short_codename: 'hung_tien',
            parentId: 282,
          },
          {
            title: 'Xã An Tiến',
            code: 10495,
            value: 'xa_an_tien',
            division_type: 'xã',
            short_codename: 'an_tien',
            parentId: 282,
          },
          {
            title: 'Xã Hợp Tiến',
            code: 10498,
            value: 'xa_hop_tien',
            division_type: 'xã',
            short_codename: 'hop_tien',
            parentId: 282,
          },
          {
            title: 'Xã Hợp Thanh',
            code: 10501,
            value: 'xa_hop_thanh',
            division_type: 'xã',
            short_codename: 'hop_thanh',
            parentId: 282,
          },
          {
            title: 'Xã An Phú',
            code: 10504,
            value: 'xa_an_phu',
            division_type: 'xã',
            short_codename: 'an_phu',
            parentId: 282,
          },
        ],
        parentId: 1,
      },
    ],
  },
  {
    title: 'Tỉnh Hà Giang',
    code: 2,
    value: 'tinh_ha_giang',
    division_type: 'tỉnh',
    phone_code: 219,
    children: [
      {
        title: 'Thành phố Hà Giang',
        code: 24,
        value: 'thanh_pho_ha_giang',
        division_type: 'thành phố',
        short_codename: 'ha_giang',
        children: [
          {
            title: 'Phường Quang Trung',
            code: 688,
            value: 'phuong_quang_trung',
            division_type: 'phường',
            short_codename: 'quang_trung',
            parentId: 24,
          },
          {
            title: 'Phường Trần Phú',
            code: 691,
            value: 'phuong_tran_phu',
            division_type: 'phường',
            short_codename: 'tran_phu',
            parentId: 24,
          },
          {
            title: 'Phường Ngọc Hà',
            code: 692,
            value: 'phuong_ngoc_ha',
            division_type: 'phường',
            short_codename: 'ngoc_ha',
            parentId: 24,
          },
          {
            title: 'Phường Nguyễn Trãi',
            code: 694,
            value: 'phuong_nguyen_trai',
            division_type: 'phường',
            short_codename: 'nguyen_trai',
            parentId: 24,
          },
          {
            title: 'Phường Minh Khai',
            code: 697,
            value: 'phuong_minh_khai',
            division_type: 'phường',
            short_codename: 'minh_khai',
            parentId: 24,
          },
          {
            title: 'Xã Ngọc Đường',
            code: 700,
            value: 'xa_ngoc_duong',
            division_type: 'xã',
            short_codename: 'ngoc_duong',
            parentId: 24,
          },
          {
            title: 'Xã Phương Độ',
            code: 946,
            value: 'xa_phuong_do',
            division_type: 'xã',
            short_codename: 'phuong_do',
            parentId: 24,
          },
          {
            title: 'Xã Phương Thiện',
            code: 949,
            value: 'xa_phuong_thien',
            division_type: 'xã',
            short_codename: 'phuong_thien',
            parentId: 24,
          },
        ],
        parentId: 2,
      },
      {
        title: 'Huyện Đồng Văn',
        code: 26,
        value: 'huyen_dong_van',
        division_type: 'huyện',
        short_codename: 'dong_van',
        children: [
          {
            title: 'Thị trấn Phó Bảng',
            code: 712,
            value: 'thi_tran_pho_bang',
            division_type: 'thị trấn',
            short_codename: 'pho_bang',
            parentId: 26,
          },
          {
            title: 'Xã Lũng Cú',
            code: 715,
            value: 'xa_lung_cu',
            division_type: 'xã',
            short_codename: 'lung_cu',
            parentId: 26,
          },
          {
            title: 'Xã Má Lé',
            code: 718,
            value: 'xa_ma_le',
            division_type: 'xã',
            short_codename: 'ma_le',
            parentId: 26,
          },
          {
            title: 'Thị trấn Đồng Văn',
            code: 721,
            value: 'thi_tran_dong_van',
            division_type: 'thị trấn',
            short_codename: 'dong_van',
            parentId: 26,
          },
          {
            title: 'Xã Lũng Táo',
            code: 724,
            value: 'xa_lung_tao',
            division_type: 'xã',
            short_codename: 'lung_tao',
            parentId: 26,
          },
          {
            title: 'Xã Phố Là',
            code: 727,
            value: 'xa_pho_la',
            division_type: 'xã',
            short_codename: 'pho_la',
            parentId: 26,
          },
          {
            title: 'Xã Thài Phìn Tủng',
            code: 730,
            value: 'xa_thai_phin_tung',
            division_type: 'xã',
            short_codename: 'thai_phin_tung',
            parentId: 26,
          },
          {
            title: 'Xã Sủng Là',
            code: 733,
            value: 'xa_sung_la',
            division_type: 'xã',
            short_codename: 'sung_la',
            parentId: 26,
          },
          {
            title: 'Xã Xà Phìn',
            code: 736,
            value: 'xa_xa_phin',
            division_type: 'xã',
            short_codename: 'xa_phin',
            parentId: 26,
          },
          {
            title: 'Xã Tả Phìn',
            code: 739,
            value: 'xa_ta_phin',
            division_type: 'xã',
            short_codename: 'ta_phin',
            parentId: 26,
          },
          {
            title: 'Xã Tả Lủng',
            code: 742,
            value: 'xa_ta_lung',
            division_type: 'xã',
            short_codename: 'ta_lung',
            parentId: 26,
          },
          {
            title: 'Xã Phố Cáo',
            code: 745,
            value: 'xa_pho_cao',
            division_type: 'xã',
            short_codename: 'pho_cao',
            parentId: 26,
          },
          {
            title: 'Xã Sính Lủng',
            code: 748,
            value: 'xa_sinh_lung',
            division_type: 'xã',
            short_codename: 'sinh_lung',
            parentId: 26,
          },
          {
            title: 'Xã Sảng Tủng',
            code: 751,
            value: 'xa_sang_tung',
            division_type: 'xã',
            short_codename: 'sang_tung',
            parentId: 26,
          },
          {
            title: 'Xã Lũng Thầu',
            code: 754,
            value: 'xa_lung_thau',
            division_type: 'xã',
            short_codename: 'lung_thau',
            parentId: 26,
          },
          {
            title: 'Xã Hố Quáng Phìn',
            code: 757,
            value: 'xa_ho_quang_phin',
            division_type: 'xã',
            short_codename: 'ho_quang_phin',
            parentId: 26,
          },
          {
            title: 'Xã Vần Chải',
            code: 760,
            value: 'xa_van_chai',
            division_type: 'xã',
            short_codename: 'van_chai',
            parentId: 26,
          },
          {
            title: 'Xã Lũng Phìn',
            code: 763,
            value: 'xa_lung_phin',
            division_type: 'xã',
            short_codename: 'lung_phin',
            parentId: 26,
          },
          {
            title: 'Xã Sủng Trái',
            code: 766,
            value: 'xa_sung_trai',
            division_type: 'xã',
            short_codename: 'sung_trai',
            parentId: 26,
          },
        ],
        parentId: 2,
      },
      {
        title: 'Huyện Mèo Vạc',
        code: 27,
        value: 'huyen_meo_vac',
        division_type: 'huyện',
        short_codename: 'meo_vac',
        children: [
          {
            title: 'Thị trấn Mèo Vạc',
            code: 769,
            value: 'thi_tran_meo_vac',
            division_type: 'thị trấn',
            short_codename: 'meo_vac',
            parentId: 27,
          },
          {
            title: 'Xã Thượng Phùng',
            code: 772,
            value: 'xa_thuong_phung',
            division_type: 'xã',
            short_codename: 'thuong_phung',
            parentId: 27,
          },
          {
            title: 'Xã Pải Lủng',
            code: 775,
            value: 'xa_pai_lung',
            division_type: 'xã',
            short_codename: 'pai_lung',
            parentId: 27,
          },
          {
            title: 'Xã Xín Cái',
            code: 778,
            value: 'xa_xin_cai',
            division_type: 'xã',
            short_codename: 'xin_cai',
            parentId: 27,
          },
          {
            title: 'Xã Pả Vi',
            code: 781,
            value: 'xa_pa_vi',
            division_type: 'xã',
            short_codename: 'pa_vi',
            parentId: 27,
          },
          {
            title: 'Xã Giàng Chu Phìn',
            code: 784,
            value: 'xa_giang_chu_phin',
            division_type: 'xã',
            short_codename: 'giang_chu_phin',
            parentId: 27,
          },
          {
            title: 'Xã Sủng Trà',
            code: 787,
            value: 'xa_sung_tra',
            division_type: 'xã',
            short_codename: 'sung_tra',
            parentId: 27,
          },
          {
            title: 'Xã Sủng Máng',
            code: 790,
            value: 'xa_sung_mang',
            division_type: 'xã',
            short_codename: 'sung_mang',
            parentId: 27,
          },
          {
            title: 'Xã Sơn Vĩ',
            code: 793,
            value: 'xa_son_vi',
            division_type: 'xã',
            short_codename: 'son_vi',
            parentId: 27,
          },
          {
            title: 'Xã Tả Lủng',
            code: 796,
            value: 'xa_ta_lung',
            division_type: 'xã',
            short_codename: 'ta_lung',
            parentId: 27,
          },
          {
            title: 'Xã Cán Chu Phìn',
            code: 799,
            value: 'xa_can_chu_phin',
            division_type: 'xã',
            short_codename: 'can_chu_phin',
            parentId: 27,
          },
          {
            title: 'Xã Lũng Pù',
            code: 802,
            value: 'xa_lung_pu',
            division_type: 'xã',
            short_codename: 'lung_pu',
            parentId: 27,
          },
          {
            title: 'Xã Lũng Chinh',
            code: 805,
            value: 'xa_lung_chinh',
            division_type: 'xã',
            short_codename: 'lung_chinh',
            parentId: 27,
          },
          {
            title: 'Xã Tát Ngà',
            code: 808,
            value: 'xa_tat_nga',
            division_type: 'xã',
            short_codename: 'tat_nga',
            parentId: 27,
          },
          {
            title: 'Xã Nậm Ban',
            code: 811,
            value: 'xa_nam_ban',
            division_type: 'xã',
            short_codename: 'nam_ban',
            parentId: 27,
          },
          {
            title: 'Xã Khâu Vai',
            code: 814,
            value: 'xa_khau_vai',
            division_type: 'xã',
            short_codename: 'khau_vai',
            parentId: 27,
          },
          {
            title: 'Xã Niêm Tòng',
            code: 815,
            value: 'xa_niem_tong',
            division_type: 'xã',
            short_codename: 'niem_tong',
            parentId: 27,
          },
          {
            title: 'Xã Niêm Sơn',
            code: 817,
            value: 'xa_niem_son',
            division_type: 'xã',
            short_codename: 'niem_son',
            parentId: 27,
          },
        ],
        parentId: 2,
      },
      {
        title: 'Huyện Yên Minh',
        code: 28,
        value: 'huyen_yen_minh',
        division_type: 'huyện',
        short_codename: 'yen_minh',
        children: [
          {
            title: 'Thị trấn Yên Minh',
            code: 820,
            value: 'thi_tran_yen_minh',
            division_type: 'thị trấn',
            short_codename: 'yen_minh',
            parentId: 28,
          },
          {
            title: 'Xã Thắng Mố',
            code: 823,
            value: 'xa_thang_mo',
            division_type: 'xã',
            short_codename: 'thang_mo',
            parentId: 28,
          },
          {
            title: 'Xã Phú Lũng',
            code: 826,
            value: 'xa_phu_lung',
            division_type: 'xã',
            short_codename: 'phu_lung',
            parentId: 28,
          },
          {
            title: 'Xã Sủng Tráng',
            code: 829,
            value: 'xa_sung_trang',
            division_type: 'xã',
            short_codename: 'sung_trang',
            parentId: 28,
          },
          {
            title: 'Xã Bạch Đích',
            code: 832,
            value: 'xa_bach_dich',
            division_type: 'xã',
            short_codename: 'bach_dich',
            parentId: 28,
          },
          {
            title: 'Xã Na Khê',
            code: 835,
            value: 'xa_na_khe',
            division_type: 'xã',
            short_codename: 'na_khe',
            parentId: 28,
          },
          {
            title: 'Xã Sủng Thài',
            code: 838,
            value: 'xa_sung_thai',
            division_type: 'xã',
            short_codename: 'sung_thai',
            parentId: 28,
          },
          {
            title: 'Xã Hữu Vinh',
            code: 841,
            value: 'xa_huu_vinh',
            division_type: 'xã',
            short_codename: 'huu_vinh',
            parentId: 28,
          },
          {
            title: 'Xã Lao Và Chải',
            code: 844,
            value: 'xa_lao_va_chai',
            division_type: 'xã',
            short_codename: 'lao_va_chai',
            parentId: 28,
          },
          {
            title: 'Xã Mậu Duệ',
            code: 847,
            value: 'xa_mau_due',
            division_type: 'xã',
            short_codename: 'mau_due',
            parentId: 28,
          },
          {
            title: 'Xã Đông Minh',
            code: 850,
            value: 'xa_dong_minh',
            division_type: 'xã',
            short_codename: 'dong_minh',
            parentId: 28,
          },
          {
            title: 'Xã Mậu Long',
            code: 853,
            value: 'xa_mau_long',
            division_type: 'xã',
            short_codename: 'mau_long',
            parentId: 28,
          },
          {
            title: 'Xã Ngam La',
            code: 856,
            value: 'xa_ngam_la',
            division_type: 'xã',
            short_codename: 'ngam_la',
            parentId: 28,
          },
          {
            title: 'Xã Ngọc Long',
            code: 859,
            value: 'xa_ngoc_long',
            division_type: 'xã',
            short_codename: 'ngoc_long',
            parentId: 28,
          },
          {
            title: 'Xã Đường Thượng',
            code: 862,
            value: 'xa_duong_thuong',
            division_type: 'xã',
            short_codename: 'duong_thuong',
            parentId: 28,
          },
          {
            title: 'Xã Lũng Hồ',
            code: 865,
            value: 'xa_lung_ho',
            division_type: 'xã',
            short_codename: 'lung_ho',
            parentId: 28,
          },
          {
            title: 'Xã Du Tiến',
            code: 868,
            value: 'xa_du_tien',
            division_type: 'xã',
            short_codename: 'du_tien',
            parentId: 28,
          },
          {
            title: 'Xã Du Già',
            code: 871,
            value: 'xa_du_gia',
            division_type: 'xã',
            short_codename: 'du_gia',
            parentId: 28,
          },
        ],
        parentId: 2,
      },
      {
        title: 'Huyện Quản Bạ',
        code: 29,
        value: 'huyen_quan_ba',
        division_type: 'huyện',
        short_codename: 'quan_ba',
        children: [
          {
            title: 'Thị trấn Tam Sơn',
            code: 874,
            value: 'thi_tran_tam_son',
            division_type: 'thị trấn',
            short_codename: 'tam_son',
            parentId: 29,
          },
          {
            title: 'Xã Bát Đại Sơn',
            code: 877,
            value: 'xa_bat_dai_son',
            division_type: 'xã',
            short_codename: 'bat_dai_son',
            parentId: 29,
          },
          {
            title: 'Xã Nghĩa Thuận',
            code: 880,
            value: 'xa_nghia_thuan',
            division_type: 'xã',
            short_codename: 'nghia_thuan',
            parentId: 29,
          },
          {
            title: 'Xã Cán Tỷ',
            code: 883,
            value: 'xa_can_ty',
            division_type: 'xã',
            short_codename: 'can_ty',
            parentId: 29,
          },
          {
            title: 'Xã Cao Mã Pờ',
            code: 886,
            value: 'xa_cao_ma_po',
            division_type: 'xã',
            short_codename: 'cao_ma_po',
            parentId: 29,
          },
          {
            title: 'Xã Thanh Vân',
            code: 889,
            value: 'xa_thanh_van',
            division_type: 'xã',
            short_codename: 'thanh_van',
            parentId: 29,
          },
          {
            title: 'Xã Tùng Vài',
            code: 892,
            value: 'xa_tung_vai',
            division_type: 'xã',
            short_codename: 'tung_vai',
            parentId: 29,
          },
          {
            title: 'Xã Đông Hà',
            code: 895,
            value: 'xa_dong_ha',
            division_type: 'xã',
            short_codename: 'dong_ha',
            parentId: 29,
          },
          {
            title: 'Xã Quản Bạ',
            code: 898,
            value: 'xa_quan_ba',
            division_type: 'xã',
            short_codename: 'quan_ba',
            parentId: 29,
          },
          {
            title: 'Xã Lùng Tám',
            code: 901,
            value: 'xa_lung_tam',
            division_type: 'xã',
            short_codename: 'lung_tam',
            parentId: 29,
          },
          {
            title: 'Xã Quyết Tiến',
            code: 904,
            value: 'xa_quyet_tien',
            division_type: 'xã',
            short_codename: 'quyet_tien',
            parentId: 29,
          },
          {
            title: 'Xã Tả Ván',
            code: 907,
            value: 'xa_ta_van',
            division_type: 'xã',
            short_codename: 'ta_van',
            parentId: 29,
          },
          {
            title: 'Xã Thái An',
            code: 910,
            value: 'xa_thai_an',
            division_type: 'xã',
            short_codename: 'thai_an',
            parentId: 29,
          },
        ],
        parentId: 2,
      },
      {
        title: 'Huyện Vị Xuyên',
        code: 30,
        value: 'huyen_vi_xuyen',
        division_type: 'huyện',
        short_codename: 'vi_xuyen',
        children: [
          {
            title: 'Xã Kim Thạch',
            code: 703,
            value: 'xa_kim_thach',
            division_type: 'xã',
            short_codename: 'kim_thach',
            parentId: 30,
          },
          {
            title: 'Xã Phú Linh',
            code: 706,
            value: 'xa_phu_linh',
            division_type: 'xã',
            short_codename: 'phu_linh',
            parentId: 30,
          },
          {
            title: 'Xã Kim Linh',
            code: 709,
            value: 'xa_kim_linh',
            division_type: 'xã',
            short_codename: 'kim_linh',
            parentId: 30,
          },
          {
            title: 'Thị trấn Vị Xuyên',
            code: 913,
            value: 'thi_tran_vi_xuyen',
            division_type: 'thị trấn',
            short_codename: 'vi_xuyen',
            parentId: 30,
          },
          {
            title: 'Thị trấn Nông Trường Việt Lâm',
            code: 916,
            value: 'thi_tran_nong_truong_viet_lam',
            division_type: 'thị trấn',
            short_codename: 'nong_truong_viet_lam',
            parentId: 30,
          },
          {
            title: 'Xã Minh Tân',
            code: 919,
            value: 'xa_minh_tan',
            division_type: 'xã',
            short_codename: 'minh_tan',
            parentId: 30,
          },
          {
            title: 'Xã Thuận Hoà',
            code: 922,
            value: 'xa_thuan_hoa',
            division_type: 'xã',
            short_codename: 'thuan_hoa',
            parentId: 30,
          },
          {
            title: 'Xã Tùng Bá',
            code: 925,
            value: 'xa_tung_ba',
            division_type: 'xã',
            short_codename: 'tung_ba',
            parentId: 30,
          },
          {
            title: 'Xã Thanh Thủy',
            code: 928,
            value: 'xa_thanh_thuy',
            division_type: 'xã',
            short_codename: 'thanh_thuy',
            parentId: 30,
          },
          {
            title: 'Xã Thanh Đức',
            code: 931,
            value: 'xa_thanh_duc',
            division_type: 'xã',
            short_codename: 'thanh_duc',
            parentId: 30,
          },
          {
            title: 'Xã Phong Quang',
            code: 934,
            value: 'xa_phong_quang',
            division_type: 'xã',
            short_codename: 'phong_quang',
            parentId: 30,
          },
          {
            title: 'Xã Xín Chải',
            code: 937,
            value: 'xa_xin_chai',
            division_type: 'xã',
            short_codename: 'xin_chai',
            parentId: 30,
          },
          {
            title: 'Xã Phương Tiến',
            code: 940,
            value: 'xa_phuong_tien',
            division_type: 'xã',
            short_codename: 'phuong_tien',
            parentId: 30,
          },
          {
            title: 'Xã Lao Chải',
            code: 943,
            value: 'xa_lao_chai',
            division_type: 'xã',
            short_codename: 'lao_chai',
            parentId: 30,
          },
          {
            title: 'Xã Cao Bồ',
            code: 952,
            value: 'xa_cao_bo',
            division_type: 'xã',
            short_codename: 'cao_bo',
            parentId: 30,
          },
          {
            title: 'Xã Đạo Đức',
            code: 955,
            value: 'xa_dao_duc',
            division_type: 'xã',
            short_codename: 'dao_duc',
            parentId: 30,
          },
          {
            title: 'Xã Thượng Sơn',
            code: 958,
            value: 'xa_thuong_son',
            division_type: 'xã',
            short_codename: 'thuong_son',
            parentId: 30,
          },
          {
            title: 'Xã Linh Hồ',
            code: 961,
            value: 'xa_linh_ho',
            division_type: 'xã',
            short_codename: 'linh_ho',
            parentId: 30,
          },
          {
            title: 'Xã Quảng Ngần',
            code: 964,
            value: 'xa_quang_ngan',
            division_type: 'xã',
            short_codename: 'quang_ngan',
            parentId: 30,
          },
          {
            title: 'Xã Việt Lâm',
            code: 967,
            value: 'xa_viet_lam',
            division_type: 'xã',
            short_codename: 'viet_lam',
            parentId: 30,
          },
          {
            title: 'Xã Ngọc Linh',
            code: 970,
            value: 'xa_ngoc_linh',
            division_type: 'xã',
            short_codename: 'ngoc_linh',
            parentId: 30,
          },
          {
            title: 'Xã Ngọc Minh',
            code: 973,
            value: 'xa_ngoc_minh',
            division_type: 'xã',
            short_codename: 'ngoc_minh',
            parentId: 30,
          },
          {
            title: 'Xã Bạch Ngọc',
            code: 976,
            value: 'xa_bach_ngoc',
            division_type: 'xã',
            short_codename: 'bach_ngoc',
            parentId: 30,
          },
          {
            title: 'Xã Trung Thành',
            code: 979,
            value: 'xa_trung_thanh',
            division_type: 'xã',
            short_codename: 'trung_thanh',
            parentId: 30,
          },
        ],
        parentId: 2,
      },
      {
        title: 'Huyện Bắc Mê',
        code: 31,
        value: 'huyen_bac_me',
        division_type: 'huyện',
        short_codename: 'bac_me',
        children: [
          {
            title: 'Xã Minh Sơn',
            code: 982,
            value: 'xa_minh_son',
            division_type: 'xã',
            short_codename: 'minh_son',
            parentId: 31,
          },
          {
            title: 'Xã Giáp Trung',
            code: 985,
            value: 'xa_giap_trung',
            division_type: 'xã',
            short_codename: 'giap_trung',
            parentId: 31,
          },
          {
            title: 'Xã Yên Định',
            code: 988,
            value: 'xa_yen_dinh',
            division_type: 'xã',
            short_codename: 'yen_dinh',
            parentId: 31,
          },
          {
            title: 'Thị trấn Yên Phú',
            code: 991,
            value: 'thi_tran_yen_phu',
            division_type: 'thị trấn',
            short_codename: 'yen_phu',
            parentId: 31,
          },
          {
            title: 'Xã Minh Ngọc',
            code: 994,
            value: 'xa_minh_ngoc',
            division_type: 'xã',
            short_codename: 'minh_ngoc',
            parentId: 31,
          },
          {
            title: 'Xã Yên Phong',
            code: 997,
            value: 'xa_yen_phong',
            division_type: 'xã',
            short_codename: 'yen_phong',
            parentId: 31,
          },
          {
            title: 'Xã Lạc Nông',
            code: 1000,
            value: 'xa_lac_nong',
            division_type: 'xã',
            short_codename: 'lac_nong',
            parentId: 31,
          },
          {
            title: 'Xã Phú Nam',
            code: 1003,
            value: 'xa_phu_nam',
            division_type: 'xã',
            short_codename: 'phu_nam',
            parentId: 31,
          },
          {
            title: 'Xã Yên Cường',
            code: 1006,
            value: 'xa_yen_cuong',
            division_type: 'xã',
            short_codename: 'yen_cuong',
            parentId: 31,
          },
          {
            title: 'Xã Thượng Tân',
            code: 1009,
            value: 'xa_thuong_tan',
            division_type: 'xã',
            short_codename: 'thuong_tan',
            parentId: 31,
          },
          {
            title: 'Xã Đường Âm',
            code: 1012,
            value: 'xa_duong_am',
            division_type: 'xã',
            short_codename: 'duong_am',
            parentId: 31,
          },
          {
            title: 'Xã Đường Hồng',
            code: 1015,
            value: 'xa_duong_hong',
            division_type: 'xã',
            short_codename: 'duong_hong',
            parentId: 31,
          },
          {
            title: 'Xã Phiêng Luông',
            code: 1018,
            value: 'xa_phieng_luong',
            division_type: 'xã',
            short_codename: 'phieng_luong',
            parentId: 31,
          },
        ],
        parentId: 2,
      },
      {
        title: 'Huyện Hoàng Su Phì',
        code: 32,
        value: 'huyen_hoang_su_phi',
        division_type: 'huyện',
        short_codename: 'hoang_su_phi',
        children: [
          {
            title: 'Thị trấn Vinh Quang',
            code: 1021,
            value: 'thi_tran_vinh_quang',
            division_type: 'thị trấn',
            short_codename: 'vinh_quang',
            parentId: 32,
          },
          {
            title: 'Xã Bản Máy',
            code: 1024,
            value: 'xa_ban_may',
            division_type: 'xã',
            short_codename: 'ban_may',
            parentId: 32,
          },
          {
            title: 'Xã Thàng Tín',
            code: 1027,
            value: 'xa_thang_tin',
            division_type: 'xã',
            short_codename: 'thang_tin',
            parentId: 32,
          },
          {
            title: 'Xã Thèn Chu Phìn',
            code: 1030,
            value: 'xa_then_chu_phin',
            division_type: 'xã',
            short_codename: 'then_chu_phin',
            parentId: 32,
          },
          {
            title: 'Xã Pố Lồ',
            code: 1033,
            value: 'xa_po_lo',
            division_type: 'xã',
            short_codename: 'po_lo',
            parentId: 32,
          },
          {
            title: 'Xã Bản Phùng',
            code: 1036,
            value: 'xa_ban_phung',
            division_type: 'xã',
            short_codename: 'ban_phung',
            parentId: 32,
          },
          {
            title: 'Xã Túng Sán',
            code: 1039,
            value: 'xa_tung_san',
            division_type: 'xã',
            short_codename: 'tung_san',
            parentId: 32,
          },
          {
            title: 'Xã Chiến Phố',
            code: 1042,
            value: 'xa_chien_pho',
            division_type: 'xã',
            short_codename: 'chien_pho',
            parentId: 32,
          },
          {
            title: 'Xã Đản Ván',
            code: 1045,
            value: 'xa_dan_van',
            division_type: 'xã',
            short_codename: 'dan_van',
            parentId: 32,
          },
          {
            title: 'Xã Tụ Nhân',
            code: 1048,
            value: 'xa_tu_nhan',
            division_type: 'xã',
            short_codename: 'tu_nhan',
            parentId: 32,
          },
          {
            title: 'Xã Tân Tiến',
            code: 1051,
            value: 'xa_tan_tien',
            division_type: 'xã',
            short_codename: 'tan_tien',
            parentId: 32,
          },
          {
            title: 'Xã Nàng Đôn',
            code: 1054,
            value: 'xa_nang_don',
            division_type: 'xã',
            short_codename: 'nang_don',
            parentId: 32,
          },
          {
            title: 'Xã Pờ Ly Ngài',
            code: 1057,
            value: 'xa_po_ly_ngai',
            division_type: 'xã',
            short_codename: 'po_ly_ngai',
            parentId: 32,
          },
          {
            title: 'Xã Sán Xả Hồ',
            code: 1060,
            value: 'xa_san_xa_ho',
            division_type: 'xã',
            short_codename: 'san_xa_ho',
            parentId: 32,
          },
          {
            title: 'Xã Bản Luốc',
            code: 1063,
            value: 'xa_ban_luoc',
            division_type: 'xã',
            short_codename: 'ban_luoc',
            parentId: 32,
          },
          {
            title: 'Xã Ngàm Đăng Vài',
            code: 1066,
            value: 'xa_ngam_dang_vai',
            division_type: 'xã',
            short_codename: 'ngam_dang_vai',
            parentId: 32,
          },
          {
            title: 'Xã Bản Nhùng',
            code: 1069,
            value: 'xa_ban_nhung',
            division_type: 'xã',
            short_codename: 'ban_nhung',
            parentId: 32,
          },
          {
            title: 'Xã Tả Sử Choóng',
            code: 1072,
            value: 'xa_ta_su_choong',
            division_type: 'xã',
            short_codename: 'ta_su_choong',
            parentId: 32,
          },
          {
            title: 'Xã Nậm Dịch',
            code: 1075,
            value: 'xa_nam_dich',
            division_type: 'xã',
            short_codename: 'nam_dich',
            parentId: 32,
          },
          {
            title: 'Xã Hồ Thầu',
            code: 1081,
            value: 'xa_ho_thau',
            division_type: 'xã',
            short_codename: 'ho_thau',
            parentId: 32,
          },
          {
            title: 'Xã Nam Sơn',
            code: 1084,
            value: 'xa_nam_son',
            division_type: 'xã',
            short_codename: 'nam_son',
            parentId: 32,
          },
          {
            title: 'Xã Nậm Tỵ',
            code: 1087,
            value: 'xa_nam_ty',
            division_type: 'xã',
            short_codename: 'nam_ty',
            parentId: 32,
          },
          {
            title: 'Xã Thông Nguyên',
            code: 1090,
            value: 'xa_thong_nguyen',
            division_type: 'xã',
            short_codename: 'thong_nguyen',
            parentId: 32,
          },
          {
            title: 'Xã Nậm Khòa',
            code: 1093,
            value: 'xa_nam_khoa',
            division_type: 'xã',
            short_codename: 'nam_khoa',
            parentId: 32,
          },
        ],
        parentId: 2,
      },
      {
        title: 'Huyện Xín Mần',
        code: 33,
        value: 'huyen_xin_man',
        division_type: 'huyện',
        short_codename: 'xin_man',
        children: [
          {
            title: 'Thị trấn Cốc Pài',
            code: 1096,
            value: 'thi_tran_coc_pai',
            division_type: 'thị trấn',
            short_codename: 'coc_pai',
            parentId: 33,
          },
          {
            title: 'Xã Nàn Xỉn',
            code: 1099,
            value: 'xa_nan_xin',
            division_type: 'xã',
            short_codename: 'nan_xin',
            parentId: 33,
          },
          {
            title: 'Xã Bản Díu',
            code: 1102,
            value: 'xa_ban_diu',
            division_type: 'xã',
            short_codename: 'ban_diu',
            parentId: 33,
          },
          {
            title: 'Xã Chí Cà',
            code: 1105,
            value: 'xa_chi_ca',
            division_type: 'xã',
            short_codename: 'chi_ca',
            parentId: 33,
          },
          {
            title: 'Xã Xín Mần',
            code: 1108,
            value: 'xa_xin_man',
            division_type: 'xã',
            short_codename: 'xin_man',
            parentId: 33,
          },
          {
            title: 'Xã Thèn Phàng',
            code: 1114,
            value: 'xa_then_phang',
            division_type: 'xã',
            short_codename: 'then_phang',
            parentId: 33,
          },
          {
            title: 'Xã Trung Thịnh',
            code: 1117,
            value: 'xa_trung_thinh',
            division_type: 'xã',
            short_codename: 'trung_thinh',
            parentId: 33,
          },
          {
            title: 'Xã Pà Vầy Sủ',
            code: 1120,
            value: 'xa_pa_vay_su',
            division_type: 'xã',
            short_codename: 'pa_vay_su',
            parentId: 33,
          },
          {
            title: 'Xã Cốc Rế',
            code: 1123,
            value: 'xa_coc_re',
            division_type: 'xã',
            short_codename: 'coc_re',
            parentId: 33,
          },
          {
            title: 'Xã Thu Tà',
            code: 1126,
            value: 'xa_thu_ta',
            division_type: 'xã',
            short_codename: 'thu_ta',
            parentId: 33,
          },
          {
            title: 'Xã Nàn Ma',
            code: 1129,
            value: 'xa_nan_ma',
            division_type: 'xã',
            short_codename: 'nan_ma',
            parentId: 33,
          },
          {
            title: 'Xã Tả Nhìu',
            code: 1132,
            value: 'xa_ta_nhiu',
            division_type: 'xã',
            short_codename: 'ta_nhiu',
            parentId: 33,
          },
          {
            title: 'Xã Bản Ngò',
            code: 1135,
            value: 'xa_ban_ngo',
            division_type: 'xã',
            short_codename: 'ban_ngo',
            parentId: 33,
          },
          {
            title: 'Xã Chế Là',
            code: 1138,
            value: 'xa_che_la',
            division_type: 'xã',
            short_codename: 'che_la',
            parentId: 33,
          },
          {
            title: 'Xã Nấm Dẩn',
            code: 1141,
            value: 'xa_nam_dan',
            division_type: 'xã',
            short_codename: 'nam_dan',
            parentId: 33,
          },
          {
            title: 'Xã Quảng Nguyên',
            code: 1144,
            value: 'xa_quang_nguyen',
            division_type: 'xã',
            short_codename: 'quang_nguyen',
            parentId: 33,
          },
          {
            title: 'Xã Nà Chì',
            code: 1147,
            value: 'xa_na_chi',
            division_type: 'xã',
            short_codename: 'na_chi',
            parentId: 33,
          },
          {
            title: 'Xã Khuôn Lùng',
            code: 1150,
            value: 'xa_khuon_lung',
            division_type: 'xã',
            short_codename: 'khuon_lung',
            parentId: 33,
          },
        ],
        parentId: 2,
      },
      {
        title: 'Huyện Bắc Quang',
        code: 34,
        value: 'huyen_bac_quang',
        division_type: 'huyện',
        short_codename: 'bac_quang',
        children: [
          {
            title: 'Thị trấn Việt Quang',
            code: 1153,
            value: 'thi_tran_viet_quang',
            division_type: 'thị trấn',
            short_codename: 'viet_quang',
            parentId: 34,
          },
          {
            title: 'Thị trấn Vĩnh Tuy',
            code: 1156,
            value: 'thi_tran_vinh_tuy',
            division_type: 'thị trấn',
            short_codename: 'vinh_tuy',
            parentId: 34,
          },
          {
            title: 'Xã Tân Lập',
            code: 1159,
            value: 'xa_tan_lap',
            division_type: 'xã',
            short_codename: 'tan_lap',
            parentId: 34,
          },
          {
            title: 'Xã Tân Thành',
            code: 1162,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 34,
          },
          {
            title: 'Xã Đồng Tiến',
            code: 1165,
            value: 'xa_dong_tien',
            division_type: 'xã',
            short_codename: 'dong_tien',
            parentId: 34,
          },
          {
            title: 'Xã Đồng Tâm',
            code: 1168,
            value: 'xa_dong_tam',
            division_type: 'xã',
            short_codename: 'dong_tam',
            parentId: 34,
          },
          {
            title: 'Xã Tân Quang',
            code: 1171,
            value: 'xa_tan_quang',
            division_type: 'xã',
            short_codename: 'tan_quang',
            parentId: 34,
          },
          {
            title: 'Xã Thượng Bình',
            code: 1174,
            value: 'xa_thuong_binh',
            division_type: 'xã',
            short_codename: 'thuong_binh',
            parentId: 34,
          },
          {
            title: 'Xã Hữu Sản',
            code: 1177,
            value: 'xa_huu_san',
            division_type: 'xã',
            short_codename: 'huu_san',
            parentId: 34,
          },
          {
            title: 'Xã Kim Ngọc',
            code: 1180,
            value: 'xa_kim_ngoc',
            division_type: 'xã',
            short_codename: 'kim_ngoc',
            parentId: 34,
          },
          {
            title: 'Xã Việt Vinh',
            code: 1183,
            value: 'xa_viet_vinh',
            division_type: 'xã',
            short_codename: 'viet_vinh',
            parentId: 34,
          },
          {
            title: 'Xã Bằng Hành',
            code: 1186,
            value: 'xa_bang_hanh',
            division_type: 'xã',
            short_codename: 'bang_hanh',
            parentId: 34,
          },
          {
            title: 'Xã Quang Minh',
            code: 1189,
            value: 'xa_quang_minh',
            division_type: 'xã',
            short_codename: 'quang_minh',
            parentId: 34,
          },
          {
            title: 'Xã Liên Hiệp',
            code: 1192,
            value: 'xa_lien_hiep',
            division_type: 'xã',
            short_codename: 'lien_hiep',
            parentId: 34,
          },
          {
            title: 'Xã Vô Điếm',
            code: 1195,
            value: 'xa_vo_diem',
            division_type: 'xã',
            short_codename: 'vo_diem',
            parentId: 34,
          },
          {
            title: 'Xã Việt Hồng',
            code: 1198,
            value: 'xa_viet_hong',
            division_type: 'xã',
            short_codename: 'viet_hong',
            parentId: 34,
          },
          {
            title: 'Xã Hùng An',
            code: 1201,
            value: 'xa_hung_an',
            division_type: 'xã',
            short_codename: 'hung_an',
            parentId: 34,
          },
          {
            title: 'Xã Đức Xuân',
            code: 1204,
            value: 'xa_duc_xuan',
            division_type: 'xã',
            short_codename: 'duc_xuan',
            parentId: 34,
          },
          {
            title: 'Xã Tiên Kiều',
            code: 1207,
            value: 'xa_tien_kieu',
            division_type: 'xã',
            short_codename: 'tien_kieu',
            parentId: 34,
          },
          {
            title: 'Xã Vĩnh Hảo',
            code: 1210,
            value: 'xa_vinh_hao',
            division_type: 'xã',
            short_codename: 'vinh_hao',
            parentId: 34,
          },
          {
            title: 'Xã Vĩnh Phúc',
            code: 1213,
            value: 'xa_vinh_phuc',
            division_type: 'xã',
            short_codename: 'vinh_phuc',
            parentId: 34,
          },
          {
            title: 'Xã Đồng Yên',
            code: 1216,
            value: 'xa_dong_yen',
            division_type: 'xã',
            short_codename: 'dong_yen',
            parentId: 34,
          },
          {
            title: 'Xã Đông Thành',
            code: 1219,
            value: 'xa_dong_thanh',
            division_type: 'xã',
            short_codename: 'dong_thanh',
            parentId: 34,
          },
        ],
        parentId: 2,
      },
      {
        title: 'Huyện Quang Bình',
        code: 35,
        value: 'huyen_quang_binh',
        division_type: 'huyện',
        short_codename: 'quang_binh',
        children: [
          {
            title: 'Xã Xuân Minh',
            code: 1222,
            value: 'xa_xuan_minh',
            division_type: 'xã',
            short_codename: 'xuan_minh',
            parentId: 35,
          },
          {
            title: 'Xã Tiên Nguyên',
            code: 1225,
            value: 'xa_tien_nguyen',
            division_type: 'xã',
            short_codename: 'tien_nguyen',
            parentId: 35,
          },
          {
            title: 'Xã Tân Nam',
            code: 1228,
            value: 'xa_tan_nam',
            division_type: 'xã',
            short_codename: 'tan_nam',
            parentId: 35,
          },
          {
            title: 'Xã Bản Rịa',
            code: 1231,
            value: 'xa_ban_ria',
            division_type: 'xã',
            short_codename: 'ban_ria',
            parentId: 35,
          },
          {
            title: 'Xã Yên Thành',
            code: 1234,
            value: 'xa_yen_thanh',
            division_type: 'xã',
            short_codename: 'yen_thanh',
            parentId: 35,
          },
          {
            title: 'Thị trấn Yên Bình',
            code: 1237,
            value: 'thi_tran_yen_binh',
            division_type: 'thị trấn',
            short_codename: 'yen_binh',
            parentId: 35,
          },
          {
            title: 'Xã Tân Trịnh',
            code: 1240,
            value: 'xa_tan_trinh',
            division_type: 'xã',
            short_codename: 'tan_trinh',
            parentId: 35,
          },
          {
            title: 'Xã Tân Bắc',
            code: 1243,
            value: 'xa_tan_bac',
            division_type: 'xã',
            short_codename: 'tan_bac',
            parentId: 35,
          },
          {
            title: 'Xã Bằng Lang',
            code: 1246,
            value: 'xa_bang_lang',
            division_type: 'xã',
            short_codename: 'bang_lang',
            parentId: 35,
          },
          {
            title: 'Xã Yên Hà',
            code: 1249,
            value: 'xa_yen_ha',
            division_type: 'xã',
            short_codename: 'yen_ha',
            parentId: 35,
          },
          {
            title: 'Xã Hương Sơn',
            code: 1252,
            value: 'xa_huong_son',
            division_type: 'xã',
            short_codename: 'huong_son',
            parentId: 35,
          },
          {
            title: 'Xã Xuân Giang',
            code: 1255,
            value: 'xa_xuan_giang',
            division_type: 'xã',
            short_codename: 'xuan_giang',
            parentId: 35,
          },
          {
            title: 'Xã Nà Khương',
            code: 1258,
            value: 'xa_na_khuong',
            division_type: 'xã',
            short_codename: 'na_khuong',
            parentId: 35,
          },
          {
            title: 'Xã Tiên Yên',
            code: 1261,
            value: 'xa_tien_yen',
            division_type: 'xã',
            short_codename: 'tien_yen',
            parentId: 35,
          },
          {
            title: 'Xã Vĩ Thượng',
            code: 1264,
            value: 'xa_vi_thuong',
            division_type: 'xã',
            short_codename: 'vi_thuong',
            parentId: 35,
          },
        ],
        parentId: 2,
      },
    ],
  },
  {
    title: 'Tỉnh Cao Bằng',
    code: 4,
    value: 'tinh_cao_bang',
    division_type: 'tỉnh',
    phone_code: 206,
    children: [
      {
        title: 'Thành phố Cao Bằng',
        code: 40,
        value: 'thanh_pho_cao_bang',
        division_type: 'thành phố',
        short_codename: 'cao_bang',
        children: [
          {
            title: 'Phường Sông Hiến',
            code: 1267,
            value: 'phuong_song_hien',
            division_type: 'phường',
            short_codename: 'song_hien',
            parentId: 40,
          },
          {
            title: 'Phường Sông Bằng',
            code: 1270,
            value: 'phuong_song_bang',
            division_type: 'phường',
            short_codename: 'song_bang',
            parentId: 40,
          },
          {
            title: 'Phường Hợp Giang',
            code: 1273,
            value: 'phuong_hop_giang',
            division_type: 'phường',
            short_codename: 'hop_giang',
            parentId: 40,
          },
          {
            title: 'Phường Tân Giang',
            code: 1276,
            value: 'phuong_tan_giang',
            division_type: 'phường',
            short_codename: 'tan_giang',
            parentId: 40,
          },
          {
            title: 'Phường Ngọc Xuân',
            code: 1279,
            value: 'phuong_ngoc_xuan',
            division_type: 'phường',
            short_codename: 'ngoc_xuan',
            parentId: 40,
          },
          {
            title: 'Phường Đề Thám',
            code: 1282,
            value: 'phuong_de_tham',
            division_type: 'phường',
            short_codename: 'de_tham',
            parentId: 40,
          },
          {
            title: 'Phường Hoà Chung',
            code: 1285,
            value: 'phuong_hoa_chung',
            division_type: 'phường',
            short_codename: 'hoa_chung',
            parentId: 40,
          },
          {
            title: 'Phường Duyệt Trung',
            code: 1288,
            value: 'phuong_duyet_trung',
            division_type: 'phường',
            short_codename: 'duyet_trung',
            parentId: 40,
          },
          {
            title: 'Xã Vĩnh Quang',
            code: 1693,
            value: 'xa_vinh_quang',
            division_type: 'xã',
            short_codename: 'vinh_quang',
            parentId: 40,
          },
          {
            title: 'Xã Hưng Đạo',
            code: 1705,
            value: 'xa_hung_dao',
            division_type: 'xã',
            short_codename: 'hung_dao',
            parentId: 40,
          },
          {
            title: 'Xã Chu Trinh',
            code: 1720,
            value: 'xa_chu_trinh',
            division_type: 'xã',
            short_codename: 'chu_trinh',
            parentId: 40,
          },
        ],
        parentId: 4,
      },
      {
        title: 'Huyện Bảo Lâm',
        code: 42,
        value: 'huyen_bao_lam',
        division_type: 'huyện',
        short_codename: 'bao_lam',
        children: [
          {
            title: 'Thị trấn Pác Miầu',
            code: 1290,
            value: 'thi_tran_pac_miau',
            division_type: 'thị trấn',
            short_codename: 'pac_miau',
            parentId: 42,
          },
          {
            title: 'Xã Đức Hạnh',
            code: 1291,
            value: 'xa_duc_hanh',
            division_type: 'xã',
            short_codename: 'duc_hanh',
            parentId: 42,
          },
          {
            title: 'Xã Lý Bôn',
            code: 1294,
            value: 'xa_ly_bon',
            division_type: 'xã',
            short_codename: 'ly_bon',
            parentId: 42,
          },
          {
            title: 'Xã Nam Cao',
            code: 1296,
            value: 'xa_nam_cao',
            division_type: 'xã',
            short_codename: 'nam_cao',
            parentId: 42,
          },
          {
            title: 'Xã Nam Quang',
            code: 1297,
            value: 'xa_nam_quang',
            division_type: 'xã',
            short_codename: 'nam_quang',
            parentId: 42,
          },
          {
            title: 'Xã Vĩnh Quang',
            code: 1300,
            value: 'xa_vinh_quang',
            division_type: 'xã',
            short_codename: 'vinh_quang',
            parentId: 42,
          },
          {
            title: 'Xã Quảng Lâm',
            code: 1303,
            value: 'xa_quang_lam',
            division_type: 'xã',
            short_codename: 'quang_lam',
            parentId: 42,
          },
          {
            title: 'Xã Thạch Lâm',
            code: 1304,
            value: 'xa_thach_lam',
            division_type: 'xã',
            short_codename: 'thach_lam',
            parentId: 42,
          },
          {
            title: 'Xã Vĩnh Phong',
            code: 1309,
            value: 'xa_vinh_phong',
            division_type: 'xã',
            short_codename: 'vinh_phong',
            parentId: 42,
          },
          {
            title: 'Xã Mông Ân',
            code: 1312,
            value: 'xa_mong_an',
            division_type: 'xã',
            short_codename: 'mong_an',
            parentId: 42,
          },
          {
            title: 'Xã Thái Học',
            code: 1315,
            value: 'xa_thai_hoc',
            division_type: 'xã',
            short_codename: 'thai_hoc',
            parentId: 42,
          },
          {
            title: 'Xã Thái Sơn',
            code: 1316,
            value: 'xa_thai_son',
            division_type: 'xã',
            short_codename: 'thai_son',
            parentId: 42,
          },
          {
            title: 'Xã Yên Thổ',
            code: 1318,
            value: 'xa_yen_tho',
            division_type: 'xã',
            short_codename: 'yen_tho',
            parentId: 42,
          },
        ],
        parentId: 4,
      },
      {
        title: 'Huyện Bảo Lạc',
        code: 43,
        value: 'huyen_bao_lac',
        division_type: 'huyện',
        short_codename: 'bao_lac',
        children: [
          {
            title: 'Thị trấn Bảo Lạc',
            code: 1321,
            value: 'thi_tran_bao_lac',
            division_type: 'thị trấn',
            short_codename: 'bao_lac',
            parentId: 43,
          },
          {
            title: 'Xã Cốc Pàng',
            code: 1324,
            value: 'xa_coc_pang',
            division_type: 'xã',
            short_codename: 'coc_pang',
            parentId: 43,
          },
          {
            title: 'Xã Thượng Hà',
            code: 1327,
            value: 'xa_thuong_ha',
            division_type: 'xã',
            short_codename: 'thuong_ha',
            parentId: 43,
          },
          {
            title: 'Xã Cô Ba',
            code: 1330,
            value: 'xa_co_ba',
            division_type: 'xã',
            short_codename: 'co_ba',
            parentId: 43,
          },
          {
            title: 'Xã Bảo Toàn',
            code: 1333,
            value: 'xa_bao_toan',
            division_type: 'xã',
            short_codename: 'bao_toan',
            parentId: 43,
          },
          {
            title: 'Xã Khánh Xuân',
            code: 1336,
            value: 'xa_khanh_xuan',
            division_type: 'xã',
            short_codename: 'khanh_xuan',
            parentId: 43,
          },
          {
            title: 'Xã Xuân Trường',
            code: 1339,
            value: 'xa_xuan_truong',
            division_type: 'xã',
            short_codename: 'xuan_truong',
            parentId: 43,
          },
          {
            title: 'Xã Hồng Trị',
            code: 1342,
            value: 'xa_hong_tri',
            division_type: 'xã',
            short_codename: 'hong_tri',
            parentId: 43,
          },
          {
            title: 'Xã Kim Cúc',
            code: 1343,
            value: 'xa_kim_cuc',
            division_type: 'xã',
            short_codename: 'kim_cuc',
            parentId: 43,
          },
          {
            title: 'Xã Phan Thanh',
            code: 1345,
            value: 'xa_phan_thanh',
            division_type: 'xã',
            short_codename: 'phan_thanh',
            parentId: 43,
          },
          {
            title: 'Xã Hồng An',
            code: 1348,
            value: 'xa_hong_an',
            division_type: 'xã',
            short_codename: 'hong_an',
            parentId: 43,
          },
          {
            title: 'Xã Hưng Đạo',
            code: 1351,
            value: 'xa_hung_dao',
            division_type: 'xã',
            short_codename: 'hung_dao',
            parentId: 43,
          },
          {
            title: 'Xã Hưng Thịnh',
            code: 1352,
            value: 'xa_hung_thinh',
            division_type: 'xã',
            short_codename: 'hung_thinh',
            parentId: 43,
          },
          {
            title: 'Xã Huy Giáp',
            code: 1354,
            value: 'xa_huy_giap',
            division_type: 'xã',
            short_codename: 'huy_giap',
            parentId: 43,
          },
          {
            title: 'Xã Đình Phùng',
            code: 1357,
            value: 'xa_dinh_phung',
            division_type: 'xã',
            short_codename: 'dinh_phung',
            parentId: 43,
          },
          {
            title: 'Xã Sơn Lập',
            code: 1359,
            value: 'xa_son_lap',
            division_type: 'xã',
            short_codename: 'son_lap',
            parentId: 43,
          },
          {
            title: 'Xã Sơn Lộ',
            code: 1360,
            value: 'xa_son_lo',
            division_type: 'xã',
            short_codename: 'son_lo',
            parentId: 43,
          },
        ],
        parentId: 4,
      },
      {
        title: 'Huyện Hà Quảng',
        code: 45,
        value: 'huyen_ha_quang',
        division_type: 'huyện',
        short_codename: 'ha_quang',
        children: [
          {
            title: 'Thị trấn Thông Nông',
            code: 1363,
            value: 'thi_tran_thong_nong',
            division_type: 'thị trấn',
            short_codename: 'thong_nong',
            parentId: 45,
          },
          {
            title: 'Xã Cần Yên',
            code: 1366,
            value: 'xa_can_yen',
            division_type: 'xã',
            short_codename: 'can_yen',
            parentId: 45,
          },
          {
            title: 'Xã Cần Nông',
            code: 1367,
            value: 'xa_can_nong',
            division_type: 'xã',
            short_codename: 'can_nong',
            parentId: 45,
          },
          {
            title: 'Xã Lương Thông',
            code: 1372,
            value: 'xa_luong_thong',
            division_type: 'xã',
            short_codename: 'luong_thong',
            parentId: 45,
          },
          {
            title: 'Xã Đa Thông',
            code: 1375,
            value: 'xa_da_thong',
            division_type: 'xã',
            short_codename: 'da_thong',
            parentId: 45,
          },
          {
            title: 'Xã Ngọc Động',
            code: 1378,
            value: 'xa_ngoc_dong',
            division_type: 'xã',
            short_codename: 'ngoc_dong',
            parentId: 45,
          },
          {
            title: 'Xã Yên Sơn',
            code: 1381,
            value: 'xa_yen_son',
            division_type: 'xã',
            short_codename: 'yen_son',
            parentId: 45,
          },
          {
            title: 'Xã Lương Can',
            code: 1384,
            value: 'xa_luong_can',
            division_type: 'xã',
            short_codename: 'luong_can',
            parentId: 45,
          },
          {
            title: 'Xã Thanh Long',
            code: 1387,
            value: 'xa_thanh_long',
            division_type: 'xã',
            short_codename: 'thanh_long',
            parentId: 45,
          },
          {
            title: 'Thị trấn Xuân Hòa',
            code: 1392,
            value: 'thi_tran_xuan_hoa',
            division_type: 'thị trấn',
            short_codename: 'xuan_hoa',
            parentId: 45,
          },
          {
            title: 'Xã Lũng Nặm',
            code: 1393,
            value: 'xa_lung_nam',
            division_type: 'xã',
            short_codename: 'lung_nam',
            parentId: 45,
          },
          {
            title: 'Xã Trường Hà',
            code: 1399,
            value: 'xa_truong_ha',
            division_type: 'xã',
            short_codename: 'truong_ha',
            parentId: 45,
          },
          {
            title: 'Xã Cải Viên',
            code: 1402,
            value: 'xa_cai_vien',
            division_type: 'xã',
            short_codename: 'cai_vien',
            parentId: 45,
          },
          {
            title: 'Xã Nội Thôn',
            code: 1411,
            value: 'xa_noi_thon',
            division_type: 'xã',
            short_codename: 'noi_thon',
            parentId: 45,
          },
          {
            title: 'Xã Tổng Cọt',
            code: 1414,
            value: 'xa_tong_cot',
            division_type: 'xã',
            short_codename: 'tong_cot',
            parentId: 45,
          },
          {
            title: 'Xã Sóc Hà',
            code: 1417,
            value: 'xa_soc_ha',
            division_type: 'xã',
            short_codename: 'soc_ha',
            parentId: 45,
          },
          {
            title: 'Xã Thượng Thôn',
            code: 1420,
            value: 'xa_thuong_thon',
            division_type: 'xã',
            short_codename: 'thuong_thon',
            parentId: 45,
          },
          {
            title: 'Xã Hồng Sỹ',
            code: 1429,
            value: 'xa_hong_sy',
            division_type: 'xã',
            short_codename: 'hong_sy',
            parentId: 45,
          },
          {
            title: 'Xã Quý Quân',
            code: 1432,
            value: 'xa_quy_quan',
            division_type: 'xã',
            short_codename: 'quy_quan',
            parentId: 45,
          },
          {
            title: 'Xã Mã Ba',
            code: 1435,
            value: 'xa_ma_ba',
            division_type: 'xã',
            short_codename: 'ma_ba',
            parentId: 45,
          },
          {
            title: 'Xã Ngọc Đào',
            code: 1438,
            value: 'xa_ngoc_dao',
            division_type: 'xã',
            short_codename: 'ngoc_dao',
            parentId: 45,
          },
        ],
        parentId: 4,
      },
      {
        title: 'Huyện Trùng Khánh',
        code: 47,
        value: 'huyen_trung_khanh',
        division_type: 'huyện',
        short_codename: 'trung_khanh',
        children: [
          {
            title: 'Thị trấn Trà Lĩnh',
            code: 1447,
            value: 'thi_tran_tra_linh',
            division_type: 'thị trấn',
            short_codename: 'tra_linh',
            parentId: 47,
          },
          {
            title: 'Xã Tri Phương',
            code: 1453,
            value: 'xa_tri_phuong',
            division_type: 'xã',
            short_codename: 'tri_phuong',
            parentId: 47,
          },
          {
            title: 'Xã Quang Hán',
            code: 1456,
            value: 'xa_quang_han',
            division_type: 'xã',
            short_codename: 'quang_han',
            parentId: 47,
          },
          {
            title: 'Xã Xuân Nội',
            code: 1462,
            value: 'xa_xuan_noi',
            division_type: 'xã',
            short_codename: 'xuan_noi',
            parentId: 47,
          },
          {
            title: 'Xã Quang Trung',
            code: 1465,
            value: 'xa_quang_trung',
            division_type: 'xã',
            short_codename: 'quang_trung',
            parentId: 47,
          },
          {
            title: 'Xã Quang Vinh',
            code: 1468,
            value: 'xa_quang_vinh',
            division_type: 'xã',
            short_codename: 'quang_vinh',
            parentId: 47,
          },
          {
            title: 'Xã Cao Chương',
            code: 1471,
            value: 'xa_cao_chuong',
            division_type: 'xã',
            short_codename: 'cao_chuong',
            parentId: 47,
          },
          {
            title: 'Thị trấn Trùng Khánh',
            code: 1477,
            value: 'thi_tran_trung_khanh',
            division_type: 'thị trấn',
            short_codename: 'trung_khanh',
            parentId: 47,
          },
          {
            title: 'Xã Ngọc Khê',
            code: 1480,
            value: 'xa_ngoc_khe',
            division_type: 'xã',
            short_codename: 'ngoc_khe',
            parentId: 47,
          },
          {
            title: 'Xã Ngọc Côn',
            code: 1481,
            value: 'xa_ngoc_con',
            division_type: 'xã',
            short_codename: 'ngoc_con',
            parentId: 47,
          },
          {
            title: 'Xã Phong Nậm',
            code: 1483,
            value: 'xa_phong_nam',
            division_type: 'xã',
            short_codename: 'phong_nam',
            parentId: 47,
          },
          {
            title: 'Xã Đình Phong',
            code: 1489,
            value: 'xa_dinh_phong',
            division_type: 'xã',
            short_codename: 'dinh_phong',
            parentId: 47,
          },
          {
            title: 'Xã Đàm Thuỷ',
            code: 1495,
            value: 'xa_dam_thuy',
            division_type: 'xã',
            short_codename: 'dam_thuy',
            parentId: 47,
          },
          {
            title: 'Xã Khâm Thành',
            code: 1498,
            value: 'xa_kham_thanh',
            division_type: 'xã',
            short_codename: 'kham_thanh',
            parentId: 47,
          },
          {
            title: 'Xã Chí Viễn',
            code: 1501,
            value: 'xa_chi_vien',
            division_type: 'xã',
            short_codename: 'chi_vien',
            parentId: 47,
          },
          {
            title: 'Xã Lăng Hiếu',
            code: 1504,
            value: 'xa_lang_hieu',
            division_type: 'xã',
            short_codename: 'lang_hieu',
            parentId: 47,
          },
          {
            title: 'Xã Phong Châu',
            code: 1507,
            value: 'xa_phong_chau',
            division_type: 'xã',
            short_codename: 'phong_chau',
            parentId: 47,
          },
          {
            title: 'Xã Trung Phúc',
            code: 1516,
            value: 'xa_trung_phuc',
            division_type: 'xã',
            short_codename: 'trung_phuc',
            parentId: 47,
          },
          {
            title: 'Xã Cao Thăng',
            code: 1519,
            value: 'xa_cao_thang',
            division_type: 'xã',
            short_codename: 'cao_thang',
            parentId: 47,
          },
          {
            title: 'Xã Đức Hồng',
            code: 1522,
            value: 'xa_duc_hong',
            division_type: 'xã',
            short_codename: 'duc_hong',
            parentId: 47,
          },
          {
            title: 'Xã Đoài Dương',
            code: 1525,
            value: 'xa_doai_duong',
            division_type: 'xã',
            short_codename: 'doai_duong',
            parentId: 47,
          },
        ],
        parentId: 4,
      },
      {
        title: 'Huyện Hạ Lang',
        code: 48,
        value: 'huyen_ha_lang',
        division_type: 'huyện',
        short_codename: 'ha_lang',
        children: [
          {
            title: 'Xã Minh Long',
            code: 1534,
            value: 'xa_minh_long',
            division_type: 'xã',
            short_codename: 'minh_long',
            parentId: 48,
          },
          {
            title: 'Xã Lý Quốc',
            code: 1537,
            value: 'xa_ly_quoc',
            division_type: 'xã',
            short_codename: 'ly_quoc',
            parentId: 48,
          },
          {
            title: 'Xã Thắng Lợi',
            code: 1540,
            value: 'xa_thang_loi',
            division_type: 'xã',
            short_codename: 'thang_loi',
            parentId: 48,
          },
          {
            title: 'Xã Đồng Loan',
            code: 1543,
            value: 'xa_dong_loan',
            division_type: 'xã',
            short_codename: 'dong_loan',
            parentId: 48,
          },
          {
            title: 'Xã Đức Quang',
            code: 1546,
            value: 'xa_duc_quang',
            division_type: 'xã',
            short_codename: 'duc_quang',
            parentId: 48,
          },
          {
            title: 'Xã Kim Loan',
            code: 1549,
            value: 'xa_kim_loan',
            division_type: 'xã',
            short_codename: 'kim_loan',
            parentId: 48,
          },
          {
            title: 'Xã Quang Long',
            code: 1552,
            value: 'xa_quang_long',
            division_type: 'xã',
            short_codename: 'quang_long',
            parentId: 48,
          },
          {
            title: 'Xã An Lạc',
            code: 1555,
            value: 'xa_an_lac',
            division_type: 'xã',
            short_codename: 'an_lac',
            parentId: 48,
          },
          {
            title: 'Thị trấn Thanh Nhật',
            code: 1558,
            value: 'thi_tran_thanh_nhat',
            division_type: 'thị trấn',
            short_codename: 'thanh_nhat',
            parentId: 48,
          },
          {
            title: 'Xã Vinh Quý',
            code: 1561,
            value: 'xa_vinh_quy',
            division_type: 'xã',
            short_codename: 'vinh_quy',
            parentId: 48,
          },
          {
            title: 'Xã Thống Nhất',
            code: 1564,
            value: 'xa_thong_nhat',
            division_type: 'xã',
            short_codename: 'thong_nhat',
            parentId: 48,
          },
          {
            title: 'Xã Cô Ngân',
            code: 1567,
            value: 'xa_co_ngan',
            division_type: 'xã',
            short_codename: 'co_ngan',
            parentId: 48,
          },
          {
            title: 'Xã Thị Hoa',
            code: 1573,
            value: 'xa_thi_hoa',
            division_type: 'xã',
            short_codename: 'thi_hoa',
            parentId: 48,
          },
        ],
        parentId: 4,
      },
      {
        title: 'Huyện Quảng Hòa',
        code: 49,
        value: 'huyen_quang_hoa',
        division_type: 'huyện',
        short_codename: 'quang_hoa',
        children: [
          {
            title: 'Xã Quốc Toản',
            code: 1474,
            value: 'xa_quoc_toan',
            division_type: 'xã',
            short_codename: 'quoc_toan',
            parentId: 49,
          },
          {
            title: 'Thị trấn Quảng Uyên',
            code: 1576,
            value: 'thi_tran_quang_uyen',
            division_type: 'thị trấn',
            short_codename: 'quang_uyen',
            parentId: 49,
          },
          {
            title: 'Xã Phi Hải',
            code: 1579,
            value: 'xa_phi_hai',
            division_type: 'xã',
            short_codename: 'phi_hai',
            parentId: 49,
          },
          {
            title: 'Xã Quảng Hưng',
            code: 1582,
            value: 'xa_quang_hung',
            division_type: 'xã',
            short_codename: 'quang_hung',
            parentId: 49,
          },
          {
            title: 'Xã Độc Lập',
            code: 1594,
            value: 'xa_doc_lap',
            division_type: 'xã',
            short_codename: 'doc_lap',
            parentId: 49,
          },
          {
            title: 'Xã Cai Bộ',
            code: 1597,
            value: 'xa_cai_bo',
            division_type: 'xã',
            short_codename: 'cai_bo',
            parentId: 49,
          },
          {
            title: 'Xã Phúc Sen',
            code: 1603,
            value: 'xa_phuc_sen',
            division_type: 'xã',
            short_codename: 'phuc_sen',
            parentId: 49,
          },
          {
            title: 'Xã Chí Thảo',
            code: 1606,
            value: 'xa_chi_thao',
            division_type: 'xã',
            short_codename: 'chi_thao',
            parentId: 49,
          },
          {
            title: 'Xã Tự Do',
            code: 1609,
            value: 'xa_tu_do',
            division_type: 'xã',
            short_codename: 'tu_do',
            parentId: 49,
          },
          {
            title: 'Xã Hồng Quang',
            code: 1615,
            value: 'xa_hong_quang',
            division_type: 'xã',
            short_codename: 'hong_quang',
            parentId: 49,
          },
          {
            title: 'Xã Ngọc Động',
            code: 1618,
            value: 'xa_ngoc_dong',
            division_type: 'xã',
            short_codename: 'ngoc_dong',
            parentId: 49,
          },
          {
            title: 'Xã Hạnh Phúc',
            code: 1624,
            value: 'xa_hanh_phuc',
            division_type: 'xã',
            short_codename: 'hanh_phuc',
            parentId: 49,
          },
          {
            title: 'Thị trấn Tà Lùng',
            code: 1627,
            value: 'thi_tran_ta_lung',
            division_type: 'thị trấn',
            short_codename: 'ta_lung',
            parentId: 49,
          },
          {
            title: 'Xã Bế Văn Đàn',
            code: 1630,
            value: 'xa_be_van_dan',
            division_type: 'xã',
            short_codename: 'be_van_dan',
            parentId: 49,
          },
          {
            title: 'Xã Cách Linh',
            code: 1636,
            value: 'xa_cach_linh',
            division_type: 'xã',
            short_codename: 'cach_linh',
            parentId: 49,
          },
          {
            title: 'Xã Đại Sơn',
            code: 1639,
            value: 'xa_dai_son',
            division_type: 'xã',
            short_codename: 'dai_son',
            parentId: 49,
          },
          {
            title: 'Xã Tiên Thành',
            code: 1645,
            value: 'xa_tien_thanh',
            division_type: 'xã',
            short_codename: 'tien_thanh',
            parentId: 49,
          },
          {
            title: 'Thị trấn Hoà Thuận',
            code: 1648,
            value: 'thi_tran_hoa_thuan',
            division_type: 'thị trấn',
            short_codename: 'hoa_thuan',
            parentId: 49,
          },
          {
            title: 'Xã Mỹ Hưng',
            code: 1651,
            value: 'xa_my_hung',
            division_type: 'xã',
            short_codename: 'my_hung',
            parentId: 49,
          },
        ],
        parentId: 4,
      },
      {
        title: 'Huyện Hoà An',
        code: 51,
        value: 'huyen_hoa_an',
        division_type: 'huyện',
        short_codename: 'hoa_an',
        children: [
          {
            title: 'Thị trấn Nước Hai',
            code: 1654,
            value: 'thi_tran_nuoc_hai',
            division_type: 'thị trấn',
            short_codename: 'nuoc_hai',
            parentId: 51,
          },
          {
            title: 'Xã Dân Chủ',
            code: 1657,
            value: 'xa_dan_chu',
            division_type: 'xã',
            short_codename: 'dan_chu',
            parentId: 51,
          },
          {
            title: 'Xã Nam Tuấn',
            code: 1660,
            value: 'xa_nam_tuan',
            division_type: 'xã',
            short_codename: 'nam_tuan',
            parentId: 51,
          },
          {
            title: 'Xã Đại Tiến',
            code: 1666,
            value: 'xa_dai_tien',
            division_type: 'xã',
            short_codename: 'dai_tien',
            parentId: 51,
          },
          {
            title: 'Xã Đức Long',
            code: 1669,
            value: 'xa_duc_long',
            division_type: 'xã',
            short_codename: 'duc_long',
            parentId: 51,
          },
          {
            title: 'Xã Ngũ Lão',
            code: 1672,
            value: 'xa_ngu_lao',
            division_type: 'xã',
            short_codename: 'ngu_lao',
            parentId: 51,
          },
          {
            title: 'Xã Trương Lương',
            code: 1675,
            value: 'xa_truong_luong',
            division_type: 'xã',
            short_codename: 'truong_luong',
            parentId: 51,
          },
          {
            title: 'Xã Hồng Việt',
            code: 1687,
            value: 'xa_hong_viet',
            division_type: 'xã',
            short_codename: 'hong_viet',
            parentId: 51,
          },
          {
            title: 'Xã Hoàng Tung',
            code: 1696,
            value: 'xa_hoang_tung',
            division_type: 'xã',
            short_codename: 'hoang_tung',
            parentId: 51,
          },
          {
            title: 'Xã Nguyễn Huệ',
            code: 1699,
            value: 'xa_nguyen_hue',
            division_type: 'xã',
            short_codename: 'nguyen_hue',
            parentId: 51,
          },
          {
            title: 'Xã Quang Trung',
            code: 1702,
            value: 'xa_quang_trung',
            division_type: 'xã',
            short_codename: 'quang_trung',
            parentId: 51,
          },
          {
            title: 'Xã Bạch Đằng',
            code: 1708,
            value: 'xa_bach_dang',
            division_type: 'xã',
            short_codename: 'bach_dang',
            parentId: 51,
          },
          {
            title: 'Xã Bình Dương',
            code: 1711,
            value: 'xa_binh_duong',
            division_type: 'xã',
            short_codename: 'binh_duong',
            parentId: 51,
          },
          {
            title: 'Xã Lê Chung',
            code: 1714,
            value: 'xa_le_chung',
            division_type: 'xã',
            short_codename: 'le_chung',
            parentId: 51,
          },
          {
            title: 'Xã Hồng Nam',
            code: 1723,
            value: 'xa_hong_nam',
            division_type: 'xã',
            short_codename: 'hong_nam',
            parentId: 51,
          },
        ],
        parentId: 4,
      },
      {
        title: 'Huyện Nguyên Bình',
        code: 52,
        value: 'huyen_nguyen_binh',
        division_type: 'huyện',
        short_codename: 'nguyen_binh',
        children: [
          {
            title: 'Thị trấn Nguyên Bình',
            code: 1726,
            value: 'thi_tran_nguyen_binh',
            division_type: 'thị trấn',
            short_codename: 'nguyen_binh',
            parentId: 52,
          },
          {
            title: 'Thị trấn Tĩnh Túc',
            code: 1729,
            value: 'thi_tran_tinh_tuc',
            division_type: 'thị trấn',
            short_codename: 'tinh_tuc',
            parentId: 52,
          },
          {
            title: 'Xã Yên Lạc',
            code: 1732,
            value: 'xa_yen_lac',
            division_type: 'xã',
            short_codename: 'yen_lac',
            parentId: 52,
          },
          {
            title: 'Xã Triệu Nguyên',
            code: 1735,
            value: 'xa_trieu_nguyen',
            division_type: 'xã',
            short_codename: 'trieu_nguyen',
            parentId: 52,
          },
          {
            title: 'Xã Ca Thành',
            code: 1738,
            value: 'xa_ca_thanh',
            division_type: 'xã',
            short_codename: 'ca_thanh',
            parentId: 52,
          },
          {
            title: 'Xã Vũ Nông',
            code: 1744,
            value: 'xa_vu_nong',
            division_type: 'xã',
            short_codename: 'vu_nong',
            parentId: 52,
          },
          {
            title: 'Xã Minh Tâm',
            code: 1747,
            value: 'xa_minh_tam',
            division_type: 'xã',
            short_codename: 'minh_tam',
            parentId: 52,
          },
          {
            title: 'Xã Thể Dục',
            code: 1750,
            value: 'xa_the_duc',
            division_type: 'xã',
            short_codename: 'the_duc',
            parentId: 52,
          },
          {
            title: 'Xã Mai Long',
            code: 1756,
            value: 'xa_mai_long',
            division_type: 'xã',
            short_codename: 'mai_long',
            parentId: 52,
          },
          {
            title: 'Xã Vũ Minh',
            code: 1762,
            value: 'xa_vu_minh',
            division_type: 'xã',
            short_codename: 'vu_minh',
            parentId: 52,
          },
          {
            title: 'Xã Hoa Thám',
            code: 1765,
            value: 'xa_hoa_tham',
            division_type: 'xã',
            short_codename: 'hoa_tham',
            parentId: 52,
          },
          {
            title: 'Xã Phan Thanh',
            code: 1768,
            value: 'xa_phan_thanh',
            division_type: 'xã',
            short_codename: 'phan_thanh',
            parentId: 52,
          },
          {
            title: 'Xã Quang Thành',
            code: 1771,
            value: 'xa_quang_thanh',
            division_type: 'xã',
            short_codename: 'quang_thanh',
            parentId: 52,
          },
          {
            title: 'Xã Tam Kim',
            code: 1774,
            value: 'xa_tam_kim',
            division_type: 'xã',
            short_codename: 'tam_kim',
            parentId: 52,
          },
          {
            title: 'Xã Thành Công',
            code: 1777,
            value: 'xa_thanh_cong',
            division_type: 'xã',
            short_codename: 'thanh_cong',
            parentId: 52,
          },
          {
            title: 'Xã Thịnh Vượng',
            code: 1780,
            value: 'xa_thinh_vuong',
            division_type: 'xã',
            short_codename: 'thinh_vuong',
            parentId: 52,
          },
          {
            title: 'Xã Hưng Đạo',
            code: 1783,
            value: 'xa_hung_dao',
            division_type: 'xã',
            short_codename: 'hung_dao',
            parentId: 52,
          },
        ],
        parentId: 4,
      },
      {
        title: 'Huyện Thạch An',
        code: 53,
        value: 'huyen_thach_an',
        division_type: 'huyện',
        short_codename: 'thach_an',
        children: [
          {
            title: 'Thị trấn Đông Khê',
            code: 1786,
            value: 'thi_tran_dong_khe',
            division_type: 'thị trấn',
            short_codename: 'dong_khe',
            parentId: 53,
          },
          {
            title: 'Xã Canh Tân',
            code: 1789,
            value: 'xa_canh_tan',
            division_type: 'xã',
            short_codename: 'canh_tan',
            parentId: 53,
          },
          {
            title: 'Xã Kim Đồng',
            code: 1792,
            value: 'xa_kim_dong',
            division_type: 'xã',
            short_codename: 'kim_dong',
            parentId: 53,
          },
          {
            title: 'Xã Minh Khai',
            code: 1795,
            value: 'xa_minh_khai',
            division_type: 'xã',
            short_codename: 'minh_khai',
            parentId: 53,
          },
          {
            title: 'Xã Đức Thông',
            code: 1801,
            value: 'xa_duc_thong',
            division_type: 'xã',
            short_codename: 'duc_thong',
            parentId: 53,
          },
          {
            title: 'Xã Thái Cường',
            code: 1804,
            value: 'xa_thai_cuong',
            division_type: 'xã',
            short_codename: 'thai_cuong',
            parentId: 53,
          },
          {
            title: 'Xã Vân Trình',
            code: 1807,
            value: 'xa_van_trinh',
            division_type: 'xã',
            short_codename: 'van_trinh',
            parentId: 53,
          },
          {
            title: 'Xã Thụy Hùng',
            code: 1810,
            value: 'xa_thuy_hung',
            division_type: 'xã',
            short_codename: 'thuy_hung',
            parentId: 53,
          },
          {
            title: 'Xã Quang Trọng',
            code: 1813,
            value: 'xa_quang_trong',
            division_type: 'xã',
            short_codename: 'quang_trong',
            parentId: 53,
          },
          {
            title: 'Xã Trọng Con',
            code: 1816,
            value: 'xa_trong_con',
            division_type: 'xã',
            short_codename: 'trong_con',
            parentId: 53,
          },
          {
            title: 'Xã Lê Lai',
            code: 1819,
            value: 'xa_le_lai',
            division_type: 'xã',
            short_codename: 'le_lai',
            parentId: 53,
          },
          {
            title: 'Xã Đức Long',
            code: 1822,
            value: 'xa_duc_long',
            division_type: 'xã',
            short_codename: 'duc_long',
            parentId: 53,
          },
          {
            title: 'Xã Lê Lợi',
            code: 1828,
            value: 'xa_le_loi',
            division_type: 'xã',
            short_codename: 'le_loi',
            parentId: 53,
          },
          {
            title: 'Xã Đức Xuân',
            code: 1831,
            value: 'xa_duc_xuan',
            division_type: 'xã',
            short_codename: 'duc_xuan',
            parentId: 53,
          },
        ],
        parentId: 4,
      },
    ],
  },
  {
    title: 'Tỉnh Bắc Kạn',
    code: 6,
    value: 'tinh_bac_kan',
    division_type: 'tỉnh',
    phone_code: 209,
    children: [
      {
        title: 'Thành Phố Bắc Kạn',
        code: 58,
        value: 'thanh_pho_bac_kan',
        division_type: 'thành phố',
        short_codename: 'bac_kan',
        children: [
          {
            title: 'Phường Nguyễn Thị Minh Khai',
            code: 1834,
            value: 'phuong_nguyen_thi_minh_khai',
            division_type: 'phường',
            short_codename: 'nguyen_thi_minh_khai',
            parentId: 58,
          },
          {
            title: 'Phường Sông Cầu',
            code: 1837,
            value: 'phuong_song_cau',
            division_type: 'phường',
            short_codename: 'song_cau',
            parentId: 58,
          },
          {
            title: 'Phường Đức Xuân',
            code: 1840,
            value: 'phuong_duc_xuan',
            division_type: 'phường',
            short_codename: 'duc_xuan',
            parentId: 58,
          },
          {
            title: 'Phường Phùng Chí Kiên',
            code: 1843,
            value: 'phuong_phung_chi_kien',
            division_type: 'phường',
            short_codename: 'phung_chi_kien',
            parentId: 58,
          },
          {
            title: 'Phường Huyền Tụng',
            code: 1846,
            value: 'phuong_huyen_tung',
            division_type: 'phường',
            short_codename: 'huyen_tung',
            parentId: 58,
          },
          {
            title: 'Xã Dương Quang',
            code: 1849,
            value: 'xa_duong_quang',
            division_type: 'xã',
            short_codename: 'duong_quang',
            parentId: 58,
          },
          {
            title: 'Xã Nông Thượng',
            code: 1852,
            value: 'xa_nong_thuong',
            division_type: 'xã',
            short_codename: 'nong_thuong',
            parentId: 58,
          },
          {
            title: 'Phường Xuất Hóa',
            code: 1855,
            value: 'phuong_xuat_hoa',
            division_type: 'phường',
            short_codename: 'xuat_hoa',
            parentId: 58,
          },
        ],
        parentId: 6,
      },
      {
        title: 'Huyện Pác Nặm',
        code: 60,
        value: 'huyen_pac_nam',
        division_type: 'huyện',
        short_codename: 'pac_nam',
        children: [
          {
            title: 'Xã Bằng Thành',
            code: 1858,
            value: 'xa_bang_thanh',
            division_type: 'xã',
            short_codename: 'bang_thanh',
            parentId: 60,
          },
          {
            title: 'Xã Nhạn Môn',
            code: 1861,
            value: 'xa_nhan_mon',
            division_type: 'xã',
            short_codename: 'nhan_mon',
            parentId: 60,
          },
          {
            title: 'Xã Bộc Bố',
            code: 1864,
            value: 'xa_boc_bo',
            division_type: 'xã',
            short_codename: 'boc_bo',
            parentId: 60,
          },
          {
            title: 'Xã Công Bằng',
            code: 1867,
            value: 'xa_cong_bang',
            division_type: 'xã',
            short_codename: 'cong_bang',
            parentId: 60,
          },
          {
            title: 'Xã Giáo Hiệu',
            code: 1870,
            value: 'xa_giao_hieu',
            division_type: 'xã',
            short_codename: 'giao_hieu',
            parentId: 60,
          },
          {
            title: 'Xã Xuân La',
            code: 1873,
            value: 'xa_xuan_la',
            division_type: 'xã',
            short_codename: 'xuan_la',
            parentId: 60,
          },
          {
            title: 'Xã An Thắng',
            code: 1876,
            value: 'xa_an_thang',
            division_type: 'xã',
            short_codename: 'an_thang',
            parentId: 60,
          },
          {
            title: 'Xã Cổ Linh',
            code: 1879,
            value: 'xa_co_linh',
            division_type: 'xã',
            short_codename: 'co_linh',
            parentId: 60,
          },
          {
            title: 'Xã Nghiên Loan',
            code: 1882,
            value: 'xa_nghien_loan',
            division_type: 'xã',
            short_codename: 'nghien_loan',
            parentId: 60,
          },
          {
            title: 'Xã Cao Tân',
            code: 1885,
            value: 'xa_cao_tan',
            division_type: 'xã',
            short_codename: 'cao_tan',
            parentId: 60,
          },
        ],
        parentId: 6,
      },
      {
        title: 'Huyện Ba Bể',
        code: 61,
        value: 'huyen_ba_be',
        division_type: 'huyện',
        short_codename: 'ba_be',
        children: [
          {
            title: 'Thị trấn Chợ Rã',
            code: 1888,
            value: 'thi_tran_cho_ra',
            division_type: 'thị trấn',
            short_codename: 'cho_ra',
            parentId: 61,
          },
          {
            title: 'Xã Bành Trạch',
            code: 1891,
            value: 'xa_banh_trach',
            division_type: 'xã',
            short_codename: 'banh_trach',
            parentId: 61,
          },
          {
            title: 'Xã Phúc Lộc',
            code: 1894,
            value: 'xa_phuc_loc',
            division_type: 'xã',
            short_codename: 'phuc_loc',
            parentId: 61,
          },
          {
            title: 'Xã Hà Hiệu',
            code: 1897,
            value: 'xa_ha_hieu',
            division_type: 'xã',
            short_codename: 'ha_hieu',
            parentId: 61,
          },
          {
            title: 'Xã Cao Thượng',
            code: 1900,
            value: 'xa_cao_thuong',
            division_type: 'xã',
            short_codename: 'cao_thuong',
            parentId: 61,
          },
          {
            title: 'Xã Khang Ninh',
            code: 1906,
            value: 'xa_khang_ninh',
            division_type: 'xã',
            short_codename: 'khang_ninh',
            parentId: 61,
          },
          {
            title: 'Xã Nam Mẫu',
            code: 1909,
            value: 'xa_nam_mau',
            division_type: 'xã',
            short_codename: 'nam_mau',
            parentId: 61,
          },
          {
            title: 'Xã Thượng Giáo',
            code: 1912,
            value: 'xa_thuong_giao',
            division_type: 'xã',
            short_codename: 'thuong_giao',
            parentId: 61,
          },
          {
            title: 'Xã Địa Linh',
            code: 1915,
            value: 'xa_dia_linh',
            division_type: 'xã',
            short_codename: 'dia_linh',
            parentId: 61,
          },
          {
            title: 'Xã Yến Dương',
            code: 1918,
            value: 'xa_yen_duong',
            division_type: 'xã',
            short_codename: 'yen_duong',
            parentId: 61,
          },
          {
            title: 'Xã Chu Hương',
            code: 1921,
            value: 'xa_chu_huong',
            division_type: 'xã',
            short_codename: 'chu_huong',
            parentId: 61,
          },
          {
            title: 'Xã Quảng Khê',
            code: 1924,
            value: 'xa_quang_khe',
            division_type: 'xã',
            short_codename: 'quang_khe',
            parentId: 61,
          },
          {
            title: 'Xã Mỹ Phương',
            code: 1927,
            value: 'xa_my_phuong',
            division_type: 'xã',
            short_codename: 'my_phuong',
            parentId: 61,
          },
          {
            title: 'Xã Hoàng Trĩ',
            code: 1930,
            value: 'xa_hoang_tri',
            division_type: 'xã',
            short_codename: 'hoang_tri',
            parentId: 61,
          },
          {
            title: 'Xã Đồng Phúc',
            code: 1933,
            value: 'xa_dong_phuc',
            division_type: 'xã',
            short_codename: 'dong_phuc',
            parentId: 61,
          },
        ],
        parentId: 6,
      },
      {
        title: 'Huyện Ngân Sơn',
        code: 62,
        value: 'huyen_ngan_son',
        division_type: 'huyện',
        short_codename: 'ngan_son',
        children: [
          {
            title: 'Thị trấn Nà Phặc',
            code: 1936,
            value: 'thi_tran_na_phac',
            division_type: 'thị trấn',
            short_codename: 'na_phac',
            parentId: 62,
          },
          {
            title: 'Xã Thượng Ân',
            code: 1939,
            value: 'xa_thuong_an',
            division_type: 'xã',
            short_codename: 'thuong_an',
            parentId: 62,
          },
          {
            title: 'Xã Bằng Vân',
            code: 1942,
            value: 'xa_bang_van',
            division_type: 'xã',
            short_codename: 'bang_van',
            parentId: 62,
          },
          {
            title: 'Xã Cốc Đán',
            code: 1945,
            value: 'xa_coc_dan',
            division_type: 'xã',
            short_codename: 'coc_dan',
            parentId: 62,
          },
          {
            title: 'Xã Trung Hoà',
            code: 1948,
            value: 'xa_trung_hoa',
            division_type: 'xã',
            short_codename: 'trung_hoa',
            parentId: 62,
          },
          {
            title: 'Xã Đức Vân',
            code: 1951,
            value: 'xa_duc_van',
            division_type: 'xã',
            short_codename: 'duc_van',
            parentId: 62,
          },
          {
            title: 'Xã Vân Tùng',
            code: 1954,
            value: 'xa_van_tung',
            division_type: 'xã',
            short_codename: 'van_tung',
            parentId: 62,
          },
          {
            title: 'Xã Thượng Quan',
            code: 1957,
            value: 'xa_thuong_quan',
            division_type: 'xã',
            short_codename: 'thuong_quan',
            parentId: 62,
          },
          {
            title: 'Xã Hiệp Lực',
            code: 1960,
            value: 'xa_hiep_luc',
            division_type: 'xã',
            short_codename: 'hiep_luc',
            parentId: 62,
          },
          {
            title: 'Xã Thuần Mang',
            code: 1963,
            value: 'xa_thuan_mang',
            division_type: 'xã',
            short_codename: 'thuan_mang',
            parentId: 62,
          },
        ],
        parentId: 6,
      },
      {
        title: 'Huyện Bạch Thông',
        code: 63,
        value: 'huyen_bach_thong',
        division_type: 'huyện',
        short_codename: 'bach_thong',
        children: [
          {
            title: 'Thị trấn Phủ Thông',
            code: 1969,
            value: 'thi_tran_phu_thong',
            division_type: 'thị trấn',
            short_codename: 'phu_thong',
            parentId: 63,
          },
          {
            title: 'Xã Vi Hương',
            code: 1975,
            value: 'xa_vi_huong',
            division_type: 'xã',
            short_codename: 'vi_huong',
            parentId: 63,
          },
          {
            title: 'Xã Sĩ Bình',
            code: 1978,
            value: 'xa_si_binh',
            division_type: 'xã',
            short_codename: 'si_binh',
            parentId: 63,
          },
          {
            title: 'Xã Vũ Muộn',
            code: 1981,
            value: 'xa_vu_muon',
            division_type: 'xã',
            short_codename: 'vu_muon',
            parentId: 63,
          },
          {
            title: 'Xã Đôn Phong',
            code: 1984,
            value: 'xa_don_phong',
            division_type: 'xã',
            short_codename: 'don_phong',
            parentId: 63,
          },
          {
            title: 'Xã Lục Bình',
            code: 1990,
            value: 'xa_luc_binh',
            division_type: 'xã',
            short_codename: 'luc_binh',
            parentId: 63,
          },
          {
            title: 'Xã Tân Tú',
            code: 1993,
            value: 'xa_tan_tu',
            division_type: 'xã',
            short_codename: 'tan_tu',
            parentId: 63,
          },
          {
            title: 'Xã Nguyên Phúc',
            code: 1999,
            value: 'xa_nguyen_phuc',
            division_type: 'xã',
            short_codename: 'nguyen_phuc',
            parentId: 63,
          },
          {
            title: 'Xã Cao Sơn',
            code: 2002,
            value: 'xa_cao_son',
            division_type: 'xã',
            short_codename: 'cao_son',
            parentId: 63,
          },
          {
            title: 'Xã Quân Hà',
            code: 2005,
            value: 'xa_quan_ha',
            division_type: 'xã',
            short_codename: 'quan_ha',
            parentId: 63,
          },
          {
            title: 'Xã Cẩm Giàng',
            code: 2008,
            value: 'xa_cam_giang',
            division_type: 'xã',
            short_codename: 'cam_giang',
            parentId: 63,
          },
          {
            title: 'Xã Mỹ Thanh',
            code: 2011,
            value: 'xa_my_thanh',
            division_type: 'xã',
            short_codename: 'my_thanh',
            parentId: 63,
          },
          {
            title: 'Xã Dương Phong',
            code: 2014,
            value: 'xa_duong_phong',
            division_type: 'xã',
            short_codename: 'duong_phong',
            parentId: 63,
          },
          {
            title: 'Xã Quang Thuận',
            code: 2017,
            value: 'xa_quang_thuan',
            division_type: 'xã',
            short_codename: 'quang_thuan',
            parentId: 63,
          },
        ],
        parentId: 6,
      },
      {
        title: 'Huyện Chợ Đồn',
        code: 64,
        value: 'huyen_cho_don',
        division_type: 'huyện',
        short_codename: 'cho_don',
        children: [
          {
            title: 'Thị trấn Bằng Lũng',
            code: 2020,
            value: 'thi_tran_bang_lung',
            division_type: 'thị trấn',
            short_codename: 'bang_lung',
            parentId: 64,
          },
          {
            title: 'Xã Xuân Lạc',
            code: 2023,
            value: 'xa_xuan_lac',
            division_type: 'xã',
            short_codename: 'xuan_lac',
            parentId: 64,
          },
          {
            title: 'Xã Nam Cường',
            code: 2026,
            value: 'xa_nam_cuong',
            division_type: 'xã',
            short_codename: 'nam_cuong',
            parentId: 64,
          },
          {
            title: 'Xã Đồng Lạc',
            code: 2029,
            value: 'xa_dong_lac',
            division_type: 'xã',
            short_codename: 'dong_lac',
            parentId: 64,
          },
          {
            title: 'Xã Tân Lập',
            code: 2032,
            value: 'xa_tan_lap',
            division_type: 'xã',
            short_codename: 'tan_lap',
            parentId: 64,
          },
          {
            title: 'Xã Bản Thi',
            code: 2035,
            value: 'xa_ban_thi',
            division_type: 'xã',
            short_codename: 'ban_thi',
            parentId: 64,
          },
          {
            title: 'Xã Quảng Bạch',
            code: 2038,
            value: 'xa_quang_bach',
            division_type: 'xã',
            short_codename: 'quang_bach',
            parentId: 64,
          },
          {
            title: 'Xã Bằng Phúc',
            code: 2041,
            value: 'xa_bang_phuc',
            division_type: 'xã',
            short_codename: 'bang_phuc',
            parentId: 64,
          },
          {
            title: 'Xã Yên Thịnh',
            code: 2044,
            value: 'xa_yen_thinh',
            division_type: 'xã',
            short_codename: 'yen_thinh',
            parentId: 64,
          },
          {
            title: 'Xã Yên Thượng',
            code: 2047,
            value: 'xa_yen_thuong',
            division_type: 'xã',
            short_codename: 'yen_thuong',
            parentId: 64,
          },
          {
            title: 'Xã Phương Viên',
            code: 2050,
            value: 'xa_phuong_vien',
            division_type: 'xã',
            short_codename: 'phuong_vien',
            parentId: 64,
          },
          {
            title: 'Xã Ngọc Phái',
            code: 2053,
            value: 'xa_ngoc_phai',
            division_type: 'xã',
            short_codename: 'ngoc_phai',
            parentId: 64,
          },
          {
            title: 'Xã Đồng Thắng',
            code: 2059,
            value: 'xa_dong_thang',
            division_type: 'xã',
            short_codename: 'dong_thang',
            parentId: 64,
          },
          {
            title: 'Xã Lương Bằng',
            code: 2062,
            value: 'xa_luong_bang',
            division_type: 'xã',
            short_codename: 'luong_bang',
            parentId: 64,
          },
          {
            title: 'Xã Bằng Lãng',
            code: 2065,
            value: 'xa_bang_lang',
            division_type: 'xã',
            short_codename: 'bang_lang',
            parentId: 64,
          },
          {
            title: 'Xã Đại Sảo',
            code: 2068,
            value: 'xa_dai_sao',
            division_type: 'xã',
            short_codename: 'dai_sao',
            parentId: 64,
          },
          {
            title: 'Xã Nghĩa Tá',
            code: 2071,
            value: 'xa_nghia_ta',
            division_type: 'xã',
            short_codename: 'nghia_ta',
            parentId: 64,
          },
          {
            title: 'Xã Yên Mỹ',
            code: 2077,
            value: 'xa_yen_my',
            division_type: 'xã',
            short_codename: 'yen_my',
            parentId: 64,
          },
          {
            title: 'Xã Bình Trung',
            code: 2080,
            value: 'xa_binh_trung',
            division_type: 'xã',
            short_codename: 'binh_trung',
            parentId: 64,
          },
          {
            title: 'Xã Yên Phong',
            code: 2083,
            value: 'xa_yen_phong',
            division_type: 'xã',
            short_codename: 'yen_phong',
            parentId: 64,
          },
        ],
        parentId: 6,
      },
      {
        title: 'Huyện Chợ Mới',
        code: 65,
        value: 'huyen_cho_moi',
        division_type: 'huyện',
        short_codename: 'cho_moi',
        children: [
          {
            title: 'Thị trấn Đồng Tâm',
            code: 2086,
            value: 'thi_tran_dong_tam',
            division_type: 'thị trấn',
            short_codename: 'dong_tam',
            parentId: 65,
          },
          {
            title: 'Xã Tân Sơn',
            code: 2089,
            value: 'xa_tan_son',
            division_type: 'xã',
            short_codename: 'tan_son',
            parentId: 65,
          },
          {
            title: 'Xã Thanh Vận',
            code: 2092,
            value: 'xa_thanh_van',
            division_type: 'xã',
            short_codename: 'thanh_van',
            parentId: 65,
          },
          {
            title: 'Xã Mai Lạp',
            code: 2095,
            value: 'xa_mai_lap',
            division_type: 'xã',
            short_codename: 'mai_lap',
            parentId: 65,
          },
          {
            title: 'Xã Hoà Mục',
            code: 2098,
            value: 'xa_hoa_muc',
            division_type: 'xã',
            short_codename: 'hoa_muc',
            parentId: 65,
          },
          {
            title: 'Xã Thanh Mai',
            code: 2101,
            value: 'xa_thanh_mai',
            division_type: 'xã',
            short_codename: 'thanh_mai',
            parentId: 65,
          },
          {
            title: 'Xã Cao Kỳ',
            code: 2104,
            value: 'xa_cao_ky',
            division_type: 'xã',
            short_codename: 'cao_ky',
            parentId: 65,
          },
          {
            title: 'Xã Nông Hạ',
            code: 2107,
            value: 'xa_nong_ha',
            division_type: 'xã',
            short_codename: 'nong_ha',
            parentId: 65,
          },
          {
            title: 'Xã Yên Cư',
            code: 2110,
            value: 'xa_yen_cu',
            division_type: 'xã',
            short_codename: 'yen_cu',
            parentId: 65,
          },
          {
            title: 'Xã Thanh Thịnh',
            code: 2113,
            value: 'xa_thanh_thinh',
            division_type: 'xã',
            short_codename: 'thanh_thinh',
            parentId: 65,
          },
          {
            title: 'Xã Yên Hân',
            code: 2116,
            value: 'xa_yen_han',
            division_type: 'xã',
            short_codename: 'yen_han',
            parentId: 65,
          },
          {
            title: 'Xã Như Cố',
            code: 2122,
            value: 'xa_nhu_co',
            division_type: 'xã',
            short_codename: 'nhu_co',
            parentId: 65,
          },
          {
            title: 'Xã Bình Văn',
            code: 2125,
            value: 'xa_binh_van',
            division_type: 'xã',
            short_codename: 'binh_van',
            parentId: 65,
          },
          {
            title: 'Xã Quảng Chu',
            code: 2131,
            value: 'xa_quang_chu',
            division_type: 'xã',
            short_codename: 'quang_chu',
            parentId: 65,
          },
        ],
        parentId: 6,
      },
      {
        title: 'Huyện Na Rì',
        code: 66,
        value: 'huyen_na_ri',
        division_type: 'huyện',
        short_codename: 'na_ri',
        children: [
          {
            title: 'Xã Văn Vũ',
            code: 2137,
            value: 'xa_van_vu',
            division_type: 'xã',
            short_codename: 'van_vu',
            parentId: 66,
          },
          {
            title: 'Xã Văn Lang',
            code: 2140,
            value: 'xa_van_lang',
            division_type: 'xã',
            short_codename: 'van_lang',
            parentId: 66,
          },
          {
            title: 'Xã Lương Thượng',
            code: 2143,
            value: 'xa_luong_thuong',
            division_type: 'xã',
            short_codename: 'luong_thuong',
            parentId: 66,
          },
          {
            title: 'Xã Kim Hỷ',
            code: 2146,
            value: 'xa_kim_hy',
            division_type: 'xã',
            short_codename: 'kim_hy',
            parentId: 66,
          },
          {
            title: 'Xã Cường Lợi',
            code: 2152,
            value: 'xa_cuong_loi',
            division_type: 'xã',
            short_codename: 'cuong_loi',
            parentId: 66,
          },
          {
            title: 'Thị trấn Yến Lạc',
            code: 2155,
            value: 'thi_tran_yen_lac',
            division_type: 'thị trấn',
            short_codename: 'yen_lac',
            parentId: 66,
          },
          {
            title: 'Xã Kim Lư',
            code: 2158,
            value: 'xa_kim_lu',
            division_type: 'xã',
            short_codename: 'kim_lu',
            parentId: 66,
          },
          {
            title: 'Xã Sơn Thành',
            code: 2161,
            value: 'xa_son_thanh',
            division_type: 'xã',
            short_codename: 'son_thanh',
            parentId: 66,
          },
          {
            title: 'Xã Văn Minh',
            code: 2170,
            value: 'xa_van_minh',
            division_type: 'xã',
            short_codename: 'van_minh',
            parentId: 66,
          },
          {
            title: 'Xã Côn Minh',
            code: 2173,
            value: 'xa_con_minh',
            division_type: 'xã',
            short_codename: 'con_minh',
            parentId: 66,
          },
          {
            title: 'Xã Cư Lễ',
            code: 2176,
            value: 'xa_cu_le',
            division_type: 'xã',
            short_codename: 'cu_le',
            parentId: 66,
          },
          {
            title: 'Xã Trần Phú',
            code: 2179,
            value: 'xa_tran_phu',
            division_type: 'xã',
            short_codename: 'tran_phu',
            parentId: 66,
          },
          {
            title: 'Xã Quang Phong',
            code: 2185,
            value: 'xa_quang_phong',
            division_type: 'xã',
            short_codename: 'quang_phong',
            parentId: 66,
          },
          {
            title: 'Xã Dương Sơn',
            code: 2188,
            value: 'xa_duong_son',
            division_type: 'xã',
            short_codename: 'duong_son',
            parentId: 66,
          },
          {
            title: 'Xã Xuân Dương',
            code: 2191,
            value: 'xa_xuan_duong',
            division_type: 'xã',
            short_codename: 'xuan_duong',
            parentId: 66,
          },
          {
            title: 'Xã Đổng Xá',
            code: 2194,
            value: 'xa_dong_xa',
            division_type: 'xã',
            short_codename: 'dong_xa',
            parentId: 66,
          },
          {
            title: 'Xã Liêm Thuỷ',
            code: 2197,
            value: 'xa_liem_thuy',
            division_type: 'xã',
            short_codename: 'liem_thuy',
            parentId: 66,
          },
        ],
        parentId: 6,
      },
    ],
  },
  {
    title: 'Tỉnh Tuyên Quang',
    code: 8,
    value: 'tinh_tuyen_quang',
    division_type: 'tỉnh',
    phone_code: 207,
    children: [
      {
        title: 'Thành phố Tuyên Quang',
        code: 70,
        value: 'thanh_pho_tuyen_quang',
        division_type: 'thành phố',
        short_codename: 'tuyen_quang',
        children: [
          {
            title: 'Phường Phan Thiết',
            code: 2200,
            value: 'phuong_phan_thiet',
            division_type: 'phường',
            short_codename: 'phan_thiet',
            parentId: 70,
          },
          {
            title: 'Phường Minh Xuân',
            code: 2203,
            value: 'phuong_minh_xuan',
            division_type: 'phường',
            short_codename: 'minh_xuan',
            parentId: 70,
          },
          {
            title: 'Phường Tân Quang',
            code: 2206,
            value: 'phuong_tan_quang',
            division_type: 'phường',
            short_codename: 'tan_quang',
            parentId: 70,
          },
          {
            title: 'Xã Tràng Đà',
            code: 2209,
            value: 'xa_trang_da',
            division_type: 'xã',
            short_codename: 'trang_da',
            parentId: 70,
          },
          {
            title: 'Phường Nông Tiến',
            code: 2212,
            value: 'phuong_nong_tien',
            division_type: 'phường',
            short_codename: 'nong_tien',
            parentId: 70,
          },
          {
            title: 'Phường Ỷ La',
            code: 2215,
            value: 'phuong_y_la',
            division_type: 'phường',
            short_codename: 'y_la',
            parentId: 70,
          },
          {
            title: 'Phường Tân Hà',
            code: 2216,
            value: 'phuong_tan_ha',
            division_type: 'phường',
            short_codename: 'tan_ha',
            parentId: 70,
          },
          {
            title: 'Phường Hưng Thành',
            code: 2218,
            value: 'phuong_hung_thanh',
            division_type: 'phường',
            short_codename: 'hung_thanh',
            parentId: 70,
          },
          {
            title: 'Xã Kim Phú',
            code: 2497,
            value: 'xa_kim_phu',
            division_type: 'xã',
            short_codename: 'kim_phu',
            parentId: 70,
          },
          {
            title: 'Xã An Khang',
            code: 2503,
            value: 'xa_an_khang',
            division_type: 'xã',
            short_codename: 'an_khang',
            parentId: 70,
          },
          {
            title: 'Phường Mỹ Lâm',
            code: 2509,
            value: 'phuong_my_lam',
            division_type: 'phường',
            short_codename: 'my_lam',
            parentId: 70,
          },
          {
            title: 'Phường An Tường',
            code: 2512,
            value: 'phuong_an_tuong',
            division_type: 'phường',
            short_codename: 'an_tuong',
            parentId: 70,
          },
          {
            title: 'Xã Lưỡng Vượng',
            code: 2515,
            value: 'xa_luong_vuong',
            division_type: 'xã',
            short_codename: 'luong_vuong',
            parentId: 70,
          },
          {
            title: 'Xã Thái Long',
            code: 2521,
            value: 'xa_thai_long',
            division_type: 'xã',
            short_codename: 'thai_long',
            parentId: 70,
          },
          {
            title: 'Phường Đội Cấn',
            code: 2524,
            value: 'phuong_doi_can',
            division_type: 'phường',
            short_codename: 'doi_can',
            parentId: 70,
          },
        ],
        parentId: 8,
      },
      {
        title: 'Huyện Lâm Bình',
        code: 71,
        value: 'huyen_lam_binh',
        division_type: 'huyện',
        short_codename: 'lam_binh',
        children: [
          {
            title: 'Xã Phúc Yên',
            code: 2233,
            value: 'xa_phuc_yen',
            division_type: 'xã',
            short_codename: 'phuc_yen',
            parentId: 71,
          },
          {
            title: 'Xã Xuân Lập',
            code: 2242,
            value: 'xa_xuan_lap',
            division_type: 'xã',
            short_codename: 'xuan_lap',
            parentId: 71,
          },
          {
            title: 'Xã Khuôn Hà',
            code: 2251,
            value: 'xa_khuon_ha',
            division_type: 'xã',
            short_codename: 'khuon_ha',
            parentId: 71,
          },
          {
            title: 'Xã Lăng Can',
            code: 2266,
            value: 'xa_lang_can',
            division_type: 'xã',
            short_codename: 'lang_can',
            parentId: 71,
          },
          {
            title: 'Xã Thượng Lâm',
            code: 2269,
            value: 'xa_thuong_lam',
            division_type: 'xã',
            short_codename: 'thuong_lam',
            parentId: 71,
          },
          {
            title: 'Xã Bình An',
            code: 2290,
            value: 'xa_binh_an',
            division_type: 'xã',
            short_codename: 'binh_an',
            parentId: 71,
          },
          {
            title: 'Xã Hồng Quang',
            code: 2293,
            value: 'xa_hong_quang',
            division_type: 'xã',
            short_codename: 'hong_quang',
            parentId: 71,
          },
          {
            title: 'Xã Thổ Bình',
            code: 2296,
            value: 'xa_tho_binh',
            division_type: 'xã',
            short_codename: 'tho_binh',
            parentId: 71,
          },
        ],
        parentId: 8,
      },
      {
        title: 'Huyện Na Hang',
        code: 72,
        value: 'huyen_na_hang',
        division_type: 'huyện',
        short_codename: 'na_hang',
        children: [
          {
            title: 'Thị trấn Na Hang',
            code: 2221,
            value: 'thi_tran_na_hang',
            division_type: 'thị trấn',
            short_codename: 'na_hang',
            parentId: 72,
          },
          {
            title: 'Xã Sinh Long',
            code: 2227,
            value: 'xa_sinh_long',
            division_type: 'xã',
            short_codename: 'sinh_long',
            parentId: 72,
          },
          {
            title: 'Xã Thượng Giáp',
            code: 2230,
            value: 'xa_thuong_giap',
            division_type: 'xã',
            short_codename: 'thuong_giap',
            parentId: 72,
          },
          {
            title: 'Xã Thượng Nông',
            code: 2239,
            value: 'xa_thuong_nong',
            division_type: 'xã',
            short_codename: 'thuong_nong',
            parentId: 72,
          },
          {
            title: 'Xã Côn Lôn',
            code: 2245,
            value: 'xa_con_lon',
            division_type: 'xã',
            short_codename: 'con_lon',
            parentId: 72,
          },
          {
            title: 'Xã Yên Hoa',
            code: 2248,
            value: 'xa_yen_hoa',
            division_type: 'xã',
            short_codename: 'yen_hoa',
            parentId: 72,
          },
          {
            title: 'Xã Hồng Thái',
            code: 2254,
            value: 'xa_hong_thai',
            division_type: 'xã',
            short_codename: 'hong_thai',
            parentId: 72,
          },
          {
            title: 'Xã Đà Vị',
            code: 2260,
            value: 'xa_da_vi',
            division_type: 'xã',
            short_codename: 'da_vi',
            parentId: 72,
          },
          {
            title: 'Xã Khau Tinh',
            code: 2263,
            value: 'xa_khau_tinh',
            division_type: 'xã',
            short_codename: 'khau_tinh',
            parentId: 72,
          },
          {
            title: 'Xã Sơn Phú',
            code: 2275,
            value: 'xa_son_phu',
            division_type: 'xã',
            short_codename: 'son_phu',
            parentId: 72,
          },
          {
            title: 'Xã Năng Khả',
            code: 2281,
            value: 'xa_nang_kha',
            division_type: 'xã',
            short_codename: 'nang_kha',
            parentId: 72,
          },
          {
            title: 'Xã Thanh Tương',
            code: 2284,
            value: 'xa_thanh_tuong',
            division_type: 'xã',
            short_codename: 'thanh_tuong',
            parentId: 72,
          },
        ],
        parentId: 8,
      },
      {
        title: 'Huyện Chiêm Hóa',
        code: 73,
        value: 'huyen_chiem_hoa',
        division_type: 'huyện',
        short_codename: 'chiem_hoa',
        children: [
          {
            title: 'Thị trấn Vĩnh Lộc',
            code: 2287,
            value: 'thi_tran_vinh_loc',
            division_type: 'thị trấn',
            short_codename: 'vinh_loc',
            parentId: 73,
          },
          {
            title: 'Xã Phúc Sơn',
            code: 2299,
            value: 'xa_phuc_son',
            division_type: 'xã',
            short_codename: 'phuc_son',
            parentId: 73,
          },
          {
            title: 'Xã Minh Quang',
            code: 2302,
            value: 'xa_minh_quang',
            division_type: 'xã',
            short_codename: 'minh_quang',
            parentId: 73,
          },
          {
            title: 'Xã Trung Hà',
            code: 2305,
            value: 'xa_trung_ha',
            division_type: 'xã',
            short_codename: 'trung_ha',
            parentId: 73,
          },
          {
            title: 'Xã Tân Mỹ',
            code: 2308,
            value: 'xa_tan_my',
            division_type: 'xã',
            short_codename: 'tan_my',
            parentId: 73,
          },
          {
            title: 'Xã Hà Lang',
            code: 2311,
            value: 'xa_ha_lang',
            division_type: 'xã',
            short_codename: 'ha_lang',
            parentId: 73,
          },
          {
            title: 'Xã Hùng Mỹ',
            code: 2314,
            value: 'xa_hung_my',
            division_type: 'xã',
            short_codename: 'hung_my',
            parentId: 73,
          },
          {
            title: 'Xã Yên Lập',
            code: 2317,
            value: 'xa_yen_lap',
            division_type: 'xã',
            short_codename: 'yen_lap',
            parentId: 73,
          },
          {
            title: 'Xã Tân An',
            code: 2320,
            value: 'xa_tan_an',
            division_type: 'xã',
            short_codename: 'tan_an',
            parentId: 73,
          },
          {
            title: 'Xã Bình Phú',
            code: 2323,
            value: 'xa_binh_phu',
            division_type: 'xã',
            short_codename: 'binh_phu',
            parentId: 73,
          },
          {
            title: 'Xã Xuân Quang',
            code: 2326,
            value: 'xa_xuan_quang',
            division_type: 'xã',
            short_codename: 'xuan_quang',
            parentId: 73,
          },
          {
            title: 'Xã Ngọc Hội',
            code: 2329,
            value: 'xa_ngoc_hoi',
            division_type: 'xã',
            short_codename: 'ngoc_hoi',
            parentId: 73,
          },
          {
            title: 'Xã Phú Bình',
            code: 2332,
            value: 'xa_phu_binh',
            division_type: 'xã',
            short_codename: 'phu_binh',
            parentId: 73,
          },
          {
            title: 'Xã Hòa Phú',
            code: 2335,
            value: 'xa_hoa_phu',
            division_type: 'xã',
            short_codename: 'hoa_phu',
            parentId: 73,
          },
          {
            title: 'Xã Phúc Thịnh',
            code: 2338,
            value: 'xa_phuc_thinh',
            division_type: 'xã',
            short_codename: 'phuc_thinh',
            parentId: 73,
          },
          {
            title: 'Xã Kiên Đài',
            code: 2341,
            value: 'xa_kien_dai',
            division_type: 'xã',
            short_codename: 'kien_dai',
            parentId: 73,
          },
          {
            title: 'Xã Tân Thịnh',
            code: 2344,
            value: 'xa_tan_thinh',
            division_type: 'xã',
            short_codename: 'tan_thinh',
            parentId: 73,
          },
          {
            title: 'Xã Trung Hòa',
            code: 2347,
            value: 'xa_trung_hoa',
            division_type: 'xã',
            short_codename: 'trung_hoa',
            parentId: 73,
          },
          {
            title: 'Xã Kim Bình',
            code: 2350,
            value: 'xa_kim_binh',
            division_type: 'xã',
            short_codename: 'kim_binh',
            parentId: 73,
          },
          {
            title: 'Xã Hòa An',
            code: 2353,
            value: 'xa_hoa_an',
            division_type: 'xã',
            short_codename: 'hoa_an',
            parentId: 73,
          },
          {
            title: 'Xã Vinh Quang',
            code: 2356,
            value: 'xa_vinh_quang',
            division_type: 'xã',
            short_codename: 'vinh_quang',
            parentId: 73,
          },
          {
            title: 'Xã Tri Phú',
            code: 2359,
            value: 'xa_tri_phu',
            division_type: 'xã',
            short_codename: 'tri_phu',
            parentId: 73,
          },
          {
            title: 'Xã Nhân Lý',
            code: 2362,
            value: 'xa_nhan_ly',
            division_type: 'xã',
            short_codename: 'nhan_ly',
            parentId: 73,
          },
          {
            title: 'Xã Yên Nguyên',
            code: 2365,
            value: 'xa_yen_nguyen',
            division_type: 'xã',
            short_codename: 'yen_nguyen',
            parentId: 73,
          },
          {
            title: 'Xã Linh Phú',
            code: 2368,
            value: 'xa_linh_phu',
            division_type: 'xã',
            short_codename: 'linh_phu',
            parentId: 73,
          },
          {
            title: 'Xã Bình Nhân',
            code: 2371,
            value: 'xa_binh_nhan',
            division_type: 'xã',
            short_codename: 'binh_nhan',
            parentId: 73,
          },
        ],
        parentId: 8,
      },
      {
        title: 'Huyện Hàm Yên',
        code: 74,
        value: 'huyen_ham_yen',
        division_type: 'huyện',
        short_codename: 'ham_yen',
        children: [
          {
            title: 'Thị trấn Tân Yên',
            code: 2374,
            value: 'thi_tran_tan_yen',
            division_type: 'thị trấn',
            short_codename: 'tan_yen',
            parentId: 74,
          },
          {
            title: 'Xã Yên Thuận',
            code: 2377,
            value: 'xa_yen_thuan',
            division_type: 'xã',
            short_codename: 'yen_thuan',
            parentId: 74,
          },
          {
            title: 'Xã Bạch Xa',
            code: 2380,
            value: 'xa_bach_xa',
            division_type: 'xã',
            short_codename: 'bach_xa',
            parentId: 74,
          },
          {
            title: 'Xã Minh Khương',
            code: 2383,
            value: 'xa_minh_khuong',
            division_type: 'xã',
            short_codename: 'minh_khuong',
            parentId: 74,
          },
          {
            title: 'Xã Yên Lâm',
            code: 2386,
            value: 'xa_yen_lam',
            division_type: 'xã',
            short_codename: 'yen_lam',
            parentId: 74,
          },
          {
            title: 'Xã Minh Dân',
            code: 2389,
            value: 'xa_minh_dan',
            division_type: 'xã',
            short_codename: 'minh_dan',
            parentId: 74,
          },
          {
            title: 'Xã Phù Lưu',
            code: 2392,
            value: 'xa_phu_luu',
            division_type: 'xã',
            short_codename: 'phu_luu',
            parentId: 74,
          },
          {
            title: 'Xã Minh Hương',
            code: 2395,
            value: 'xa_minh_huong',
            division_type: 'xã',
            short_codename: 'minh_huong',
            parentId: 74,
          },
          {
            title: 'Xã Yên Phú',
            code: 2398,
            value: 'xa_yen_phu',
            division_type: 'xã',
            short_codename: 'yen_phu',
            parentId: 74,
          },
          {
            title: 'Xã Tân Thành',
            code: 2401,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 74,
          },
          {
            title: 'Xã Bình Xa',
            code: 2404,
            value: 'xa_binh_xa',
            division_type: 'xã',
            short_codename: 'binh_xa',
            parentId: 74,
          },
          {
            title: 'Xã Thái Sơn',
            code: 2407,
            value: 'xa_thai_son',
            division_type: 'xã',
            short_codename: 'thai_son',
            parentId: 74,
          },
          {
            title: 'Xã Nhân Mục',
            code: 2410,
            value: 'xa_nhan_muc',
            division_type: 'xã',
            short_codename: 'nhan_muc',
            parentId: 74,
          },
          {
            title: 'Xã Thành Long',
            code: 2413,
            value: 'xa_thanh_long',
            division_type: 'xã',
            short_codename: 'thanh_long',
            parentId: 74,
          },
          {
            title: 'Xã Bằng Cốc',
            code: 2416,
            value: 'xa_bang_coc',
            division_type: 'xã',
            short_codename: 'bang_coc',
            parentId: 74,
          },
          {
            title: 'Xã Thái Hòa',
            code: 2419,
            value: 'xa_thai_hoa',
            division_type: 'xã',
            short_codename: 'thai_hoa',
            parentId: 74,
          },
          {
            title: 'Xã Đức Ninh',
            code: 2422,
            value: 'xa_duc_ninh',
            division_type: 'xã',
            short_codename: 'duc_ninh',
            parentId: 74,
          },
          {
            title: 'Xã Hùng Đức',
            code: 2425,
            value: 'xa_hung_duc',
            division_type: 'xã',
            short_codename: 'hung_duc',
            parentId: 74,
          },
        ],
        parentId: 8,
      },
      {
        title: 'Huyện Yên Sơn',
        code: 75,
        value: 'huyen_yen_son',
        division_type: 'huyện',
        short_codename: 'yen_son',
        children: [
          {
            title: 'Xã Quí Quân',
            code: 2431,
            value: 'xa_qui_quan',
            division_type: 'xã',
            short_codename: 'qui_quan',
            parentId: 75,
          },
          {
            title: 'Xã Lực Hành',
            code: 2434,
            value: 'xa_luc_hanh',
            division_type: 'xã',
            short_codename: 'luc_hanh',
            parentId: 75,
          },
          {
            title: 'Xã Kiến Thiết',
            code: 2437,
            value: 'xa_kien_thiet',
            division_type: 'xã',
            short_codename: 'kien_thiet',
            parentId: 75,
          },
          {
            title: 'Xã Trung Minh',
            code: 2440,
            value: 'xa_trung_minh',
            division_type: 'xã',
            short_codename: 'trung_minh',
            parentId: 75,
          },
          {
            title: 'Xã Chiêu Yên',
            code: 2443,
            value: 'xa_chieu_yen',
            division_type: 'xã',
            short_codename: 'chieu_yen',
            parentId: 75,
          },
          {
            title: 'Xã Trung Trực',
            code: 2446,
            value: 'xa_trung_truc',
            division_type: 'xã',
            short_codename: 'trung_truc',
            parentId: 75,
          },
          {
            title: 'Xã Xuân Vân',
            code: 2449,
            value: 'xa_xuan_van',
            division_type: 'xã',
            short_codename: 'xuan_van',
            parentId: 75,
          },
          {
            title: 'Xã Phúc Ninh',
            code: 2452,
            value: 'xa_phuc_ninh',
            division_type: 'xã',
            short_codename: 'phuc_ninh',
            parentId: 75,
          },
          {
            title: 'Xã Hùng Lợi',
            code: 2455,
            value: 'xa_hung_loi',
            division_type: 'xã',
            short_codename: 'hung_loi',
            parentId: 75,
          },
          {
            title: 'Xã Trung Sơn',
            code: 2458,
            value: 'xa_trung_son',
            division_type: 'xã',
            short_codename: 'trung_son',
            parentId: 75,
          },
          {
            title: 'Xã Tân Tiến',
            code: 2461,
            value: 'xa_tan_tien',
            division_type: 'xã',
            short_codename: 'tan_tien',
            parentId: 75,
          },
          {
            title: 'Xã Tứ Quận',
            code: 2464,
            value: 'xa_tu_quan',
            division_type: 'xã',
            short_codename: 'tu_quan',
            parentId: 75,
          },
          {
            title: 'Xã Đạo Viện',
            code: 2467,
            value: 'xa_dao_vien',
            division_type: 'xã',
            short_codename: 'dao_vien',
            parentId: 75,
          },
          {
            title: 'Xã Tân Long',
            code: 2470,
            value: 'xa_tan_long',
            division_type: 'xã',
            short_codename: 'tan_long',
            parentId: 75,
          },
          {
            title: 'Xã Thắng Quân',
            code: 2473,
            value: 'xa_thang_quan',
            division_type: 'xã',
            short_codename: 'thang_quan',
            parentId: 75,
          },
          {
            title: 'Xã Kim Quan',
            code: 2476,
            value: 'xa_kim_quan',
            division_type: 'xã',
            short_codename: 'kim_quan',
            parentId: 75,
          },
          {
            title: 'Xã Lang Quán',
            code: 2479,
            value: 'xa_lang_quan',
            division_type: 'xã',
            short_codename: 'lang_quan',
            parentId: 75,
          },
          {
            title: 'Xã Phú Thịnh',
            code: 2482,
            value: 'xa_phu_thinh',
            division_type: 'xã',
            short_codename: 'phu_thinh',
            parentId: 75,
          },
          {
            title: 'Xã Công Đa',
            code: 2485,
            value: 'xa_cong_da',
            division_type: 'xã',
            short_codename: 'cong_da',
            parentId: 75,
          },
          {
            title: 'Xã Trung Môn',
            code: 2488,
            value: 'xa_trung_mon',
            division_type: 'xã',
            short_codename: 'trung_mon',
            parentId: 75,
          },
          {
            title: 'Xã Chân Sơn',
            code: 2491,
            value: 'xa_chan_son',
            division_type: 'xã',
            short_codename: 'chan_son',
            parentId: 75,
          },
          {
            title: 'Xã Thái Bình',
            code: 2494,
            value: 'xa_thai_binh',
            division_type: 'xã',
            short_codename: 'thai_binh',
            parentId: 75,
          },
          {
            title: 'Xã Tiến Bộ',
            code: 2500,
            value: 'xa_tien_bo',
            division_type: 'xã',
            short_codename: 'tien_bo',
            parentId: 75,
          },
          {
            title: 'Xã Mỹ Bằng',
            code: 2506,
            value: 'xa_my_bang',
            division_type: 'xã',
            short_codename: 'my_bang',
            parentId: 75,
          },
          {
            title: 'Xã Hoàng Khai',
            code: 2518,
            value: 'xa_hoang_khai',
            division_type: 'xã',
            short_codename: 'hoang_khai',
            parentId: 75,
          },
          {
            title: 'Xã Nhữ Hán',
            code: 2527,
            value: 'xa_nhu_han',
            division_type: 'xã',
            short_codename: 'nhu_han',
            parentId: 75,
          },
          {
            title: 'Xã Nhữ Khê',
            code: 2530,
            value: 'xa_nhu_khe',
            division_type: 'xã',
            short_codename: 'nhu_khe',
            parentId: 75,
          },
          {
            title: 'Xã Đội Bình',
            code: 2533,
            value: 'xa_doi_binh',
            division_type: 'xã',
            short_codename: 'doi_binh',
            parentId: 75,
          },
        ],
        parentId: 8,
      },
      {
        title: 'Huyện Sơn Dương',
        code: 76,
        value: 'huyen_son_duong',
        division_type: 'huyện',
        short_codename: 'son_duong',
        children: [
          {
            title: 'Thị trấn Sơn Dương',
            code: 2536,
            value: 'thi_tran_son_duong',
            division_type: 'thị trấn',
            short_codename: 'son_duong',
            parentId: 76,
          },
          {
            title: 'Xã Trung Yên',
            code: 2539,
            value: 'xa_trung_yen',
            division_type: 'xã',
            short_codename: 'trung_yen',
            parentId: 76,
          },
          {
            title: 'Xã Minh Thanh',
            code: 2542,
            value: 'xa_minh_thanh',
            division_type: 'xã',
            short_codename: 'minh_thanh',
            parentId: 76,
          },
          {
            title: 'Xã Tân Trào',
            code: 2545,
            value: 'xa_tan_trao',
            division_type: 'xã',
            short_codename: 'tan_trao',
            parentId: 76,
          },
          {
            title: 'Xã Vĩnh Lợi',
            code: 2548,
            value: 'xa_vinh_loi',
            division_type: 'xã',
            short_codename: 'vinh_loi',
            parentId: 76,
          },
          {
            title: 'Xã Thượng Ấm',
            code: 2551,
            value: 'xa_thuong_am',
            division_type: 'xã',
            short_codename: 'thuong_am',
            parentId: 76,
          },
          {
            title: 'Xã Bình Yên',
            code: 2554,
            value: 'xa_binh_yen',
            division_type: 'xã',
            short_codename: 'binh_yen',
            parentId: 76,
          },
          {
            title: 'Xã Lương Thiện',
            code: 2557,
            value: 'xa_luong_thien',
            division_type: 'xã',
            short_codename: 'luong_thien',
            parentId: 76,
          },
          {
            title: 'Xã Tú Thịnh',
            code: 2560,
            value: 'xa_tu_thinh',
            division_type: 'xã',
            short_codename: 'tu_thinh',
            parentId: 76,
          },
          {
            title: 'Xã Cấp Tiến',
            code: 2563,
            value: 'xa_cap_tien',
            division_type: 'xã',
            short_codename: 'cap_tien',
            parentId: 76,
          },
          {
            title: 'Xã Hợp Thành',
            code: 2566,
            value: 'xa_hop_thanh',
            division_type: 'xã',
            short_codename: 'hop_thanh',
            parentId: 76,
          },
          {
            title: 'Xã Phúc Ứng',
            code: 2569,
            value: 'xa_phuc_ung',
            division_type: 'xã',
            short_codename: 'phuc_ung',
            parentId: 76,
          },
          {
            title: 'Xã Đông Thọ',
            code: 2572,
            value: 'xa_dong_tho',
            division_type: 'xã',
            short_codename: 'dong_tho',
            parentId: 76,
          },
          {
            title: 'Xã Kháng Nhật',
            code: 2575,
            value: 'xa_khang_nhat',
            division_type: 'xã',
            short_codename: 'khang_nhat',
            parentId: 76,
          },
          {
            title: 'Xã Hợp Hòa',
            code: 2578,
            value: 'xa_hop_hoa',
            division_type: 'xã',
            short_codename: 'hop_hoa',
            parentId: 76,
          },
          {
            title: 'Xã Quyết Thắng',
            code: 2584,
            value: 'xa_quyet_thang',
            division_type: 'xã',
            short_codename: 'quyet_thang',
            parentId: 76,
          },
          {
            title: 'Xã Đồng Quý',
            code: 2587,
            value: 'xa_dong_quy',
            division_type: 'xã',
            short_codename: 'dong_quy',
            parentId: 76,
          },
          {
            title: 'Xã Tân Thanh',
            code: 2590,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 76,
          },
          {
            title: 'Xã Vân Sơn',
            code: 2593,
            value: 'xa_van_son',
            division_type: 'xã',
            short_codename: 'van_son',
            parentId: 76,
          },
          {
            title: 'Xã Văn Phú',
            code: 2596,
            value: 'xa_van_phu',
            division_type: 'xã',
            short_codename: 'van_phu',
            parentId: 76,
          },
          {
            title: 'Xã Chi Thiết',
            code: 2599,
            value: 'xa_chi_thiet',
            division_type: 'xã',
            short_codename: 'chi_thiet',
            parentId: 76,
          },
          {
            title: 'Xã Đông Lợi',
            code: 2602,
            value: 'xa_dong_loi',
            division_type: 'xã',
            short_codename: 'dong_loi',
            parentId: 76,
          },
          {
            title: 'Xã Thiện Kế',
            code: 2605,
            value: 'xa_thien_ke',
            division_type: 'xã',
            short_codename: 'thien_ke',
            parentId: 76,
          },
          {
            title: 'Xã Hồng Lạc',
            code: 2608,
            value: 'xa_hong_lac',
            division_type: 'xã',
            short_codename: 'hong_lac',
            parentId: 76,
          },
          {
            title: 'Xã Phú Lương',
            code: 2611,
            value: 'xa_phu_luong',
            division_type: 'xã',
            short_codename: 'phu_luong',
            parentId: 76,
          },
          {
            title: 'Xã Ninh Lai',
            code: 2614,
            value: 'xa_ninh_lai',
            division_type: 'xã',
            short_codename: 'ninh_lai',
            parentId: 76,
          },
          {
            title: 'Xã Đại Phú',
            code: 2617,
            value: 'xa_dai_phu',
            division_type: 'xã',
            short_codename: 'dai_phu',
            parentId: 76,
          },
          {
            title: 'Xã Sơn Nam',
            code: 2620,
            value: 'xa_son_nam',
            division_type: 'xã',
            short_codename: 'son_nam',
            parentId: 76,
          },
          {
            title: 'Xã Hào Phú',
            code: 2623,
            value: 'xa_hao_phu',
            division_type: 'xã',
            short_codename: 'hao_phu',
            parentId: 76,
          },
          {
            title: 'Xã Tam Đa',
            code: 2626,
            value: 'xa_tam_da',
            division_type: 'xã',
            short_codename: 'tam_da',
            parentId: 76,
          },
          {
            title: 'Xã Trường Sinh',
            code: 2632,
            value: 'xa_truong_sinh',
            division_type: 'xã',
            short_codename: 'truong_sinh',
            parentId: 76,
          },
        ],
        parentId: 8,
      },
    ],
  },
  {
    title: 'Tỉnh Lào Cai',
    code: 10,
    value: 'tinh_lao_cai',
    division_type: 'tỉnh',
    phone_code: 214,
    children: [
      {
        title: 'Thành phố Lào Cai',
        code: 80,
        value: 'thanh_pho_lao_cai',
        division_type: 'thành phố',
        short_codename: 'lao_cai',
        children: [
          {
            title: 'Phường Duyên Hải',
            code: 2635,
            value: 'phuong_duyen_hai',
            division_type: 'phường',
            short_codename: 'duyen_hai',
            parentId: 80,
          },
          {
            title: 'Phường Lào Cai',
            code: 2641,
            value: 'phuong_lao_cai',
            division_type: 'phường',
            short_codename: 'lao_cai',
            parentId: 80,
          },
          {
            title: 'Phường Cốc Lếu',
            code: 2644,
            value: 'phuong_coc_leu',
            division_type: 'phường',
            short_codename: 'coc_leu',
            parentId: 80,
          },
          {
            title: 'Phường Kim Tân',
            code: 2647,
            value: 'phuong_kim_tan',
            division_type: 'phường',
            short_codename: 'kim_tan',
            parentId: 80,
          },
          {
            title: 'Phường Bắc Lệnh',
            code: 2650,
            value: 'phuong_bac_lenh',
            division_type: 'phường',
            short_codename: 'bac_lenh',
            parentId: 80,
          },
          {
            title: 'Phường Pom Hán',
            code: 2653,
            value: 'phuong_pom_han',
            division_type: 'phường',
            short_codename: 'pom_han',
            parentId: 80,
          },
          {
            title: 'Phường Xuân Tăng',
            code: 2656,
            value: 'phuong_xuan_tang',
            division_type: 'phường',
            short_codename: 'xuan_tang',
            parentId: 80,
          },
          {
            title: 'Phường Bình Minh',
            code: 2658,
            value: 'phuong_binh_minh',
            division_type: 'phường',
            short_codename: 'binh_minh',
            parentId: 80,
          },
          {
            title: 'Xã Thống Nhất',
            code: 2659,
            value: 'xa_thong_nhat',
            division_type: 'xã',
            short_codename: 'thong_nhat',
            parentId: 80,
          },
          {
            title: 'Xã Đồng Tuyển',
            code: 2662,
            value: 'xa_dong_tuyen',
            division_type: 'xã',
            short_codename: 'dong_tuyen',
            parentId: 80,
          },
          {
            title: 'Xã Vạn Hoà',
            code: 2665,
            value: 'xa_van_hoa',
            division_type: 'xã',
            short_codename: 'van_hoa',
            parentId: 80,
          },
          {
            title: 'Phường Bắc Cường',
            code: 2668,
            value: 'phuong_bac_cuong',
            division_type: 'phường',
            short_codename: 'bac_cuong',
            parentId: 80,
          },
          {
            title: 'Phường Nam Cường',
            code: 2671,
            value: 'phuong_nam_cuong',
            division_type: 'phường',
            short_codename: 'nam_cuong',
            parentId: 80,
          },
          {
            title: 'Xã Cam Đường',
            code: 2674,
            value: 'xa_cam_duong',
            division_type: 'xã',
            short_codename: 'cam_duong',
            parentId: 80,
          },
          {
            title: 'Xã Tả Phời',
            code: 2677,
            value: 'xa_ta_phoi',
            division_type: 'xã',
            short_codename: 'ta_phoi',
            parentId: 80,
          },
          {
            title: 'Xã Hợp Thành',
            code: 2680,
            value: 'xa_hop_thanh',
            division_type: 'xã',
            short_codename: 'hop_thanh',
            parentId: 80,
          },
          {
            title: 'Xã Cốc San',
            code: 2746,
            value: 'xa_coc_san',
            division_type: 'xã',
            short_codename: 'coc_san',
            parentId: 80,
          },
        ],
        parentId: 10,
      },
      {
        title: 'Huyện Bát Xát',
        code: 82,
        value: 'huyen_bat_xat',
        division_type: 'huyện',
        short_codename: 'bat_xat',
        children: [
          {
            title: 'Thị trấn Bát Xát',
            code: 2683,
            value: 'thi_tran_bat_xat',
            division_type: 'thị trấn',
            short_codename: 'bat_xat',
            parentId: 82,
          },
          {
            title: 'Xã A Mú Sung',
            code: 2686,
            value: 'xa_a_mu_sung',
            division_type: 'xã',
            short_codename: 'a_mu_sung',
            parentId: 82,
          },
          {
            title: 'Xã Nậm Chạc',
            code: 2689,
            value: 'xa_nam_chac',
            division_type: 'xã',
            short_codename: 'nam_chac',
            parentId: 82,
          },
          {
            title: 'Xã A Lù',
            code: 2692,
            value: 'xa_a_lu',
            division_type: 'xã',
            short_codename: 'a_lu',
            parentId: 82,
          },
          {
            title: 'Xã Trịnh Tường',
            code: 2695,
            value: 'xa_trinh_tuong',
            division_type: 'xã',
            short_codename: 'trinh_tuong',
            parentId: 82,
          },
          {
            title: 'Xã Y Tý',
            code: 2701,
            value: 'xa_y_ty',
            division_type: 'xã',
            short_codename: 'y_ty',
            parentId: 82,
          },
          {
            title: 'Xã Cốc Mỳ',
            code: 2704,
            value: 'xa_coc_my',
            division_type: 'xã',
            short_codename: 'coc_my',
            parentId: 82,
          },
          {
            title: 'Xã Dền Sáng',
            code: 2707,
            value: 'xa_den_sang',
            division_type: 'xã',
            short_codename: 'den_sang',
            parentId: 82,
          },
          {
            title: 'Xã Bản Vược',
            code: 2710,
            value: 'xa_ban_vuoc',
            division_type: 'xã',
            short_codename: 'ban_vuoc',
            parentId: 82,
          },
          {
            title: 'Xã Sàng Ma Sáo',
            code: 2713,
            value: 'xa_sang_ma_sao',
            division_type: 'xã',
            short_codename: 'sang_ma_sao',
            parentId: 82,
          },
          {
            title: 'Xã Bản Qua',
            code: 2716,
            value: 'xa_ban_qua',
            division_type: 'xã',
            short_codename: 'ban_qua',
            parentId: 82,
          },
          {
            title: 'Xã Mường Vi',
            code: 2719,
            value: 'xa_muong_vi',
            division_type: 'xã',
            short_codename: 'muong_vi',
            parentId: 82,
          },
          {
            title: 'Xã Dền Thàng',
            code: 2722,
            value: 'xa_den_thang',
            division_type: 'xã',
            short_codename: 'den_thang',
            parentId: 82,
          },
          {
            title: 'Xã Bản Xèo',
            code: 2725,
            value: 'xa_ban_xeo',
            division_type: 'xã',
            short_codename: 'ban_xeo',
            parentId: 82,
          },
          {
            title: 'Xã Mường Hum',
            code: 2728,
            value: 'xa_muong_hum',
            division_type: 'xã',
            short_codename: 'muong_hum',
            parentId: 82,
          },
          {
            title: 'Xã Trung Lèng Hồ',
            code: 2731,
            value: 'xa_trung_leng_ho',
            division_type: 'xã',
            short_codename: 'trung_leng_ho',
            parentId: 82,
          },
          {
            title: 'Xã Quang Kim',
            code: 2734,
            value: 'xa_quang_kim',
            division_type: 'xã',
            short_codename: 'quang_kim',
            parentId: 82,
          },
          {
            title: 'Xã Pa Cheo',
            code: 2737,
            value: 'xa_pa_cheo',
            division_type: 'xã',
            short_codename: 'pa_cheo',
            parentId: 82,
          },
          {
            title: 'Xã Nậm Pung',
            code: 2740,
            value: 'xa_nam_pung',
            division_type: 'xã',
            short_codename: 'nam_pung',
            parentId: 82,
          },
          {
            title: 'Xã Phìn Ngan',
            code: 2743,
            value: 'xa_phin_ngan',
            division_type: 'xã',
            short_codename: 'phin_ngan',
            parentId: 82,
          },
          {
            title: 'Xã Tòng Sành',
            code: 2749,
            value: 'xa_tong_sanh',
            division_type: 'xã',
            short_codename: 'tong_sanh',
            parentId: 82,
          },
        ],
        parentId: 10,
      },
      {
        title: 'Huyện Mường Khương',
        code: 83,
        value: 'huyen_muong_khuong',
        division_type: 'huyện',
        short_codename: 'muong_khuong',
        children: [
          {
            title: 'Xã Pha Long',
            code: 2752,
            value: 'xa_pha_long',
            division_type: 'xã',
            short_codename: 'pha_long',
            parentId: 83,
          },
          {
            title: 'Xã Tả Ngải Chồ',
            code: 2755,
            value: 'xa_ta_ngai_cho',
            division_type: 'xã',
            short_codename: 'ta_ngai_cho',
            parentId: 83,
          },
          {
            title: 'Xã Tung Chung Phố',
            code: 2758,
            value: 'xa_tung_chung_pho',
            division_type: 'xã',
            short_codename: 'tung_chung_pho',
            parentId: 83,
          },
          {
            title: 'Thị trấn Mường Khương',
            code: 2761,
            value: 'thi_tran_muong_khuong',
            division_type: 'thị trấn',
            short_codename: 'muong_khuong',
            parentId: 83,
          },
          {
            title: 'Xã Dìn Chin',
            code: 2764,
            value: 'xa_din_chin',
            division_type: 'xã',
            short_codename: 'din_chin',
            parentId: 83,
          },
          {
            title: 'Xã Tả Gia Khâu',
            code: 2767,
            value: 'xa_ta_gia_khau',
            division_type: 'xã',
            short_codename: 'ta_gia_khau',
            parentId: 83,
          },
          {
            title: 'Xã Nậm Chảy',
            code: 2770,
            value: 'xa_nam_chay',
            division_type: 'xã',
            short_codename: 'nam_chay',
            parentId: 83,
          },
          {
            title: 'Xã Nấm Lư',
            code: 2773,
            value: 'xa_nam_lu',
            division_type: 'xã',
            short_codename: 'nam_lu',
            parentId: 83,
          },
          {
            title: 'Xã Lùng Khấu Nhin',
            code: 2776,
            value: 'xa_lung_khau_nhin',
            division_type: 'xã',
            short_codename: 'lung_khau_nhin',
            parentId: 83,
          },
          {
            title: 'Xã Thanh Bình',
            code: 2779,
            value: 'xa_thanh_binh',
            division_type: 'xã',
            short_codename: 'thanh_binh',
            parentId: 83,
          },
          {
            title: 'Xã Cao Sơn',
            code: 2782,
            value: 'xa_cao_son',
            division_type: 'xã',
            short_codename: 'cao_son',
            parentId: 83,
          },
          {
            title: 'Xã Lùng Vai',
            code: 2785,
            value: 'xa_lung_vai',
            division_type: 'xã',
            short_codename: 'lung_vai',
            parentId: 83,
          },
          {
            title: 'Xã Bản Lầu',
            code: 2788,
            value: 'xa_ban_lau',
            division_type: 'xã',
            short_codename: 'ban_lau',
            parentId: 83,
          },
          {
            title: 'Xã La Pan Tẩn',
            code: 2791,
            value: 'xa_la_pan_tan',
            division_type: 'xã',
            short_codename: 'la_pan_tan',
            parentId: 83,
          },
          {
            title: 'Xã Tả Thàng',
            code: 2794,
            value: 'xa_ta_thang',
            division_type: 'xã',
            short_codename: 'ta_thang',
            parentId: 83,
          },
          {
            title: 'Xã Bản Sen',
            code: 2797,
            value: 'xa_ban_sen',
            division_type: 'xã',
            short_codename: 'ban_sen',
            parentId: 83,
          },
        ],
        parentId: 10,
      },
      {
        title: 'Huyện Si Ma Cai',
        code: 84,
        value: 'huyen_si_ma_cai',
        division_type: 'huyện',
        short_codename: 'si_ma_cai',
        children: [
          {
            title: 'Xã Nàn Sán',
            code: 2800,
            value: 'xa_nan_san',
            division_type: 'xã',
            short_codename: 'nan_san',
            parentId: 84,
          },
          {
            title: 'Xã Thào Chư Phìn',
            code: 2803,
            value: 'xa_thao_chu_phin',
            division_type: 'xã',
            short_codename: 'thao_chu_phin',
            parentId: 84,
          },
          {
            title: 'Xã Bản Mế',
            code: 2806,
            value: 'xa_ban_me',
            division_type: 'xã',
            short_codename: 'ban_me',
            parentId: 84,
          },
          {
            title: 'Thị trấn Si Ma Cai',
            code: 2809,
            value: 'thi_tran_si_ma_cai',
            division_type: 'thị trấn',
            short_codename: 'si_ma_cai',
            parentId: 84,
          },
          {
            title: 'Xã Sán Chải',
            code: 2812,
            value: 'xa_san_chai',
            division_type: 'xã',
            short_codename: 'san_chai',
            parentId: 84,
          },
          {
            title: 'Xã Lùng Thẩn',
            code: 2818,
            value: 'xa_lung_than',
            division_type: 'xã',
            short_codename: 'lung_than',
            parentId: 84,
          },
          {
            title: 'Xã Cán Cấu',
            code: 2821,
            value: 'xa_can_cau',
            division_type: 'xã',
            short_codename: 'can_cau',
            parentId: 84,
          },
          {
            title: 'Xã Sín Chéng',
            code: 2824,
            value: 'xa_sin_cheng',
            division_type: 'xã',
            short_codename: 'sin_cheng',
            parentId: 84,
          },
          {
            title: 'Xã Quan Hồ Thẩn',
            code: 2827,
            value: 'xa_quan_ho_than',
            division_type: 'xã',
            short_codename: 'quan_ho_than',
            parentId: 84,
          },
          {
            title: 'Xã Nàn Xín',
            code: 2836,
            value: 'xa_nan_xin',
            division_type: 'xã',
            short_codename: 'nan_xin',
            parentId: 84,
          },
        ],
        parentId: 10,
      },
      {
        title: 'Huyện Bắc Hà',
        code: 85,
        value: 'huyen_bac_ha',
        division_type: 'huyện',
        short_codename: 'bac_ha',
        children: [
          {
            title: 'Thị trấn Bắc Hà',
            code: 2839,
            value: 'thi_tran_bac_ha',
            division_type: 'thị trấn',
            short_codename: 'bac_ha',
            parentId: 85,
          },
          {
            title: 'Xã Lùng Cải',
            code: 2842,
            value: 'xa_lung_cai',
            division_type: 'xã',
            short_codename: 'lung_cai',
            parentId: 85,
          },
          {
            title: 'Xã Lùng Phình',
            code: 2848,
            value: 'xa_lung_phinh',
            division_type: 'xã',
            short_codename: 'lung_phinh',
            parentId: 85,
          },
          {
            title: 'Xã Tả Van Chư',
            code: 2851,
            value: 'xa_ta_van_chu',
            division_type: 'xã',
            short_codename: 'ta_van_chu',
            parentId: 85,
          },
          {
            title: 'Xã Tả Củ Tỷ',
            code: 2854,
            value: 'xa_ta_cu_ty',
            division_type: 'xã',
            short_codename: 'ta_cu_ty',
            parentId: 85,
          },
          {
            title: 'Xã Thải Giàng Phố',
            code: 2857,
            value: 'xa_thai_giang_pho',
            division_type: 'xã',
            short_codename: 'thai_giang_pho',
            parentId: 85,
          },
          {
            title: 'Xã Hoàng Thu Phố',
            code: 2863,
            value: 'xa_hoang_thu_pho',
            division_type: 'xã',
            short_codename: 'hoang_thu_pho',
            parentId: 85,
          },
          {
            title: 'Xã Bản Phố',
            code: 2866,
            value: 'xa_ban_pho',
            division_type: 'xã',
            short_codename: 'ban_pho',
            parentId: 85,
          },
          {
            title: 'Xã Bản Liền',
            code: 2869,
            value: 'xa_ban_lien',
            division_type: 'xã',
            short_codename: 'ban_lien',
            parentId: 85,
          },
          {
            title: 'Xã Tà Chải',
            code: 2872,
            value: 'xa_ta_chai',
            division_type: 'xã',
            short_codename: 'ta_chai',
            parentId: 85,
          },
          {
            title: 'Xã Na Hối',
            code: 2875,
            value: 'xa_na_hoi',
            division_type: 'xã',
            short_codename: 'na_hoi',
            parentId: 85,
          },
          {
            title: 'Xã Cốc Ly',
            code: 2878,
            value: 'xa_coc_ly',
            division_type: 'xã',
            short_codename: 'coc_ly',
            parentId: 85,
          },
          {
            title: 'Xã Nậm Mòn',
            code: 2881,
            value: 'xa_nam_mon',
            division_type: 'xã',
            short_codename: 'nam_mon',
            parentId: 85,
          },
          {
            title: 'Xã Nậm Đét',
            code: 2884,
            value: 'xa_nam_det',
            division_type: 'xã',
            short_codename: 'nam_det',
            parentId: 85,
          },
          {
            title: 'Xã Nậm Khánh',
            code: 2887,
            value: 'xa_nam_khanh',
            division_type: 'xã',
            short_codename: 'nam_khanh',
            parentId: 85,
          },
          {
            title: 'Xã Bảo Nhai',
            code: 2890,
            value: 'xa_bao_nhai',
            division_type: 'xã',
            short_codename: 'bao_nhai',
            parentId: 85,
          },
          {
            title: 'Xã Nậm Lúc',
            code: 2893,
            value: 'xa_nam_luc',
            division_type: 'xã',
            short_codename: 'nam_luc',
            parentId: 85,
          },
          {
            title: 'Xã Cốc Lầu',
            code: 2896,
            value: 'xa_coc_lau',
            division_type: 'xã',
            short_codename: 'coc_lau',
            parentId: 85,
          },
          {
            title: 'Xã Bản Cái',
            code: 2899,
            value: 'xa_ban_cai',
            division_type: 'xã',
            short_codename: 'ban_cai',
            parentId: 85,
          },
        ],
        parentId: 10,
      },
      {
        title: 'Huyện Bảo Thắng',
        code: 86,
        value: 'huyen_bao_thang',
        division_type: 'huyện',
        short_codename: 'bao_thang',
        children: [
          {
            title: 'Thị trấn N.T Phong Hải',
            code: 2902,
            value: 'thi_tran_n_t_phong_hai',
            division_type: 'thị trấn',
            short_codename: 'n_t_phong_hai',
            parentId: 86,
          },
          {
            title: 'Thị trấn Phố Lu',
            code: 2905,
            value: 'thi_tran_pho_lu',
            division_type: 'thị trấn',
            short_codename: 'pho_lu',
            parentId: 86,
          },
          {
            title: 'Thị trấn Tằng Loỏng',
            code: 2908,
            value: 'thi_tran_tang_loong',
            division_type: 'thị trấn',
            short_codename: 'tang_loong',
            parentId: 86,
          },
          {
            title: 'Xã Bản Phiệt',
            code: 2911,
            value: 'xa_ban_phiet',
            division_type: 'xã',
            short_codename: 'ban_phiet',
            parentId: 86,
          },
          {
            title: 'Xã Bản Cầm',
            code: 2914,
            value: 'xa_ban_cam',
            division_type: 'xã',
            short_codename: 'ban_cam',
            parentId: 86,
          },
          {
            title: 'Xã Thái Niên',
            code: 2917,
            value: 'xa_thai_nien',
            division_type: 'xã',
            short_codename: 'thai_nien',
            parentId: 86,
          },
          {
            title: 'Xã Phong Niên',
            code: 2920,
            value: 'xa_phong_nien',
            division_type: 'xã',
            short_codename: 'phong_nien',
            parentId: 86,
          },
          {
            title: 'Xã Gia Phú',
            code: 2923,
            value: 'xa_gia_phu',
            division_type: 'xã',
            short_codename: 'gia_phu',
            parentId: 86,
          },
          {
            title: 'Xã Xuân Quang',
            code: 2926,
            value: 'xa_xuan_quang',
            division_type: 'xã',
            short_codename: 'xuan_quang',
            parentId: 86,
          },
          {
            title: 'Xã Sơn Hải',
            code: 2929,
            value: 'xa_son_hai',
            division_type: 'xã',
            short_codename: 'son_hai',
            parentId: 86,
          },
          {
            title: 'Xã Xuân Giao',
            code: 2932,
            value: 'xa_xuan_giao',
            division_type: 'xã',
            short_codename: 'xuan_giao',
            parentId: 86,
          },
          {
            title: 'Xã Trì Quang',
            code: 2935,
            value: 'xa_tri_quang',
            division_type: 'xã',
            short_codename: 'tri_quang',
            parentId: 86,
          },
          {
            title: 'Xã Sơn Hà',
            code: 2938,
            value: 'xa_son_ha',
            division_type: 'xã',
            short_codename: 'son_ha',
            parentId: 86,
          },
          {
            title: 'Xã Phú Nhuận',
            code: 2944,
            value: 'xa_phu_nhuan',
            division_type: 'xã',
            short_codename: 'phu_nhuan',
            parentId: 86,
          },
        ],
        parentId: 10,
      },
      {
        title: 'Huyện Bảo Yên',
        code: 87,
        value: 'huyen_bao_yen',
        division_type: 'huyện',
        short_codename: 'bao_yen',
        children: [
          {
            title: 'Thị trấn Phố Ràng',
            code: 2947,
            value: 'thi_tran_pho_rang',
            division_type: 'thị trấn',
            short_codename: 'pho_rang',
            parentId: 87,
          },
          {
            title: 'Xã Tân Tiến',
            code: 2950,
            value: 'xa_tan_tien',
            division_type: 'xã',
            short_codename: 'tan_tien',
            parentId: 87,
          },
          {
            title: 'Xã Nghĩa Đô',
            code: 2953,
            value: 'xa_nghia_do',
            division_type: 'xã',
            short_codename: 'nghia_do',
            parentId: 87,
          },
          {
            title: 'Xã Vĩnh Yên',
            code: 2956,
            value: 'xa_vinh_yen',
            division_type: 'xã',
            short_codename: 'vinh_yen',
            parentId: 87,
          },
          {
            title: 'Xã Điện Quan',
            code: 2959,
            value: 'xa_dien_quan',
            division_type: 'xã',
            short_codename: 'dien_quan',
            parentId: 87,
          },
          {
            title: 'Xã Xuân Hoà',
            code: 2962,
            value: 'xa_xuan_hoa',
            division_type: 'xã',
            short_codename: 'xuan_hoa',
            parentId: 87,
          },
          {
            title: 'Xã Tân Dương',
            code: 2965,
            value: 'xa_tan_duong',
            division_type: 'xã',
            short_codename: 'tan_duong',
            parentId: 87,
          },
          {
            title: 'Xã Thượng Hà',
            code: 2968,
            value: 'xa_thuong_ha',
            division_type: 'xã',
            short_codename: 'thuong_ha',
            parentId: 87,
          },
          {
            title: 'Xã Kim Sơn',
            code: 2971,
            value: 'xa_kim_son',
            division_type: 'xã',
            short_codename: 'kim_son',
            parentId: 87,
          },
          {
            title: 'Xã Cam Cọn',
            code: 2974,
            value: 'xa_cam_con',
            division_type: 'xã',
            short_codename: 'cam_con',
            parentId: 87,
          },
          {
            title: 'Xã Minh Tân',
            code: 2977,
            value: 'xa_minh_tan',
            division_type: 'xã',
            short_codename: 'minh_tan',
            parentId: 87,
          },
          {
            title: 'Xã Xuân Thượng',
            code: 2980,
            value: 'xa_xuan_thuong',
            division_type: 'xã',
            short_codename: 'xuan_thuong',
            parentId: 87,
          },
          {
            title: 'Xã Việt Tiến',
            code: 2983,
            value: 'xa_viet_tien',
            division_type: 'xã',
            short_codename: 'viet_tien',
            parentId: 87,
          },
          {
            title: 'Xã Yên Sơn',
            code: 2986,
            value: 'xa_yen_son',
            division_type: 'xã',
            short_codename: 'yen_son',
            parentId: 87,
          },
          {
            title: 'Xã Bảo Hà',
            code: 2989,
            value: 'xa_bao_ha',
            division_type: 'xã',
            short_codename: 'bao_ha',
            parentId: 87,
          },
          {
            title: 'Xã Lương Sơn',
            code: 2992,
            value: 'xa_luong_son',
            division_type: 'xã',
            short_codename: 'luong_son',
            parentId: 87,
          },
          {
            title: 'Xã Phúc Khánh',
            code: 2998,
            value: 'xa_phuc_khanh',
            division_type: 'xã',
            short_codename: 'phuc_khanh',
            parentId: 87,
          },
        ],
        parentId: 10,
      },
      {
        title: 'Thị xã Sa Pa',
        code: 88,
        value: 'thi_xa_sa_pa',
        division_type: 'thị xã',
        short_codename: 'sa_pa',
        children: [
          {
            title: 'Phường Sa Pa',
            code: 3001,
            value: 'phuong_sa_pa',
            division_type: 'phường',
            short_codename: 'sa_pa',
            parentId: 88,
          },
          {
            title: 'Phường Sa Pả',
            code: 3002,
            value: 'phuong_sa_pa',
            division_type: 'phường',
            short_codename: 'sa_pa',
            parentId: 88,
          },
          {
            title: 'Phường Ô Quý Hồ',
            code: 3003,
            value: 'phuong_o_quy_ho',
            division_type: 'phường',
            short_codename: 'o_quy_ho',
            parentId: 88,
          },
          {
            title: 'Xã Ngũ Chỉ Sơn',
            code: 3004,
            value: 'xa_ngu_chi_son',
            division_type: 'xã',
            short_codename: 'ngu_chi_son',
            parentId: 88,
          },
          {
            title: 'Phường Phan Si Păng',
            code: 3006,
            value: 'phuong_phan_si_pang',
            division_type: 'phường',
            short_codename: 'phan_si_pang',
            parentId: 88,
          },
          {
            title: 'Xã Trung Chải',
            code: 3010,
            value: 'xa_trung_chai',
            division_type: 'xã',
            short_codename: 'trung_chai',
            parentId: 88,
          },
          {
            title: 'Xã Tả Phìn',
            code: 3013,
            value: 'xa_ta_phin',
            division_type: 'xã',
            short_codename: 'ta_phin',
            parentId: 88,
          },
          {
            title: 'Phường Hàm Rồng',
            code: 3016,
            value: 'phuong_ham_rong',
            division_type: 'phường',
            short_codename: 'ham_rong',
            parentId: 88,
          },
          {
            title: 'Xã Hoàng Liên',
            code: 3019,
            value: 'xa_hoang_lien',
            division_type: 'xã',
            short_codename: 'hoang_lien',
            parentId: 88,
          },
          {
            title: 'Xã Thanh Bình',
            code: 3022,
            value: 'xa_thanh_binh',
            division_type: 'xã',
            short_codename: 'thanh_binh',
            parentId: 88,
          },
          {
            title: 'Phường Cầu Mây',
            code: 3028,
            value: 'phuong_cau_may',
            division_type: 'phường',
            short_codename: 'cau_may',
            parentId: 88,
          },
          {
            title: 'Xã Mường Hoa',
            code: 3037,
            value: 'xa_muong_hoa',
            division_type: 'xã',
            short_codename: 'muong_hoa',
            parentId: 88,
          },
          {
            title: 'Xã Tả Van',
            code: 3040,
            value: 'xa_ta_van',
            division_type: 'xã',
            short_codename: 'ta_van',
            parentId: 88,
          },
          {
            title: 'Xã Mường Bo',
            code: 3043,
            value: 'xa_muong_bo',
            division_type: 'xã',
            short_codename: 'muong_bo',
            parentId: 88,
          },
          {
            title: 'Xã Bản Hồ',
            code: 3046,
            value: 'xa_ban_ho',
            division_type: 'xã',
            short_codename: 'ban_ho',
            parentId: 88,
          },
          {
            title: 'Xã Liên Minh',
            code: 3052,
            value: 'xa_lien_minh',
            division_type: 'xã',
            short_codename: 'lien_minh',
            parentId: 88,
          },
        ],
        parentId: 10,
      },
      {
        title: 'Huyện Văn Bàn',
        code: 89,
        value: 'huyen_van_ban',
        division_type: 'huyện',
        short_codename: 'van_ban',
        children: [
          {
            title: 'Thị trấn Khánh Yên',
            code: 3055,
            value: 'thi_tran_khanh_yen',
            division_type: 'thị trấn',
            short_codename: 'khanh_yen',
            parentId: 89,
          },
          {
            title: 'Xã Võ Lao',
            code: 3061,
            value: 'xa_vo_lao',
            division_type: 'xã',
            short_codename: 'vo_lao',
            parentId: 89,
          },
          {
            title: 'Xã Sơn Thuỷ',
            code: 3064,
            value: 'xa_son_thuy',
            division_type: 'xã',
            short_codename: 'son_thuy',
            parentId: 89,
          },
          {
            title: 'Xã Nậm Mả',
            code: 3067,
            value: 'xa_nam_ma',
            division_type: 'xã',
            short_codename: 'nam_ma',
            parentId: 89,
          },
          {
            title: 'Xã Tân Thượng',
            code: 3070,
            value: 'xa_tan_thuong',
            division_type: 'xã',
            short_codename: 'tan_thuong',
            parentId: 89,
          },
          {
            title: 'Xã Nậm Rạng',
            code: 3073,
            value: 'xa_nam_rang',
            division_type: 'xã',
            short_codename: 'nam_rang',
            parentId: 89,
          },
          {
            title: 'Xã Nậm Chầy',
            code: 3076,
            value: 'xa_nam_chay',
            division_type: 'xã',
            short_codename: 'nam_chay',
            parentId: 89,
          },
          {
            title: 'Xã Tân An',
            code: 3079,
            value: 'xa_tan_an',
            division_type: 'xã',
            short_codename: 'tan_an',
            parentId: 89,
          },
          {
            title: 'Xã Khánh Yên Thượng',
            code: 3082,
            value: 'xa_khanh_yen_thuong',
            division_type: 'xã',
            short_codename: 'khanh_yen_thuong',
            parentId: 89,
          },
          {
            title: 'Xã Nậm Xé',
            code: 3085,
            value: 'xa_nam_xe',
            division_type: 'xã',
            short_codename: 'nam_xe',
            parentId: 89,
          },
          {
            title: 'Xã Dần Thàng',
            code: 3088,
            value: 'xa_dan_thang',
            division_type: 'xã',
            short_codename: 'dan_thang',
            parentId: 89,
          },
          {
            title: 'Xã Chiềng Ken',
            code: 3091,
            value: 'xa_chieng_ken',
            division_type: 'xã',
            short_codename: 'chieng_ken',
            parentId: 89,
          },
          {
            title: 'Xã Làng Giàng',
            code: 3094,
            value: 'xa_lang_giang',
            division_type: 'xã',
            short_codename: 'lang_giang',
            parentId: 89,
          },
          {
            title: 'Xã Hoà Mạc',
            code: 3097,
            value: 'xa_hoa_mac',
            division_type: 'xã',
            short_codename: 'hoa_mac',
            parentId: 89,
          },
          {
            title: 'Xã Khánh Yên Trung',
            code: 3100,
            value: 'xa_khanh_yen_trung',
            division_type: 'xã',
            short_codename: 'khanh_yen_trung',
            parentId: 89,
          },
          {
            title: 'Xã Khánh Yên Hạ',
            code: 3103,
            value: 'xa_khanh_yen_ha',
            division_type: 'xã',
            short_codename: 'khanh_yen_ha',
            parentId: 89,
          },
          {
            title: 'Xã Dương Quỳ',
            code: 3106,
            value: 'xa_duong_quy',
            division_type: 'xã',
            short_codename: 'duong_quy',
            parentId: 89,
          },
          {
            title: 'Xã Nậm Tha',
            code: 3109,
            value: 'xa_nam_tha',
            division_type: 'xã',
            short_codename: 'nam_tha',
            parentId: 89,
          },
          {
            title: 'Xã Minh Lương',
            code: 3112,
            value: 'xa_minh_luong',
            division_type: 'xã',
            short_codename: 'minh_luong',
            parentId: 89,
          },
          {
            title: 'Xã Thẩm Dương',
            code: 3115,
            value: 'xa_tham_duong',
            division_type: 'xã',
            short_codename: 'tham_duong',
            parentId: 89,
          },
          {
            title: 'Xã Liêm Phú',
            code: 3118,
            value: 'xa_liem_phu',
            division_type: 'xã',
            short_codename: 'liem_phu',
            parentId: 89,
          },
          {
            title: 'Xã Nậm Xây',
            code: 3121,
            value: 'xa_nam_xay',
            division_type: 'xã',
            short_codename: 'nam_xay',
            parentId: 89,
          },
        ],
        parentId: 10,
      },
    ],
  },
  {
    title: 'Tỉnh Điện Biên',
    code: 11,
    value: 'tinh_dien_bien',
    division_type: 'tỉnh',
    phone_code: 215,
    children: [
      {
        title: 'Thành phố Điện Biên Phủ',
        code: 94,
        value: 'thanh_pho_dien_bien_phu',
        division_type: 'thành phố',
        short_codename: 'dien_bien_phu',
        children: [
          {
            title: 'Phường Noong Bua',
            code: 3124,
            value: 'phuong_noong_bua',
            division_type: 'phường',
            short_codename: 'noong_bua',
            parentId: 94,
          },
          {
            title: 'Phường Him Lam',
            code: 3127,
            value: 'phuong_him_lam',
            division_type: 'phường',
            short_codename: 'him_lam',
            parentId: 94,
          },
          {
            title: 'Phường Thanh Bình',
            code: 3130,
            value: 'phuong_thanh_binh',
            division_type: 'phường',
            short_codename: 'thanh_binh',
            parentId: 94,
          },
          {
            title: 'Phường Tân Thanh',
            code: 3133,
            value: 'phuong_tan_thanh',
            division_type: 'phường',
            short_codename: 'tan_thanh',
            parentId: 94,
          },
          {
            title: 'Phường Mường Thanh',
            code: 3136,
            value: 'phuong_muong_thanh',
            division_type: 'phường',
            short_codename: 'muong_thanh',
            parentId: 94,
          },
          {
            title: 'Phường Nam Thanh',
            code: 3139,
            value: 'phuong_nam_thanh',
            division_type: 'phường',
            short_codename: 'nam_thanh',
            parentId: 94,
          },
          {
            title: 'Phường Thanh Trường',
            code: 3142,
            value: 'phuong_thanh_truong',
            division_type: 'phường',
            short_codename: 'thanh_truong',
            parentId: 94,
          },
          {
            title: 'Xã Thanh Minh',
            code: 3145,
            value: 'xa_thanh_minh',
            division_type: 'xã',
            short_codename: 'thanh_minh',
            parentId: 94,
          },
          {
            title: 'Xã Nà Tấu',
            code: 3316,
            value: 'xa_na_tau',
            division_type: 'xã',
            short_codename: 'na_tau',
            parentId: 94,
          },
          {
            title: 'Xã Nà Nhạn',
            code: 3317,
            value: 'xa_na_nhan',
            division_type: 'xã',
            short_codename: 'na_nhan',
            parentId: 94,
          },
          {
            title: 'Xã Mường Phăng',
            code: 3325,
            value: 'xa_muong_phang',
            division_type: 'xã',
            short_codename: 'muong_phang',
            parentId: 94,
          },
          {
            title: 'Xã Pá Khoang',
            code: 3326,
            value: 'xa_pa_khoang',
            division_type: 'xã',
            short_codename: 'pa_khoang',
            parentId: 94,
          },
        ],
        parentId: 11,
      },
      {
        title: 'Thị Xã Mường Lay',
        code: 95,
        value: 'thi_xa_muong_lay',
        division_type: 'thị xã',
        short_codename: 'muong_lay',
        children: [
          {
            title: 'Phường Sông Đà',
            code: 3148,
            value: 'phuong_song_da',
            division_type: 'phường',
            short_codename: 'song_da',
            parentId: 95,
          },
          {
            title: 'Phường Na Lay',
            code: 3151,
            value: 'phuong_na_lay',
            division_type: 'phường',
            short_codename: 'na_lay',
            parentId: 95,
          },
          {
            title: 'Xã Lay Nưa',
            code: 3184,
            value: 'xa_lay_nua',
            division_type: 'xã',
            short_codename: 'lay_nua',
            parentId: 95,
          },
        ],
        parentId: 11,
      },
      {
        title: 'Huyện Mường Nhé',
        code: 96,
        value: 'huyen_muong_nhe',
        division_type: 'huyện',
        short_codename: 'muong_nhe',
        children: [
          {
            title: 'Xã Sín Thầu',
            code: 3154,
            value: 'xa_sin_thau',
            division_type: 'xã',
            short_codename: 'sin_thau',
            parentId: 96,
          },
          {
            title: 'Xã Sen Thượng',
            code: 3155,
            value: 'xa_sen_thuong',
            division_type: 'xã',
            short_codename: 'sen_thuong',
            parentId: 96,
          },
          {
            title: 'Xã Chung Chải',
            code: 3157,
            value: 'xa_chung_chai',
            division_type: 'xã',
            short_codename: 'chung_chai',
            parentId: 96,
          },
          {
            title: 'Xã Leng Su Sìn',
            code: 3158,
            value: 'xa_leng_su_sin',
            division_type: 'xã',
            short_codename: 'leng_su_sin',
            parentId: 96,
          },
          {
            title: 'Xã Pá Mỳ',
            code: 3159,
            value: 'xa_pa_my',
            division_type: 'xã',
            short_codename: 'pa_my',
            parentId: 96,
          },
          {
            title: 'Xã Mường Nhé',
            code: 3160,
            value: 'xa_muong_nhe',
            division_type: 'xã',
            short_codename: 'muong_nhe',
            parentId: 96,
          },
          {
            title: 'Xã Nậm Vì',
            code: 3161,
            value: 'xa_nam_vi',
            division_type: 'xã',
            short_codename: 'nam_vi',
            parentId: 96,
          },
          {
            title: 'Xã Nậm Kè',
            code: 3162,
            value: 'xa_nam_ke',
            division_type: 'xã',
            short_codename: 'nam_ke',
            parentId: 96,
          },
          {
            title: 'Xã Mường Toong',
            code: 3163,
            value: 'xa_muong_toong',
            division_type: 'xã',
            short_codename: 'muong_toong',
            parentId: 96,
          },
          {
            title: 'Xã Quảng Lâm',
            code: 3164,
            value: 'xa_quang_lam',
            division_type: 'xã',
            short_codename: 'quang_lam',
            parentId: 96,
          },
          {
            title: 'Xã Huổi Lếnh',
            code: 3177,
            value: 'xa_huoi_lenh',
            division_type: 'xã',
            short_codename: 'huoi_lenh',
            parentId: 96,
          },
        ],
        parentId: 11,
      },
      {
        title: 'Huyện Mường Chà',
        code: 97,
        value: 'huyen_muong_cha',
        division_type: 'huyện',
        short_codename: 'muong_cha',
        children: [
          {
            title: 'Thị Trấn Mường Chà',
            code: 3172,
            value: 'thi_tran_muong_cha',
            division_type: 'thị trấn',
            short_codename: 'muong_cha',
            parentId: 97,
          },
          {
            title: 'Xã Xá Tổng',
            code: 3178,
            value: 'xa_xa_tong',
            division_type: 'xã',
            short_codename: 'xa_tong',
            parentId: 97,
          },
          {
            title: 'Xã Mường Tùng',
            code: 3181,
            value: 'xa_muong_tung',
            division_type: 'xã',
            short_codename: 'muong_tung',
            parentId: 97,
          },
          {
            title: 'Xã Hừa Ngài',
            code: 3190,
            value: 'xa_hua_ngai',
            division_type: 'xã',
            short_codename: 'hua_ngai',
            parentId: 97,
          },
          {
            title: 'Xã Huổi Mí',
            code: 3191,
            value: 'xa_huoi_mi',
            division_type: 'xã',
            short_codename: 'huoi_mi',
            parentId: 97,
          },
          {
            title: 'Xã Pa Ham',
            code: 3193,
            value: 'xa_pa_ham',
            division_type: 'xã',
            short_codename: 'pa_ham',
            parentId: 97,
          },
          {
            title: 'Xã Nậm Nèn',
            code: 3194,
            value: 'xa_nam_nen',
            division_type: 'xã',
            short_codename: 'nam_nen',
            parentId: 97,
          },
          {
            title: 'Xã Huổi Lèng',
            code: 3196,
            value: 'xa_huoi_leng',
            division_type: 'xã',
            short_codename: 'huoi_leng',
            parentId: 97,
          },
          {
            title: 'Xã Sa Lông',
            code: 3197,
            value: 'xa_sa_long',
            division_type: 'xã',
            short_codename: 'sa_long',
            parentId: 97,
          },
          {
            title: 'Xã Ma Thì Hồ',
            code: 3200,
            value: 'xa_ma_thi_ho',
            division_type: 'xã',
            short_codename: 'ma_thi_ho',
            parentId: 97,
          },
          {
            title: 'Xã Na Sang',
            code: 3201,
            value: 'xa_na_sang',
            division_type: 'xã',
            short_codename: 'na_sang',
            parentId: 97,
          },
          {
            title: 'Xã Mường Mươn',
            code: 3202,
            value: 'xa_muong_muon',
            division_type: 'xã',
            short_codename: 'muong_muon',
            parentId: 97,
          },
        ],
        parentId: 11,
      },
      {
        title: 'Huyện Tủa Chùa',
        code: 98,
        value: 'huyen_tua_chua',
        division_type: 'huyện',
        short_codename: 'tua_chua',
        children: [
          {
            title: 'Thị trấn Tủa Chùa',
            code: 3217,
            value: 'thi_tran_tua_chua',
            division_type: 'thị trấn',
            short_codename: 'tua_chua',
            parentId: 98,
          },
          {
            title: 'Xã Huổi Só',
            code: 3220,
            value: 'xa_huoi_so',
            division_type: 'xã',
            short_codename: 'huoi_so',
            parentId: 98,
          },
          {
            title: 'Xã Xín Chải',
            code: 3223,
            value: 'xa_xin_chai',
            division_type: 'xã',
            short_codename: 'xin_chai',
            parentId: 98,
          },
          {
            title: 'Xã Tả Sìn Thàng',
            code: 3226,
            value: 'xa_ta_sin_thang',
            division_type: 'xã',
            short_codename: 'ta_sin_thang',
            parentId: 98,
          },
          {
            title: 'Xã Lao Xả Phình',
            code: 3229,
            value: 'xa_lao_xa_phinh',
            division_type: 'xã',
            short_codename: 'lao_xa_phinh',
            parentId: 98,
          },
          {
            title: 'Xã Tả Phìn',
            code: 3232,
            value: 'xa_ta_phin',
            division_type: 'xã',
            short_codename: 'ta_phin',
            parentId: 98,
          },
          {
            title: 'Xã Tủa Thàng',
            code: 3235,
            value: 'xa_tua_thang',
            division_type: 'xã',
            short_codename: 'tua_thang',
            parentId: 98,
          },
          {
            title: 'Xã Trung Thu',
            code: 3238,
            value: 'xa_trung_thu',
            division_type: 'xã',
            short_codename: 'trung_thu',
            parentId: 98,
          },
          {
            title: 'Xã Sính Phình',
            code: 3241,
            value: 'xa_sinh_phinh',
            division_type: 'xã',
            short_codename: 'sinh_phinh',
            parentId: 98,
          },
          {
            title: 'Xã Sáng Nhè',
            code: 3244,
            value: 'xa_sang_nhe',
            division_type: 'xã',
            short_codename: 'sang_nhe',
            parentId: 98,
          },
          {
            title: 'Xã Mường Đun',
            code: 3247,
            value: 'xa_muong_dun',
            division_type: 'xã',
            short_codename: 'muong_dun',
            parentId: 98,
          },
          {
            title: 'Xã Mường Báng',
            code: 3250,
            value: 'xa_muong_bang',
            division_type: 'xã',
            short_codename: 'muong_bang',
            parentId: 98,
          },
        ],
        parentId: 11,
      },
      {
        title: 'Huyện Tuần Giáo',
        code: 99,
        value: 'huyen_tuan_giao',
        division_type: 'huyện',
        short_codename: 'tuan_giao',
        children: [
          {
            title: 'Thị trấn Tuần Giáo',
            code: 3253,
            value: 'thi_tran_tuan_giao',
            division_type: 'thị trấn',
            short_codename: 'tuan_giao',
            parentId: 99,
          },
          {
            title: 'Xã Phình Sáng',
            code: 3259,
            value: 'xa_phinh_sang',
            division_type: 'xã',
            short_codename: 'phinh_sang',
            parentId: 99,
          },
          {
            title: 'Xã Rạng Đông',
            code: 3260,
            value: 'xa_rang_dong',
            division_type: 'xã',
            short_codename: 'rang_dong',
            parentId: 99,
          },
          {
            title: 'Xã Mùn Chung',
            code: 3262,
            value: 'xa_mun_chung',
            division_type: 'xã',
            short_codename: 'mun_chung',
            parentId: 99,
          },
          {
            title: 'Xã Nà Tòng',
            code: 3263,
            value: 'xa_na_tong',
            division_type: 'xã',
            short_codename: 'na_tong',
            parentId: 99,
          },
          {
            title: 'Xã Ta Ma',
            code: 3265,
            value: 'xa_ta_ma',
            division_type: 'xã',
            short_codename: 'ta_ma',
            parentId: 99,
          },
          {
            title: 'Xã Mường Mùn',
            code: 3268,
            value: 'xa_muong_mun',
            division_type: 'xã',
            short_codename: 'muong_mun',
            parentId: 99,
          },
          {
            title: 'Xã Pú Xi',
            code: 3269,
            value: 'xa_pu_xi',
            division_type: 'xã',
            short_codename: 'pu_xi',
            parentId: 99,
          },
          {
            title: 'Xã Pú Nhung',
            code: 3271,
            value: 'xa_pu_nhung',
            division_type: 'xã',
            short_codename: 'pu_nhung',
            parentId: 99,
          },
          {
            title: 'Xã Quài Nưa',
            code: 3274,
            value: 'xa_quai_nua',
            division_type: 'xã',
            short_codename: 'quai_nua',
            parentId: 99,
          },
          {
            title: 'Xã Mường Thín',
            code: 3277,
            value: 'xa_muong_thin',
            division_type: 'xã',
            short_codename: 'muong_thin',
            parentId: 99,
          },
          {
            title: 'Xã Tỏa Tình',
            code: 3280,
            value: 'xa_toa_tinh',
            division_type: 'xã',
            short_codename: 'toa_tinh',
            parentId: 99,
          },
          {
            title: 'Xã Nà Sáy',
            code: 3283,
            value: 'xa_na_say',
            division_type: 'xã',
            short_codename: 'na_say',
            parentId: 99,
          },
          {
            title: 'Xã Mường Khong',
            code: 3284,
            value: 'xa_muong_khong',
            division_type: 'xã',
            short_codename: 'muong_khong',
            parentId: 99,
          },
          {
            title: 'Xã Quài Cang',
            code: 3289,
            value: 'xa_quai_cang',
            division_type: 'xã',
            short_codename: 'quai_cang',
            parentId: 99,
          },
          {
            title: 'Xã Quài Tở',
            code: 3295,
            value: 'xa_quai_to',
            division_type: 'xã',
            short_codename: 'quai_to',
            parentId: 99,
          },
          {
            title: 'Xã Chiềng Sinh',
            code: 3298,
            value: 'xa_chieng_sinh',
            division_type: 'xã',
            short_codename: 'chieng_sinh',
            parentId: 99,
          },
          {
            title: 'Xã Chiềng Đông',
            code: 3299,
            value: 'xa_chieng_dong',
            division_type: 'xã',
            short_codename: 'chieng_dong',
            parentId: 99,
          },
          {
            title: 'Xã Tênh Phông',
            code: 3304,
            value: 'xa_tenh_phong',
            division_type: 'xã',
            short_codename: 'tenh_phong',
            parentId: 99,
          },
        ],
        parentId: 11,
      },
      {
        title: 'Huyện Điện Biên',
        code: 100,
        value: 'huyen_dien_bien',
        division_type: 'huyện',
        short_codename: 'dien_bien',
        children: [
          {
            title: 'Xã Mường Pồn',
            code: 3319,
            value: 'xa_muong_pon',
            division_type: 'xã',
            short_codename: 'muong_pon',
            parentId: 100,
          },
          {
            title: 'Xã Thanh Nưa',
            code: 3322,
            value: 'xa_thanh_nua',
            division_type: 'xã',
            short_codename: 'thanh_nua',
            parentId: 100,
          },
          {
            title: 'Xã Hua Thanh',
            code: 3323,
            value: 'xa_hua_thanh',
            division_type: 'xã',
            short_codename: 'hua_thanh',
            parentId: 100,
          },
          {
            title: 'Xã Thanh Luông',
            code: 3328,
            value: 'xa_thanh_luong',
            division_type: 'xã',
            short_codename: 'thanh_luong',
            parentId: 100,
          },
          {
            title: 'Xã Thanh Hưng',
            code: 3331,
            value: 'xa_thanh_hung',
            division_type: 'xã',
            short_codename: 'thanh_hung',
            parentId: 100,
          },
          {
            title: 'Xã Thanh Xương',
            code: 3334,
            value: 'xa_thanh_xuong',
            division_type: 'xã',
            short_codename: 'thanh_xuong',
            parentId: 100,
          },
          {
            title: 'Xã Thanh Chăn',
            code: 3337,
            value: 'xa_thanh_chan',
            division_type: 'xã',
            short_codename: 'thanh_chan',
            parentId: 100,
          },
          {
            title: 'Xã Pa Thơm',
            code: 3340,
            value: 'xa_pa_thom',
            division_type: 'xã',
            short_codename: 'pa_thom',
            parentId: 100,
          },
          {
            title: 'Xã Thanh An',
            code: 3343,
            value: 'xa_thanh_an',
            division_type: 'xã',
            short_codename: 'thanh_an',
            parentId: 100,
          },
          {
            title: 'Xã Thanh Yên',
            code: 3346,
            value: 'xa_thanh_yen',
            division_type: 'xã',
            short_codename: 'thanh_yen',
            parentId: 100,
          },
          {
            title: 'Xã Noong Luống',
            code: 3349,
            value: 'xa_noong_luong',
            division_type: 'xã',
            short_codename: 'noong_luong',
            parentId: 100,
          },
          {
            title: 'Xã Noọng Hẹt',
            code: 3352,
            value: 'xa_noong_het',
            division_type: 'xã',
            short_codename: 'noong_het',
            parentId: 100,
          },
          {
            title: 'Xã Sam Mứn',
            code: 3355,
            value: 'xa_sam_mun',
            division_type: 'xã',
            short_codename: 'sam_mun',
            parentId: 100,
          },
          {
            title: 'Xã Pom Lót',
            code: 3356,
            value: 'xa_pom_lot',
            division_type: 'xã',
            short_codename: 'pom_lot',
            parentId: 100,
          },
          {
            title: 'Xã Núa Ngam',
            code: 3358,
            value: 'xa_nua_ngam',
            division_type: 'xã',
            short_codename: 'nua_ngam',
            parentId: 100,
          },
          {
            title: 'Xã Hẹ Muông',
            code: 3359,
            value: 'xa_he_muong',
            division_type: 'xã',
            short_codename: 'he_muong',
            parentId: 100,
          },
          {
            title: 'Xã Na Ư',
            code: 3361,
            value: 'xa_na_u',
            division_type: 'xã',
            short_codename: 'na_u',
            parentId: 100,
          },
          {
            title: 'Xã Mường Nhà',
            code: 3364,
            value: 'xa_muong_nha',
            division_type: 'xã',
            short_codename: 'muong_nha',
            parentId: 100,
          },
          {
            title: 'Xã Na Tông',
            code: 3365,
            value: 'xa_na_tong',
            division_type: 'xã',
            short_codename: 'na_tong',
            parentId: 100,
          },
          {
            title: 'Xã Mường Lói',
            code: 3367,
            value: 'xa_muong_loi',
            division_type: 'xã',
            short_codename: 'muong_loi',
            parentId: 100,
          },
          {
            title: 'Xã Phu Luông',
            code: 3368,
            value: 'xa_phu_luong',
            division_type: 'xã',
            short_codename: 'phu_luong',
            parentId: 100,
          },
        ],
        parentId: 11,
      },
      {
        title: 'Huyện Điện Biên Đông',
        code: 101,
        value: 'huyen_dien_bien_dong',
        division_type: 'huyện',
        short_codename: 'dien_bien_dong',
        children: [
          {
            title: 'Thị trấn Điện Biên Đông',
            code: 3203,
            value: 'thi_tran_dien_bien_dong',
            division_type: 'thị trấn',
            short_codename: 'dien_bien_dong',
            parentId: 101,
          },
          {
            title: 'Xã Na Son',
            code: 3205,
            value: 'xa_na_son',
            division_type: 'xã',
            short_codename: 'na_son',
            parentId: 101,
          },
          {
            title: 'Xã Phì Nhừ',
            code: 3208,
            value: 'xa_phi_nhu',
            division_type: 'xã',
            short_codename: 'phi_nhu',
            parentId: 101,
          },
          {
            title: 'Xã Chiềng Sơ',
            code: 3211,
            value: 'xa_chieng_so',
            division_type: 'xã',
            short_codename: 'chieng_so',
            parentId: 101,
          },
          {
            title: 'Xã Mường Luân',
            code: 3214,
            value: 'xa_muong_luan',
            division_type: 'xã',
            short_codename: 'muong_luan',
            parentId: 101,
          },
          {
            title: 'Xã Pú Nhi',
            code: 3370,
            value: 'xa_pu_nhi',
            division_type: 'xã',
            short_codename: 'pu_nhi',
            parentId: 101,
          },
          {
            title: 'Xã Nong U',
            code: 3371,
            value: 'xa_nong_u',
            division_type: 'xã',
            short_codename: 'nong_u',
            parentId: 101,
          },
          {
            title: 'Xã Xa Dung',
            code: 3373,
            value: 'xa_xa_dung',
            division_type: 'xã',
            short_codename: 'xa_dung',
            parentId: 101,
          },
          {
            title: 'Xã Keo Lôm',
            code: 3376,
            value: 'xa_keo_lom',
            division_type: 'xã',
            short_codename: 'keo_lom',
            parentId: 101,
          },
          {
            title: 'Xã Luân Giới',
            code: 3379,
            value: 'xa_luan_gioi',
            division_type: 'xã',
            short_codename: 'luan_gioi',
            parentId: 101,
          },
          {
            title: 'Xã Phình Giàng',
            code: 3382,
            value: 'xa_phinh_giang',
            division_type: 'xã',
            short_codename: 'phinh_giang',
            parentId: 101,
          },
          {
            title: 'Xã Pú Hồng',
            code: 3383,
            value: 'xa_pu_hong',
            division_type: 'xã',
            short_codename: 'pu_hong',
            parentId: 101,
          },
          {
            title: 'Xã Tìa Dình',
            code: 3384,
            value: 'xa_tia_dinh',
            division_type: 'xã',
            short_codename: 'tia_dinh',
            parentId: 101,
          },
          {
            title: 'Xã Háng Lìa',
            code: 3385,
            value: 'xa_hang_lia',
            division_type: 'xã',
            short_codename: 'hang_lia',
            parentId: 101,
          },
        ],
        parentId: 11,
      },
      {
        title: 'Huyện Mường Ảng',
        code: 102,
        value: 'huyen_muong_ang',
        division_type: 'huyện',
        short_codename: 'muong_ang',
        children: [
          {
            title: 'Thị trấn Mường Ảng',
            code: 3256,
            value: 'thi_tran_muong_ang',
            division_type: 'thị trấn',
            short_codename: 'muong_ang',
            parentId: 102,
          },
          {
            title: 'Xã Mường Đăng',
            code: 3286,
            value: 'xa_muong_dang',
            division_type: 'xã',
            short_codename: 'muong_dang',
            parentId: 102,
          },
          {
            title: 'Xã Ngối Cáy',
            code: 3287,
            value: 'xa_ngoi_cay',
            division_type: 'xã',
            short_codename: 'ngoi_cay',
            parentId: 102,
          },
          {
            title: 'Xã Ẳng Tở',
            code: 3292,
            value: 'xa_ang_to',
            division_type: 'xã',
            short_codename: 'ang_to',
            parentId: 102,
          },
          {
            title: 'Xã Búng Lao',
            code: 3301,
            value: 'xa_bung_lao',
            division_type: 'xã',
            short_codename: 'bung_lao',
            parentId: 102,
          },
          {
            title: 'Xã Xuân Lao',
            code: 3302,
            value: 'xa_xuan_lao',
            division_type: 'xã',
            short_codename: 'xuan_lao',
            parentId: 102,
          },
          {
            title: 'Xã Ẳng Nưa',
            code: 3307,
            value: 'xa_ang_nua',
            division_type: 'xã',
            short_codename: 'ang_nua',
            parentId: 102,
          },
          {
            title: 'Xã Ẳng Cang',
            code: 3310,
            value: 'xa_ang_cang',
            division_type: 'xã',
            short_codename: 'ang_cang',
            parentId: 102,
          },
          {
            title: 'Xã Nặm Lịch',
            code: 3312,
            value: 'xa_nam_lich',
            division_type: 'xã',
            short_codename: 'nam_lich',
            parentId: 102,
          },
          {
            title: 'Xã Mường Lạn',
            code: 3313,
            value: 'xa_muong_lan',
            division_type: 'xã',
            short_codename: 'muong_lan',
            parentId: 102,
          },
        ],
        parentId: 11,
      },
      {
        title: 'Huyện Nậm Pồ',
        code: 103,
        value: 'huyen_nam_po',
        division_type: 'huyện',
        short_codename: 'nam_po',
        children: [
          {
            title: 'Xã Nậm Tin',
            code: 3156,
            value: 'xa_nam_tin',
            division_type: 'xã',
            short_codename: 'nam_tin',
            parentId: 103,
          },
          {
            title: 'Xã Pa Tần',
            code: 3165,
            value: 'xa_pa_tan',
            division_type: 'xã',
            short_codename: 'pa_tan',
            parentId: 103,
          },
          {
            title: 'Xã Chà Cang',
            code: 3166,
            value: 'xa_cha_cang',
            division_type: 'xã',
            short_codename: 'cha_cang',
            parentId: 103,
          },
          {
            title: 'Xã Na Cô Sa',
            code: 3167,
            value: 'xa_na_co_sa',
            division_type: 'xã',
            short_codename: 'na_co_sa',
            parentId: 103,
          },
          {
            title: 'Xã Nà Khoa',
            code: 3168,
            value: 'xa_na_khoa',
            division_type: 'xã',
            short_codename: 'na_khoa',
            parentId: 103,
          },
          {
            title: 'Xã Nà Hỳ',
            code: 3169,
            value: 'xa_na_hy',
            division_type: 'xã',
            short_codename: 'na_hy',
            parentId: 103,
          },
          {
            title: 'Xã Nà Bủng',
            code: 3170,
            value: 'xa_na_bung',
            division_type: 'xã',
            short_codename: 'na_bung',
            parentId: 103,
          },
          {
            title: 'Xã Nậm Nhừ',
            code: 3171,
            value: 'xa_nam_nhu',
            division_type: 'xã',
            short_codename: 'nam_nhu',
            parentId: 103,
          },
          {
            title: 'Xã Nậm Chua',
            code: 3173,
            value: 'xa_nam_chua',
            division_type: 'xã',
            short_codename: 'nam_chua',
            parentId: 103,
          },
          {
            title: 'Xã Nậm Khăn',
            code: 3174,
            value: 'xa_nam_khan',
            division_type: 'xã',
            short_codename: 'nam_khan',
            parentId: 103,
          },
          {
            title: 'Xã Chà Tở',
            code: 3175,
            value: 'xa_cha_to',
            division_type: 'xã',
            short_codename: 'cha_to',
            parentId: 103,
          },
          {
            title: 'Xã Vàng Đán',
            code: 3176,
            value: 'xa_vang_dan',
            division_type: 'xã',
            short_codename: 'vang_dan',
            parentId: 103,
          },
          {
            title: 'Xã Chà Nưa',
            code: 3187,
            value: 'xa_cha_nua',
            division_type: 'xã',
            short_codename: 'cha_nua',
            parentId: 103,
          },
          {
            title: 'Xã Phìn Hồ',
            code: 3198,
            value: 'xa_phin_ho',
            division_type: 'xã',
            short_codename: 'phin_ho',
            parentId: 103,
          },
          {
            title: 'Xã Si Pa Phìn',
            code: 3199,
            value: 'xa_si_pa_phin',
            division_type: 'xã',
            short_codename: 'si_pa_phin',
            parentId: 103,
          },
        ],
        parentId: 11,
      },
    ],
  },
  {
    title: 'Tỉnh Lai Châu',
    code: 12,
    value: 'tinh_lai_chau',
    division_type: 'tỉnh',
    phone_code: 213,
    children: [
      {
        title: 'Thành phố Lai Châu',
        code: 105,
        value: 'thanh_pho_lai_chau',
        division_type: 'thành phố',
        short_codename: 'lai_chau',
        children: [
          {
            title: 'Phường Quyết Thắng',
            code: 3386,
            value: 'phuong_quyet_thang',
            division_type: 'phường',
            short_codename: 'quyet_thang',
            parentId: 105,
          },
          {
            title: 'Phường Tân Phong',
            code: 3387,
            value: 'phuong_tan_phong',
            division_type: 'phường',
            short_codename: 'tan_phong',
            parentId: 105,
          },
          {
            title: 'Phường Quyết Tiến',
            code: 3388,
            value: 'phuong_quyet_tien',
            division_type: 'phường',
            short_codename: 'quyet_tien',
            parentId: 105,
          },
          {
            title: 'Phường Đoàn Kết',
            code: 3389,
            value: 'phuong_doan_ket',
            division_type: 'phường',
            short_codename: 'doan_ket',
            parentId: 105,
          },
          {
            title: 'Xã Sùng Phài',
            code: 3403,
            value: 'xa_sung_phai',
            division_type: 'xã',
            short_codename: 'sung_phai',
            parentId: 105,
          },
          {
            title: 'Phường Đông Phong',
            code: 3408,
            value: 'phuong_dong_phong',
            division_type: 'phường',
            short_codename: 'dong_phong',
            parentId: 105,
          },
          {
            title: 'Xã San Thàng',
            code: 3409,
            value: 'xa_san_thang',
            division_type: 'xã',
            short_codename: 'san_thang',
            parentId: 105,
          },
        ],
        parentId: 12,
      },
      {
        title: 'Huyện Tam Đường',
        code: 106,
        value: 'huyen_tam_duong',
        division_type: 'huyện',
        short_codename: 'tam_duong',
        children: [
          {
            title: 'Thị trấn Tam Đường',
            code: 3390,
            value: 'thi_tran_tam_duong',
            division_type: 'thị trấn',
            short_codename: 'tam_duong',
            parentId: 106,
          },
          {
            title: 'Xã Thèn Sin',
            code: 3394,
            value: 'xa_then_sin',
            division_type: 'xã',
            short_codename: 'then_sin',
            parentId: 106,
          },
          {
            title: 'Xã Tả Lèng',
            code: 3400,
            value: 'xa_ta_leng',
            division_type: 'xã',
            short_codename: 'ta_leng',
            parentId: 106,
          },
          {
            title: 'Xã Giang Ma',
            code: 3405,
            value: 'xa_giang_ma',
            division_type: 'xã',
            short_codename: 'giang_ma',
            parentId: 106,
          },
          {
            title: 'Xã Hồ Thầu',
            code: 3406,
            value: 'xa_ho_thau',
            division_type: 'xã',
            short_codename: 'ho_thau',
            parentId: 106,
          },
          {
            title: 'Xã Bình Lư',
            code: 3412,
            value: 'xa_binh_lu',
            division_type: 'xã',
            short_codename: 'binh_lu',
            parentId: 106,
          },
          {
            title: 'Xã Sơn Bình',
            code: 3413,
            value: 'xa_son_binh',
            division_type: 'xã',
            short_codename: 'son_binh',
            parentId: 106,
          },
          {
            title: 'Xã Nùng Nàng',
            code: 3415,
            value: 'xa_nung_nang',
            division_type: 'xã',
            short_codename: 'nung_nang',
            parentId: 106,
          },
          {
            title: 'Xã Bản Giang',
            code: 3418,
            value: 'xa_ban_giang',
            division_type: 'xã',
            short_codename: 'ban_giang',
            parentId: 106,
          },
          {
            title: 'Xã Bản Hon',
            code: 3421,
            value: 'xa_ban_hon',
            division_type: 'xã',
            short_codename: 'ban_hon',
            parentId: 106,
          },
          {
            title: 'Xã Bản Bo',
            code: 3424,
            value: 'xa_ban_bo',
            division_type: 'xã',
            short_codename: 'ban_bo',
            parentId: 106,
          },
          {
            title: 'Xã Nà Tăm',
            code: 3427,
            value: 'xa_na_tam',
            division_type: 'xã',
            short_codename: 'na_tam',
            parentId: 106,
          },
          {
            title: 'Xã Khun Há',
            code: 3430,
            value: 'xa_khun_ha',
            division_type: 'xã',
            short_codename: 'khun_ha',
            parentId: 106,
          },
        ],
        parentId: 12,
      },
      {
        title: 'Huyện Mường Tè',
        code: 107,
        value: 'huyen_muong_te',
        division_type: 'huyện',
        short_codename: 'muong_te',
        children: [
          {
            title: 'Thị trấn Mường Tè',
            code: 3433,
            value: 'thi_tran_muong_te',
            division_type: 'thị trấn',
            short_codename: 'muong_te',
            parentId: 107,
          },
          {
            title: 'Xã Thu Lũm',
            code: 3436,
            value: 'xa_thu_lum',
            division_type: 'xã',
            short_codename: 'thu_lum',
            parentId: 107,
          },
          {
            title: 'Xã Ka Lăng',
            code: 3439,
            value: 'xa_ka_lang',
            division_type: 'xã',
            short_codename: 'ka_lang',
            parentId: 107,
          },
          {
            title: 'Xã Tá Bạ',
            code: 3440,
            value: 'xa_ta_ba',
            division_type: 'xã',
            short_codename: 'ta_ba',
            parentId: 107,
          },
          {
            title: 'Xã Pa ủ',
            code: 3442,
            value: 'xa_pa_u',
            division_type: 'xã',
            short_codename: 'pa_u',
            parentId: 107,
          },
          {
            title: 'Xã Mường Tè',
            code: 3445,
            value: 'xa_muong_te',
            division_type: 'xã',
            short_codename: 'xa_muong_te',
            parentId: 107,
          },
          {
            title: 'Xã Pa Vệ Sử',
            code: 3448,
            value: 'xa_pa_ve_su',
            division_type: 'xã',
            short_codename: 'pa_ve_su',
            parentId: 107,
          },
          {
            title: 'Xã Mù Cả',
            code: 3451,
            value: 'xa_mu_ca',
            division_type: 'xã',
            short_codename: 'mu_ca',
            parentId: 107,
          },
          {
            title: 'Xã Bum Tở',
            code: 3454,
            value: 'xa_bum_to',
            division_type: 'xã',
            short_codename: 'bum_to',
            parentId: 107,
          },
          {
            title: 'Xã Nậm Khao',
            code: 3457,
            value: 'xa_nam_khao',
            division_type: 'xã',
            short_codename: 'nam_khao',
            parentId: 107,
          },
          {
            title: 'Xã Tà Tổng',
            code: 3463,
            value: 'xa_ta_tong',
            division_type: 'xã',
            short_codename: 'ta_tong',
            parentId: 107,
          },
          {
            title: 'Xã Bum Nưa',
            code: 3466,
            value: 'xa_bum_nua',
            division_type: 'xã',
            short_codename: 'bum_nua',
            parentId: 107,
          },
          {
            title: 'Xã Vàng San',
            code: 3467,
            value: 'xa_vang_san',
            division_type: 'xã',
            short_codename: 'vang_san',
            parentId: 107,
          },
          {
            title: 'Xã Kan Hồ',
            code: 3469,
            value: 'xa_kan_ho',
            division_type: 'xã',
            short_codename: 'kan_ho',
            parentId: 107,
          },
        ],
        parentId: 12,
      },
      {
        title: 'Huyện Sìn Hồ',
        code: 108,
        value: 'huyen_sin_ho',
        division_type: 'huyện',
        short_codename: 'sin_ho',
        children: [
          {
            title: 'Thị trấn Sìn Hồ',
            code: 3478,
            value: 'thi_tran_sin_ho',
            division_type: 'thị trấn',
            short_codename: 'sin_ho',
            parentId: 108,
          },
          {
            title: 'Xã Chăn Nưa',
            code: 3487,
            value: 'xa_chan_nua',
            division_type: 'xã',
            short_codename: 'chan_nua',
            parentId: 108,
          },
          {
            title: 'Xã Pa Tần',
            code: 3493,
            value: 'xa_pa_tan',
            division_type: 'xã',
            short_codename: 'pa_tan',
            parentId: 108,
          },
          {
            title: 'Xã Phìn Hồ',
            code: 3496,
            value: 'xa_phin_ho',
            division_type: 'xã',
            short_codename: 'phin_ho',
            parentId: 108,
          },
          {
            title: 'Xã Hồng Thu',
            code: 3499,
            value: 'xa_hong_thu',
            division_type: 'xã',
            short_codename: 'hong_thu',
            parentId: 108,
          },
          {
            title: 'Xã Phăng Sô Lin',
            code: 3505,
            value: 'xa_phang_so_lin',
            division_type: 'xã',
            short_codename: 'phang_so_lin',
            parentId: 108,
          },
          {
            title: 'Xã Ma Quai',
            code: 3508,
            value: 'xa_ma_quai',
            division_type: 'xã',
            short_codename: 'ma_quai',
            parentId: 108,
          },
          {
            title: 'Xã Lùng Thàng',
            code: 3509,
            value: 'xa_lung_thang',
            division_type: 'xã',
            short_codename: 'lung_thang',
            parentId: 108,
          },
          {
            title: 'Xã Tả Phìn',
            code: 3511,
            value: 'xa_ta_phin',
            division_type: 'xã',
            short_codename: 'ta_phin',
            parentId: 108,
          },
          {
            title: 'Xã Sà Dề Phìn',
            code: 3514,
            value: 'xa_sa_de_phin',
            division_type: 'xã',
            short_codename: 'sa_de_phin',
            parentId: 108,
          },
          {
            title: 'Xã Nậm Tăm',
            code: 3517,
            value: 'xa_nam_tam',
            division_type: 'xã',
            short_codename: 'nam_tam',
            parentId: 108,
          },
          {
            title: 'Xã Tả Ngảo',
            code: 3520,
            value: 'xa_ta_ngao',
            division_type: 'xã',
            short_codename: 'ta_ngao',
            parentId: 108,
          },
          {
            title: 'Xã Pu Sam Cáp',
            code: 3523,
            value: 'xa_pu_sam_cap',
            division_type: 'xã',
            short_codename: 'pu_sam_cap',
            parentId: 108,
          },
          {
            title: 'Xã Nậm Cha',
            code: 3526,
            value: 'xa_nam_cha',
            division_type: 'xã',
            short_codename: 'nam_cha',
            parentId: 108,
          },
          {
            title: 'Xã Pa Khoá',
            code: 3527,
            value: 'xa_pa_khoa',
            division_type: 'xã',
            short_codename: 'pa_khoa',
            parentId: 108,
          },
          {
            title: 'Xã Làng Mô',
            code: 3529,
            value: 'xa_lang_mo',
            division_type: 'xã',
            short_codename: 'lang_mo',
            parentId: 108,
          },
          {
            title: 'Xã Noong Hẻo',
            code: 3532,
            value: 'xa_noong_heo',
            division_type: 'xã',
            short_codename: 'noong_heo',
            parentId: 108,
          },
          {
            title: 'Xã Nậm Mạ',
            code: 3535,
            value: 'xa_nam_ma',
            division_type: 'xã',
            short_codename: 'nam_ma',
            parentId: 108,
          },
          {
            title: 'Xã Căn Co',
            code: 3538,
            value: 'xa_can_co',
            division_type: 'xã',
            short_codename: 'can_co',
            parentId: 108,
          },
          {
            title: 'Xã Tủa Sín Chải',
            code: 3541,
            value: 'xa_tua_sin_chai',
            division_type: 'xã',
            short_codename: 'tua_sin_chai',
            parentId: 108,
          },
          {
            title: 'Xã Nậm Cuổi',
            code: 3544,
            value: 'xa_nam_cuoi',
            division_type: 'xã',
            short_codename: 'nam_cuoi',
            parentId: 108,
          },
          {
            title: 'Xã Nậm Hăn',
            code: 3547,
            value: 'xa_nam_han',
            division_type: 'xã',
            short_codename: 'nam_han',
            parentId: 108,
          },
        ],
        parentId: 12,
      },
      {
        title: 'Huyện Phong Thổ',
        code: 109,
        value: 'huyen_phong_tho',
        division_type: 'huyện',
        short_codename: 'phong_tho',
        children: [
          {
            title: 'Xã Lả Nhì Thàng',
            code: 3391,
            value: 'xa_la_nhi_thang',
            division_type: 'xã',
            short_codename: 'la_nhi_thang',
            parentId: 109,
          },
          {
            title: 'Xã Huổi Luông',
            code: 3490,
            value: 'xa_huoi_luong',
            division_type: 'xã',
            short_codename: 'huoi_luong',
            parentId: 109,
          },
          {
            title: 'Thị trấn Phong Thổ',
            code: 3549,
            value: 'thi_tran_phong_tho',
            division_type: 'thị trấn',
            short_codename: 'phong_tho',
            parentId: 109,
          },
          {
            title: 'Xã Sì Lở Lầu',
            code: 3550,
            value: 'xa_si_lo_lau',
            division_type: 'xã',
            short_codename: 'si_lo_lau',
            parentId: 109,
          },
          {
            title: 'Xã Mồ Sì San',
            code: 3553,
            value: 'xa_mo_si_san',
            division_type: 'xã',
            short_codename: 'mo_si_san',
            parentId: 109,
          },
          {
            title: 'Xã Pa Vây Sử',
            code: 3559,
            value: 'xa_pa_vay_su',
            division_type: 'xã',
            short_codename: 'pa_vay_su',
            parentId: 109,
          },
          {
            title: 'Xã Vàng Ma Chải',
            code: 3562,
            value: 'xa_vang_ma_chai',
            division_type: 'xã',
            short_codename: 'vang_ma_chai',
            parentId: 109,
          },
          {
            title: 'Xã Tông Qua Lìn',
            code: 3565,
            value: 'xa_tong_qua_lin',
            division_type: 'xã',
            short_codename: 'tong_qua_lin',
            parentId: 109,
          },
          {
            title: 'Xã Mù Sang',
            code: 3568,
            value: 'xa_mu_sang',
            division_type: 'xã',
            short_codename: 'mu_sang',
            parentId: 109,
          },
          {
            title: 'Xã Dào San',
            code: 3571,
            value: 'xa_dao_san',
            division_type: 'xã',
            short_codename: 'dao_san',
            parentId: 109,
          },
          {
            title: 'Xã Ma Ly Pho',
            code: 3574,
            value: 'xa_ma_ly_pho',
            division_type: 'xã',
            short_codename: 'ma_ly_pho',
            parentId: 109,
          },
          {
            title: 'Xã Bản Lang',
            code: 3577,
            value: 'xa_ban_lang',
            division_type: 'xã',
            short_codename: 'ban_lang',
            parentId: 109,
          },
          {
            title: 'Xã Hoang Thèn',
            code: 3580,
            value: 'xa_hoang_then',
            division_type: 'xã',
            short_codename: 'hoang_then',
            parentId: 109,
          },
          {
            title: 'Xã Khổng Lào',
            code: 3583,
            value: 'xa_khong_lao',
            division_type: 'xã',
            short_codename: 'khong_lao',
            parentId: 109,
          },
          {
            title: 'Xã Nậm Xe',
            code: 3586,
            value: 'xa_nam_xe',
            division_type: 'xã',
            short_codename: 'nam_xe',
            parentId: 109,
          },
          {
            title: 'Xã Mường So',
            code: 3589,
            value: 'xa_muong_so',
            division_type: 'xã',
            short_codename: 'muong_so',
            parentId: 109,
          },
          {
            title: 'Xã Sin Suối Hồ',
            code: 3592,
            value: 'xa_sin_suoi_ho',
            division_type: 'xã',
            short_codename: 'sin_suoi_ho',
            parentId: 109,
          },
        ],
        parentId: 12,
      },
      {
        title: 'Huyện Than Uyên',
        code: 110,
        value: 'huyen_than_uyen',
        division_type: 'huyện',
        short_codename: 'than_uyen',
        children: [
          {
            title: 'Thị trấn Than Uyên',
            code: 3595,
            value: 'thi_tran_than_uyen',
            division_type: 'thị trấn',
            short_codename: 'than_uyen',
            parentId: 110,
          },
          {
            title: 'Xã Phúc Than',
            code: 3618,
            value: 'xa_phuc_than',
            division_type: 'xã',
            short_codename: 'phuc_than',
            parentId: 110,
          },
          {
            title: 'Xã Mường Than',
            code: 3619,
            value: 'xa_muong_than',
            division_type: 'xã',
            short_codename: 'muong_than',
            parentId: 110,
          },
          {
            title: 'Xã Mường Mít',
            code: 3625,
            value: 'xa_muong_mit',
            division_type: 'xã',
            short_codename: 'muong_mit',
            parentId: 110,
          },
          {
            title: 'Xã Pha Mu',
            code: 3628,
            value: 'xa_pha_mu',
            division_type: 'xã',
            short_codename: 'pha_mu',
            parentId: 110,
          },
          {
            title: 'Xã Mường Cang',
            code: 3631,
            value: 'xa_muong_cang',
            division_type: 'xã',
            short_codename: 'muong_cang',
            parentId: 110,
          },
          {
            title: 'Xã Hua Nà',
            code: 3632,
            value: 'xa_hua_na',
            division_type: 'xã',
            short_codename: 'hua_na',
            parentId: 110,
          },
          {
            title: 'Xã Tà Hừa',
            code: 3634,
            value: 'xa_ta_hua',
            division_type: 'xã',
            short_codename: 'ta_hua',
            parentId: 110,
          },
          {
            title: 'Xã Mường Kim',
            code: 3637,
            value: 'xa_muong_kim',
            division_type: 'xã',
            short_codename: 'muong_kim',
            parentId: 110,
          },
          {
            title: 'Xã Tà Mung',
            code: 3638,
            value: 'xa_ta_mung',
            division_type: 'xã',
            short_codename: 'ta_mung',
            parentId: 110,
          },
          {
            title: 'Xã Tà Gia',
            code: 3640,
            value: 'xa_ta_gia',
            division_type: 'xã',
            short_codename: 'ta_gia',
            parentId: 110,
          },
          {
            title: 'Xã Khoen On',
            code: 3643,
            value: 'xa_khoen_on',
            division_type: 'xã',
            short_codename: 'khoen_on',
            parentId: 110,
          },
        ],
        parentId: 12,
      },
      {
        title: 'Huyện Tân Uyên',
        code: 111,
        value: 'huyen_tan_uyen',
        division_type: 'huyện',
        short_codename: 'tan_uyen',
        children: [
          {
            title: 'Thị trấn Tân Uyên',
            code: 3598,
            value: 'thi_tran_tan_uyen',
            division_type: 'thị trấn',
            short_codename: 'tan_uyen',
            parentId: 111,
          },
          {
            title: 'Xã Mường Khoa',
            code: 3601,
            value: 'xa_muong_khoa',
            division_type: 'xã',
            short_codename: 'muong_khoa',
            parentId: 111,
          },
          {
            title: 'Xã Phúc Khoa',
            code: 3602,
            value: 'xa_phuc_khoa',
            division_type: 'xã',
            short_codename: 'phuc_khoa',
            parentId: 111,
          },
          {
            title: 'Xã Thân Thuộc',
            code: 3604,
            value: 'xa_than_thuoc',
            division_type: 'xã',
            short_codename: 'than_thuoc',
            parentId: 111,
          },
          {
            title: 'Xã Trung Đồng',
            code: 3605,
            value: 'xa_trung_dong',
            division_type: 'xã',
            short_codename: 'trung_dong',
            parentId: 111,
          },
          {
            title: 'Xã Hố Mít',
            code: 3607,
            value: 'xa_ho_mit',
            division_type: 'xã',
            short_codename: 'ho_mit',
            parentId: 111,
          },
          {
            title: 'Xã Nậm Cần',
            code: 3610,
            value: 'xa_nam_can',
            division_type: 'xã',
            short_codename: 'nam_can',
            parentId: 111,
          },
          {
            title: 'Xã Nậm Sỏ',
            code: 3613,
            value: 'xa_nam_so',
            division_type: 'xã',
            short_codename: 'nam_so',
            parentId: 111,
          },
          {
            title: 'Xã Pắc Ta',
            code: 3616,
            value: 'xa_pac_ta',
            division_type: 'xã',
            short_codename: 'pac_ta',
            parentId: 111,
          },
          {
            title: 'Xã Tà Mít',
            code: 3622,
            value: 'xa_ta_mit',
            division_type: 'xã',
            short_codename: 'ta_mit',
            parentId: 111,
          },
        ],
        parentId: 12,
      },
      {
        title: 'Huyện Nậm Nhùn',
        code: 112,
        value: 'huyen_nam_nhun',
        division_type: 'huyện',
        short_codename: 'nam_nhun',
        children: [
          {
            title: 'Thị trấn Nậm Nhùn',
            code: 3434,
            value: 'thi_tran_nam_nhun',
            division_type: 'thị trấn',
            short_codename: 'nam_nhun',
            parentId: 112,
          },
          {
            title: 'Xã Hua Bun',
            code: 3460,
            value: 'xa_hua_bun',
            division_type: 'xã',
            short_codename: 'hua_bun',
            parentId: 112,
          },
          {
            title: 'Xã Mường Mô',
            code: 3472,
            value: 'xa_muong_mo',
            division_type: 'xã',
            short_codename: 'muong_mo',
            parentId: 112,
          },
          {
            title: 'Xã Nậm Chà',
            code: 3473,
            value: 'xa_nam_cha',
            division_type: 'xã',
            short_codename: 'nam_cha',
            parentId: 112,
          },
          {
            title: 'Xã Nậm Manh',
            code: 3474,
            value: 'xa_nam_manh',
            division_type: 'xã',
            short_codename: 'nam_manh',
            parentId: 112,
          },
          {
            title: 'Xã Nậm Hàng',
            code: 3475,
            value: 'xa_nam_hang',
            division_type: 'xã',
            short_codename: 'nam_hang',
            parentId: 112,
          },
          {
            title: 'Xã Lê Lợi',
            code: 3481,
            value: 'xa_le_loi',
            division_type: 'xã',
            short_codename: 'le_loi',
            parentId: 112,
          },
          {
            title: 'Xã Pú Đao',
            code: 3484,
            value: 'xa_pu_dao',
            division_type: 'xã',
            short_codename: 'pu_dao',
            parentId: 112,
          },
          {
            title: 'Xã Nậm Pì',
            code: 3488,
            value: 'xa_nam_pi',
            division_type: 'xã',
            short_codename: 'nam_pi',
            parentId: 112,
          },
          {
            title: 'Xã Nậm Ban',
            code: 3502,
            value: 'xa_nam_ban',
            division_type: 'xã',
            short_codename: 'nam_ban',
            parentId: 112,
          },
          {
            title: 'Xã Trung Chải',
            code: 3503,
            value: 'xa_trung_chai',
            division_type: 'xã',
            short_codename: 'trung_chai',
            parentId: 112,
          },
        ],
        parentId: 12,
      },
    ],
  },
  {
    title: 'Tỉnh Sơn La',
    code: 14,
    value: 'tinh_son_la',
    division_type: 'tỉnh',
    phone_code: 212,
    children: [
      {
        title: 'Thành phố Sơn La',
        code: 116,
        value: 'thanh_pho_son_la',
        division_type: 'thành phố',
        short_codename: 'son_la',
        children: [
          {
            title: 'Phường Chiềng Lề',
            code: 3646,
            value: 'phuong_chieng_le',
            division_type: 'phường',
            short_codename: 'chieng_le',
            parentId: 116,
          },
          {
            title: 'Phường Tô Hiệu',
            code: 3649,
            value: 'phuong_to_hieu',
            division_type: 'phường',
            short_codename: 'to_hieu',
            parentId: 116,
          },
          {
            title: 'Phường Quyết Thắng',
            code: 3652,
            value: 'phuong_quyet_thang',
            division_type: 'phường',
            short_codename: 'quyet_thang',
            parentId: 116,
          },
          {
            title: 'Phường Quyết Tâm',
            code: 3655,
            value: 'phuong_quyet_tam',
            division_type: 'phường',
            short_codename: 'quyet_tam',
            parentId: 116,
          },
          {
            title: 'Xã Chiềng Cọ',
            code: 3658,
            value: 'xa_chieng_co',
            division_type: 'xã',
            short_codename: 'chieng_co',
            parentId: 116,
          },
          {
            title: 'Xã Chiềng Đen',
            code: 3661,
            value: 'xa_chieng_den',
            division_type: 'xã',
            short_codename: 'chieng_den',
            parentId: 116,
          },
          {
            title: 'Xã Chiềng Xôm',
            code: 3664,
            value: 'xa_chieng_xom',
            division_type: 'xã',
            short_codename: 'chieng_xom',
            parentId: 116,
          },
          {
            title: 'Phường Chiềng An',
            code: 3667,
            value: 'phuong_chieng_an',
            division_type: 'phường',
            short_codename: 'chieng_an',
            parentId: 116,
          },
          {
            title: 'Phường Chiềng Cơi',
            code: 3670,
            value: 'phuong_chieng_coi',
            division_type: 'phường',
            short_codename: 'chieng_coi',
            parentId: 116,
          },
          {
            title: 'Xã Chiềng Ngần',
            code: 3673,
            value: 'xa_chieng_ngan',
            division_type: 'xã',
            short_codename: 'chieng_ngan',
            parentId: 116,
          },
          {
            title: 'Xã Hua La',
            code: 3676,
            value: 'xa_hua_la',
            division_type: 'xã',
            short_codename: 'hua_la',
            parentId: 116,
          },
          {
            title: 'Phường Chiềng Sinh',
            code: 3679,
            value: 'phuong_chieng_sinh',
            division_type: 'phường',
            short_codename: 'chieng_sinh',
            parentId: 116,
          },
        ],
        parentId: 14,
      },
      {
        title: 'Huyện Quỳnh Nhai',
        code: 118,
        value: 'huyen_quynh_nhai',
        division_type: 'huyện',
        short_codename: 'quynh_nhai',
        children: [
          {
            title: 'Xã Mường Chiên',
            code: 3682,
            value: 'xa_muong_chien',
            division_type: 'xã',
            short_codename: 'muong_chien',
            parentId: 118,
          },
          {
            title: 'Xã Cà Nàng',
            code: 3685,
            value: 'xa_ca_nang',
            division_type: 'xã',
            short_codename: 'ca_nang',
            parentId: 118,
          },
          {
            title: 'Xã Chiềng Khay',
            code: 3688,
            value: 'xa_chieng_khay',
            division_type: 'xã',
            short_codename: 'chieng_khay',
            parentId: 118,
          },
          {
            title: 'Xã Mường Giôn',
            code: 3694,
            value: 'xa_muong_gion',
            division_type: 'xã',
            short_codename: 'muong_gion',
            parentId: 118,
          },
          {
            title: 'Xã Pá Ma Pha Khinh',
            code: 3697,
            value: 'xa_pa_ma_pha_khinh',
            division_type: 'xã',
            short_codename: 'pa_ma_pha_khinh',
            parentId: 118,
          },
          {
            title: 'Xã Chiềng Ơn',
            code: 3700,
            value: 'xa_chieng_on',
            division_type: 'xã',
            short_codename: 'chieng_on',
            parentId: 118,
          },
          {
            title: 'Xã Mường Giàng',
            code: 3703,
            value: 'xa_muong_giang',
            division_type: 'xã',
            short_codename: 'muong_giang',
            parentId: 118,
          },
          {
            title: 'Xã Chiềng Bằng',
            code: 3706,
            value: 'xa_chieng_bang',
            division_type: 'xã',
            short_codename: 'chieng_bang',
            parentId: 118,
          },
          {
            title: 'Xã Mường Sại',
            code: 3709,
            value: 'xa_muong_sai',
            division_type: 'xã',
            short_codename: 'muong_sai',
            parentId: 118,
          },
          {
            title: 'Xã Nậm ét',
            code: 3712,
            value: 'xa_nam_et',
            division_type: 'xã',
            short_codename: 'nam_et',
            parentId: 118,
          },
          {
            title: 'Xã Chiềng Khoang',
            code: 3718,
            value: 'xa_chieng_khoang',
            division_type: 'xã',
            short_codename: 'chieng_khoang',
            parentId: 118,
          },
        ],
        parentId: 14,
      },
      {
        title: 'Huyện Thuận Châu',
        code: 119,
        value: 'huyen_thuan_chau',
        division_type: 'huyện',
        short_codename: 'thuan_chau',
        children: [
          {
            title: 'Thị trấn Thuận Châu',
            code: 3721,
            value: 'thi_tran_thuan_chau',
            division_type: 'thị trấn',
            short_codename: 'thuan_chau',
            parentId: 119,
          },
          {
            title: 'Xã Phổng Lái',
            code: 3724,
            value: 'xa_phong_lai',
            division_type: 'xã',
            short_codename: 'phong_lai',
            parentId: 119,
          },
          {
            title: 'Xã Mường é',
            code: 3727,
            value: 'xa_muong_e',
            division_type: 'xã',
            short_codename: 'muong_e',
            parentId: 119,
          },
          {
            title: 'Xã Chiềng Pha',
            code: 3730,
            value: 'xa_chieng_pha',
            division_type: 'xã',
            short_codename: 'chieng_pha',
            parentId: 119,
          },
          {
            title: 'Xã Chiềng La',
            code: 3733,
            value: 'xa_chieng_la',
            division_type: 'xã',
            short_codename: 'chieng_la',
            parentId: 119,
          },
          {
            title: 'Xã Chiềng Ngàm',
            code: 3736,
            value: 'xa_chieng_ngam',
            division_type: 'xã',
            short_codename: 'chieng_ngam',
            parentId: 119,
          },
          {
            title: 'Xã Liệp Tè',
            code: 3739,
            value: 'xa_liep_te',
            division_type: 'xã',
            short_codename: 'liep_te',
            parentId: 119,
          },
          {
            title: 'Xã é Tòng',
            code: 3742,
            value: 'xa_e_tong',
            division_type: 'xã',
            short_codename: 'e_tong',
            parentId: 119,
          },
          {
            title: 'Xã Phổng Lập',
            code: 3745,
            value: 'xa_phong_lap',
            division_type: 'xã',
            short_codename: 'phong_lap',
            parentId: 119,
          },
          {
            title: 'Xã Phổng Lăng',
            code: 3748,
            value: 'xa_phong_lang',
            division_type: 'xã',
            short_codename: 'phong_lang',
            parentId: 119,
          },
          {
            title: 'Xã Chiềng Ly',
            code: 3751,
            value: 'xa_chieng_ly',
            division_type: 'xã',
            short_codename: 'chieng_ly',
            parentId: 119,
          },
          {
            title: 'Xã Noong Lay',
            code: 3754,
            value: 'xa_noong_lay',
            division_type: 'xã',
            short_codename: 'noong_lay',
            parentId: 119,
          },
          {
            title: 'Xã Mường Khiêng',
            code: 3757,
            value: 'xa_muong_khieng',
            division_type: 'xã',
            short_codename: 'muong_khieng',
            parentId: 119,
          },
          {
            title: 'Xã Mường Bám',
            code: 3760,
            value: 'xa_muong_bam',
            division_type: 'xã',
            short_codename: 'muong_bam',
            parentId: 119,
          },
          {
            title: 'Xã Long Hẹ',
            code: 3763,
            value: 'xa_long_he',
            division_type: 'xã',
            short_codename: 'long_he',
            parentId: 119,
          },
          {
            title: 'Xã Chiềng Bôm',
            code: 3766,
            value: 'xa_chieng_bom',
            division_type: 'xã',
            short_codename: 'chieng_bom',
            parentId: 119,
          },
          {
            title: 'Xã Thôm Mòn',
            code: 3769,
            value: 'xa_thom_mon',
            division_type: 'xã',
            short_codename: 'thom_mon',
            parentId: 119,
          },
          {
            title: 'Xã Tông Lạnh',
            code: 3772,
            value: 'xa_tong_lanh',
            division_type: 'xã',
            short_codename: 'tong_lanh',
            parentId: 119,
          },
          {
            title: 'Xã Tông Cọ',
            code: 3775,
            value: 'xa_tong_co',
            division_type: 'xã',
            short_codename: 'tong_co',
            parentId: 119,
          },
          {
            title: 'Xã Bó Mười',
            code: 3778,
            value: 'xa_bo_muoi',
            division_type: 'xã',
            short_codename: 'bo_muoi',
            parentId: 119,
          },
          {
            title: 'Xã Co Mạ',
            code: 3781,
            value: 'xa_co_ma',
            division_type: 'xã',
            short_codename: 'co_ma',
            parentId: 119,
          },
          {
            title: 'Xã Púng Tra',
            code: 3784,
            value: 'xa_pung_tra',
            division_type: 'xã',
            short_codename: 'pung_tra',
            parentId: 119,
          },
          {
            title: 'Xã Chiềng Pấc',
            code: 3787,
            value: 'xa_chieng_pac',
            division_type: 'xã',
            short_codename: 'chieng_pac',
            parentId: 119,
          },
          {
            title: 'Xã Nậm Lầu',
            code: 3790,
            value: 'xa_nam_lau',
            division_type: 'xã',
            short_codename: 'nam_lau',
            parentId: 119,
          },
          {
            title: 'Xã Bon Phặng',
            code: 3793,
            value: 'xa_bon_phang',
            division_type: 'xã',
            short_codename: 'bon_phang',
            parentId: 119,
          },
          {
            title: 'Xã Co Tòng',
            code: 3796,
            value: 'xa_co_tong',
            division_type: 'xã',
            short_codename: 'co_tong',
            parentId: 119,
          },
          {
            title: 'Xã Muổi Nọi',
            code: 3799,
            value: 'xa_muoi_noi',
            division_type: 'xã',
            short_codename: 'muoi_noi',
            parentId: 119,
          },
          {
            title: 'Xã Pá Lông',
            code: 3802,
            value: 'xa_pa_long',
            division_type: 'xã',
            short_codename: 'pa_long',
            parentId: 119,
          },
          {
            title: 'Xã Bản Lầm',
            code: 3805,
            value: 'xa_ban_lam',
            division_type: 'xã',
            short_codename: 'ban_lam',
            parentId: 119,
          },
        ],
        parentId: 14,
      },
      {
        title: 'Huyện Mường La',
        code: 120,
        value: 'huyen_muong_la',
        division_type: 'huyện',
        short_codename: 'muong_la',
        children: [
          {
            title: 'Thị trấn Ít Ong',
            code: 3808,
            value: 'thi_tran_it_ong',
            division_type: 'thị trấn',
            short_codename: 'it_ong',
            parentId: 120,
          },
          {
            title: 'Xã Nậm Giôn',
            code: 3811,
            value: 'xa_nam_gion',
            division_type: 'xã',
            short_codename: 'nam_gion',
            parentId: 120,
          },
          {
            title: 'Xã Chiềng Lao',
            code: 3814,
            value: 'xa_chieng_lao',
            division_type: 'xã',
            short_codename: 'chieng_lao',
            parentId: 120,
          },
          {
            title: 'Xã Hua Trai',
            code: 3817,
            value: 'xa_hua_trai',
            division_type: 'xã',
            short_codename: 'hua_trai',
            parentId: 120,
          },
          {
            title: 'Xã Ngọc Chiến',
            code: 3820,
            value: 'xa_ngoc_chien',
            division_type: 'xã',
            short_codename: 'ngoc_chien',
            parentId: 120,
          },
          {
            title: 'Xã Mường Trai',
            code: 3823,
            value: 'xa_muong_trai',
            division_type: 'xã',
            short_codename: 'muong_trai',
            parentId: 120,
          },
          {
            title: 'Xã Nậm Păm',
            code: 3826,
            value: 'xa_nam_pam',
            division_type: 'xã',
            short_codename: 'nam_pam',
            parentId: 120,
          },
          {
            title: 'Xã Chiềng Muôn',
            code: 3829,
            value: 'xa_chieng_muon',
            division_type: 'xã',
            short_codename: 'chieng_muon',
            parentId: 120,
          },
          {
            title: 'Xã Chiềng Ân',
            code: 3832,
            value: 'xa_chieng_an',
            division_type: 'xã',
            short_codename: 'chieng_an',
            parentId: 120,
          },
          {
            title: 'Xã Pi Toong',
            code: 3835,
            value: 'xa_pi_toong',
            division_type: 'xã',
            short_codename: 'pi_toong',
            parentId: 120,
          },
          {
            title: 'Xã Chiềng Công',
            code: 3838,
            value: 'xa_chieng_cong',
            division_type: 'xã',
            short_codename: 'chieng_cong',
            parentId: 120,
          },
          {
            title: 'Xã Tạ Bú',
            code: 3841,
            value: 'xa_ta_bu',
            division_type: 'xã',
            short_codename: 'ta_bu',
            parentId: 120,
          },
          {
            title: 'Xã Chiềng San',
            code: 3844,
            value: 'xa_chieng_san',
            division_type: 'xã',
            short_codename: 'chieng_san',
            parentId: 120,
          },
          {
            title: 'Xã Mường Bú',
            code: 3847,
            value: 'xa_muong_bu',
            division_type: 'xã',
            short_codename: 'muong_bu',
            parentId: 120,
          },
          {
            title: 'Xã Chiềng Hoa',
            code: 3850,
            value: 'xa_chieng_hoa',
            division_type: 'xã',
            short_codename: 'chieng_hoa',
            parentId: 120,
          },
          {
            title: 'Xã Mường Chùm',
            code: 3853,
            value: 'xa_muong_chum',
            division_type: 'xã',
            short_codename: 'muong_chum',
            parentId: 120,
          },
        ],
        parentId: 14,
      },
      {
        title: 'Huyện Bắc Yên',
        code: 121,
        value: 'huyen_bac_yen',
        division_type: 'huyện',
        short_codename: 'bac_yen',
        children: [
          {
            title: 'Thị trấn Bắc Yên',
            code: 3856,
            value: 'thi_tran_bac_yen',
            division_type: 'thị trấn',
            short_codename: 'bac_yen',
            parentId: 121,
          },
          {
            title: 'Xã Phiêng Ban',
            code: 3859,
            value: 'xa_phieng_ban',
            division_type: 'xã',
            short_codename: 'phieng_ban',
            parentId: 121,
          },
          {
            title: 'Xã Hang Chú',
            code: 3862,
            value: 'xa_hang_chu',
            division_type: 'xã',
            short_codename: 'hang_chu',
            parentId: 121,
          },
          {
            title: 'Xã Xím Vàng',
            code: 3865,
            value: 'xa_xim_vang',
            division_type: 'xã',
            short_codename: 'xim_vang',
            parentId: 121,
          },
          {
            title: 'Xã Tà Xùa',
            code: 3868,
            value: 'xa_ta_xua',
            division_type: 'xã',
            short_codename: 'ta_xua',
            parentId: 121,
          },
          {
            title: 'Xã Háng Đồng',
            code: 3869,
            value: 'xa_hang_dong',
            division_type: 'xã',
            short_codename: 'hang_dong',
            parentId: 121,
          },
          {
            title: 'Xã Pắc Ngà',
            code: 3871,
            value: 'xa_pac_nga',
            division_type: 'xã',
            short_codename: 'pac_nga',
            parentId: 121,
          },
          {
            title: 'Xã Làng Chếu',
            code: 3874,
            value: 'xa_lang_cheu',
            division_type: 'xã',
            short_codename: 'lang_cheu',
            parentId: 121,
          },
          {
            title: 'Xã Chim Vàn',
            code: 3877,
            value: 'xa_chim_van',
            division_type: 'xã',
            short_codename: 'chim_van',
            parentId: 121,
          },
          {
            title: 'Xã Mường Khoa',
            code: 3880,
            value: 'xa_muong_khoa',
            division_type: 'xã',
            short_codename: 'muong_khoa',
            parentId: 121,
          },
          {
            title: 'Xã Song Pe',
            code: 3883,
            value: 'xa_song_pe',
            division_type: 'xã',
            short_codename: 'song_pe',
            parentId: 121,
          },
          {
            title: 'Xã Hồng Ngài',
            code: 3886,
            value: 'xa_hong_ngai',
            division_type: 'xã',
            short_codename: 'hong_ngai',
            parentId: 121,
          },
          {
            title: 'Xã Tạ Khoa',
            code: 3889,
            value: 'xa_ta_khoa',
            division_type: 'xã',
            short_codename: 'ta_khoa',
            parentId: 121,
          },
          {
            title: 'Xã Hua Nhàn',
            code: 3890,
            value: 'xa_hua_nhan',
            division_type: 'xã',
            short_codename: 'hua_nhan',
            parentId: 121,
          },
          {
            title: 'Xã Phiêng Côn',
            code: 3892,
            value: 'xa_phieng_con',
            division_type: 'xã',
            short_codename: 'phieng_con',
            parentId: 121,
          },
          {
            title: 'Xã Chiềng Sại',
            code: 3895,
            value: 'xa_chieng_sai',
            division_type: 'xã',
            short_codename: 'chieng_sai',
            parentId: 121,
          },
        ],
        parentId: 14,
      },
      {
        title: 'Huyện Phù Yên',
        code: 122,
        value: 'huyen_phu_yen',
        division_type: 'huyện',
        short_codename: 'phu_yen',
        children: [
          {
            title: 'Thị trấn Phù Yên',
            code: 3898,
            value: 'thi_tran_phu_yen',
            division_type: 'thị trấn',
            short_codename: 'phu_yen',
            parentId: 122,
          },
          {
            title: 'Xã Suối Tọ',
            code: 3901,
            value: 'xa_suoi_to',
            division_type: 'xã',
            short_codename: 'suoi_to',
            parentId: 122,
          },
          {
            title: 'Xã Mường Thải',
            code: 3904,
            value: 'xa_muong_thai',
            division_type: 'xã',
            short_codename: 'muong_thai',
            parentId: 122,
          },
          {
            title: 'Xã Mường Cơi',
            code: 3907,
            value: 'xa_muong_coi',
            division_type: 'xã',
            short_codename: 'muong_coi',
            parentId: 122,
          },
          {
            title: 'Xã Quang Huy',
            code: 3910,
            value: 'xa_quang_huy',
            division_type: 'xã',
            short_codename: 'quang_huy',
            parentId: 122,
          },
          {
            title: 'Xã Huy Bắc',
            code: 3913,
            value: 'xa_huy_bac',
            division_type: 'xã',
            short_codename: 'huy_bac',
            parentId: 122,
          },
          {
            title: 'Xã Huy Thượng',
            code: 3916,
            value: 'xa_huy_thuong',
            division_type: 'xã',
            short_codename: 'huy_thuong',
            parentId: 122,
          },
          {
            title: 'Xã Tân Lang',
            code: 3919,
            value: 'xa_tan_lang',
            division_type: 'xã',
            short_codename: 'tan_lang',
            parentId: 122,
          },
          {
            title: 'Xã Gia Phù',
            code: 3922,
            value: 'xa_gia_phu',
            division_type: 'xã',
            short_codename: 'gia_phu',
            parentId: 122,
          },
          {
            title: 'Xã Tường Phù',
            code: 3925,
            value: 'xa_tuong_phu',
            division_type: 'xã',
            short_codename: 'tuong_phu',
            parentId: 122,
          },
          {
            title: 'Xã Huy Hạ',
            code: 3928,
            value: 'xa_huy_ha',
            division_type: 'xã',
            short_codename: 'huy_ha',
            parentId: 122,
          },
          {
            title: 'Xã Huy Tân',
            code: 3931,
            value: 'xa_huy_tan',
            division_type: 'xã',
            short_codename: 'huy_tan',
            parentId: 122,
          },
          {
            title: 'Xã Mường Lang',
            code: 3934,
            value: 'xa_muong_lang',
            division_type: 'xã',
            short_codename: 'muong_lang',
            parentId: 122,
          },
          {
            title: 'Xã Suối Bau',
            code: 3937,
            value: 'xa_suoi_bau',
            division_type: 'xã',
            short_codename: 'suoi_bau',
            parentId: 122,
          },
          {
            title: 'Xã Huy Tường',
            code: 3940,
            value: 'xa_huy_tuong',
            division_type: 'xã',
            short_codename: 'huy_tuong',
            parentId: 122,
          },
          {
            title: 'Xã Mường Do',
            code: 3943,
            value: 'xa_muong_do',
            division_type: 'xã',
            short_codename: 'muong_do',
            parentId: 122,
          },
          {
            title: 'Xã Sập Xa',
            code: 3946,
            value: 'xa_sap_xa',
            division_type: 'xã',
            short_codename: 'sap_xa',
            parentId: 122,
          },
          {
            title: 'Xã Tường Thượng',
            code: 3949,
            value: 'xa_tuong_thuong',
            division_type: 'xã',
            short_codename: 'tuong_thuong',
            parentId: 122,
          },
          {
            title: 'Xã Tường Tiến',
            code: 3952,
            value: 'xa_tuong_tien',
            division_type: 'xã',
            short_codename: 'tuong_tien',
            parentId: 122,
          },
          {
            title: 'Xã Tường Phong',
            code: 3955,
            value: 'xa_tuong_phong',
            division_type: 'xã',
            short_codename: 'tuong_phong',
            parentId: 122,
          },
          {
            title: 'Xã Tường Hạ',
            code: 3958,
            value: 'xa_tuong_ha',
            division_type: 'xã',
            short_codename: 'tuong_ha',
            parentId: 122,
          },
          {
            title: 'Xã Kim Bon',
            code: 3961,
            value: 'xa_kim_bon',
            division_type: 'xã',
            short_codename: 'kim_bon',
            parentId: 122,
          },
          {
            title: 'Xã Mường Bang',
            code: 3964,
            value: 'xa_muong_bang',
            division_type: 'xã',
            short_codename: 'muong_bang',
            parentId: 122,
          },
          {
            title: 'Xã Đá Đỏ',
            code: 3967,
            value: 'xa_da_do',
            division_type: 'xã',
            short_codename: 'da_do',
            parentId: 122,
          },
          {
            title: 'Xã Tân Phong',
            code: 3970,
            value: 'xa_tan_phong',
            division_type: 'xã',
            short_codename: 'tan_phong',
            parentId: 122,
          },
          {
            title: 'Xã Nam Phong',
            code: 3973,
            value: 'xa_nam_phong',
            division_type: 'xã',
            short_codename: 'nam_phong',
            parentId: 122,
          },
          {
            title: 'Xã Bắc Phong',
            code: 3976,
            value: 'xa_bac_phong',
            division_type: 'xã',
            short_codename: 'bac_phong',
            parentId: 122,
          },
        ],
        parentId: 14,
      },
      {
        title: 'Huyện Mộc Châu',
        code: 123,
        value: 'huyen_moc_chau',
        division_type: 'huyện',
        short_codename: 'moc_chau',
        children: [
          {
            title: 'Thị trấn Mộc Châu',
            code: 3979,
            value: 'thi_tran_moc_chau',
            division_type: 'thị trấn',
            short_codename: 'moc_chau',
            parentId: 123,
          },
          {
            title: 'Thị trấn NT Mộc Châu',
            code: 3982,
            value: 'thi_tran_nt_moc_chau',
            division_type: 'thị trấn',
            short_codename: 'nt_moc_chau',
            parentId: 123,
          },
          {
            title: 'Xã Chiềng Sơn',
            code: 3985,
            value: 'xa_chieng_son',
            division_type: 'xã',
            short_codename: 'chieng_son',
            parentId: 123,
          },
          {
            title: 'Xã Tân Hợp',
            code: 3988,
            value: 'xa_tan_hop',
            division_type: 'xã',
            short_codename: 'tan_hop',
            parentId: 123,
          },
          {
            title: 'Xã Qui Hướng',
            code: 3991,
            value: 'xa_qui_huong',
            division_type: 'xã',
            short_codename: 'qui_huong',
            parentId: 123,
          },
          {
            title: 'Xã Tân Lập',
            code: 3997,
            value: 'xa_tan_lap',
            division_type: 'xã',
            short_codename: 'tan_lap',
            parentId: 123,
          },
          {
            title: 'Xã Nà Mường',
            code: 4000,
            value: 'xa_na_muong',
            division_type: 'xã',
            short_codename: 'na_muong',
            parentId: 123,
          },
          {
            title: 'Xã Tà Lai',
            code: 4003,
            value: 'xa_ta_lai',
            division_type: 'xã',
            short_codename: 'ta_lai',
            parentId: 123,
          },
          {
            title: 'Xã Chiềng Hắc',
            code: 4012,
            value: 'xa_chieng_hac',
            division_type: 'xã',
            short_codename: 'chieng_hac',
            parentId: 123,
          },
          {
            title: 'Xã Hua Păng',
            code: 4015,
            value: 'xa_hua_pang',
            division_type: 'xã',
            short_codename: 'hua_pang',
            parentId: 123,
          },
          {
            title: 'Xã Chiềng Khừa',
            code: 4024,
            value: 'xa_chieng_khua',
            division_type: 'xã',
            short_codename: 'chieng_khua',
            parentId: 123,
          },
          {
            title: 'Xã Mường Sang',
            code: 4027,
            value: 'xa_muong_sang',
            division_type: 'xã',
            short_codename: 'muong_sang',
            parentId: 123,
          },
          {
            title: 'Xã Đông Sang',
            code: 4030,
            value: 'xa_dong_sang',
            division_type: 'xã',
            short_codename: 'dong_sang',
            parentId: 123,
          },
          {
            title: 'Xã Phiêng Luông',
            code: 4033,
            value: 'xa_phieng_luong',
            division_type: 'xã',
            short_codename: 'phieng_luong',
            parentId: 123,
          },
          {
            title: 'Xã Lóng Sập',
            code: 4045,
            value: 'xa_long_sap',
            division_type: 'xã',
            short_codename: 'long_sap',
            parentId: 123,
          },
        ],
        parentId: 14,
      },
      {
        title: 'Huyện Yên Châu',
        code: 124,
        value: 'huyen_yen_chau',
        division_type: 'huyện',
        short_codename: 'yen_chau',
        children: [
          {
            title: 'Thị trấn Yên Châu',
            code: 4060,
            value: 'thi_tran_yen_chau',
            division_type: 'thị trấn',
            short_codename: 'yen_chau',
            parentId: 124,
          },
          {
            title: 'Xã Chiềng Đông',
            code: 4063,
            value: 'xa_chieng_dong',
            division_type: 'xã',
            short_codename: 'chieng_dong',
            parentId: 124,
          },
          {
            title: 'Xã Sập Vạt',
            code: 4066,
            value: 'xa_sap_vat',
            division_type: 'xã',
            short_codename: 'sap_vat',
            parentId: 124,
          },
          {
            title: 'Xã Chiềng Sàng',
            code: 4069,
            value: 'xa_chieng_sang',
            division_type: 'xã',
            short_codename: 'chieng_sang',
            parentId: 124,
          },
          {
            title: 'Xã Chiềng Pằn',
            code: 4072,
            value: 'xa_chieng_pan',
            division_type: 'xã',
            short_codename: 'chieng_pan',
            parentId: 124,
          },
          {
            title: 'Xã Viêng Lán',
            code: 4075,
            value: 'xa_vieng_lan',
            division_type: 'xã',
            short_codename: 'vieng_lan',
            parentId: 124,
          },
          {
            title: 'Xã Chiềng Hặc',
            code: 4078,
            value: 'xa_chieng_hac',
            division_type: 'xã',
            short_codename: 'chieng_hac',
            parentId: 124,
          },
          {
            title: 'Xã Mường Lựm',
            code: 4081,
            value: 'xa_muong_lum',
            division_type: 'xã',
            short_codename: 'muong_lum',
            parentId: 124,
          },
          {
            title: 'Xã Chiềng On',
            code: 4084,
            value: 'xa_chieng_on',
            division_type: 'xã',
            short_codename: 'chieng_on',
            parentId: 124,
          },
          {
            title: 'Xã Yên Sơn',
            code: 4087,
            value: 'xa_yen_son',
            division_type: 'xã',
            short_codename: 'yen_son',
            parentId: 124,
          },
          {
            title: 'Xã Chiềng Khoi',
            code: 4090,
            value: 'xa_chieng_khoi',
            division_type: 'xã',
            short_codename: 'chieng_khoi',
            parentId: 124,
          },
          {
            title: 'Xã Tú Nang',
            code: 4093,
            value: 'xa_tu_nang',
            division_type: 'xã',
            short_codename: 'tu_nang',
            parentId: 124,
          },
          {
            title: 'Xã Lóng Phiêng',
            code: 4096,
            value: 'xa_long_phieng',
            division_type: 'xã',
            short_codename: 'long_phieng',
            parentId: 124,
          },
          {
            title: 'Xã Phiêng Khoài',
            code: 4099,
            value: 'xa_phieng_khoai',
            division_type: 'xã',
            short_codename: 'phieng_khoai',
            parentId: 124,
          },
          {
            title: 'Xã Chiềng Tương',
            code: 4102,
            value: 'xa_chieng_tuong',
            division_type: 'xã',
            short_codename: 'chieng_tuong',
            parentId: 124,
          },
        ],
        parentId: 14,
      },
      {
        title: 'Huyện Mai Sơn',
        code: 125,
        value: 'huyen_mai_son',
        division_type: 'huyện',
        short_codename: 'mai_son',
        children: [
          {
            title: 'Thị trấn Hát Lót',
            code: 4105,
            value: 'thi_tran_hat_lot',
            division_type: 'thị trấn',
            short_codename: 'hat_lot',
            parentId: 125,
          },
          {
            title: 'Xã Chiềng Sung',
            code: 4108,
            value: 'xa_chieng_sung',
            division_type: 'xã',
            short_codename: 'chieng_sung',
            parentId: 125,
          },
          {
            title: 'Xã Mường Bằng',
            code: 4111,
            value: 'xa_muong_bang',
            division_type: 'xã',
            short_codename: 'muong_bang',
            parentId: 125,
          },
          {
            title: 'Xã Chiềng Chăn',
            code: 4114,
            value: 'xa_chieng_chan',
            division_type: 'xã',
            short_codename: 'chieng_chan',
            parentId: 125,
          },
          {
            title: 'Xã Mương Chanh',
            code: 4117,
            value: 'xa_muong_chanh',
            division_type: 'xã',
            short_codename: 'muong_chanh',
            parentId: 125,
          },
          {
            title: 'Xã Chiềng Ban',
            code: 4120,
            value: 'xa_chieng_ban',
            division_type: 'xã',
            short_codename: 'chieng_ban',
            parentId: 125,
          },
          {
            title: 'Xã Chiềng Mung',
            code: 4123,
            value: 'xa_chieng_mung',
            division_type: 'xã',
            short_codename: 'chieng_mung',
            parentId: 125,
          },
          {
            title: 'Xã Mường Bon',
            code: 4126,
            value: 'xa_muong_bon',
            division_type: 'xã',
            short_codename: 'muong_bon',
            parentId: 125,
          },
          {
            title: 'Xã Chiềng Chung',
            code: 4129,
            value: 'xa_chieng_chung',
            division_type: 'xã',
            short_codename: 'chieng_chung',
            parentId: 125,
          },
          {
            title: 'Xã Chiềng Mai',
            code: 4132,
            value: 'xa_chieng_mai',
            division_type: 'xã',
            short_codename: 'chieng_mai',
            parentId: 125,
          },
          {
            title: 'Xã Hát Lót',
            code: 4135,
            value: 'xa_hat_lot',
            division_type: 'xã',
            short_codename: 'xa_hat_lot',
            parentId: 125,
          },
          {
            title: 'Xã Nà Pó',
            code: 4136,
            value: 'xa_na_po',
            division_type: 'xã',
            short_codename: 'na_po',
            parentId: 125,
          },
          {
            title: 'Xã Cò Nòi',
            code: 4138,
            value: 'xa_co_noi',
            division_type: 'xã',
            short_codename: 'co_noi',
            parentId: 125,
          },
          {
            title: 'Xã Chiềng Nơi',
            code: 4141,
            value: 'xa_chieng_noi',
            division_type: 'xã',
            short_codename: 'chieng_noi',
            parentId: 125,
          },
          {
            title: 'Xã Phiêng Cằm',
            code: 4144,
            value: 'xa_phieng_cam',
            division_type: 'xã',
            short_codename: 'phieng_cam',
            parentId: 125,
          },
          {
            title: 'Xã Chiềng Dong',
            code: 4147,
            value: 'xa_chieng_dong',
            division_type: 'xã',
            short_codename: 'chieng_dong',
            parentId: 125,
          },
          {
            title: 'Xã Chiềng Kheo',
            code: 4150,
            value: 'xa_chieng_kheo',
            division_type: 'xã',
            short_codename: 'chieng_kheo',
            parentId: 125,
          },
          {
            title: 'Xã Chiềng Ve',
            code: 4153,
            value: 'xa_chieng_ve',
            division_type: 'xã',
            short_codename: 'chieng_ve',
            parentId: 125,
          },
          {
            title: 'Xã Chiềng Lương',
            code: 4156,
            value: 'xa_chieng_luong',
            division_type: 'xã',
            short_codename: 'chieng_luong',
            parentId: 125,
          },
          {
            title: 'Xã Phiêng Pằn',
            code: 4159,
            value: 'xa_phieng_pan',
            division_type: 'xã',
            short_codename: 'phieng_pan',
            parentId: 125,
          },
          {
            title: 'Xã Nà Ơt',
            code: 4162,
            value: 'xa_na_ot',
            division_type: 'xã',
            short_codename: 'na_ot',
            parentId: 125,
          },
          {
            title: 'Xã Tà Hộc',
            code: 4165,
            value: 'xa_ta_hoc',
            division_type: 'xã',
            short_codename: 'ta_hoc',
            parentId: 125,
          },
        ],
        parentId: 14,
      },
      {
        title: 'Huyện Sông Mã',
        code: 126,
        value: 'huyen_song_ma',
        division_type: 'huyện',
        short_codename: 'song_ma',
        children: [
          {
            title: 'Thị trấn Sông Mã',
            code: 4168,
            value: 'thi_tran_song_ma',
            division_type: 'thị trấn',
            short_codename: 'song_ma',
            parentId: 126,
          },
          {
            title: 'Xã Bó Sinh',
            code: 4171,
            value: 'xa_bo_sinh',
            division_type: 'xã',
            short_codename: 'bo_sinh',
            parentId: 126,
          },
          {
            title: 'Xã Pú Pẩu',
            code: 4174,
            value: 'xa_pu_pau',
            division_type: 'xã',
            short_codename: 'pu_pau',
            parentId: 126,
          },
          {
            title: 'Xã Chiềng Phung',
            code: 4177,
            value: 'xa_chieng_phung',
            division_type: 'xã',
            short_codename: 'chieng_phung',
            parentId: 126,
          },
          {
            title: 'Xã Chiềng En',
            code: 4180,
            value: 'xa_chieng_en',
            division_type: 'xã',
            short_codename: 'chieng_en',
            parentId: 126,
          },
          {
            title: 'Xã Mường Lầm',
            code: 4183,
            value: 'xa_muong_lam',
            division_type: 'xã',
            short_codename: 'muong_lam',
            parentId: 126,
          },
          {
            title: 'Xã Nậm Ty',
            code: 4186,
            value: 'xa_nam_ty',
            division_type: 'xã',
            short_codename: 'nam_ty',
            parentId: 126,
          },
          {
            title: 'Xã Đứa Mòn',
            code: 4189,
            value: 'xa_dua_mon',
            division_type: 'xã',
            short_codename: 'dua_mon',
            parentId: 126,
          },
          {
            title: 'Xã Yên Hưng',
            code: 4192,
            value: 'xa_yen_hung',
            division_type: 'xã',
            short_codename: 'yen_hung',
            parentId: 126,
          },
          {
            title: 'Xã Chiềng Sơ',
            code: 4195,
            value: 'xa_chieng_so',
            division_type: 'xã',
            short_codename: 'chieng_so',
            parentId: 126,
          },
          {
            title: 'Xã Nà Nghịu',
            code: 4198,
            value: 'xa_na_nghiu',
            division_type: 'xã',
            short_codename: 'na_nghiu',
            parentId: 126,
          },
          {
            title: 'Xã Nậm Mằn',
            code: 4201,
            value: 'xa_nam_man',
            division_type: 'xã',
            short_codename: 'nam_man',
            parentId: 126,
          },
          {
            title: 'Xã Chiềng Khoong',
            code: 4204,
            value: 'xa_chieng_khoong',
            division_type: 'xã',
            short_codename: 'chieng_khoong',
            parentId: 126,
          },
          {
            title: 'Xã Chiềng Cang',
            code: 4207,
            value: 'xa_chieng_cang',
            division_type: 'xã',
            short_codename: 'chieng_cang',
            parentId: 126,
          },
          {
            title: 'Xã Huổi Một',
            code: 4210,
            value: 'xa_huoi_mot',
            division_type: 'xã',
            short_codename: 'huoi_mot',
            parentId: 126,
          },
          {
            title: 'Xã Mường Sai',
            code: 4213,
            value: 'xa_muong_sai',
            division_type: 'xã',
            short_codename: 'muong_sai',
            parentId: 126,
          },
          {
            title: 'Xã Mường Cai',
            code: 4216,
            value: 'xa_muong_cai',
            division_type: 'xã',
            short_codename: 'muong_cai',
            parentId: 126,
          },
          {
            title: 'Xã Mường Hung',
            code: 4219,
            value: 'xa_muong_hung',
            division_type: 'xã',
            short_codename: 'muong_hung',
            parentId: 126,
          },
          {
            title: 'Xã Chiềng Khương',
            code: 4222,
            value: 'xa_chieng_khuong',
            division_type: 'xã',
            short_codename: 'chieng_khuong',
            parentId: 126,
          },
        ],
        parentId: 14,
      },
      {
        title: 'Huyện Sốp Cộp',
        code: 127,
        value: 'huyen_sop_cop',
        division_type: 'huyện',
        short_codename: 'sop_cop',
        children: [
          {
            title: 'Xã Sam Kha',
            code: 4225,
            value: 'xa_sam_kha',
            division_type: 'xã',
            short_codename: 'sam_kha',
            parentId: 127,
          },
          {
            title: 'Xã Púng Bánh',
            code: 4228,
            value: 'xa_pung_banh',
            division_type: 'xã',
            short_codename: 'pung_banh',
            parentId: 127,
          },
          {
            title: 'Xã Sốp Cộp',
            code: 4231,
            value: 'xa_sop_cop',
            division_type: 'xã',
            short_codename: 'sop_cop',
            parentId: 127,
          },
          {
            title: 'Xã Dồm Cang',
            code: 4234,
            value: 'xa_dom_cang',
            division_type: 'xã',
            short_codename: 'dom_cang',
            parentId: 127,
          },
          {
            title: 'Xã Nậm Lạnh',
            code: 4237,
            value: 'xa_nam_lanh',
            division_type: 'xã',
            short_codename: 'nam_lanh',
            parentId: 127,
          },
          {
            title: 'Xã Mường Lèo',
            code: 4240,
            value: 'xa_muong_leo',
            division_type: 'xã',
            short_codename: 'muong_leo',
            parentId: 127,
          },
          {
            title: 'Xã Mường Và',
            code: 4243,
            value: 'xa_muong_va',
            division_type: 'xã',
            short_codename: 'muong_va',
            parentId: 127,
          },
          {
            title: 'Xã Mường Lạn',
            code: 4246,
            value: 'xa_muong_lan',
            division_type: 'xã',
            short_codename: 'muong_lan',
            parentId: 127,
          },
        ],
        parentId: 14,
      },
      {
        title: 'Huyện Vân Hồ',
        code: 128,
        value: 'huyen_van_ho',
        division_type: 'huyện',
        short_codename: 'van_ho',
        children: [
          {
            title: 'Xã Suối Bàng',
            code: 3994,
            value: 'xa_suoi_bang',
            division_type: 'xã',
            short_codename: 'suoi_bang',
            parentId: 128,
          },
          {
            title: 'Xã Song Khủa',
            code: 4006,
            value: 'xa_song_khua',
            division_type: 'xã',
            short_codename: 'song_khua',
            parentId: 128,
          },
          {
            title: 'Xã Liên Hoà',
            code: 4009,
            value: 'xa_lien_hoa',
            division_type: 'xã',
            short_codename: 'lien_hoa',
            parentId: 128,
          },
          {
            title: 'Xã Tô Múa',
            code: 4018,
            value: 'xa_to_mua',
            division_type: 'xã',
            short_codename: 'to_mua',
            parentId: 128,
          },
          {
            title: 'Xã Mường Tè',
            code: 4021,
            value: 'xa_muong_te',
            division_type: 'xã',
            short_codename: 'muong_te',
            parentId: 128,
          },
          {
            title: 'Xã Chiềng Khoa',
            code: 4036,
            value: 'xa_chieng_khoa',
            division_type: 'xã',
            short_codename: 'chieng_khoa',
            parentId: 128,
          },
          {
            title: 'Xã Mường Men',
            code: 4039,
            value: 'xa_muong_men',
            division_type: 'xã',
            short_codename: 'muong_men',
            parentId: 128,
          },
          {
            title: 'Xã Quang Minh',
            code: 4042,
            value: 'xa_quang_minh',
            division_type: 'xã',
            short_codename: 'quang_minh',
            parentId: 128,
          },
          {
            title: 'Xã Vân Hồ',
            code: 4048,
            value: 'xa_van_ho',
            division_type: 'xã',
            short_codename: 'van_ho',
            parentId: 128,
          },
          {
            title: 'Xã Lóng Luông',
            code: 4051,
            value: 'xa_long_luong',
            division_type: 'xã',
            short_codename: 'long_luong',
            parentId: 128,
          },
          {
            title: 'Xã Chiềng Yên',
            code: 4054,
            value: 'xa_chieng_yen',
            division_type: 'xã',
            short_codename: 'chieng_yen',
            parentId: 128,
          },
          {
            title: 'Xã Chiềng Xuân',
            code: 4056,
            value: 'xa_chieng_xuan',
            division_type: 'xã',
            short_codename: 'chieng_xuan',
            parentId: 128,
          },
          {
            title: 'Xã Xuân Nha',
            code: 4057,
            value: 'xa_xuan_nha',
            division_type: 'xã',
            short_codename: 'xuan_nha',
            parentId: 128,
          },
          {
            title: 'Xã Tân Xuân',
            code: 4058,
            value: 'xa_tan_xuan',
            division_type: 'xã',
            short_codename: 'tan_xuan',
            parentId: 128,
          },
        ],
        parentId: 14,
      },
    ],
  },
  {
    title: 'Tỉnh Yên Bái',
    code: 15,
    value: 'tinh_yen_bai',
    division_type: 'tỉnh',
    phone_code: 216,
    children: [
      {
        title: 'Thành phố Yên Bái',
        code: 132,
        value: 'thanh_pho_yen_bai',
        division_type: 'thành phố',
        short_codename: 'yen_bai',
        children: [
          {
            title: 'Phường Yên Thịnh',
            code: 4249,
            value: 'phuong_yen_thinh',
            division_type: 'phường',
            short_codename: 'yen_thinh',
            parentId: 132,
          },
          {
            title: 'Phường Yên Ninh',
            code: 4252,
            value: 'phuong_yen_ninh',
            division_type: 'phường',
            short_codename: 'yen_ninh',
            parentId: 132,
          },
          {
            title: 'Phường Minh Tân',
            code: 4255,
            value: 'phuong_minh_tan',
            division_type: 'phường',
            short_codename: 'minh_tan',
            parentId: 132,
          },
          {
            title: 'Phường Nguyễn Thái Học',
            code: 4258,
            value: 'phuong_nguyen_thai_hoc',
            division_type: 'phường',
            short_codename: 'nguyen_thai_hoc',
            parentId: 132,
          },
          {
            title: 'Phường Đồng Tâm',
            code: 4261,
            value: 'phuong_dong_tam',
            division_type: 'phường',
            short_codename: 'dong_tam',
            parentId: 132,
          },
          {
            title: 'Phường Nguyễn Phúc',
            code: 4264,
            value: 'phuong_nguyen_phuc',
            division_type: 'phường',
            short_codename: 'nguyen_phuc',
            parentId: 132,
          },
          {
            title: 'Phường Hồng Hà',
            code: 4267,
            value: 'phuong_hong_ha',
            division_type: 'phường',
            short_codename: 'hong_ha',
            parentId: 132,
          },
          {
            title: 'Xã Minh Bảo',
            code: 4270,
            value: 'xa_minh_bao',
            division_type: 'xã',
            short_codename: 'minh_bao',
            parentId: 132,
          },
          {
            title: 'Phường Nam Cường',
            code: 4273,
            value: 'phuong_nam_cuong',
            division_type: 'phường',
            short_codename: 'nam_cuong',
            parentId: 132,
          },
          {
            title: 'Xã Tuy Lộc',
            code: 4276,
            value: 'xa_tuy_loc',
            division_type: 'xã',
            short_codename: 'tuy_loc',
            parentId: 132,
          },
          {
            title: 'Xã Tân Thịnh',
            code: 4279,
            value: 'xa_tan_thinh',
            division_type: 'xã',
            short_codename: 'tan_thinh',
            parentId: 132,
          },
          {
            title: 'Xã Âu Lâu',
            code: 4540,
            value: 'xa_au_lau',
            division_type: 'xã',
            short_codename: 'au_lau',
            parentId: 132,
          },
          {
            title: 'Xã Giới Phiên',
            code: 4543,
            value: 'xa_gioi_phien',
            division_type: 'xã',
            short_codename: 'gioi_phien',
            parentId: 132,
          },
          {
            title: 'Phường Hợp Minh',
            code: 4546,
            value: 'phuong_hop_minh',
            division_type: 'phường',
            short_codename: 'hop_minh',
            parentId: 132,
          },
          {
            title: 'Xã Văn Phú',
            code: 4558,
            value: 'xa_van_phu',
            division_type: 'xã',
            short_codename: 'van_phu',
            parentId: 132,
          },
        ],
        parentId: 15,
      },
      {
        title: 'Thị xã Nghĩa Lộ',
        code: 133,
        value: 'thi_xa_nghia_lo',
        division_type: 'thị xã',
        short_codename: 'nghia_lo',
        children: [
          {
            title: 'Phường Pú Trạng',
            code: 4282,
            value: 'phuong_pu_trang',
            division_type: 'phường',
            short_codename: 'pu_trang',
            parentId: 133,
          },
          {
            title: 'Phường Trung Tâm',
            code: 4285,
            value: 'phuong_trung_tam',
            division_type: 'phường',
            short_codename: 'trung_tam',
            parentId: 133,
          },
          {
            title: 'Phường Tân An',
            code: 4288,
            value: 'phuong_tan_an',
            division_type: 'phường',
            short_codename: 'tan_an',
            parentId: 133,
          },
          {
            title: 'Phường Cầu Thia',
            code: 4291,
            value: 'phuong_cau_thia',
            division_type: 'phường',
            short_codename: 'cau_thia',
            parentId: 133,
          },
          {
            title: 'Xã Nghĩa Lợi',
            code: 4294,
            value: 'xa_nghia_loi',
            division_type: 'xã',
            short_codename: 'nghia_loi',
            parentId: 133,
          },
          {
            title: 'Xã Nghĩa Phúc',
            code: 4297,
            value: 'xa_nghia_phuc',
            division_type: 'xã',
            short_codename: 'nghia_phuc',
            parentId: 133,
          },
          {
            title: 'Xã Nghĩa An',
            code: 4300,
            value: 'xa_nghia_an',
            division_type: 'xã',
            short_codename: 'nghia_an',
            parentId: 133,
          },
          {
            title: 'Xã Nghĩa Lộ',
            code: 4624,
            value: 'xa_nghia_lo',
            division_type: 'xã',
            short_codename: 'nghia_lo',
            parentId: 133,
          },
          {
            title: 'Xã Sơn A',
            code: 4660,
            value: 'xa_son_a',
            division_type: 'xã',
            short_codename: 'son_a',
            parentId: 133,
          },
          {
            title: 'Xã Phù Nham',
            code: 4663,
            value: 'xa_phu_nham',
            division_type: 'xã',
            short_codename: 'phu_nham',
            parentId: 133,
          },
          {
            title: 'Xã Thanh Lương',
            code: 4675,
            value: 'xa_thanh_luong',
            division_type: 'xã',
            short_codename: 'thanh_luong',
            parentId: 133,
          },
          {
            title: 'Xã Hạnh Sơn',
            code: 4678,
            value: 'xa_hanh_son',
            division_type: 'xã',
            short_codename: 'hanh_son',
            parentId: 133,
          },
          {
            title: 'Xã Phúc Sơn',
            code: 4681,
            value: 'xa_phuc_son',
            division_type: 'xã',
            short_codename: 'phuc_son',
            parentId: 133,
          },
          {
            title: 'Xã Thạch Lương',
            code: 4684,
            value: 'xa_thach_luong',
            division_type: 'xã',
            short_codename: 'thach_luong',
            parentId: 133,
          },
        ],
        parentId: 15,
      },
      {
        title: 'Huyện Lục Yên',
        code: 135,
        value: 'huyen_luc_yen',
        division_type: 'huyện',
        short_codename: 'luc_yen',
        children: [
          {
            title: 'Thị trấn Yên Thế',
            code: 4303,
            value: 'thi_tran_yen_the',
            division_type: 'thị trấn',
            short_codename: 'yen_the',
            parentId: 135,
          },
          {
            title: 'Xã Tân Phượng',
            code: 4306,
            value: 'xa_tan_phuong',
            division_type: 'xã',
            short_codename: 'tan_phuong',
            parentId: 135,
          },
          {
            title: 'Xã Lâm Thượng',
            code: 4309,
            value: 'xa_lam_thuong',
            division_type: 'xã',
            short_codename: 'lam_thuong',
            parentId: 135,
          },
          {
            title: 'Xã Khánh Thiện',
            code: 4312,
            value: 'xa_khanh_thien',
            division_type: 'xã',
            short_codename: 'khanh_thien',
            parentId: 135,
          },
          {
            title: 'Xã Minh Chuẩn',
            code: 4315,
            value: 'xa_minh_chuan',
            division_type: 'xã',
            short_codename: 'minh_chuan',
            parentId: 135,
          },
          {
            title: 'Xã Mai Sơn',
            code: 4318,
            value: 'xa_mai_son',
            division_type: 'xã',
            short_codename: 'mai_son',
            parentId: 135,
          },
          {
            title: 'Xã Khai Trung',
            code: 4321,
            value: 'xa_khai_trung',
            division_type: 'xã',
            short_codename: 'khai_trung',
            parentId: 135,
          },
          {
            title: 'Xã Mường Lai',
            code: 4324,
            value: 'xa_muong_lai',
            division_type: 'xã',
            short_codename: 'muong_lai',
            parentId: 135,
          },
          {
            title: 'Xã An Lạc',
            code: 4327,
            value: 'xa_an_lac',
            division_type: 'xã',
            short_codename: 'an_lac',
            parentId: 135,
          },
          {
            title: 'Xã Minh Xuân',
            code: 4330,
            value: 'xa_minh_xuan',
            division_type: 'xã',
            short_codename: 'minh_xuan',
            parentId: 135,
          },
          {
            title: 'Xã Tô Mậu',
            code: 4333,
            value: 'xa_to_mau',
            division_type: 'xã',
            short_codename: 'to_mau',
            parentId: 135,
          },
          {
            title: 'Xã Tân Lĩnh',
            code: 4336,
            value: 'xa_tan_linh',
            division_type: 'xã',
            short_codename: 'tan_linh',
            parentId: 135,
          },
          {
            title: 'Xã Yên Thắng',
            code: 4339,
            value: 'xa_yen_thang',
            division_type: 'xã',
            short_codename: 'yen_thang',
            parentId: 135,
          },
          {
            title: 'Xã Khánh Hoà',
            code: 4342,
            value: 'xa_khanh_hoa',
            division_type: 'xã',
            short_codename: 'khanh_hoa',
            parentId: 135,
          },
          {
            title: 'Xã Vĩnh Lạc',
            code: 4345,
            value: 'xa_vinh_lac',
            division_type: 'xã',
            short_codename: 'vinh_lac',
            parentId: 135,
          },
          {
            title: 'Xã Liễu Đô',
            code: 4348,
            value: 'xa_lieu_do',
            division_type: 'xã',
            short_codename: 'lieu_do',
            parentId: 135,
          },
          {
            title: 'Xã Động Quan',
            code: 4351,
            value: 'xa_dong_quan',
            division_type: 'xã',
            short_codename: 'dong_quan',
            parentId: 135,
          },
          {
            title: 'Xã Tân Lập',
            code: 4354,
            value: 'xa_tan_lap',
            division_type: 'xã',
            short_codename: 'tan_lap',
            parentId: 135,
          },
          {
            title: 'Xã Minh Tiến',
            code: 4357,
            value: 'xa_minh_tien',
            division_type: 'xã',
            short_codename: 'minh_tien',
            parentId: 135,
          },
          {
            title: 'Xã Trúc Lâu',
            code: 4360,
            value: 'xa_truc_lau',
            division_type: 'xã',
            short_codename: 'truc_lau',
            parentId: 135,
          },
          {
            title: 'Xã Phúc Lợi',
            code: 4363,
            value: 'xa_phuc_loi',
            division_type: 'xã',
            short_codename: 'phuc_loi',
            parentId: 135,
          },
          {
            title: 'Xã Phan Thanh',
            code: 4366,
            value: 'xa_phan_thanh',
            division_type: 'xã',
            short_codename: 'phan_thanh',
            parentId: 135,
          },
          {
            title: 'Xã An Phú',
            code: 4369,
            value: 'xa_an_phu',
            division_type: 'xã',
            short_codename: 'an_phu',
            parentId: 135,
          },
          {
            title: 'Xã Trung Tâm',
            code: 4372,
            value: 'xa_trung_tam',
            division_type: 'xã',
            short_codename: 'trung_tam',
            parentId: 135,
          },
        ],
        parentId: 15,
      },
      {
        title: 'Huyện Văn Yên',
        code: 136,
        value: 'huyen_van_yen',
        division_type: 'huyện',
        short_codename: 'van_yen',
        children: [
          {
            title: 'Thị trấn Mậu A',
            code: 4375,
            value: 'thi_tran_mau_a',
            division_type: 'thị trấn',
            short_codename: 'mau_a',
            parentId: 136,
          },
          {
            title: 'Xã Lang Thíp',
            code: 4378,
            value: 'xa_lang_thip',
            division_type: 'xã',
            short_codename: 'lang_thip',
            parentId: 136,
          },
          {
            title: 'Xã Lâm Giang',
            code: 4381,
            value: 'xa_lam_giang',
            division_type: 'xã',
            short_codename: 'lam_giang',
            parentId: 136,
          },
          {
            title: 'Xã Châu Quế Thượng',
            code: 4384,
            value: 'xa_chau_que_thuong',
            division_type: 'xã',
            short_codename: 'chau_que_thuong',
            parentId: 136,
          },
          {
            title: 'Xã Châu Quế Hạ',
            code: 4387,
            value: 'xa_chau_que_ha',
            division_type: 'xã',
            short_codename: 'chau_que_ha',
            parentId: 136,
          },
          {
            title: 'Xã An Bình',
            code: 4390,
            value: 'xa_an_binh',
            division_type: 'xã',
            short_codename: 'an_binh',
            parentId: 136,
          },
          {
            title: 'Xã Quang Minh',
            code: 4393,
            value: 'xa_quang_minh',
            division_type: 'xã',
            short_codename: 'quang_minh',
            parentId: 136,
          },
          {
            title: 'Xã Đông An',
            code: 4396,
            value: 'xa_dong_an',
            division_type: 'xã',
            short_codename: 'dong_an',
            parentId: 136,
          },
          {
            title: 'Xã Đông Cuông',
            code: 4399,
            value: 'xa_dong_cuong',
            division_type: 'xã',
            short_codename: 'dong_cuong',
            parentId: 136,
          },
          {
            title: 'Xã Phong Dụ Hạ',
            code: 4402,
            value: 'xa_phong_du_ha',
            division_type: 'xã',
            short_codename: 'phong_du_ha',
            parentId: 136,
          },
          {
            title: 'Xã Mậu Đông',
            code: 4405,
            value: 'xa_mau_dong',
            division_type: 'xã',
            short_codename: 'mau_dong',
            parentId: 136,
          },
          {
            title: 'Xã Ngòi A',
            code: 4408,
            value: 'xa_ngoi_a',
            division_type: 'xã',
            short_codename: 'ngoi_a',
            parentId: 136,
          },
          {
            title: 'Xã Xuân Tầm',
            code: 4411,
            value: 'xa_xuan_tam',
            division_type: 'xã',
            short_codename: 'xuan_tam',
            parentId: 136,
          },
          {
            title: 'Xã Tân Hợp',
            code: 4414,
            value: 'xa_tan_hop',
            division_type: 'xã',
            short_codename: 'tan_hop',
            parentId: 136,
          },
          {
            title: 'Xã An Thịnh',
            code: 4417,
            value: 'xa_an_thinh',
            division_type: 'xã',
            short_codename: 'an_thinh',
            parentId: 136,
          },
          {
            title: 'Xã Yên Thái',
            code: 4420,
            value: 'xa_yen_thai',
            division_type: 'xã',
            short_codename: 'yen_thai',
            parentId: 136,
          },
          {
            title: 'Xã Phong Dụ Thượng',
            code: 4423,
            value: 'xa_phong_du_thuong',
            division_type: 'xã',
            short_codename: 'phong_du_thuong',
            parentId: 136,
          },
          {
            title: 'Xã Yên Hợp',
            code: 4426,
            value: 'xa_yen_hop',
            division_type: 'xã',
            short_codename: 'yen_hop',
            parentId: 136,
          },
          {
            title: 'Xã Đại Sơn',
            code: 4429,
            value: 'xa_dai_son',
            division_type: 'xã',
            short_codename: 'dai_son',
            parentId: 136,
          },
          {
            title: 'Xã Đại Phác',
            code: 4435,
            value: 'xa_dai_phac',
            division_type: 'xã',
            short_codename: 'dai_phac',
            parentId: 136,
          },
          {
            title: 'Xã Yên Phú',
            code: 4438,
            value: 'xa_yen_phu',
            division_type: 'xã',
            short_codename: 'yen_phu',
            parentId: 136,
          },
          {
            title: 'Xã Xuân Ái',
            code: 4441,
            value: 'xa_xuan_ai',
            division_type: 'xã',
            short_codename: 'xuan_ai',
            parentId: 136,
          },
          {
            title: 'Xã Viễn Sơn',
            code: 4447,
            value: 'xa_vien_son',
            division_type: 'xã',
            short_codename: 'vien_son',
            parentId: 136,
          },
          {
            title: 'Xã Mỏ Vàng',
            code: 4450,
            value: 'xa_mo_vang',
            division_type: 'xã',
            short_codename: 'mo_vang',
            parentId: 136,
          },
          {
            title: 'Xã Nà Hẩu',
            code: 4453,
            value: 'xa_na_hau',
            division_type: 'xã',
            short_codename: 'na_hau',
            parentId: 136,
          },
        ],
        parentId: 15,
      },
      {
        title: 'Huyện Mù Căng Chải',
        code: 137,
        value: 'huyen_mu_cang_chai',
        division_type: 'huyện',
        short_codename: 'mu_cang_chai',
        children: [
          {
            title: 'Thị trấn Mù Căng Chải',
            code: 4456,
            value: 'thi_tran_mu_cang_chai',
            division_type: 'thị trấn',
            short_codename: 'mu_cang_chai',
            parentId: 137,
          },
          {
            title: 'Xã Hồ Bốn',
            code: 4459,
            value: 'xa_ho_bon',
            division_type: 'xã',
            short_codename: 'ho_bon',
            parentId: 137,
          },
          {
            title: 'Xã Nậm Có',
            code: 4462,
            value: 'xa_nam_co',
            division_type: 'xã',
            short_codename: 'nam_co',
            parentId: 137,
          },
          {
            title: 'Xã Khao Mang',
            code: 4465,
            value: 'xa_khao_mang',
            division_type: 'xã',
            short_codename: 'khao_mang',
            parentId: 137,
          },
          {
            title: 'Xã Mồ Dề',
            code: 4468,
            value: 'xa_mo_de',
            division_type: 'xã',
            short_codename: 'mo_de',
            parentId: 137,
          },
          {
            title: 'Xã Chế Cu Nha',
            code: 4471,
            value: 'xa_che_cu_nha',
            division_type: 'xã',
            short_codename: 'che_cu_nha',
            parentId: 137,
          },
          {
            title: 'Xã Lao Chải',
            code: 4474,
            value: 'xa_lao_chai',
            division_type: 'xã',
            short_codename: 'lao_chai',
            parentId: 137,
          },
          {
            title: 'Xã Kim Nọi',
            code: 4477,
            value: 'xa_kim_noi',
            division_type: 'xã',
            short_codename: 'kim_noi',
            parentId: 137,
          },
          {
            title: 'Xã Cao Phạ',
            code: 4480,
            value: 'xa_cao_pha',
            division_type: 'xã',
            short_codename: 'cao_pha',
            parentId: 137,
          },
          {
            title: 'Xã La Pán Tẩn',
            code: 4483,
            value: 'xa_la_pan_tan',
            division_type: 'xã',
            short_codename: 'la_pan_tan',
            parentId: 137,
          },
          {
            title: 'Xã Dế Su Phình',
            code: 4486,
            value: 'xa_de_su_phinh',
            division_type: 'xã',
            short_codename: 'de_su_phinh',
            parentId: 137,
          },
          {
            title: 'Xã Chế Tạo',
            code: 4489,
            value: 'xa_che_tao',
            division_type: 'xã',
            short_codename: 'che_tao',
            parentId: 137,
          },
          {
            title: 'Xã Púng Luông',
            code: 4492,
            value: 'xa_pung_luong',
            division_type: 'xã',
            short_codename: 'pung_luong',
            parentId: 137,
          },
          {
            title: 'Xã Nậm Khắt',
            code: 4495,
            value: 'xa_nam_khat',
            division_type: 'xã',
            short_codename: 'nam_khat',
            parentId: 137,
          },
        ],
        parentId: 15,
      },
      {
        title: 'Huyện Trấn Yên',
        code: 138,
        value: 'huyen_tran_yen',
        division_type: 'huyện',
        short_codename: 'tran_yen',
        children: [
          {
            title: 'Thị trấn Cổ Phúc',
            code: 4498,
            value: 'thi_tran_co_phuc',
            division_type: 'thị trấn',
            short_codename: 'co_phuc',
            parentId: 138,
          },
          {
            title: 'Xã Tân Đồng',
            code: 4501,
            value: 'xa_tan_dong',
            division_type: 'xã',
            short_codename: 'tan_dong',
            parentId: 138,
          },
          {
            title: 'Xã Báo Đáp',
            code: 4504,
            value: 'xa_bao_dap',
            division_type: 'xã',
            short_codename: 'bao_dap',
            parentId: 138,
          },
          {
            title: 'Xã Đào Thịnh',
            code: 4507,
            value: 'xa_dao_thinh',
            division_type: 'xã',
            short_codename: 'dao_thinh',
            parentId: 138,
          },
          {
            title: 'Xã Việt Thành',
            code: 4510,
            value: 'xa_viet_thanh',
            division_type: 'xã',
            short_codename: 'viet_thanh',
            parentId: 138,
          },
          {
            title: 'Xã Hòa Cuông',
            code: 4513,
            value: 'xa_hoa_cuong',
            division_type: 'xã',
            short_codename: 'hoa_cuong',
            parentId: 138,
          },
          {
            title: 'Xã Minh Quán',
            code: 4516,
            value: 'xa_minh_quan',
            division_type: 'xã',
            short_codename: 'xa_minh_quan',
            parentId: 138,
          },
          {
            title: 'Xã Quy Mông',
            code: 4519,
            value: 'xa_quy_mong',
            division_type: 'xã',
            short_codename: 'quy_mong',
            parentId: 138,
          },
          {
            title: 'Xã Cường Thịnh',
            code: 4522,
            value: 'xa_cuong_thinh',
            division_type: 'xã',
            short_codename: 'cuong_thinh',
            parentId: 138,
          },
          {
            title: 'Xã Kiên Thành',
            code: 4525,
            value: 'xa_kien_thanh',
            division_type: 'xã',
            short_codename: 'kien_thanh',
            parentId: 138,
          },
          {
            title: 'Xã Nga Quán',
            code: 4528,
            value: 'xa_nga_quan',
            division_type: 'xã',
            short_codename: 'nga_quan',
            parentId: 138,
          },
          {
            title: 'Xã Y Can',
            code: 4531,
            value: 'xa_y_can',
            division_type: 'xã',
            short_codename: 'y_can',
            parentId: 138,
          },
          {
            title: 'Xã Lương Thịnh',
            code: 4537,
            value: 'xa_luong_thinh',
            division_type: 'xã',
            short_codename: 'luong_thinh',
            parentId: 138,
          },
          {
            title: 'Xã Bảo Hưng',
            code: 4561,
            value: 'xa_bao_hung',
            division_type: 'xã',
            short_codename: 'bao_hung',
            parentId: 138,
          },
          {
            title: 'Xã Việt Cường',
            code: 4564,
            value: 'xa_viet_cuong',
            division_type: 'xã',
            short_codename: 'viet_cuong',
            parentId: 138,
          },
          {
            title: 'Xã Minh Quân',
            code: 4567,
            value: 'xa_minh_quan',
            division_type: 'xã',
            short_codename: 'xa_minh_quan',
            parentId: 138,
          },
          {
            title: 'Xã Hồng Ca',
            code: 4570,
            value: 'xa_hong_ca',
            division_type: 'xã',
            short_codename: 'hong_ca',
            parentId: 138,
          },
          {
            title: 'Xã Hưng Thịnh',
            code: 4573,
            value: 'xa_hung_thinh',
            division_type: 'xã',
            short_codename: 'hung_thinh',
            parentId: 138,
          },
          {
            title: 'Xã Hưng Khánh',
            code: 4576,
            value: 'xa_hung_khanh',
            division_type: 'xã',
            short_codename: 'hung_khanh',
            parentId: 138,
          },
          {
            title: 'Xã Việt Hồng',
            code: 4579,
            value: 'xa_viet_hong',
            division_type: 'xã',
            short_codename: 'viet_hong',
            parentId: 138,
          },
          {
            title: 'Xã Vân Hội',
            code: 4582,
            value: 'xa_van_hoi',
            division_type: 'xã',
            short_codename: 'van_hoi',
            parentId: 138,
          },
        ],
        parentId: 15,
      },
      {
        title: 'Huyện Trạm Tấu',
        code: 139,
        value: 'huyen_tram_tau',
        division_type: 'huyện',
        short_codename: 'tram_tau',
        children: [
          {
            title: 'Thị trấn Trạm Tấu',
            code: 4585,
            value: 'thi_tran_tram_tau',
            division_type: 'thị trấn',
            short_codename: 'tram_tau',
            parentId: 139,
          },
          {
            title: 'Xã Túc Đán',
            code: 4588,
            value: 'xa_tuc_dan',
            division_type: 'xã',
            short_codename: 'tuc_dan',
            parentId: 139,
          },
          {
            title: 'Xã Pá Lau',
            code: 4591,
            value: 'xa_pa_lau',
            division_type: 'xã',
            short_codename: 'pa_lau',
            parentId: 139,
          },
          {
            title: 'Xã Xà Hồ',
            code: 4594,
            value: 'xa_xa_ho',
            division_type: 'xã',
            short_codename: 'xa_ho',
            parentId: 139,
          },
          {
            title: 'Xã Phình Hồ',
            code: 4597,
            value: 'xa_phinh_ho',
            division_type: 'xã',
            short_codename: 'phinh_ho',
            parentId: 139,
          },
          {
            title: 'Xã Trạm Tấu',
            code: 4600,
            value: 'xa_tram_tau',
            division_type: 'xã',
            short_codename: 'xa_tram_tau',
            parentId: 139,
          },
          {
            title: 'Xã Tà Si Láng',
            code: 4603,
            value: 'xa_ta_si_lang',
            division_type: 'xã',
            short_codename: 'ta_si_lang',
            parentId: 139,
          },
          {
            title: 'Xã Pá Hu',
            code: 4606,
            value: 'xa_pa_hu',
            division_type: 'xã',
            short_codename: 'pa_hu',
            parentId: 139,
          },
          {
            title: 'Xã Làng Nhì',
            code: 4609,
            value: 'xa_lang_nhi',
            division_type: 'xã',
            short_codename: 'lang_nhi',
            parentId: 139,
          },
          {
            title: 'Xã Bản Công',
            code: 4612,
            value: 'xa_ban_cong',
            division_type: 'xã',
            short_codename: 'ban_cong',
            parentId: 139,
          },
          {
            title: 'Xã Bản Mù',
            code: 4615,
            value: 'xa_ban_mu',
            division_type: 'xã',
            short_codename: 'ban_mu',
            parentId: 139,
          },
          {
            title: 'Xã Hát Lìu',
            code: 4618,
            value: 'xa_hat_liu',
            division_type: 'xã',
            short_codename: 'hat_liu',
            parentId: 139,
          },
        ],
        parentId: 15,
      },
      {
        title: 'Huyện Văn Chấn',
        code: 140,
        value: 'huyen_van_chan',
        division_type: 'huyện',
        short_codename: 'van_chan',
        children: [
          {
            title: 'Thị trấn NT Liên Sơn',
            code: 4621,
            value: 'thi_tran_nt_lien_son',
            division_type: 'thị trấn',
            short_codename: 'nt_lien_son',
            parentId: 140,
          },
          {
            title: 'Thị trấn NT Trần Phú',
            code: 4627,
            value: 'thi_tran_nt_tran_phu',
            division_type: 'thị trấn',
            short_codename: 'nt_tran_phu',
            parentId: 140,
          },
          {
            title: 'Xã Tú Lệ',
            code: 4630,
            value: 'xa_tu_le',
            division_type: 'xã',
            short_codename: 'tu_le',
            parentId: 140,
          },
          {
            title: 'Xã Nậm Búng',
            code: 4633,
            value: 'xa_nam_bung',
            division_type: 'xã',
            short_codename: 'nam_bung',
            parentId: 140,
          },
          {
            title: 'Xã Gia Hội',
            code: 4636,
            value: 'xa_gia_hoi',
            division_type: 'xã',
            short_codename: 'gia_hoi',
            parentId: 140,
          },
          {
            title: 'Xã Sùng Đô',
            code: 4639,
            value: 'xa_sung_do',
            division_type: 'xã',
            short_codename: 'sung_do',
            parentId: 140,
          },
          {
            title: 'Xã Nậm Mười',
            code: 4642,
            value: 'xa_nam_muoi',
            division_type: 'xã',
            short_codename: 'nam_muoi',
            parentId: 140,
          },
          {
            title: 'Xã An Lương',
            code: 4645,
            value: 'xa_an_luong',
            division_type: 'xã',
            short_codename: 'an_luong',
            parentId: 140,
          },
          {
            title: 'Xã Nậm Lành',
            code: 4648,
            value: 'xa_nam_lanh',
            division_type: 'xã',
            short_codename: 'nam_lanh',
            parentId: 140,
          },
          {
            title: 'Xã Sơn Lương',
            code: 4651,
            value: 'xa_son_luong',
            division_type: 'xã',
            short_codename: 'son_luong',
            parentId: 140,
          },
          {
            title: 'Xã Suối Quyền',
            code: 4654,
            value: 'xa_suoi_quyen',
            division_type: 'xã',
            short_codename: 'suoi_quyen',
            parentId: 140,
          },
          {
            title: 'Xã Suối Giàng',
            code: 4657,
            value: 'xa_suoi_giang',
            division_type: 'xã',
            short_codename: 'suoi_giang',
            parentId: 140,
          },
          {
            title: 'Xã Nghĩa Sơn',
            code: 4666,
            value: 'xa_nghia_son',
            division_type: 'xã',
            short_codename: 'nghia_son',
            parentId: 140,
          },
          {
            title: 'Xã Suối Bu',
            code: 4669,
            value: 'xa_suoi_bu',
            division_type: 'xã',
            short_codename: 'suoi_bu',
            parentId: 140,
          },
          {
            title: 'Thị trấn Sơn Thịnh',
            code: 4672,
            value: 'thi_tran_son_thinh',
            division_type: 'thị trấn',
            short_codename: 'son_thinh',
            parentId: 140,
          },
          {
            title: 'Xã Đại Lịch',
            code: 4687,
            value: 'xa_dai_lich',
            division_type: 'xã',
            short_codename: 'dai_lich',
            parentId: 140,
          },
          {
            title: 'Xã Đồng Khê',
            code: 4690,
            value: 'xa_dong_khe',
            division_type: 'xã',
            short_codename: 'dong_khe',
            parentId: 140,
          },
          {
            title: 'Xã Cát Thịnh',
            code: 4693,
            value: 'xa_cat_thinh',
            division_type: 'xã',
            short_codename: 'cat_thinh',
            parentId: 140,
          },
          {
            title: 'Xã Tân Thịnh',
            code: 4696,
            value: 'xa_tan_thinh',
            division_type: 'xã',
            short_codename: 'tan_thinh',
            parentId: 140,
          },
          {
            title: 'Xã Chấn Thịnh',
            code: 4699,
            value: 'xa_chan_thinh',
            division_type: 'xã',
            short_codename: 'chan_thinh',
            parentId: 140,
          },
          {
            title: 'Xã Bình Thuận',
            code: 4702,
            value: 'xa_binh_thuan',
            division_type: 'xã',
            short_codename: 'binh_thuan',
            parentId: 140,
          },
          {
            title: 'Xã Thượng Bằng La',
            code: 4705,
            value: 'xa_thuong_bang_la',
            division_type: 'xã',
            short_codename: 'thuong_bang_la',
            parentId: 140,
          },
          {
            title: 'Xã Minh An',
            code: 4708,
            value: 'xa_minh_an',
            division_type: 'xã',
            short_codename: 'minh_an',
            parentId: 140,
          },
          {
            title: 'Xã Nghĩa Tâm',
            code: 4711,
            value: 'xa_nghia_tam',
            division_type: 'xã',
            short_codename: 'nghia_tam',
            parentId: 140,
          },
        ],
        parentId: 15,
      },
      {
        title: 'Huyện Yên Bình',
        code: 141,
        value: 'huyen_yen_binh',
        division_type: 'huyện',
        short_codename: 'yen_binh',
        children: [
          {
            title: 'Thị trấn Yên Bình',
            code: 4714,
            value: 'thi_tran_yen_binh',
            division_type: 'thị trấn',
            short_codename: 'yen_binh',
            parentId: 141,
          },
          {
            title: 'Thị trấn Thác Bà',
            code: 4717,
            value: 'thi_tran_thac_ba',
            division_type: 'thị trấn',
            short_codename: 'thac_ba',
            parentId: 141,
          },
          {
            title: 'Xã Xuân Long',
            code: 4720,
            value: 'xa_xuan_long',
            division_type: 'xã',
            short_codename: 'xuan_long',
            parentId: 141,
          },
          {
            title: 'Xã Cảm Nhân',
            code: 4726,
            value: 'xa_cam_nhan',
            division_type: 'xã',
            short_codename: 'cam_nhan',
            parentId: 141,
          },
          {
            title: 'Xã Ngọc Chấn',
            code: 4729,
            value: 'xa_ngoc_chan',
            division_type: 'xã',
            short_codename: 'ngoc_chan',
            parentId: 141,
          },
          {
            title: 'Xã Tân Nguyên',
            code: 4732,
            value: 'xa_tan_nguyen',
            division_type: 'xã',
            short_codename: 'tan_nguyen',
            parentId: 141,
          },
          {
            title: 'Xã Phúc Ninh',
            code: 4735,
            value: 'xa_phuc_ninh',
            division_type: 'xã',
            short_codename: 'phuc_ninh',
            parentId: 141,
          },
          {
            title: 'Xã Bảo Ái',
            code: 4738,
            value: 'xa_bao_ai',
            division_type: 'xã',
            short_codename: 'bao_ai',
            parentId: 141,
          },
          {
            title: 'Xã Mỹ Gia',
            code: 4741,
            value: 'xa_my_gia',
            division_type: 'xã',
            short_codename: 'my_gia',
            parentId: 141,
          },
          {
            title: 'Xã Xuân Lai',
            code: 4744,
            value: 'xa_xuan_lai',
            division_type: 'xã',
            short_codename: 'xuan_lai',
            parentId: 141,
          },
          {
            title: 'Xã Mông Sơn',
            code: 4747,
            value: 'xa_mong_son',
            division_type: 'xã',
            short_codename: 'mong_son',
            parentId: 141,
          },
          {
            title: 'Xã Cảm Ân',
            code: 4750,
            value: 'xa_cam_an',
            division_type: 'xã',
            short_codename: 'cam_an',
            parentId: 141,
          },
          {
            title: 'Xã Yên Thành',
            code: 4753,
            value: 'xa_yen_thanh',
            division_type: 'xã',
            short_codename: 'yen_thanh',
            parentId: 141,
          },
          {
            title: 'Xã Tân Hương',
            code: 4756,
            value: 'xa_tan_huong',
            division_type: 'xã',
            short_codename: 'tan_huong',
            parentId: 141,
          },
          {
            title: 'Xã Phúc An',
            code: 4759,
            value: 'xa_phuc_an',
            division_type: 'xã',
            short_codename: 'phuc_an',
            parentId: 141,
          },
          {
            title: 'Xã Bạch Hà',
            code: 4762,
            value: 'xa_bach_ha',
            division_type: 'xã',
            short_codename: 'bach_ha',
            parentId: 141,
          },
          {
            title: 'Xã Vũ Linh',
            code: 4765,
            value: 'xa_vu_linh',
            division_type: 'xã',
            short_codename: 'vu_linh',
            parentId: 141,
          },
          {
            title: 'Xã Đại Đồng',
            code: 4768,
            value: 'xa_dai_dong',
            division_type: 'xã',
            short_codename: 'dai_dong',
            parentId: 141,
          },
          {
            title: 'Xã Vĩnh Kiên',
            code: 4771,
            value: 'xa_vinh_kien',
            division_type: 'xã',
            short_codename: 'vinh_kien',
            parentId: 141,
          },
          {
            title: 'Xã Yên Bình',
            code: 4774,
            value: 'xa_yen_binh',
            division_type: 'xã',
            short_codename: 'xa_yen_binh',
            parentId: 141,
          },
          {
            title: 'Xã Thịnh Hưng',
            code: 4777,
            value: 'xa_thinh_hung',
            division_type: 'xã',
            short_codename: 'thinh_hung',
            parentId: 141,
          },
          {
            title: 'Xã Hán Đà',
            code: 4780,
            value: 'xa_han_da',
            division_type: 'xã',
            short_codename: 'han_da',
            parentId: 141,
          },
          {
            title: 'Xã Phú Thịnh',
            code: 4783,
            value: 'xa_phu_thinh',
            division_type: 'xã',
            short_codename: 'phu_thinh',
            parentId: 141,
          },
          {
            title: 'Xã Đại Minh',
            code: 4786,
            value: 'xa_dai_minh',
            division_type: 'xã',
            short_codename: 'dai_minh',
            parentId: 141,
          },
        ],
        parentId: 15,
      },
    ],
  },
  {
    title: 'Tỉnh Hoà Bình',
    code: 17,
    value: 'tinh_hoa_binh',
    division_type: 'tỉnh',
    phone_code: 218,
    children: [
      {
        title: 'Thành phố Hòa Bình',
        code: 148,
        value: 'thanh_pho_hoa_binh',
        division_type: 'thành phố',
        short_codename: 'hoa_binh',
        children: [
          {
            title: 'Phường Thái Bình',
            code: 4789,
            value: 'phuong_thai_binh',
            division_type: 'phường',
            short_codename: 'thai_binh',
            parentId: 148,
          },
          {
            title: 'Phường Tân Hòa',
            code: 4792,
            value: 'phuong_tan_hoa',
            division_type: 'phường',
            short_codename: 'tan_hoa',
            parentId: 148,
          },
          {
            title: 'Phường Thịnh Lang',
            code: 4795,
            value: 'phuong_thinh_lang',
            division_type: 'phường',
            short_codename: 'thinh_lang',
            parentId: 148,
          },
          {
            title: 'Phường Hữu Nghị',
            code: 4798,
            value: 'phuong_huu_nghi',
            division_type: 'phường',
            short_codename: 'huu_nghi',
            parentId: 148,
          },
          {
            title: 'Phường Tân Thịnh',
            code: 4801,
            value: 'phuong_tan_thinh',
            division_type: 'phường',
            short_codename: 'tan_thinh',
            parentId: 148,
          },
          {
            title: 'Phường Đồng Tiến',
            code: 4804,
            value: 'phuong_dong_tien',
            division_type: 'phường',
            short_codename: 'dong_tien',
            parentId: 148,
          },
          {
            title: 'Phường Phương Lâm',
            code: 4807,
            value: 'phuong_phuong_lam',
            division_type: 'phường',
            short_codename: 'phuong_lam',
            parentId: 148,
          },
          {
            title: 'Xã Yên Mông',
            code: 4813,
            value: 'xa_yen_mong',
            division_type: 'xã',
            short_codename: 'yen_mong',
            parentId: 148,
          },
          {
            title: 'Phường Quỳnh Lâm',
            code: 4816,
            value: 'phuong_quynh_lam',
            division_type: 'phường',
            short_codename: 'quynh_lam',
            parentId: 148,
          },
          {
            title: 'Phường Dân Chủ',
            code: 4819,
            value: 'phuong_dan_chu',
            division_type: 'phường',
            short_codename: 'dan_chu',
            parentId: 148,
          },
          {
            title: 'Xã Hòa Bình',
            code: 4825,
            value: 'xa_hoa_binh',
            division_type: 'xã',
            short_codename: 'hoa_binh',
            parentId: 148,
          },
          {
            title: 'Phường Thống Nhất',
            code: 4828,
            value: 'phuong_thong_nhat',
            division_type: 'phường',
            short_codename: 'thong_nhat',
            parentId: 148,
          },
          {
            title: 'Phường Kỳ Sơn',
            code: 4894,
            value: 'phuong_ky_son',
            division_type: 'phường',
            short_codename: 'ky_son',
            parentId: 148,
          },
          {
            title: 'Xã Thịnh Minh',
            code: 4897,
            value: 'xa_thinh_minh',
            division_type: 'xã',
            short_codename: 'thinh_minh',
            parentId: 148,
          },
          {
            title: 'Xã Hợp Thành',
            code: 4903,
            value: 'xa_hop_thanh',
            division_type: 'xã',
            short_codename: 'hop_thanh',
            parentId: 148,
          },
          {
            title: 'Xã Quang Tiến',
            code: 4906,
            value: 'xa_quang_tien',
            division_type: 'xã',
            short_codename: 'quang_tien',
            parentId: 148,
          },
          {
            title: 'Xã Mông Hóa',
            code: 4912,
            value: 'xa_mong_hoa',
            division_type: 'xã',
            short_codename: 'mong_hoa',
            parentId: 148,
          },
          {
            title: 'Phường Trung Minh',
            code: 4918,
            value: 'phuong_trung_minh',
            division_type: 'phường',
            short_codename: 'trung_minh',
            parentId: 148,
          },
          {
            title: 'Xã Độc Lập',
            code: 4921,
            value: 'xa_doc_lap',
            division_type: 'xã',
            short_codename: 'doc_lap',
            parentId: 148,
          },
        ],
        parentId: 17,
      },
      {
        title: 'Huyện Đà Bắc',
        code: 150,
        value: 'huyen_da_bac',
        division_type: 'huyện',
        short_codename: 'da_bac',
        children: [
          {
            title: 'Thị trấn Đà Bắc',
            code: 4831,
            value: 'thi_tran_da_bac',
            division_type: 'thị trấn',
            short_codename: 'da_bac',
            parentId: 150,
          },
          {
            title: 'Xã Nánh Nghê',
            code: 4834,
            value: 'xa_nanh_nghe',
            division_type: 'xã',
            short_codename: 'nanh_nghe',
            parentId: 150,
          },
          {
            title: 'Xã Giáp Đắt',
            code: 4840,
            value: 'xa_giap_dat',
            division_type: 'xã',
            short_codename: 'giap_dat',
            parentId: 150,
          },
          {
            title: 'Xã Mường Chiềng',
            code: 4846,
            value: 'xa_muong_chieng',
            division_type: 'xã',
            short_codename: 'muong_chieng',
            parentId: 150,
          },
          {
            title: 'Xã Tân Pheo',
            code: 4849,
            value: 'xa_tan_pheo',
            division_type: 'xã',
            short_codename: 'tan_pheo',
            parentId: 150,
          },
          {
            title: 'Xã Đồng Chum',
            code: 4852,
            value: 'xa_dong_chum',
            division_type: 'xã',
            short_codename: 'dong_chum',
            parentId: 150,
          },
          {
            title: 'Xã Tân Minh',
            code: 4855,
            value: 'xa_tan_minh',
            division_type: 'xã',
            short_codename: 'tan_minh',
            parentId: 150,
          },
          {
            title: 'Xã Đoàn Kết',
            code: 4858,
            value: 'xa_doan_ket',
            division_type: 'xã',
            short_codename: 'doan_ket',
            parentId: 150,
          },
          {
            title: 'Xã Đồng Ruộng',
            code: 4861,
            value: 'xa_dong_ruong',
            division_type: 'xã',
            short_codename: 'dong_ruong',
            parentId: 150,
          },
          {
            title: 'Xã Tú Lý',
            code: 4867,
            value: 'xa_tu_ly',
            division_type: 'xã',
            short_codename: 'tu_ly',
            parentId: 150,
          },
          {
            title: 'Xã Trung Thành',
            code: 4870,
            value: 'xa_trung_thanh',
            division_type: 'xã',
            short_codename: 'trung_thanh',
            parentId: 150,
          },
          {
            title: 'Xã Yên Hòa',
            code: 4873,
            value: 'xa_yen_hoa',
            division_type: 'xã',
            short_codename: 'yen_hoa',
            parentId: 150,
          },
          {
            title: 'Xã Cao Sơn',
            code: 4876,
            value: 'xa_cao_son',
            division_type: 'xã',
            short_codename: 'cao_son',
            parentId: 150,
          },
          {
            title: 'Xã Toàn Sơn',
            code: 4879,
            value: 'xa_toan_son',
            division_type: 'xã',
            short_codename: 'toan_son',
            parentId: 150,
          },
          {
            title: 'Xã Hiền Lương',
            code: 4885,
            value: 'xa_hien_luong',
            division_type: 'xã',
            short_codename: 'hien_luong',
            parentId: 150,
          },
          {
            title: 'Xã Tiền Phong',
            code: 4888,
            value: 'xa_tien_phong',
            division_type: 'xã',
            short_codename: 'tien_phong',
            parentId: 150,
          },
          {
            title: 'Xã Vầy Nưa',
            code: 4891,
            value: 'xa_vay_nua',
            division_type: 'xã',
            short_codename: 'vay_nua',
            parentId: 150,
          },
        ],
        parentId: 17,
      },
      {
        title: 'Huyện Lương Sơn',
        code: 152,
        value: 'huyen_luong_son',
        division_type: 'huyện',
        short_codename: 'luong_son',
        children: [
          {
            title: 'Thị trấn Lương Sơn',
            code: 4924,
            value: 'thi_tran_luong_son',
            division_type: 'thị trấn',
            short_codename: 'luong_son',
            parentId: 152,
          },
          {
            title: 'Xã Lâm Sơn',
            code: 4942,
            value: 'xa_lam_son',
            division_type: 'xã',
            short_codename: 'lam_son',
            parentId: 152,
          },
          {
            title: 'Xã Hòa Sơn',
            code: 4945,
            value: 'xa_hoa_son',
            division_type: 'xã',
            short_codename: 'hoa_son',
            parentId: 152,
          },
          {
            title: 'Xã Tân Vinh',
            code: 4951,
            value: 'xa_tan_vinh',
            division_type: 'xã',
            short_codename: 'tan_vinh',
            parentId: 152,
          },
          {
            title: 'Xã Nhuận Trạch',
            code: 4954,
            value: 'xa_nhuan_trach',
            division_type: 'xã',
            short_codename: 'nhuan_trach',
            parentId: 152,
          },
          {
            title: 'Xã Cao Sơn',
            code: 4957,
            value: 'xa_cao_son',
            division_type: 'xã',
            short_codename: 'cao_son',
            parentId: 152,
          },
          {
            title: 'Xã Cư Yên',
            code: 4960,
            value: 'xa_cu_yen',
            division_type: 'xã',
            short_codename: 'cu_yen',
            parentId: 152,
          },
          {
            title: 'Xã Liên Sơn',
            code: 4969,
            value: 'xa_lien_son',
            division_type: 'xã',
            short_codename: 'lien_son',
            parentId: 152,
          },
          {
            title: 'Xã Cao Dương',
            code: 5008,
            value: 'xa_cao_duong',
            division_type: 'xã',
            short_codename: 'cao_duong',
            parentId: 152,
          },
          {
            title: 'Xã Thanh Sơn',
            code: 5041,
            value: 'xa_thanh_son',
            division_type: 'xã',
            short_codename: 'thanh_son',
            parentId: 152,
          },
          {
            title: 'Xã Thanh Cao',
            code: 5047,
            value: 'xa_thanh_cao',
            division_type: 'xã',
            short_codename: 'thanh_cao',
            parentId: 152,
          },
        ],
        parentId: 17,
      },
      {
        title: 'Huyện Kim Bôi',
        code: 153,
        value: 'huyen_kim_boi',
        division_type: 'huyện',
        short_codename: 'kim_boi',
        children: [
          {
            title: 'Thị trấn Bo',
            code: 4978,
            value: 'thi_tran_bo',
            division_type: 'thị trấn',
            short_codename: 'bo',
            parentId: 153,
          },
          {
            title: 'Xã Đú Sáng',
            code: 4984,
            value: 'xa_du_sang',
            division_type: 'xã',
            short_codename: 'du_sang',
            parentId: 153,
          },
          {
            title: 'Xã Hùng Sơn',
            code: 4987,
            value: 'xa_hung_son',
            division_type: 'xã',
            short_codename: 'hung_son',
            parentId: 153,
          },
          {
            title: 'Xã Bình Sơn',
            code: 4990,
            value: 'xa_binh_son',
            division_type: 'xã',
            short_codename: 'binh_son',
            parentId: 153,
          },
          {
            title: 'Xã Tú Sơn',
            code: 4999,
            value: 'xa_tu_son',
            division_type: 'xã',
            short_codename: 'tu_son',
            parentId: 153,
          },
          {
            title: 'Xã Vĩnh Tiến',
            code: 5005,
            value: 'xa_vinh_tien',
            division_type: 'xã',
            short_codename: 'vinh_tien',
            parentId: 153,
          },
          {
            title: 'Xã Đông Bắc',
            code: 5014,
            value: 'xa_dong_bac',
            division_type: 'xã',
            short_codename: 'dong_bac',
            parentId: 153,
          },
          {
            title: 'Xã Xuân Thủy',
            code: 5017,
            value: 'xa_xuan_thuy',
            division_type: 'xã',
            short_codename: 'xuan_thuy',
            parentId: 153,
          },
          {
            title: 'Xã Vĩnh Đồng',
            code: 5026,
            value: 'xa_vinh_dong',
            division_type: 'xã',
            short_codename: 'vinh_dong',
            parentId: 153,
          },
          {
            title: 'Xã Kim Lập',
            code: 5035,
            value: 'xa_kim_lap',
            division_type: 'xã',
            short_codename: 'kim_lap',
            parentId: 153,
          },
          {
            title: 'Xã Hợp Tiến',
            code: 5038,
            value: 'xa_hop_tien',
            division_type: 'xã',
            short_codename: 'hop_tien',
            parentId: 153,
          },
          {
            title: 'Xã Kim Bôi',
            code: 5065,
            value: 'xa_kim_boi',
            division_type: 'xã',
            short_codename: 'kim_boi',
            parentId: 153,
          },
          {
            title: 'Xã Nam Thượng',
            code: 5068,
            value: 'xa_nam_thuong',
            division_type: 'xã',
            short_codename: 'nam_thuong',
            parentId: 153,
          },
          {
            title: 'Xã Cuối Hạ',
            code: 5077,
            value: 'xa_cuoi_ha',
            division_type: 'xã',
            short_codename: 'cuoi_ha',
            parentId: 153,
          },
          {
            title: 'Xã Sào Báy',
            code: 5080,
            value: 'xa_sao_bay',
            division_type: 'xã',
            short_codename: 'sao_bay',
            parentId: 153,
          },
          {
            title: 'Xã Mi Hòa',
            code: 5083,
            value: 'xa_mi_hoa',
            division_type: 'xã',
            short_codename: 'mi_hoa',
            parentId: 153,
          },
          {
            title: 'Xã Nuông Dăm',
            code: 5086,
            value: 'xa_nuong_dam',
            division_type: 'xã',
            short_codename: 'nuong_dam',
            parentId: 153,
          },
        ],
        parentId: 17,
      },
      {
        title: 'Huyện Cao Phong',
        code: 154,
        value: 'huyen_cao_phong',
        division_type: 'huyện',
        short_codename: 'cao_phong',
        children: [
          {
            title: 'Thị trấn Cao Phong',
            code: 5089,
            value: 'thi_tran_cao_phong',
            division_type: 'thị trấn',
            short_codename: 'cao_phong',
            parentId: 154,
          },
          {
            title: 'Xã Bình Thanh',
            code: 5092,
            value: 'xa_binh_thanh',
            division_type: 'xã',
            short_codename: 'binh_thanh',
            parentId: 154,
          },
          {
            title: 'Xã Thung Nai',
            code: 5095,
            value: 'xa_thung_nai',
            division_type: 'xã',
            short_codename: 'thung_nai',
            parentId: 154,
          },
          {
            title: 'Xã Bắc Phong',
            code: 5098,
            value: 'xa_bac_phong',
            division_type: 'xã',
            short_codename: 'bac_phong',
            parentId: 154,
          },
          {
            title: 'Xã Thu Phong',
            code: 5101,
            value: 'xa_thu_phong',
            division_type: 'xã',
            short_codename: 'thu_phong',
            parentId: 154,
          },
          {
            title: 'Xã Hợp Phong',
            code: 5104,
            value: 'xa_hop_phong',
            division_type: 'xã',
            short_codename: 'hop_phong',
            parentId: 154,
          },
          {
            title: 'Xã Tây Phong',
            code: 5110,
            value: 'xa_tay_phong',
            division_type: 'xã',
            short_codename: 'tay_phong',
            parentId: 154,
          },
          {
            title: 'Xã Dũng Phong',
            code: 5116,
            value: 'xa_dung_phong',
            division_type: 'xã',
            short_codename: 'dung_phong',
            parentId: 154,
          },
          {
            title: 'Xã Nam Phong',
            code: 5119,
            value: 'xa_nam_phong',
            division_type: 'xã',
            short_codename: 'nam_phong',
            parentId: 154,
          },
          {
            title: 'Xã Thạch Yên',
            code: 5125,
            value: 'xa_thach_yen',
            division_type: 'xã',
            short_codename: 'thach_yen',
            parentId: 154,
          },
        ],
        parentId: 17,
      },
      {
        title: 'Huyện Tân Lạc',
        code: 155,
        value: 'huyen_tan_lac',
        division_type: 'huyện',
        short_codename: 'tan_lac',
        children: [
          {
            title: 'Thị trấn Mãn Đức',
            code: 5128,
            value: 'thi_tran_man_duc',
            division_type: 'thị trấn',
            short_codename: 'man_duc',
            parentId: 155,
          },
          {
            title: 'Xã Suối Hoa',
            code: 5134,
            value: 'xa_suoi_hoa',
            division_type: 'xã',
            short_codename: 'suoi_hoa',
            parentId: 155,
          },
          {
            title: 'Xã Phú Vinh',
            code: 5137,
            value: 'xa_phu_vinh',
            division_type: 'xã',
            short_codename: 'phu_vinh',
            parentId: 155,
          },
          {
            title: 'Xã Phú Cường',
            code: 5140,
            value: 'xa_phu_cuong',
            division_type: 'xã',
            short_codename: 'phu_cuong',
            parentId: 155,
          },
          {
            title: 'Xã Mỹ Hòa',
            code: 5143,
            value: 'xa_my_hoa',
            division_type: 'xã',
            short_codename: 'my_hoa',
            parentId: 155,
          },
          {
            title: 'Xã Quyết Chiến',
            code: 5152,
            value: 'xa_quyet_chien',
            division_type: 'xã',
            short_codename: 'quyet_chien',
            parentId: 155,
          },
          {
            title: 'Xã Phong Phú',
            code: 5158,
            value: 'xa_phong_phu',
            division_type: 'xã',
            short_codename: 'phong_phu',
            parentId: 155,
          },
          {
            title: 'Xã Tử Nê',
            code: 5164,
            value: 'xa_tu_ne',
            division_type: 'xã',
            short_codename: 'tu_ne',
            parentId: 155,
          },
          {
            title: 'Xã Thanh Hối',
            code: 5167,
            value: 'xa_thanh_hoi',
            division_type: 'xã',
            short_codename: 'thanh_hoi',
            parentId: 155,
          },
          {
            title: 'Xã Ngọc Mỹ',
            code: 5170,
            value: 'xa_ngoc_my',
            division_type: 'xã',
            short_codename: 'ngoc_my',
            parentId: 155,
          },
          {
            title: 'Xã Đông Lai',
            code: 5173,
            value: 'xa_dong_lai',
            division_type: 'xã',
            short_codename: 'dong_lai',
            parentId: 155,
          },
          {
            title: 'Xã Vân Sơn',
            code: 5176,
            value: 'xa_van_son',
            division_type: 'xã',
            short_codename: 'van_son',
            parentId: 155,
          },
          {
            title: 'Xã Nhân Mỹ',
            code: 5182,
            value: 'xa_nhan_my',
            division_type: 'xã',
            short_codename: 'nhan_my',
            parentId: 155,
          },
          {
            title: 'Xã Lỗ Sơn',
            code: 5191,
            value: 'xa_lo_son',
            division_type: 'xã',
            short_codename: 'lo_son',
            parentId: 155,
          },
          {
            title: 'Xã Ngổ Luông',
            code: 5194,
            value: 'xa_ngo_luong',
            division_type: 'xã',
            short_codename: 'ngo_luong',
            parentId: 155,
          },
          {
            title: 'Xã Gia Mô',
            code: 5197,
            value: 'xa_gia_mo',
            division_type: 'xã',
            short_codename: 'gia_mo',
            parentId: 155,
          },
        ],
        parentId: 17,
      },
      {
        title: 'Huyện Mai Châu',
        code: 156,
        value: 'huyen_mai_chau',
        division_type: 'huyện',
        short_codename: 'mai_chau',
        children: [
          {
            title: 'Xã Tân Thành',
            code: 4882,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 156,
          },
          {
            title: 'Thị trấn Mai Châu',
            code: 5200,
            value: 'thi_tran_mai_chau',
            division_type: 'thị trấn',
            short_codename: 'mai_chau',
            parentId: 156,
          },
          {
            title: 'Xã Sơn Thủy',
            code: 5206,
            value: 'xa_son_thuy',
            division_type: 'xã',
            short_codename: 'son_thuy',
            parentId: 156,
          },
          {
            title: 'Xã Pà Cò',
            code: 5209,
            value: 'xa_pa_co',
            division_type: 'xã',
            short_codename: 'pa_co',
            parentId: 156,
          },
          {
            title: 'Xã Hang Kia',
            code: 5212,
            value: 'xa_hang_kia',
            division_type: 'xã',
            short_codename: 'hang_kia',
            parentId: 156,
          },
          {
            title: 'Xã Đồng Tân',
            code: 5221,
            value: 'xa_dong_tan',
            division_type: 'xã',
            short_codename: 'dong_tan',
            parentId: 156,
          },
          {
            title: 'Xã Cun Pheo',
            code: 5224,
            value: 'xa_cun_pheo',
            division_type: 'xã',
            short_codename: 'cun_pheo',
            parentId: 156,
          },
          {
            title: 'Xã Bao La',
            code: 5227,
            value: 'xa_bao_la',
            division_type: 'xã',
            short_codename: 'bao_la',
            parentId: 156,
          },
          {
            title: 'Xã Tòng Đậu',
            code: 5233,
            value: 'xa_tong_dau',
            division_type: 'xã',
            short_codename: 'tong_dau',
            parentId: 156,
          },
          {
            title: 'Xã Nà Phòn',
            code: 5242,
            value: 'xa_na_phon',
            division_type: 'xã',
            short_codename: 'na_phon',
            parentId: 156,
          },
          {
            title: 'Xã Săm Khóe',
            code: 5245,
            value: 'xa_sam_khoe',
            division_type: 'xã',
            short_codename: 'sam_khoe',
            parentId: 156,
          },
          {
            title: 'Xã Chiềng Châu',
            code: 5248,
            value: 'xa_chieng_chau',
            division_type: 'xã',
            short_codename: 'chieng_chau',
            parentId: 156,
          },
          {
            title: 'Xã Mai Hạ',
            code: 5251,
            value: 'xa_mai_ha',
            division_type: 'xã',
            short_codename: 'mai_ha',
            parentId: 156,
          },
          {
            title: 'Xã Thành Sơn',
            code: 5254,
            value: 'xa_thanh_son',
            division_type: 'xã',
            short_codename: 'thanh_son',
            parentId: 156,
          },
          {
            title: 'Xã Mai Hịch',
            code: 5257,
            value: 'xa_mai_hich',
            division_type: 'xã',
            short_codename: 'mai_hich',
            parentId: 156,
          },
          {
            title: 'Xã Vạn Mai',
            code: 5263,
            value: 'xa_van_mai',
            division_type: 'xã',
            short_codename: 'van_mai',
            parentId: 156,
          },
        ],
        parentId: 17,
      },
      {
        title: 'Huyện Lạc Sơn',
        code: 157,
        value: 'huyen_lac_son',
        division_type: 'huyện',
        short_codename: 'lac_son',
        children: [
          {
            title: 'Thị trấn Vụ Bản',
            code: 5266,
            value: 'thi_tran_vu_ban',
            division_type: 'thị trấn',
            short_codename: 'vu_ban',
            parentId: 157,
          },
          {
            title: 'Xã Quý Hòa',
            code: 5269,
            value: 'xa_quy_hoa',
            division_type: 'xã',
            short_codename: 'quy_hoa',
            parentId: 157,
          },
          {
            title: 'Xã Miền Đồi',
            code: 5272,
            value: 'xa_mien_doi',
            division_type: 'xã',
            short_codename: 'mien_doi',
            parentId: 157,
          },
          {
            title: 'Xã Mỹ Thành',
            code: 5275,
            value: 'xa_my_thanh',
            division_type: 'xã',
            short_codename: 'my_thanh',
            parentId: 157,
          },
          {
            title: 'Xã Tuân Đạo',
            code: 5278,
            value: 'xa_tuan_dao',
            division_type: 'xã',
            short_codename: 'tuan_dao',
            parentId: 157,
          },
          {
            title: 'Xã Văn Nghĩa',
            code: 5281,
            value: 'xa_van_nghia',
            division_type: 'xã',
            short_codename: 'van_nghia',
            parentId: 157,
          },
          {
            title: 'Xã Văn Sơn',
            code: 5284,
            value: 'xa_van_son',
            division_type: 'xã',
            short_codename: 'van_son',
            parentId: 157,
          },
          {
            title: 'Xã Tân Lập',
            code: 5287,
            value: 'xa_tan_lap',
            division_type: 'xã',
            short_codename: 'tan_lap',
            parentId: 157,
          },
          {
            title: 'Xã Nhân Nghĩa',
            code: 5290,
            value: 'xa_nhan_nghia',
            division_type: 'xã',
            short_codename: 'nhan_nghia',
            parentId: 157,
          },
          {
            title: 'Xã Thượng Cốc',
            code: 5293,
            value: 'xa_thuong_coc',
            division_type: 'xã',
            short_codename: 'thuong_coc',
            parentId: 157,
          },
          {
            title: 'Xã Quyết Thắng',
            code: 5299,
            value: 'xa_quyet_thang',
            division_type: 'xã',
            short_codename: 'quyet_thang',
            parentId: 157,
          },
          {
            title: 'Xã Xuất Hóa',
            code: 5302,
            value: 'xa_xuat_hoa',
            division_type: 'xã',
            short_codename: 'xuat_hoa',
            parentId: 157,
          },
          {
            title: 'Xã Yên Phú',
            code: 5305,
            value: 'xa_yen_phu',
            division_type: 'xã',
            short_codename: 'yen_phu',
            parentId: 157,
          },
          {
            title: 'Xã Bình Hẻm',
            code: 5308,
            value: 'xa_binh_hem',
            division_type: 'xã',
            short_codename: 'binh_hem',
            parentId: 157,
          },
          {
            title: 'Xã Định Cư',
            code: 5320,
            value: 'xa_dinh_cu',
            division_type: 'xã',
            short_codename: 'dinh_cu',
            parentId: 157,
          },
          {
            title: 'Xã Chí Đạo',
            code: 5323,
            value: 'xa_chi_dao',
            division_type: 'xã',
            short_codename: 'chi_dao',
            parentId: 157,
          },
          {
            title: 'Xã Ngọc Sơn',
            code: 5329,
            value: 'xa_ngoc_son',
            division_type: 'xã',
            short_codename: 'ngoc_son',
            parentId: 157,
          },
          {
            title: 'Xã Hương Nhượng',
            code: 5332,
            value: 'xa_huong_nhuong',
            division_type: 'xã',
            short_codename: 'huong_nhuong',
            parentId: 157,
          },
          {
            title: 'Xã Vũ Bình',
            code: 5335,
            value: 'xa_vu_binh',
            division_type: 'xã',
            short_codename: 'vu_binh',
            parentId: 157,
          },
          {
            title: 'Xã Tự Do',
            code: 5338,
            value: 'xa_tu_do',
            division_type: 'xã',
            short_codename: 'tu_do',
            parentId: 157,
          },
          {
            title: 'Xã Yên Nghiệp',
            code: 5341,
            value: 'xa_yen_nghiep',
            division_type: 'xã',
            short_codename: 'yen_nghiep',
            parentId: 157,
          },
          {
            title: 'Xã Tân Mỹ',
            code: 5344,
            value: 'xa_tan_my',
            division_type: 'xã',
            short_codename: 'tan_my',
            parentId: 157,
          },
          {
            title: 'Xã Ân Nghĩa',
            code: 5347,
            value: 'xa_an_nghia',
            division_type: 'xã',
            short_codename: 'an_nghia',
            parentId: 157,
          },
          {
            title: 'Xã Ngọc Lâu',
            code: 5350,
            value: 'xa_ngoc_lau',
            division_type: 'xã',
            short_codename: 'ngoc_lau',
            parentId: 157,
          },
        ],
        parentId: 17,
      },
      {
        title: 'Huyện Yên Thủy',
        code: 158,
        value: 'huyen_yen_thuy',
        division_type: 'huyện',
        short_codename: 'yen_thuy',
        children: [
          {
            title: 'Thị trấn Hàng Trạm',
            code: 5353,
            value: 'thi_tran_hang_tram',
            division_type: 'thị trấn',
            short_codename: 'hang_tram',
            parentId: 158,
          },
          {
            title: 'Xã Lạc Sỹ',
            code: 5356,
            value: 'xa_lac_sy',
            division_type: 'xã',
            short_codename: 'lac_sy',
            parentId: 158,
          },
          {
            title: 'Xã Lạc Lương',
            code: 5362,
            value: 'xa_lac_luong',
            division_type: 'xã',
            short_codename: 'lac_luong',
            parentId: 158,
          },
          {
            title: 'Xã Bảo Hiệu',
            code: 5365,
            value: 'xa_bao_hieu',
            division_type: 'xã',
            short_codename: 'bao_hieu',
            parentId: 158,
          },
          {
            title: 'Xã Đa Phúc',
            code: 5368,
            value: 'xa_da_phuc',
            division_type: 'xã',
            short_codename: 'da_phuc',
            parentId: 158,
          },
          {
            title: 'Xã Hữu Lợi',
            code: 5371,
            value: 'xa_huu_loi',
            division_type: 'xã',
            short_codename: 'huu_loi',
            parentId: 158,
          },
          {
            title: 'Xã Lạc Thịnh',
            code: 5374,
            value: 'xa_lac_thinh',
            division_type: 'xã',
            short_codename: 'lac_thinh',
            parentId: 158,
          },
          {
            title: 'Xã Đoàn Kết',
            code: 5380,
            value: 'xa_doan_ket',
            division_type: 'xã',
            short_codename: 'doan_ket',
            parentId: 158,
          },
          {
            title: 'Xã Phú Lai',
            code: 5383,
            value: 'xa_phu_lai',
            division_type: 'xã',
            short_codename: 'phu_lai',
            parentId: 158,
          },
          {
            title: 'Xã Yên Trị',
            code: 5386,
            value: 'xa_yen_tri',
            division_type: 'xã',
            short_codename: 'yen_tri',
            parentId: 158,
          },
          {
            title: 'Xã Ngọc Lương',
            code: 5389,
            value: 'xa_ngoc_luong',
            division_type: 'xã',
            short_codename: 'ngoc_luong',
            parentId: 158,
          },
        ],
        parentId: 17,
      },
      {
        title: 'Huyện Lạc Thủy',
        code: 159,
        value: 'huyen_lac_thuy',
        division_type: 'huyện',
        short_codename: 'lac_thuy',
        children: [
          {
            title: 'Thị trấn Ba Hàng Đồi',
            code: 4981,
            value: 'thi_tran_ba_hang_doi',
            division_type: 'thị trấn',
            short_codename: 'ba_hang_doi',
            parentId: 159,
          },
          {
            title: 'Thị trấn Chi Nê',
            code: 5392,
            value: 'thi_tran_chi_ne',
            division_type: 'thị trấn',
            short_codename: 'chi_ne',
            parentId: 159,
          },
          {
            title: 'Xã Phú Nghĩa',
            code: 5395,
            value: 'xa_phu_nghia',
            division_type: 'xã',
            short_codename: 'phu_nghia',
            parentId: 159,
          },
          {
            title: 'Xã Phú Thành',
            code: 5398,
            value: 'xa_phu_thanh',
            division_type: 'xã',
            short_codename: 'phu_thanh',
            parentId: 159,
          },
          {
            title: 'Xã Hưng Thi',
            code: 5404,
            value: 'xa_hung_thi',
            division_type: 'xã',
            short_codename: 'hung_thi',
            parentId: 159,
          },
          {
            title: 'Xã Khoan Dụ',
            code: 5413,
            value: 'xa_khoan_du',
            division_type: 'xã',
            short_codename: 'khoan_du',
            parentId: 159,
          },
          {
            title: 'Xã Đồng Tâm',
            code: 5419,
            value: 'xa_dong_tam',
            division_type: 'xã',
            short_codename: 'dong_tam',
            parentId: 159,
          },
          {
            title: 'Xã Yên Bồng',
            code: 5422,
            value: 'xa_yen_bong',
            division_type: 'xã',
            short_codename: 'yen_bong',
            parentId: 159,
          },
          {
            title: 'Xã Thống Nhất',
            code: 5425,
            value: 'xa_thong_nhat',
            division_type: 'xã',
            short_codename: 'thong_nhat',
            parentId: 159,
          },
          {
            title: 'Xã An Bình',
            code: 5428,
            value: 'xa_an_binh',
            division_type: 'xã',
            short_codename: 'an_binh',
            parentId: 159,
          },
        ],
        parentId: 17,
      },
    ],
  },
  {
    title: 'Tỉnh Thái Nguyên',
    code: 19,
    value: 'tinh_thai_nguyen',
    division_type: 'tỉnh',
    phone_code: 208,
    children: [
      {
        title: 'Thành phố Thái Nguyên',
        code: 164,
        value: 'thanh_pho_thai_nguyen',
        division_type: 'thành phố',
        short_codename: 'thai_nguyen',
        children: [
          {
            title: 'Phường Quán Triều',
            code: 5431,
            value: 'phuong_quan_trieu',
            division_type: 'phường',
            short_codename: 'quan_trieu',
            parentId: 164,
          },
          {
            title: 'Phường Quang Vinh',
            code: 5434,
            value: 'phuong_quang_vinh',
            division_type: 'phường',
            short_codename: 'quang_vinh',
            parentId: 164,
          },
          {
            title: 'Phường Túc Duyên',
            code: 5437,
            value: 'phuong_tuc_duyen',
            division_type: 'phường',
            short_codename: 'tuc_duyen',
            parentId: 164,
          },
          {
            title: 'Phường Hoàng Văn Thụ',
            code: 5440,
            value: 'phuong_hoang_van_thu',
            division_type: 'phường',
            short_codename: 'hoang_van_thu',
            parentId: 164,
          },
          {
            title: 'Phường Trưng Vương',
            code: 5443,
            value: 'phuong_trung_vuong',
            division_type: 'phường',
            short_codename: 'trung_vuong',
            parentId: 164,
          },
          {
            title: 'Phường Quang Trung',
            code: 5446,
            value: 'phuong_quang_trung',
            division_type: 'phường',
            short_codename: 'quang_trung',
            parentId: 164,
          },
          {
            title: 'Phường Phan Đình Phùng',
            code: 5449,
            value: 'phuong_phan_dinh_phung',
            division_type: 'phường',
            short_codename: 'phan_dinh_phung',
            parentId: 164,
          },
          {
            title: 'Phường Tân Thịnh',
            code: 5452,
            value: 'phuong_tan_thinh',
            division_type: 'phường',
            short_codename: 'tan_thinh',
            parentId: 164,
          },
          {
            title: 'Phường Thịnh Đán',
            code: 5455,
            value: 'phuong_thinh_dan',
            division_type: 'phường',
            short_codename: 'thinh_dan',
            parentId: 164,
          },
          {
            title: 'Phường Đồng Quang',
            code: 5458,
            value: 'phuong_dong_quang',
            division_type: 'phường',
            short_codename: 'dong_quang',
            parentId: 164,
          },
          {
            title: 'Phường Gia Sàng',
            code: 5461,
            value: 'phuong_gia_sang',
            division_type: 'phường',
            short_codename: 'gia_sang',
            parentId: 164,
          },
          {
            title: 'Phường Tân Lập',
            code: 5464,
            value: 'phuong_tan_lap',
            division_type: 'phường',
            short_codename: 'tan_lap',
            parentId: 164,
          },
          {
            title: 'Phường Cam Giá',
            code: 5467,
            value: 'phuong_cam_gia',
            division_type: 'phường',
            short_codename: 'cam_gia',
            parentId: 164,
          },
          {
            title: 'Phường Phú Xá',
            code: 5470,
            value: 'phuong_phu_xa',
            division_type: 'phường',
            short_codename: 'phu_xa',
            parentId: 164,
          },
          {
            title: 'Phường Hương Sơn',
            code: 5473,
            value: 'phuong_huong_son',
            division_type: 'phường',
            short_codename: 'huong_son',
            parentId: 164,
          },
          {
            title: 'Phường Trung Thành',
            code: 5476,
            value: 'phuong_trung_thanh',
            division_type: 'phường',
            short_codename: 'trung_thanh',
            parentId: 164,
          },
          {
            title: 'Phường Tân Thành',
            code: 5479,
            value: 'phuong_tan_thanh',
            division_type: 'phường',
            short_codename: 'tan_thanh',
            parentId: 164,
          },
          {
            title: 'Phường Tân Long',
            code: 5482,
            value: 'phuong_tan_long',
            division_type: 'phường',
            short_codename: 'tan_long',
            parentId: 164,
          },
          {
            title: 'Xã Phúc Hà',
            code: 5485,
            value: 'xa_phuc_ha',
            division_type: 'xã',
            short_codename: 'phuc_ha',
            parentId: 164,
          },
          {
            title: 'Xã Phúc Xuân',
            code: 5488,
            value: 'xa_phuc_xuan',
            division_type: 'xã',
            short_codename: 'phuc_xuan',
            parentId: 164,
          },
          {
            title: 'Xã Quyết Thắng',
            code: 5491,
            value: 'xa_quyet_thang',
            division_type: 'xã',
            short_codename: 'quyet_thang',
            parentId: 164,
          },
          {
            title: 'Xã Phúc Trìu',
            code: 5494,
            value: 'xa_phuc_triu',
            division_type: 'xã',
            short_codename: 'phuc_triu',
            parentId: 164,
          },
          {
            title: 'Xã Thịnh Đức',
            code: 5497,
            value: 'xa_thinh_duc',
            division_type: 'xã',
            short_codename: 'thinh_duc',
            parentId: 164,
          },
          {
            title: 'Phường Tích Lương',
            code: 5500,
            value: 'phuong_tich_luong',
            division_type: 'phường',
            short_codename: 'tich_luong',
            parentId: 164,
          },
          {
            title: 'Xã Tân Cương',
            code: 5503,
            value: 'xa_tan_cuong',
            division_type: 'xã',
            short_codename: 'tan_cuong',
            parentId: 164,
          },
          {
            title: 'Xã Sơn Cẩm',
            code: 5653,
            value: 'xa_son_cam',
            division_type: 'xã',
            short_codename: 'son_cam',
            parentId: 164,
          },
          {
            title: 'Phường Chùa Hang',
            code: 5659,
            value: 'phuong_chua_hang',
            division_type: 'phường',
            short_codename: 'chua_hang',
            parentId: 164,
          },
          {
            title: 'Xã Cao Ngạn',
            code: 5695,
            value: 'xa_cao_ngan',
            division_type: 'xã',
            short_codename: 'cao_ngan',
            parentId: 164,
          },
          {
            title: 'Xã Linh Sơn',
            code: 5701,
            value: 'xa_linh_son',
            division_type: 'xã',
            short_codename: 'linh_son',
            parentId: 164,
          },
          {
            title: 'Phường Đồng Bẩm',
            code: 5710,
            value: 'phuong_dong_bam',
            division_type: 'phường',
            short_codename: 'dong_bam',
            parentId: 164,
          },
          {
            title: 'Xã Huống Thượng',
            code: 5713,
            value: 'xa_huong_thuong',
            division_type: 'xã',
            short_codename: 'huong_thuong',
            parentId: 164,
          },
          {
            title: 'Xã Đồng Liên',
            code: 5914,
            value: 'xa_dong_lien',
            division_type: 'xã',
            short_codename: 'dong_lien',
            parentId: 164,
          },
        ],
        parentId: 19,
      },
      {
        title: 'Thành phố Sông Công',
        code: 165,
        value: 'thanh_pho_song_cong',
        division_type: 'thành phố',
        short_codename: 'song_cong',
        children: [
          {
            title: 'Phường Lương Sơn',
            code: 5506,
            value: 'phuong_luong_son',
            division_type: 'phường',
            short_codename: 'luong_son',
            parentId: 165,
          },
          {
            title: 'Phường Châu Sơn',
            code: 5509,
            value: 'phuong_chau_son',
            division_type: 'phường',
            short_codename: 'chau_son',
            parentId: 165,
          },
          {
            title: 'Phường Mỏ Chè',
            code: 5512,
            value: 'phuong_mo_che',
            division_type: 'phường',
            short_codename: 'mo_che',
            parentId: 165,
          },
          {
            title: 'Phường Cải Đan',
            code: 5515,
            value: 'phuong_cai_dan',
            division_type: 'phường',
            short_codename: 'cai_dan',
            parentId: 165,
          },
          {
            title: 'Phường Thắng Lợi',
            code: 5518,
            value: 'phuong_thang_loi',
            division_type: 'phường',
            short_codename: 'thang_loi',
            parentId: 165,
          },
          {
            title: 'Phường Phố Cò',
            code: 5521,
            value: 'phuong_pho_co',
            division_type: 'phường',
            short_codename: 'pho_co',
            parentId: 165,
          },
          {
            title: 'Xã Tân Quang',
            code: 5527,
            value: 'xa_tan_quang',
            division_type: 'xã',
            short_codename: 'tan_quang',
            parentId: 165,
          },
          {
            title: 'Phường Bách Quang',
            code: 5528,
            value: 'phuong_bach_quang',
            division_type: 'phường',
            short_codename: 'bach_quang',
            parentId: 165,
          },
          {
            title: 'Xã Bình Sơn',
            code: 5530,
            value: 'xa_binh_son',
            division_type: 'xã',
            short_codename: 'binh_son',
            parentId: 165,
          },
          {
            title: 'Xã Bá Xuyên',
            code: 5533,
            value: 'xa_ba_xuyen',
            division_type: 'xã',
            short_codename: 'ba_xuyen',
            parentId: 165,
          },
        ],
        parentId: 19,
      },
      {
        title: 'Huyện Định Hóa',
        code: 167,
        value: 'huyen_dinh_hoa',
        division_type: 'huyện',
        short_codename: 'dinh_hoa',
        children: [
          {
            title: 'Thị trấn Chợ Chu',
            code: 5536,
            value: 'thi_tran_cho_chu',
            division_type: 'thị trấn',
            short_codename: 'cho_chu',
            parentId: 167,
          },
          {
            title: 'Xã Linh Thông',
            code: 5539,
            value: 'xa_linh_thong',
            division_type: 'xã',
            short_codename: 'linh_thong',
            parentId: 167,
          },
          {
            title: 'Xã Lam Vỹ',
            code: 5542,
            value: 'xa_lam_vy',
            division_type: 'xã',
            short_codename: 'lam_vy',
            parentId: 167,
          },
          {
            title: 'Xã Quy Kỳ',
            code: 5545,
            value: 'xa_quy_ky',
            division_type: 'xã',
            short_codename: 'quy_ky',
            parentId: 167,
          },
          {
            title: 'Xã Tân Thịnh',
            code: 5548,
            value: 'xa_tan_thinh',
            division_type: 'xã',
            short_codename: 'tan_thinh',
            parentId: 167,
          },
          {
            title: 'Xã Kim Phượng',
            code: 5551,
            value: 'xa_kim_phuong',
            division_type: 'xã',
            short_codename: 'kim_phuong',
            parentId: 167,
          },
          {
            title: 'Xã Bảo Linh',
            code: 5554,
            value: 'xa_bao_linh',
            division_type: 'xã',
            short_codename: 'bao_linh',
            parentId: 167,
          },
          {
            title: 'Xã Phúc Chu',
            code: 5560,
            value: 'xa_phuc_chu',
            division_type: 'xã',
            short_codename: 'phuc_chu',
            parentId: 167,
          },
          {
            title: 'Xã Tân Dương',
            code: 5563,
            value: 'xa_tan_duong',
            division_type: 'xã',
            short_codename: 'tan_duong',
            parentId: 167,
          },
          {
            title: 'Xã Phượng Tiến',
            code: 5566,
            value: 'xa_phuong_tien',
            division_type: 'xã',
            short_codename: 'phuong_tien',
            parentId: 167,
          },
          {
            title: 'Xã Bảo Cường',
            code: 5569,
            value: 'xa_bao_cuong',
            division_type: 'xã',
            short_codename: 'bao_cuong',
            parentId: 167,
          },
          {
            title: 'Xã Đồng Thịnh',
            code: 5572,
            value: 'xa_dong_thinh',
            division_type: 'xã',
            short_codename: 'dong_thinh',
            parentId: 167,
          },
          {
            title: 'Xã Định Biên',
            code: 5575,
            value: 'xa_dinh_bien',
            division_type: 'xã',
            short_codename: 'dinh_bien',
            parentId: 167,
          },
          {
            title: 'Xã Thanh Định',
            code: 5578,
            value: 'xa_thanh_dinh',
            division_type: 'xã',
            short_codename: 'thanh_dinh',
            parentId: 167,
          },
          {
            title: 'Xã Trung Hội',
            code: 5581,
            value: 'xa_trung_hoi',
            division_type: 'xã',
            short_codename: 'trung_hoi',
            parentId: 167,
          },
          {
            title: 'Xã Trung Lương',
            code: 5584,
            value: 'xa_trung_luong',
            division_type: 'xã',
            short_codename: 'trung_luong',
            parentId: 167,
          },
          {
            title: 'Xã Bình Yên',
            code: 5587,
            value: 'xa_binh_yen',
            division_type: 'xã',
            short_codename: 'binh_yen',
            parentId: 167,
          },
          {
            title: 'Xã Điềm Mặc',
            code: 5590,
            value: 'xa_diem_mac',
            division_type: 'xã',
            short_codename: 'diem_mac',
            parentId: 167,
          },
          {
            title: 'Xã Phú Tiến',
            code: 5593,
            value: 'xa_phu_tien',
            division_type: 'xã',
            short_codename: 'phu_tien',
            parentId: 167,
          },
          {
            title: 'Xã Bộc Nhiêu',
            code: 5596,
            value: 'xa_boc_nhieu',
            division_type: 'xã',
            short_codename: 'boc_nhieu',
            parentId: 167,
          },
          {
            title: 'Xã Sơn Phú',
            code: 5599,
            value: 'xa_son_phu',
            division_type: 'xã',
            short_codename: 'son_phu',
            parentId: 167,
          },
          {
            title: 'Xã Phú Đình',
            code: 5602,
            value: 'xa_phu_dinh',
            division_type: 'xã',
            short_codename: 'phu_dinh',
            parentId: 167,
          },
          {
            title: 'Xã Bình Thành',
            code: 5605,
            value: 'xa_binh_thanh',
            division_type: 'xã',
            short_codename: 'binh_thanh',
            parentId: 167,
          },
        ],
        parentId: 19,
      },
      {
        title: 'Huyện Phú Lương',
        code: 168,
        value: 'huyen_phu_luong',
        division_type: 'huyện',
        short_codename: 'phu_luong',
        children: [
          {
            title: 'Thị trấn Giang Tiên',
            code: 5608,
            value: 'thi_tran_giang_tien',
            division_type: 'thị trấn',
            short_codename: 'giang_tien',
            parentId: 168,
          },
          {
            title: 'Thị trấn Đu',
            code: 5611,
            value: 'thi_tran_du',
            division_type: 'thị trấn',
            short_codename: 'du',
            parentId: 168,
          },
          {
            title: 'Xã Yên Ninh',
            code: 5614,
            value: 'xa_yen_ninh',
            division_type: 'xã',
            short_codename: 'yen_ninh',
            parentId: 168,
          },
          {
            title: 'Xã Yên Trạch',
            code: 5617,
            value: 'xa_yen_trach',
            division_type: 'xã',
            short_codename: 'yen_trach',
            parentId: 168,
          },
          {
            title: 'Xã Yên Đổ',
            code: 5620,
            value: 'xa_yen_do',
            division_type: 'xã',
            short_codename: 'yen_do',
            parentId: 168,
          },
          {
            title: 'Xã Yên Lạc',
            code: 5623,
            value: 'xa_yen_lac',
            division_type: 'xã',
            short_codename: 'yen_lac',
            parentId: 168,
          },
          {
            title: 'Xã Ôn Lương',
            code: 5626,
            value: 'xa_on_luong',
            division_type: 'xã',
            short_codename: 'on_luong',
            parentId: 168,
          },
          {
            title: 'Xã Động Đạt',
            code: 5629,
            value: 'xa_dong_dat',
            division_type: 'xã',
            short_codename: 'dong_dat',
            parentId: 168,
          },
          {
            title: 'Xã Phủ Lý',
            code: 5632,
            value: 'xa_phu_ly',
            division_type: 'xã',
            short_codename: 'phu_ly',
            parentId: 168,
          },
          {
            title: 'Xã Phú Đô',
            code: 5635,
            value: 'xa_phu_do',
            division_type: 'xã',
            short_codename: 'phu_do',
            parentId: 168,
          },
          {
            title: 'Xã Hợp Thành',
            code: 5638,
            value: 'xa_hop_thanh',
            division_type: 'xã',
            short_codename: 'hop_thanh',
            parentId: 168,
          },
          {
            title: 'Xã Tức Tranh',
            code: 5641,
            value: 'xa_tuc_tranh',
            division_type: 'xã',
            short_codename: 'tuc_tranh',
            parentId: 168,
          },
          {
            title: 'Xã Phấn Mễ',
            code: 5644,
            value: 'xa_phan_me',
            division_type: 'xã',
            short_codename: 'phan_me',
            parentId: 168,
          },
          {
            title: 'Xã Vô Tranh',
            code: 5647,
            value: 'xa_vo_tranh',
            division_type: 'xã',
            short_codename: 'vo_tranh',
            parentId: 168,
          },
          {
            title: 'Xã Cổ Lũng',
            code: 5650,
            value: 'xa_co_lung',
            division_type: 'xã',
            short_codename: 'co_lung',
            parentId: 168,
          },
        ],
        parentId: 19,
      },
      {
        title: 'Huyện Đồng Hỷ',
        code: 169,
        value: 'huyen_dong_hy',
        division_type: 'huyện',
        short_codename: 'dong_hy',
        children: [
          {
            title: 'Thị trấn Sông Cầu',
            code: 5656,
            value: 'thi_tran_song_cau',
            division_type: 'thị trấn',
            short_codename: 'song_cau',
            parentId: 169,
          },
          {
            title: 'Thị trấn Trại Cau',
            code: 5662,
            value: 'thi_tran_trai_cau',
            division_type: 'thị trấn',
            short_codename: 'trai_cau',
            parentId: 169,
          },
          {
            title: 'Xã Văn Lăng',
            code: 5665,
            value: 'xa_van_lang',
            division_type: 'xã',
            short_codename: 'van_lang',
            parentId: 169,
          },
          {
            title: 'Xã Tân Long',
            code: 5668,
            value: 'xa_tan_long',
            division_type: 'xã',
            short_codename: 'tan_long',
            parentId: 169,
          },
          {
            title: 'Xã Hòa Bình',
            code: 5671,
            value: 'xa_hoa_binh',
            division_type: 'xã',
            short_codename: 'hoa_binh',
            parentId: 169,
          },
          {
            title: 'Xã Quang Sơn',
            code: 5674,
            value: 'xa_quang_son',
            division_type: 'xã',
            short_codename: 'quang_son',
            parentId: 169,
          },
          {
            title: 'Xã Minh Lập',
            code: 5677,
            value: 'xa_minh_lap',
            division_type: 'xã',
            short_codename: 'minh_lap',
            parentId: 169,
          },
          {
            title: 'Xã Văn Hán',
            code: 5680,
            value: 'xa_van_han',
            division_type: 'xã',
            short_codename: 'van_han',
            parentId: 169,
          },
          {
            title: 'Xã Hóa Trung',
            code: 5683,
            value: 'xa_hoa_trung',
            division_type: 'xã',
            short_codename: 'hoa_trung',
            parentId: 169,
          },
          {
            title: 'Xã Khe Mo',
            code: 5686,
            value: 'xa_khe_mo',
            division_type: 'xã',
            short_codename: 'khe_mo',
            parentId: 169,
          },
          {
            title: 'Xã Cây Thị',
            code: 5689,
            value: 'xa_cay_thi',
            division_type: 'xã',
            short_codename: 'cay_thi',
            parentId: 169,
          },
          {
            title: 'Xã Hóa Thượng',
            code: 5692,
            value: 'xa_hoa_thuong',
            division_type: 'xã',
            short_codename: 'hoa_thuong',
            parentId: 169,
          },
          {
            title: 'Xã Hợp Tiến',
            code: 5698,
            value: 'xa_hop_tien',
            division_type: 'xã',
            short_codename: 'hop_tien',
            parentId: 169,
          },
          {
            title: 'Xã Tân Lợi',
            code: 5704,
            value: 'xa_tan_loi',
            division_type: 'xã',
            short_codename: 'tan_loi',
            parentId: 169,
          },
          {
            title: 'Xã Nam Hòa',
            code: 5707,
            value: 'xa_nam_hoa',
            division_type: 'xã',
            short_codename: 'nam_hoa',
            parentId: 169,
          },
        ],
        parentId: 19,
      },
      {
        title: 'Huyện Võ Nhai',
        code: 170,
        value: 'huyen_vo_nhai',
        division_type: 'huyện',
        short_codename: 'vo_nhai',
        children: [
          {
            title: 'Thị trấn Đình Cả',
            code: 5716,
            value: 'thi_tran_dinh_ca',
            division_type: 'thị trấn',
            short_codename: 'dinh_ca',
            parentId: 170,
          },
          {
            title: 'Xã Sảng Mộc',
            code: 5719,
            value: 'xa_sang_moc',
            division_type: 'xã',
            short_codename: 'sang_moc',
            parentId: 170,
          },
          {
            title: 'Xã Nghinh Tường',
            code: 5722,
            value: 'xa_nghinh_tuong',
            division_type: 'xã',
            short_codename: 'nghinh_tuong',
            parentId: 170,
          },
          {
            title: 'Xã Thần Xa',
            code: 5725,
            value: 'xa_than_xa',
            division_type: 'xã',
            short_codename: 'than_xa',
            parentId: 170,
          },
          {
            title: 'Xã Vũ Chấn',
            code: 5728,
            value: 'xa_vu_chan',
            division_type: 'xã',
            short_codename: 'vu_chan',
            parentId: 170,
          },
          {
            title: 'Xã Thượng Nung',
            code: 5731,
            value: 'xa_thuong_nung',
            division_type: 'xã',
            short_codename: 'thuong_nung',
            parentId: 170,
          },
          {
            title: 'Xã Phú Thượng',
            code: 5734,
            value: 'xa_phu_thuong',
            division_type: 'xã',
            short_codename: 'phu_thuong',
            parentId: 170,
          },
          {
            title: 'Xã Cúc Đường',
            code: 5737,
            value: 'xa_cuc_duong',
            division_type: 'xã',
            short_codename: 'cuc_duong',
            parentId: 170,
          },
          {
            title: 'Xã La Hiên',
            code: 5740,
            value: 'xa_la_hien',
            division_type: 'xã',
            short_codename: 'la_hien',
            parentId: 170,
          },
          {
            title: 'Xã Lâu Thượng',
            code: 5743,
            value: 'xa_lau_thuong',
            division_type: 'xã',
            short_codename: 'lau_thuong',
            parentId: 170,
          },
          {
            title: 'Xã Tràng Xá',
            code: 5746,
            value: 'xa_trang_xa',
            division_type: 'xã',
            short_codename: 'trang_xa',
            parentId: 170,
          },
          {
            title: 'Xã Phương Giao',
            code: 5749,
            value: 'xa_phuong_giao',
            division_type: 'xã',
            short_codename: 'phuong_giao',
            parentId: 170,
          },
          {
            title: 'Xã Liên Minh',
            code: 5752,
            value: 'xa_lien_minh',
            division_type: 'xã',
            short_codename: 'lien_minh',
            parentId: 170,
          },
          {
            title: 'Xã Dân Tiến',
            code: 5755,
            value: 'xa_dan_tien',
            division_type: 'xã',
            short_codename: 'dan_tien',
            parentId: 170,
          },
          {
            title: 'Xã Bình Long',
            code: 5758,
            value: 'xa_binh_long',
            division_type: 'xã',
            short_codename: 'binh_long',
            parentId: 170,
          },
        ],
        parentId: 19,
      },
      {
        title: 'Huyện Đại Từ',
        code: 171,
        value: 'huyen_dai_tu',
        division_type: 'huyện',
        short_codename: 'dai_tu',
        children: [
          {
            title: 'Thị trấn Hùng Sơn',
            code: 5761,
            value: 'thi_tran_hung_son',
            division_type: 'thị trấn',
            short_codename: 'hung_son',
            parentId: 171,
          },
          {
            title: 'Thị trấn Quân Chu',
            code: 5764,
            value: 'thi_tran_quan_chu',
            division_type: 'thị trấn',
            short_codename: 'quan_chu',
            parentId: 171,
          },
          {
            title: 'Xã Phúc Lương',
            code: 5767,
            value: 'xa_phuc_luong',
            division_type: 'xã',
            short_codename: 'phuc_luong',
            parentId: 171,
          },
          {
            title: 'Xã Minh Tiến',
            code: 5770,
            value: 'xa_minh_tien',
            division_type: 'xã',
            short_codename: 'minh_tien',
            parentId: 171,
          },
          {
            title: 'Xã Yên Lãng',
            code: 5773,
            value: 'xa_yen_lang',
            division_type: 'xã',
            short_codename: 'yen_lang',
            parentId: 171,
          },
          {
            title: 'Xã Đức Lương',
            code: 5776,
            value: 'xa_duc_luong',
            division_type: 'xã',
            short_codename: 'duc_luong',
            parentId: 171,
          },
          {
            title: 'Xã Phú Cường',
            code: 5779,
            value: 'xa_phu_cuong',
            division_type: 'xã',
            short_codename: 'phu_cuong',
            parentId: 171,
          },
          {
            title: 'Xã Na Mao',
            code: 5782,
            value: 'xa_na_mao',
            division_type: 'xã',
            short_codename: 'na_mao',
            parentId: 171,
          },
          {
            title: 'Xã Phú Lạc',
            code: 5785,
            value: 'xa_phu_lac',
            division_type: 'xã',
            short_codename: 'phu_lac',
            parentId: 171,
          },
          {
            title: 'Xã Tân Linh',
            code: 5788,
            value: 'xa_tan_linh',
            division_type: 'xã',
            short_codename: 'tan_linh',
            parentId: 171,
          },
          {
            title: 'Xã Phú Thịnh',
            code: 5791,
            value: 'xa_phu_thinh',
            division_type: 'xã',
            short_codename: 'phu_thinh',
            parentId: 171,
          },
          {
            title: 'Xã Phục Linh',
            code: 5794,
            value: 'xa_phuc_linh',
            division_type: 'xã',
            short_codename: 'phuc_linh',
            parentId: 171,
          },
          {
            title: 'Xã Phú Xuyên',
            code: 5797,
            value: 'xa_phu_xuyen',
            division_type: 'xã',
            short_codename: 'phu_xuyen',
            parentId: 171,
          },
          {
            title: 'Xã Bản Ngoại',
            code: 5800,
            value: 'xa_ban_ngoai',
            division_type: 'xã',
            short_codename: 'ban_ngoai',
            parentId: 171,
          },
          {
            title: 'Xã Tiên Hội',
            code: 5803,
            value: 'xa_tien_hoi',
            division_type: 'xã',
            short_codename: 'tien_hoi',
            parentId: 171,
          },
          {
            title: 'Xã Cù Vân',
            code: 5809,
            value: 'xa_cu_van',
            division_type: 'xã',
            short_codename: 'cu_van',
            parentId: 171,
          },
          {
            title: 'Xã Hà Thượng',
            code: 5812,
            value: 'xa_ha_thuong',
            division_type: 'xã',
            short_codename: 'ha_thuong',
            parentId: 171,
          },
          {
            title: 'Xã La Bằng',
            code: 5815,
            value: 'xa_la_bang',
            division_type: 'xã',
            short_codename: 'la_bang',
            parentId: 171,
          },
          {
            title: 'Xã Hoàng Nông',
            code: 5818,
            value: 'xa_hoang_nong',
            division_type: 'xã',
            short_codename: 'hoang_nong',
            parentId: 171,
          },
          {
            title: 'Xã Khôi Kỳ',
            code: 5821,
            value: 'xa_khoi_ky',
            division_type: 'xã',
            short_codename: 'khoi_ky',
            parentId: 171,
          },
          {
            title: 'Xã An Khánh',
            code: 5824,
            value: 'xa_an_khanh',
            division_type: 'xã',
            short_codename: 'an_khanh',
            parentId: 171,
          },
          {
            title: 'Xã Tân Thái',
            code: 5827,
            value: 'xa_tan_thai',
            division_type: 'xã',
            short_codename: 'tan_thai',
            parentId: 171,
          },
          {
            title: 'Xã Bình Thuận',
            code: 5830,
            value: 'xa_binh_thuan',
            division_type: 'xã',
            short_codename: 'binh_thuan',
            parentId: 171,
          },
          {
            title: 'Xã Lục Ba',
            code: 5833,
            value: 'xa_luc_ba',
            division_type: 'xã',
            short_codename: 'luc_ba',
            parentId: 171,
          },
          {
            title: 'Xã Mỹ Yên',
            code: 5836,
            value: 'xa_my_yen',
            division_type: 'xã',
            short_codename: 'my_yen',
            parentId: 171,
          },
          {
            title: 'Xã Vạn Thọ',
            code: 5839,
            value: 'xa_van_tho',
            division_type: 'xã',
            short_codename: 'van_tho',
            parentId: 171,
          },
          {
            title: 'Xã Văn Yên',
            code: 5842,
            value: 'xa_van_yen',
            division_type: 'xã',
            short_codename: 'van_yen',
            parentId: 171,
          },
          {
            title: 'Xã Ký Phú',
            code: 5845,
            value: 'xa_ky_phu',
            division_type: 'xã',
            short_codename: 'ky_phu',
            parentId: 171,
          },
          {
            title: 'Xã Cát Nê',
            code: 5848,
            value: 'xa_cat_ne',
            division_type: 'xã',
            short_codename: 'cat_ne',
            parentId: 171,
          },
          {
            title: 'Xã Quân Chu',
            code: 5851,
            value: 'xa_quan_chu',
            division_type: 'xã',
            short_codename: 'xa_quan_chu',
            parentId: 171,
          },
        ],
        parentId: 19,
      },
      {
        title: 'Thị xã Phổ Yên',
        code: 172,
        value: 'thi_xa_pho_yen',
        division_type: 'thị xã',
        short_codename: 'pho_yen',
        children: [
          {
            title: 'Phường Bãi Bông',
            code: 5854,
            value: 'phuong_bai_bong',
            division_type: 'phường',
            short_codename: 'bai_bong',
            parentId: 172,
          },
          {
            title: 'Phường Bắc Sơn',
            code: 5857,
            value: 'phuong_bac_son',
            division_type: 'phường',
            short_codename: 'bac_son',
            parentId: 172,
          },
          {
            title: 'Phường Ba Hàng',
            code: 5860,
            value: 'phuong_ba_hang',
            division_type: 'phường',
            short_codename: 'ba_hang',
            parentId: 172,
          },
          {
            title: 'Xã Phúc Tân',
            code: 5863,
            value: 'xa_phuc_tan',
            division_type: 'xã',
            short_codename: 'phuc_tan',
            parentId: 172,
          },
          {
            title: 'Xã Phúc Thuận',
            code: 5866,
            value: 'xa_phuc_thuan',
            division_type: 'xã',
            short_codename: 'phuc_thuan',
            parentId: 172,
          },
          {
            title: 'Xã Hồng Tiến',
            code: 5869,
            value: 'xa_hong_tien',
            division_type: 'xã',
            short_codename: 'hong_tien',
            parentId: 172,
          },
          {
            title: 'Xã Minh Đức',
            code: 5872,
            value: 'xa_minh_duc',
            division_type: 'xã',
            short_codename: 'minh_duc',
            parentId: 172,
          },
          {
            title: 'Xã Đắc Sơn',
            code: 5875,
            value: 'xa_dac_son',
            division_type: 'xã',
            short_codename: 'dac_son',
            parentId: 172,
          },
          {
            title: 'Phường Đồng Tiến',
            code: 5878,
            value: 'phuong_dong_tien',
            division_type: 'phường',
            short_codename: 'dong_tien',
            parentId: 172,
          },
          {
            title: 'Xã Thành Công',
            code: 5881,
            value: 'xa_thanh_cong',
            division_type: 'xã',
            short_codename: 'thanh_cong',
            parentId: 172,
          },
          {
            title: 'Xã Tiên Phong',
            code: 5884,
            value: 'xa_tien_phong',
            division_type: 'xã',
            short_codename: 'tien_phong',
            parentId: 172,
          },
          {
            title: 'Xã Vạn Phái',
            code: 5887,
            value: 'xa_van_phai',
            division_type: 'xã',
            short_codename: 'van_phai',
            parentId: 172,
          },
          {
            title: 'Xã Nam Tiến',
            code: 5890,
            value: 'xa_nam_tien',
            division_type: 'xã',
            short_codename: 'nam_tien',
            parentId: 172,
          },
          {
            title: 'Xã Tân Hương',
            code: 5893,
            value: 'xa_tan_huong',
            division_type: 'xã',
            short_codename: 'tan_huong',
            parentId: 172,
          },
          {
            title: 'Xã Đông Cao',
            code: 5896,
            value: 'xa_dong_cao',
            division_type: 'xã',
            short_codename: 'dong_cao',
            parentId: 172,
          },
          {
            title: 'Xã Trung Thành',
            code: 5899,
            value: 'xa_trung_thanh',
            division_type: 'xã',
            short_codename: 'trung_thanh',
            parentId: 172,
          },
          {
            title: 'Xã Tân Phú',
            code: 5902,
            value: 'xa_tan_phu',
            division_type: 'xã',
            short_codename: 'tan_phu',
            parentId: 172,
          },
          {
            title: 'Xã Thuận Thành',
            code: 5905,
            value: 'xa_thuan_thanh',
            division_type: 'xã',
            short_codename: 'thuan_thanh',
            parentId: 172,
          },
        ],
        parentId: 19,
      },
      {
        title: 'Huyện Phú Bình',
        code: 173,
        value: 'huyen_phu_binh',
        division_type: 'huyện',
        short_codename: 'phu_binh',
        children: [
          {
            title: 'Thị trấn Hương Sơn',
            code: 5908,
            value: 'thi_tran_huong_son',
            division_type: 'thị trấn',
            short_codename: 'huong_son',
            parentId: 173,
          },
          {
            title: 'Xã Bàn Đạt',
            code: 5911,
            value: 'xa_ban_dat',
            division_type: 'xã',
            short_codename: 'ban_dat',
            parentId: 173,
          },
          {
            title: 'Xã Tân Khánh',
            code: 5917,
            value: 'xa_tan_khanh',
            division_type: 'xã',
            short_codename: 'tan_khanh',
            parentId: 173,
          },
          {
            title: 'Xã Tân Kim',
            code: 5920,
            value: 'xa_tan_kim',
            division_type: 'xã',
            short_codename: 'tan_kim',
            parentId: 173,
          },
          {
            title: 'Xã Tân Thành',
            code: 5923,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 173,
          },
          {
            title: 'Xã Đào Xá',
            code: 5926,
            value: 'xa_dao_xa',
            division_type: 'xã',
            short_codename: 'dao_xa',
            parentId: 173,
          },
          {
            title: 'Xã Bảo Lý',
            code: 5929,
            value: 'xa_bao_ly',
            division_type: 'xã',
            short_codename: 'bao_ly',
            parentId: 173,
          },
          {
            title: 'Xã Thượng Đình',
            code: 5932,
            value: 'xa_thuong_dinh',
            division_type: 'xã',
            short_codename: 'thuong_dinh',
            parentId: 173,
          },
          {
            title: 'Xã Tân Hòa',
            code: 5935,
            value: 'xa_tan_hoa',
            division_type: 'xã',
            short_codename: 'tan_hoa',
            parentId: 173,
          },
          {
            title: 'Xã Nhã Lộng',
            code: 5938,
            value: 'xa_nha_long',
            division_type: 'xã',
            short_codename: 'nha_long',
            parentId: 173,
          },
          {
            title: 'Xã Điềm Thụy',
            code: 5941,
            value: 'xa_diem_thuy',
            division_type: 'xã',
            short_codename: 'diem_thuy',
            parentId: 173,
          },
          {
            title: 'Xã Xuân Phương',
            code: 5944,
            value: 'xa_xuan_phuong',
            division_type: 'xã',
            short_codename: 'xuan_phuong',
            parentId: 173,
          },
          {
            title: 'Xã Tân Đức',
            code: 5947,
            value: 'xa_tan_duc',
            division_type: 'xã',
            short_codename: 'tan_duc',
            parentId: 173,
          },
          {
            title: 'Xã Úc Kỳ',
            code: 5950,
            value: 'xa_uc_ky',
            division_type: 'xã',
            short_codename: 'uc_ky',
            parentId: 173,
          },
          {
            title: 'Xã Lương Phú',
            code: 5953,
            value: 'xa_luong_phu',
            division_type: 'xã',
            short_codename: 'luong_phu',
            parentId: 173,
          },
          {
            title: 'Xã Nga My',
            code: 5956,
            value: 'xa_nga_my',
            division_type: 'xã',
            short_codename: 'nga_my',
            parentId: 173,
          },
          {
            title: 'Xã Kha Sơn',
            code: 5959,
            value: 'xa_kha_son',
            division_type: 'xã',
            short_codename: 'kha_son',
            parentId: 173,
          },
          {
            title: 'Xã Thanh Ninh',
            code: 5962,
            value: 'xa_thanh_ninh',
            division_type: 'xã',
            short_codename: 'thanh_ninh',
            parentId: 173,
          },
          {
            title: 'Xã Dương Thành',
            code: 5965,
            value: 'xa_duong_thanh',
            division_type: 'xã',
            short_codename: 'duong_thanh',
            parentId: 173,
          },
          {
            title: 'Xã Hà Châu',
            code: 5968,
            value: 'xa_ha_chau',
            division_type: 'xã',
            short_codename: 'ha_chau',
            parentId: 173,
          },
        ],
        parentId: 19,
      },
    ],
  },
  {
    title: 'Tỉnh Lạng Sơn',
    code: 20,
    value: 'tinh_lang_son',
    division_type: 'tỉnh',
    phone_code: 205,
    children: [
      {
        title: 'Thành phố Lạng Sơn',
        code: 178,
        value: 'thanh_pho_lang_son',
        division_type: 'thành phố',
        short_codename: 'lang_son',
        children: [
          {
            title: 'Phường Hoàng Văn Thụ',
            code: 5971,
            value: 'phuong_hoang_van_thu',
            division_type: 'phường',
            short_codename: 'hoang_van_thu',
            parentId: 178,
          },
          {
            title: 'Phường Tam Thanh',
            code: 5974,
            value: 'phuong_tam_thanh',
            division_type: 'phường',
            short_codename: 'tam_thanh',
            parentId: 178,
          },
          {
            title: 'Phường Vĩnh Trại',
            code: 5977,
            value: 'phuong_vinh_trai',
            division_type: 'phường',
            short_codename: 'vinh_trai',
            parentId: 178,
          },
          {
            title: 'Phường Đông Kinh',
            code: 5980,
            value: 'phuong_dong_kinh',
            division_type: 'phường',
            short_codename: 'dong_kinh',
            parentId: 178,
          },
          {
            title: 'Phường Chi Lăng',
            code: 5983,
            value: 'phuong_chi_lang',
            division_type: 'phường',
            short_codename: 'chi_lang',
            parentId: 178,
          },
          {
            title: 'Xã Hoàng Đồng',
            code: 5986,
            value: 'xa_hoang_dong',
            division_type: 'xã',
            short_codename: 'hoang_dong',
            parentId: 178,
          },
          {
            title: 'Xã Quảng Lạc',
            code: 5989,
            value: 'xa_quang_lac',
            division_type: 'xã',
            short_codename: 'quang_lac',
            parentId: 178,
          },
          {
            title: 'Xã Mai Pha',
            code: 5992,
            value: 'xa_mai_pha',
            division_type: 'xã',
            short_codename: 'mai_pha',
            parentId: 178,
          },
        ],
        parentId: 20,
      },
      {
        title: 'Huyện Tràng Định',
        code: 180,
        value: 'huyen_trang_dinh',
        division_type: 'huyện',
        short_codename: 'trang_dinh',
        children: [
          {
            title: 'Thị trấn Thất Khê',
            code: 5995,
            value: 'thi_tran_that_khe',
            division_type: 'thị trấn',
            short_codename: 'that_khe',
            parentId: 180,
          },
          {
            title: 'Xã Khánh Long',
            code: 5998,
            value: 'xa_khanh_long',
            division_type: 'xã',
            short_codename: 'khanh_long',
            parentId: 180,
          },
          {
            title: 'Xã Đoàn Kết',
            code: 6001,
            value: 'xa_doan_ket',
            division_type: 'xã',
            short_codename: 'doan_ket',
            parentId: 180,
          },
          {
            title: 'Xã Quốc Khánh',
            code: 6004,
            value: 'xa_quoc_khanh',
            division_type: 'xã',
            short_codename: 'quoc_khanh',
            parentId: 180,
          },
          {
            title: 'Xã Vĩnh Tiến',
            code: 6007,
            value: 'xa_vinh_tien',
            division_type: 'xã',
            short_codename: 'vinh_tien',
            parentId: 180,
          },
          {
            title: 'Xã Cao Minh',
            code: 6010,
            value: 'xa_cao_minh',
            division_type: 'xã',
            short_codename: 'cao_minh',
            parentId: 180,
          },
          {
            title: 'Xã Chí Minh',
            code: 6013,
            value: 'xa_chi_minh',
            division_type: 'xã',
            short_codename: 'chi_minh',
            parentId: 180,
          },
          {
            title: 'Xã Tri Phương',
            code: 6016,
            value: 'xa_tri_phuong',
            division_type: 'xã',
            short_codename: 'tri_phuong',
            parentId: 180,
          },
          {
            title: 'Xã Tân Tiến',
            code: 6019,
            value: 'xa_tan_tien',
            division_type: 'xã',
            short_codename: 'tan_tien',
            parentId: 180,
          },
          {
            title: 'Xã Tân Yên',
            code: 6022,
            value: 'xa_tan_yen',
            division_type: 'xã',
            short_codename: 'tan_yen',
            parentId: 180,
          },
          {
            title: 'Xã Đội Cấn',
            code: 6025,
            value: 'xa_doi_can',
            division_type: 'xã',
            short_codename: 'doi_can',
            parentId: 180,
          },
          {
            title: 'Xã Tân Minh',
            code: 6028,
            value: 'xa_tan_minh',
            division_type: 'xã',
            short_codename: 'tan_minh',
            parentId: 180,
          },
          {
            title: 'Xã Kim Đồng',
            code: 6031,
            value: 'xa_kim_dong',
            division_type: 'xã',
            short_codename: 'kim_dong',
            parentId: 180,
          },
          {
            title: 'Xã Chi Lăng',
            code: 6034,
            value: 'xa_chi_lang',
            division_type: 'xã',
            short_codename: 'chi_lang',
            parentId: 180,
          },
          {
            title: 'Xã Trung Thành',
            code: 6037,
            value: 'xa_trung_thanh',
            division_type: 'xã',
            short_codename: 'trung_thanh',
            parentId: 180,
          },
          {
            title: 'Xã Đại Đồng',
            code: 6040,
            value: 'xa_dai_dong',
            division_type: 'xã',
            short_codename: 'dai_dong',
            parentId: 180,
          },
          {
            title: 'Xã Đào Viên',
            code: 6043,
            value: 'xa_dao_vien',
            division_type: 'xã',
            short_codename: 'dao_vien',
            parentId: 180,
          },
          {
            title: 'Xã Đề Thám',
            code: 6046,
            value: 'xa_de_tham',
            division_type: 'xã',
            short_codename: 'de_tham',
            parentId: 180,
          },
          {
            title: 'Xã Kháng Chiến',
            code: 6049,
            value: 'xa_khang_chien',
            division_type: 'xã',
            short_codename: 'khang_chien',
            parentId: 180,
          },
          {
            title: 'Xã Hùng Sơn',
            code: 6055,
            value: 'xa_hung_son',
            division_type: 'xã',
            short_codename: 'hung_son',
            parentId: 180,
          },
          {
            title: 'Xã Quốc Việt',
            code: 6058,
            value: 'xa_quoc_viet',
            division_type: 'xã',
            short_codename: 'quoc_viet',
            parentId: 180,
          },
          {
            title: 'Xã Hùng Việt',
            code: 6061,
            value: 'xa_hung_viet',
            division_type: 'xã',
            short_codename: 'hung_viet',
            parentId: 180,
          },
        ],
        parentId: 20,
      },
      {
        title: 'Huyện Bình Gia',
        code: 181,
        value: 'huyen_binh_gia',
        division_type: 'huyện',
        short_codename: 'binh_gia',
        children: [
          {
            title: 'Xã Hưng Đạo',
            code: 6067,
            value: 'xa_hung_dao',
            division_type: 'xã',
            short_codename: 'hung_dao',
            parentId: 181,
          },
          {
            title: 'Xã Vĩnh Yên',
            code: 6070,
            value: 'xa_vinh_yen',
            division_type: 'xã',
            short_codename: 'vinh_yen',
            parentId: 181,
          },
          {
            title: 'Xã Hoa Thám',
            code: 6073,
            value: 'xa_hoa_tham',
            division_type: 'xã',
            short_codename: 'hoa_tham',
            parentId: 181,
          },
          {
            title: 'Xã Quý Hòa',
            code: 6076,
            value: 'xa_quy_hoa',
            division_type: 'xã',
            short_codename: 'quy_hoa',
            parentId: 181,
          },
          {
            title: 'Xã Hồng Phong',
            code: 6079,
            value: 'xa_hong_phong',
            division_type: 'xã',
            short_codename: 'hong_phong',
            parentId: 181,
          },
          {
            title: 'Xã Yên Lỗ',
            code: 6082,
            value: 'xa_yen_lo',
            division_type: 'xã',
            short_codename: 'yen_lo',
            parentId: 181,
          },
          {
            title: 'Xã Thiện Hòa',
            code: 6085,
            value: 'xa_thien_hoa',
            division_type: 'xã',
            short_codename: 'thien_hoa',
            parentId: 181,
          },
          {
            title: 'Xã Quang Trung',
            code: 6088,
            value: 'xa_quang_trung',
            division_type: 'xã',
            short_codename: 'quang_trung',
            parentId: 181,
          },
          {
            title: 'Xã Thiện Thuật',
            code: 6091,
            value: 'xa_thien_thuat',
            division_type: 'xã',
            short_codename: 'thien_thuat',
            parentId: 181,
          },
          {
            title: 'Xã Minh Khai',
            code: 6094,
            value: 'xa_minh_khai',
            division_type: 'xã',
            short_codename: 'minh_khai',
            parentId: 181,
          },
          {
            title: 'Xã Thiện Long',
            code: 6097,
            value: 'xa_thien_long',
            division_type: 'xã',
            short_codename: 'thien_long',
            parentId: 181,
          },
          {
            title: 'Xã Hoàng Văn Thụ',
            code: 6100,
            value: 'xa_hoang_van_thu',
            division_type: 'xã',
            short_codename: 'hoang_van_thu',
            parentId: 181,
          },
          {
            title: 'Xã Hòa Bình',
            code: 6103,
            value: 'xa_hoa_binh',
            division_type: 'xã',
            short_codename: 'hoa_binh',
            parentId: 181,
          },
          {
            title: 'Xã Mông Ân',
            code: 6106,
            value: 'xa_mong_an',
            division_type: 'xã',
            short_codename: 'mong_an',
            parentId: 181,
          },
          {
            title: 'Xã Tân Hòa',
            code: 6109,
            value: 'xa_tan_hoa',
            division_type: 'xã',
            short_codename: 'tan_hoa',
            parentId: 181,
          },
          {
            title: 'Thị trấn Bình Gia',
            code: 6112,
            value: 'thi_tran_binh_gia',
            division_type: 'thị trấn',
            short_codename: 'binh_gia',
            parentId: 181,
          },
          {
            title: 'Xã Hồng Thái',
            code: 6115,
            value: 'xa_hong_thai',
            division_type: 'xã',
            short_codename: 'hong_thai',
            parentId: 181,
          },
          {
            title: 'Xã Bình La',
            code: 6118,
            value: 'xa_binh_la',
            division_type: 'xã',
            short_codename: 'binh_la',
            parentId: 181,
          },
          {
            title: 'Xã Tân Văn',
            code: 6121,
            value: 'xa_tan_van',
            division_type: 'xã',
            short_codename: 'tan_van',
            parentId: 181,
          },
        ],
        parentId: 20,
      },
      {
        title: 'Huyện Văn Lãng',
        code: 182,
        value: 'huyen_van_lang',
        division_type: 'huyện',
        short_codename: 'van_lang',
        children: [
          {
            title: 'Thị trấn Na Sầm',
            code: 6124,
            value: 'thi_tran_na_sam',
            division_type: 'thị trấn',
            short_codename: 'na_sam',
            parentId: 182,
          },
          {
            title: 'Xã Trùng Khánh',
            code: 6127,
            value: 'xa_trung_khanh',
            division_type: 'xã',
            short_codename: 'trung_khanh',
            parentId: 182,
          },
          {
            title: 'Xã Bắc La',
            code: 6133,
            value: 'xa_bac_la',
            division_type: 'xã',
            short_codename: 'bac_la',
            parentId: 182,
          },
          {
            title: 'Xã Thụy Hùng',
            code: 6136,
            value: 'xa_thuy_hung',
            division_type: 'xã',
            short_codename: 'thuy_hung',
            parentId: 182,
          },
          {
            title: 'Xã Bắc Hùng',
            code: 6139,
            value: 'xa_bac_hung',
            division_type: 'xã',
            short_codename: 'bac_hung',
            parentId: 182,
          },
          {
            title: 'Xã Tân Tác',
            code: 6142,
            value: 'xa_tan_tac',
            division_type: 'xã',
            short_codename: 'tan_tac',
            parentId: 182,
          },
          {
            title: 'Xã Thanh Long',
            code: 6148,
            value: 'xa_thanh_long',
            division_type: 'xã',
            short_codename: 'thanh_long',
            parentId: 182,
          },
          {
            title: 'Xã Hội Hoan',
            code: 6151,
            value: 'xa_hoi_hoan',
            division_type: 'xã',
            short_codename: 'hoi_hoan',
            parentId: 182,
          },
          {
            title: 'Xã Bắc Việt',
            code: 6154,
            value: 'xa_bac_viet',
            division_type: 'xã',
            short_codename: 'bac_viet',
            parentId: 182,
          },
          {
            title: 'Xã Hoàng Việt',
            code: 6157,
            value: 'xa_hoang_viet',
            division_type: 'xã',
            short_codename: 'hoang_viet',
            parentId: 182,
          },
          {
            title: 'Xã Gia Miễn',
            code: 6160,
            value: 'xa_gia_mien',
            division_type: 'xã',
            short_codename: 'gia_mien',
            parentId: 182,
          },
          {
            title: 'Xã Thành Hòa',
            code: 6163,
            value: 'xa_thanh_hoa',
            division_type: 'xã',
            short_codename: 'thanh_hoa',
            parentId: 182,
          },
          {
            title: 'Xã Tân Thanh',
            code: 6166,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 182,
          },
          {
            title: 'Xã Tân Mỹ',
            code: 6172,
            value: 'xa_tan_my',
            division_type: 'xã',
            short_codename: 'tan_my',
            parentId: 182,
          },
          {
            title: 'Xã Hồng Thái',
            code: 6175,
            value: 'xa_hong_thai',
            division_type: 'xã',
            short_codename: 'hong_thai',
            parentId: 182,
          },
          {
            title: 'Xã Hoàng Văn Thụ',
            code: 6178,
            value: 'xa_hoang_van_thu',
            division_type: 'xã',
            short_codename: 'hoang_van_thu',
            parentId: 182,
          },
          {
            title: 'Xã Nhạc Kỳ',
            code: 6181,
            value: 'xa_nhac_ky',
            division_type: 'xã',
            short_codename: 'nhac_ky',
            parentId: 182,
          },
        ],
        parentId: 20,
      },
      {
        title: 'Huyện Cao Lộc',
        code: 183,
        value: 'huyen_cao_loc',
        division_type: 'huyện',
        short_codename: 'cao_loc',
        children: [
          {
            title: 'Thị trấn Đồng Đăng',
            code: 6184,
            value: 'thi_tran_dong_dang',
            division_type: 'thị trấn',
            short_codename: 'dong_dang',
            parentId: 183,
          },
          {
            title: 'Thị trấn Cao Lộc',
            code: 6187,
            value: 'thi_tran_cao_loc',
            division_type: 'thị trấn',
            short_codename: 'cao_loc',
            parentId: 183,
          },
          {
            title: 'Xã Bảo Lâm',
            code: 6190,
            value: 'xa_bao_lam',
            division_type: 'xã',
            short_codename: 'bao_lam',
            parentId: 183,
          },
          {
            title: 'Xã Thanh Lòa',
            code: 6193,
            value: 'xa_thanh_loa',
            division_type: 'xã',
            short_codename: 'thanh_loa',
            parentId: 183,
          },
          {
            title: 'Xã Cao Lâu',
            code: 6196,
            value: 'xa_cao_lau',
            division_type: 'xã',
            short_codename: 'cao_lau',
            parentId: 183,
          },
          {
            title: 'Xã Thạch Đạn',
            code: 6199,
            value: 'xa_thach_dan',
            division_type: 'xã',
            short_codename: 'thach_dan',
            parentId: 183,
          },
          {
            title: 'Xã Xuất Lễ',
            code: 6202,
            value: 'xa_xuat_le',
            division_type: 'xã',
            short_codename: 'xuat_le',
            parentId: 183,
          },
          {
            title: 'Xã Hồng Phong',
            code: 6205,
            value: 'xa_hong_phong',
            division_type: 'xã',
            short_codename: 'hong_phong',
            parentId: 183,
          },
          {
            title: 'Xã Thụy Hùng',
            code: 6208,
            value: 'xa_thuy_hung',
            division_type: 'xã',
            short_codename: 'thuy_hung',
            parentId: 183,
          },
          {
            title: 'Xã Lộc Yên',
            code: 6211,
            value: 'xa_loc_yen',
            division_type: 'xã',
            short_codename: 'loc_yen',
            parentId: 183,
          },
          {
            title: 'Xã Phú Xá',
            code: 6214,
            value: 'xa_phu_xa',
            division_type: 'xã',
            short_codename: 'phu_xa',
            parentId: 183,
          },
          {
            title: 'Xã Bình Trung',
            code: 6217,
            value: 'xa_binh_trung',
            division_type: 'xã',
            short_codename: 'binh_trung',
            parentId: 183,
          },
          {
            title: 'Xã Hải Yến',
            code: 6220,
            value: 'xa_hai_yen',
            division_type: 'xã',
            short_codename: 'hai_yen',
            parentId: 183,
          },
          {
            title: 'Xã Hòa Cư',
            code: 6223,
            value: 'xa_hoa_cu',
            division_type: 'xã',
            short_codename: 'hoa_cu',
            parentId: 183,
          },
          {
            title: 'Xã Hợp Thành',
            code: 6226,
            value: 'xa_hop_thanh',
            division_type: 'xã',
            short_codename: 'hop_thanh',
            parentId: 183,
          },
          {
            title: 'Xã Công Sơn',
            code: 6232,
            value: 'xa_cong_son',
            division_type: 'xã',
            short_codename: 'cong_son',
            parentId: 183,
          },
          {
            title: 'Xã Gia Cát',
            code: 6235,
            value: 'xa_gia_cat',
            division_type: 'xã',
            short_codename: 'gia_cat',
            parentId: 183,
          },
          {
            title: 'Xã Mẫu Sơn',
            code: 6238,
            value: 'xa_mau_son',
            division_type: 'xã',
            short_codename: 'mau_son',
            parentId: 183,
          },
          {
            title: 'Xã Xuân Long',
            code: 6241,
            value: 'xa_xuan_long',
            division_type: 'xã',
            short_codename: 'xuan_long',
            parentId: 183,
          },
          {
            title: 'Xã Tân Liên',
            code: 6244,
            value: 'xa_tan_lien',
            division_type: 'xã',
            short_codename: 'tan_lien',
            parentId: 183,
          },
          {
            title: 'Xã Yên Trạch',
            code: 6247,
            value: 'xa_yen_trach',
            division_type: 'xã',
            short_codename: 'yen_trach',
            parentId: 183,
          },
          {
            title: 'Xã Tân Thành',
            code: 6250,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 183,
          },
        ],
        parentId: 20,
      },
      {
        title: 'Huyện Văn Quan',
        code: 184,
        value: 'huyen_van_quan',
        division_type: 'huyện',
        short_codename: 'van_quan',
        children: [
          {
            title: 'Thị trấn Văn Quan',
            code: 6253,
            value: 'thi_tran_van_quan',
            division_type: 'thị trấn',
            short_codename: 'van_quan',
            parentId: 184,
          },
          {
            title: 'Xã Trấn Ninh',
            code: 6256,
            value: 'xa_tran_ninh',
            division_type: 'xã',
            short_codename: 'tran_ninh',
            parentId: 184,
          },
          {
            title: 'Xã Liên Hội',
            code: 6268,
            value: 'xa_lien_hoi',
            division_type: 'xã',
            short_codename: 'lien_hoi',
            parentId: 184,
          },
          {
            title: 'Xã Hòa Bình',
            code: 6274,
            value: 'xa_hoa_binh',
            division_type: 'xã',
            short_codename: 'hoa_binh',
            parentId: 184,
          },
          {
            title: 'Xã Tú Xuyên',
            code: 6277,
            value: 'xa_tu_xuyen',
            division_type: 'xã',
            short_codename: 'tu_xuyen',
            parentId: 184,
          },
          {
            title: 'Xã Điềm He',
            code: 6280,
            value: 'xa_diem_he',
            division_type: 'xã',
            short_codename: 'diem_he',
            parentId: 184,
          },
          {
            title: 'Xã An Sơn',
            code: 6283,
            value: 'xa_an_son',
            division_type: 'xã',
            short_codename: 'an_son',
            parentId: 184,
          },
          {
            title: 'Xã Khánh Khê',
            code: 6286,
            value: 'xa_khanh_khe',
            division_type: 'xã',
            short_codename: 'khanh_khe',
            parentId: 184,
          },
          {
            title: 'Xã Lương Năng',
            code: 6292,
            value: 'xa_luong_nang',
            division_type: 'xã',
            short_codename: 'luong_nang',
            parentId: 184,
          },
          {
            title: 'Xã Đồng Giáp',
            code: 6295,
            value: 'xa_dong_giap',
            division_type: 'xã',
            short_codename: 'dong_giap',
            parentId: 184,
          },
          {
            title: 'Xã Bình Phúc',
            code: 6298,
            value: 'xa_binh_phuc',
            division_type: 'xã',
            short_codename: 'binh_phuc',
            parentId: 184,
          },
          {
            title: 'Xã Tràng Các',
            code: 6301,
            value: 'xa_trang_cac',
            division_type: 'xã',
            short_codename: 'trang_cac',
            parentId: 184,
          },
          {
            title: 'Xã Tân Đoàn',
            code: 6307,
            value: 'xa_tan_doan',
            division_type: 'xã',
            short_codename: 'tan_doan',
            parentId: 184,
          },
          {
            title: 'Xã Tri Lễ',
            code: 6313,
            value: 'xa_tri_le',
            division_type: 'xã',
            short_codename: 'tri_le',
            parentId: 184,
          },
          {
            title: 'Xã Tràng Phái',
            code: 6316,
            value: 'xa_trang_phai',
            division_type: 'xã',
            short_codename: 'trang_phai',
            parentId: 184,
          },
          {
            title: 'Xã Yên Phúc',
            code: 6319,
            value: 'xa_yen_phuc',
            division_type: 'xã',
            short_codename: 'yen_phuc',
            parentId: 184,
          },
          {
            title: 'Xã Hữu Lễ',
            code: 6322,
            value: 'xa_huu_le',
            division_type: 'xã',
            short_codename: 'huu_le',
            parentId: 184,
          },
        ],
        parentId: 20,
      },
      {
        title: 'Huyện Bắc Sơn',
        code: 185,
        value: 'huyen_bac_son',
        division_type: 'huyện',
        short_codename: 'bac_son',
        children: [
          {
            title: 'Thị trấn Bắc Sơn',
            code: 6325,
            value: 'thi_tran_bac_son',
            division_type: 'thị trấn',
            short_codename: 'bac_son',
            parentId: 185,
          },
          {
            title: 'Xã Long Đống',
            code: 6328,
            value: 'xa_long_dong',
            division_type: 'xã',
            short_codename: 'long_dong',
            parentId: 185,
          },
          {
            title: 'Xã Vạn Thủy',
            code: 6331,
            value: 'xa_van_thuy',
            division_type: 'xã',
            short_codename: 'van_thuy',
            parentId: 185,
          },
          {
            title: 'Xã Đồng ý',
            code: 6337,
            value: 'xa_dong_y',
            division_type: 'xã',
            short_codename: 'dong_y',
            parentId: 185,
          },
          {
            title: 'Xã Tân Tri',
            code: 6340,
            value: 'xa_tan_tri',
            division_type: 'xã',
            short_codename: 'tan_tri',
            parentId: 185,
          },
          {
            title: 'Xã Bắc Quỳnh',
            code: 6343,
            value: 'xa_bac_quynh',
            division_type: 'xã',
            short_codename: 'bac_quynh',
            parentId: 185,
          },
          {
            title: 'Xã Hưng Vũ',
            code: 6349,
            value: 'xa_hung_vu',
            division_type: 'xã',
            short_codename: 'hung_vu',
            parentId: 185,
          },
          {
            title: 'Xã Tân Lập',
            code: 6352,
            value: 'xa_tan_lap',
            division_type: 'xã',
            short_codename: 'tan_lap',
            parentId: 185,
          },
          {
            title: 'Xã Vũ Sơn',
            code: 6355,
            value: 'xa_vu_son',
            division_type: 'xã',
            short_codename: 'vu_son',
            parentId: 185,
          },
          {
            title: 'Xã Chiêu Vũ',
            code: 6358,
            value: 'xa_chieu_vu',
            division_type: 'xã',
            short_codename: 'chieu_vu',
            parentId: 185,
          },
          {
            title: 'Xã Tân Hương',
            code: 6361,
            value: 'xa_tan_huong',
            division_type: 'xã',
            short_codename: 'tan_huong',
            parentId: 185,
          },
          {
            title: 'Xã Chiến Thắng',
            code: 6364,
            value: 'xa_chien_thang',
            division_type: 'xã',
            short_codename: 'chien_thang',
            parentId: 185,
          },
          {
            title: 'Xã Vũ Lăng',
            code: 6367,
            value: 'xa_vu_lang',
            division_type: 'xã',
            short_codename: 'vu_lang',
            parentId: 185,
          },
          {
            title: 'Xã Trấn Yên',
            code: 6370,
            value: 'xa_tran_yen',
            division_type: 'xã',
            short_codename: 'tran_yen',
            parentId: 185,
          },
          {
            title: 'Xã Vũ Lễ',
            code: 6373,
            value: 'xa_vu_le',
            division_type: 'xã',
            short_codename: 'vu_le',
            parentId: 185,
          },
          {
            title: 'Xã Nhất Hòa',
            code: 6376,
            value: 'xa_nhat_hoa',
            division_type: 'xã',
            short_codename: 'nhat_hoa',
            parentId: 185,
          },
          {
            title: 'Xã Tân Thành',
            code: 6379,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 185,
          },
          {
            title: 'Xã Nhất Tiến',
            code: 6382,
            value: 'xa_nhat_tien',
            division_type: 'xã',
            short_codename: 'nhat_tien',
            parentId: 185,
          },
        ],
        parentId: 20,
      },
      {
        title: 'Huyện Hữu Lũng',
        code: 186,
        value: 'huyen_huu_lung',
        division_type: 'huyện',
        short_codename: 'huu_lung',
        children: [
          {
            title: 'Thị trấn Hữu Lũng',
            code: 6385,
            value: 'thi_tran_huu_lung',
            division_type: 'thị trấn',
            short_codename: 'huu_lung',
            parentId: 186,
          },
          {
            title: 'Xã Hữu Liên',
            code: 6388,
            value: 'xa_huu_lien',
            division_type: 'xã',
            short_codename: 'huu_lien',
            parentId: 186,
          },
          {
            title: 'Xã Yên Bình',
            code: 6391,
            value: 'xa_yen_binh',
            division_type: 'xã',
            short_codename: 'yen_binh',
            parentId: 186,
          },
          {
            title: 'Xã Quyết Thắng',
            code: 6394,
            value: 'xa_quyet_thang',
            division_type: 'xã',
            short_codename: 'quyet_thang',
            parentId: 186,
          },
          {
            title: 'Xã Hòa Bình',
            code: 6397,
            value: 'xa_hoa_binh',
            division_type: 'xã',
            short_codename: 'hoa_binh',
            parentId: 186,
          },
          {
            title: 'Xã Yên Thịnh',
            code: 6400,
            value: 'xa_yen_thinh',
            division_type: 'xã',
            short_codename: 'yen_thinh',
            parentId: 186,
          },
          {
            title: 'Xã Yên Sơn',
            code: 6403,
            value: 'xa_yen_son',
            division_type: 'xã',
            short_codename: 'yen_son',
            parentId: 186,
          },
          {
            title: 'Xã Thiện Tân',
            code: 6406,
            value: 'xa_thien_tan',
            division_type: 'xã',
            short_codename: 'thien_tan',
            parentId: 186,
          },
          {
            title: 'Xã Yên Vượng',
            code: 6412,
            value: 'xa_yen_vuong',
            division_type: 'xã',
            short_codename: 'yen_vuong',
            parentId: 186,
          },
          {
            title: 'Xã Minh Tiến',
            code: 6415,
            value: 'xa_minh_tien',
            division_type: 'xã',
            short_codename: 'minh_tien',
            parentId: 186,
          },
          {
            title: 'Xã Nhật Tiến',
            code: 6418,
            value: 'xa_nhat_tien',
            division_type: 'xã',
            short_codename: 'nhat_tien',
            parentId: 186,
          },
          {
            title: 'Xã Thanh Sơn',
            code: 6421,
            value: 'xa_thanh_son',
            division_type: 'xã',
            short_codename: 'thanh_son',
            parentId: 186,
          },
          {
            title: 'Xã Đồng Tân',
            code: 6424,
            value: 'xa_dong_tan',
            division_type: 'xã',
            short_codename: 'dong_tan',
            parentId: 186,
          },
          {
            title: 'Xã Cai Kinh',
            code: 6427,
            value: 'xa_cai_kinh',
            division_type: 'xã',
            short_codename: 'cai_kinh',
            parentId: 186,
          },
          {
            title: 'Xã Hòa Lạc',
            code: 6430,
            value: 'xa_hoa_lac',
            division_type: 'xã',
            short_codename: 'hoa_lac',
            parentId: 186,
          },
          {
            title: 'Xã Vân Nham',
            code: 6433,
            value: 'xa_van_nham',
            division_type: 'xã',
            short_codename: 'van_nham',
            parentId: 186,
          },
          {
            title: 'Xã Đồng Tiến',
            code: 6436,
            value: 'xa_dong_tien',
            division_type: 'xã',
            short_codename: 'dong_tien',
            parentId: 186,
          },
          {
            title: 'Xã Tân Thành',
            code: 6442,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 186,
          },
          {
            title: 'Xã Hòa Sơn',
            code: 6445,
            value: 'xa_hoa_son',
            division_type: 'xã',
            short_codename: 'hoa_son',
            parentId: 186,
          },
          {
            title: 'Xã Minh Sơn',
            code: 6448,
            value: 'xa_minh_son',
            division_type: 'xã',
            short_codename: 'minh_son',
            parentId: 186,
          },
          {
            title: 'Xã Hồ Sơn',
            code: 6451,
            value: 'xa_ho_son',
            division_type: 'xã',
            short_codename: 'ho_son',
            parentId: 186,
          },
          {
            title: 'Xã Sơn Hà',
            code: 6454,
            value: 'xa_son_ha',
            division_type: 'xã',
            short_codename: 'son_ha',
            parentId: 186,
          },
          {
            title: 'Xã Minh Hòa',
            code: 6457,
            value: 'xa_minh_hoa',
            division_type: 'xã',
            short_codename: 'minh_hoa',
            parentId: 186,
          },
          {
            title: 'Xã Hòa Thắng',
            code: 6460,
            value: 'xa_hoa_thang',
            division_type: 'xã',
            short_codename: 'hoa_thang',
            parentId: 186,
          },
        ],
        parentId: 20,
      },
      {
        title: 'Huyện Chi Lăng',
        code: 187,
        value: 'huyen_chi_lang',
        division_type: 'huyện',
        short_codename: 'chi_lang',
        children: [
          {
            title: 'Thị trấn Đồng Mỏ',
            code: 6463,
            value: 'thi_tran_dong_mo',
            division_type: 'thị trấn',
            short_codename: 'dong_mo',
            parentId: 187,
          },
          {
            title: 'Thị trấn Chi Lăng',
            code: 6466,
            value: 'thi_tran_chi_lang',
            division_type: 'thị trấn',
            short_codename: 'chi_lang',
            parentId: 187,
          },
          {
            title: 'Xã Vân An',
            code: 6469,
            value: 'xa_van_an',
            division_type: 'xã',
            short_codename: 'van_an',
            parentId: 187,
          },
          {
            title: 'Xã Vân Thủy',
            code: 6472,
            value: 'xa_van_thuy',
            division_type: 'xã',
            short_codename: 'van_thuy',
            parentId: 187,
          },
          {
            title: 'Xã Gia Lộc',
            code: 6475,
            value: 'xa_gia_loc',
            division_type: 'xã',
            short_codename: 'gia_loc',
            parentId: 187,
          },
          {
            title: 'Xã Bắc Thủy',
            code: 6478,
            value: 'xa_bac_thuy',
            division_type: 'xã',
            short_codename: 'bac_thuy',
            parentId: 187,
          },
          {
            title: 'Xã Chiến Thắng',
            code: 6481,
            value: 'xa_chien_thang',
            division_type: 'xã',
            short_codename: 'chien_thang',
            parentId: 187,
          },
          {
            title: 'Xã Mai Sao',
            code: 6484,
            value: 'xa_mai_sao',
            division_type: 'xã',
            short_codename: 'mai_sao',
            parentId: 187,
          },
          {
            title: 'Xã Bằng Hữu',
            code: 6487,
            value: 'xa_bang_huu',
            division_type: 'xã',
            short_codename: 'bang_huu',
            parentId: 187,
          },
          {
            title: 'Xã Thượng Cường',
            code: 6490,
            value: 'xa_thuong_cuong',
            division_type: 'xã',
            short_codename: 'thuong_cuong',
            parentId: 187,
          },
          {
            title: 'Xã Bằng Mạc',
            code: 6493,
            value: 'xa_bang_mac',
            division_type: 'xã',
            short_codename: 'bang_mac',
            parentId: 187,
          },
          {
            title: 'Xã Nhân Lý',
            code: 6496,
            value: 'xa_nhan_ly',
            division_type: 'xã',
            short_codename: 'nhan_ly',
            parentId: 187,
          },
          {
            title: 'Xã Lâm Sơn',
            code: 6499,
            value: 'xa_lam_son',
            division_type: 'xã',
            short_codename: 'lam_son',
            parentId: 187,
          },
          {
            title: 'Xã Liên Sơn',
            code: 6502,
            value: 'xa_lien_son',
            division_type: 'xã',
            short_codename: 'lien_son',
            parentId: 187,
          },
          {
            title: 'Xã Vạn Linh',
            code: 6505,
            value: 'xa_van_linh',
            division_type: 'xã',
            short_codename: 'van_linh',
            parentId: 187,
          },
          {
            title: 'Xã Hòa Bình',
            code: 6508,
            value: 'xa_hoa_binh',
            division_type: 'xã',
            short_codename: 'hoa_binh',
            parentId: 187,
          },
          {
            title: 'Xã Hữu Kiên',
            code: 6514,
            value: 'xa_huu_kien',
            division_type: 'xã',
            short_codename: 'huu_kien',
            parentId: 187,
          },
          {
            title: 'Xã Quan Sơn',
            code: 6517,
            value: 'xa_quan_son',
            division_type: 'xã',
            short_codename: 'quan_son',
            parentId: 187,
          },
          {
            title: 'Xã Y Tịch',
            code: 6520,
            value: 'xa_y_tich',
            division_type: 'xã',
            short_codename: 'y_tich',
            parentId: 187,
          },
          {
            title: 'Xã Chi Lăng',
            code: 6523,
            value: 'xa_chi_lang',
            division_type: 'xã',
            short_codename: 'xa_chi_lang',
            parentId: 187,
          },
        ],
        parentId: 20,
      },
      {
        title: 'Huyện Lộc Bình',
        code: 188,
        value: 'huyen_loc_binh',
        division_type: 'huyện',
        short_codename: 'loc_binh',
        children: [
          {
            title: 'Thị trấn Na Dương',
            code: 6526,
            value: 'thi_tran_na_duong',
            division_type: 'thị trấn',
            short_codename: 'na_duong',
            parentId: 188,
          },
          {
            title: 'Thị trấn Lộc Bình',
            code: 6529,
            value: 'thi_tran_loc_binh',
            division_type: 'thị trấn',
            short_codename: 'loc_binh',
            parentId: 188,
          },
          {
            title: 'Xã Mẫu Sơn',
            code: 6532,
            value: 'xa_mau_son',
            division_type: 'xã',
            short_codename: 'mau_son',
            parentId: 188,
          },
          {
            title: 'Xã Yên Khoái',
            code: 6541,
            value: 'xa_yen_khoai',
            division_type: 'xã',
            short_codename: 'yen_khoai',
            parentId: 188,
          },
          {
            title: 'Xã Khánh Xuân',
            code: 6544,
            value: 'xa_khanh_xuan',
            division_type: 'xã',
            short_codename: 'khanh_xuan',
            parentId: 188,
          },
          {
            title: 'Xã Tú Mịch',
            code: 6547,
            value: 'xa_tu_mich',
            division_type: 'xã',
            short_codename: 'tu_mich',
            parentId: 188,
          },
          {
            title: 'Xã Hữu Khánh',
            code: 6550,
            value: 'xa_huu_khanh',
            division_type: 'xã',
            short_codename: 'huu_khanh',
            parentId: 188,
          },
          {
            title: 'Xã Đồng Bục',
            code: 6553,
            value: 'xa_dong_buc',
            division_type: 'xã',
            short_codename: 'dong_buc',
            parentId: 188,
          },
          {
            title: 'Xã Tam Gia',
            code: 6559,
            value: 'xa_tam_gia',
            division_type: 'xã',
            short_codename: 'tam_gia',
            parentId: 188,
          },
          {
            title: 'Xã Tú Đoạn',
            code: 6562,
            value: 'xa_tu_doan',
            division_type: 'xã',
            short_codename: 'tu_doan',
            parentId: 188,
          },
          {
            title: 'Xã Khuất Xá',
            code: 6565,
            value: 'xa_khuat_xa',
            division_type: 'xã',
            short_codename: 'khuat_xa',
            parentId: 188,
          },
          {
            title: 'Xã Tĩnh Bắc',
            code: 6574,
            value: 'xa_tinh_bac',
            division_type: 'xã',
            short_codename: 'tinh_bac',
            parentId: 188,
          },
          {
            title: 'Xã Thống Nhất',
            code: 6577,
            value: 'xa_thong_nhat',
            division_type: 'xã',
            short_codename: 'thong_nhat',
            parentId: 188,
          },
          {
            title: 'Xã Sàn Viên',
            code: 6589,
            value: 'xa_san_vien',
            division_type: 'xã',
            short_codename: 'san_vien',
            parentId: 188,
          },
          {
            title: 'Xã Đông Quan',
            code: 6592,
            value: 'xa_dong_quan',
            division_type: 'xã',
            short_codename: 'dong_quan',
            parentId: 188,
          },
          {
            title: 'Xã Minh Hiệp',
            code: 6595,
            value: 'xa_minh_hiep',
            division_type: 'xã',
            short_codename: 'minh_hiep',
            parentId: 188,
          },
          {
            title: 'Xã Hữu Lân',
            code: 6598,
            value: 'xa_huu_lan',
            division_type: 'xã',
            short_codename: 'huu_lan',
            parentId: 188,
          },
          {
            title: 'Xã Lợi Bác',
            code: 6601,
            value: 'xa_loi_bac',
            division_type: 'xã',
            short_codename: 'loi_bac',
            parentId: 188,
          },
          {
            title: 'Xã Nam Quan',
            code: 6604,
            value: 'xa_nam_quan',
            division_type: 'xã',
            short_codename: 'nam_quan',
            parentId: 188,
          },
          {
            title: 'Xã Xuân Dương',
            code: 6607,
            value: 'xa_xuan_duong',
            division_type: 'xã',
            short_codename: 'xuan_duong',
            parentId: 188,
          },
          {
            title: 'Xã Ái Quốc',
            code: 6610,
            value: 'xa_ai_quoc',
            division_type: 'xã',
            short_codename: 'ai_quoc',
            parentId: 188,
          },
        ],
        parentId: 20,
      },
      {
        title: 'Huyện Đình Lập',
        code: 189,
        value: 'huyen_dinh_lap',
        division_type: 'huyện',
        short_codename: 'dinh_lap',
        children: [
          {
            title: 'Thị trấn Đình Lập',
            code: 6613,
            value: 'thi_tran_dinh_lap',
            division_type: 'thị trấn',
            short_codename: 'dinh_lap',
            parentId: 189,
          },
          {
            title: 'Thị trấn NT Thái Bình',
            code: 6616,
            value: 'thi_tran_nt_thai_binh',
            division_type: 'thị trấn',
            short_codename: 'nt_thai_binh',
            parentId: 189,
          },
          {
            title: 'Xã Bắc Xa',
            code: 6619,
            value: 'xa_bac_xa',
            division_type: 'xã',
            short_codename: 'bac_xa',
            parentId: 189,
          },
          {
            title: 'Xã Bính Xá',
            code: 6622,
            value: 'xa_binh_xa',
            division_type: 'xã',
            short_codename: 'binh_xa',
            parentId: 189,
          },
          {
            title: 'Xã Kiên Mộc',
            code: 6625,
            value: 'xa_kien_moc',
            division_type: 'xã',
            short_codename: 'kien_moc',
            parentId: 189,
          },
          {
            title: 'Xã Đình Lập',
            code: 6628,
            value: 'xa_dinh_lap',
            division_type: 'xã',
            short_codename: 'xa_dinh_lap',
            parentId: 189,
          },
          {
            title: 'Xã Thái Bình',
            code: 6631,
            value: 'xa_thai_binh',
            division_type: 'xã',
            short_codename: 'thai_binh',
            parentId: 189,
          },
          {
            title: 'Xã Cường Lợi',
            code: 6634,
            value: 'xa_cuong_loi',
            division_type: 'xã',
            short_codename: 'cuong_loi',
            parentId: 189,
          },
          {
            title: 'Xã Châu Sơn',
            code: 6637,
            value: 'xa_chau_son',
            division_type: 'xã',
            short_codename: 'chau_son',
            parentId: 189,
          },
          {
            title: 'Xã Lâm Ca',
            code: 6640,
            value: 'xa_lam_ca',
            division_type: 'xã',
            short_codename: 'lam_ca',
            parentId: 189,
          },
          {
            title: 'Xã Đồng Thắng',
            code: 6643,
            value: 'xa_dong_thang',
            division_type: 'xã',
            short_codename: 'dong_thang',
            parentId: 189,
          },
          {
            title: 'Xã Bắc Lãng',
            code: 6646,
            value: 'xa_bac_lang',
            division_type: 'xã',
            short_codename: 'bac_lang',
            parentId: 189,
          },
        ],
        parentId: 20,
      },
    ],
  },
  {
    title: 'Tỉnh Quảng Ninh',
    code: 22,
    value: 'tinh_quang_ninh',
    division_type: 'tỉnh',
    phone_code: 203,
    children: [
      {
        title: 'Thành phố Hạ Long',
        code: 193,
        value: 'thanh_pho_ha_long',
        division_type: 'thành phố',
        short_codename: 'ha_long',
        children: [
          {
            title: 'Phường Hà Khánh',
            code: 6649,
            value: 'phuong_ha_khanh',
            division_type: 'phường',
            short_codename: 'ha_khanh',
            parentId: 193,
          },
          {
            title: 'Phường Hà Phong',
            code: 6652,
            value: 'phuong_ha_phong',
            division_type: 'phường',
            short_codename: 'ha_phong',
            parentId: 193,
          },
          {
            title: 'Phường Hà Khẩu',
            code: 6655,
            value: 'phuong_ha_khau',
            division_type: 'phường',
            short_codename: 'ha_khau',
            parentId: 193,
          },
          {
            title: 'Phường Cao Xanh',
            code: 6658,
            value: 'phuong_cao_xanh',
            division_type: 'phường',
            short_codename: 'cao_xanh',
            parentId: 193,
          },
          {
            title: 'Phường Giếng Đáy',
            code: 6661,
            value: 'phuong_gieng_day',
            division_type: 'phường',
            short_codename: 'gieng_day',
            parentId: 193,
          },
          {
            title: 'Phường Hà Tu',
            code: 6664,
            value: 'phuong_ha_tu',
            division_type: 'phường',
            short_codename: 'ha_tu',
            parentId: 193,
          },
          {
            title: 'Phường Hà Trung',
            code: 6667,
            value: 'phuong_ha_trung',
            division_type: 'phường',
            short_codename: 'ha_trung',
            parentId: 193,
          },
          {
            title: 'Phường Hà Lầm',
            code: 6670,
            value: 'phuong_ha_lam',
            division_type: 'phường',
            short_codename: 'ha_lam',
            parentId: 193,
          },
          {
            title: 'Phường Bãi Cháy',
            code: 6673,
            value: 'phuong_bai_chay',
            division_type: 'phường',
            short_codename: 'bai_chay',
            parentId: 193,
          },
          {
            title: 'Phường Cao Thắng',
            code: 6676,
            value: 'phuong_cao_thang',
            division_type: 'phường',
            short_codename: 'cao_thang',
            parentId: 193,
          },
          {
            title: 'Phường Hùng Thắng',
            code: 6679,
            value: 'phuong_hung_thang',
            division_type: 'phường',
            short_codename: 'hung_thang',
            parentId: 193,
          },
          {
            title: 'Phường Yết Kiêu',
            code: 6682,
            value: 'phuong_yet_kieu',
            division_type: 'phường',
            short_codename: 'yet_kieu',
            parentId: 193,
          },
          {
            title: 'Phường Trần Hưng Đạo',
            code: 6685,
            value: 'phuong_tran_hung_dao',
            division_type: 'phường',
            short_codename: 'tran_hung_dao',
            parentId: 193,
          },
          {
            title: 'Phường Hồng Hải',
            code: 6688,
            value: 'phuong_hong_hai',
            division_type: 'phường',
            short_codename: 'hong_hai',
            parentId: 193,
          },
          {
            title: 'Phường Hồng Gai',
            code: 6691,
            value: 'phuong_hong_gai',
            division_type: 'phường',
            short_codename: 'hong_gai',
            parentId: 193,
          },
          {
            title: 'Phường Bạch Đằng',
            code: 6694,
            value: 'phuong_bach_dang',
            division_type: 'phường',
            short_codename: 'bach_dang',
            parentId: 193,
          },
          {
            title: 'Phường Hồng Hà',
            code: 6697,
            value: 'phuong_hong_ha',
            division_type: 'phường',
            short_codename: 'hong_ha',
            parentId: 193,
          },
          {
            title: 'Phường Tuần Châu',
            code: 6700,
            value: 'phuong_tuan_chau',
            division_type: 'phường',
            short_codename: 'tuan_chau',
            parentId: 193,
          },
          {
            title: 'Phường Việt Hưng',
            code: 6703,
            value: 'phuong_viet_hung',
            division_type: 'phường',
            short_codename: 'viet_hung',
            parentId: 193,
          },
          {
            title: 'Phường Đại Yên',
            code: 6706,
            value: 'phuong_dai_yen',
            division_type: 'phường',
            short_codename: 'dai_yen',
            parentId: 193,
          },
          {
            title: 'Phường Hoành Bồ',
            code: 7030,
            value: 'phuong_hoanh_bo',
            division_type: 'phường',
            short_codename: 'hoanh_bo',
            parentId: 193,
          },
          {
            title: 'Xã Kỳ Thượng',
            code: 7033,
            value: 'xa_ky_thuong',
            division_type: 'xã',
            short_codename: 'ky_thuong',
            parentId: 193,
          },
          {
            title: 'Xã Đồng Sơn',
            code: 7036,
            value: 'xa_dong_son',
            division_type: 'xã',
            short_codename: 'dong_son',
            parentId: 193,
          },
          {
            title: 'Xã Tân Dân',
            code: 7039,
            value: 'xa_tan_dan',
            division_type: 'xã',
            short_codename: 'tan_dan',
            parentId: 193,
          },
          {
            title: 'Xã Đồng Lâm',
            code: 7042,
            value: 'xa_dong_lam',
            division_type: 'xã',
            short_codename: 'dong_lam',
            parentId: 193,
          },
          {
            title: 'Xã Hòa Bình',
            code: 7045,
            value: 'xa_hoa_binh',
            division_type: 'xã',
            short_codename: 'hoa_binh',
            parentId: 193,
          },
          {
            title: 'Xã Vũ Oai',
            code: 7048,
            value: 'xa_vu_oai',
            division_type: 'xã',
            short_codename: 'vu_oai',
            parentId: 193,
          },
          {
            title: 'Xã Dân Chủ',
            code: 7051,
            value: 'xa_dan_chu',
            division_type: 'xã',
            short_codename: 'dan_chu',
            parentId: 193,
          },
          {
            title: 'Xã Quảng La',
            code: 7054,
            value: 'xa_quang_la',
            division_type: 'xã',
            short_codename: 'quang_la',
            parentId: 193,
          },
          {
            title: 'Xã Bằng Cả',
            code: 7057,
            value: 'xa_bang_ca',
            division_type: 'xã',
            short_codename: 'bang_ca',
            parentId: 193,
          },
          {
            title: 'Xã Thống Nhất',
            code: 7060,
            value: 'xa_thong_nhat',
            division_type: 'xã',
            short_codename: 'thong_nhat',
            parentId: 193,
          },
          {
            title: 'Xã Sơn Dương',
            code: 7063,
            value: 'xa_son_duong',
            division_type: 'xã',
            short_codename: 'son_duong',
            parentId: 193,
          },
          {
            title: 'Xã Lê Lợi',
            code: 7066,
            value: 'xa_le_loi',
            division_type: 'xã',
            short_codename: 'le_loi',
            parentId: 193,
          },
        ],
        parentId: 22,
      },
      {
        title: 'Thành phố Móng Cái',
        code: 194,
        value: 'thanh_pho_mong_cai',
        division_type: 'thành phố',
        short_codename: 'mong_cai',
        children: [
          {
            title: 'Phường Ka Long',
            code: 6709,
            value: 'phuong_ka_long',
            division_type: 'phường',
            short_codename: 'ka_long',
            parentId: 194,
          },
          {
            title: 'Phường Trần Phú',
            code: 6712,
            value: 'phuong_tran_phu',
            division_type: 'phường',
            short_codename: 'tran_phu',
            parentId: 194,
          },
          {
            title: 'Phường Ninh Dương',
            code: 6715,
            value: 'phuong_ninh_duong',
            division_type: 'phường',
            short_codename: 'ninh_duong',
            parentId: 194,
          },
          {
            title: 'Phường Hoà Lạc',
            code: 6718,
            value: 'phuong_hoa_lac',
            division_type: 'phường',
            short_codename: 'hoa_lac',
            parentId: 194,
          },
          {
            title: 'Phường Trà Cổ',
            code: 6721,
            value: 'phuong_tra_co',
            division_type: 'phường',
            short_codename: 'tra_co',
            parentId: 194,
          },
          {
            title: 'Xã Hải Sơn',
            code: 6724,
            value: 'xa_hai_son',
            division_type: 'xã',
            short_codename: 'hai_son',
            parentId: 194,
          },
          {
            title: 'Xã Bắc Sơn',
            code: 6727,
            value: 'xa_bac_son',
            division_type: 'xã',
            short_codename: 'bac_son',
            parentId: 194,
          },
          {
            title: 'Xã Hải Đông',
            code: 6730,
            value: 'xa_hai_dong',
            division_type: 'xã',
            short_codename: 'hai_dong',
            parentId: 194,
          },
          {
            title: 'Xã Hải Tiến',
            code: 6733,
            value: 'xa_hai_tien',
            division_type: 'xã',
            short_codename: 'hai_tien',
            parentId: 194,
          },
          {
            title: 'Phường Hải Yên',
            code: 6736,
            value: 'phuong_hai_yen',
            division_type: 'phường',
            short_codename: 'hai_yen',
            parentId: 194,
          },
          {
            title: 'Xã Quảng Nghĩa',
            code: 6739,
            value: 'xa_quang_nghia',
            division_type: 'xã',
            short_codename: 'quang_nghia',
            parentId: 194,
          },
          {
            title: 'Phường Hải Hoà',
            code: 6742,
            value: 'phuong_hai_hoa',
            division_type: 'phường',
            short_codename: 'hai_hoa',
            parentId: 194,
          },
          {
            title: 'Xã Hải Xuân',
            code: 6745,
            value: 'xa_hai_xuan',
            division_type: 'xã',
            short_codename: 'hai_xuan',
            parentId: 194,
          },
          {
            title: 'Xã Vạn Ninh',
            code: 6748,
            value: 'xa_van_ninh',
            division_type: 'xã',
            short_codename: 'van_ninh',
            parentId: 194,
          },
          {
            title: 'Phường Bình Ngọc',
            code: 6751,
            value: 'phuong_binh_ngoc',
            division_type: 'phường',
            short_codename: 'binh_ngoc',
            parentId: 194,
          },
          {
            title: 'Xã Vĩnh Trung',
            code: 6754,
            value: 'xa_vinh_trung',
            division_type: 'xã',
            short_codename: 'vinh_trung',
            parentId: 194,
          },
          {
            title: 'Xã Vĩnh Thực',
            code: 6757,
            value: 'xa_vinh_thuc',
            division_type: 'xã',
            short_codename: 'vinh_thuc',
            parentId: 194,
          },
        ],
        parentId: 22,
      },
      {
        title: 'Thành phố Cẩm Phả',
        code: 195,
        value: 'thanh_pho_cam_pha',
        division_type: 'thành phố',
        short_codename: 'cam_pha',
        children: [
          {
            title: 'Phường Mông Dương',
            code: 6760,
            value: 'phuong_mong_duong',
            division_type: 'phường',
            short_codename: 'mong_duong',
            parentId: 195,
          },
          {
            title: 'Phường Cửa Ông',
            code: 6763,
            value: 'phuong_cua_ong',
            division_type: 'phường',
            short_codename: 'cua_ong',
            parentId: 195,
          },
          {
            title: 'Phường Cẩm Sơn',
            code: 6766,
            value: 'phuong_cam_son',
            division_type: 'phường',
            short_codename: 'cam_son',
            parentId: 195,
          },
          {
            title: 'Phường Cẩm Đông',
            code: 6769,
            value: 'phuong_cam_dong',
            division_type: 'phường',
            short_codename: 'cam_dong',
            parentId: 195,
          },
          {
            title: 'Phường Cẩm Phú',
            code: 6772,
            value: 'phuong_cam_phu',
            division_type: 'phường',
            short_codename: 'cam_phu',
            parentId: 195,
          },
          {
            title: 'Phường Cẩm Tây',
            code: 6775,
            value: 'phuong_cam_tay',
            division_type: 'phường',
            short_codename: 'cam_tay',
            parentId: 195,
          },
          {
            title: 'Phường Quang Hanh',
            code: 6778,
            value: 'phuong_quang_hanh',
            division_type: 'phường',
            short_codename: 'quang_hanh',
            parentId: 195,
          },
          {
            title: 'Phường Cẩm Thịnh',
            code: 6781,
            value: 'phuong_cam_thinh',
            division_type: 'phường',
            short_codename: 'cam_thinh',
            parentId: 195,
          },
          {
            title: 'Phường Cẩm Thủy',
            code: 6784,
            value: 'phuong_cam_thuy',
            division_type: 'phường',
            short_codename: 'cam_thuy',
            parentId: 195,
          },
          {
            title: 'Phường Cẩm Thạch',
            code: 6787,
            value: 'phuong_cam_thach',
            division_type: 'phường',
            short_codename: 'cam_thach',
            parentId: 195,
          },
          {
            title: 'Phường Cẩm Thành',
            code: 6790,
            value: 'phuong_cam_thanh',
            division_type: 'phường',
            short_codename: 'cam_thanh',
            parentId: 195,
          },
          {
            title: 'Phường Cẩm Trung',
            code: 6793,
            value: 'phuong_cam_trung',
            division_type: 'phường',
            short_codename: 'cam_trung',
            parentId: 195,
          },
          {
            title: 'Phường Cẩm Bình',
            code: 6796,
            value: 'phuong_cam_binh',
            division_type: 'phường',
            short_codename: 'cam_binh',
            parentId: 195,
          },
          {
            title: 'Xã Cộng Hòa',
            code: 6799,
            value: 'xa_cong_hoa',
            division_type: 'xã',
            short_codename: 'cong_hoa',
            parentId: 195,
          },
          {
            title: 'Xã Cẩm Hải',
            code: 6802,
            value: 'xa_cam_hai',
            division_type: 'xã',
            short_codename: 'cam_hai',
            parentId: 195,
          },
          {
            title: 'Xã Dương Huy',
            code: 6805,
            value: 'xa_duong_huy',
            division_type: 'xã',
            short_codename: 'duong_huy',
            parentId: 195,
          },
        ],
        parentId: 22,
      },
      {
        title: 'Thành phố Uông Bí',
        code: 196,
        value: 'thanh_pho_uong_bi',
        division_type: 'thành phố',
        short_codename: 'uong_bi',
        children: [
          {
            title: 'Phường Vàng Danh',
            code: 6808,
            value: 'phuong_vang_danh',
            division_type: 'phường',
            short_codename: 'vang_danh',
            parentId: 196,
          },
          {
            title: 'Phường Thanh Sơn',
            code: 6811,
            value: 'phuong_thanh_son',
            division_type: 'phường',
            short_codename: 'thanh_son',
            parentId: 196,
          },
          {
            title: 'Phường Bắc Sơn',
            code: 6814,
            value: 'phuong_bac_son',
            division_type: 'phường',
            short_codename: 'bac_son',
            parentId: 196,
          },
          {
            title: 'Phường Quang Trung',
            code: 6817,
            value: 'phuong_quang_trung',
            division_type: 'phường',
            short_codename: 'quang_trung',
            parentId: 196,
          },
          {
            title: 'Phường Trưng Vương',
            code: 6820,
            value: 'phuong_trung_vuong',
            division_type: 'phường',
            short_codename: 'trung_vuong',
            parentId: 196,
          },
          {
            title: 'Phường Nam Khê',
            code: 6823,
            value: 'phuong_nam_khe',
            division_type: 'phường',
            short_codename: 'nam_khe',
            parentId: 196,
          },
          {
            title: 'Phường Yên Thanh',
            code: 6826,
            value: 'phuong_yen_thanh',
            division_type: 'phường',
            short_codename: 'yen_thanh',
            parentId: 196,
          },
          {
            title: 'Xã Thượng Yên Công',
            code: 6829,
            value: 'xa_thuong_yen_cong',
            division_type: 'xã',
            short_codename: 'thuong_yen_cong',
            parentId: 196,
          },
          {
            title: 'Phường Phương Đông',
            code: 6832,
            value: 'phuong_phuong_dong',
            division_type: 'phường',
            short_codename: 'phuong_dong',
            parentId: 196,
          },
          {
            title: 'Phường Phương Nam',
            code: 6835,
            value: 'phuong_phuong_nam',
            division_type: 'phường',
            short_codename: 'phuong_nam',
            parentId: 196,
          },
        ],
        parentId: 22,
      },
      {
        title: 'Huyện Bình Liêu',
        code: 198,
        value: 'huyen_binh_lieu',
        division_type: 'huyện',
        short_codename: 'binh_lieu',
        children: [
          {
            title: 'Thị trấn Bình Liêu',
            code: 6838,
            value: 'thi_tran_binh_lieu',
            division_type: 'thị trấn',
            short_codename: 'binh_lieu',
            parentId: 198,
          },
          {
            title: 'Xã Hoành Mô',
            code: 6841,
            value: 'xa_hoanh_mo',
            division_type: 'xã',
            short_codename: 'hoanh_mo',
            parentId: 198,
          },
          {
            title: 'Xã Đồng Tâm',
            code: 6844,
            value: 'xa_dong_tam',
            division_type: 'xã',
            short_codename: 'dong_tam',
            parentId: 198,
          },
          {
            title: 'Xã Đồng Văn',
            code: 6847,
            value: 'xa_dong_van',
            division_type: 'xã',
            short_codename: 'dong_van',
            parentId: 198,
          },
          {
            title: 'Xã Vô Ngại',
            code: 6853,
            value: 'xa_vo_ngai',
            division_type: 'xã',
            short_codename: 'vo_ngai',
            parentId: 198,
          },
          {
            title: 'Xã Lục Hồn',
            code: 6856,
            value: 'xa_luc_hon',
            division_type: 'xã',
            short_codename: 'luc_hon',
            parentId: 198,
          },
          {
            title: 'Xã Húc Động',
            code: 6859,
            value: 'xa_huc_dong',
            division_type: 'xã',
            short_codename: 'huc_dong',
            parentId: 198,
          },
        ],
        parentId: 22,
      },
      {
        title: 'Huyện Tiên Yên',
        code: 199,
        value: 'huyen_tien_yen',
        division_type: 'huyện',
        short_codename: 'tien_yen',
        children: [
          {
            title: 'Thị trấn Tiên Yên',
            code: 6862,
            value: 'thi_tran_tien_yen',
            division_type: 'thị trấn',
            short_codename: 'tien_yen',
            parentId: 199,
          },
          {
            title: 'Xã Hà Lâu',
            code: 6865,
            value: 'xa_ha_lau',
            division_type: 'xã',
            short_codename: 'ha_lau',
            parentId: 199,
          },
          {
            title: 'Xã Đại Dực',
            code: 6868,
            value: 'xa_dai_duc',
            division_type: 'xã',
            short_codename: 'dai_duc',
            parentId: 199,
          },
          {
            title: 'Xã Phong Dụ',
            code: 6871,
            value: 'xa_phong_du',
            division_type: 'xã',
            short_codename: 'phong_du',
            parentId: 199,
          },
          {
            title: 'Xã Điền Xá',
            code: 6874,
            value: 'xa_dien_xa',
            division_type: 'xã',
            short_codename: 'dien_xa',
            parentId: 199,
          },
          {
            title: 'Xã Đông Ngũ',
            code: 6877,
            value: 'xa_dong_ngu',
            division_type: 'xã',
            short_codename: 'dong_ngu',
            parentId: 199,
          },
          {
            title: 'Xã Yên Than',
            code: 6880,
            value: 'xa_yen_than',
            division_type: 'xã',
            short_codename: 'yen_than',
            parentId: 199,
          },
          {
            title: 'Xã Đông Hải',
            code: 6883,
            value: 'xa_dong_hai',
            division_type: 'xã',
            short_codename: 'dong_hai',
            parentId: 199,
          },
          {
            title: 'Xã Hải Lạng',
            code: 6886,
            value: 'xa_hai_lang',
            division_type: 'xã',
            short_codename: 'hai_lang',
            parentId: 199,
          },
          {
            title: 'Xã Tiên Lãng',
            code: 6889,
            value: 'xa_tien_lang',
            division_type: 'xã',
            short_codename: 'tien_lang',
            parentId: 199,
          },
          {
            title: 'Xã Đồng Rui',
            code: 6892,
            value: 'xa_dong_rui',
            division_type: 'xã',
            short_codename: 'dong_rui',
            parentId: 199,
          },
        ],
        parentId: 22,
      },
      {
        title: 'Huyện Đầm Hà',
        code: 200,
        value: 'huyen_dam_ha',
        division_type: 'huyện',
        short_codename: 'dam_ha',
        children: [
          {
            title: 'Thị trấn Đầm Hà',
            code: 6895,
            value: 'thi_tran_dam_ha',
            division_type: 'thị trấn',
            short_codename: 'dam_ha',
            parentId: 200,
          },
          {
            title: 'Xã Quảng Lâm',
            code: 6898,
            value: 'xa_quang_lam',
            division_type: 'xã',
            short_codename: 'quang_lam',
            parentId: 200,
          },
          {
            title: 'Xã Quảng An',
            code: 6901,
            value: 'xa_quang_an',
            division_type: 'xã',
            short_codename: 'quang_an',
            parentId: 200,
          },
          {
            title: 'Xã Tân Bình',
            code: 6904,
            value: 'xa_tan_binh',
            division_type: 'xã',
            short_codename: 'tan_binh',
            parentId: 200,
          },
          {
            title: 'Xã Dực Yên',
            code: 6910,
            value: 'xa_duc_yen',
            division_type: 'xã',
            short_codename: 'duc_yen',
            parentId: 200,
          },
          {
            title: 'Xã Quảng Tân',
            code: 6913,
            value: 'xa_quang_tan',
            division_type: 'xã',
            short_codename: 'quang_tan',
            parentId: 200,
          },
          {
            title: 'Xã Đầm Hà',
            code: 6916,
            value: 'xa_dam_ha',
            division_type: 'xã',
            short_codename: 'xa_dam_ha',
            parentId: 200,
          },
          {
            title: 'Xã Tân Lập',
            code: 6917,
            value: 'xa_tan_lap',
            division_type: 'xã',
            short_codename: 'tan_lap',
            parentId: 200,
          },
          {
            title: 'Xã Đại Bình',
            code: 6919,
            value: 'xa_dai_binh',
            division_type: 'xã',
            short_codename: 'dai_binh',
            parentId: 200,
          },
        ],
        parentId: 22,
      },
      {
        title: 'Huyện Hải Hà',
        code: 201,
        value: 'huyen_hai_ha',
        division_type: 'huyện',
        short_codename: 'hai_ha',
        children: [
          {
            title: 'Thị trấn Quảng Hà',
            code: 6922,
            value: 'thi_tran_quang_ha',
            division_type: 'thị trấn',
            short_codename: 'quang_ha',
            parentId: 201,
          },
          {
            title: 'Xã Quảng Đức',
            code: 6925,
            value: 'xa_quang_duc',
            division_type: 'xã',
            short_codename: 'quang_duc',
            parentId: 201,
          },
          {
            title: 'Xã Quảng Sơn',
            code: 6928,
            value: 'xa_quang_son',
            division_type: 'xã',
            short_codename: 'quang_son',
            parentId: 201,
          },
          {
            title: 'Xã Quảng Thành',
            code: 6931,
            value: 'xa_quang_thanh',
            division_type: 'xã',
            short_codename: 'quang_thanh',
            parentId: 201,
          },
          {
            title: 'Xã Quảng Thịnh',
            code: 6937,
            value: 'xa_quang_thinh',
            division_type: 'xã',
            short_codename: 'quang_thinh',
            parentId: 201,
          },
          {
            title: 'Xã Quảng Minh',
            code: 6940,
            value: 'xa_quang_minh',
            division_type: 'xã',
            short_codename: 'quang_minh',
            parentId: 201,
          },
          {
            title: 'Xã Quảng Chính',
            code: 6943,
            value: 'xa_quang_chinh',
            division_type: 'xã',
            short_codename: 'quang_chinh',
            parentId: 201,
          },
          {
            title: 'Xã Quảng Long',
            code: 6946,
            value: 'xa_quang_long',
            division_type: 'xã',
            short_codename: 'quang_long',
            parentId: 201,
          },
          {
            title: 'Xã Đường Hoa',
            code: 6949,
            value: 'xa_duong_hoa',
            division_type: 'xã',
            short_codename: 'duong_hoa',
            parentId: 201,
          },
          {
            title: 'Xã Quảng Phong',
            code: 6952,
            value: 'xa_quang_phong',
            division_type: 'xã',
            short_codename: 'quang_phong',
            parentId: 201,
          },
          {
            title: 'Xã Cái Chiên',
            code: 6967,
            value: 'xa_cai_chien',
            division_type: 'xã',
            short_codename: 'cai_chien',
            parentId: 201,
          },
        ],
        parentId: 22,
      },
      {
        title: 'Huyện Ba Chẽ',
        code: 202,
        value: 'huyen_ba_che',
        division_type: 'huyện',
        short_codename: 'ba_che',
        children: [
          {
            title: 'Thị trấn Ba Chẽ',
            code: 6970,
            value: 'thi_tran_ba_che',
            division_type: 'thị trấn',
            short_codename: 'ba_che',
            parentId: 202,
          },
          {
            title: 'Xã Thanh Sơn',
            code: 6973,
            value: 'xa_thanh_son',
            division_type: 'xã',
            short_codename: 'thanh_son',
            parentId: 202,
          },
          {
            title: 'Xã Thanh Lâm',
            code: 6976,
            value: 'xa_thanh_lam',
            division_type: 'xã',
            short_codename: 'thanh_lam',
            parentId: 202,
          },
          {
            title: 'Xã Đạp Thanh',
            code: 6979,
            value: 'xa_dap_thanh',
            division_type: 'xã',
            short_codename: 'dap_thanh',
            parentId: 202,
          },
          {
            title: 'Xã Nam Sơn',
            code: 6982,
            value: 'xa_nam_son',
            division_type: 'xã',
            short_codename: 'nam_son',
            parentId: 202,
          },
          {
            title: 'Xã Lương Mông',
            code: 6985,
            value: 'xa_luong_mong',
            division_type: 'xã',
            short_codename: 'luong_mong',
            parentId: 202,
          },
          {
            title: 'Xã Đồn Đạc',
            code: 6988,
            value: 'xa_don_dac',
            division_type: 'xã',
            short_codename: 'don_dac',
            parentId: 202,
          },
          {
            title: 'Xã Minh Cầm',
            code: 6991,
            value: 'xa_minh_cam',
            division_type: 'xã',
            short_codename: 'minh_cam',
            parentId: 202,
          },
        ],
        parentId: 22,
      },
      {
        title: 'Huyện Vân Đồn',
        code: 203,
        value: 'huyen_van_don',
        division_type: 'huyện',
        short_codename: 'van_don',
        children: [
          {
            title: 'Thị trấn Cái Rồng',
            code: 6994,
            value: 'thi_tran_cai_rong',
            division_type: 'thị trấn',
            short_codename: 'cai_rong',
            parentId: 203,
          },
          {
            title: 'Xã Đài Xuyên',
            code: 6997,
            value: 'xa_dai_xuyen',
            division_type: 'xã',
            short_codename: 'dai_xuyen',
            parentId: 203,
          },
          {
            title: 'Xã Bình Dân',
            code: 7000,
            value: 'xa_binh_dan',
            division_type: 'xã',
            short_codename: 'binh_dan',
            parentId: 203,
          },
          {
            title: 'Xã Vạn Yên',
            code: 7003,
            value: 'xa_van_yen',
            division_type: 'xã',
            short_codename: 'van_yen',
            parentId: 203,
          },
          {
            title: 'Xã Minh Châu',
            code: 7006,
            value: 'xa_minh_chau',
            division_type: 'xã',
            short_codename: 'minh_chau',
            parentId: 203,
          },
          {
            title: 'Xã Đoàn Kết',
            code: 7009,
            value: 'xa_doan_ket',
            division_type: 'xã',
            short_codename: 'doan_ket',
            parentId: 203,
          },
          {
            title: 'Xã Hạ Long',
            code: 7012,
            value: 'xa_ha_long',
            division_type: 'xã',
            short_codename: 'ha_long',
            parentId: 203,
          },
          {
            title: 'Xã Đông Xá',
            code: 7015,
            value: 'xa_dong_xa',
            division_type: 'xã',
            short_codename: 'dong_xa',
            parentId: 203,
          },
          {
            title: 'Xã Bản Sen',
            code: 7018,
            value: 'xa_ban_sen',
            division_type: 'xã',
            short_codename: 'ban_sen',
            parentId: 203,
          },
          {
            title: 'Xã Thắng Lợi',
            code: 7021,
            value: 'xa_thang_loi',
            division_type: 'xã',
            short_codename: 'thang_loi',
            parentId: 203,
          },
          {
            title: 'Xã Quan Lạn',
            code: 7024,
            value: 'xa_quan_lan',
            division_type: 'xã',
            short_codename: 'quan_lan',
            parentId: 203,
          },
          {
            title: 'Xã Ngọc Vừng',
            code: 7027,
            value: 'xa_ngoc_vung',
            division_type: 'xã',
            short_codename: 'ngoc_vung',
            parentId: 203,
          },
        ],
        parentId: 22,
      },
      {
        title: 'Thị xã Đông Triều',
        code: 205,
        value: 'thi_xa_dong_trieu',
        division_type: 'thị xã',
        short_codename: 'dong_trieu',
        children: [
          {
            title: 'Phường Mạo Khê',
            code: 7069,
            value: 'phuong_mao_khe',
            division_type: 'phường',
            short_codename: 'mao_khe',
            parentId: 205,
          },
          {
            title: 'Phường Đông Triều',
            code: 7072,
            value: 'phuong_dong_trieu',
            division_type: 'phường',
            short_codename: 'dong_trieu',
            parentId: 205,
          },
          {
            title: 'Xã An Sinh',
            code: 7075,
            value: 'xa_an_sinh',
            division_type: 'xã',
            short_codename: 'an_sinh',
            parentId: 205,
          },
          {
            title: 'Xã Tràng Lương',
            code: 7078,
            value: 'xa_trang_luong',
            division_type: 'xã',
            short_codename: 'trang_luong',
            parentId: 205,
          },
          {
            title: 'Xã Bình Khê',
            code: 7081,
            value: 'xa_binh_khe',
            division_type: 'xã',
            short_codename: 'binh_khe',
            parentId: 205,
          },
          {
            title: 'Xã Việt Dân',
            code: 7084,
            value: 'xa_viet_dan',
            division_type: 'xã',
            short_codename: 'viet_dan',
            parentId: 205,
          },
          {
            title: 'Xã Tân Việt',
            code: 7087,
            value: 'xa_tan_viet',
            division_type: 'xã',
            short_codename: 'tan_viet',
            parentId: 205,
          },
          {
            title: 'Xã Bình Dương',
            code: 7090,
            value: 'xa_binh_duong',
            division_type: 'xã',
            short_codename: 'binh_duong',
            parentId: 205,
          },
          {
            title: 'Phường Đức Chính',
            code: 7093,
            value: 'phuong_duc_chinh',
            division_type: 'phường',
            short_codename: 'duc_chinh',
            parentId: 205,
          },
          {
            title: 'Phường Tràng An',
            code: 7096,
            value: 'phuong_trang_an',
            division_type: 'phường',
            short_codename: 'trang_an',
            parentId: 205,
          },
          {
            title: 'Xã Nguyễn Huệ',
            code: 7099,
            value: 'xa_nguyen_hue',
            division_type: 'xã',
            short_codename: 'nguyen_hue',
            parentId: 205,
          },
          {
            title: 'Xã Thủy An',
            code: 7102,
            value: 'xa_thuy_an',
            division_type: 'xã',
            short_codename: 'thuy_an',
            parentId: 205,
          },
          {
            title: 'Phường Xuân Sơn',
            code: 7105,
            value: 'phuong_xuan_son',
            division_type: 'phường',
            short_codename: 'xuan_son',
            parentId: 205,
          },
          {
            title: 'Xã Hồng Thái Tây',
            code: 7108,
            value: 'xa_hong_thai_tay',
            division_type: 'xã',
            short_codename: 'hong_thai_tay',
            parentId: 205,
          },
          {
            title: 'Xã Hồng Thái Đông',
            code: 7111,
            value: 'xa_hong_thai_dong',
            division_type: 'xã',
            short_codename: 'hong_thai_dong',
            parentId: 205,
          },
          {
            title: 'Phường Hoàng Quế',
            code: 7114,
            value: 'phuong_hoang_que',
            division_type: 'phường',
            short_codename: 'hoang_que',
            parentId: 205,
          },
          {
            title: 'Phường Yên Thọ',
            code: 7117,
            value: 'phuong_yen_tho',
            division_type: 'phường',
            short_codename: 'yen_tho',
            parentId: 205,
          },
          {
            title: 'Phường Hồng Phong',
            code: 7120,
            value: 'phuong_hong_phong',
            division_type: 'phường',
            short_codename: 'hong_phong',
            parentId: 205,
          },
          {
            title: 'Phường Kim Sơn',
            code: 7123,
            value: 'phuong_kim_son',
            division_type: 'phường',
            short_codename: 'kim_son',
            parentId: 205,
          },
          {
            title: 'Phường Hưng Đạo',
            code: 7126,
            value: 'phuong_hung_dao',
            division_type: 'phường',
            short_codename: 'hung_dao',
            parentId: 205,
          },
          {
            title: 'Xã Yên Đức',
            code: 7129,
            value: 'xa_yen_duc',
            division_type: 'xã',
            short_codename: 'yen_duc',
            parentId: 205,
          },
        ],
        parentId: 22,
      },
      {
        title: 'Thị xã Quảng Yên',
        code: 206,
        value: 'thi_xa_quang_yen',
        division_type: 'thị xã',
        short_codename: 'quang_yen',
        children: [
          {
            title: 'Phường Quảng Yên',
            code: 7132,
            value: 'phuong_quang_yen',
            division_type: 'phường',
            short_codename: 'quang_yen',
            parentId: 206,
          },
          {
            title: 'Phường Đông Mai',
            code: 7135,
            value: 'phuong_dong_mai',
            division_type: 'phường',
            short_codename: 'dong_mai',
            parentId: 206,
          },
          {
            title: 'Phường Minh Thành',
            code: 7138,
            value: 'phuong_minh_thanh',
            division_type: 'phường',
            short_codename: 'minh_thanh',
            parentId: 206,
          },
          {
            title: 'Xã Sông Khoai',
            code: 7144,
            value: 'xa_song_khoai',
            division_type: 'xã',
            short_codename: 'song_khoai',
            parentId: 206,
          },
          {
            title: 'Xã Hiệp Hòa',
            code: 7147,
            value: 'xa_hiep_hoa',
            division_type: 'xã',
            short_codename: 'hiep_hoa',
            parentId: 206,
          },
          {
            title: 'Phường Cộng Hòa',
            code: 7150,
            value: 'phuong_cong_hoa',
            division_type: 'phường',
            short_codename: 'cong_hoa',
            parentId: 206,
          },
          {
            title: 'Xã Tiền An',
            code: 7153,
            value: 'xa_tien_an',
            division_type: 'xã',
            short_codename: 'tien_an',
            parentId: 206,
          },
          {
            title: 'Xã Hoàng Tân',
            code: 7156,
            value: 'xa_hoang_tan',
            division_type: 'xã',
            short_codename: 'hoang_tan',
            parentId: 206,
          },
          {
            title: 'Phường Tân An',
            code: 7159,
            value: 'phuong_tan_an',
            division_type: 'phường',
            short_codename: 'tan_an',
            parentId: 206,
          },
          {
            title: 'Phường Yên Giang',
            code: 7162,
            value: 'phuong_yen_giang',
            division_type: 'phường',
            short_codename: 'yen_giang',
            parentId: 206,
          },
          {
            title: 'Phường Nam Hoà',
            code: 7165,
            value: 'phuong_nam_hoa',
            division_type: 'phường',
            short_codename: 'nam_hoa',
            parentId: 206,
          },
          {
            title: 'Phường Hà An',
            code: 7168,
            value: 'phuong_ha_an',
            division_type: 'phường',
            short_codename: 'ha_an',
            parentId: 206,
          },
          {
            title: 'Xã Cẩm La',
            code: 7171,
            value: 'xa_cam_la',
            division_type: 'xã',
            short_codename: 'cam_la',
            parentId: 206,
          },
          {
            title: 'Phường Phong Hải',
            code: 7174,
            value: 'phuong_phong_hai',
            division_type: 'phường',
            short_codename: 'phong_hai',
            parentId: 206,
          },
          {
            title: 'Phường Yên Hải',
            code: 7177,
            value: 'phuong_yen_hai',
            division_type: 'phường',
            short_codename: 'yen_hai',
            parentId: 206,
          },
          {
            title: 'Xã Liên Hòa',
            code: 7180,
            value: 'xa_lien_hoa',
            division_type: 'xã',
            short_codename: 'lien_hoa',
            parentId: 206,
          },
          {
            title: 'Phường Phong Cốc',
            code: 7183,
            value: 'phuong_phong_coc',
            division_type: 'phường',
            short_codename: 'phong_coc',
            parentId: 206,
          },
          {
            title: 'Xã Liên Vị',
            code: 7186,
            value: 'xa_lien_vi',
            division_type: 'xã',
            short_codename: 'lien_vi',
            parentId: 206,
          },
          {
            title: 'Xã Tiền Phong',
            code: 7189,
            value: 'xa_tien_phong',
            division_type: 'xã',
            short_codename: 'tien_phong',
            parentId: 206,
          },
        ],
        parentId: 22,
      },
      {
        title: 'Huyện Cô Tô',
        code: 207,
        value: 'huyen_co_to',
        division_type: 'huyện',
        short_codename: 'co_to',
        children: [
          {
            title: 'Thị trấn Cô Tô',
            code: 7192,
            value: 'thi_tran_co_to',
            division_type: 'thị trấn',
            short_codename: 'co_to',
            parentId: 207,
          },
          {
            title: 'Xã Đồng Tiến',
            code: 7195,
            value: 'xa_dong_tien',
            division_type: 'xã',
            short_codename: 'dong_tien',
            parentId: 207,
          },
          {
            title: 'Xã Thanh Lân',
            code: 7198,
            value: 'xa_thanh_lan',
            division_type: 'xã',
            short_codename: 'thanh_lan',
            parentId: 207,
          },
        ],
        parentId: 22,
      },
    ],
  },
  {
    title: 'Tỉnh Bắc Giang',
    code: 24,
    value: 'tinh_bac_giang',
    division_type: 'tỉnh',
    phone_code: 204,
    children: [
      {
        title: 'Thành phố Bắc Giang',
        code: 213,
        value: 'thanh_pho_bac_giang',
        division_type: 'thành phố',
        short_codename: 'bac_giang',
        children: [
          {
            title: 'Phường Thọ Xương',
            code: 7201,
            value: 'phuong_tho_xuong',
            division_type: 'phường',
            short_codename: 'tho_xuong',
            parentId: 213,
          },
          {
            title: 'Phường Trần Nguyên Hãn',
            code: 7204,
            value: 'phuong_tran_nguyen_han',
            division_type: 'phường',
            short_codename: 'tran_nguyen_han',
            parentId: 213,
          },
          {
            title: 'Phường Ngô Quyền',
            code: 7207,
            value: 'phuong_ngo_quyen',
            division_type: 'phường',
            short_codename: 'ngo_quyen',
            parentId: 213,
          },
          {
            title: 'Phường Hoàng Văn Thụ',
            code: 7210,
            value: 'phuong_hoang_van_thu',
            division_type: 'phường',
            short_codename: 'hoang_van_thu',
            parentId: 213,
          },
          {
            title: 'Phường Trần Phú',
            code: 7213,
            value: 'phuong_tran_phu',
            division_type: 'phường',
            short_codename: 'tran_phu',
            parentId: 213,
          },
          {
            title: 'Phường Mỹ Độ',
            code: 7216,
            value: 'phuong_my_do',
            division_type: 'phường',
            short_codename: 'my_do',
            parentId: 213,
          },
          {
            title: 'Phường Lê Lợi',
            code: 7219,
            value: 'phuong_le_loi',
            division_type: 'phường',
            short_codename: 'le_loi',
            parentId: 213,
          },
          {
            title: 'Xã Song Mai',
            code: 7222,
            value: 'xa_song_mai',
            division_type: 'xã',
            short_codename: 'song_mai',
            parentId: 213,
          },
          {
            title: 'Phường Xương Giang',
            code: 7225,
            value: 'phuong_xuong_giang',
            division_type: 'phường',
            short_codename: 'xuong_giang',
            parentId: 213,
          },
          {
            title: 'Phường Đa Mai',
            code: 7228,
            value: 'phuong_da_mai',
            division_type: 'phường',
            short_codename: 'da_mai',
            parentId: 213,
          },
          {
            title: 'Phường Dĩnh Kế',
            code: 7231,
            value: 'phuong_dinh_ke',
            division_type: 'phường',
            short_codename: 'dinh_ke',
            parentId: 213,
          },
          {
            title: 'Xã Dĩnh Trì',
            code: 7441,
            value: 'xa_dinh_tri',
            division_type: 'xã',
            short_codename: 'dinh_tri',
            parentId: 213,
          },
          {
            title: 'Xã Tân Mỹ',
            code: 7687,
            value: 'xa_tan_my',
            division_type: 'xã',
            short_codename: 'tan_my',
            parentId: 213,
          },
          {
            title: 'Xã Đồng Sơn',
            code: 7696,
            value: 'xa_dong_son',
            division_type: 'xã',
            short_codename: 'dong_son',
            parentId: 213,
          },
          {
            title: 'Xã Tân Tiến',
            code: 7699,
            value: 'xa_tan_tien',
            division_type: 'xã',
            short_codename: 'tan_tien',
            parentId: 213,
          },
          {
            title: 'Xã Song Khê',
            code: 7705,
            value: 'xa_song_khe',
            division_type: 'xã',
            short_codename: 'song_khe',
            parentId: 213,
          },
        ],
        parentId: 24,
      },
      {
        title: 'Huyện Yên Thế',
        code: 215,
        value: 'huyen_yen_the',
        division_type: 'huyện',
        short_codename: 'yen_the',
        children: [
          {
            title: 'Xã Đồng Tiến',
            code: 7243,
            value: 'xa_dong_tien',
            division_type: 'xã',
            short_codename: 'dong_tien',
            parentId: 215,
          },
          {
            title: 'Xã Canh Nậu',
            code: 7246,
            value: 'xa_canh_nau',
            division_type: 'xã',
            short_codename: 'canh_nau',
            parentId: 215,
          },
          {
            title: 'Xã Xuân Lương',
            code: 7249,
            value: 'xa_xuan_luong',
            division_type: 'xã',
            short_codename: 'xuan_luong',
            parentId: 215,
          },
          {
            title: 'Xã Tam Tiến',
            code: 7252,
            value: 'xa_tam_tien',
            division_type: 'xã',
            short_codename: 'tam_tien',
            parentId: 215,
          },
          {
            title: 'Xã Đồng Vương',
            code: 7255,
            value: 'xa_dong_vuong',
            division_type: 'xã',
            short_codename: 'dong_vuong',
            parentId: 215,
          },
          {
            title: 'Xã Đồng Hưu',
            code: 7258,
            value: 'xa_dong_huu',
            division_type: 'xã',
            short_codename: 'dong_huu',
            parentId: 215,
          },
          {
            title: 'Xã Đồng Tâm',
            code: 7260,
            value: 'xa_dong_tam',
            division_type: 'xã',
            short_codename: 'dong_tam',
            parentId: 215,
          },
          {
            title: 'Xã Tam Hiệp',
            code: 7261,
            value: 'xa_tam_hiep',
            division_type: 'xã',
            short_codename: 'tam_hiep',
            parentId: 215,
          },
          {
            title: 'Xã Tiến Thắng',
            code: 7264,
            value: 'xa_tien_thang',
            division_type: 'xã',
            short_codename: 'tien_thang',
            parentId: 215,
          },
          {
            title: 'Xã Hồng Kỳ',
            code: 7267,
            value: 'xa_hong_ky',
            division_type: 'xã',
            short_codename: 'hong_ky',
            parentId: 215,
          },
          {
            title: 'Xã Đồng Lạc',
            code: 7270,
            value: 'xa_dong_lac',
            division_type: 'xã',
            short_codename: 'dong_lac',
            parentId: 215,
          },
          {
            title: 'Xã Đông Sơn',
            code: 7273,
            value: 'xa_dong_son',
            division_type: 'xã',
            short_codename: 'dong_son',
            parentId: 215,
          },
          {
            title: 'Xã Tân Hiệp',
            code: 7276,
            value: 'xa_tan_hiep',
            division_type: 'xã',
            short_codename: 'tan_hiep',
            parentId: 215,
          },
          {
            title: 'Xã Hương Vĩ',
            code: 7279,
            value: 'xa_huong_vi',
            division_type: 'xã',
            short_codename: 'huong_vi',
            parentId: 215,
          },
          {
            title: 'Xã Đồng Kỳ',
            code: 7282,
            value: 'xa_dong_ky',
            division_type: 'xã',
            short_codename: 'dong_ky',
            parentId: 215,
          },
          {
            title: 'Xã An Thượng',
            code: 7285,
            value: 'xa_an_thuong',
            division_type: 'xã',
            short_codename: 'an_thuong',
            parentId: 215,
          },
          {
            title: 'Thị trấn Phồn Xương',
            code: 7288,
            value: 'thi_tran_phon_xuong',
            division_type: 'thị trấn',
            short_codename: 'phon_xuong',
            parentId: 215,
          },
          {
            title: 'Xã Tân Sỏi',
            code: 7291,
            value: 'xa_tan_soi',
            division_type: 'xã',
            short_codename: 'tan_soi',
            parentId: 215,
          },
          {
            title: 'Thị trấn Bố Hạ',
            code: 7294,
            value: 'thi_tran_bo_ha',
            division_type: 'thị trấn',
            short_codename: 'bo_ha',
            parentId: 215,
          },
        ],
        parentId: 24,
      },
      {
        title: 'Huyện Tân Yên',
        code: 216,
        value: 'huyen_tan_yen',
        division_type: 'huyện',
        short_codename: 'tan_yen',
        children: [
          {
            title: 'Xã Lan Giới',
            code: 7303,
            value: 'xa_lan_gioi',
            division_type: 'xã',
            short_codename: 'lan_gioi',
            parentId: 216,
          },
          {
            title: 'Thị trấn Nhã Nam',
            code: 7306,
            value: 'thi_tran_nha_nam',
            division_type: 'thị trấn',
            short_codename: 'nha_nam',
            parentId: 216,
          },
          {
            title: 'Xã Tân Trung',
            code: 7309,
            value: 'xa_tan_trung',
            division_type: 'xã',
            short_codename: 'tan_trung',
            parentId: 216,
          },
          {
            title: 'Xã Đại Hóa',
            code: 7312,
            value: 'xa_dai_hoa',
            division_type: 'xã',
            short_codename: 'dai_hoa',
            parentId: 216,
          },
          {
            title: 'Xã Quang Tiến',
            code: 7315,
            value: 'xa_quang_tien',
            division_type: 'xã',
            short_codename: 'quang_tien',
            parentId: 216,
          },
          {
            title: 'Xã Phúc Sơn',
            code: 7318,
            value: 'xa_phuc_son',
            division_type: 'xã',
            short_codename: 'phuc_son',
            parentId: 216,
          },
          {
            title: 'Xã An Dương',
            code: 7321,
            value: 'xa_an_duong',
            division_type: 'xã',
            short_codename: 'an_duong',
            parentId: 216,
          },
          {
            title: 'Xã Phúc Hòa',
            code: 7324,
            value: 'xa_phuc_hoa',
            division_type: 'xã',
            short_codename: 'phuc_hoa',
            parentId: 216,
          },
          {
            title: 'Xã Liên Sơn',
            code: 7327,
            value: 'xa_lien_son',
            division_type: 'xã',
            short_codename: 'lien_son',
            parentId: 216,
          },
          {
            title: 'Xã Hợp Đức',
            code: 7330,
            value: 'xa_hop_duc',
            division_type: 'xã',
            short_codename: 'hop_duc',
            parentId: 216,
          },
          {
            title: 'Xã Lam Cốt',
            code: 7333,
            value: 'xa_lam_cot',
            division_type: 'xã',
            short_codename: 'lam_cot',
            parentId: 216,
          },
          {
            title: 'Xã Cao Xá',
            code: 7336,
            value: 'xa_cao_xa',
            division_type: 'xã',
            short_codename: 'cao_xa',
            parentId: 216,
          },
          {
            title: 'Thị trấn Cao Thượng',
            code: 7339,
            value: 'thi_tran_cao_thuong',
            division_type: 'thị trấn',
            short_codename: 'cao_thuong',
            parentId: 216,
          },
          {
            title: 'Xã Việt Ngọc',
            code: 7342,
            value: 'xa_viet_ngoc',
            division_type: 'xã',
            short_codename: 'viet_ngoc',
            parentId: 216,
          },
          {
            title: 'Xã Song Vân',
            code: 7345,
            value: 'xa_song_van',
            division_type: 'xã',
            short_codename: 'song_van',
            parentId: 216,
          },
          {
            title: 'Xã Ngọc Châu',
            code: 7348,
            value: 'xa_ngoc_chau',
            division_type: 'xã',
            short_codename: 'ngoc_chau',
            parentId: 216,
          },
          {
            title: 'Xã Ngọc Vân',
            code: 7351,
            value: 'xa_ngoc_van',
            division_type: 'xã',
            short_codename: 'ngoc_van',
            parentId: 216,
          },
          {
            title: 'Xã Việt Lập',
            code: 7354,
            value: 'xa_viet_lap',
            division_type: 'xã',
            short_codename: 'viet_lap',
            parentId: 216,
          },
          {
            title: 'Xã Liên Chung',
            code: 7357,
            value: 'xa_lien_chung',
            division_type: 'xã',
            short_codename: 'lien_chung',
            parentId: 216,
          },
          {
            title: 'Xã Ngọc Thiện',
            code: 7360,
            value: 'xa_ngoc_thien',
            division_type: 'xã',
            short_codename: 'ngoc_thien',
            parentId: 216,
          },
          {
            title: 'Xã Ngọc Lý',
            code: 7363,
            value: 'xa_ngoc_ly',
            division_type: 'xã',
            short_codename: 'ngoc_ly',
            parentId: 216,
          },
          {
            title: 'Xã Quế Nham',
            code: 7366,
            value: 'xa_que_nham',
            division_type: 'xã',
            short_codename: 'que_nham',
            parentId: 216,
          },
        ],
        parentId: 24,
      },
      {
        title: 'Huyện Lạng Giang',
        code: 217,
        value: 'huyen_lang_giang',
        division_type: 'huyện',
        short_codename: 'lang_giang',
        children: [
          {
            title: 'Thị trấn Vôi',
            code: 7375,
            value: 'thi_tran_voi',
            division_type: 'thị trấn',
            short_codename: 'voi',
            parentId: 217,
          },
          {
            title: 'Xã Nghĩa Hòa',
            code: 7378,
            value: 'xa_nghia_hoa',
            division_type: 'xã',
            short_codename: 'nghia_hoa',
            parentId: 217,
          },
          {
            title: 'Xã Nghĩa Hưng',
            code: 7381,
            value: 'xa_nghia_hung',
            division_type: 'xã',
            short_codename: 'nghia_hung',
            parentId: 217,
          },
          {
            title: 'Xã Quang Thịnh',
            code: 7384,
            value: 'xa_quang_thinh',
            division_type: 'xã',
            short_codename: 'quang_thinh',
            parentId: 217,
          },
          {
            title: 'Xã Hương Sơn',
            code: 7387,
            value: 'xa_huong_son',
            division_type: 'xã',
            short_codename: 'huong_son',
            parentId: 217,
          },
          {
            title: 'Xã Đào Mỹ',
            code: 7390,
            value: 'xa_dao_my',
            division_type: 'xã',
            short_codename: 'dao_my',
            parentId: 217,
          },
          {
            title: 'Xã Tiên Lục',
            code: 7393,
            value: 'xa_tien_luc',
            division_type: 'xã',
            short_codename: 'tien_luc',
            parentId: 217,
          },
          {
            title: 'Xã An Hà',
            code: 7396,
            value: 'xa_an_ha',
            division_type: 'xã',
            short_codename: 'an_ha',
            parentId: 217,
          },
          {
            title: 'Thị trấn Kép',
            code: 7399,
            value: 'thi_tran_kep',
            division_type: 'thị trấn',
            short_codename: 'kep',
            parentId: 217,
          },
          {
            title: 'Xã Mỹ Hà',
            code: 7402,
            value: 'xa_my_ha',
            division_type: 'xã',
            short_codename: 'my_ha',
            parentId: 217,
          },
          {
            title: 'Xã Hương Lạc',
            code: 7405,
            value: 'xa_huong_lac',
            division_type: 'xã',
            short_codename: 'huong_lac',
            parentId: 217,
          },
          {
            title: 'Xã Dương Đức',
            code: 7408,
            value: 'xa_duong_duc',
            division_type: 'xã',
            short_codename: 'duong_duc',
            parentId: 217,
          },
          {
            title: 'Xã Tân Thanh',
            code: 7411,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 217,
          },
          {
            title: 'Xã Yên Mỹ',
            code: 7414,
            value: 'xa_yen_my',
            division_type: 'xã',
            short_codename: 'yen_my',
            parentId: 217,
          },
          {
            title: 'Xã Tân Hưng',
            code: 7417,
            value: 'xa_tan_hung',
            division_type: 'xã',
            short_codename: 'tan_hung',
            parentId: 217,
          },
          {
            title: 'Xã Mỹ Thái',
            code: 7420,
            value: 'xa_my_thai',
            division_type: 'xã',
            short_codename: 'my_thai',
            parentId: 217,
          },
          {
            title: 'Xã Xương Lâm',
            code: 7426,
            value: 'xa_xuong_lam',
            division_type: 'xã',
            short_codename: 'xuong_lam',
            parentId: 217,
          },
          {
            title: 'Xã Xuân Hương',
            code: 7429,
            value: 'xa_xuan_huong',
            division_type: 'xã',
            short_codename: 'xuan_huong',
            parentId: 217,
          },
          {
            title: 'Xã Tân Dĩnh',
            code: 7432,
            value: 'xa_tan_dinh',
            division_type: 'xã',
            short_codename: 'tan_dinh',
            parentId: 217,
          },
          {
            title: 'Xã Đại Lâm',
            code: 7435,
            value: 'xa_dai_lam',
            division_type: 'xã',
            short_codename: 'dai_lam',
            parentId: 217,
          },
          {
            title: 'Xã Thái Đào',
            code: 7438,
            value: 'xa_thai_dao',
            division_type: 'xã',
            short_codename: 'thai_dao',
            parentId: 217,
          },
        ],
        parentId: 24,
      },
      {
        title: 'Huyện Lục Nam',
        code: 218,
        value: 'huyen_luc_nam',
        division_type: 'huyện',
        short_codename: 'luc_nam',
        children: [
          {
            title: 'Thị trấn Đồi Ngô',
            code: 7444,
            value: 'thi_tran_doi_ngo',
            division_type: 'thị trấn',
            short_codename: 'doi_ngo',
            parentId: 218,
          },
          {
            title: 'Xã Đông Hưng',
            code: 7450,
            value: 'xa_dong_hung',
            division_type: 'xã',
            short_codename: 'dong_hung',
            parentId: 218,
          },
          {
            title: 'Xã Đông Phú',
            code: 7453,
            value: 'xa_dong_phu',
            division_type: 'xã',
            short_codename: 'dong_phu',
            parentId: 218,
          },
          {
            title: 'Xã Tam Dị',
            code: 7456,
            value: 'xa_tam_di',
            division_type: 'xã',
            short_codename: 'tam_di',
            parentId: 218,
          },
          {
            title: 'Xã Bảo Sơn',
            code: 7459,
            value: 'xa_bao_son',
            division_type: 'xã',
            short_codename: 'bao_son',
            parentId: 218,
          },
          {
            title: 'Xã Bảo Đài',
            code: 7462,
            value: 'xa_bao_dai',
            division_type: 'xã',
            short_codename: 'bao_dai',
            parentId: 218,
          },
          {
            title: 'Xã Thanh Lâm',
            code: 7465,
            value: 'xa_thanh_lam',
            division_type: 'xã',
            short_codename: 'thanh_lam',
            parentId: 218,
          },
          {
            title: 'Xã Tiên Nha',
            code: 7468,
            value: 'xa_tien_nha',
            division_type: 'xã',
            short_codename: 'tien_nha',
            parentId: 218,
          },
          {
            title: 'Xã Trường Giang',
            code: 7471,
            value: 'xa_truong_giang',
            division_type: 'xã',
            short_codename: 'truong_giang',
            parentId: 218,
          },
          {
            title: 'Xã Phương Sơn',
            code: 7477,
            value: 'xa_phuong_son',
            division_type: 'xã',
            short_codename: 'phuong_son',
            parentId: 218,
          },
          {
            title: 'Xã Chu Điện',
            code: 7480,
            value: 'xa_chu_dien',
            division_type: 'xã',
            short_codename: 'chu_dien',
            parentId: 218,
          },
          {
            title: 'Xã Cương Sơn',
            code: 7483,
            value: 'xa_cuong_son',
            division_type: 'xã',
            short_codename: 'cuong_son',
            parentId: 218,
          },
          {
            title: 'Xã Nghĩa Phương',
            code: 7486,
            value: 'xa_nghia_phuong',
            division_type: 'xã',
            short_codename: 'nghia_phuong',
            parentId: 218,
          },
          {
            title: 'Xã Vô Tranh',
            code: 7489,
            value: 'xa_vo_tranh',
            division_type: 'xã',
            short_codename: 'vo_tranh',
            parentId: 218,
          },
          {
            title: 'Xã Bình Sơn',
            code: 7492,
            value: 'xa_binh_son',
            division_type: 'xã',
            short_codename: 'binh_son',
            parentId: 218,
          },
          {
            title: 'Xã Lan Mẫu',
            code: 7495,
            value: 'xa_lan_mau',
            division_type: 'xã',
            short_codename: 'lan_mau',
            parentId: 218,
          },
          {
            title: 'Xã Yên Sơn',
            code: 7498,
            value: 'xa_yen_son',
            division_type: 'xã',
            short_codename: 'yen_son',
            parentId: 218,
          },
          {
            title: 'Xã Khám Lạng',
            code: 7501,
            value: 'xa_kham_lang',
            division_type: 'xã',
            short_codename: 'kham_lang',
            parentId: 218,
          },
          {
            title: 'Xã Huyền Sơn',
            code: 7504,
            value: 'xa_huyen_son',
            division_type: 'xã',
            short_codename: 'huyen_son',
            parentId: 218,
          },
          {
            title: 'Xã Trường Sơn',
            code: 7507,
            value: 'xa_truong_son',
            division_type: 'xã',
            short_codename: 'truong_son',
            parentId: 218,
          },
          {
            title: 'Xã Lục Sơn',
            code: 7510,
            value: 'xa_luc_son',
            division_type: 'xã',
            short_codename: 'luc_son',
            parentId: 218,
          },
          {
            title: 'Xã Bắc Lũng',
            code: 7513,
            value: 'xa_bac_lung',
            division_type: 'xã',
            short_codename: 'bac_lung',
            parentId: 218,
          },
          {
            title: 'Xã Vũ Xá',
            code: 7516,
            value: 'xa_vu_xa',
            division_type: 'xã',
            short_codename: 'vu_xa',
            parentId: 218,
          },
          {
            title: 'Xã Cẩm Lý',
            code: 7519,
            value: 'xa_cam_ly',
            division_type: 'xã',
            short_codename: 'cam_ly',
            parentId: 218,
          },
          {
            title: 'Xã Đan Hội',
            code: 7522,
            value: 'xa_dan_hoi',
            division_type: 'xã',
            short_codename: 'dan_hoi',
            parentId: 218,
          },
        ],
        parentId: 24,
      },
      {
        title: 'Huyện Lục Ngạn',
        code: 219,
        value: 'huyen_luc_ngan',
        division_type: 'huyện',
        short_codename: 'luc_ngan',
        children: [
          {
            title: 'Thị trấn Chũ',
            code: 7525,
            value: 'thi_tran_chu',
            division_type: 'thị trấn',
            short_codename: 'chu',
            parentId: 219,
          },
          {
            title: 'Xã Cấm Sơn',
            code: 7528,
            value: 'xa_cam_son',
            division_type: 'xã',
            short_codename: 'cam_son',
            parentId: 219,
          },
          {
            title: 'Xã Tân Sơn',
            code: 7531,
            value: 'xa_tan_son',
            division_type: 'xã',
            short_codename: 'tan_son',
            parentId: 219,
          },
          {
            title: 'Xã Phong Minh',
            code: 7534,
            value: 'xa_phong_minh',
            division_type: 'xã',
            short_codename: 'phong_minh',
            parentId: 219,
          },
          {
            title: 'Xã Phong Vân',
            code: 7537,
            value: 'xa_phong_van',
            division_type: 'xã',
            short_codename: 'phong_van',
            parentId: 219,
          },
          {
            title: 'Xã Xa Lý',
            code: 7540,
            value: 'xa_xa_ly',
            division_type: 'xã',
            short_codename: 'xa_ly',
            parentId: 219,
          },
          {
            title: 'Xã Hộ Đáp',
            code: 7543,
            value: 'xa_ho_dap',
            division_type: 'xã',
            short_codename: 'ho_dap',
            parentId: 219,
          },
          {
            title: 'Xã Sơn Hải',
            code: 7546,
            value: 'xa_son_hai',
            division_type: 'xã',
            short_codename: 'son_hai',
            parentId: 219,
          },
          {
            title: 'Xã Thanh Hải',
            code: 7549,
            value: 'xa_thanh_hai',
            division_type: 'xã',
            short_codename: 'thanh_hai',
            parentId: 219,
          },
          {
            title: 'Xã Kiên Lao',
            code: 7552,
            value: 'xa_kien_lao',
            division_type: 'xã',
            short_codename: 'kien_lao',
            parentId: 219,
          },
          {
            title: 'Xã Biên Sơn',
            code: 7555,
            value: 'xa_bien_son',
            division_type: 'xã',
            short_codename: 'bien_son',
            parentId: 219,
          },
          {
            title: 'Xã Kiên Thành',
            code: 7558,
            value: 'xa_kien_thanh',
            division_type: 'xã',
            short_codename: 'kien_thanh',
            parentId: 219,
          },
          {
            title: 'Xã Hồng Giang',
            code: 7561,
            value: 'xa_hong_giang',
            division_type: 'xã',
            short_codename: 'hong_giang',
            parentId: 219,
          },
          {
            title: 'Xã Kim Sơn',
            code: 7564,
            value: 'xa_kim_son',
            division_type: 'xã',
            short_codename: 'kim_son',
            parentId: 219,
          },
          {
            title: 'Xã Tân Hoa',
            code: 7567,
            value: 'xa_tan_hoa',
            division_type: 'xã',
            short_codename: 'tan_hoa',
            parentId: 219,
          },
          {
            title: 'Xã Giáp Sơn',
            code: 7570,
            value: 'xa_giap_son',
            division_type: 'xã',
            short_codename: 'giap_son',
            parentId: 219,
          },
          {
            title: 'Xã Biển Động',
            code: 7573,
            value: 'xa_bien_dong',
            division_type: 'xã',
            short_codename: 'bien_dong',
            parentId: 219,
          },
          {
            title: 'Xã Quý Sơn',
            code: 7576,
            value: 'xa_quy_son',
            division_type: 'xã',
            short_codename: 'quy_son',
            parentId: 219,
          },
          {
            title: 'Xã Trù Hựu',
            code: 7579,
            value: 'xa_tru_huu',
            division_type: 'xã',
            short_codename: 'tru_huu',
            parentId: 219,
          },
          {
            title: 'Xã Phì Điền',
            code: 7582,
            value: 'xa_phi_dien',
            division_type: 'xã',
            short_codename: 'phi_dien',
            parentId: 219,
          },
          {
            title: 'Xã Tân Quang',
            code: 7588,
            value: 'xa_tan_quang',
            division_type: 'xã',
            short_codename: 'tan_quang',
            parentId: 219,
          },
          {
            title: 'Xã Đồng Cốc',
            code: 7591,
            value: 'xa_dong_coc',
            division_type: 'xã',
            short_codename: 'dong_coc',
            parentId: 219,
          },
          {
            title: 'Xã Tân Lập',
            code: 7594,
            value: 'xa_tan_lap',
            division_type: 'xã',
            short_codename: 'tan_lap',
            parentId: 219,
          },
          {
            title: 'Xã Phú Nhuận',
            code: 7597,
            value: 'xa_phu_nhuan',
            division_type: 'xã',
            short_codename: 'phu_nhuan',
            parentId: 219,
          },
          {
            title: 'Xã Mỹ An',
            code: 7600,
            value: 'xa_my_an',
            division_type: 'xã',
            short_codename: 'my_an',
            parentId: 219,
          },
          {
            title: 'Xã Nam Dương',
            code: 7603,
            value: 'xa_nam_duong',
            division_type: 'xã',
            short_codename: 'nam_duong',
            parentId: 219,
          },
          {
            title: 'Xã Tân Mộc',
            code: 7606,
            value: 'xa_tan_moc',
            division_type: 'xã',
            short_codename: 'tan_moc',
            parentId: 219,
          },
          {
            title: 'Xã Đèo Gia',
            code: 7609,
            value: 'xa_deo_gia',
            division_type: 'xã',
            short_codename: 'deo_gia',
            parentId: 219,
          },
          {
            title: 'Xã Phượng Sơn',
            code: 7612,
            value: 'xa_phuong_son',
            division_type: 'xã',
            short_codename: 'phuong_son',
            parentId: 219,
          },
        ],
        parentId: 24,
      },
      {
        title: 'Huyện Sơn Động',
        code: 220,
        value: 'huyen_son_dong',
        division_type: 'huyện',
        short_codename: 'son_dong',
        children: [
          {
            title: 'Thị trấn An Châu',
            code: 7615,
            value: 'thi_tran_an_chau',
            division_type: 'thị trấn',
            short_codename: 'an_chau',
            parentId: 220,
          },
          {
            title: 'Thị trấn Tây Yên Tử',
            code: 7616,
            value: 'thi_tran_tay_yen_tu',
            division_type: 'thị trấn',
            short_codename: 'tay_yen_tu',
            parentId: 220,
          },
          {
            title: 'Xã Vân Sơn',
            code: 7621,
            value: 'xa_van_son',
            division_type: 'xã',
            short_codename: 'van_son',
            parentId: 220,
          },
          {
            title: 'Xã Hữu Sản',
            code: 7624,
            value: 'xa_huu_san',
            division_type: 'xã',
            short_codename: 'huu_san',
            parentId: 220,
          },
          {
            title: 'Xã Đại Sơn',
            code: 7627,
            value: 'xa_dai_son',
            division_type: 'xã',
            short_codename: 'dai_son',
            parentId: 220,
          },
          {
            title: 'Xã Phúc Sơn',
            code: 7630,
            value: 'xa_phuc_son',
            division_type: 'xã',
            short_codename: 'phuc_son',
            parentId: 220,
          },
          {
            title: 'Xã Giáo Liêm',
            code: 7636,
            value: 'xa_giao_liem',
            division_type: 'xã',
            short_codename: 'giao_liem',
            parentId: 220,
          },
          {
            title: 'Xã Cẩm Đàn',
            code: 7642,
            value: 'xa_cam_dan',
            division_type: 'xã',
            short_codename: 'cam_dan',
            parentId: 220,
          },
          {
            title: 'Xã An Lạc',
            code: 7645,
            value: 'xa_an_lac',
            division_type: 'xã',
            short_codename: 'an_lac',
            parentId: 220,
          },
          {
            title: 'Xã Vĩnh An',
            code: 7648,
            value: 'xa_vinh_an',
            division_type: 'xã',
            short_codename: 'vinh_an',
            parentId: 220,
          },
          {
            title: 'Xã Yên Định',
            code: 7651,
            value: 'xa_yen_dinh',
            division_type: 'xã',
            short_codename: 'yen_dinh',
            parentId: 220,
          },
          {
            title: 'Xã Lệ Viễn',
            code: 7654,
            value: 'xa_le_vien',
            division_type: 'xã',
            short_codename: 'le_vien',
            parentId: 220,
          },
          {
            title: 'Xã An Bá',
            code: 7660,
            value: 'xa_an_ba',
            division_type: 'xã',
            short_codename: 'an_ba',
            parentId: 220,
          },
          {
            title: 'Xã Tuấn Đạo',
            code: 7663,
            value: 'xa_tuan_dao',
            division_type: 'xã',
            short_codename: 'tuan_dao',
            parentId: 220,
          },
          {
            title: 'Xã Dương Hưu',
            code: 7666,
            value: 'xa_duong_huu',
            division_type: 'xã',
            short_codename: 'duong_huu',
            parentId: 220,
          },
          {
            title: 'Xã Long Sơn',
            code: 7672,
            value: 'xa_long_son',
            division_type: 'xã',
            short_codename: 'long_son',
            parentId: 220,
          },
          {
            title: 'Xã Thanh Luận',
            code: 7678,
            value: 'xa_thanh_luan',
            division_type: 'xã',
            short_codename: 'thanh_luan',
            parentId: 220,
          },
        ],
        parentId: 24,
      },
      {
        title: 'Huyện Yên Dũng',
        code: 221,
        value: 'huyen_yen_dung',
        division_type: 'huyện',
        short_codename: 'yen_dung',
        children: [
          {
            title: 'Thị trấn Nham Biền',
            code: 7681,
            value: 'thi_tran_nham_bien',
            division_type: 'thị trấn',
            short_codename: 'nham_bien',
            parentId: 221,
          },
          {
            title: 'Thị trấn Tân An',
            code: 7682,
            value: 'thi_tran_tan_an',
            division_type: 'thị trấn',
            short_codename: 'tan_an',
            parentId: 221,
          },
          {
            title: 'Xã Lão Hộ',
            code: 7684,
            value: 'xa_lao_ho',
            division_type: 'xã',
            short_codename: 'lao_ho',
            parentId: 221,
          },
          {
            title: 'Xã Hương Gián',
            code: 7690,
            value: 'xa_huong_gian',
            division_type: 'xã',
            short_codename: 'huong_gian',
            parentId: 221,
          },
          {
            title: 'Xã Quỳnh Sơn',
            code: 7702,
            value: 'xa_quynh_son',
            division_type: 'xã',
            short_codename: 'quynh_son',
            parentId: 221,
          },
          {
            title: 'Xã Nội Hoàng',
            code: 7708,
            value: 'xa_noi_hoang',
            division_type: 'xã',
            short_codename: 'noi_hoang',
            parentId: 221,
          },
          {
            title: 'Xã Tiền Phong',
            code: 7711,
            value: 'xa_tien_phong',
            division_type: 'xã',
            short_codename: 'tien_phong',
            parentId: 221,
          },
          {
            title: 'Xã Xuân Phú',
            code: 7714,
            value: 'xa_xuan_phu',
            division_type: 'xã',
            short_codename: 'xuan_phu',
            parentId: 221,
          },
          {
            title: 'Xã Tân Liễu',
            code: 7717,
            value: 'xa_tan_lieu',
            division_type: 'xã',
            short_codename: 'tan_lieu',
            parentId: 221,
          },
          {
            title: 'Xã Trí Yên',
            code: 7720,
            value: 'xa_tri_yen',
            division_type: 'xã',
            short_codename: 'tri_yen',
            parentId: 221,
          },
          {
            title: 'Xã Lãng Sơn',
            code: 7723,
            value: 'xa_lang_son',
            division_type: 'xã',
            short_codename: 'lang_son',
            parentId: 221,
          },
          {
            title: 'Xã Yên Lư',
            code: 7726,
            value: 'xa_yen_lu',
            division_type: 'xã',
            short_codename: 'yen_lu',
            parentId: 221,
          },
          {
            title: 'Xã Tiến Dũng',
            code: 7729,
            value: 'xa_tien_dung',
            division_type: 'xã',
            short_codename: 'tien_dung',
            parentId: 221,
          },
          {
            title: 'Xã Đức Giang',
            code: 7735,
            value: 'xa_duc_giang',
            division_type: 'xã',
            short_codename: 'duc_giang',
            parentId: 221,
          },
          {
            title: 'Xã Cảnh Thụy',
            code: 7738,
            value: 'xa_canh_thuy',
            division_type: 'xã',
            short_codename: 'canh_thuy',
            parentId: 221,
          },
          {
            title: 'Xã Tư Mại',
            code: 7741,
            value: 'xa_tu_mai',
            division_type: 'xã',
            short_codename: 'tu_mai',
            parentId: 221,
          },
          {
            title: 'Xã Đồng Việt',
            code: 7747,
            value: 'xa_dong_viet',
            division_type: 'xã',
            short_codename: 'dong_viet',
            parentId: 221,
          },
          {
            title: 'Xã Đồng Phúc',
            code: 7750,
            value: 'xa_dong_phuc',
            division_type: 'xã',
            short_codename: 'dong_phuc',
            parentId: 221,
          },
        ],
        parentId: 24,
      },
      {
        title: 'Huyện Việt Yên',
        code: 222,
        value: 'huyen_viet_yen',
        division_type: 'huyện',
        short_codename: 'viet_yen',
        children: [
          {
            title: 'Xã Thượng Lan',
            code: 7759,
            value: 'xa_thuong_lan',
            division_type: 'xã',
            short_codename: 'thuong_lan',
            parentId: 222,
          },
          {
            title: 'Xã Việt Tiến',
            code: 7762,
            value: 'xa_viet_tien',
            division_type: 'xã',
            short_codename: 'viet_tien',
            parentId: 222,
          },
          {
            title: 'Xã Nghĩa Trung',
            code: 7765,
            value: 'xa_nghia_trung',
            division_type: 'xã',
            short_codename: 'nghia_trung',
            parentId: 222,
          },
          {
            title: 'Xã Minh Đức',
            code: 7768,
            value: 'xa_minh_duc',
            division_type: 'xã',
            short_codename: 'minh_duc',
            parentId: 222,
          },
          {
            title: 'Xã Hương Mai',
            code: 7771,
            value: 'xa_huong_mai',
            division_type: 'xã',
            short_codename: 'huong_mai',
            parentId: 222,
          },
          {
            title: 'Xã Tự Lạn',
            code: 7774,
            value: 'xa_tu_lan',
            division_type: 'xã',
            short_codename: 'tu_lan',
            parentId: 222,
          },
          {
            title: 'Thị trấn Bích Động',
            code: 7777,
            value: 'thi_tran_bich_dong',
            division_type: 'thị trấn',
            short_codename: 'bich_dong',
            parentId: 222,
          },
          {
            title: 'Xã Trung Sơn',
            code: 7780,
            value: 'xa_trung_son',
            division_type: 'xã',
            short_codename: 'trung_son',
            parentId: 222,
          },
          {
            title: 'Xã Hồng Thái',
            code: 7783,
            value: 'xa_hong_thai',
            division_type: 'xã',
            short_codename: 'hong_thai',
            parentId: 222,
          },
          {
            title: 'Xã Tiên Sơn',
            code: 7786,
            value: 'xa_tien_son',
            division_type: 'xã',
            short_codename: 'tien_son',
            parentId: 222,
          },
          {
            title: 'Xã Tăng Tiến',
            code: 7789,
            value: 'xa_tang_tien',
            division_type: 'xã',
            short_codename: 'tang_tien',
            parentId: 222,
          },
          {
            title: 'Xã Quảng Minh',
            code: 7792,
            value: 'xa_quang_minh',
            division_type: 'xã',
            short_codename: 'quang_minh',
            parentId: 222,
          },
          {
            title: 'Thị trấn Nếnh',
            code: 7795,
            value: 'thi_tran_nenh',
            division_type: 'thị trấn',
            short_codename: 'nenh',
            parentId: 222,
          },
          {
            title: 'Xã Ninh Sơn',
            code: 7798,
            value: 'xa_ninh_son',
            division_type: 'xã',
            short_codename: 'ninh_son',
            parentId: 222,
          },
          {
            title: 'Xã Vân Trung',
            code: 7801,
            value: 'xa_van_trung',
            division_type: 'xã',
            short_codename: 'van_trung',
            parentId: 222,
          },
          {
            title: 'Xã Vân Hà',
            code: 7804,
            value: 'xa_van_ha',
            division_type: 'xã',
            short_codename: 'van_ha',
            parentId: 222,
          },
          {
            title: 'Xã Quang Châu',
            code: 7807,
            value: 'xa_quang_chau',
            division_type: 'xã',
            short_codename: 'quang_chau',
            parentId: 222,
          },
        ],
        parentId: 24,
      },
      {
        title: 'Huyện Hiệp Hòa',
        code: 223,
        value: 'huyen_hiep_hoa',
        division_type: 'huyện',
        short_codename: 'hiep_hoa',
        children: [
          {
            title: 'Xã Đồng Tân',
            code: 7813,
            value: 'xa_dong_tan',
            division_type: 'xã',
            short_codename: 'dong_tan',
            parentId: 223,
          },
          {
            title: 'Xã Thanh Vân',
            code: 7816,
            value: 'xa_thanh_van',
            division_type: 'xã',
            short_codename: 'thanh_van',
            parentId: 223,
          },
          {
            title: 'Xã Hoàng Lương',
            code: 7819,
            value: 'xa_hoang_luong',
            division_type: 'xã',
            short_codename: 'hoang_luong',
            parentId: 223,
          },
          {
            title: 'Xã Hoàng Vân',
            code: 7822,
            value: 'xa_hoang_van',
            division_type: 'xã',
            short_codename: 'hoang_van',
            parentId: 223,
          },
          {
            title: 'Xã Hoàng Thanh',
            code: 7825,
            value: 'xa_hoang_thanh',
            division_type: 'xã',
            short_codename: 'hoang_thanh',
            parentId: 223,
          },
          {
            title: 'Xã Hoàng An',
            code: 7828,
            value: 'xa_hoang_an',
            division_type: 'xã',
            short_codename: 'hoang_an',
            parentId: 223,
          },
          {
            title: 'Xã Ngọc Sơn',
            code: 7831,
            value: 'xa_ngoc_son',
            division_type: 'xã',
            short_codename: 'ngoc_son',
            parentId: 223,
          },
          {
            title: 'Xã Thái Sơn',
            code: 7834,
            value: 'xa_thai_son',
            division_type: 'xã',
            short_codename: 'thai_son',
            parentId: 223,
          },
          {
            title: 'Xã Hòa Sơn',
            code: 7837,
            value: 'xa_hoa_son',
            division_type: 'xã',
            short_codename: 'hoa_son',
            parentId: 223,
          },
          {
            title: 'Thị trấn Thắng',
            code: 7840,
            value: 'thi_tran_thang',
            division_type: 'thị trấn',
            short_codename: 'thang',
            parentId: 223,
          },
          {
            title: 'Xã Quang Minh',
            code: 7843,
            value: 'xa_quang_minh',
            division_type: 'xã',
            short_codename: 'quang_minh',
            parentId: 223,
          },
          {
            title: 'Xã Lương Phong',
            code: 7846,
            value: 'xa_luong_phong',
            division_type: 'xã',
            short_codename: 'luong_phong',
            parentId: 223,
          },
          {
            title: 'Xã Hùng Sơn',
            code: 7849,
            value: 'xa_hung_son',
            division_type: 'xã',
            short_codename: 'hung_son',
            parentId: 223,
          },
          {
            title: 'Xã Đại Thành',
            code: 7852,
            value: 'xa_dai_thanh',
            division_type: 'xã',
            short_codename: 'dai_thanh',
            parentId: 223,
          },
          {
            title: 'Xã Thường Thắng',
            code: 7855,
            value: 'xa_thuong_thang',
            division_type: 'xã',
            short_codename: 'thuong_thang',
            parentId: 223,
          },
          {
            title: 'Xã Hợp Thịnh',
            code: 7858,
            value: 'xa_hop_thinh',
            division_type: 'xã',
            short_codename: 'hop_thinh',
            parentId: 223,
          },
          {
            title: 'Xã Danh Thắng',
            code: 7861,
            value: 'xa_danh_thang',
            division_type: 'xã',
            short_codename: 'danh_thang',
            parentId: 223,
          },
          {
            title: 'Xã Mai Trung',
            code: 7864,
            value: 'xa_mai_trung',
            division_type: 'xã',
            short_codename: 'mai_trung',
            parentId: 223,
          },
          {
            title: 'Xã Đoan Bái',
            code: 7867,
            value: 'xa_doan_bai',
            division_type: 'xã',
            short_codename: 'doan_bai',
            parentId: 223,
          },
          {
            title: 'Xã Bắc Lý',
            code: 7870,
            value: 'xa_bac_ly',
            division_type: 'xã',
            short_codename: 'bac_ly',
            parentId: 223,
          },
          {
            title: 'Xã Xuân Cẩm',
            code: 7873,
            value: 'xa_xuan_cam',
            division_type: 'xã',
            short_codename: 'xuan_cam',
            parentId: 223,
          },
          {
            title: 'Xã Hương Lâm',
            code: 7876,
            value: 'xa_huong_lam',
            division_type: 'xã',
            short_codename: 'huong_lam',
            parentId: 223,
          },
          {
            title: 'Xã Đông Lỗ',
            code: 7879,
            value: 'xa_dong_lo',
            division_type: 'xã',
            short_codename: 'dong_lo',
            parentId: 223,
          },
          {
            title: 'Xã Châu Minh',
            code: 7882,
            value: 'xa_chau_minh',
            division_type: 'xã',
            short_codename: 'chau_minh',
            parentId: 223,
          },
          {
            title: 'Xã Mai Đình',
            code: 7885,
            value: 'xa_mai_dinh',
            division_type: 'xã',
            short_codename: 'mai_dinh',
            parentId: 223,
          },
        ],
        parentId: 24,
      },
    ],
  },
  {
    title: 'Tỉnh Phú Thọ',
    code: 25,
    value: 'tinh_phu_tho',
    division_type: 'tỉnh',
    phone_code: 210,
    children: [
      {
        title: 'Thành phố Việt Trì',
        code: 227,
        value: 'thanh_pho_viet_tri',
        division_type: 'thành phố',
        short_codename: 'viet_tri',
        children: [
          {
            title: 'Phường Dữu Lâu',
            code: 7888,
            value: 'phuong_duu_lau',
            division_type: 'phường',
            short_codename: 'duu_lau',
            parentId: 227,
          },
          {
            title: 'Phường Vân Cơ',
            code: 7891,
            value: 'phuong_van_co',
            division_type: 'phường',
            short_codename: 'van_co',
            parentId: 227,
          },
          {
            title: 'Phường Nông Trang',
            code: 7894,
            value: 'phuong_nong_trang',
            division_type: 'phường',
            short_codename: 'nong_trang',
            parentId: 227,
          },
          {
            title: 'Phường Tân Dân',
            code: 7897,
            value: 'phuong_tan_dan',
            division_type: 'phường',
            short_codename: 'tan_dan',
            parentId: 227,
          },
          {
            title: 'Phường Gia Cẩm',
            code: 7900,
            value: 'phuong_gia_cam',
            division_type: 'phường',
            short_codename: 'gia_cam',
            parentId: 227,
          },
          {
            title: 'Phường Tiên Cát',
            code: 7903,
            value: 'phuong_tien_cat',
            division_type: 'phường',
            short_codename: 'tien_cat',
            parentId: 227,
          },
          {
            title: 'Phường Thọ Sơn',
            code: 7906,
            value: 'phuong_tho_son',
            division_type: 'phường',
            short_codename: 'tho_son',
            parentId: 227,
          },
          {
            title: 'Phường Thanh Miếu',
            code: 7909,
            value: 'phuong_thanh_mieu',
            division_type: 'phường',
            short_codename: 'thanh_mieu',
            parentId: 227,
          },
          {
            title: 'Phường Bạch Hạc',
            code: 7912,
            value: 'phuong_bach_hac',
            division_type: 'phường',
            short_codename: 'bach_hac',
            parentId: 227,
          },
          {
            title: 'Phường Bến Gót',
            code: 7915,
            value: 'phuong_ben_got',
            division_type: 'phường',
            short_codename: 'ben_got',
            parentId: 227,
          },
          {
            title: 'Phường Vân Phú',
            code: 7918,
            value: 'phuong_van_phu',
            division_type: 'phường',
            short_codename: 'van_phu',
            parentId: 227,
          },
          {
            title: 'Xã Phượng Lâu',
            code: 7921,
            value: 'xa_phuong_lau',
            division_type: 'xã',
            short_codename: 'phuong_lau',
            parentId: 227,
          },
          {
            title: 'Xã Thụy Vân',
            code: 7924,
            value: 'xa_thuy_van',
            division_type: 'xã',
            short_codename: 'thuy_van',
            parentId: 227,
          },
          {
            title: 'Phường Minh Phương',
            code: 7927,
            value: 'phuong_minh_phuong',
            division_type: 'phường',
            short_codename: 'minh_phuong',
            parentId: 227,
          },
          {
            title: 'Xã Trưng Vương',
            code: 7930,
            value: 'xa_trung_vuong',
            division_type: 'xã',
            short_codename: 'trung_vuong',
            parentId: 227,
          },
          {
            title: 'Phường Minh Nông',
            code: 7933,
            value: 'phuong_minh_nong',
            division_type: 'phường',
            short_codename: 'minh_nong',
            parentId: 227,
          },
          {
            title: 'Xã Sông Lô',
            code: 7936,
            value: 'xa_song_lo',
            division_type: 'xã',
            short_codename: 'song_lo',
            parentId: 227,
          },
          {
            title: 'Xã Kim Đức',
            code: 8281,
            value: 'xa_kim_duc',
            division_type: 'xã',
            short_codename: 'kim_duc',
            parentId: 227,
          },
          {
            title: 'Xã Hùng Lô',
            code: 8287,
            value: 'xa_hung_lo',
            division_type: 'xã',
            short_codename: 'hung_lo',
            parentId: 227,
          },
          {
            title: 'Xã Hy Cương',
            code: 8503,
            value: 'xa_hy_cuong',
            division_type: 'xã',
            short_codename: 'hy_cuong',
            parentId: 227,
          },
          {
            title: 'Xã Chu Hóa',
            code: 8506,
            value: 'xa_chu_hoa',
            division_type: 'xã',
            short_codename: 'chu_hoa',
            parentId: 227,
          },
          {
            title: 'Xã Thanh Đình',
            code: 8515,
            value: 'xa_thanh_dinh',
            division_type: 'xã',
            short_codename: 'thanh_dinh',
            parentId: 227,
          },
        ],
        parentId: 25,
      },
      {
        title: 'Thị xã Phú Thọ',
        code: 228,
        value: 'thi_xa_phu_tho',
        division_type: 'thị xã',
        short_codename: 'phu_tho',
        children: [
          {
            title: 'Phường Hùng Vương',
            code: 7942,
            value: 'phuong_hung_vuong',
            division_type: 'phường',
            short_codename: 'hung_vuong',
            parentId: 228,
          },
          {
            title: 'Phường Phong Châu',
            code: 7945,
            value: 'phuong_phong_chau',
            division_type: 'phường',
            short_codename: 'phong_chau',
            parentId: 228,
          },
          {
            title: 'Phường Âu Cơ',
            code: 7948,
            value: 'phuong_au_co',
            division_type: 'phường',
            short_codename: 'au_co',
            parentId: 228,
          },
          {
            title: 'Xã Hà Lộc',
            code: 7951,
            value: 'xa_ha_loc',
            division_type: 'xã',
            short_codename: 'ha_loc',
            parentId: 228,
          },
          {
            title: 'Xã Phú Hộ',
            code: 7954,
            value: 'xa_phu_ho',
            division_type: 'xã',
            short_codename: 'phu_ho',
            parentId: 228,
          },
          {
            title: 'Xã Văn Lung',
            code: 7957,
            value: 'xa_van_lung',
            division_type: 'xã',
            short_codename: 'van_lung',
            parentId: 228,
          },
          {
            title: 'Xã Thanh Minh',
            code: 7960,
            value: 'xa_thanh_minh',
            division_type: 'xã',
            short_codename: 'thanh_minh',
            parentId: 228,
          },
          {
            title: 'Xã Hà Thạch',
            code: 7963,
            value: 'xa_ha_thach',
            division_type: 'xã',
            short_codename: 'ha_thach',
            parentId: 228,
          },
          {
            title: 'Phường Thanh Vinh',
            code: 7966,
            value: 'phuong_thanh_vinh',
            division_type: 'phường',
            short_codename: 'thanh_vinh',
            parentId: 228,
          },
        ],
        parentId: 25,
      },
      {
        title: 'Huyện Đoan Hùng',
        code: 230,
        value: 'huyen_doan_hung',
        division_type: 'huyện',
        short_codename: 'doan_hung',
        children: [
          {
            title: 'Thị trấn Đoan Hùng',
            code: 7969,
            value: 'thi_tran_doan_hung',
            division_type: 'thị trấn',
            short_codename: 'doan_hung',
            parentId: 230,
          },
          {
            title: 'Xã Hùng Xuyên',
            code: 7975,
            value: 'xa_hung_xuyen',
            division_type: 'xã',
            short_codename: 'hung_xuyen',
            parentId: 230,
          },
          {
            title: 'Xã Bằng Luân',
            code: 7981,
            value: 'xa_bang_luan',
            division_type: 'xã',
            short_codename: 'bang_luan',
            parentId: 230,
          },
          {
            title: 'Xã Vân Du',
            code: 7984,
            value: 'xa_van_du',
            division_type: 'xã',
            short_codename: 'van_du',
            parentId: 230,
          },
          {
            title: 'Xã Phú Lâm',
            code: 7987,
            value: 'xa_phu_lam',
            division_type: 'xã',
            short_codename: 'phu_lam',
            parentId: 230,
          },
          {
            title: 'Xã Minh Lương',
            code: 7993,
            value: 'xa_minh_luong',
            division_type: 'xã',
            short_codename: 'minh_luong',
            parentId: 230,
          },
          {
            title: 'Xã Bằng Doãn',
            code: 7996,
            value: 'xa_bang_doan',
            division_type: 'xã',
            short_codename: 'bang_doan',
            parentId: 230,
          },
          {
            title: 'Xã Chí Đám',
            code: 7999,
            value: 'xa_chi_dam',
            division_type: 'xã',
            short_codename: 'chi_dam',
            parentId: 230,
          },
          {
            title: 'Xã Phúc Lai',
            code: 8005,
            value: 'xa_phuc_lai',
            division_type: 'xã',
            short_codename: 'phuc_lai',
            parentId: 230,
          },
          {
            title: 'Xã Ngọc Quan',
            code: 8008,
            value: 'xa_ngoc_quan',
            division_type: 'xã',
            short_codename: 'ngoc_quan',
            parentId: 230,
          },
          {
            title: 'Xã Hợp Nhất',
            code: 8014,
            value: 'xa_hop_nhat',
            division_type: 'xã',
            short_codename: 'hop_nhat',
            parentId: 230,
          },
          {
            title: 'Xã Sóc Đăng',
            code: 8017,
            value: 'xa_soc_dang',
            division_type: 'xã',
            short_codename: 'soc_dang',
            parentId: 230,
          },
          {
            title: 'Xã Tây Cốc',
            code: 8023,
            value: 'xa_tay_coc',
            division_type: 'xã',
            short_codename: 'tay_coc',
            parentId: 230,
          },
          {
            title: 'Xã Yên Kiện',
            code: 8026,
            value: 'xa_yen_kien',
            division_type: 'xã',
            short_codename: 'yen_kien',
            parentId: 230,
          },
          {
            title: 'Xã Hùng Long',
            code: 8029,
            value: 'xa_hung_long',
            division_type: 'xã',
            short_codename: 'hung_long',
            parentId: 230,
          },
          {
            title: 'Xã Vụ Quang',
            code: 8032,
            value: 'xa_vu_quang',
            division_type: 'xã',
            short_codename: 'vu_quang',
            parentId: 230,
          },
          {
            title: 'Xã Vân Đồn',
            code: 8035,
            value: 'xa_van_don',
            division_type: 'xã',
            short_codename: 'van_don',
            parentId: 230,
          },
          {
            title: 'Xã Tiêu Sơn',
            code: 8038,
            value: 'xa_tieu_son',
            division_type: 'xã',
            short_codename: 'tieu_son',
            parentId: 230,
          },
          {
            title: 'Xã Minh Tiến',
            code: 8041,
            value: 'xa_minh_tien',
            division_type: 'xã',
            short_codename: 'minh_tien',
            parentId: 230,
          },
          {
            title: 'Xã Minh Phú',
            code: 8044,
            value: 'xa_minh_phu',
            division_type: 'xã',
            short_codename: 'minh_phu',
            parentId: 230,
          },
          {
            title: 'Xã Chân Mộng',
            code: 8047,
            value: 'xa_chan_mong',
            division_type: 'xã',
            short_codename: 'chan_mong',
            parentId: 230,
          },
          {
            title: 'Xã Ca Đình',
            code: 8050,
            value: 'xa_ca_dinh',
            division_type: 'xã',
            short_codename: 'ca_dinh',
            parentId: 230,
          },
        ],
        parentId: 25,
      },
      {
        title: 'Huyện Hạ Hoà',
        code: 231,
        value: 'huyen_ha_hoa',
        division_type: 'huyện',
        short_codename: 'ha_hoa',
        children: [
          {
            title: 'Thị trấn Hạ Hoà',
            code: 8053,
            value: 'thi_tran_ha_hoa',
            division_type: 'thị trấn',
            short_codename: 'ha_hoa',
            parentId: 231,
          },
          {
            title: 'Xã Đại Phạm',
            code: 8056,
            value: 'xa_dai_pham',
            division_type: 'xã',
            short_codename: 'dai_pham',
            parentId: 231,
          },
          {
            title: 'Xã Đan Thượng',
            code: 8062,
            value: 'xa_dan_thuong',
            division_type: 'xã',
            short_codename: 'dan_thuong',
            parentId: 231,
          },
          {
            title: 'Xã Hà Lương',
            code: 8065,
            value: 'xa_ha_luong',
            division_type: 'xã',
            short_codename: 'ha_luong',
            parentId: 231,
          },
          {
            title: 'Xã Tứ Hiệp',
            code: 8071,
            value: 'xa_tu_hiep',
            division_type: 'xã',
            short_codename: 'tu_hiep',
            parentId: 231,
          },
          {
            title: 'Xã Hiền Lương',
            code: 8080,
            value: 'xa_hien_luong',
            division_type: 'xã',
            short_codename: 'hien_luong',
            parentId: 231,
          },
          {
            title: 'Xã Phương Viên',
            code: 8089,
            value: 'xa_phuong_vien',
            division_type: 'xã',
            short_codename: 'phuong_vien',
            parentId: 231,
          },
          {
            title: 'Xã Gia Điền',
            code: 8092,
            value: 'xa_gia_dien',
            division_type: 'xã',
            short_codename: 'gia_dien',
            parentId: 231,
          },
          {
            title: 'Xã Ấm Hạ',
            code: 8095,
            value: 'xa_am_ha',
            division_type: 'xã',
            short_codename: 'am_ha',
            parentId: 231,
          },
          {
            title: 'Xã Hương Xạ',
            code: 8104,
            value: 'xa_huong_xa',
            division_type: 'xã',
            short_codename: 'huong_xa',
            parentId: 231,
          },
          {
            title: 'Xã Xuân Áng',
            code: 8110,
            value: 'xa_xuan_ang',
            division_type: 'xã',
            short_codename: 'xuan_ang',
            parentId: 231,
          },
          {
            title: 'Xã Yên Kỳ',
            code: 8113,
            value: 'xa_yen_ky',
            division_type: 'xã',
            short_codename: 'yen_ky',
            parentId: 231,
          },
          {
            title: 'Xã Minh Hạc',
            code: 8119,
            value: 'xa_minh_hac',
            division_type: 'xã',
            short_codename: 'minh_hac',
            parentId: 231,
          },
          {
            title: 'Xã Lang Sơn',
            code: 8122,
            value: 'xa_lang_son',
            division_type: 'xã',
            short_codename: 'lang_son',
            parentId: 231,
          },
          {
            title: 'Xã Bằng Giã',
            code: 8125,
            value: 'xa_bang_gia',
            division_type: 'xã',
            short_codename: 'bang_gia',
            parentId: 231,
          },
          {
            title: 'Xã Yên Luật',
            code: 8128,
            value: 'xa_yen_luat',
            division_type: 'xã',
            short_codename: 'yen_luat',
            parentId: 231,
          },
          {
            title: 'Xã Vô Tranh',
            code: 8131,
            value: 'xa_vo_tranh',
            division_type: 'xã',
            short_codename: 'vo_tranh',
            parentId: 231,
          },
          {
            title: 'Xã Văn Lang',
            code: 8134,
            value: 'xa_van_lang',
            division_type: 'xã',
            short_codename: 'van_lang',
            parentId: 231,
          },
          {
            title: 'Xã Minh Côi',
            code: 8140,
            value: 'xa_minh_coi',
            division_type: 'xã',
            short_codename: 'minh_coi',
            parentId: 231,
          },
          {
            title: 'Xã Vĩnh Chân',
            code: 8143,
            value: 'xa_vinh_chan',
            division_type: 'xã',
            short_codename: 'vinh_chan',
            parentId: 231,
          },
        ],
        parentId: 25,
      },
      {
        title: 'Huyện Thanh Ba',
        code: 232,
        value: 'huyen_thanh_ba',
        division_type: 'huyện',
        short_codename: 'thanh_ba',
        children: [
          {
            title: 'Thị trấn Thanh Ba',
            code: 8152,
            value: 'thi_tran_thanh_ba',
            division_type: 'thị trấn',
            short_codename: 'thanh_ba',
            parentId: 232,
          },
          {
            title: 'Xã Vân Lĩnh',
            code: 8156,
            value: 'xa_van_linh',
            division_type: 'xã',
            short_codename: 'van_linh',
            parentId: 232,
          },
          {
            title: 'Xã Đông Lĩnh',
            code: 8158,
            value: 'xa_dong_linh',
            division_type: 'xã',
            short_codename: 'dong_linh',
            parentId: 232,
          },
          {
            title: 'Xã Đại An',
            code: 8161,
            value: 'xa_dai_an',
            division_type: 'xã',
            short_codename: 'dai_an',
            parentId: 232,
          },
          {
            title: 'Xã Hanh Cù',
            code: 8164,
            value: 'xa_hanh_cu',
            division_type: 'xã',
            short_codename: 'hanh_cu',
            parentId: 232,
          },
          {
            title: 'Xã Đồng Xuân',
            code: 8170,
            value: 'xa_dong_xuan',
            division_type: 'xã',
            short_codename: 'dong_xuan',
            parentId: 232,
          },
          {
            title: 'Xã Quảng Yên',
            code: 8173,
            value: 'xa_quang_yen',
            division_type: 'xã',
            short_codename: 'quang_yen',
            parentId: 232,
          },
          {
            title: 'Xã Ninh Dân',
            code: 8179,
            value: 'xa_ninh_dan',
            division_type: 'xã',
            short_codename: 'ninh_dan',
            parentId: 232,
          },
          {
            title: 'Xã Võ Lao',
            code: 8194,
            value: 'xa_vo_lao',
            division_type: 'xã',
            short_codename: 'vo_lao',
            parentId: 232,
          },
          {
            title: 'Xã Khải Xuân',
            code: 8197,
            value: 'xa_khai_xuan',
            division_type: 'xã',
            short_codename: 'khai_xuan',
            parentId: 232,
          },
          {
            title: 'Xã Mạn Lạn',
            code: 8200,
            value: 'xa_man_lan',
            division_type: 'xã',
            short_codename: 'man_lan',
            parentId: 232,
          },
          {
            title: 'Xã Hoàng Cương',
            code: 8203,
            value: 'xa_hoang_cuong',
            division_type: 'xã',
            short_codename: 'hoang_cuong',
            parentId: 232,
          },
          {
            title: 'Xã Chí Tiên',
            code: 8206,
            value: 'xa_chi_tien',
            division_type: 'xã',
            short_codename: 'chi_tien',
            parentId: 232,
          },
          {
            title: 'Xã Đông Thành',
            code: 8209,
            value: 'xa_dong_thanh',
            division_type: 'xã',
            short_codename: 'dong_thanh',
            parentId: 232,
          },
          {
            title: 'Xã Sơn Cương',
            code: 8215,
            value: 'xa_son_cuong',
            division_type: 'xã',
            short_codename: 'son_cuong',
            parentId: 232,
          },
          {
            title: 'Xã Thanh Hà',
            code: 8218,
            value: 'xa_thanh_ha',
            division_type: 'xã',
            short_codename: 'thanh_ha',
            parentId: 232,
          },
          {
            title: 'Xã Đỗ Sơn',
            code: 8221,
            value: 'xa_do_son',
            division_type: 'xã',
            short_codename: 'do_son',
            parentId: 232,
          },
          {
            title: 'Xã Đỗ Xuyên',
            code: 8224,
            value: 'xa_do_xuyen',
            division_type: 'xã',
            short_codename: 'do_xuyen',
            parentId: 232,
          },
          {
            title: 'Xã Lương Lỗ',
            code: 8227,
            value: 'xa_luong_lo',
            division_type: 'xã',
            short_codename: 'luong_lo',
            parentId: 232,
          },
        ],
        parentId: 25,
      },
      {
        title: 'Huyện Phù Ninh',
        code: 233,
        value: 'huyen_phu_ninh',
        division_type: 'huyện',
        short_codename: 'phu_ninh',
        children: [
          {
            title: 'Thị trấn Phong Châu',
            code: 8230,
            value: 'thi_tran_phong_chau',
            division_type: 'thị trấn',
            short_codename: 'phong_chau',
            parentId: 233,
          },
          {
            title: 'Xã Phú Mỹ',
            code: 8233,
            value: 'xa_phu_my',
            division_type: 'xã',
            short_codename: 'phu_my',
            parentId: 233,
          },
          {
            title: 'Xã Lệ Mỹ',
            code: 8234,
            value: 'xa_le_my',
            division_type: 'xã',
            short_codename: 'le_my',
            parentId: 233,
          },
          {
            title: 'Xã Liên Hoa',
            code: 8236,
            value: 'xa_lien_hoa',
            division_type: 'xã',
            short_codename: 'lien_hoa',
            parentId: 233,
          },
          {
            title: 'Xã Trạm Thản',
            code: 8239,
            value: 'xa_tram_than',
            division_type: 'xã',
            short_codename: 'tram_than',
            parentId: 233,
          },
          {
            title: 'Xã Trị Quận',
            code: 8242,
            value: 'xa_tri_quan',
            division_type: 'xã',
            short_codename: 'tri_quan',
            parentId: 233,
          },
          {
            title: 'Xã Trung Giáp',
            code: 8245,
            value: 'xa_trung_giap',
            division_type: 'xã',
            short_codename: 'trung_giap',
            parentId: 233,
          },
          {
            title: 'Xã Tiên Phú',
            code: 8248,
            value: 'xa_tien_phu',
            division_type: 'xã',
            short_codename: 'tien_phu',
            parentId: 233,
          },
          {
            title: 'Xã Hạ Giáp',
            code: 8251,
            value: 'xa_ha_giap',
            division_type: 'xã',
            short_codename: 'ha_giap',
            parentId: 233,
          },
          {
            title: 'Xã Bảo Thanh',
            code: 8254,
            value: 'xa_bao_thanh',
            division_type: 'xã',
            short_codename: 'bao_thanh',
            parentId: 233,
          },
          {
            title: 'Xã Phú Lộc',
            code: 8257,
            value: 'xa_phu_loc',
            division_type: 'xã',
            short_codename: 'phu_loc',
            parentId: 233,
          },
          {
            title: 'Xã Gia Thanh',
            code: 8260,
            value: 'xa_gia_thanh',
            division_type: 'xã',
            short_codename: 'gia_thanh',
            parentId: 233,
          },
          {
            title: 'Xã Tiên Du',
            code: 8263,
            value: 'xa_tien_du',
            division_type: 'xã',
            short_codename: 'tien_du',
            parentId: 233,
          },
          {
            title: 'Xã Phú Nham',
            code: 8266,
            value: 'xa_phu_nham',
            division_type: 'xã',
            short_codename: 'phu_nham',
            parentId: 233,
          },
          {
            title: 'Xã An Đạo',
            code: 8272,
            value: 'xa_an_dao',
            division_type: 'xã',
            short_codename: 'an_dao',
            parentId: 233,
          },
          {
            title: 'Xã Bình Phú',
            code: 8275,
            value: 'xa_binh_phu',
            division_type: 'xã',
            short_codename: 'binh_phu',
            parentId: 233,
          },
          {
            title: 'Xã Phù Ninh',
            code: 8278,
            value: 'xa_phu_ninh',
            division_type: 'xã',
            short_codename: 'phu_ninh',
            parentId: 233,
          },
        ],
        parentId: 25,
      },
      {
        title: 'Huyện Yên Lập',
        code: 234,
        value: 'huyen_yen_lap',
        division_type: 'huyện',
        short_codename: 'yen_lap',
        children: [
          {
            title: 'Thị trấn Yên Lập',
            code: 8290,
            value: 'thi_tran_yen_lap',
            division_type: 'thị trấn',
            short_codename: 'yen_lap',
            parentId: 234,
          },
          {
            title: 'Xã Mỹ Lung',
            code: 8293,
            value: 'xa_my_lung',
            division_type: 'xã',
            short_codename: 'my_lung',
            parentId: 234,
          },
          {
            title: 'Xã Mỹ Lương',
            code: 8296,
            value: 'xa_my_luong',
            division_type: 'xã',
            short_codename: 'my_luong',
            parentId: 234,
          },
          {
            title: 'Xã Lương Sơn',
            code: 8299,
            value: 'xa_luong_son',
            division_type: 'xã',
            short_codename: 'luong_son',
            parentId: 234,
          },
          {
            title: 'Xã Xuân An',
            code: 8302,
            value: 'xa_xuan_an',
            division_type: 'xã',
            short_codename: 'xuan_an',
            parentId: 234,
          },
          {
            title: 'Xã Xuân Viên',
            code: 8305,
            value: 'xa_xuan_vien',
            division_type: 'xã',
            short_codename: 'xuan_vien',
            parentId: 234,
          },
          {
            title: 'Xã Xuân Thủy',
            code: 8308,
            value: 'xa_xuan_thuy',
            division_type: 'xã',
            short_codename: 'xuan_thuy',
            parentId: 234,
          },
          {
            title: 'Xã Trung Sơn',
            code: 8311,
            value: 'xa_trung_son',
            division_type: 'xã',
            short_codename: 'trung_son',
            parentId: 234,
          },
          {
            title: 'Xã Hưng Long',
            code: 8314,
            value: 'xa_hung_long',
            division_type: 'xã',
            short_codename: 'hung_long',
            parentId: 234,
          },
          {
            title: 'Xã Nga Hoàng',
            code: 8317,
            value: 'xa_nga_hoang',
            division_type: 'xã',
            short_codename: 'nga_hoang',
            parentId: 234,
          },
          {
            title: 'Xã Đồng Lạc',
            code: 8320,
            value: 'xa_dong_lac',
            division_type: 'xã',
            short_codename: 'dong_lac',
            parentId: 234,
          },
          {
            title: 'Xã Thượng Long',
            code: 8323,
            value: 'xa_thuong_long',
            division_type: 'xã',
            short_codename: 'thuong_long',
            parentId: 234,
          },
          {
            title: 'Xã Đồng Thịnh',
            code: 8326,
            value: 'xa_dong_thinh',
            division_type: 'xã',
            short_codename: 'dong_thinh',
            parentId: 234,
          },
          {
            title: 'Xã Phúc Khánh',
            code: 8329,
            value: 'xa_phuc_khanh',
            division_type: 'xã',
            short_codename: 'phuc_khanh',
            parentId: 234,
          },
          {
            title: 'Xã Minh Hòa',
            code: 8332,
            value: 'xa_minh_hoa',
            division_type: 'xã',
            short_codename: 'minh_hoa',
            parentId: 234,
          },
          {
            title: 'Xã Ngọc Lập',
            code: 8335,
            value: 'xa_ngoc_lap',
            division_type: 'xã',
            short_codename: 'ngoc_lap',
            parentId: 234,
          },
          {
            title: 'Xã Ngọc Đồng',
            code: 8338,
            value: 'xa_ngoc_dong',
            division_type: 'xã',
            short_codename: 'ngoc_dong',
            parentId: 234,
          },
        ],
        parentId: 25,
      },
      {
        title: 'Huyện Cẩm Khê',
        code: 235,
        value: 'huyen_cam_khe',
        division_type: 'huyện',
        short_codename: 'cam_khe',
        children: [
          {
            title: 'Thị trấn Cẩm Khê',
            code: 8341,
            value: 'thi_tran_cam_khe',
            division_type: 'thị trấn',
            short_codename: 'cam_khe',
            parentId: 235,
          },
          {
            title: 'Xã Tiên Lương',
            code: 8344,
            value: 'xa_tien_luong',
            division_type: 'xã',
            short_codename: 'tien_luong',
            parentId: 235,
          },
          {
            title: 'Xã Tuy Lộc',
            code: 8347,
            value: 'xa_tuy_loc',
            division_type: 'xã',
            short_codename: 'tuy_loc',
            parentId: 235,
          },
          {
            title: 'Xã Ngô Xá',
            code: 8350,
            value: 'xa_ngo_xa',
            division_type: 'xã',
            short_codename: 'ngo_xa',
            parentId: 235,
          },
          {
            title: 'Xã Minh Tân',
            code: 8353,
            value: 'xa_minh_tan',
            division_type: 'xã',
            short_codename: 'minh_tan',
            parentId: 235,
          },
          {
            title: 'Xã Phượng Vĩ',
            code: 8356,
            value: 'xa_phuong_vi',
            division_type: 'xã',
            short_codename: 'phuong_vi',
            parentId: 235,
          },
          {
            title: 'Xã Thụy Liễu',
            code: 8362,
            value: 'xa_thuy_lieu',
            division_type: 'xã',
            short_codename: 'thuy_lieu',
            parentId: 235,
          },
          {
            title: 'Xã Tùng Khê',
            code: 8374,
            value: 'xa_tung_khe',
            division_type: 'xã',
            short_codename: 'tung_khe',
            parentId: 235,
          },
          {
            title: 'Xã Tam Sơn',
            code: 8377,
            value: 'xa_tam_son',
            division_type: 'xã',
            short_codename: 'tam_son',
            parentId: 235,
          },
          {
            title: 'Xã Văn Bán',
            code: 8380,
            value: 'xa_van_ban',
            division_type: 'xã',
            short_codename: 'van_ban',
            parentId: 235,
          },
          {
            title: 'Xã Cấp Dẫn',
            code: 8383,
            value: 'xa_cap_dan',
            division_type: 'xã',
            short_codename: 'cap_dan',
            parentId: 235,
          },
          {
            title: 'Xã Xương Thịnh',
            code: 8389,
            value: 'xa_xuong_thinh',
            division_type: 'xã',
            short_codename: 'xuong_thinh',
            parentId: 235,
          },
          {
            title: 'Xã Phú Khê',
            code: 8392,
            value: 'xa_phu_khe',
            division_type: 'xã',
            short_codename: 'phu_khe',
            parentId: 235,
          },
          {
            title: 'Xã Sơn Tình',
            code: 8395,
            value: 'xa_son_tinh',
            division_type: 'xã',
            short_codename: 'son_tinh',
            parentId: 235,
          },
          {
            title: 'Xã Yên Tập',
            code: 8398,
            value: 'xa_yen_tap',
            division_type: 'xã',
            short_codename: 'yen_tap',
            parentId: 235,
          },
          {
            title: 'Xã Hương Lung',
            code: 8401,
            value: 'xa_huong_lung',
            division_type: 'xã',
            short_codename: 'huong_lung',
            parentId: 235,
          },
          {
            title: 'Xã Tạ Xá',
            code: 8404,
            value: 'xa_ta_xa',
            division_type: 'xã',
            short_codename: 'ta_xa',
            parentId: 235,
          },
          {
            title: 'Xã Phú Lạc',
            code: 8407,
            value: 'xa_phu_lac',
            division_type: 'xã',
            short_codename: 'phu_lac',
            parentId: 235,
          },
          {
            title: 'Xã Chương Xá',
            code: 8413,
            value: 'xa_chuong_xa',
            division_type: 'xã',
            short_codename: 'chuong_xa',
            parentId: 235,
          },
          {
            title: 'Xã Hùng Việt',
            code: 8416,
            value: 'xa_hung_viet',
            division_type: 'xã',
            short_codename: 'hung_viet',
            parentId: 235,
          },
          {
            title: 'Xã Văn Khúc',
            code: 8419,
            value: 'xa_van_khuc',
            division_type: 'xã',
            short_codename: 'van_khuc',
            parentId: 235,
          },
          {
            title: 'Xã Yên Dưỡng',
            code: 8422,
            value: 'xa_yen_duong',
            division_type: 'xã',
            short_codename: 'yen_duong',
            parentId: 235,
          },
          {
            title: 'Xã Điêu Lương',
            code: 8428,
            value: 'xa_dieu_luong',
            division_type: 'xã',
            short_codename: 'dieu_luong',
            parentId: 235,
          },
          {
            title: 'Xã Đồng Lương',
            code: 8431,
            value: 'xa_dong_luong',
            division_type: 'xã',
            short_codename: 'dong_luong',
            parentId: 235,
          },
        ],
        parentId: 25,
      },
      {
        title: 'Huyện Tam Nông',
        code: 236,
        value: 'huyen_tam_nong',
        division_type: 'huyện',
        short_codename: 'tam_nong',
        children: [
          {
            title: 'Thị trấn Hưng Hoá',
            code: 8434,
            value: 'thi_tran_hung_hoa',
            division_type: 'thị trấn',
            short_codename: 'hung_hoa',
            parentId: 236,
          },
          {
            title: 'Xã Hiền Quan',
            code: 8440,
            value: 'xa_hien_quan',
            division_type: 'xã',
            short_codename: 'hien_quan',
            parentId: 236,
          },
          {
            title: 'Xã Bắc Sơn',
            code: 8443,
            value: 'xa_bac_son',
            division_type: 'xã',
            short_codename: 'bac_son',
            parentId: 236,
          },
          {
            title: 'Xã Thanh Uyên',
            code: 8446,
            value: 'xa_thanh_uyen',
            division_type: 'xã',
            short_codename: 'thanh_uyen',
            parentId: 236,
          },
          {
            title: 'Xã Lam Sơn',
            code: 8461,
            value: 'xa_lam_son',
            division_type: 'xã',
            short_codename: 'lam_son',
            parentId: 236,
          },
          {
            title: 'Xã Vạn Xuân',
            code: 8467,
            value: 'xa_van_xuan',
            division_type: 'xã',
            short_codename: 'van_xuan',
            parentId: 236,
          },
          {
            title: 'Xã Quang Húc',
            code: 8470,
            value: 'xa_quang_huc',
            division_type: 'xã',
            short_codename: 'quang_huc',
            parentId: 236,
          },
          {
            title: 'Xã Hương Nộn',
            code: 8473,
            value: 'xa_huong_non',
            division_type: 'xã',
            short_codename: 'huong_non',
            parentId: 236,
          },
          {
            title: 'Xã Tề Lễ',
            code: 8476,
            value: 'xa_te_le',
            division_type: 'xã',
            short_codename: 'te_le',
            parentId: 236,
          },
          {
            title: 'Xã Thọ Văn',
            code: 8479,
            value: 'xa_tho_van',
            division_type: 'xã',
            short_codename: 'tho_van',
            parentId: 236,
          },
          {
            title: 'Xã Dị Nậu',
            code: 8482,
            value: 'xa_di_nau',
            division_type: 'xã',
            short_codename: 'di_nau',
            parentId: 236,
          },
          {
            title: 'Xã Dân Quyền',
            code: 8491,
            value: 'xa_dan_quyen',
            division_type: 'xã',
            short_codename: 'dan_quyen',
            parentId: 236,
          },
        ],
        parentId: 25,
      },
      {
        title: 'Huyện Lâm Thao',
        code: 237,
        value: 'huyen_lam_thao',
        division_type: 'huyện',
        short_codename: 'lam_thao',
        children: [
          {
            title: 'Thị trấn Lâm Thao',
            code: 8494,
            value: 'thi_tran_lam_thao',
            division_type: 'thị trấn',
            short_codename: 'lam_thao',
            parentId: 237,
          },
          {
            title: 'Xã Tiên Kiên',
            code: 8497,
            value: 'xa_tien_kien',
            division_type: 'xã',
            short_codename: 'tien_kien',
            parentId: 237,
          },
          {
            title: 'Thị trấn Hùng Sơn',
            code: 8498,
            value: 'thi_tran_hung_son',
            division_type: 'thị trấn',
            short_codename: 'hung_son',
            parentId: 237,
          },
          {
            title: 'Xã Xuân Lũng',
            code: 8500,
            value: 'xa_xuan_lung',
            division_type: 'xã',
            short_codename: 'xuan_lung',
            parentId: 237,
          },
          {
            title: 'Xã Xuân Huy',
            code: 8509,
            value: 'xa_xuan_huy',
            division_type: 'xã',
            short_codename: 'xuan_huy',
            parentId: 237,
          },
          {
            title: 'Xã Thạch Sơn',
            code: 8512,
            value: 'xa_thach_son',
            division_type: 'xã',
            short_codename: 'thach_son',
            parentId: 237,
          },
          {
            title: 'Xã Sơn Vi',
            code: 8518,
            value: 'xa_son_vi',
            division_type: 'xã',
            short_codename: 'son_vi',
            parentId: 237,
          },
          {
            title: 'Xã Phùng Nguyên',
            code: 8521,
            value: 'xa_phung_nguyen',
            division_type: 'xã',
            short_codename: 'phung_nguyen',
            parentId: 237,
          },
          {
            title: 'Xã Cao Xá',
            code: 8527,
            value: 'xa_cao_xa',
            division_type: 'xã',
            short_codename: 'cao_xa',
            parentId: 237,
          },
          {
            title: 'Xã Vĩnh Lại',
            code: 8533,
            value: 'xa_vinh_lai',
            division_type: 'xã',
            short_codename: 'vinh_lai',
            parentId: 237,
          },
          {
            title: 'Xã Tứ Xã',
            code: 8536,
            value: 'xa_tu_xa',
            division_type: 'xã',
            short_codename: 'tu_xa',
            parentId: 237,
          },
          {
            title: 'Xã Bản Nguyên',
            code: 8539,
            value: 'xa_ban_nguyen',
            division_type: 'xã',
            short_codename: 'ban_nguyen',
            parentId: 237,
          },
        ],
        parentId: 25,
      },
      {
        title: 'Huyện Thanh Sơn',
        code: 238,
        value: 'huyen_thanh_son',
        division_type: 'huyện',
        short_codename: 'thanh_son',
        children: [
          {
            title: 'Thị trấn Thanh Sơn',
            code: 8542,
            value: 'thi_tran_thanh_son',
            division_type: 'thị trấn',
            short_codename: 'thanh_son',
            parentId: 238,
          },
          {
            title: 'Xã Sơn Hùng',
            code: 8563,
            value: 'xa_son_hung',
            division_type: 'xã',
            short_codename: 'son_hung',
            parentId: 238,
          },
          {
            title: 'Xã Địch Quả',
            code: 8572,
            value: 'xa_dich_qua',
            division_type: 'xã',
            short_codename: 'dich_qua',
            parentId: 238,
          },
          {
            title: 'Xã Giáp Lai',
            code: 8575,
            value: 'xa_giap_lai',
            division_type: 'xã',
            short_codename: 'giap_lai',
            parentId: 238,
          },
          {
            title: 'Xã Thục Luyện',
            code: 8581,
            value: 'xa_thuc_luyen',
            division_type: 'xã',
            short_codename: 'thuc_luyen',
            parentId: 238,
          },
          {
            title: 'Xã Võ Miếu',
            code: 8584,
            value: 'xa_vo_mieu',
            division_type: 'xã',
            short_codename: 'vo_mieu',
            parentId: 238,
          },
          {
            title: 'Xã Thạch Khoán',
            code: 8587,
            value: 'xa_thach_khoan',
            division_type: 'xã',
            short_codename: 'thach_khoan',
            parentId: 238,
          },
          {
            title: 'Xã Cự Thắng',
            code: 8602,
            value: 'xa_cu_thang',
            division_type: 'xã',
            short_codename: 'cu_thang',
            parentId: 238,
          },
          {
            title: 'Xã Tất Thắng',
            code: 8605,
            value: 'xa_tat_thang',
            division_type: 'xã',
            short_codename: 'tat_thang',
            parentId: 238,
          },
          {
            title: 'Xã Văn Miếu',
            code: 8611,
            value: 'xa_van_mieu',
            division_type: 'xã',
            short_codename: 'van_mieu',
            parentId: 238,
          },
          {
            title: 'Xã Cự Đồng',
            code: 8614,
            value: 'xa_cu_dong',
            division_type: 'xã',
            short_codename: 'cu_dong',
            parentId: 238,
          },
          {
            title: 'Xã Thắng Sơn',
            code: 8623,
            value: 'xa_thang_son',
            division_type: 'xã',
            short_codename: 'thang_son',
            parentId: 238,
          },
          {
            title: 'Xã Tân Minh',
            code: 8629,
            value: 'xa_tan_minh',
            division_type: 'xã',
            short_codename: 'tan_minh',
            parentId: 238,
          },
          {
            title: 'Xã Hương Cần',
            code: 8632,
            value: 'xa_huong_can',
            division_type: 'xã',
            short_codename: 'huong_can',
            parentId: 238,
          },
          {
            title: 'Xã Khả Cửu',
            code: 8635,
            value: 'xa_kha_cuu',
            division_type: 'xã',
            short_codename: 'kha_cuu',
            parentId: 238,
          },
          {
            title: 'Xã Đông Cửu',
            code: 8638,
            value: 'xa_dong_cuu',
            division_type: 'xã',
            short_codename: 'dong_cuu',
            parentId: 238,
          },
          {
            title: 'Xã Tân Lập',
            code: 8641,
            value: 'xa_tan_lap',
            division_type: 'xã',
            short_codename: 'tan_lap',
            parentId: 238,
          },
          {
            title: 'Xã Yên Lãng',
            code: 8644,
            value: 'xa_yen_lang',
            division_type: 'xã',
            short_codename: 'yen_lang',
            parentId: 238,
          },
          {
            title: 'Xã Yên Lương',
            code: 8647,
            value: 'xa_yen_luong',
            division_type: 'xã',
            short_codename: 'yen_luong',
            parentId: 238,
          },
          {
            title: 'Xã Thượng Cửu',
            code: 8650,
            value: 'xa_thuong_cuu',
            division_type: 'xã',
            short_codename: 'thuong_cuu',
            parentId: 238,
          },
          {
            title: 'Xã Lương Nha',
            code: 8653,
            value: 'xa_luong_nha',
            division_type: 'xã',
            short_codename: 'luong_nha',
            parentId: 238,
          },
          {
            title: 'Xã Yên Sơn',
            code: 8656,
            value: 'xa_yen_son',
            division_type: 'xã',
            short_codename: 'yen_son',
            parentId: 238,
          },
          {
            title: 'Xã Tinh Nhuệ',
            code: 8659,
            value: 'xa_tinh_nhue',
            division_type: 'xã',
            short_codename: 'tinh_nhue',
            parentId: 238,
          },
        ],
        parentId: 25,
      },
      {
        title: 'Huyện Thanh Thuỷ',
        code: 239,
        value: 'huyen_thanh_thuy',
        division_type: 'huyện',
        short_codename: 'thanh_thuy',
        children: [
          {
            title: 'Xã Đào Xá',
            code: 8662,
            value: 'xa_dao_xa',
            division_type: 'xã',
            short_codename: 'dao_xa',
            parentId: 239,
          },
          {
            title: 'Xã Thạch Đồng',
            code: 8665,
            value: 'xa_thach_dong',
            division_type: 'xã',
            short_codename: 'thach_dong',
            parentId: 239,
          },
          {
            title: 'Xã Xuân Lộc',
            code: 8668,
            value: 'xa_xuan_loc',
            division_type: 'xã',
            short_codename: 'xuan_loc',
            parentId: 239,
          },
          {
            title: 'Xã Tân Phương',
            code: 8671,
            value: 'xa_tan_phuong',
            division_type: 'xã',
            short_codename: 'tan_phuong',
            parentId: 239,
          },
          {
            title: 'Thị trấn Thanh Thủy',
            code: 8674,
            value: 'thi_tran_thanh_thuy',
            division_type: 'thị trấn',
            short_codename: 'thanh_thuy',
            parentId: 239,
          },
          {
            title: 'Xã Sơn Thủy',
            code: 8677,
            value: 'xa_son_thuy',
            division_type: 'xã',
            short_codename: 'son_thuy',
            parentId: 239,
          },
          {
            title: 'Xã Bảo Yên',
            code: 8680,
            value: 'xa_bao_yen',
            division_type: 'xã',
            short_codename: 'bao_yen',
            parentId: 239,
          },
          {
            title: 'Xã Đoan Hạ',
            code: 8683,
            value: 'xa_doan_ha',
            division_type: 'xã',
            short_codename: 'doan_ha',
            parentId: 239,
          },
          {
            title: 'Xã Đồng Trung',
            code: 8686,
            value: 'xa_dong_trung',
            division_type: 'xã',
            short_codename: 'dong_trung',
            parentId: 239,
          },
          {
            title: 'Xã Hoàng Xá',
            code: 8689,
            value: 'xa_hoang_xa',
            division_type: 'xã',
            short_codename: 'hoang_xa',
            parentId: 239,
          },
          {
            title: 'Xã Tu Vũ',
            code: 8701,
            value: 'xa_tu_vu',
            division_type: 'xã',
            short_codename: 'tu_vu',
            parentId: 239,
          },
        ],
        parentId: 25,
      },
      {
        title: 'Huyện Tân Sơn',
        code: 240,
        value: 'huyen_tan_son',
        division_type: 'huyện',
        short_codename: 'tan_son',
        children: [
          {
            title: 'Xã Thu Cúc',
            code: 8545,
            value: 'xa_thu_cuc',
            division_type: 'xã',
            short_codename: 'thu_cuc',
            parentId: 240,
          },
          {
            title: 'Xã Thạch Kiệt',
            code: 8548,
            value: 'xa_thach_kiet',
            division_type: 'xã',
            short_codename: 'thach_kiet',
            parentId: 240,
          },
          {
            title: 'Xã Thu Ngạc',
            code: 8551,
            value: 'xa_thu_ngac',
            division_type: 'xã',
            short_codename: 'thu_ngac',
            parentId: 240,
          },
          {
            title: 'Xã Kiệt Sơn',
            code: 8554,
            value: 'xa_kiet_son',
            division_type: 'xã',
            short_codename: 'kiet_son',
            parentId: 240,
          },
          {
            title: 'Xã Đồng Sơn',
            code: 8557,
            value: 'xa_dong_son',
            division_type: 'xã',
            short_codename: 'dong_son',
            parentId: 240,
          },
          {
            title: 'Xã Lai Đồng',
            code: 8560,
            value: 'xa_lai_dong',
            division_type: 'xã',
            short_codename: 'lai_dong',
            parentId: 240,
          },
          {
            title: 'Xã Tân Phú',
            code: 8566,
            value: 'xa_tan_phu',
            division_type: 'xã',
            short_codename: 'tan_phu',
            parentId: 240,
          },
          {
            title: 'Xã Mỹ Thuận',
            code: 8569,
            value: 'xa_my_thuan',
            division_type: 'xã',
            short_codename: 'my_thuan',
            parentId: 240,
          },
          {
            title: 'Xã Tân Sơn',
            code: 8578,
            value: 'xa_tan_son',
            division_type: 'xã',
            short_codename: 'tan_son',
            parentId: 240,
          },
          {
            title: 'Xã Xuân Đài',
            code: 8590,
            value: 'xa_xuan_dai',
            division_type: 'xã',
            short_codename: 'xuan_dai',
            parentId: 240,
          },
          {
            title: 'Xã Minh Đài',
            code: 8593,
            value: 'xa_minh_dai',
            division_type: 'xã',
            short_codename: 'minh_dai',
            parentId: 240,
          },
          {
            title: 'Xã Văn Luông',
            code: 8596,
            value: 'xa_van_luong',
            division_type: 'xã',
            short_codename: 'van_luong',
            parentId: 240,
          },
          {
            title: 'Xã Xuân Sơn',
            code: 8599,
            value: 'xa_xuan_son',
            division_type: 'xã',
            short_codename: 'xuan_son',
            parentId: 240,
          },
          {
            title: 'Xã Long Cốc',
            code: 8608,
            value: 'xa_long_coc',
            division_type: 'xã',
            short_codename: 'long_coc',
            parentId: 240,
          },
          {
            title: 'Xã Kim Thượng',
            code: 8617,
            value: 'xa_kim_thuong',
            division_type: 'xã',
            short_codename: 'kim_thuong',
            parentId: 240,
          },
          {
            title: 'Xã Tam Thanh',
            code: 8620,
            value: 'xa_tam_thanh',
            division_type: 'xã',
            short_codename: 'tam_thanh',
            parentId: 240,
          },
          {
            title: 'Xã Vinh Tiền',
            code: 8626,
            value: 'xa_vinh_tien',
            division_type: 'xã',
            short_codename: 'vinh_tien',
            parentId: 240,
          },
        ],
        parentId: 25,
      },
    ],
  },
  {
    title: 'Tỉnh Vĩnh Phúc',
    code: 26,
    value: 'tinh_vinh_phuc',
    division_type: 'tỉnh',
    phone_code: 211,
    children: [
      {
        title: 'Thành phố Vĩnh Yên',
        code: 243,
        value: 'thanh_pho_vinh_yen',
        division_type: 'thành phố',
        short_codename: 'vinh_yen',
        children: [
          {
            title: 'Phường Tích Sơn',
            code: 8707,
            value: 'phuong_tich_son',
            division_type: 'phường',
            short_codename: 'tich_son',
            parentId: 243,
          },
          {
            title: 'Phường Liên Bảo',
            code: 8710,
            value: 'phuong_lien_bao',
            division_type: 'phường',
            short_codename: 'lien_bao',
            parentId: 243,
          },
          {
            title: 'Phường Hội Hợp',
            code: 8713,
            value: 'phuong_hoi_hop',
            division_type: 'phường',
            short_codename: 'hoi_hop',
            parentId: 243,
          },
          {
            title: 'Phường Đống Đa',
            code: 8716,
            value: 'phuong_dong_da',
            division_type: 'phường',
            short_codename: 'dong_da',
            parentId: 243,
          },
          {
            title: 'Phường Ngô Quyền',
            code: 8719,
            value: 'phuong_ngo_quyen',
            division_type: 'phường',
            short_codename: 'ngo_quyen',
            parentId: 243,
          },
          {
            title: 'Phường Đồng Tâm',
            code: 8722,
            value: 'phuong_dong_tam',
            division_type: 'phường',
            short_codename: 'dong_tam',
            parentId: 243,
          },
          {
            title: 'Xã Định Trung',
            code: 8725,
            value: 'xa_dinh_trung',
            division_type: 'xã',
            short_codename: 'dinh_trung',
            parentId: 243,
          },
          {
            title: 'Phường Khai Quang',
            code: 8728,
            value: 'phuong_khai_quang',
            division_type: 'phường',
            short_codename: 'khai_quang',
            parentId: 243,
          },
          {
            title: 'Xã Thanh Trù',
            code: 8731,
            value: 'xa_thanh_tru',
            division_type: 'xã',
            short_codename: 'thanh_tru',
            parentId: 243,
          },
        ],
        parentId: 26,
      },
      {
        title: 'Thành phố Phúc Yên',
        code: 244,
        value: 'thanh_pho_phuc_yen',
        division_type: 'thành phố',
        short_codename: 'phuc_yen',
        children: [
          {
            title: 'Phường Trưng Trắc',
            code: 8734,
            value: 'phuong_trung_trac',
            division_type: 'phường',
            short_codename: 'trung_trac',
            parentId: 244,
          },
          {
            title: 'Phường Hùng Vương',
            code: 8737,
            value: 'phuong_hung_vuong',
            division_type: 'phường',
            short_codename: 'hung_vuong',
            parentId: 244,
          },
          {
            title: 'Phường Trưng Nhị',
            code: 8740,
            value: 'phuong_trung_nhi',
            division_type: 'phường',
            short_codename: 'trung_nhi',
            parentId: 244,
          },
          {
            title: 'Phường Phúc Thắng',
            code: 8743,
            value: 'phuong_phuc_thang',
            division_type: 'phường',
            short_codename: 'phuc_thang',
            parentId: 244,
          },
          {
            title: 'Phường Xuân Hoà',
            code: 8746,
            value: 'phuong_xuan_hoa',
            division_type: 'phường',
            short_codename: 'xuan_hoa',
            parentId: 244,
          },
          {
            title: 'Phường Đồng Xuân',
            code: 8747,
            value: 'phuong_dong_xuan',
            division_type: 'phường',
            short_codename: 'dong_xuan',
            parentId: 244,
          },
          {
            title: 'Xã Ngọc Thanh',
            code: 8749,
            value: 'xa_ngoc_thanh',
            division_type: 'xã',
            short_codename: 'ngoc_thanh',
            parentId: 244,
          },
          {
            title: 'Xã Cao Minh',
            code: 8752,
            value: 'xa_cao_minh',
            division_type: 'xã',
            short_codename: 'cao_minh',
            parentId: 244,
          },
          {
            title: 'Phường Nam Viêm',
            code: 8755,
            value: 'phuong_nam_viem',
            division_type: 'phường',
            short_codename: 'nam_viem',
            parentId: 244,
          },
          {
            title: 'Phường Tiền Châu',
            code: 8758,
            value: 'phuong_tien_chau',
            division_type: 'phường',
            short_codename: 'tien_chau',
            parentId: 244,
          },
        ],
        parentId: 26,
      },
      {
        title: 'Huyện Lập Thạch',
        code: 246,
        value: 'huyen_lap_thach',
        division_type: 'huyện',
        short_codename: 'lap_thach',
        children: [
          {
            title: 'Thị trấn Lập Thạch',
            code: 8761,
            value: 'thi_tran_lap_thach',
            division_type: 'thị trấn',
            short_codename: 'lap_thach',
            parentId: 246,
          },
          {
            title: 'Xã Quang Sơn',
            code: 8764,
            value: 'xa_quang_son',
            division_type: 'xã',
            short_codename: 'quang_son',
            parentId: 246,
          },
          {
            title: 'Xã Ngọc Mỹ',
            code: 8767,
            value: 'xa_ngoc_my',
            division_type: 'xã',
            short_codename: 'ngoc_my',
            parentId: 246,
          },
          {
            title: 'Xã Hợp Lý',
            code: 8770,
            value: 'xa_hop_ly',
            division_type: 'xã',
            short_codename: 'hop_ly',
            parentId: 246,
          },
          {
            title: 'Xã Bắc Bình',
            code: 8785,
            value: 'xa_bac_binh',
            division_type: 'xã',
            short_codename: 'bac_binh',
            parentId: 246,
          },
          {
            title: 'Xã Thái Hòa',
            code: 8788,
            value: 'xa_thai_hoa',
            division_type: 'xã',
            short_codename: 'thai_hoa',
            parentId: 246,
          },
          {
            title: 'Thị trấn Hoa Sơn',
            code: 8789,
            value: 'thi_tran_hoa_son',
            division_type: 'thị trấn',
            short_codename: 'hoa_son',
            parentId: 246,
          },
          {
            title: 'Xã Liễn Sơn',
            code: 8791,
            value: 'xa_lien_son',
            division_type: 'xã',
            short_codename: 'lien_son',
            parentId: 246,
          },
          {
            title: 'Xã Xuân Hòa',
            code: 8794,
            value: 'xa_xuan_hoa',
            division_type: 'xã',
            short_codename: 'xuan_hoa',
            parentId: 246,
          },
          {
            title: 'Xã Vân Trục',
            code: 8797,
            value: 'xa_van_truc',
            division_type: 'xã',
            short_codename: 'van_truc',
            parentId: 246,
          },
          {
            title: 'Xã Liên Hòa',
            code: 8812,
            value: 'xa_lien_hoa',
            division_type: 'xã',
            short_codename: 'lien_hoa',
            parentId: 246,
          },
          {
            title: 'Xã Tử Du',
            code: 8815,
            value: 'xa_tu_du',
            division_type: 'xã',
            short_codename: 'tu_du',
            parentId: 246,
          },
          {
            title: 'Xã Bàn Giản',
            code: 8833,
            value: 'xa_ban_gian',
            division_type: 'xã',
            short_codename: 'ban_gian',
            parentId: 246,
          },
          {
            title: 'Xã Xuân Lôi',
            code: 8836,
            value: 'xa_xuan_loi',
            division_type: 'xã',
            short_codename: 'xuan_loi',
            parentId: 246,
          },
          {
            title: 'Xã Đồng Ích',
            code: 8839,
            value: 'xa_dong_ich',
            division_type: 'xã',
            short_codename: 'dong_ich',
            parentId: 246,
          },
          {
            title: 'Xã Tiên Lữ',
            code: 8842,
            value: 'xa_tien_lu',
            division_type: 'xã',
            short_codename: 'tien_lu',
            parentId: 246,
          },
          {
            title: 'Xã Văn Quán',
            code: 8845,
            value: 'xa_van_quan',
            division_type: 'xã',
            short_codename: 'van_quan',
            parentId: 246,
          },
          {
            title: 'Xã Đình Chu',
            code: 8857,
            value: 'xa_dinh_chu',
            division_type: 'xã',
            short_codename: 'dinh_chu',
            parentId: 246,
          },
          {
            title: 'Xã Triệu Đề',
            code: 8863,
            value: 'xa_trieu_de',
            division_type: 'xã',
            short_codename: 'trieu_de',
            parentId: 246,
          },
          {
            title: 'Xã Sơn Đông',
            code: 8866,
            value: 'xa_son_dong',
            division_type: 'xã',
            short_codename: 'son_dong',
            parentId: 246,
          },
        ],
        parentId: 26,
      },
      {
        title: 'Huyện Tam Dương',
        code: 247,
        value: 'huyen_tam_duong',
        division_type: 'huyện',
        short_codename: 'tam_duong',
        children: [
          {
            title: 'Thị trấn Hợp Hòa',
            code: 8869,
            value: 'thi_tran_hop_hoa',
            division_type: 'thị trấn',
            short_codename: 'hop_hoa',
            parentId: 247,
          },
          {
            title: 'Xã Hoàng Hoa',
            code: 8872,
            value: 'xa_hoang_hoa',
            division_type: 'xã',
            short_codename: 'hoang_hoa',
            parentId: 247,
          },
          {
            title: 'Xã Đồng Tĩnh',
            code: 8875,
            value: 'xa_dong_tinh',
            division_type: 'xã',
            short_codename: 'dong_tinh',
            parentId: 247,
          },
          {
            title: 'Xã Kim Long',
            code: 8878,
            value: 'xa_kim_long',
            division_type: 'xã',
            short_codename: 'kim_long',
            parentId: 247,
          },
          {
            title: 'Xã Hướng Đạo',
            code: 8881,
            value: 'xa_huong_dao',
            division_type: 'xã',
            short_codename: 'huong_dao',
            parentId: 247,
          },
          {
            title: 'Xã Đạo Tú',
            code: 8884,
            value: 'xa_dao_tu',
            division_type: 'xã',
            short_codename: 'dao_tu',
            parentId: 247,
          },
          {
            title: 'Xã An Hòa',
            code: 8887,
            value: 'xa_an_hoa',
            division_type: 'xã',
            short_codename: 'an_hoa',
            parentId: 247,
          },
          {
            title: 'Xã Thanh Vân',
            code: 8890,
            value: 'xa_thanh_van',
            division_type: 'xã',
            short_codename: 'thanh_van',
            parentId: 247,
          },
          {
            title: 'Xã Duy Phiên',
            code: 8893,
            value: 'xa_duy_phien',
            division_type: 'xã',
            short_codename: 'duy_phien',
            parentId: 247,
          },
          {
            title: 'Xã Hoàng Đan',
            code: 8896,
            value: 'xa_hoang_dan',
            division_type: 'xã',
            short_codename: 'hoang_dan',
            parentId: 247,
          },
          {
            title: 'Xã Hoàng Lâu',
            code: 8899,
            value: 'xa_hoang_lau',
            division_type: 'xã',
            short_codename: 'hoang_lau',
            parentId: 247,
          },
          {
            title: 'Xã Vân Hội',
            code: 8902,
            value: 'xa_van_hoi',
            division_type: 'xã',
            short_codename: 'van_hoi',
            parentId: 247,
          },
          {
            title: 'Xã Hợp Thịnh',
            code: 8905,
            value: 'xa_hop_thinh',
            division_type: 'xã',
            short_codename: 'hop_thinh',
            parentId: 247,
          },
        ],
        parentId: 26,
      },
      {
        title: 'Huyện Tam Đảo',
        code: 248,
        value: 'huyen_tam_dao',
        division_type: 'huyện',
        short_codename: 'tam_dao',
        children: [
          {
            title: 'Thị trấn Tam Đảo',
            code: 8908,
            value: 'thi_tran_tam_dao',
            division_type: 'thị trấn',
            short_codename: 'tam_dao',
            parentId: 248,
          },
          {
            title: 'Thị trấn Hợp Châu',
            code: 8911,
            value: 'thi_tran_hop_chau',
            division_type: 'thị trấn',
            short_codename: 'hop_chau',
            parentId: 248,
          },
          {
            title: 'Xã Đạo Trù',
            code: 8914,
            value: 'xa_dao_tru',
            division_type: 'xã',
            short_codename: 'dao_tru',
            parentId: 248,
          },
          {
            title: 'Xã Yên Dương',
            code: 8917,
            value: 'xa_yen_duong',
            division_type: 'xã',
            short_codename: 'yen_duong',
            parentId: 248,
          },
          {
            title: 'Xã Bồ Lý',
            code: 8920,
            value: 'xa_bo_ly',
            division_type: 'xã',
            short_codename: 'bo_ly',
            parentId: 248,
          },
          {
            title: 'Thị trấn Đại Đình',
            code: 8923,
            value: 'thi_tran_dai_dinh',
            division_type: 'thị trấn',
            short_codename: 'dai_dinh',
            parentId: 248,
          },
          {
            title: 'Xã Tam Quan',
            code: 8926,
            value: 'xa_tam_quan',
            division_type: 'xã',
            short_codename: 'tam_quan',
            parentId: 248,
          },
          {
            title: 'Xã Hồ Sơn',
            code: 8929,
            value: 'xa_ho_son',
            division_type: 'xã',
            short_codename: 'ho_son',
            parentId: 248,
          },
          {
            title: 'Xã Minh Quang',
            code: 8932,
            value: 'xa_minh_quang',
            division_type: 'xã',
            short_codename: 'minh_quang',
            parentId: 248,
          },
        ],
        parentId: 26,
      },
      {
        title: 'Huyện Bình Xuyên',
        code: 249,
        value: 'huyen_binh_xuyen',
        division_type: 'huyện',
        short_codename: 'binh_xuyen',
        children: [
          {
            title: 'Thị trấn Hương Canh',
            code: 8935,
            value: 'thi_tran_huong_canh',
            division_type: 'thị trấn',
            short_codename: 'huong_canh',
            parentId: 249,
          },
          {
            title: 'Thị trấn Gia Khánh',
            code: 8936,
            value: 'thi_tran_gia_khanh',
            division_type: 'thị trấn',
            short_codename: 'gia_khanh',
            parentId: 249,
          },
          {
            title: 'Xã Trung Mỹ',
            code: 8938,
            value: 'xa_trung_my',
            division_type: 'xã',
            short_codename: 'trung_my',
            parentId: 249,
          },
          {
            title: 'Thị trấn Bá Hiến',
            code: 8944,
            value: 'thi_tran_ba_hien',
            division_type: 'thị trấn',
            short_codename: 'ba_hien',
            parentId: 249,
          },
          {
            title: 'Xã Thiện Kế',
            code: 8947,
            value: 'xa_thien_ke',
            division_type: 'xã',
            short_codename: 'thien_ke',
            parentId: 249,
          },
          {
            title: 'Xã Hương Sơn',
            code: 8950,
            value: 'xa_huong_son',
            division_type: 'xã',
            short_codename: 'huong_son',
            parentId: 249,
          },
          {
            title: 'Xã Tam Hợp',
            code: 8953,
            value: 'xa_tam_hop',
            division_type: 'xã',
            short_codename: 'tam_hop',
            parentId: 249,
          },
          {
            title: 'Xã Quất Lưu',
            code: 8956,
            value: 'xa_quat_luu',
            division_type: 'xã',
            short_codename: 'quat_luu',
            parentId: 249,
          },
          {
            title: 'Xã Sơn Lôi',
            code: 8959,
            value: 'xa_son_loi',
            division_type: 'xã',
            short_codename: 'son_loi',
            parentId: 249,
          },
          {
            title: 'Thị trấn Đạo Đức',
            code: 8962,
            value: 'thi_tran_dao_duc',
            division_type: 'thị trấn',
            short_codename: 'dao_duc',
            parentId: 249,
          },
          {
            title: 'Xã Tân Phong',
            code: 8965,
            value: 'xa_tan_phong',
            division_type: 'xã',
            short_codename: 'tan_phong',
            parentId: 249,
          },
          {
            title: 'Thị trấn Thanh Lãng',
            code: 8968,
            value: 'thi_tran_thanh_lang',
            division_type: 'thị trấn',
            short_codename: 'thanh_lang',
            parentId: 249,
          },
          {
            title: 'Xã Phú Xuân',
            code: 8971,
            value: 'xa_phu_xuan',
            division_type: 'xã',
            short_codename: 'phu_xuan',
            parentId: 249,
          },
        ],
        parentId: 26,
      },
      {
        title: 'Huyện Yên Lạc',
        code: 251,
        value: 'huyen_yen_lac',
        division_type: 'huyện',
        short_codename: 'yen_lac',
        children: [
          {
            title: 'Thị trấn Yên Lạc',
            code: 9025,
            value: 'thi_tran_yen_lac',
            division_type: 'thị trấn',
            short_codename: 'yen_lac',
            parentId: 251,
          },
          {
            title: 'Xã Đồng Cương',
            code: 9028,
            value: 'xa_dong_cuong',
            division_type: 'xã',
            short_codename: 'dong_cuong',
            parentId: 251,
          },
          {
            title: 'Xã Đồng Văn',
            code: 9031,
            value: 'xa_dong_van',
            division_type: 'xã',
            short_codename: 'dong_van',
            parentId: 251,
          },
          {
            title: 'Xã Bình Định',
            code: 9034,
            value: 'xa_binh_dinh',
            division_type: 'xã',
            short_codename: 'binh_dinh',
            parentId: 251,
          },
          {
            title: 'Xã Trung Nguyên',
            code: 9037,
            value: 'xa_trung_nguyen',
            division_type: 'xã',
            short_codename: 'trung_nguyen',
            parentId: 251,
          },
          {
            title: 'Xã Tề Lỗ',
            code: 9040,
            value: 'xa_te_lo',
            division_type: 'xã',
            short_codename: 'te_lo',
            parentId: 251,
          },
          {
            title: 'Xã Tam Hồng',
            code: 9043,
            value: 'xa_tam_hong',
            division_type: 'xã',
            short_codename: 'tam_hong',
            parentId: 251,
          },
          {
            title: 'Xã Yên Đồng',
            code: 9046,
            value: 'xa_yen_dong',
            division_type: 'xã',
            short_codename: 'yen_dong',
            parentId: 251,
          },
          {
            title: 'Xã Văn Tiến',
            code: 9049,
            value: 'xa_van_tien',
            division_type: 'xã',
            short_codename: 'van_tien',
            parentId: 251,
          },
          {
            title: 'Xã Nguyệt Đức',
            code: 9052,
            value: 'xa_nguyet_duc',
            division_type: 'xã',
            short_codename: 'nguyet_duc',
            parentId: 251,
          },
          {
            title: 'Xã Yên Phương',
            code: 9055,
            value: 'xa_yen_phuong',
            division_type: 'xã',
            short_codename: 'yen_phuong',
            parentId: 251,
          },
          {
            title: 'Xã Hồng Phương',
            code: 9058,
            value: 'xa_hong_phuong',
            division_type: 'xã',
            short_codename: 'hong_phuong',
            parentId: 251,
          },
          {
            title: 'Xã Trung Kiên',
            code: 9061,
            value: 'xa_trung_kien',
            division_type: 'xã',
            short_codename: 'trung_kien',
            parentId: 251,
          },
          {
            title: 'Xã Liên Châu',
            code: 9064,
            value: 'xa_lien_chau',
            division_type: 'xã',
            short_codename: 'lien_chau',
            parentId: 251,
          },
          {
            title: 'Xã Đại Tự',
            code: 9067,
            value: 'xa_dai_tu',
            division_type: 'xã',
            short_codename: 'dai_tu',
            parentId: 251,
          },
          {
            title: 'Xã Hồng Châu',
            code: 9070,
            value: 'xa_hong_chau',
            division_type: 'xã',
            short_codename: 'hong_chau',
            parentId: 251,
          },
          {
            title: 'Xã Trung Hà',
            code: 9073,
            value: 'xa_trung_ha',
            division_type: 'xã',
            short_codename: 'trung_ha',
            parentId: 251,
          },
        ],
        parentId: 26,
      },
      {
        title: 'Huyện Vĩnh Tường',
        code: 252,
        value: 'huyen_vinh_tuong',
        division_type: 'huyện',
        short_codename: 'vinh_tuong',
        children: [
          {
            title: 'Thị trấn Vĩnh Tường',
            code: 9076,
            value: 'thi_tran_vinh_tuong',
            division_type: 'thị trấn',
            short_codename: 'vinh_tuong',
            parentId: 252,
          },
          {
            title: 'Xã Kim Xá',
            code: 9079,
            value: 'xa_kim_xa',
            division_type: 'xã',
            short_codename: 'kim_xa',
            parentId: 252,
          },
          {
            title: 'Xã Yên Bình',
            code: 9082,
            value: 'xa_yen_binh',
            division_type: 'xã',
            short_codename: 'yen_binh',
            parentId: 252,
          },
          {
            title: 'Xã Chấn Hưng',
            code: 9085,
            value: 'xa_chan_hung',
            division_type: 'xã',
            short_codename: 'chan_hung',
            parentId: 252,
          },
          {
            title: 'Xã Nghĩa Hưng',
            code: 9088,
            value: 'xa_nghia_hung',
            division_type: 'xã',
            short_codename: 'nghia_hung',
            parentId: 252,
          },
          {
            title: 'Xã Yên Lập',
            code: 9091,
            value: 'xa_yen_lap',
            division_type: 'xã',
            short_codename: 'yen_lap',
            parentId: 252,
          },
          {
            title: 'Xã Việt Xuân',
            code: 9094,
            value: 'xa_viet_xuan',
            division_type: 'xã',
            short_codename: 'viet_xuan',
            parentId: 252,
          },
          {
            title: 'Xã Bồ Sao',
            code: 9097,
            value: 'xa_bo_sao',
            division_type: 'xã',
            short_codename: 'bo_sao',
            parentId: 252,
          },
          {
            title: 'Xã Đại Đồng',
            code: 9100,
            value: 'xa_dai_dong',
            division_type: 'xã',
            short_codename: 'dai_dong',
            parentId: 252,
          },
          {
            title: 'Xã Tân Tiến',
            code: 9103,
            value: 'xa_tan_tien',
            division_type: 'xã',
            short_codename: 'tan_tien',
            parentId: 252,
          },
          {
            title: 'Xã Lũng Hoà',
            code: 9106,
            value: 'xa_lung_hoa',
            division_type: 'xã',
            short_codename: 'lung_hoa',
            parentId: 252,
          },
          {
            title: 'Xã Cao Đại',
            code: 9109,
            value: 'xa_cao_dai',
            division_type: 'xã',
            short_codename: 'cao_dai',
            parentId: 252,
          },
          {
            title: 'Thị Trấn Thổ Tang',
            code: 9112,
            value: 'thi_tran_tho_tang',
            division_type: 'thị trấn',
            short_codename: 'tho_tang',
            parentId: 252,
          },
          {
            title: 'Xã Vĩnh Sơn',
            code: 9115,
            value: 'xa_vinh_son',
            division_type: 'xã',
            short_codename: 'vinh_son',
            parentId: 252,
          },
          {
            title: 'Xã Bình Dương',
            code: 9118,
            value: 'xa_binh_duong',
            division_type: 'xã',
            short_codename: 'binh_duong',
            parentId: 252,
          },
          {
            title: 'Xã Tân Phú',
            code: 9124,
            value: 'xa_tan_phu',
            division_type: 'xã',
            short_codename: 'tan_phu',
            parentId: 252,
          },
          {
            title: 'Xã Thượng Trưng',
            code: 9127,
            value: 'xa_thuong_trung',
            division_type: 'xã',
            short_codename: 'thuong_trung',
            parentId: 252,
          },
          {
            title: 'Xã Vũ Di',
            code: 9130,
            value: 'xa_vu_di',
            division_type: 'xã',
            short_codename: 'vu_di',
            parentId: 252,
          },
          {
            title: 'Xã Lý Nhân',
            code: 9133,
            value: 'xa_ly_nhan',
            division_type: 'xã',
            short_codename: 'ly_nhan',
            parentId: 252,
          },
          {
            title: 'Xã Tuân Chính',
            code: 9136,
            value: 'xa_tuan_chinh',
            division_type: 'xã',
            short_codename: 'tuan_chinh',
            parentId: 252,
          },
          {
            title: 'Xã Vân Xuân',
            code: 9139,
            value: 'xa_van_xuan',
            division_type: 'xã',
            short_codename: 'van_xuan',
            parentId: 252,
          },
          {
            title: 'Xã Tam Phúc',
            code: 9142,
            value: 'xa_tam_phuc',
            division_type: 'xã',
            short_codename: 'tam_phuc',
            parentId: 252,
          },
          {
            title: 'Thị trấn Tứ Trưng',
            code: 9145,
            value: 'thi_tran_tu_trung',
            division_type: 'thị trấn',
            short_codename: 'tu_trung',
            parentId: 252,
          },
          {
            title: 'Xã Ngũ Kiên',
            code: 9148,
            value: 'xa_ngu_kien',
            division_type: 'xã',
            short_codename: 'ngu_kien',
            parentId: 252,
          },
          {
            title: 'Xã An Tường',
            code: 9151,
            value: 'xa_an_tuong',
            division_type: 'xã',
            short_codename: 'an_tuong',
            parentId: 252,
          },
          {
            title: 'Xã Vĩnh Thịnh',
            code: 9154,
            value: 'xa_vinh_thinh',
            division_type: 'xã',
            short_codename: 'vinh_thinh',
            parentId: 252,
          },
          {
            title: 'Xã Phú Đa',
            code: 9157,
            value: 'xa_phu_da',
            division_type: 'xã',
            short_codename: 'phu_da',
            parentId: 252,
          },
          {
            title: 'Xã Vĩnh Ninh',
            code: 9160,
            value: 'xa_vinh_ninh',
            division_type: 'xã',
            short_codename: 'vinh_ninh',
            parentId: 252,
          },
        ],
        parentId: 26,
      },
      {
        title: 'Huyện Sông Lô',
        code: 253,
        value: 'huyen_song_lo',
        division_type: 'huyện',
        short_codename: 'song_lo',
        children: [
          {
            title: 'Xã Lãng Công',
            code: 8773,
            value: 'xa_lang_cong',
            division_type: 'xã',
            short_codename: 'lang_cong',
            parentId: 253,
          },
          {
            title: 'Xã Quang Yên',
            code: 8776,
            value: 'xa_quang_yen',
            division_type: 'xã',
            short_codename: 'quang_yen',
            parentId: 253,
          },
          {
            title: 'Xã Bạch Lưu',
            code: 8779,
            value: 'xa_bach_luu',
            division_type: 'xã',
            short_codename: 'bach_luu',
            parentId: 253,
          },
          {
            title: 'Xã Hải Lựu',
            code: 8782,
            value: 'xa_hai_luu',
            division_type: 'xã',
            short_codename: 'hai_luu',
            parentId: 253,
          },
          {
            title: 'Xã Đồng Quế',
            code: 8800,
            value: 'xa_dong_que',
            division_type: 'xã',
            short_codename: 'dong_que',
            parentId: 253,
          },
          {
            title: 'Xã Nhân Đạo',
            code: 8803,
            value: 'xa_nhan_dao',
            division_type: 'xã',
            short_codename: 'nhan_dao',
            parentId: 253,
          },
          {
            title: 'Xã Đôn Nhân',
            code: 8806,
            value: 'xa_don_nhan',
            division_type: 'xã',
            short_codename: 'don_nhan',
            parentId: 253,
          },
          {
            title: 'Xã Phương Khoan',
            code: 8809,
            value: 'xa_phuong_khoan',
            division_type: 'xã',
            short_codename: 'phuong_khoan',
            parentId: 253,
          },
          {
            title: 'Xã Tân Lập',
            code: 8818,
            value: 'xa_tan_lap',
            division_type: 'xã',
            short_codename: 'tan_lap',
            parentId: 253,
          },
          {
            title: 'Xã Nhạo Sơn',
            code: 8821,
            value: 'xa_nhao_son',
            division_type: 'xã',
            short_codename: 'nhao_son',
            parentId: 253,
          },
          {
            title: 'Thị trấn Tam Sơn',
            code: 8824,
            value: 'thi_tran_tam_son',
            division_type: 'thị trấn',
            short_codename: 'tam_son',
            parentId: 253,
          },
          {
            title: 'Xã Như Thụy',
            code: 8827,
            value: 'xa_nhu_thuy',
            division_type: 'xã',
            short_codename: 'nhu_thuy',
            parentId: 253,
          },
          {
            title: 'Xã Yên Thạch',
            code: 8830,
            value: 'xa_yen_thach',
            division_type: 'xã',
            short_codename: 'yen_thach',
            parentId: 253,
          },
          {
            title: 'Xã Đồng Thịnh',
            code: 8848,
            value: 'xa_dong_thinh',
            division_type: 'xã',
            short_codename: 'dong_thinh',
            parentId: 253,
          },
          {
            title: 'Xã Tứ Yên',
            code: 8851,
            value: 'xa_tu_yen',
            division_type: 'xã',
            short_codename: 'tu_yen',
            parentId: 253,
          },
          {
            title: 'Xã Đức Bác',
            code: 8854,
            value: 'xa_duc_bac',
            division_type: 'xã',
            short_codename: 'duc_bac',
            parentId: 253,
          },
          {
            title: 'Xã Cao Phong',
            code: 8860,
            value: 'xa_cao_phong',
            division_type: 'xã',
            short_codename: 'cao_phong',
            parentId: 253,
          },
        ],
        parentId: 26,
      },
    ],
  },
  {
    title: 'Tỉnh Bắc Ninh',
    code: 27,
    value: 'tinh_bac_ninh',
    division_type: 'tỉnh',
    phone_code: 222,
    children: [
      {
        title: 'Thành phố Bắc Ninh',
        code: 256,
        value: 'thanh_pho_bac_ninh',
        division_type: 'thành phố',
        short_codename: 'bac_ninh',
        children: [
          {
            title: 'Phường Vũ Ninh',
            code: 9163,
            value: 'phuong_vu_ninh',
            division_type: 'phường',
            short_codename: 'vu_ninh',
            parentId: 256,
          },
          {
            title: 'Phường Đáp Cầu',
            code: 9166,
            value: 'phuong_dap_cau',
            division_type: 'phường',
            short_codename: 'dap_cau',
            parentId: 256,
          },
          {
            title: 'Phường Thị Cầu',
            code: 9169,
            value: 'phuong_thi_cau',
            division_type: 'phường',
            short_codename: 'thi_cau',
            parentId: 256,
          },
          {
            title: 'Phường Kinh Bắc',
            code: 9172,
            value: 'phuong_kinh_bac',
            division_type: 'phường',
            short_codename: 'kinh_bac',
            parentId: 256,
          },
          {
            title: 'Phường Vệ An',
            code: 9175,
            value: 'phuong_ve_an',
            division_type: 'phường',
            short_codename: 've_an',
            parentId: 256,
          },
          {
            title: 'Phường Tiền An',
            code: 9178,
            value: 'phuong_tien_an',
            division_type: 'phường',
            short_codename: 'tien_an',
            parentId: 256,
          },
          {
            title: 'Phường Đại Phúc',
            code: 9181,
            value: 'phuong_dai_phuc',
            division_type: 'phường',
            short_codename: 'dai_phuc',
            parentId: 256,
          },
          {
            title: 'Phường Ninh Xá',
            code: 9184,
            value: 'phuong_ninh_xa',
            division_type: 'phường',
            short_codename: 'ninh_xa',
            parentId: 256,
          },
          {
            title: 'Phường Suối Hoa',
            code: 9187,
            value: 'phuong_suoi_hoa',
            division_type: 'phường',
            short_codename: 'suoi_hoa',
            parentId: 256,
          },
          {
            title: 'Phường Võ Cường',
            code: 9190,
            value: 'phuong_vo_cuong',
            division_type: 'phường',
            short_codename: 'vo_cuong',
            parentId: 256,
          },
          {
            title: 'Phường Hòa Long',
            code: 9214,
            value: 'phuong_hoa_long',
            division_type: 'phường',
            short_codename: 'hoa_long',
            parentId: 256,
          },
          {
            title: 'Phường Vạn An',
            code: 9226,
            value: 'phuong_van_an',
            division_type: 'phường',
            short_codename: 'van_an',
            parentId: 256,
          },
          {
            title: 'Phường Khúc Xuyên',
            code: 9235,
            value: 'phuong_khuc_xuyen',
            division_type: 'phường',
            short_codename: 'khuc_xuyen',
            parentId: 256,
          },
          {
            title: 'Phường Phong Khê',
            code: 9244,
            value: 'phuong_phong_khe',
            division_type: 'phường',
            short_codename: 'phong_khe',
            parentId: 256,
          },
          {
            title: 'Phường Kim Chân',
            code: 9256,
            value: 'phuong_kim_chan',
            division_type: 'phường',
            short_codename: 'kim_chan',
            parentId: 256,
          },
          {
            title: 'Phường Vân Dương',
            code: 9271,
            value: 'phuong_van_duong',
            division_type: 'phường',
            short_codename: 'van_duong',
            parentId: 256,
          },
          {
            title: 'Phường Nam Sơn',
            code: 9286,
            value: 'phuong_nam_son',
            division_type: 'phường',
            short_codename: 'nam_son',
            parentId: 256,
          },
          {
            title: 'Phường Khắc Niệm',
            code: 9325,
            value: 'phuong_khac_niem',
            division_type: 'phường',
            short_codename: 'khac_niem',
            parentId: 256,
          },
          {
            title: 'Phường Hạp Lĩnh',
            code: 9331,
            value: 'phuong_hap_linh',
            division_type: 'phường',
            short_codename: 'hap_linh',
            parentId: 256,
          },
        ],
        parentId: 27,
      },
      {
        title: 'Huyện Yên Phong',
        code: 258,
        value: 'huyen_yen_phong',
        division_type: 'huyện',
        short_codename: 'yen_phong',
        children: [
          {
            title: 'Thị trấn Chờ',
            code: 9193,
            value: 'thi_tran_cho',
            division_type: 'thị trấn',
            short_codename: 'cho',
            parentId: 258,
          },
          {
            title: 'Xã Dũng Liệt',
            code: 9196,
            value: 'xa_dung_liet',
            division_type: 'xã',
            short_codename: 'dung_liet',
            parentId: 258,
          },
          {
            title: 'Xã Tam Đa',
            code: 9199,
            value: 'xa_tam_da',
            division_type: 'xã',
            short_codename: 'tam_da',
            parentId: 258,
          },
          {
            title: 'Xã Tam Giang',
            code: 9202,
            value: 'xa_tam_giang',
            division_type: 'xã',
            short_codename: 'tam_giang',
            parentId: 258,
          },
          {
            title: 'Xã Yên Trung',
            code: 9205,
            value: 'xa_yen_trung',
            division_type: 'xã',
            short_codename: 'yen_trung',
            parentId: 258,
          },
          {
            title: 'Xã Thụy Hòa',
            code: 9208,
            value: 'xa_thuy_hoa',
            division_type: 'xã',
            short_codename: 'thuy_hoa',
            parentId: 258,
          },
          {
            title: 'Xã Hòa Tiến',
            code: 9211,
            value: 'xa_hoa_tien',
            division_type: 'xã',
            short_codename: 'hoa_tien',
            parentId: 258,
          },
          {
            title: 'Xã Đông Tiến',
            code: 9217,
            value: 'xa_dong_tien',
            division_type: 'xã',
            short_codename: 'dong_tien',
            parentId: 258,
          },
          {
            title: 'Xã Yên Phụ',
            code: 9220,
            value: 'xa_yen_phu',
            division_type: 'xã',
            short_codename: 'yen_phu',
            parentId: 258,
          },
          {
            title: 'Xã Trung Nghĩa',
            code: 9223,
            value: 'xa_trung_nghia',
            division_type: 'xã',
            short_codename: 'trung_nghia',
            parentId: 258,
          },
          {
            title: 'Xã Đông Phong',
            code: 9229,
            value: 'xa_dong_phong',
            division_type: 'xã',
            short_codename: 'dong_phong',
            parentId: 258,
          },
          {
            title: 'Xã Long Châu',
            code: 9232,
            value: 'xa_long_chau',
            division_type: 'xã',
            short_codename: 'long_chau',
            parentId: 258,
          },
          {
            title: 'Xã Văn Môn',
            code: 9238,
            value: 'xa_van_mon',
            division_type: 'xã',
            short_codename: 'van_mon',
            parentId: 258,
          },
          {
            title: 'Xã Đông Thọ',
            code: 9241,
            value: 'xa_dong_tho',
            division_type: 'xã',
            short_codename: 'dong_tho',
            parentId: 258,
          },
        ],
        parentId: 27,
      },
      {
        title: 'Huyện Quế Võ',
        code: 259,
        value: 'huyen_que_vo',
        division_type: 'huyện',
        short_codename: 'que_vo',
        children: [
          {
            title: 'Thị trấn Phố Mới',
            code: 9247,
            value: 'thi_tran_pho_moi',
            division_type: 'thị trấn',
            short_codename: 'pho_moi',
            parentId: 259,
          },
          {
            title: 'Xã Việt Thống',
            code: 9250,
            value: 'xa_viet_thong',
            division_type: 'xã',
            short_codename: 'viet_thong',
            parentId: 259,
          },
          {
            title: 'Xã Đại Xuân',
            code: 9253,
            value: 'xa_dai_xuan',
            division_type: 'xã',
            short_codename: 'dai_xuan',
            parentId: 259,
          },
          {
            title: 'Xã Nhân Hòa',
            code: 9259,
            value: 'xa_nhan_hoa',
            division_type: 'xã',
            short_codename: 'nhan_hoa',
            parentId: 259,
          },
          {
            title: 'Xã Bằng An',
            code: 9262,
            value: 'xa_bang_an',
            division_type: 'xã',
            short_codename: 'bang_an',
            parentId: 259,
          },
          {
            title: 'Xã Phương Liễu',
            code: 9265,
            value: 'xa_phuong_lieu',
            division_type: 'xã',
            short_codename: 'phuong_lieu',
            parentId: 259,
          },
          {
            title: 'Xã Quế Tân',
            code: 9268,
            value: 'xa_que_tan',
            division_type: 'xã',
            short_codename: 'que_tan',
            parentId: 259,
          },
          {
            title: 'Xã Phù Lương',
            code: 9274,
            value: 'xa_phu_luong',
            division_type: 'xã',
            short_codename: 'phu_luong',
            parentId: 259,
          },
          {
            title: 'Xã Phù Lãng',
            code: 9277,
            value: 'xa_phu_lang',
            division_type: 'xã',
            short_codename: 'phu_lang',
            parentId: 259,
          },
          {
            title: 'Xã Phượng Mao',
            code: 9280,
            value: 'xa_phuong_mao',
            division_type: 'xã',
            short_codename: 'phuong_mao',
            parentId: 259,
          },
          {
            title: 'Xã Việt Hùng',
            code: 9283,
            value: 'xa_viet_hung',
            division_type: 'xã',
            short_codename: 'viet_hung',
            parentId: 259,
          },
          {
            title: 'Xã Ngọc Xá',
            code: 9289,
            value: 'xa_ngoc_xa',
            division_type: 'xã',
            short_codename: 'ngoc_xa',
            parentId: 259,
          },
          {
            title: 'Xã Châu Phong',
            code: 9292,
            value: 'xa_chau_phong',
            division_type: 'xã',
            short_codename: 'chau_phong',
            parentId: 259,
          },
          {
            title: 'Xã Bồng Lai',
            code: 9295,
            value: 'xa_bong_lai',
            division_type: 'xã',
            short_codename: 'bong_lai',
            parentId: 259,
          },
          {
            title: 'Xã Cách Bi',
            code: 9298,
            value: 'xa_cach_bi',
            division_type: 'xã',
            short_codename: 'cach_bi',
            parentId: 259,
          },
          {
            title: 'Xã Đào Viên',
            code: 9301,
            value: 'xa_dao_vien',
            division_type: 'xã',
            short_codename: 'dao_vien',
            parentId: 259,
          },
          {
            title: 'Xã Yên Giả',
            code: 9304,
            value: 'xa_yen_gia',
            division_type: 'xã',
            short_codename: 'yen_gia',
            parentId: 259,
          },
          {
            title: 'Xã Mộ Đạo',
            code: 9307,
            value: 'xa_mo_dao',
            division_type: 'xã',
            short_codename: 'mo_dao',
            parentId: 259,
          },
          {
            title: 'Xã Đức Long',
            code: 9310,
            value: 'xa_duc_long',
            division_type: 'xã',
            short_codename: 'duc_long',
            parentId: 259,
          },
          {
            title: 'Xã Chi Lăng',
            code: 9313,
            value: 'xa_chi_lang',
            division_type: 'xã',
            short_codename: 'chi_lang',
            parentId: 259,
          },
          {
            title: 'Xã Hán Quảng',
            code: 9316,
            value: 'xa_han_quang',
            division_type: 'xã',
            short_codename: 'han_quang',
            parentId: 259,
          },
        ],
        parentId: 27,
      },
      {
        title: 'Huyện Tiên Du',
        code: 260,
        value: 'huyen_tien_du',
        division_type: 'huyện',
        short_codename: 'tien_du',
        children: [
          {
            title: 'Thị trấn Lim',
            code: 9319,
            value: 'thi_tran_lim',
            division_type: 'thị trấn',
            short_codename: 'lim',
            parentId: 260,
          },
          {
            title: 'Xã Phú Lâm',
            code: 9322,
            value: 'xa_phu_lam',
            division_type: 'xã',
            short_codename: 'phu_lam',
            parentId: 260,
          },
          {
            title: 'Xã Nội Duệ',
            code: 9328,
            value: 'xa_noi_due',
            division_type: 'xã',
            short_codename: 'noi_due',
            parentId: 260,
          },
          {
            title: 'Xã Liên Bão',
            code: 9334,
            value: 'xa_lien_bao',
            division_type: 'xã',
            short_codename: 'lien_bao',
            parentId: 260,
          },
          {
            title: 'Xã Hiên Vân',
            code: 9337,
            value: 'xa_hien_van',
            division_type: 'xã',
            short_codename: 'hien_van',
            parentId: 260,
          },
          {
            title: 'Xã Hoàn Sơn',
            code: 9340,
            value: 'xa_hoan_son',
            division_type: 'xã',
            short_codename: 'hoan_son',
            parentId: 260,
          },
          {
            title: 'Xã Lạc Vệ',
            code: 9343,
            value: 'xa_lac_ve',
            division_type: 'xã',
            short_codename: 'lac_ve',
            parentId: 260,
          },
          {
            title: 'Xã Việt Đoàn',
            code: 9346,
            value: 'xa_viet_doan',
            division_type: 'xã',
            short_codename: 'viet_doan',
            parentId: 260,
          },
          {
            title: 'Xã Phật Tích',
            code: 9349,
            value: 'xa_phat_tich',
            division_type: 'xã',
            short_codename: 'phat_tich',
            parentId: 260,
          },
          {
            title: 'Xã Tân Chi',
            code: 9352,
            value: 'xa_tan_chi',
            division_type: 'xã',
            short_codename: 'tan_chi',
            parentId: 260,
          },
          {
            title: 'Xã Đại Đồng',
            code: 9355,
            value: 'xa_dai_dong',
            division_type: 'xã',
            short_codename: 'dai_dong',
            parentId: 260,
          },
          {
            title: 'Xã Tri Phương',
            code: 9358,
            value: 'xa_tri_phuong',
            division_type: 'xã',
            short_codename: 'tri_phuong',
            parentId: 260,
          },
          {
            title: 'Xã Minh Đạo',
            code: 9361,
            value: 'xa_minh_dao',
            division_type: 'xã',
            short_codename: 'minh_dao',
            parentId: 260,
          },
          {
            title: 'Xã Cảnh Hưng',
            code: 9364,
            value: 'xa_canh_hung',
            division_type: 'xã',
            short_codename: 'canh_hung',
            parentId: 260,
          },
        ],
        parentId: 27,
      },
      {
        title: 'Thị xã Từ Sơn',
        code: 261,
        value: 'thi_xa_tu_son',
        division_type: 'thị xã',
        short_codename: 'tu_son',
        children: [
          {
            title: 'Phường Đông Ngàn',
            code: 9367,
            value: 'phuong_dong_ngan',
            division_type: 'phường',
            short_codename: 'dong_ngan',
            parentId: 261,
          },
          {
            title: 'Phường Tam Sơn',
            code: 9370,
            value: 'phuong_tam_son',
            division_type: 'phường',
            short_codename: 'tam_son',
            parentId: 261,
          },
          {
            title: 'Phường Hương Mạc',
            code: 9373,
            value: 'phuong_huong_mac',
            division_type: 'phường',
            short_codename: 'huong_mac',
            parentId: 261,
          },
          {
            title: 'Phường Tương Giang',
            code: 9376,
            value: 'phuong_tuong_giang',
            division_type: 'phường',
            short_codename: 'tuong_giang',
            parentId: 261,
          },
          {
            title: 'Phường Phù Khê',
            code: 9379,
            value: 'phuong_phu_khe',
            division_type: 'phường',
            short_codename: 'phu_khe',
            parentId: 261,
          },
          {
            title: 'Phường Đồng Kỵ',
            code: 9382,
            value: 'phuong_dong_ky',
            division_type: 'phường',
            short_codename: 'dong_ky',
            parentId: 261,
          },
          {
            title: 'Phường Trang Hạ',
            code: 9383,
            value: 'phuong_trang_ha',
            division_type: 'phường',
            short_codename: 'trang_ha',
            parentId: 261,
          },
          {
            title: 'Phường Đồng Nguyên',
            code: 9385,
            value: 'phuong_dong_nguyen',
            division_type: 'phường',
            short_codename: 'dong_nguyen',
            parentId: 261,
          },
          {
            title: 'Phường Châu Khê',
            code: 9388,
            value: 'phuong_chau_khe',
            division_type: 'phường',
            short_codename: 'chau_khe',
            parentId: 261,
          },
          {
            title: 'Phường Tân Hồng',
            code: 9391,
            value: 'phuong_tan_hong',
            division_type: 'phường',
            short_codename: 'tan_hong',
            parentId: 261,
          },
          {
            title: 'Phường Đình Bảng',
            code: 9394,
            value: 'phuong_dinh_bang',
            division_type: 'phường',
            short_codename: 'dinh_bang',
            parentId: 261,
          },
          {
            title: 'Phường Phù Chẩn',
            code: 9397,
            value: 'phuong_phu_chan',
            division_type: 'phường',
            short_codename: 'phu_chan',
            parentId: 261,
          },
        ],
        parentId: 27,
      },
      {
        title: 'Huyện Thuận Thành',
        code: 262,
        value: 'huyen_thuan_thanh',
        division_type: 'huyện',
        short_codename: 'thuan_thanh',
        children: [
          {
            title: 'Thị trấn Hồ',
            code: 9400,
            value: 'thi_tran_ho',
            division_type: 'thị trấn',
            short_codename: 'ho',
            parentId: 262,
          },
          {
            title: 'Xã Hoài Thượng',
            code: 9403,
            value: 'xa_hoai_thuong',
            division_type: 'xã',
            short_codename: 'hoai_thuong',
            parentId: 262,
          },
          {
            title: 'Xã Đại Đồng Thành',
            code: 9406,
            value: 'xa_dai_dong_thanh',
            division_type: 'xã',
            short_codename: 'dai_dong_thanh',
            parentId: 262,
          },
          {
            title: 'Xã Mão Điền',
            code: 9409,
            value: 'xa_mao_dien',
            division_type: 'xã',
            short_codename: 'mao_dien',
            parentId: 262,
          },
          {
            title: 'Xã Song Hồ',
            code: 9412,
            value: 'xa_song_ho',
            division_type: 'xã',
            short_codename: 'song_ho',
            parentId: 262,
          },
          {
            title: 'Xã Đình Tổ',
            code: 9415,
            value: 'xa_dinh_to',
            division_type: 'xã',
            short_codename: 'dinh_to',
            parentId: 262,
          },
          {
            title: 'Xã An Bình',
            code: 9418,
            value: 'xa_an_binh',
            division_type: 'xã',
            short_codename: 'an_binh',
            parentId: 262,
          },
          {
            title: 'Xã Trí Quả',
            code: 9421,
            value: 'xa_tri_qua',
            division_type: 'xã',
            short_codename: 'tri_qua',
            parentId: 262,
          },
          {
            title: 'Xã Gia Đông',
            code: 9424,
            value: 'xa_gia_dong',
            division_type: 'xã',
            short_codename: 'gia_dong',
            parentId: 262,
          },
          {
            title: 'Xã Thanh Khương',
            code: 9427,
            value: 'xa_thanh_khuong',
            division_type: 'xã',
            short_codename: 'thanh_khuong',
            parentId: 262,
          },
          {
            title: 'Xã Trạm Lộ',
            code: 9430,
            value: 'xa_tram_lo',
            division_type: 'xã',
            short_codename: 'tram_lo',
            parentId: 262,
          },
          {
            title: 'Xã Xuân Lâm',
            code: 9433,
            value: 'xa_xuan_lam',
            division_type: 'xã',
            short_codename: 'xuan_lam',
            parentId: 262,
          },
          {
            title: 'Xã Hà Mãn',
            code: 9436,
            value: 'xa_ha_man',
            division_type: 'xã',
            short_codename: 'ha_man',
            parentId: 262,
          },
          {
            title: 'Xã Ngũ Thái',
            code: 9439,
            value: 'xa_ngu_thai',
            division_type: 'xã',
            short_codename: 'ngu_thai',
            parentId: 262,
          },
          {
            title: 'Xã Nguyệt Đức',
            code: 9442,
            value: 'xa_nguyet_duc',
            division_type: 'xã',
            short_codename: 'nguyet_duc',
            parentId: 262,
          },
          {
            title: 'Xã Ninh Xá',
            code: 9445,
            value: 'xa_ninh_xa',
            division_type: 'xã',
            short_codename: 'ninh_xa',
            parentId: 262,
          },
          {
            title: 'Xã Nghĩa Đạo',
            code: 9448,
            value: 'xa_nghia_dao',
            division_type: 'xã',
            short_codename: 'nghia_dao',
            parentId: 262,
          },
          {
            title: 'Xã Song Liễu',
            code: 9451,
            value: 'xa_song_lieu',
            division_type: 'xã',
            short_codename: 'song_lieu',
            parentId: 262,
          },
        ],
        parentId: 27,
      },
      {
        title: 'Huyện Gia Bình',
        code: 263,
        value: 'huyen_gia_binh',
        division_type: 'huyện',
        short_codename: 'gia_binh',
        children: [
          {
            title: 'Thị trấn Gia Bình',
            code: 9454,
            value: 'thi_tran_gia_binh',
            division_type: 'thị trấn',
            short_codename: 'gia_binh',
            parentId: 263,
          },
          {
            title: 'Xã Vạn Ninh',
            code: 9457,
            value: 'xa_van_ninh',
            division_type: 'xã',
            short_codename: 'van_ninh',
            parentId: 263,
          },
          {
            title: 'Xã Thái Bảo',
            code: 9460,
            value: 'xa_thai_bao',
            division_type: 'xã',
            short_codename: 'thai_bao',
            parentId: 263,
          },
          {
            title: 'Xã Giang Sơn',
            code: 9463,
            value: 'xa_giang_son',
            division_type: 'xã',
            short_codename: 'giang_son',
            parentId: 263,
          },
          {
            title: 'Xã Cao Đức',
            code: 9466,
            value: 'xa_cao_duc',
            division_type: 'xã',
            short_codename: 'cao_duc',
            parentId: 263,
          },
          {
            title: 'Xã Đại Lai',
            code: 9469,
            value: 'xa_dai_lai',
            division_type: 'xã',
            short_codename: 'dai_lai',
            parentId: 263,
          },
          {
            title: 'Xã Song Giang',
            code: 9472,
            value: 'xa_song_giang',
            division_type: 'xã',
            short_codename: 'song_giang',
            parentId: 263,
          },
          {
            title: 'Xã Bình Dương',
            code: 9475,
            value: 'xa_binh_duong',
            division_type: 'xã',
            short_codename: 'binh_duong',
            parentId: 263,
          },
          {
            title: 'Xã Lãng Ngâm',
            code: 9478,
            value: 'xa_lang_ngam',
            division_type: 'xã',
            short_codename: 'lang_ngam',
            parentId: 263,
          },
          {
            title: 'Xã Nhân Thắng',
            code: 9481,
            value: 'xa_nhan_thang',
            division_type: 'xã',
            short_codename: 'nhan_thang',
            parentId: 263,
          },
          {
            title: 'Xã Xuân Lai',
            code: 9484,
            value: 'xa_xuan_lai',
            division_type: 'xã',
            short_codename: 'xuan_lai',
            parentId: 263,
          },
          {
            title: 'Xã Đông Cứu',
            code: 9487,
            value: 'xa_dong_cuu',
            division_type: 'xã',
            short_codename: 'dong_cuu',
            parentId: 263,
          },
          {
            title: 'Xã Đại Bái',
            code: 9490,
            value: 'xa_dai_bai',
            division_type: 'xã',
            short_codename: 'dai_bai',
            parentId: 263,
          },
          {
            title: 'Xã Quỳnh Phú',
            code: 9493,
            value: 'xa_quynh_phu',
            division_type: 'xã',
            short_codename: 'quynh_phu',
            parentId: 263,
          },
        ],
        parentId: 27,
      },
      {
        title: 'Huyện Lương Tài',
        code: 264,
        value: 'huyen_luong_tai',
        division_type: 'huyện',
        short_codename: 'luong_tai',
        children: [
          {
            title: 'Thị trấn Thứa',
            code: 9496,
            value: 'thi_tran_thua',
            division_type: 'thị trấn',
            short_codename: 'thua',
            parentId: 264,
          },
          {
            title: 'Xã An Thịnh',
            code: 9499,
            value: 'xa_an_thinh',
            division_type: 'xã',
            short_codename: 'an_thinh',
            parentId: 264,
          },
          {
            title: 'Xã Trung Kênh',
            code: 9502,
            value: 'xa_trung_kenh',
            division_type: 'xã',
            short_codename: 'trung_kenh',
            parentId: 264,
          },
          {
            title: 'Xã Phú Hòa',
            code: 9505,
            value: 'xa_phu_hoa',
            division_type: 'xã',
            short_codename: 'phu_hoa',
            parentId: 264,
          },
          {
            title: 'Xã Mỹ Hương',
            code: 9508,
            value: 'xa_my_huong',
            division_type: 'xã',
            short_codename: 'my_huong',
            parentId: 264,
          },
          {
            title: 'Xã Tân Lãng',
            code: 9511,
            value: 'xa_tan_lang',
            division_type: 'xã',
            short_codename: 'tan_lang',
            parentId: 264,
          },
          {
            title: 'Xã Quảng Phú',
            code: 9514,
            value: 'xa_quang_phu',
            division_type: 'xã',
            short_codename: 'quang_phu',
            parentId: 264,
          },
          {
            title: 'Xã Trừng Xá',
            code: 9517,
            value: 'xa_trung_xa',
            division_type: 'xã',
            short_codename: 'trung_xa',
            parentId: 264,
          },
          {
            title: 'Xã Lai Hạ',
            code: 9520,
            value: 'xa_lai_ha',
            division_type: 'xã',
            short_codename: 'lai_ha',
            parentId: 264,
          },
          {
            title: 'Xã Trung Chính',
            code: 9523,
            value: 'xa_trung_chinh',
            division_type: 'xã',
            short_codename: 'trung_chinh',
            parentId: 264,
          },
          {
            title: 'Xã Minh Tân',
            code: 9526,
            value: 'xa_minh_tan',
            division_type: 'xã',
            short_codename: 'minh_tan',
            parentId: 264,
          },
          {
            title: 'Xã Bình Định',
            code: 9529,
            value: 'xa_binh_dinh',
            division_type: 'xã',
            short_codename: 'binh_dinh',
            parentId: 264,
          },
          {
            title: 'Xã Phú Lương',
            code: 9532,
            value: 'xa_phu_luong',
            division_type: 'xã',
            short_codename: 'phu_luong',
            parentId: 264,
          },
          {
            title: 'Xã Lâm Thao',
            code: 9535,
            value: 'xa_lam_thao',
            division_type: 'xã',
            short_codename: 'lam_thao',
            parentId: 264,
          },
        ],
        parentId: 27,
      },
    ],
  },
  {
    title: 'Tỉnh Hải Dương',
    code: 30,
    value: 'tinh_hai_duong',
    division_type: 'tỉnh',
    phone_code: 220,
    children: [
      {
        title: 'Thành phố Hải Dương',
        code: 288,
        value: 'thanh_pho_hai_duong',
        division_type: 'thành phố',
        short_codename: 'hai_duong',
        children: [
          {
            title: 'Phường Cẩm Thượng',
            code: 10507,
            value: 'phuong_cam_thuong',
            division_type: 'phường',
            short_codename: 'cam_thuong',
            parentId: 288,
          },
          {
            title: 'Phường Bình Hàn',
            code: 10510,
            value: 'phuong_binh_han',
            division_type: 'phường',
            short_codename: 'binh_han',
            parentId: 288,
          },
          {
            title: 'Phường Ngọc Châu',
            code: 10513,
            value: 'phuong_ngoc_chau',
            division_type: 'phường',
            short_codename: 'ngoc_chau',
            parentId: 288,
          },
          {
            title: 'Phường Nhị Châu',
            code: 10514,
            value: 'phuong_nhi_chau',
            division_type: 'phường',
            short_codename: 'nhi_chau',
            parentId: 288,
          },
          {
            title: 'Phường Quang Trung',
            code: 10516,
            value: 'phuong_quang_trung',
            division_type: 'phường',
            short_codename: 'quang_trung',
            parentId: 288,
          },
          {
            title: 'Phường Nguyễn Trãi',
            code: 10519,
            value: 'phuong_nguyen_trai',
            division_type: 'phường',
            short_codename: 'nguyen_trai',
            parentId: 288,
          },
          {
            title: 'Phường Phạm Ngũ Lão',
            code: 10522,
            value: 'phuong_pham_ngu_lao',
            division_type: 'phường',
            short_codename: 'pham_ngu_lao',
            parentId: 288,
          },
          {
            title: 'Phường Trần Hưng Đạo',
            code: 10525,
            value: 'phuong_tran_hung_dao',
            division_type: 'phường',
            short_codename: 'tran_hung_dao',
            parentId: 288,
          },
          {
            title: 'Phường Trần Phú',
            code: 10528,
            value: 'phuong_tran_phu',
            division_type: 'phường',
            short_codename: 'tran_phu',
            parentId: 288,
          },
          {
            title: 'Phường Thanh Bình',
            code: 10531,
            value: 'phuong_thanh_binh',
            division_type: 'phường',
            short_codename: 'thanh_binh',
            parentId: 288,
          },
          {
            title: 'Phường Tân Bình',
            code: 10532,
            value: 'phuong_tan_binh',
            division_type: 'phường',
            short_codename: 'tan_binh',
            parentId: 288,
          },
          {
            title: 'Phường Lê Thanh Nghị',
            code: 10534,
            value: 'phuong_le_thanh_nghi',
            division_type: 'phường',
            short_codename: 'le_thanh_nghi',
            parentId: 288,
          },
          {
            title: 'Phường Hải Tân',
            code: 10537,
            value: 'phuong_hai_tan',
            division_type: 'phường',
            short_codename: 'hai_tan',
            parentId: 288,
          },
          {
            title: 'Phường Tứ Minh',
            code: 10540,
            value: 'phuong_tu_minh',
            division_type: 'phường',
            short_codename: 'tu_minh',
            parentId: 288,
          },
          {
            title: 'Phường Việt Hoà',
            code: 10543,
            value: 'phuong_viet_hoa',
            division_type: 'phường',
            short_codename: 'viet_hoa',
            parentId: 288,
          },
          {
            title: 'Phường Ái Quốc',
            code: 10660,
            value: 'phuong_ai_quoc',
            division_type: 'phường',
            short_codename: 'ai_quoc',
            parentId: 288,
          },
          {
            title: 'Xã An Thượng',
            code: 10663,
            value: 'xa_an_thuong',
            division_type: 'xã',
            short_codename: 'an_thuong',
            parentId: 288,
          },
          {
            title: 'Phường Nam Đồng',
            code: 10672,
            value: 'phuong_nam_dong',
            division_type: 'phường',
            short_codename: 'nam_dong',
            parentId: 288,
          },
          {
            title: 'Xã Quyết Thắng',
            code: 10822,
            value: 'xa_quyet_thang',
            division_type: 'xã',
            short_codename: 'quyet_thang',
            parentId: 288,
          },
          {
            title: 'Xã Tiền Tiến',
            code: 10837,
            value: 'xa_tien_tien',
            division_type: 'xã',
            short_codename: 'tien_tien',
            parentId: 288,
          },
          {
            title: 'Phường Thạch Khôi',
            code: 11002,
            value: 'phuong_thach_khoi',
            division_type: 'phường',
            short_codename: 'thach_khoi',
            parentId: 288,
          },
          {
            title: 'Xã Liên Hồng',
            code: 11005,
            value: 'xa_lien_hong',
            division_type: 'xã',
            short_codename: 'lien_hong',
            parentId: 288,
          },
          {
            title: 'Phường Tân Hưng',
            code: 11011,
            value: 'phuong_tan_hung',
            division_type: 'phường',
            short_codename: 'tan_hung',
            parentId: 288,
          },
          {
            title: 'Xã Gia Xuyên',
            code: 11017,
            value: 'xa_gia_xuyen',
            division_type: 'xã',
            short_codename: 'gia_xuyen',
            parentId: 288,
          },
          {
            title: 'Xã Ngọc Sơn',
            code: 11077,
            value: 'xa_ngoc_son',
            division_type: 'xã',
            short_codename: 'ngoc_son',
            parentId: 288,
          },
        ],
        parentId: 30,
      },
      {
        title: 'Thành phố Chí Linh',
        code: 290,
        value: 'thanh_pho_chi_linh',
        division_type: 'thành phố',
        short_codename: 'chi_linh',
        children: [
          {
            title: 'Phường Phả Lại',
            code: 10546,
            value: 'phuong_pha_lai',
            division_type: 'phường',
            short_codename: 'pha_lai',
            parentId: 290,
          },
          {
            title: 'Phường Sao Đỏ',
            code: 10549,
            value: 'phuong_sao_do',
            division_type: 'phường',
            short_codename: 'sao_do',
            parentId: 290,
          },
          {
            title: 'Phường Bến Tắm',
            code: 10552,
            value: 'phuong_ben_tam',
            division_type: 'phường',
            short_codename: 'ben_tam',
            parentId: 290,
          },
          {
            title: 'Xã Hoàng Hoa Thám',
            code: 10555,
            value: 'xa_hoang_hoa_tham',
            division_type: 'xã',
            short_codename: 'hoang_hoa_tham',
            parentId: 290,
          },
          {
            title: 'Xã Bắc An',
            code: 10558,
            value: 'xa_bac_an',
            division_type: 'xã',
            short_codename: 'bac_an',
            parentId: 290,
          },
          {
            title: 'Xã Hưng Đạo',
            code: 10561,
            value: 'xa_hung_dao',
            division_type: 'xã',
            short_codename: 'hung_dao',
            parentId: 290,
          },
          {
            title: 'Xã Lê Lợi',
            code: 10564,
            value: 'xa_le_loi',
            division_type: 'xã',
            short_codename: 'le_loi',
            parentId: 290,
          },
          {
            title: 'Phường Hoàng Tiến',
            code: 10567,
            value: 'phuong_hoang_tien',
            division_type: 'phường',
            short_codename: 'hoang_tien',
            parentId: 290,
          },
          {
            title: 'Phường Cộng Hoà',
            code: 10570,
            value: 'phuong_cong_hoa',
            division_type: 'phường',
            short_codename: 'cong_hoa',
            parentId: 290,
          },
          {
            title: 'Phường Hoàng Tân',
            code: 10573,
            value: 'phuong_hoang_tan',
            division_type: 'phường',
            short_codename: 'hoang_tan',
            parentId: 290,
          },
          {
            title: 'Phường Cổ Thành',
            code: 10576,
            value: 'phuong_co_thanh',
            division_type: 'phường',
            short_codename: 'co_thanh',
            parentId: 290,
          },
          {
            title: 'Phường Văn An',
            code: 10579,
            value: 'phuong_van_an',
            division_type: 'phường',
            short_codename: 'van_an',
            parentId: 290,
          },
          {
            title: 'Phường Chí Minh',
            code: 10582,
            value: 'phuong_chi_minh',
            division_type: 'phường',
            short_codename: 'chi_minh',
            parentId: 290,
          },
          {
            title: 'Phường Văn Đức',
            code: 10585,
            value: 'phuong_van_duc',
            division_type: 'phường',
            short_codename: 'van_duc',
            parentId: 290,
          },
          {
            title: 'Phường Thái Học',
            code: 10588,
            value: 'phuong_thai_hoc',
            division_type: 'phường',
            short_codename: 'thai_hoc',
            parentId: 290,
          },
          {
            title: 'Xã Nhân Huệ',
            code: 10591,
            value: 'xa_nhan_hue',
            division_type: 'xã',
            short_codename: 'nhan_hue',
            parentId: 290,
          },
          {
            title: 'Phường An Lạc',
            code: 10594,
            value: 'phuong_an_lac',
            division_type: 'phường',
            short_codename: 'an_lac',
            parentId: 290,
          },
          {
            title: 'Phường Đồng Lạc',
            code: 10600,
            value: 'phuong_dong_lac',
            division_type: 'phường',
            short_codename: 'dong_lac',
            parentId: 290,
          },
          {
            title: 'Phường Tân Dân',
            code: 10603,
            value: 'phuong_tan_dan',
            division_type: 'phường',
            short_codename: 'tan_dan',
            parentId: 290,
          },
        ],
        parentId: 30,
      },
      {
        title: 'Huyện Nam Sách',
        code: 291,
        value: 'huyen_nam_sach',
        division_type: 'huyện',
        short_codename: 'nam_sach',
        children: [
          {
            title: 'Thị trấn Nam Sách',
            code: 10606,
            value: 'thi_tran_nam_sach',
            division_type: 'thị trấn',
            short_codename: 'nam_sach',
            parentId: 291,
          },
          {
            title: 'Xã Nam Hưng',
            code: 10609,
            value: 'xa_nam_hung',
            division_type: 'xã',
            short_codename: 'nam_hung',
            parentId: 291,
          },
          {
            title: 'Xã Nam Tân',
            code: 10612,
            value: 'xa_nam_tan',
            division_type: 'xã',
            short_codename: 'nam_tan',
            parentId: 291,
          },
          {
            title: 'Xã Hợp Tiến',
            code: 10615,
            value: 'xa_hop_tien',
            division_type: 'xã',
            short_codename: 'hop_tien',
            parentId: 291,
          },
          {
            title: 'Xã Hiệp Cát',
            code: 10618,
            value: 'xa_hiep_cat',
            division_type: 'xã',
            short_codename: 'hiep_cat',
            parentId: 291,
          },
          {
            title: 'Xã Thanh Quang',
            code: 10621,
            value: 'xa_thanh_quang',
            division_type: 'xã',
            short_codename: 'thanh_quang',
            parentId: 291,
          },
          {
            title: 'Xã Quốc Tuấn',
            code: 10624,
            value: 'xa_quoc_tuan',
            division_type: 'xã',
            short_codename: 'quoc_tuan',
            parentId: 291,
          },
          {
            title: 'Xã Nam Chính',
            code: 10627,
            value: 'xa_nam_chinh',
            division_type: 'xã',
            short_codename: 'nam_chinh',
            parentId: 291,
          },
          {
            title: 'Xã An Bình',
            code: 10630,
            value: 'xa_an_binh',
            division_type: 'xã',
            short_codename: 'an_binh',
            parentId: 291,
          },
          {
            title: 'Xã Nam Trung',
            code: 10633,
            value: 'xa_nam_trung',
            division_type: 'xã',
            short_codename: 'nam_trung',
            parentId: 291,
          },
          {
            title: 'Xã An Sơn',
            code: 10636,
            value: 'xa_an_son',
            division_type: 'xã',
            short_codename: 'an_son',
            parentId: 291,
          },
          {
            title: 'Xã Cộng Hòa',
            code: 10639,
            value: 'xa_cong_hoa',
            division_type: 'xã',
            short_codename: 'cong_hoa',
            parentId: 291,
          },
          {
            title: 'Xã Thái Tân',
            code: 10642,
            value: 'xa_thai_tan',
            division_type: 'xã',
            short_codename: 'thai_tan',
            parentId: 291,
          },
          {
            title: 'Xã An Lâm',
            code: 10645,
            value: 'xa_an_lam',
            division_type: 'xã',
            short_codename: 'an_lam',
            parentId: 291,
          },
          {
            title: 'Xã Phú Điền',
            code: 10648,
            value: 'xa_phu_dien',
            division_type: 'xã',
            short_codename: 'phu_dien',
            parentId: 291,
          },
          {
            title: 'Xã Nam Hồng',
            code: 10651,
            value: 'xa_nam_hong',
            division_type: 'xã',
            short_codename: 'nam_hong',
            parentId: 291,
          },
          {
            title: 'Xã Hồng Phong',
            code: 10654,
            value: 'xa_hong_phong',
            division_type: 'xã',
            short_codename: 'hong_phong',
            parentId: 291,
          },
          {
            title: 'Xã Đồng Lạc',
            code: 10657,
            value: 'xa_dong_lac',
            division_type: 'xã',
            short_codename: 'dong_lac',
            parentId: 291,
          },
          {
            title: 'Xã Minh Tân',
            code: 10666,
            value: 'xa_minh_tan',
            division_type: 'xã',
            short_codename: 'minh_tan',
            parentId: 291,
          },
        ],
        parentId: 30,
      },
      {
        title: 'Thị xã Kinh Môn',
        code: 292,
        value: 'thi_xa_kinh_mon',
        division_type: 'thị xã',
        short_codename: 'kinh_mon',
        children: [
          {
            title: 'Phường An Lưu',
            code: 10675,
            value: 'phuong_an_luu',
            division_type: 'phường',
            short_codename: 'an_luu',
            parentId: 292,
          },
          {
            title: 'Xã Bạch Đằng',
            code: 10678,
            value: 'xa_bach_dang',
            division_type: 'xã',
            short_codename: 'bach_dang',
            parentId: 292,
          },
          {
            title: 'Phường Thất Hùng',
            code: 10681,
            value: 'phuong_that_hung',
            division_type: 'phường',
            short_codename: 'that_hung',
            parentId: 292,
          },
          {
            title: 'Xã Lê Ninh',
            code: 10684,
            value: 'xa_le_ninh',
            division_type: 'xã',
            short_codename: 'le_ninh',
            parentId: 292,
          },
          {
            title: 'Xã Hoành Sơn',
            code: 10687,
            value: 'xa_hoanh_son',
            division_type: 'xã',
            short_codename: 'hoanh_son',
            parentId: 292,
          },
          {
            title: 'Phường Phạm Thái',
            code: 10693,
            value: 'phuong_pham_thai',
            division_type: 'phường',
            short_codename: 'pham_thai',
            parentId: 292,
          },
          {
            title: 'Phường Duy Tân',
            code: 10696,
            value: 'phuong_duy_tan',
            division_type: 'phường',
            short_codename: 'duy_tan',
            parentId: 292,
          },
          {
            title: 'Phường Tân Dân',
            code: 10699,
            value: 'phuong_tan_dan',
            division_type: 'phường',
            short_codename: 'tan_dan',
            parentId: 292,
          },
          {
            title: 'Phường Minh Tân',
            code: 10702,
            value: 'phuong_minh_tan',
            division_type: 'phường',
            short_codename: 'minh_tan',
            parentId: 292,
          },
          {
            title: 'Xã Quang Thành',
            code: 10705,
            value: 'xa_quang_thanh',
            division_type: 'xã',
            short_codename: 'quang_thanh',
            parentId: 292,
          },
          {
            title: 'Xã Hiệp Hòa',
            code: 10708,
            value: 'xa_hiep_hoa',
            division_type: 'xã',
            short_codename: 'hiep_hoa',
            parentId: 292,
          },
          {
            title: 'Phường Phú Thứ',
            code: 10714,
            value: 'phuong_phu_thu',
            division_type: 'phường',
            short_codename: 'phu_thu',
            parentId: 292,
          },
          {
            title: 'Xã Thăng Long',
            code: 10717,
            value: 'xa_thang_long',
            division_type: 'xã',
            short_codename: 'thang_long',
            parentId: 292,
          },
          {
            title: 'Xã Lạc Long',
            code: 10720,
            value: 'xa_lac_long',
            division_type: 'xã',
            short_codename: 'lac_long',
            parentId: 292,
          },
          {
            title: 'Phường An Sinh',
            code: 10723,
            value: 'phuong_an_sinh',
            division_type: 'phường',
            short_codename: 'an_sinh',
            parentId: 292,
          },
          {
            title: 'Phường Hiệp Sơn',
            code: 10726,
            value: 'phuong_hiep_son',
            division_type: 'phường',
            short_codename: 'hiep_son',
            parentId: 292,
          },
          {
            title: 'Xã Thượng Quận',
            code: 10729,
            value: 'xa_thuong_quan',
            division_type: 'xã',
            short_codename: 'thuong_quan',
            parentId: 292,
          },
          {
            title: 'Phường An Phụ',
            code: 10732,
            value: 'phuong_an_phu',
            division_type: 'phường',
            short_codename: 'an_phu',
            parentId: 292,
          },
          {
            title: 'Phường Hiệp An',
            code: 10735,
            value: 'phuong_hiep_an',
            division_type: 'phường',
            short_codename: 'hiep_an',
            parentId: 292,
          },
          {
            title: 'Phường Long Xuyên',
            code: 10738,
            value: 'phuong_long_xuyen',
            division_type: 'phường',
            short_codename: 'long_xuyen',
            parentId: 292,
          },
          {
            title: 'Phường Thái Thịnh',
            code: 10741,
            value: 'phuong_thai_thinh',
            division_type: 'phường',
            short_codename: 'thai_thinh',
            parentId: 292,
          },
          {
            title: 'Phường Hiến Thành',
            code: 10744,
            value: 'phuong_hien_thanh',
            division_type: 'phường',
            short_codename: 'hien_thanh',
            parentId: 292,
          },
          {
            title: 'Xã Minh Hòa',
            code: 10747,
            value: 'xa_minh_hoa',
            division_type: 'xã',
            short_codename: 'minh_hoa',
            parentId: 292,
          },
        ],
        parentId: 30,
      },
      {
        title: 'Huyện Kim Thành',
        code: 293,
        value: 'huyen_kim_thanh',
        division_type: 'huyện',
        short_codename: 'kim_thanh',
        children: [
          {
            title: 'Thị trấn Phú Thái',
            code: 10750,
            value: 'thi_tran_phu_thai',
            division_type: 'thị trấn',
            short_codename: 'phu_thai',
            parentId: 293,
          },
          {
            title: 'Xã Lai Vu',
            code: 10753,
            value: 'xa_lai_vu',
            division_type: 'xã',
            short_codename: 'lai_vu',
            parentId: 293,
          },
          {
            title: 'Xã Cộng Hòa',
            code: 10756,
            value: 'xa_cong_hoa',
            division_type: 'xã',
            short_codename: 'cong_hoa',
            parentId: 293,
          },
          {
            title: 'Xã Thượng Vũ',
            code: 10759,
            value: 'xa_thuong_vu',
            division_type: 'xã',
            short_codename: 'thuong_vu',
            parentId: 293,
          },
          {
            title: 'Xã Cổ Dũng',
            code: 10762,
            value: 'xa_co_dung',
            division_type: 'xã',
            short_codename: 'co_dung',
            parentId: 293,
          },
          {
            title: 'Xã Tuấn Việt',
            code: 10768,
            value: 'xa_tuan_viet',
            division_type: 'xã',
            short_codename: 'tuan_viet',
            parentId: 293,
          },
          {
            title: 'Xã Kim Xuyên',
            code: 10771,
            value: 'xa_kim_xuyen',
            division_type: 'xã',
            short_codename: 'kim_xuyen',
            parentId: 293,
          },
          {
            title: 'Xã Phúc Thành A',
            code: 10774,
            value: 'xa_phuc_thanh_a',
            division_type: 'xã',
            short_codename: 'phuc_thanh_a',
            parentId: 293,
          },
          {
            title: 'Xã Ngũ Phúc',
            code: 10777,
            value: 'xa_ngu_phuc',
            division_type: 'xã',
            short_codename: 'ngu_phuc',
            parentId: 293,
          },
          {
            title: 'Xã Kim Anh',
            code: 10780,
            value: 'xa_kim_anh',
            division_type: 'xã',
            short_codename: 'kim_anh',
            parentId: 293,
          },
          {
            title: 'Xã Kim Liên',
            code: 10783,
            value: 'xa_kim_lien',
            division_type: 'xã',
            short_codename: 'kim_lien',
            parentId: 293,
          },
          {
            title: 'Xã Kim Tân',
            code: 10786,
            value: 'xa_kim_tan',
            division_type: 'xã',
            short_codename: 'kim_tan',
            parentId: 293,
          },
          {
            title: 'Xã Kim Đính',
            code: 10792,
            value: 'xa_kim_dinh',
            division_type: 'xã',
            short_codename: 'kim_dinh',
            parentId: 293,
          },
          {
            title: 'Xã Bình Dân',
            code: 10798,
            value: 'xa_binh_dan',
            division_type: 'xã',
            short_codename: 'binh_dan',
            parentId: 293,
          },
          {
            title: 'Xã Tam Kỳ',
            code: 10801,
            value: 'xa_tam_ky',
            division_type: 'xã',
            short_codename: 'tam_ky',
            parentId: 293,
          },
          {
            title: 'Xã Đồng Cẩm',
            code: 10804,
            value: 'xa_dong_cam',
            division_type: 'xã',
            short_codename: 'dong_cam',
            parentId: 293,
          },
          {
            title: 'Xã Liên Hòa',
            code: 10807,
            value: 'xa_lien_hoa',
            division_type: 'xã',
            short_codename: 'lien_hoa',
            parentId: 293,
          },
          {
            title: 'Xã Đại Đức',
            code: 10810,
            value: 'xa_dai_duc',
            division_type: 'xã',
            short_codename: 'dai_duc',
            parentId: 293,
          },
        ],
        parentId: 30,
      },
      {
        title: 'Huyện Thanh Hà',
        code: 294,
        value: 'huyen_thanh_ha',
        division_type: 'huyện',
        short_codename: 'thanh_ha',
        children: [
          {
            title: 'Thị trấn Thanh Hà',
            code: 10813,
            value: 'thi_tran_thanh_ha',
            division_type: 'thị trấn',
            short_codename: 'thanh_ha',
            parentId: 294,
          },
          {
            title: 'Xã Hồng Lạc',
            code: 10816,
            value: 'xa_hong_lac',
            division_type: 'xã',
            short_codename: 'hong_lac',
            parentId: 294,
          },
          {
            title: 'Xã Việt Hồng',
            code: 10819,
            value: 'xa_viet_hong',
            division_type: 'xã',
            short_codename: 'viet_hong',
            parentId: 294,
          },
          {
            title: 'Xã Tân Việt',
            code: 10825,
            value: 'xa_tan_viet',
            division_type: 'xã',
            short_codename: 'tan_viet',
            parentId: 294,
          },
          {
            title: 'Xã Cẩm Chế',
            code: 10828,
            value: 'xa_cam_che',
            division_type: 'xã',
            short_codename: 'cam_che',
            parentId: 294,
          },
          {
            title: 'Xã Thanh An',
            code: 10831,
            value: 'xa_thanh_an',
            division_type: 'xã',
            short_codename: 'thanh_an',
            parentId: 294,
          },
          {
            title: 'Xã Thanh Lang',
            code: 10834,
            value: 'xa_thanh_lang',
            division_type: 'xã',
            short_codename: 'thanh_lang',
            parentId: 294,
          },
          {
            title: 'Xã Tân An',
            code: 10840,
            value: 'xa_tan_an',
            division_type: 'xã',
            short_codename: 'tan_an',
            parentId: 294,
          },
          {
            title: 'Xã Liên Mạc',
            code: 10843,
            value: 'xa_lien_mac',
            division_type: 'xã',
            short_codename: 'lien_mac',
            parentId: 294,
          },
          {
            title: 'Xã Thanh Hải',
            code: 10846,
            value: 'xa_thanh_hai',
            division_type: 'xã',
            short_codename: 'thanh_hai',
            parentId: 294,
          },
          {
            title: 'Xã Thanh Khê',
            code: 10849,
            value: 'xa_thanh_khe',
            division_type: 'xã',
            short_codename: 'thanh_khe',
            parentId: 294,
          },
          {
            title: 'Xã Thanh Xá',
            code: 10852,
            value: 'xa_thanh_xa',
            division_type: 'xã',
            short_codename: 'thanh_xa',
            parentId: 294,
          },
          {
            title: 'Xã Thanh Xuân',
            code: 10855,
            value: 'xa_thanh_xuan',
            division_type: 'xã',
            short_codename: 'thanh_xuan',
            parentId: 294,
          },
          {
            title: 'Xã Thanh Thủy',
            code: 10861,
            value: 'xa_thanh_thuy',
            division_type: 'xã',
            short_codename: 'thanh_thuy',
            parentId: 294,
          },
          {
            title: 'Xã An Phượng',
            code: 10864,
            value: 'xa_an_phuong',
            division_type: 'xã',
            short_codename: 'an_phuong',
            parentId: 294,
          },
          {
            title: 'Xã Thanh Sơn',
            code: 10867,
            value: 'xa_thanh_son',
            division_type: 'xã',
            short_codename: 'thanh_son',
            parentId: 294,
          },
          {
            title: 'Xã Thanh Quang',
            code: 10876,
            value: 'xa_thanh_quang',
            division_type: 'xã',
            short_codename: 'thanh_quang',
            parentId: 294,
          },
          {
            title: 'Xã Thanh Hồng',
            code: 10879,
            value: 'xa_thanh_hong',
            division_type: 'xã',
            short_codename: 'thanh_hong',
            parentId: 294,
          },
          {
            title: 'Xã Thanh Cường',
            code: 10882,
            value: 'xa_thanh_cuong',
            division_type: 'xã',
            short_codename: 'thanh_cuong',
            parentId: 294,
          },
          {
            title: 'Xã Vĩnh Lập',
            code: 10885,
            value: 'xa_vinh_lap',
            division_type: 'xã',
            short_codename: 'vinh_lap',
            parentId: 294,
          },
        ],
        parentId: 30,
      },
      {
        title: 'Huyện Cẩm Giàng',
        code: 295,
        value: 'huyen_cam_giang',
        division_type: 'huyện',
        short_codename: 'cam_giang',
        children: [
          {
            title: 'Thị trấn Cẩm Giang',
            code: 10888,
            value: 'thi_tran_cam_giang',
            division_type: 'thị trấn',
            short_codename: 'cam_giang',
            parentId: 295,
          },
          {
            title: 'Thị trấn Lai Cách',
            code: 10891,
            value: 'thi_tran_lai_cach',
            division_type: 'thị trấn',
            short_codename: 'lai_cach',
            parentId: 295,
          },
          {
            title: 'Xã Cẩm Hưng',
            code: 10894,
            value: 'xa_cam_hung',
            division_type: 'xã',
            short_codename: 'cam_hung',
            parentId: 295,
          },
          {
            title: 'Xã Cẩm Hoàng',
            code: 10897,
            value: 'xa_cam_hoang',
            division_type: 'xã',
            short_codename: 'cam_hoang',
            parentId: 295,
          },
          {
            title: 'Xã Cẩm Văn',
            code: 10900,
            value: 'xa_cam_van',
            division_type: 'xã',
            short_codename: 'cam_van',
            parentId: 295,
          },
          {
            title: 'Xã Ngọc Liên',
            code: 10903,
            value: 'xa_ngoc_lien',
            division_type: 'xã',
            short_codename: 'ngoc_lien',
            parentId: 295,
          },
          {
            title: 'Xã Thạch Lỗi',
            code: 10906,
            value: 'xa_thach_loi',
            division_type: 'xã',
            short_codename: 'thach_loi',
            parentId: 295,
          },
          {
            title: 'Xã Cẩm Vũ',
            code: 10909,
            value: 'xa_cam_vu',
            division_type: 'xã',
            short_codename: 'cam_vu',
            parentId: 295,
          },
          {
            title: 'Xã Đức Chính',
            code: 10912,
            value: 'xa_duc_chinh',
            division_type: 'xã',
            short_codename: 'duc_chinh',
            parentId: 295,
          },
          {
            title: 'Xã Định Sơn',
            code: 10918,
            value: 'xa_dinh_son',
            division_type: 'xã',
            short_codename: 'dinh_son',
            parentId: 295,
          },
          {
            title: 'Xã Lương Điền',
            code: 10924,
            value: 'xa_luong_dien',
            division_type: 'xã',
            short_codename: 'luong_dien',
            parentId: 295,
          },
          {
            title: 'Xã Cao An',
            code: 10927,
            value: 'xa_cao_an',
            division_type: 'xã',
            short_codename: 'cao_an',
            parentId: 295,
          },
          {
            title: 'Xã Tân Trường',
            code: 10930,
            value: 'xa_tan_truong',
            division_type: 'xã',
            short_codename: 'tan_truong',
            parentId: 295,
          },
          {
            title: 'Xã Cẩm Phúc',
            code: 10933,
            value: 'xa_cam_phuc',
            division_type: 'xã',
            short_codename: 'cam_phuc',
            parentId: 295,
          },
          {
            title: 'Xã Cẩm Điền',
            code: 10936,
            value: 'xa_cam_dien',
            division_type: 'xã',
            short_codename: 'cam_dien',
            parentId: 295,
          },
          {
            title: 'Xã Cẩm Đông',
            code: 10939,
            value: 'xa_cam_dong',
            division_type: 'xã',
            short_codename: 'cam_dong',
            parentId: 295,
          },
          {
            title: 'Xã Cẩm Đoài',
            code: 10942,
            value: 'xa_cam_doai',
            division_type: 'xã',
            short_codename: 'cam_doai',
            parentId: 295,
          },
        ],
        parentId: 30,
      },
      {
        title: 'Huyện Bình Giang',
        code: 296,
        value: 'huyen_binh_giang',
        division_type: 'huyện',
        short_codename: 'binh_giang',
        children: [
          {
            title: 'Thị trấn Kẻ Sặt',
            code: 10945,
            value: 'thi_tran_ke_sat',
            division_type: 'thị trấn',
            short_codename: 'ke_sat',
            parentId: 296,
          },
          {
            title: 'Xã Vĩnh Hưng',
            code: 10951,
            value: 'xa_vinh_hung',
            division_type: 'xã',
            short_codename: 'vinh_hung',
            parentId: 296,
          },
          {
            title: 'Xã Hùng Thắng',
            code: 10954,
            value: 'xa_hung_thang',
            division_type: 'xã',
            short_codename: 'hung_thang',
            parentId: 296,
          },
          {
            title: 'Xã Vĩnh Hồng',
            code: 10960,
            value: 'xa_vinh_hong',
            division_type: 'xã',
            short_codename: 'vinh_hong',
            parentId: 296,
          },
          {
            title: 'Xã Long Xuyên',
            code: 10963,
            value: 'xa_long_xuyen',
            division_type: 'xã',
            short_codename: 'long_xuyen',
            parentId: 296,
          },
          {
            title: 'Xã Tân Việt',
            code: 10966,
            value: 'xa_tan_viet',
            division_type: 'xã',
            short_codename: 'tan_viet',
            parentId: 296,
          },
          {
            title: 'Xã Thúc Kháng',
            code: 10969,
            value: 'xa_thuc_khang',
            division_type: 'xã',
            short_codename: 'thuc_khang',
            parentId: 296,
          },
          {
            title: 'Xã Tân Hồng',
            code: 10972,
            value: 'xa_tan_hong',
            division_type: 'xã',
            short_codename: 'tan_hong',
            parentId: 296,
          },
          {
            title: 'Xã Bình Minh',
            code: 10975,
            value: 'xa_binh_minh',
            division_type: 'xã',
            short_codename: 'binh_minh',
            parentId: 296,
          },
          {
            title: 'Xã Hồng Khê',
            code: 10978,
            value: 'xa_hong_khe',
            division_type: 'xã',
            short_codename: 'hong_khe',
            parentId: 296,
          },
          {
            title: 'Xã Thái Học',
            code: 10981,
            value: 'xa_thai_hoc',
            division_type: 'xã',
            short_codename: 'thai_hoc',
            parentId: 296,
          },
          {
            title: 'Xã Cổ Bì',
            code: 10984,
            value: 'xa_co_bi',
            division_type: 'xã',
            short_codename: 'co_bi',
            parentId: 296,
          },
          {
            title: 'Xã Nhân Quyền',
            code: 10987,
            value: 'xa_nhan_quyen',
            division_type: 'xã',
            short_codename: 'nhan_quyen',
            parentId: 296,
          },
          {
            title: 'Xã Thái Dương',
            code: 10990,
            value: 'xa_thai_duong',
            division_type: 'xã',
            short_codename: 'thai_duong',
            parentId: 296,
          },
          {
            title: 'Xã Thái Hòa',
            code: 10993,
            value: 'xa_thai_hoa',
            division_type: 'xã',
            short_codename: 'thai_hoa',
            parentId: 296,
          },
          {
            title: 'Xã Bình Xuyên',
            code: 10996,
            value: 'xa_binh_xuyen',
            division_type: 'xã',
            short_codename: 'binh_xuyen',
            parentId: 296,
          },
        ],
        parentId: 30,
      },
      {
        title: 'Huyện Gia Lộc',
        code: 297,
        value: 'huyen_gia_loc',
        division_type: 'huyện',
        short_codename: 'gia_loc',
        children: [
          {
            title: 'Thị trấn Gia Lộc',
            code: 10999,
            value: 'thi_tran_gia_loc',
            division_type: 'thị trấn',
            short_codename: 'gia_loc',
            parentId: 297,
          },
          {
            title: 'Xã Thống Nhất',
            code: 11008,
            value: 'xa_thong_nhat',
            division_type: 'xã',
            short_codename: 'thong_nhat',
            parentId: 297,
          },
          {
            title: 'Xã Yết Kiêu',
            code: 11020,
            value: 'xa_yet_kieu',
            division_type: 'xã',
            short_codename: 'yet_kieu',
            parentId: 297,
          },
          {
            title: 'Xã Gia Tân',
            code: 11029,
            value: 'xa_gia_tan',
            division_type: 'xã',
            short_codename: 'gia_tan',
            parentId: 297,
          },
          {
            title: 'Xã Tân Tiến',
            code: 11032,
            value: 'xa_tan_tien',
            division_type: 'xã',
            short_codename: 'tan_tien',
            parentId: 297,
          },
          {
            title: 'Xã Gia Khánh',
            code: 11035,
            value: 'xa_gia_khanh',
            division_type: 'xã',
            short_codename: 'gia_khanh',
            parentId: 297,
          },
          {
            title: 'Xã Gia Lương',
            code: 11038,
            value: 'xa_gia_luong',
            division_type: 'xã',
            short_codename: 'gia_luong',
            parentId: 297,
          },
          {
            title: 'Xã Lê Lợi',
            code: 11041,
            value: 'xa_le_loi',
            division_type: 'xã',
            short_codename: 'le_loi',
            parentId: 297,
          },
          {
            title: 'Xã Toàn Thắng',
            code: 11044,
            value: 'xa_toan_thang',
            division_type: 'xã',
            short_codename: 'toan_thang',
            parentId: 297,
          },
          {
            title: 'Xã Hoàng Diệu',
            code: 11047,
            value: 'xa_hoang_dieu',
            division_type: 'xã',
            short_codename: 'hoang_dieu',
            parentId: 297,
          },
          {
            title: 'Xã Hồng Hưng',
            code: 11050,
            value: 'xa_hong_hung',
            division_type: 'xã',
            short_codename: 'hong_hung',
            parentId: 297,
          },
          {
            title: 'Xã Phạm Trấn',
            code: 11053,
            value: 'xa_pham_tran',
            division_type: 'xã',
            short_codename: 'pham_tran',
            parentId: 297,
          },
          {
            title: 'Xã Đoàn Thượng',
            code: 11056,
            value: 'xa_doan_thuong',
            division_type: 'xã',
            short_codename: 'doan_thuong',
            parentId: 297,
          },
          {
            title: 'Xã Thống Kênh',
            code: 11059,
            value: 'xa_thong_kenh',
            division_type: 'xã',
            short_codename: 'thong_kenh',
            parentId: 297,
          },
          {
            title: 'Xã Quang Minh',
            code: 11062,
            value: 'xa_quang_minh',
            division_type: 'xã',
            short_codename: 'quang_minh',
            parentId: 297,
          },
          {
            title: 'Xã Đồng Quang',
            code: 11065,
            value: 'xa_dong_quang',
            division_type: 'xã',
            short_codename: 'dong_quang',
            parentId: 297,
          },
          {
            title: 'Xã Nhật Tân',
            code: 11068,
            value: 'xa_nhat_tan',
            division_type: 'xã',
            short_codename: 'nhat_tan',
            parentId: 297,
          },
          {
            title: 'Xã Đức Xương',
            code: 11071,
            value: 'xa_duc_xuong',
            division_type: 'xã',
            short_codename: 'duc_xuong',
            parentId: 297,
          },
        ],
        parentId: 30,
      },
      {
        title: 'Huyện Tứ Kỳ',
        code: 298,
        value: 'huyen_tu_ky',
        division_type: 'huyện',
        short_codename: 'tu_ky',
        children: [
          {
            title: 'Thị trấn Tứ Kỳ',
            code: 11074,
            value: 'thi_tran_tu_ky',
            division_type: 'thị trấn',
            short_codename: 'tu_ky',
            parentId: 298,
          },
          {
            title: 'Xã Đại Sơn',
            code: 11083,
            value: 'xa_dai_son',
            division_type: 'xã',
            short_codename: 'dai_son',
            parentId: 298,
          },
          {
            title: 'Xã Hưng Đạo',
            code: 11086,
            value: 'xa_hung_dao',
            division_type: 'xã',
            short_codename: 'hung_dao',
            parentId: 298,
          },
          {
            title: 'Xã Ngọc Kỳ',
            code: 11089,
            value: 'xa_ngoc_ky',
            division_type: 'xã',
            short_codename: 'ngoc_ky',
            parentId: 298,
          },
          {
            title: 'Xã Bình Lăng',
            code: 11092,
            value: 'xa_binh_lang',
            division_type: 'xã',
            short_codename: 'binh_lang',
            parentId: 298,
          },
          {
            title: 'Xã Chí Minh',
            code: 11095,
            value: 'xa_chi_minh',
            division_type: 'xã',
            short_codename: 'chi_minh',
            parentId: 298,
          },
          {
            title: 'Xã Tái Sơn',
            code: 11098,
            value: 'xa_tai_son',
            division_type: 'xã',
            short_codename: 'tai_son',
            parentId: 298,
          },
          {
            title: 'Xã Quang Phục',
            code: 11101,
            value: 'xa_quang_phuc',
            division_type: 'xã',
            short_codename: 'quang_phuc',
            parentId: 298,
          },
          {
            title: 'Xã Dân Chủ',
            code: 11110,
            value: 'xa_dan_chu',
            division_type: 'xã',
            short_codename: 'dan_chu',
            parentId: 298,
          },
          {
            title: 'Xã Tân Kỳ',
            code: 11113,
            value: 'xa_tan_ky',
            division_type: 'xã',
            short_codename: 'tan_ky',
            parentId: 298,
          },
          {
            title: 'Xã Quang Khải',
            code: 11116,
            value: 'xa_quang_khai',
            division_type: 'xã',
            short_codename: 'quang_khai',
            parentId: 298,
          },
          {
            title: 'Xã Đại Hợp',
            code: 11119,
            value: 'xa_dai_hop',
            division_type: 'xã',
            short_codename: 'dai_hop',
            parentId: 298,
          },
          {
            title: 'Xã Quảng Nghiệp',
            code: 11122,
            value: 'xa_quang_nghiep',
            division_type: 'xã',
            short_codename: 'quang_nghiep',
            parentId: 298,
          },
          {
            title: 'Xã An Thanh',
            code: 11125,
            value: 'xa_an_thanh',
            division_type: 'xã',
            short_codename: 'an_thanh',
            parentId: 298,
          },
          {
            title: 'Xã Minh Đức',
            code: 11128,
            value: 'xa_minh_duc',
            division_type: 'xã',
            short_codename: 'minh_duc',
            parentId: 298,
          },
          {
            title: 'Xã Văn Tố',
            code: 11131,
            value: 'xa_van_to',
            division_type: 'xã',
            short_codename: 'van_to',
            parentId: 298,
          },
          {
            title: 'Xã Quang Trung',
            code: 11134,
            value: 'xa_quang_trung',
            division_type: 'xã',
            short_codename: 'quang_trung',
            parentId: 298,
          },
          {
            title: 'Xã Phượng Kỳ',
            code: 11137,
            value: 'xa_phuong_ky',
            division_type: 'xã',
            short_codename: 'phuong_ky',
            parentId: 298,
          },
          {
            title: 'Xã Cộng Lạc',
            code: 11140,
            value: 'xa_cong_lac',
            division_type: 'xã',
            short_codename: 'cong_lac',
            parentId: 298,
          },
          {
            title: 'Xã Tiên Động',
            code: 11143,
            value: 'xa_tien_dong',
            division_type: 'xã',
            short_codename: 'tien_dong',
            parentId: 298,
          },
          {
            title: 'Xã Nguyên Giáp',
            code: 11146,
            value: 'xa_nguyen_giap',
            division_type: 'xã',
            short_codename: 'nguyen_giap',
            parentId: 298,
          },
          {
            title: 'Xã Hà Kỳ',
            code: 11149,
            value: 'xa_ha_ky',
            division_type: 'xã',
            short_codename: 'ha_ky',
            parentId: 298,
          },
          {
            title: 'Xã Hà Thanh',
            code: 11152,
            value: 'xa_ha_thanh',
            division_type: 'xã',
            short_codename: 'ha_thanh',
            parentId: 298,
          },
        ],
        parentId: 30,
      },
      {
        title: 'Huyện Ninh Giang',
        code: 299,
        value: 'huyen_ninh_giang',
        division_type: 'huyện',
        short_codename: 'ninh_giang',
        children: [
          {
            title: 'Thị trấn Ninh Giang',
            code: 11155,
            value: 'thi_tran_ninh_giang',
            division_type: 'thị trấn',
            short_codename: 'ninh_giang',
            parentId: 299,
          },
          {
            title: 'Xã Ứng Hoè',
            code: 11161,
            value: 'xa_ung_hoe',
            division_type: 'xã',
            short_codename: 'ung_hoe',
            parentId: 299,
          },
          {
            title: 'Xã Nghĩa An',
            code: 11164,
            value: 'xa_nghia_an',
            division_type: 'xã',
            short_codename: 'nghia_an',
            parentId: 299,
          },
          {
            title: 'Xã Hồng Đức',
            code: 11167,
            value: 'xa_hong_duc',
            division_type: 'xã',
            short_codename: 'hong_duc',
            parentId: 299,
          },
          {
            title: 'Xã An Đức',
            code: 11173,
            value: 'xa_an_duc',
            division_type: 'xã',
            short_codename: 'an_duc',
            parentId: 299,
          },
          {
            title: 'Xã Vạn Phúc',
            code: 11176,
            value: 'xa_van_phuc',
            division_type: 'xã',
            short_codename: 'van_phuc',
            parentId: 299,
          },
          {
            title: 'Xã Tân Hương',
            code: 11179,
            value: 'xa_tan_huong',
            division_type: 'xã',
            short_codename: 'tan_huong',
            parentId: 299,
          },
          {
            title: 'Xã Vĩnh Hòa',
            code: 11185,
            value: 'xa_vinh_hoa',
            division_type: 'xã',
            short_codename: 'vinh_hoa',
            parentId: 299,
          },
          {
            title: 'Xã Đông Xuyên',
            code: 11188,
            value: 'xa_dong_xuyen',
            division_type: 'xã',
            short_codename: 'dong_xuyen',
            parentId: 299,
          },
          {
            title: 'Xã Tân Phong',
            code: 11197,
            value: 'xa_tan_phong',
            division_type: 'xã',
            short_codename: 'tan_phong',
            parentId: 299,
          },
          {
            title: 'Xã Ninh Hải',
            code: 11200,
            value: 'xa_ninh_hai',
            division_type: 'xã',
            short_codename: 'ninh_hai',
            parentId: 299,
          },
          {
            title: 'Xã Đồng Tâm',
            code: 11203,
            value: 'xa_dong_tam',
            division_type: 'xã',
            short_codename: 'dong_tam',
            parentId: 299,
          },
          {
            title: 'Xã Tân Quang',
            code: 11206,
            value: 'xa_tan_quang',
            division_type: 'xã',
            short_codename: 'tan_quang',
            parentId: 299,
          },
          {
            title: 'Xã Kiến Quốc',
            code: 11209,
            value: 'xa_kien_quoc',
            division_type: 'xã',
            short_codename: 'kien_quoc',
            parentId: 299,
          },
          {
            title: 'Xã Hồng Dụ',
            code: 11215,
            value: 'xa_hong_du',
            division_type: 'xã',
            short_codename: 'hong_du',
            parentId: 299,
          },
          {
            title: 'Xã Văn Hội',
            code: 11218,
            value: 'xa_van_hoi',
            division_type: 'xã',
            short_codename: 'van_hoi',
            parentId: 299,
          },
          {
            title: 'Xã Hồng Phong',
            code: 11224,
            value: 'xa_hong_phong',
            division_type: 'xã',
            short_codename: 'hong_phong',
            parentId: 299,
          },
          {
            title: 'Xã Hiệp Lực',
            code: 11227,
            value: 'xa_hiep_luc',
            division_type: 'xã',
            short_codename: 'hiep_luc',
            parentId: 299,
          },
          {
            title: 'Xã Hồng Phúc',
            code: 11230,
            value: 'xa_hong_phuc',
            division_type: 'xã',
            short_codename: 'hong_phuc',
            parentId: 299,
          },
          {
            title: 'Xã Hưng Long',
            code: 11233,
            value: 'xa_hung_long',
            division_type: 'xã',
            short_codename: 'hung_long',
            parentId: 299,
          },
        ],
        parentId: 30,
      },
      {
        title: 'Huyện Thanh Miện',
        code: 300,
        value: 'huyen_thanh_mien',
        division_type: 'huyện',
        short_codename: 'thanh_mien',
        children: [
          {
            title: 'Thị trấn Thanh Miện',
            code: 11239,
            value: 'thi_tran_thanh_mien',
            division_type: 'thị trấn',
            short_codename: 'thanh_mien',
            parentId: 300,
          },
          {
            title: 'Xã Thanh Tùng',
            code: 11242,
            value: 'xa_thanh_tung',
            division_type: 'xã',
            short_codename: 'thanh_tung',
            parentId: 300,
          },
          {
            title: 'Xã Phạm Kha',
            code: 11245,
            value: 'xa_pham_kha',
            division_type: 'xã',
            short_codename: 'pham_kha',
            parentId: 300,
          },
          {
            title: 'Xã Ngô Quyền',
            code: 11248,
            value: 'xa_ngo_quyen',
            division_type: 'xã',
            short_codename: 'ngo_quyen',
            parentId: 300,
          },
          {
            title: 'Xã Đoàn Tùng',
            code: 11251,
            value: 'xa_doan_tung',
            division_type: 'xã',
            short_codename: 'doan_tung',
            parentId: 300,
          },
          {
            title: 'Xã Hồng Quang',
            code: 11254,
            value: 'xa_hong_quang',
            division_type: 'xã',
            short_codename: 'hong_quang',
            parentId: 300,
          },
          {
            title: 'Xã Tân Trào',
            code: 11257,
            value: 'xa_tan_trao',
            division_type: 'xã',
            short_codename: 'tan_trao',
            parentId: 300,
          },
          {
            title: 'Xã Lam Sơn',
            code: 11260,
            value: 'xa_lam_son',
            division_type: 'xã',
            short_codename: 'lam_son',
            parentId: 300,
          },
          {
            title: 'Xã Đoàn Kết',
            code: 11263,
            value: 'xa_doan_ket',
            division_type: 'xã',
            short_codename: 'doan_ket',
            parentId: 300,
          },
          {
            title: 'Xã Lê Hồng',
            code: 11266,
            value: 'xa_le_hong',
            division_type: 'xã',
            short_codename: 'le_hong',
            parentId: 300,
          },
          {
            title: 'Xã Tứ Cường',
            code: 11269,
            value: 'xa_tu_cuong',
            division_type: 'xã',
            short_codename: 'tu_cuong',
            parentId: 300,
          },
          {
            title: 'Xã Ngũ Hùng',
            code: 11275,
            value: 'xa_ngu_hung',
            division_type: 'xã',
            short_codename: 'ngu_hung',
            parentId: 300,
          },
          {
            title: 'Xã Cao Thắng',
            code: 11278,
            value: 'xa_cao_thang',
            division_type: 'xã',
            short_codename: 'cao_thang',
            parentId: 300,
          },
          {
            title: 'Xã Chi Lăng Bắc',
            code: 11281,
            value: 'xa_chi_lang_bac',
            division_type: 'xã',
            short_codename: 'chi_lang_bac',
            parentId: 300,
          },
          {
            title: 'Xã Chi Lăng Nam',
            code: 11284,
            value: 'xa_chi_lang_nam',
            division_type: 'xã',
            short_codename: 'chi_lang_nam',
            parentId: 300,
          },
          {
            title: 'Xã Thanh Giang',
            code: 11287,
            value: 'xa_thanh_giang',
            division_type: 'xã',
            short_codename: 'thanh_giang',
            parentId: 300,
          },
          {
            title: 'Xã Hồng Phong',
            code: 11293,
            value: 'xa_hong_phong',
            division_type: 'xã',
            short_codename: 'hong_phong',
            parentId: 300,
          },
        ],
        parentId: 30,
      },
    ],
  },
  {
    title: 'Thành phố Hải Phòng',
    code: 31,
    value: 'thanh_pho_hai_phong',
    division_type: 'thành phố trung ương',
    phone_code: 225,
    children: [
      {
        title: 'Quận Hồng Bàng',
        code: 303,
        value: 'quan_hong_bang',
        division_type: 'quận',
        short_codename: 'hong_bang',
        children: [
          {
            title: 'Phường Quán Toan',
            code: 11296,
            value: 'phuong_quan_toan',
            division_type: 'phường',
            short_codename: 'quan_toan',
            parentId: 303,
          },
          {
            title: 'Phường Hùng Vương',
            code: 11299,
            value: 'phuong_hung_vuong',
            division_type: 'phường',
            short_codename: 'hung_vuong',
            parentId: 303,
          },
          {
            title: 'Phường Sở Dầu',
            code: 11302,
            value: 'phuong_so_dau',
            division_type: 'phường',
            short_codename: 'so_dau',
            parentId: 303,
          },
          {
            title: 'Phường Thượng Lý',
            code: 11305,
            value: 'phuong_thuong_ly',
            division_type: 'phường',
            short_codename: 'thuong_ly',
            parentId: 303,
          },
          {
            title: 'Phường Hạ Lý',
            code: 11308,
            value: 'phuong_ha_ly',
            division_type: 'phường',
            short_codename: 'ha_ly',
            parentId: 303,
          },
          {
            title: 'Phường Minh Khai',
            code: 11311,
            value: 'phuong_minh_khai',
            division_type: 'phường',
            short_codename: 'minh_khai',
            parentId: 303,
          },
          {
            title: 'Phường Trại Chuối',
            code: 11314,
            value: 'phuong_trai_chuoi',
            division_type: 'phường',
            short_codename: 'trai_chuoi',
            parentId: 303,
          },
          {
            title: 'Phường Hoàng Văn Thụ',
            code: 11320,
            value: 'phuong_hoang_van_thu',
            division_type: 'phường',
            short_codename: 'hoang_van_thu',
            parentId: 303,
          },
          {
            title: 'Phường Phan Bội Châu',
            code: 11323,
            value: 'phuong_phan_boi_chau',
            division_type: 'phường',
            short_codename: 'phan_boi_chau',
            parentId: 303,
          },
        ],
        parentId: 31,
      },
      {
        title: 'Quận Ngô Quyền',
        code: 304,
        value: 'quan_ngo_quyen',
        division_type: 'quận',
        short_codename: 'ngo_quyen',
        children: [
          {
            title: 'Phường Máy Chai',
            code: 11329,
            value: 'phuong_may_chai',
            division_type: 'phường',
            short_codename: 'may_chai',
            parentId: 304,
          },
          {
            title: 'Phường Máy Tơ',
            code: 11332,
            value: 'phuong_may_to',
            division_type: 'phường',
            short_codename: 'may_to',
            parentId: 304,
          },
          {
            title: 'Phường Vạn Mỹ',
            code: 11335,
            value: 'phuong_van_my',
            division_type: 'phường',
            short_codename: 'van_my',
            parentId: 304,
          },
          {
            title: 'Phường Cầu Tre',
            code: 11338,
            value: 'phuong_cau_tre',
            division_type: 'phường',
            short_codename: 'cau_tre',
            parentId: 304,
          },
          {
            title: 'Phường Lạc Viên',
            code: 11341,
            value: 'phuong_lac_vien',
            division_type: 'phường',
            short_codename: 'lac_vien',
            parentId: 304,
          },
          {
            title: 'Phường Gia Viên',
            code: 11347,
            value: 'phuong_gia_vien',
            division_type: 'phường',
            short_codename: 'gia_vien',
            parentId: 304,
          },
          {
            title: 'Phường Đông Khê',
            code: 11350,
            value: 'phuong_dong_khe',
            division_type: 'phường',
            short_codename: 'dong_khe',
            parentId: 304,
          },
          {
            title: 'Phường Cầu Đất',
            code: 11353,
            value: 'phuong_cau_dat',
            division_type: 'phường',
            short_codename: 'cau_dat',
            parentId: 304,
          },
          {
            title: 'Phường Lê Lợi',
            code: 11356,
            value: 'phuong_le_loi',
            division_type: 'phường',
            short_codename: 'le_loi',
            parentId: 304,
          },
          {
            title: 'Phường Đằng Giang',
            code: 11359,
            value: 'phuong_dang_giang',
            division_type: 'phường',
            short_codename: 'dang_giang',
            parentId: 304,
          },
          {
            title: 'Phường Lạch Tray',
            code: 11362,
            value: 'phuong_lach_tray',
            division_type: 'phường',
            short_codename: 'lach_tray',
            parentId: 304,
          },
          {
            title: 'Phường Đổng Quốc Bình',
            code: 11365,
            value: 'phuong_dong_quoc_binh',
            division_type: 'phường',
            short_codename: 'dong_quoc_binh',
            parentId: 304,
          },
        ],
        parentId: 31,
      },
      {
        title: 'Quận Lê Chân',
        code: 305,
        value: 'quan_le_chan',
        division_type: 'quận',
        short_codename: 'le_chan',
        children: [
          {
            title: 'Phường Cát Dài',
            code: 11368,
            value: 'phuong_cat_dai',
            division_type: 'phường',
            short_codename: 'cat_dai',
            parentId: 305,
          },
          {
            title: 'Phường An Biên',
            code: 11371,
            value: 'phuong_an_bien',
            division_type: 'phường',
            short_codename: 'an_bien',
            parentId: 305,
          },
          {
            title: 'Phường Lam Sơn',
            code: 11374,
            value: 'phuong_lam_son',
            division_type: 'phường',
            short_codename: 'lam_son',
            parentId: 305,
          },
          {
            title: 'Phường An Dương',
            code: 11377,
            value: 'phuong_an_duong',
            division_type: 'phường',
            short_codename: 'an_duong',
            parentId: 305,
          },
          {
            title: 'Phường Trần Nguyên Hãn',
            code: 11380,
            value: 'phuong_tran_nguyen_han',
            division_type: 'phường',
            short_codename: 'tran_nguyen_han',
            parentId: 305,
          },
          {
            title: 'Phường Hồ Nam',
            code: 11383,
            value: 'phuong_ho_nam',
            division_type: 'phường',
            short_codename: 'ho_nam',
            parentId: 305,
          },
          {
            title: 'Phường Trại Cau',
            code: 11386,
            value: 'phuong_trai_cau',
            division_type: 'phường',
            short_codename: 'trai_cau',
            parentId: 305,
          },
          {
            title: 'Phường Dư Hàng',
            code: 11389,
            value: 'phuong_du_hang',
            division_type: 'phường',
            short_codename: 'du_hang',
            parentId: 305,
          },
          {
            title: 'Phường Hàng Kênh',
            code: 11392,
            value: 'phuong_hang_kenh',
            division_type: 'phường',
            short_codename: 'hang_kenh',
            parentId: 305,
          },
          {
            title: 'Phường Đông Hải',
            code: 11395,
            value: 'phuong_dong_hai',
            division_type: 'phường',
            short_codename: 'dong_hai',
            parentId: 305,
          },
          {
            title: 'Phường Niệm Nghĩa',
            code: 11398,
            value: 'phuong_niem_nghia',
            division_type: 'phường',
            short_codename: 'niem_nghia',
            parentId: 305,
          },
          {
            title: 'Phường Nghĩa Xá',
            code: 11401,
            value: 'phuong_nghia_xa',
            division_type: 'phường',
            short_codename: 'nghia_xa',
            parentId: 305,
          },
          {
            title: 'Phường Dư Hàng Kênh',
            code: 11404,
            value: 'phuong_du_hang_kenh',
            division_type: 'phường',
            short_codename: 'du_hang_kenh',
            parentId: 305,
          },
          {
            title: 'Phường Kênh Dương',
            code: 11405,
            value: 'phuong_kenh_duong',
            division_type: 'phường',
            short_codename: 'kenh_duong',
            parentId: 305,
          },
          {
            title: 'Phường Vĩnh Niệm',
            code: 11407,
            value: 'phuong_vinh_niem',
            division_type: 'phường',
            short_codename: 'vinh_niem',
            parentId: 305,
          },
        ],
        parentId: 31,
      },
      {
        title: 'Quận Hải An',
        code: 306,
        value: 'quan_hai_an',
        division_type: 'quận',
        short_codename: 'hai_an',
        children: [
          {
            title: 'Phường Đông Hải 1',
            code: 11410,
            value: 'phuong_dong_hai_1',
            division_type: 'phường',
            short_codename: 'dong_hai_1',
            parentId: 306,
          },
          {
            title: 'Phường Đông Hải 2',
            code: 11411,
            value: 'phuong_dong_hai_2',
            division_type: 'phường',
            short_codename: 'dong_hai_2',
            parentId: 306,
          },
          {
            title: 'Phường Đằng Lâm',
            code: 11413,
            value: 'phuong_dang_lam',
            division_type: 'phường',
            short_codename: 'dang_lam',
            parentId: 306,
          },
          {
            title: 'Phường Thành Tô',
            code: 11414,
            value: 'phuong_thanh_to',
            division_type: 'phường',
            short_codename: 'thanh_to',
            parentId: 306,
          },
          {
            title: 'Phường Đằng Hải',
            code: 11416,
            value: 'phuong_dang_hai',
            division_type: 'phường',
            short_codename: 'dang_hai',
            parentId: 306,
          },
          {
            title: 'Phường Nam Hải',
            code: 11419,
            value: 'phuong_nam_hai',
            division_type: 'phường',
            short_codename: 'nam_hai',
            parentId: 306,
          },
          {
            title: 'Phường Cát Bi',
            code: 11422,
            value: 'phuong_cat_bi',
            division_type: 'phường',
            short_codename: 'cat_bi',
            parentId: 306,
          },
          {
            title: 'Phường Tràng Cát',
            code: 11425,
            value: 'phuong_trang_cat',
            division_type: 'phường',
            short_codename: 'trang_cat',
            parentId: 306,
          },
        ],
        parentId: 31,
      },
      {
        title: 'Quận Kiến An',
        code: 307,
        value: 'quan_kien_an',
        division_type: 'quận',
        short_codename: 'kien_an',
        children: [
          {
            title: 'Phường Quán Trữ',
            code: 11428,
            value: 'phuong_quan_tru',
            division_type: 'phường',
            short_codename: 'quan_tru',
            parentId: 307,
          },
          {
            title: 'Phường Lãm Hà',
            code: 11429,
            value: 'phuong_lam_ha',
            division_type: 'phường',
            short_codename: 'lam_ha',
            parentId: 307,
          },
          {
            title: 'Phường Đồng Hoà',
            code: 11431,
            value: 'phuong_dong_hoa',
            division_type: 'phường',
            short_codename: 'dong_hoa',
            parentId: 307,
          },
          {
            title: 'Phường Bắc Sơn',
            code: 11434,
            value: 'phuong_bac_son',
            division_type: 'phường',
            short_codename: 'bac_son',
            parentId: 307,
          },
          {
            title: 'Phường Nam Sơn',
            code: 11437,
            value: 'phuong_nam_son',
            division_type: 'phường',
            short_codename: 'nam_son',
            parentId: 307,
          },
          {
            title: 'Phường Ngọc Sơn',
            code: 11440,
            value: 'phuong_ngoc_son',
            division_type: 'phường',
            short_codename: 'ngoc_son',
            parentId: 307,
          },
          {
            title: 'Phường Trần Thành Ngọ',
            code: 11443,
            value: 'phuong_tran_thanh_ngo',
            division_type: 'phường',
            short_codename: 'tran_thanh_ngo',
            parentId: 307,
          },
          {
            title: 'Phường Văn Đẩu',
            code: 11446,
            value: 'phuong_van_dau',
            division_type: 'phường',
            short_codename: 'van_dau',
            parentId: 307,
          },
          {
            title: 'Phường Phù Liễn',
            code: 11449,
            value: 'phuong_phu_lien',
            division_type: 'phường',
            short_codename: 'phu_lien',
            parentId: 307,
          },
          {
            title: 'Phường Tràng Minh',
            code: 11452,
            value: 'phuong_trang_minh',
            division_type: 'phường',
            short_codename: 'trang_minh',
            parentId: 307,
          },
        ],
        parentId: 31,
      },
      {
        title: 'Quận Đồ Sơn',
        code: 308,
        value: 'quan_do_son',
        division_type: 'quận',
        short_codename: 'do_son',
        children: [
          {
            title: 'Phường Ngọc Xuyên',
            code: 11455,
            value: 'phuong_ngoc_xuyen',
            division_type: 'phường',
            short_codename: 'ngoc_xuyen',
            parentId: 308,
          },
          {
            title: 'Phường Hải Sơn',
            code: 11458,
            value: 'phuong_hai_son',
            division_type: 'phường',
            short_codename: 'hai_son',
            parentId: 308,
          },
          {
            title: 'Phường Vạn Hương',
            code: 11461,
            value: 'phuong_van_huong',
            division_type: 'phường',
            short_codename: 'van_huong',
            parentId: 308,
          },
          {
            title: 'Phường Minh Đức',
            code: 11465,
            value: 'phuong_minh_duc',
            division_type: 'phường',
            short_codename: 'minh_duc',
            parentId: 308,
          },
          {
            title: 'Phường Bàng La',
            code: 11467,
            value: 'phuong_bang_la',
            division_type: 'phường',
            short_codename: 'bang_la',
            parentId: 308,
          },
          {
            title: 'Phường Hợp Đức',
            code: 11737,
            value: 'phuong_hop_duc',
            division_type: 'phường',
            short_codename: 'hop_duc',
            parentId: 308,
          },
        ],
        parentId: 31,
      },
      {
        title: 'Quận Dương Kinh',
        code: 309,
        value: 'quan_duong_kinh',
        division_type: 'quận',
        short_codename: 'duong_kinh',
        children: [
          {
            title: 'Phường Đa Phúc',
            code: 11683,
            value: 'phuong_da_phuc',
            division_type: 'phường',
            short_codename: 'da_phuc',
            parentId: 309,
          },
          {
            title: 'Phường Hưng Đạo',
            code: 11686,
            value: 'phuong_hung_dao',
            division_type: 'phường',
            short_codename: 'hung_dao',
            parentId: 309,
          },
          {
            title: 'Phường Anh Dũng',
            code: 11689,
            value: 'phuong_anh_dung',
            division_type: 'phường',
            short_codename: 'anh_dung',
            parentId: 309,
          },
          {
            title: 'Phường Hải Thành',
            code: 11692,
            value: 'phuong_hai_thanh',
            division_type: 'phường',
            short_codename: 'hai_thanh',
            parentId: 309,
          },
          {
            title: 'Phường Hoà Nghĩa',
            code: 11707,
            value: 'phuong_hoa_nghia',
            division_type: 'phường',
            short_codename: 'hoa_nghia',
            parentId: 309,
          },
          {
            title: 'Phường Tân Thành',
            code: 11740,
            value: 'phuong_tan_thanh',
            division_type: 'phường',
            short_codename: 'tan_thanh',
            parentId: 309,
          },
        ],
        parentId: 31,
      },
      {
        title: 'Huyện Thuỷ Nguyên',
        code: 311,
        value: 'huyen_thuy_nguyen',
        division_type: 'huyện',
        short_codename: 'thuy_nguyen',
        children: [
          {
            title: 'Thị trấn Núi Đèo',
            code: 11470,
            value: 'thi_tran_nui_deo',
            division_type: 'thị trấn',
            short_codename: 'nui_deo',
            parentId: 311,
          },
          {
            title: 'Thị trấn Minh Đức',
            code: 11473,
            value: 'thi_tran_minh_duc',
            division_type: 'thị trấn',
            short_codename: 'minh_duc',
            parentId: 311,
          },
          {
            title: 'Xã Lại Xuân',
            code: 11476,
            value: 'xa_lai_xuan',
            division_type: 'xã',
            short_codename: 'lai_xuan',
            parentId: 311,
          },
          {
            title: 'Xã An Sơn',
            code: 11479,
            value: 'xa_an_son',
            division_type: 'xã',
            short_codename: 'an_son',
            parentId: 311,
          },
          {
            title: 'Xã Kỳ Sơn',
            code: 11482,
            value: 'xa_ky_son',
            division_type: 'xã',
            short_codename: 'ky_son',
            parentId: 311,
          },
          {
            title: 'Xã Liên Khê',
            code: 11485,
            value: 'xa_lien_khe',
            division_type: 'xã',
            short_codename: 'lien_khe',
            parentId: 311,
          },
          {
            title: 'Xã Lưu Kiếm',
            code: 11488,
            value: 'xa_luu_kiem',
            division_type: 'xã',
            short_codename: 'luu_kiem',
            parentId: 311,
          },
          {
            title: 'Xã Lưu Kỳ',
            code: 11491,
            value: 'xa_luu_ky',
            division_type: 'xã',
            short_codename: 'luu_ky',
            parentId: 311,
          },
          {
            title: 'Xã Gia Minh',
            code: 11494,
            value: 'xa_gia_minh',
            division_type: 'xã',
            short_codename: 'gia_minh',
            parentId: 311,
          },
          {
            title: 'Xã Gia Đức',
            code: 11497,
            value: 'xa_gia_duc',
            division_type: 'xã',
            short_codename: 'gia_duc',
            parentId: 311,
          },
          {
            title: 'Xã Minh Tân',
            code: 11500,
            value: 'xa_minh_tan',
            division_type: 'xã',
            short_codename: 'minh_tan',
            parentId: 311,
          },
          {
            title: 'Xã Phù Ninh',
            code: 11503,
            value: 'xa_phu_ninh',
            division_type: 'xã',
            short_codename: 'phu_ninh',
            parentId: 311,
          },
          {
            title: 'Xã Quảng Thanh',
            code: 11506,
            value: 'xa_quang_thanh',
            division_type: 'xã',
            short_codename: 'quang_thanh',
            parentId: 311,
          },
          {
            title: 'Xã Chính Mỹ',
            code: 11509,
            value: 'xa_chinh_my',
            division_type: 'xã',
            short_codename: 'chinh_my',
            parentId: 311,
          },
          {
            title: 'Xã Kênh Giang',
            code: 11512,
            value: 'xa_kenh_giang',
            division_type: 'xã',
            short_codename: 'kenh_giang',
            parentId: 311,
          },
          {
            title: 'Xã Hợp Thành',
            code: 11515,
            value: 'xa_hop_thanh',
            division_type: 'xã',
            short_codename: 'hop_thanh',
            parentId: 311,
          },
          {
            title: 'Xã Cao Nhân',
            code: 11518,
            value: 'xa_cao_nhan',
            division_type: 'xã',
            short_codename: 'cao_nhan',
            parentId: 311,
          },
          {
            title: 'Xã Mỹ Đồng',
            code: 11521,
            value: 'xa_my_dong',
            division_type: 'xã',
            short_codename: 'my_dong',
            parentId: 311,
          },
          {
            title: 'Xã Đông Sơn',
            code: 11524,
            value: 'xa_dong_son',
            division_type: 'xã',
            short_codename: 'dong_son',
            parentId: 311,
          },
          {
            title: 'Xã Hoà Bình',
            code: 11527,
            value: 'xa_hoa_binh',
            division_type: 'xã',
            short_codename: 'hoa_binh',
            parentId: 311,
          },
          {
            title: 'Xã Trung Hà',
            code: 11530,
            value: 'xa_trung_ha',
            division_type: 'xã',
            short_codename: 'trung_ha',
            parentId: 311,
          },
          {
            title: 'Xã An Lư',
            code: 11533,
            value: 'xa_an_lu',
            division_type: 'xã',
            short_codename: 'an_lu',
            parentId: 311,
          },
          {
            title: 'Xã Thuỷ Triều',
            code: 11536,
            value: 'xa_thuy_trieu',
            division_type: 'xã',
            short_codename: 'thuy_trieu',
            parentId: 311,
          },
          {
            title: 'Xã Ngũ Lão',
            code: 11539,
            value: 'xa_ngu_lao',
            division_type: 'xã',
            short_codename: 'ngu_lao',
            parentId: 311,
          },
          {
            title: 'Xã Phục Lễ',
            code: 11542,
            value: 'xa_phuc_le',
            division_type: 'xã',
            short_codename: 'phuc_le',
            parentId: 311,
          },
          {
            title: 'Xã Tam Hưng',
            code: 11545,
            value: 'xa_tam_hung',
            division_type: 'xã',
            short_codename: 'tam_hung',
            parentId: 311,
          },
          {
            title: 'Xã Phả Lễ',
            code: 11548,
            value: 'xa_pha_le',
            division_type: 'xã',
            short_codename: 'pha_le',
            parentId: 311,
          },
          {
            title: 'Xã Lập Lễ',
            code: 11551,
            value: 'xa_lap_le',
            division_type: 'xã',
            short_codename: 'lap_le',
            parentId: 311,
          },
          {
            title: 'Xã Kiền Bái',
            code: 11554,
            value: 'xa_kien_bai',
            division_type: 'xã',
            short_codename: 'kien_bai',
            parentId: 311,
          },
          {
            title: 'Xã Thiên Hương',
            code: 11557,
            value: 'xa_thien_huong',
            division_type: 'xã',
            short_codename: 'thien_huong',
            parentId: 311,
          },
          {
            title: 'Xã Thuỷ Sơn',
            code: 11560,
            value: 'xa_thuy_son',
            division_type: 'xã',
            short_codename: 'thuy_son',
            parentId: 311,
          },
          {
            title: 'Xã Thuỷ Đường',
            code: 11563,
            value: 'xa_thuy_duong',
            division_type: 'xã',
            short_codename: 'thuy_duong',
            parentId: 311,
          },
          {
            title: 'Xã Hoàng Động',
            code: 11566,
            value: 'xa_hoang_dong',
            division_type: 'xã',
            short_codename: 'hoang_dong',
            parentId: 311,
          },
          {
            title: 'Xã Lâm Động',
            code: 11569,
            value: 'xa_lam_dong',
            division_type: 'xã',
            short_codename: 'lam_dong',
            parentId: 311,
          },
          {
            title: 'Xã Hoa Động',
            code: 11572,
            value: 'xa_hoa_dong',
            division_type: 'xã',
            short_codename: 'hoa_dong',
            parentId: 311,
          },
          {
            title: 'Xã Tân Dương',
            code: 11575,
            value: 'xa_tan_duong',
            division_type: 'xã',
            short_codename: 'tan_duong',
            parentId: 311,
          },
          {
            title: 'Xã Dương Quan',
            code: 11578,
            value: 'xa_duong_quan',
            division_type: 'xã',
            short_codename: 'duong_quan',
            parentId: 311,
          },
        ],
        parentId: 31,
      },
      {
        title: 'Huyện An Dương',
        code: 312,
        value: 'huyen_an_duong',
        division_type: 'huyện',
        short_codename: 'an_duong',
        children: [
          {
            title: 'Thị trấn An Dương',
            code: 11581,
            value: 'thi_tran_an_duong',
            division_type: 'thị trấn',
            short_codename: 'an_duong',
            parentId: 312,
          },
          {
            title: 'Xã Lê Thiện',
            code: 11584,
            value: 'xa_le_thien',
            division_type: 'xã',
            short_codename: 'le_thien',
            parentId: 312,
          },
          {
            title: 'Xã Đại Bản',
            code: 11587,
            value: 'xa_dai_ban',
            division_type: 'xã',
            short_codename: 'dai_ban',
            parentId: 312,
          },
          {
            title: 'Xã An Hoà',
            code: 11590,
            value: 'xa_an_hoa',
            division_type: 'xã',
            short_codename: 'an_hoa',
            parentId: 312,
          },
          {
            title: 'Xã Hồng Phong',
            code: 11593,
            value: 'xa_hong_phong',
            division_type: 'xã',
            short_codename: 'hong_phong',
            parentId: 312,
          },
          {
            title: 'Xã Tân Tiến',
            code: 11596,
            value: 'xa_tan_tien',
            division_type: 'xã',
            short_codename: 'tan_tien',
            parentId: 312,
          },
          {
            title: 'Xã An Hưng',
            code: 11599,
            value: 'xa_an_hung',
            division_type: 'xã',
            short_codename: 'an_hung',
            parentId: 312,
          },
          {
            title: 'Xã An Hồng',
            code: 11602,
            value: 'xa_an_hong',
            division_type: 'xã',
            short_codename: 'an_hong',
            parentId: 312,
          },
          {
            title: 'Xã Bắc Sơn',
            code: 11605,
            value: 'xa_bac_son',
            division_type: 'xã',
            short_codename: 'bac_son',
            parentId: 312,
          },
          {
            title: 'Xã Nam Sơn',
            code: 11608,
            value: 'xa_nam_son',
            division_type: 'xã',
            short_codename: 'nam_son',
            parentId: 312,
          },
          {
            title: 'Xã Lê Lợi',
            code: 11611,
            value: 'xa_le_loi',
            division_type: 'xã',
            short_codename: 'le_loi',
            parentId: 312,
          },
          {
            title: 'Xã Đặng Cương',
            code: 11614,
            value: 'xa_dang_cuong',
            division_type: 'xã',
            short_codename: 'dang_cuong',
            parentId: 312,
          },
          {
            title: 'Xã Đồng Thái',
            code: 11617,
            value: 'xa_dong_thai',
            division_type: 'xã',
            short_codename: 'dong_thai',
            parentId: 312,
          },
          {
            title: 'Xã Quốc Tuấn',
            code: 11620,
            value: 'xa_quoc_tuan',
            division_type: 'xã',
            short_codename: 'quoc_tuan',
            parentId: 312,
          },
          {
            title: 'Xã An Đồng',
            code: 11623,
            value: 'xa_an_dong',
            division_type: 'xã',
            short_codename: 'an_dong',
            parentId: 312,
          },
          {
            title: 'Xã Hồng Thái',
            code: 11626,
            value: 'xa_hong_thai',
            division_type: 'xã',
            short_codename: 'hong_thai',
            parentId: 312,
          },
        ],
        parentId: 31,
      },
      {
        title: 'Huyện An Lão',
        code: 313,
        value: 'huyen_an_lao',
        division_type: 'huyện',
        short_codename: 'an_lao',
        children: [
          {
            title: 'Thị trấn An Lão',
            code: 11629,
            value: 'thi_tran_an_lao',
            division_type: 'thị trấn',
            short_codename: 'an_lao',
            parentId: 313,
          },
          {
            title: 'Xã Bát Trang',
            code: 11632,
            value: 'xa_bat_trang',
            division_type: 'xã',
            short_codename: 'bat_trang',
            parentId: 313,
          },
          {
            title: 'Xã Trường Thọ',
            code: 11635,
            value: 'xa_truong_tho',
            division_type: 'xã',
            short_codename: 'truong_tho',
            parentId: 313,
          },
          {
            title: 'Xã Trường Thành',
            code: 11638,
            value: 'xa_truong_thanh',
            division_type: 'xã',
            short_codename: 'truong_thanh',
            parentId: 313,
          },
          {
            title: 'Xã An Tiến',
            code: 11641,
            value: 'xa_an_tien',
            division_type: 'xã',
            short_codename: 'an_tien',
            parentId: 313,
          },
          {
            title: 'Xã Quang Hưng',
            code: 11644,
            value: 'xa_quang_hung',
            division_type: 'xã',
            short_codename: 'quang_hung',
            parentId: 313,
          },
          {
            title: 'Xã Quang Trung',
            code: 11647,
            value: 'xa_quang_trung',
            division_type: 'xã',
            short_codename: 'quang_trung',
            parentId: 313,
          },
          {
            title: 'Xã Quốc Tuấn',
            code: 11650,
            value: 'xa_quoc_tuan',
            division_type: 'xã',
            short_codename: 'quoc_tuan',
            parentId: 313,
          },
          {
            title: 'Xã An Thắng',
            code: 11653,
            value: 'xa_an_thang',
            division_type: 'xã',
            short_codename: 'an_thang',
            parentId: 313,
          },
          {
            title: 'Thị trấn Trường Sơn',
            code: 11656,
            value: 'thi_tran_truong_son',
            division_type: 'thị trấn',
            short_codename: 'truong_son',
            parentId: 313,
          },
          {
            title: 'Xã Tân Dân',
            code: 11659,
            value: 'xa_tan_dan',
            division_type: 'xã',
            short_codename: 'tan_dan',
            parentId: 313,
          },
          {
            title: 'Xã Thái Sơn',
            code: 11662,
            value: 'xa_thai_son',
            division_type: 'xã',
            short_codename: 'thai_son',
            parentId: 313,
          },
          {
            title: 'Xã Tân Viên',
            code: 11665,
            value: 'xa_tan_vien',
            division_type: 'xã',
            short_codename: 'tan_vien',
            parentId: 313,
          },
          {
            title: 'Xã Mỹ Đức',
            code: 11668,
            value: 'xa_my_duc',
            division_type: 'xã',
            short_codename: 'my_duc',
            parentId: 313,
          },
          {
            title: 'Xã Chiến Thắng',
            code: 11671,
            value: 'xa_chien_thang',
            division_type: 'xã',
            short_codename: 'chien_thang',
            parentId: 313,
          },
          {
            title: 'Xã An Thọ',
            code: 11674,
            value: 'xa_an_tho',
            division_type: 'xã',
            short_codename: 'an_tho',
            parentId: 313,
          },
          {
            title: 'Xã An Thái',
            code: 11677,
            value: 'xa_an_thai',
            division_type: 'xã',
            short_codename: 'an_thai',
            parentId: 313,
          },
        ],
        parentId: 31,
      },
      {
        title: 'Huyện Kiến Thuỵ',
        code: 314,
        value: 'huyen_kien_thuy',
        division_type: 'huyện',
        short_codename: 'kien_thuy',
        children: [
          {
            title: 'Thị trấn Núi Đối',
            code: 11680,
            value: 'thi_tran_nui_doi',
            division_type: 'thị trấn',
            short_codename: 'nui_doi',
            parentId: 314,
          },
          {
            title: 'Xã Đông Phương',
            code: 11695,
            value: 'xa_dong_phuong',
            division_type: 'xã',
            short_codename: 'dong_phuong',
            parentId: 314,
          },
          {
            title: 'Xã Thuận Thiên',
            code: 11698,
            value: 'xa_thuan_thien',
            division_type: 'xã',
            short_codename: 'thuan_thien',
            parentId: 314,
          },
          {
            title: 'Xã Hữu Bằng',
            code: 11701,
            value: 'xa_huu_bang',
            division_type: 'xã',
            short_codename: 'huu_bang',
            parentId: 314,
          },
          {
            title: 'Xã Đại Đồng',
            code: 11704,
            value: 'xa_dai_dong',
            division_type: 'xã',
            short_codename: 'dai_dong',
            parentId: 314,
          },
          {
            title: 'Xã Ngũ Phúc',
            code: 11710,
            value: 'xa_ngu_phuc',
            division_type: 'xã',
            short_codename: 'ngu_phuc',
            parentId: 314,
          },
          {
            title: 'Xã Kiến Quốc',
            code: 11713,
            value: 'xa_kien_quoc',
            division_type: 'xã',
            short_codename: 'kien_quoc',
            parentId: 314,
          },
          {
            title: 'Xã Du Lễ',
            code: 11716,
            value: 'xa_du_le',
            division_type: 'xã',
            short_codename: 'du_le',
            parentId: 314,
          },
          {
            title: 'Xã Thuỵ Hương',
            code: 11719,
            value: 'xa_thuy_huong',
            division_type: 'xã',
            short_codename: 'thuy_huong',
            parentId: 314,
          },
          {
            title: 'Xã Thanh Sơn',
            code: 11722,
            value: 'xa_thanh_son',
            division_type: 'xã',
            short_codename: 'thanh_son',
            parentId: 314,
          },
          {
            title: 'Xã Minh Tân',
            code: 11725,
            value: 'xa_minh_tan',
            division_type: 'xã',
            short_codename: 'minh_tan',
            parentId: 314,
          },
          {
            title: 'Xã Đại Hà',
            code: 11728,
            value: 'xa_dai_ha',
            division_type: 'xã',
            short_codename: 'dai_ha',
            parentId: 314,
          },
          {
            title: 'Xã Ngũ Đoan',
            code: 11731,
            value: 'xa_ngu_doan',
            division_type: 'xã',
            short_codename: 'ngu_doan',
            parentId: 314,
          },
          {
            title: 'Xã Tân Phong',
            code: 11734,
            value: 'xa_tan_phong',
            division_type: 'xã',
            short_codename: 'tan_phong',
            parentId: 314,
          },
          {
            title: 'Xã Tân Trào',
            code: 11743,
            value: 'xa_tan_trao',
            division_type: 'xã',
            short_codename: 'tan_trao',
            parentId: 314,
          },
          {
            title: 'Xã Đoàn Xá',
            code: 11746,
            value: 'xa_doan_xa',
            division_type: 'xã',
            short_codename: 'doan_xa',
            parentId: 314,
          },
          {
            title: 'Xã Tú Sơn',
            code: 11749,
            value: 'xa_tu_son',
            division_type: 'xã',
            short_codename: 'tu_son',
            parentId: 314,
          },
          {
            title: 'Xã Đại Hợp',
            code: 11752,
            value: 'xa_dai_hop',
            division_type: 'xã',
            short_codename: 'dai_hop',
            parentId: 314,
          },
        ],
        parentId: 31,
      },
      {
        title: 'Huyện Tiên Lãng',
        code: 315,
        value: 'huyen_tien_lang',
        division_type: 'huyện',
        short_codename: 'tien_lang',
        children: [
          {
            title: 'Thị trấn Tiên Lãng',
            code: 11755,
            value: 'thi_tran_tien_lang',
            division_type: 'thị trấn',
            short_codename: 'tien_lang',
            parentId: 315,
          },
          {
            title: 'Xã Đại Thắng',
            code: 11758,
            value: 'xa_dai_thang',
            division_type: 'xã',
            short_codename: 'dai_thang',
            parentId: 315,
          },
          {
            title: 'Xã Tiên Cường',
            code: 11761,
            value: 'xa_tien_cuong',
            division_type: 'xã',
            short_codename: 'tien_cuong',
            parentId: 315,
          },
          {
            title: 'Xã Tự Cường',
            code: 11764,
            value: 'xa_tu_cuong',
            division_type: 'xã',
            short_codename: 'tu_cuong',
            parentId: 315,
          },
          {
            title: 'Xã Quyết Tiến',
            code: 11770,
            value: 'xa_quyet_tien',
            division_type: 'xã',
            short_codename: 'quyet_tien',
            parentId: 315,
          },
          {
            title: 'Xã Khởi Nghĩa',
            code: 11773,
            value: 'xa_khoi_nghia',
            division_type: 'xã',
            short_codename: 'khoi_nghia',
            parentId: 315,
          },
          {
            title: 'Xã Tiên Thanh',
            code: 11776,
            value: 'xa_tien_thanh',
            division_type: 'xã',
            short_codename: 'tien_thanh',
            parentId: 315,
          },
          {
            title: 'Xã Cấp Tiến',
            code: 11779,
            value: 'xa_cap_tien',
            division_type: 'xã',
            short_codename: 'cap_tien',
            parentId: 315,
          },
          {
            title: 'Xã Kiến Thiết',
            code: 11782,
            value: 'xa_kien_thiet',
            division_type: 'xã',
            short_codename: 'kien_thiet',
            parentId: 315,
          },
          {
            title: 'Xã Đoàn Lập',
            code: 11785,
            value: 'xa_doan_lap',
            division_type: 'xã',
            short_codename: 'doan_lap',
            parentId: 315,
          },
          {
            title: 'Xã Bạch Đằng',
            code: 11788,
            value: 'xa_bach_dang',
            division_type: 'xã',
            short_codename: 'bach_dang',
            parentId: 315,
          },
          {
            title: 'Xã Quang Phục',
            code: 11791,
            value: 'xa_quang_phuc',
            division_type: 'xã',
            short_codename: 'quang_phuc',
            parentId: 315,
          },
          {
            title: 'Xã Toàn Thắng',
            code: 11794,
            value: 'xa_toan_thang',
            division_type: 'xã',
            short_codename: 'toan_thang',
            parentId: 315,
          },
          {
            title: 'Xã Tiên Thắng',
            code: 11797,
            value: 'xa_tien_thang',
            division_type: 'xã',
            short_codename: 'tien_thang',
            parentId: 315,
          },
          {
            title: 'Xã Tiên Minh',
            code: 11800,
            value: 'xa_tien_minh',
            division_type: 'xã',
            short_codename: 'tien_minh',
            parentId: 315,
          },
          {
            title: 'Xã Bắc Hưng',
            code: 11803,
            value: 'xa_bac_hung',
            division_type: 'xã',
            short_codename: 'bac_hung',
            parentId: 315,
          },
          {
            title: 'Xã Nam Hưng',
            code: 11806,
            value: 'xa_nam_hung',
            division_type: 'xã',
            short_codename: 'nam_hung',
            parentId: 315,
          },
          {
            title: 'Xã Hùng Thắng',
            code: 11809,
            value: 'xa_hung_thang',
            division_type: 'xã',
            short_codename: 'hung_thang',
            parentId: 315,
          },
          {
            title: 'Xã Tây Hưng',
            code: 11812,
            value: 'xa_tay_hung',
            division_type: 'xã',
            short_codename: 'tay_hung',
            parentId: 315,
          },
          {
            title: 'Xã Đông Hưng',
            code: 11815,
            value: 'xa_dong_hung',
            division_type: 'xã',
            short_codename: 'dong_hung',
            parentId: 315,
          },
          {
            title: 'Xã Vinh Quang',
            code: 11821,
            value: 'xa_vinh_quang',
            division_type: 'xã',
            short_codename: 'vinh_quang',
            parentId: 315,
          },
        ],
        parentId: 31,
      },
      {
        title: 'Huyện Vĩnh Bảo',
        code: 316,
        value: 'huyen_vinh_bao',
        division_type: 'huyện',
        short_codename: 'vinh_bao',
        children: [
          {
            title: 'Thị trấn Vĩnh Bảo',
            code: 11824,
            value: 'thi_tran_vinh_bao',
            division_type: 'thị trấn',
            short_codename: 'vinh_bao',
            parentId: 316,
          },
          {
            title: 'Xã Dũng Tiến',
            code: 11827,
            value: 'xa_dung_tien',
            division_type: 'xã',
            short_codename: 'dung_tien',
            parentId: 316,
          },
          {
            title: 'Xã Giang Biên',
            code: 11830,
            value: 'xa_giang_bien',
            division_type: 'xã',
            short_codename: 'giang_bien',
            parentId: 316,
          },
          {
            title: 'Xã Thắng Thuỷ',
            code: 11833,
            value: 'xa_thang_thuy',
            division_type: 'xã',
            short_codename: 'thang_thuy',
            parentId: 316,
          },
          {
            title: 'Xã Trung Lập',
            code: 11836,
            value: 'xa_trung_lap',
            division_type: 'xã',
            short_codename: 'trung_lap',
            parentId: 316,
          },
          {
            title: 'Xã Việt Tiến',
            code: 11839,
            value: 'xa_viet_tien',
            division_type: 'xã',
            short_codename: 'viet_tien',
            parentId: 316,
          },
          {
            title: 'Xã Vĩnh An',
            code: 11842,
            value: 'xa_vinh_an',
            division_type: 'xã',
            short_codename: 'vinh_an',
            parentId: 316,
          },
          {
            title: 'Xã Vĩnh Long',
            code: 11845,
            value: 'xa_vinh_long',
            division_type: 'xã',
            short_codename: 'vinh_long',
            parentId: 316,
          },
          {
            title: 'Xã Hiệp Hoà',
            code: 11848,
            value: 'xa_hiep_hoa',
            division_type: 'xã',
            short_codename: 'hiep_hoa',
            parentId: 316,
          },
          {
            title: 'Xã Hùng Tiến',
            code: 11851,
            value: 'xa_hung_tien',
            division_type: 'xã',
            short_codename: 'hung_tien',
            parentId: 316,
          },
          {
            title: 'Xã An Hoà',
            code: 11854,
            value: 'xa_an_hoa',
            division_type: 'xã',
            short_codename: 'an_hoa',
            parentId: 316,
          },
          {
            title: 'Xã Tân Hưng',
            code: 11857,
            value: 'xa_tan_hung',
            division_type: 'xã',
            short_codename: 'tan_hung',
            parentId: 316,
          },
          {
            title: 'Xã Tân Liên',
            code: 11860,
            value: 'xa_tan_lien',
            division_type: 'xã',
            short_codename: 'tan_lien',
            parentId: 316,
          },
          {
            title: 'Xã Nhân Hoà',
            code: 11863,
            value: 'xa_nhan_hoa',
            division_type: 'xã',
            short_codename: 'nhan_hoa',
            parentId: 316,
          },
          {
            title: 'Xã Tam Đa',
            code: 11866,
            value: 'xa_tam_da',
            division_type: 'xã',
            short_codename: 'tam_da',
            parentId: 316,
          },
          {
            title: 'Xã Hưng Nhân',
            code: 11869,
            value: 'xa_hung_nhan',
            division_type: 'xã',
            short_codename: 'hung_nhan',
            parentId: 316,
          },
          {
            title: 'Xã Vinh Quang',
            code: 11872,
            value: 'xa_vinh_quang',
            division_type: 'xã',
            short_codename: 'vinh_quang',
            parentId: 316,
          },
          {
            title: 'Xã Đồng Minh',
            code: 11875,
            value: 'xa_dong_minh',
            division_type: 'xã',
            short_codename: 'dong_minh',
            parentId: 316,
          },
          {
            title: 'Xã Thanh Lương',
            code: 11878,
            value: 'xa_thanh_luong',
            division_type: 'xã',
            short_codename: 'thanh_luong',
            parentId: 316,
          },
          {
            title: 'Xã Liên Am',
            code: 11881,
            value: 'xa_lien_am',
            division_type: 'xã',
            short_codename: 'lien_am',
            parentId: 316,
          },
          {
            title: 'Xã Lý Học',
            code: 11884,
            value: 'xa_ly_hoc',
            division_type: 'xã',
            short_codename: 'ly_hoc',
            parentId: 316,
          },
          {
            title: 'Xã Tam Cường',
            code: 11887,
            value: 'xa_tam_cuong',
            division_type: 'xã',
            short_codename: 'tam_cuong',
            parentId: 316,
          },
          {
            title: 'Xã Hoà Bình',
            code: 11890,
            value: 'xa_hoa_binh',
            division_type: 'xã',
            short_codename: 'hoa_binh',
            parentId: 316,
          },
          {
            title: 'Xã Tiền Phong',
            code: 11893,
            value: 'xa_tien_phong',
            division_type: 'xã',
            short_codename: 'tien_phong',
            parentId: 316,
          },
          {
            title: 'Xã Vĩnh Phong',
            code: 11896,
            value: 'xa_vinh_phong',
            division_type: 'xã',
            short_codename: 'vinh_phong',
            parentId: 316,
          },
          {
            title: 'Xã Cộng Hiền',
            code: 11899,
            value: 'xa_cong_hien',
            division_type: 'xã',
            short_codename: 'cong_hien',
            parentId: 316,
          },
          {
            title: 'Xã Cao Minh',
            code: 11902,
            value: 'xa_cao_minh',
            division_type: 'xã',
            short_codename: 'cao_minh',
            parentId: 316,
          },
          {
            title: 'Xã Cổ Am',
            code: 11905,
            value: 'xa_co_am',
            division_type: 'xã',
            short_codename: 'co_am',
            parentId: 316,
          },
          {
            title: 'Xã Vĩnh Tiến',
            code: 11908,
            value: 'xa_vinh_tien',
            division_type: 'xã',
            short_codename: 'vinh_tien',
            parentId: 316,
          },
          {
            title: 'Xã Trấn Dương',
            code: 11911,
            value: 'xa_tran_duong',
            division_type: 'xã',
            short_codename: 'tran_duong',
            parentId: 316,
          },
        ],
        parentId: 31,
      },
      {
        title: 'Huyện Cát Hải',
        code: 317,
        value: 'huyen_cat_hai',
        division_type: 'huyện',
        short_codename: 'cat_hai',
        children: [
          {
            title: 'Thị trấn Cát Bà',
            code: 11914,
            value: 'thi_tran_cat_ba',
            division_type: 'thị trấn',
            short_codename: 'cat_ba',
            parentId: 317,
          },
          {
            title: 'Thị trấn Cát Hải',
            code: 11917,
            value: 'thi_tran_cat_hai',
            division_type: 'thị trấn',
            short_codename: 'cat_hai',
            parentId: 317,
          },
          {
            title: 'Xã Nghĩa Lộ',
            code: 11920,
            value: 'xa_nghia_lo',
            division_type: 'xã',
            short_codename: 'nghia_lo',
            parentId: 317,
          },
          {
            title: 'Xã Đồng Bài',
            code: 11923,
            value: 'xa_dong_bai',
            division_type: 'xã',
            short_codename: 'dong_bai',
            parentId: 317,
          },
          {
            title: 'Xã Hoàng Châu',
            code: 11926,
            value: 'xa_hoang_chau',
            division_type: 'xã',
            short_codename: 'hoang_chau',
            parentId: 317,
          },
          {
            title: 'Xã Văn Phong',
            code: 11929,
            value: 'xa_van_phong',
            division_type: 'xã',
            short_codename: 'van_phong',
            parentId: 317,
          },
          {
            title: 'Xã Phù Long',
            code: 11932,
            value: 'xa_phu_long',
            division_type: 'xã',
            short_codename: 'phu_long',
            parentId: 317,
          },
          {
            title: 'Xã Gia Luận',
            code: 11935,
            value: 'xa_gia_luan',
            division_type: 'xã',
            short_codename: 'gia_luan',
            parentId: 317,
          },
          {
            title: 'Xã Hiền Hào',
            code: 11938,
            value: 'xa_hien_hao',
            division_type: 'xã',
            short_codename: 'hien_hao',
            parentId: 317,
          },
          {
            title: 'Xã Trân Châu',
            code: 11941,
            value: 'xa_tran_chau',
            division_type: 'xã',
            short_codename: 'tran_chau',
            parentId: 317,
          },
          {
            title: 'Xã Việt Hải',
            code: 11944,
            value: 'xa_viet_hai',
            division_type: 'xã',
            short_codename: 'viet_hai',
            parentId: 317,
          },
          {
            title: 'Xã Xuân Đám',
            code: 11947,
            value: 'xa_xuan_dam',
            division_type: 'xã',
            short_codename: 'xuan_dam',
            parentId: 317,
          },
        ],
        parentId: 31,
      },
      {
        title: 'Huyện Bạch Long Vĩ',
        code: 318,
        value: 'huyen_bach_long_vi',
        division_type: 'huyện',
        short_codename: 'bach_long_vi',
        children: [],
        parentId: 31,
      },
    ],
  },
  {
    title: 'Tỉnh Hưng Yên',
    code: 33,
    value: 'tinh_hung_yen',
    division_type: 'tỉnh',
    phone_code: 221,
    children: [
      {
        title: 'Thành phố Hưng Yên',
        code: 323,
        value: 'thanh_pho_hung_yen',
        division_type: 'thành phố',
        short_codename: 'hung_yen',
        children: [
          {
            title: 'Phường Lam Sơn',
            code: 11950,
            value: 'phuong_lam_son',
            division_type: 'phường',
            short_codename: 'lam_son',
            parentId: 323,
          },
          {
            title: 'Phường Hiến Nam',
            code: 11953,
            value: 'phuong_hien_nam',
            division_type: 'phường',
            short_codename: 'hien_nam',
            parentId: 323,
          },
          {
            title: 'Phường An Tảo',
            code: 11956,
            value: 'phuong_an_tao',
            division_type: 'phường',
            short_codename: 'an_tao',
            parentId: 323,
          },
          {
            title: 'Phường Lê Lợi',
            code: 11959,
            value: 'phuong_le_loi',
            division_type: 'phường',
            short_codename: 'le_loi',
            parentId: 323,
          },
          {
            title: 'Phường Minh Khai',
            code: 11962,
            value: 'phuong_minh_khai',
            division_type: 'phường',
            short_codename: 'minh_khai',
            parentId: 323,
          },
          {
            title: 'Phường Quang Trung',
            code: 11965,
            value: 'phuong_quang_trung',
            division_type: 'phường',
            short_codename: 'quang_trung',
            parentId: 323,
          },
          {
            title: 'Phường Hồng Châu',
            code: 11968,
            value: 'phuong_hong_chau',
            division_type: 'phường',
            short_codename: 'hong_chau',
            parentId: 323,
          },
          {
            title: 'Xã Trung Nghĩa',
            code: 11971,
            value: 'xa_trung_nghia',
            division_type: 'xã',
            short_codename: 'trung_nghia',
            parentId: 323,
          },
          {
            title: 'Xã Liên Phương',
            code: 11974,
            value: 'xa_lien_phuong',
            division_type: 'xã',
            short_codename: 'lien_phuong',
            parentId: 323,
          },
          {
            title: 'Xã Hồng Nam',
            code: 11977,
            value: 'xa_hong_nam',
            division_type: 'xã',
            short_codename: 'hong_nam',
            parentId: 323,
          },
          {
            title: 'Xã Quảng Châu',
            code: 11980,
            value: 'xa_quang_chau',
            division_type: 'xã',
            short_codename: 'quang_chau',
            parentId: 323,
          },
          {
            title: 'Xã Bảo Khê',
            code: 11983,
            value: 'xa_bao_khe',
            division_type: 'xã',
            short_codename: 'bao_khe',
            parentId: 323,
          },
          {
            title: 'Xã Phú Cường',
            code: 12331,
            value: 'xa_phu_cuong',
            division_type: 'xã',
            short_codename: 'phu_cuong',
            parentId: 323,
          },
          {
            title: 'Xã Hùng Cường',
            code: 12334,
            value: 'xa_hung_cuong',
            division_type: 'xã',
            short_codename: 'hung_cuong',
            parentId: 323,
          },
          {
            title: 'Xã Phương Chiểu',
            code: 12382,
            value: 'xa_phuong_chieu',
            division_type: 'xã',
            short_codename: 'phuong_chieu',
            parentId: 323,
          },
          {
            title: 'Xã Tân Hưng',
            code: 12385,
            value: 'xa_tan_hung',
            division_type: 'xã',
            short_codename: 'tan_hung',
            parentId: 323,
          },
          {
            title: 'Xã Hoàng Hanh',
            code: 12388,
            value: 'xa_hoang_hanh',
            division_type: 'xã',
            short_codename: 'hoang_hanh',
            parentId: 323,
          },
        ],
        parentId: 33,
      },
      {
        title: 'Huyện Văn Lâm',
        code: 325,
        value: 'huyen_van_lam',
        division_type: 'huyện',
        short_codename: 'van_lam',
        children: [
          {
            title: 'Thị trấn Như Quỳnh',
            code: 11986,
            value: 'thi_tran_nhu_quynh',
            division_type: 'thị trấn',
            short_codename: 'nhu_quynh',
            parentId: 325,
          },
          {
            title: 'Xã Lạc Đạo',
            code: 11989,
            value: 'xa_lac_dao',
            division_type: 'xã',
            short_codename: 'lac_dao',
            parentId: 325,
          },
          {
            title: 'Xã Chỉ Đạo',
            code: 11992,
            value: 'xa_chi_dao',
            division_type: 'xã',
            short_codename: 'chi_dao',
            parentId: 325,
          },
          {
            title: 'Xã Đại Đồng',
            code: 11995,
            value: 'xa_dai_dong',
            division_type: 'xã',
            short_codename: 'dai_dong',
            parentId: 325,
          },
          {
            title: 'Xã Việt Hưng',
            code: 11998,
            value: 'xa_viet_hung',
            division_type: 'xã',
            short_codename: 'viet_hung',
            parentId: 325,
          },
          {
            title: 'Xã Tân Quang',
            code: 12001,
            value: 'xa_tan_quang',
            division_type: 'xã',
            short_codename: 'tan_quang',
            parentId: 325,
          },
          {
            title: 'Xã Đình Dù',
            code: 12004,
            value: 'xa_dinh_du',
            division_type: 'xã',
            short_codename: 'dinh_du',
            parentId: 325,
          },
          {
            title: 'Xã Minh Hải',
            code: 12007,
            value: 'xa_minh_hai',
            division_type: 'xã',
            short_codename: 'minh_hai',
            parentId: 325,
          },
          {
            title: 'Xã Lương Tài',
            code: 12010,
            value: 'xa_luong_tai',
            division_type: 'xã',
            short_codename: 'luong_tai',
            parentId: 325,
          },
          {
            title: 'Xã Trưng Trắc',
            code: 12013,
            value: 'xa_trung_trac',
            division_type: 'xã',
            short_codename: 'trung_trac',
            parentId: 325,
          },
          {
            title: 'Xã Lạc Hồng',
            code: 12016,
            value: 'xa_lac_hong',
            division_type: 'xã',
            short_codename: 'lac_hong',
            parentId: 325,
          },
        ],
        parentId: 33,
      },
      {
        title: 'Huyện Văn Giang',
        code: 326,
        value: 'huyen_van_giang',
        division_type: 'huyện',
        short_codename: 'van_giang',
        children: [
          {
            title: 'Thị trấn Văn Giang',
            code: 12019,
            value: 'thi_tran_van_giang',
            division_type: 'thị trấn',
            short_codename: 'van_giang',
            parentId: 326,
          },
          {
            title: 'Xã Xuân Quan',
            code: 12022,
            value: 'xa_xuan_quan',
            division_type: 'xã',
            short_codename: 'xuan_quan',
            parentId: 326,
          },
          {
            title: 'Xã Cửu Cao',
            code: 12025,
            value: 'xa_cuu_cao',
            division_type: 'xã',
            short_codename: 'cuu_cao',
            parentId: 326,
          },
          {
            title: 'Xã Phụng Công',
            code: 12028,
            value: 'xa_phung_cong',
            division_type: 'xã',
            short_codename: 'phung_cong',
            parentId: 326,
          },
          {
            title: 'Xã Nghĩa Trụ',
            code: 12031,
            value: 'xa_nghia_tru',
            division_type: 'xã',
            short_codename: 'nghia_tru',
            parentId: 326,
          },
          {
            title: 'Xã Long Hưng',
            code: 12034,
            value: 'xa_long_hung',
            division_type: 'xã',
            short_codename: 'long_hung',
            parentId: 326,
          },
          {
            title: 'Xã Vĩnh Khúc',
            code: 12037,
            value: 'xa_vinh_khuc',
            division_type: 'xã',
            short_codename: 'vinh_khuc',
            parentId: 326,
          },
          {
            title: 'Xã Liên Nghĩa',
            code: 12040,
            value: 'xa_lien_nghia',
            division_type: 'xã',
            short_codename: 'lien_nghia',
            parentId: 326,
          },
          {
            title: 'Xã Tân Tiến',
            code: 12043,
            value: 'xa_tan_tien',
            division_type: 'xã',
            short_codename: 'tan_tien',
            parentId: 326,
          },
          {
            title: 'Xã Thắng Lợi',
            code: 12046,
            value: 'xa_thang_loi',
            division_type: 'xã',
            short_codename: 'thang_loi',
            parentId: 326,
          },
          {
            title: 'Xã Mễ Sở',
            code: 12049,
            value: 'xa_me_so',
            division_type: 'xã',
            short_codename: 'me_so',
            parentId: 326,
          },
        ],
        parentId: 33,
      },
      {
        title: 'Huyện Yên Mỹ',
        code: 327,
        value: 'huyen_yen_my',
        division_type: 'huyện',
        short_codename: 'yen_my',
        children: [
          {
            title: 'Thị trấn Yên Mỹ',
            code: 12052,
            value: 'thi_tran_yen_my',
            division_type: 'thị trấn',
            short_codename: 'yen_my',
            parentId: 327,
          },
          {
            title: 'Xã Giai Phạm',
            code: 12055,
            value: 'xa_giai_pham',
            division_type: 'xã',
            short_codename: 'giai_pham',
            parentId: 327,
          },
          {
            title: 'Xã Nghĩa Hiệp',
            code: 12058,
            value: 'xa_nghia_hiep',
            division_type: 'xã',
            short_codename: 'nghia_hiep',
            parentId: 327,
          },
          {
            title: 'Xã Đồng Than',
            code: 12061,
            value: 'xa_dong_than',
            division_type: 'xã',
            short_codename: 'dong_than',
            parentId: 327,
          },
          {
            title: 'Xã Ngọc Long',
            code: 12064,
            value: 'xa_ngoc_long',
            division_type: 'xã',
            short_codename: 'ngoc_long',
            parentId: 327,
          },
          {
            title: 'Xã Liêu Xá',
            code: 12067,
            value: 'xa_lieu_xa',
            division_type: 'xã',
            short_codename: 'lieu_xa',
            parentId: 327,
          },
          {
            title: 'Xã Hoàn Long',
            code: 12070,
            value: 'xa_hoan_long',
            division_type: 'xã',
            short_codename: 'hoan_long',
            parentId: 327,
          },
          {
            title: 'Xã Tân Lập',
            code: 12073,
            value: 'xa_tan_lap',
            division_type: 'xã',
            short_codename: 'tan_lap',
            parentId: 327,
          },
          {
            title: 'Xã Thanh Long',
            code: 12076,
            value: 'xa_thanh_long',
            division_type: 'xã',
            short_codename: 'thanh_long',
            parentId: 327,
          },
          {
            title: 'Xã Yên Phú',
            code: 12079,
            value: 'xa_yen_phu',
            division_type: 'xã',
            short_codename: 'yen_phu',
            parentId: 327,
          },
          {
            title: 'Xã Việt Cường',
            code: 12082,
            value: 'xa_viet_cuong',
            division_type: 'xã',
            short_codename: 'viet_cuong',
            parentId: 327,
          },
          {
            title: 'Xã Trung Hòa',
            code: 12085,
            value: 'xa_trung_hoa',
            division_type: 'xã',
            short_codename: 'trung_hoa',
            parentId: 327,
          },
          {
            title: 'Xã Yên Hòa',
            code: 12088,
            value: 'xa_yen_hoa',
            division_type: 'xã',
            short_codename: 'yen_hoa',
            parentId: 327,
          },
          {
            title: 'Xã Minh Châu',
            code: 12091,
            value: 'xa_minh_chau',
            division_type: 'xã',
            short_codename: 'minh_chau',
            parentId: 327,
          },
          {
            title: 'Xã Trung Hưng',
            code: 12094,
            value: 'xa_trung_hung',
            division_type: 'xã',
            short_codename: 'trung_hung',
            parentId: 327,
          },
          {
            title: 'Xã Lý Thường Kiệt',
            code: 12097,
            value: 'xa_ly_thuong_kiet',
            division_type: 'xã',
            short_codename: 'ly_thuong_kiet',
            parentId: 327,
          },
          {
            title: 'Xã Tân Việt',
            code: 12100,
            value: 'xa_tan_viet',
            division_type: 'xã',
            short_codename: 'tan_viet',
            parentId: 327,
          },
        ],
        parentId: 33,
      },
      {
        title: 'Thị xã Mỹ Hào',
        code: 328,
        value: 'thi_xa_my_hao',
        division_type: 'thị xã',
        short_codename: 'my_hao',
        children: [
          {
            title: 'Phường Bần Yên Nhân',
            code: 12103,
            value: 'phuong_ban_yen_nhan',
            division_type: 'phường',
            short_codename: 'ban_yen_nhan',
            parentId: 328,
          },
          {
            title: 'Phường Phan Đình Phùng',
            code: 12106,
            value: 'phuong_phan_dinh_phung',
            division_type: 'phường',
            short_codename: 'phan_dinh_phung',
            parentId: 328,
          },
          {
            title: 'Xã Cẩm Xá',
            code: 12109,
            value: 'xa_cam_xa',
            division_type: 'xã',
            short_codename: 'cam_xa',
            parentId: 328,
          },
          {
            title: 'Xã Dương Quang',
            code: 12112,
            value: 'xa_duong_quang',
            division_type: 'xã',
            short_codename: 'duong_quang',
            parentId: 328,
          },
          {
            title: 'Xã Hòa Phong',
            code: 12115,
            value: 'xa_hoa_phong',
            division_type: 'xã',
            short_codename: 'hoa_phong',
            parentId: 328,
          },
          {
            title: 'Phường Nhân Hòa',
            code: 12118,
            value: 'phuong_nhan_hoa',
            division_type: 'phường',
            short_codename: 'nhan_hoa',
            parentId: 328,
          },
          {
            title: 'Phường Dị Sử',
            code: 12121,
            value: 'phuong_di_su',
            division_type: 'phường',
            short_codename: 'di_su',
            parentId: 328,
          },
          {
            title: 'Phường Bạch Sam',
            code: 12124,
            value: 'phuong_bach_sam',
            division_type: 'phường',
            short_codename: 'bach_sam',
            parentId: 328,
          },
          {
            title: 'Phường Minh Đức',
            code: 12127,
            value: 'phuong_minh_duc',
            division_type: 'phường',
            short_codename: 'minh_duc',
            parentId: 328,
          },
          {
            title: 'Phường Phùng Chí Kiên',
            code: 12130,
            value: 'phuong_phung_chi_kien',
            division_type: 'phường',
            short_codename: 'phung_chi_kien',
            parentId: 328,
          },
          {
            title: 'Xã Xuân Dục',
            code: 12133,
            value: 'xa_xuan_duc',
            division_type: 'xã',
            short_codename: 'xuan_duc',
            parentId: 328,
          },
          {
            title: 'Xã Ngọc Lâm',
            code: 12136,
            value: 'xa_ngoc_lam',
            division_type: 'xã',
            short_codename: 'ngoc_lam',
            parentId: 328,
          },
          {
            title: 'Xã Hưng Long',
            code: 12139,
            value: 'xa_hung_long',
            division_type: 'xã',
            short_codename: 'hung_long',
            parentId: 328,
          },
        ],
        parentId: 33,
      },
      {
        title: 'Huyện Ân Thi',
        code: 329,
        value: 'huyen_an_thi',
        division_type: 'huyện',
        short_codename: 'an_thi',
        children: [
          {
            title: 'Thị trấn Ân Thi',
            code: 12142,
            value: 'thi_tran_an_thi',
            division_type: 'thị trấn',
            short_codename: 'an_thi',
            parentId: 329,
          },
          {
            title: 'Xã Phù Ủng',
            code: 12145,
            value: 'xa_phu_ung',
            division_type: 'xã',
            short_codename: 'phu_ung',
            parentId: 329,
          },
          {
            title: 'Xã Bắc Sơn',
            code: 12148,
            value: 'xa_bac_son',
            division_type: 'xã',
            short_codename: 'bac_son',
            parentId: 329,
          },
          {
            title: 'Xã Bãi Sậy',
            code: 12151,
            value: 'xa_bai_say',
            division_type: 'xã',
            short_codename: 'bai_say',
            parentId: 329,
          },
          {
            title: 'Xã Đào Dương',
            code: 12154,
            value: 'xa_dao_duong',
            division_type: 'xã',
            short_codename: 'dao_duong',
            parentId: 329,
          },
          {
            title: 'Xã Tân Phúc',
            code: 12157,
            value: 'xa_tan_phuc',
            division_type: 'xã',
            short_codename: 'tan_phuc',
            parentId: 329,
          },
          {
            title: 'Xã Vân Du',
            code: 12160,
            value: 'xa_van_du',
            division_type: 'xã',
            short_codename: 'van_du',
            parentId: 329,
          },
          {
            title: 'Xã Quang Vinh',
            code: 12163,
            value: 'xa_quang_vinh',
            division_type: 'xã',
            short_codename: 'quang_vinh',
            parentId: 329,
          },
          {
            title: 'Xã Xuân Trúc',
            code: 12166,
            value: 'xa_xuan_truc',
            division_type: 'xã',
            short_codename: 'xuan_truc',
            parentId: 329,
          },
          {
            title: 'Xã Hoàng Hoa Thám',
            code: 12169,
            value: 'xa_hoang_hoa_tham',
            division_type: 'xã',
            short_codename: 'hoang_hoa_tham',
            parentId: 329,
          },
          {
            title: 'Xã Quảng Lãng',
            code: 12172,
            value: 'xa_quang_lang',
            division_type: 'xã',
            short_codename: 'quang_lang',
            parentId: 329,
          },
          {
            title: 'Xã Văn Nhuệ',
            code: 12175,
            value: 'xa_van_nhue',
            division_type: 'xã',
            short_codename: 'van_nhue',
            parentId: 329,
          },
          {
            title: 'Xã Đặng Lễ',
            code: 12178,
            value: 'xa_dang_le',
            division_type: 'xã',
            short_codename: 'dang_le',
            parentId: 329,
          },
          {
            title: 'Xã Cẩm Ninh',
            code: 12181,
            value: 'xa_cam_ninh',
            division_type: 'xã',
            short_codename: 'cam_ninh',
            parentId: 329,
          },
          {
            title: 'Xã Nguyễn Trãi',
            code: 12184,
            value: 'xa_nguyen_trai',
            division_type: 'xã',
            short_codename: 'nguyen_trai',
            parentId: 329,
          },
          {
            title: 'Xã Đa Lộc',
            code: 12187,
            value: 'xa_da_loc',
            division_type: 'xã',
            short_codename: 'da_loc',
            parentId: 329,
          },
          {
            title: 'Xã Hồ Tùng Mậu',
            code: 12190,
            value: 'xa_ho_tung_mau',
            division_type: 'xã',
            short_codename: 'ho_tung_mau',
            parentId: 329,
          },
          {
            title: 'Xã Tiền Phong',
            code: 12193,
            value: 'xa_tien_phong',
            division_type: 'xã',
            short_codename: 'tien_phong',
            parentId: 329,
          },
          {
            title: 'Xã Hồng Vân',
            code: 12196,
            value: 'xa_hong_van',
            division_type: 'xã',
            short_codename: 'hong_van',
            parentId: 329,
          },
          {
            title: 'Xã Hồng Quang',
            code: 12199,
            value: 'xa_hong_quang',
            division_type: 'xã',
            short_codename: 'hong_quang',
            parentId: 329,
          },
          {
            title: 'Xã Hạ Lễ',
            code: 12202,
            value: 'xa_ha_le',
            division_type: 'xã',
            short_codename: 'ha_le',
            parentId: 329,
          },
        ],
        parentId: 33,
      },
      {
        title: 'Huyện Khoái Châu',
        code: 330,
        value: 'huyen_khoai_chau',
        division_type: 'huyện',
        short_codename: 'khoai_chau',
        children: [
          {
            title: 'Thị trấn Khoái Châu',
            code: 12205,
            value: 'thi_tran_khoai_chau',
            division_type: 'thị trấn',
            short_codename: 'khoai_chau',
            parentId: 330,
          },
          {
            title: 'Xã Đông Tảo',
            code: 12208,
            value: 'xa_dong_tao',
            division_type: 'xã',
            short_codename: 'dong_tao',
            parentId: 330,
          },
          {
            title: 'Xã Bình Minh',
            code: 12211,
            value: 'xa_binh_minh',
            division_type: 'xã',
            short_codename: 'binh_minh',
            parentId: 330,
          },
          {
            title: 'Xã Dạ Trạch',
            code: 12214,
            value: 'xa_da_trach',
            division_type: 'xã',
            short_codename: 'da_trach',
            parentId: 330,
          },
          {
            title: 'Xã Hàm Tử',
            code: 12217,
            value: 'xa_ham_tu',
            division_type: 'xã',
            short_codename: 'ham_tu',
            parentId: 330,
          },
          {
            title: 'Xã Ông Đình',
            code: 12220,
            value: 'xa_ong_dinh',
            division_type: 'xã',
            short_codename: 'ong_dinh',
            parentId: 330,
          },
          {
            title: 'Xã Tân Dân',
            code: 12223,
            value: 'xa_tan_dan',
            division_type: 'xã',
            short_codename: 'tan_dan',
            parentId: 330,
          },
          {
            title: 'Xã Tứ Dân',
            code: 12226,
            value: 'xa_tu_dan',
            division_type: 'xã',
            short_codename: 'tu_dan',
            parentId: 330,
          },
          {
            title: 'Xã An Vĩ',
            code: 12229,
            value: 'xa_an_vi',
            division_type: 'xã',
            short_codename: 'an_vi',
            parentId: 330,
          },
          {
            title: 'Xã Đông Kết',
            code: 12232,
            value: 'xa_dong_ket',
            division_type: 'xã',
            short_codename: 'dong_ket',
            parentId: 330,
          },
          {
            title: 'Xã Bình Kiều',
            code: 12235,
            value: 'xa_binh_kieu',
            division_type: 'xã',
            short_codename: 'binh_kieu',
            parentId: 330,
          },
          {
            title: 'Xã Dân Tiến',
            code: 12238,
            value: 'xa_dan_tien',
            division_type: 'xã',
            short_codename: 'dan_tien',
            parentId: 330,
          },
          {
            title: 'Xã Đồng Tiến',
            code: 12241,
            value: 'xa_dong_tien',
            division_type: 'xã',
            short_codename: 'dong_tien',
            parentId: 330,
          },
          {
            title: 'Xã Hồng Tiến',
            code: 12244,
            value: 'xa_hong_tien',
            division_type: 'xã',
            short_codename: 'hong_tien',
            parentId: 330,
          },
          {
            title: 'Xã Tân Châu',
            code: 12247,
            value: 'xa_tan_chau',
            division_type: 'xã',
            short_codename: 'tan_chau',
            parentId: 330,
          },
          {
            title: 'Xã Liên Khê',
            code: 12250,
            value: 'xa_lien_khe',
            division_type: 'xã',
            short_codename: 'lien_khe',
            parentId: 330,
          },
          {
            title: 'Xã Phùng Hưng',
            code: 12253,
            value: 'xa_phung_hung',
            division_type: 'xã',
            short_codename: 'phung_hung',
            parentId: 330,
          },
          {
            title: 'Xã Việt Hòa',
            code: 12256,
            value: 'xa_viet_hoa',
            division_type: 'xã',
            short_codename: 'viet_hoa',
            parentId: 330,
          },
          {
            title: 'Xã Đông Ninh',
            code: 12259,
            value: 'xa_dong_ninh',
            division_type: 'xã',
            short_codename: 'dong_ninh',
            parentId: 330,
          },
          {
            title: 'Xã Đại Tập',
            code: 12262,
            value: 'xa_dai_tap',
            division_type: 'xã',
            short_codename: 'dai_tap',
            parentId: 330,
          },
          {
            title: 'Xã Chí Tân',
            code: 12265,
            value: 'xa_chi_tan',
            division_type: 'xã',
            short_codename: 'chi_tan',
            parentId: 330,
          },
          {
            title: 'Xã Đại Hưng',
            code: 12268,
            value: 'xa_dai_hung',
            division_type: 'xã',
            short_codename: 'dai_hung',
            parentId: 330,
          },
          {
            title: 'Xã Thuần Hưng',
            code: 12271,
            value: 'xa_thuan_hung',
            division_type: 'xã',
            short_codename: 'thuan_hung',
            parentId: 330,
          },
          {
            title: 'Xã Thành Công',
            code: 12274,
            value: 'xa_thanh_cong',
            division_type: 'xã',
            short_codename: 'thanh_cong',
            parentId: 330,
          },
          {
            title: 'Xã Nhuế Dương',
            code: 12277,
            value: 'xa_nhue_duong',
            division_type: 'xã',
            short_codename: 'nhue_duong',
            parentId: 330,
          },
        ],
        parentId: 33,
      },
      {
        title: 'Huyện Kim Động',
        code: 331,
        value: 'huyen_kim_dong',
        division_type: 'huyện',
        short_codename: 'kim_dong',
        children: [
          {
            title: 'Thị trấn Lương Bằng',
            code: 12280,
            value: 'thi_tran_luong_bang',
            division_type: 'thị trấn',
            short_codename: 'luong_bang',
            parentId: 331,
          },
          {
            title: 'Xã Nghĩa Dân',
            code: 12283,
            value: 'xa_nghia_dan',
            division_type: 'xã',
            short_codename: 'nghia_dan',
            parentId: 331,
          },
          {
            title: 'Xã Toàn Thắng',
            code: 12286,
            value: 'xa_toan_thang',
            division_type: 'xã',
            short_codename: 'toan_thang',
            parentId: 331,
          },
          {
            title: 'Xã Vĩnh Xá',
            code: 12289,
            value: 'xa_vinh_xa',
            division_type: 'xã',
            short_codename: 'vinh_xa',
            parentId: 331,
          },
          {
            title: 'Xã Phạm Ngũ Lão',
            code: 12292,
            value: 'xa_pham_ngu_lao',
            division_type: 'xã',
            short_codename: 'pham_ngu_lao',
            parentId: 331,
          },
          {
            title: 'Xã Thọ Vinh',
            code: 12295,
            value: 'xa_tho_vinh',
            division_type: 'xã',
            short_codename: 'tho_vinh',
            parentId: 331,
          },
          {
            title: 'Xã Đồng Thanh',
            code: 12298,
            value: 'xa_dong_thanh',
            division_type: 'xã',
            short_codename: 'dong_thanh',
            parentId: 331,
          },
          {
            title: 'Xã Song Mai',
            code: 12301,
            value: 'xa_song_mai',
            division_type: 'xã',
            short_codename: 'song_mai',
            parentId: 331,
          },
          {
            title: 'Xã Chính Nghĩa',
            code: 12304,
            value: 'xa_chinh_nghia',
            division_type: 'xã',
            short_codename: 'chinh_nghia',
            parentId: 331,
          },
          {
            title: 'Xã Nhân La',
            code: 12307,
            value: 'xa_nhan_la',
            division_type: 'xã',
            short_codename: 'nhan_la',
            parentId: 331,
          },
          {
            title: 'Xã Phú Thịnh',
            code: 12310,
            value: 'xa_phu_thinh',
            division_type: 'xã',
            short_codename: 'phu_thinh',
            parentId: 331,
          },
          {
            title: 'Xã Mai Động',
            code: 12313,
            value: 'xa_mai_dong',
            division_type: 'xã',
            short_codename: 'mai_dong',
            parentId: 331,
          },
          {
            title: 'Xã Đức Hợp',
            code: 12316,
            value: 'xa_duc_hop',
            division_type: 'xã',
            short_codename: 'duc_hop',
            parentId: 331,
          },
          {
            title: 'Xã Hùng An',
            code: 12319,
            value: 'xa_hung_an',
            division_type: 'xã',
            short_codename: 'hung_an',
            parentId: 331,
          },
          {
            title: 'Xã Ngọc Thanh',
            code: 12322,
            value: 'xa_ngoc_thanh',
            division_type: 'xã',
            short_codename: 'ngoc_thanh',
            parentId: 331,
          },
          {
            title: 'Xã Vũ Xá',
            code: 12325,
            value: 'xa_vu_xa',
            division_type: 'xã',
            short_codename: 'vu_xa',
            parentId: 331,
          },
          {
            title: 'Xã Hiệp Cường',
            code: 12328,
            value: 'xa_hiep_cuong',
            division_type: 'xã',
            short_codename: 'hiep_cuong',
            parentId: 331,
          },
        ],
        parentId: 33,
      },
      {
        title: 'Huyện Tiên Lữ',
        code: 332,
        value: 'huyen_tien_lu',
        division_type: 'huyện',
        short_codename: 'tien_lu',
        children: [
          {
            title: 'Thị trấn Vương',
            code: 12337,
            value: 'thi_tran_vuong',
            division_type: 'thị trấn',
            short_codename: 'vuong',
            parentId: 332,
          },
          {
            title: 'Xã Hưng Đạo',
            code: 12340,
            value: 'xa_hung_dao',
            division_type: 'xã',
            short_codename: 'hung_dao',
            parentId: 332,
          },
          {
            title: 'Xã Ngô Quyền',
            code: 12343,
            value: 'xa_ngo_quyen',
            division_type: 'xã',
            short_codename: 'ngo_quyen',
            parentId: 332,
          },
          {
            title: 'Xã Nhật Tân',
            code: 12346,
            value: 'xa_nhat_tan',
            division_type: 'xã',
            short_codename: 'nhat_tan',
            parentId: 332,
          },
          {
            title: 'Xã Dị Chế',
            code: 12349,
            value: 'xa_di_che',
            division_type: 'xã',
            short_codename: 'di_che',
            parentId: 332,
          },
          {
            title: 'Xã Lệ Xá',
            code: 12352,
            value: 'xa_le_xa',
            division_type: 'xã',
            short_codename: 'le_xa',
            parentId: 332,
          },
          {
            title: 'Xã An Viên',
            code: 12355,
            value: 'xa_an_vien',
            division_type: 'xã',
            short_codename: 'an_vien',
            parentId: 332,
          },
          {
            title: 'Xã Đức Thắng',
            code: 12358,
            value: 'xa_duc_thang',
            division_type: 'xã',
            short_codename: 'duc_thang',
            parentId: 332,
          },
          {
            title: 'Xã Trung Dũng',
            code: 12361,
            value: 'xa_trung_dung',
            division_type: 'xã',
            short_codename: 'trung_dung',
            parentId: 332,
          },
          {
            title: 'Xã Hải Triều',
            code: 12364,
            value: 'xa_hai_trieu',
            division_type: 'xã',
            short_codename: 'hai_trieu',
            parentId: 332,
          },
          {
            title: 'Xã Thủ Sỹ',
            code: 12367,
            value: 'xa_thu_sy',
            division_type: 'xã',
            short_codename: 'thu_sy',
            parentId: 332,
          },
          {
            title: 'Xã Thiện Phiến',
            code: 12370,
            value: 'xa_thien_phien',
            division_type: 'xã',
            short_codename: 'thien_phien',
            parentId: 332,
          },
          {
            title: 'Xã Thụy Lôi',
            code: 12373,
            value: 'xa_thuy_loi',
            division_type: 'xã',
            short_codename: 'thuy_loi',
            parentId: 332,
          },
          {
            title: 'Xã Cương Chính',
            code: 12376,
            value: 'xa_cuong_chinh',
            division_type: 'xã',
            short_codename: 'cuong_chinh',
            parentId: 332,
          },
          {
            title: 'Xã Minh Phượng',
            code: 12379,
            value: 'xa_minh_phuong',
            division_type: 'xã',
            short_codename: 'minh_phuong',
            parentId: 332,
          },
        ],
        parentId: 33,
      },
      {
        title: 'Huyện Phù Cừ',
        code: 333,
        value: 'huyen_phu_cu',
        division_type: 'huyện',
        short_codename: 'phu_cu',
        children: [
          {
            title: 'Thị trấn Trần Cao',
            code: 12391,
            value: 'thi_tran_tran_cao',
            division_type: 'thị trấn',
            short_codename: 'tran_cao',
            parentId: 333,
          },
          {
            title: 'Xã Minh Tân',
            code: 12394,
            value: 'xa_minh_tan',
            division_type: 'xã',
            short_codename: 'minh_tan',
            parentId: 333,
          },
          {
            title: 'Xã Phan Sào Nam',
            code: 12397,
            value: 'xa_phan_sao_nam',
            division_type: 'xã',
            short_codename: 'phan_sao_nam',
            parentId: 333,
          },
          {
            title: 'Xã Quang Hưng',
            code: 12400,
            value: 'xa_quang_hung',
            division_type: 'xã',
            short_codename: 'quang_hung',
            parentId: 333,
          },
          {
            title: 'Xã Minh Hoàng',
            code: 12403,
            value: 'xa_minh_hoang',
            division_type: 'xã',
            short_codename: 'minh_hoang',
            parentId: 333,
          },
          {
            title: 'Xã Đoàn Đào',
            code: 12406,
            value: 'xa_doan_dao',
            division_type: 'xã',
            short_codename: 'doan_dao',
            parentId: 333,
          },
          {
            title: 'Xã Tống Phan',
            code: 12409,
            value: 'xa_tong_phan',
            division_type: 'xã',
            short_codename: 'tong_phan',
            parentId: 333,
          },
          {
            title: 'Xã Đình Cao',
            code: 12412,
            value: 'xa_dinh_cao',
            division_type: 'xã',
            short_codename: 'dinh_cao',
            parentId: 333,
          },
          {
            title: 'Xã Nhật Quang',
            code: 12415,
            value: 'xa_nhat_quang',
            division_type: 'xã',
            short_codename: 'nhat_quang',
            parentId: 333,
          },
          {
            title: 'Xã Tiền Tiến',
            code: 12418,
            value: 'xa_tien_tien',
            division_type: 'xã',
            short_codename: 'tien_tien',
            parentId: 333,
          },
          {
            title: 'Xã Tam Đa',
            code: 12421,
            value: 'xa_tam_da',
            division_type: 'xã',
            short_codename: 'tam_da',
            parentId: 333,
          },
          {
            title: 'Xã Minh Tiến',
            code: 12424,
            value: 'xa_minh_tien',
            division_type: 'xã',
            short_codename: 'minh_tien',
            parentId: 333,
          },
          {
            title: 'Xã Nguyên Hòa',
            code: 12427,
            value: 'xa_nguyen_hoa',
            division_type: 'xã',
            short_codename: 'nguyen_hoa',
            parentId: 333,
          },
          {
            title: 'Xã Tống Trân',
            code: 12430,
            value: 'xa_tong_tran',
            division_type: 'xã',
            short_codename: 'tong_tran',
            parentId: 333,
          },
        ],
        parentId: 33,
      },
    ],
  },
  {
    title: 'Tỉnh Thái Bình',
    code: 34,
    value: 'tinh_thai_binh',
    division_type: 'tỉnh',
    phone_code: 227,
    children: [
      {
        title: 'Thành phố Thái Bình',
        code: 336,
        value: 'thanh_pho_thai_binh',
        division_type: 'thành phố',
        short_codename: 'thai_binh',
        children: [
          {
            title: 'Phường Lê Hồng Phong',
            code: 12433,
            value: 'phuong_le_hong_phong',
            division_type: 'phường',
            short_codename: 'le_hong_phong',
            parentId: 336,
          },
          {
            title: 'Phường Bồ Xuyên',
            code: 12436,
            value: 'phuong_bo_xuyen',
            division_type: 'phường',
            short_codename: 'bo_xuyen',
            parentId: 336,
          },
          {
            title: 'Phường Đề Thám',
            code: 12439,
            value: 'phuong_de_tham',
            division_type: 'phường',
            short_codename: 'de_tham',
            parentId: 336,
          },
          {
            title: 'Phường Kỳ Bá',
            code: 12442,
            value: 'phuong_ky_ba',
            division_type: 'phường',
            short_codename: 'ky_ba',
            parentId: 336,
          },
          {
            title: 'Phường Quang Trung',
            code: 12445,
            value: 'phuong_quang_trung',
            division_type: 'phường',
            short_codename: 'quang_trung',
            parentId: 336,
          },
          {
            title: 'Phường Phú Khánh',
            code: 12448,
            value: 'phuong_phu_khanh',
            division_type: 'phường',
            short_codename: 'phu_khanh',
            parentId: 336,
          },
          {
            title: 'Phường Tiền Phong',
            code: 12451,
            value: 'phuong_tien_phong',
            division_type: 'phường',
            short_codename: 'tien_phong',
            parentId: 336,
          },
          {
            title: 'Phường Trần Hưng Đạo',
            code: 12452,
            value: 'phuong_tran_hung_dao',
            division_type: 'phường',
            short_codename: 'tran_hung_dao',
            parentId: 336,
          },
          {
            title: 'Phường Trần Lãm',
            code: 12454,
            value: 'phuong_tran_lam',
            division_type: 'phường',
            short_codename: 'tran_lam',
            parentId: 336,
          },
          {
            title: 'Xã Đông Hòa',
            code: 12457,
            value: 'xa_dong_hoa',
            division_type: 'xã',
            short_codename: 'dong_hoa',
            parentId: 336,
          },
          {
            title: 'Phường Hoàng Diệu',
            code: 12460,
            value: 'phuong_hoang_dieu',
            division_type: 'phường',
            short_codename: 'hoang_dieu',
            parentId: 336,
          },
          {
            title: 'Xã Phú Xuân',
            code: 12463,
            value: 'xa_phu_xuan',
            division_type: 'xã',
            short_codename: 'phu_xuan',
            parentId: 336,
          },
          {
            title: 'Xã Vũ Phúc',
            code: 12466,
            value: 'xa_vu_phuc',
            division_type: 'xã',
            short_codename: 'vu_phuc',
            parentId: 336,
          },
          {
            title: 'Xã Vũ Chính',
            code: 12469,
            value: 'xa_vu_chinh',
            division_type: 'xã',
            short_codename: 'vu_chinh',
            parentId: 336,
          },
          {
            title: 'Xã Đông Mỹ',
            code: 12817,
            value: 'xa_dong_my',
            division_type: 'xã',
            short_codename: 'dong_my',
            parentId: 336,
          },
          {
            title: 'Xã Đông Thọ',
            code: 12820,
            value: 'xa_dong_tho',
            division_type: 'xã',
            short_codename: 'dong_tho',
            parentId: 336,
          },
          {
            title: 'Xã Vũ Đông',
            code: 13084,
            value: 'xa_vu_dong',
            division_type: 'xã',
            short_codename: 'vu_dong',
            parentId: 336,
          },
          {
            title: 'Xã Vũ Lạc',
            code: 13108,
            value: 'xa_vu_lac',
            division_type: 'xã',
            short_codename: 'vu_lac',
            parentId: 336,
          },
          {
            title: 'Xã Tân Bình',
            code: 13225,
            value: 'xa_tan_binh',
            division_type: 'xã',
            short_codename: 'tan_binh',
            parentId: 336,
          },
        ],
        parentId: 34,
      },
      {
        title: 'Huyện Quỳnh Phụ',
        code: 338,
        value: 'huyen_quynh_phu',
        division_type: 'huyện',
        short_codename: 'quynh_phu',
        children: [
          {
            title: 'Thị trấn Quỳnh Côi',
            code: 12472,
            value: 'thi_tran_quynh_coi',
            division_type: 'thị trấn',
            short_codename: 'quynh_coi',
            parentId: 338,
          },
          {
            title: 'Xã An Khê',
            code: 12475,
            value: 'xa_an_khe',
            division_type: 'xã',
            short_codename: 'an_khe',
            parentId: 338,
          },
          {
            title: 'Xã An Đồng',
            code: 12478,
            value: 'xa_an_dong',
            division_type: 'xã',
            short_codename: 'an_dong',
            parentId: 338,
          },
          {
            title: 'Xã Quỳnh Hoa',
            code: 12481,
            value: 'xa_quynh_hoa',
            division_type: 'xã',
            short_codename: 'quynh_hoa',
            parentId: 338,
          },
          {
            title: 'Xã Quỳnh Lâm',
            code: 12484,
            value: 'xa_quynh_lam',
            division_type: 'xã',
            short_codename: 'quynh_lam',
            parentId: 338,
          },
          {
            title: 'Xã Quỳnh Thọ',
            code: 12487,
            value: 'xa_quynh_tho',
            division_type: 'xã',
            short_codename: 'quynh_tho',
            parentId: 338,
          },
          {
            title: 'Xã An Hiệp',
            code: 12490,
            value: 'xa_an_hiep',
            division_type: 'xã',
            short_codename: 'an_hiep',
            parentId: 338,
          },
          {
            title: 'Xã Quỳnh Hoàng',
            code: 12493,
            value: 'xa_quynh_hoang',
            division_type: 'xã',
            short_codename: 'quynh_hoang',
            parentId: 338,
          },
          {
            title: 'Xã Quỳnh Giao',
            code: 12496,
            value: 'xa_quynh_giao',
            division_type: 'xã',
            short_codename: 'quynh_giao',
            parentId: 338,
          },
          {
            title: 'Xã An Thái',
            code: 12499,
            value: 'xa_an_thai',
            division_type: 'xã',
            short_codename: 'an_thai',
            parentId: 338,
          },
          {
            title: 'Xã An Cầu',
            code: 12502,
            value: 'xa_an_cau',
            division_type: 'xã',
            short_codename: 'an_cau',
            parentId: 338,
          },
          {
            title: 'Xã Quỳnh Hồng',
            code: 12505,
            value: 'xa_quynh_hong',
            division_type: 'xã',
            short_codename: 'quynh_hong',
            parentId: 338,
          },
          {
            title: 'Xã Quỳnh Khê',
            code: 12508,
            value: 'xa_quynh_khe',
            division_type: 'xã',
            short_codename: 'quynh_khe',
            parentId: 338,
          },
          {
            title: 'Xã Quỳnh Minh',
            code: 12511,
            value: 'xa_quynh_minh',
            division_type: 'xã',
            short_codename: 'quynh_minh',
            parentId: 338,
          },
          {
            title: 'Xã An Ninh',
            code: 12514,
            value: 'xa_an_ninh',
            division_type: 'xã',
            short_codename: 'an_ninh',
            parentId: 338,
          },
          {
            title: 'Xã Quỳnh Ngọc',
            code: 12517,
            value: 'xa_quynh_ngoc',
            division_type: 'xã',
            short_codename: 'quynh_ngoc',
            parentId: 338,
          },
          {
            title: 'Xã Quỳnh Hải',
            code: 12520,
            value: 'xa_quynh_hai',
            division_type: 'xã',
            short_codename: 'quynh_hai',
            parentId: 338,
          },
          {
            title: 'Thị trấn An Bài',
            code: 12523,
            value: 'thi_tran_an_bai',
            division_type: 'thị trấn',
            short_codename: 'an_bai',
            parentId: 338,
          },
          {
            title: 'Xã An Ấp',
            code: 12526,
            value: 'xa_an_ap',
            division_type: 'xã',
            short_codename: 'an_ap',
            parentId: 338,
          },
          {
            title: 'Xã Quỳnh Hội',
            code: 12529,
            value: 'xa_quynh_hoi',
            division_type: 'xã',
            short_codename: 'quynh_hoi',
            parentId: 338,
          },
          {
            title: 'Xã Châu Sơn',
            code: 12532,
            value: 'xa_chau_son',
            division_type: 'xã',
            short_codename: 'chau_son',
            parentId: 338,
          },
          {
            title: 'Xã Quỳnh Mỹ',
            code: 12535,
            value: 'xa_quynh_my',
            division_type: 'xã',
            short_codename: 'quynh_my',
            parentId: 338,
          },
          {
            title: 'Xã An Quí',
            code: 12538,
            value: 'xa_an_qui',
            division_type: 'xã',
            short_codename: 'an_qui',
            parentId: 338,
          },
          {
            title: 'Xã An Thanh',
            code: 12541,
            value: 'xa_an_thanh',
            division_type: 'xã',
            short_codename: 'an_thanh',
            parentId: 338,
          },
          {
            title: 'Xã An Vũ',
            code: 12547,
            value: 'xa_an_vu',
            division_type: 'xã',
            short_codename: 'an_vu',
            parentId: 338,
          },
          {
            title: 'Xã An Lễ',
            code: 12550,
            value: 'xa_an_le',
            division_type: 'xã',
            short_codename: 'an_le',
            parentId: 338,
          },
          {
            title: 'Xã Quỳnh Hưng',
            code: 12553,
            value: 'xa_quynh_hung',
            division_type: 'xã',
            short_codename: 'quynh_hung',
            parentId: 338,
          },
          {
            title: 'Xã Quỳnh Bảo',
            code: 12556,
            value: 'xa_quynh_bao',
            division_type: 'xã',
            short_codename: 'quynh_bao',
            parentId: 338,
          },
          {
            title: 'Xã An Mỹ',
            code: 12559,
            value: 'xa_an_my',
            division_type: 'xã',
            short_codename: 'an_my',
            parentId: 338,
          },
          {
            title: 'Xã Quỳnh Nguyên',
            code: 12562,
            value: 'xa_quynh_nguyen',
            division_type: 'xã',
            short_codename: 'quynh_nguyen',
            parentId: 338,
          },
          {
            title: 'Xã An Vinh',
            code: 12565,
            value: 'xa_an_vinh',
            division_type: 'xã',
            short_codename: 'an_vinh',
            parentId: 338,
          },
          {
            title: 'Xã Quỳnh Xá',
            code: 12568,
            value: 'xa_quynh_xa',
            division_type: 'xã',
            short_codename: 'quynh_xa',
            parentId: 338,
          },
          {
            title: 'Xã An Dục',
            code: 12571,
            value: 'xa_an_duc',
            division_type: 'xã',
            short_codename: 'an_duc',
            parentId: 338,
          },
          {
            title: 'Xã Đông Hải',
            code: 12574,
            value: 'xa_dong_hai',
            division_type: 'xã',
            short_codename: 'dong_hai',
            parentId: 338,
          },
          {
            title: 'Xã Quỳnh Trang',
            code: 12577,
            value: 'xa_quynh_trang',
            division_type: 'xã',
            short_codename: 'quynh_trang',
            parentId: 338,
          },
          {
            title: 'Xã An Tràng',
            code: 12580,
            value: 'xa_an_trang',
            division_type: 'xã',
            short_codename: 'an_trang',
            parentId: 338,
          },
          {
            title: 'Xã Đồng Tiến',
            code: 12583,
            value: 'xa_dong_tien',
            division_type: 'xã',
            short_codename: 'dong_tien',
            parentId: 338,
          },
        ],
        parentId: 34,
      },
      {
        title: 'Huyện Hưng Hà',
        code: 339,
        value: 'huyen_hung_ha',
        division_type: 'huyện',
        short_codename: 'hung_ha',
        children: [
          {
            title: 'Thị trấn Hưng Hà',
            code: 12586,
            value: 'thi_tran_hung_ha',
            division_type: 'thị trấn',
            short_codename: 'hung_ha',
            parentId: 339,
          },
          {
            title: 'Xã Điệp Nông',
            code: 12589,
            value: 'xa_diep_nong',
            division_type: 'xã',
            short_codename: 'diep_nong',
            parentId: 339,
          },
          {
            title: 'Xã Tân Lễ',
            code: 12592,
            value: 'xa_tan_le',
            division_type: 'xã',
            short_codename: 'tan_le',
            parentId: 339,
          },
          {
            title: 'Xã Cộng Hòa',
            code: 12595,
            value: 'xa_cong_hoa',
            division_type: 'xã',
            short_codename: 'cong_hoa',
            parentId: 339,
          },
          {
            title: 'Xã Dân Chủ',
            code: 12598,
            value: 'xa_dan_chu',
            division_type: 'xã',
            short_codename: 'dan_chu',
            parentId: 339,
          },
          {
            title: 'Xã Canh Tân',
            code: 12601,
            value: 'xa_canh_tan',
            division_type: 'xã',
            short_codename: 'canh_tan',
            parentId: 339,
          },
          {
            title: 'Xã Hòa Tiến',
            code: 12604,
            value: 'xa_hoa_tien',
            division_type: 'xã',
            short_codename: 'hoa_tien',
            parentId: 339,
          },
          {
            title: 'Xã Hùng Dũng',
            code: 12607,
            value: 'xa_hung_dung',
            division_type: 'xã',
            short_codename: 'hung_dung',
            parentId: 339,
          },
          {
            title: 'Xã Tân Tiến',
            code: 12610,
            value: 'xa_tan_tien',
            division_type: 'xã',
            short_codename: 'tan_tien',
            parentId: 339,
          },
          {
            title: 'Thị trấn Hưng Nhân',
            code: 12613,
            value: 'thi_tran_hung_nhan',
            division_type: 'thị trấn',
            short_codename: 'hung_nhan',
            parentId: 339,
          },
          {
            title: 'Xã Đoan Hùng',
            code: 12616,
            value: 'xa_doan_hung',
            division_type: 'xã',
            short_codename: 'doan_hung',
            parentId: 339,
          },
          {
            title: 'Xã Duyên Hải',
            code: 12619,
            value: 'xa_duyen_hai',
            division_type: 'xã',
            short_codename: 'duyen_hai',
            parentId: 339,
          },
          {
            title: 'Xã Tân Hòa',
            code: 12622,
            value: 'xa_tan_hoa',
            division_type: 'xã',
            short_codename: 'tan_hoa',
            parentId: 339,
          },
          {
            title: 'Xã Văn Cẩm',
            code: 12625,
            value: 'xa_van_cam',
            division_type: 'xã',
            short_codename: 'van_cam',
            parentId: 339,
          },
          {
            title: 'Xã Bắc Sơn',
            code: 12628,
            value: 'xa_bac_son',
            division_type: 'xã',
            short_codename: 'bac_son',
            parentId: 339,
          },
          {
            title: 'Xã Đông Đô',
            code: 12631,
            value: 'xa_dong_do',
            division_type: 'xã',
            short_codename: 'dong_do',
            parentId: 339,
          },
          {
            title: 'Xã Phúc Khánh',
            code: 12634,
            value: 'xa_phuc_khanh',
            division_type: 'xã',
            short_codename: 'phuc_khanh',
            parentId: 339,
          },
          {
            title: 'Xã Liên Hiệp',
            code: 12637,
            value: 'xa_lien_hiep',
            division_type: 'xã',
            short_codename: 'lien_hiep',
            parentId: 339,
          },
          {
            title: 'Xã Tây Đô',
            code: 12640,
            value: 'xa_tay_do',
            division_type: 'xã',
            short_codename: 'tay_do',
            parentId: 339,
          },
          {
            title: 'Xã Thống Nhất',
            code: 12643,
            value: 'xa_thong_nhat',
            division_type: 'xã',
            short_codename: 'thong_nhat',
            parentId: 339,
          },
          {
            title: 'Xã Tiến Đức',
            code: 12646,
            value: 'xa_tien_duc',
            division_type: 'xã',
            short_codename: 'tien_duc',
            parentId: 339,
          },
          {
            title: 'Xã Thái Hưng',
            code: 12649,
            value: 'xa_thai_hung',
            division_type: 'xã',
            short_codename: 'thai_hung',
            parentId: 339,
          },
          {
            title: 'Xã Thái Phương',
            code: 12652,
            value: 'xa_thai_phuong',
            division_type: 'xã',
            short_codename: 'thai_phuong',
            parentId: 339,
          },
          {
            title: 'Xã Hòa Bình',
            code: 12655,
            value: 'xa_hoa_binh',
            division_type: 'xã',
            short_codename: 'hoa_binh',
            parentId: 339,
          },
          {
            title: 'Xã Chi Lăng',
            code: 12656,
            value: 'xa_chi_lang',
            division_type: 'xã',
            short_codename: 'chi_lang',
            parentId: 339,
          },
          {
            title: 'Xã Minh Khai',
            code: 12658,
            value: 'xa_minh_khai',
            division_type: 'xã',
            short_codename: 'minh_khai',
            parentId: 339,
          },
          {
            title: 'Xã Hồng An',
            code: 12661,
            value: 'xa_hong_an',
            division_type: 'xã',
            short_codename: 'hong_an',
            parentId: 339,
          },
          {
            title: 'Xã Kim Chung',
            code: 12664,
            value: 'xa_kim_chung',
            division_type: 'xã',
            short_codename: 'kim_chung',
            parentId: 339,
          },
          {
            title: 'Xã Hồng Lĩnh',
            code: 12667,
            value: 'xa_hong_linh',
            division_type: 'xã',
            short_codename: 'hong_linh',
            parentId: 339,
          },
          {
            title: 'Xã Minh Tân',
            code: 12670,
            value: 'xa_minh_tan',
            division_type: 'xã',
            short_codename: 'minh_tan',
            parentId: 339,
          },
          {
            title: 'Xã Văn Lang',
            code: 12673,
            value: 'xa_van_lang',
            division_type: 'xã',
            short_codename: 'van_lang',
            parentId: 339,
          },
          {
            title: 'Xã Độc Lập',
            code: 12676,
            value: 'xa_doc_lap',
            division_type: 'xã',
            short_codename: 'doc_lap',
            parentId: 339,
          },
          {
            title: 'Xã Chí Hòa',
            code: 12679,
            value: 'xa_chi_hoa',
            division_type: 'xã',
            short_codename: 'chi_hoa',
            parentId: 339,
          },
          {
            title: 'Xã Minh Hòa',
            code: 12682,
            value: 'xa_minh_hoa',
            division_type: 'xã',
            short_codename: 'minh_hoa',
            parentId: 339,
          },
          {
            title: 'Xã Hồng Minh',
            code: 12685,
            value: 'xa_hong_minh',
            division_type: 'xã',
            short_codename: 'hong_minh',
            parentId: 339,
          },
        ],
        parentId: 34,
      },
      {
        title: 'Huyện Đông Hưng',
        code: 340,
        value: 'huyen_dong_hung',
        division_type: 'huyện',
        short_codename: 'dong_hung',
        children: [
          {
            title: 'Thị trấn Đông Hưng',
            code: 12688,
            value: 'thi_tran_dong_hung',
            division_type: 'thị trấn',
            short_codename: 'dong_hung',
            parentId: 340,
          },
          {
            title: 'Xã Đô Lương',
            code: 12691,
            value: 'xa_do_luong',
            division_type: 'xã',
            short_codename: 'do_luong',
            parentId: 340,
          },
          {
            title: 'Xã Đông Phương',
            code: 12694,
            value: 'xa_dong_phuong',
            division_type: 'xã',
            short_codename: 'dong_phuong',
            parentId: 340,
          },
          {
            title: 'Xã Liên Giang',
            code: 12697,
            value: 'xa_lien_giang',
            division_type: 'xã',
            short_codename: 'lien_giang',
            parentId: 340,
          },
          {
            title: 'Xã An Châu',
            code: 12700,
            value: 'xa_an_chau',
            division_type: 'xã',
            short_codename: 'an_chau',
            parentId: 340,
          },
          {
            title: 'Xã Đông Sơn',
            code: 12703,
            value: 'xa_dong_son',
            division_type: 'xã',
            short_codename: 'dong_son',
            parentId: 340,
          },
          {
            title: 'Xã Đông Cường',
            code: 12706,
            value: 'xa_dong_cuong',
            division_type: 'xã',
            short_codename: 'dong_cuong',
            parentId: 340,
          },
          {
            title: 'Xã Phú Lương',
            code: 12709,
            value: 'xa_phu_luong',
            division_type: 'xã',
            short_codename: 'phu_luong',
            parentId: 340,
          },
          {
            title: 'Xã Mê Linh',
            code: 12712,
            value: 'xa_me_linh',
            division_type: 'xã',
            short_codename: 'me_linh',
            parentId: 340,
          },
          {
            title: 'Xã Lô Giang',
            code: 12715,
            value: 'xa_lo_giang',
            division_type: 'xã',
            short_codename: 'lo_giang',
            parentId: 340,
          },
          {
            title: 'Xã Đông La',
            code: 12718,
            value: 'xa_dong_la',
            division_type: 'xã',
            short_codename: 'dong_la',
            parentId: 340,
          },
          {
            title: 'Xã Minh Tân',
            code: 12721,
            value: 'xa_minh_tan',
            division_type: 'xã',
            short_codename: 'minh_tan',
            parentId: 340,
          },
          {
            title: 'Xã Đông Xá',
            code: 12724,
            value: 'xa_dong_xa',
            division_type: 'xã',
            short_codename: 'dong_xa',
            parentId: 340,
          },
          {
            title: 'Xã Chương Dương',
            code: 12727,
            value: 'xa_chuong_duong',
            division_type: 'xã',
            short_codename: 'chuong_duong',
            parentId: 340,
          },
          {
            title: 'Xã Nguyên Xá',
            code: 12730,
            value: 'xa_nguyen_xa',
            division_type: 'xã',
            short_codename: 'nguyen_xa',
            parentId: 340,
          },
          {
            title: 'Xã Phong Châu',
            code: 12733,
            value: 'xa_phong_chau',
            division_type: 'xã',
            short_codename: 'phong_chau',
            parentId: 340,
          },
          {
            title: 'Xã Hợp Tiến',
            code: 12736,
            value: 'xa_hop_tien',
            division_type: 'xã',
            short_codename: 'hop_tien',
            parentId: 340,
          },
          {
            title: 'Xã Hồng Việt',
            code: 12739,
            value: 'xa_hong_viet',
            division_type: 'xã',
            short_codename: 'hong_viet',
            parentId: 340,
          },
          {
            title: 'Xã Hà Giang',
            code: 12745,
            value: 'xa_ha_giang',
            division_type: 'xã',
            short_codename: 'ha_giang',
            parentId: 340,
          },
          {
            title: 'Xã Đông Kinh',
            code: 12748,
            value: 'xa_dong_kinh',
            division_type: 'xã',
            short_codename: 'dong_kinh',
            parentId: 340,
          },
          {
            title: 'Xã Đông Hợp',
            code: 12751,
            value: 'xa_dong_hop',
            division_type: 'xã',
            short_codename: 'dong_hop',
            parentId: 340,
          },
          {
            title: 'Xã Thăng Long',
            code: 12754,
            value: 'xa_thang_long',
            division_type: 'xã',
            short_codename: 'thang_long',
            parentId: 340,
          },
          {
            title: 'Xã Đông Các',
            code: 12757,
            value: 'xa_dong_cac',
            division_type: 'xã',
            short_codename: 'dong_cac',
            parentId: 340,
          },
          {
            title: 'Xã Phú Châu',
            code: 12760,
            value: 'xa_phu_chau',
            division_type: 'xã',
            short_codename: 'phu_chau',
            parentId: 340,
          },
          {
            title: 'Xã Liên Hoa',
            code: 12763,
            value: 'xa_lien_hoa',
            division_type: 'xã',
            short_codename: 'lien_hoa',
            parentId: 340,
          },
          {
            title: 'Xã Đông Tân',
            code: 12769,
            value: 'xa_dong_tan',
            division_type: 'xã',
            short_codename: 'dong_tan',
            parentId: 340,
          },
          {
            title: 'Xã Đông Vinh',
            code: 12772,
            value: 'xa_dong_vinh',
            division_type: 'xã',
            short_codename: 'dong_vinh',
            parentId: 340,
          },
          {
            title: 'Xã Đông Động',
            code: 12775,
            value: 'xa_dong_dong',
            division_type: 'xã',
            short_codename: 'dong_dong',
            parentId: 340,
          },
          {
            title: 'Xã Hồng Bạch',
            code: 12778,
            value: 'xa_hong_bach',
            division_type: 'xã',
            short_codename: 'hong_bach',
            parentId: 340,
          },
          {
            title: 'Xã Trọng Quan',
            code: 12784,
            value: 'xa_trong_quan',
            division_type: 'xã',
            short_codename: 'trong_quan',
            parentId: 340,
          },
          {
            title: 'Xã Hồng Giang',
            code: 12790,
            value: 'xa_hong_giang',
            division_type: 'xã',
            short_codename: 'hong_giang',
            parentId: 340,
          },
          {
            title: 'Xã Đông Quan',
            code: 12793,
            value: 'xa_dong_quan',
            division_type: 'xã',
            short_codename: 'dong_quan',
            parentId: 340,
          },
          {
            title: 'Xã Đông Quang',
            code: 12796,
            value: 'xa_dong_quang',
            division_type: 'xã',
            short_codename: 'dong_quang',
            parentId: 340,
          },
          {
            title: 'Xã Đông Xuân',
            code: 12799,
            value: 'xa_dong_xuan',
            division_type: 'xã',
            short_codename: 'dong_xuan',
            parentId: 340,
          },
          {
            title: 'Xã Đông Á',
            code: 12802,
            value: 'xa_dong_a',
            division_type: 'xã',
            short_codename: 'dong_a',
            parentId: 340,
          },
          {
            title: 'Xã Đông Hoàng',
            code: 12808,
            value: 'xa_dong_hoang',
            division_type: 'xã',
            short_codename: 'dong_hoang',
            parentId: 340,
          },
          {
            title: 'Xã Đông Dương',
            code: 12811,
            value: 'xa_dong_duong',
            division_type: 'xã',
            short_codename: 'dong_duong',
            parentId: 340,
          },
          {
            title: 'Xã Minh Phú',
            code: 12823,
            value: 'xa_minh_phu',
            division_type: 'xã',
            short_codename: 'minh_phu',
            parentId: 340,
          },
        ],
        parentId: 34,
      },
      {
        title: 'Huyện Thái Thụy',
        code: 341,
        value: 'huyen_thai_thuy',
        division_type: 'huyện',
        short_codename: 'thai_thuy',
        children: [
          {
            title: 'Thị trấn Diêm Điền',
            code: 12826,
            value: 'thi_tran_diem_dien',
            division_type: 'thị trấn',
            short_codename: 'diem_dien',
            parentId: 341,
          },
          {
            title: 'Xã Thụy Trường',
            code: 12832,
            value: 'xa_thuy_truong',
            division_type: 'xã',
            short_codename: 'thuy_truong',
            parentId: 341,
          },
          {
            title: 'Xã Hồng Dũng',
            code: 12841,
            value: 'xa_hong_dung',
            division_type: 'xã',
            short_codename: 'hong_dung',
            parentId: 341,
          },
          {
            title: 'Xã Thụy Quỳnh',
            code: 12844,
            value: 'xa_thuy_quynh',
            division_type: 'xã',
            short_codename: 'thuy_quynh',
            parentId: 341,
          },
          {
            title: 'Xã An Tân',
            code: 12847,
            value: 'xa_an_tan',
            division_type: 'xã',
            short_codename: 'an_tan',
            parentId: 341,
          },
          {
            title: 'Xã Thụy Ninh',
            code: 12850,
            value: 'xa_thuy_ninh',
            division_type: 'xã',
            short_codename: 'thuy_ninh',
            parentId: 341,
          },
          {
            title: 'Xã Thụy Hưng',
            code: 12853,
            value: 'xa_thuy_hung',
            division_type: 'xã',
            short_codename: 'thuy_hung',
            parentId: 341,
          },
          {
            title: 'Xã Thụy Việt',
            code: 12856,
            value: 'xa_thuy_viet',
            division_type: 'xã',
            short_codename: 'thuy_viet',
            parentId: 341,
          },
          {
            title: 'Xã Thụy Văn',
            code: 12859,
            value: 'xa_thuy_van',
            division_type: 'xã',
            short_codename: 'thuy_van',
            parentId: 341,
          },
          {
            title: 'Xã Thụy Xuân',
            code: 12862,
            value: 'xa_thuy_xuan',
            division_type: 'xã',
            short_codename: 'thuy_xuan',
            parentId: 341,
          },
          {
            title: 'Xã Dương Phúc',
            code: 12865,
            value: 'xa_duong_phuc',
            division_type: 'xã',
            short_codename: 'duong_phuc',
            parentId: 341,
          },
          {
            title: 'Xã Thụy Trình',
            code: 12868,
            value: 'xa_thuy_trinh',
            division_type: 'xã',
            short_codename: 'thuy_trinh',
            parentId: 341,
          },
          {
            title: 'Xã Thụy Bình',
            code: 12871,
            value: 'xa_thuy_binh',
            division_type: 'xã',
            short_codename: 'thuy_binh',
            parentId: 341,
          },
          {
            title: 'Xã Thụy Chính',
            code: 12874,
            value: 'xa_thuy_chinh',
            division_type: 'xã',
            short_codename: 'thuy_chinh',
            parentId: 341,
          },
          {
            title: 'Xã Thụy Dân',
            code: 12877,
            value: 'xa_thuy_dan',
            division_type: 'xã',
            short_codename: 'thuy_dan',
            parentId: 341,
          },
          {
            title: 'Xã Thụy Hải',
            code: 12880,
            value: 'xa_thuy_hai',
            division_type: 'xã',
            short_codename: 'thuy_hai',
            parentId: 341,
          },
          {
            title: 'Xã Thụy Liên',
            code: 12889,
            value: 'xa_thuy_lien',
            division_type: 'xã',
            short_codename: 'thuy_lien',
            parentId: 341,
          },
          {
            title: 'Xã Thụy Duyên',
            code: 12892,
            value: 'xa_thuy_duyen',
            division_type: 'xã',
            short_codename: 'thuy_duyen',
            parentId: 341,
          },
          {
            title: 'Xã Thụy Thanh',
            code: 12898,
            value: 'xa_thuy_thanh',
            division_type: 'xã',
            short_codename: 'thuy_thanh',
            parentId: 341,
          },
          {
            title: 'Xã Thụy Sơn',
            code: 12901,
            value: 'xa_thuy_son',
            division_type: 'xã',
            short_codename: 'thuy_son',
            parentId: 341,
          },
          {
            title: 'Xã Thụy Phong',
            code: 12904,
            value: 'xa_thuy_phong',
            division_type: 'xã',
            short_codename: 'thuy_phong',
            parentId: 341,
          },
          {
            title: 'Xã Thái Thượng',
            code: 12907,
            value: 'xa_thai_thuong',
            division_type: 'xã',
            short_codename: 'thai_thuong',
            parentId: 341,
          },
          {
            title: 'Xã Thái Nguyên',
            code: 12910,
            value: 'xa_thai_nguyen',
            division_type: 'xã',
            short_codename: 'thai_nguyen',
            parentId: 341,
          },
          {
            title: 'Xã Dương Hồng Thủy',
            code: 12916,
            value: 'xa_duong_hong_thuy',
            division_type: 'xã',
            short_codename: 'duong_hong_thuy',
            parentId: 341,
          },
          {
            title: 'Xã Thái Giang',
            code: 12919,
            value: 'xa_thai_giang',
            division_type: 'xã',
            short_codename: 'thai_giang',
            parentId: 341,
          },
          {
            title: 'Xã Hòa An',
            code: 12922,
            value: 'xa_hoa_an',
            division_type: 'xã',
            short_codename: 'hoa_an',
            parentId: 341,
          },
          {
            title: 'Xã Sơn Hà',
            code: 12925,
            value: 'xa_son_ha',
            division_type: 'xã',
            short_codename: 'son_ha',
            parentId: 341,
          },
          {
            title: 'Xã Thái Phúc',
            code: 12934,
            value: 'xa_thai_phuc',
            division_type: 'xã',
            short_codename: 'thai_phuc',
            parentId: 341,
          },
          {
            title: 'Xã Thái Hưng',
            code: 12937,
            value: 'xa_thai_hung',
            division_type: 'xã',
            short_codename: 'thai_hung',
            parentId: 341,
          },
          {
            title: 'Xã Thái Đô',
            code: 12940,
            value: 'xa_thai_do',
            division_type: 'xã',
            short_codename: 'thai_do',
            parentId: 341,
          },
          {
            title: 'Xã Thái Xuyên',
            code: 12943,
            value: 'xa_thai_xuyen',
            division_type: 'xã',
            short_codename: 'thai_xuyen',
            parentId: 341,
          },
          {
            title: 'Xã Mỹ Lộc',
            code: 12949,
            value: 'xa_my_loc',
            division_type: 'xã',
            short_codename: 'my_loc',
            parentId: 341,
          },
          {
            title: 'Xã Tân Học',
            code: 12958,
            value: 'xa_tan_hoc',
            division_type: 'xã',
            short_codename: 'tan_hoc',
            parentId: 341,
          },
          {
            title: 'Xã Thái Thịnh',
            code: 12961,
            value: 'xa_thai_thinh',
            division_type: 'xã',
            short_codename: 'thai_thinh',
            parentId: 341,
          },
          {
            title: 'Xã Thuần Thành',
            code: 12964,
            value: 'xa_thuan_thanh',
            division_type: 'xã',
            short_codename: 'thuan_thanh',
            parentId: 341,
          },
          {
            title: 'Xã Thái Thọ',
            code: 12967,
            value: 'xa_thai_tho',
            division_type: 'xã',
            short_codename: 'thai_tho',
            parentId: 341,
          },
        ],
        parentId: 34,
      },
      {
        title: 'Huyện Tiền Hải',
        code: 342,
        value: 'huyen_tien_hai',
        division_type: 'huyện',
        short_codename: 'tien_hai',
        children: [
          {
            title: 'Thị trấn Tiền Hải',
            code: 12970,
            value: 'thi_tran_tien_hai',
            division_type: 'thị trấn',
            short_codename: 'tien_hai',
            parentId: 342,
          },
          {
            title: 'Xã Đông Trà',
            code: 12976,
            value: 'xa_dong_tra',
            division_type: 'xã',
            short_codename: 'dong_tra',
            parentId: 342,
          },
          {
            title: 'Xã Đông Long',
            code: 12979,
            value: 'xa_dong_long',
            division_type: 'xã',
            short_codename: 'dong_long',
            parentId: 342,
          },
          {
            title: 'Xã Đông Quí',
            code: 12982,
            value: 'xa_dong_qui',
            division_type: 'xã',
            short_codename: 'dong_qui',
            parentId: 342,
          },
          {
            title: 'Xã Vũ Lăng',
            code: 12985,
            value: 'xa_vu_lang',
            division_type: 'xã',
            short_codename: 'vu_lang',
            parentId: 342,
          },
          {
            title: 'Xã Đông Xuyên',
            code: 12988,
            value: 'xa_dong_xuyen',
            division_type: 'xã',
            short_codename: 'dong_xuyen',
            parentId: 342,
          },
          {
            title: 'Xã Tây Lương',
            code: 12991,
            value: 'xa_tay_luong',
            division_type: 'xã',
            short_codename: 'tay_luong',
            parentId: 342,
          },
          {
            title: 'Xã Tây Ninh',
            code: 12994,
            value: 'xa_tay_ninh',
            division_type: 'xã',
            short_codename: 'tay_ninh',
            parentId: 342,
          },
          {
            title: 'Xã Đông Trung',
            code: 12997,
            value: 'xa_dong_trung',
            division_type: 'xã',
            short_codename: 'dong_trung',
            parentId: 342,
          },
          {
            title: 'Xã Đông Hoàng',
            code: 13000,
            value: 'xa_dong_hoang',
            division_type: 'xã',
            short_codename: 'dong_hoang',
            parentId: 342,
          },
          {
            title: 'Xã Đông Minh',
            code: 13003,
            value: 'xa_dong_minh',
            division_type: 'xã',
            short_codename: 'dong_minh',
            parentId: 342,
          },
          {
            title: 'Xã Đông Phong',
            code: 13009,
            value: 'xa_dong_phong',
            division_type: 'xã',
            short_codename: 'dong_phong',
            parentId: 342,
          },
          {
            title: 'Xã An Ninh',
            code: 13012,
            value: 'xa_an_ninh',
            division_type: 'xã',
            short_codename: 'an_ninh',
            parentId: 342,
          },
          {
            title: 'Xã Đông Cơ',
            code: 13018,
            value: 'xa_dong_co',
            division_type: 'xã',
            short_codename: 'dong_co',
            parentId: 342,
          },
          {
            title: 'Xã Tây Giang',
            code: 13021,
            value: 'xa_tay_giang',
            division_type: 'xã',
            short_codename: 'tay_giang',
            parentId: 342,
          },
          {
            title: 'Xã Đông Lâm',
            code: 13024,
            value: 'xa_dong_lam',
            division_type: 'xã',
            short_codename: 'dong_lam',
            parentId: 342,
          },
          {
            title: 'Xã Phương Công',
            code: 13027,
            value: 'xa_phuong_cong',
            division_type: 'xã',
            short_codename: 'phuong_cong',
            parentId: 342,
          },
          {
            title: 'Xã Tây Phong',
            code: 13030,
            value: 'xa_tay_phong',
            division_type: 'xã',
            short_codename: 'tay_phong',
            parentId: 342,
          },
          {
            title: 'Xã Tây Tiến',
            code: 13033,
            value: 'xa_tay_tien',
            division_type: 'xã',
            short_codename: 'tay_tien',
            parentId: 342,
          },
          {
            title: 'Xã Nam Cường',
            code: 13036,
            value: 'xa_nam_cuong',
            division_type: 'xã',
            short_codename: 'nam_cuong',
            parentId: 342,
          },
          {
            title: 'Xã Vân Trường',
            code: 13039,
            value: 'xa_van_truong',
            division_type: 'xã',
            short_codename: 'van_truong',
            parentId: 342,
          },
          {
            title: 'Xã Nam Thắng',
            code: 13042,
            value: 'xa_nam_thang',
            division_type: 'xã',
            short_codename: 'nam_thang',
            parentId: 342,
          },
          {
            title: 'Xã Nam Chính',
            code: 13045,
            value: 'xa_nam_chinh',
            division_type: 'xã',
            short_codename: 'nam_chinh',
            parentId: 342,
          },
          {
            title: 'Xã Bắc Hải',
            code: 13048,
            value: 'xa_bac_hai',
            division_type: 'xã',
            short_codename: 'bac_hai',
            parentId: 342,
          },
          {
            title: 'Xã Nam Thịnh',
            code: 13051,
            value: 'xa_nam_thinh',
            division_type: 'xã',
            short_codename: 'nam_thinh',
            parentId: 342,
          },
          {
            title: 'Xã Nam Hà',
            code: 13054,
            value: 'xa_nam_ha',
            division_type: 'xã',
            short_codename: 'nam_ha',
            parentId: 342,
          },
          {
            title: 'Xã Nam Thanh',
            code: 13057,
            value: 'xa_nam_thanh',
            division_type: 'xã',
            short_codename: 'nam_thanh',
            parentId: 342,
          },
          {
            title: 'Xã Nam Trung',
            code: 13060,
            value: 'xa_nam_trung',
            division_type: 'xã',
            short_codename: 'nam_trung',
            parentId: 342,
          },
          {
            title: 'Xã Nam Hồng',
            code: 13063,
            value: 'xa_nam_hong',
            division_type: 'xã',
            short_codename: 'nam_hong',
            parentId: 342,
          },
          {
            title: 'Xã Nam Hưng',
            code: 13066,
            value: 'xa_nam_hung',
            division_type: 'xã',
            short_codename: 'nam_hung',
            parentId: 342,
          },
          {
            title: 'Xã Nam Hải',
            code: 13069,
            value: 'xa_nam_hai',
            division_type: 'xã',
            short_codename: 'nam_hai',
            parentId: 342,
          },
          {
            title: 'Xã Nam Phú',
            code: 13072,
            value: 'xa_nam_phu',
            division_type: 'xã',
            short_codename: 'nam_phu',
            parentId: 342,
          },
        ],
        parentId: 34,
      },
      {
        title: 'Huyện Kiến Xương',
        code: 343,
        value: 'huyen_kien_xuong',
        division_type: 'huyện',
        short_codename: 'kien_xuong',
        children: [
          {
            title: 'Thị trấn Kiến Xương',
            code: 13075,
            value: 'thi_tran_kien_xuong',
            division_type: 'thị trấn',
            short_codename: 'kien_xuong',
            parentId: 343,
          },
          {
            title: 'Xã Trà Giang',
            code: 13078,
            value: 'xa_tra_giang',
            division_type: 'xã',
            short_codename: 'tra_giang',
            parentId: 343,
          },
          {
            title: 'Xã Quốc Tuấn',
            code: 13081,
            value: 'xa_quoc_tuan',
            division_type: 'xã',
            short_codename: 'quoc_tuan',
            parentId: 343,
          },
          {
            title: 'Xã An Bình',
            code: 13087,
            value: 'xa_an_binh',
            division_type: 'xã',
            short_codename: 'an_binh',
            parentId: 343,
          },
          {
            title: 'Xã Tây Sơn',
            code: 13090,
            value: 'xa_tay_son',
            division_type: 'xã',
            short_codename: 'tay_son',
            parentId: 343,
          },
          {
            title: 'Xã Hồng Thái',
            code: 13093,
            value: 'xa_hong_thai',
            division_type: 'xã',
            short_codename: 'hong_thai',
            parentId: 343,
          },
          {
            title: 'Xã Bình Nguyên',
            code: 13096,
            value: 'xa_binh_nguyen',
            division_type: 'xã',
            short_codename: 'binh_nguyen',
            parentId: 343,
          },
          {
            title: 'Xã Lê Lợi',
            code: 13102,
            value: 'xa_le_loi',
            division_type: 'xã',
            short_codename: 'le_loi',
            parentId: 343,
          },
          {
            title: 'Xã Vũ Lễ',
            code: 13111,
            value: 'xa_vu_le',
            division_type: 'xã',
            short_codename: 'vu_le',
            parentId: 343,
          },
          {
            title: 'Xã Thanh Tân',
            code: 13114,
            value: 'xa_thanh_tan',
            division_type: 'xã',
            short_codename: 'thanh_tan',
            parentId: 343,
          },
          {
            title: 'Xã Thượng Hiền',
            code: 13117,
            value: 'xa_thuong_hien',
            division_type: 'xã',
            short_codename: 'thuong_hien',
            parentId: 343,
          },
          {
            title: 'Xã Nam Cao',
            code: 13120,
            value: 'xa_nam_cao',
            division_type: 'xã',
            short_codename: 'nam_cao',
            parentId: 343,
          },
          {
            title: 'Xã Đình Phùng',
            code: 13123,
            value: 'xa_dinh_phung',
            division_type: 'xã',
            short_codename: 'dinh_phung',
            parentId: 343,
          },
          {
            title: 'Xã Vũ Ninh',
            code: 13126,
            value: 'xa_vu_ninh',
            division_type: 'xã',
            short_codename: 'vu_ninh',
            parentId: 343,
          },
          {
            title: 'Xã Vũ An',
            code: 13129,
            value: 'xa_vu_an',
            division_type: 'xã',
            short_codename: 'vu_an',
            parentId: 343,
          },
          {
            title: 'Xã Quang Lịch',
            code: 13132,
            value: 'xa_quang_lich',
            division_type: 'xã',
            short_codename: 'quang_lich',
            parentId: 343,
          },
          {
            title: 'Xã Hòa Bình',
            code: 13135,
            value: 'xa_hoa_binh',
            division_type: 'xã',
            short_codename: 'hoa_binh',
            parentId: 343,
          },
          {
            title: 'Xã Bình Minh',
            code: 13138,
            value: 'xa_binh_minh',
            division_type: 'xã',
            short_codename: 'binh_minh',
            parentId: 343,
          },
          {
            title: 'Xã Vũ Quí',
            code: 13141,
            value: 'xa_vu_qui',
            division_type: 'xã',
            short_codename: 'vu_qui',
            parentId: 343,
          },
          {
            title: 'Xã Quang Bình',
            code: 13144,
            value: 'xa_quang_binh',
            division_type: 'xã',
            short_codename: 'quang_binh',
            parentId: 343,
          },
          {
            title: 'Xã Vũ Trung',
            code: 13150,
            value: 'xa_vu_trung',
            division_type: 'xã',
            short_codename: 'vu_trung',
            parentId: 343,
          },
          {
            title: 'Xã Vũ Thắng',
            code: 13153,
            value: 'xa_vu_thang',
            division_type: 'xã',
            short_codename: 'vu_thang',
            parentId: 343,
          },
          {
            title: 'Xã Vũ Công',
            code: 13156,
            value: 'xa_vu_cong',
            division_type: 'xã',
            short_codename: 'vu_cong',
            parentId: 343,
          },
          {
            title: 'Xã Vũ Hòa',
            code: 13159,
            value: 'xa_vu_hoa',
            division_type: 'xã',
            short_codename: 'vu_hoa',
            parentId: 343,
          },
          {
            title: 'Xã Quang Minh',
            code: 13162,
            value: 'xa_quang_minh',
            division_type: 'xã',
            short_codename: 'quang_minh',
            parentId: 343,
          },
          {
            title: 'Xã Quang Trung',
            code: 13165,
            value: 'xa_quang_trung',
            division_type: 'xã',
            short_codename: 'quang_trung',
            parentId: 343,
          },
          {
            title: 'Xã Minh Quang',
            code: 13171,
            value: 'xa_minh_quang',
            division_type: 'xã',
            short_codename: 'minh_quang',
            parentId: 343,
          },
          {
            title: 'Xã Vũ Bình',
            code: 13174,
            value: 'xa_vu_binh',
            division_type: 'xã',
            short_codename: 'vu_binh',
            parentId: 343,
          },
          {
            title: 'Xã Minh Tân',
            code: 13177,
            value: 'xa_minh_tan',
            division_type: 'xã',
            short_codename: 'minh_tan',
            parentId: 343,
          },
          {
            title: 'Xã Nam Bình',
            code: 13180,
            value: 'xa_nam_binh',
            division_type: 'xã',
            short_codename: 'nam_binh',
            parentId: 343,
          },
          {
            title: 'Xã Bình Thanh',
            code: 13183,
            value: 'xa_binh_thanh',
            division_type: 'xã',
            short_codename: 'binh_thanh',
            parentId: 343,
          },
          {
            title: 'Xã Bình Định',
            code: 13186,
            value: 'xa_binh_dinh',
            division_type: 'xã',
            short_codename: 'binh_dinh',
            parentId: 343,
          },
          {
            title: 'Xã Hồng Tiến',
            code: 13189,
            value: 'xa_hong_tien',
            division_type: 'xã',
            short_codename: 'hong_tien',
            parentId: 343,
          },
        ],
        parentId: 34,
      },
      {
        title: 'Huyện Vũ Thư',
        code: 344,
        value: 'huyen_vu_thu',
        division_type: 'huyện',
        short_codename: 'vu_thu',
        children: [
          {
            title: 'Thị trấn Vũ Thư',
            code: 13192,
            value: 'thi_tran_vu_thu',
            division_type: 'thị trấn',
            short_codename: 'vu_thu',
            parentId: 344,
          },
          {
            title: 'Xã Hồng Lý',
            code: 13195,
            value: 'xa_hong_ly',
            division_type: 'xã',
            short_codename: 'hong_ly',
            parentId: 344,
          },
          {
            title: 'Xã Đồng Thanh',
            code: 13198,
            value: 'xa_dong_thanh',
            division_type: 'xã',
            short_codename: 'dong_thanh',
            parentId: 344,
          },
          {
            title: 'Xã Xuân Hòa',
            code: 13201,
            value: 'xa_xuan_hoa',
            division_type: 'xã',
            short_codename: 'xuan_hoa',
            parentId: 344,
          },
          {
            title: 'Xã Hiệp Hòa',
            code: 13204,
            value: 'xa_hiep_hoa',
            division_type: 'xã',
            short_codename: 'hiep_hoa',
            parentId: 344,
          },
          {
            title: 'Xã Phúc Thành',
            code: 13207,
            value: 'xa_phuc_thanh',
            division_type: 'xã',
            short_codename: 'phuc_thanh',
            parentId: 344,
          },
          {
            title: 'Xã Tân Phong',
            code: 13210,
            value: 'xa_tan_phong',
            division_type: 'xã',
            short_codename: 'tan_phong',
            parentId: 344,
          },
          {
            title: 'Xã Song Lãng',
            code: 13213,
            value: 'xa_song_lang',
            division_type: 'xã',
            short_codename: 'song_lang',
            parentId: 344,
          },
          {
            title: 'Xã Tân Hòa',
            code: 13216,
            value: 'xa_tan_hoa',
            division_type: 'xã',
            short_codename: 'tan_hoa',
            parentId: 344,
          },
          {
            title: 'Xã Việt Hùng',
            code: 13219,
            value: 'xa_viet_hung',
            division_type: 'xã',
            short_codename: 'viet_hung',
            parentId: 344,
          },
          {
            title: 'Xã Minh Lãng',
            code: 13222,
            value: 'xa_minh_lang',
            division_type: 'xã',
            short_codename: 'minh_lang',
            parentId: 344,
          },
          {
            title: 'Xã Minh Khai',
            code: 13228,
            value: 'xa_minh_khai',
            division_type: 'xã',
            short_codename: 'minh_khai',
            parentId: 344,
          },
          {
            title: 'Xã Dũng Nghĩa',
            code: 13231,
            value: 'xa_dung_nghia',
            division_type: 'xã',
            short_codename: 'dung_nghia',
            parentId: 344,
          },
          {
            title: 'Xã Minh Quang',
            code: 13234,
            value: 'xa_minh_quang',
            division_type: 'xã',
            short_codename: 'minh_quang',
            parentId: 344,
          },
          {
            title: 'Xã Tam Quang',
            code: 13237,
            value: 'xa_tam_quang',
            division_type: 'xã',
            short_codename: 'tam_quang',
            parentId: 344,
          },
          {
            title: 'Xã Tân Lập',
            code: 13240,
            value: 'xa_tan_lap',
            division_type: 'xã',
            short_codename: 'tan_lap',
            parentId: 344,
          },
          {
            title: 'Xã Bách Thuận',
            code: 13243,
            value: 'xa_bach_thuan',
            division_type: 'xã',
            short_codename: 'bach_thuan',
            parentId: 344,
          },
          {
            title: 'Xã Tự Tân',
            code: 13246,
            value: 'xa_tu_tan',
            division_type: 'xã',
            short_codename: 'tu_tan',
            parentId: 344,
          },
          {
            title: 'Xã Song An',
            code: 13249,
            value: 'xa_song_an',
            division_type: 'xã',
            short_codename: 'song_an',
            parentId: 344,
          },
          {
            title: 'Xã Trung An',
            code: 13252,
            value: 'xa_trung_an',
            division_type: 'xã',
            short_codename: 'trung_an',
            parentId: 344,
          },
          {
            title: 'Xã Vũ Hội',
            code: 13255,
            value: 'xa_vu_hoi',
            division_type: 'xã',
            short_codename: 'vu_hoi',
            parentId: 344,
          },
          {
            title: 'Xã Hòa Bình',
            code: 13258,
            value: 'xa_hoa_binh',
            division_type: 'xã',
            short_codename: 'hoa_binh',
            parentId: 344,
          },
          {
            title: 'Xã Nguyên Xá',
            code: 13261,
            value: 'xa_nguyen_xa',
            division_type: 'xã',
            short_codename: 'nguyen_xa',
            parentId: 344,
          },
          {
            title: 'Xã Việt Thuận',
            code: 13264,
            value: 'xa_viet_thuan',
            division_type: 'xã',
            short_codename: 'viet_thuan',
            parentId: 344,
          },
          {
            title: 'Xã Vũ Vinh',
            code: 13267,
            value: 'xa_vu_vinh',
            division_type: 'xã',
            short_codename: 'vu_vinh',
            parentId: 344,
          },
          {
            title: 'Xã Vũ Đoài',
            code: 13270,
            value: 'xa_vu_doai',
            division_type: 'xã',
            short_codename: 'vu_doai',
            parentId: 344,
          },
          {
            title: 'Xã Vũ Tiến',
            code: 13273,
            value: 'xa_vu_tien',
            division_type: 'xã',
            short_codename: 'vu_tien',
            parentId: 344,
          },
          {
            title: 'Xã Vũ Vân',
            code: 13276,
            value: 'xa_vu_van',
            division_type: 'xã',
            short_codename: 'vu_van',
            parentId: 344,
          },
          {
            title: 'Xã Duy Nhất',
            code: 13279,
            value: 'xa_duy_nhat',
            division_type: 'xã',
            short_codename: 'duy_nhat',
            parentId: 344,
          },
          {
            title: 'Xã Hồng Phong',
            code: 13282,
            value: 'xa_hong_phong',
            division_type: 'xã',
            short_codename: 'hong_phong',
            parentId: 344,
          },
        ],
        parentId: 34,
      },
    ],
  },
  {
    title: 'Tỉnh Hà Nam',
    code: 35,
    value: 'tinh_ha_nam',
    division_type: 'tỉnh',
    phone_code: 226,
    children: [
      {
        title: 'Thành phố Phủ Lý',
        code: 347,
        value: 'thanh_pho_phu_ly',
        division_type: 'thành phố',
        short_codename: 'phu_ly',
        children: [
          {
            title: 'Phường Quang Trung',
            code: 13285,
            value: 'phuong_quang_trung',
            division_type: 'phường',
            short_codename: 'quang_trung',
            parentId: 347,
          },
          {
            title: 'Phường Lương Khánh Thiện',
            code: 13288,
            value: 'phuong_luong_khanh_thien',
            division_type: 'phường',
            short_codename: 'luong_khanh_thien',
            parentId: 347,
          },
          {
            title: 'Phường Lê Hồng Phong',
            code: 13291,
            value: 'phuong_le_hong_phong',
            division_type: 'phường',
            short_codename: 'le_hong_phong',
            parentId: 347,
          },
          {
            title: 'Phường Minh Khai',
            code: 13294,
            value: 'phuong_minh_khai',
            division_type: 'phường',
            short_codename: 'minh_khai',
            parentId: 347,
          },
          {
            title: 'Phường Hai Bà Trưng',
            code: 13297,
            value: 'phuong_hai_ba_trung',
            division_type: 'phường',
            short_codename: 'hai_ba_trung',
            parentId: 347,
          },
          {
            title: 'Phường Trần Hưng Đạo',
            code: 13300,
            value: 'phuong_tran_hung_dao',
            division_type: 'phường',
            short_codename: 'tran_hung_dao',
            parentId: 347,
          },
          {
            title: 'Phường Lam Hạ',
            code: 13303,
            value: 'phuong_lam_ha',
            division_type: 'phường',
            short_codename: 'lam_ha',
            parentId: 347,
          },
          {
            title: 'Xã Phù Vân',
            code: 13306,
            value: 'xa_phu_van',
            division_type: 'xã',
            short_codename: 'phu_van',
            parentId: 347,
          },
          {
            title: 'Phường Liêm Chính',
            code: 13309,
            value: 'phuong_liem_chinh',
            division_type: 'phường',
            short_codename: 'liem_chinh',
            parentId: 347,
          },
          {
            title: 'Xã Liêm Chung',
            code: 13312,
            value: 'xa_liem_chung',
            division_type: 'xã',
            short_codename: 'liem_chung',
            parentId: 347,
          },
          {
            title: 'Phường Thanh Châu',
            code: 13315,
            value: 'phuong_thanh_chau',
            division_type: 'phường',
            short_codename: 'thanh_chau',
            parentId: 347,
          },
          {
            title: 'Phường Châu Sơn',
            code: 13318,
            value: 'phuong_chau_son',
            division_type: 'phường',
            short_codename: 'chau_son',
            parentId: 347,
          },
          {
            title: 'Xã Tiên Tân',
            code: 13366,
            value: 'xa_tien_tan',
            division_type: 'xã',
            short_codename: 'tien_tan',
            parentId: 347,
          },
          {
            title: 'Xã Tiên Hiệp',
            code: 13372,
            value: 'xa_tien_hiep',
            division_type: 'xã',
            short_codename: 'tien_hiep',
            parentId: 347,
          },
          {
            title: 'Xã Tiên Hải',
            code: 13381,
            value: 'xa_tien_hai',
            division_type: 'xã',
            short_codename: 'tien_hai',
            parentId: 347,
          },
          {
            title: 'Xã Kim Bình',
            code: 13426,
            value: 'xa_kim_binh',
            division_type: 'xã',
            short_codename: 'kim_binh',
            parentId: 347,
          },
          {
            title: 'Xã Liêm Tuyền',
            code: 13444,
            value: 'xa_liem_tuyen',
            division_type: 'xã',
            short_codename: 'liem_tuyen',
            parentId: 347,
          },
          {
            title: 'Xã Liêm Tiết',
            code: 13447,
            value: 'xa_liem_tiet',
            division_type: 'xã',
            short_codename: 'liem_tiet',
            parentId: 347,
          },
          {
            title: 'Phường Thanh Tuyền',
            code: 13459,
            value: 'phuong_thanh_tuyen',
            division_type: 'phường',
            short_codename: 'thanh_tuyen',
            parentId: 347,
          },
          {
            title: 'Xã Đinh Xá',
            code: 13507,
            value: 'xa_dinh_xa',
            division_type: 'xã',
            short_codename: 'dinh_xa',
            parentId: 347,
          },
          {
            title: 'Xã Trịnh Xá',
            code: 13513,
            value: 'xa_trinh_xa',
            division_type: 'xã',
            short_codename: 'trinh_xa',
            parentId: 347,
          },
        ],
        parentId: 35,
      },
      {
        title: 'Thị xã Duy Tiên',
        code: 349,
        value: 'thi_xa_duy_tien',
        division_type: 'thị xã',
        short_codename: 'duy_tien',
        children: [
          {
            title: 'Phường Đồng Văn',
            code: 13321,
            value: 'phuong_dong_van',
            division_type: 'phường',
            short_codename: 'dong_van',
            parentId: 349,
          },
          {
            title: 'Phường Hòa Mạc',
            code: 13324,
            value: 'phuong_hoa_mac',
            division_type: 'phường',
            short_codename: 'hoa_mac',
            parentId: 349,
          },
          {
            title: 'Xã Mộc Bắc',
            code: 13327,
            value: 'xa_moc_bac',
            division_type: 'xã',
            short_codename: 'moc_bac',
            parentId: 349,
          },
          {
            title: 'Phường Châu Giang',
            code: 13330,
            value: 'phuong_chau_giang',
            division_type: 'phường',
            short_codename: 'chau_giang',
            parentId: 349,
          },
          {
            title: 'Phường Bạch Thượng',
            code: 13333,
            value: 'phuong_bach_thuong',
            division_type: 'phường',
            short_codename: 'bach_thuong',
            parentId: 349,
          },
          {
            title: 'Phường Duy Minh',
            code: 13336,
            value: 'phuong_duy_minh',
            division_type: 'phường',
            short_codename: 'duy_minh',
            parentId: 349,
          },
          {
            title: 'Xã Mộc Nam',
            code: 13339,
            value: 'xa_moc_nam',
            division_type: 'xã',
            short_codename: 'moc_nam',
            parentId: 349,
          },
          {
            title: 'Phường Duy Hải',
            code: 13342,
            value: 'phuong_duy_hai',
            division_type: 'phường',
            short_codename: 'duy_hai',
            parentId: 349,
          },
          {
            title: 'Xã Chuyên Ngoại',
            code: 13345,
            value: 'xa_chuyen_ngoai',
            division_type: 'xã',
            short_codename: 'chuyen_ngoai',
            parentId: 349,
          },
          {
            title: 'Phường Yên Bắc',
            code: 13348,
            value: 'phuong_yen_bac',
            division_type: 'phường',
            short_codename: 'yen_bac',
            parentId: 349,
          },
          {
            title: 'Xã Trác Văn',
            code: 13351,
            value: 'xa_trac_van',
            division_type: 'xã',
            short_codename: 'trac_van',
            parentId: 349,
          },
          {
            title: 'Phường Tiên Nội',
            code: 13354,
            value: 'phuong_tien_noi',
            division_type: 'phường',
            short_codename: 'tien_noi',
            parentId: 349,
          },
          {
            title: 'Phường Hoàng Đông',
            code: 13357,
            value: 'phuong_hoang_dong',
            division_type: 'phường',
            short_codename: 'hoang_dong',
            parentId: 349,
          },
          {
            title: 'Xã Yên Nam',
            code: 13360,
            value: 'xa_yen_nam',
            division_type: 'xã',
            short_codename: 'yen_nam',
            parentId: 349,
          },
          {
            title: 'Xã Tiên Ngoại',
            code: 13363,
            value: 'xa_tien_ngoai',
            division_type: 'xã',
            short_codename: 'tien_ngoai',
            parentId: 349,
          },
          {
            title: 'Xã Tiên Sơn',
            code: 13369,
            value: 'xa_tien_son',
            division_type: 'xã',
            short_codename: 'tien_son',
            parentId: 349,
          },
        ],
        parentId: 35,
      },
      {
        title: 'Huyện Kim Bảng',
        code: 350,
        value: 'huyen_kim_bang',
        division_type: 'huyện',
        short_codename: 'kim_bang',
        children: [
          {
            title: 'Thị trấn Quế',
            code: 13384,
            value: 'thi_tran_que',
            division_type: 'thị trấn',
            short_codename: 'que',
            parentId: 350,
          },
          {
            title: 'Xã Nguyễn Úy',
            code: 13387,
            value: 'xa_nguyen_uy',
            division_type: 'xã',
            short_codename: 'nguyen_uy',
            parentId: 350,
          },
          {
            title: 'Xã Đại Cương',
            code: 13390,
            value: 'xa_dai_cuong',
            division_type: 'xã',
            short_codename: 'dai_cuong',
            parentId: 350,
          },
          {
            title: 'Xã Lê Hồ',
            code: 13393,
            value: 'xa_le_ho',
            division_type: 'xã',
            short_codename: 'le_ho',
            parentId: 350,
          },
          {
            title: 'Xã Tượng Lĩnh',
            code: 13396,
            value: 'xa_tuong_linh',
            division_type: 'xã',
            short_codename: 'tuong_linh',
            parentId: 350,
          },
          {
            title: 'Xã Nhật Tựu',
            code: 13399,
            value: 'xa_nhat_tuu',
            division_type: 'xã',
            short_codename: 'nhat_tuu',
            parentId: 350,
          },
          {
            title: 'Xã Nhật Tân',
            code: 13402,
            value: 'xa_nhat_tan',
            division_type: 'xã',
            short_codename: 'nhat_tan',
            parentId: 350,
          },
          {
            title: 'Xã Đồng Hóa',
            code: 13405,
            value: 'xa_dong_hoa',
            division_type: 'xã',
            short_codename: 'dong_hoa',
            parentId: 350,
          },
          {
            title: 'Xã Hoàng Tây',
            code: 13408,
            value: 'xa_hoang_tay',
            division_type: 'xã',
            short_codename: 'hoang_tay',
            parentId: 350,
          },
          {
            title: 'Xã Tân Sơn',
            code: 13411,
            value: 'xa_tan_son',
            division_type: 'xã',
            short_codename: 'tan_son',
            parentId: 350,
          },
          {
            title: 'Xã Thụy Lôi',
            code: 13414,
            value: 'xa_thuy_loi',
            division_type: 'xã',
            short_codename: 'thuy_loi',
            parentId: 350,
          },
          {
            title: 'Xã Văn Xá',
            code: 13417,
            value: 'xa_van_xa',
            division_type: 'xã',
            short_codename: 'van_xa',
            parentId: 350,
          },
          {
            title: 'Xã Khả Phong',
            code: 13420,
            value: 'xa_kha_phong',
            division_type: 'xã',
            short_codename: 'kha_phong',
            parentId: 350,
          },
          {
            title: 'Xã Ngọc Sơn',
            code: 13423,
            value: 'xa_ngoc_son',
            division_type: 'xã',
            short_codename: 'ngoc_son',
            parentId: 350,
          },
          {
            title: 'Thị trấn Ba Sao',
            code: 13429,
            value: 'thi_tran_ba_sao',
            division_type: 'thị trấn',
            short_codename: 'ba_sao',
            parentId: 350,
          },
          {
            title: 'Xã Liên Sơn',
            code: 13432,
            value: 'xa_lien_son',
            division_type: 'xã',
            short_codename: 'lien_son',
            parentId: 350,
          },
          {
            title: 'Xã Thi Sơn',
            code: 13435,
            value: 'xa_thi_son',
            division_type: 'xã',
            short_codename: 'thi_son',
            parentId: 350,
          },
          {
            title: 'Xã Thanh Sơn',
            code: 13438,
            value: 'xa_thanh_son',
            division_type: 'xã',
            short_codename: 'thanh_son',
            parentId: 350,
          },
        ],
        parentId: 35,
      },
      {
        title: 'Huyện Thanh Liêm',
        code: 351,
        value: 'huyen_thanh_liem',
        division_type: 'huyện',
        short_codename: 'thanh_liem',
        children: [
          {
            title: 'Thị trấn Kiện Khê',
            code: 13441,
            value: 'thi_tran_kien_khe',
            division_type: 'thị trấn',
            short_codename: 'kien_khe',
            parentId: 351,
          },
          {
            title: 'Xã Liêm Phong',
            code: 13450,
            value: 'xa_liem_phong',
            division_type: 'xã',
            short_codename: 'liem_phong',
            parentId: 351,
          },
          {
            title: 'Xã Thanh Hà',
            code: 13453,
            value: 'xa_thanh_ha',
            division_type: 'xã',
            short_codename: 'thanh_ha',
            parentId: 351,
          },
          {
            title: 'Xã Liêm Cần',
            code: 13456,
            value: 'xa_liem_can',
            division_type: 'xã',
            short_codename: 'liem_can',
            parentId: 351,
          },
          {
            title: 'Xã Liêm Thuận',
            code: 13465,
            value: 'xa_liem_thuan',
            division_type: 'xã',
            short_codename: 'liem_thuan',
            parentId: 351,
          },
          {
            title: 'Xã Thanh Thủy',
            code: 13468,
            value: 'xa_thanh_thuy',
            division_type: 'xã',
            short_codename: 'thanh_thuy',
            parentId: 351,
          },
          {
            title: 'Xã Thanh Phong',
            code: 13471,
            value: 'xa_thanh_phong',
            division_type: 'xã',
            short_codename: 'thanh_phong',
            parentId: 351,
          },
          {
            title: 'Thị trấn Tân Thanh',
            code: 13474,
            value: 'thi_tran_tan_thanh',
            division_type: 'thị trấn',
            short_codename: 'tan_thanh',
            parentId: 351,
          },
          {
            title: 'Xã Thanh Tân',
            code: 13477,
            value: 'xa_thanh_tan',
            division_type: 'xã',
            short_codename: 'thanh_tan',
            parentId: 351,
          },
          {
            title: 'Xã Liêm Túc',
            code: 13480,
            value: 'xa_liem_tuc',
            division_type: 'xã',
            short_codename: 'liem_tuc',
            parentId: 351,
          },
          {
            title: 'Xã Liêm Sơn',
            code: 13483,
            value: 'xa_liem_son',
            division_type: 'xã',
            short_codename: 'liem_son',
            parentId: 351,
          },
          {
            title: 'Xã Thanh Hương',
            code: 13486,
            value: 'xa_thanh_huong',
            division_type: 'xã',
            short_codename: 'thanh_huong',
            parentId: 351,
          },
          {
            title: 'Xã Thanh Nghị',
            code: 13489,
            value: 'xa_thanh_nghi',
            division_type: 'xã',
            short_codename: 'thanh_nghi',
            parentId: 351,
          },
          {
            title: 'Xã Thanh Tâm',
            code: 13492,
            value: 'xa_thanh_tam',
            division_type: 'xã',
            short_codename: 'thanh_tam',
            parentId: 351,
          },
          {
            title: 'Xã Thanh Nguyên',
            code: 13495,
            value: 'xa_thanh_nguyen',
            division_type: 'xã',
            short_codename: 'thanh_nguyen',
            parentId: 351,
          },
          {
            title: 'Xã Thanh Hải',
            code: 13498,
            value: 'xa_thanh_hai',
            division_type: 'xã',
            short_codename: 'thanh_hai',
            parentId: 351,
          },
        ],
        parentId: 35,
      },
      {
        title: 'Huyện Bình Lục',
        code: 352,
        value: 'huyen_binh_luc',
        division_type: 'huyện',
        short_codename: 'binh_luc',
        children: [
          {
            title: 'Thị trấn Bình Mỹ',
            code: 13501,
            value: 'thi_tran_binh_my',
            division_type: 'thị trấn',
            short_codename: 'binh_my',
            parentId: 352,
          },
          {
            title: 'Xã Bình Nghĩa',
            code: 13504,
            value: 'xa_binh_nghia',
            division_type: 'xã',
            short_codename: 'binh_nghia',
            parentId: 352,
          },
          {
            title: 'Xã Tràng An',
            code: 13510,
            value: 'xa_trang_an',
            division_type: 'xã',
            short_codename: 'trang_an',
            parentId: 352,
          },
          {
            title: 'Xã Đồng Du',
            code: 13516,
            value: 'xa_dong_du',
            division_type: 'xã',
            short_codename: 'dong_du',
            parentId: 352,
          },
          {
            title: 'Xã Ngọc Lũ',
            code: 13519,
            value: 'xa_ngoc_lu',
            division_type: 'xã',
            short_codename: 'ngoc_lu',
            parentId: 352,
          },
          {
            title: 'Xã Hưng Công',
            code: 13522,
            value: 'xa_hung_cong',
            division_type: 'xã',
            short_codename: 'hung_cong',
            parentId: 352,
          },
          {
            title: 'Xã Đồn Xá',
            code: 13525,
            value: 'xa_don_xa',
            division_type: 'xã',
            short_codename: 'don_xa',
            parentId: 352,
          },
          {
            title: 'Xã An Ninh',
            code: 13528,
            value: 'xa_an_ninh',
            division_type: 'xã',
            short_codename: 'an_ninh',
            parentId: 352,
          },
          {
            title: 'Xã Bồ Đề',
            code: 13531,
            value: 'xa_bo_de',
            division_type: 'xã',
            short_codename: 'bo_de',
            parentId: 352,
          },
          {
            title: 'Xã Bối Cầu',
            code: 13534,
            value: 'xa_boi_cau',
            division_type: 'xã',
            short_codename: 'boi_cau',
            parentId: 352,
          },
          {
            title: 'Xã An Nội',
            code: 13540,
            value: 'xa_an_noi',
            division_type: 'xã',
            short_codename: 'an_noi',
            parentId: 352,
          },
          {
            title: 'Xã Vũ Bản',
            code: 13543,
            value: 'xa_vu_ban',
            division_type: 'xã',
            short_codename: 'vu_ban',
            parentId: 352,
          },
          {
            title: 'Xã Trung Lương',
            code: 13546,
            value: 'xa_trung_luong',
            division_type: 'xã',
            short_codename: 'trung_luong',
            parentId: 352,
          },
          {
            title: 'Xã An Đổ',
            code: 13552,
            value: 'xa_an_do',
            division_type: 'xã',
            short_codename: 'an_do',
            parentId: 352,
          },
          {
            title: 'Xã La Sơn',
            code: 13555,
            value: 'xa_la_son',
            division_type: 'xã',
            short_codename: 'la_son',
            parentId: 352,
          },
          {
            title: 'Xã Tiêu Động',
            code: 13558,
            value: 'xa_tieu_dong',
            division_type: 'xã',
            short_codename: 'tieu_dong',
            parentId: 352,
          },
          {
            title: 'Xã An Lão',
            code: 13561,
            value: 'xa_an_lao',
            division_type: 'xã',
            short_codename: 'an_lao',
            parentId: 352,
          },
        ],
        parentId: 35,
      },
      {
        title: 'Huyện Lý Nhân',
        code: 353,
        value: 'huyen_ly_nhan',
        division_type: 'huyện',
        short_codename: 'ly_nhan',
        children: [
          {
            title: 'Xã Hợp Lý',
            code: 13567,
            value: 'xa_hop_ly',
            division_type: 'xã',
            short_codename: 'hop_ly',
            parentId: 353,
          },
          {
            title: 'Xã Nguyên Lý',
            code: 13570,
            value: 'xa_nguyen_ly',
            division_type: 'xã',
            short_codename: 'nguyen_ly',
            parentId: 353,
          },
          {
            title: 'Xã Chính Lý',
            code: 13573,
            value: 'xa_chinh_ly',
            division_type: 'xã',
            short_codename: 'chinh_ly',
            parentId: 353,
          },
          {
            title: 'Xã Chân Lý',
            code: 13576,
            value: 'xa_chan_ly',
            division_type: 'xã',
            short_codename: 'chan_ly',
            parentId: 353,
          },
          {
            title: 'Xã Đạo Lý',
            code: 13579,
            value: 'xa_dao_ly',
            division_type: 'xã',
            short_codename: 'dao_ly',
            parentId: 353,
          },
          {
            title: 'Xã Công Lý',
            code: 13582,
            value: 'xa_cong_ly',
            division_type: 'xã',
            short_codename: 'cong_ly',
            parentId: 353,
          },
          {
            title: 'Xã Văn Lý',
            code: 13585,
            value: 'xa_van_ly',
            division_type: 'xã',
            short_codename: 'van_ly',
            parentId: 353,
          },
          {
            title: 'Xã Bắc Lý',
            code: 13588,
            value: 'xa_bac_ly',
            division_type: 'xã',
            short_codename: 'bac_ly',
            parentId: 353,
          },
          {
            title: 'Xã Đức Lý',
            code: 13591,
            value: 'xa_duc_ly',
            division_type: 'xã',
            short_codename: 'duc_ly',
            parentId: 353,
          },
          {
            title: 'Xã Trần Hưng Đạo',
            code: 13594,
            value: 'xa_tran_hung_dao',
            division_type: 'xã',
            short_codename: 'tran_hung_dao',
            parentId: 353,
          },
          {
            title: 'Thị trấn Vĩnh Trụ',
            code: 13597,
            value: 'thi_tran_vinh_tru',
            division_type: 'thị trấn',
            short_codename: 'vinh_tru',
            parentId: 353,
          },
          {
            title: 'Xã Nhân Thịnh',
            code: 13600,
            value: 'xa_nhan_thinh',
            division_type: 'xã',
            short_codename: 'nhan_thinh',
            parentId: 353,
          },
          {
            title: 'Xã Nhân Khang',
            code: 13606,
            value: 'xa_nhan_khang',
            division_type: 'xã',
            short_codename: 'nhan_khang',
            parentId: 353,
          },
          {
            title: 'Xã Nhân Mỹ',
            code: 13609,
            value: 'xa_nhan_my',
            division_type: 'xã',
            short_codename: 'nhan_my',
            parentId: 353,
          },
          {
            title: 'Xã Nhân Nghĩa',
            code: 13612,
            value: 'xa_nhan_nghia',
            division_type: 'xã',
            short_codename: 'nhan_nghia',
            parentId: 353,
          },
          {
            title: 'Xã Nhân Chính',
            code: 13615,
            value: 'xa_nhan_chinh',
            division_type: 'xã',
            short_codename: 'nhan_chinh',
            parentId: 353,
          },
          {
            title: 'Xã Nhân Bình',
            code: 13618,
            value: 'xa_nhan_binh',
            division_type: 'xã',
            short_codename: 'nhan_binh',
            parentId: 353,
          },
          {
            title: 'Xã Phú Phúc',
            code: 13621,
            value: 'xa_phu_phuc',
            division_type: 'xã',
            short_codename: 'phu_phuc',
            parentId: 353,
          },
          {
            title: 'Xã Xuân Khê',
            code: 13624,
            value: 'xa_xuan_khe',
            division_type: 'xã',
            short_codename: 'xuan_khe',
            parentId: 353,
          },
          {
            title: 'Xã Tiến Thắng',
            code: 13627,
            value: 'xa_tien_thang',
            division_type: 'xã',
            short_codename: 'tien_thang',
            parentId: 353,
          },
          {
            title: 'Xã Hòa Hậu',
            code: 13630,
            value: 'xa_hoa_hau',
            division_type: 'xã',
            short_codename: 'hoa_hau',
            parentId: 353,
          },
        ],
        parentId: 35,
      },
    ],
  },
  {
    title: 'Tỉnh Nam Định',
    code: 36,
    value: 'tinh_nam_dinh',
    division_type: 'tỉnh',
    phone_code: 228,
    children: [
      {
        title: 'Thành phố Nam Định',
        code: 356,
        value: 'thanh_pho_nam_dinh',
        division_type: 'thành phố',
        short_codename: 'nam_dinh',
        children: [
          {
            title: 'Phường Hạ Long',
            code: 13633,
            value: 'phuong_ha_long',
            division_type: 'phường',
            short_codename: 'ha_long',
            parentId: 356,
          },
          {
            title: 'Phường Trần Tế Xương',
            code: 13636,
            value: 'phuong_tran_te_xuong',
            division_type: 'phường',
            short_codename: 'tran_te_xuong',
            parentId: 356,
          },
          {
            title: 'Phường Vị Hoàng',
            code: 13639,
            value: 'phuong_vi_hoang',
            division_type: 'phường',
            short_codename: 'vi_hoang',
            parentId: 356,
          },
          {
            title: 'Phường Vị Xuyên',
            code: 13642,
            value: 'phuong_vi_xuyen',
            division_type: 'phường',
            short_codename: 'vi_xuyen',
            parentId: 356,
          },
          {
            title: 'Phường Quang Trung',
            code: 13645,
            value: 'phuong_quang_trung',
            division_type: 'phường',
            short_codename: 'quang_trung',
            parentId: 356,
          },
          {
            title: 'Phường Cửa Bắc',
            code: 13648,
            value: 'phuong_cua_bac',
            division_type: 'phường',
            short_codename: 'cua_bac',
            parentId: 356,
          },
          {
            title: 'Phường Nguyễn Du',
            code: 13651,
            value: 'phuong_nguyen_du',
            division_type: 'phường',
            short_codename: 'nguyen_du',
            parentId: 356,
          },
          {
            title: 'Phường Bà Triệu',
            code: 13654,
            value: 'phuong_ba_trieu',
            division_type: 'phường',
            short_codename: 'ba_trieu',
            parentId: 356,
          },
          {
            title: 'Phường Trường Thi',
            code: 13657,
            value: 'phuong_truong_thi',
            division_type: 'phường',
            short_codename: 'truong_thi',
            parentId: 356,
          },
          {
            title: 'Phường Phan Đình Phùng',
            code: 13660,
            value: 'phuong_phan_dinh_phung',
            division_type: 'phường',
            short_codename: 'phan_dinh_phung',
            parentId: 356,
          },
          {
            title: 'Phường Ngô Quyền',
            code: 13663,
            value: 'phuong_ngo_quyen',
            division_type: 'phường',
            short_codename: 'ngo_quyen',
            parentId: 356,
          },
          {
            title: 'Phường Trần Hưng Đạo',
            code: 13666,
            value: 'phuong_tran_hung_dao',
            division_type: 'phường',
            short_codename: 'tran_hung_dao',
            parentId: 356,
          },
          {
            title: 'Phường Trần Đăng Ninh',
            code: 13669,
            value: 'phuong_tran_dang_ninh',
            division_type: 'phường',
            short_codename: 'tran_dang_ninh',
            parentId: 356,
          },
          {
            title: 'Phường Năng Tĩnh',
            code: 13672,
            value: 'phuong_nang_tinh',
            division_type: 'phường',
            short_codename: 'nang_tinh',
            parentId: 356,
          },
          {
            title: 'Phường Văn Miếu',
            code: 13675,
            value: 'phuong_van_mieu',
            division_type: 'phường',
            short_codename: 'van_mieu',
            parentId: 356,
          },
          {
            title: 'Phường Trần Quang Khải',
            code: 13678,
            value: 'phuong_tran_quang_khai',
            division_type: 'phường',
            short_codename: 'tran_quang_khai',
            parentId: 356,
          },
          {
            title: 'Phường Thống Nhất',
            code: 13681,
            value: 'phuong_thong_nhat',
            division_type: 'phường',
            short_codename: 'thong_nhat',
            parentId: 356,
          },
          {
            title: 'Phường Lộc Hạ',
            code: 13684,
            value: 'phuong_loc_ha',
            division_type: 'phường',
            short_codename: 'loc_ha',
            parentId: 356,
          },
          {
            title: 'Phường Lộc Vượng',
            code: 13687,
            value: 'phuong_loc_vuong',
            division_type: 'phường',
            short_codename: 'loc_vuong',
            parentId: 356,
          },
          {
            title: 'Phường Cửa Nam',
            code: 13690,
            value: 'phuong_cua_nam',
            division_type: 'phường',
            short_codename: 'cua_nam',
            parentId: 356,
          },
          {
            title: 'Phường Lộc Hòa',
            code: 13693,
            value: 'phuong_loc_hoa',
            division_type: 'phường',
            short_codename: 'loc_hoa',
            parentId: 356,
          },
          {
            title: 'Xã Nam Phong',
            code: 13696,
            value: 'xa_nam_phong',
            division_type: 'xã',
            short_codename: 'nam_phong',
            parentId: 356,
          },
          {
            title: 'Phường Mỹ Xá',
            code: 13699,
            value: 'phuong_my_xa',
            division_type: 'phường',
            short_codename: 'my_xa',
            parentId: 356,
          },
          {
            title: 'Xã Lộc An',
            code: 13702,
            value: 'xa_loc_an',
            division_type: 'xã',
            short_codename: 'loc_an',
            parentId: 356,
          },
          {
            title: 'Xã Nam Vân',
            code: 13705,
            value: 'xa_nam_van',
            division_type: 'xã',
            short_codename: 'nam_van',
            parentId: 356,
          },
        ],
        parentId: 36,
      },
      {
        title: 'Huyện Mỹ Lộc',
        code: 358,
        value: 'huyen_my_loc',
        division_type: 'huyện',
        short_codename: 'my_loc',
        children: [
          {
            title: 'Thị trấn Mỹ Lộc',
            code: 13708,
            value: 'thi_tran_my_loc',
            division_type: 'thị trấn',
            short_codename: 'my_loc',
            parentId: 358,
          },
          {
            title: 'Xã Mỹ Hà',
            code: 13711,
            value: 'xa_my_ha',
            division_type: 'xã',
            short_codename: 'my_ha',
            parentId: 358,
          },
          {
            title: 'Xã Mỹ Tiến',
            code: 13714,
            value: 'xa_my_tien',
            division_type: 'xã',
            short_codename: 'my_tien',
            parentId: 358,
          },
          {
            title: 'Xã Mỹ Thắng',
            code: 13717,
            value: 'xa_my_thang',
            division_type: 'xã',
            short_codename: 'my_thang',
            parentId: 358,
          },
          {
            title: 'Xã Mỹ Trung',
            code: 13720,
            value: 'xa_my_trung',
            division_type: 'xã',
            short_codename: 'my_trung',
            parentId: 358,
          },
          {
            title: 'Xã Mỹ Tân',
            code: 13723,
            value: 'xa_my_tan',
            division_type: 'xã',
            short_codename: 'my_tan',
            parentId: 358,
          },
          {
            title: 'Xã Mỹ Phúc',
            code: 13726,
            value: 'xa_my_phuc',
            division_type: 'xã',
            short_codename: 'my_phuc',
            parentId: 358,
          },
          {
            title: 'Xã Mỹ Hưng',
            code: 13729,
            value: 'xa_my_hung',
            division_type: 'xã',
            short_codename: 'my_hung',
            parentId: 358,
          },
          {
            title: 'Xã Mỹ Thuận',
            code: 13732,
            value: 'xa_my_thuan',
            division_type: 'xã',
            short_codename: 'my_thuan',
            parentId: 358,
          },
          {
            title: 'Xã Mỹ Thịnh',
            code: 13735,
            value: 'xa_my_thinh',
            division_type: 'xã',
            short_codename: 'my_thinh',
            parentId: 358,
          },
          {
            title: 'Xã Mỹ Thành',
            code: 13738,
            value: 'xa_my_thanh',
            division_type: 'xã',
            short_codename: 'my_thanh',
            parentId: 358,
          },
        ],
        parentId: 36,
      },
      {
        title: 'Huyện Vụ Bản',
        code: 359,
        value: 'huyen_vu_ban',
        division_type: 'huyện',
        short_codename: 'vu_ban',
        children: [
          {
            title: 'Thị trấn Gôi',
            code: 13741,
            value: 'thi_tran_goi',
            division_type: 'thị trấn',
            short_codename: 'goi',
            parentId: 359,
          },
          {
            title: 'Xã Minh Thuận',
            code: 13744,
            value: 'xa_minh_thuan',
            division_type: 'xã',
            short_codename: 'minh_thuan',
            parentId: 359,
          },
          {
            title: 'Xã Hiển Khánh',
            code: 13747,
            value: 'xa_hien_khanh',
            division_type: 'xã',
            short_codename: 'hien_khanh',
            parentId: 359,
          },
          {
            title: 'Xã Tân Khánh',
            code: 13750,
            value: 'xa_tan_khanh',
            division_type: 'xã',
            short_codename: 'tan_khanh',
            parentId: 359,
          },
          {
            title: 'Xã Hợp Hưng',
            code: 13753,
            value: 'xa_hop_hung',
            division_type: 'xã',
            short_codename: 'hop_hung',
            parentId: 359,
          },
          {
            title: 'Xã Đại An',
            code: 13756,
            value: 'xa_dai_an',
            division_type: 'xã',
            short_codename: 'dai_an',
            parentId: 359,
          },
          {
            title: 'Xã Tân Thành',
            code: 13759,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 359,
          },
          {
            title: 'Xã Cộng Hòa',
            code: 13762,
            value: 'xa_cong_hoa',
            division_type: 'xã',
            short_codename: 'cong_hoa',
            parentId: 359,
          },
          {
            title: 'Xã Trung Thành',
            code: 13765,
            value: 'xa_trung_thanh',
            division_type: 'xã',
            short_codename: 'trung_thanh',
            parentId: 359,
          },
          {
            title: 'Xã Quang Trung',
            code: 13768,
            value: 'xa_quang_trung',
            division_type: 'xã',
            short_codename: 'quang_trung',
            parentId: 359,
          },
          {
            title: 'Xã Minh Tân',
            code: 13771,
            value: 'xa_minh_tan',
            division_type: 'xã',
            short_codename: 'minh_tan',
            parentId: 359,
          },
          {
            title: 'Xã Liên Bảo',
            code: 13774,
            value: 'xa_lien_bao',
            division_type: 'xã',
            short_codename: 'lien_bao',
            parentId: 359,
          },
          {
            title: 'Xã Thành Lợi',
            code: 13777,
            value: 'xa_thanh_loi',
            division_type: 'xã',
            short_codename: 'thanh_loi',
            parentId: 359,
          },
          {
            title: 'Xã Kim Thái',
            code: 13780,
            value: 'xa_kim_thai',
            division_type: 'xã',
            short_codename: 'kim_thai',
            parentId: 359,
          },
          {
            title: 'Xã Liên Minh',
            code: 13783,
            value: 'xa_lien_minh',
            division_type: 'xã',
            short_codename: 'lien_minh',
            parentId: 359,
          },
          {
            title: 'Xã Đại Thắng',
            code: 13786,
            value: 'xa_dai_thang',
            division_type: 'xã',
            short_codename: 'dai_thang',
            parentId: 359,
          },
          {
            title: 'Xã Tam Thanh',
            code: 13789,
            value: 'xa_tam_thanh',
            division_type: 'xã',
            short_codename: 'tam_thanh',
            parentId: 359,
          },
          {
            title: 'Xã Vĩnh Hào',
            code: 13792,
            value: 'xa_vinh_hao',
            division_type: 'xã',
            short_codename: 'vinh_hao',
            parentId: 359,
          },
        ],
        parentId: 36,
      },
      {
        title: 'Huyện Ý Yên',
        code: 360,
        value: 'huyen_y_yen',
        division_type: 'huyện',
        short_codename: 'y_yen',
        children: [
          {
            title: 'Thị trấn Lâm',
            code: 13795,
            value: 'thi_tran_lam',
            division_type: 'thị trấn',
            short_codename: 'lam',
            parentId: 360,
          },
          {
            title: 'Xã Yên Trung',
            code: 13798,
            value: 'xa_yen_trung',
            division_type: 'xã',
            short_codename: 'yen_trung',
            parentId: 360,
          },
          {
            title: 'Xã Yên Thành',
            code: 13801,
            value: 'xa_yen_thanh',
            division_type: 'xã',
            short_codename: 'yen_thanh',
            parentId: 360,
          },
          {
            title: 'Xã Yên Tân',
            code: 13804,
            value: 'xa_yen_tan',
            division_type: 'xã',
            short_codename: 'yen_tan',
            parentId: 360,
          },
          {
            title: 'Xã Yên Lợi',
            code: 13807,
            value: 'xa_yen_loi',
            division_type: 'xã',
            short_codename: 'yen_loi',
            parentId: 360,
          },
          {
            title: 'Xã Yên Thọ',
            code: 13810,
            value: 'xa_yen_tho',
            division_type: 'xã',
            short_codename: 'yen_tho',
            parentId: 360,
          },
          {
            title: 'Xã Yên Nghĩa',
            code: 13813,
            value: 'xa_yen_nghia',
            division_type: 'xã',
            short_codename: 'yen_nghia',
            parentId: 360,
          },
          {
            title: 'Xã Yên Minh',
            code: 13816,
            value: 'xa_yen_minh',
            division_type: 'xã',
            short_codename: 'yen_minh',
            parentId: 360,
          },
          {
            title: 'Xã Yên Phương',
            code: 13819,
            value: 'xa_yen_phuong',
            division_type: 'xã',
            short_codename: 'yen_phuong',
            parentId: 360,
          },
          {
            title: 'Xã Yên Chính',
            code: 13822,
            value: 'xa_yen_chinh',
            division_type: 'xã',
            short_codename: 'yen_chinh',
            parentId: 360,
          },
          {
            title: 'Xã Yên Bình',
            code: 13825,
            value: 'xa_yen_binh',
            division_type: 'xã',
            short_codename: 'yen_binh',
            parentId: 360,
          },
          {
            title: 'Xã Yên Phú',
            code: 13828,
            value: 'xa_yen_phu',
            division_type: 'xã',
            short_codename: 'yen_phu',
            parentId: 360,
          },
          {
            title: 'Xã Yên Mỹ',
            code: 13831,
            value: 'xa_yen_my',
            division_type: 'xã',
            short_codename: 'yen_my',
            parentId: 360,
          },
          {
            title: 'Xã Yên Dương',
            code: 13834,
            value: 'xa_yen_duong',
            division_type: 'xã',
            short_codename: 'yen_duong',
            parentId: 360,
          },
          {
            title: 'Xã Yên Hưng',
            code: 13840,
            value: 'xa_yen_hung',
            division_type: 'xã',
            short_codename: 'yen_hung',
            parentId: 360,
          },
          {
            title: 'Xã Yên Khánh',
            code: 13843,
            value: 'xa_yen_khanh',
            division_type: 'xã',
            short_codename: 'yen_khanh',
            parentId: 360,
          },
          {
            title: 'Xã Yên Phong',
            code: 13846,
            value: 'xa_yen_phong',
            division_type: 'xã',
            short_codename: 'yen_phong',
            parentId: 360,
          },
          {
            title: 'Xã Yên Ninh',
            code: 13849,
            value: 'xa_yen_ninh',
            division_type: 'xã',
            short_codename: 'yen_ninh',
            parentId: 360,
          },
          {
            title: 'Xã Yên Lương',
            code: 13852,
            value: 'xa_yen_luong',
            division_type: 'xã',
            short_codename: 'yen_luong',
            parentId: 360,
          },
          {
            title: 'Xã Yên Hồng',
            code: 13855,
            value: 'xa_yen_hong',
            division_type: 'xã',
            short_codename: 'yen_hong',
            parentId: 360,
          },
          {
            title: 'Xã Yên Quang',
            code: 13858,
            value: 'xa_yen_quang',
            division_type: 'xã',
            short_codename: 'yen_quang',
            parentId: 360,
          },
          {
            title: 'Xã Yên Tiến',
            code: 13861,
            value: 'xa_yen_tien',
            division_type: 'xã',
            short_codename: 'yen_tien',
            parentId: 360,
          },
          {
            title: 'Xã Yên Thắng',
            code: 13864,
            value: 'xa_yen_thang',
            division_type: 'xã',
            short_codename: 'yen_thang',
            parentId: 360,
          },
          {
            title: 'Xã Yên Phúc',
            code: 13867,
            value: 'xa_yen_phuc',
            division_type: 'xã',
            short_codename: 'yen_phuc',
            parentId: 360,
          },
          {
            title: 'Xã Yên Cường',
            code: 13870,
            value: 'xa_yen_cuong',
            division_type: 'xã',
            short_codename: 'yen_cuong',
            parentId: 360,
          },
          {
            title: 'Xã Yên Lộc',
            code: 13873,
            value: 'xa_yen_loc',
            division_type: 'xã',
            short_codename: 'yen_loc',
            parentId: 360,
          },
          {
            title: 'Xã Yên Bằng',
            code: 13876,
            value: 'xa_yen_bang',
            division_type: 'xã',
            short_codename: 'yen_bang',
            parentId: 360,
          },
          {
            title: 'Xã Yên Đồng',
            code: 13879,
            value: 'xa_yen_dong',
            division_type: 'xã',
            short_codename: 'yen_dong',
            parentId: 360,
          },
          {
            title: 'Xã Yên Khang',
            code: 13882,
            value: 'xa_yen_khang',
            division_type: 'xã',
            short_codename: 'yen_khang',
            parentId: 360,
          },
          {
            title: 'Xã Yên Nhân',
            code: 13885,
            value: 'xa_yen_nhan',
            division_type: 'xã',
            short_codename: 'yen_nhan',
            parentId: 360,
          },
          {
            title: 'Xã Yên Trị',
            code: 13888,
            value: 'xa_yen_tri',
            division_type: 'xã',
            short_codename: 'yen_tri',
            parentId: 360,
          },
        ],
        parentId: 36,
      },
      {
        title: 'Huyện Nghĩa Hưng',
        code: 361,
        value: 'huyen_nghia_hung',
        division_type: 'huyện',
        short_codename: 'nghia_hung',
        children: [
          {
            title: 'Thị trấn Liễu Đề',
            code: 13891,
            value: 'thi_tran_lieu_de',
            division_type: 'thị trấn',
            short_codename: 'lieu_de',
            parentId: 361,
          },
          {
            title: 'Thị trấn Rạng Đông',
            code: 13894,
            value: 'thi_tran_rang_dong',
            division_type: 'thị trấn',
            short_codename: 'rang_dong',
            parentId: 361,
          },
          {
            title: 'Xã Nghĩa Đồng',
            code: 13897,
            value: 'xa_nghia_dong',
            division_type: 'xã',
            short_codename: 'nghia_dong',
            parentId: 361,
          },
          {
            title: 'Xã Nghĩa Thịnh',
            code: 13900,
            value: 'xa_nghia_thinh',
            division_type: 'xã',
            short_codename: 'nghia_thinh',
            parentId: 361,
          },
          {
            title: 'Xã Nghĩa Minh',
            code: 13903,
            value: 'xa_nghia_minh',
            division_type: 'xã',
            short_codename: 'nghia_minh',
            parentId: 361,
          },
          {
            title: 'Xã Nghĩa Thái',
            code: 13906,
            value: 'xa_nghia_thai',
            division_type: 'xã',
            short_codename: 'nghia_thai',
            parentId: 361,
          },
          {
            title: 'Xã Hoàng Nam',
            code: 13909,
            value: 'xa_hoang_nam',
            division_type: 'xã',
            short_codename: 'hoang_nam',
            parentId: 361,
          },
          {
            title: 'Xã Nghĩa Châu',
            code: 13912,
            value: 'xa_nghia_chau',
            division_type: 'xã',
            short_codename: 'nghia_chau',
            parentId: 361,
          },
          {
            title: 'Xã Nghĩa Trung',
            code: 13915,
            value: 'xa_nghia_trung',
            division_type: 'xã',
            short_codename: 'nghia_trung',
            parentId: 361,
          },
          {
            title: 'Xã Nghĩa Sơn',
            code: 13918,
            value: 'xa_nghia_son',
            division_type: 'xã',
            short_codename: 'nghia_son',
            parentId: 361,
          },
          {
            title: 'Xã Nghĩa Lạc',
            code: 13921,
            value: 'xa_nghia_lac',
            division_type: 'xã',
            short_codename: 'nghia_lac',
            parentId: 361,
          },
          {
            title: 'Xã Nghĩa Hồng',
            code: 13924,
            value: 'xa_nghia_hong',
            division_type: 'xã',
            short_codename: 'nghia_hong',
            parentId: 361,
          },
          {
            title: 'Xã Nghĩa Phong',
            code: 13927,
            value: 'xa_nghia_phong',
            division_type: 'xã',
            short_codename: 'nghia_phong',
            parentId: 361,
          },
          {
            title: 'Xã Nghĩa Phú',
            code: 13930,
            value: 'xa_nghia_phu',
            division_type: 'xã',
            short_codename: 'nghia_phu',
            parentId: 361,
          },
          {
            title: 'Xã Nghĩa Bình',
            code: 13933,
            value: 'xa_nghia_binh',
            division_type: 'xã',
            short_codename: 'nghia_binh',
            parentId: 361,
          },
          {
            title: 'Thị trấn Quỹ Nhất',
            code: 13936,
            value: 'thi_tran_quy_nhat',
            division_type: 'thị trấn',
            short_codename: 'quy_nhat',
            parentId: 361,
          },
          {
            title: 'Xã Nghĩa Tân',
            code: 13939,
            value: 'xa_nghia_tan',
            division_type: 'xã',
            short_codename: 'nghia_tan',
            parentId: 361,
          },
          {
            title: 'Xã Nghĩa Hùng',
            code: 13942,
            value: 'xa_nghia_hung',
            division_type: 'xã',
            short_codename: 'nghia_hung',
            parentId: 361,
          },
          {
            title: 'Xã Nghĩa Lâm',
            code: 13945,
            value: 'xa_nghia_lam',
            division_type: 'xã',
            short_codename: 'nghia_lam',
            parentId: 361,
          },
          {
            title: 'Xã Nghĩa Thành',
            code: 13948,
            value: 'xa_nghia_thanh',
            division_type: 'xã',
            short_codename: 'nghia_thanh',
            parentId: 361,
          },
          {
            title: 'Xã Phúc Thắng',
            code: 13951,
            value: 'xa_phuc_thang',
            division_type: 'xã',
            short_codename: 'phuc_thang',
            parentId: 361,
          },
          {
            title: 'Xã Nghĩa Lợi',
            code: 13954,
            value: 'xa_nghia_loi',
            division_type: 'xã',
            short_codename: 'nghia_loi',
            parentId: 361,
          },
          {
            title: 'Xã Nghĩa Hải',
            code: 13957,
            value: 'xa_nghia_hai',
            division_type: 'xã',
            short_codename: 'nghia_hai',
            parentId: 361,
          },
          {
            title: 'Xã Nam Điền',
            code: 13963,
            value: 'xa_nam_dien',
            division_type: 'xã',
            short_codename: 'nam_dien',
            parentId: 361,
          },
        ],
        parentId: 36,
      },
      {
        title: 'Huyện Nam Trực',
        code: 362,
        value: 'huyen_nam_truc',
        division_type: 'huyện',
        short_codename: 'nam_truc',
        children: [
          {
            title: 'Thị trấn Nam Giang',
            code: 13966,
            value: 'thi_tran_nam_giang',
            division_type: 'thị trấn',
            short_codename: 'nam_giang',
            parentId: 362,
          },
          {
            title: 'Xã Nam Mỹ',
            code: 13969,
            value: 'xa_nam_my',
            division_type: 'xã',
            short_codename: 'nam_my',
            parentId: 362,
          },
          {
            title: 'Xã Điền Xá',
            code: 13972,
            value: 'xa_dien_xa',
            division_type: 'xã',
            short_codename: 'dien_xa',
            parentId: 362,
          },
          {
            title: 'Xã Nghĩa An',
            code: 13975,
            value: 'xa_nghia_an',
            division_type: 'xã',
            short_codename: 'nghia_an',
            parentId: 362,
          },
          {
            title: 'Xã Nam Thắng',
            code: 13978,
            value: 'xa_nam_thang',
            division_type: 'xã',
            short_codename: 'nam_thang',
            parentId: 362,
          },
          {
            title: 'Xã Nam Toàn',
            code: 13981,
            value: 'xa_nam_toan',
            division_type: 'xã',
            short_codename: 'nam_toan',
            parentId: 362,
          },
          {
            title: 'Xã Hồng Quang',
            code: 13984,
            value: 'xa_hong_quang',
            division_type: 'xã',
            short_codename: 'hong_quang',
            parentId: 362,
          },
          {
            title: 'Xã Tân Thịnh',
            code: 13987,
            value: 'xa_tan_thinh',
            division_type: 'xã',
            short_codename: 'tan_thinh',
            parentId: 362,
          },
          {
            title: 'Xã Nam Cường',
            code: 13990,
            value: 'xa_nam_cuong',
            division_type: 'xã',
            short_codename: 'nam_cuong',
            parentId: 362,
          },
          {
            title: 'Xã Nam Hồng',
            code: 13993,
            value: 'xa_nam_hong',
            division_type: 'xã',
            short_codename: 'nam_hong',
            parentId: 362,
          },
          {
            title: 'Xã Nam Hùng',
            code: 13996,
            value: 'xa_nam_hung',
            division_type: 'xã',
            short_codename: 'nam_hung',
            parentId: 362,
          },
          {
            title: 'Xã Nam Hoa',
            code: 13999,
            value: 'xa_nam_hoa',
            division_type: 'xã',
            short_codename: 'nam_hoa',
            parentId: 362,
          },
          {
            title: 'Xã Nam Dương',
            code: 14002,
            value: 'xa_nam_duong',
            division_type: 'xã',
            short_codename: 'nam_duong',
            parentId: 362,
          },
          {
            title: 'Xã Nam Thanh',
            code: 14005,
            value: 'xa_nam_thanh',
            division_type: 'xã',
            short_codename: 'nam_thanh',
            parentId: 362,
          },
          {
            title: 'Xã Nam Lợi',
            code: 14008,
            value: 'xa_nam_loi',
            division_type: 'xã',
            short_codename: 'nam_loi',
            parentId: 362,
          },
          {
            title: 'Xã Bình Minh',
            code: 14011,
            value: 'xa_binh_minh',
            division_type: 'xã',
            short_codename: 'binh_minh',
            parentId: 362,
          },
          {
            title: 'Xã Đồng Sơn',
            code: 14014,
            value: 'xa_dong_son',
            division_type: 'xã',
            short_codename: 'dong_son',
            parentId: 362,
          },
          {
            title: 'Xã Nam Tiến',
            code: 14017,
            value: 'xa_nam_tien',
            division_type: 'xã',
            short_codename: 'nam_tien',
            parentId: 362,
          },
          {
            title: 'Xã Nam Hải',
            code: 14020,
            value: 'xa_nam_hai',
            division_type: 'xã',
            short_codename: 'nam_hai',
            parentId: 362,
          },
          {
            title: 'Xã Nam Thái',
            code: 14023,
            value: 'xa_nam_thai',
            division_type: 'xã',
            short_codename: 'nam_thai',
            parentId: 362,
          },
        ],
        parentId: 36,
      },
      {
        title: 'Huyện Trực Ninh',
        code: 363,
        value: 'huyen_truc_ninh',
        division_type: 'huyện',
        short_codename: 'truc_ninh',
        children: [
          {
            title: 'Thị trấn Cổ Lễ',
            code: 14026,
            value: 'thi_tran_co_le',
            division_type: 'thị trấn',
            short_codename: 'co_le',
            parentId: 363,
          },
          {
            title: 'Xã Phương Định',
            code: 14029,
            value: 'xa_phuong_dinh',
            division_type: 'xã',
            short_codename: 'phuong_dinh',
            parentId: 363,
          },
          {
            title: 'Xã Trực Chính',
            code: 14032,
            value: 'xa_truc_chinh',
            division_type: 'xã',
            short_codename: 'truc_chinh',
            parentId: 363,
          },
          {
            title: 'Xã Trung Đông',
            code: 14035,
            value: 'xa_trung_dong',
            division_type: 'xã',
            short_codename: 'trung_dong',
            parentId: 363,
          },
          {
            title: 'Xã Liêm Hải',
            code: 14038,
            value: 'xa_liem_hai',
            division_type: 'xã',
            short_codename: 'liem_hai',
            parentId: 363,
          },
          {
            title: 'Xã Trực Tuấn',
            code: 14041,
            value: 'xa_truc_tuan',
            division_type: 'xã',
            short_codename: 'truc_tuan',
            parentId: 363,
          },
          {
            title: 'Xã Việt Hùng',
            code: 14044,
            value: 'xa_viet_hung',
            division_type: 'xã',
            short_codename: 'viet_hung',
            parentId: 363,
          },
          {
            title: 'Xã Trực Đạo',
            code: 14047,
            value: 'xa_truc_dao',
            division_type: 'xã',
            short_codename: 'truc_dao',
            parentId: 363,
          },
          {
            title: 'Xã Trực Hưng',
            code: 14050,
            value: 'xa_truc_hung',
            division_type: 'xã',
            short_codename: 'xa_truc_hung',
            parentId: 363,
          },
          {
            title: 'Xã Trực Nội',
            code: 14053,
            value: 'xa_truc_noi',
            division_type: 'xã',
            short_codename: 'truc_noi',
            parentId: 363,
          },
          {
            title: 'Thị trấn Cát Thành',
            code: 14056,
            value: 'thi_tran_cat_thanh',
            division_type: 'thị trấn',
            short_codename: 'cat_thanh',
            parentId: 363,
          },
          {
            title: 'Xã Trực Thanh',
            code: 14059,
            value: 'xa_truc_thanh',
            division_type: 'xã',
            short_codename: 'truc_thanh',
            parentId: 363,
          },
          {
            title: 'Xã Trực Khang',
            code: 14062,
            value: 'xa_truc_khang',
            division_type: 'xã',
            short_codename: 'truc_khang',
            parentId: 363,
          },
          {
            title: 'Xã Trực Thuận',
            code: 14065,
            value: 'xa_truc_thuan',
            division_type: 'xã',
            short_codename: 'truc_thuan',
            parentId: 363,
          },
          {
            title: 'Xã Trực Mỹ',
            code: 14068,
            value: 'xa_truc_my',
            division_type: 'xã',
            short_codename: 'truc_my',
            parentId: 363,
          },
          {
            title: 'Xã Trực Đại',
            code: 14071,
            value: 'xa_truc_dai',
            division_type: 'xã',
            short_codename: 'truc_dai',
            parentId: 363,
          },
          {
            title: 'Xã Trực Cường',
            code: 14074,
            value: 'xa_truc_cuong',
            division_type: 'xã',
            short_codename: 'truc_cuong',
            parentId: 363,
          },
          {
            title: 'Thị trấn Ninh Cường',
            code: 14077,
            value: 'thi_tran_ninh_cuong',
            division_type: 'thị trấn',
            short_codename: 'ninh_cuong',
            parentId: 363,
          },
          {
            title: 'Xã Trực Thái',
            code: 14080,
            value: 'xa_truc_thai',
            division_type: 'xã',
            short_codename: 'truc_thai',
            parentId: 363,
          },
          {
            title: 'Xã Trực Hùng',
            code: 14083,
            value: 'xa_truc_hung',
            division_type: 'xã',
            short_codename: 'xa_truc_hung',
            parentId: 363,
          },
          {
            title: 'Xã Trực Thắng',
            code: 14086,
            value: 'xa_truc_thang',
            division_type: 'xã',
            short_codename: 'truc_thang',
            parentId: 363,
          },
        ],
        parentId: 36,
      },
      {
        title: 'Huyện Xuân Trường',
        code: 364,
        value: 'huyen_xuan_truong',
        division_type: 'huyện',
        short_codename: 'xuan_truong',
        children: [
          {
            title: 'Thị trấn Xuân Trường',
            code: 14089,
            value: 'thi_tran_xuan_truong',
            division_type: 'thị trấn',
            short_codename: 'xuan_truong',
            parentId: 364,
          },
          {
            title: 'Xã Xuân Châu',
            code: 14092,
            value: 'xa_xuan_chau',
            division_type: 'xã',
            short_codename: 'xuan_chau',
            parentId: 364,
          },
          {
            title: 'Xã Xuân Hồng',
            code: 14095,
            value: 'xa_xuan_hong',
            division_type: 'xã',
            short_codename: 'xuan_hong',
            parentId: 364,
          },
          {
            title: 'Xã Xuân Thành',
            code: 14098,
            value: 'xa_xuan_thanh',
            division_type: 'xã',
            short_codename: 'xuan_thanh',
            parentId: 364,
          },
          {
            title: 'Xã Xuân Thượng',
            code: 14101,
            value: 'xa_xuan_thuong',
            division_type: 'xã',
            short_codename: 'xuan_thuong',
            parentId: 364,
          },
          {
            title: 'Xã Xuân Phong',
            code: 14104,
            value: 'xa_xuan_phong',
            division_type: 'xã',
            short_codename: 'xuan_phong',
            parentId: 364,
          },
          {
            title: 'Xã Xuân Đài',
            code: 14107,
            value: 'xa_xuan_dai',
            division_type: 'xã',
            short_codename: 'xuan_dai',
            parentId: 364,
          },
          {
            title: 'Xã Xuân Tân',
            code: 14110,
            value: 'xa_xuan_tan',
            division_type: 'xã',
            short_codename: 'xuan_tan',
            parentId: 364,
          },
          {
            title: 'Xã Xuân Thủy',
            code: 14113,
            value: 'xa_xuan_thuy',
            division_type: 'xã',
            short_codename: 'xuan_thuy',
            parentId: 364,
          },
          {
            title: 'Xã Xuân Ngọc',
            code: 14116,
            value: 'xa_xuan_ngoc',
            division_type: 'xã',
            short_codename: 'xuan_ngoc',
            parentId: 364,
          },
          {
            title: 'Xã Xuân Bắc',
            code: 14119,
            value: 'xa_xuan_bac',
            division_type: 'xã',
            short_codename: 'xuan_bac',
            parentId: 364,
          },
          {
            title: 'Xã Xuân Phương',
            code: 14122,
            value: 'xa_xuan_phuong',
            division_type: 'xã',
            short_codename: 'xuan_phuong',
            parentId: 364,
          },
          {
            title: 'Xã Thọ Nghiệp',
            code: 14125,
            value: 'xa_tho_nghiep',
            division_type: 'xã',
            short_codename: 'tho_nghiep',
            parentId: 364,
          },
          {
            title: 'Xã Xuân Phú',
            code: 14128,
            value: 'xa_xuan_phu',
            division_type: 'xã',
            short_codename: 'xuan_phu',
            parentId: 364,
          },
          {
            title: 'Xã Xuân Trung',
            code: 14131,
            value: 'xa_xuan_trung',
            division_type: 'xã',
            short_codename: 'xuan_trung',
            parentId: 364,
          },
          {
            title: 'Xã Xuân Vinh',
            code: 14134,
            value: 'xa_xuan_vinh',
            division_type: 'xã',
            short_codename: 'xuan_vinh',
            parentId: 364,
          },
          {
            title: 'Xã Xuân Kiên',
            code: 14137,
            value: 'xa_xuan_kien',
            division_type: 'xã',
            short_codename: 'xuan_kien',
            parentId: 364,
          },
          {
            title: 'Xã Xuân Tiến',
            code: 14140,
            value: 'xa_xuan_tien',
            division_type: 'xã',
            short_codename: 'xuan_tien',
            parentId: 364,
          },
          {
            title: 'Xã Xuân Ninh',
            code: 14143,
            value: 'xa_xuan_ninh',
            division_type: 'xã',
            short_codename: 'xuan_ninh',
            parentId: 364,
          },
          {
            title: 'Xã Xuân Hòa',
            code: 14146,
            value: 'xa_xuan_hoa',
            division_type: 'xã',
            short_codename: 'xuan_hoa',
            parentId: 364,
          },
        ],
        parentId: 36,
      },
      {
        title: 'Huyện Giao Thủy',
        code: 365,
        value: 'huyen_giao_thuy',
        division_type: 'huyện',
        short_codename: 'giao_thuy',
        children: [
          {
            title: 'Thị trấn Ngô Đồng',
            code: 14149,
            value: 'thi_tran_ngo_dong',
            division_type: 'thị trấn',
            short_codename: 'ngo_dong',
            parentId: 365,
          },
          {
            title: 'Thị trấn Quất Lâm',
            code: 14152,
            value: 'thi_tran_quat_lam',
            division_type: 'thị trấn',
            short_codename: 'quat_lam',
            parentId: 365,
          },
          {
            title: 'Xã Giao Hương',
            code: 14155,
            value: 'xa_giao_huong',
            division_type: 'xã',
            short_codename: 'giao_huong',
            parentId: 365,
          },
          {
            title: 'Xã Hồng Thuận',
            code: 14158,
            value: 'xa_hong_thuan',
            division_type: 'xã',
            short_codename: 'hong_thuan',
            parentId: 365,
          },
          {
            title: 'Xã Giao Thiện',
            code: 14161,
            value: 'xa_giao_thien',
            division_type: 'xã',
            short_codename: 'giao_thien',
            parentId: 365,
          },
          {
            title: 'Xã Giao Thanh',
            code: 14164,
            value: 'xa_giao_thanh',
            division_type: 'xã',
            short_codename: 'giao_thanh',
            parentId: 365,
          },
          {
            title: 'Xã Hoành Sơn',
            code: 14167,
            value: 'xa_hoanh_son',
            division_type: 'xã',
            short_codename: 'hoanh_son',
            parentId: 365,
          },
          {
            title: 'Xã Bình Hòa',
            code: 14170,
            value: 'xa_binh_hoa',
            division_type: 'xã',
            short_codename: 'binh_hoa',
            parentId: 365,
          },
          {
            title: 'Xã Giao Tiến',
            code: 14173,
            value: 'xa_giao_tien',
            division_type: 'xã',
            short_codename: 'giao_tien',
            parentId: 365,
          },
          {
            title: 'Xã Giao Hà',
            code: 14176,
            value: 'xa_giao_ha',
            division_type: 'xã',
            short_codename: 'giao_ha',
            parentId: 365,
          },
          {
            title: 'Xã Giao Nhân',
            code: 14179,
            value: 'xa_giao_nhan',
            division_type: 'xã',
            short_codename: 'giao_nhan',
            parentId: 365,
          },
          {
            title: 'Xã Giao An',
            code: 14182,
            value: 'xa_giao_an',
            division_type: 'xã',
            short_codename: 'giao_an',
            parentId: 365,
          },
          {
            title: 'Xã Giao Lạc',
            code: 14185,
            value: 'xa_giao_lac',
            division_type: 'xã',
            short_codename: 'giao_lac',
            parentId: 365,
          },
          {
            title: 'Xã Giao Châu',
            code: 14188,
            value: 'xa_giao_chau',
            division_type: 'xã',
            short_codename: 'giao_chau',
            parentId: 365,
          },
          {
            title: 'Xã Giao Tân',
            code: 14191,
            value: 'xa_giao_tan',
            division_type: 'xã',
            short_codename: 'giao_tan',
            parentId: 365,
          },
          {
            title: 'Xã Giao Yến',
            code: 14194,
            value: 'xa_giao_yen',
            division_type: 'xã',
            short_codename: 'giao_yen',
            parentId: 365,
          },
          {
            title: 'Xã Giao Xuân',
            code: 14197,
            value: 'xa_giao_xuan',
            division_type: 'xã',
            short_codename: 'giao_xuan',
            parentId: 365,
          },
          {
            title: 'Xã Giao Thịnh',
            code: 14200,
            value: 'xa_giao_thinh',
            division_type: 'xã',
            short_codename: 'giao_thinh',
            parentId: 365,
          },
          {
            title: 'Xã Giao Hải',
            code: 14203,
            value: 'xa_giao_hai',
            division_type: 'xã',
            short_codename: 'giao_hai',
            parentId: 365,
          },
          {
            title: 'Xã Bạch Long',
            code: 14206,
            value: 'xa_bach_long',
            division_type: 'xã',
            short_codename: 'bach_long',
            parentId: 365,
          },
          {
            title: 'Xã Giao Long',
            code: 14209,
            value: 'xa_giao_long',
            division_type: 'xã',
            short_codename: 'giao_long',
            parentId: 365,
          },
          {
            title: 'Xã Giao Phong',
            code: 14212,
            value: 'xa_giao_phong',
            division_type: 'xã',
            short_codename: 'giao_phong',
            parentId: 365,
          },
        ],
        parentId: 36,
      },
      {
        title: 'Huyện Hải Hậu',
        code: 366,
        value: 'huyen_hai_hau',
        division_type: 'huyện',
        short_codename: 'hai_hau',
        children: [
          {
            title: 'Thị trấn Yên Định',
            code: 14215,
            value: 'thi_tran_yen_dinh',
            division_type: 'thị trấn',
            short_codename: 'yen_dinh',
            parentId: 366,
          },
          {
            title: 'Thị trấn Cồn',
            code: 14218,
            value: 'thi_tran_con',
            division_type: 'thị trấn',
            short_codename: 'con',
            parentId: 366,
          },
          {
            title: 'Thị trấn Thịnh Long',
            code: 14221,
            value: 'thi_tran_thinh_long',
            division_type: 'thị trấn',
            short_codename: 'thinh_long',
            parentId: 366,
          },
          {
            title: 'Xã Hải Nam',
            code: 14224,
            value: 'xa_hai_nam',
            division_type: 'xã',
            short_codename: 'hai_nam',
            parentId: 366,
          },
          {
            title: 'Xã Hải Trung',
            code: 14227,
            value: 'xa_hai_trung',
            division_type: 'xã',
            short_codename: 'hai_trung',
            parentId: 366,
          },
          {
            title: 'Xã Hải Vân',
            code: 14230,
            value: 'xa_hai_van',
            division_type: 'xã',
            short_codename: 'hai_van',
            parentId: 366,
          },
          {
            title: 'Xã Hải Minh',
            code: 14233,
            value: 'xa_hai_minh',
            division_type: 'xã',
            short_codename: 'hai_minh',
            parentId: 366,
          },
          {
            title: 'Xã Hải Anh',
            code: 14236,
            value: 'xa_hai_anh',
            division_type: 'xã',
            short_codename: 'hai_anh',
            parentId: 366,
          },
          {
            title: 'Xã Hải Hưng',
            code: 14239,
            value: 'xa_hai_hung',
            division_type: 'xã',
            short_codename: 'hai_hung',
            parentId: 366,
          },
          {
            title: 'Xã Hải Bắc',
            code: 14242,
            value: 'xa_hai_bac',
            division_type: 'xã',
            short_codename: 'hai_bac',
            parentId: 366,
          },
          {
            title: 'Xã Hải Phúc',
            code: 14245,
            value: 'xa_hai_phuc',
            division_type: 'xã',
            short_codename: 'hai_phuc',
            parentId: 366,
          },
          {
            title: 'Xã Hải Thanh',
            code: 14248,
            value: 'xa_hai_thanh',
            division_type: 'xã',
            short_codename: 'hai_thanh',
            parentId: 366,
          },
          {
            title: 'Xã Hải Hà',
            code: 14251,
            value: 'xa_hai_ha',
            division_type: 'xã',
            short_codename: 'hai_ha',
            parentId: 366,
          },
          {
            title: 'Xã Hải Long',
            code: 14254,
            value: 'xa_hai_long',
            division_type: 'xã',
            short_codename: 'hai_long',
            parentId: 366,
          },
          {
            title: 'Xã Hải Phương',
            code: 14257,
            value: 'xa_hai_phuong',
            division_type: 'xã',
            short_codename: 'hai_phuong',
            parentId: 366,
          },
          {
            title: 'Xã Hải Đường',
            code: 14260,
            value: 'xa_hai_duong',
            division_type: 'xã',
            short_codename: 'hai_duong',
            parentId: 366,
          },
          {
            title: 'Xã Hải Lộc',
            code: 14263,
            value: 'xa_hai_loc',
            division_type: 'xã',
            short_codename: 'hai_loc',
            parentId: 366,
          },
          {
            title: 'Xã Hải Quang',
            code: 14266,
            value: 'xa_hai_quang',
            division_type: 'xã',
            short_codename: 'hai_quang',
            parentId: 366,
          },
          {
            title: 'Xã Hải Đông',
            code: 14269,
            value: 'xa_hai_dong',
            division_type: 'xã',
            short_codename: 'hai_dong',
            parentId: 366,
          },
          {
            title: 'Xã Hải Sơn',
            code: 14272,
            value: 'xa_hai_son',
            division_type: 'xã',
            short_codename: 'hai_son',
            parentId: 366,
          },
          {
            title: 'Xã Hải Tân',
            code: 14275,
            value: 'xa_hai_tan',
            division_type: 'xã',
            short_codename: 'hai_tan',
            parentId: 366,
          },
          {
            title: 'Xã Hải Phong',
            code: 14281,
            value: 'xa_hai_phong',
            division_type: 'xã',
            short_codename: 'hai_phong',
            parentId: 366,
          },
          {
            title: 'Xã Hải An',
            code: 14284,
            value: 'xa_hai_an',
            division_type: 'xã',
            short_codename: 'hai_an',
            parentId: 366,
          },
          {
            title: 'Xã Hải Tây',
            code: 14287,
            value: 'xa_hai_tay',
            division_type: 'xã',
            short_codename: 'hai_tay',
            parentId: 366,
          },
          {
            title: 'Xã Hải Lý',
            code: 14290,
            value: 'xa_hai_ly',
            division_type: 'xã',
            short_codename: 'hai_ly',
            parentId: 366,
          },
          {
            title: 'Xã Hải Phú',
            code: 14293,
            value: 'xa_hai_phu',
            division_type: 'xã',
            short_codename: 'hai_phu',
            parentId: 366,
          },
          {
            title: 'Xã Hải Giang',
            code: 14296,
            value: 'xa_hai_giang',
            division_type: 'xã',
            short_codename: 'hai_giang',
            parentId: 366,
          },
          {
            title: 'Xã Hải Cường',
            code: 14299,
            value: 'xa_hai_cuong',
            division_type: 'xã',
            short_codename: 'hai_cuong',
            parentId: 366,
          },
          {
            title: 'Xã Hải Ninh',
            code: 14302,
            value: 'xa_hai_ninh',
            division_type: 'xã',
            short_codename: 'hai_ninh',
            parentId: 366,
          },
          {
            title: 'Xã Hải Chính',
            code: 14305,
            value: 'xa_hai_chinh',
            division_type: 'xã',
            short_codename: 'hai_chinh',
            parentId: 366,
          },
          {
            title: 'Xã Hải Xuân',
            code: 14308,
            value: 'xa_hai_xuan',
            division_type: 'xã',
            short_codename: 'hai_xuan',
            parentId: 366,
          },
          {
            title: 'Xã Hải Châu',
            code: 14311,
            value: 'xa_hai_chau',
            division_type: 'xã',
            short_codename: 'hai_chau',
            parentId: 366,
          },
          {
            title: 'Xã Hải Triều',
            code: 14314,
            value: 'xa_hai_trieu',
            division_type: 'xã',
            short_codename: 'hai_trieu',
            parentId: 366,
          },
          {
            title: 'Xã Hải Hòa',
            code: 14317,
            value: 'xa_hai_hoa',
            division_type: 'xã',
            short_codename: 'hai_hoa',
            parentId: 366,
          },
        ],
        parentId: 36,
      },
    ],
  },
  {
    title: 'Tỉnh Ninh Bình',
    code: 37,
    value: 'tinh_ninh_binh',
    division_type: 'tỉnh',
    phone_code: 229,
    children: [
      {
        title: 'Thành phố Ninh Bình',
        code: 369,
        value: 'thanh_pho_ninh_binh',
        division_type: 'thành phố',
        short_codename: 'ninh_binh',
        children: [
          {
            title: 'Phường Đông Thành',
            code: 14320,
            value: 'phuong_dong_thanh',
            division_type: 'phường',
            short_codename: 'dong_thanh',
            parentId: 369,
          },
          {
            title: 'Phường Tân Thành',
            code: 14323,
            value: 'phuong_tan_thanh',
            division_type: 'phường',
            short_codename: 'tan_thanh',
            parentId: 369,
          },
          {
            title: 'Phường Thanh Bình',
            code: 14326,
            value: 'phuong_thanh_binh',
            division_type: 'phường',
            short_codename: 'thanh_binh',
            parentId: 369,
          },
          {
            title: 'Phường Vân Giang',
            code: 14329,
            value: 'phuong_van_giang',
            division_type: 'phường',
            short_codename: 'van_giang',
            parentId: 369,
          },
          {
            title: 'Phường Bích Đào',
            code: 14332,
            value: 'phuong_bich_dao',
            division_type: 'phường',
            short_codename: 'bich_dao',
            parentId: 369,
          },
          {
            title: 'Phường Phúc Thành',
            code: 14335,
            value: 'phuong_phuc_thanh',
            division_type: 'phường',
            short_codename: 'phuc_thanh',
            parentId: 369,
          },
          {
            title: 'Phường Nam Bình',
            code: 14338,
            value: 'phuong_nam_binh',
            division_type: 'phường',
            short_codename: 'nam_binh',
            parentId: 369,
          },
          {
            title: 'Phường Nam Thành',
            code: 14341,
            value: 'phuong_nam_thanh',
            division_type: 'phường',
            short_codename: 'nam_thanh',
            parentId: 369,
          },
          {
            title: 'Phường Ninh Khánh',
            code: 14344,
            value: 'phuong_ninh_khanh',
            division_type: 'phường',
            short_codename: 'ninh_khanh',
            parentId: 369,
          },
          {
            title: 'Xã Ninh Nhất',
            code: 14347,
            value: 'xa_ninh_nhat',
            division_type: 'xã',
            short_codename: 'ninh_nhat',
            parentId: 369,
          },
          {
            title: 'Xã Ninh Tiến',
            code: 14350,
            value: 'xa_ninh_tien',
            division_type: 'xã',
            short_codename: 'ninh_tien',
            parentId: 369,
          },
          {
            title: 'Xã Ninh Phúc',
            code: 14353,
            value: 'xa_ninh_phuc',
            division_type: 'xã',
            short_codename: 'ninh_phuc',
            parentId: 369,
          },
          {
            title: 'Phường Ninh Sơn',
            code: 14356,
            value: 'phuong_ninh_son',
            division_type: 'phường',
            short_codename: 'ninh_son',
            parentId: 369,
          },
          {
            title: 'Phường Ninh Phong',
            code: 14359,
            value: 'phuong_ninh_phong',
            division_type: 'phường',
            short_codename: 'ninh_phong',
            parentId: 369,
          },
        ],
        parentId: 37,
      },
      {
        title: 'Thành phố Tam Điệp',
        code: 370,
        value: 'thanh_pho_tam_diep',
        division_type: 'thành phố',
        short_codename: 'tam_diep',
        children: [
          {
            title: 'Phường Bắc Sơn',
            code: 14362,
            value: 'phuong_bac_son',
            division_type: 'phường',
            short_codename: 'bac_son',
            parentId: 370,
          },
          {
            title: 'Phường Trung Sơn',
            code: 14365,
            value: 'phuong_trung_son',
            division_type: 'phường',
            short_codename: 'trung_son',
            parentId: 370,
          },
          {
            title: 'Phường Nam Sơn',
            code: 14368,
            value: 'phuong_nam_son',
            division_type: 'phường',
            short_codename: 'nam_son',
            parentId: 370,
          },
          {
            title: 'Phường Tây Sơn',
            code: 14369,
            value: 'phuong_tay_son',
            division_type: 'phường',
            short_codename: 'tay_son',
            parentId: 370,
          },
          {
            title: 'Xã Yên Sơn',
            code: 14371,
            value: 'xa_yen_son',
            division_type: 'xã',
            short_codename: 'yen_son',
            parentId: 370,
          },
          {
            title: 'Phường Yên Bình',
            code: 14374,
            value: 'phuong_yen_binh',
            division_type: 'phường',
            short_codename: 'yen_binh',
            parentId: 370,
          },
          {
            title: 'Phường Tân Bình',
            code: 14375,
            value: 'phuong_tan_binh',
            division_type: 'phường',
            short_codename: 'tan_binh',
            parentId: 370,
          },
          {
            title: 'Xã Quang Sơn',
            code: 14377,
            value: 'xa_quang_son',
            division_type: 'xã',
            short_codename: 'quang_son',
            parentId: 370,
          },
          {
            title: 'Xã Đông Sơn',
            code: 14380,
            value: 'xa_dong_son',
            division_type: 'xã',
            short_codename: 'dong_son',
            parentId: 370,
          },
        ],
        parentId: 37,
      },
      {
        title: 'Huyện Nho Quan',
        code: 372,
        value: 'huyen_nho_quan',
        division_type: 'huyện',
        short_codename: 'nho_quan',
        children: [
          {
            title: 'Thị trấn Nho Quan',
            code: 14383,
            value: 'thi_tran_nho_quan',
            division_type: 'thị trấn',
            short_codename: 'nho_quan',
            parentId: 372,
          },
          {
            title: 'Xã Xích Thổ',
            code: 14386,
            value: 'xa_xich_tho',
            division_type: 'xã',
            short_codename: 'xich_tho',
            parentId: 372,
          },
          {
            title: 'Xã Gia Lâm',
            code: 14389,
            value: 'xa_gia_lam',
            division_type: 'xã',
            short_codename: 'gia_lam',
            parentId: 372,
          },
          {
            title: 'Xã Gia Sơn',
            code: 14392,
            value: 'xa_gia_son',
            division_type: 'xã',
            short_codename: 'gia_son',
            parentId: 372,
          },
          {
            title: 'Xã Thạch Bình',
            code: 14395,
            value: 'xa_thach_binh',
            division_type: 'xã',
            short_codename: 'thach_binh',
            parentId: 372,
          },
          {
            title: 'Xã Gia Thủy',
            code: 14398,
            value: 'xa_gia_thuy',
            division_type: 'xã',
            short_codename: 'gia_thuy',
            parentId: 372,
          },
          {
            title: 'Xã Gia Tường',
            code: 14401,
            value: 'xa_gia_tuong',
            division_type: 'xã',
            short_codename: 'gia_tuong',
            parentId: 372,
          },
          {
            title: 'Xã Cúc Phương',
            code: 14404,
            value: 'xa_cuc_phuong',
            division_type: 'xã',
            short_codename: 'cuc_phuong',
            parentId: 372,
          },
          {
            title: 'Xã Phú Sơn',
            code: 14407,
            value: 'xa_phu_son',
            division_type: 'xã',
            short_codename: 'phu_son',
            parentId: 372,
          },
          {
            title: 'Xã Đức Long',
            code: 14410,
            value: 'xa_duc_long',
            division_type: 'xã',
            short_codename: 'duc_long',
            parentId: 372,
          },
          {
            title: 'Xã Lạc Vân',
            code: 14413,
            value: 'xa_lac_van',
            division_type: 'xã',
            short_codename: 'lac_van',
            parentId: 372,
          },
          {
            title: 'Xã Đồng Phong',
            code: 14416,
            value: 'xa_dong_phong',
            division_type: 'xã',
            short_codename: 'dong_phong',
            parentId: 372,
          },
          {
            title: 'Xã Yên Quang',
            code: 14419,
            value: 'xa_yen_quang',
            division_type: 'xã',
            short_codename: 'yen_quang',
            parentId: 372,
          },
          {
            title: 'Xã Lạng Phong',
            code: 14422,
            value: 'xa_lang_phong',
            division_type: 'xã',
            short_codename: 'lang_phong',
            parentId: 372,
          },
          {
            title: 'Xã Thượng Hòa',
            code: 14425,
            value: 'xa_thuong_hoa',
            division_type: 'xã',
            short_codename: 'thuong_hoa',
            parentId: 372,
          },
          {
            title: 'Xã Văn Phong',
            code: 14428,
            value: 'xa_van_phong',
            division_type: 'xã',
            short_codename: 'van_phong',
            parentId: 372,
          },
          {
            title: 'Xã Văn Phương',
            code: 14431,
            value: 'xa_van_phuong',
            division_type: 'xã',
            short_codename: 'van_phuong',
            parentId: 372,
          },
          {
            title: 'Xã Thanh Lạc',
            code: 14434,
            value: 'xa_thanh_lac',
            division_type: 'xã',
            short_codename: 'thanh_lac',
            parentId: 372,
          },
          {
            title: 'Xã Sơn Lai',
            code: 14437,
            value: 'xa_son_lai',
            division_type: 'xã',
            short_codename: 'son_lai',
            parentId: 372,
          },
          {
            title: 'Xã Sơn Thành',
            code: 14440,
            value: 'xa_son_thanh',
            division_type: 'xã',
            short_codename: 'son_thanh',
            parentId: 372,
          },
          {
            title: 'Xã Văn Phú',
            code: 14443,
            value: 'xa_van_phu',
            division_type: 'xã',
            short_codename: 'van_phu',
            parentId: 372,
          },
          {
            title: 'Xã Phú Lộc',
            code: 14446,
            value: 'xa_phu_loc',
            division_type: 'xã',
            short_codename: 'phu_loc',
            parentId: 372,
          },
          {
            title: 'Xã Kỳ Phú',
            code: 14449,
            value: 'xa_ky_phu',
            division_type: 'xã',
            short_codename: 'ky_phu',
            parentId: 372,
          },
          {
            title: 'Xã Quỳnh Lưu',
            code: 14452,
            value: 'xa_quynh_luu',
            division_type: 'xã',
            short_codename: 'quynh_luu',
            parentId: 372,
          },
          {
            title: 'Xã Sơn Hà',
            code: 14455,
            value: 'xa_son_ha',
            division_type: 'xã',
            short_codename: 'son_ha',
            parentId: 372,
          },
          {
            title: 'Xã Phú Long',
            code: 14458,
            value: 'xa_phu_long',
            division_type: 'xã',
            short_codename: 'phu_long',
            parentId: 372,
          },
          {
            title: 'Xã Quảng Lạc',
            code: 14461,
            value: 'xa_quang_lac',
            division_type: 'xã',
            short_codename: 'quang_lac',
            parentId: 372,
          },
        ],
        parentId: 37,
      },
      {
        title: 'Huyện Gia Viễn',
        code: 373,
        value: 'huyen_gia_vien',
        division_type: 'huyện',
        short_codename: 'gia_vien',
        children: [
          {
            title: 'Thị trấn Me',
            code: 14464,
            value: 'thi_tran_me',
            division_type: 'thị trấn',
            short_codename: 'me',
            parentId: 373,
          },
          {
            title: 'Xã Gia Hòa',
            code: 14467,
            value: 'xa_gia_hoa',
            division_type: 'xã',
            short_codename: 'gia_hoa',
            parentId: 373,
          },
          {
            title: 'Xã Gia Hưng',
            code: 14470,
            value: 'xa_gia_hung',
            division_type: 'xã',
            short_codename: 'gia_hung',
            parentId: 373,
          },
          {
            title: 'Xã Liên Sơn',
            code: 14473,
            value: 'xa_lien_son',
            division_type: 'xã',
            short_codename: 'lien_son',
            parentId: 373,
          },
          {
            title: 'Xã Gia Thanh',
            code: 14476,
            value: 'xa_gia_thanh',
            division_type: 'xã',
            short_codename: 'gia_thanh',
            parentId: 373,
          },
          {
            title: 'Xã Gia Vân',
            code: 14479,
            value: 'xa_gia_van',
            division_type: 'xã',
            short_codename: 'gia_van',
            parentId: 373,
          },
          {
            title: 'Xã Gia Phú',
            code: 14482,
            value: 'xa_gia_phu',
            division_type: 'xã',
            short_codename: 'gia_phu',
            parentId: 373,
          },
          {
            title: 'Xã Gia Xuân',
            code: 14485,
            value: 'xa_gia_xuan',
            division_type: 'xã',
            short_codename: 'gia_xuan',
            parentId: 373,
          },
          {
            title: 'Xã Gia Lập',
            code: 14488,
            value: 'xa_gia_lap',
            division_type: 'xã',
            short_codename: 'gia_lap',
            parentId: 373,
          },
          {
            title: 'Xã Gia Vượng',
            code: 14491,
            value: 'xa_gia_vuong',
            division_type: 'xã',
            short_codename: 'gia_vuong',
            parentId: 373,
          },
          {
            title: 'Xã Gia Trấn',
            code: 14494,
            value: 'xa_gia_tran',
            division_type: 'xã',
            short_codename: 'gia_tran',
            parentId: 373,
          },
          {
            title: 'Xã Gia Thịnh',
            code: 14497,
            value: 'xa_gia_thinh',
            division_type: 'xã',
            short_codename: 'gia_thinh',
            parentId: 373,
          },
          {
            title: 'Xã Gia Phương',
            code: 14500,
            value: 'xa_gia_phuong',
            division_type: 'xã',
            short_codename: 'gia_phuong',
            parentId: 373,
          },
          {
            title: 'Xã Gia Tân',
            code: 14503,
            value: 'xa_gia_tan',
            division_type: 'xã',
            short_codename: 'gia_tan',
            parentId: 373,
          },
          {
            title: 'Xã Gia Thắng',
            code: 14506,
            value: 'xa_gia_thang',
            division_type: 'xã',
            short_codename: 'gia_thang',
            parentId: 373,
          },
          {
            title: 'Xã Gia Trung',
            code: 14509,
            value: 'xa_gia_trung',
            division_type: 'xã',
            short_codename: 'gia_trung',
            parentId: 373,
          },
          {
            title: 'Xã Gia Minh',
            code: 14512,
            value: 'xa_gia_minh',
            division_type: 'xã',
            short_codename: 'gia_minh',
            parentId: 373,
          },
          {
            title: 'Xã Gia Lạc',
            code: 14515,
            value: 'xa_gia_lac',
            division_type: 'xã',
            short_codename: 'gia_lac',
            parentId: 373,
          },
          {
            title: 'Xã Gia Tiến',
            code: 14518,
            value: 'xa_gia_tien',
            division_type: 'xã',
            short_codename: 'gia_tien',
            parentId: 373,
          },
          {
            title: 'Xã Gia Sinh',
            code: 14521,
            value: 'xa_gia_sinh',
            division_type: 'xã',
            short_codename: 'gia_sinh',
            parentId: 373,
          },
          {
            title: 'Xã Gia Phong',
            code: 14524,
            value: 'xa_gia_phong',
            division_type: 'xã',
            short_codename: 'gia_phong',
            parentId: 373,
          },
        ],
        parentId: 37,
      },
      {
        title: 'Huyện Hoa Lư',
        code: 374,
        value: 'huyen_hoa_lu',
        division_type: 'huyện',
        short_codename: 'hoa_lu',
        children: [
          {
            title: 'Thị trấn Thiên Tôn',
            code: 14527,
            value: 'thi_tran_thien_ton',
            division_type: 'thị trấn',
            short_codename: 'thien_ton',
            parentId: 374,
          },
          {
            title: 'Xã Ninh Giang',
            code: 14530,
            value: 'xa_ninh_giang',
            division_type: 'xã',
            short_codename: 'ninh_giang',
            parentId: 374,
          },
          {
            title: 'Xã Trường Yên',
            code: 14533,
            value: 'xa_truong_yen',
            division_type: 'xã',
            short_codename: 'truong_yen',
            parentId: 374,
          },
          {
            title: 'Xã Ninh Khang',
            code: 14536,
            value: 'xa_ninh_khang',
            division_type: 'xã',
            short_codename: 'ninh_khang',
            parentId: 374,
          },
          {
            title: 'Xã Ninh Mỹ',
            code: 14539,
            value: 'xa_ninh_my',
            division_type: 'xã',
            short_codename: 'ninh_my',
            parentId: 374,
          },
          {
            title: 'Xã Ninh Hòa',
            code: 14542,
            value: 'xa_ninh_hoa',
            division_type: 'xã',
            short_codename: 'ninh_hoa',
            parentId: 374,
          },
          {
            title: 'Xã Ninh Xuân',
            code: 14545,
            value: 'xa_ninh_xuan',
            division_type: 'xã',
            short_codename: 'ninh_xuan',
            parentId: 374,
          },
          {
            title: 'Xã Ninh Hải',
            code: 14548,
            value: 'xa_ninh_hai',
            division_type: 'xã',
            short_codename: 'ninh_hai',
            parentId: 374,
          },
          {
            title: 'Xã Ninh Thắng',
            code: 14551,
            value: 'xa_ninh_thang',
            division_type: 'xã',
            short_codename: 'ninh_thang',
            parentId: 374,
          },
          {
            title: 'Xã Ninh Vân',
            code: 14554,
            value: 'xa_ninh_van',
            division_type: 'xã',
            short_codename: 'ninh_van',
            parentId: 374,
          },
          {
            title: 'Xã Ninh An',
            code: 14557,
            value: 'xa_ninh_an',
            division_type: 'xã',
            short_codename: 'ninh_an',
            parentId: 374,
          },
        ],
        parentId: 37,
      },
      {
        title: 'Huyện Yên Khánh',
        code: 375,
        value: 'huyen_yen_khanh',
        division_type: 'huyện',
        short_codename: 'yen_khanh',
        children: [
          {
            title: 'Thị trấn Yên Ninh',
            code: 14560,
            value: 'thi_tran_yen_ninh',
            division_type: 'thị trấn',
            short_codename: 'yen_ninh',
            parentId: 375,
          },
          {
            title: 'Xã Khánh Tiên',
            code: 14563,
            value: 'xa_khanh_tien',
            division_type: 'xã',
            short_codename: 'khanh_tien',
            parentId: 375,
          },
          {
            title: 'Xã Khánh Phú',
            code: 14566,
            value: 'xa_khanh_phu',
            division_type: 'xã',
            short_codename: 'khanh_phu',
            parentId: 375,
          },
          {
            title: 'Xã Khánh Hòa',
            code: 14569,
            value: 'xa_khanh_hoa',
            division_type: 'xã',
            short_codename: 'khanh_hoa',
            parentId: 375,
          },
          {
            title: 'Xã Khánh Lợi',
            code: 14572,
            value: 'xa_khanh_loi',
            division_type: 'xã',
            short_codename: 'khanh_loi',
            parentId: 375,
          },
          {
            title: 'Xã Khánh An',
            code: 14575,
            value: 'xa_khanh_an',
            division_type: 'xã',
            short_codename: 'khanh_an',
            parentId: 375,
          },
          {
            title: 'Xã Khánh Cường',
            code: 14578,
            value: 'xa_khanh_cuong',
            division_type: 'xã',
            short_codename: 'khanh_cuong',
            parentId: 375,
          },
          {
            title: 'Xã Khánh Cư',
            code: 14581,
            value: 'xa_khanh_cu',
            division_type: 'xã',
            short_codename: 'khanh_cu',
            parentId: 375,
          },
          {
            title: 'Xã Khánh Thiện',
            code: 14584,
            value: 'xa_khanh_thien',
            division_type: 'xã',
            short_codename: 'khanh_thien',
            parentId: 375,
          },
          {
            title: 'Xã Khánh Hải',
            code: 14587,
            value: 'xa_khanh_hai',
            division_type: 'xã',
            short_codename: 'khanh_hai',
            parentId: 375,
          },
          {
            title: 'Xã Khánh Trung',
            code: 14590,
            value: 'xa_khanh_trung',
            division_type: 'xã',
            short_codename: 'khanh_trung',
            parentId: 375,
          },
          {
            title: 'Xã Khánh Mậu',
            code: 14593,
            value: 'xa_khanh_mau',
            division_type: 'xã',
            short_codename: 'khanh_mau',
            parentId: 375,
          },
          {
            title: 'Xã Khánh Vân',
            code: 14596,
            value: 'xa_khanh_van',
            division_type: 'xã',
            short_codename: 'khanh_van',
            parentId: 375,
          },
          {
            title: 'Xã Khánh Hội',
            code: 14599,
            value: 'xa_khanh_hoi',
            division_type: 'xã',
            short_codename: 'khanh_hoi',
            parentId: 375,
          },
          {
            title: 'Xã Khánh Công',
            code: 14602,
            value: 'xa_khanh_cong',
            division_type: 'xã',
            short_codename: 'khanh_cong',
            parentId: 375,
          },
          {
            title: 'Xã Khánh Thành',
            code: 14608,
            value: 'xa_khanh_thanh',
            division_type: 'xã',
            short_codename: 'khanh_thanh',
            parentId: 375,
          },
          {
            title: 'Xã Khánh Nhạc',
            code: 14611,
            value: 'xa_khanh_nhac',
            division_type: 'xã',
            short_codename: 'khanh_nhac',
            parentId: 375,
          },
          {
            title: 'Xã Khánh Thủy',
            code: 14614,
            value: 'xa_khanh_thuy',
            division_type: 'xã',
            short_codename: 'khanh_thuy',
            parentId: 375,
          },
          {
            title: 'Xã Khánh Hồng',
            code: 14617,
            value: 'xa_khanh_hong',
            division_type: 'xã',
            short_codename: 'khanh_hong',
            parentId: 375,
          },
        ],
        parentId: 37,
      },
      {
        title: 'Huyện Kim Sơn',
        code: 376,
        value: 'huyen_kim_son',
        division_type: 'huyện',
        short_codename: 'kim_son',
        children: [
          {
            title: 'Thị trấn Phát Diệm',
            code: 14620,
            value: 'thi_tran_phat_diem',
            division_type: 'thị trấn',
            short_codename: 'phat_diem',
            parentId: 376,
          },
          {
            title: 'Thị trấn Bình Minh',
            code: 14623,
            value: 'thi_tran_binh_minh',
            division_type: 'thị trấn',
            short_codename: 'binh_minh',
            parentId: 376,
          },
          {
            title: 'Xã Hồi Ninh',
            code: 14629,
            value: 'xa_hoi_ninh',
            division_type: 'xã',
            short_codename: 'hoi_ninh',
            parentId: 376,
          },
          {
            title: 'Xã Xuân Chính',
            code: 14632,
            value: 'xa_xuan_chinh',
            division_type: 'xã',
            short_codename: 'xuan_chinh',
            parentId: 376,
          },
          {
            title: 'Xã Kim Định',
            code: 14635,
            value: 'xa_kim_dinh',
            division_type: 'xã',
            short_codename: 'kim_dinh',
            parentId: 376,
          },
          {
            title: 'Xã Ân Hòa',
            code: 14638,
            value: 'xa_an_hoa',
            division_type: 'xã',
            short_codename: 'an_hoa',
            parentId: 376,
          },
          {
            title: 'Xã Hùng Tiến',
            code: 14641,
            value: 'xa_hung_tien',
            division_type: 'xã',
            short_codename: 'hung_tien',
            parentId: 376,
          },
          {
            title: 'Xã Quang Thiện',
            code: 14647,
            value: 'xa_quang_thien',
            division_type: 'xã',
            short_codename: 'quang_thien',
            parentId: 376,
          },
          {
            title: 'Xã Như Hòa',
            code: 14650,
            value: 'xa_nhu_hoa',
            division_type: 'xã',
            short_codename: 'nhu_hoa',
            parentId: 376,
          },
          {
            title: 'Xã Chất Bình',
            code: 14653,
            value: 'xa_chat_binh',
            division_type: 'xã',
            short_codename: 'chat_binh',
            parentId: 376,
          },
          {
            title: 'Xã Đồng Hướng',
            code: 14656,
            value: 'xa_dong_huong',
            division_type: 'xã',
            short_codename: 'dong_huong',
            parentId: 376,
          },
          {
            title: 'Xã Kim Chính',
            code: 14659,
            value: 'xa_kim_chinh',
            division_type: 'xã',
            short_codename: 'kim_chinh',
            parentId: 376,
          },
          {
            title: 'Xã Thượng Kiệm',
            code: 14662,
            value: 'xa_thuong_kiem',
            division_type: 'xã',
            short_codename: 'thuong_kiem',
            parentId: 376,
          },
          {
            title: 'Xã Lưu Phương',
            code: 14665,
            value: 'xa_luu_phuong',
            division_type: 'xã',
            short_codename: 'luu_phuong',
            parentId: 376,
          },
          {
            title: 'Xã Tân Thành',
            code: 14668,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 376,
          },
          {
            title: 'Xã Yên Lộc',
            code: 14671,
            value: 'xa_yen_loc',
            division_type: 'xã',
            short_codename: 'yen_loc',
            parentId: 376,
          },
          {
            title: 'Xã Lai Thành',
            code: 14674,
            value: 'xa_lai_thanh',
            division_type: 'xã',
            short_codename: 'lai_thanh',
            parentId: 376,
          },
          {
            title: 'Xã Định Hóa',
            code: 14677,
            value: 'xa_dinh_hoa',
            division_type: 'xã',
            short_codename: 'dinh_hoa',
            parentId: 376,
          },
          {
            title: 'Xã Văn Hải',
            code: 14680,
            value: 'xa_van_hai',
            division_type: 'xã',
            short_codename: 'van_hai',
            parentId: 376,
          },
          {
            title: 'Xã Kim Tân',
            code: 14683,
            value: 'xa_kim_tan',
            division_type: 'xã',
            short_codename: 'kim_tan',
            parentId: 376,
          },
          {
            title: 'Xã Kim Mỹ',
            code: 14686,
            value: 'xa_kim_my',
            division_type: 'xã',
            short_codename: 'kim_my',
            parentId: 376,
          },
          {
            title: 'Xã Cồn Thoi',
            code: 14689,
            value: 'xa_con_thoi',
            division_type: 'xã',
            short_codename: 'con_thoi',
            parentId: 376,
          },
          {
            title: 'Xã Kim Hải',
            code: 14692,
            value: 'xa_kim_hai',
            division_type: 'xã',
            short_codename: 'kim_hai',
            parentId: 376,
          },
          {
            title: 'Xã Kim Trung',
            code: 14695,
            value: 'xa_kim_trung',
            division_type: 'xã',
            short_codename: 'kim_trung',
            parentId: 376,
          },
          {
            title: 'Xã Kim Đông',
            code: 14698,
            value: 'xa_kim_dong',
            division_type: 'xã',
            short_codename: 'kim_dong',
            parentId: 376,
          },
        ],
        parentId: 37,
      },
      {
        title: 'Huyện Yên Mô',
        code: 377,
        value: 'huyen_yen_mo',
        division_type: 'huyện',
        short_codename: 'yen_mo',
        children: [
          {
            title: 'Thị trấn Yên Thịnh',
            code: 14701,
            value: 'thi_tran_yen_thinh',
            division_type: 'thị trấn',
            short_codename: 'yen_thinh',
            parentId: 377,
          },
          {
            title: 'Xã Khánh Thượng',
            code: 14704,
            value: 'xa_khanh_thuong',
            division_type: 'xã',
            short_codename: 'khanh_thuong',
            parentId: 377,
          },
          {
            title: 'Xã Khánh Dương',
            code: 14707,
            value: 'xa_khanh_duong',
            division_type: 'xã',
            short_codename: 'khanh_duong',
            parentId: 377,
          },
          {
            title: 'Xã Mai Sơn',
            code: 14710,
            value: 'xa_mai_son',
            division_type: 'xã',
            short_codename: 'mai_son',
            parentId: 377,
          },
          {
            title: 'Xã Khánh Thịnh',
            code: 14713,
            value: 'xa_khanh_thinh',
            division_type: 'xã',
            short_codename: 'khanh_thinh',
            parentId: 377,
          },
          {
            title: 'Xã Yên Phong',
            code: 14719,
            value: 'xa_yen_phong',
            division_type: 'xã',
            short_codename: 'yen_phong',
            parentId: 377,
          },
          {
            title: 'Xã Yên Hòa',
            code: 14722,
            value: 'xa_yen_hoa',
            division_type: 'xã',
            short_codename: 'yen_hoa',
            parentId: 377,
          },
          {
            title: 'Xã Yên Thắng',
            code: 14725,
            value: 'xa_yen_thang',
            division_type: 'xã',
            short_codename: 'yen_thang',
            parentId: 377,
          },
          {
            title: 'Xã Yên Từ',
            code: 14728,
            value: 'xa_yen_tu',
            division_type: 'xã',
            short_codename: 'yen_tu',
            parentId: 377,
          },
          {
            title: 'Xã Yên Hưng',
            code: 14731,
            value: 'xa_yen_hung',
            division_type: 'xã',
            short_codename: 'yen_hung',
            parentId: 377,
          },
          {
            title: 'Xã Yên Thành',
            code: 14734,
            value: 'xa_yen_thanh',
            division_type: 'xã',
            short_codename: 'yen_thanh',
            parentId: 377,
          },
          {
            title: 'Xã Yên Nhân',
            code: 14737,
            value: 'xa_yen_nhan',
            division_type: 'xã',
            short_codename: 'yen_nhan',
            parentId: 377,
          },
          {
            title: 'Xã Yên Mỹ',
            code: 14740,
            value: 'xa_yen_my',
            division_type: 'xã',
            short_codename: 'yen_my',
            parentId: 377,
          },
          {
            title: 'Xã Yên Mạc',
            code: 14743,
            value: 'xa_yen_mac',
            division_type: 'xã',
            short_codename: 'yen_mac',
            parentId: 377,
          },
          {
            title: 'Xã Yên Đồng',
            code: 14746,
            value: 'xa_yen_dong',
            division_type: 'xã',
            short_codename: 'yen_dong',
            parentId: 377,
          },
          {
            title: 'Xã Yên Thái',
            code: 14749,
            value: 'xa_yen_thai',
            division_type: 'xã',
            short_codename: 'yen_thai',
            parentId: 377,
          },
          {
            title: 'Xã Yên Lâm',
            code: 14752,
            value: 'xa_yen_lam',
            division_type: 'xã',
            short_codename: 'yen_lam',
            parentId: 377,
          },
        ],
        parentId: 37,
      },
    ],
  },
  {
    title: 'Tỉnh Thanh Hóa',
    code: 38,
    value: 'tinh_thanh_hoa',
    division_type: 'tỉnh',
    phone_code: 237,
    children: [
      {
        title: 'Thành phố Thanh Hóa',
        code: 380,
        value: 'thanh_pho_thanh_hoa',
        division_type: 'thành phố',
        short_codename: 'thanh_hoa',
        children: [
          {
            title: 'Phường Hàm Rồng',
            code: 14755,
            value: 'phuong_ham_rong',
            division_type: 'phường',
            short_codename: 'ham_rong',
            parentId: 380,
          },
          {
            title: 'Phường Đông Thọ',
            code: 14758,
            value: 'phuong_dong_tho',
            division_type: 'phường',
            short_codename: 'dong_tho',
            parentId: 380,
          },
          {
            title: 'Phường Nam Ngạn',
            code: 14761,
            value: 'phuong_nam_ngan',
            division_type: 'phường',
            short_codename: 'nam_ngan',
            parentId: 380,
          },
          {
            title: 'Phường Trường Thi',
            code: 14764,
            value: 'phuong_truong_thi',
            division_type: 'phường',
            short_codename: 'truong_thi',
            parentId: 380,
          },
          {
            title: 'Phường Điện Biên',
            code: 14767,
            value: 'phuong_dien_bien',
            division_type: 'phường',
            short_codename: 'dien_bien',
            parentId: 380,
          },
          {
            title: 'Phường Phú Sơn',
            code: 14770,
            value: 'phuong_phu_son',
            division_type: 'phường',
            short_codename: 'phu_son',
            parentId: 380,
          },
          {
            title: 'Phường Lam Sơn',
            code: 14773,
            value: 'phuong_lam_son',
            division_type: 'phường',
            short_codename: 'lam_son',
            parentId: 380,
          },
          {
            title: 'Phường Ba Đình',
            code: 14776,
            value: 'phuong_ba_dinh',
            division_type: 'phường',
            short_codename: 'ba_dinh',
            parentId: 380,
          },
          {
            title: 'Phường Ngọc Trạo',
            code: 14779,
            value: 'phuong_ngoc_trao',
            division_type: 'phường',
            short_codename: 'ngoc_trao',
            parentId: 380,
          },
          {
            title: 'Phường Đông Vệ',
            code: 14782,
            value: 'phuong_dong_ve',
            division_type: 'phường',
            short_codename: 'dong_ve',
            parentId: 380,
          },
          {
            title: 'Phường Đông Sơn',
            code: 14785,
            value: 'phuong_dong_son',
            division_type: 'phường',
            short_codename: 'dong_son',
            parentId: 380,
          },
          {
            title: 'Phường Tân Sơn',
            code: 14788,
            value: 'phuong_tan_son',
            division_type: 'phường',
            short_codename: 'tan_son',
            parentId: 380,
          },
          {
            title: 'Phường Đông Cương',
            code: 14791,
            value: 'phuong_dong_cuong',
            division_type: 'phường',
            short_codename: 'dong_cuong',
            parentId: 380,
          },
          {
            title: 'Phường Đông Hương',
            code: 14794,
            value: 'phuong_dong_huong',
            division_type: 'phường',
            short_codename: 'dong_huong',
            parentId: 380,
          },
          {
            title: 'Phường Đông Hải',
            code: 14797,
            value: 'phuong_dong_hai',
            division_type: 'phường',
            short_codename: 'dong_hai',
            parentId: 380,
          },
          {
            title: 'Phường Quảng Hưng',
            code: 14800,
            value: 'phuong_quang_hung',
            division_type: 'phường',
            short_codename: 'quang_hung',
            parentId: 380,
          },
          {
            title: 'Phường Quảng Thắng',
            code: 14803,
            value: 'phuong_quang_thang',
            division_type: 'phường',
            short_codename: 'quang_thang',
            parentId: 380,
          },
          {
            title: 'Phường Quảng Thành',
            code: 14806,
            value: 'phuong_quang_thanh',
            division_type: 'phường',
            short_codename: 'quang_thanh',
            parentId: 380,
          },
          {
            title: 'Xã Thiệu Vân',
            code: 15850,
            value: 'xa_thieu_van',
            division_type: 'xã',
            short_codename: 'thieu_van',
            parentId: 380,
          },
          {
            title: 'Phường Thiệu Khánh',
            code: 15856,
            value: 'phuong_thieu_khanh',
            division_type: 'phường',
            short_codename: 'thieu_khanh',
            parentId: 380,
          },
          {
            title: 'Phường Thiệu Dương',
            code: 15859,
            value: 'phuong_thieu_duong',
            division_type: 'phường',
            short_codename: 'thieu_duong',
            parentId: 380,
          },
          {
            title: 'Phường Tào Xuyên',
            code: 15913,
            value: 'phuong_tao_xuyen',
            division_type: 'phường',
            short_codename: 'tao_xuyen',
            parentId: 380,
          },
          {
            title: 'Phường Long Anh',
            code: 15922,
            value: 'phuong_long_anh',
            division_type: 'phường',
            short_codename: 'long_anh',
            parentId: 380,
          },
          {
            title: 'Xã Hoằng Quang',
            code: 15925,
            value: 'xa_hoang_quang',
            division_type: 'xã',
            short_codename: 'hoang_quang',
            parentId: 380,
          },
          {
            title: 'Xã Hoằng Đại',
            code: 15970,
            value: 'xa_hoang_dai',
            division_type: 'xã',
            short_codename: 'hoang_dai',
            parentId: 380,
          },
          {
            title: 'Phường Đông Lĩnh',
            code: 16396,
            value: 'phuong_dong_linh',
            division_type: 'phường',
            short_codename: 'dong_linh',
            parentId: 380,
          },
          {
            title: 'Xã Đông Vinh',
            code: 16429,
            value: 'xa_dong_vinh',
            division_type: 'xã',
            short_codename: 'dong_vinh',
            parentId: 380,
          },
          {
            title: 'Phường Đông Tân',
            code: 16432,
            value: 'phuong_dong_tan',
            division_type: 'phường',
            short_codename: 'dong_tan',
            parentId: 380,
          },
          {
            title: 'Phường An Hưng',
            code: 16435,
            value: 'phuong_an_hung',
            division_type: 'phường',
            short_codename: 'an_hung',
            parentId: 380,
          },
          {
            title: 'Phường Quảng Thịnh',
            code: 16441,
            value: 'phuong_quang_thinh',
            division_type: 'phường',
            short_codename: 'quang_thinh',
            parentId: 380,
          },
          {
            title: 'Phường Quảng Đông',
            code: 16459,
            value: 'phuong_quang_dong',
            division_type: 'phường',
            short_codename: 'quang_dong',
            parentId: 380,
          },
          {
            title: 'Phường Quảng Cát',
            code: 16507,
            value: 'phuong_quang_cat',
            division_type: 'phường',
            short_codename: 'quang_cat',
            parentId: 380,
          },
          {
            title: 'Phường Quảng Phú',
            code: 16522,
            value: 'phuong_quang_phu',
            division_type: 'phường',
            short_codename: 'quang_phu',
            parentId: 380,
          },
          {
            title: 'Phường Quảng Tâm',
            code: 16525,
            value: 'phuong_quang_tam',
            division_type: 'phường',
            short_codename: 'quang_tam',
            parentId: 380,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Thị xã Bỉm Sơn',
        code: 381,
        value: 'thi_xa_bim_son',
        division_type: 'thị xã',
        short_codename: 'bim_son',
        children: [
          {
            title: 'Phường Bắc Sơn',
            code: 14809,
            value: 'phuong_bac_son',
            division_type: 'phường',
            short_codename: 'bac_son',
            parentId: 381,
          },
          {
            title: 'Phường Ba Đình',
            code: 14812,
            value: 'phuong_ba_dinh',
            division_type: 'phường',
            short_codename: 'ba_dinh',
            parentId: 381,
          },
          {
            title: 'Phường Lam Sơn',
            code: 14815,
            value: 'phuong_lam_son',
            division_type: 'phường',
            short_codename: 'lam_son',
            parentId: 381,
          },
          {
            title: 'Phường Ngọc Trạo',
            code: 14818,
            value: 'phuong_ngoc_trao',
            division_type: 'phường',
            short_codename: 'ngoc_trao',
            parentId: 381,
          },
          {
            title: 'Phường Đông Sơn',
            code: 14821,
            value: 'phuong_dong_son',
            division_type: 'phường',
            short_codename: 'dong_son',
            parentId: 381,
          },
          {
            title: 'Phường Phú Sơn',
            code: 14823,
            value: 'phuong_phu_son',
            division_type: 'phường',
            short_codename: 'phu_son',
            parentId: 381,
          },
          {
            title: 'Xã Quang Trung',
            code: 14824,
            value: 'xa_quang_trung',
            division_type: 'xã',
            short_codename: 'quang_trung',
            parentId: 381,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Thành phố Sầm Sơn',
        code: 382,
        value: 'thanh_pho_sam_son',
        division_type: 'thành phố',
        short_codename: 'sam_son',
        children: [
          {
            title: 'Phường Trung Sơn',
            code: 14830,
            value: 'phuong_trung_son',
            division_type: 'phường',
            short_codename: 'trung_son',
            parentId: 382,
          },
          {
            title: 'Phường Bắc Sơn',
            code: 14833,
            value: 'phuong_bac_son',
            division_type: 'phường',
            short_codename: 'bac_son',
            parentId: 382,
          },
          {
            title: 'Phường Trường Sơn',
            code: 14836,
            value: 'phuong_truong_son',
            division_type: 'phường',
            short_codename: 'truong_son',
            parentId: 382,
          },
          {
            title: 'Phường Quảng Cư',
            code: 14839,
            value: 'phuong_quang_cu',
            division_type: 'phường',
            short_codename: 'quang_cu',
            parentId: 382,
          },
          {
            title: 'Phường Quảng Tiến',
            code: 14842,
            value: 'phuong_quang_tien',
            division_type: 'phường',
            short_codename: 'quang_tien',
            parentId: 382,
          },
          {
            title: 'Xã Quảng Minh',
            code: 16513,
            value: 'xa_quang_minh',
            division_type: 'xã',
            short_codename: 'quang_minh',
            parentId: 382,
          },
          {
            title: 'Xã Quảng Hùng',
            code: 16516,
            value: 'xa_quang_hung',
            division_type: 'xã',
            short_codename: 'quang_hung',
            parentId: 382,
          },
          {
            title: 'Phường Quảng Thọ',
            code: 16528,
            value: 'phuong_quang_tho',
            division_type: 'phường',
            short_codename: 'quang_tho',
            parentId: 382,
          },
          {
            title: 'Phường Quảng Châu',
            code: 16531,
            value: 'phuong_quang_chau',
            division_type: 'phường',
            short_codename: 'quang_chau',
            parentId: 382,
          },
          {
            title: 'Phường Quảng Vinh',
            code: 16534,
            value: 'phuong_quang_vinh',
            division_type: 'phường',
            short_codename: 'quang_vinh',
            parentId: 382,
          },
          {
            title: 'Xã Quảng Đại',
            code: 16537,
            value: 'xa_quang_dai',
            division_type: 'xã',
            short_codename: 'quang_dai',
            parentId: 382,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Huyện Mường Lát',
        code: 384,
        value: 'huyen_muong_lat',
        division_type: 'huyện',
        short_codename: 'muong_lat',
        children: [
          {
            title: 'Thị trấn Mường Lát',
            code: 14845,
            value: 'thi_tran_muong_lat',
            division_type: 'thị trấn',
            short_codename: 'muong_lat',
            parentId: 384,
          },
          {
            title: 'Xã Tam Chung',
            code: 14848,
            value: 'xa_tam_chung',
            division_type: 'xã',
            short_codename: 'tam_chung',
            parentId: 384,
          },
          {
            title: 'Xã Mường Lý',
            code: 14854,
            value: 'xa_muong_ly',
            division_type: 'xã',
            short_codename: 'muong_ly',
            parentId: 384,
          },
          {
            title: 'Xã Trung Lý',
            code: 14857,
            value: 'xa_trung_ly',
            division_type: 'xã',
            short_codename: 'trung_ly',
            parentId: 384,
          },
          {
            title: 'Xã Quang Chiểu',
            code: 14860,
            value: 'xa_quang_chieu',
            division_type: 'xã',
            short_codename: 'quang_chieu',
            parentId: 384,
          },
          {
            title: 'Xã Pù Nhi',
            code: 14863,
            value: 'xa_pu_nhi',
            division_type: 'xã',
            short_codename: 'pu_nhi',
            parentId: 384,
          },
          {
            title: 'Xã Nhi Sơn',
            code: 14864,
            value: 'xa_nhi_son',
            division_type: 'xã',
            short_codename: 'nhi_son',
            parentId: 384,
          },
          {
            title: 'Xã Mường Chanh',
            code: 14866,
            value: 'xa_muong_chanh',
            division_type: 'xã',
            short_codename: 'muong_chanh',
            parentId: 384,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Huyện Quan Hóa',
        code: 385,
        value: 'huyen_quan_hoa',
        division_type: 'huyện',
        short_codename: 'quan_hoa',
        children: [
          {
            title: 'Thị trấn Hồi Xuân',
            code: 14869,
            value: 'thi_tran_hoi_xuan',
            division_type: 'thị trấn',
            short_codename: 'hoi_xuan',
            parentId: 385,
          },
          {
            title: 'Xã Thành Sơn',
            code: 14872,
            value: 'xa_thanh_son',
            division_type: 'xã',
            short_codename: 'thanh_son',
            parentId: 385,
          },
          {
            title: 'Xã Trung Sơn',
            code: 14875,
            value: 'xa_trung_son',
            division_type: 'xã',
            short_codename: 'trung_son',
            parentId: 385,
          },
          {
            title: 'Xã Phú Thanh',
            code: 14878,
            value: 'xa_phu_thanh',
            division_type: 'xã',
            short_codename: 'phu_thanh',
            parentId: 385,
          },
          {
            title: 'Xã Trung Thành',
            code: 14881,
            value: 'xa_trung_thanh',
            division_type: 'xã',
            short_codename: 'trung_thanh',
            parentId: 385,
          },
          {
            title: 'Xã Phú Lệ',
            code: 14884,
            value: 'xa_phu_le',
            division_type: 'xã',
            short_codename: 'phu_le',
            parentId: 385,
          },
          {
            title: 'Xã Phú Sơn',
            code: 14887,
            value: 'xa_phu_son',
            division_type: 'xã',
            short_codename: 'phu_son',
            parentId: 385,
          },
          {
            title: 'Xã Phú Xuân',
            code: 14890,
            value: 'xa_phu_xuan',
            division_type: 'xã',
            short_codename: 'phu_xuan',
            parentId: 385,
          },
          {
            title: 'Xã Hiền Chung',
            code: 14896,
            value: 'xa_hien_chung',
            division_type: 'xã',
            short_codename: 'hien_chung',
            parentId: 385,
          },
          {
            title: 'Xã Hiền Kiệt',
            code: 14899,
            value: 'xa_hien_kiet',
            division_type: 'xã',
            short_codename: 'hien_kiet',
            parentId: 385,
          },
          {
            title: 'Xã Nam Tiến',
            code: 14902,
            value: 'xa_nam_tien',
            division_type: 'xã',
            short_codename: 'nam_tien',
            parentId: 385,
          },
          {
            title: 'Xã Thiên Phủ',
            code: 14908,
            value: 'xa_thien_phu',
            division_type: 'xã',
            short_codename: 'thien_phu',
            parentId: 385,
          },
          {
            title: 'Xã Phú Nghiêm',
            code: 14911,
            value: 'xa_phu_nghiem',
            division_type: 'xã',
            short_codename: 'phu_nghiem',
            parentId: 385,
          },
          {
            title: 'Xã Nam Xuân',
            code: 14914,
            value: 'xa_nam_xuan',
            division_type: 'xã',
            short_codename: 'nam_xuan',
            parentId: 385,
          },
          {
            title: 'Xã Nam Động',
            code: 14917,
            value: 'xa_nam_dong',
            division_type: 'xã',
            short_codename: 'nam_dong',
            parentId: 385,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Huyện Bá Thước',
        code: 386,
        value: 'huyen_ba_thuoc',
        division_type: 'huyện',
        short_codename: 'ba_thuoc',
        children: [
          {
            title: 'Thị trấn Cành Nàng',
            code: 14923,
            value: 'thi_tran_canh_nang',
            division_type: 'thị trấn',
            short_codename: 'canh_nang',
            parentId: 386,
          },
          {
            title: 'Xã Điền Thượng',
            code: 14926,
            value: 'xa_dien_thuong',
            division_type: 'xã',
            short_codename: 'dien_thuong',
            parentId: 386,
          },
          {
            title: 'Xã Điền Hạ',
            code: 14929,
            value: 'xa_dien_ha',
            division_type: 'xã',
            short_codename: 'dien_ha',
            parentId: 386,
          },
          {
            title: 'Xã Điền Quang',
            code: 14932,
            value: 'xa_dien_quang',
            division_type: 'xã',
            short_codename: 'dien_quang',
            parentId: 386,
          },
          {
            title: 'Xã Điền Trung',
            code: 14935,
            value: 'xa_dien_trung',
            division_type: 'xã',
            short_codename: 'dien_trung',
            parentId: 386,
          },
          {
            title: 'Xã Thành Sơn',
            code: 14938,
            value: 'xa_thanh_son',
            division_type: 'xã',
            short_codename: 'thanh_son',
            parentId: 386,
          },
          {
            title: 'Xã Lương Ngoại',
            code: 14941,
            value: 'xa_luong_ngoai',
            division_type: 'xã',
            short_codename: 'luong_ngoai',
            parentId: 386,
          },
          {
            title: 'Xã Ái Thượng',
            code: 14944,
            value: 'xa_ai_thuong',
            division_type: 'xã',
            short_codename: 'ai_thuong',
            parentId: 386,
          },
          {
            title: 'Xã Lương Nội',
            code: 14947,
            value: 'xa_luong_noi',
            division_type: 'xã',
            short_codename: 'luong_noi',
            parentId: 386,
          },
          {
            title: 'Xã Điền Lư',
            code: 14950,
            value: 'xa_dien_lu',
            division_type: 'xã',
            short_codename: 'dien_lu',
            parentId: 386,
          },
          {
            title: 'Xã Lương Trung',
            code: 14953,
            value: 'xa_luong_trung',
            division_type: 'xã',
            short_codename: 'luong_trung',
            parentId: 386,
          },
          {
            title: 'Xã Lũng Niêm',
            code: 14956,
            value: 'xa_lung_niem',
            division_type: 'xã',
            short_codename: 'lung_niem',
            parentId: 386,
          },
          {
            title: 'Xã Lũng Cao',
            code: 14959,
            value: 'xa_lung_cao',
            division_type: 'xã',
            short_codename: 'lung_cao',
            parentId: 386,
          },
          {
            title: 'Xã Hạ Trung',
            code: 14962,
            value: 'xa_ha_trung',
            division_type: 'xã',
            short_codename: 'ha_trung',
            parentId: 386,
          },
          {
            title: 'Xã Cổ Lũng',
            code: 14965,
            value: 'xa_co_lung',
            division_type: 'xã',
            short_codename: 'co_lung',
            parentId: 386,
          },
          {
            title: 'Xã Thành Lâm',
            code: 14968,
            value: 'xa_thanh_lam',
            division_type: 'xã',
            short_codename: 'thanh_lam',
            parentId: 386,
          },
          {
            title: 'Xã Ban Công',
            code: 14971,
            value: 'xa_ban_cong',
            division_type: 'xã',
            short_codename: 'ban_cong',
            parentId: 386,
          },
          {
            title: 'Xã Kỳ Tân',
            code: 14974,
            value: 'xa_ky_tan',
            division_type: 'xã',
            short_codename: 'ky_tan',
            parentId: 386,
          },
          {
            title: 'Xã Văn Nho',
            code: 14977,
            value: 'xa_van_nho',
            division_type: 'xã',
            short_codename: 'van_nho',
            parentId: 386,
          },
          {
            title: 'Xã Thiết Ống',
            code: 14980,
            value: 'xa_thiet_ong',
            division_type: 'xã',
            short_codename: 'thiet_ong',
            parentId: 386,
          },
          {
            title: 'Xã Thiết Kế',
            code: 14986,
            value: 'xa_thiet_ke',
            division_type: 'xã',
            short_codename: 'thiet_ke',
            parentId: 386,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Huyện Quan Sơn',
        code: 387,
        value: 'huyen_quan_son',
        division_type: 'huyện',
        short_codename: 'quan_son',
        children: [
          {
            title: 'Xã Trung Xuân',
            code: 14995,
            value: 'xa_trung_xuan',
            division_type: 'xã',
            short_codename: 'trung_xuan',
            parentId: 387,
          },
          {
            title: 'Xã Trung Thượng',
            code: 14998,
            value: 'xa_trung_thuong',
            division_type: 'xã',
            short_codename: 'trung_thuong',
            parentId: 387,
          },
          {
            title: 'Xã Trung Tiến',
            code: 14999,
            value: 'xa_trung_tien',
            division_type: 'xã',
            short_codename: 'trung_tien',
            parentId: 387,
          },
          {
            title: 'Xã Trung Hạ',
            code: 15001,
            value: 'xa_trung_ha',
            division_type: 'xã',
            short_codename: 'trung_ha',
            parentId: 387,
          },
          {
            title: 'Xã Sơn Hà',
            code: 15004,
            value: 'xa_son_ha',
            division_type: 'xã',
            short_codename: 'son_ha',
            parentId: 387,
          },
          {
            title: 'Xã Tam Thanh',
            code: 15007,
            value: 'xa_tam_thanh',
            division_type: 'xã',
            short_codename: 'tam_thanh',
            parentId: 387,
          },
          {
            title: 'Xã Sơn Thủy',
            code: 15010,
            value: 'xa_son_thuy',
            division_type: 'xã',
            short_codename: 'son_thuy',
            parentId: 387,
          },
          {
            title: 'Xã Na Mèo',
            code: 15013,
            value: 'xa_na_meo',
            division_type: 'xã',
            short_codename: 'na_meo',
            parentId: 387,
          },
          {
            title: 'Thị trấn Sơn Lư',
            code: 15016,
            value: 'thi_tran_son_lu',
            division_type: 'thị trấn',
            short_codename: 'son_lu',
            parentId: 387,
          },
          {
            title: 'Xã Tam Lư',
            code: 15019,
            value: 'xa_tam_lu',
            division_type: 'xã',
            short_codename: 'tam_lu',
            parentId: 387,
          },
          {
            title: 'Xã Sơn Điện',
            code: 15022,
            value: 'xa_son_dien',
            division_type: 'xã',
            short_codename: 'son_dien',
            parentId: 387,
          },
          {
            title: 'Xã Mường Mìn',
            code: 15025,
            value: 'xa_muong_min',
            division_type: 'xã',
            short_codename: 'muong_min',
            parentId: 387,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Huyện Lang Chánh',
        code: 388,
        value: 'huyen_lang_chanh',
        division_type: 'huyện',
        short_codename: 'lang_chanh',
        children: [
          {
            title: 'Xã Yên Khương',
            code: 15031,
            value: 'xa_yen_khuong',
            division_type: 'xã',
            short_codename: 'yen_khuong',
            parentId: 388,
          },
          {
            title: 'Xã Yên Thắng',
            code: 15034,
            value: 'xa_yen_thang',
            division_type: 'xã',
            short_codename: 'yen_thang',
            parentId: 388,
          },
          {
            title: 'Xã Trí Nang',
            code: 15037,
            value: 'xa_tri_nang',
            division_type: 'xã',
            short_codename: 'tri_nang',
            parentId: 388,
          },
          {
            title: 'Xã Giao An',
            code: 15040,
            value: 'xa_giao_an',
            division_type: 'xã',
            short_codename: 'giao_an',
            parentId: 388,
          },
          {
            title: 'Xã Giao Thiện',
            code: 15043,
            value: 'xa_giao_thien',
            division_type: 'xã',
            short_codename: 'giao_thien',
            parentId: 388,
          },
          {
            title: 'Xã Tân Phúc',
            code: 15046,
            value: 'xa_tan_phuc',
            division_type: 'xã',
            short_codename: 'tan_phuc',
            parentId: 388,
          },
          {
            title: 'Xã Tam Văn',
            code: 15049,
            value: 'xa_tam_van',
            division_type: 'xã',
            short_codename: 'tam_van',
            parentId: 388,
          },
          {
            title: 'Xã Lâm Phú',
            code: 15052,
            value: 'xa_lam_phu',
            division_type: 'xã',
            short_codename: 'lam_phu',
            parentId: 388,
          },
          {
            title: 'Thị trấn Lang Chánh',
            code: 15055,
            value: 'thi_tran_lang_chanh',
            division_type: 'thị trấn',
            short_codename: 'lang_chanh',
            parentId: 388,
          },
          {
            title: 'Xã Đồng Lương',
            code: 15058,
            value: 'xa_dong_luong',
            division_type: 'xã',
            short_codename: 'dong_luong',
            parentId: 388,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Huyện Ngọc Lặc',
        code: 389,
        value: 'huyen_ngoc_lac',
        division_type: 'huyện',
        short_codename: 'ngoc_lac',
        children: [
          {
            title: 'Thị Trấn Ngọc Lặc',
            code: 15061,
            value: 'thi_tran_ngoc_lac',
            division_type: 'thị trấn',
            short_codename: 'ngoc_lac',
            parentId: 389,
          },
          {
            title: 'Xã Lam Sơn',
            code: 15064,
            value: 'xa_lam_son',
            division_type: 'xã',
            short_codename: 'lam_son',
            parentId: 389,
          },
          {
            title: 'Xã Mỹ Tân',
            code: 15067,
            value: 'xa_my_tan',
            division_type: 'xã',
            short_codename: 'my_tan',
            parentId: 389,
          },
          {
            title: 'Xã Thúy Sơn',
            code: 15070,
            value: 'xa_thuy_son',
            division_type: 'xã',
            short_codename: 'thuy_son',
            parentId: 389,
          },
          {
            title: 'Xã Thạch Lập',
            code: 15073,
            value: 'xa_thach_lap',
            division_type: 'xã',
            short_codename: 'thach_lap',
            parentId: 389,
          },
          {
            title: 'Xã Vân Âm',
            code: 15076,
            value: 'xa_van_am',
            division_type: 'xã',
            short_codename: 'van_am',
            parentId: 389,
          },
          {
            title: 'Xã Cao Ngọc',
            code: 15079,
            value: 'xa_cao_ngoc',
            division_type: 'xã',
            short_codename: 'cao_ngoc',
            parentId: 389,
          },
          {
            title: 'Xã Quang Trung',
            code: 15085,
            value: 'xa_quang_trung',
            division_type: 'xã',
            short_codename: 'quang_trung',
            parentId: 389,
          },
          {
            title: 'Xã Đồng Thịnh',
            code: 15088,
            value: 'xa_dong_thinh',
            division_type: 'xã',
            short_codename: 'dong_thinh',
            parentId: 389,
          },
          {
            title: 'Xã Ngọc Liên',
            code: 15091,
            value: 'xa_ngoc_lien',
            division_type: 'xã',
            short_codename: 'ngoc_lien',
            parentId: 389,
          },
          {
            title: 'Xã Ngọc Sơn',
            code: 15094,
            value: 'xa_ngoc_son',
            division_type: 'xã',
            short_codename: 'ngoc_son',
            parentId: 389,
          },
          {
            title: 'Xã Lộc Thịnh',
            code: 15097,
            value: 'xa_loc_thinh',
            division_type: 'xã',
            short_codename: 'loc_thinh',
            parentId: 389,
          },
          {
            title: 'Xã Cao Thịnh',
            code: 15100,
            value: 'xa_cao_thinh',
            division_type: 'xã',
            short_codename: 'cao_thinh',
            parentId: 389,
          },
          {
            title: 'Xã Ngọc Trung',
            code: 15103,
            value: 'xa_ngoc_trung',
            division_type: 'xã',
            short_codename: 'ngoc_trung',
            parentId: 389,
          },
          {
            title: 'Xã Phùng Giáo',
            code: 15106,
            value: 'xa_phung_giao',
            division_type: 'xã',
            short_codename: 'phung_giao',
            parentId: 389,
          },
          {
            title: 'Xã Phùng Minh',
            code: 15109,
            value: 'xa_phung_minh',
            division_type: 'xã',
            short_codename: 'phung_minh',
            parentId: 389,
          },
          {
            title: 'Xã Phúc Thịnh',
            code: 15112,
            value: 'xa_phuc_thinh',
            division_type: 'xã',
            short_codename: 'phuc_thinh',
            parentId: 389,
          },
          {
            title: 'Xã Nguyệt Ấn',
            code: 15115,
            value: 'xa_nguyet_an',
            division_type: 'xã',
            short_codename: 'nguyet_an',
            parentId: 389,
          },
          {
            title: 'Xã Kiên Thọ',
            code: 15118,
            value: 'xa_kien_tho',
            division_type: 'xã',
            short_codename: 'kien_tho',
            parentId: 389,
          },
          {
            title: 'Xã Minh Tiến',
            code: 15121,
            value: 'xa_minh_tien',
            division_type: 'xã',
            short_codename: 'minh_tien',
            parentId: 389,
          },
          {
            title: 'Xã Minh Sơn',
            code: 15124,
            value: 'xa_minh_son',
            division_type: 'xã',
            short_codename: 'minh_son',
            parentId: 389,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Huyện Cẩm Thủy',
        code: 390,
        value: 'huyen_cam_thuy',
        division_type: 'huyện',
        short_codename: 'cam_thuy',
        children: [
          {
            title: 'Thị trấn Phong Sơn',
            code: 15127,
            value: 'thi_tran_phong_son',
            division_type: 'thị trấn',
            short_codename: 'phong_son',
            parentId: 390,
          },
          {
            title: 'Xã Cẩm Thành',
            code: 15133,
            value: 'xa_cam_thanh',
            division_type: 'xã',
            short_codename: 'cam_thanh',
            parentId: 390,
          },
          {
            title: 'Xã Cẩm Quý',
            code: 15136,
            value: 'xa_cam_quy',
            division_type: 'xã',
            short_codename: 'cam_quy',
            parentId: 390,
          },
          {
            title: 'Xã Cẩm Lương',
            code: 15139,
            value: 'xa_cam_luong',
            division_type: 'xã',
            short_codename: 'cam_luong',
            parentId: 390,
          },
          {
            title: 'Xã Cẩm Thạch',
            code: 15142,
            value: 'xa_cam_thach',
            division_type: 'xã',
            short_codename: 'cam_thach',
            parentId: 390,
          },
          {
            title: 'Xã Cẩm Liên',
            code: 15145,
            value: 'xa_cam_lien',
            division_type: 'xã',
            short_codename: 'cam_lien',
            parentId: 390,
          },
          {
            title: 'Xã Cẩm Giang',
            code: 15148,
            value: 'xa_cam_giang',
            division_type: 'xã',
            short_codename: 'cam_giang',
            parentId: 390,
          },
          {
            title: 'Xã Cẩm Bình',
            code: 15151,
            value: 'xa_cam_binh',
            division_type: 'xã',
            short_codename: 'cam_binh',
            parentId: 390,
          },
          {
            title: 'Xã Cẩm Tú',
            code: 15154,
            value: 'xa_cam_tu',
            division_type: 'xã',
            short_codename: 'cam_tu',
            parentId: 390,
          },
          {
            title: 'Xã Cẩm Châu',
            code: 15160,
            value: 'xa_cam_chau',
            division_type: 'xã',
            short_codename: 'cam_chau',
            parentId: 390,
          },
          {
            title: 'Xã Cẩm Tâm',
            code: 15163,
            value: 'xa_cam_tam',
            division_type: 'xã',
            short_codename: 'cam_tam',
            parentId: 390,
          },
          {
            title: 'Xã Cẩm Ngọc',
            code: 15169,
            value: 'xa_cam_ngoc',
            division_type: 'xã',
            short_codename: 'cam_ngoc',
            parentId: 390,
          },
          {
            title: 'Xã Cẩm Long',
            code: 15172,
            value: 'xa_cam_long',
            division_type: 'xã',
            short_codename: 'cam_long',
            parentId: 390,
          },
          {
            title: 'Xã Cẩm Yên',
            code: 15175,
            value: 'xa_cam_yen',
            division_type: 'xã',
            short_codename: 'cam_yen',
            parentId: 390,
          },
          {
            title: 'Xã Cẩm Tân',
            code: 15178,
            value: 'xa_cam_tan',
            division_type: 'xã',
            short_codename: 'cam_tan',
            parentId: 390,
          },
          {
            title: 'Xã Cẩm Phú',
            code: 15181,
            value: 'xa_cam_phu',
            division_type: 'xã',
            short_codename: 'cam_phu',
            parentId: 390,
          },
          {
            title: 'Xã Cẩm Vân',
            code: 15184,
            value: 'xa_cam_van',
            division_type: 'xã',
            short_codename: 'cam_van',
            parentId: 390,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Huyện Thạch Thành',
        code: 391,
        value: 'huyen_thach_thanh',
        division_type: 'huyện',
        short_codename: 'thach_thanh',
        children: [
          {
            title: 'Thị trấn Kim Tân',
            code: 15187,
            value: 'thi_tran_kim_tan',
            division_type: 'thị trấn',
            short_codename: 'kim_tan',
            parentId: 391,
          },
          {
            title: 'Thị trấn Vân Du',
            code: 15190,
            value: 'thi_tran_van_du',
            division_type: 'thị trấn',
            short_codename: 'van_du',
            parentId: 391,
          },
          {
            title: 'Xã Thạch Lâm',
            code: 15196,
            value: 'xa_thach_lam',
            division_type: 'xã',
            short_codename: 'thach_lam',
            parentId: 391,
          },
          {
            title: 'Xã Thạch Quảng',
            code: 15199,
            value: 'xa_thach_quang',
            division_type: 'xã',
            short_codename: 'thach_quang',
            parentId: 391,
          },
          {
            title: 'Xã Thạch Tượng',
            code: 15202,
            value: 'xa_thach_tuong',
            division_type: 'xã',
            short_codename: 'thach_tuong',
            parentId: 391,
          },
          {
            title: 'Xã Thạch Cẩm',
            code: 15205,
            value: 'xa_thach_cam',
            division_type: 'xã',
            short_codename: 'thach_cam',
            parentId: 391,
          },
          {
            title: 'Xã Thạch Sơn',
            code: 15208,
            value: 'xa_thach_son',
            division_type: 'xã',
            short_codename: 'thach_son',
            parentId: 391,
          },
          {
            title: 'Xã Thạch Bình',
            code: 15211,
            value: 'xa_thach_binh',
            division_type: 'xã',
            short_codename: 'thach_binh',
            parentId: 391,
          },
          {
            title: 'Xã Thạch Định',
            code: 15214,
            value: 'xa_thach_dinh',
            division_type: 'xã',
            short_codename: 'thach_dinh',
            parentId: 391,
          },
          {
            title: 'Xã Thạch Đồng',
            code: 15217,
            value: 'xa_thach_dong',
            division_type: 'xã',
            short_codename: 'thach_dong',
            parentId: 391,
          },
          {
            title: 'Xã Thạch Long',
            code: 15220,
            value: 'xa_thach_long',
            division_type: 'xã',
            short_codename: 'thach_long',
            parentId: 391,
          },
          {
            title: 'Xã Thành Mỹ',
            code: 15223,
            value: 'xa_thanh_my',
            division_type: 'xã',
            short_codename: 'thanh_my',
            parentId: 391,
          },
          {
            title: 'Xã Thành Yên',
            code: 15226,
            value: 'xa_thanh_yen',
            division_type: 'xã',
            short_codename: 'thanh_yen',
            parentId: 391,
          },
          {
            title: 'Xã Thành Vinh',
            code: 15229,
            value: 'xa_thanh_vinh',
            division_type: 'xã',
            short_codename: 'thanh_vinh',
            parentId: 391,
          },
          {
            title: 'Xã Thành Minh',
            code: 15232,
            value: 'xa_thanh_minh',
            division_type: 'xã',
            short_codename: 'thanh_minh',
            parentId: 391,
          },
          {
            title: 'Xã Thành Công',
            code: 15235,
            value: 'xa_thanh_cong',
            division_type: 'xã',
            short_codename: 'thanh_cong',
            parentId: 391,
          },
          {
            title: 'Xã Thành Tân',
            code: 15238,
            value: 'xa_thanh_tan',
            division_type: 'xã',
            short_codename: 'thanh_tan',
            parentId: 391,
          },
          {
            title: 'Xã Thành Trực',
            code: 15241,
            value: 'xa_thanh_truc',
            division_type: 'xã',
            short_codename: 'thanh_truc',
            parentId: 391,
          },
          {
            title: 'Xã Thành Tâm',
            code: 15247,
            value: 'xa_thanh_tam',
            division_type: 'xã',
            short_codename: 'thanh_tam',
            parentId: 391,
          },
          {
            title: 'Xã Thành An',
            code: 15250,
            value: 'xa_thanh_an',
            division_type: 'xã',
            short_codename: 'thanh_an',
            parentId: 391,
          },
          {
            title: 'Xã Thành Thọ',
            code: 15253,
            value: 'xa_thanh_tho',
            division_type: 'xã',
            short_codename: 'thanh_tho',
            parentId: 391,
          },
          {
            title: 'Xã Thành Tiến',
            code: 15256,
            value: 'xa_thanh_tien',
            division_type: 'xã',
            short_codename: 'thanh_tien',
            parentId: 391,
          },
          {
            title: 'Xã Thành Long',
            code: 15259,
            value: 'xa_thanh_long',
            division_type: 'xã',
            short_codename: 'thanh_long',
            parentId: 391,
          },
          {
            title: 'Xã Thành Hưng',
            code: 15265,
            value: 'xa_thanh_hung',
            division_type: 'xã',
            short_codename: 'thanh_hung',
            parentId: 391,
          },
          {
            title: 'Xã Ngọc Trạo',
            code: 15268,
            value: 'xa_ngoc_trao',
            division_type: 'xã',
            short_codename: 'ngoc_trao',
            parentId: 391,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Huyện Hà Trung',
        code: 392,
        value: 'huyen_ha_trung',
        division_type: 'huyện',
        short_codename: 'ha_trung',
        children: [
          {
            title: 'Thị trấn Hà Trung',
            code: 15271,
            value: 'thi_tran_ha_trung',
            division_type: 'thị trấn',
            short_codename: 'ha_trung',
            parentId: 392,
          },
          {
            title: 'Xã Hà Long',
            code: 15274,
            value: 'xa_ha_long',
            division_type: 'xã',
            short_codename: 'ha_long',
            parentId: 392,
          },
          {
            title: 'Xã Hà Vinh',
            code: 15277,
            value: 'xa_ha_vinh',
            division_type: 'xã',
            short_codename: 'ha_vinh',
            parentId: 392,
          },
          {
            title: 'Xã Hà Bắc',
            code: 15280,
            value: 'xa_ha_bac',
            division_type: 'xã',
            short_codename: 'ha_bac',
            parentId: 392,
          },
          {
            title: 'Xã Hoạt Giang',
            code: 15283,
            value: 'xa_hoat_giang',
            division_type: 'xã',
            short_codename: 'hoat_giang',
            parentId: 392,
          },
          {
            title: 'Xã Yên Dương',
            code: 15286,
            value: 'xa_yen_duong',
            division_type: 'xã',
            short_codename: 'yen_duong',
            parentId: 392,
          },
          {
            title: 'Xã Hà Giang',
            code: 15292,
            value: 'xa_ha_giang',
            division_type: 'xã',
            short_codename: 'ha_giang',
            parentId: 392,
          },
          {
            title: 'Xã Lĩnh Toại',
            code: 15298,
            value: 'xa_linh_toai',
            division_type: 'xã',
            short_codename: 'linh_toai',
            parentId: 392,
          },
          {
            title: 'Xã Hà Ngọc',
            code: 15304,
            value: 'xa_ha_ngoc',
            division_type: 'xã',
            short_codename: 'ha_ngoc',
            parentId: 392,
          },
          {
            title: 'Xã Yến Sơn',
            code: 15307,
            value: 'xa_yen_son',
            division_type: 'xã',
            short_codename: 'yen_son',
            parentId: 392,
          },
          {
            title: 'Xã Hà Sơn',
            code: 15313,
            value: 'xa_ha_son',
            division_type: 'xã',
            short_codename: 'ha_son',
            parentId: 392,
          },
          {
            title: 'Xã Hà Lĩnh',
            code: 15316,
            value: 'xa_ha_linh',
            division_type: 'xã',
            short_codename: 'ha_linh',
            parentId: 392,
          },
          {
            title: 'Xã Hà Đông',
            code: 15319,
            value: 'xa_ha_dong',
            division_type: 'xã',
            short_codename: 'ha_dong',
            parentId: 392,
          },
          {
            title: 'Xã Hà Tân',
            code: 15322,
            value: 'xa_ha_tan',
            division_type: 'xã',
            short_codename: 'ha_tan',
            parentId: 392,
          },
          {
            title: 'Xã Hà Tiến',
            code: 15325,
            value: 'xa_ha_tien',
            division_type: 'xã',
            short_codename: 'ha_tien',
            parentId: 392,
          },
          {
            title: 'Xã Hà Bình',
            code: 15328,
            value: 'xa_ha_binh',
            division_type: 'xã',
            short_codename: 'ha_binh',
            parentId: 392,
          },
          {
            title: 'Xã Hà Lai',
            code: 15331,
            value: 'xa_ha_lai',
            division_type: 'xã',
            short_codename: 'ha_lai',
            parentId: 392,
          },
          {
            title: 'Xã Hà Châu',
            code: 15334,
            value: 'xa_ha_chau',
            division_type: 'xã',
            short_codename: 'ha_chau',
            parentId: 392,
          },
          {
            title: 'Xã Hà Thái',
            code: 15340,
            value: 'xa_ha_thai',
            division_type: 'xã',
            short_codename: 'ha_thai',
            parentId: 392,
          },
          {
            title: 'Xã Hà Hải',
            code: 15343,
            value: 'xa_ha_hai',
            division_type: 'xã',
            short_codename: 'ha_hai',
            parentId: 392,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Huyện Vĩnh Lộc',
        code: 393,
        value: 'huyen_vinh_loc',
        division_type: 'huyện',
        short_codename: 'vinh_loc',
        children: [
          {
            title: 'Thị trấn Vĩnh Lộc',
            code: 15349,
            value: 'thi_tran_vinh_loc',
            division_type: 'thị trấn',
            short_codename: 'vinh_loc',
            parentId: 393,
          },
          {
            title: 'Xã Vĩnh Quang',
            code: 15352,
            value: 'xa_vinh_quang',
            division_type: 'xã',
            short_codename: 'vinh_quang',
            parentId: 393,
          },
          {
            title: 'Xã Vĩnh Yên',
            code: 15355,
            value: 'xa_vinh_yen',
            division_type: 'xã',
            short_codename: 'vinh_yen',
            parentId: 393,
          },
          {
            title: 'Xã Vĩnh Tiến',
            code: 15358,
            value: 'xa_vinh_tien',
            division_type: 'xã',
            short_codename: 'vinh_tien',
            parentId: 393,
          },
          {
            title: 'Xã Vĩnh Long',
            code: 15361,
            value: 'xa_vinh_long',
            division_type: 'xã',
            short_codename: 'vinh_long',
            parentId: 393,
          },
          {
            title: 'Xã Vĩnh Phúc',
            code: 15364,
            value: 'xa_vinh_phuc',
            division_type: 'xã',
            short_codename: 'vinh_phuc',
            parentId: 393,
          },
          {
            title: 'Xã Vĩnh Hưng',
            code: 15367,
            value: 'xa_vinh_hung',
            division_type: 'xã',
            short_codename: 'xa_vinh_hung',
            parentId: 393,
          },
          {
            title: 'Xã Vĩnh Hòa',
            code: 15376,
            value: 'xa_vinh_hoa',
            division_type: 'xã',
            short_codename: 'vinh_hoa',
            parentId: 393,
          },
          {
            title: 'Xã Vĩnh Hùng',
            code: 15379,
            value: 'xa_vinh_hung',
            division_type: 'xã',
            short_codename: 'xa_vinh_hung',
            parentId: 393,
          },
          {
            title: 'Xã Minh Tân',
            code: 15382,
            value: 'xa_minh_tan',
            division_type: 'xã',
            short_codename: 'minh_tan',
            parentId: 393,
          },
          {
            title: 'Xã Ninh Khang',
            code: 15385,
            value: 'xa_ninh_khang',
            division_type: 'xã',
            short_codename: 'ninh_khang',
            parentId: 393,
          },
          {
            title: 'Xã Vĩnh Thịnh',
            code: 15388,
            value: 'xa_vinh_thinh',
            division_type: 'xã',
            short_codename: 'vinh_thinh',
            parentId: 393,
          },
          {
            title: 'Xã Vĩnh An',
            code: 15391,
            value: 'xa_vinh_an',
            division_type: 'xã',
            short_codename: 'vinh_an',
            parentId: 393,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Huyện Yên Định',
        code: 394,
        value: 'huyen_yen_dinh',
        division_type: 'huyện',
        short_codename: 'yen_dinh',
        children: [
          {
            title: 'Thị trấn Thống Nhất',
            code: 15397,
            value: 'thi_tran_thong_nhat',
            division_type: 'thị trấn',
            short_codename: 'thong_nhat',
            parentId: 394,
          },
          {
            title: 'Xã Yên Lâm',
            code: 15403,
            value: 'xa_yen_lam',
            division_type: 'xã',
            short_codename: 'yen_lam',
            parentId: 394,
          },
          {
            title: 'Xã Yên Tâm',
            code: 15406,
            value: 'xa_yen_tam',
            division_type: 'xã',
            short_codename: 'yen_tam',
            parentId: 394,
          },
          {
            title: 'Xã Yên Phú',
            code: 15409,
            value: 'xa_yen_phu',
            division_type: 'xã',
            short_codename: 'yen_phu',
            parentId: 394,
          },
          {
            title: 'Xã Quí Lộc',
            code: 15412,
            value: 'xa_qui_loc',
            division_type: 'xã',
            short_codename: 'qui_loc',
            parentId: 394,
          },
          {
            title: 'Xã Yên Thọ',
            code: 15415,
            value: 'xa_yen_tho',
            division_type: 'xã',
            short_codename: 'yen_tho',
            parentId: 394,
          },
          {
            title: 'Xã Yên Trung',
            code: 15418,
            value: 'xa_yen_trung',
            division_type: 'xã',
            short_codename: 'yen_trung',
            parentId: 394,
          },
          {
            title: 'Xã Yên Trường',
            code: 15421,
            value: 'xa_yen_truong',
            division_type: 'xã',
            short_codename: 'yen_truong',
            parentId: 394,
          },
          {
            title: 'Xã Yên Phong',
            code: 15427,
            value: 'xa_yen_phong',
            division_type: 'xã',
            short_codename: 'yen_phong',
            parentId: 394,
          },
          {
            title: 'Xã Yên Thái',
            code: 15430,
            value: 'xa_yen_thai',
            division_type: 'xã',
            short_codename: 'yen_thai',
            parentId: 394,
          },
          {
            title: 'Xã Yên Hùng',
            code: 15433,
            value: 'xa_yen_hung',
            division_type: 'xã',
            short_codename: 'yen_hung',
            parentId: 394,
          },
          {
            title: 'Xã Yên Thịnh',
            code: 15436,
            value: 'xa_yen_thinh',
            division_type: 'xã',
            short_codename: 'yen_thinh',
            parentId: 394,
          },
          {
            title: 'Xã Yên Ninh',
            code: 15439,
            value: 'xa_yen_ninh',
            division_type: 'xã',
            short_codename: 'yen_ninh',
            parentId: 394,
          },
          {
            title: 'Xã Yên Lạc',
            code: 15442,
            value: 'xa_yen_lac',
            division_type: 'xã',
            short_codename: 'yen_lac',
            parentId: 394,
          },
          {
            title: 'Xã Định Tăng',
            code: 15445,
            value: 'xa_dinh_tang',
            division_type: 'xã',
            short_codename: 'dinh_tang',
            parentId: 394,
          },
          {
            title: 'Xã Định Hòa',
            code: 15448,
            value: 'xa_dinh_hoa',
            division_type: 'xã',
            short_codename: 'dinh_hoa',
            parentId: 394,
          },
          {
            title: 'Xã Định Thành',
            code: 15451,
            value: 'xa_dinh_thanh',
            division_type: 'xã',
            short_codename: 'dinh_thanh',
            parentId: 394,
          },
          {
            title: 'Xã Định Công',
            code: 15454,
            value: 'xa_dinh_cong',
            division_type: 'xã',
            short_codename: 'dinh_cong',
            parentId: 394,
          },
          {
            title: 'Xã Định Tân',
            code: 15457,
            value: 'xa_dinh_tan',
            division_type: 'xã',
            short_codename: 'dinh_tan',
            parentId: 394,
          },
          {
            title: 'Xã Định Tiến',
            code: 15460,
            value: 'xa_dinh_tien',
            division_type: 'xã',
            short_codename: 'dinh_tien',
            parentId: 394,
          },
          {
            title: 'Xã Định Long',
            code: 15463,
            value: 'xa_dinh_long',
            division_type: 'xã',
            short_codename: 'dinh_long',
            parentId: 394,
          },
          {
            title: 'Xã Định Liên',
            code: 15466,
            value: 'xa_dinh_lien',
            division_type: 'xã',
            short_codename: 'dinh_lien',
            parentId: 394,
          },
          {
            title: 'Thị trấn Quán Lào',
            code: 15469,
            value: 'thi_tran_quan_lao',
            division_type: 'thị trấn',
            short_codename: 'quan_lao',
            parentId: 394,
          },
          {
            title: 'Xã Định Hưng',
            code: 15472,
            value: 'xa_dinh_hung',
            division_type: 'xã',
            short_codename: 'dinh_hung',
            parentId: 394,
          },
          {
            title: 'Xã Định Hải',
            code: 15475,
            value: 'xa_dinh_hai',
            division_type: 'xã',
            short_codename: 'dinh_hai',
            parentId: 394,
          },
          {
            title: 'Xã Định Bình',
            code: 15478,
            value: 'xa_dinh_binh',
            division_type: 'xã',
            short_codename: 'dinh_binh',
            parentId: 394,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Huyện Thọ Xuân',
        code: 395,
        value: 'huyen_tho_xuan',
        division_type: 'huyện',
        short_codename: 'tho_xuan',
        children: [
          {
            title: 'Xã Xuân Hồng',
            code: 15493,
            value: 'xa_xuan_hong',
            division_type: 'xã',
            short_codename: 'xuan_hong',
            parentId: 395,
          },
          {
            title: 'Thị trấn Thọ Xuân',
            code: 15499,
            value: 'thi_tran_tho_xuan',
            division_type: 'thị trấn',
            short_codename: 'tho_xuan',
            parentId: 395,
          },
          {
            title: 'Xã Bắc Lương',
            code: 15502,
            value: 'xa_bac_luong',
            division_type: 'xã',
            short_codename: 'bac_luong',
            parentId: 395,
          },
          {
            title: 'Xã Nam Giang',
            code: 15505,
            value: 'xa_nam_giang',
            division_type: 'xã',
            short_codename: 'nam_giang',
            parentId: 395,
          },
          {
            title: 'Xã Xuân Phong',
            code: 15508,
            value: 'xa_xuan_phong',
            division_type: 'xã',
            short_codename: 'xuan_phong',
            parentId: 395,
          },
          {
            title: 'Xã Thọ Lộc',
            code: 15511,
            value: 'xa_tho_loc',
            division_type: 'xã',
            short_codename: 'tho_loc',
            parentId: 395,
          },
          {
            title: 'Xã Xuân Trường',
            code: 15514,
            value: 'xa_xuan_truong',
            division_type: 'xã',
            short_codename: 'xuan_truong',
            parentId: 395,
          },
          {
            title: 'Xã Xuân Hòa',
            code: 15517,
            value: 'xa_xuan_hoa',
            division_type: 'xã',
            short_codename: 'xuan_hoa',
            parentId: 395,
          },
          {
            title: 'Xã Thọ Hải',
            code: 15520,
            value: 'xa_tho_hai',
            division_type: 'xã',
            short_codename: 'tho_hai',
            parentId: 395,
          },
          {
            title: 'Xã Tây Hồ',
            code: 15523,
            value: 'xa_tay_ho',
            division_type: 'xã',
            short_codename: 'tay_ho',
            parentId: 395,
          },
          {
            title: 'Xã Xuân Giang',
            code: 15526,
            value: 'xa_xuan_giang',
            division_type: 'xã',
            short_codename: 'xuan_giang',
            parentId: 395,
          },
          {
            title: 'Xã Xuân Sinh',
            code: 15532,
            value: 'xa_xuan_sinh',
            division_type: 'xã',
            short_codename: 'xuan_sinh',
            parentId: 395,
          },
          {
            title: 'Xã Xuân Hưng',
            code: 15535,
            value: 'xa_xuan_hung',
            division_type: 'xã',
            short_codename: 'xuan_hung',
            parentId: 395,
          },
          {
            title: 'Xã Thọ Diên',
            code: 15538,
            value: 'xa_tho_dien',
            division_type: 'xã',
            short_codename: 'tho_dien',
            parentId: 395,
          },
          {
            title: 'Xã Thọ Lâm',
            code: 15541,
            value: 'xa_tho_lam',
            division_type: 'xã',
            short_codename: 'tho_lam',
            parentId: 395,
          },
          {
            title: 'Xã Thọ Xương',
            code: 15544,
            value: 'xa_tho_xuong',
            division_type: 'xã',
            short_codename: 'tho_xuong',
            parentId: 395,
          },
          {
            title: 'Xã Xuân Bái',
            code: 15547,
            value: 'xa_xuan_bai',
            division_type: 'xã',
            short_codename: 'xuan_bai',
            parentId: 395,
          },
          {
            title: 'Xã Xuân Phú',
            code: 15550,
            value: 'xa_xuan_phu',
            division_type: 'xã',
            short_codename: 'xuan_phu',
            parentId: 395,
          },
          {
            title: 'Thị trấn Sao Vàng',
            code: 15553,
            value: 'thi_tran_sao_vang',
            division_type: 'thị trấn',
            short_codename: 'sao_vang',
            parentId: 395,
          },
          {
            title: 'Thị trấn Lam Sơn',
            code: 15556,
            value: 'thi_tran_lam_son',
            division_type: 'thị trấn',
            short_codename: 'lam_son',
            parentId: 395,
          },
          {
            title: 'Xã Xuân Thiên',
            code: 15559,
            value: 'xa_xuan_thien',
            division_type: 'xã',
            short_codename: 'xuan_thien',
            parentId: 395,
          },
          {
            title: 'Xã Thuận Minh',
            code: 15565,
            value: 'xa_thuan_minh',
            division_type: 'xã',
            short_codename: 'thuan_minh',
            parentId: 395,
          },
          {
            title: 'Xã Thọ Lập',
            code: 15568,
            value: 'xa_tho_lap',
            division_type: 'xã',
            short_codename: 'tho_lap',
            parentId: 395,
          },
          {
            title: 'Xã Quảng Phú',
            code: 15571,
            value: 'xa_quang_phu',
            division_type: 'xã',
            short_codename: 'quang_phu',
            parentId: 395,
          },
          {
            title: 'Xã Xuân Tín',
            code: 15574,
            value: 'xa_xuan_tin',
            division_type: 'xã',
            short_codename: 'xuan_tin',
            parentId: 395,
          },
          {
            title: 'Xã Phú Xuân',
            code: 15577,
            value: 'xa_phu_xuan',
            division_type: 'xã',
            short_codename: 'phu_xuan',
            parentId: 395,
          },
          {
            title: 'Xã Xuân Lai',
            code: 15583,
            value: 'xa_xuan_lai',
            division_type: 'xã',
            short_codename: 'xuan_lai',
            parentId: 395,
          },
          {
            title: 'Xã Xuân Lập',
            code: 15586,
            value: 'xa_xuan_lap',
            division_type: 'xã',
            short_codename: 'xuan_lap',
            parentId: 395,
          },
          {
            title: 'Xã Xuân Minh',
            code: 15592,
            value: 'xa_xuan_minh',
            division_type: 'xã',
            short_codename: 'xuan_minh',
            parentId: 395,
          },
          {
            title: 'Xã Trường Xuân',
            code: 15598,
            value: 'xa_truong_xuan',
            division_type: 'xã',
            short_codename: 'truong_xuan',
            parentId: 395,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Huyện Thường Xuân',
        code: 396,
        value: 'huyen_thuong_xuan',
        division_type: 'huyện',
        short_codename: 'thuong_xuan',
        children: [
          {
            title: 'Xã Bát Mọt',
            code: 15607,
            value: 'xa_bat_mot',
            division_type: 'xã',
            short_codename: 'bat_mot',
            parentId: 396,
          },
          {
            title: 'Xã Yên Nhân',
            code: 15610,
            value: 'xa_yen_nhan',
            division_type: 'xã',
            short_codename: 'yen_nhan',
            parentId: 396,
          },
          {
            title: 'Xã Xuân Lẹ',
            code: 15619,
            value: 'xa_xuan_le',
            division_type: 'xã',
            short_codename: 'xuan_le',
            parentId: 396,
          },
          {
            title: 'Xã Vạn Xuân',
            code: 15622,
            value: 'xa_van_xuan',
            division_type: 'xã',
            short_codename: 'van_xuan',
            parentId: 396,
          },
          {
            title: 'Xã Lương Sơn',
            code: 15628,
            value: 'xa_luong_son',
            division_type: 'xã',
            short_codename: 'luong_son',
            parentId: 396,
          },
          {
            title: 'Xã Xuân Cao',
            code: 15631,
            value: 'xa_xuan_cao',
            division_type: 'xã',
            short_codename: 'xuan_cao',
            parentId: 396,
          },
          {
            title: 'Xã Luận Thành',
            code: 15634,
            value: 'xa_luan_thanh',
            division_type: 'xã',
            short_codename: 'luan_thanh',
            parentId: 396,
          },
          {
            title: 'Xã Luận Khê',
            code: 15637,
            value: 'xa_luan_khe',
            division_type: 'xã',
            short_codename: 'luan_khe',
            parentId: 396,
          },
          {
            title: 'Xã Xuân Thắng',
            code: 15640,
            value: 'xa_xuan_thang',
            division_type: 'xã',
            short_codename: 'xuan_thang',
            parentId: 396,
          },
          {
            title: 'Xã Xuân Lộc',
            code: 15643,
            value: 'xa_xuan_loc',
            division_type: 'xã',
            short_codename: 'xuan_loc',
            parentId: 396,
          },
          {
            title: 'Thị trấn Thường Xuân',
            code: 15646,
            value: 'thi_tran_thuong_xuan',
            division_type: 'thị trấn',
            short_codename: 'thuong_xuan',
            parentId: 396,
          },
          {
            title: 'Xã Xuân Dương',
            code: 15649,
            value: 'xa_xuan_duong',
            division_type: 'xã',
            short_codename: 'xuan_duong',
            parentId: 396,
          },
          {
            title: 'Xã Thọ Thanh',
            code: 15652,
            value: 'xa_tho_thanh',
            division_type: 'xã',
            short_codename: 'tho_thanh',
            parentId: 396,
          },
          {
            title: 'Xã Ngọc Phụng',
            code: 15655,
            value: 'xa_ngoc_phung',
            division_type: 'xã',
            short_codename: 'ngoc_phung',
            parentId: 396,
          },
          {
            title: 'Xã Xuân Chinh',
            code: 15658,
            value: 'xa_xuan_chinh',
            division_type: 'xã',
            short_codename: 'xuan_chinh',
            parentId: 396,
          },
          {
            title: 'Xã Tân Thành',
            code: 15661,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 396,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Huyện Triệu Sơn',
        code: 397,
        value: 'huyen_trieu_son',
        division_type: 'huyện',
        short_codename: 'trieu_son',
        children: [
          {
            title: 'Thị trấn Triệu Sơn',
            code: 15664,
            value: 'thi_tran_trieu_son',
            division_type: 'thị trấn',
            short_codename: 'trieu_son',
            parentId: 397,
          },
          {
            title: 'Xã Thọ Sơn',
            code: 15667,
            value: 'xa_tho_son',
            division_type: 'xã',
            short_codename: 'tho_son',
            parentId: 397,
          },
          {
            title: 'Xã Thọ Bình',
            code: 15670,
            value: 'xa_tho_binh',
            division_type: 'xã',
            short_codename: 'tho_binh',
            parentId: 397,
          },
          {
            title: 'Xã Thọ Tiến',
            code: 15673,
            value: 'xa_tho_tien',
            division_type: 'xã',
            short_codename: 'tho_tien',
            parentId: 397,
          },
          {
            title: 'Xã Hợp Lý',
            code: 15676,
            value: 'xa_hop_ly',
            division_type: 'xã',
            short_codename: 'hop_ly',
            parentId: 397,
          },
          {
            title: 'Xã Hợp Tiến',
            code: 15679,
            value: 'xa_hop_tien',
            division_type: 'xã',
            short_codename: 'hop_tien',
            parentId: 397,
          },
          {
            title: 'Xã Hợp Thành',
            code: 15682,
            value: 'xa_hop_thanh',
            division_type: 'xã',
            short_codename: 'hop_thanh',
            parentId: 397,
          },
          {
            title: 'Xã Triệu Thành',
            code: 15685,
            value: 'xa_trieu_thanh',
            division_type: 'xã',
            short_codename: 'trieu_thanh',
            parentId: 397,
          },
          {
            title: 'Xã Hợp Thắng',
            code: 15688,
            value: 'xa_hop_thang',
            division_type: 'xã',
            short_codename: 'hop_thang',
            parentId: 397,
          },
          {
            title: 'Xã Minh Sơn',
            code: 15691,
            value: 'xa_minh_son',
            division_type: 'xã',
            short_codename: 'minh_son',
            parentId: 397,
          },
          {
            title: 'Xã Dân Lực',
            code: 15700,
            value: 'xa_dan_luc',
            division_type: 'xã',
            short_codename: 'dan_luc',
            parentId: 397,
          },
          {
            title: 'Xã Dân Lý',
            code: 15703,
            value: 'xa_dan_ly',
            division_type: 'xã',
            short_codename: 'dan_ly',
            parentId: 397,
          },
          {
            title: 'Xã Dân Quyền',
            code: 15706,
            value: 'xa_dan_quyen',
            division_type: 'xã',
            short_codename: 'dan_quyen',
            parentId: 397,
          },
          {
            title: 'Xã An Nông',
            code: 15709,
            value: 'xa_an_nong',
            division_type: 'xã',
            short_codename: 'an_nong',
            parentId: 397,
          },
          {
            title: 'Xã Văn Sơn',
            code: 15712,
            value: 'xa_van_son',
            division_type: 'xã',
            short_codename: 'van_son',
            parentId: 397,
          },
          {
            title: 'Xã Thái Hòa',
            code: 15715,
            value: 'xa_thai_hoa',
            division_type: 'xã',
            short_codename: 'thai_hoa',
            parentId: 397,
          },
          {
            title: 'Thị trấn Nưa',
            code: 15718,
            value: 'thi_tran_nua',
            division_type: 'thị trấn',
            short_codename: 'nua',
            parentId: 397,
          },
          {
            title: 'Xã Đồng Lợi',
            code: 15721,
            value: 'xa_dong_loi',
            division_type: 'xã',
            short_codename: 'dong_loi',
            parentId: 397,
          },
          {
            title: 'Xã Đồng Tiến',
            code: 15724,
            value: 'xa_dong_tien',
            division_type: 'xã',
            short_codename: 'dong_tien',
            parentId: 397,
          },
          {
            title: 'Xã Đồng Thắng',
            code: 15727,
            value: 'xa_dong_thang',
            division_type: 'xã',
            short_codename: 'dong_thang',
            parentId: 397,
          },
          {
            title: 'Xã Tiến Nông',
            code: 15730,
            value: 'xa_tien_nong',
            division_type: 'xã',
            short_codename: 'tien_nong',
            parentId: 397,
          },
          {
            title: 'Xã Khuyến Nông',
            code: 15733,
            value: 'xa_khuyen_nong',
            division_type: 'xã',
            short_codename: 'khuyen_nong',
            parentId: 397,
          },
          {
            title: 'Xã Xuân Thịnh',
            code: 15736,
            value: 'xa_xuan_thinh',
            division_type: 'xã',
            short_codename: 'xuan_thinh',
            parentId: 397,
          },
          {
            title: 'Xã Xuân Lộc',
            code: 15739,
            value: 'xa_xuan_loc',
            division_type: 'xã',
            short_codename: 'xuan_loc',
            parentId: 397,
          },
          {
            title: 'Xã Thọ Dân',
            code: 15742,
            value: 'xa_tho_dan',
            division_type: 'xã',
            short_codename: 'tho_dan',
            parentId: 397,
          },
          {
            title: 'Xã Xuân Thọ',
            code: 15745,
            value: 'xa_xuan_tho',
            division_type: 'xã',
            short_codename: 'xuan_tho',
            parentId: 397,
          },
          {
            title: 'Xã Thọ Tân',
            code: 15748,
            value: 'xa_tho_tan',
            division_type: 'xã',
            short_codename: 'tho_tan',
            parentId: 397,
          },
          {
            title: 'Xã Thọ Ngọc',
            code: 15751,
            value: 'xa_tho_ngoc',
            division_type: 'xã',
            short_codename: 'tho_ngoc',
            parentId: 397,
          },
          {
            title: 'Xã Thọ Cường',
            code: 15754,
            value: 'xa_tho_cuong',
            division_type: 'xã',
            short_codename: 'tho_cuong',
            parentId: 397,
          },
          {
            title: 'Xã Thọ Phú',
            code: 15757,
            value: 'xa_tho_phu',
            division_type: 'xã',
            short_codename: 'tho_phu',
            parentId: 397,
          },
          {
            title: 'Xã Thọ Vực',
            code: 15760,
            value: 'xa_tho_vuc',
            division_type: 'xã',
            short_codename: 'tho_vuc',
            parentId: 397,
          },
          {
            title: 'Xã Thọ Thế',
            code: 15763,
            value: 'xa_tho_the',
            division_type: 'xã',
            short_codename: 'tho_the',
            parentId: 397,
          },
          {
            title: 'Xã Nông Trường',
            code: 15766,
            value: 'xa_nong_truong',
            division_type: 'xã',
            short_codename: 'nong_truong',
            parentId: 397,
          },
          {
            title: 'Xã Bình Sơn',
            code: 15769,
            value: 'xa_binh_son',
            division_type: 'xã',
            short_codename: 'binh_son',
            parentId: 397,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Huyện Thiệu Hóa',
        code: 398,
        value: 'huyen_thieu_hoa',
        division_type: 'huyện',
        short_codename: 'thieu_hoa',
        children: [
          {
            title: 'Thị trấn Thiệu Hóa',
            code: 15772,
            value: 'thi_tran_thieu_hoa',
            division_type: 'thị trấn',
            short_codename: 'thieu_hoa',
            parentId: 398,
          },
          {
            title: 'Xã Thiệu Ngọc',
            code: 15775,
            value: 'xa_thieu_ngoc',
            division_type: 'xã',
            short_codename: 'thieu_ngoc',
            parentId: 398,
          },
          {
            title: 'Xã Thiệu Vũ',
            code: 15778,
            value: 'xa_thieu_vu',
            division_type: 'xã',
            short_codename: 'thieu_vu',
            parentId: 398,
          },
          {
            title: 'Xã Thiệu Phúc',
            code: 15781,
            value: 'xa_thieu_phuc',
            division_type: 'xã',
            short_codename: 'thieu_phuc',
            parentId: 398,
          },
          {
            title: 'Xã Thiệu Tiến',
            code: 15784,
            value: 'xa_thieu_tien',
            division_type: 'xã',
            short_codename: 'thieu_tien',
            parentId: 398,
          },
          {
            title: 'Xã Thiệu Công',
            code: 15787,
            value: 'xa_thieu_cong',
            division_type: 'xã',
            short_codename: 'thieu_cong',
            parentId: 398,
          },
          {
            title: 'Xã Thiệu Phú',
            code: 15790,
            value: 'xa_thieu_phu',
            division_type: 'xã',
            short_codename: 'thieu_phu',
            parentId: 398,
          },
          {
            title: 'Xã Thiệu Long',
            code: 15793,
            value: 'xa_thieu_long',
            division_type: 'xã',
            short_codename: 'thieu_long',
            parentId: 398,
          },
          {
            title: 'Xã Thiệu Giang',
            code: 15796,
            value: 'xa_thieu_giang',
            division_type: 'xã',
            short_codename: 'thieu_giang',
            parentId: 398,
          },
          {
            title: 'Xã Thiệu Duy',
            code: 15799,
            value: 'xa_thieu_duy',
            division_type: 'xã',
            short_codename: 'thieu_duy',
            parentId: 398,
          },
          {
            title: 'Xã Thiệu Nguyên',
            code: 15802,
            value: 'xa_thieu_nguyen',
            division_type: 'xã',
            short_codename: 'thieu_nguyen',
            parentId: 398,
          },
          {
            title: 'Xã Thiệu Hợp',
            code: 15805,
            value: 'xa_thieu_hop',
            division_type: 'xã',
            short_codename: 'thieu_hop',
            parentId: 398,
          },
          {
            title: 'Xã Thiệu Thịnh',
            code: 15808,
            value: 'xa_thieu_thinh',
            division_type: 'xã',
            short_codename: 'thieu_thinh',
            parentId: 398,
          },
          {
            title: 'Xã Thiệu Quang',
            code: 15811,
            value: 'xa_thieu_quang',
            division_type: 'xã',
            short_codename: 'thieu_quang',
            parentId: 398,
          },
          {
            title: 'Xã Thiệu Thành',
            code: 15814,
            value: 'xa_thieu_thanh',
            division_type: 'xã',
            short_codename: 'thieu_thanh',
            parentId: 398,
          },
          {
            title: 'Xã Thiệu Toán',
            code: 15817,
            value: 'xa_thieu_toan',
            division_type: 'xã',
            short_codename: 'thieu_toan',
            parentId: 398,
          },
          {
            title: 'Xã Thiệu Chính',
            code: 15820,
            value: 'xa_thieu_chinh',
            division_type: 'xã',
            short_codename: 'thieu_chinh',
            parentId: 398,
          },
          {
            title: 'Xã Thiệu Hòa',
            code: 15823,
            value: 'xa_thieu_hoa',
            division_type: 'xã',
            short_codename: 'xa_thieu_hoa',
            parentId: 398,
          },
          {
            title: 'Xã Minh Tâm',
            code: 15829,
            value: 'xa_minh_tam',
            division_type: 'xã',
            short_codename: 'minh_tam',
            parentId: 398,
          },
          {
            title: 'Xã Thiệu Viên',
            code: 15832,
            value: 'xa_thieu_vien',
            division_type: 'xã',
            short_codename: 'thieu_vien',
            parentId: 398,
          },
          {
            title: 'Xã Thiệu Lý',
            code: 15835,
            value: 'xa_thieu_ly',
            division_type: 'xã',
            short_codename: 'thieu_ly',
            parentId: 398,
          },
          {
            title: 'Xã Thiệu Vận',
            code: 15838,
            value: 'xa_thieu_van',
            division_type: 'xã',
            short_codename: 'thieu_van',
            parentId: 398,
          },
          {
            title: 'Xã Thiệu Trung',
            code: 15841,
            value: 'xa_thieu_trung',
            division_type: 'xã',
            short_codename: 'thieu_trung',
            parentId: 398,
          },
          {
            title: 'Xã Tân Châu',
            code: 15847,
            value: 'xa_tan_chau',
            division_type: 'xã',
            short_codename: 'tan_chau',
            parentId: 398,
          },
          {
            title: 'Xã Thiệu Giao',
            code: 15853,
            value: 'xa_thieu_giao',
            division_type: 'xã',
            short_codename: 'thieu_giao',
            parentId: 398,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Huyện Hoằng Hóa',
        code: 399,
        value: 'huyen_hoang_hoa',
        division_type: 'huyện',
        short_codename: 'hoang_hoa',
        children: [
          {
            title: 'Thị trấn Bút Sơn',
            code: 15865,
            value: 'thi_tran_but_son',
            division_type: 'thị trấn',
            short_codename: 'but_son',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Giang',
            code: 15871,
            value: 'xa_hoang_giang',
            division_type: 'xã',
            short_codename: 'hoang_giang',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Xuân',
            code: 15877,
            value: 'xa_hoang_xuan',
            division_type: 'xã',
            short_codename: 'hoang_xuan',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Phượng',
            code: 15880,
            value: 'xa_hoang_phuong',
            division_type: 'xã',
            short_codename: 'hoang_phuong',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Phú',
            code: 15883,
            value: 'xa_hoang_phu',
            division_type: 'xã',
            short_codename: 'xa_hoang_phu',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Quỳ',
            code: 15886,
            value: 'xa_hoang_quy',
            division_type: 'xã',
            short_codename: 'xa_hoang_quy',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Kim',
            code: 15889,
            value: 'xa_hoang_kim',
            division_type: 'xã',
            short_codename: 'hoang_kim',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Trung',
            code: 15892,
            value: 'xa_hoang_trung',
            division_type: 'xã',
            short_codename: 'hoang_trung',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Trinh',
            code: 15895,
            value: 'xa_hoang_trinh',
            division_type: 'xã',
            short_codename: 'hoang_trinh',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Sơn',
            code: 15901,
            value: 'xa_hoang_son',
            division_type: 'xã',
            short_codename: 'hoang_son',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Cát',
            code: 15907,
            value: 'xa_hoang_cat',
            division_type: 'xã',
            short_codename: 'hoang_cat',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Xuyên',
            code: 15910,
            value: 'xa_hoang_xuyen',
            division_type: 'xã',
            short_codename: 'hoang_xuyen',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Quý',
            code: 15916,
            value: 'xa_hoang_quy',
            division_type: 'xã',
            short_codename: 'xa_hoang_quy',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Hợp',
            code: 15919,
            value: 'xa_hoang_hop',
            division_type: 'xã',
            short_codename: 'hoang_hop',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Đức',
            code: 15928,
            value: 'xa_hoang_duc',
            division_type: 'xã',
            short_codename: 'hoang_duc',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Hà',
            code: 15937,
            value: 'xa_hoang_ha',
            division_type: 'xã',
            short_codename: 'hoang_ha',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Đạt',
            code: 15940,
            value: 'xa_hoang_dat',
            division_type: 'xã',
            short_codename: 'hoang_dat',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Đạo',
            code: 15946,
            value: 'xa_hoang_dao',
            division_type: 'xã',
            short_codename: 'hoang_dao',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Thắng',
            code: 15949,
            value: 'xa_hoang_thang',
            division_type: 'xã',
            short_codename: 'hoang_thang',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Đồng',
            code: 15952,
            value: 'xa_hoang_dong',
            division_type: 'xã',
            short_codename: 'xa_hoang_dong',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Thái',
            code: 15955,
            value: 'xa_hoang_thai',
            division_type: 'xã',
            short_codename: 'hoang_thai',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Thịnh',
            code: 15958,
            value: 'xa_hoang_thinh',
            division_type: 'xã',
            short_codename: 'hoang_thinh',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Thành',
            code: 15961,
            value: 'xa_hoang_thanh',
            division_type: 'xã',
            short_codename: 'xa_hoang_thanh',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Lộc',
            code: 15964,
            value: 'xa_hoang_loc',
            division_type: 'xã',
            short_codename: 'hoang_loc',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Trạch',
            code: 15967,
            value: 'xa_hoang_trach',
            division_type: 'xã',
            short_codename: 'hoang_trach',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Phong',
            code: 15973,
            value: 'xa_hoang_phong',
            division_type: 'xã',
            short_codename: 'hoang_phong',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Lưu',
            code: 15976,
            value: 'xa_hoang_luu',
            division_type: 'xã',
            short_codename: 'hoang_luu',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Châu',
            code: 15979,
            value: 'xa_hoang_chau',
            division_type: 'xã',
            short_codename: 'hoang_chau',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Tân',
            code: 15982,
            value: 'xa_hoang_tan',
            division_type: 'xã',
            short_codename: 'hoang_tan',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Yến',
            code: 15985,
            value: 'xa_hoang_yen',
            division_type: 'xã',
            short_codename: 'hoang_yen',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Tiến',
            code: 15988,
            value: 'xa_hoang_tien',
            division_type: 'xã',
            short_codename: 'hoang_tien',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Hải',
            code: 15991,
            value: 'xa_hoang_hai',
            division_type: 'xã',
            short_codename: 'hoang_hai',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Ngọc',
            code: 15994,
            value: 'xa_hoang_ngoc',
            division_type: 'xã',
            short_codename: 'hoang_ngoc',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Đông',
            code: 15997,
            value: 'xa_hoang_dong',
            division_type: 'xã',
            short_codename: 'xa_hoang_dong',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Thanh',
            code: 16000,
            value: 'xa_hoang_thanh',
            division_type: 'xã',
            short_codename: 'xa_hoang_thanh',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Phụ',
            code: 16003,
            value: 'xa_hoang_phu',
            division_type: 'xã',
            short_codename: 'xa_hoang_phu',
            parentId: 399,
          },
          {
            title: 'Xã Hoằng Trường',
            code: 16006,
            value: 'xa_hoang_truong',
            division_type: 'xã',
            short_codename: 'hoang_truong',
            parentId: 399,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Huyện Hậu Lộc',
        code: 400,
        value: 'huyen_hau_loc',
        division_type: 'huyện',
        short_codename: 'hau_loc',
        children: [
          {
            title: 'Thị trấn Hậu Lộc',
            code: 16012,
            value: 'thi_tran_hau_loc',
            division_type: 'thị trấn',
            short_codename: 'hau_loc',
            parentId: 400,
          },
          {
            title: 'Xã Đồng Lộc',
            code: 16015,
            value: 'xa_dong_loc',
            division_type: 'xã',
            short_codename: 'dong_loc',
            parentId: 400,
          },
          {
            title: 'Xã Đại Lộc',
            code: 16018,
            value: 'xa_dai_loc',
            division_type: 'xã',
            short_codename: 'dai_loc',
            parentId: 400,
          },
          {
            title: 'Xã Triệu Lộc',
            code: 16021,
            value: 'xa_trieu_loc',
            division_type: 'xã',
            short_codename: 'trieu_loc',
            parentId: 400,
          },
          {
            title: 'Xã Tiến Lộc',
            code: 16027,
            value: 'xa_tien_loc',
            division_type: 'xã',
            short_codename: 'tien_loc',
            parentId: 400,
          },
          {
            title: 'Xã Lộc Sơn',
            code: 16030,
            value: 'xa_loc_son',
            division_type: 'xã',
            short_codename: 'loc_son',
            parentId: 400,
          },
          {
            title: 'Xã Cầu Lộc',
            code: 16033,
            value: 'xa_cau_loc',
            division_type: 'xã',
            short_codename: 'cau_loc',
            parentId: 400,
          },
          {
            title: 'Xã Thành Lộc',
            code: 16036,
            value: 'xa_thanh_loc',
            division_type: 'xã',
            short_codename: 'thanh_loc',
            parentId: 400,
          },
          {
            title: 'Xã Tuy Lộc',
            code: 16039,
            value: 'xa_tuy_loc',
            division_type: 'xã',
            short_codename: 'tuy_loc',
            parentId: 400,
          },
          {
            title: 'Xã Phong Lộc',
            code: 16042,
            value: 'xa_phong_loc',
            division_type: 'xã',
            short_codename: 'phong_loc',
            parentId: 400,
          },
          {
            title: 'Xã Mỹ Lộc',
            code: 16045,
            value: 'xa_my_loc',
            division_type: 'xã',
            short_codename: 'my_loc',
            parentId: 400,
          },
          {
            title: 'Xã Thuần Lộc',
            code: 16048,
            value: 'xa_thuan_loc',
            division_type: 'xã',
            short_codename: 'thuan_loc',
            parentId: 400,
          },
          {
            title: 'Xã Xuân Lộc',
            code: 16057,
            value: 'xa_xuan_loc',
            division_type: 'xã',
            short_codename: 'xuan_loc',
            parentId: 400,
          },
          {
            title: 'Xã Hoa Lộc',
            code: 16063,
            value: 'xa_hoa_loc',
            division_type: 'xã',
            short_codename: 'xa_hoa_loc',
            parentId: 400,
          },
          {
            title: 'Xã Liên Lộc',
            code: 16066,
            value: 'xa_lien_loc',
            division_type: 'xã',
            short_codename: 'lien_loc',
            parentId: 400,
          },
          {
            title: 'Xã Quang Lộc',
            code: 16069,
            value: 'xa_quang_loc',
            division_type: 'xã',
            short_codename: 'quang_loc',
            parentId: 400,
          },
          {
            title: 'Xã Phú Lộc',
            code: 16072,
            value: 'xa_phu_loc',
            division_type: 'xã',
            short_codename: 'phu_loc',
            parentId: 400,
          },
          {
            title: 'Xã Hòa Lộc',
            code: 16075,
            value: 'xa_hoa_loc',
            division_type: 'xã',
            short_codename: 'xa_hoa_loc',
            parentId: 400,
          },
          {
            title: 'Xã Minh Lộc',
            code: 16078,
            value: 'xa_minh_loc',
            division_type: 'xã',
            short_codename: 'minh_loc',
            parentId: 400,
          },
          {
            title: 'Xã Hưng Lộc',
            code: 16081,
            value: 'xa_hung_loc',
            division_type: 'xã',
            short_codename: 'hung_loc',
            parentId: 400,
          },
          {
            title: 'Xã Hải Lộc',
            code: 16084,
            value: 'xa_hai_loc',
            division_type: 'xã',
            short_codename: 'hai_loc',
            parentId: 400,
          },
          {
            title: 'Xã Đa Lộc',
            code: 16087,
            value: 'xa_da_loc',
            division_type: 'xã',
            short_codename: 'da_loc',
            parentId: 400,
          },
          {
            title: 'Xã Ngư Lộc',
            code: 16090,
            value: 'xa_ngu_loc',
            division_type: 'xã',
            short_codename: 'ngu_loc',
            parentId: 400,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Huyện Nga Sơn',
        code: 401,
        value: 'huyen_nga_son',
        division_type: 'huyện',
        short_codename: 'nga_son',
        children: [
          {
            title: 'Thị trấn Nga Sơn',
            code: 16093,
            value: 'thi_tran_nga_son',
            division_type: 'thị trấn',
            short_codename: 'nga_son',
            parentId: 401,
          },
          {
            title: 'Xã Ba Đình',
            code: 16096,
            value: 'xa_ba_dinh',
            division_type: 'xã',
            short_codename: 'ba_dinh',
            parentId: 401,
          },
          {
            title: 'Xã Nga Vịnh',
            code: 16099,
            value: 'xa_nga_vinh',
            division_type: 'xã',
            short_codename: 'nga_vinh',
            parentId: 401,
          },
          {
            title: 'Xã Nga Văn',
            code: 16102,
            value: 'xa_nga_van',
            division_type: 'xã',
            short_codename: 'nga_van',
            parentId: 401,
          },
          {
            title: 'Xã Nga Thiện',
            code: 16105,
            value: 'xa_nga_thien',
            division_type: 'xã',
            short_codename: 'nga_thien',
            parentId: 401,
          },
          {
            title: 'Xã Nga Tiến',
            code: 16108,
            value: 'xa_nga_tien',
            division_type: 'xã',
            short_codename: 'nga_tien',
            parentId: 401,
          },
          {
            title: 'Xã Nga Phượng',
            code: 16114,
            value: 'xa_nga_phuong',
            division_type: 'xã',
            short_codename: 'nga_phuong',
            parentId: 401,
          },
          {
            title: 'Xã Nga Trung',
            code: 16117,
            value: 'xa_nga_trung',
            division_type: 'xã',
            short_codename: 'nga_trung',
            parentId: 401,
          },
          {
            title: 'Xã Nga Bạch',
            code: 16120,
            value: 'xa_nga_bach',
            division_type: 'xã',
            short_codename: 'nga_bach',
            parentId: 401,
          },
          {
            title: 'Xã Nga Thanh',
            code: 16123,
            value: 'xa_nga_thanh',
            division_type: 'xã',
            short_codename: 'xa_nga_thanh',
            parentId: 401,
          },
          {
            title: 'Xã Nga Yên',
            code: 16132,
            value: 'xa_nga_yen',
            division_type: 'xã',
            short_codename: 'nga_yen',
            parentId: 401,
          },
          {
            title: 'Xã Nga Giáp',
            code: 16135,
            value: 'xa_nga_giap',
            division_type: 'xã',
            short_codename: 'nga_giap',
            parentId: 401,
          },
          {
            title: 'Xã Nga Hải',
            code: 16138,
            value: 'xa_nga_hai',
            division_type: 'xã',
            short_codename: 'nga_hai',
            parentId: 401,
          },
          {
            title: 'Xã Nga Thành',
            code: 16141,
            value: 'xa_nga_thanh',
            division_type: 'xã',
            short_codename: 'xa_nga_thanh',
            parentId: 401,
          },
          {
            title: 'Xã Nga An',
            code: 16144,
            value: 'xa_nga_an',
            division_type: 'xã',
            short_codename: 'nga_an',
            parentId: 401,
          },
          {
            title: 'Xã Nga Phú',
            code: 16147,
            value: 'xa_nga_phu',
            division_type: 'xã',
            short_codename: 'nga_phu',
            parentId: 401,
          },
          {
            title: 'Xã Nga Điền',
            code: 16150,
            value: 'xa_nga_dien',
            division_type: 'xã',
            short_codename: 'nga_dien',
            parentId: 401,
          },
          {
            title: 'Xã Nga Tân',
            code: 16153,
            value: 'xa_nga_tan',
            division_type: 'xã',
            short_codename: 'nga_tan',
            parentId: 401,
          },
          {
            title: 'Xã Nga Thủy',
            code: 16156,
            value: 'xa_nga_thuy',
            division_type: 'xã',
            short_codename: 'nga_thuy',
            parentId: 401,
          },
          {
            title: 'Xã Nga Liên',
            code: 16159,
            value: 'xa_nga_lien',
            division_type: 'xã',
            short_codename: 'nga_lien',
            parentId: 401,
          },
          {
            title: 'Xã Nga Thái',
            code: 16162,
            value: 'xa_nga_thai',
            division_type: 'xã',
            short_codename: 'nga_thai',
            parentId: 401,
          },
          {
            title: 'Xã Nga Thạch',
            code: 16165,
            value: 'xa_nga_thach',
            division_type: 'xã',
            short_codename: 'nga_thach',
            parentId: 401,
          },
          {
            title: 'Xã Nga Thắng',
            code: 16168,
            value: 'xa_nga_thang',
            division_type: 'xã',
            short_codename: 'nga_thang',
            parentId: 401,
          },
          {
            title: 'Xã Nga Trường',
            code: 16171,
            value: 'xa_nga_truong',
            division_type: 'xã',
            short_codename: 'nga_truong',
            parentId: 401,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Huyện Như Xuân',
        code: 402,
        value: 'huyen_nhu_xuan',
        division_type: 'huyện',
        short_codename: 'nhu_xuan',
        children: [
          {
            title: 'Thị trấn Yên Cát',
            code: 16174,
            value: 'thi_tran_yen_cat',
            division_type: 'thị trấn',
            short_codename: 'yen_cat',
            parentId: 402,
          },
          {
            title: 'Xã Bãi Trành',
            code: 16177,
            value: 'xa_bai_tranh',
            division_type: 'xã',
            short_codename: 'bai_tranh',
            parentId: 402,
          },
          {
            title: 'Xã Xuân Hòa',
            code: 16180,
            value: 'xa_xuan_hoa',
            division_type: 'xã',
            short_codename: 'xuan_hoa',
            parentId: 402,
          },
          {
            title: 'Xã Xuân Bình',
            code: 16183,
            value: 'xa_xuan_binh',
            division_type: 'xã',
            short_codename: 'xuan_binh',
            parentId: 402,
          },
          {
            title: 'Xã Hóa Quỳ',
            code: 16186,
            value: 'xa_hoa_quy',
            division_type: 'xã',
            short_codename: 'hoa_quy',
            parentId: 402,
          },
          {
            title: 'Xã Cát Vân',
            code: 16195,
            value: 'xa_cat_van',
            division_type: 'xã',
            short_codename: 'cat_van',
            parentId: 402,
          },
          {
            title: 'Xã Cát Tân',
            code: 16198,
            value: 'xa_cat_tan',
            division_type: 'xã',
            short_codename: 'cat_tan',
            parentId: 402,
          },
          {
            title: 'Xã Tân Bình',
            code: 16201,
            value: 'xa_tan_binh',
            division_type: 'xã',
            short_codename: 'tan_binh',
            parentId: 402,
          },
          {
            title: 'Xã Bình Lương',
            code: 16204,
            value: 'xa_binh_luong',
            division_type: 'xã',
            short_codename: 'binh_luong',
            parentId: 402,
          },
          {
            title: 'Xã Thanh Quân',
            code: 16207,
            value: 'xa_thanh_quan',
            division_type: 'xã',
            short_codename: 'thanh_quan',
            parentId: 402,
          },
          {
            title: 'Xã Thanh Xuân',
            code: 16210,
            value: 'xa_thanh_xuan',
            division_type: 'xã',
            short_codename: 'thanh_xuan',
            parentId: 402,
          },
          {
            title: 'Xã Thanh Hòa',
            code: 16213,
            value: 'xa_thanh_hoa',
            division_type: 'xã',
            short_codename: 'thanh_hoa',
            parentId: 402,
          },
          {
            title: 'Xã Thanh Phong',
            code: 16216,
            value: 'xa_thanh_phong',
            division_type: 'xã',
            short_codename: 'thanh_phong',
            parentId: 402,
          },
          {
            title: 'Xã Thanh Lâm',
            code: 16219,
            value: 'xa_thanh_lam',
            division_type: 'xã',
            short_codename: 'thanh_lam',
            parentId: 402,
          },
          {
            title: 'Xã Thanh Sơn',
            code: 16222,
            value: 'xa_thanh_son',
            division_type: 'xã',
            short_codename: 'thanh_son',
            parentId: 402,
          },
          {
            title: 'Xã Thượng Ninh',
            code: 16225,
            value: 'xa_thuong_ninh',
            division_type: 'xã',
            short_codename: 'thuong_ninh',
            parentId: 402,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Huyện Như Thanh',
        code: 403,
        value: 'huyen_nhu_thanh',
        division_type: 'huyện',
        short_codename: 'nhu_thanh',
        children: [
          {
            title: 'Thị trấn Bến Sung',
            code: 16228,
            value: 'thi_tran_ben_sung',
            division_type: 'thị trấn',
            short_codename: 'ben_sung',
            parentId: 403,
          },
          {
            title: 'Xã Cán Khê',
            code: 16231,
            value: 'xa_can_khe',
            division_type: 'xã',
            short_codename: 'can_khe',
            parentId: 403,
          },
          {
            title: 'Xã Xuân Du',
            code: 16234,
            value: 'xa_xuan_du',
            division_type: 'xã',
            short_codename: 'xuan_du',
            parentId: 403,
          },
          {
            title: 'Xã Phượng Nghi',
            code: 16240,
            value: 'xa_phuong_nghi',
            division_type: 'xã',
            short_codename: 'phuong_nghi',
            parentId: 403,
          },
          {
            title: 'Xã Mậu Lâm',
            code: 16243,
            value: 'xa_mau_lam',
            division_type: 'xã',
            short_codename: 'mau_lam',
            parentId: 403,
          },
          {
            title: 'Xã Xuân Khang',
            code: 16246,
            value: 'xa_xuan_khang',
            division_type: 'xã',
            short_codename: 'xuan_khang',
            parentId: 403,
          },
          {
            title: 'Xã Phú Nhuận',
            code: 16249,
            value: 'xa_phu_nhuan',
            division_type: 'xã',
            short_codename: 'phu_nhuan',
            parentId: 403,
          },
          {
            title: 'Xã Hải Long',
            code: 16252,
            value: 'xa_hai_long',
            division_type: 'xã',
            short_codename: 'hai_long',
            parentId: 403,
          },
          {
            title: 'Xã Xuân Thái',
            code: 16258,
            value: 'xa_xuan_thai',
            division_type: 'xã',
            short_codename: 'xuan_thai',
            parentId: 403,
          },
          {
            title: 'Xã Xuân Phúc',
            code: 16261,
            value: 'xa_xuan_phuc',
            division_type: 'xã',
            short_codename: 'xuan_phuc',
            parentId: 403,
          },
          {
            title: 'Xã Yên Thọ',
            code: 16264,
            value: 'xa_yen_tho',
            division_type: 'xã',
            short_codename: 'yen_tho',
            parentId: 403,
          },
          {
            title: 'Xã Yên Lạc',
            code: 16267,
            value: 'xa_yen_lac',
            division_type: 'xã',
            short_codename: 'yen_lac',
            parentId: 403,
          },
          {
            title: 'Xã Thanh Tân',
            code: 16273,
            value: 'xa_thanh_tan',
            division_type: 'xã',
            short_codename: 'thanh_tan',
            parentId: 403,
          },
          {
            title: 'Xã Thanh Kỳ',
            code: 16276,
            value: 'xa_thanh_ky',
            division_type: 'xã',
            short_codename: 'thanh_ky',
            parentId: 403,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Huyện Nông Cống',
        code: 404,
        value: 'huyen_nong_cong',
        division_type: 'huyện',
        short_codename: 'nong_cong',
        children: [
          {
            title: 'Thị trấn Nông Cống',
            code: 16279,
            value: 'thi_tran_nong_cong',
            division_type: 'thị trấn',
            short_codename: 'nong_cong',
            parentId: 404,
          },
          {
            title: 'Xã Tân Phúc',
            code: 16282,
            value: 'xa_tan_phuc',
            division_type: 'xã',
            short_codename: 'tan_phuc',
            parentId: 404,
          },
          {
            title: 'Xã Tân Thọ',
            code: 16285,
            value: 'xa_tan_tho',
            division_type: 'xã',
            short_codename: 'tan_tho',
            parentId: 404,
          },
          {
            title: 'Xã Hoàng Sơn',
            code: 16288,
            value: 'xa_hoang_son',
            division_type: 'xã',
            short_codename: 'hoang_son',
            parentId: 404,
          },
          {
            title: 'Xã Tân Khang',
            code: 16291,
            value: 'xa_tan_khang',
            division_type: 'xã',
            short_codename: 'tan_khang',
            parentId: 404,
          },
          {
            title: 'Xã Hoàng Giang',
            code: 16294,
            value: 'xa_hoang_giang',
            division_type: 'xã',
            short_codename: 'hoang_giang',
            parentId: 404,
          },
          {
            title: 'Xã Trung Chính',
            code: 16297,
            value: 'xa_trung_chinh',
            division_type: 'xã',
            short_codename: 'trung_chinh',
            parentId: 404,
          },
          {
            title: 'Xã Trung Thành',
            code: 16303,
            value: 'xa_trung_thanh',
            division_type: 'xã',
            short_codename: 'trung_thanh',
            parentId: 404,
          },
          {
            title: 'Xã Tế Thắng',
            code: 16309,
            value: 'xa_te_thang',
            division_type: 'xã',
            short_codename: 'te_thang',
            parentId: 404,
          },
          {
            title: 'Xã Tế Lợi',
            code: 16315,
            value: 'xa_te_loi',
            division_type: 'xã',
            short_codename: 'te_loi',
            parentId: 404,
          },
          {
            title: 'Xã Tế Nông',
            code: 16318,
            value: 'xa_te_nong',
            division_type: 'xã',
            short_codename: 'te_nong',
            parentId: 404,
          },
          {
            title: 'Xã Minh Nghĩa',
            code: 16321,
            value: 'xa_minh_nghia',
            division_type: 'xã',
            short_codename: 'minh_nghia',
            parentId: 404,
          },
          {
            title: 'Xã Minh Khôi',
            code: 16324,
            value: 'xa_minh_khoi',
            division_type: 'xã',
            short_codename: 'minh_khoi',
            parentId: 404,
          },
          {
            title: 'Xã Vạn Hòa',
            code: 16327,
            value: 'xa_van_hoa',
            division_type: 'xã',
            short_codename: 'van_hoa',
            parentId: 404,
          },
          {
            title: 'Xã Trường Trung',
            code: 16330,
            value: 'xa_truong_trung',
            division_type: 'xã',
            short_codename: 'truong_trung',
            parentId: 404,
          },
          {
            title: 'Xã Vạn Thắng',
            code: 16333,
            value: 'xa_van_thang',
            division_type: 'xã',
            short_codename: 'van_thang',
            parentId: 404,
          },
          {
            title: 'Xã Trường Giang',
            code: 16336,
            value: 'xa_truong_giang',
            division_type: 'xã',
            short_codename: 'truong_giang',
            parentId: 404,
          },
          {
            title: 'Xã Vạn Thiện',
            code: 16339,
            value: 'xa_van_thien',
            division_type: 'xã',
            short_codename: 'van_thien',
            parentId: 404,
          },
          {
            title: 'Xã Thăng Long',
            code: 16342,
            value: 'xa_thang_long',
            division_type: 'xã',
            short_codename: 'thang_long',
            parentId: 404,
          },
          {
            title: 'Xã Trường Minh',
            code: 16345,
            value: 'xa_truong_minh',
            division_type: 'xã',
            short_codename: 'truong_minh',
            parentId: 404,
          },
          {
            title: 'Xã Trường Sơn',
            code: 16348,
            value: 'xa_truong_son',
            division_type: 'xã',
            short_codename: 'truong_son',
            parentId: 404,
          },
          {
            title: 'Xã Thăng Bình',
            code: 16351,
            value: 'xa_thang_binh',
            division_type: 'xã',
            short_codename: 'thang_binh',
            parentId: 404,
          },
          {
            title: 'Xã Công Liêm',
            code: 16354,
            value: 'xa_cong_liem',
            division_type: 'xã',
            short_codename: 'cong_liem',
            parentId: 404,
          },
          {
            title: 'Xã Tượng Văn',
            code: 16357,
            value: 'xa_tuong_van',
            division_type: 'xã',
            short_codename: 'tuong_van',
            parentId: 404,
          },
          {
            title: 'Xã Thăng Thọ',
            code: 16360,
            value: 'xa_thang_tho',
            division_type: 'xã',
            short_codename: 'thang_tho',
            parentId: 404,
          },
          {
            title: 'Xã Tượng Lĩnh',
            code: 16363,
            value: 'xa_tuong_linh',
            division_type: 'xã',
            short_codename: 'tuong_linh',
            parentId: 404,
          },
          {
            title: 'Xã Tượng Sơn',
            code: 16366,
            value: 'xa_tuong_son',
            division_type: 'xã',
            short_codename: 'tuong_son',
            parentId: 404,
          },
          {
            title: 'Xã Công Chính',
            code: 16369,
            value: 'xa_cong_chinh',
            division_type: 'xã',
            short_codename: 'cong_chinh',
            parentId: 404,
          },
          {
            title: 'Xã Yên Mỹ',
            code: 16375,
            value: 'xa_yen_my',
            division_type: 'xã',
            short_codename: 'yen_my',
            parentId: 404,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Huyện Đông Sơn',
        code: 405,
        value: 'huyen_dong_son',
        division_type: 'huyện',
        short_codename: 'dong_son',
        children: [
          {
            title: 'Thị trấn Rừng Thông',
            code: 16378,
            value: 'thi_tran_rung_thong',
            division_type: 'thị trấn',
            short_codename: 'rung_thong',
            parentId: 405,
          },
          {
            title: 'Xã Đông Hoàng',
            code: 16381,
            value: 'xa_dong_hoang',
            division_type: 'xã',
            short_codename: 'dong_hoang',
            parentId: 405,
          },
          {
            title: 'Xã Đông Ninh',
            code: 16384,
            value: 'xa_dong_ninh',
            division_type: 'xã',
            short_codename: 'dong_ninh',
            parentId: 405,
          },
          {
            title: 'Xã Đông Hòa',
            code: 16390,
            value: 'xa_dong_hoa',
            division_type: 'xã',
            short_codename: 'dong_hoa',
            parentId: 405,
          },
          {
            title: 'Xã Đông Yên',
            code: 16393,
            value: 'xa_dong_yen',
            division_type: 'xã',
            short_codename: 'dong_yen',
            parentId: 405,
          },
          {
            title: 'Xã Đông Minh',
            code: 16399,
            value: 'xa_dong_minh',
            division_type: 'xã',
            short_codename: 'dong_minh',
            parentId: 405,
          },
          {
            title: 'Xã Đông Thanh',
            code: 16402,
            value: 'xa_dong_thanh',
            division_type: 'xã',
            short_codename: 'dong_thanh',
            parentId: 405,
          },
          {
            title: 'Xã Đông Tiến',
            code: 16405,
            value: 'xa_dong_tien',
            division_type: 'xã',
            short_codename: 'dong_tien',
            parentId: 405,
          },
          {
            title: 'Xã Đông Khê',
            code: 16408,
            value: 'xa_dong_khe',
            division_type: 'xã',
            short_codename: 'dong_khe',
            parentId: 405,
          },
          {
            title: 'Xã Đông Thịnh',
            code: 16414,
            value: 'xa_dong_thinh',
            division_type: 'xã',
            short_codename: 'dong_thinh',
            parentId: 405,
          },
          {
            title: 'Xã Đông Văn',
            code: 16417,
            value: 'xa_dong_van',
            division_type: 'xã',
            short_codename: 'dong_van',
            parentId: 405,
          },
          {
            title: 'Xã Đông Phú',
            code: 16420,
            value: 'xa_dong_phu',
            division_type: 'xã',
            short_codename: 'dong_phu',
            parentId: 405,
          },
          {
            title: 'Xã Đông Nam',
            code: 16423,
            value: 'xa_dong_nam',
            division_type: 'xã',
            short_codename: 'dong_nam',
            parentId: 405,
          },
          {
            title: 'Xã Đông Quang',
            code: 16426,
            value: 'xa_dong_quang',
            division_type: 'xã',
            short_codename: 'dong_quang',
            parentId: 405,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Huyện Quảng Xương',
        code: 406,
        value: 'huyen_quang_xuong',
        division_type: 'huyện',
        short_codename: 'quang_xuong',
        children: [
          {
            title: 'Thị trấn Tân Phong',
            code: 16438,
            value: 'thi_tran_tan_phong',
            division_type: 'thị trấn',
            short_codename: 'tan_phong',
            parentId: 406,
          },
          {
            title: 'Xã Quảng Trạch',
            code: 16447,
            value: 'xa_quang_trach',
            division_type: 'xã',
            short_codename: 'quang_trach',
            parentId: 406,
          },
          {
            title: 'Xã Quảng Đức',
            code: 16453,
            value: 'xa_quang_duc',
            division_type: 'xã',
            short_codename: 'quang_duc',
            parentId: 406,
          },
          {
            title: 'Xã Quảng Định',
            code: 16456,
            value: 'xa_quang_dinh',
            division_type: 'xã',
            short_codename: 'quang_dinh',
            parentId: 406,
          },
          {
            title: 'Xã Quảng Nhân',
            code: 16462,
            value: 'xa_quang_nhan',
            division_type: 'xã',
            short_codename: 'quang_nhan',
            parentId: 406,
          },
          {
            title: 'Xã Quảng Ninh',
            code: 16465,
            value: 'xa_quang_ninh',
            division_type: 'xã',
            short_codename: 'quang_ninh',
            parentId: 406,
          },
          {
            title: 'Xã Quảng Bình',
            code: 16468,
            value: 'xa_quang_binh',
            division_type: 'xã',
            short_codename: 'quang_binh',
            parentId: 406,
          },
          {
            title: 'Xã Quảng Hợp',
            code: 16471,
            value: 'xa_quang_hop',
            division_type: 'xã',
            short_codename: 'quang_hop',
            parentId: 406,
          },
          {
            title: 'Xã Quảng Văn',
            code: 16474,
            value: 'xa_quang_van',
            division_type: 'xã',
            short_codename: 'quang_van',
            parentId: 406,
          },
          {
            title: 'Xã Quảng Long',
            code: 16477,
            value: 'xa_quang_long',
            division_type: 'xã',
            short_codename: 'quang_long',
            parentId: 406,
          },
          {
            title: 'Xã Quảng Yên',
            code: 16480,
            value: 'xa_quang_yen',
            division_type: 'xã',
            short_codename: 'quang_yen',
            parentId: 406,
          },
          {
            title: 'Xã Quảng Hòa',
            code: 16483,
            value: 'xa_quang_hoa',
            division_type: 'xã',
            short_codename: 'quang_hoa',
            parentId: 406,
          },
          {
            title: 'Xã Quảng Khê',
            code: 16489,
            value: 'xa_quang_khe',
            division_type: 'xã',
            short_codename: 'quang_khe',
            parentId: 406,
          },
          {
            title: 'Xã Quảng Trung',
            code: 16492,
            value: 'xa_quang_trung',
            division_type: 'xã',
            short_codename: 'quang_trung',
            parentId: 406,
          },
          {
            title: 'Xã Quảng Chính',
            code: 16495,
            value: 'xa_quang_chinh',
            division_type: 'xã',
            short_codename: 'quang_chinh',
            parentId: 406,
          },
          {
            title: 'Xã Quảng Ngọc',
            code: 16498,
            value: 'xa_quang_ngoc',
            division_type: 'xã',
            short_codename: 'quang_ngoc',
            parentId: 406,
          },
          {
            title: 'Xã Quảng Trường',
            code: 16501,
            value: 'xa_quang_truong',
            division_type: 'xã',
            short_codename: 'quang_truong',
            parentId: 406,
          },
          {
            title: 'Xã Quảng Phúc',
            code: 16510,
            value: 'xa_quang_phuc',
            division_type: 'xã',
            short_codename: 'quang_phuc',
            parentId: 406,
          },
          {
            title: 'Xã Quảng Giao',
            code: 16519,
            value: 'xa_quang_giao',
            division_type: 'xã',
            short_codename: 'quang_giao',
            parentId: 406,
          },
          {
            title: 'Xã Quảng Hải',
            code: 16540,
            value: 'xa_quang_hai',
            division_type: 'xã',
            short_codename: 'quang_hai',
            parentId: 406,
          },
          {
            title: 'Xã Quảng Lưu',
            code: 16543,
            value: 'xa_quang_luu',
            division_type: 'xã',
            short_codename: 'quang_luu',
            parentId: 406,
          },
          {
            title: 'Xã Quảng Lộc',
            code: 16546,
            value: 'xa_quang_loc',
            division_type: 'xã',
            short_codename: 'quang_loc',
            parentId: 406,
          },
          {
            title: 'Xã Tiên Trang',
            code: 16549,
            value: 'xa_tien_trang',
            division_type: 'xã',
            short_codename: 'tien_trang',
            parentId: 406,
          },
          {
            title: 'Xã Quảng Nham',
            code: 16552,
            value: 'xa_quang_nham',
            division_type: 'xã',
            short_codename: 'quang_nham',
            parentId: 406,
          },
          {
            title: 'Xã Quảng Thạch',
            code: 16555,
            value: 'xa_quang_thach',
            division_type: 'xã',
            short_codename: 'quang_thach',
            parentId: 406,
          },
          {
            title: 'Xã Quảng Thái',
            code: 16558,
            value: 'xa_quang_thai',
            division_type: 'xã',
            short_codename: 'quang_thai',
            parentId: 406,
          },
        ],
        parentId: 38,
      },
      {
        title: 'Thị xã Nghi Sơn',
        code: 407,
        value: 'thi_xa_nghi_son',
        division_type: 'thị xã',
        short_codename: 'nghi_son',
        children: [
          {
            title: 'Phường Hải Hòa',
            code: 16561,
            value: 'phuong_hai_hoa',
            division_type: 'phường',
            short_codename: 'hai_hoa',
            parentId: 407,
          },
          {
            title: 'Phường Hải Châu',
            code: 16564,
            value: 'phuong_hai_chau',
            division_type: 'phường',
            short_codename: 'hai_chau',
            parentId: 407,
          },
          {
            title: 'Xã Thanh Thủy',
            code: 16567,
            value: 'xa_thanh_thuy',
            division_type: 'xã',
            short_codename: 'thanh_thuy',
            parentId: 407,
          },
          {
            title: 'Xã Thanh Sơn',
            code: 16570,
            value: 'xa_thanh_son',
            division_type: 'xã',
            short_codename: 'thanh_son',
            parentId: 407,
          },
          {
            title: 'Phường Hải Ninh',
            code: 16576,
            value: 'phuong_hai_ninh',
            division_type: 'phường',
            short_codename: 'hai_ninh',
            parentId: 407,
          },
          {
            title: 'Xã Anh Sơn',
            code: 16579,
            value: 'xa_anh_son',
            division_type: 'xã',
            short_codename: 'anh_son',
            parentId: 407,
          },
          {
            title: 'Xã Ngọc Lĩnh',
            code: 16582,
            value: 'xa_ngoc_linh',
            division_type: 'xã',
            short_codename: 'ngoc_linh',
            parentId: 407,
          },
          {
            title: 'Phường Hải An',
            code: 16585,
            value: 'phuong_hai_an',
            division_type: 'phường',
            short_codename: 'hai_an',
            parentId: 407,
          },
          {
            title: 'Xã Các Sơn',
            code: 16591,
            value: 'xa_cac_son',
            division_type: 'xã',
            short_codename: 'cac_son',
            parentId: 407,
          },
          {
            title: 'Phường Tân Dân',
            code: 16594,
            value: 'phuong_tan_dan',
            division_type: 'phường',
            short_codename: 'tan_dan',
            parentId: 407,
          },
          {
            title: 'Phường Hải Lĩnh',
            code: 16597,
            value: 'phuong_hai_linh',
            division_type: 'phường',
            short_codename: 'hai_linh',
            parentId: 407,
          },
          {
            title: 'Xã Định Hải',
            code: 16600,
            value: 'xa_dinh_hai',
            division_type: 'xã',
            short_codename: 'dinh_hai',
            parentId: 407,
          },
          {
            title: 'Xã Phú Sơn',
            code: 16603,
            value: 'xa_phu_son',
            division_type: 'xã',
            short_codename: 'phu_son',
            parentId: 407,
          },
          {
            title: 'Phường Ninh Hải',
            code: 16606,
            value: 'phuong_ninh_hai',
            division_type: 'phường',
            short_codename: 'ninh_hai',
            parentId: 407,
          },
          {
            title: 'Phường Nguyên Bình',
            code: 16609,
            value: 'phuong_nguyen_binh',
            division_type: 'phường',
            short_codename: 'nguyen_binh',
            parentId: 407,
          },
          {
            title: 'Xã Hải Nhân',
            code: 16612,
            value: 'xa_hai_nhan',
            division_type: 'xã',
            short_codename: 'hai_nhan',
            parentId: 407,
          },
          {
            title: 'Phường Bình Minh',
            code: 16618,
            value: 'phuong_binh_minh',
            division_type: 'phường',
            short_codename: 'binh_minh',
            parentId: 407,
          },
          {
            title: 'Phường Hải Thanh',
            code: 16621,
            value: 'phuong_hai_thanh',
            division_type: 'phường',
            short_codename: 'hai_thanh',
            parentId: 407,
          },
          {
            title: 'Xã Phú Lâm',
            code: 16624,
            value: 'xa_phu_lam',
            division_type: 'xã',
            short_codename: 'phu_lam',
            parentId: 407,
          },
          {
            title: 'Phường Xuân Lâm',
            code: 16627,
            value: 'phuong_xuan_lam',
            division_type: 'phường',
            short_codename: 'xuan_lam',
            parentId: 407,
          },
          {
            title: 'Phường Trúc Lâm',
            code: 16630,
            value: 'phuong_truc_lam',
            division_type: 'phường',
            short_codename: 'truc_lam',
            parentId: 407,
          },
          {
            title: 'Phường Hải Bình',
            code: 16633,
            value: 'phuong_hai_binh',
            division_type: 'phường',
            short_codename: 'hai_binh',
            parentId: 407,
          },
          {
            title: 'Xã Tân Trường',
            code: 16636,
            value: 'xa_tan_truong',
            division_type: 'xã',
            short_codename: 'tan_truong',
            parentId: 407,
          },
          {
            title: 'Xã Tùng Lâm',
            code: 16639,
            value: 'xa_tung_lam',
            division_type: 'xã',
            short_codename: 'tung_lam',
            parentId: 407,
          },
          {
            title: 'Phường Tĩnh Hải',
            code: 16642,
            value: 'phuong_tinh_hai',
            division_type: 'phường',
            short_codename: 'tinh_hai',
            parentId: 407,
          },
          {
            title: 'Phường Mai Lâm',
            code: 16645,
            value: 'phuong_mai_lam',
            division_type: 'phường',
            short_codename: 'mai_lam',
            parentId: 407,
          },
          {
            title: 'Xã Trường Lâm',
            code: 16648,
            value: 'xa_truong_lam',
            division_type: 'xã',
            short_codename: 'truong_lam',
            parentId: 407,
          },
          {
            title: 'Xã Hải Yến',
            code: 16651,
            value: 'xa_hai_yen',
            division_type: 'xã',
            short_codename: 'hai_yen',
            parentId: 407,
          },
          {
            title: 'Phường Hải Thượng',
            code: 16654,
            value: 'phuong_hai_thuong',
            division_type: 'phường',
            short_codename: 'hai_thuong',
            parentId: 407,
          },
          {
            title: 'Xã Nghi Sơn',
            code: 16657,
            value: 'xa_nghi_son',
            division_type: 'xã',
            short_codename: 'nghi_son',
            parentId: 407,
          },
          {
            title: 'Xã Hải Hà',
            code: 16660,
            value: 'xa_hai_ha',
            division_type: 'xã',
            short_codename: 'hai_ha',
            parentId: 407,
          },
        ],
        parentId: 38,
      },
    ],
  },
  {
    title: 'Tỉnh Nghệ An',
    code: 40,
    value: 'tinh_nghe_an',
    division_type: 'tỉnh',
    phone_code: 238,
    children: [
      {
        title: 'Thành phố Vinh',
        code: 412,
        value: 'thanh_pho_vinh',
        division_type: 'thành phố',
        short_codename: 'vinh',
        children: [
          {
            title: 'Phường Đông Vĩnh',
            code: 16663,
            value: 'phuong_dong_vinh',
            division_type: 'phường',
            short_codename: 'dong_vinh',
            parentId: 412,
          },
          {
            title: 'Phường Hà Huy Tập',
            code: 16666,
            value: 'phuong_ha_huy_tap',
            division_type: 'phường',
            short_codename: 'ha_huy_tap',
            parentId: 412,
          },
          {
            title: 'Phường Lê Lợi',
            code: 16669,
            value: 'phuong_le_loi',
            division_type: 'phường',
            short_codename: 'le_loi',
            parentId: 412,
          },
          {
            title: 'Phường Quán Bàu',
            code: 16670,
            value: 'phuong_quan_bau',
            division_type: 'phường',
            short_codename: 'quan_bau',
            parentId: 412,
          },
          {
            title: 'Phường Hưng Bình',
            code: 16672,
            value: 'phuong_hung_binh',
            division_type: 'phường',
            short_codename: 'hung_binh',
            parentId: 412,
          },
          {
            title: 'Phường Hưng Phúc',
            code: 16673,
            value: 'phuong_hung_phuc',
            division_type: 'phường',
            short_codename: 'hung_phuc',
            parentId: 412,
          },
          {
            title: 'Phường Hưng Dũng',
            code: 16675,
            value: 'phuong_hung_dung',
            division_type: 'phường',
            short_codename: 'hung_dung',
            parentId: 412,
          },
          {
            title: 'Phường Cửa Nam',
            code: 16678,
            value: 'phuong_cua_nam',
            division_type: 'phường',
            short_codename: 'cua_nam',
            parentId: 412,
          },
          {
            title: 'Phường Quang Trung',
            code: 16681,
            value: 'phuong_quang_trung',
            division_type: 'phường',
            short_codename: 'quang_trung',
            parentId: 412,
          },
          {
            title: 'Phường Đội Cung',
            code: 16684,
            value: 'phuong_doi_cung',
            division_type: 'phường',
            short_codename: 'doi_cung',
            parentId: 412,
          },
          {
            title: 'Phường Lê Mao',
            code: 16687,
            value: 'phuong_le_mao',
            division_type: 'phường',
            short_codename: 'le_mao',
            parentId: 412,
          },
          {
            title: 'Phường Trường Thi',
            code: 16690,
            value: 'phuong_truong_thi',
            division_type: 'phường',
            short_codename: 'truong_thi',
            parentId: 412,
          },
          {
            title: 'Phường Bến Thủy',
            code: 16693,
            value: 'phuong_ben_thuy',
            division_type: 'phường',
            short_codename: 'ben_thuy',
            parentId: 412,
          },
          {
            title: 'Phường Hồng Sơn',
            code: 16696,
            value: 'phuong_hong_son',
            division_type: 'phường',
            short_codename: 'hong_son',
            parentId: 412,
          },
          {
            title: 'Phường Trung Đô',
            code: 16699,
            value: 'phuong_trung_do',
            division_type: 'phường',
            short_codename: 'trung_do',
            parentId: 412,
          },
          {
            title: 'Xã Nghi Phú',
            code: 16702,
            value: 'xa_nghi_phu',
            division_type: 'xã',
            short_codename: 'nghi_phu',
            parentId: 412,
          },
          {
            title: 'Xã Hưng Đông',
            code: 16705,
            value: 'xa_hung_dong',
            division_type: 'xã',
            short_codename: 'hung_dong',
            parentId: 412,
          },
          {
            title: 'Xã Hưng Lộc',
            code: 16708,
            value: 'xa_hung_loc',
            division_type: 'xã',
            short_codename: 'hung_loc',
            parentId: 412,
          },
          {
            title: 'Xã Hưng Hòa',
            code: 16711,
            value: 'xa_hung_hoa',
            division_type: 'xã',
            short_codename: 'hung_hoa',
            parentId: 412,
          },
          {
            title: 'Phường Vinh Tân',
            code: 16714,
            value: 'phuong_vinh_tan',
            division_type: 'phường',
            short_codename: 'vinh_tan',
            parentId: 412,
          },
          {
            title: 'Xã Nghi Liên',
            code: 17908,
            value: 'xa_nghi_lien',
            division_type: 'xã',
            short_codename: 'nghi_lien',
            parentId: 412,
          },
          {
            title: 'Xã Nghi Ân',
            code: 17914,
            value: 'xa_nghi_an',
            division_type: 'xã',
            short_codename: 'nghi_an',
            parentId: 412,
          },
          {
            title: 'Xã Nghi Kim',
            code: 17920,
            value: 'xa_nghi_kim',
            division_type: 'xã',
            short_codename: 'nghi_kim',
            parentId: 412,
          },
          {
            title: 'Xã Nghi Đức',
            code: 17923,
            value: 'xa_nghi_duc',
            division_type: 'xã',
            short_codename: 'nghi_duc',
            parentId: 412,
          },
          {
            title: 'Xã Hưng Chính',
            code: 18013,
            value: 'xa_hung_chinh',
            division_type: 'xã',
            short_codename: 'hung_chinh',
            parentId: 412,
          },
        ],
        parentId: 40,
      },
      {
        title: 'Thị xã Cửa Lò',
        code: 413,
        value: 'thi_xa_cua_lo',
        division_type: 'thị xã',
        short_codename: 'cua_lo',
        children: [
          {
            title: 'Phường Nghi Thuỷ',
            code: 16717,
            value: 'phuong_nghi_thuy',
            division_type: 'phường',
            short_codename: 'nghi_thuy',
            parentId: 413,
          },
          {
            title: 'Phường Nghi Tân',
            code: 16720,
            value: 'phuong_nghi_tan',
            division_type: 'phường',
            short_codename: 'nghi_tan',
            parentId: 413,
          },
          {
            title: 'Phường Thu Thuỷ',
            code: 16723,
            value: 'phuong_thu_thuy',
            division_type: 'phường',
            short_codename: 'thu_thuy',
            parentId: 413,
          },
          {
            title: 'Phường Nghi Hòa',
            code: 16726,
            value: 'phuong_nghi_hoa',
            division_type: 'phường',
            short_codename: 'nghi_hoa',
            parentId: 413,
          },
          {
            title: 'Phường Nghi Hải',
            code: 16729,
            value: 'phuong_nghi_hai',
            division_type: 'phường',
            short_codename: 'nghi_hai',
            parentId: 413,
          },
          {
            title: 'Phường Nghi Hương',
            code: 16732,
            value: 'phuong_nghi_huong',
            division_type: 'phường',
            short_codename: 'nghi_huong',
            parentId: 413,
          },
          {
            title: 'Phường Nghi Thu',
            code: 16735,
            value: 'phuong_nghi_thu',
            division_type: 'phường',
            short_codename: 'nghi_thu',
            parentId: 413,
          },
        ],
        parentId: 40,
      },
      {
        title: 'Thị xã Thái Hoà',
        code: 414,
        value: 'thi_xa_thai_hoa',
        division_type: 'thị xã',
        short_codename: 'thai_hoa',
        children: [
          {
            title: 'Phường Hoà Hiếu',
            code: 16939,
            value: 'phuong_hoa_hieu',
            division_type: 'phường',
            short_codename: 'hoa_hieu',
            parentId: 414,
          },
          {
            title: 'Phường Quang Phong',
            code: 16993,
            value: 'phuong_quang_phong',
            division_type: 'phường',
            short_codename: 'quang_phong',
            parentId: 414,
          },
          {
            title: 'Phường Quang Tiến',
            code: 16994,
            value: 'phuong_quang_tien',
            division_type: 'phường',
            short_codename: 'quang_tien',
            parentId: 414,
          },
          {
            title: 'Phường Long Sơn',
            code: 17003,
            value: 'phuong_long_son',
            division_type: 'phường',
            short_codename: 'long_son',
            parentId: 414,
          },
          {
            title: 'Xã Nghĩa Tiến',
            code: 17005,
            value: 'xa_nghia_tien',
            division_type: 'xã',
            short_codename: 'nghia_tien',
            parentId: 414,
          },
          {
            title: 'Xã Nghĩa Mỹ',
            code: 17008,
            value: 'xa_nghia_my',
            division_type: 'xã',
            short_codename: 'nghia_my',
            parentId: 414,
          },
          {
            title: 'Xã Tây Hiếu',
            code: 17011,
            value: 'xa_tay_hieu',
            division_type: 'xã',
            short_codename: 'tay_hieu',
            parentId: 414,
          },
          {
            title: 'Xã Nghĩa Thuận',
            code: 17014,
            value: 'xa_nghia_thuan',
            division_type: 'xã',
            short_codename: 'nghia_thuan',
            parentId: 414,
          },
          {
            title: 'Xã Đông Hiếu',
            code: 17017,
            value: 'xa_dong_hieu',
            division_type: 'xã',
            short_codename: 'dong_hieu',
            parentId: 414,
          },
        ],
        parentId: 40,
      },
      {
        title: 'Huyện Quế Phong',
        code: 415,
        value: 'huyen_que_phong',
        division_type: 'huyện',
        short_codename: 'que_phong',
        children: [
          {
            title: 'Thị trấn Kim Sơn',
            code: 16738,
            value: 'thi_tran_kim_son',
            division_type: 'thị trấn',
            short_codename: 'kim_son',
            parentId: 415,
          },
          {
            title: 'Xã Thông Thụ',
            code: 16741,
            value: 'xa_thong_thu',
            division_type: 'xã',
            short_codename: 'thong_thu',
            parentId: 415,
          },
          {
            title: 'Xã Đồng Văn',
            code: 16744,
            value: 'xa_dong_van',
            division_type: 'xã',
            short_codename: 'dong_van',
            parentId: 415,
          },
          {
            title: 'Xã Hạnh Dịch',
            code: 16747,
            value: 'xa_hanh_dich',
            division_type: 'xã',
            short_codename: 'hanh_dich',
            parentId: 415,
          },
          {
            title: 'Xã Tiền Phong',
            code: 16750,
            value: 'xa_tien_phong',
            division_type: 'xã',
            short_codename: 'tien_phong',
            parentId: 415,
          },
          {
            title: 'Xã Nậm Giải',
            code: 16753,
            value: 'xa_nam_giai',
            division_type: 'xã',
            short_codename: 'nam_giai',
            parentId: 415,
          },
          {
            title: 'Xã Tri Lễ',
            code: 16756,
            value: 'xa_tri_le',
            division_type: 'xã',
            short_codename: 'tri_le',
            parentId: 415,
          },
          {
            title: 'Xã Châu Kim',
            code: 16759,
            value: 'xa_chau_kim',
            division_type: 'xã',
            short_codename: 'chau_kim',
            parentId: 415,
          },
          {
            title: 'Xã Mường Nọc',
            code: 16763,
            value: 'xa_muong_noc',
            division_type: 'xã',
            short_codename: 'muong_noc',
            parentId: 415,
          },
          {
            title: 'Xã Châu Thôn',
            code: 16765,
            value: 'xa_chau_thon',
            division_type: 'xã',
            short_codename: 'chau_thon',
            parentId: 415,
          },
          {
            title: 'Xã Nậm Nhoóng',
            code: 16768,
            value: 'xa_nam_nhoong',
            division_type: 'xã',
            short_codename: 'nam_nhoong',
            parentId: 415,
          },
          {
            title: 'Xã Quang Phong',
            code: 16771,
            value: 'xa_quang_phong',
            division_type: 'xã',
            short_codename: 'quang_phong',
            parentId: 415,
          },
          {
            title: 'Xã Căm Muộn',
            code: 16774,
            value: 'xa_cam_muon',
            division_type: 'xã',
            short_codename: 'cam_muon',
            parentId: 415,
          },
        ],
        parentId: 40,
      },
      {
        title: 'Huyện Quỳ Châu',
        code: 416,
        value: 'huyen_quy_chau',
        division_type: 'huyện',
        short_codename: 'quy_chau',
        children: [
          {
            title: 'Thị trấn Tân Lạc',
            code: 16777,
            value: 'thi_tran_tan_lac',
            division_type: 'thị trấn',
            short_codename: 'tan_lac',
            parentId: 416,
          },
          {
            title: 'Xã Châu Bính',
            code: 16780,
            value: 'xa_chau_binh',
            division_type: 'xã',
            short_codename: 'xa_chau_binh',
            parentId: 416,
          },
          {
            title: 'Xã Châu Thuận',
            code: 16783,
            value: 'xa_chau_thuan',
            division_type: 'xã',
            short_codename: 'chau_thuan',
            parentId: 416,
          },
          {
            title: 'Xã Châu Hội',
            code: 16786,
            value: 'xa_chau_hoi',
            division_type: 'xã',
            short_codename: 'chau_hoi',
            parentId: 416,
          },
          {
            title: 'Xã Châu Nga',
            code: 16789,
            value: 'xa_chau_nga',
            division_type: 'xã',
            short_codename: 'chau_nga',
            parentId: 416,
          },
          {
            title: 'Xã Châu Tiến',
            code: 16792,
            value: 'xa_chau_tien',
            division_type: 'xã',
            short_codename: 'chau_tien',
            parentId: 416,
          },
          {
            title: 'Xã Châu Hạnh',
            code: 16795,
            value: 'xa_chau_hanh',
            division_type: 'xã',
            short_codename: 'chau_hanh',
            parentId: 416,
          },
          {
            title: 'Xã Châu Thắng',
            code: 16798,
            value: 'xa_chau_thang',
            division_type: 'xã',
            short_codename: 'chau_thang',
            parentId: 416,
          },
          {
            title: 'Xã Châu Phong',
            code: 16801,
            value: 'xa_chau_phong',
            division_type: 'xã',
            short_codename: 'chau_phong',
            parentId: 416,
          },
          {
            title: 'Xã Châu Bình',
            code: 16804,
            value: 'xa_chau_binh',
            division_type: 'xã',
            short_codename: 'xa_chau_binh',
            parentId: 416,
          },
          {
            title: 'Xã Châu Hoàn',
            code: 16807,
            value: 'xa_chau_hoan',
            division_type: 'xã',
            short_codename: 'chau_hoan',
            parentId: 416,
          },
          {
            title: 'Xã Diên Lãm',
            code: 16810,
            value: 'xa_dien_lam',
            division_type: 'xã',
            short_codename: 'dien_lam',
            parentId: 416,
          },
        ],
        parentId: 40,
      },
      {
        title: 'Huyện Kỳ Sơn',
        code: 417,
        value: 'huyen_ky_son',
        division_type: 'huyện',
        short_codename: 'ky_son',
        children: [
          {
            title: 'Thị trấn Mường Xén',
            code: 16813,
            value: 'thi_tran_muong_xen',
            division_type: 'thị trấn',
            short_codename: 'muong_xen',
            parentId: 417,
          },
          {
            title: 'Xã Mỹ Lý',
            code: 16816,
            value: 'xa_my_ly',
            division_type: 'xã',
            short_codename: 'my_ly',
            parentId: 417,
          },
          {
            title: 'Xã Bắc Lý',
            code: 16819,
            value: 'xa_bac_ly',
            division_type: 'xã',
            short_codename: 'bac_ly',
            parentId: 417,
          },
          {
            title: 'Xã Keng Đu',
            code: 16822,
            value: 'xa_keng_du',
            division_type: 'xã',
            short_codename: 'keng_du',
            parentId: 417,
          },
          {
            title: 'Xã Đoọc Mạy',
            code: 16825,
            value: 'xa_dooc_may',
            division_type: 'xã',
            short_codename: 'dooc_may',
            parentId: 417,
          },
          {
            title: 'Xã Huồi Tụ',
            code: 16828,
            value: 'xa_huoi_tu',
            division_type: 'xã',
            short_codename: 'huoi_tu',
            parentId: 417,
          },
          {
            title: 'Xã Mường Lống',
            code: 16831,
            value: 'xa_muong_long',
            division_type: 'xã',
            short_codename: 'muong_long',
            parentId: 417,
          },
          {
            title: 'Xã Na Loi',
            code: 16834,
            value: 'xa_na_loi',
            division_type: 'xã',
            short_codename: 'na_loi',
            parentId: 417,
          },
          {
            title: 'Xã Nậm Cắn',
            code: 16837,
            value: 'xa_nam_can',
            division_type: 'xã',
            short_codename: 'xa_nam_can',
            parentId: 417,
          },
          {
            title: 'Xã Bảo Nam',
            code: 16840,
            value: 'xa_bao_nam',
            division_type: 'xã',
            short_codename: 'bao_nam',
            parentId: 417,
          },
          {
            title: 'Xã Phà Đánh',
            code: 16843,
            value: 'xa_pha_danh',
            division_type: 'xã',
            short_codename: 'pha_danh',
            parentId: 417,
          },
          {
            title: 'Xã Bảo Thắng',
            code: 16846,
            value: 'xa_bao_thang',
            division_type: 'xã',
            short_codename: 'bao_thang',
            parentId: 417,
          },
          {
            title: 'Xã Hữu Lập',
            code: 16849,
            value: 'xa_huu_lap',
            division_type: 'xã',
            short_codename: 'huu_lap',
            parentId: 417,
          },
          {
            title: 'Xã Tà Cạ',
            code: 16852,
            value: 'xa_ta_ca',
            division_type: 'xã',
            short_codename: 'ta_ca',
            parentId: 417,
          },
          {
            title: 'Xã Chiêu Lưu',
            code: 16855,
            value: 'xa_chieu_luu',
            division_type: 'xã',
            short_codename: 'chieu_luu',
            parentId: 417,
          },
          {
            title: 'Xã Mường Típ',
            code: 16858,
            value: 'xa_muong_tip',
            division_type: 'xã',
            short_codename: 'muong_tip',
            parentId: 417,
          },
          {
            title: 'Xã Hữu Kiệm',
            code: 16861,
            value: 'xa_huu_kiem',
            division_type: 'xã',
            short_codename: 'huu_kiem',
            parentId: 417,
          },
          {
            title: 'Xã Tây Sơn',
            code: 16864,
            value: 'xa_tay_son',
            division_type: 'xã',
            short_codename: 'tay_son',
            parentId: 417,
          },
          {
            title: 'Xã Mường Ải',
            code: 16867,
            value: 'xa_muong_ai',
            division_type: 'xã',
            short_codename: 'muong_ai',
            parentId: 417,
          },
          {
            title: 'Xã Na Ngoi',
            code: 16870,
            value: 'xa_na_ngoi',
            division_type: 'xã',
            short_codename: 'na_ngoi',
            parentId: 417,
          },
          {
            title: 'Xã Nậm Càn',
            code: 16873,
            value: 'xa_nam_can',
            division_type: 'xã',
            short_codename: 'xa_nam_can',
            parentId: 417,
          },
        ],
        parentId: 40,
      },
      {
        title: 'Huyện Tương Dương',
        code: 418,
        value: 'huyen_tuong_duong',
        division_type: 'huyện',
        short_codename: 'tuong_duong',
        children: [
          {
            title: 'Thị trấn Thạch Giám',
            code: 16876,
            value: 'thi_tran_thach_giam',
            division_type: 'thị trấn',
            short_codename: 'thach_giam',
            parentId: 418,
          },
          {
            title: 'Xã Mai Sơn',
            code: 16879,
            value: 'xa_mai_son',
            division_type: 'xã',
            short_codename: 'mai_son',
            parentId: 418,
          },
          {
            title: 'Xã Nhôn Mai',
            code: 16882,
            value: 'xa_nhon_mai',
            division_type: 'xã',
            short_codename: 'nhon_mai',
            parentId: 418,
          },
          {
            title: 'Xã Hữu Khuông',
            code: 16885,
            value: 'xa_huu_khuong',
            division_type: 'xã',
            short_codename: 'huu_khuong',
            parentId: 418,
          },
          {
            title: 'Xã Yên Tĩnh',
            code: 16900,
            value: 'xa_yen_tinh',
            division_type: 'xã',
            short_codename: 'yen_tinh',
            parentId: 418,
          },
          {
            title: 'Xã Nga My',
            code: 16903,
            value: 'xa_nga_my',
            division_type: 'xã',
            short_codename: 'nga_my',
            parentId: 418,
          },
          {
            title: 'Xã Xiêng My',
            code: 16904,
            value: 'xa_xieng_my',
            division_type: 'xã',
            short_codename: 'xieng_my',
            parentId: 418,
          },
          {
            title: 'Xã Lưỡng Minh',
            code: 16906,
            value: 'xa_luong_minh',
            division_type: 'xã',
            short_codename: 'luong_minh',
            parentId: 418,
          },
          {
            title: 'Xã Yên Hòa',
            code: 16909,
            value: 'xa_yen_hoa',
            division_type: 'xã',
            short_codename: 'yen_hoa',
            parentId: 418,
          },
          {
            title: 'Xã Yên Na',
            code: 16912,
            value: 'xa_yen_na',
            division_type: 'xã',
            short_codename: 'yen_na',
            parentId: 418,
          },
          {
            title: 'Xã Lưu Kiền',
            code: 16915,
            value: 'xa_luu_kien',
            division_type: 'xã',
            short_codename: 'luu_kien',
            parentId: 418,
          },
          {
            title: 'Xã Xá Lượng',
            code: 16921,
            value: 'xa_xa_luong',
            division_type: 'xã',
            short_codename: 'xa_luong',
            parentId: 418,
          },
          {
            title: 'Xã Tam Thái',
            code: 16924,
            value: 'xa_tam_thai',
            division_type: 'xã',
            short_codename: 'tam_thai',
            parentId: 418,
          },
          {
            title: 'Xã Tam Đình',
            code: 16927,
            value: 'xa_tam_dinh',
            division_type: 'xã',
            short_codename: 'tam_dinh',
            parentId: 418,
          },
          {
            title: 'Xã Yên Thắng',
            code: 16930,
            value: 'xa_yen_thang',
            division_type: 'xã',
            short_codename: 'yen_thang',
            parentId: 418,
          },
          {
            title: 'Xã Tam Quang',
            code: 16933,
            value: 'xa_tam_quang',
            division_type: 'xã',
            short_codename: 'tam_quang',
            parentId: 418,
          },
          {
            title: 'Xã Tam Hợp',
            code: 16936,
            value: 'xa_tam_hop',
            division_type: 'xã',
            short_codename: 'tam_hop',
            parentId: 418,
          },
        ],
        parentId: 40,
      },
      {
        title: 'Huyện Nghĩa Đàn',
        code: 419,
        value: 'huyen_nghia_dan',
        division_type: 'huyện',
        short_codename: 'nghia_dan',
        children: [
          {
            title: 'Thị trấn Nghĩa Đàn',
            code: 16941,
            value: 'thi_tran_nghia_dan',
            division_type: 'thị trấn',
            short_codename: 'nghia_dan',
            parentId: 419,
          },
          {
            title: 'Xã Nghĩa Mai',
            code: 16942,
            value: 'xa_nghia_mai',
            division_type: 'xã',
            short_codename: 'nghia_mai',
            parentId: 419,
          },
          {
            title: 'Xã Nghĩa Yên',
            code: 16945,
            value: 'xa_nghia_yen',
            division_type: 'xã',
            short_codename: 'nghia_yen',
            parentId: 419,
          },
          {
            title: 'Xã Nghĩa Lạc',
            code: 16948,
            value: 'xa_nghia_lac',
            division_type: 'xã',
            short_codename: 'nghia_lac',
            parentId: 419,
          },
          {
            title: 'Xã Nghĩa Lâm',
            code: 16951,
            value: 'xa_nghia_lam',
            division_type: 'xã',
            short_codename: 'nghia_lam',
            parentId: 419,
          },
          {
            title: 'Xã Nghĩa Sơn',
            code: 16954,
            value: 'xa_nghia_son',
            division_type: 'xã',
            short_codename: 'nghia_son',
            parentId: 419,
          },
          {
            title: 'Xã Nghĩa Lợi',
            code: 16957,
            value: 'xa_nghia_loi',
            division_type: 'xã',
            short_codename: 'nghia_loi',
            parentId: 419,
          },
          {
            title: 'Xã Nghĩa Bình',
            code: 16960,
            value: 'xa_nghia_binh',
            division_type: 'xã',
            short_codename: 'nghia_binh',
            parentId: 419,
          },
          {
            title: 'Xã Nghĩa Thọ',
            code: 16963,
            value: 'xa_nghia_tho',
            division_type: 'xã',
            short_codename: 'nghia_tho',
            parentId: 419,
          },
          {
            title: 'Xã Nghĩa Minh',
            code: 16966,
            value: 'xa_nghia_minh',
            division_type: 'xã',
            short_codename: 'nghia_minh',
            parentId: 419,
          },
          {
            title: 'Xã Nghĩa Phú',
            code: 16969,
            value: 'xa_nghia_phu',
            division_type: 'xã',
            short_codename: 'nghia_phu',
            parentId: 419,
          },
          {
            title: 'Xã Nghĩa Hưng',
            code: 16972,
            value: 'xa_nghia_hung',
            division_type: 'xã',
            short_codename: 'nghia_hung',
            parentId: 419,
          },
          {
            title: 'Xã Nghĩa Hồng',
            code: 16975,
            value: 'xa_nghia_hong',
            division_type: 'xã',
            short_codename: 'nghia_hong',
            parentId: 419,
          },
          {
            title: 'Xã Nghĩa Thịnh',
            code: 16978,
            value: 'xa_nghia_thinh',
            division_type: 'xã',
            short_codename: 'nghia_thinh',
            parentId: 419,
          },
          {
            title: 'Xã Nghĩa Trung',
            code: 16981,
            value: 'xa_nghia_trung',
            division_type: 'xã',
            short_codename: 'nghia_trung',
            parentId: 419,
          },
          {
            title: 'Xã Nghĩa Hội',
            code: 16984,
            value: 'xa_nghia_hoi',
            division_type: 'xã',
            short_codename: 'nghia_hoi',
            parentId: 419,
          },
          {
            title: 'Xã Nghĩa Thành',
            code: 16987,
            value: 'xa_nghia_thanh',
            division_type: 'xã',
            short_codename: 'nghia_thanh',
            parentId: 419,
          },
          {
            title: 'Xã Nghĩa Hiếu',
            code: 16996,
            value: 'xa_nghia_hieu',
            division_type: 'xã',
            short_codename: 'nghia_hieu',
            parentId: 419,
          },
          {
            title: 'Xã Nghĩa Đức',
            code: 17020,
            value: 'xa_nghia_duc',
            division_type: 'xã',
            short_codename: 'nghia_duc',
            parentId: 419,
          },
          {
            title: 'Xã Nghĩa An',
            code: 17023,
            value: 'xa_nghia_an',
            division_type: 'xã',
            short_codename: 'nghia_an',
            parentId: 419,
          },
          {
            title: 'Xã Nghĩa Long',
            code: 17026,
            value: 'xa_nghia_long',
            division_type: 'xã',
            short_codename: 'nghia_long',
            parentId: 419,
          },
          {
            title: 'Xã Nghĩa Lộc',
            code: 17029,
            value: 'xa_nghia_loc',
            division_type: 'xã',
            short_codename: 'nghia_loc',
            parentId: 419,
          },
          {
            title: 'Xã Nghĩa Khánh',
            code: 17032,
            value: 'xa_nghia_khanh',
            division_type: 'xã',
            short_codename: 'nghia_khanh',
            parentId: 419,
          },
        ],
        parentId: 40,
      },
      {
        title: 'Huyện Quỳ Hợp',
        code: 420,
        value: 'huyen_quy_hop',
        division_type: 'huyện',
        short_codename: 'quy_hop',
        children: [
          {
            title: 'Thị trấn Quỳ Hợp',
            code: 17035,
            value: 'thi_tran_quy_hop',
            division_type: 'thị trấn',
            short_codename: 'quy_hop',
            parentId: 420,
          },
          {
            title: 'Xã Yên Hợp',
            code: 17038,
            value: 'xa_yen_hop',
            division_type: 'xã',
            short_codename: 'yen_hop',
            parentId: 420,
          },
          {
            title: 'Xã Châu Tiến',
            code: 17041,
            value: 'xa_chau_tien',
            division_type: 'xã',
            short_codename: 'chau_tien',
            parentId: 420,
          },
          {
            title: 'Xã Châu Hồng',
            code: 17044,
            value: 'xa_chau_hong',
            division_type: 'xã',
            short_codename: 'chau_hong',
            parentId: 420,
          },
          {
            title: 'Xã Đồng Hợp',
            code: 17047,
            value: 'xa_dong_hop',
            division_type: 'xã',
            short_codename: 'dong_hop',
            parentId: 420,
          },
          {
            title: 'Xã Châu Thành',
            code: 17050,
            value: 'xa_chau_thanh',
            division_type: 'xã',
            short_codename: 'chau_thanh',
            parentId: 420,
          },
          {
            title: 'Xã Liên Hợp',
            code: 17053,
            value: 'xa_lien_hop',
            division_type: 'xã',
            short_codename: 'lien_hop',
            parentId: 420,
          },
          {
            title: 'Xã Châu Lộc',
            code: 17056,
            value: 'xa_chau_loc',
            division_type: 'xã',
            short_codename: 'chau_loc',
            parentId: 420,
          },
          {
            title: 'Xã Tam Hợp',
            code: 17059,
            value: 'xa_tam_hop',
            division_type: 'xã',
            short_codename: 'tam_hop',
            parentId: 420,
          },
          {
            title: 'Xã Châu Cường',
            code: 17062,
            value: 'xa_chau_cuong',
            division_type: 'xã',
            short_codename: 'chau_cuong',
            parentId: 420,
          },
          {
            title: 'Xã Châu Quang',
            code: 17065,
            value: 'xa_chau_quang',
            division_type: 'xã',
            short_codename: 'chau_quang',
            parentId: 420,
          },
          {
            title: 'Xã Thọ Hợp',
            code: 17068,
            value: 'xa_tho_hop',
            division_type: 'xã',
            short_codename: 'tho_hop',
            parentId: 420,
          },
          {
            title: 'Xã Minh Hợp',
            code: 17071,
            value: 'xa_minh_hop',
            division_type: 'xã',
            short_codename: 'minh_hop',
            parentId: 420,
          },
          {
            title: 'Xã Nghĩa Xuân',
            code: 17074,
            value: 'xa_nghia_xuan',
            division_type: 'xã',
            short_codename: 'nghia_xuan',
            parentId: 420,
          },
          {
            title: 'Xã Châu Thái',
            code: 17077,
            value: 'xa_chau_thai',
            division_type: 'xã',
            short_codename: 'chau_thai',
            parentId: 420,
          },
          {
            title: 'Xã Châu Đình',
            code: 17080,
            value: 'xa_chau_dinh',
            division_type: 'xã',
            short_codename: 'chau_dinh',
            parentId: 420,
          },
          {
            title: 'Xã Văn Lợi',
            code: 17083,
            value: 'xa_van_loi',
            division_type: 'xã',
            short_codename: 'van_loi',
            parentId: 420,
          },
          {
            title: 'Xã Nam Sơn',
            code: 17086,
            value: 'xa_nam_son',
            division_type: 'xã',
            short_codename: 'nam_son',
            parentId: 420,
          },
          {
            title: 'Xã Châu Lý',
            code: 17089,
            value: 'xa_chau_ly',
            division_type: 'xã',
            short_codename: 'chau_ly',
            parentId: 420,
          },
          {
            title: 'Xã Hạ Sơn',
            code: 17092,
            value: 'xa_ha_son',
            division_type: 'xã',
            short_codename: 'ha_son',
            parentId: 420,
          },
          {
            title: 'Xã Bắc Sơn',
            code: 17095,
            value: 'xa_bac_son',
            division_type: 'xã',
            short_codename: 'bac_son',
            parentId: 420,
          },
        ],
        parentId: 40,
      },
      {
        title: 'Huyện Quỳnh Lưu',
        code: 421,
        value: 'huyen_quynh_luu',
        division_type: 'huyện',
        short_codename: 'quynh_luu',
        children: [
          {
            title: 'Thị trấn Cầu Giát',
            code: 17098,
            value: 'thi_tran_cau_giat',
            division_type: 'thị trấn',
            short_codename: 'cau_giat',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Thắng',
            code: 17101,
            value: 'xa_quynh_thang',
            division_type: 'xã',
            short_codename: 'quynh_thang',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Tân',
            code: 17119,
            value: 'xa_quynh_tan',
            division_type: 'xã',
            short_codename: 'quynh_tan',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Châu',
            code: 17122,
            value: 'xa_quynh_chau',
            division_type: 'xã',
            short_codename: 'quynh_chau',
            parentId: 421,
          },
          {
            title: 'Xã Tân Sơn',
            code: 17140,
            value: 'xa_tan_son',
            division_type: 'xã',
            short_codename: 'tan_son',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Văn',
            code: 17143,
            value: 'xa_quynh_van',
            division_type: 'xã',
            short_codename: 'quynh_van',
            parentId: 421,
          },
          {
            title: 'Xã Ngọc Sơn',
            code: 17146,
            value: 'xa_ngoc_son',
            division_type: 'xã',
            short_codename: 'ngoc_son',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Tam',
            code: 17149,
            value: 'xa_quynh_tam',
            division_type: 'xã',
            short_codename: 'quynh_tam',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Hoa',
            code: 17152,
            value: 'xa_quynh_hoa',
            division_type: 'xã',
            short_codename: 'quynh_hoa',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Thạch',
            code: 17155,
            value: 'xa_quynh_thach',
            division_type: 'xã',
            short_codename: 'quynh_thach',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Bảng',
            code: 17158,
            value: 'xa_quynh_bang',
            division_type: 'xã',
            short_codename: 'quynh_bang',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Mỹ',
            code: 17161,
            value: 'xa_quynh_my',
            division_type: 'xã',
            short_codename: 'quynh_my',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Thanh',
            code: 17164,
            value: 'xa_quynh_thanh',
            division_type: 'xã',
            short_codename: 'quynh_thanh',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Hậu',
            code: 17167,
            value: 'xa_quynh_hau',
            division_type: 'xã',
            short_codename: 'quynh_hau',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Lâm',
            code: 17170,
            value: 'xa_quynh_lam',
            division_type: 'xã',
            short_codename: 'quynh_lam',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Đôi',
            code: 17173,
            value: 'xa_quynh_doi',
            division_type: 'xã',
            short_codename: 'quynh_doi',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Lương',
            code: 17176,
            value: 'xa_quynh_luong',
            division_type: 'xã',
            short_codename: 'quynh_luong',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Hồng',
            code: 17179,
            value: 'xa_quynh_hong',
            division_type: 'xã',
            short_codename: 'quynh_hong',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Yên',
            code: 17182,
            value: 'xa_quynh_yen',
            division_type: 'xã',
            short_codename: 'quynh_yen',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Bá',
            code: 17185,
            value: 'xa_quynh_ba',
            division_type: 'xã',
            short_codename: 'quynh_ba',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Minh',
            code: 17188,
            value: 'xa_quynh_minh',
            division_type: 'xã',
            short_codename: 'quynh_minh',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Diễn',
            code: 17191,
            value: 'xa_quynh_dien',
            division_type: 'xã',
            short_codename: 'quynh_dien',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Hưng',
            code: 17194,
            value: 'xa_quynh_hung',
            division_type: 'xã',
            short_codename: 'quynh_hung',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Giang',
            code: 17197,
            value: 'xa_quynh_giang',
            division_type: 'xã',
            short_codename: 'quynh_giang',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Ngọc',
            code: 17200,
            value: 'xa_quynh_ngoc',
            division_type: 'xã',
            short_codename: 'quynh_ngoc',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Nghĩa',
            code: 17203,
            value: 'xa_quynh_nghia',
            division_type: 'xã',
            short_codename: 'quynh_nghia',
            parentId: 421,
          },
          {
            title: 'Xã An Hòa',
            code: 17206,
            value: 'xa_an_hoa',
            division_type: 'xã',
            short_codename: 'an_hoa',
            parentId: 421,
          },
          {
            title: 'Xã Tiến Thủy',
            code: 17209,
            value: 'xa_tien_thuy',
            division_type: 'xã',
            short_codename: 'tien_thuy',
            parentId: 421,
          },
          {
            title: 'Xã Sơn Hải',
            code: 17212,
            value: 'xa_son_hai',
            division_type: 'xã',
            short_codename: 'son_hai',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Thọ',
            code: 17215,
            value: 'xa_quynh_tho',
            division_type: 'xã',
            short_codename: 'quynh_tho',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Thuận',
            code: 17218,
            value: 'xa_quynh_thuan',
            division_type: 'xã',
            short_codename: 'quynh_thuan',
            parentId: 421,
          },
          {
            title: 'Xã Quỳnh Long',
            code: 17221,
            value: 'xa_quynh_long',
            division_type: 'xã',
            short_codename: 'quynh_long',
            parentId: 421,
          },
          {
            title: 'Xã Tân Thắng',
            code: 17224,
            value: 'xa_tan_thang',
            division_type: 'xã',
            short_codename: 'tan_thang',
            parentId: 421,
          },
        ],
        parentId: 40,
      },
      {
        title: 'Huyện Con Cuông',
        code: 422,
        value: 'huyen_con_cuong',
        division_type: 'huyện',
        short_codename: 'con_cuong',
        children: [
          {
            title: 'Thị trấn Con Cuông',
            code: 17227,
            value: 'thi_tran_con_cuong',
            division_type: 'thị trấn',
            short_codename: 'con_cuong',
            parentId: 422,
          },
          {
            title: 'Xã Bình Chuẩn',
            code: 17230,
            value: 'xa_binh_chuan',
            division_type: 'xã',
            short_codename: 'binh_chuan',
            parentId: 422,
          },
          {
            title: 'Xã Lạng Khê',
            code: 17233,
            value: 'xa_lang_khe',
            division_type: 'xã',
            short_codename: 'lang_khe',
            parentId: 422,
          },
          {
            title: 'Xã Cam Lâm',
            code: 17236,
            value: 'xa_cam_lam',
            division_type: 'xã',
            short_codename: 'cam_lam',
            parentId: 422,
          },
          {
            title: 'Xã Thạch Ngàn',
            code: 17239,
            value: 'xa_thach_ngan',
            division_type: 'xã',
            short_codename: 'thach_ngan',
            parentId: 422,
          },
          {
            title: 'Xã Đôn Phục',
            code: 17242,
            value: 'xa_don_phuc',
            division_type: 'xã',
            short_codename: 'don_phuc',
            parentId: 422,
          },
          {
            title: 'Xã Mậu Đức',
            code: 17245,
            value: 'xa_mau_duc',
            division_type: 'xã',
            short_codename: 'mau_duc',
            parentId: 422,
          },
          {
            title: 'Xã Châu Khê',
            code: 17248,
            value: 'xa_chau_khe',
            division_type: 'xã',
            short_codename: 'chau_khe',
            parentId: 422,
          },
          {
            title: 'Xã Chi Khê',
            code: 17251,
            value: 'xa_chi_khe',
            division_type: 'xã',
            short_codename: 'chi_khe',
            parentId: 422,
          },
          {
            title: 'Xã Bồng Khê',
            code: 17254,
            value: 'xa_bong_khe',
            division_type: 'xã',
            short_codename: 'bong_khe',
            parentId: 422,
          },
          {
            title: 'Xã Yên Khê',
            code: 17257,
            value: 'xa_yen_khe',
            division_type: 'xã',
            short_codename: 'yen_khe',
            parentId: 422,
          },
          {
            title: 'Xã Lục Dạ',
            code: 17260,
            value: 'xa_luc_da',
            division_type: 'xã',
            short_codename: 'luc_da',
            parentId: 422,
          },
          {
            title: 'Xã Môn Sơn',
            code: 17263,
            value: 'xa_mon_son',
            division_type: 'xã',
            short_codename: 'mon_son',
            parentId: 422,
          },
        ],
        parentId: 40,
      },
      {
        title: 'Huyện Tân Kỳ',
        code: 423,
        value: 'huyen_tan_ky',
        division_type: 'huyện',
        short_codename: 'tan_ky',
        children: [
          {
            title: 'Thị trấn Tân Kỳ',
            code: 17266,
            value: 'thi_tran_tan_ky',
            division_type: 'thị trấn',
            short_codename: 'tan_ky',
            parentId: 423,
          },
          {
            title: 'Xã Tân Hợp',
            code: 17269,
            value: 'xa_tan_hop',
            division_type: 'xã',
            short_codename: 'tan_hop',
            parentId: 423,
          },
          {
            title: 'Xã Tân Phú',
            code: 17272,
            value: 'xa_tan_phu',
            division_type: 'xã',
            short_codename: 'tan_phu',
            parentId: 423,
          },
          {
            title: 'Xã Tân Xuân',
            code: 17275,
            value: 'xa_tan_xuan',
            division_type: 'xã',
            short_codename: 'tan_xuan',
            parentId: 423,
          },
          {
            title: 'Xã Giai Xuân',
            code: 17278,
            value: 'xa_giai_xuan',
            division_type: 'xã',
            short_codename: 'giai_xuan',
            parentId: 423,
          },
          {
            title: 'Xã Nghĩa Bình',
            code: 17281,
            value: 'xa_nghia_binh',
            division_type: 'xã',
            short_codename: 'nghia_binh',
            parentId: 423,
          },
          {
            title: 'Xã Nghĩa Đồng',
            code: 17284,
            value: 'xa_nghia_dong',
            division_type: 'xã',
            short_codename: 'nghia_dong',
            parentId: 423,
          },
          {
            title: 'Xã Đồng Văn',
            code: 17287,
            value: 'xa_dong_van',
            division_type: 'xã',
            short_codename: 'dong_van',
            parentId: 423,
          },
          {
            title: 'Xã Nghĩa Thái',
            code: 17290,
            value: 'xa_nghia_thai',
            division_type: 'xã',
            short_codename: 'nghia_thai',
            parentId: 423,
          },
          {
            title: 'Xã Nghĩa Hợp',
            code: 17293,
            value: 'xa_nghia_hop',
            division_type: 'xã',
            short_codename: 'nghia_hop',
            parentId: 423,
          },
          {
            title: 'Xã Nghĩa Hoàn',
            code: 17296,
            value: 'xa_nghia_hoan',
            division_type: 'xã',
            short_codename: 'nghia_hoan',
            parentId: 423,
          },
          {
            title: 'Xã Nghĩa Phúc',
            code: 17299,
            value: 'xa_nghia_phuc',
            division_type: 'xã',
            short_codename: 'nghia_phuc',
            parentId: 423,
          },
          {
            title: 'Xã Tiên Kỳ',
            code: 17302,
            value: 'xa_tien_ky',
            division_type: 'xã',
            short_codename: 'tien_ky',
            parentId: 423,
          },
          {
            title: 'Xã Tân An',
            code: 17305,
            value: 'xa_tan_an',
            division_type: 'xã',
            short_codename: 'tan_an',
            parentId: 423,
          },
          {
            title: 'Xã Nghĩa Dũng',
            code: 17308,
            value: 'xa_nghia_dung',
            division_type: 'xã',
            short_codename: 'nghia_dung',
            parentId: 423,
          },
          {
            title: 'Xã Tân Long',
            code: 17311,
            value: 'xa_tan_long',
            division_type: 'xã',
            short_codename: 'tan_long',
            parentId: 423,
          },
          {
            title: 'Xã Kỳ Sơn',
            code: 17314,
            value: 'xa_ky_son',
            division_type: 'xã',
            short_codename: 'ky_son',
            parentId: 423,
          },
          {
            title: 'Xã Hương Sơn',
            code: 17317,
            value: 'xa_huong_son',
            division_type: 'xã',
            short_codename: 'huong_son',
            parentId: 423,
          },
          {
            title: 'Xã Kỳ Tân',
            code: 17320,
            value: 'xa_ky_tan',
            division_type: 'xã',
            short_codename: 'ky_tan',
            parentId: 423,
          },
          {
            title: 'Xã Phú Sơn',
            code: 17323,
            value: 'xa_phu_son',
            division_type: 'xã',
            short_codename: 'phu_son',
            parentId: 423,
          },
          {
            title: 'Xã Tân Hương',
            code: 17325,
            value: 'xa_tan_huong',
            division_type: 'xã',
            short_codename: 'tan_huong',
            parentId: 423,
          },
          {
            title: 'Xã Nghĩa Hành',
            code: 17326,
            value: 'xa_nghia_hanh',
            division_type: 'xã',
            short_codename: 'nghia_hanh',
            parentId: 423,
          },
        ],
        parentId: 40,
      },
      {
        title: 'Huyện Anh Sơn',
        code: 424,
        value: 'huyen_anh_son',
        division_type: 'huyện',
        short_codename: 'anh_son',
        children: [
          {
            title: 'Thị trấn Anh Sơn',
            code: 17329,
            value: 'thi_tran_anh_son',
            division_type: 'thị trấn',
            short_codename: 'anh_son',
            parentId: 424,
          },
          {
            title: 'Xã Thọ Sơn',
            code: 17332,
            value: 'xa_tho_son',
            division_type: 'xã',
            short_codename: 'tho_son',
            parentId: 424,
          },
          {
            title: 'Xã Thành Sơn',
            code: 17335,
            value: 'xa_thanh_son',
            division_type: 'xã',
            short_codename: 'thanh_son',
            parentId: 424,
          },
          {
            title: 'Xã Bình Sơn',
            code: 17338,
            value: 'xa_binh_son',
            division_type: 'xã',
            short_codename: 'binh_son',
            parentId: 424,
          },
          {
            title: 'Xã Tam Sơn',
            code: 17341,
            value: 'xa_tam_son',
            division_type: 'xã',
            short_codename: 'tam_son',
            parentId: 424,
          },
          {
            title: 'Xã Đỉnh Sơn',
            code: 17344,
            value: 'xa_dinh_son',
            division_type: 'xã',
            short_codename: 'dinh_son',
            parentId: 424,
          },
          {
            title: 'Xã Hùng Sơn',
            code: 17347,
            value: 'xa_hung_son',
            division_type: 'xã',
            short_codename: 'hung_son',
            parentId: 424,
          },
          {
            title: 'Xã Cẩm Sơn',
            code: 17350,
            value: 'xa_cam_son',
            division_type: 'xã',
            short_codename: 'cam_son',
            parentId: 424,
          },
          {
            title: 'Xã Đức Sơn',
            code: 17353,
            value: 'xa_duc_son',
            division_type: 'xã',
            short_codename: 'duc_son',
            parentId: 424,
          },
          {
            title: 'Xã Tường Sơn',
            code: 17356,
            value: 'xa_tuong_son',
            division_type: 'xã',
            short_codename: 'tuong_son',
            parentId: 424,
          },
          {
            title: 'Xã Hoa Sơn',
            code: 17357,
            value: 'xa_hoa_son',
            division_type: 'xã',
            short_codename: 'hoa_son',
            parentId: 424,
          },
          {
            title: 'Xã Tào Sơn',
            code: 17359,
            value: 'xa_tao_son',
            division_type: 'xã',
            short_codename: 'tao_son',
            parentId: 424,
          },
          {
            title: 'Xã Vĩnh Sơn',
            code: 17362,
            value: 'xa_vinh_son',
            division_type: 'xã',
            short_codename: 'vinh_son',
            parentId: 424,
          },
          {
            title: 'Xã Lạng Sơn',
            code: 17365,
            value: 'xa_lang_son',
            division_type: 'xã',
            short_codename: 'lang_son',
            parentId: 424,
          },
          {
            title: 'Xã Hội Sơn',
            code: 17368,
            value: 'xa_hoi_son',
            division_type: 'xã',
            short_codename: 'hoi_son',
            parentId: 424,
          },
          {
            title: 'Xã Thạch Sơn',
            code: 17371,
            value: 'xa_thach_son',
            division_type: 'xã',
            short_codename: 'thach_son',
            parentId: 424,
          },
          {
            title: 'Xã Phúc Sơn',
            code: 17374,
            value: 'xa_phuc_son',
            division_type: 'xã',
            short_codename: 'phuc_son',
            parentId: 424,
          },
          {
            title: 'Xã Long Sơn',
            code: 17377,
            value: 'xa_long_son',
            division_type: 'xã',
            short_codename: 'long_son',
            parentId: 424,
          },
          {
            title: 'Xã Khai Sơn',
            code: 17380,
            value: 'xa_khai_son',
            division_type: 'xã',
            short_codename: 'khai_son',
            parentId: 424,
          },
          {
            title: 'Xã Lĩnh Sơn',
            code: 17383,
            value: 'xa_linh_son',
            division_type: 'xã',
            short_codename: 'linh_son',
            parentId: 424,
          },
          {
            title: 'Xã Cao Sơn',
            code: 17386,
            value: 'xa_cao_son',
            division_type: 'xã',
            short_codename: 'cao_son',
            parentId: 424,
          },
        ],
        parentId: 40,
      },
      {
        title: 'Huyện Diễn Châu',
        code: 425,
        value: 'huyen_dien_chau',
        division_type: 'huyện',
        short_codename: 'dien_chau',
        children: [
          {
            title: 'Thị trấn Diễn Châu',
            code: 17389,
            value: 'thi_tran_dien_chau',
            division_type: 'thị trấn',
            short_codename: 'dien_chau',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Lâm',
            code: 17392,
            value: 'xa_dien_lam',
            division_type: 'xã',
            short_codename: 'dien_lam',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Đoài',
            code: 17395,
            value: 'xa_dien_doai',
            division_type: 'xã',
            short_codename: 'dien_doai',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Trường',
            code: 17398,
            value: 'xa_dien_truong',
            division_type: 'xã',
            short_codename: 'dien_truong',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Yên',
            code: 17401,
            value: 'xa_dien_yen',
            division_type: 'xã',
            short_codename: 'dien_yen',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Hoàng',
            code: 17404,
            value: 'xa_dien_hoang',
            division_type: 'xã',
            short_codename: 'dien_hoang',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Hùng',
            code: 17407,
            value: 'xa_dien_hung',
            division_type: 'xã',
            short_codename: 'dien_hung',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Mỹ',
            code: 17410,
            value: 'xa_dien_my',
            division_type: 'xã',
            short_codename: 'dien_my',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Hồng',
            code: 17413,
            value: 'xa_dien_hong',
            division_type: 'xã',
            short_codename: 'dien_hong',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Phong',
            code: 17416,
            value: 'xa_dien_phong',
            division_type: 'xã',
            short_codename: 'dien_phong',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Hải',
            code: 17419,
            value: 'xa_dien_hai',
            division_type: 'xã',
            short_codename: 'dien_hai',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Tháp',
            code: 17422,
            value: 'xa_dien_thap',
            division_type: 'xã',
            short_codename: 'dien_thap',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Liên',
            code: 17425,
            value: 'xa_dien_lien',
            division_type: 'xã',
            short_codename: 'dien_lien',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Vạn',
            code: 17428,
            value: 'xa_dien_van',
            division_type: 'xã',
            short_codename: 'dien_van',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Kim',
            code: 17431,
            value: 'xa_dien_kim',
            division_type: 'xã',
            short_codename: 'dien_kim',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Kỷ',
            code: 17434,
            value: 'xa_dien_ky',
            division_type: 'xã',
            short_codename: 'dien_ky',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Xuân',
            code: 17437,
            value: 'xa_dien_xuan',
            division_type: 'xã',
            short_codename: 'dien_xuan',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Thái',
            code: 17440,
            value: 'xa_dien_thai',
            division_type: 'xã',
            short_codename: 'dien_thai',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Đồng',
            code: 17443,
            value: 'xa_dien_dong',
            division_type: 'xã',
            short_codename: 'dien_dong',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Bích',
            code: 17446,
            value: 'xa_dien_bich',
            division_type: 'xã',
            short_codename: 'dien_bich',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Hạnh',
            code: 17449,
            value: 'xa_dien_hanh',
            division_type: 'xã',
            short_codename: 'dien_hanh',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Ngọc',
            code: 17452,
            value: 'xa_dien_ngoc',
            division_type: 'xã',
            short_codename: 'dien_ngoc',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Quảng',
            code: 17455,
            value: 'xa_dien_quang',
            division_type: 'xã',
            short_codename: 'dien_quang',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Nguyên',
            code: 17458,
            value: 'xa_dien_nguyen',
            division_type: 'xã',
            short_codename: 'dien_nguyen',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Hoa',
            code: 17461,
            value: 'xa_dien_hoa',
            division_type: 'xã',
            short_codename: 'dien_hoa',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Thành',
            code: 17464,
            value: 'xa_dien_thanh',
            division_type: 'xã',
            short_codename: 'dien_thanh',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Phúc',
            code: 17467,
            value: 'xa_dien_phuc',
            division_type: 'xã',
            short_codename: 'dien_phuc',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Cát',
            code: 17476,
            value: 'xa_dien_cat',
            division_type: 'xã',
            short_codename: 'dien_cat',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Thịnh',
            code: 17479,
            value: 'xa_dien_thinh',
            division_type: 'xã',
            short_codename: 'dien_thinh',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Tân',
            code: 17482,
            value: 'xa_dien_tan',
            division_type: 'xã',
            short_codename: 'dien_tan',
            parentId: 425,
          },
          {
            title: 'Xã Minh Châu',
            code: 17485,
            value: 'xa_minh_chau',
            division_type: 'xã',
            short_codename: 'minh_chau',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Thọ',
            code: 17488,
            value: 'xa_dien_tho',
            division_type: 'xã',
            short_codename: 'dien_tho',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Lợi',
            code: 17491,
            value: 'xa_dien_loi',
            division_type: 'xã',
            short_codename: 'dien_loi',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Lộc',
            code: 17494,
            value: 'xa_dien_loc',
            division_type: 'xã',
            short_codename: 'dien_loc',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Trung',
            code: 17497,
            value: 'xa_dien_trung',
            division_type: 'xã',
            short_codename: 'dien_trung',
            parentId: 425,
          },
          {
            title: 'Xã Diễn An',
            code: 17500,
            value: 'xa_dien_an',
            division_type: 'xã',
            short_codename: 'dien_an',
            parentId: 425,
          },
          {
            title: 'Xã Diễn Phú',
            code: 17503,
            value: 'xa_dien_phu',
            division_type: 'xã',
            short_codename: 'dien_phu',
            parentId: 425,
          },
        ],
        parentId: 40,
      },
      {
        title: 'Huyện Yên Thành',
        code: 426,
        value: 'huyen_yen_thanh',
        division_type: 'huyện',
        short_codename: 'yen_thanh',
        children: [
          {
            title: 'Thị trấn Yên Thành',
            code: 17506,
            value: 'thi_tran_yen_thanh',
            division_type: 'thị trấn',
            short_codename: 'yen_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Mã Thành',
            code: 17509,
            value: 'xa_ma_thanh',
            division_type: 'xã',
            short_codename: 'ma_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Tiến Thành',
            code: 17510,
            value: 'xa_tien_thanh',
            division_type: 'xã',
            short_codename: 'tien_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Lăng Thành',
            code: 17512,
            value: 'xa_lang_thanh',
            division_type: 'xã',
            short_codename: 'lang_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Tân Thành',
            code: 17515,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Đức Thành',
            code: 17518,
            value: 'xa_duc_thanh',
            division_type: 'xã',
            short_codename: 'duc_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Kim Thành',
            code: 17521,
            value: 'xa_kim_thanh',
            division_type: 'xã',
            short_codename: 'kim_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Hậu Thành',
            code: 17524,
            value: 'xa_hau_thanh',
            division_type: 'xã',
            short_codename: 'hau_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Hùng Thành',
            code: 17525,
            value: 'xa_hung_thanh',
            division_type: 'xã',
            short_codename: 'hung_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Đô Thành',
            code: 17527,
            value: 'xa_do_thanh',
            division_type: 'xã',
            short_codename: 'do_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Thọ Thành',
            code: 17530,
            value: 'xa_tho_thanh',
            division_type: 'xã',
            short_codename: 'tho_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Quang Thành',
            code: 17533,
            value: 'xa_quang_thanh',
            division_type: 'xã',
            short_codename: 'quang_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Tây Thành',
            code: 17536,
            value: 'xa_tay_thanh',
            division_type: 'xã',
            short_codename: 'tay_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Phúc Thành',
            code: 17539,
            value: 'xa_phuc_thanh',
            division_type: 'xã',
            short_codename: 'phuc_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Hồng Thành',
            code: 17542,
            value: 'xa_hong_thanh',
            division_type: 'xã',
            short_codename: 'hong_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Đồng Thành',
            code: 17545,
            value: 'xa_dong_thanh',
            division_type: 'xã',
            short_codename: 'dong_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Phú Thành',
            code: 17548,
            value: 'xa_phu_thanh',
            division_type: 'xã',
            short_codename: 'phu_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Hoa Thành',
            code: 17551,
            value: 'xa_hoa_thanh',
            division_type: 'xã',
            short_codename: 'hoa_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Tăng Thành',
            code: 17554,
            value: 'xa_tang_thanh',
            division_type: 'xã',
            short_codename: 'tang_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Văn Thành',
            code: 17557,
            value: 'xa_van_thanh',
            division_type: 'xã',
            short_codename: 'van_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Thịnh Thành',
            code: 17560,
            value: 'xa_thinh_thanh',
            division_type: 'xã',
            short_codename: 'thinh_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Hợp Thành',
            code: 17563,
            value: 'xa_hop_thanh',
            division_type: 'xã',
            short_codename: 'hop_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Xuân Thành',
            code: 17566,
            value: 'xa_xuan_thanh',
            division_type: 'xã',
            short_codename: 'xuan_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Bắc Thành',
            code: 17569,
            value: 'xa_bac_thanh',
            division_type: 'xã',
            short_codename: 'bac_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Nhân Thành',
            code: 17572,
            value: 'xa_nhan_thanh',
            division_type: 'xã',
            short_codename: 'nhan_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Trung Thành',
            code: 17575,
            value: 'xa_trung_thanh',
            division_type: 'xã',
            short_codename: 'trung_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Long Thành',
            code: 17578,
            value: 'xa_long_thanh',
            division_type: 'xã',
            short_codename: 'long_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Minh Thành',
            code: 17581,
            value: 'xa_minh_thanh',
            division_type: 'xã',
            short_codename: 'minh_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Nam Thành',
            code: 17584,
            value: 'xa_nam_thanh',
            division_type: 'xã',
            short_codename: 'nam_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Vĩnh Thành',
            code: 17587,
            value: 'xa_vinh_thanh',
            division_type: 'xã',
            short_codename: 'vinh_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Lý Thành',
            code: 17590,
            value: 'xa_ly_thanh',
            division_type: 'xã',
            short_codename: 'ly_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Khánh Thành',
            code: 17593,
            value: 'xa_khanh_thanh',
            division_type: 'xã',
            short_codename: 'khanh_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Viên Thành',
            code: 17596,
            value: 'xa_vien_thanh',
            division_type: 'xã',
            short_codename: 'vien_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Đại Thành',
            code: 17599,
            value: 'xa_dai_thanh',
            division_type: 'xã',
            short_codename: 'dai_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Liên Thành',
            code: 17602,
            value: 'xa_lien_thanh',
            division_type: 'xã',
            short_codename: 'lien_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Bảo Thành',
            code: 17605,
            value: 'xa_bao_thanh',
            division_type: 'xã',
            short_codename: 'bao_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Mỹ Thành',
            code: 17608,
            value: 'xa_my_thanh',
            division_type: 'xã',
            short_codename: 'my_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Công Thành',
            code: 17611,
            value: 'xa_cong_thanh',
            division_type: 'xã',
            short_codename: 'cong_thanh',
            parentId: 426,
          },
          {
            title: 'Xã Sơn Thành',
            code: 17614,
            value: 'xa_son_thanh',
            division_type: 'xã',
            short_codename: 'son_thanh',
            parentId: 426,
          },
        ],
        parentId: 40,
      },
      {
        title: 'Huyện Đô Lương',
        code: 427,
        value: 'huyen_do_luong',
        division_type: 'huyện',
        short_codename: 'do_luong',
        children: [
          {
            title: 'Thị trấn Đô Lương',
            code: 17617,
            value: 'thi_tran_do_luong',
            division_type: 'thị trấn',
            short_codename: 'do_luong',
            parentId: 427,
          },
          {
            title: 'Xã Giang Sơn Đông',
            code: 17619,
            value: 'xa_giang_son_dong',
            division_type: 'xã',
            short_codename: 'giang_son_dong',
            parentId: 427,
          },
          {
            title: 'Xã Giang Sơn Tây',
            code: 17620,
            value: 'xa_giang_son_tay',
            division_type: 'xã',
            short_codename: 'giang_son_tay',
            parentId: 427,
          },
          {
            title: 'Xã Lam Sơn',
            code: 17623,
            value: 'xa_lam_son',
            division_type: 'xã',
            short_codename: 'lam_son',
            parentId: 427,
          },
          {
            title: 'Xã Bồi Sơn',
            code: 17626,
            value: 'xa_boi_son',
            division_type: 'xã',
            short_codename: 'boi_son',
            parentId: 427,
          },
          {
            title: 'Xã Hồng Sơn',
            code: 17629,
            value: 'xa_hong_son',
            division_type: 'xã',
            short_codename: 'hong_son',
            parentId: 427,
          },
          {
            title: 'Xã Bài Sơn',
            code: 17632,
            value: 'xa_bai_son',
            division_type: 'xã',
            short_codename: 'bai_son',
            parentId: 427,
          },
          {
            title: 'Xã Ngọc Sơn',
            code: 17635,
            value: 'xa_ngoc_son',
            division_type: 'xã',
            short_codename: 'ngoc_son',
            parentId: 427,
          },
          {
            title: 'Xã Bắc Sơn',
            code: 17638,
            value: 'xa_bac_son',
            division_type: 'xã',
            short_codename: 'bac_son',
            parentId: 427,
          },
          {
            title: 'Xã Tràng Sơn',
            code: 17641,
            value: 'xa_trang_son',
            division_type: 'xã',
            short_codename: 'trang_son',
            parentId: 427,
          },
          {
            title: 'Xã Thượng Sơn',
            code: 17644,
            value: 'xa_thuong_son',
            division_type: 'xã',
            short_codename: 'thuong_son',
            parentId: 427,
          },
          {
            title: 'Xã Hòa Sơn',
            code: 17647,
            value: 'xa_hoa_son',
            division_type: 'xã',
            short_codename: 'hoa_son',
            parentId: 427,
          },
          {
            title: 'Xã Đặng Sơn',
            code: 17650,
            value: 'xa_dang_son',
            division_type: 'xã',
            short_codename: 'dang_son',
            parentId: 427,
          },
          {
            title: 'Xã Đông Sơn',
            code: 17653,
            value: 'xa_dong_son',
            division_type: 'xã',
            short_codename: 'dong_son',
            parentId: 427,
          },
          {
            title: 'Xã Nam Sơn',
            code: 17656,
            value: 'xa_nam_son',
            division_type: 'xã',
            short_codename: 'nam_son',
            parentId: 427,
          },
          {
            title: 'Xã Lưu Sơn',
            code: 17659,
            value: 'xa_luu_son',
            division_type: 'xã',
            short_codename: 'luu_son',
            parentId: 427,
          },
          {
            title: 'Xã Yên Sơn',
            code: 17662,
            value: 'xa_yen_son',
            division_type: 'xã',
            short_codename: 'yen_son',
            parentId: 427,
          },
          {
            title: 'Xã Văn Sơn',
            code: 17665,
            value: 'xa_van_son',
            division_type: 'xã',
            short_codename: 'van_son',
            parentId: 427,
          },
          {
            title: 'Xã Đà Sơn',
            code: 17668,
            value: 'xa_da_son',
            division_type: 'xã',
            short_codename: 'da_son',
            parentId: 427,
          },
          {
            title: 'Xã Lạc Sơn',
            code: 17671,
            value: 'xa_lac_son',
            division_type: 'xã',
            short_codename: 'lac_son',
            parentId: 427,
          },
          {
            title: 'Xã Tân Sơn',
            code: 17674,
            value: 'xa_tan_son',
            division_type: 'xã',
            short_codename: 'tan_son',
            parentId: 427,
          },
          {
            title: 'Xã Thái Sơn',
            code: 17677,
            value: 'xa_thai_son',
            division_type: 'xã',
            short_codename: 'thai_son',
            parentId: 427,
          },
          {
            title: 'Xã Quang Sơn',
            code: 17680,
            value: 'xa_quang_son',
            division_type: 'xã',
            short_codename: 'quang_son',
            parentId: 427,
          },
          {
            title: 'Xã Thịnh Sơn',
            code: 17683,
            value: 'xa_thinh_son',
            division_type: 'xã',
            short_codename: 'thinh_son',
            parentId: 427,
          },
          {
            title: 'Xã Trung Sơn',
            code: 17686,
            value: 'xa_trung_son',
            division_type: 'xã',
            short_codename: 'trung_son',
            parentId: 427,
          },
          {
            title: 'Xã Xuân Sơn',
            code: 17689,
            value: 'xa_xuan_son',
            division_type: 'xã',
            short_codename: 'xuan_son',
            parentId: 427,
          },
          {
            title: 'Xã Minh Sơn',
            code: 17692,
            value: 'xa_minh_son',
            division_type: 'xã',
            short_codename: 'minh_son',
            parentId: 427,
          },
          {
            title: 'Xã Thuận Sơn',
            code: 17695,
            value: 'xa_thuan_son',
            division_type: 'xã',
            short_codename: 'thuan_son',
            parentId: 427,
          },
          {
            title: 'Xã Nhân Sơn',
            code: 17698,
            value: 'xa_nhan_son',
            division_type: 'xã',
            short_codename: 'nhan_son',
            parentId: 427,
          },
          {
            title: 'Xã Hiến Sơn',
            code: 17701,
            value: 'xa_hien_son',
            division_type: 'xã',
            short_codename: 'hien_son',
            parentId: 427,
          },
          {
            title: 'Xã Mỹ Sơn',
            code: 17704,
            value: 'xa_my_son',
            division_type: 'xã',
            short_codename: 'my_son',
            parentId: 427,
          },
          {
            title: 'Xã Trù Sơn',
            code: 17707,
            value: 'xa_tru_son',
            division_type: 'xã',
            short_codename: 'tru_son',
            parentId: 427,
          },
          {
            title: 'Xã Đại Sơn',
            code: 17710,
            value: 'xa_dai_son',
            division_type: 'xã',
            short_codename: 'dai_son',
            parentId: 427,
          },
        ],
        parentId: 40,
      },
      {
        title: 'Huyện Thanh Chương',
        code: 428,
        value: 'huyen_thanh_chuong',
        division_type: 'huyện',
        short_codename: 'thanh_chuong',
        children: [
          {
            title: 'Thị trấn Thanh Chương',
            code: 17713,
            value: 'thi_tran_thanh_chuong',
            division_type: 'thị trấn',
            short_codename: 'thanh_chuong',
            parentId: 428,
          },
          {
            title: 'Xã Cát Văn',
            code: 17716,
            value: 'xa_cat_van',
            division_type: 'xã',
            short_codename: 'cat_van',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Nho',
            code: 17719,
            value: 'xa_thanh_nho',
            division_type: 'xã',
            short_codename: 'thanh_nho',
            parentId: 428,
          },
          {
            title: 'Xã Hạnh Lâm',
            code: 17722,
            value: 'xa_hanh_lam',
            division_type: 'xã',
            short_codename: 'hanh_lam',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Sơn',
            code: 17723,
            value: 'xa_thanh_son',
            division_type: 'xã',
            short_codename: 'thanh_son',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Hòa',
            code: 17725,
            value: 'xa_thanh_hoa',
            division_type: 'xã',
            short_codename: 'thanh_hoa',
            parentId: 428,
          },
          {
            title: 'Xã Phong Thịnh',
            code: 17728,
            value: 'xa_phong_thinh',
            division_type: 'xã',
            short_codename: 'phong_thinh',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Phong',
            code: 17731,
            value: 'xa_thanh_phong',
            division_type: 'xã',
            short_codename: 'thanh_phong',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Mỹ',
            code: 17734,
            value: 'xa_thanh_my',
            division_type: 'xã',
            short_codename: 'thanh_my',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Tiên',
            code: 17737,
            value: 'xa_thanh_tien',
            division_type: 'xã',
            short_codename: 'thanh_tien',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Liên',
            code: 17743,
            value: 'xa_thanh_lien',
            division_type: 'xã',
            short_codename: 'thanh_lien',
            parentId: 428,
          },
          {
            title: 'Xã Đại Đồng',
            code: 17749,
            value: 'xa_dai_dong',
            division_type: 'xã',
            short_codename: 'dai_dong',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Đồng',
            code: 17752,
            value: 'xa_thanh_dong',
            division_type: 'xã',
            short_codename: 'thanh_dong',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Ngọc',
            code: 17755,
            value: 'xa_thanh_ngoc',
            division_type: 'xã',
            short_codename: 'thanh_ngoc',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Hương',
            code: 17758,
            value: 'xa_thanh_huong',
            division_type: 'xã',
            short_codename: 'thanh_huong',
            parentId: 428,
          },
          {
            title: 'Xã Ngọc Lâm',
            code: 17759,
            value: 'xa_ngoc_lam',
            division_type: 'xã',
            short_codename: 'ngoc_lam',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Lĩnh',
            code: 17761,
            value: 'xa_thanh_linh',
            division_type: 'xã',
            short_codename: 'thanh_linh',
            parentId: 428,
          },
          {
            title: 'Xã Đồng Văn',
            code: 17764,
            value: 'xa_dong_van',
            division_type: 'xã',
            short_codename: 'dong_van',
            parentId: 428,
          },
          {
            title: 'Xã Ngọc Sơn',
            code: 17767,
            value: 'xa_ngoc_son',
            division_type: 'xã',
            short_codename: 'ngoc_son',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Thịnh',
            code: 17770,
            value: 'xa_thanh_thinh',
            division_type: 'xã',
            short_codename: 'thanh_thinh',
            parentId: 428,
          },
          {
            title: 'Xã Thanh An',
            code: 17773,
            value: 'xa_thanh_an',
            division_type: 'xã',
            short_codename: 'thanh_an',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Chi',
            code: 17776,
            value: 'xa_thanh_chi',
            division_type: 'xã',
            short_codename: 'thanh_chi',
            parentId: 428,
          },
          {
            title: 'Xã Xuân Tường',
            code: 17779,
            value: 'xa_xuan_tuong',
            division_type: 'xã',
            short_codename: 'xuan_tuong',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Dương',
            code: 17782,
            value: 'xa_thanh_duong',
            division_type: 'xã',
            short_codename: 'thanh_duong',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Lương',
            code: 17785,
            value: 'xa_thanh_luong',
            division_type: 'xã',
            short_codename: 'thanh_luong',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Khê',
            code: 17788,
            value: 'xa_thanh_khe',
            division_type: 'xã',
            short_codename: 'thanh_khe',
            parentId: 428,
          },
          {
            title: 'Xã Võ Liệt',
            code: 17791,
            value: 'xa_vo_liet',
            division_type: 'xã',
            short_codename: 'vo_liet',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Long',
            code: 17794,
            value: 'xa_thanh_long',
            division_type: 'xã',
            short_codename: 'thanh_long',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Thủy',
            code: 17797,
            value: 'xa_thanh_thuy',
            division_type: 'xã',
            short_codename: 'thanh_thuy',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Khai',
            code: 17800,
            value: 'xa_thanh_khai',
            division_type: 'xã',
            short_codename: 'thanh_khai',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Yên',
            code: 17803,
            value: 'xa_thanh_yen',
            division_type: 'xã',
            short_codename: 'thanh_yen',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Hà',
            code: 17806,
            value: 'xa_thanh_ha',
            division_type: 'xã',
            short_codename: 'thanh_ha',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Giang',
            code: 17809,
            value: 'xa_thanh_giang',
            division_type: 'xã',
            short_codename: 'thanh_giang',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Tùng',
            code: 17812,
            value: 'xa_thanh_tung',
            division_type: 'xã',
            short_codename: 'thanh_tung',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Lâm',
            code: 17815,
            value: 'xa_thanh_lam',
            division_type: 'xã',
            short_codename: 'thanh_lam',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Mai',
            code: 17818,
            value: 'xa_thanh_mai',
            division_type: 'xã',
            short_codename: 'thanh_mai',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Xuân',
            code: 17821,
            value: 'xa_thanh_xuan',
            division_type: 'xã',
            short_codename: 'thanh_xuan',
            parentId: 428,
          },
          {
            title: 'Xã Thanh Đức',
            code: 17824,
            value: 'xa_thanh_duc',
            division_type: 'xã',
            short_codename: 'thanh_duc',
            parentId: 428,
          },
        ],
        parentId: 40,
      },
      {
        title: 'Huyện Nghi Lộc',
        code: 429,
        value: 'huyen_nghi_loc',
        division_type: 'huyện',
        short_codename: 'nghi_loc',
        children: [
          {
            title: 'Thị trấn Quán Hành',
            code: 17827,
            value: 'thi_tran_quan_hanh',
            division_type: 'thị trấn',
            short_codename: 'quan_hanh',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Văn',
            code: 17830,
            value: 'xa_nghi_van',
            division_type: 'xã',
            short_codename: 'xa_nghi_van',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Yên',
            code: 17833,
            value: 'xa_nghi_yen',
            division_type: 'xã',
            short_codename: 'nghi_yen',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Tiến',
            code: 17836,
            value: 'xa_nghi_tien',
            division_type: 'xã',
            short_codename: 'nghi_tien',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Hưng',
            code: 17839,
            value: 'xa_nghi_hung',
            division_type: 'xã',
            short_codename: 'nghi_hung',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Đồng',
            code: 17842,
            value: 'xa_nghi_dong',
            division_type: 'xã',
            short_codename: 'nghi_dong',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Thiết',
            code: 17845,
            value: 'xa_nghi_thiet',
            division_type: 'xã',
            short_codename: 'nghi_thiet',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Lâm',
            code: 17848,
            value: 'xa_nghi_lam',
            division_type: 'xã',
            short_codename: 'nghi_lam',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Quang',
            code: 17851,
            value: 'xa_nghi_quang',
            division_type: 'xã',
            short_codename: 'nghi_quang',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Kiều',
            code: 17854,
            value: 'xa_nghi_kieu',
            division_type: 'xã',
            short_codename: 'nghi_kieu',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Mỹ',
            code: 17857,
            value: 'xa_nghi_my',
            division_type: 'xã',
            short_codename: 'nghi_my',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Phương',
            code: 17860,
            value: 'xa_nghi_phuong',
            division_type: 'xã',
            short_codename: 'nghi_phuong',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Thuận',
            code: 17863,
            value: 'xa_nghi_thuan',
            division_type: 'xã',
            short_codename: 'nghi_thuan',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Long',
            code: 17866,
            value: 'xa_nghi_long',
            division_type: 'xã',
            short_codename: 'nghi_long',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Xá',
            code: 17869,
            value: 'xa_nghi_xa',
            division_type: 'xã',
            short_codename: 'nghi_xa',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Hoa',
            code: 17875,
            value: 'xa_nghi_hoa',
            division_type: 'xã',
            short_codename: 'nghi_hoa',
            parentId: 429,
          },
          {
            title: 'Xã Khánh Hợp',
            code: 17878,
            value: 'xa_khanh_hop',
            division_type: 'xã',
            short_codename: 'khanh_hop',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Thịnh',
            code: 17881,
            value: 'xa_nghi_thinh',
            division_type: 'xã',
            short_codename: 'nghi_thinh',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Công Bắc',
            code: 17884,
            value: 'xa_nghi_cong_bac',
            division_type: 'xã',
            short_codename: 'nghi_cong_bac',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Công Nam',
            code: 17887,
            value: 'xa_nghi_cong_nam',
            division_type: 'xã',
            short_codename: 'nghi_cong_nam',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Thạch',
            code: 17890,
            value: 'xa_nghi_thach',
            division_type: 'xã',
            short_codename: 'nghi_thach',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Trung',
            code: 17893,
            value: 'xa_nghi_trung',
            division_type: 'xã',
            short_codename: 'nghi_trung',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Trường',
            code: 17896,
            value: 'xa_nghi_truong',
            division_type: 'xã',
            short_codename: 'nghi_truong',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Diên',
            code: 17899,
            value: 'xa_nghi_dien',
            division_type: 'xã',
            short_codename: 'nghi_dien',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Phong',
            code: 17902,
            value: 'xa_nghi_phong',
            division_type: 'xã',
            short_codename: 'nghi_phong',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Xuân',
            code: 17905,
            value: 'xa_nghi_xuan',
            division_type: 'xã',
            short_codename: 'nghi_xuan',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Vạn',
            code: 17911,
            value: 'xa_nghi_van',
            division_type: 'xã',
            short_codename: 'xa_nghi_van',
            parentId: 429,
          },
          {
            title: 'Xã Phúc Thọ',
            code: 17917,
            value: 'xa_phuc_tho',
            division_type: 'xã',
            short_codename: 'phuc_tho',
            parentId: 429,
          },
          {
            title: 'Xã Nghi Thái',
            code: 17926,
            value: 'xa_nghi_thai',
            division_type: 'xã',
            short_codename: 'nghi_thai',
            parentId: 429,
          },
        ],
        parentId: 40,
      },
      {
        title: 'Huyện Nam Đàn',
        code: 430,
        value: 'huyen_nam_dan',
        division_type: 'huyện',
        short_codename: 'nam_dan',
        children: [
          {
            title: 'Xã Nam Hưng',
            code: 17932,
            value: 'xa_nam_hung',
            division_type: 'xã',
            short_codename: 'nam_hung',
            parentId: 430,
          },
          {
            title: 'Xã Nam Nghĩa',
            code: 17935,
            value: 'xa_nam_nghia',
            division_type: 'xã',
            short_codename: 'nam_nghia',
            parentId: 430,
          },
          {
            title: 'Xã Nam Thanh',
            code: 17938,
            value: 'xa_nam_thanh',
            division_type: 'xã',
            short_codename: 'nam_thanh',
            parentId: 430,
          },
          {
            title: 'Xã Nam Anh',
            code: 17941,
            value: 'xa_nam_anh',
            division_type: 'xã',
            short_codename: 'nam_anh',
            parentId: 430,
          },
          {
            title: 'Xã Nam Xuân',
            code: 17944,
            value: 'xa_nam_xuan',
            division_type: 'xã',
            short_codename: 'nam_xuan',
            parentId: 430,
          },
          {
            title: 'Xã Nam Thái',
            code: 17947,
            value: 'xa_nam_thai',
            division_type: 'xã',
            short_codename: 'nam_thai',
            parentId: 430,
          },
          {
            title: 'Thị trấn Nam Đàn',
            code: 17950,
            value: 'thi_tran_nam_dan',
            division_type: 'thị trấn',
            short_codename: 'nam_dan',
            parentId: 430,
          },
          {
            title: 'Xã Nam Lĩnh',
            code: 17953,
            value: 'xa_nam_linh',
            division_type: 'xã',
            short_codename: 'nam_linh',
            parentId: 430,
          },
          {
            title: 'Xã Nam Giang',
            code: 17956,
            value: 'xa_nam_giang',
            division_type: 'xã',
            short_codename: 'nam_giang',
            parentId: 430,
          },
          {
            title: 'Xã Xuân Hòa',
            code: 17959,
            value: 'xa_xuan_hoa',
            division_type: 'xã',
            short_codename: 'xuan_hoa',
            parentId: 430,
          },
          {
            title: 'Xã Hùng Tiến',
            code: 17962,
            value: 'xa_hung_tien',
            division_type: 'xã',
            short_codename: 'hung_tien',
            parentId: 430,
          },
          {
            title: 'Xã Thượng Tân Lộc',
            code: 17968,
            value: 'xa_thuong_tan_loc',
            division_type: 'xã',
            short_codename: 'thuong_tan_loc',
            parentId: 430,
          },
          {
            title: 'Xã Kim Liên',
            code: 17971,
            value: 'xa_kim_lien',
            division_type: 'xã',
            short_codename: 'kim_lien',
            parentId: 430,
          },
          {
            title: 'Xã Hồng Long',
            code: 17977,
            value: 'xa_hong_long',
            division_type: 'xã',
            short_codename: 'hong_long',
            parentId: 430,
          },
          {
            title: 'Xã Xuân Lâm',
            code: 17980,
            value: 'xa_xuan_lam',
            division_type: 'xã',
            short_codename: 'xuan_lam',
            parentId: 430,
          },
          {
            title: 'Xã Nam Cát',
            code: 17983,
            value: 'xa_nam_cat',
            division_type: 'xã',
            short_codename: 'nam_cat',
            parentId: 430,
          },
          {
            title: 'Xã Khánh Sơn',
            code: 17986,
            value: 'xa_khanh_son',
            division_type: 'xã',
            short_codename: 'khanh_son',
            parentId: 430,
          },
          {
            title: 'Xã Trung Phúc Cường',
            code: 17989,
            value: 'xa_trung_phuc_cuong',
            division_type: 'xã',
            short_codename: 'trung_phuc_cuong',
            parentId: 430,
          },
          {
            title: 'Xã Nam Kim',
            code: 17998,
            value: 'xa_nam_kim',
            division_type: 'xã',
            short_codename: 'nam_kim',
            parentId: 430,
          },
        ],
        parentId: 40,
      },
      {
        title: 'Huyện Hưng Nguyên',
        code: 431,
        value: 'huyen_hung_nguyen',
        division_type: 'huyện',
        short_codename: 'hung_nguyen',
        children: [
          {
            title: 'Thị trấn Hưng Nguyên',
            code: 18001,
            value: 'thi_tran_hung_nguyen',
            division_type: 'thị trấn',
            short_codename: 'hung_nguyen',
            parentId: 431,
          },
          {
            title: 'Xã Hưng Trung',
            code: 18004,
            value: 'xa_hung_trung',
            division_type: 'xã',
            short_codename: 'hung_trung',
            parentId: 431,
          },
          {
            title: 'Xã Hưng Yên',
            code: 18007,
            value: 'xa_hung_yen',
            division_type: 'xã',
            short_codename: 'hung_yen',
            parentId: 431,
          },
          {
            title: 'Xã Hưng Yên Bắc',
            code: 18008,
            value: 'xa_hung_yen_bac',
            division_type: 'xã',
            short_codename: 'hung_yen_bac',
            parentId: 431,
          },
          {
            title: 'Xã Hưng Tây',
            code: 18010,
            value: 'xa_hung_tay',
            division_type: 'xã',
            short_codename: 'hung_tay',
            parentId: 431,
          },
          {
            title: 'Xã Hưng Đạo',
            code: 18016,
            value: 'xa_hung_dao',
            division_type: 'xã',
            short_codename: 'hung_dao',
            parentId: 431,
          },
          {
            title: 'Xã Hưng Mỹ',
            code: 18019,
            value: 'xa_hung_my',
            division_type: 'xã',
            short_codename: 'hung_my',
            parentId: 431,
          },
          {
            title: 'Xã Hưng Thịnh',
            code: 18022,
            value: 'xa_hung_thinh',
            division_type: 'xã',
            short_codename: 'hung_thinh',
            parentId: 431,
          },
          {
            title: 'Xã Hưng Lĩnh',
            code: 18025,
            value: 'xa_hung_linh',
            division_type: 'xã',
            short_codename: 'hung_linh',
            parentId: 431,
          },
          {
            title: 'Xã Hưng Thông',
            code: 18028,
            value: 'xa_hung_thong',
            division_type: 'xã',
            short_codename: 'hung_thong',
            parentId: 431,
          },
          {
            title: 'Xã Hưng Tân',
            code: 18031,
            value: 'xa_hung_tan',
            division_type: 'xã',
            short_codename: 'hung_tan',
            parentId: 431,
          },
          {
            title: 'Xã Hưng Lợi',
            code: 18034,
            value: 'xa_hung_loi',
            division_type: 'xã',
            short_codename: 'hung_loi',
            parentId: 431,
          },
          {
            title: 'Xã Hưng Nghĩa',
            code: 18037,
            value: 'xa_hung_nghia',
            division_type: 'xã',
            short_codename: 'hung_nghia',
            parentId: 431,
          },
          {
            title: 'Xã Hưng Phúc',
            code: 18040,
            value: 'xa_hung_phuc',
            division_type: 'xã',
            short_codename: 'hung_phuc',
            parentId: 431,
          },
          {
            title: 'Xã Long Xá',
            code: 18043,
            value: 'xa_long_xa',
            division_type: 'xã',
            short_codename: 'long_xa',
            parentId: 431,
          },
          {
            title: 'Xã Châu Nhân',
            code: 18052,
            value: 'xa_chau_nhan',
            division_type: 'xã',
            short_codename: 'chau_nhan',
            parentId: 431,
          },
          {
            title: 'Xã Xuân Lam',
            code: 18055,
            value: 'xa_xuan_lam',
            division_type: 'xã',
            short_codename: 'xuan_lam',
            parentId: 431,
          },
          {
            title: 'Xã Hưng Thành',
            code: 18064,
            value: 'xa_hung_thanh',
            division_type: 'xã',
            short_codename: 'hung_thanh',
            parentId: 431,
          },
        ],
        parentId: 40,
      },
      {
        title: 'Thị xã Hoàng Mai',
        code: 432,
        value: 'thi_xa_hoang_mai',
        division_type: 'thị xã',
        short_codename: 'hoang_mai',
        children: [
          {
            title: 'Xã Quỳnh Vinh',
            code: 17104,
            value: 'xa_quynh_vinh',
            division_type: 'xã',
            short_codename: 'quynh_vinh',
            parentId: 432,
          },
          {
            title: 'Xã Quỳnh Lộc',
            code: 17107,
            value: 'xa_quynh_loc',
            division_type: 'xã',
            short_codename: 'quynh_loc',
            parentId: 432,
          },
          {
            title: 'Phường Quỳnh Thiện',
            code: 17110,
            value: 'phuong_quynh_thien',
            division_type: 'phường',
            short_codename: 'quynh_thien',
            parentId: 432,
          },
          {
            title: 'Xã Quỳnh Lập',
            code: 17113,
            value: 'xa_quynh_lap',
            division_type: 'xã',
            short_codename: 'quynh_lap',
            parentId: 432,
          },
          {
            title: 'Xã Quỳnh Trang',
            code: 17116,
            value: 'xa_quynh_trang',
            division_type: 'xã',
            short_codename: 'quynh_trang',
            parentId: 432,
          },
          {
            title: 'Phường Mai Hùng',
            code: 17125,
            value: 'phuong_mai_hung',
            division_type: 'phường',
            short_codename: 'mai_hung',
            parentId: 432,
          },
          {
            title: 'Phường Quỳnh Dị',
            code: 17128,
            value: 'phuong_quynh_di',
            division_type: 'phường',
            short_codename: 'quynh_di',
            parentId: 432,
          },
          {
            title: 'Phường Quỳnh Xuân',
            code: 17131,
            value: 'phuong_quynh_xuan',
            division_type: 'phường',
            short_codename: 'quynh_xuan',
            parentId: 432,
          },
          {
            title: 'Phường Quỳnh Phương',
            code: 17134,
            value: 'phuong_quynh_phuong',
            division_type: 'phường',
            short_codename: 'quynh_phuong',
            parentId: 432,
          },
          {
            title: 'Xã Quỳnh Liên',
            code: 17137,
            value: 'xa_quynh_lien',
            division_type: 'xã',
            short_codename: 'quynh_lien',
            parentId: 432,
          },
        ],
        parentId: 40,
      },
    ],
  },
  {
    title: 'Tỉnh Hà Tĩnh',
    code: 42,
    value: 'tinh_ha_tinh',
    division_type: 'tỉnh',
    phone_code: 239,
    children: [
      {
        title: 'Thành phố Hà Tĩnh',
        code: 436,
        value: 'thanh_pho_ha_tinh',
        division_type: 'thành phố',
        short_codename: 'ha_tinh',
        children: [
          {
            title: 'Phường Trần Phú',
            code: 18070,
            value: 'phuong_tran_phu',
            division_type: 'phường',
            short_codename: 'tran_phu',
            parentId: 436,
          },
          {
            title: 'Phường Nam Hà',
            code: 18073,
            value: 'phuong_nam_ha',
            division_type: 'phường',
            short_codename: 'nam_ha',
            parentId: 436,
          },
          {
            title: 'Phường Bắc Hà',
            code: 18076,
            value: 'phuong_bac_ha',
            division_type: 'phường',
            short_codename: 'bac_ha',
            parentId: 436,
          },
          {
            title: 'Phường Nguyễn Du',
            code: 18077,
            value: 'phuong_nguyen_du',
            division_type: 'phường',
            short_codename: 'nguyen_du',
            parentId: 436,
          },
          {
            title: 'Phường Tân Giang',
            code: 18079,
            value: 'phuong_tan_giang',
            division_type: 'phường',
            short_codename: 'tan_giang',
            parentId: 436,
          },
          {
            title: 'Phường Đại Nài',
            code: 18082,
            value: 'phuong_dai_nai',
            division_type: 'phường',
            short_codename: 'dai_nai',
            parentId: 436,
          },
          {
            title: 'Phường Hà Huy Tập',
            code: 18085,
            value: 'phuong_ha_huy_tap',
            division_type: 'phường',
            short_codename: 'ha_huy_tap',
            parentId: 436,
          },
          {
            title: 'Xã Thạch Trung',
            code: 18088,
            value: 'xa_thach_trung',
            division_type: 'xã',
            short_codename: 'thach_trung',
            parentId: 436,
          },
          {
            title: 'Phường Thạch Quý',
            code: 18091,
            value: 'phuong_thach_quy',
            division_type: 'phường',
            short_codename: 'thach_quy',
            parentId: 436,
          },
          {
            title: 'Phường Thạch Linh',
            code: 18094,
            value: 'phuong_thach_linh',
            division_type: 'phường',
            short_codename: 'thach_linh',
            parentId: 436,
          },
          {
            title: 'Phường Văn Yên',
            code: 18097,
            value: 'phuong_van_yen',
            division_type: 'phường',
            short_codename: 'van_yen',
            parentId: 436,
          },
          {
            title: 'Xã Thạch Hạ',
            code: 18100,
            value: 'xa_thach_ha',
            division_type: 'xã',
            short_codename: 'thach_ha',
            parentId: 436,
          },
          {
            title: 'Xã Đồng Môn',
            code: 18103,
            value: 'xa_dong_mon',
            division_type: 'xã',
            short_codename: 'dong_mon',
            parentId: 436,
          },
          {
            title: 'Xã Thạch Hưng',
            code: 18109,
            value: 'xa_thach_hung',
            division_type: 'xã',
            short_codename: 'thach_hung',
            parentId: 436,
          },
          {
            title: 'Xã Thạch Bình',
            code: 18112,
            value: 'xa_thach_binh',
            division_type: 'xã',
            short_codename: 'thach_binh',
            parentId: 436,
          },
        ],
        parentId: 42,
      },
      {
        title: 'Thị xã Hồng Lĩnh',
        code: 437,
        value: 'thi_xa_hong_linh',
        division_type: 'thị xã',
        short_codename: 'hong_linh',
        children: [
          {
            title: 'Phường Bắc Hồng',
            code: 18115,
            value: 'phuong_bac_hong',
            division_type: 'phường',
            short_codename: 'bac_hong',
            parentId: 437,
          },
          {
            title: 'Phường Nam Hồng',
            code: 18118,
            value: 'phuong_nam_hong',
            division_type: 'phường',
            short_codename: 'nam_hong',
            parentId: 437,
          },
          {
            title: 'Phường Trung Lương',
            code: 18121,
            value: 'phuong_trung_luong',
            division_type: 'phường',
            short_codename: 'trung_luong',
            parentId: 437,
          },
          {
            title: 'Phường Đức Thuận',
            code: 18124,
            value: 'phuong_duc_thuan',
            division_type: 'phường',
            short_codename: 'duc_thuan',
            parentId: 437,
          },
          {
            title: 'Phường Đậu Liêu',
            code: 18127,
            value: 'phuong_dau_lieu',
            division_type: 'phường',
            short_codename: 'dau_lieu',
            parentId: 437,
          },
          {
            title: 'Xã Thuận Lộc',
            code: 18130,
            value: 'xa_thuan_loc',
            division_type: 'xã',
            short_codename: 'thuan_loc',
            parentId: 437,
          },
        ],
        parentId: 42,
      },
      {
        title: 'Huyện Hương Sơn',
        code: 439,
        value: 'huyen_huong_son',
        division_type: 'huyện',
        short_codename: 'huong_son',
        children: [
          {
            title: 'Thị trấn Phố Châu',
            code: 18133,
            value: 'thi_tran_pho_chau',
            division_type: 'thị trấn',
            short_codename: 'pho_chau',
            parentId: 439,
          },
          {
            title: 'Thị trấn Tây Sơn',
            code: 18136,
            value: 'thi_tran_tay_son',
            division_type: 'thị trấn',
            short_codename: 'tay_son',
            parentId: 439,
          },
          {
            title: 'Xã Sơn Hồng',
            code: 18139,
            value: 'xa_son_hong',
            division_type: 'xã',
            short_codename: 'son_hong',
            parentId: 439,
          },
          {
            title: 'Xã Sơn Tiến',
            code: 18142,
            value: 'xa_son_tien',
            division_type: 'xã',
            short_codename: 'son_tien',
            parentId: 439,
          },
          {
            title: 'Xã Sơn Lâm',
            code: 18145,
            value: 'xa_son_lam',
            division_type: 'xã',
            short_codename: 'son_lam',
            parentId: 439,
          },
          {
            title: 'Xã Sơn Lễ',
            code: 18148,
            value: 'xa_son_le',
            division_type: 'xã',
            short_codename: 'son_le',
            parentId: 439,
          },
          {
            title: 'Xã Sơn Giang',
            code: 18157,
            value: 'xa_son_giang',
            division_type: 'xã',
            short_codename: 'son_giang',
            parentId: 439,
          },
          {
            title: 'Xã Sơn Lĩnh',
            code: 18160,
            value: 'xa_son_linh',
            division_type: 'xã',
            short_codename: 'son_linh',
            parentId: 439,
          },
          {
            title: 'Xã An Hòa Thịnh',
            code: 18163,
            value: 'xa_an_hoa_thinh',
            division_type: 'xã',
            short_codename: 'an_hoa_thinh',
            parentId: 439,
          },
          {
            title: 'Xã Sơn Tây',
            code: 18172,
            value: 'xa_son_tay',
            division_type: 'xã',
            short_codename: 'son_tay',
            parentId: 439,
          },
          {
            title: 'Xã Sơn Ninh',
            code: 18175,
            value: 'xa_son_ninh',
            division_type: 'xã',
            short_codename: 'son_ninh',
            parentId: 439,
          },
          {
            title: 'Xã Sơn Châu',
            code: 18178,
            value: 'xa_son_chau',
            division_type: 'xã',
            short_codename: 'son_chau',
            parentId: 439,
          },
          {
            title: 'Xã Tân Mỹ Hà',
            code: 18181,
            value: 'xa_tan_my_ha',
            division_type: 'xã',
            short_codename: 'tan_my_ha',
            parentId: 439,
          },
          {
            title: 'Xã Quang Diệm',
            code: 18184,
            value: 'xa_quang_diem',
            division_type: 'xã',
            short_codename: 'quang_diem',
            parentId: 439,
          },
          {
            title: 'Xã Sơn Trung',
            code: 18187,
            value: 'xa_son_trung',
            division_type: 'xã',
            short_codename: 'son_trung',
            parentId: 439,
          },
          {
            title: 'Xã Sơn Bằng',
            code: 18190,
            value: 'xa_son_bang',
            division_type: 'xã',
            short_codename: 'son_bang',
            parentId: 439,
          },
          {
            title: 'Xã Sơn Bình',
            code: 18193,
            value: 'xa_son_binh',
            division_type: 'xã',
            short_codename: 'son_binh',
            parentId: 439,
          },
          {
            title: 'Xã Sơn Kim 1',
            code: 18196,
            value: 'xa_son_kim_1',
            division_type: 'xã',
            short_codename: 'son_kim_1',
            parentId: 439,
          },
          {
            title: 'Xã Sơn Kim 2',
            code: 18199,
            value: 'xa_son_kim_2',
            division_type: 'xã',
            short_codename: 'son_kim_2',
            parentId: 439,
          },
          {
            title: 'Xã Sơn Trà',
            code: 18202,
            value: 'xa_son_tra',
            division_type: 'xã',
            short_codename: 'son_tra',
            parentId: 439,
          },
          {
            title: 'Xã Sơn Long',
            code: 18205,
            value: 'xa_son_long',
            division_type: 'xã',
            short_codename: 'son_long',
            parentId: 439,
          },
          {
            title: 'Xã Kim Hoa',
            code: 18211,
            value: 'xa_kim_hoa',
            division_type: 'xã',
            short_codename: 'kim_hoa',
            parentId: 439,
          },
          {
            title: 'Xã Sơn Hàm',
            code: 18214,
            value: 'xa_son_ham',
            division_type: 'xã',
            short_codename: 'son_ham',
            parentId: 439,
          },
          {
            title: 'Xã Sơn Phú',
            code: 18217,
            value: 'xa_son_phu',
            division_type: 'xã',
            short_codename: 'son_phu',
            parentId: 439,
          },
          {
            title: 'Xã Sơn Trường',
            code: 18223,
            value: 'xa_son_truong',
            division_type: 'xã',
            short_codename: 'son_truong',
            parentId: 439,
          },
        ],
        parentId: 42,
      },
      {
        title: 'Huyện Đức Thọ',
        code: 440,
        value: 'huyen_duc_tho',
        division_type: 'huyện',
        short_codename: 'duc_tho',
        children: [
          {
            title: 'Thị trấn Đức Thọ',
            code: 18229,
            value: 'thi_tran_duc_tho',
            division_type: 'thị trấn',
            short_codename: 'duc_tho',
            parentId: 440,
          },
          {
            title: 'Xã Quang Vĩnh',
            code: 18235,
            value: 'xa_quang_vinh',
            division_type: 'xã',
            short_codename: 'quang_vinh',
            parentId: 440,
          },
          {
            title: 'Xã Tùng Châu',
            code: 18241,
            value: 'xa_tung_chau',
            division_type: 'xã',
            short_codename: 'tung_chau',
            parentId: 440,
          },
          {
            title: 'Xã Trường Sơn',
            code: 18244,
            value: 'xa_truong_son',
            division_type: 'xã',
            short_codename: 'truong_son',
            parentId: 440,
          },
          {
            title: 'Xã Liên Minh',
            code: 18247,
            value: 'xa_lien_minh',
            division_type: 'xã',
            short_codename: 'lien_minh',
            parentId: 440,
          },
          {
            title: 'Xã Yên Hồ',
            code: 18253,
            value: 'xa_yen_ho',
            division_type: 'xã',
            short_codename: 'yen_ho',
            parentId: 440,
          },
          {
            title: 'Xã Tùng Ảnh',
            code: 18259,
            value: 'xa_tung_anh',
            division_type: 'xã',
            short_codename: 'tung_anh',
            parentId: 440,
          },
          {
            title: 'Xã Bùi La Nhân',
            code: 18262,
            value: 'xa_bui_la_nhan',
            division_type: 'xã',
            short_codename: 'bui_la_nhan',
            parentId: 440,
          },
          {
            title: 'Xã Thanh Bình Thịnh',
            code: 18274,
            value: 'xa_thanh_binh_thinh',
            division_type: 'xã',
            short_codename: 'thanh_binh_thinh',
            parentId: 440,
          },
          {
            title: 'Xã Lâm Trung Thủy',
            code: 18277,
            value: 'xa_lam_trung_thuy',
            division_type: 'xã',
            short_codename: 'lam_trung_thuy',
            parentId: 440,
          },
          {
            title: 'Xã Hòa Lạc',
            code: 18280,
            value: 'xa_hoa_lac',
            division_type: 'xã',
            short_codename: 'hoa_lac',
            parentId: 440,
          },
          {
            title: 'Xã Tân Dân',
            code: 18283,
            value: 'xa_tan_dan',
            division_type: 'xã',
            short_codename: 'tan_dan',
            parentId: 440,
          },
          {
            title: 'Xã An Dũng',
            code: 18298,
            value: 'xa_an_dung',
            division_type: 'xã',
            short_codename: 'an_dung',
            parentId: 440,
          },
          {
            title: 'Xã Đức Đồng',
            code: 18304,
            value: 'xa_duc_dong',
            division_type: 'xã',
            short_codename: 'duc_dong',
            parentId: 440,
          },
          {
            title: 'Xã Đức Lạng',
            code: 18307,
            value: 'xa_duc_lang',
            division_type: 'xã',
            short_codename: 'duc_lang',
            parentId: 440,
          },
          {
            title: 'Xã Tân Hương',
            code: 18310,
            value: 'xa_tan_huong',
            division_type: 'xã',
            short_codename: 'tan_huong',
            parentId: 440,
          },
        ],
        parentId: 42,
      },
      {
        title: 'Huyện Vũ Quang',
        code: 441,
        value: 'huyen_vu_quang',
        division_type: 'huyện',
        short_codename: 'vu_quang',
        children: [
          {
            title: 'Thị trấn Vũ Quang',
            code: 18313,
            value: 'thi_tran_vu_quang',
            division_type: 'thị trấn',
            short_codename: 'vu_quang',
            parentId: 441,
          },
          {
            title: 'Xã Ân Phú',
            code: 18316,
            value: 'xa_an_phu',
            division_type: 'xã',
            short_codename: 'an_phu',
            parentId: 441,
          },
          {
            title: 'Xã Đức Giang',
            code: 18319,
            value: 'xa_duc_giang',
            division_type: 'xã',
            short_codename: 'duc_giang',
            parentId: 441,
          },
          {
            title: 'Xã Đức Lĩnh',
            code: 18322,
            value: 'xa_duc_linh',
            division_type: 'xã',
            short_codename: 'duc_linh',
            parentId: 441,
          },
          {
            title: 'Xã Thọ Điền',
            code: 18325,
            value: 'xa_tho_dien',
            division_type: 'xã',
            short_codename: 'tho_dien',
            parentId: 441,
          },
          {
            title: 'Xã Đức Hương',
            code: 18328,
            value: 'xa_duc_huong',
            division_type: 'xã',
            short_codename: 'duc_huong',
            parentId: 441,
          },
          {
            title: 'Xã Đức Bồng',
            code: 18331,
            value: 'xa_duc_bong',
            division_type: 'xã',
            short_codename: 'duc_bong',
            parentId: 441,
          },
          {
            title: 'Xã Đức Liên',
            code: 18334,
            value: 'xa_duc_lien',
            division_type: 'xã',
            short_codename: 'duc_lien',
            parentId: 441,
          },
          {
            title: 'Xã Hương Minh',
            code: 18340,
            value: 'xa_huong_minh',
            division_type: 'xã',
            short_codename: 'huong_minh',
            parentId: 441,
          },
          {
            title: 'Xã Quang Thọ',
            code: 18343,
            value: 'xa_quang_tho',
            division_type: 'xã',
            short_codename: 'quang_tho',
            parentId: 441,
          },
        ],
        parentId: 42,
      },
      {
        title: 'Huyện Nghi Xuân',
        code: 442,
        value: 'huyen_nghi_xuan',
        division_type: 'huyện',
        short_codename: 'nghi_xuan',
        children: [
          {
            title: 'Thị trấn Xuân An',
            code: 18352,
            value: 'thi_tran_xuan_an',
            division_type: 'thị trấn',
            short_codename: 'xuan_an',
            parentId: 442,
          },
          {
            title: 'Xã Xuân Hội',
            code: 18355,
            value: 'xa_xuan_hoi',
            division_type: 'xã',
            short_codename: 'xuan_hoi',
            parentId: 442,
          },
          {
            title: 'Xã Đan Trường',
            code: 18358,
            value: 'xa_dan_truong',
            division_type: 'xã',
            short_codename: 'dan_truong',
            parentId: 442,
          },
          {
            title: 'Xã Xuân Phổ',
            code: 18364,
            value: 'xa_xuan_pho',
            division_type: 'xã',
            short_codename: 'xuan_pho',
            parentId: 442,
          },
          {
            title: 'Xã Xuân Hải',
            code: 18367,
            value: 'xa_xuan_hai',
            division_type: 'xã',
            short_codename: 'xuan_hai',
            parentId: 442,
          },
          {
            title: 'Xã Xuân Giang',
            code: 18370,
            value: 'xa_xuan_giang',
            division_type: 'xã',
            short_codename: 'xuan_giang',
            parentId: 442,
          },
          {
            title: 'Thị trấn Tiên Điền',
            code: 18373,
            value: 'thi_tran_tien_dien',
            division_type: 'thị trấn',
            short_codename: 'tien_dien',
            parentId: 442,
          },
          {
            title: 'Xã Xuân Yên',
            code: 18376,
            value: 'xa_xuan_yen',
            division_type: 'xã',
            short_codename: 'xuan_yen',
            parentId: 442,
          },
          {
            title: 'Xã Xuân Mỹ',
            code: 18379,
            value: 'xa_xuan_my',
            division_type: 'xã',
            short_codename: 'xuan_my',
            parentId: 442,
          },
          {
            title: 'Xã Xuân Thành',
            code: 18382,
            value: 'xa_xuan_thanh',
            division_type: 'xã',
            short_codename: 'xuan_thanh',
            parentId: 442,
          },
          {
            title: 'Xã Xuân Viên',
            code: 18385,
            value: 'xa_xuan_vien',
            division_type: 'xã',
            short_codename: 'xuan_vien',
            parentId: 442,
          },
          {
            title: 'Xã Xuân Hồng',
            code: 18388,
            value: 'xa_xuan_hong',
            division_type: 'xã',
            short_codename: 'xuan_hong',
            parentId: 442,
          },
          {
            title: 'Xã Cỗ Đạm',
            code: 18391,
            value: 'xa_co_dam',
            division_type: 'xã',
            short_codename: 'co_dam',
            parentId: 442,
          },
          {
            title: 'Xã Xuân Liên',
            code: 18394,
            value: 'xa_xuan_lien',
            division_type: 'xã',
            short_codename: 'xuan_lien',
            parentId: 442,
          },
          {
            title: 'Xã Xuân Lĩnh',
            code: 18397,
            value: 'xa_xuan_linh',
            division_type: 'xã',
            short_codename: 'xuan_linh',
            parentId: 442,
          },
          {
            title: 'Xã Xuân Lam',
            code: 18400,
            value: 'xa_xuan_lam',
            division_type: 'xã',
            short_codename: 'xuan_lam',
            parentId: 442,
          },
          {
            title: 'Xã Cương Gián',
            code: 18403,
            value: 'xa_cuong_gian',
            division_type: 'xã',
            short_codename: 'cuong_gian',
            parentId: 442,
          },
        ],
        parentId: 42,
      },
      {
        title: 'Huyện Can Lộc',
        code: 443,
        value: 'huyen_can_loc',
        division_type: 'huyện',
        short_codename: 'can_loc',
        children: [
          {
            title: 'Thị trấn Nghèn',
            code: 18406,
            value: 'thi_tran_nghen',
            division_type: 'thị trấn',
            short_codename: 'nghen',
            parentId: 443,
          },
          {
            title: 'Xã Thiên Lộc',
            code: 18415,
            value: 'xa_thien_loc',
            division_type: 'xã',
            short_codename: 'thien_loc',
            parentId: 443,
          },
          {
            title: 'Xã Thuần Thiện',
            code: 18418,
            value: 'xa_thuan_thien',
            division_type: 'xã',
            short_codename: 'thuan_thien',
            parentId: 443,
          },
          {
            title: 'Xã Vượng Lộc',
            code: 18427,
            value: 'xa_vuong_loc',
            division_type: 'xã',
            short_codename: 'vuong_loc',
            parentId: 443,
          },
          {
            title: 'Xã Thanh Lộc',
            code: 18433,
            value: 'xa_thanh_loc',
            division_type: 'xã',
            short_codename: 'thanh_loc',
            parentId: 443,
          },
          {
            title: 'Xã Kim Song Trường',
            code: 18436,
            value: 'xa_kim_song_truong',
            division_type: 'xã',
            short_codename: 'kim_song_truong',
            parentId: 443,
          },
          {
            title: 'Xã Thường Nga',
            code: 18439,
            value: 'xa_thuong_nga',
            division_type: 'xã',
            short_codename: 'thuong_nga',
            parentId: 443,
          },
          {
            title: 'Xã Tùng Lộc',
            code: 18445,
            value: 'xa_tung_loc',
            division_type: 'xã',
            short_codename: 'tung_loc',
            parentId: 443,
          },
          {
            title: 'Xã Phú Lộc',
            code: 18454,
            value: 'xa_phu_loc',
            division_type: 'xã',
            short_codename: 'phu_loc',
            parentId: 443,
          },
          {
            title: 'Xã Gia Hanh',
            code: 18463,
            value: 'xa_gia_hanh',
            division_type: 'xã',
            short_codename: 'gia_hanh',
            parentId: 443,
          },
          {
            title: 'Xã Khánh Vĩnh Yên',
            code: 18466,
            value: 'xa_khanh_vinh_yen',
            division_type: 'xã',
            short_codename: 'khanh_vinh_yen',
            parentId: 443,
          },
          {
            title: 'Xã Trung Lộc',
            code: 18472,
            value: 'xa_trung_loc',
            division_type: 'xã',
            short_codename: 'trung_loc',
            parentId: 443,
          },
          {
            title: 'Xã Xuân Lộc',
            code: 18475,
            value: 'xa_xuan_loc',
            division_type: 'xã',
            short_codename: 'xuan_loc',
            parentId: 443,
          },
          {
            title: 'Xã Thượng Lộc',
            code: 18478,
            value: 'xa_thuong_loc',
            division_type: 'xã',
            short_codename: 'thuong_loc',
            parentId: 443,
          },
          {
            title: 'Xã Quang Lộc',
            code: 18481,
            value: 'xa_quang_loc',
            division_type: 'xã',
            short_codename: 'quang_loc',
            parentId: 443,
          },
          {
            title: 'Thị trấn Đồng Lộc',
            code: 18484,
            value: 'thi_tran_dong_loc',
            division_type: 'thị trấn',
            short_codename: 'dong_loc',
            parentId: 443,
          },
          {
            title: 'Xã Mỹ Lộc',
            code: 18487,
            value: 'xa_my_loc',
            division_type: 'xã',
            short_codename: 'my_loc',
            parentId: 443,
          },
          {
            title: 'Xã Sơn Lộc',
            code: 18490,
            value: 'xa_son_loc',
            division_type: 'xã',
            short_codename: 'son_loc',
            parentId: 443,
          },
        ],
        parentId: 42,
      },
      {
        title: 'Huyện Hương Khê',
        code: 444,
        value: 'huyen_huong_khe',
        division_type: 'huyện',
        short_codename: 'huong_khe',
        children: [
          {
            title: 'Thị trấn Hương Khê',
            code: 18496,
            value: 'thi_tran_huong_khe',
            division_type: 'thị trấn',
            short_codename: 'huong_khe',
            parentId: 444,
          },
          {
            title: 'Xã Điền Mỹ',
            code: 18499,
            value: 'xa_dien_my',
            division_type: 'xã',
            short_codename: 'dien_my',
            parentId: 444,
          },
          {
            title: 'Xã Hà Linh',
            code: 18502,
            value: 'xa_ha_linh',
            division_type: 'xã',
            short_codename: 'ha_linh',
            parentId: 444,
          },
          {
            title: 'Xã Hương Thủy',
            code: 18505,
            value: 'xa_huong_thuy',
            division_type: 'xã',
            short_codename: 'huong_thuy',
            parentId: 444,
          },
          {
            title: 'Xã Hòa Hải',
            code: 18508,
            value: 'xa_hoa_hai',
            division_type: 'xã',
            short_codename: 'hoa_hai',
            parentId: 444,
          },
          {
            title: 'Xã Phúc Đồng',
            code: 18514,
            value: 'xa_phuc_dong',
            division_type: 'xã',
            short_codename: 'phuc_dong',
            parentId: 444,
          },
          {
            title: 'Xã Hương Giang',
            code: 18517,
            value: 'xa_huong_giang',
            division_type: 'xã',
            short_codename: 'huong_giang',
            parentId: 444,
          },
          {
            title: 'Xã Lộc Yên',
            code: 18520,
            value: 'xa_loc_yen',
            division_type: 'xã',
            short_codename: 'loc_yen',
            parentId: 444,
          },
          {
            title: 'Xã Hương Bình',
            code: 18523,
            value: 'xa_huong_binh',
            division_type: 'xã',
            short_codename: 'huong_binh',
            parentId: 444,
          },
          {
            title: 'Xã Hương Long',
            code: 18526,
            value: 'xa_huong_long',
            division_type: 'xã',
            short_codename: 'huong_long',
            parentId: 444,
          },
          {
            title: 'Xã Phú Gia',
            code: 18529,
            value: 'xa_phu_gia',
            division_type: 'xã',
            short_codename: 'phu_gia',
            parentId: 444,
          },
          {
            title: 'Xã Gia Phố',
            code: 18532,
            value: 'xa_gia_pho',
            division_type: 'xã',
            short_codename: 'gia_pho',
            parentId: 444,
          },
          {
            title: 'Xã Phú Phong',
            code: 18535,
            value: 'xa_phu_phong',
            division_type: 'xã',
            short_codename: 'phu_phong',
            parentId: 444,
          },
          {
            title: 'Xã Hương Đô',
            code: 18538,
            value: 'xa_huong_do',
            division_type: 'xã',
            short_codename: 'huong_do',
            parentId: 444,
          },
          {
            title: 'Xã Hương Vĩnh',
            code: 18541,
            value: 'xa_huong_vinh',
            division_type: 'xã',
            short_codename: 'huong_vinh',
            parentId: 444,
          },
          {
            title: 'Xã Hương Xuân',
            code: 18544,
            value: 'xa_huong_xuan',
            division_type: 'xã',
            short_codename: 'huong_xuan',
            parentId: 444,
          },
          {
            title: 'Xã Phúc Trạch',
            code: 18547,
            value: 'xa_phuc_trach',
            division_type: 'xã',
            short_codename: 'phuc_trach',
            parentId: 444,
          },
          {
            title: 'Xã Hương Trà',
            code: 18550,
            value: 'xa_huong_tra',
            division_type: 'xã',
            short_codename: 'huong_tra',
            parentId: 444,
          },
          {
            title: 'Xã Hương Trạch',
            code: 18553,
            value: 'xa_huong_trach',
            division_type: 'xã',
            short_codename: 'huong_trach',
            parentId: 444,
          },
          {
            title: 'Xã Hương Lâm',
            code: 18556,
            value: 'xa_huong_lam',
            division_type: 'xã',
            short_codename: 'huong_lam',
            parentId: 444,
          },
          {
            title: 'Xã Hương Liên',
            code: 18559,
            value: 'xa_huong_lien',
            division_type: 'xã',
            short_codename: 'huong_lien',
            parentId: 444,
          },
        ],
        parentId: 42,
      },
      {
        title: 'Huyện Thạch Hà',
        code: 445,
        value: 'huyen_thach_ha',
        division_type: 'huyện',
        short_codename: 'thach_ha',
        children: [
          {
            title: 'Thị trấn Thạch Hà',
            code: 18562,
            value: 'thi_tran_thach_ha',
            division_type: 'thị trấn',
            short_codename: 'thach_ha',
            parentId: 445,
          },
          {
            title: 'Xã Ngọc Sơn',
            code: 18565,
            value: 'xa_ngoc_son',
            division_type: 'xã',
            short_codename: 'ngoc_son',
            parentId: 445,
          },
          {
            title: 'Xã Thạch Hải',
            code: 18571,
            value: 'xa_thach_hai',
            division_type: 'xã',
            short_codename: 'thach_hai',
            parentId: 445,
          },
          {
            title: 'Xã Thạch Kênh',
            code: 18586,
            value: 'xa_thach_kenh',
            division_type: 'xã',
            short_codename: 'thach_kenh',
            parentId: 445,
          },
          {
            title: 'Xã Thạch Sơn',
            code: 18589,
            value: 'xa_thach_son',
            division_type: 'xã',
            short_codename: 'thach_son',
            parentId: 445,
          },
          {
            title: 'Xã Thạch Liên',
            code: 18592,
            value: 'xa_thach_lien',
            division_type: 'xã',
            short_codename: 'thach_lien',
            parentId: 445,
          },
          {
            title: 'Xã Đỉnh Bàn',
            code: 18595,
            value: 'xa_dinh_ban',
            division_type: 'xã',
            short_codename: 'dinh_ban',
            parentId: 445,
          },
          {
            title: 'Xã Việt Tiến',
            code: 18601,
            value: 'xa_viet_tien',
            division_type: 'xã',
            short_codename: 'viet_tien',
            parentId: 445,
          },
          {
            title: 'Xã Thạch Khê',
            code: 18604,
            value: 'xa_thach_khe',
            division_type: 'xã',
            short_codename: 'thach_khe',
            parentId: 445,
          },
          {
            title: 'Xã Thạch Long',
            code: 18607,
            value: 'xa_thach_long',
            division_type: 'xã',
            short_codename: 'thach_long',
            parentId: 445,
          },
          {
            title: 'Xã Thạch Trị',
            code: 18619,
            value: 'xa_thach_tri',
            division_type: 'xã',
            short_codename: 'thach_tri',
            parentId: 445,
          },
          {
            title: 'Xã Thạch Lạc',
            code: 18622,
            value: 'xa_thach_lac',
            division_type: 'xã',
            short_codename: 'thach_lac',
            parentId: 445,
          },
          {
            title: 'Xã Thạch Ngọc',
            code: 18625,
            value: 'xa_thach_ngoc',
            division_type: 'xã',
            short_codename: 'thach_ngoc',
            parentId: 445,
          },
          {
            title: 'Xã Tượng Sơn',
            code: 18628,
            value: 'xa_tuong_son',
            division_type: 'xã',
            short_codename: 'tuong_son',
            parentId: 445,
          },
          {
            title: 'Xã Thạch Văn',
            code: 18631,
            value: 'xa_thach_van',
            division_type: 'xã',
            short_codename: 'thach_van',
            parentId: 445,
          },
          {
            title: 'Xã Lưu Vĩnh Sơn',
            code: 18634,
            value: 'xa_luu_vinh_son',
            division_type: 'xã',
            short_codename: 'luu_vinh_son',
            parentId: 445,
          },
          {
            title: 'Xã Thạch Thắng',
            code: 18637,
            value: 'xa_thach_thang',
            division_type: 'xã',
            short_codename: 'thach_thang',
            parentId: 445,
          },
          {
            title: 'Xã Thạch Đài',
            code: 18643,
            value: 'xa_thach_dai',
            division_type: 'xã',
            short_codename: 'thach_dai',
            parentId: 445,
          },
          {
            title: 'Xã Thạch Hội',
            code: 18649,
            value: 'xa_thach_hoi',
            division_type: 'xã',
            short_codename: 'thach_hoi',
            parentId: 445,
          },
          {
            title: 'Xã Tân Lâm Hương',
            code: 18652,
            value: 'xa_tan_lam_huong',
            division_type: 'xã',
            short_codename: 'tan_lam_huong',
            parentId: 445,
          },
          {
            title: 'Xã Thạch Xuân',
            code: 18658,
            value: 'xa_thach_xuan',
            division_type: 'xã',
            short_codename: 'thach_xuan',
            parentId: 445,
          },
          {
            title: 'Xã Nam Điền',
            code: 18667,
            value: 'xa_nam_dien',
            division_type: 'xã',
            short_codename: 'nam_dien',
            parentId: 445,
          },
        ],
        parentId: 42,
      },
      {
        title: 'Huyện Cẩm Xuyên',
        code: 446,
        value: 'huyen_cam_xuyen',
        division_type: 'huyện',
        short_codename: 'cam_xuyen',
        children: [
          {
            title: 'Thị trấn Cẩm Xuyên',
            code: 18673,
            value: 'thi_tran_cam_xuyen',
            division_type: 'thị trấn',
            short_codename: 'cam_xuyen',
            parentId: 446,
          },
          {
            title: 'Thị trấn Thiên Cầm',
            code: 18676,
            value: 'thi_tran_thien_cam',
            division_type: 'thị trấn',
            short_codename: 'thien_cam',
            parentId: 446,
          },
          {
            title: 'Xã Yên Hòa',
            code: 18679,
            value: 'xa_yen_hoa',
            division_type: 'xã',
            short_codename: 'yen_hoa',
            parentId: 446,
          },
          {
            title: 'Xã Cẩm Dương',
            code: 18682,
            value: 'xa_cam_duong',
            division_type: 'xã',
            short_codename: 'cam_duong',
            parentId: 446,
          },
          {
            title: 'Xã Cẩm Bình',
            code: 18685,
            value: 'xa_cam_binh',
            division_type: 'xã',
            short_codename: 'cam_binh',
            parentId: 446,
          },
          {
            title: 'Xã Cẩm Vĩnh',
            code: 18691,
            value: 'xa_cam_vinh',
            division_type: 'xã',
            short_codename: 'cam_vinh',
            parentId: 446,
          },
          {
            title: 'Xã Cẩm Thành',
            code: 18694,
            value: 'xa_cam_thanh',
            division_type: 'xã',
            short_codename: 'cam_thanh',
            parentId: 446,
          },
          {
            title: 'Xã Cẩm Quang',
            code: 18697,
            value: 'xa_cam_quang',
            division_type: 'xã',
            short_codename: 'cam_quang',
            parentId: 446,
          },
          {
            title: 'Xã Cẩm Thạch',
            code: 18706,
            value: 'xa_cam_thach',
            division_type: 'xã',
            short_codename: 'cam_thach',
            parentId: 446,
          },
          {
            title: 'Xã Cẩm Nhượng',
            code: 18709,
            value: 'xa_cam_nhuong',
            division_type: 'xã',
            short_codename: 'cam_nhuong',
            parentId: 446,
          },
          {
            title: 'Xã Nam Phúc Thăng',
            code: 18712,
            value: 'xa_nam_phuc_thang',
            division_type: 'xã',
            short_codename: 'nam_phuc_thang',
            parentId: 446,
          },
          {
            title: 'Xã Cẩm Duệ',
            code: 18715,
            value: 'xa_cam_due',
            division_type: 'xã',
            short_codename: 'cam_due',
            parentId: 446,
          },
          {
            title: 'Xã Cẩm Lĩnh',
            code: 18721,
            value: 'xa_cam_linh',
            division_type: 'xã',
            short_codename: 'cam_linh',
            parentId: 446,
          },
          {
            title: 'Xã Cẩm Quan',
            code: 18724,
            value: 'xa_cam_quan',
            division_type: 'xã',
            short_codename: 'cam_quan',
            parentId: 446,
          },
          {
            title: 'Xã Cẩm Hà',
            code: 18727,
            value: 'xa_cam_ha',
            division_type: 'xã',
            short_codename: 'cam_ha',
            parentId: 446,
          },
          {
            title: 'Xã Cẩm Lộc',
            code: 18730,
            value: 'xa_cam_loc',
            division_type: 'xã',
            short_codename: 'cam_loc',
            parentId: 446,
          },
          {
            title: 'Xã Cẩm Hưng',
            code: 18733,
            value: 'xa_cam_hung',
            division_type: 'xã',
            short_codename: 'cam_hung',
            parentId: 446,
          },
          {
            title: 'Xã Cẩm Thịnh',
            code: 18736,
            value: 'xa_cam_thinh',
            division_type: 'xã',
            short_codename: 'cam_thinh',
            parentId: 446,
          },
          {
            title: 'Xã Cẩm Mỹ',
            code: 18739,
            value: 'xa_cam_my',
            division_type: 'xã',
            short_codename: 'cam_my',
            parentId: 446,
          },
          {
            title: 'Xã Cẩm Trung',
            code: 18742,
            value: 'xa_cam_trung',
            division_type: 'xã',
            short_codename: 'cam_trung',
            parentId: 446,
          },
          {
            title: 'Xã Cẩm Sơn',
            code: 18745,
            value: 'xa_cam_son',
            division_type: 'xã',
            short_codename: 'cam_son',
            parentId: 446,
          },
          {
            title: 'Xã Cẩm Lạc',
            code: 18748,
            value: 'xa_cam_lac',
            division_type: 'xã',
            short_codename: 'cam_lac',
            parentId: 446,
          },
          {
            title: 'Xã Cẩm Minh',
            code: 18751,
            value: 'xa_cam_minh',
            division_type: 'xã',
            short_codename: 'cam_minh',
            parentId: 446,
          },
        ],
        parentId: 42,
      },
      {
        title: 'Huyện Kỳ Anh',
        code: 447,
        value: 'huyen_ky_anh',
        division_type: 'huyện',
        short_codename: 'huyen_ky_anh',
        children: [
          {
            title: 'Xã Kỳ Xuân',
            code: 18757,
            value: 'xa_ky_xuan',
            division_type: 'xã',
            short_codename: 'ky_xuan',
            parentId: 447,
          },
          {
            title: 'Xã Kỳ Bắc',
            code: 18760,
            value: 'xa_ky_bac',
            division_type: 'xã',
            short_codename: 'ky_bac',
            parentId: 447,
          },
          {
            title: 'Xã Kỳ Phú',
            code: 18763,
            value: 'xa_ky_phu',
            division_type: 'xã',
            short_codename: 'ky_phu',
            parentId: 447,
          },
          {
            title: 'Xã Kỳ Phong',
            code: 18766,
            value: 'xa_ky_phong',
            division_type: 'xã',
            short_codename: 'ky_phong',
            parentId: 447,
          },
          {
            title: 'Xã Kỳ Tiến',
            code: 18769,
            value: 'xa_ky_tien',
            division_type: 'xã',
            short_codename: 'ky_tien',
            parentId: 447,
          },
          {
            title: 'Xã Kỳ Giang',
            code: 18772,
            value: 'xa_ky_giang',
            division_type: 'xã',
            short_codename: 'ky_giang',
            parentId: 447,
          },
          {
            title: 'Xã Kỳ Đồng',
            code: 18775,
            value: 'xa_ky_dong',
            division_type: 'xã',
            short_codename: 'ky_dong',
            parentId: 447,
          },
          {
            title: 'Xã Kỳ Khang',
            code: 18778,
            value: 'xa_ky_khang',
            division_type: 'xã',
            short_codename: 'ky_khang',
            parentId: 447,
          },
          {
            title: 'Xã Kỳ Văn',
            code: 18784,
            value: 'xa_ky_van',
            division_type: 'xã',
            short_codename: 'ky_van',
            parentId: 447,
          },
          {
            title: 'Xã Kỳ Trung',
            code: 18787,
            value: 'xa_ky_trung',
            division_type: 'xã',
            short_codename: 'ky_trung',
            parentId: 447,
          },
          {
            title: 'Xã Kỳ Thọ',
            code: 18790,
            value: 'xa_ky_tho',
            division_type: 'xã',
            short_codename: 'ky_tho',
            parentId: 447,
          },
          {
            title: 'Xã Kỳ Tây',
            code: 18793,
            value: 'xa_ky_tay',
            division_type: 'xã',
            short_codename: 'ky_tay',
            parentId: 447,
          },
          {
            title: 'Xã Kỳ Thượng',
            code: 18799,
            value: 'xa_ky_thuong',
            division_type: 'xã',
            short_codename: 'ky_thuong',
            parentId: 447,
          },
          {
            title: 'Xã Kỳ Hải',
            code: 18802,
            value: 'xa_ky_hai',
            division_type: 'xã',
            short_codename: 'ky_hai',
            parentId: 447,
          },
          {
            title: 'Xã Kỳ Thư',
            code: 18805,
            value: 'xa_ky_thu',
            division_type: 'xã',
            short_codename: 'ky_thu',
            parentId: 447,
          },
          {
            title: 'Xã Kỳ Châu',
            code: 18811,
            value: 'xa_ky_chau',
            division_type: 'xã',
            short_codename: 'ky_chau',
            parentId: 447,
          },
          {
            title: 'Xã Kỳ Tân',
            code: 18814,
            value: 'xa_ky_tan',
            division_type: 'xã',
            short_codename: 'ky_tan',
            parentId: 447,
          },
          {
            title: 'Xã Lâm Hợp',
            code: 18838,
            value: 'xa_lam_hop',
            division_type: 'xã',
            short_codename: 'lam_hop',
            parentId: 447,
          },
          {
            title: 'Xã Kỳ Sơn',
            code: 18844,
            value: 'xa_ky_son',
            division_type: 'xã',
            short_codename: 'ky_son',
            parentId: 447,
          },
          {
            title: 'Xã Kỳ Lạc',
            code: 18850,
            value: 'xa_ky_lac',
            division_type: 'xã',
            short_codename: 'ky_lac',
            parentId: 447,
          },
        ],
        parentId: 42,
      },
      {
        title: 'Huyện Lộc Hà',
        code: 448,
        value: 'huyen_loc_ha',
        division_type: 'huyện',
        short_codename: 'loc_ha',
        children: [
          {
            title: 'Xã Tân Lộc',
            code: 18409,
            value: 'xa_tan_loc',
            division_type: 'xã',
            short_codename: 'tan_loc',
            parentId: 448,
          },
          {
            title: 'Xã Hồng Lộc',
            code: 18412,
            value: 'xa_hong_loc',
            division_type: 'xã',
            short_codename: 'hong_loc',
            parentId: 448,
          },
          {
            title: 'Xã Thịnh Lộc',
            code: 18421,
            value: 'xa_thinh_loc',
            division_type: 'xã',
            short_codename: 'thinh_loc',
            parentId: 448,
          },
          {
            title: 'Xã Bình An',
            code: 18430,
            value: 'xa_binh_an',
            division_type: 'xã',
            short_codename: 'binh_an',
            parentId: 448,
          },
          {
            title: 'Xã Ích Hậu',
            code: 18457,
            value: 'xa_ich_hau',
            division_type: 'xã',
            short_codename: 'ich_hau',
            parentId: 448,
          },
          {
            title: 'Xã Phù Lưu',
            code: 18493,
            value: 'xa_phu_luu',
            division_type: 'xã',
            short_codename: 'phu_luu',
            parentId: 448,
          },
          {
            title: 'Thị trấn Lộc Hà',
            code: 18568,
            value: 'thi_tran_loc_ha',
            division_type: 'thị trấn',
            short_codename: 'loc_ha',
            parentId: 448,
          },
          {
            title: 'Xã Thạch Mỹ',
            code: 18577,
            value: 'xa_thach_my',
            division_type: 'xã',
            short_codename: 'thach_my',
            parentId: 448,
          },
          {
            title: 'Xã Thạch Kim',
            code: 18580,
            value: 'xa_thach_kim',
            division_type: 'xã',
            short_codename: 'thach_kim',
            parentId: 448,
          },
          {
            title: 'Xã Thạch Châu',
            code: 18583,
            value: 'xa_thach_chau',
            division_type: 'xã',
            short_codename: 'thach_chau',
            parentId: 448,
          },
          {
            title: 'Xã Hộ Độ',
            code: 18598,
            value: 'xa_ho_do',
            division_type: 'xã',
            short_codename: 'ho_do',
            parentId: 448,
          },
          {
            title: 'Xã Mai Phụ',
            code: 18670,
            value: 'xa_mai_phu',
            division_type: 'xã',
            short_codename: 'mai_phu',
            parentId: 448,
          },
        ],
        parentId: 42,
      },
      {
        title: 'Thị xã Kỳ Anh',
        code: 449,
        value: 'thi_xa_ky_anh',
        division_type: 'thị xã',
        short_codename: 'ky_anh',
        children: [
          {
            title: 'Phường Hưng Trí',
            code: 18754,
            value: 'phuong_hung_tri',
            division_type: 'phường',
            short_codename: 'hung_tri',
            parentId: 449,
          },
          {
            title: 'Xã Kỳ Ninh',
            code: 18781,
            value: 'xa_ky_ninh',
            division_type: 'xã',
            short_codename: 'ky_ninh',
            parentId: 449,
          },
          {
            title: 'Xã Kỳ Lợi',
            code: 18796,
            value: 'xa_ky_loi',
            division_type: 'xã',
            short_codename: 'ky_loi',
            parentId: 449,
          },
          {
            title: 'Xã Kỳ Hà',
            code: 18808,
            value: 'xa_ky_ha',
            division_type: 'xã',
            short_codename: 'ky_ha',
            parentId: 449,
          },
          {
            title: 'Phường Kỳ Trinh',
            code: 18820,
            value: 'phuong_ky_trinh',
            division_type: 'phường',
            short_codename: 'ky_trinh',
            parentId: 449,
          },
          {
            title: 'Phường Kỳ Thịnh',
            code: 18823,
            value: 'phuong_ky_thinh',
            division_type: 'phường',
            short_codename: 'ky_thinh',
            parentId: 449,
          },
          {
            title: 'Xã Kỳ Hoa',
            code: 18829,
            value: 'xa_ky_hoa',
            division_type: 'xã',
            short_codename: 'ky_hoa',
            parentId: 449,
          },
          {
            title: 'Phường Kỳ Phương',
            code: 18832,
            value: 'phuong_ky_phuong',
            division_type: 'phường',
            short_codename: 'ky_phuong',
            parentId: 449,
          },
          {
            title: 'Phường Kỳ Long',
            code: 18835,
            value: 'phuong_ky_long',
            division_type: 'phường',
            short_codename: 'ky_long',
            parentId: 449,
          },
          {
            title: 'Phường Kỳ Liên',
            code: 18841,
            value: 'phuong_ky_lien',
            division_type: 'phường',
            short_codename: 'ky_lien',
            parentId: 449,
          },
          {
            title: 'Xã Kỳ Nam',
            code: 18847,
            value: 'xa_ky_nam',
            division_type: 'xã',
            short_codename: 'ky_nam',
            parentId: 449,
          },
        ],
        parentId: 42,
      },
    ],
  },
  {
    title: 'Tỉnh Quảng Bình',
    code: 44,
    value: 'tinh_quang_binh',
    division_type: 'tỉnh',
    phone_code: 232,
    children: [
      {
        title: 'Thành Phố Đồng Hới',
        code: 450,
        value: 'thanh_pho_dong_hoi',
        division_type: 'thành phố',
        short_codename: 'dong_hoi',
        children: [
          {
            title: 'Phường Hải Thành',
            code: 18853,
            value: 'phuong_hai_thanh',
            division_type: 'phường',
            short_codename: 'hai_thanh',
            parentId: 450,
          },
          {
            title: 'Phường Đồng Phú',
            code: 18856,
            value: 'phuong_dong_phu',
            division_type: 'phường',
            short_codename: 'dong_phu',
            parentId: 450,
          },
          {
            title: 'Phường Bắc Lý',
            code: 18859,
            value: 'phuong_bac_ly',
            division_type: 'phường',
            short_codename: 'bac_ly',
            parentId: 450,
          },
          {
            title: 'Phường Nam Lý',
            code: 18865,
            value: 'phuong_nam_ly',
            division_type: 'phường',
            short_codename: 'nam_ly',
            parentId: 450,
          },
          {
            title: 'Phường Đồng Hải',
            code: 18868,
            value: 'phuong_dong_hai',
            division_type: 'phường',
            short_codename: 'dong_hai',
            parentId: 450,
          },
          {
            title: 'Phường Đồng Sơn',
            code: 18871,
            value: 'phuong_dong_son',
            division_type: 'phường',
            short_codename: 'dong_son',
            parentId: 450,
          },
          {
            title: 'Phường Phú Hải',
            code: 18874,
            value: 'phuong_phu_hai',
            division_type: 'phường',
            short_codename: 'phu_hai',
            parentId: 450,
          },
          {
            title: 'Phường Bắc Nghĩa',
            code: 18877,
            value: 'phuong_bac_nghia',
            division_type: 'phường',
            short_codename: 'bac_nghia',
            parentId: 450,
          },
          {
            title: 'Phường Đức Ninh Đông',
            code: 18880,
            value: 'phuong_duc_ninh_dong',
            division_type: 'phường',
            short_codename: 'duc_ninh_dong',
            parentId: 450,
          },
          {
            title: 'Xã Quang Phú',
            code: 18883,
            value: 'xa_quang_phu',
            division_type: 'xã',
            short_codename: 'quang_phu',
            parentId: 450,
          },
          {
            title: 'Xã Lộc Ninh',
            code: 18886,
            value: 'xa_loc_ninh',
            division_type: 'xã',
            short_codename: 'loc_ninh',
            parentId: 450,
          },
          {
            title: 'Xã Bảo Ninh',
            code: 18889,
            value: 'xa_bao_ninh',
            division_type: 'xã',
            short_codename: 'bao_ninh',
            parentId: 450,
          },
          {
            title: 'Xã Nghĩa Ninh',
            code: 18892,
            value: 'xa_nghia_ninh',
            division_type: 'xã',
            short_codename: 'nghia_ninh',
            parentId: 450,
          },
          {
            title: 'Xã Thuận Đức',
            code: 18895,
            value: 'xa_thuan_duc',
            division_type: 'xã',
            short_codename: 'thuan_duc',
            parentId: 450,
          },
          {
            title: 'Xã Đức Ninh',
            code: 18898,
            value: 'xa_duc_ninh',
            division_type: 'xã',
            short_codename: 'duc_ninh',
            parentId: 450,
          },
        ],
        parentId: 44,
      },
      {
        title: 'Huyện Minh Hóa',
        code: 452,
        value: 'huyen_minh_hoa',
        division_type: 'huyện',
        short_codename: 'minh_hoa',
        children: [
          {
            title: 'Thị trấn Quy Đạt',
            code: 18901,
            value: 'thi_tran_quy_dat',
            division_type: 'thị trấn',
            short_codename: 'quy_dat',
            parentId: 452,
          },
          {
            title: 'Xã Dân Hóa',
            code: 18904,
            value: 'xa_dan_hoa',
            division_type: 'xã',
            short_codename: 'dan_hoa',
            parentId: 452,
          },
          {
            title: 'Xã Trọng Hóa',
            code: 18907,
            value: 'xa_trong_hoa',
            division_type: 'xã',
            short_codename: 'trong_hoa',
            parentId: 452,
          },
          {
            title: 'Xã Hóa Phúc',
            code: 18910,
            value: 'xa_hoa_phuc',
            division_type: 'xã',
            short_codename: 'hoa_phuc',
            parentId: 452,
          },
          {
            title: 'Xã Hồng Hóa',
            code: 18913,
            value: 'xa_hong_hoa',
            division_type: 'xã',
            short_codename: 'hong_hoa',
            parentId: 452,
          },
          {
            title: 'Xã Hóa Thanh',
            code: 18916,
            value: 'xa_hoa_thanh',
            division_type: 'xã',
            short_codename: 'hoa_thanh',
            parentId: 452,
          },
          {
            title: 'Xã Hóa Tiến',
            code: 18919,
            value: 'xa_hoa_tien',
            division_type: 'xã',
            short_codename: 'hoa_tien',
            parentId: 452,
          },
          {
            title: 'Xã Hóa Hợp',
            code: 18922,
            value: 'xa_hoa_hop',
            division_type: 'xã',
            short_codename: 'hoa_hop',
            parentId: 452,
          },
          {
            title: 'Xã Xuân Hóa',
            code: 18925,
            value: 'xa_xuan_hoa',
            division_type: 'xã',
            short_codename: 'xuan_hoa',
            parentId: 452,
          },
          {
            title: 'Xã Yên Hóa',
            code: 18928,
            value: 'xa_yen_hoa',
            division_type: 'xã',
            short_codename: 'yen_hoa',
            parentId: 452,
          },
          {
            title: 'Xã Minh Hóa',
            code: 18931,
            value: 'xa_minh_hoa',
            division_type: 'xã',
            short_codename: 'minh_hoa',
            parentId: 452,
          },
          {
            title: 'Xã Tân Hóa',
            code: 18934,
            value: 'xa_tan_hoa',
            division_type: 'xã',
            short_codename: 'tan_hoa',
            parentId: 452,
          },
          {
            title: 'Xã Hóa Sơn',
            code: 18937,
            value: 'xa_hoa_son',
            division_type: 'xã',
            short_codename: 'hoa_son',
            parentId: 452,
          },
          {
            title: 'Xã Trung Hóa',
            code: 18943,
            value: 'xa_trung_hoa',
            division_type: 'xã',
            short_codename: 'trung_hoa',
            parentId: 452,
          },
          {
            title: 'Xã Thượng Hóa',
            code: 18946,
            value: 'xa_thuong_hoa',
            division_type: 'xã',
            short_codename: 'thuong_hoa',
            parentId: 452,
          },
        ],
        parentId: 44,
      },
      {
        title: 'Huyện Tuyên Hóa',
        code: 453,
        value: 'huyen_tuyen_hoa',
        division_type: 'huyện',
        short_codename: 'tuyen_hoa',
        children: [
          {
            title: 'Thị trấn Đồng Lê',
            code: 18949,
            value: 'thi_tran_dong_le',
            division_type: 'thị trấn',
            short_codename: 'dong_le',
            parentId: 453,
          },
          {
            title: 'Xã Hương Hóa',
            code: 18952,
            value: 'xa_huong_hoa',
            division_type: 'xã',
            short_codename: 'huong_hoa',
            parentId: 453,
          },
          {
            title: 'Xã Kim Hóa',
            code: 18955,
            value: 'xa_kim_hoa',
            division_type: 'xã',
            short_codename: 'kim_hoa',
            parentId: 453,
          },
          {
            title: 'Xã Thanh Hóa',
            code: 18958,
            value: 'xa_thanh_hoa',
            division_type: 'xã',
            short_codename: 'thanh_hoa',
            parentId: 453,
          },
          {
            title: 'Xã Thanh Thạch',
            code: 18961,
            value: 'xa_thanh_thach',
            division_type: 'xã',
            short_codename: 'thanh_thach',
            parentId: 453,
          },
          {
            title: 'Xã Thuận Hóa',
            code: 18964,
            value: 'xa_thuan_hoa',
            division_type: 'xã',
            short_codename: 'thuan_hoa',
            parentId: 453,
          },
          {
            title: 'Xã Lâm Hóa',
            code: 18967,
            value: 'xa_lam_hoa',
            division_type: 'xã',
            short_codename: 'lam_hoa',
            parentId: 453,
          },
          {
            title: 'Xã Lê Hóa',
            code: 18970,
            value: 'xa_le_hoa',
            division_type: 'xã',
            short_codename: 'le_hoa',
            parentId: 453,
          },
          {
            title: 'Xã Sơn Hóa',
            code: 18973,
            value: 'xa_son_hoa',
            division_type: 'xã',
            short_codename: 'son_hoa',
            parentId: 453,
          },
          {
            title: 'Xã Đồng Hóa',
            code: 18976,
            value: 'xa_dong_hoa',
            division_type: 'xã',
            short_codename: 'dong_hoa',
            parentId: 453,
          },
          {
            title: 'Xã Ngư Hóa',
            code: 18979,
            value: 'xa_ngu_hoa',
            division_type: 'xã',
            short_codename: 'ngu_hoa',
            parentId: 453,
          },
          {
            title: 'Xã Thạch Hóa',
            code: 18985,
            value: 'xa_thach_hoa',
            division_type: 'xã',
            short_codename: 'thach_hoa',
            parentId: 453,
          },
          {
            title: 'Xã Đức Hóa',
            code: 18988,
            value: 'xa_duc_hoa',
            division_type: 'xã',
            short_codename: 'duc_hoa',
            parentId: 453,
          },
          {
            title: 'Xã Phong Hóa',
            code: 18991,
            value: 'xa_phong_hoa',
            division_type: 'xã',
            short_codename: 'phong_hoa',
            parentId: 453,
          },
          {
            title: 'Xã Mai Hóa',
            code: 18994,
            value: 'xa_mai_hoa',
            division_type: 'xã',
            short_codename: 'mai_hoa',
            parentId: 453,
          },
          {
            title: 'Xã Tiến Hóa',
            code: 18997,
            value: 'xa_tien_hoa',
            division_type: 'xã',
            short_codename: 'tien_hoa',
            parentId: 453,
          },
          {
            title: 'Xã Châu Hóa',
            code: 19000,
            value: 'xa_chau_hoa',
            division_type: 'xã',
            short_codename: 'chau_hoa',
            parentId: 453,
          },
          {
            title: 'Xã Cao Quảng',
            code: 19003,
            value: 'xa_cao_quang',
            division_type: 'xã',
            short_codename: 'cao_quang',
            parentId: 453,
          },
          {
            title: 'Xã Văn Hóa',
            code: 19006,
            value: 'xa_van_hoa',
            division_type: 'xã',
            short_codename: 'van_hoa',
            parentId: 453,
          },
        ],
        parentId: 44,
      },
      {
        title: 'Huyện Quảng Trạch',
        code: 454,
        value: 'huyen_quang_trach',
        division_type: 'huyện',
        short_codename: 'quang_trach',
        children: [
          {
            title: 'Xã Quảng Hợp',
            code: 19012,
            value: 'xa_quang_hop',
            division_type: 'xã',
            short_codename: 'quang_hop',
            parentId: 454,
          },
          {
            title: 'Xã Quảng Kim',
            code: 19015,
            value: 'xa_quang_kim',
            division_type: 'xã',
            short_codename: 'quang_kim',
            parentId: 454,
          },
          {
            title: 'Xã Quảng Đông',
            code: 19018,
            value: 'xa_quang_dong',
            division_type: 'xã',
            short_codename: 'quang_dong',
            parentId: 454,
          },
          {
            title: 'Xã Quảng Phú',
            code: 19021,
            value: 'xa_quang_phu',
            division_type: 'xã',
            short_codename: 'quang_phu',
            parentId: 454,
          },
          {
            title: 'Xã Quảng Châu',
            code: 19024,
            value: 'xa_quang_chau',
            division_type: 'xã',
            short_codename: 'quang_chau',
            parentId: 454,
          },
          {
            title: 'Xã Quảng Thạch',
            code: 19027,
            value: 'xa_quang_thach',
            division_type: 'xã',
            short_codename: 'quang_thach',
            parentId: 454,
          },
          {
            title: 'Xã Quảng Lưu',
            code: 19030,
            value: 'xa_quang_luu',
            division_type: 'xã',
            short_codename: 'quang_luu',
            parentId: 454,
          },
          {
            title: 'Xã Quảng Tùng',
            code: 19033,
            value: 'xa_quang_tung',
            division_type: 'xã',
            short_codename: 'quang_tung',
            parentId: 454,
          },
          {
            title: 'Xã Cảnh Dương',
            code: 19036,
            value: 'xa_canh_duong',
            division_type: 'xã',
            short_codename: 'canh_duong',
            parentId: 454,
          },
          {
            title: 'Xã Quảng Tiến',
            code: 19039,
            value: 'xa_quang_tien',
            division_type: 'xã',
            short_codename: 'quang_tien',
            parentId: 454,
          },
          {
            title: 'Xã Quảng Hưng',
            code: 19042,
            value: 'xa_quang_hung',
            division_type: 'xã',
            short_codename: 'quang_hung',
            parentId: 454,
          },
          {
            title: 'Xã Quảng Xuân',
            code: 19045,
            value: 'xa_quang_xuan',
            division_type: 'xã',
            short_codename: 'quang_xuan',
            parentId: 454,
          },
          {
            title: 'Xã Cảnh Hóa',
            code: 19048,
            value: 'xa_canh_hoa',
            division_type: 'xã',
            short_codename: 'canh_hoa',
            parentId: 454,
          },
          {
            title: 'Xã Liên Trường',
            code: 19051,
            value: 'xa_lien_truong',
            division_type: 'xã',
            short_codename: 'lien_truong',
            parentId: 454,
          },
          {
            title: 'Xã Quảng Phương',
            code: 19057,
            value: 'xa_quang_phuong',
            division_type: 'xã',
            short_codename: 'quang_phuong',
            parentId: 454,
          },
          {
            title: 'Xã Phù Hóa',
            code: 19063,
            value: 'xa_phu_hoa',
            division_type: 'xã',
            short_codename: 'phu_hoa',
            parentId: 454,
          },
          {
            title: 'Xã Quảng Thanh',
            code: 19072,
            value: 'xa_quang_thanh',
            division_type: 'xã',
            short_codename: 'quang_thanh',
            parentId: 454,
          },
        ],
        parentId: 44,
      },
      {
        title: 'Huyện Bố Trạch',
        code: 455,
        value: 'huyen_bo_trach',
        division_type: 'huyện',
        short_codename: 'bo_trach',
        children: [
          {
            title: 'Thị trấn Hoàn Lão',
            code: 19111,
            value: 'thi_tran_hoan_lao',
            division_type: 'thị trấn',
            short_codename: 'hoan_lao',
            parentId: 455,
          },
          {
            title: 'Thị trấn NT Việt Trung',
            code: 19114,
            value: 'thi_tran_nt_viet_trung',
            division_type: 'thị trấn',
            short_codename: 'nt_viet_trung',
            parentId: 455,
          },
          {
            title: 'Xã Xuân Trạch',
            code: 19117,
            value: 'xa_xuan_trach',
            division_type: 'xã',
            short_codename: 'xuan_trach',
            parentId: 455,
          },
          {
            title: 'Xã Mỹ Trạch',
            code: 19120,
            value: 'xa_my_trach',
            division_type: 'xã',
            short_codename: 'my_trach',
            parentId: 455,
          },
          {
            title: 'Xã Hạ Trạch',
            code: 19123,
            value: 'xa_ha_trach',
            division_type: 'xã',
            short_codename: 'ha_trach',
            parentId: 455,
          },
          {
            title: 'Xã Bắc Trạch',
            code: 19126,
            value: 'xa_bac_trach',
            division_type: 'xã',
            short_codename: 'bac_trach',
            parentId: 455,
          },
          {
            title: 'Xã Lâm Trạch',
            code: 19129,
            value: 'xa_lam_trach',
            division_type: 'xã',
            short_codename: 'lam_trach',
            parentId: 455,
          },
          {
            title: 'Xã Thanh Trạch',
            code: 19132,
            value: 'xa_thanh_trach',
            division_type: 'xã',
            short_codename: 'thanh_trach',
            parentId: 455,
          },
          {
            title: 'Xã Liên Trạch',
            code: 19135,
            value: 'xa_lien_trach',
            division_type: 'xã',
            short_codename: 'lien_trach',
            parentId: 455,
          },
          {
            title: 'Xã Phúc Trạch',
            code: 19138,
            value: 'xa_phuc_trach',
            division_type: 'xã',
            short_codename: 'phuc_trach',
            parentId: 455,
          },
          {
            title: 'Xã Cự Nẫm',
            code: 19141,
            value: 'xa_cu_nam',
            division_type: 'xã',
            short_codename: 'cu_nam',
            parentId: 455,
          },
          {
            title: 'Xã Hải Phú',
            code: 19144,
            value: 'xa_hai_phu',
            division_type: 'xã',
            short_codename: 'hai_phu',
            parentId: 455,
          },
          {
            title: 'Xã Thượng Trạch',
            code: 19147,
            value: 'xa_thuong_trach',
            division_type: 'xã',
            short_codename: 'thuong_trach',
            parentId: 455,
          },
          {
            title: 'Xã Sơn Lộc',
            code: 19150,
            value: 'xa_son_loc',
            division_type: 'xã',
            short_codename: 'son_loc',
            parentId: 455,
          },
          {
            title: 'Xã Hưng Trạch',
            code: 19156,
            value: 'xa_hung_trach',
            division_type: 'xã',
            short_codename: 'hung_trach',
            parentId: 455,
          },
          {
            title: 'Xã Đồng Trạch',
            code: 19159,
            value: 'xa_dong_trach',
            division_type: 'xã',
            short_codename: 'dong_trach',
            parentId: 455,
          },
          {
            title: 'Xã Đức Trạch',
            code: 19162,
            value: 'xa_duc_trach',
            division_type: 'xã',
            short_codename: 'duc_trach',
            parentId: 455,
          },
          {
            title: 'Thị trấn Phong Nha',
            code: 19165,
            value: 'thi_tran_phong_nha',
            division_type: 'thị trấn',
            short_codename: 'phong_nha',
            parentId: 455,
          },
          {
            title: 'Xã Vạn Trạch',
            code: 19168,
            value: 'xa_van_trach',
            division_type: 'xã',
            short_codename: 'van_trach',
            parentId: 455,
          },
          {
            title: 'Xã Phú Định',
            code: 19174,
            value: 'xa_phu_dinh',
            division_type: 'xã',
            short_codename: 'phu_dinh',
            parentId: 455,
          },
          {
            title: 'Xã Trung Trạch',
            code: 19177,
            value: 'xa_trung_trach',
            division_type: 'xã',
            short_codename: 'trung_trach',
            parentId: 455,
          },
          {
            title: 'Xã Tây Trạch',
            code: 19180,
            value: 'xa_tay_trach',
            division_type: 'xã',
            short_codename: 'tay_trach',
            parentId: 455,
          },
          {
            title: 'Xã Hòa Trạch',
            code: 19183,
            value: 'xa_hoa_trach',
            division_type: 'xã',
            short_codename: 'hoa_trach',
            parentId: 455,
          },
          {
            title: 'Xã Đại Trạch',
            code: 19186,
            value: 'xa_dai_trach',
            division_type: 'xã',
            short_codename: 'dai_trach',
            parentId: 455,
          },
          {
            title: 'Xã Nhân Trạch',
            code: 19189,
            value: 'xa_nhan_trach',
            division_type: 'xã',
            short_codename: 'nhan_trach',
            parentId: 455,
          },
          {
            title: 'Xã Tân Trạch',
            code: 19192,
            value: 'xa_tan_trach',
            division_type: 'xã',
            short_codename: 'tan_trach',
            parentId: 455,
          },
          {
            title: 'Xã Nam Trạch',
            code: 19195,
            value: 'xa_nam_trach',
            division_type: 'xã',
            short_codename: 'nam_trach',
            parentId: 455,
          },
          {
            title: 'Xã Lý Trạch',
            code: 19198,
            value: 'xa_ly_trach',
            division_type: 'xã',
            short_codename: 'ly_trach',
            parentId: 455,
          },
        ],
        parentId: 44,
      },
      {
        title: 'Huyện Quảng Ninh',
        code: 456,
        value: 'huyen_quang_ninh',
        division_type: 'huyện',
        short_codename: 'quang_ninh',
        children: [
          {
            title: 'Thị trấn Quán Hàu',
            code: 19201,
            value: 'thi_tran_quan_hau',
            division_type: 'thị trấn',
            short_codename: 'quan_hau',
            parentId: 456,
          },
          {
            title: 'Xã Trường Sơn',
            code: 19204,
            value: 'xa_truong_son',
            division_type: 'xã',
            short_codename: 'truong_son',
            parentId: 456,
          },
          {
            title: 'Xã Lương Ninh',
            code: 19207,
            value: 'xa_luong_ninh',
            division_type: 'xã',
            short_codename: 'luong_ninh',
            parentId: 456,
          },
          {
            title: 'Xã Vĩnh Ninh',
            code: 19210,
            value: 'xa_vinh_ninh',
            division_type: 'xã',
            short_codename: 'vinh_ninh',
            parentId: 456,
          },
          {
            title: 'Xã Võ Ninh',
            code: 19213,
            value: 'xa_vo_ninh',
            division_type: 'xã',
            short_codename: 'vo_ninh',
            parentId: 456,
          },
          {
            title: 'Xã Hải Ninh',
            code: 19216,
            value: 'xa_hai_ninh',
            division_type: 'xã',
            short_codename: 'hai_ninh',
            parentId: 456,
          },
          {
            title: 'Xã Hàm Ninh',
            code: 19219,
            value: 'xa_ham_ninh',
            division_type: 'xã',
            short_codename: 'ham_ninh',
            parentId: 456,
          },
          {
            title: 'Xã Duy Ninh',
            code: 19222,
            value: 'xa_duy_ninh',
            division_type: 'xã',
            short_codename: 'duy_ninh',
            parentId: 456,
          },
          {
            title: 'Xã Gia Ninh',
            code: 19225,
            value: 'xa_gia_ninh',
            division_type: 'xã',
            short_codename: 'gia_ninh',
            parentId: 456,
          },
          {
            title: 'Xã Trường Xuân',
            code: 19228,
            value: 'xa_truong_xuan',
            division_type: 'xã',
            short_codename: 'truong_xuan',
            parentId: 456,
          },
          {
            title: 'Xã Hiền Ninh',
            code: 19231,
            value: 'xa_hien_ninh',
            division_type: 'xã',
            short_codename: 'hien_ninh',
            parentId: 456,
          },
          {
            title: 'Xã Tân Ninh',
            code: 19234,
            value: 'xa_tan_ninh',
            division_type: 'xã',
            short_codename: 'tan_ninh',
            parentId: 456,
          },
          {
            title: 'Xã Xuân Ninh',
            code: 19237,
            value: 'xa_xuan_ninh',
            division_type: 'xã',
            short_codename: 'xuan_ninh',
            parentId: 456,
          },
          {
            title: 'Xã An Ninh',
            code: 19240,
            value: 'xa_an_ninh',
            division_type: 'xã',
            short_codename: 'an_ninh',
            parentId: 456,
          },
          {
            title: 'Xã Vạn Ninh',
            code: 19243,
            value: 'xa_van_ninh',
            division_type: 'xã',
            short_codename: 'van_ninh',
            parentId: 456,
          },
        ],
        parentId: 44,
      },
      {
        title: 'Huyện Lệ Thủy',
        code: 457,
        value: 'huyen_le_thuy',
        division_type: 'huyện',
        short_codename: 'le_thuy',
        children: [
          {
            title: 'Thị trấn NT Lệ Ninh',
            code: 19246,
            value: 'thi_tran_nt_le_ninh',
            division_type: 'thị trấn',
            short_codename: 'nt_le_ninh',
            parentId: 457,
          },
          {
            title: 'Thị trấn Kiến Giang',
            code: 19249,
            value: 'thi_tran_kien_giang',
            division_type: 'thị trấn',
            short_codename: 'kien_giang',
            parentId: 457,
          },
          {
            title: 'Xã Hồng Thủy',
            code: 19252,
            value: 'xa_hong_thuy',
            division_type: 'xã',
            short_codename: 'hong_thuy',
            parentId: 457,
          },
          {
            title: 'Xã Ngư Thủy Bắc',
            code: 19255,
            value: 'xa_ngu_thuy_bac',
            division_type: 'xã',
            short_codename: 'ngu_thuy_bac',
            parentId: 457,
          },
          {
            title: 'Xã Hoa Thủy',
            code: 19258,
            value: 'xa_hoa_thuy',
            division_type: 'xã',
            short_codename: 'hoa_thuy',
            parentId: 457,
          },
          {
            title: 'Xã Thanh Thủy',
            code: 19261,
            value: 'xa_thanh_thuy',
            division_type: 'xã',
            short_codename: 'thanh_thuy',
            parentId: 457,
          },
          {
            title: 'Xã An Thủy',
            code: 19264,
            value: 'xa_an_thuy',
            division_type: 'xã',
            short_codename: 'an_thuy',
            parentId: 457,
          },
          {
            title: 'Xã Phong Thủy',
            code: 19267,
            value: 'xa_phong_thuy',
            division_type: 'xã',
            short_codename: 'phong_thuy',
            parentId: 457,
          },
          {
            title: 'Xã Cam Thủy',
            code: 19270,
            value: 'xa_cam_thuy',
            division_type: 'xã',
            short_codename: 'cam_thuy',
            parentId: 457,
          },
          {
            title: 'Xã Ngân Thủy',
            code: 19273,
            value: 'xa_ngan_thuy',
            division_type: 'xã',
            short_codename: 'ngan_thuy',
            parentId: 457,
          },
          {
            title: 'Xã Sơn Thủy',
            code: 19276,
            value: 'xa_son_thuy',
            division_type: 'xã',
            short_codename: 'son_thuy',
            parentId: 457,
          },
          {
            title: 'Xã Lộc Thủy',
            code: 19279,
            value: 'xa_loc_thuy',
            division_type: 'xã',
            short_codename: 'loc_thuy',
            parentId: 457,
          },
          {
            title: 'Xã Liên Thủy',
            code: 19285,
            value: 'xa_lien_thuy',
            division_type: 'xã',
            short_codename: 'lien_thuy',
            parentId: 457,
          },
          {
            title: 'Xã Hưng Thủy',
            code: 19288,
            value: 'xa_hung_thuy',
            division_type: 'xã',
            short_codename: 'hung_thuy',
            parentId: 457,
          },
          {
            title: 'Xã Dương Thủy',
            code: 19291,
            value: 'xa_duong_thuy',
            division_type: 'xã',
            short_codename: 'duong_thuy',
            parentId: 457,
          },
          {
            title: 'Xã Tân Thủy',
            code: 19294,
            value: 'xa_tan_thuy',
            division_type: 'xã',
            short_codename: 'tan_thuy',
            parentId: 457,
          },
          {
            title: 'Xã Phú Thủy',
            code: 19297,
            value: 'xa_phu_thuy',
            division_type: 'xã',
            short_codename: 'phu_thuy',
            parentId: 457,
          },
          {
            title: 'Xã Xuân Thủy',
            code: 19300,
            value: 'xa_xuan_thuy',
            division_type: 'xã',
            short_codename: 'xuan_thuy',
            parentId: 457,
          },
          {
            title: 'Xã Mỹ Thủy',
            code: 19303,
            value: 'xa_my_thuy',
            division_type: 'xã',
            short_codename: 'my_thuy',
            parentId: 457,
          },
          {
            title: 'Xã Ngư Thủy',
            code: 19306,
            value: 'xa_ngu_thuy',
            division_type: 'xã',
            short_codename: 'ngu_thuy',
            parentId: 457,
          },
          {
            title: 'Xã Mai Thủy',
            code: 19309,
            value: 'xa_mai_thuy',
            division_type: 'xã',
            short_codename: 'mai_thuy',
            parentId: 457,
          },
          {
            title: 'Xã Sen Thủy',
            code: 19312,
            value: 'xa_sen_thuy',
            division_type: 'xã',
            short_codename: 'sen_thuy',
            parentId: 457,
          },
          {
            title: 'Xã Thái Thủy',
            code: 19315,
            value: 'xa_thai_thuy',
            division_type: 'xã',
            short_codename: 'thai_thuy',
            parentId: 457,
          },
          {
            title: 'Xã Kim Thủy',
            code: 19318,
            value: 'xa_kim_thuy',
            division_type: 'xã',
            short_codename: 'kim_thuy',
            parentId: 457,
          },
          {
            title: 'Xã Trường Thủy',
            code: 19321,
            value: 'xa_truong_thuy',
            division_type: 'xã',
            short_codename: 'truong_thuy',
            parentId: 457,
          },
          {
            title: 'Xã Lâm Thủy',
            code: 19327,
            value: 'xa_lam_thuy',
            division_type: 'xã',
            short_codename: 'lam_thuy',
            parentId: 457,
          },
        ],
        parentId: 44,
      },
      {
        title: 'Thị xã Ba Đồn',
        code: 458,
        value: 'thi_xa_ba_don',
        division_type: 'thị xã',
        short_codename: 'ba_don',
        children: [
          {
            title: 'Phường Ba Đồn',
            code: 19009,
            value: 'phuong_ba_don',
            division_type: 'phường',
            short_codename: 'ba_don',
            parentId: 458,
          },
          {
            title: 'Phường Quảng Long',
            code: 19060,
            value: 'phuong_quang_long',
            division_type: 'phường',
            short_codename: 'quang_long',
            parentId: 458,
          },
          {
            title: 'Phường Quảng Thọ',
            code: 19066,
            value: 'phuong_quang_tho',
            division_type: 'phường',
            short_codename: 'quang_tho',
            parentId: 458,
          },
          {
            title: 'Xã Quảng Tiên',
            code: 19069,
            value: 'xa_quang_tien',
            division_type: 'xã',
            short_codename: 'quang_tien',
            parentId: 458,
          },
          {
            title: 'Xã Quảng Trung',
            code: 19075,
            value: 'xa_quang_trung',
            division_type: 'xã',
            short_codename: 'quang_trung',
            parentId: 458,
          },
          {
            title: 'Phường Quảng Phong',
            code: 19078,
            value: 'phuong_quang_phong',
            division_type: 'phường',
            short_codename: 'quang_phong',
            parentId: 458,
          },
          {
            title: 'Phường Quảng Thuận',
            code: 19081,
            value: 'phuong_quang_thuan',
            division_type: 'phường',
            short_codename: 'quang_thuan',
            parentId: 458,
          },
          {
            title: 'Xã Quảng Tân',
            code: 19084,
            value: 'xa_quang_tan',
            division_type: 'xã',
            short_codename: 'quang_tan',
            parentId: 458,
          },
          {
            title: 'Xã Quảng Hải',
            code: 19087,
            value: 'xa_quang_hai',
            division_type: 'xã',
            short_codename: 'quang_hai',
            parentId: 458,
          },
          {
            title: 'Xã Quảng Sơn',
            code: 19090,
            value: 'xa_quang_son',
            division_type: 'xã',
            short_codename: 'quang_son',
            parentId: 458,
          },
          {
            title: 'Xã Quảng Lộc',
            code: 19093,
            value: 'xa_quang_loc',
            division_type: 'xã',
            short_codename: 'quang_loc',
            parentId: 458,
          },
          {
            title: 'Xã Quảng Thủy',
            code: 19096,
            value: 'xa_quang_thuy',
            division_type: 'xã',
            short_codename: 'quang_thuy',
            parentId: 458,
          },
          {
            title: 'Xã Quảng Văn',
            code: 19099,
            value: 'xa_quang_van',
            division_type: 'xã',
            short_codename: 'quang_van',
            parentId: 458,
          },
          {
            title: 'Phường Quảng Phúc',
            code: 19102,
            value: 'phuong_quang_phuc',
            division_type: 'phường',
            short_codename: 'quang_phuc',
            parentId: 458,
          },
          {
            title: 'Xã Quảng Hòa',
            code: 19105,
            value: 'xa_quang_hoa',
            division_type: 'xã',
            short_codename: 'quang_hoa',
            parentId: 458,
          },
          {
            title: 'Xã Quảng Minh',
            code: 19108,
            value: 'xa_quang_minh',
            division_type: 'xã',
            short_codename: 'quang_minh',
            parentId: 458,
          },
        ],
        parentId: 44,
      },
    ],
  },
  {
    title: 'Tỉnh Quảng Trị',
    code: 45,
    value: 'tinh_quang_tri',
    division_type: 'tỉnh',
    phone_code: 233,
    children: [
      {
        title: 'Thành phố Đông Hà',
        code: 461,
        value: 'thanh_pho_dong_ha',
        division_type: 'thành phố',
        short_codename: 'dong_ha',
        children: [
          {
            title: 'Phường Đông Giang',
            code: 19330,
            value: 'phuong_dong_giang',
            division_type: 'phường',
            short_codename: 'dong_giang',
            parentId: 461,
          },
          {
            title: 'Phường 1',
            code: 19333,
            value: 'phuong_1',
            division_type: 'phường',
            short_codename: 'phuong_1',
            parentId: 461,
          },
          {
            title: 'Phường Đông Lễ',
            code: 19336,
            value: 'phuong_dong_le',
            division_type: 'phường',
            short_codename: 'dong_le',
            parentId: 461,
          },
          {
            title: 'Phường Đông Thanh',
            code: 19339,
            value: 'phuong_dong_thanh',
            division_type: 'phường',
            short_codename: 'dong_thanh',
            parentId: 461,
          },
          {
            title: 'Phường 2',
            code: 19342,
            value: 'phuong_2',
            division_type: 'phường',
            short_codename: 'phuong_2',
            parentId: 461,
          },
          {
            title: 'Phường 4',
            code: 19345,
            value: 'phuong_4',
            division_type: 'phường',
            short_codename: 'phuong_4',
            parentId: 461,
          },
          {
            title: 'Phường 5',
            code: 19348,
            value: 'phuong_5',
            division_type: 'phường',
            short_codename: 'phuong_5',
            parentId: 461,
          },
          {
            title: 'Phường Đông Lương',
            code: 19351,
            value: 'phuong_dong_luong',
            division_type: 'phường',
            short_codename: 'dong_luong',
            parentId: 461,
          },
          {
            title: 'Phường 3',
            code: 19354,
            value: 'phuong_3',
            division_type: 'phường',
            short_codename: 'phuong_3',
            parentId: 461,
          },
        ],
        parentId: 45,
      },
      {
        title: 'Thị xã Quảng Trị',
        code: 462,
        value: 'thi_xa_quang_tri',
        division_type: 'thị xã',
        short_codename: 'quang_tri',
        children: [
          {
            title: 'Phường 1',
            code: 19357,
            value: 'phuong_1',
            division_type: 'phường',
            short_codename: 'phuong_1',
            parentId: 462,
          },
          {
            title: 'Phường An Đôn',
            code: 19358,
            value: 'phuong_an_don',
            division_type: 'phường',
            short_codename: 'an_don',
            parentId: 462,
          },
          {
            title: 'Phường 2',
            code: 19360,
            value: 'phuong_2',
            division_type: 'phường',
            short_codename: 'phuong_2',
            parentId: 462,
          },
          {
            title: 'Phường 3',
            code: 19361,
            value: 'phuong_3',
            division_type: 'phường',
            short_codename: 'phuong_3',
            parentId: 462,
          },
          {
            title: 'Xã Hải Lệ',
            code: 19705,
            value: 'xa_hai_le',
            division_type: 'xã',
            short_codename: 'hai_le',
            parentId: 462,
          },
        ],
        parentId: 45,
      },
      {
        title: 'Huyện Vĩnh Linh',
        code: 464,
        value: 'huyen_vinh_linh',
        division_type: 'huyện',
        short_codename: 'vinh_linh',
        children: [
          {
            title: 'Thị trấn Hồ Xá',
            code: 19363,
            value: 'thi_tran_ho_xa',
            division_type: 'thị trấn',
            short_codename: 'ho_xa',
            parentId: 464,
          },
          {
            title: 'Thị trấn Bến Quan',
            code: 19366,
            value: 'thi_tran_ben_quan',
            division_type: 'thị trấn',
            short_codename: 'ben_quan',
            parentId: 464,
          },
          {
            title: 'Xã Vĩnh Thái',
            code: 19369,
            value: 'xa_vinh_thai',
            division_type: 'xã',
            short_codename: 'vinh_thai',
            parentId: 464,
          },
          {
            title: 'Xã Vĩnh Tú',
            code: 19372,
            value: 'xa_vinh_tu',
            division_type: 'xã',
            short_codename: 'vinh_tu',
            parentId: 464,
          },
          {
            title: 'Xã Vĩnh Chấp',
            code: 19375,
            value: 'xa_vinh_chap',
            division_type: 'xã',
            short_codename: 'vinh_chap',
            parentId: 464,
          },
          {
            title: 'Xã Trung Nam',
            code: 19378,
            value: 'xa_trung_nam',
            division_type: 'xã',
            short_codename: 'trung_nam',
            parentId: 464,
          },
          {
            title: 'Xã Kim Thạch',
            code: 19384,
            value: 'xa_kim_thach',
            division_type: 'xã',
            short_codename: 'kim_thach',
            parentId: 464,
          },
          {
            title: 'Xã Vĩnh Long',
            code: 19387,
            value: 'xa_vinh_long',
            division_type: 'xã',
            short_codename: 'vinh_long',
            parentId: 464,
          },
          {
            title: 'Xã Vĩnh Khê',
            code: 19393,
            value: 'xa_vinh_khe',
            division_type: 'xã',
            short_codename: 'vinh_khe',
            parentId: 464,
          },
          {
            title: 'Xã Vĩnh Hòa',
            code: 19396,
            value: 'xa_vinh_hoa',
            division_type: 'xã',
            short_codename: 'vinh_hoa',
            parentId: 464,
          },
          {
            title: 'Xã Vĩnh Thủy',
            code: 19402,
            value: 'xa_vinh_thuy',
            division_type: 'xã',
            short_codename: 'vinh_thuy',
            parentId: 464,
          },
          {
            title: 'Xã Vĩnh Lâm',
            code: 19405,
            value: 'xa_vinh_lam',
            division_type: 'xã',
            short_codename: 'vinh_lam',
            parentId: 464,
          },
          {
            title: 'Xã Hiền Thành',
            code: 19408,
            value: 'xa_hien_thanh',
            division_type: 'xã',
            short_codename: 'hien_thanh',
            parentId: 464,
          },
          {
            title: 'Thị trấn Cửa Tùng',
            code: 19414,
            value: 'thi_tran_cua_tung',
            division_type: 'thị trấn',
            short_codename: 'cua_tung',
            parentId: 464,
          },
          {
            title: 'Xã Vĩnh Hà',
            code: 19417,
            value: 'xa_vinh_ha',
            division_type: 'xã',
            short_codename: 'vinh_ha',
            parentId: 464,
          },
          {
            title: 'Xã Vĩnh Sơn',
            code: 19420,
            value: 'xa_vinh_son',
            division_type: 'xã',
            short_codename: 'vinh_son',
            parentId: 464,
          },
          {
            title: 'Xã Vĩnh Giang',
            code: 19423,
            value: 'xa_vinh_giang',
            division_type: 'xã',
            short_codename: 'vinh_giang',
            parentId: 464,
          },
          {
            title: 'Xã Vĩnh Ô',
            code: 19426,
            value: 'xa_vinh_o',
            division_type: 'xã',
            short_codename: 'vinh_o',
            parentId: 464,
          },
        ],
        parentId: 45,
      },
      {
        title: 'Huyện Hướng Hóa',
        code: 465,
        value: 'huyen_huong_hoa',
        division_type: 'huyện',
        short_codename: 'huong_hoa',
        children: [
          {
            title: 'Thị trấn Khe Sanh',
            code: 19429,
            value: 'thi_tran_khe_sanh',
            division_type: 'thị trấn',
            short_codename: 'khe_sanh',
            parentId: 465,
          },
          {
            title: 'Thị trấn Lao Bảo',
            code: 19432,
            value: 'thi_tran_lao_bao',
            division_type: 'thị trấn',
            short_codename: 'lao_bao',
            parentId: 465,
          },
          {
            title: 'Xã Hướng Lập',
            code: 19435,
            value: 'xa_huong_lap',
            division_type: 'xã',
            short_codename: 'huong_lap',
            parentId: 465,
          },
          {
            title: 'Xã Hướng Việt',
            code: 19438,
            value: 'xa_huong_viet',
            division_type: 'xã',
            short_codename: 'huong_viet',
            parentId: 465,
          },
          {
            title: 'Xã Hướng Phùng',
            code: 19441,
            value: 'xa_huong_phung',
            division_type: 'xã',
            short_codename: 'huong_phung',
            parentId: 465,
          },
          {
            title: 'Xã Hướng Sơn',
            code: 19444,
            value: 'xa_huong_son',
            division_type: 'xã',
            short_codename: 'huong_son',
            parentId: 465,
          },
          {
            title: 'Xã Hướng Linh',
            code: 19447,
            value: 'xa_huong_linh',
            division_type: 'xã',
            short_codename: 'huong_linh',
            parentId: 465,
          },
          {
            title: 'Xã Tân Hợp',
            code: 19450,
            value: 'xa_tan_hop',
            division_type: 'xã',
            short_codename: 'tan_hop',
            parentId: 465,
          },
          {
            title: 'Xã Hướng Tân',
            code: 19453,
            value: 'xa_huong_tan',
            division_type: 'xã',
            short_codename: 'huong_tan',
            parentId: 465,
          },
          {
            title: 'Xã Tân Thành',
            code: 19456,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 465,
          },
          {
            title: 'Xã Tân Long',
            code: 19459,
            value: 'xa_tan_long',
            division_type: 'xã',
            short_codename: 'tan_long',
            parentId: 465,
          },
          {
            title: 'Xã Tân Lập',
            code: 19462,
            value: 'xa_tan_lap',
            division_type: 'xã',
            short_codename: 'tan_lap',
            parentId: 465,
          },
          {
            title: 'Xã Tân Liên',
            code: 19465,
            value: 'xa_tan_lien',
            division_type: 'xã',
            short_codename: 'tan_lien',
            parentId: 465,
          },
          {
            title: 'Xã Húc',
            code: 19468,
            value: 'xa_huc',
            division_type: 'xã',
            short_codename: 'huc',
            parentId: 465,
          },
          {
            title: 'Xã Thuận',
            code: 19471,
            value: 'xa_thuan',
            division_type: 'xã',
            short_codename: 'thuan',
            parentId: 465,
          },
          {
            title: 'Xã Hướng Lộc',
            code: 19474,
            value: 'xa_huong_loc',
            division_type: 'xã',
            short_codename: 'huong_loc',
            parentId: 465,
          },
          {
            title: 'Xã Ba Tầng',
            code: 19477,
            value: 'xa_ba_tang',
            division_type: 'xã',
            short_codename: 'ba_tang',
            parentId: 465,
          },
          {
            title: 'Xã Thanh',
            code: 19480,
            value: 'xa_thanh',
            division_type: 'xã',
            short_codename: 'thanh',
            parentId: 465,
          },
          {
            title: 'Xã A Dơi',
            code: 19483,
            value: 'xa_a_doi',
            division_type: 'xã',
            short_codename: 'a_doi',
            parentId: 465,
          },
          {
            title: 'Xã Lìa',
            code: 19489,
            value: 'xa_lia',
            division_type: 'xã',
            short_codename: 'lia',
            parentId: 465,
          },
          {
            title: 'Xã Xy',
            code: 19492,
            value: 'xa_xy',
            division_type: 'xã',
            short_codename: 'xy',
            parentId: 465,
          },
        ],
        parentId: 45,
      },
      {
        title: 'Huyện Gio Linh',
        code: 466,
        value: 'huyen_gio_linh',
        division_type: 'huyện',
        short_codename: 'gio_linh',
        children: [
          {
            title: 'Thị trấn Gio Linh',
            code: 19495,
            value: 'thi_tran_gio_linh',
            division_type: 'thị trấn',
            short_codename: 'gio_linh',
            parentId: 466,
          },
          {
            title: 'Thị trấn Cửa Việt',
            code: 19496,
            value: 'thi_tran_cua_viet',
            division_type: 'thị trấn',
            short_codename: 'cua_viet',
            parentId: 466,
          },
          {
            title: 'Xã Trung Giang',
            code: 19498,
            value: 'xa_trung_giang',
            division_type: 'xã',
            short_codename: 'trung_giang',
            parentId: 466,
          },
          {
            title: 'Xã Trung Hải',
            code: 19501,
            value: 'xa_trung_hai',
            division_type: 'xã',
            short_codename: 'trung_hai',
            parentId: 466,
          },
          {
            title: 'Xã Trung Sơn',
            code: 19504,
            value: 'xa_trung_son',
            division_type: 'xã',
            short_codename: 'trung_son',
            parentId: 466,
          },
          {
            title: 'Xã Phong Bình',
            code: 19507,
            value: 'xa_phong_binh',
            division_type: 'xã',
            short_codename: 'phong_binh',
            parentId: 466,
          },
          {
            title: 'Xã Gio Mỹ',
            code: 19510,
            value: 'xa_gio_my',
            division_type: 'xã',
            short_codename: 'gio_my',
            parentId: 466,
          },
          {
            title: 'Xã Gio Hải',
            code: 19519,
            value: 'xa_gio_hai',
            division_type: 'xã',
            short_codename: 'gio_hai',
            parentId: 466,
          },
          {
            title: 'Xã Gio An',
            code: 19522,
            value: 'xa_gio_an',
            division_type: 'xã',
            short_codename: 'gio_an',
            parentId: 466,
          },
          {
            title: 'Xã Gio Châu',
            code: 19525,
            value: 'xa_gio_chau',
            division_type: 'xã',
            short_codename: 'gio_chau',
            parentId: 466,
          },
          {
            title: 'Xã Gio Việt',
            code: 19531,
            value: 'xa_gio_viet',
            division_type: 'xã',
            short_codename: 'gio_viet',
            parentId: 466,
          },
          {
            title: 'Xã Linh Trường',
            code: 19534,
            value: 'xa_linh_truong',
            division_type: 'xã',
            short_codename: 'linh_truong',
            parentId: 466,
          },
          {
            title: 'Xã Gio Sơn',
            code: 19537,
            value: 'xa_gio_son',
            division_type: 'xã',
            short_codename: 'gio_son',
            parentId: 466,
          },
          {
            title: 'Xã Gio Mai',
            code: 19543,
            value: 'xa_gio_mai',
            division_type: 'xã',
            short_codename: 'gio_mai',
            parentId: 466,
          },
          {
            title: 'Xã Hải Thái',
            code: 19546,
            value: 'xa_hai_thai',
            division_type: 'xã',
            short_codename: 'hai_thai',
            parentId: 466,
          },
          {
            title: 'Xã Linh Hải',
            code: 19549,
            value: 'xa_linh_hai',
            division_type: 'xã',
            short_codename: 'linh_hai',
            parentId: 466,
          },
          {
            title: 'Xã Gio Quang',
            code: 19552,
            value: 'xa_gio_quang',
            division_type: 'xã',
            short_codename: 'gio_quang',
            parentId: 466,
          },
        ],
        parentId: 45,
      },
      {
        title: 'Huyện Đa Krông',
        code: 467,
        value: 'huyen_da_krong',
        division_type: 'huyện',
        short_codename: 'da_krong',
        children: [
          {
            title: 'Thị trấn Krông Klang',
            code: 19555,
            value: 'thi_tran_krong_klang',
            division_type: 'thị trấn',
            short_codename: 'krong_klang',
            parentId: 467,
          },
          {
            title: 'Xã Mò Ó',
            code: 19558,
            value: 'xa_mo_o',
            division_type: 'xã',
            short_codename: 'mo_o',
            parentId: 467,
          },
          {
            title: 'Xã Hướng Hiệp',
            code: 19561,
            value: 'xa_huong_hiep',
            division_type: 'xã',
            short_codename: 'huong_hiep',
            parentId: 467,
          },
          {
            title: 'Xã Đa Krông',
            code: 19564,
            value: 'xa_da_krong',
            division_type: 'xã',
            short_codename: 'da_krong',
            parentId: 467,
          },
          {
            title: 'Xã Triệu Nguyên',
            code: 19567,
            value: 'xa_trieu_nguyen',
            division_type: 'xã',
            short_codename: 'trieu_nguyen',
            parentId: 467,
          },
          {
            title: 'Xã Ba Lòng',
            code: 19570,
            value: 'xa_ba_long',
            division_type: 'xã',
            short_codename: 'ba_long',
            parentId: 467,
          },
          {
            title: 'Xã Ba Nang',
            code: 19576,
            value: 'xa_ba_nang',
            division_type: 'xã',
            short_codename: 'ba_nang',
            parentId: 467,
          },
          {
            title: 'Xã Tà Long',
            code: 19579,
            value: 'xa_ta_long',
            division_type: 'xã',
            short_codename: 'ta_long',
            parentId: 467,
          },
          {
            title: 'Xã Húc Nghì',
            code: 19582,
            value: 'xa_huc_nghi',
            division_type: 'xã',
            short_codename: 'huc_nghi',
            parentId: 467,
          },
          {
            title: 'Xã A Vao',
            code: 19585,
            value: 'xa_a_vao',
            division_type: 'xã',
            short_codename: 'a_vao',
            parentId: 467,
          },
          {
            title: 'Xã Tà Rụt',
            code: 19588,
            value: 'xa_ta_rut',
            division_type: 'xã',
            short_codename: 'ta_rut',
            parentId: 467,
          },
          {
            title: 'Xã A Bung',
            code: 19591,
            value: 'xa_a_bung',
            division_type: 'xã',
            short_codename: 'a_bung',
            parentId: 467,
          },
          {
            title: 'Xã A Ngo',
            code: 19594,
            value: 'xa_a_ngo',
            division_type: 'xã',
            short_codename: 'a_ngo',
            parentId: 467,
          },
        ],
        parentId: 45,
      },
      {
        title: 'Huyện Cam Lộ',
        code: 468,
        value: 'huyen_cam_lo',
        division_type: 'huyện',
        short_codename: 'cam_lo',
        children: [
          {
            title: 'Thị trấn Cam Lộ',
            code: 19597,
            value: 'thi_tran_cam_lo',
            division_type: 'thị trấn',
            short_codename: 'cam_lo',
            parentId: 468,
          },
          {
            title: 'Xã Cam Tuyền',
            code: 19600,
            value: 'xa_cam_tuyen',
            division_type: 'xã',
            short_codename: 'cam_tuyen',
            parentId: 468,
          },
          {
            title: 'Xã Thanh An',
            code: 19603,
            value: 'xa_thanh_an',
            division_type: 'xã',
            short_codename: 'thanh_an',
            parentId: 468,
          },
          {
            title: 'Xã Cam Thủy',
            code: 19606,
            value: 'xa_cam_thuy',
            division_type: 'xã',
            short_codename: 'cam_thuy',
            parentId: 468,
          },
          {
            title: 'Xã Cam Thành',
            code: 19612,
            value: 'xa_cam_thanh',
            division_type: 'xã',
            short_codename: 'cam_thanh',
            parentId: 468,
          },
          {
            title: 'Xã Cam Hiếu',
            code: 19615,
            value: 'xa_cam_hieu',
            division_type: 'xã',
            short_codename: 'cam_hieu',
            parentId: 468,
          },
          {
            title: 'Xã Cam Chính',
            code: 19618,
            value: 'xa_cam_chinh',
            division_type: 'xã',
            short_codename: 'cam_chinh',
            parentId: 468,
          },
          {
            title: 'Xã Cam Nghĩa',
            code: 19621,
            value: 'xa_cam_nghia',
            division_type: 'xã',
            short_codename: 'cam_nghia',
            parentId: 468,
          },
        ],
        parentId: 45,
      },
      {
        title: 'Huyện Triệu Phong',
        code: 469,
        value: 'huyen_trieu_phong',
        division_type: 'huyện',
        short_codename: 'trieu_phong',
        children: [
          {
            title: 'Thị Trấn Ái Tử',
            code: 19624,
            value: 'thi_tran_ai_tu',
            division_type: 'thị trấn',
            short_codename: 'ai_tu',
            parentId: 469,
          },
          {
            title: 'Xã Triệu An',
            code: 19627,
            value: 'xa_trieu_an',
            division_type: 'xã',
            short_codename: 'trieu_an',
            parentId: 469,
          },
          {
            title: 'Xã Triệu Vân',
            code: 19630,
            value: 'xa_trieu_van',
            division_type: 'xã',
            short_codename: 'trieu_van',
            parentId: 469,
          },
          {
            title: 'Xã Triệu Phước',
            code: 19633,
            value: 'xa_trieu_phuoc',
            division_type: 'xã',
            short_codename: 'trieu_phuoc',
            parentId: 469,
          },
          {
            title: 'Xã Triệu Độ',
            code: 19636,
            value: 'xa_trieu_do',
            division_type: 'xã',
            short_codename: 'trieu_do',
            parentId: 469,
          },
          {
            title: 'Xã Triệu Trạch',
            code: 19639,
            value: 'xa_trieu_trach',
            division_type: 'xã',
            short_codename: 'trieu_trach',
            parentId: 469,
          },
          {
            title: 'Xã Triệu Thuận',
            code: 19642,
            value: 'xa_trieu_thuan',
            division_type: 'xã',
            short_codename: 'trieu_thuan',
            parentId: 469,
          },
          {
            title: 'Xã Triệu Đại',
            code: 19645,
            value: 'xa_trieu_dai',
            division_type: 'xã',
            short_codename: 'trieu_dai',
            parentId: 469,
          },
          {
            title: 'Xã Triệu Hòa',
            code: 19648,
            value: 'xa_trieu_hoa',
            division_type: 'xã',
            short_codename: 'trieu_hoa',
            parentId: 469,
          },
          {
            title: 'Xã Triệu Lăng',
            code: 19651,
            value: 'xa_trieu_lang',
            division_type: 'xã',
            short_codename: 'trieu_lang',
            parentId: 469,
          },
          {
            title: 'Xã Triệu Sơn',
            code: 19654,
            value: 'xa_trieu_son',
            division_type: 'xã',
            short_codename: 'trieu_son',
            parentId: 469,
          },
          {
            title: 'Xã Triệu Long',
            code: 19657,
            value: 'xa_trieu_long',
            division_type: 'xã',
            short_codename: 'trieu_long',
            parentId: 469,
          },
          {
            title: 'Xã Triệu Tài',
            code: 19660,
            value: 'xa_trieu_tai',
            division_type: 'xã',
            short_codename: 'trieu_tai',
            parentId: 469,
          },
          {
            title: 'Xã Triệu Trung',
            code: 19666,
            value: 'xa_trieu_trung',
            division_type: 'xã',
            short_codename: 'trieu_trung',
            parentId: 469,
          },
          {
            title: 'Xã Triệu Ái',
            code: 19669,
            value: 'xa_trieu_ai',
            division_type: 'xã',
            short_codename: 'trieu_ai',
            parentId: 469,
          },
          {
            title: 'Xã Triệu Thượng',
            code: 19672,
            value: 'xa_trieu_thuong',
            division_type: 'xã',
            short_codename: 'trieu_thuong',
            parentId: 469,
          },
          {
            title: 'Xã Triệu Giang',
            code: 19675,
            value: 'xa_trieu_giang',
            division_type: 'xã',
            short_codename: 'trieu_giang',
            parentId: 469,
          },
          {
            title: 'Xã Triệu Thành',
            code: 19678,
            value: 'xa_trieu_thanh',
            division_type: 'xã',
            short_codename: 'trieu_thanh',
            parentId: 469,
          },
        ],
        parentId: 45,
      },
      {
        title: 'Huyện Hải Lăng',
        code: 470,
        value: 'huyen_hai_lang',
        division_type: 'huyện',
        short_codename: 'hai_lang',
        children: [
          {
            title: 'Thị trấn Diên Sanh',
            code: 19681,
            value: 'thi_tran_dien_sanh',
            division_type: 'thị trấn',
            short_codename: 'dien_sanh',
            parentId: 470,
          },
          {
            title: 'Xã Hải An',
            code: 19684,
            value: 'xa_hai_an',
            division_type: 'xã',
            short_codename: 'hai_an',
            parentId: 470,
          },
          {
            title: 'Xã Hải Ba',
            code: 19687,
            value: 'xa_hai_ba',
            division_type: 'xã',
            short_codename: 'hai_ba',
            parentId: 470,
          },
          {
            title: 'Xã Hải Quy',
            code: 19693,
            value: 'xa_hai_quy',
            division_type: 'xã',
            short_codename: 'hai_quy',
            parentId: 470,
          },
          {
            title: 'Xã Hải Quế',
            code: 19696,
            value: 'xa_hai_que',
            division_type: 'xã',
            short_codename: 'hai_que',
            parentId: 470,
          },
          {
            title: 'Xã Hải Hưng',
            code: 19699,
            value: 'xa_hai_hung',
            division_type: 'xã',
            short_codename: 'hai_hung',
            parentId: 470,
          },
          {
            title: 'Xã Hải Phú',
            code: 19702,
            value: 'xa_hai_phu',
            division_type: 'xã',
            short_codename: 'hai_phu',
            parentId: 470,
          },
          {
            title: 'Xã Hải Thượng',
            code: 19708,
            value: 'xa_hai_thuong',
            division_type: 'xã',
            short_codename: 'hai_thuong',
            parentId: 470,
          },
          {
            title: 'Xã Hải Dương',
            code: 19711,
            value: 'xa_hai_duong',
            division_type: 'xã',
            short_codename: 'hai_duong',
            parentId: 470,
          },
          {
            title: 'Xã Hải Định',
            code: 19714,
            value: 'xa_hai_dinh',
            division_type: 'xã',
            short_codename: 'hai_dinh',
            parentId: 470,
          },
          {
            title: 'Xã Hải Lâm',
            code: 19717,
            value: 'xa_hai_lam',
            division_type: 'xã',
            short_codename: 'hai_lam',
            parentId: 470,
          },
          {
            title: 'Xã Hải Phong',
            code: 19726,
            value: 'xa_hai_phong',
            division_type: 'xã',
            short_codename: 'hai_phong',
            parentId: 470,
          },
          {
            title: 'Xã Hải Trường',
            code: 19729,
            value: 'xa_hai_truong',
            division_type: 'xã',
            short_codename: 'hai_truong',
            parentId: 470,
          },
          {
            title: 'Xã Hải Sơn',
            code: 19735,
            value: 'xa_hai_son',
            division_type: 'xã',
            short_codename: 'hai_son',
            parentId: 470,
          },
          {
            title: 'Xã Hải Chánh',
            code: 19738,
            value: 'xa_hai_chanh',
            division_type: 'xã',
            short_codename: 'hai_chanh',
            parentId: 470,
          },
          {
            title: 'Xã Hải Khê',
            code: 19741,
            value: 'xa_hai_khe',
            division_type: 'xã',
            short_codename: 'hai_khe',
            parentId: 470,
          },
        ],
        parentId: 45,
      },
      {
        title: 'Huyện Cồn Cỏ',
        code: 471,
        value: 'huyen_con_co',
        division_type: 'huyện',
        short_codename: 'con_co',
        children: [],
        parentId: 45,
      },
    ],
  },
  {
    title: 'Tỉnh Thừa Thiên Huế',
    code: 46,
    value: 'tinh_thua_thien_hue',
    division_type: 'tỉnh',
    phone_code: 234,
    children: [
      {
        title: 'Thành phố Huế',
        code: 474,
        value: 'thanh_pho_hue',
        division_type: 'thành phố',
        short_codename: 'hue',
        children: [
          {
            title: 'Phường Phú Thuận',
            code: 19744,
            value: 'phuong_phu_thuan',
            division_type: 'phường',
            short_codename: 'phu_thuan',
            parentId: 474,
          },
          {
            title: 'Phường Phú Bình',
            code: 19747,
            value: 'phuong_phu_binh',
            division_type: 'phường',
            short_codename: 'phu_binh',
            parentId: 474,
          },
          {
            title: 'Phường Tây Lộc',
            code: 19750,
            value: 'phuong_tay_loc',
            division_type: 'phường',
            short_codename: 'tay_loc',
            parentId: 474,
          },
          {
            title: 'Phường Thuận Lộc',
            code: 19753,
            value: 'phuong_thuan_loc',
            division_type: 'phường',
            short_codename: 'thuan_loc',
            parentId: 474,
          },
          {
            title: 'Phường Phú Hiệp',
            code: 19756,
            value: 'phuong_phu_hiep',
            division_type: 'phường',
            short_codename: 'phu_hiep',
            parentId: 474,
          },
          {
            title: 'Phường Phú Hậu',
            code: 19759,
            value: 'phuong_phu_hau',
            division_type: 'phường',
            short_codename: 'phu_hau',
            parentId: 474,
          },
          {
            title: 'Phường Thuận Hòa',
            code: 19762,
            value: 'phuong_thuan_hoa',
            division_type: 'phường',
            short_codename: 'thuan_hoa',
            parentId: 474,
          },
          {
            title: 'Phường Thuận Thành',
            code: 19765,
            value: 'phuong_thuan_thanh',
            division_type: 'phường',
            short_codename: 'thuan_thanh',
            parentId: 474,
          },
          {
            title: 'Phường Phú Hòa',
            code: 19768,
            value: 'phuong_phu_hoa',
            division_type: 'phường',
            short_codename: 'phu_hoa',
            parentId: 474,
          },
          {
            title: 'Phường Phú Cát',
            code: 19771,
            value: 'phuong_phu_cat',
            division_type: 'phường',
            short_codename: 'phu_cat',
            parentId: 474,
          },
          {
            title: 'Phường Kim Long',
            code: 19774,
            value: 'phuong_kim_long',
            division_type: 'phường',
            short_codename: 'kim_long',
            parentId: 474,
          },
          {
            title: 'Phường Vỹ Dạ',
            code: 19777,
            value: 'phuong_vy_da',
            division_type: 'phường',
            short_codename: 'vy_da',
            parentId: 474,
          },
          {
            title: 'Phường Phường Đúc',
            code: 19780,
            value: 'phuong_phuong_duc',
            division_type: 'phường',
            short_codename: 'phuong_duc',
            parentId: 474,
          },
          {
            title: 'Phường Vĩnh Ninh',
            code: 19783,
            value: 'phuong_vinh_ninh',
            division_type: 'phường',
            short_codename: 'vinh_ninh',
            parentId: 474,
          },
          {
            title: 'Phường Phú Hội',
            code: 19786,
            value: 'phuong_phu_hoi',
            division_type: 'phường',
            short_codename: 'phu_hoi',
            parentId: 474,
          },
          {
            title: 'Phường Phú Nhuận',
            code: 19789,
            value: 'phuong_phu_nhuan',
            division_type: 'phường',
            short_codename: 'phu_nhuan',
            parentId: 474,
          },
          {
            title: 'Phường Xuân Phú',
            code: 19792,
            value: 'phuong_xuan_phu',
            division_type: 'phường',
            short_codename: 'xuan_phu',
            parentId: 474,
          },
          {
            title: 'Phường Trường An',
            code: 19795,
            value: 'phuong_truong_an',
            division_type: 'phường',
            short_codename: 'truong_an',
            parentId: 474,
          },
          {
            title: 'Phường Phước Vĩnh',
            code: 19798,
            value: 'phuong_phuoc_vinh',
            division_type: 'phường',
            short_codename: 'phuoc_vinh',
            parentId: 474,
          },
          {
            title: 'Phường An Cựu',
            code: 19801,
            value: 'phuong_an_cuu',
            division_type: 'phường',
            short_codename: 'an_cuu',
            parentId: 474,
          },
          {
            title: 'Phường An Hòa',
            code: 19803,
            value: 'phuong_an_hoa',
            division_type: 'phường',
            short_codename: 'an_hoa',
            parentId: 474,
          },
          {
            title: 'Phường Hương Sơ',
            code: 19804,
            value: 'phuong_huong_so',
            division_type: 'phường',
            short_codename: 'huong_so',
            parentId: 474,
          },
          {
            title: 'Phường Thuỷ Biều',
            code: 19807,
            value: 'phuong_thuy_bieu',
            division_type: 'phường',
            short_codename: 'thuy_bieu',
            parentId: 474,
          },
          {
            title: 'Phường Hương Long',
            code: 19810,
            value: 'phuong_huong_long',
            division_type: 'phường',
            short_codename: 'huong_long',
            parentId: 474,
          },
          {
            title: 'Phường Thuỷ Xuân',
            code: 19813,
            value: 'phuong_thuy_xuan',
            division_type: 'phường',
            short_codename: 'thuy_xuan',
            parentId: 474,
          },
          {
            title: 'Phường An Đông',
            code: 19815,
            value: 'phuong_an_dong',
            division_type: 'phường',
            short_codename: 'an_dong',
            parentId: 474,
          },
          {
            title: 'Phường An Tây',
            code: 19816,
            value: 'phuong_an_tay',
            division_type: 'phường',
            short_codename: 'an_tay',
            parentId: 474,
          },
        ],
        parentId: 46,
      },
      {
        title: 'Huyện Phong Điền',
        code: 476,
        value: 'huyen_phong_dien',
        division_type: 'huyện',
        short_codename: 'phong_dien',
        children: [
          {
            title: 'Thị trấn Phong Điền',
            code: 19819,
            value: 'thi_tran_phong_dien',
            division_type: 'thị trấn',
            short_codename: 'phong_dien',
            parentId: 476,
          },
          {
            title: 'Xã Điền Hương',
            code: 19822,
            value: 'xa_dien_huong',
            division_type: 'xã',
            short_codename: 'dien_huong',
            parentId: 476,
          },
          {
            title: 'Xã Điền Môn',
            code: 19825,
            value: 'xa_dien_mon',
            division_type: 'xã',
            short_codename: 'dien_mon',
            parentId: 476,
          },
          {
            title: 'Xã Điền Lộc',
            code: 19828,
            value: 'xa_dien_loc',
            division_type: 'xã',
            short_codename: 'dien_loc',
            parentId: 476,
          },
          {
            title: 'Xã Phong Bình',
            code: 19831,
            value: 'xa_phong_binh',
            division_type: 'xã',
            short_codename: 'phong_binh',
            parentId: 476,
          },
          {
            title: 'Xã Điền Hòa',
            code: 19834,
            value: 'xa_dien_hoa',
            division_type: 'xã',
            short_codename: 'dien_hoa',
            parentId: 476,
          },
          {
            title: 'Xã Phong Chương',
            code: 19837,
            value: 'xa_phong_chuong',
            division_type: 'xã',
            short_codename: 'phong_chuong',
            parentId: 476,
          },
          {
            title: 'Xã Phong Hải',
            code: 19840,
            value: 'xa_phong_hai',
            division_type: 'xã',
            short_codename: 'phong_hai',
            parentId: 476,
          },
          {
            title: 'Xã Điền Hải',
            code: 19843,
            value: 'xa_dien_hai',
            division_type: 'xã',
            short_codename: 'dien_hai',
            parentId: 476,
          },
          {
            title: 'Xã Phong Hòa',
            code: 19846,
            value: 'xa_phong_hoa',
            division_type: 'xã',
            short_codename: 'phong_hoa',
            parentId: 476,
          },
          {
            title: 'Xã Phong Thu',
            code: 19849,
            value: 'xa_phong_thu',
            division_type: 'xã',
            short_codename: 'phong_thu',
            parentId: 476,
          },
          {
            title: 'Xã Phong Hiền',
            code: 19852,
            value: 'xa_phong_hien',
            division_type: 'xã',
            short_codename: 'phong_hien',
            parentId: 476,
          },
          {
            title: 'Xã Phong Mỹ',
            code: 19855,
            value: 'xa_phong_my',
            division_type: 'xã',
            short_codename: 'phong_my',
            parentId: 476,
          },
          {
            title: 'Xã Phong An',
            code: 19858,
            value: 'xa_phong_an',
            division_type: 'xã',
            short_codename: 'phong_an',
            parentId: 476,
          },
          {
            title: 'Xã Phong Xuân',
            code: 19861,
            value: 'xa_phong_xuan',
            division_type: 'xã',
            short_codename: 'phong_xuan',
            parentId: 476,
          },
          {
            title: 'Xã Phong Sơn',
            code: 19864,
            value: 'xa_phong_son',
            division_type: 'xã',
            short_codename: 'phong_son',
            parentId: 476,
          },
        ],
        parentId: 46,
      },
      {
        title: 'Huyện Quảng Điền',
        code: 477,
        value: 'huyen_quang_dien',
        division_type: 'huyện',
        short_codename: 'quang_dien',
        children: [
          {
            title: 'Thị trấn Sịa',
            code: 19867,
            value: 'thi_tran_sia',
            division_type: 'thị trấn',
            short_codename: 'sia',
            parentId: 477,
          },
          {
            title: 'Xã Quảng Thái',
            code: 19870,
            value: 'xa_quang_thai',
            division_type: 'xã',
            short_codename: 'quang_thai',
            parentId: 477,
          },
          {
            title: 'Xã Quảng Ngạn',
            code: 19873,
            value: 'xa_quang_ngan',
            division_type: 'xã',
            short_codename: 'quang_ngan',
            parentId: 477,
          },
          {
            title: 'Xã Quảng Lợi',
            code: 19876,
            value: 'xa_quang_loi',
            division_type: 'xã',
            short_codename: 'quang_loi',
            parentId: 477,
          },
          {
            title: 'Xã Quảng Công',
            code: 19879,
            value: 'xa_quang_cong',
            division_type: 'xã',
            short_codename: 'quang_cong',
            parentId: 477,
          },
          {
            title: 'Xã Quảng Phước',
            code: 19882,
            value: 'xa_quang_phuoc',
            division_type: 'xã',
            short_codename: 'quang_phuoc',
            parentId: 477,
          },
          {
            title: 'Xã Quảng Vinh',
            code: 19885,
            value: 'xa_quang_vinh',
            division_type: 'xã',
            short_codename: 'quang_vinh',
            parentId: 477,
          },
          {
            title: 'Xã Quảng An',
            code: 19888,
            value: 'xa_quang_an',
            division_type: 'xã',
            short_codename: 'quang_an',
            parentId: 477,
          },
          {
            title: 'Xã Quảng Thành',
            code: 19891,
            value: 'xa_quang_thanh',
            division_type: 'xã',
            short_codename: 'quang_thanh',
            parentId: 477,
          },
          {
            title: 'Xã Quảng Thọ',
            code: 19894,
            value: 'xa_quang_tho',
            division_type: 'xã',
            short_codename: 'quang_tho',
            parentId: 477,
          },
          {
            title: 'Xã Quảng Phú',
            code: 19897,
            value: 'xa_quang_phu',
            division_type: 'xã',
            short_codename: 'quang_phu',
            parentId: 477,
          },
        ],
        parentId: 46,
      },
      {
        title: 'Huyện Phú Vang',
        code: 478,
        value: 'huyen_phu_vang',
        division_type: 'huyện',
        short_codename: 'phu_vang',
        children: [
          {
            title: 'Thị trấn Thuận An',
            code: 19900,
            value: 'thi_tran_thuan_an',
            division_type: 'thị trấn',
            short_codename: 'thuan_an',
            parentId: 478,
          },
          {
            title: 'Xã Phú Thuận',
            code: 19903,
            value: 'xa_phu_thuan',
            division_type: 'xã',
            short_codename: 'phu_thuan',
            parentId: 478,
          },
          {
            title: 'Xã Phú Dương',
            code: 19906,
            value: 'xa_phu_duong',
            division_type: 'xã',
            short_codename: 'phu_duong',
            parentId: 478,
          },
          {
            title: 'Xã Phú Mậu',
            code: 19909,
            value: 'xa_phu_mau',
            division_type: 'xã',
            short_codename: 'phu_mau',
            parentId: 478,
          },
          {
            title: 'Xã Phú An',
            code: 19912,
            value: 'xa_phu_an',
            division_type: 'xã',
            short_codename: 'phu_an',
            parentId: 478,
          },
          {
            title: 'Xã Phú Hải',
            code: 19915,
            value: 'xa_phu_hai',
            division_type: 'xã',
            short_codename: 'phu_hai',
            parentId: 478,
          },
          {
            title: 'Xã Phú Xuân',
            code: 19918,
            value: 'xa_phu_xuan',
            division_type: 'xã',
            short_codename: 'phu_xuan',
            parentId: 478,
          },
          {
            title: 'Xã Phú Diên',
            code: 19921,
            value: 'xa_phu_dien',
            division_type: 'xã',
            short_codename: 'phu_dien',
            parentId: 478,
          },
          {
            title: 'Xã Phú Thanh',
            code: 19924,
            value: 'xa_phu_thanh',
            division_type: 'xã',
            short_codename: 'phu_thanh',
            parentId: 478,
          },
          {
            title: 'Xã Phú Mỹ',
            code: 19927,
            value: 'xa_phu_my',
            division_type: 'xã',
            short_codename: 'phu_my',
            parentId: 478,
          },
          {
            title: 'Xã Phú Thượng',
            code: 19930,
            value: 'xa_phu_thuong',
            division_type: 'xã',
            short_codename: 'phu_thuong',
            parentId: 478,
          },
          {
            title: 'Xã Phú Hồ',
            code: 19933,
            value: 'xa_phu_ho',
            division_type: 'xã',
            short_codename: 'phu_ho',
            parentId: 478,
          },
          {
            title: 'Xã Vinh Xuân',
            code: 19936,
            value: 'xa_vinh_xuan',
            division_type: 'xã',
            short_codename: 'vinh_xuan',
            parentId: 478,
          },
          {
            title: 'Xã Phú Lương',
            code: 19939,
            value: 'xa_phu_luong',
            division_type: 'xã',
            short_codename: 'phu_luong',
            parentId: 478,
          },
          {
            title: 'Thị trấn Phú Đa',
            code: 19942,
            value: 'thi_tran_phu_da',
            division_type: 'thị trấn',
            short_codename: 'phu_da',
            parentId: 478,
          },
          {
            title: 'Xã Vinh Thanh',
            code: 19945,
            value: 'xa_vinh_thanh',
            division_type: 'xã',
            short_codename: 'vinh_thanh',
            parentId: 478,
          },
          {
            title: 'Xã Vinh An',
            code: 19948,
            value: 'xa_vinh_an',
            division_type: 'xã',
            short_codename: 'vinh_an',
            parentId: 478,
          },
          {
            title: 'Xã Phú Gia',
            code: 19954,
            value: 'xa_phu_gia',
            division_type: 'xã',
            short_codename: 'phu_gia',
            parentId: 478,
          },
          {
            title: 'Xã Vinh Hà',
            code: 19957,
            value: 'xa_vinh_ha',
            division_type: 'xã',
            short_codename: 'vinh_ha',
            parentId: 478,
          },
        ],
        parentId: 46,
      },
      {
        title: 'Thị xã Hương Thủy',
        code: 479,
        value: 'thi_xa_huong_thuy',
        division_type: 'thị xã',
        short_codename: 'huong_thuy',
        children: [
          {
            title: 'Phường Phú Bài',
            code: 19960,
            value: 'phuong_phu_bai',
            division_type: 'phường',
            short_codename: 'phu_bai',
            parentId: 479,
          },
          {
            title: 'Xã Thủy Vân',
            code: 19963,
            value: 'xa_thuy_van',
            division_type: 'xã',
            short_codename: 'thuy_van',
            parentId: 479,
          },
          {
            title: 'Xã Thủy Thanh',
            code: 19966,
            value: 'xa_thuy_thanh',
            division_type: 'xã',
            short_codename: 'thuy_thanh',
            parentId: 479,
          },
          {
            title: 'Phường Thủy Dương',
            code: 19969,
            value: 'phuong_thuy_duong',
            division_type: 'phường',
            short_codename: 'thuy_duong',
            parentId: 479,
          },
          {
            title: 'Phường Thủy Phương',
            code: 19972,
            value: 'phuong_thuy_phuong',
            division_type: 'phường',
            short_codename: 'thuy_phuong',
            parentId: 479,
          },
          {
            title: 'Phường Thủy Châu',
            code: 19975,
            value: 'phuong_thuy_chau',
            division_type: 'phường',
            short_codename: 'thuy_chau',
            parentId: 479,
          },
          {
            title: 'Phường Thủy Lương',
            code: 19978,
            value: 'phuong_thuy_luong',
            division_type: 'phường',
            short_codename: 'thuy_luong',
            parentId: 479,
          },
          {
            title: 'Xã Thủy Bằng',
            code: 19981,
            value: 'xa_thuy_bang',
            division_type: 'xã',
            short_codename: 'thuy_bang',
            parentId: 479,
          },
          {
            title: 'Xã Thủy Tân',
            code: 19984,
            value: 'xa_thuy_tan',
            division_type: 'xã',
            short_codename: 'thuy_tan',
            parentId: 479,
          },
          {
            title: 'Xã Thủy Phù',
            code: 19987,
            value: 'xa_thuy_phu',
            division_type: 'xã',
            short_codename: 'thuy_phu',
            parentId: 479,
          },
          {
            title: 'Xã Phú Sơn',
            code: 19990,
            value: 'xa_phu_son',
            division_type: 'xã',
            short_codename: 'phu_son',
            parentId: 479,
          },
          {
            title: 'Xã Dương Hòa',
            code: 19993,
            value: 'xa_duong_hoa',
            division_type: 'xã',
            short_codename: 'duong_hoa',
            parentId: 479,
          },
        ],
        parentId: 46,
      },
      {
        title: 'Thị xã Hương Trà',
        code: 480,
        value: 'thi_xa_huong_tra',
        division_type: 'thị xã',
        short_codename: 'huong_tra',
        children: [
          {
            title: 'Phường Tứ Hạ',
            code: 19996,
            value: 'phuong_tu_ha',
            division_type: 'phường',
            short_codename: 'tu_ha',
            parentId: 480,
          },
          {
            title: 'Xã Hải Dương',
            code: 19999,
            value: 'xa_hai_duong',
            division_type: 'xã',
            short_codename: 'hai_duong',
            parentId: 480,
          },
          {
            title: 'Xã Hương Phong',
            code: 20002,
            value: 'xa_huong_phong',
            division_type: 'xã',
            short_codename: 'huong_phong',
            parentId: 480,
          },
          {
            title: 'Xã Hương Toàn',
            code: 20005,
            value: 'xa_huong_toan',
            division_type: 'xã',
            short_codename: 'huong_toan',
            parentId: 480,
          },
          {
            title: 'Phường Hương Vân',
            code: 20008,
            value: 'phuong_huong_van',
            division_type: 'phường',
            short_codename: 'huong_van',
            parentId: 480,
          },
          {
            title: 'Phường Hương Văn',
            code: 20011,
            value: 'phuong_huong_van',
            division_type: 'phường',
            short_codename: 'huong_van',
            parentId: 480,
          },
          {
            title: 'Xã Hương Vinh',
            code: 20014,
            value: 'xa_huong_vinh',
            division_type: 'xã',
            short_codename: 'huong_vinh',
            parentId: 480,
          },
          {
            title: 'Phường Hương Xuân',
            code: 20017,
            value: 'phuong_huong_xuan',
            division_type: 'phường',
            short_codename: 'huong_xuan',
            parentId: 480,
          },
          {
            title: 'Phường Hương Chữ',
            code: 20020,
            value: 'phuong_huong_chu',
            division_type: 'phường',
            short_codename: 'huong_chu',
            parentId: 480,
          },
          {
            title: 'Phường Hương An',
            code: 20023,
            value: 'phuong_huong_an',
            division_type: 'phường',
            short_codename: 'huong_an',
            parentId: 480,
          },
          {
            title: 'Xã Hương Bình',
            code: 20026,
            value: 'xa_huong_binh',
            division_type: 'xã',
            short_codename: 'huong_binh',
            parentId: 480,
          },
          {
            title: 'Phường Hương Hồ',
            code: 20029,
            value: 'phuong_huong_ho',
            division_type: 'phường',
            short_codename: 'huong_ho',
            parentId: 480,
          },
          {
            title: 'Xã Hương Thọ',
            code: 20032,
            value: 'xa_huong_tho',
            division_type: 'xã',
            short_codename: 'huong_tho',
            parentId: 480,
          },
          {
            title: 'Xã Bình Tiến',
            code: 20035,
            value: 'xa_binh_tien',
            division_type: 'xã',
            short_codename: 'binh_tien',
            parentId: 480,
          },
          {
            title: 'Xã Bình Thành',
            code: 20041,
            value: 'xa_binh_thanh',
            division_type: 'xã',
            short_codename: 'binh_thanh',
            parentId: 480,
          },
        ],
        parentId: 46,
      },
      {
        title: 'Huyện A Lưới',
        code: 481,
        value: 'huyen_a_luoi',
        division_type: 'huyện',
        short_codename: 'a_luoi',
        children: [
          {
            title: 'Thị trấn A Lưới',
            code: 20044,
            value: 'thi_tran_a_luoi',
            division_type: 'thị trấn',
            short_codename: 'a_luoi',
            parentId: 481,
          },
          {
            title: 'Xã Hồng Vân',
            code: 20047,
            value: 'xa_hong_van',
            division_type: 'xã',
            short_codename: 'hong_van',
            parentId: 481,
          },
          {
            title: 'Xã Hồng Hạ',
            code: 20050,
            value: 'xa_hong_ha',
            division_type: 'xã',
            short_codename: 'hong_ha',
            parentId: 481,
          },
          {
            title: 'Xã Hồng Kim',
            code: 20053,
            value: 'xa_hong_kim',
            division_type: 'xã',
            short_codename: 'hong_kim',
            parentId: 481,
          },
          {
            title: 'Xã Trung Sơn',
            code: 20056,
            value: 'xa_trung_son',
            division_type: 'xã',
            short_codename: 'trung_son',
            parentId: 481,
          },
          {
            title: 'Xã Hương Nguyên',
            code: 20059,
            value: 'xa_huong_nguyen',
            division_type: 'xã',
            short_codename: 'huong_nguyen',
            parentId: 481,
          },
          {
            title: 'Xã Hồng Bắc',
            code: 20065,
            value: 'xa_hong_bac',
            division_type: 'xã',
            short_codename: 'hong_bac',
            parentId: 481,
          },
          {
            title: 'Xã A Ngo',
            code: 20068,
            value: 'xa_a_ngo',
            division_type: 'xã',
            short_codename: 'a_ngo',
            parentId: 481,
          },
          {
            title: 'Xã Sơn Thủy',
            code: 20071,
            value: 'xa_son_thuy',
            division_type: 'xã',
            short_codename: 'son_thuy',
            parentId: 481,
          },
          {
            title: 'Xã Phú Vinh',
            code: 20074,
            value: 'xa_phu_vinh',
            division_type: 'xã',
            short_codename: 'phu_vinh',
            parentId: 481,
          },
          {
            title: 'Xã Hương Phong',
            code: 20080,
            value: 'xa_huong_phong',
            division_type: 'xã',
            short_codename: 'huong_phong',
            parentId: 481,
          },
          {
            title: 'Xã Quảng Nhâm',
            code: 20083,
            value: 'xa_quang_nham',
            division_type: 'xã',
            short_codename: 'quang_nham',
            parentId: 481,
          },
          {
            title: 'Xã Hồng Thượng',
            code: 20086,
            value: 'xa_hong_thuong',
            division_type: 'xã',
            short_codename: 'hong_thuong',
            parentId: 481,
          },
          {
            title: 'Xã Hồng Thái',
            code: 20089,
            value: 'xa_hong_thai',
            division_type: 'xã',
            short_codename: 'hong_thai',
            parentId: 481,
          },
          {
            title: 'Xã A Roàng',
            code: 20095,
            value: 'xa_a_roang',
            division_type: 'xã',
            short_codename: 'a_roang',
            parentId: 481,
          },
          {
            title: 'Xã Đông Sơn',
            code: 20098,
            value: 'xa_dong_son',
            division_type: 'xã',
            short_codename: 'dong_son',
            parentId: 481,
          },
          {
            title: 'Xã Lâm Đớt',
            code: 20101,
            value: 'xa_lam_dot',
            division_type: 'xã',
            short_codename: 'lam_dot',
            parentId: 481,
          },
          {
            title: 'Xã Hồng Thủy',
            code: 20104,
            value: 'xa_hong_thuy',
            division_type: 'xã',
            short_codename: 'hong_thuy',
            parentId: 481,
          },
        ],
        parentId: 46,
      },
      {
        title: 'Huyện Phú Lộc',
        code: 482,
        value: 'huyen_phu_loc',
        division_type: 'huyện',
        short_codename: 'phu_loc',
        children: [
          {
            title: 'Thị trấn Phú Lộc',
            code: 20107,
            value: 'thi_tran_phu_loc',
            division_type: 'thị trấn',
            short_codename: 'phu_loc',
            parentId: 482,
          },
          {
            title: 'Thị trấn Lăng Cô',
            code: 20110,
            value: 'thi_tran_lang_co',
            division_type: 'thị trấn',
            short_codename: 'lang_co',
            parentId: 482,
          },
          {
            title: 'Xã Vinh Mỹ',
            code: 20113,
            value: 'xa_vinh_my',
            division_type: 'xã',
            short_codename: 'vinh_my',
            parentId: 482,
          },
          {
            title: 'Xã Vinh Hưng',
            code: 20116,
            value: 'xa_vinh_hung',
            division_type: 'xã',
            short_codename: 'vinh_hung',
            parentId: 482,
          },
          {
            title: 'Xã Giang Hải',
            code: 20122,
            value: 'xa_giang_hai',
            division_type: 'xã',
            short_codename: 'giang_hai',
            parentId: 482,
          },
          {
            title: 'Xã Vinh Hiền',
            code: 20125,
            value: 'xa_vinh_hien',
            division_type: 'xã',
            short_codename: 'vinh_hien',
            parentId: 482,
          },
          {
            title: 'Xã Lộc Bổn',
            code: 20128,
            value: 'xa_loc_bon',
            division_type: 'xã',
            short_codename: 'loc_bon',
            parentId: 482,
          },
          {
            title: 'Xã Lộc Sơn',
            code: 20131,
            value: 'xa_loc_son',
            division_type: 'xã',
            short_codename: 'loc_son',
            parentId: 482,
          },
          {
            title: 'Xã Lộc Bình',
            code: 20134,
            value: 'xa_loc_binh',
            division_type: 'xã',
            short_codename: 'loc_binh',
            parentId: 482,
          },
          {
            title: 'Xã Lộc Vĩnh',
            code: 20137,
            value: 'xa_loc_vinh',
            division_type: 'xã',
            short_codename: 'loc_vinh',
            parentId: 482,
          },
          {
            title: 'Xã Lộc An',
            code: 20140,
            value: 'xa_loc_an',
            division_type: 'xã',
            short_codename: 'loc_an',
            parentId: 482,
          },
          {
            title: 'Xã Lộc Điền',
            code: 20143,
            value: 'xa_loc_dien',
            division_type: 'xã',
            short_codename: 'loc_dien',
            parentId: 482,
          },
          {
            title: 'Xã Lộc Thủy',
            code: 20146,
            value: 'xa_loc_thuy',
            division_type: 'xã',
            short_codename: 'loc_thuy',
            parentId: 482,
          },
          {
            title: 'Xã Lộc Trì',
            code: 20149,
            value: 'xa_loc_tri',
            division_type: 'xã',
            short_codename: 'loc_tri',
            parentId: 482,
          },
          {
            title: 'Xã Lộc Tiến',
            code: 20152,
            value: 'xa_loc_tien',
            division_type: 'xã',
            short_codename: 'loc_tien',
            parentId: 482,
          },
          {
            title: 'Xã Lộc Hòa',
            code: 20155,
            value: 'xa_loc_hoa',
            division_type: 'xã',
            short_codename: 'loc_hoa',
            parentId: 482,
          },
          {
            title: 'Xã Xuân Lộc',
            code: 20158,
            value: 'xa_xuan_loc',
            division_type: 'xã',
            short_codename: 'xuan_loc',
            parentId: 482,
          },
        ],
        parentId: 46,
      },
      {
        title: 'Huyện Nam Đông',
        code: 483,
        value: 'huyen_nam_dong',
        division_type: 'huyện',
        short_codename: 'nam_dong',
        children: [
          {
            title: 'Thị trấn Khe Tre',
            code: 20161,
            value: 'thi_tran_khe_tre',
            division_type: 'thị trấn',
            short_codename: 'khe_tre',
            parentId: 483,
          },
          {
            title: 'Xã Hương Phú',
            code: 20164,
            value: 'xa_huong_phu',
            division_type: 'xã',
            short_codename: 'huong_phu',
            parentId: 483,
          },
          {
            title: 'Xã Hương Sơn',
            code: 20167,
            value: 'xa_huong_son',
            division_type: 'xã',
            short_codename: 'huong_son',
            parentId: 483,
          },
          {
            title: 'Xã Hương Lộc',
            code: 20170,
            value: 'xa_huong_loc',
            division_type: 'xã',
            short_codename: 'huong_loc',
            parentId: 483,
          },
          {
            title: 'Xã Thượng Quảng',
            code: 20173,
            value: 'xa_thuong_quang',
            division_type: 'xã',
            short_codename: 'thuong_quang',
            parentId: 483,
          },
          {
            title: 'Xã Hương Xuân',
            code: 20179,
            value: 'xa_huong_xuan',
            division_type: 'xã',
            short_codename: 'huong_xuan',
            parentId: 483,
          },
          {
            title: 'Xã Hương Hữu',
            code: 20182,
            value: 'xa_huong_huu',
            division_type: 'xã',
            short_codename: 'huong_huu',
            parentId: 483,
          },
          {
            title: 'Xã Thượng Lộ',
            code: 20185,
            value: 'xa_thuong_lo',
            division_type: 'xã',
            short_codename: 'thuong_lo',
            parentId: 483,
          },
          {
            title: 'Xã Thượng Long',
            code: 20188,
            value: 'xa_thuong_long',
            division_type: 'xã',
            short_codename: 'thuong_long',
            parentId: 483,
          },
          {
            title: 'Xã Thượng Nhật',
            code: 20191,
            value: 'xa_thuong_nhat',
            division_type: 'xã',
            short_codename: 'thuong_nhat',
            parentId: 483,
          },
        ],
        parentId: 46,
      },
    ],
  },
  {
    title: 'Thành phố Đà Nẵng',
    code: 48,
    value: 'thanh_pho_da_nang',
    division_type: 'thành phố trung ương',
    phone_code: 236,
    children: [
      {
        title: 'Quận Liên Chiểu',
        code: 490,
        value: 'quan_lien_chieu',
        division_type: 'quận',
        short_codename: 'lien_chieu',
        children: [
          {
            title: 'Phường Hòa Hiệp Bắc',
            code: 20194,
            value: 'phuong_hoa_hiep_bac',
            division_type: 'phường',
            short_codename: 'hoa_hiep_bac',
            parentId: 490,
          },
          {
            title: 'Phường Hòa Hiệp Nam',
            code: 20195,
            value: 'phuong_hoa_hiep_nam',
            division_type: 'phường',
            short_codename: 'hoa_hiep_nam',
            parentId: 490,
          },
          {
            title: 'Phường Hòa Khánh Bắc',
            code: 20197,
            value: 'phuong_hoa_khanh_bac',
            division_type: 'phường',
            short_codename: 'hoa_khanh_bac',
            parentId: 490,
          },
          {
            title: 'Phường Hòa Khánh Nam',
            code: 20198,
            value: 'phuong_hoa_khanh_nam',
            division_type: 'phường',
            short_codename: 'hoa_khanh_nam',
            parentId: 490,
          },
          {
            title: 'Phường Hòa Minh',
            code: 20200,
            value: 'phuong_hoa_minh',
            division_type: 'phường',
            short_codename: 'hoa_minh',
            parentId: 490,
          },
        ],
        parentId: 48,
      },
      {
        title: 'Quận Thanh Khê',
        code: 491,
        value: 'quan_thanh_khe',
        division_type: 'quận',
        short_codename: 'thanh_khe',
        children: [
          {
            title: 'Phường Tam Thuận',
            code: 20203,
            value: 'phuong_tam_thuan',
            division_type: 'phường',
            short_codename: 'tam_thuan',
            parentId: 491,
          },
          {
            title: 'Phường Thanh Khê Tây',
            code: 20206,
            value: 'phuong_thanh_khe_tay',
            division_type: 'phường',
            short_codename: 'thanh_khe_tay',
            parentId: 491,
          },
          {
            title: 'Phường Thanh Khê Đông',
            code: 20207,
            value: 'phuong_thanh_khe_dong',
            division_type: 'phường',
            short_codename: 'thanh_khe_dong',
            parentId: 491,
          },
          {
            title: 'Phường Xuân Hà',
            code: 20209,
            value: 'phuong_xuan_ha',
            division_type: 'phường',
            short_codename: 'xuan_ha',
            parentId: 491,
          },
          {
            title: 'Phường Tân Chính',
            code: 20212,
            value: 'phuong_tan_chinh',
            division_type: 'phường',
            short_codename: 'tan_chinh',
            parentId: 491,
          },
          {
            title: 'Phường Chính Gián',
            code: 20215,
            value: 'phuong_chinh_gian',
            division_type: 'phường',
            short_codename: 'chinh_gian',
            parentId: 491,
          },
          {
            title: 'Phường Vĩnh Trung',
            code: 20218,
            value: 'phuong_vinh_trung',
            division_type: 'phường',
            short_codename: 'vinh_trung',
            parentId: 491,
          },
          {
            title: 'Phường Thạc Gián',
            code: 20221,
            value: 'phuong_thac_gian',
            division_type: 'phường',
            short_codename: 'thac_gian',
            parentId: 491,
          },
          {
            title: 'Phường An Khê',
            code: 20224,
            value: 'phuong_an_khe',
            division_type: 'phường',
            short_codename: 'an_khe',
            parentId: 491,
          },
          {
            title: 'Phường Hòa Khê',
            code: 20225,
            value: 'phuong_hoa_khe',
            division_type: 'phường',
            short_codename: 'hoa_khe',
            parentId: 491,
          },
        ],
        parentId: 48,
      },
      {
        title: 'Quận Hải Châu',
        code: 492,
        value: 'quan_hai_chau',
        division_type: 'quận',
        short_codename: 'hai_chau',
        children: [
          {
            title: 'Phường Thanh Bình',
            code: 20227,
            value: 'phuong_thanh_binh',
            division_type: 'phường',
            short_codename: 'thanh_binh',
            parentId: 492,
          },
          {
            title: 'Phường Thuận Phước',
            code: 20230,
            value: 'phuong_thuan_phuoc',
            division_type: 'phường',
            short_codename: 'thuan_phuoc',
            parentId: 492,
          },
          {
            title: 'Phường Thạch Thang',
            code: 20233,
            value: 'phuong_thach_thang',
            division_type: 'phường',
            short_codename: 'thach_thang',
            parentId: 492,
          },
          {
            title: 'Phường Hải Châu I',
            code: 20236,
            value: 'phuong_hai_chau_i',
            division_type: 'phường',
            short_codename: 'hai_chau_i',
            parentId: 492,
          },
          {
            title: 'Phường Hải Châu II',
            code: 20239,
            value: 'phuong_hai_chau_ii',
            division_type: 'phường',
            short_codename: 'hai_chau_ii',
            parentId: 492,
          },
          {
            title: 'Phường Phước Ninh',
            code: 20242,
            value: 'phuong_phuoc_ninh',
            division_type: 'phường',
            short_codename: 'phuoc_ninh',
            parentId: 492,
          },
          {
            title: 'Phường Hòa Thuận Tây',
            code: 20245,
            value: 'phuong_hoa_thuan_tay',
            division_type: 'phường',
            short_codename: 'hoa_thuan_tay',
            parentId: 492,
          },
          {
            title: 'Phường Hòa Thuận Đông',
            code: 20246,
            value: 'phuong_hoa_thuan_dong',
            division_type: 'phường',
            short_codename: 'hoa_thuan_dong',
            parentId: 492,
          },
          {
            title: 'Phường Nam Dương',
            code: 20248,
            value: 'phuong_nam_duong',
            division_type: 'phường',
            short_codename: 'nam_duong',
            parentId: 492,
          },
          {
            title: 'Phường Bình Hiên',
            code: 20251,
            value: 'phuong_binh_hien',
            division_type: 'phường',
            short_codename: 'binh_hien',
            parentId: 492,
          },
          {
            title: 'Phường Bình Thuận',
            code: 20254,
            value: 'phuong_binh_thuan',
            division_type: 'phường',
            short_codename: 'binh_thuan',
            parentId: 492,
          },
          {
            title: 'Phường Hòa Cường Bắc',
            code: 20257,
            value: 'phuong_hoa_cuong_bac',
            division_type: 'phường',
            short_codename: 'hoa_cuong_bac',
            parentId: 492,
          },
          {
            title: 'Phường Hòa Cường Nam',
            code: 20258,
            value: 'phuong_hoa_cuong_nam',
            division_type: 'phường',
            short_codename: 'hoa_cuong_nam',
            parentId: 492,
          },
        ],
        parentId: 48,
      },
      {
        title: 'Quận Sơn Trà',
        code: 493,
        value: 'quan_son_tra',
        division_type: 'quận',
        short_codename: 'son_tra',
        children: [
          {
            title: 'Phường Thọ Quang',
            code: 20263,
            value: 'phuong_tho_quang',
            division_type: 'phường',
            short_codename: 'tho_quang',
            parentId: 493,
          },
          {
            title: 'Phường Nại Hiên Đông',
            code: 20266,
            value: 'phuong_nai_hien_dong',
            division_type: 'phường',
            short_codename: 'nai_hien_dong',
            parentId: 493,
          },
          {
            title: 'Phường Mân Thái',
            code: 20269,
            value: 'phuong_man_thai',
            division_type: 'phường',
            short_codename: 'man_thai',
            parentId: 493,
          },
          {
            title: 'Phường An Hải Bắc',
            code: 20272,
            value: 'phuong_an_hai_bac',
            division_type: 'phường',
            short_codename: 'an_hai_bac',
            parentId: 493,
          },
          {
            title: 'Phường Phước Mỹ',
            code: 20275,
            value: 'phuong_phuoc_my',
            division_type: 'phường',
            short_codename: 'phuoc_my',
            parentId: 493,
          },
          {
            title: 'Phường An Hải Tây',
            code: 20278,
            value: 'phuong_an_hai_tay',
            division_type: 'phường',
            short_codename: 'an_hai_tay',
            parentId: 493,
          },
          {
            title: 'Phường An Hải Đông',
            code: 20281,
            value: 'phuong_an_hai_dong',
            division_type: 'phường',
            short_codename: 'an_hai_dong',
            parentId: 493,
          },
        ],
        parentId: 48,
      },
      {
        title: 'Quận Ngũ Hành Sơn',
        code: 494,
        value: 'quan_ngu_hanh_son',
        division_type: 'quận',
        short_codename: 'ngu_hanh_son',
        children: [
          {
            title: 'Phường Mỹ An',
            code: 20284,
            value: 'phuong_my_an',
            division_type: 'phường',
            short_codename: 'my_an',
            parentId: 494,
          },
          {
            title: 'Phường Khuê Mỹ',
            code: 20285,
            value: 'phuong_khue_my',
            division_type: 'phường',
            short_codename: 'khue_my',
            parentId: 494,
          },
          {
            title: 'Phường Hoà Quý',
            code: 20287,
            value: 'phuong_hoa_quy',
            division_type: 'phường',
            short_codename: 'hoa_quy',
            parentId: 494,
          },
          {
            title: 'Phường Hoà Hải',
            code: 20290,
            value: 'phuong_hoa_hai',
            division_type: 'phường',
            short_codename: 'hoa_hai',
            parentId: 494,
          },
        ],
        parentId: 48,
      },
      {
        title: 'Quận Cẩm Lệ',
        code: 495,
        value: 'quan_cam_le',
        division_type: 'quận',
        short_codename: 'cam_le',
        children: [
          {
            title: 'Phường Khuê Trung',
            code: 20260,
            value: 'phuong_khue_trung',
            division_type: 'phường',
            short_codename: 'khue_trung',
            parentId: 495,
          },
          {
            title: 'Phường Hòa Phát',
            code: 20305,
            value: 'phuong_hoa_phat',
            division_type: 'phường',
            short_codename: 'hoa_phat',
            parentId: 495,
          },
          {
            title: 'Phường Hòa An',
            code: 20306,
            value: 'phuong_hoa_an',
            division_type: 'phường',
            short_codename: 'hoa_an',
            parentId: 495,
          },
          {
            title: 'Phường Hòa Thọ Tây',
            code: 20311,
            value: 'phuong_hoa_tho_tay',
            division_type: 'phường',
            short_codename: 'hoa_tho_tay',
            parentId: 495,
          },
          {
            title: 'Phường Hòa Thọ Đông',
            code: 20312,
            value: 'phuong_hoa_tho_dong',
            division_type: 'phường',
            short_codename: 'hoa_tho_dong',
            parentId: 495,
          },
          {
            title: 'Phường Hòa Xuân',
            code: 20314,
            value: 'phuong_hoa_xuan',
            division_type: 'phường',
            short_codename: 'hoa_xuan',
            parentId: 495,
          },
        ],
        parentId: 48,
      },
      {
        title: 'Huyện Hòa Vang',
        code: 497,
        value: 'huyen_hoa_vang',
        division_type: 'huyện',
        short_codename: 'hoa_vang',
        children: [
          {
            title: 'Xã Hòa Bắc',
            code: 20293,
            value: 'xa_hoa_bac',
            division_type: 'xã',
            short_codename: 'hoa_bac',
            parentId: 497,
          },
          {
            title: 'Xã Hòa Liên',
            code: 20296,
            value: 'xa_hoa_lien',
            division_type: 'xã',
            short_codename: 'hoa_lien',
            parentId: 497,
          },
          {
            title: 'Xã Hòa Ninh',
            code: 20299,
            value: 'xa_hoa_ninh',
            division_type: 'xã',
            short_codename: 'hoa_ninh',
            parentId: 497,
          },
          {
            title: 'Xã Hòa Sơn',
            code: 20302,
            value: 'xa_hoa_son',
            division_type: 'xã',
            short_codename: 'hoa_son',
            parentId: 497,
          },
          {
            title: 'Xã Hòa Nhơn',
            code: 20308,
            value: 'xa_hoa_nhon',
            division_type: 'xã',
            short_codename: 'hoa_nhon',
            parentId: 497,
          },
          {
            title: 'Xã Hòa Phú',
            code: 20317,
            value: 'xa_hoa_phu',
            division_type: 'xã',
            short_codename: 'hoa_phu',
            parentId: 497,
          },
          {
            title: 'Xã Hòa Phong',
            code: 20320,
            value: 'xa_hoa_phong',
            division_type: 'xã',
            short_codename: 'hoa_phong',
            parentId: 497,
          },
          {
            title: 'Xã Hòa Châu',
            code: 20323,
            value: 'xa_hoa_chau',
            division_type: 'xã',
            short_codename: 'hoa_chau',
            parentId: 497,
          },
          {
            title: 'Xã Hòa Tiến',
            code: 20326,
            value: 'xa_hoa_tien',
            division_type: 'xã',
            short_codename: 'hoa_tien',
            parentId: 497,
          },
          {
            title: 'Xã Hòa Phước',
            code: 20329,
            value: 'xa_hoa_phuoc',
            division_type: 'xã',
            short_codename: 'hoa_phuoc',
            parentId: 497,
          },
          {
            title: 'Xã Hòa Khương',
            code: 20332,
            value: 'xa_hoa_khuong',
            division_type: 'xã',
            short_codename: 'hoa_khuong',
            parentId: 497,
          },
        ],
        parentId: 48,
      },
      {
        title: 'Huyện Hoàng Sa',
        code: 498,
        value: 'huyen_hoang_sa',
        division_type: 'huyện',
        short_codename: 'hoang_sa',
        children: [],
        parentId: 48,
      },
    ],
  },
  {
    title: 'Tỉnh Quảng Nam',
    code: 49,
    value: 'tinh_quang_nam',
    division_type: 'tỉnh',
    phone_code: 235,
    children: [
      {
        title: 'Thành phố Tam Kỳ',
        code: 502,
        value: 'thanh_pho_tam_ky',
        division_type: 'thành phố',
        short_codename: 'tam_ky',
        children: [
          {
            title: 'Phường Tân Thạnh',
            code: 20335,
            value: 'phuong_tan_thanh',
            division_type: 'phường',
            short_codename: 'tan_thanh',
            parentId: 502,
          },
          {
            title: 'Phường Phước Hòa',
            code: 20338,
            value: 'phuong_phuoc_hoa',
            division_type: 'phường',
            short_codename: 'phuoc_hoa',
            parentId: 502,
          },
          {
            title: 'Phường An Mỹ',
            code: 20341,
            value: 'phuong_an_my',
            division_type: 'phường',
            short_codename: 'an_my',
            parentId: 502,
          },
          {
            title: 'Phường Hòa Hương',
            code: 20344,
            value: 'phuong_hoa_huong',
            division_type: 'phường',
            short_codename: 'hoa_huong',
            parentId: 502,
          },
          {
            title: 'Phường An Xuân',
            code: 20347,
            value: 'phuong_an_xuan',
            division_type: 'phường',
            short_codename: 'an_xuan',
            parentId: 502,
          },
          {
            title: 'Phường An Sơn',
            code: 20350,
            value: 'phuong_an_son',
            division_type: 'phường',
            short_codename: 'an_son',
            parentId: 502,
          },
          {
            title: 'Phường Trường Xuân',
            code: 20353,
            value: 'phuong_truong_xuan',
            division_type: 'phường',
            short_codename: 'truong_xuan',
            parentId: 502,
          },
          {
            title: 'Phường An Phú',
            code: 20356,
            value: 'phuong_an_phu',
            division_type: 'phường',
            short_codename: 'an_phu',
            parentId: 502,
          },
          {
            title: 'Xã Tam Thanh',
            code: 20359,
            value: 'xa_tam_thanh',
            division_type: 'xã',
            short_codename: 'tam_thanh',
            parentId: 502,
          },
          {
            title: 'Xã Tam Thăng',
            code: 20362,
            value: 'xa_tam_thang',
            division_type: 'xã',
            short_codename: 'tam_thang',
            parentId: 502,
          },
          {
            title: 'Xã Tam Phú',
            code: 20371,
            value: 'xa_tam_phu',
            division_type: 'xã',
            short_codename: 'tam_phu',
            parentId: 502,
          },
          {
            title: 'Phường Hoà Thuận',
            code: 20375,
            value: 'phuong_hoa_thuan',
            division_type: 'phường',
            short_codename: 'hoa_thuan',
            parentId: 502,
          },
          {
            title: 'Xã Tam Ngọc',
            code: 20389,
            value: 'xa_tam_ngoc',
            division_type: 'xã',
            short_codename: 'tam_ngoc',
            parentId: 502,
          },
        ],
        parentId: 49,
      },
      {
        title: 'Thành phố Hội An',
        code: 503,
        value: 'thanh_pho_hoi_an',
        division_type: 'thành phố',
        short_codename: 'hoi_an',
        children: [
          {
            title: 'Phường Minh An',
            code: 20398,
            value: 'phuong_minh_an',
            division_type: 'phường',
            short_codename: 'minh_an',
            parentId: 503,
          },
          {
            title: 'Phường Tân An',
            code: 20401,
            value: 'phuong_tan_an',
            division_type: 'phường',
            short_codename: 'tan_an',
            parentId: 503,
          },
          {
            title: 'Phường Cẩm Phô',
            code: 20404,
            value: 'phuong_cam_pho',
            division_type: 'phường',
            short_codename: 'cam_pho',
            parentId: 503,
          },
          {
            title: 'Phường Thanh Hà',
            code: 20407,
            value: 'phuong_thanh_ha',
            division_type: 'phường',
            short_codename: 'thanh_ha',
            parentId: 503,
          },
          {
            title: 'Phường Sơn Phong',
            code: 20410,
            value: 'phuong_son_phong',
            division_type: 'phường',
            short_codename: 'son_phong',
            parentId: 503,
          },
          {
            title: 'Phường Cẩm Châu',
            code: 20413,
            value: 'phuong_cam_chau',
            division_type: 'phường',
            short_codename: 'cam_chau',
            parentId: 503,
          },
          {
            title: 'Phường Cửa Đại',
            code: 20416,
            value: 'phuong_cua_dai',
            division_type: 'phường',
            short_codename: 'cua_dai',
            parentId: 503,
          },
          {
            title: 'Phường Cẩm An',
            code: 20419,
            value: 'phuong_cam_an',
            division_type: 'phường',
            short_codename: 'cam_an',
            parentId: 503,
          },
          {
            title: 'Xã Cẩm Hà',
            code: 20422,
            value: 'xa_cam_ha',
            division_type: 'xã',
            short_codename: 'cam_ha',
            parentId: 503,
          },
          {
            title: 'Xã Cẩm Kim',
            code: 20425,
            value: 'xa_cam_kim',
            division_type: 'xã',
            short_codename: 'cam_kim',
            parentId: 503,
          },
          {
            title: 'Phường Cẩm Nam',
            code: 20428,
            value: 'phuong_cam_nam',
            division_type: 'phường',
            short_codename: 'cam_nam',
            parentId: 503,
          },
          {
            title: 'Xã Cẩm Thanh',
            code: 20431,
            value: 'xa_cam_thanh',
            division_type: 'xã',
            short_codename: 'cam_thanh',
            parentId: 503,
          },
          {
            title: 'Xã Tân Hiệp',
            code: 20434,
            value: 'xa_tan_hiep',
            division_type: 'xã',
            short_codename: 'tan_hiep',
            parentId: 503,
          },
        ],
        parentId: 49,
      },
      {
        title: 'Huyện Tây Giang',
        code: 504,
        value: 'huyen_tay_giang',
        division_type: 'huyện',
        short_codename: 'tay_giang',
        children: [
          {
            title: "Xã Ch'ơm",
            code: 20437,
            value: 'xa_chom',
            division_type: 'xã',
            short_codename: 'chom',
            parentId: 504,
          },
          {
            title: 'Xã Ga Ri',
            code: 20440,
            value: 'xa_ga_ri',
            division_type: 'xã',
            short_codename: 'ga_ri',
            parentId: 504,
          },
          {
            title: 'Xã A Xan',
            code: 20443,
            value: 'xa_a_xan',
            division_type: 'xã',
            short_codename: 'a_xan',
            parentId: 504,
          },
          {
            title: "Xã Tr'Hy",
            code: 20446,
            value: 'xa_trhy',
            division_type: 'xã',
            short_codename: 'trhy',
            parentId: 504,
          },
          {
            title: 'Xã Lăng',
            code: 20449,
            value: 'xa_lang',
            division_type: 'xã',
            short_codename: 'lang',
            parentId: 504,
          },
          {
            title: 'Xã A Nông',
            code: 20452,
            value: 'xa_a_nong',
            division_type: 'xã',
            short_codename: 'a_nong',
            parentId: 504,
          },
          {
            title: 'Xã A Tiêng',
            code: 20455,
            value: 'xa_a_tieng',
            division_type: 'xã',
            short_codename: 'a_tieng',
            parentId: 504,
          },
          {
            title: 'Xã Bha Lê',
            code: 20458,
            value: 'xa_bha_le',
            division_type: 'xã',
            short_codename: 'bha_le',
            parentId: 504,
          },
          {
            title: 'Xã A Vương',
            code: 20461,
            value: 'xa_a_vuong',
            division_type: 'xã',
            short_codename: 'a_vuong',
            parentId: 504,
          },
          {
            title: 'Xã Dang',
            code: 20464,
            value: 'xa_dang',
            division_type: 'xã',
            short_codename: 'dang',
            parentId: 504,
          },
        ],
        parentId: 49,
      },
      {
        title: 'Huyện Đông Giang',
        code: 505,
        value: 'huyen_dong_giang',
        division_type: 'huyện',
        short_codename: 'dong_giang',
        children: [
          {
            title: 'Thị trấn P Rao',
            code: 20467,
            value: 'thi_tran_p_rao',
            division_type: 'thị trấn',
            short_codename: 'p_rao',
            parentId: 505,
          },
          {
            title: 'Xã Tà Lu',
            code: 20470,
            value: 'xa_ta_lu',
            division_type: 'xã',
            short_codename: 'ta_lu',
            parentId: 505,
          },
          {
            title: 'Xã Sông Kôn',
            code: 20473,
            value: 'xa_song_kon',
            division_type: 'xã',
            short_codename: 'song_kon',
            parentId: 505,
          },
          {
            title: 'Xã Jơ Ngây',
            code: 20476,
            value: 'xa_jo_ngay',
            division_type: 'xã',
            short_codename: 'jo_ngay',
            parentId: 505,
          },
          {
            title: 'Xã A Ting',
            code: 20479,
            value: 'xa_a_ting',
            division_type: 'xã',
            short_codename: 'a_ting',
            parentId: 505,
          },
          {
            title: 'Xã Tư',
            code: 20482,
            value: 'xa_tu',
            division_type: 'xã',
            short_codename: 'tu',
            parentId: 505,
          },
          {
            title: 'Xã Ba',
            code: 20485,
            value: 'xa_ba',
            division_type: 'xã',
            short_codename: 'ba',
            parentId: 505,
          },
          {
            title: 'Xã A Rooi',
            code: 20488,
            value: 'xa_a_rooi',
            division_type: 'xã',
            short_codename: 'a_rooi',
            parentId: 505,
          },
          {
            title: 'Xã Za Hung',
            code: 20491,
            value: 'xa_za_hung',
            division_type: 'xã',
            short_codename: 'za_hung',
            parentId: 505,
          },
          {
            title: 'Xã Mà Cooi',
            code: 20494,
            value: 'xa_ma_cooi',
            division_type: 'xã',
            short_codename: 'ma_cooi',
            parentId: 505,
          },
          {
            title: 'Xã Ka Dăng',
            code: 20497,
            value: 'xa_ka_dang',
            division_type: 'xã',
            short_codename: 'ka_dang',
            parentId: 505,
          },
        ],
        parentId: 49,
      },
      {
        title: 'Huyện Đại Lộc',
        code: 506,
        value: 'huyen_dai_loc',
        division_type: 'huyện',
        short_codename: 'dai_loc',
        children: [
          {
            title: 'Thị Trấn Ái Nghĩa',
            code: 20500,
            value: 'thi_tran_ai_nghia',
            division_type: 'thị trấn',
            short_codename: 'ai_nghia',
            parentId: 506,
          },
          {
            title: 'Xã Đại Sơn',
            code: 20503,
            value: 'xa_dai_son',
            division_type: 'xã',
            short_codename: 'dai_son',
            parentId: 506,
          },
          {
            title: 'Xã Đại Lãnh',
            code: 20506,
            value: 'xa_dai_lanh',
            division_type: 'xã',
            short_codename: 'dai_lanh',
            parentId: 506,
          },
          {
            title: 'Xã Đại Hưng',
            code: 20509,
            value: 'xa_dai_hung',
            division_type: 'xã',
            short_codename: 'dai_hung',
            parentId: 506,
          },
          {
            title: 'Xã Đại Hồng',
            code: 20512,
            value: 'xa_dai_hong',
            division_type: 'xã',
            short_codename: 'dai_hong',
            parentId: 506,
          },
          {
            title: 'Xã Đại Đồng',
            code: 20515,
            value: 'xa_dai_dong',
            division_type: 'xã',
            short_codename: 'dai_dong',
            parentId: 506,
          },
          {
            title: 'Xã Đại Quang',
            code: 20518,
            value: 'xa_dai_quang',
            division_type: 'xã',
            short_codename: 'dai_quang',
            parentId: 506,
          },
          {
            title: 'Xã Đại Nghĩa',
            code: 20521,
            value: 'xa_dai_nghia',
            division_type: 'xã',
            short_codename: 'dai_nghia',
            parentId: 506,
          },
          {
            title: 'Xã Đại Hiệp',
            code: 20524,
            value: 'xa_dai_hiep',
            division_type: 'xã',
            short_codename: 'dai_hiep',
            parentId: 506,
          },
          {
            title: 'Xã Đại Thạnh',
            code: 20527,
            value: 'xa_dai_thanh',
            division_type: 'xã',
            short_codename: 'dai_thanh',
            parentId: 506,
          },
          {
            title: 'Xã Đại Chánh',
            code: 20530,
            value: 'xa_dai_chanh',
            division_type: 'xã',
            short_codename: 'dai_chanh',
            parentId: 506,
          },
          {
            title: 'Xã Đại Tân',
            code: 20533,
            value: 'xa_dai_tan',
            division_type: 'xã',
            short_codename: 'dai_tan',
            parentId: 506,
          },
          {
            title: 'Xã Đại Phong',
            code: 20536,
            value: 'xa_dai_phong',
            division_type: 'xã',
            short_codename: 'dai_phong',
            parentId: 506,
          },
          {
            title: 'Xã Đại Minh',
            code: 20539,
            value: 'xa_dai_minh',
            division_type: 'xã',
            short_codename: 'dai_minh',
            parentId: 506,
          },
          {
            title: 'Xã Đại Thắng',
            code: 20542,
            value: 'xa_dai_thang',
            division_type: 'xã',
            short_codename: 'dai_thang',
            parentId: 506,
          },
          {
            title: 'Xã Đại Cường',
            code: 20545,
            value: 'xa_dai_cuong',
            division_type: 'xã',
            short_codename: 'dai_cuong',
            parentId: 506,
          },
          {
            title: 'Xã Đại An',
            code: 20547,
            value: 'xa_dai_an',
            division_type: 'xã',
            short_codename: 'dai_an',
            parentId: 506,
          },
          {
            title: 'Xã Đại Hòa',
            code: 20548,
            value: 'xa_dai_hoa',
            division_type: 'xã',
            short_codename: 'dai_hoa',
            parentId: 506,
          },
        ],
        parentId: 49,
      },
      {
        title: 'Thị xã Điện Bàn',
        code: 507,
        value: 'thi_xa_dien_ban',
        division_type: 'thị xã',
        short_codename: 'dien_ban',
        children: [
          {
            title: 'Phường Vĩnh Điện',
            code: 20551,
            value: 'phuong_vinh_dien',
            division_type: 'phường',
            short_codename: 'vinh_dien',
            parentId: 507,
          },
          {
            title: 'Xã Điện Tiến',
            code: 20554,
            value: 'xa_dien_tien',
            division_type: 'xã',
            short_codename: 'dien_tien',
            parentId: 507,
          },
          {
            title: 'Xã Điện Hòa',
            code: 20557,
            value: 'xa_dien_hoa',
            division_type: 'xã',
            short_codename: 'dien_hoa',
            parentId: 507,
          },
          {
            title: 'Xã Điện Thắng Bắc',
            code: 20560,
            value: 'xa_dien_thang_bac',
            division_type: 'xã',
            short_codename: 'dien_thang_bac',
            parentId: 507,
          },
          {
            title: 'Xã Điện Thắng Trung',
            code: 20561,
            value: 'xa_dien_thang_trung',
            division_type: 'xã',
            short_codename: 'dien_thang_trung',
            parentId: 507,
          },
          {
            title: 'Xã Điện Thắng Nam',
            code: 20562,
            value: 'xa_dien_thang_nam',
            division_type: 'xã',
            short_codename: 'dien_thang_nam',
            parentId: 507,
          },
          {
            title: 'Phường Điện Ngọc',
            code: 20563,
            value: 'phuong_dien_ngoc',
            division_type: 'phường',
            short_codename: 'dien_ngoc',
            parentId: 507,
          },
          {
            title: 'Xã Điện Hồng',
            code: 20566,
            value: 'xa_dien_hong',
            division_type: 'xã',
            short_codename: 'dien_hong',
            parentId: 507,
          },
          {
            title: 'Xã Điện Thọ',
            code: 20569,
            value: 'xa_dien_tho',
            division_type: 'xã',
            short_codename: 'dien_tho',
            parentId: 507,
          },
          {
            title: 'Xã Điện Phước',
            code: 20572,
            value: 'xa_dien_phuoc',
            division_type: 'xã',
            short_codename: 'dien_phuoc',
            parentId: 507,
          },
          {
            title: 'Phường Điện An',
            code: 20575,
            value: 'phuong_dien_an',
            division_type: 'phường',
            short_codename: 'dien_an',
            parentId: 507,
          },
          {
            title: 'Phường Điện Nam Bắc',
            code: 20578,
            value: 'phuong_dien_nam_bac',
            division_type: 'phường',
            short_codename: 'dien_nam_bac',
            parentId: 507,
          },
          {
            title: 'Phường Điện Nam Trung',
            code: 20579,
            value: 'phuong_dien_nam_trung',
            division_type: 'phường',
            short_codename: 'dien_nam_trung',
            parentId: 507,
          },
          {
            title: 'Phường Điện Nam Đông',
            code: 20580,
            value: 'phuong_dien_nam_dong',
            division_type: 'phường',
            short_codename: 'dien_nam_dong',
            parentId: 507,
          },
          {
            title: 'Phường Điện Dương',
            code: 20581,
            value: 'phuong_dien_duong',
            division_type: 'phường',
            short_codename: 'dien_duong',
            parentId: 507,
          },
          {
            title: 'Xã Điện Quang',
            code: 20584,
            value: 'xa_dien_quang',
            division_type: 'xã',
            short_codename: 'dien_quang',
            parentId: 507,
          },
          {
            title: 'Xã Điện Trung',
            code: 20587,
            value: 'xa_dien_trung',
            division_type: 'xã',
            short_codename: 'dien_trung',
            parentId: 507,
          },
          {
            title: 'Xã Điện Phong',
            code: 20590,
            value: 'xa_dien_phong',
            division_type: 'xã',
            short_codename: 'dien_phong',
            parentId: 507,
          },
          {
            title: 'Xã Điện Minh',
            code: 20593,
            value: 'xa_dien_minh',
            division_type: 'xã',
            short_codename: 'dien_minh',
            parentId: 507,
          },
          {
            title: 'Xã Điện Phương',
            code: 20596,
            value: 'xa_dien_phuong',
            division_type: 'xã',
            short_codename: 'dien_phuong',
            parentId: 507,
          },
        ],
        parentId: 49,
      },
      {
        title: 'Huyện Duy Xuyên',
        code: 508,
        value: 'huyen_duy_xuyen',
        division_type: 'huyện',
        short_codename: 'duy_xuyen',
        children: [
          {
            title: 'Thị trấn Nam Phước',
            code: 20599,
            value: 'thi_tran_nam_phuoc',
            division_type: 'thị trấn',
            short_codename: 'nam_phuoc',
            parentId: 508,
          },
          {
            title: 'Xã Duy Thu',
            code: 20602,
            value: 'xa_duy_thu',
            division_type: 'xã',
            short_codename: 'duy_thu',
            parentId: 508,
          },
          {
            title: 'Xã Duy Phú',
            code: 20605,
            value: 'xa_duy_phu',
            division_type: 'xã',
            short_codename: 'duy_phu',
            parentId: 508,
          },
          {
            title: 'Xã Duy Tân',
            code: 20608,
            value: 'xa_duy_tan',
            division_type: 'xã',
            short_codename: 'duy_tan',
            parentId: 508,
          },
          {
            title: 'Xã Duy Hòa',
            code: 20611,
            value: 'xa_duy_hoa',
            division_type: 'xã',
            short_codename: 'duy_hoa',
            parentId: 508,
          },
          {
            title: 'Xã Duy Châu',
            code: 20614,
            value: 'xa_duy_chau',
            division_type: 'xã',
            short_codename: 'duy_chau',
            parentId: 508,
          },
          {
            title: 'Xã Duy Trinh',
            code: 20617,
            value: 'xa_duy_trinh',
            division_type: 'xã',
            short_codename: 'duy_trinh',
            parentId: 508,
          },
          {
            title: 'Xã Duy Sơn',
            code: 20620,
            value: 'xa_duy_son',
            division_type: 'xã',
            short_codename: 'duy_son',
            parentId: 508,
          },
          {
            title: 'Xã Duy Trung',
            code: 20623,
            value: 'xa_duy_trung',
            division_type: 'xã',
            short_codename: 'duy_trung',
            parentId: 508,
          },
          {
            title: 'Xã Duy Phước',
            code: 20626,
            value: 'xa_duy_phuoc',
            division_type: 'xã',
            short_codename: 'duy_phuoc',
            parentId: 508,
          },
          {
            title: 'Xã Duy Thành',
            code: 20629,
            value: 'xa_duy_thanh',
            division_type: 'xã',
            short_codename: 'duy_thanh',
            parentId: 508,
          },
          {
            title: 'Xã Duy Vinh',
            code: 20632,
            value: 'xa_duy_vinh',
            division_type: 'xã',
            short_codename: 'duy_vinh',
            parentId: 508,
          },
          {
            title: 'Xã Duy Nghĩa',
            code: 20635,
            value: 'xa_duy_nghia',
            division_type: 'xã',
            short_codename: 'duy_nghia',
            parentId: 508,
          },
          {
            title: 'Xã Duy Hải',
            code: 20638,
            value: 'xa_duy_hai',
            division_type: 'xã',
            short_codename: 'duy_hai',
            parentId: 508,
          },
        ],
        parentId: 49,
      },
      {
        title: 'Huyện Quế Sơn',
        code: 509,
        value: 'huyen_que_son',
        division_type: 'huyện',
        short_codename: 'que_son',
        children: [
          {
            title: 'Thị trấn Đông Phú',
            code: 20641,
            value: 'thi_tran_dong_phu',
            division_type: 'thị trấn',
            short_codename: 'dong_phu',
            parentId: 509,
          },
          {
            title: 'Xã Quế Xuân 1',
            code: 20644,
            value: 'xa_que_xuan_1',
            division_type: 'xã',
            short_codename: 'que_xuan_1',
            parentId: 509,
          },
          {
            title: 'Xã Quế Xuân 2',
            code: 20647,
            value: 'xa_que_xuan_2',
            division_type: 'xã',
            short_codename: 'que_xuan_2',
            parentId: 509,
          },
          {
            title: 'Xã Quế Phú',
            code: 20650,
            value: 'xa_que_phu',
            division_type: 'xã',
            short_codename: 'que_phu',
            parentId: 509,
          },
          {
            title: 'Thị trấn Hương An',
            code: 20651,
            value: 'thi_tran_huong_an',
            division_type: 'thị trấn',
            short_codename: 'huong_an',
            parentId: 509,
          },
          {
            title: 'Xã Quế Hiệp',
            code: 20659,
            value: 'xa_que_hiep',
            division_type: 'xã',
            short_codename: 'que_hiep',
            parentId: 509,
          },
          {
            title: 'Xã Quế Thuận',
            code: 20662,
            value: 'xa_que_thuan',
            division_type: 'xã',
            short_codename: 'que_thuan',
            parentId: 509,
          },
          {
            title: 'Xã Quế Mỹ',
            code: 20665,
            value: 'xa_que_my',
            division_type: 'xã',
            short_codename: 'que_my',
            parentId: 509,
          },
          {
            title: 'Xã Quế Long',
            code: 20677,
            value: 'xa_que_long',
            division_type: 'xã',
            short_codename: 'que_long',
            parentId: 509,
          },
          {
            title: 'Xã Quế Châu',
            code: 20680,
            value: 'xa_que_chau',
            division_type: 'xã',
            short_codename: 'que_chau',
            parentId: 509,
          },
          {
            title: 'Xã Quế Phong',
            code: 20683,
            value: 'xa_que_phong',
            division_type: 'xã',
            short_codename: 'que_phong',
            parentId: 509,
          },
          {
            title: 'Xã Quế An',
            code: 20686,
            value: 'xa_que_an',
            division_type: 'xã',
            short_codename: 'que_an',
            parentId: 509,
          },
          {
            title: 'Xã Quế Minh',
            code: 20689,
            value: 'xa_que_minh',
            division_type: 'xã',
            short_codename: 'que_minh',
            parentId: 509,
          },
        ],
        parentId: 49,
      },
      {
        title: 'Huyện Nam Giang',
        code: 510,
        value: 'huyen_nam_giang',
        division_type: 'huyện',
        short_codename: 'nam_giang',
        children: [
          {
            title: 'Thị trấn Thạnh Mỹ',
            code: 20695,
            value: 'thi_tran_thanh_my',
            division_type: 'thị trấn',
            short_codename: 'thanh_my',
            parentId: 510,
          },
          {
            title: 'Xã Laêê',
            code: 20698,
            value: 'xa_laee',
            division_type: 'xã',
            short_codename: 'laee',
            parentId: 510,
          },
          {
            title: 'Xã Chơ Chun',
            code: 20699,
            value: 'xa_cho_chun',
            division_type: 'xã',
            short_codename: 'cho_chun',
            parentId: 510,
          },
          {
            title: 'Xã Zuôich',
            code: 20701,
            value: 'xa_zuoich',
            division_type: 'xã',
            short_codename: 'zuoich',
            parentId: 510,
          },
          {
            title: 'Xã Tà Pơơ',
            code: 20702,
            value: 'xa_ta_poo',
            division_type: 'xã',
            short_codename: 'ta_poo',
            parentId: 510,
          },
          {
            title: 'Xã La Dêê',
            code: 20704,
            value: 'xa_la_dee',
            division_type: 'xã',
            short_codename: 'la_dee',
            parentId: 510,
          },
          {
            title: 'Xã Đắc Tôi',
            code: 20705,
            value: 'xa_dac_toi',
            division_type: 'xã',
            short_codename: 'dac_toi',
            parentId: 510,
          },
          {
            title: 'Xã Chà Vàl',
            code: 20707,
            value: 'xa_cha_val',
            division_type: 'xã',
            short_codename: 'cha_val',
            parentId: 510,
          },
          {
            title: 'Xã Tà Bhinh',
            code: 20710,
            value: 'xa_ta_bhinh',
            division_type: 'xã',
            short_codename: 'ta_bhinh',
            parentId: 510,
          },
          {
            title: 'Xã Cà Dy',
            code: 20713,
            value: 'xa_ca_dy',
            division_type: 'xã',
            short_codename: 'ca_dy',
            parentId: 510,
          },
          {
            title: 'Xã Đắc Pre',
            code: 20716,
            value: 'xa_dac_pre',
            division_type: 'xã',
            short_codename: 'dac_pre',
            parentId: 510,
          },
          {
            title: 'Xã Đắc Pring',
            code: 20719,
            value: 'xa_dac_pring',
            division_type: 'xã',
            short_codename: 'dac_pring',
            parentId: 510,
          },
        ],
        parentId: 49,
      },
      {
        title: 'Huyện Phước Sơn',
        code: 511,
        value: 'huyen_phuoc_son',
        division_type: 'huyện',
        short_codename: 'phuoc_son',
        children: [
          {
            title: 'Thị trấn Khâm Đức',
            code: 20722,
            value: 'thi_tran_kham_duc',
            division_type: 'thị trấn',
            short_codename: 'kham_duc',
            parentId: 511,
          },
          {
            title: 'Xã Phước Xuân',
            code: 20725,
            value: 'xa_phuoc_xuan',
            division_type: 'xã',
            short_codename: 'phuoc_xuan',
            parentId: 511,
          },
          {
            title: 'Xã Phước Hiệp',
            code: 20728,
            value: 'xa_phuoc_hiep',
            division_type: 'xã',
            short_codename: 'phuoc_hiep',
            parentId: 511,
          },
          {
            title: 'Xã Phước Hoà',
            code: 20729,
            value: 'xa_phuoc_hoa',
            division_type: 'xã',
            short_codename: 'phuoc_hoa',
            parentId: 511,
          },
          {
            title: 'Xã Phước Đức',
            code: 20731,
            value: 'xa_phuoc_duc',
            division_type: 'xã',
            short_codename: 'phuoc_duc',
            parentId: 511,
          },
          {
            title: 'Xã Phước Năng',
            code: 20734,
            value: 'xa_phuoc_nang',
            division_type: 'xã',
            short_codename: 'phuoc_nang',
            parentId: 511,
          },
          {
            title: 'Xã Phước Mỹ',
            code: 20737,
            value: 'xa_phuoc_my',
            division_type: 'xã',
            short_codename: 'phuoc_my',
            parentId: 511,
          },
          {
            title: 'Xã Phước Chánh',
            code: 20740,
            value: 'xa_phuoc_chanh',
            division_type: 'xã',
            short_codename: 'phuoc_chanh',
            parentId: 511,
          },
          {
            title: 'Xã Phước Công',
            code: 20743,
            value: 'xa_phuoc_cong',
            division_type: 'xã',
            short_codename: 'phuoc_cong',
            parentId: 511,
          },
          {
            title: 'Xã Phước Kim',
            code: 20746,
            value: 'xa_phuoc_kim',
            division_type: 'xã',
            short_codename: 'phuoc_kim',
            parentId: 511,
          },
          {
            title: 'Xã Phước Lộc',
            code: 20749,
            value: 'xa_phuoc_loc',
            division_type: 'xã',
            short_codename: 'phuoc_loc',
            parentId: 511,
          },
          {
            title: 'Xã Phước Thành',
            code: 20752,
            value: 'xa_phuoc_thanh',
            division_type: 'xã',
            short_codename: 'phuoc_thanh',
            parentId: 511,
          },
        ],
        parentId: 49,
      },
      {
        title: 'Huyện Hiệp Đức',
        code: 512,
        value: 'huyen_hiep_duc',
        division_type: 'huyện',
        short_codename: 'hiep_duc',
        children: [
          {
            title: 'Xã Hiệp Hòa',
            code: 20758,
            value: 'xa_hiep_hoa',
            division_type: 'xã',
            short_codename: 'hiep_hoa',
            parentId: 512,
          },
          {
            title: 'Xã Hiệp Thuận',
            code: 20761,
            value: 'xa_hiep_thuan',
            division_type: 'xã',
            short_codename: 'hiep_thuan',
            parentId: 512,
          },
          {
            title: 'Xã Quế Thọ',
            code: 20764,
            value: 'xa_que_tho',
            division_type: 'xã',
            short_codename: 'que_tho',
            parentId: 512,
          },
          {
            title: 'Xã Bình Lâm',
            code: 20767,
            value: 'xa_binh_lam',
            division_type: 'xã',
            short_codename: 'binh_lam',
            parentId: 512,
          },
          {
            title: 'Xã Sông Trà',
            code: 20770,
            value: 'xa_song_tra',
            division_type: 'xã',
            short_codename: 'song_tra',
            parentId: 512,
          },
          {
            title: 'Xã Phước Trà',
            code: 20773,
            value: 'xa_phuoc_tra',
            division_type: 'xã',
            short_codename: 'phuoc_tra',
            parentId: 512,
          },
          {
            title: 'Xã Phước Gia',
            code: 20776,
            value: 'xa_phuoc_gia',
            division_type: 'xã',
            short_codename: 'phuoc_gia',
            parentId: 512,
          },
          {
            title: 'Thị trấn Tân Bình',
            code: 20779,
            value: 'thi_tran_tan_binh',
            division_type: 'thị trấn',
            short_codename: 'tan_binh',
            parentId: 512,
          },
          {
            title: 'Xã Quế Lưu',
            code: 20782,
            value: 'xa_que_luu',
            division_type: 'xã',
            short_codename: 'que_luu',
            parentId: 512,
          },
          {
            title: 'Xã Thăng Phước',
            code: 20785,
            value: 'xa_thang_phuoc',
            division_type: 'xã',
            short_codename: 'thang_phuoc',
            parentId: 512,
          },
          {
            title: 'Xã Bình Sơn',
            code: 20788,
            value: 'xa_binh_son',
            division_type: 'xã',
            short_codename: 'binh_son',
            parentId: 512,
          },
        ],
        parentId: 49,
      },
      {
        title: 'Huyện Thăng Bình',
        code: 513,
        value: 'huyen_thang_binh',
        division_type: 'huyện',
        short_codename: 'thang_binh',
        children: [
          {
            title: 'Thị trấn Hà Lam',
            code: 20791,
            value: 'thi_tran_ha_lam',
            division_type: 'thị trấn',
            short_codename: 'ha_lam',
            parentId: 513,
          },
          {
            title: 'Xã Bình Dương',
            code: 20794,
            value: 'xa_binh_duong',
            division_type: 'xã',
            short_codename: 'binh_duong',
            parentId: 513,
          },
          {
            title: 'Xã Bình Giang',
            code: 20797,
            value: 'xa_binh_giang',
            division_type: 'xã',
            short_codename: 'binh_giang',
            parentId: 513,
          },
          {
            title: 'Xã Bình Nguyên',
            code: 20800,
            value: 'xa_binh_nguyen',
            division_type: 'xã',
            short_codename: 'binh_nguyen',
            parentId: 513,
          },
          {
            title: 'Xã Bình Phục',
            code: 20803,
            value: 'xa_binh_phuc',
            division_type: 'xã',
            short_codename: 'binh_phuc',
            parentId: 513,
          },
          {
            title: 'Xã Bình Triều',
            code: 20806,
            value: 'xa_binh_trieu',
            division_type: 'xã',
            short_codename: 'binh_trieu',
            parentId: 513,
          },
          {
            title: 'Xã Bình Đào',
            code: 20809,
            value: 'xa_binh_dao',
            division_type: 'xã',
            short_codename: 'binh_dao',
            parentId: 513,
          },
          {
            title: 'Xã Bình Minh',
            code: 20812,
            value: 'xa_binh_minh',
            division_type: 'xã',
            short_codename: 'binh_minh',
            parentId: 513,
          },
          {
            title: 'Xã Bình Lãnh',
            code: 20815,
            value: 'xa_binh_lanh',
            division_type: 'xã',
            short_codename: 'binh_lanh',
            parentId: 513,
          },
          {
            title: 'Xã Bình Trị',
            code: 20818,
            value: 'xa_binh_tri',
            division_type: 'xã',
            short_codename: 'binh_tri',
            parentId: 513,
          },
          {
            title: 'Xã Bình Định Bắc',
            code: 20821,
            value: 'xa_binh_dinh_bac',
            division_type: 'xã',
            short_codename: 'binh_dinh_bac',
            parentId: 513,
          },
          {
            title: 'Xã Bình Định Nam',
            code: 20822,
            value: 'xa_binh_dinh_nam',
            division_type: 'xã',
            short_codename: 'binh_dinh_nam',
            parentId: 513,
          },
          {
            title: 'Xã Bình Quý',
            code: 20824,
            value: 'xa_binh_quy',
            division_type: 'xã',
            short_codename: 'binh_quy',
            parentId: 513,
          },
          {
            title: 'Xã Bình Phú',
            code: 20827,
            value: 'xa_binh_phu',
            division_type: 'xã',
            short_codename: 'binh_phu',
            parentId: 513,
          },
          {
            title: 'Xã Bình Chánh',
            code: 20830,
            value: 'xa_binh_chanh',
            division_type: 'xã',
            short_codename: 'binh_chanh',
            parentId: 513,
          },
          {
            title: 'Xã Bình Tú',
            code: 20833,
            value: 'xa_binh_tu',
            division_type: 'xã',
            short_codename: 'binh_tu',
            parentId: 513,
          },
          {
            title: 'Xã Bình Sa',
            code: 20836,
            value: 'xa_binh_sa',
            division_type: 'xã',
            short_codename: 'binh_sa',
            parentId: 513,
          },
          {
            title: 'Xã Bình Hải',
            code: 20839,
            value: 'xa_binh_hai',
            division_type: 'xã',
            short_codename: 'binh_hai',
            parentId: 513,
          },
          {
            title: 'Xã Bình Quế',
            code: 20842,
            value: 'xa_binh_que',
            division_type: 'xã',
            short_codename: 'binh_que',
            parentId: 513,
          },
          {
            title: 'Xã Bình An',
            code: 20845,
            value: 'xa_binh_an',
            division_type: 'xã',
            short_codename: 'binh_an',
            parentId: 513,
          },
          {
            title: 'Xã Bình Trung',
            code: 20848,
            value: 'xa_binh_trung',
            division_type: 'xã',
            short_codename: 'binh_trung',
            parentId: 513,
          },
          {
            title: 'Xã Bình Nam',
            code: 20851,
            value: 'xa_binh_nam',
            division_type: 'xã',
            short_codename: 'binh_nam',
            parentId: 513,
          },
        ],
        parentId: 49,
      },
      {
        title: 'Huyện Tiên Phước',
        code: 514,
        value: 'huyen_tien_phuoc',
        division_type: 'huyện',
        short_codename: 'tien_phuoc',
        children: [
          {
            title: 'Thị trấn Tiên Kỳ',
            code: 20854,
            value: 'thi_tran_tien_ky',
            division_type: 'thị trấn',
            short_codename: 'tien_ky',
            parentId: 514,
          },
          {
            title: 'Xã Tiên Sơn',
            code: 20857,
            value: 'xa_tien_son',
            division_type: 'xã',
            short_codename: 'tien_son',
            parentId: 514,
          },
          {
            title: 'Xã Tiên Hà',
            code: 20860,
            value: 'xa_tien_ha',
            division_type: 'xã',
            short_codename: 'tien_ha',
            parentId: 514,
          },
          {
            title: 'Xã Tiên Cẩm',
            code: 20863,
            value: 'xa_tien_cam',
            division_type: 'xã',
            short_codename: 'tien_cam',
            parentId: 514,
          },
          {
            title: 'Xã Tiên Châu',
            code: 20866,
            value: 'xa_tien_chau',
            division_type: 'xã',
            short_codename: 'tien_chau',
            parentId: 514,
          },
          {
            title: 'Xã Tiên Lãnh',
            code: 20869,
            value: 'xa_tien_lanh',
            division_type: 'xã',
            short_codename: 'tien_lanh',
            parentId: 514,
          },
          {
            title: 'Xã Tiên Ngọc',
            code: 20872,
            value: 'xa_tien_ngoc',
            division_type: 'xã',
            short_codename: 'tien_ngoc',
            parentId: 514,
          },
          {
            title: 'Xã Tiên Hiệp',
            code: 20875,
            value: 'xa_tien_hiep',
            division_type: 'xã',
            short_codename: 'tien_hiep',
            parentId: 514,
          },
          {
            title: 'Xã Tiên Cảnh',
            code: 20878,
            value: 'xa_tien_canh',
            division_type: 'xã',
            short_codename: 'tien_canh',
            parentId: 514,
          },
          {
            title: 'Xã Tiên Mỹ',
            code: 20881,
            value: 'xa_tien_my',
            division_type: 'xã',
            short_codename: 'tien_my',
            parentId: 514,
          },
          {
            title: 'Xã Tiên Phong',
            code: 20884,
            value: 'xa_tien_phong',
            division_type: 'xã',
            short_codename: 'tien_phong',
            parentId: 514,
          },
          {
            title: 'Xã Tiên Thọ',
            code: 20887,
            value: 'xa_tien_tho',
            division_type: 'xã',
            short_codename: 'tien_tho',
            parentId: 514,
          },
          {
            title: 'Xã Tiên An',
            code: 20890,
            value: 'xa_tien_an',
            division_type: 'xã',
            short_codename: 'tien_an',
            parentId: 514,
          },
          {
            title: 'Xã Tiên Lộc',
            code: 20893,
            value: 'xa_tien_loc',
            division_type: 'xã',
            short_codename: 'tien_loc',
            parentId: 514,
          },
          {
            title: 'Xã Tiên Lập',
            code: 20896,
            value: 'xa_tien_lap',
            division_type: 'xã',
            short_codename: 'tien_lap',
            parentId: 514,
          },
        ],
        parentId: 49,
      },
      {
        title: 'Huyện Bắc Trà My',
        code: 515,
        value: 'huyen_bac_tra_my',
        division_type: 'huyện',
        short_codename: 'bac_tra_my',
        children: [
          {
            title: 'Thị trấn Trà My',
            code: 20899,
            value: 'thi_tran_tra_my',
            division_type: 'thị trấn',
            short_codename: 'tra_my',
            parentId: 515,
          },
          {
            title: 'Xã Trà Sơn',
            code: 20900,
            value: 'xa_tra_son',
            division_type: 'xã',
            short_codename: 'tra_son',
            parentId: 515,
          },
          {
            title: 'Xã Trà Kót',
            code: 20902,
            value: 'xa_tra_kot',
            division_type: 'xã',
            short_codename: 'tra_kot',
            parentId: 515,
          },
          {
            title: 'Xã Trà Nú',
            code: 20905,
            value: 'xa_tra_nu',
            division_type: 'xã',
            short_codename: 'tra_nu',
            parentId: 515,
          },
          {
            title: 'Xã Trà Đông',
            code: 20908,
            value: 'xa_tra_dong',
            division_type: 'xã',
            short_codename: 'tra_dong',
            parentId: 515,
          },
          {
            title: 'Xã Trà Dương',
            code: 20911,
            value: 'xa_tra_duong',
            division_type: 'xã',
            short_codename: 'tra_duong',
            parentId: 515,
          },
          {
            title: 'Xã Trà Giang',
            code: 20914,
            value: 'xa_tra_giang',
            division_type: 'xã',
            short_codename: 'tra_giang',
            parentId: 515,
          },
          {
            title: 'Xã Trà Bui',
            code: 20917,
            value: 'xa_tra_bui',
            division_type: 'xã',
            short_codename: 'tra_bui',
            parentId: 515,
          },
          {
            title: 'Xã Trà Đốc',
            code: 20920,
            value: 'xa_tra_doc',
            division_type: 'xã',
            short_codename: 'tra_doc',
            parentId: 515,
          },
          {
            title: 'Xã Trà Tân',
            code: 20923,
            value: 'xa_tra_tan',
            division_type: 'xã',
            short_codename: 'tra_tan',
            parentId: 515,
          },
          {
            title: 'Xã Trà Giác',
            code: 20926,
            value: 'xa_tra_giac',
            division_type: 'xã',
            short_codename: 'tra_giac',
            parentId: 515,
          },
          {
            title: 'Xã Trà Giáp',
            code: 20929,
            value: 'xa_tra_giap',
            division_type: 'xã',
            short_codename: 'tra_giap',
            parentId: 515,
          },
          {
            title: 'Xã Trà Ka',
            code: 20932,
            value: 'xa_tra_ka',
            division_type: 'xã',
            short_codename: 'tra_ka',
            parentId: 515,
          },
        ],
        parentId: 49,
      },
      {
        title: 'Huyện Nam Trà My',
        code: 516,
        value: 'huyen_nam_tra_my',
        division_type: 'huyện',
        short_codename: 'nam_tra_my',
        children: [
          {
            title: 'Xã Trà Leng',
            code: 20935,
            value: 'xa_tra_leng',
            division_type: 'xã',
            short_codename: 'tra_leng',
            parentId: 516,
          },
          {
            title: 'Xã Trà Dơn',
            code: 20938,
            value: 'xa_tra_don',
            division_type: 'xã',
            short_codename: 'xa_tra_don',
            parentId: 516,
          },
          {
            title: 'Xã Trà Tập',
            code: 20941,
            value: 'xa_tra_tap',
            division_type: 'xã',
            short_codename: 'tra_tap',
            parentId: 516,
          },
          {
            title: 'Xã Trà Mai',
            code: 20944,
            value: 'xa_tra_mai',
            division_type: 'xã',
            short_codename: 'tra_mai',
            parentId: 516,
          },
          {
            title: 'Xã Trà Cang',
            code: 20947,
            value: 'xa_tra_cang',
            division_type: 'xã',
            short_codename: 'tra_cang',
            parentId: 516,
          },
          {
            title: 'Xã Trà Linh',
            code: 20950,
            value: 'xa_tra_linh',
            division_type: 'xã',
            short_codename: 'tra_linh',
            parentId: 516,
          },
          {
            title: 'Xã Trà Nam',
            code: 20953,
            value: 'xa_tra_nam',
            division_type: 'xã',
            short_codename: 'tra_nam',
            parentId: 516,
          },
          {
            title: 'Xã Trà Don',
            code: 20956,
            value: 'xa_tra_don',
            division_type: 'xã',
            short_codename: 'xa_tra_don',
            parentId: 516,
          },
          {
            title: 'Xã Trà Vân',
            code: 20959,
            value: 'xa_tra_van',
            division_type: 'xã',
            short_codename: 'tra_van',
            parentId: 516,
          },
          {
            title: 'Xã Trà Vinh',
            code: 20962,
            value: 'xa_tra_vinh',
            division_type: 'xã',
            short_codename: 'tra_vinh',
            parentId: 516,
          },
        ],
        parentId: 49,
      },
      {
        title: 'Huyện Núi Thành',
        code: 517,
        value: 'huyen_nui_thanh',
        division_type: 'huyện',
        short_codename: 'nui_thanh',
        children: [
          {
            title: 'Thị trấn Núi Thành',
            code: 20965,
            value: 'thi_tran_nui_thanh',
            division_type: 'thị trấn',
            short_codename: 'nui_thanh',
            parentId: 517,
          },
          {
            title: 'Xã Tam Xuân I',
            code: 20968,
            value: 'xa_tam_xuan_i',
            division_type: 'xã',
            short_codename: 'tam_xuan_i',
            parentId: 517,
          },
          {
            title: 'Xã Tam Xuân II',
            code: 20971,
            value: 'xa_tam_xuan_ii',
            division_type: 'xã',
            short_codename: 'tam_xuan_ii',
            parentId: 517,
          },
          {
            title: 'Xã Tam Tiến',
            code: 20974,
            value: 'xa_tam_tien',
            division_type: 'xã',
            short_codename: 'tam_tien',
            parentId: 517,
          },
          {
            title: 'Xã Tam Sơn',
            code: 20977,
            value: 'xa_tam_son',
            division_type: 'xã',
            short_codename: 'tam_son',
            parentId: 517,
          },
          {
            title: 'Xã Tam Thạnh',
            code: 20980,
            value: 'xa_tam_thanh',
            division_type: 'xã',
            short_codename: 'tam_thanh',
            parentId: 517,
          },
          {
            title: 'Xã Tam Anh Bắc',
            code: 20983,
            value: 'xa_tam_anh_bac',
            division_type: 'xã',
            short_codename: 'tam_anh_bac',
            parentId: 517,
          },
          {
            title: 'Xã Tam Anh Nam',
            code: 20984,
            value: 'xa_tam_anh_nam',
            division_type: 'xã',
            short_codename: 'tam_anh_nam',
            parentId: 517,
          },
          {
            title: 'Xã Tam Hòa',
            code: 20986,
            value: 'xa_tam_hoa',
            division_type: 'xã',
            short_codename: 'tam_hoa',
            parentId: 517,
          },
          {
            title: 'Xã Tam Hiệp',
            code: 20989,
            value: 'xa_tam_hiep',
            division_type: 'xã',
            short_codename: 'tam_hiep',
            parentId: 517,
          },
          {
            title: 'Xã Tam Hải',
            code: 20992,
            value: 'xa_tam_hai',
            division_type: 'xã',
            short_codename: 'tam_hai',
            parentId: 517,
          },
          {
            title: 'Xã Tam Giang',
            code: 20995,
            value: 'xa_tam_giang',
            division_type: 'xã',
            short_codename: 'tam_giang',
            parentId: 517,
          },
          {
            title: 'Xã Tam Quang',
            code: 20998,
            value: 'xa_tam_quang',
            division_type: 'xã',
            short_codename: 'tam_quang',
            parentId: 517,
          },
          {
            title: 'Xã Tam Nghĩa',
            code: 21001,
            value: 'xa_tam_nghia',
            division_type: 'xã',
            short_codename: 'tam_nghia',
            parentId: 517,
          },
          {
            title: 'Xã Tam Mỹ Tây',
            code: 21004,
            value: 'xa_tam_my_tay',
            division_type: 'xã',
            short_codename: 'tam_my_tay',
            parentId: 517,
          },
          {
            title: 'Xã Tam Mỹ Đông',
            code: 21005,
            value: 'xa_tam_my_dong',
            division_type: 'xã',
            short_codename: 'tam_my_dong',
            parentId: 517,
          },
          {
            title: 'Xã Tam Trà',
            code: 21007,
            value: 'xa_tam_tra',
            division_type: 'xã',
            short_codename: 'tam_tra',
            parentId: 517,
          },
        ],
        parentId: 49,
      },
      {
        title: 'Huyện Phú Ninh',
        code: 518,
        value: 'huyen_phu_ninh',
        division_type: 'huyện',
        short_codename: 'phu_ninh',
        children: [
          {
            title: 'Thị trấn Phú Thịnh',
            code: 20364,
            value: 'thi_tran_phu_thinh',
            division_type: 'thị trấn',
            short_codename: 'phu_thinh',
            parentId: 518,
          },
          {
            title: 'Xã Tam Thành',
            code: 20365,
            value: 'xa_tam_thanh',
            division_type: 'xã',
            short_codename: 'tam_thanh',
            parentId: 518,
          },
          {
            title: 'Xã Tam An',
            code: 20368,
            value: 'xa_tam_an',
            division_type: 'xã',
            short_codename: 'tam_an',
            parentId: 518,
          },
          {
            title: 'Xã Tam Đàn',
            code: 20374,
            value: 'xa_tam_dan',
            division_type: 'xã',
            short_codename: 'xa_tam_dan',
            parentId: 518,
          },
          {
            title: 'Xã Tam Lộc',
            code: 20377,
            value: 'xa_tam_loc',
            division_type: 'xã',
            short_codename: 'tam_loc',
            parentId: 518,
          },
          {
            title: 'Xã Tam Phước',
            code: 20380,
            value: 'xa_tam_phuoc',
            division_type: 'xã',
            short_codename: 'tam_phuoc',
            parentId: 518,
          },
          {
            title: 'Xã Tam Vinh',
            code: 20383,
            value: 'xa_tam_vinh',
            division_type: 'xã',
            short_codename: 'tam_vinh',
            parentId: 518,
          },
          {
            title: 'Xã Tam Thái',
            code: 20386,
            value: 'xa_tam_thai',
            division_type: 'xã',
            short_codename: 'tam_thai',
            parentId: 518,
          },
          {
            title: 'Xã Tam Đại',
            code: 20387,
            value: 'xa_tam_dai',
            division_type: 'xã',
            short_codename: 'tam_dai',
            parentId: 518,
          },
          {
            title: 'Xã Tam Dân',
            code: 20392,
            value: 'xa_tam_dan',
            division_type: 'xã',
            short_codename: 'xa_tam_dan',
            parentId: 518,
          },
          {
            title: 'Xã Tam Lãnh',
            code: 20395,
            value: 'xa_tam_lanh',
            division_type: 'xã',
            short_codename: 'tam_lanh',
            parentId: 518,
          },
        ],
        parentId: 49,
      },
      {
        title: 'Huyện Nông Sơn',
        code: 519,
        value: 'huyen_nong_son',
        division_type: 'huyện',
        short_codename: 'nong_son',
        children: [
          {
            title: 'Xã Quế Trung',
            code: 20656,
            value: 'xa_que_trung',
            division_type: 'xã',
            short_codename: 'que_trung',
            parentId: 519,
          },
          {
            title: 'Xã Ninh Phước',
            code: 20668,
            value: 'xa_ninh_phuoc',
            division_type: 'xã',
            short_codename: 'ninh_phuoc',
            parentId: 519,
          },
          {
            title: 'Xã Phước Ninh',
            code: 20669,
            value: 'xa_phuoc_ninh',
            division_type: 'xã',
            short_codename: 'phuoc_ninh',
            parentId: 519,
          },
          {
            title: 'Xã Quế Lộc',
            code: 20671,
            value: 'xa_que_loc',
            division_type: 'xã',
            short_codename: 'que_loc',
            parentId: 519,
          },
          {
            title: 'Xã Sơn Viên',
            code: 20672,
            value: 'xa_son_vien',
            division_type: 'xã',
            short_codename: 'son_vien',
            parentId: 519,
          },
          {
            title: 'Xã Quế Lâm',
            code: 20692,
            value: 'xa_que_lam',
            division_type: 'xã',
            short_codename: 'que_lam',
            parentId: 519,
          },
        ],
        parentId: 49,
      },
    ],
  },
  {
    title: 'Tỉnh Quảng Ngãi',
    code: 51,
    value: 'tinh_quang_ngai',
    division_type: 'tỉnh',
    phone_code: 255,
    children: [
      {
        title: 'Thành phố Quảng Ngãi',
        code: 522,
        value: 'thanh_pho_quang_ngai',
        division_type: 'thành phố',
        short_codename: 'quang_ngai',
        children: [
          {
            title: 'Phường Lê Hồng Phong',
            code: 21010,
            value: 'phuong_le_hong_phong',
            division_type: 'phường',
            short_codename: 'le_hong_phong',
            parentId: 522,
          },
          {
            title: 'Phường Trần Phú',
            code: 21013,
            value: 'phuong_tran_phu',
            division_type: 'phường',
            short_codename: 'tran_phu',
            parentId: 522,
          },
          {
            title: 'Phường Quảng Phú',
            code: 21016,
            value: 'phuong_quang_phu',
            division_type: 'phường',
            short_codename: 'quang_phu',
            parentId: 522,
          },
          {
            title: 'Phường Nghĩa Chánh',
            code: 21019,
            value: 'phuong_nghia_chanh',
            division_type: 'phường',
            short_codename: 'nghia_chanh',
            parentId: 522,
          },
          {
            title: 'Phường Trần Hưng Đạo',
            code: 21022,
            value: 'phuong_tran_hung_dao',
            division_type: 'phường',
            short_codename: 'tran_hung_dao',
            parentId: 522,
          },
          {
            title: 'Phường Nguyễn Nghiêm',
            code: 21025,
            value: 'phuong_nguyen_nghiem',
            division_type: 'phường',
            short_codename: 'nguyen_nghiem',
            parentId: 522,
          },
          {
            title: 'Phường Nghĩa Lộ',
            code: 21028,
            value: 'phuong_nghia_lo',
            division_type: 'phường',
            short_codename: 'nghia_lo',
            parentId: 522,
          },
          {
            title: 'Phường Chánh Lộ',
            code: 21031,
            value: 'phuong_chanh_lo',
            division_type: 'phường',
            short_codename: 'chanh_lo',
            parentId: 522,
          },
          {
            title: 'Xã Nghĩa Dũng',
            code: 21034,
            value: 'xa_nghia_dung',
            division_type: 'xã',
            short_codename: 'nghia_dung',
            parentId: 522,
          },
          {
            title: 'Xã Nghĩa Dõng',
            code: 21037,
            value: 'xa_nghia_dong',
            division_type: 'xã',
            short_codename: 'nghia_dong',
            parentId: 522,
          },
          {
            title: 'Phường Trương Quang Trọng',
            code: 21172,
            value: 'phuong_truong_quang_trong',
            division_type: 'phường',
            short_codename: 'truong_quang_trong',
            parentId: 522,
          },
          {
            title: 'Xã Tịnh Hòa',
            code: 21187,
            value: 'xa_tinh_hoa',
            division_type: 'xã',
            short_codename: 'tinh_hoa',
            parentId: 522,
          },
          {
            title: 'Xã Tịnh Kỳ',
            code: 21190,
            value: 'xa_tinh_ky',
            division_type: 'xã',
            short_codename: 'tinh_ky',
            parentId: 522,
          },
          {
            title: 'Xã Tịnh Thiện',
            code: 21199,
            value: 'xa_tinh_thien',
            division_type: 'xã',
            short_codename: 'tinh_thien',
            parentId: 522,
          },
          {
            title: 'Xã Tịnh Ấn Đông',
            code: 21202,
            value: 'xa_tinh_an_dong',
            division_type: 'xã',
            short_codename: 'tinh_an_dong',
            parentId: 522,
          },
          {
            title: 'Xã Tịnh Châu',
            code: 21208,
            value: 'xa_tinh_chau',
            division_type: 'xã',
            short_codename: 'tinh_chau',
            parentId: 522,
          },
          {
            title: 'Xã Tịnh Khê',
            code: 21211,
            value: 'xa_tinh_khe',
            division_type: 'xã',
            short_codename: 'tinh_khe',
            parentId: 522,
          },
          {
            title: 'Xã Tịnh Long',
            code: 21214,
            value: 'xa_tinh_long',
            division_type: 'xã',
            short_codename: 'tinh_long',
            parentId: 522,
          },
          {
            title: 'Xã Tịnh Ấn Tây',
            code: 21223,
            value: 'xa_tinh_an_tay',
            division_type: 'xã',
            short_codename: 'tinh_an_tay',
            parentId: 522,
          },
          {
            title: 'Xã Tịnh An',
            code: 21232,
            value: 'xa_tinh_an',
            division_type: 'xã',
            short_codename: 'tinh_an',
            parentId: 522,
          },
          {
            title: 'Xã Nghĩa Phú',
            code: 21253,
            value: 'xa_nghia_phu',
            division_type: 'xã',
            short_codename: 'nghia_phu',
            parentId: 522,
          },
          {
            title: 'Xã Nghĩa Hà',
            code: 21256,
            value: 'xa_nghia_ha',
            division_type: 'xã',
            short_codename: 'nghia_ha',
            parentId: 522,
          },
          {
            title: 'Xã Nghĩa An',
            code: 21262,
            value: 'xa_nghia_an',
            division_type: 'xã',
            short_codename: 'nghia_an',
            parentId: 522,
          },
        ],
        parentId: 51,
      },
      {
        title: 'Huyện Bình Sơn',
        code: 524,
        value: 'huyen_binh_son',
        division_type: 'huyện',
        short_codename: 'binh_son',
        children: [
          {
            title: 'Thị Trấn Châu Ổ',
            code: 21040,
            value: 'thi_tran_chau_o',
            division_type: 'thị trấn',
            short_codename: 'chau_o',
            parentId: 524,
          },
          {
            title: 'Xã Bình Thuận',
            code: 21043,
            value: 'xa_binh_thuan',
            division_type: 'xã',
            short_codename: 'binh_thuan',
            parentId: 524,
          },
          {
            title: 'Xã Bình Thạnh',
            code: 21046,
            value: 'xa_binh_thanh',
            division_type: 'xã',
            short_codename: 'xa_binh_thanh',
            parentId: 524,
          },
          {
            title: 'Xã Bình Đông',
            code: 21049,
            value: 'xa_binh_dong',
            division_type: 'xã',
            short_codename: 'binh_dong',
            parentId: 524,
          },
          {
            title: 'Xã Bình Chánh',
            code: 21052,
            value: 'xa_binh_chanh',
            division_type: 'xã',
            short_codename: 'binh_chanh',
            parentId: 524,
          },
          {
            title: 'Xã Bình Nguyên',
            code: 21055,
            value: 'xa_binh_nguyen',
            division_type: 'xã',
            short_codename: 'binh_nguyen',
            parentId: 524,
          },
          {
            title: 'Xã Bình Khương',
            code: 21058,
            value: 'xa_binh_khuong',
            division_type: 'xã',
            short_codename: 'binh_khuong',
            parentId: 524,
          },
          {
            title: 'Xã Bình Trị',
            code: 21061,
            value: 'xa_binh_tri',
            division_type: 'xã',
            short_codename: 'binh_tri',
            parentId: 524,
          },
          {
            title: 'Xã Bình An',
            code: 21064,
            value: 'xa_binh_an',
            division_type: 'xã',
            short_codename: 'binh_an',
            parentId: 524,
          },
          {
            title: 'Xã Bình Hải',
            code: 21067,
            value: 'xa_binh_hai',
            division_type: 'xã',
            short_codename: 'binh_hai',
            parentId: 524,
          },
          {
            title: 'Xã Bình Dương',
            code: 21070,
            value: 'xa_binh_duong',
            division_type: 'xã',
            short_codename: 'binh_duong',
            parentId: 524,
          },
          {
            title: 'Xã Bình Phước',
            code: 21073,
            value: 'xa_binh_phuoc',
            division_type: 'xã',
            short_codename: 'binh_phuoc',
            parentId: 524,
          },
          {
            title: 'Xã Bình Hòa',
            code: 21079,
            value: 'xa_binh_hoa',
            division_type: 'xã',
            short_codename: 'binh_hoa',
            parentId: 524,
          },
          {
            title: 'Xã Bình Trung',
            code: 21082,
            value: 'xa_binh_trung',
            division_type: 'xã',
            short_codename: 'binh_trung',
            parentId: 524,
          },
          {
            title: 'Xã Bình Minh',
            code: 21085,
            value: 'xa_binh_minh',
            division_type: 'xã',
            short_codename: 'binh_minh',
            parentId: 524,
          },
          {
            title: 'Xã Bình Long',
            code: 21088,
            value: 'xa_binh_long',
            division_type: 'xã',
            short_codename: 'binh_long',
            parentId: 524,
          },
          {
            title: 'Xã Bình Thanh',
            code: 21091,
            value: 'xa_binh_thanh',
            division_type: 'xã',
            short_codename: 'xa_binh_thanh',
            parentId: 524,
          },
          {
            title: 'Xã Bình Chương',
            code: 21100,
            value: 'xa_binh_chuong',
            division_type: 'xã',
            short_codename: 'binh_chuong',
            parentId: 524,
          },
          {
            title: 'Xã Bình Hiệp',
            code: 21103,
            value: 'xa_binh_hiep',
            division_type: 'xã',
            short_codename: 'binh_hiep',
            parentId: 524,
          },
          {
            title: 'Xã Bình Mỹ',
            code: 21106,
            value: 'xa_binh_my',
            division_type: 'xã',
            short_codename: 'binh_my',
            parentId: 524,
          },
          {
            title: 'Xã Bình Tân Phú',
            code: 21109,
            value: 'xa_binh_tan_phu',
            division_type: 'xã',
            short_codename: 'binh_tan_phu',
            parentId: 524,
          },
          {
            title: 'Xã Bình Châu',
            code: 21112,
            value: 'xa_binh_chau',
            division_type: 'xã',
            short_codename: 'binh_chau',
            parentId: 524,
          },
        ],
        parentId: 51,
      },
      {
        title: 'Huyện Trà Bồng',
        code: 525,
        value: 'huyen_tra_bong',
        division_type: 'huyện',
        short_codename: 'tra_bong',
        children: [
          {
            title: 'Thị trấn Trà Xuân',
            code: 21115,
            value: 'thi_tran_tra_xuan',
            division_type: 'thị trấn',
            short_codename: 'tra_xuan',
            parentId: 525,
          },
          {
            title: 'Xã Trà Giang',
            code: 21118,
            value: 'xa_tra_giang',
            division_type: 'xã',
            short_codename: 'tra_giang',
            parentId: 525,
          },
          {
            title: 'Xã Trà Thủy',
            code: 21121,
            value: 'xa_tra_thuy',
            division_type: 'xã',
            short_codename: 'tra_thuy',
            parentId: 525,
          },
          {
            title: 'Xã Trà Hiệp',
            code: 21124,
            value: 'xa_tra_hiep',
            division_type: 'xã',
            short_codename: 'tra_hiep',
            parentId: 525,
          },
          {
            title: 'Xã Trà Bình',
            code: 21127,
            value: 'xa_tra_binh',
            division_type: 'xã',
            short_codename: 'tra_binh',
            parentId: 525,
          },
          {
            title: 'Xã Trà Phú',
            code: 21130,
            value: 'xa_tra_phu',
            division_type: 'xã',
            short_codename: 'tra_phu',
            parentId: 525,
          },
          {
            title: 'Xã Trà Lâm',
            code: 21133,
            value: 'xa_tra_lam',
            division_type: 'xã',
            short_codename: 'tra_lam',
            parentId: 525,
          },
          {
            title: 'Xã Trà Tân',
            code: 21136,
            value: 'xa_tra_tan',
            division_type: 'xã',
            short_codename: 'tra_tan',
            parentId: 525,
          },
          {
            title: 'Xã Trà Sơn',
            code: 21139,
            value: 'xa_tra_son',
            division_type: 'xã',
            short_codename: 'tra_son',
            parentId: 525,
          },
          {
            title: 'Xã Trà Bùi',
            code: 21142,
            value: 'xa_tra_bui',
            division_type: 'xã',
            short_codename: 'tra_bui',
            parentId: 525,
          },
          {
            title: 'Xã Trà Thanh',
            code: 21145,
            value: 'xa_tra_thanh',
            division_type: 'xã',
            short_codename: 'tra_thanh',
            parentId: 525,
          },
          {
            title: 'Xã Sơn Trà',
            code: 21148,
            value: 'xa_son_tra',
            division_type: 'xã',
            short_codename: 'son_tra',
            parentId: 525,
          },
          {
            title: 'Xã Trà Phong',
            code: 21154,
            value: 'xa_tra_phong',
            division_type: 'xã',
            short_codename: 'tra_phong',
            parentId: 525,
          },
          {
            title: 'Xã Hương Trà',
            code: 21157,
            value: 'xa_huong_tra',
            division_type: 'xã',
            short_codename: 'huong_tra',
            parentId: 525,
          },
          {
            title: 'Xã Trà Xinh',
            code: 21163,
            value: 'xa_tra_xinh',
            division_type: 'xã',
            short_codename: 'tra_xinh',
            parentId: 525,
          },
          {
            title: 'Xã Trà Tây',
            code: 21166,
            value: 'xa_tra_tay',
            division_type: 'xã',
            short_codename: 'tra_tay',
            parentId: 525,
          },
        ],
        parentId: 51,
      },
      {
        title: 'Huyện Sơn Tịnh',
        code: 527,
        value: 'huyen_son_tinh',
        division_type: 'huyện',
        short_codename: 'son_tinh',
        children: [
          {
            title: 'Xã Tịnh Thọ',
            code: 21175,
            value: 'xa_tinh_tho',
            division_type: 'xã',
            short_codename: 'tinh_tho',
            parentId: 527,
          },
          {
            title: 'Xã Tịnh Trà',
            code: 21178,
            value: 'xa_tinh_tra',
            division_type: 'xã',
            short_codename: 'tinh_tra',
            parentId: 527,
          },
          {
            title: 'Xã Tịnh Phong',
            code: 21181,
            value: 'xa_tinh_phong',
            division_type: 'xã',
            short_codename: 'tinh_phong',
            parentId: 527,
          },
          {
            title: 'Xã Tịnh Hiệp',
            code: 21184,
            value: 'xa_tinh_hiep',
            division_type: 'xã',
            short_codename: 'tinh_hiep',
            parentId: 527,
          },
          {
            title: 'Xã Tịnh Bình',
            code: 21193,
            value: 'xa_tinh_binh',
            division_type: 'xã',
            short_codename: 'tinh_binh',
            parentId: 527,
          },
          {
            title: 'Xã Tịnh Đông',
            code: 21196,
            value: 'xa_tinh_dong',
            division_type: 'xã',
            short_codename: 'tinh_dong',
            parentId: 527,
          },
          {
            title: 'Xã Tịnh Bắc',
            code: 21205,
            value: 'xa_tinh_bac',
            division_type: 'xã',
            short_codename: 'tinh_bac',
            parentId: 527,
          },
          {
            title: 'Xã Tịnh Sơn',
            code: 21217,
            value: 'xa_tinh_son',
            division_type: 'xã',
            short_codename: 'tinh_son',
            parentId: 527,
          },
          {
            title: 'Xã Tịnh Hà',
            code: 21220,
            value: 'xa_tinh_ha',
            division_type: 'xã',
            short_codename: 'tinh_ha',
            parentId: 527,
          },
          {
            title: 'Xã Tịnh Giang',
            code: 21226,
            value: 'xa_tinh_giang',
            division_type: 'xã',
            short_codename: 'tinh_giang',
            parentId: 527,
          },
          {
            title: 'Xã Tịnh Minh',
            code: 21229,
            value: 'xa_tinh_minh',
            division_type: 'xã',
            short_codename: 'tinh_minh',
            parentId: 527,
          },
        ],
        parentId: 51,
      },
      {
        title: 'Huyện Tư Nghĩa',
        code: 528,
        value: 'huyen_tu_nghia',
        division_type: 'huyện',
        short_codename: 'tu_nghia',
        children: [
          {
            title: 'Thị trấn La Hà',
            code: 21235,
            value: 'thi_tran_la_ha',
            division_type: 'thị trấn',
            short_codename: 'la_ha',
            parentId: 528,
          },
          {
            title: 'Thị trấn Sông Vệ',
            code: 21238,
            value: 'thi_tran_song_ve',
            division_type: 'thị trấn',
            short_codename: 'song_ve',
            parentId: 528,
          },
          {
            title: 'Xã Nghĩa Lâm',
            code: 21241,
            value: 'xa_nghia_lam',
            division_type: 'xã',
            short_codename: 'nghia_lam',
            parentId: 528,
          },
          {
            title: 'Xã Nghĩa Thắng',
            code: 21244,
            value: 'xa_nghia_thang',
            division_type: 'xã',
            short_codename: 'nghia_thang',
            parentId: 528,
          },
          {
            title: 'Xã Nghĩa Thuận',
            code: 21247,
            value: 'xa_nghia_thuan',
            division_type: 'xã',
            short_codename: 'nghia_thuan',
            parentId: 528,
          },
          {
            title: 'Xã Nghĩa Kỳ',
            code: 21250,
            value: 'xa_nghia_ky',
            division_type: 'xã',
            short_codename: 'nghia_ky',
            parentId: 528,
          },
          {
            title: 'Xã Nghĩa Sơn',
            code: 21259,
            value: 'xa_nghia_son',
            division_type: 'xã',
            short_codename: 'nghia_son',
            parentId: 528,
          },
          {
            title: 'Xã Nghĩa Hòa',
            code: 21268,
            value: 'xa_nghia_hoa',
            division_type: 'xã',
            short_codename: 'nghia_hoa',
            parentId: 528,
          },
          {
            title: 'Xã Nghĩa Điền',
            code: 21271,
            value: 'xa_nghia_dien',
            division_type: 'xã',
            short_codename: 'nghia_dien',
            parentId: 528,
          },
          {
            title: 'Xã Nghĩa Thương',
            code: 21274,
            value: 'xa_nghia_thuong',
            division_type: 'xã',
            short_codename: 'nghia_thuong',
            parentId: 528,
          },
          {
            title: 'Xã Nghĩa Trung',
            code: 21277,
            value: 'xa_nghia_trung',
            division_type: 'xã',
            short_codename: 'nghia_trung',
            parentId: 528,
          },
          {
            title: 'Xã Nghĩa Hiệp',
            code: 21280,
            value: 'xa_nghia_hiep',
            division_type: 'xã',
            short_codename: 'nghia_hiep',
            parentId: 528,
          },
          {
            title: 'Xã Nghĩa Phương',
            code: 21283,
            value: 'xa_nghia_phuong',
            division_type: 'xã',
            short_codename: 'nghia_phuong',
            parentId: 528,
          },
          {
            title: 'Xã Nghĩa Mỹ',
            code: 21286,
            value: 'xa_nghia_my',
            division_type: 'xã',
            short_codename: 'nghia_my',
            parentId: 528,
          },
        ],
        parentId: 51,
      },
      {
        title: 'Huyện Sơn Hà',
        code: 529,
        value: 'huyen_son_ha',
        division_type: 'huyện',
        short_codename: 'son_ha',
        children: [
          {
            title: 'Thị trấn Di Lăng',
            code: 21289,
            value: 'thi_tran_di_lang',
            division_type: 'thị trấn',
            short_codename: 'di_lang',
            parentId: 529,
          },
          {
            title: 'Xã Sơn Hạ',
            code: 21292,
            value: 'xa_son_ha',
            division_type: 'xã',
            short_codename: 'son_ha',
            parentId: 529,
          },
          {
            title: 'Xã Sơn Thành',
            code: 21295,
            value: 'xa_son_thanh',
            division_type: 'xã',
            short_codename: 'son_thanh',
            parentId: 529,
          },
          {
            title: 'Xã Sơn Nham',
            code: 21298,
            value: 'xa_son_nham',
            division_type: 'xã',
            short_codename: 'son_nham',
            parentId: 529,
          },
          {
            title: 'Xã Sơn Bao',
            code: 21301,
            value: 'xa_son_bao',
            division_type: 'xã',
            short_codename: 'son_bao',
            parentId: 529,
          },
          {
            title: 'Xã Sơn Linh',
            code: 21304,
            value: 'xa_son_linh',
            division_type: 'xã',
            short_codename: 'son_linh',
            parentId: 529,
          },
          {
            title: 'Xã Sơn Giang',
            code: 21307,
            value: 'xa_son_giang',
            division_type: 'xã',
            short_codename: 'son_giang',
            parentId: 529,
          },
          {
            title: 'Xã Sơn Trung',
            code: 21310,
            value: 'xa_son_trung',
            division_type: 'xã',
            short_codename: 'son_trung',
            parentId: 529,
          },
          {
            title: 'Xã Sơn Thượng',
            code: 21313,
            value: 'xa_son_thuong',
            division_type: 'xã',
            short_codename: 'son_thuong',
            parentId: 529,
          },
          {
            title: 'Xã Sơn Cao',
            code: 21316,
            value: 'xa_son_cao',
            division_type: 'xã',
            short_codename: 'son_cao',
            parentId: 529,
          },
          {
            title: 'Xã Sơn Hải',
            code: 21319,
            value: 'xa_son_hai',
            division_type: 'xã',
            short_codename: 'son_hai',
            parentId: 529,
          },
          {
            title: 'Xã Sơn Thủy',
            code: 21322,
            value: 'xa_son_thuy',
            division_type: 'xã',
            short_codename: 'son_thuy',
            parentId: 529,
          },
          {
            title: 'Xã Sơn Kỳ',
            code: 21325,
            value: 'xa_son_ky',
            division_type: 'xã',
            short_codename: 'son_ky',
            parentId: 529,
          },
          {
            title: 'Xã Sơn Ba',
            code: 21328,
            value: 'xa_son_ba',
            division_type: 'xã',
            short_codename: 'son_ba',
            parentId: 529,
          },
        ],
        parentId: 51,
      },
      {
        title: 'Huyện Sơn Tây',
        code: 530,
        value: 'huyen_son_tay',
        division_type: 'huyện',
        short_codename: 'son_tay',
        children: [
          {
            title: 'Xã Sơn Bua',
            code: 21331,
            value: 'xa_son_bua',
            division_type: 'xã',
            short_codename: 'son_bua',
            parentId: 530,
          },
          {
            title: 'Xã Sơn Mùa',
            code: 21334,
            value: 'xa_son_mua',
            division_type: 'xã',
            short_codename: 'son_mua',
            parentId: 530,
          },
          {
            title: 'Xã Sơn Liên',
            code: 21335,
            value: 'xa_son_lien',
            division_type: 'xã',
            short_codename: 'son_lien',
            parentId: 530,
          },
          {
            title: 'Xã Sơn Tân',
            code: 21337,
            value: 'xa_son_tan',
            division_type: 'xã',
            short_codename: 'son_tan',
            parentId: 530,
          },
          {
            title: 'Xã Sơn Màu',
            code: 21338,
            value: 'xa_son_mau',
            division_type: 'xã',
            short_codename: 'son_mau',
            parentId: 530,
          },
          {
            title: 'Xã Sơn Dung',
            code: 21340,
            value: 'xa_son_dung',
            division_type: 'xã',
            short_codename: 'son_dung',
            parentId: 530,
          },
          {
            title: 'Xã Sơn Long',
            code: 21341,
            value: 'xa_son_long',
            division_type: 'xã',
            short_codename: 'son_long',
            parentId: 530,
          },
          {
            title: 'Xã Sơn Tinh',
            code: 21343,
            value: 'xa_son_tinh',
            division_type: 'xã',
            short_codename: 'son_tinh',
            parentId: 530,
          },
          {
            title: 'Xã Sơn Lập',
            code: 21346,
            value: 'xa_son_lap',
            division_type: 'xã',
            short_codename: 'son_lap',
            parentId: 530,
          },
        ],
        parentId: 51,
      },
      {
        title: 'Huyện Minh Long',
        code: 531,
        value: 'huyen_minh_long',
        division_type: 'huyện',
        short_codename: 'minh_long',
        children: [
          {
            title: 'Xã Long Sơn',
            code: 21349,
            value: 'xa_long_son',
            division_type: 'xã',
            short_codename: 'long_son',
            parentId: 531,
          },
          {
            title: 'Xã Long Mai',
            code: 21352,
            value: 'xa_long_mai',
            division_type: 'xã',
            short_codename: 'long_mai',
            parentId: 531,
          },
          {
            title: 'Xã Thanh An',
            code: 21355,
            value: 'xa_thanh_an',
            division_type: 'xã',
            short_codename: 'thanh_an',
            parentId: 531,
          },
          {
            title: 'Xã Long Môn',
            code: 21358,
            value: 'xa_long_mon',
            division_type: 'xã',
            short_codename: 'long_mon',
            parentId: 531,
          },
          {
            title: 'Xã Long Hiệp',
            code: 21361,
            value: 'xa_long_hiep',
            division_type: 'xã',
            short_codename: 'long_hiep',
            parentId: 531,
          },
        ],
        parentId: 51,
      },
      {
        title: 'Huyện Nghĩa Hành',
        code: 532,
        value: 'huyen_nghia_hanh',
        division_type: 'huyện',
        short_codename: 'nghia_hanh',
        children: [
          {
            title: 'Thị trấn Chợ Chùa',
            code: 21364,
            value: 'thi_tran_cho_chua',
            division_type: 'thị trấn',
            short_codename: 'cho_chua',
            parentId: 532,
          },
          {
            title: 'Xã Hành Thuận',
            code: 21367,
            value: 'xa_hanh_thuan',
            division_type: 'xã',
            short_codename: 'hanh_thuan',
            parentId: 532,
          },
          {
            title: 'Xã Hành Dũng',
            code: 21370,
            value: 'xa_hanh_dung',
            division_type: 'xã',
            short_codename: 'hanh_dung',
            parentId: 532,
          },
          {
            title: 'Xã Hành Trung',
            code: 21373,
            value: 'xa_hanh_trung',
            division_type: 'xã',
            short_codename: 'hanh_trung',
            parentId: 532,
          },
          {
            title: 'Xã Hành Nhân',
            code: 21376,
            value: 'xa_hanh_nhan',
            division_type: 'xã',
            short_codename: 'hanh_nhan',
            parentId: 532,
          },
          {
            title: 'Xã Hành Đức',
            code: 21379,
            value: 'xa_hanh_duc',
            division_type: 'xã',
            short_codename: 'hanh_duc',
            parentId: 532,
          },
          {
            title: 'Xã Hành Minh',
            code: 21382,
            value: 'xa_hanh_minh',
            division_type: 'xã',
            short_codename: 'hanh_minh',
            parentId: 532,
          },
          {
            title: 'Xã Hành Phước',
            code: 21385,
            value: 'xa_hanh_phuoc',
            division_type: 'xã',
            short_codename: 'hanh_phuoc',
            parentId: 532,
          },
          {
            title: 'Xã Hành Thiện',
            code: 21388,
            value: 'xa_hanh_thien',
            division_type: 'xã',
            short_codename: 'hanh_thien',
            parentId: 532,
          },
          {
            title: 'Xã Hành Thịnh',
            code: 21391,
            value: 'xa_hanh_thinh',
            division_type: 'xã',
            short_codename: 'hanh_thinh',
            parentId: 532,
          },
          {
            title: 'Xã Hành Tín Tây',
            code: 21394,
            value: 'xa_hanh_tin_tay',
            division_type: 'xã',
            short_codename: 'hanh_tin_tay',
            parentId: 532,
          },
          {
            title: 'Xã Hành Tín Đông',
            code: 21397,
            value: 'xa_hanh_tin_dong',
            division_type: 'xã',
            short_codename: 'hanh_tin_dong',
            parentId: 532,
          },
        ],
        parentId: 51,
      },
      {
        title: 'Huyện Mộ Đức',
        code: 533,
        value: 'huyen_mo_duc',
        division_type: 'huyện',
        short_codename: 'mo_duc',
        children: [
          {
            title: 'Thị trấn Mộ Đức',
            code: 21400,
            value: 'thi_tran_mo_duc',
            division_type: 'thị trấn',
            short_codename: 'mo_duc',
            parentId: 533,
          },
          {
            title: 'Xã Đức Lợi',
            code: 21403,
            value: 'xa_duc_loi',
            division_type: 'xã',
            short_codename: 'duc_loi',
            parentId: 533,
          },
          {
            title: 'Xã Đức Thắng',
            code: 21406,
            value: 'xa_duc_thang',
            division_type: 'xã',
            short_codename: 'duc_thang',
            parentId: 533,
          },
          {
            title: 'Xã Đức Nhuận',
            code: 21409,
            value: 'xa_duc_nhuan',
            division_type: 'xã',
            short_codename: 'duc_nhuan',
            parentId: 533,
          },
          {
            title: 'Xã Đức Chánh',
            code: 21412,
            value: 'xa_duc_chanh',
            division_type: 'xã',
            short_codename: 'duc_chanh',
            parentId: 533,
          },
          {
            title: 'Xã Đức Hiệp',
            code: 21415,
            value: 'xa_duc_hiep',
            division_type: 'xã',
            short_codename: 'duc_hiep',
            parentId: 533,
          },
          {
            title: 'Xã Đức Minh',
            code: 21418,
            value: 'xa_duc_minh',
            division_type: 'xã',
            short_codename: 'duc_minh',
            parentId: 533,
          },
          {
            title: 'Xã Đức Thạnh',
            code: 21421,
            value: 'xa_duc_thanh',
            division_type: 'xã',
            short_codename: 'duc_thanh',
            parentId: 533,
          },
          {
            title: 'Xã Đức Hòa',
            code: 21424,
            value: 'xa_duc_hoa',
            division_type: 'xã',
            short_codename: 'duc_hoa',
            parentId: 533,
          },
          {
            title: 'Xã Đức Tân',
            code: 21427,
            value: 'xa_duc_tan',
            division_type: 'xã',
            short_codename: 'duc_tan',
            parentId: 533,
          },
          {
            title: 'Xã Đức Phú',
            code: 21430,
            value: 'xa_duc_phu',
            division_type: 'xã',
            short_codename: 'duc_phu',
            parentId: 533,
          },
          {
            title: 'Xã Đức Phong',
            code: 21433,
            value: 'xa_duc_phong',
            division_type: 'xã',
            short_codename: 'duc_phong',
            parentId: 533,
          },
          {
            title: 'Xã Đức Lân',
            code: 21436,
            value: 'xa_duc_lan',
            division_type: 'xã',
            short_codename: 'duc_lan',
            parentId: 533,
          },
        ],
        parentId: 51,
      },
      {
        title: 'Thị xã Đức Phổ',
        code: 534,
        value: 'thi_xa_duc_pho',
        division_type: 'thị xã',
        short_codename: 'duc_pho',
        children: [
          {
            title: 'Phường Nguyễn Nghiêm',
            code: 21439,
            value: 'phuong_nguyen_nghiem',
            division_type: 'phường',
            short_codename: 'nguyen_nghiem',
            parentId: 534,
          },
          {
            title: 'Xã Phổ An',
            code: 21442,
            value: 'xa_pho_an',
            division_type: 'xã',
            short_codename: 'pho_an',
            parentId: 534,
          },
          {
            title: 'Xã Phổ Phong',
            code: 21445,
            value: 'xa_pho_phong',
            division_type: 'xã',
            short_codename: 'pho_phong',
            parentId: 534,
          },
          {
            title: 'Xã Phổ Thuận',
            code: 21448,
            value: 'xa_pho_thuan',
            division_type: 'xã',
            short_codename: 'pho_thuan',
            parentId: 534,
          },
          {
            title: 'Phường Phổ Văn',
            code: 21451,
            value: 'phuong_pho_van',
            division_type: 'phường',
            short_codename: 'pho_van',
            parentId: 534,
          },
          {
            title: 'Phường Phổ Quang',
            code: 21454,
            value: 'phuong_pho_quang',
            division_type: 'phường',
            short_codename: 'pho_quang',
            parentId: 534,
          },
          {
            title: 'Xã Phổ Nhơn',
            code: 21457,
            value: 'xa_pho_nhon',
            division_type: 'xã',
            short_codename: 'pho_nhon',
            parentId: 534,
          },
          {
            title: 'Phường Phổ Ninh',
            code: 21460,
            value: 'phuong_pho_ninh',
            division_type: 'phường',
            short_codename: 'pho_ninh',
            parentId: 534,
          },
          {
            title: 'Phường Phổ Minh',
            code: 21463,
            value: 'phuong_pho_minh',
            division_type: 'phường',
            short_codename: 'pho_minh',
            parentId: 534,
          },
          {
            title: 'Phường Phổ Vinh',
            code: 21466,
            value: 'phuong_pho_vinh',
            division_type: 'phường',
            short_codename: 'pho_vinh',
            parentId: 534,
          },
          {
            title: 'Phường Phổ Hòa',
            code: 21469,
            value: 'phuong_pho_hoa',
            division_type: 'phường',
            short_codename: 'pho_hoa',
            parentId: 534,
          },
          {
            title: 'Xã Phổ Cường',
            code: 21472,
            value: 'xa_pho_cuong',
            division_type: 'xã',
            short_codename: 'pho_cuong',
            parentId: 534,
          },
          {
            title: 'Xã Phổ Khánh',
            code: 21475,
            value: 'xa_pho_khanh',
            division_type: 'xã',
            short_codename: 'pho_khanh',
            parentId: 534,
          },
          {
            title: 'Phường Phổ Thạnh',
            code: 21478,
            value: 'phuong_pho_thanh',
            division_type: 'phường',
            short_codename: 'pho_thanh',
            parentId: 534,
          },
          {
            title: 'Xã Phổ Châu',
            code: 21481,
            value: 'xa_pho_chau',
            division_type: 'xã',
            short_codename: 'pho_chau',
            parentId: 534,
          },
        ],
        parentId: 51,
      },
      {
        title: 'Huyện Ba Tơ',
        code: 535,
        value: 'huyen_ba_to',
        division_type: 'huyện',
        short_codename: 'ba_to',
        children: [
          {
            title: 'Thị trấn Ba Tơ',
            code: 21484,
            value: 'thi_tran_ba_to',
            division_type: 'thị trấn',
            short_codename: 'ba_to',
            parentId: 535,
          },
          {
            title: 'Xã Ba Điền',
            code: 21487,
            value: 'xa_ba_dien',
            division_type: 'xã',
            short_codename: 'ba_dien',
            parentId: 535,
          },
          {
            title: 'Xã Ba Vinh',
            code: 21490,
            value: 'xa_ba_vinh',
            division_type: 'xã',
            short_codename: 'ba_vinh',
            parentId: 535,
          },
          {
            title: 'Xã Ba Thành',
            code: 21493,
            value: 'xa_ba_thanh',
            division_type: 'xã',
            short_codename: 'ba_thanh',
            parentId: 535,
          },
          {
            title: 'Xã Ba Động',
            code: 21496,
            value: 'xa_ba_dong',
            division_type: 'xã',
            short_codename: 'ba_dong',
            parentId: 535,
          },
          {
            title: 'Xã Ba Dinh',
            code: 21499,
            value: 'xa_ba_dinh',
            division_type: 'xã',
            short_codename: 'ba_dinh',
            parentId: 535,
          },
          {
            title: 'Xã Ba Giang',
            code: 21500,
            value: 'xa_ba_giang',
            division_type: 'xã',
            short_codename: 'ba_giang',
            parentId: 535,
          },
          {
            title: 'Xã Ba Liên',
            code: 21502,
            value: 'xa_ba_lien',
            division_type: 'xã',
            short_codename: 'ba_lien',
            parentId: 535,
          },
          {
            title: 'Xã Ba Ngạc',
            code: 21505,
            value: 'xa_ba_ngac',
            division_type: 'xã',
            short_codename: 'ba_ngac',
            parentId: 535,
          },
          {
            title: 'Xã Ba Khâm',
            code: 21508,
            value: 'xa_ba_kham',
            division_type: 'xã',
            short_codename: 'ba_kham',
            parentId: 535,
          },
          {
            title: 'Xã Ba Cung',
            code: 21511,
            value: 'xa_ba_cung',
            division_type: 'xã',
            short_codename: 'ba_cung',
            parentId: 535,
          },
          {
            title: 'Xã Ba Tiêu',
            code: 21517,
            value: 'xa_ba_tieu',
            division_type: 'xã',
            short_codename: 'ba_tieu',
            parentId: 535,
          },
          {
            title: 'Xã Ba Trang',
            code: 21520,
            value: 'xa_ba_trang',
            division_type: 'xã',
            short_codename: 'ba_trang',
            parentId: 535,
          },
          {
            title: 'Xã Ba Tô',
            code: 21523,
            value: 'xa_ba_to',
            division_type: 'xã',
            short_codename: 'xa_ba_to',
            parentId: 535,
          },
          {
            title: 'Xã Ba Bích',
            code: 21526,
            value: 'xa_ba_bich',
            division_type: 'xã',
            short_codename: 'ba_bich',
            parentId: 535,
          },
          {
            title: 'Xã Ba Vì',
            code: 21529,
            value: 'xa_ba_vi',
            division_type: 'xã',
            short_codename: 'ba_vi',
            parentId: 535,
          },
          {
            title: 'Xã Ba Lế',
            code: 21532,
            value: 'xa_ba_le',
            division_type: 'xã',
            short_codename: 'ba_le',
            parentId: 535,
          },
          {
            title: 'Xã Ba Nam',
            code: 21535,
            value: 'xa_ba_nam',
            division_type: 'xã',
            short_codename: 'ba_nam',
            parentId: 535,
          },
          {
            title: 'Xã Ba Xa',
            code: 21538,
            value: 'xa_ba_xa',
            division_type: 'xã',
            short_codename: 'ba_xa',
            parentId: 535,
          },
        ],
        parentId: 51,
      },
      {
        title: 'Huyện Lý Sơn',
        code: 536,
        value: 'huyen_ly_son',
        division_type: 'huyện',
        short_codename: 'ly_son',
        children: [],
        parentId: 51,
      },
    ],
  },
  {
    title: 'Tỉnh Bình Định',
    code: 52,
    value: 'tinh_binh_dinh',
    division_type: 'tỉnh',
    phone_code: 256,
    children: [
      {
        title: 'Thành phố Quy Nhơn',
        code: 540,
        value: 'thanh_pho_quy_nhon',
        division_type: 'thành phố',
        short_codename: 'quy_nhon',
        children: [
          {
            title: 'Phường Nhơn Bình',
            code: 21550,
            value: 'phuong_nhon_binh',
            division_type: 'phường',
            short_codename: 'nhon_binh',
            parentId: 540,
          },
          {
            title: 'Phường Nhơn Phú',
            code: 21553,
            value: 'phuong_nhon_phu',
            division_type: 'phường',
            short_codename: 'nhon_phu',
            parentId: 540,
          },
          {
            title: 'Phường Đống Đa',
            code: 21556,
            value: 'phuong_dong_da',
            division_type: 'phường',
            short_codename: 'dong_da',
            parentId: 540,
          },
          {
            title: 'Phường Trần Quang Diệu',
            code: 21559,
            value: 'phuong_tran_quang_dieu',
            division_type: 'phường',
            short_codename: 'tran_quang_dieu',
            parentId: 540,
          },
          {
            title: 'Phường Hải Cảng',
            code: 21562,
            value: 'phuong_hai_cang',
            division_type: 'phường',
            short_codename: 'hai_cang',
            parentId: 540,
          },
          {
            title: 'Phường Quang Trung',
            code: 21565,
            value: 'phuong_quang_trung',
            division_type: 'phường',
            short_codename: 'quang_trung',
            parentId: 540,
          },
          {
            title: 'Phường Thị Nại',
            code: 21568,
            value: 'phuong_thi_nai',
            division_type: 'phường',
            short_codename: 'thi_nai',
            parentId: 540,
          },
          {
            title: 'Phường Lê Hồng Phong',
            code: 21571,
            value: 'phuong_le_hong_phong',
            division_type: 'phường',
            short_codename: 'le_hong_phong',
            parentId: 540,
          },
          {
            title: 'Phường Trần Hưng Đạo',
            code: 21574,
            value: 'phuong_tran_hung_dao',
            division_type: 'phường',
            short_codename: 'tran_hung_dao',
            parentId: 540,
          },
          {
            title: 'Phường Ngô Mây',
            code: 21577,
            value: 'phuong_ngo_may',
            division_type: 'phường',
            short_codename: 'ngo_may',
            parentId: 540,
          },
          {
            title: 'Phường Lý Thường Kiệt',
            code: 21580,
            value: 'phuong_ly_thuong_kiet',
            division_type: 'phường',
            short_codename: 'ly_thuong_kiet',
            parentId: 540,
          },
          {
            title: 'Phường Lê Lợi',
            code: 21583,
            value: 'phuong_le_loi',
            division_type: 'phường',
            short_codename: 'le_loi',
            parentId: 540,
          },
          {
            title: 'Phường Trần Phú',
            code: 21586,
            value: 'phuong_tran_phu',
            division_type: 'phường',
            short_codename: 'tran_phu',
            parentId: 540,
          },
          {
            title: 'Phường Bùi Thị Xuân',
            code: 21589,
            value: 'phuong_bui_thi_xuan',
            division_type: 'phường',
            short_codename: 'bui_thi_xuan',
            parentId: 540,
          },
          {
            title: 'Phường Nguyễn Văn Cừ',
            code: 21592,
            value: 'phuong_nguyen_van_cu',
            division_type: 'phường',
            short_codename: 'nguyen_van_cu',
            parentId: 540,
          },
          {
            title: 'Phường Ghềnh Ráng',
            code: 21595,
            value: 'phuong_ghenh_rang',
            division_type: 'phường',
            short_codename: 'ghenh_rang',
            parentId: 540,
          },
          {
            title: 'Xã Nhơn Lý',
            code: 21598,
            value: 'xa_nhon_ly',
            division_type: 'xã',
            short_codename: 'nhon_ly',
            parentId: 540,
          },
          {
            title: 'Xã Nhơn Hội',
            code: 21601,
            value: 'xa_nhon_hoi',
            division_type: 'xã',
            short_codename: 'nhon_hoi',
            parentId: 540,
          },
          {
            title: 'Xã Nhơn Hải',
            code: 21604,
            value: 'xa_nhon_hai',
            division_type: 'xã',
            short_codename: 'nhon_hai',
            parentId: 540,
          },
          {
            title: 'Xã Nhơn Châu',
            code: 21607,
            value: 'xa_nhon_chau',
            division_type: 'xã',
            short_codename: 'nhon_chau',
            parentId: 540,
          },
          {
            title: 'Xã Phước Mỹ',
            code: 21991,
            value: 'xa_phuoc_my',
            division_type: 'xã',
            short_codename: 'phuoc_my',
            parentId: 540,
          },
        ],
        parentId: 52,
      },
      {
        title: 'Huyện An Lão',
        code: 542,
        value: 'huyen_an_lao',
        division_type: 'huyện',
        short_codename: 'an_lao',
        children: [
          {
            title: 'Thị trấn An Lão',
            code: 21609,
            value: 'thi_tran_an_lao',
            division_type: 'thị trấn',
            short_codename: 'an_lao',
            parentId: 542,
          },
          {
            title: 'Xã An Hưng',
            code: 21610,
            value: 'xa_an_hung',
            division_type: 'xã',
            short_codename: 'an_hung',
            parentId: 542,
          },
          {
            title: 'Xã An Trung',
            code: 21613,
            value: 'xa_an_trung',
            division_type: 'xã',
            short_codename: 'an_trung',
            parentId: 542,
          },
          {
            title: 'Xã An Dũng',
            code: 21616,
            value: 'xa_an_dung',
            division_type: 'xã',
            short_codename: 'an_dung',
            parentId: 542,
          },
          {
            title: 'Xã An Vinh',
            code: 21619,
            value: 'xa_an_vinh',
            division_type: 'xã',
            short_codename: 'an_vinh',
            parentId: 542,
          },
          {
            title: 'Xã An Toàn',
            code: 21622,
            value: 'xa_an_toan',
            division_type: 'xã',
            short_codename: 'an_toan',
            parentId: 542,
          },
          {
            title: 'Xã An Tân',
            code: 21625,
            value: 'xa_an_tan',
            division_type: 'xã',
            short_codename: 'an_tan',
            parentId: 542,
          },
          {
            title: 'Xã An Hòa',
            code: 21628,
            value: 'xa_an_hoa',
            division_type: 'xã',
            short_codename: 'an_hoa',
            parentId: 542,
          },
          {
            title: 'Xã An Quang',
            code: 21631,
            value: 'xa_an_quang',
            division_type: 'xã',
            short_codename: 'an_quang',
            parentId: 542,
          },
          {
            title: 'Xã An Nghĩa',
            code: 21634,
            value: 'xa_an_nghia',
            division_type: 'xã',
            short_codename: 'an_nghia',
            parentId: 542,
          },
        ],
        parentId: 52,
      },
      {
        title: 'Thị xã Hoài Nhơn',
        code: 543,
        value: 'thi_xa_hoai_nhon',
        division_type: 'thị xã',
        short_codename: 'hoai_nhon',
        children: [
          {
            title: 'Phường Tam Quan',
            code: 21637,
            value: 'phuong_tam_quan',
            division_type: 'phường',
            short_codename: 'tam_quan',
            parentId: 543,
          },
          {
            title: 'Phường Bồng Sơn',
            code: 21640,
            value: 'phuong_bong_son',
            division_type: 'phường',
            short_codename: 'bong_son',
            parentId: 543,
          },
          {
            title: 'Xã Hoài Sơn',
            code: 21643,
            value: 'xa_hoai_son',
            division_type: 'xã',
            short_codename: 'hoai_son',
            parentId: 543,
          },
          {
            title: 'Xã Hoài Châu Bắc',
            code: 21646,
            value: 'xa_hoai_chau_bac',
            division_type: 'xã',
            short_codename: 'hoai_chau_bac',
            parentId: 543,
          },
          {
            title: 'Xã Hoài Châu',
            code: 21649,
            value: 'xa_hoai_chau',
            division_type: 'xã',
            short_codename: 'hoai_chau',
            parentId: 543,
          },
          {
            title: 'Xã Hoài Phú',
            code: 21652,
            value: 'xa_hoai_phu',
            division_type: 'xã',
            short_codename: 'hoai_phu',
            parentId: 543,
          },
          {
            title: 'Phường Tam Quan Bắc',
            code: 21655,
            value: 'phuong_tam_quan_bac',
            division_type: 'phường',
            short_codename: 'tam_quan_bac',
            parentId: 543,
          },
          {
            title: 'Phường Tam Quan Nam',
            code: 21658,
            value: 'phuong_tam_quan_nam',
            division_type: 'phường',
            short_codename: 'tam_quan_nam',
            parentId: 543,
          },
          {
            title: 'Phường Hoài Hảo',
            code: 21661,
            value: 'phuong_hoai_hao',
            division_type: 'phường',
            short_codename: 'hoai_hao',
            parentId: 543,
          },
          {
            title: 'Phường Hoài Thanh Tây',
            code: 21664,
            value: 'phuong_hoai_thanh_tay',
            division_type: 'phường',
            short_codename: 'hoai_thanh_tay',
            parentId: 543,
          },
          {
            title: 'Phường Hoài Thanh',
            code: 21667,
            value: 'phuong_hoai_thanh',
            division_type: 'phường',
            short_codename: 'hoai_thanh',
            parentId: 543,
          },
          {
            title: 'Phường Hoài Hương',
            code: 21670,
            value: 'phuong_hoai_huong',
            division_type: 'phường',
            short_codename: 'hoai_huong',
            parentId: 543,
          },
          {
            title: 'Phường Hoài Tân',
            code: 21673,
            value: 'phuong_hoai_tan',
            division_type: 'phường',
            short_codename: 'hoai_tan',
            parentId: 543,
          },
          {
            title: 'Xã Hoài Hải',
            code: 21676,
            value: 'xa_hoai_hai',
            division_type: 'xã',
            short_codename: 'hoai_hai',
            parentId: 543,
          },
          {
            title: 'Phường Hoài Xuân',
            code: 21679,
            value: 'phuong_hoai_xuan',
            division_type: 'phường',
            short_codename: 'hoai_xuan',
            parentId: 543,
          },
          {
            title: 'Xã Hoài Mỹ',
            code: 21682,
            value: 'xa_hoai_my',
            division_type: 'xã',
            short_codename: 'hoai_my',
            parentId: 543,
          },
          {
            title: 'Phường Hoài Đức',
            code: 21685,
            value: 'phuong_hoai_duc',
            division_type: 'phường',
            short_codename: 'hoai_duc',
            parentId: 543,
          },
        ],
        parentId: 52,
      },
      {
        title: 'Huyện Hoài Ân',
        code: 544,
        value: 'huyen_hoai_an',
        division_type: 'huyện',
        short_codename: 'hoai_an',
        children: [
          {
            title: 'Thị trấn Tăng Bạt Hổ',
            code: 21688,
            value: 'thi_tran_tang_bat_ho',
            division_type: 'thị trấn',
            short_codename: 'tang_bat_ho',
            parentId: 544,
          },
          {
            title: 'Xã Ân Hảo Tây',
            code: 21690,
            value: 'xa_an_hao_tay',
            division_type: 'xã',
            short_codename: 'an_hao_tay',
            parentId: 544,
          },
          {
            title: 'Xã Ân Hảo Đông',
            code: 21691,
            value: 'xa_an_hao_dong',
            division_type: 'xã',
            short_codename: 'an_hao_dong',
            parentId: 544,
          },
          {
            title: 'Xã Ân Sơn',
            code: 21694,
            value: 'xa_an_son',
            division_type: 'xã',
            short_codename: 'an_son',
            parentId: 544,
          },
          {
            title: 'Xã Ân Mỹ',
            code: 21697,
            value: 'xa_an_my',
            division_type: 'xã',
            short_codename: 'an_my',
            parentId: 544,
          },
          {
            title: 'Xã Đak Mang',
            code: 21700,
            value: 'xa_dak_mang',
            division_type: 'xã',
            short_codename: 'dak_mang',
            parentId: 544,
          },
          {
            title: 'Xã Ân Tín',
            code: 21703,
            value: 'xa_an_tin',
            division_type: 'xã',
            short_codename: 'an_tin',
            parentId: 544,
          },
          {
            title: 'Xã Ân Thạnh',
            code: 21706,
            value: 'xa_an_thanh',
            division_type: 'xã',
            short_codename: 'an_thanh',
            parentId: 544,
          },
          {
            title: 'Xã Ân Phong',
            code: 21709,
            value: 'xa_an_phong',
            division_type: 'xã',
            short_codename: 'an_phong',
            parentId: 544,
          },
          {
            title: 'Xã Ân Đức',
            code: 21712,
            value: 'xa_an_duc',
            division_type: 'xã',
            short_codename: 'an_duc',
            parentId: 544,
          },
          {
            title: 'Xã Ân Hữu',
            code: 21715,
            value: 'xa_an_huu',
            division_type: 'xã',
            short_codename: 'an_huu',
            parentId: 544,
          },
          {
            title: 'Xã Bok Tới',
            code: 21718,
            value: 'xa_bok_toi',
            division_type: 'xã',
            short_codename: 'bok_toi',
            parentId: 544,
          },
          {
            title: 'Xã Ân Tường Tây',
            code: 21721,
            value: 'xa_an_tuong_tay',
            division_type: 'xã',
            short_codename: 'an_tuong_tay',
            parentId: 544,
          },
          {
            title: 'Xã Ân Tường Đông',
            code: 21724,
            value: 'xa_an_tuong_dong',
            division_type: 'xã',
            short_codename: 'an_tuong_dong',
            parentId: 544,
          },
          {
            title: 'Xã Ân Nghĩa',
            code: 21727,
            value: 'xa_an_nghia',
            division_type: 'xã',
            short_codename: 'an_nghia',
            parentId: 544,
          },
        ],
        parentId: 52,
      },
      {
        title: 'Huyện Phù Mỹ',
        code: 545,
        value: 'huyen_phu_my',
        division_type: 'huyện',
        short_codename: 'phu_my',
        children: [
          {
            title: 'Thị trấn Phù Mỹ',
            code: 21730,
            value: 'thi_tran_phu_my',
            division_type: 'thị trấn',
            short_codename: 'phu_my',
            parentId: 545,
          },
          {
            title: 'Thị trấn Bình Dương',
            code: 21733,
            value: 'thi_tran_binh_duong',
            division_type: 'thị trấn',
            short_codename: 'binh_duong',
            parentId: 545,
          },
          {
            title: 'Xã Mỹ Đức',
            code: 21736,
            value: 'xa_my_duc',
            division_type: 'xã',
            short_codename: 'my_duc',
            parentId: 545,
          },
          {
            title: 'Xã Mỹ Châu',
            code: 21739,
            value: 'xa_my_chau',
            division_type: 'xã',
            short_codename: 'my_chau',
            parentId: 545,
          },
          {
            title: 'Xã Mỹ Thắng',
            code: 21742,
            value: 'xa_my_thang',
            division_type: 'xã',
            short_codename: 'my_thang',
            parentId: 545,
          },
          {
            title: 'Xã Mỹ Lộc',
            code: 21745,
            value: 'xa_my_loc',
            division_type: 'xã',
            short_codename: 'my_loc',
            parentId: 545,
          },
          {
            title: 'Xã Mỹ Lợi',
            code: 21748,
            value: 'xa_my_loi',
            division_type: 'xã',
            short_codename: 'my_loi',
            parentId: 545,
          },
          {
            title: 'Xã Mỹ An',
            code: 21751,
            value: 'xa_my_an',
            division_type: 'xã',
            short_codename: 'my_an',
            parentId: 545,
          },
          {
            title: 'Xã Mỹ Phong',
            code: 21754,
            value: 'xa_my_phong',
            division_type: 'xã',
            short_codename: 'my_phong',
            parentId: 545,
          },
          {
            title: 'Xã Mỹ Trinh',
            code: 21757,
            value: 'xa_my_trinh',
            division_type: 'xã',
            short_codename: 'my_trinh',
            parentId: 545,
          },
          {
            title: 'Xã Mỹ Thọ',
            code: 21760,
            value: 'xa_my_tho',
            division_type: 'xã',
            short_codename: 'my_tho',
            parentId: 545,
          },
          {
            title: 'Xã Mỹ Hòa',
            code: 21763,
            value: 'xa_my_hoa',
            division_type: 'xã',
            short_codename: 'my_hoa',
            parentId: 545,
          },
          {
            title: 'Xã Mỹ Thành',
            code: 21766,
            value: 'xa_my_thanh',
            division_type: 'xã',
            short_codename: 'my_thanh',
            parentId: 545,
          },
          {
            title: 'Xã Mỹ Chánh',
            code: 21769,
            value: 'xa_my_chanh',
            division_type: 'xã',
            short_codename: 'my_chanh',
            parentId: 545,
          },
          {
            title: 'Xã Mỹ Quang',
            code: 21772,
            value: 'xa_my_quang',
            division_type: 'xã',
            short_codename: 'my_quang',
            parentId: 545,
          },
          {
            title: 'Xã Mỹ Hiệp',
            code: 21775,
            value: 'xa_my_hiep',
            division_type: 'xã',
            short_codename: 'my_hiep',
            parentId: 545,
          },
          {
            title: 'Xã Mỹ Tài',
            code: 21778,
            value: 'xa_my_tai',
            division_type: 'xã',
            short_codename: 'my_tai',
            parentId: 545,
          },
          {
            title: 'Xã Mỹ Cát',
            code: 21781,
            value: 'xa_my_cat',
            division_type: 'xã',
            short_codename: 'my_cat',
            parentId: 545,
          },
          {
            title: 'Xã Mỹ Chánh Tây',
            code: 21784,
            value: 'xa_my_chanh_tay',
            division_type: 'xã',
            short_codename: 'my_chanh_tay',
            parentId: 545,
          },
        ],
        parentId: 52,
      },
      {
        title: 'Huyện Vĩnh Thạnh',
        code: 546,
        value: 'huyen_vinh_thanh',
        division_type: 'huyện',
        short_codename: 'vinh_thanh',
        children: [
          {
            title: 'Thị trấn Vĩnh Thạnh',
            code: 21786,
            value: 'thi_tran_vinh_thanh',
            division_type: 'thị trấn',
            short_codename: 'vinh_thanh',
            parentId: 546,
          },
          {
            title: 'Xã Vĩnh Sơn',
            code: 21787,
            value: 'xa_vinh_son',
            division_type: 'xã',
            short_codename: 'vinh_son',
            parentId: 546,
          },
          {
            title: 'Xã Vĩnh Kim',
            code: 21790,
            value: 'xa_vinh_kim',
            division_type: 'xã',
            short_codename: 'vinh_kim',
            parentId: 546,
          },
          {
            title: 'Xã Vĩnh Hiệp',
            code: 21796,
            value: 'xa_vinh_hiep',
            division_type: 'xã',
            short_codename: 'vinh_hiep',
            parentId: 546,
          },
          {
            title: 'Xã Vĩnh Hảo',
            code: 21799,
            value: 'xa_vinh_hao',
            division_type: 'xã',
            short_codename: 'vinh_hao',
            parentId: 546,
          },
          {
            title: 'Xã Vĩnh Hòa',
            code: 21801,
            value: 'xa_vinh_hoa',
            division_type: 'xã',
            short_codename: 'vinh_hoa',
            parentId: 546,
          },
          {
            title: 'Xã Vĩnh Thịnh',
            code: 21802,
            value: 'xa_vinh_thinh',
            division_type: 'xã',
            short_codename: 'vinh_thinh',
            parentId: 546,
          },
          {
            title: 'Xã Vĩnh Thuận',
            code: 21804,
            value: 'xa_vinh_thuan',
            division_type: 'xã',
            short_codename: 'vinh_thuan',
            parentId: 546,
          },
          {
            title: 'Xã Vĩnh Quang',
            code: 21805,
            value: 'xa_vinh_quang',
            division_type: 'xã',
            short_codename: 'vinh_quang',
            parentId: 546,
          },
        ],
        parentId: 52,
      },
      {
        title: 'Huyện Tây Sơn',
        code: 547,
        value: 'huyen_tay_son',
        division_type: 'huyện',
        short_codename: 'tay_son',
        children: [
          {
            title: 'Thị trấn Phú Phong',
            code: 21808,
            value: 'thi_tran_phu_phong',
            division_type: 'thị trấn',
            short_codename: 'phu_phong',
            parentId: 547,
          },
          {
            title: 'Xã Bình Tân',
            code: 21811,
            value: 'xa_binh_tan',
            division_type: 'xã',
            short_codename: 'binh_tan',
            parentId: 547,
          },
          {
            title: 'Xã Tây Thuận',
            code: 21814,
            value: 'xa_tay_thuan',
            division_type: 'xã',
            short_codename: 'tay_thuan',
            parentId: 547,
          },
          {
            title: 'Xã Bình Thuận',
            code: 21817,
            value: 'xa_binh_thuan',
            division_type: 'xã',
            short_codename: 'binh_thuan',
            parentId: 547,
          },
          {
            title: 'Xã Tây Giang',
            code: 21820,
            value: 'xa_tay_giang',
            division_type: 'xã',
            short_codename: 'tay_giang',
            parentId: 547,
          },
          {
            title: 'Xã Bình Thành',
            code: 21823,
            value: 'xa_binh_thanh',
            division_type: 'xã',
            short_codename: 'binh_thanh',
            parentId: 547,
          },
          {
            title: 'Xã Tây An',
            code: 21826,
            value: 'xa_tay_an',
            division_type: 'xã',
            short_codename: 'tay_an',
            parentId: 547,
          },
          {
            title: 'Xã Bình Hòa',
            code: 21829,
            value: 'xa_binh_hoa',
            division_type: 'xã',
            short_codename: 'binh_hoa',
            parentId: 547,
          },
          {
            title: 'Xã Tây Bình',
            code: 21832,
            value: 'xa_tay_binh',
            division_type: 'xã',
            short_codename: 'tay_binh',
            parentId: 547,
          },
          {
            title: 'Xã Bình Tường',
            code: 21835,
            value: 'xa_binh_tuong',
            division_type: 'xã',
            short_codename: 'binh_tuong',
            parentId: 547,
          },
          {
            title: 'Xã Tây Vinh',
            code: 21838,
            value: 'xa_tay_vinh',
            division_type: 'xã',
            short_codename: 'tay_vinh',
            parentId: 547,
          },
          {
            title: 'Xã Vĩnh An',
            code: 21841,
            value: 'xa_vinh_an',
            division_type: 'xã',
            short_codename: 'vinh_an',
            parentId: 547,
          },
          {
            title: 'Xã Tây Xuân',
            code: 21844,
            value: 'xa_tay_xuan',
            division_type: 'xã',
            short_codename: 'tay_xuan',
            parentId: 547,
          },
          {
            title: 'Xã Bình Nghi',
            code: 21847,
            value: 'xa_binh_nghi',
            division_type: 'xã',
            short_codename: 'binh_nghi',
            parentId: 547,
          },
          {
            title: 'Xã Tây Phú',
            code: 21850,
            value: 'xa_tay_phu',
            division_type: 'xã',
            short_codename: 'tay_phu',
            parentId: 547,
          },
        ],
        parentId: 52,
      },
      {
        title: 'Huyện Phù Cát',
        code: 548,
        value: 'huyen_phu_cat',
        division_type: 'huyện',
        short_codename: 'phu_cat',
        children: [
          {
            title: 'Thị trấn Ngô Mây',
            code: 21853,
            value: 'thi_tran_ngo_may',
            division_type: 'thị trấn',
            short_codename: 'ngo_may',
            parentId: 548,
          },
          {
            title: 'Xã Cát Sơn',
            code: 21856,
            value: 'xa_cat_son',
            division_type: 'xã',
            short_codename: 'cat_son',
            parentId: 548,
          },
          {
            title: 'Xã Cát Minh',
            code: 21859,
            value: 'xa_cat_minh',
            division_type: 'xã',
            short_codename: 'cat_minh',
            parentId: 548,
          },
          {
            title: 'Xã Cát Khánh',
            code: 21862,
            value: 'xa_cat_khanh',
            division_type: 'xã',
            short_codename: 'cat_khanh',
            parentId: 548,
          },
          {
            title: 'Xã Cát Tài',
            code: 21865,
            value: 'xa_cat_tai',
            division_type: 'xã',
            short_codename: 'cat_tai',
            parentId: 548,
          },
          {
            title: 'Xã Cát Lâm',
            code: 21868,
            value: 'xa_cat_lam',
            division_type: 'xã',
            short_codename: 'cat_lam',
            parentId: 548,
          },
          {
            title: 'Xã Cát Hanh',
            code: 21871,
            value: 'xa_cat_hanh',
            division_type: 'xã',
            short_codename: 'cat_hanh',
            parentId: 548,
          },
          {
            title: 'Xã Cát Thành',
            code: 21874,
            value: 'xa_cat_thanh',
            division_type: 'xã',
            short_codename: 'cat_thanh',
            parentId: 548,
          },
          {
            title: 'Xã Cát Trinh',
            code: 21877,
            value: 'xa_cat_trinh',
            division_type: 'xã',
            short_codename: 'cat_trinh',
            parentId: 548,
          },
          {
            title: 'Xã Cát Hải',
            code: 21880,
            value: 'xa_cat_hai',
            division_type: 'xã',
            short_codename: 'cat_hai',
            parentId: 548,
          },
          {
            title: 'Xã Cát Hiệp',
            code: 21883,
            value: 'xa_cat_hiep',
            division_type: 'xã',
            short_codename: 'cat_hiep',
            parentId: 548,
          },
          {
            title: 'Xã Cát Nhơn',
            code: 21886,
            value: 'xa_cat_nhon',
            division_type: 'xã',
            short_codename: 'cat_nhon',
            parentId: 548,
          },
          {
            title: 'Xã Cát Hưng',
            code: 21889,
            value: 'xa_cat_hung',
            division_type: 'xã',
            short_codename: 'cat_hung',
            parentId: 548,
          },
          {
            title: 'Xã Cát Tường',
            code: 21892,
            value: 'xa_cat_tuong',
            division_type: 'xã',
            short_codename: 'cat_tuong',
            parentId: 548,
          },
          {
            title: 'Xã Cát Tân',
            code: 21895,
            value: 'xa_cat_tan',
            division_type: 'xã',
            short_codename: 'cat_tan',
            parentId: 548,
          },
          {
            title: 'Thị trấn Cát Tiến',
            code: 21898,
            value: 'thi_tran_cat_tien',
            division_type: 'thị trấn',
            short_codename: 'cat_tien',
            parentId: 548,
          },
          {
            title: 'Xã Cát Thắng',
            code: 21901,
            value: 'xa_cat_thang',
            division_type: 'xã',
            short_codename: 'cat_thang',
            parentId: 548,
          },
          {
            title: 'Xã Cát Chánh',
            code: 21904,
            value: 'xa_cat_chanh',
            division_type: 'xã',
            short_codename: 'cat_chanh',
            parentId: 548,
          },
        ],
        parentId: 52,
      },
      {
        title: 'Thị xã An Nhơn',
        code: 549,
        value: 'thi_xa_an_nhon',
        division_type: 'thị xã',
        short_codename: 'an_nhon',
        children: [
          {
            title: 'Phường Bình Định',
            code: 21907,
            value: 'phuong_binh_dinh',
            division_type: 'phường',
            short_codename: 'binh_dinh',
            parentId: 549,
          },
          {
            title: 'Phường Đập Đá',
            code: 21910,
            value: 'phuong_dap_da',
            division_type: 'phường',
            short_codename: 'dap_da',
            parentId: 549,
          },
          {
            title: 'Xã Nhơn Mỹ',
            code: 21913,
            value: 'xa_nhon_my',
            division_type: 'xã',
            short_codename: 'nhon_my',
            parentId: 549,
          },
          {
            title: 'Phường Nhơn Thành',
            code: 21916,
            value: 'phuong_nhon_thanh',
            division_type: 'phường',
            short_codename: 'nhon_thanh',
            parentId: 549,
          },
          {
            title: 'Xã Nhơn Hạnh',
            code: 21919,
            value: 'xa_nhon_hanh',
            division_type: 'xã',
            short_codename: 'nhon_hanh',
            parentId: 549,
          },
          {
            title: 'Xã Nhơn Hậu',
            code: 21922,
            value: 'xa_nhon_hau',
            division_type: 'xã',
            short_codename: 'nhon_hau',
            parentId: 549,
          },
          {
            title: 'Xã Nhơn Phong',
            code: 21925,
            value: 'xa_nhon_phong',
            division_type: 'xã',
            short_codename: 'nhon_phong',
            parentId: 549,
          },
          {
            title: 'Xã Nhơn An',
            code: 21928,
            value: 'xa_nhon_an',
            division_type: 'xã',
            short_codename: 'nhon_an',
            parentId: 549,
          },
          {
            title: 'Xã Nhơn Phúc',
            code: 21931,
            value: 'xa_nhon_phuc',
            division_type: 'xã',
            short_codename: 'nhon_phuc',
            parentId: 549,
          },
          {
            title: 'Phường Nhơn Hưng',
            code: 21934,
            value: 'phuong_nhon_hung',
            division_type: 'phường',
            short_codename: 'nhon_hung',
            parentId: 549,
          },
          {
            title: 'Xã Nhơn Khánh',
            code: 21937,
            value: 'xa_nhon_khanh',
            division_type: 'xã',
            short_codename: 'nhon_khanh',
            parentId: 549,
          },
          {
            title: 'Xã Nhơn Lộc',
            code: 21940,
            value: 'xa_nhon_loc',
            division_type: 'xã',
            short_codename: 'nhon_loc',
            parentId: 549,
          },
          {
            title: 'Phường Nhơn Hoà',
            code: 21943,
            value: 'phuong_nhon_hoa',
            division_type: 'phường',
            short_codename: 'nhon_hoa',
            parentId: 549,
          },
          {
            title: 'Xã Nhơn Tân',
            code: 21946,
            value: 'xa_nhon_tan',
            division_type: 'xã',
            short_codename: 'nhon_tan',
            parentId: 549,
          },
          {
            title: 'Xã Nhơn Thọ',
            code: 21949,
            value: 'xa_nhon_tho',
            division_type: 'xã',
            short_codename: 'nhon_tho',
            parentId: 549,
          },
        ],
        parentId: 52,
      },
      {
        title: 'Huyện Tuy Phước',
        code: 550,
        value: 'huyen_tuy_phuoc',
        division_type: 'huyện',
        short_codename: 'tuy_phuoc',
        children: [
          {
            title: 'Thị trấn Tuy Phước',
            code: 21952,
            value: 'thi_tran_tuy_phuoc',
            division_type: 'thị trấn',
            short_codename: 'tuy_phuoc',
            parentId: 550,
          },
          {
            title: 'Thị trấn Diêu Trì',
            code: 21955,
            value: 'thi_tran_dieu_tri',
            division_type: 'thị trấn',
            short_codename: 'dieu_tri',
            parentId: 550,
          },
          {
            title: 'Xã Phước Thắng',
            code: 21958,
            value: 'xa_phuoc_thang',
            division_type: 'xã',
            short_codename: 'phuoc_thang',
            parentId: 550,
          },
          {
            title: 'Xã Phước Hưng',
            code: 21961,
            value: 'xa_phuoc_hung',
            division_type: 'xã',
            short_codename: 'phuoc_hung',
            parentId: 550,
          },
          {
            title: 'Xã Phước Quang',
            code: 21964,
            value: 'xa_phuoc_quang',
            division_type: 'xã',
            short_codename: 'phuoc_quang',
            parentId: 550,
          },
          {
            title: 'Xã Phước Hòa',
            code: 21967,
            value: 'xa_phuoc_hoa',
            division_type: 'xã',
            short_codename: 'phuoc_hoa',
            parentId: 550,
          },
          {
            title: 'Xã Phước Sơn',
            code: 21970,
            value: 'xa_phuoc_son',
            division_type: 'xã',
            short_codename: 'phuoc_son',
            parentId: 550,
          },
          {
            title: 'Xã Phước Hiệp',
            code: 21973,
            value: 'xa_phuoc_hiep',
            division_type: 'xã',
            short_codename: 'phuoc_hiep',
            parentId: 550,
          },
          {
            title: 'Xã Phước Lộc',
            code: 21976,
            value: 'xa_phuoc_loc',
            division_type: 'xã',
            short_codename: 'phuoc_loc',
            parentId: 550,
          },
          {
            title: 'Xã Phước Nghĩa',
            code: 21979,
            value: 'xa_phuoc_nghia',
            division_type: 'xã',
            short_codename: 'phuoc_nghia',
            parentId: 550,
          },
          {
            title: 'Xã Phước Thuận',
            code: 21982,
            value: 'xa_phuoc_thuan',
            division_type: 'xã',
            short_codename: 'phuoc_thuan',
            parentId: 550,
          },
          {
            title: 'Xã Phước An',
            code: 21985,
            value: 'xa_phuoc_an',
            division_type: 'xã',
            short_codename: 'phuoc_an',
            parentId: 550,
          },
          {
            title: 'Xã Phước Thành',
            code: 21988,
            value: 'xa_phuoc_thanh',
            division_type: 'xã',
            short_codename: 'phuoc_thanh',
            parentId: 550,
          },
        ],
        parentId: 52,
      },
      {
        title: 'Huyện Vân Canh',
        code: 551,
        value: 'huyen_van_canh',
        division_type: 'huyện',
        short_codename: 'van_canh',
        children: [
          {
            title: 'Thị trấn Vân Canh',
            code: 21994,
            value: 'thi_tran_van_canh',
            division_type: 'thị trấn',
            short_codename: 'van_canh',
            parentId: 551,
          },
          {
            title: 'Xã Canh Liên',
            code: 21997,
            value: 'xa_canh_lien',
            division_type: 'xã',
            short_codename: 'canh_lien',
            parentId: 551,
          },
          {
            title: 'Xã Canh Hiệp',
            code: 22000,
            value: 'xa_canh_hiep',
            division_type: 'xã',
            short_codename: 'canh_hiep',
            parentId: 551,
          },
          {
            title: 'Xã Canh Vinh',
            code: 22003,
            value: 'xa_canh_vinh',
            division_type: 'xã',
            short_codename: 'canh_vinh',
            parentId: 551,
          },
          {
            title: 'Xã Canh Hiển',
            code: 22006,
            value: 'xa_canh_hien',
            division_type: 'xã',
            short_codename: 'canh_hien',
            parentId: 551,
          },
          {
            title: 'Xã Canh Thuận',
            code: 22009,
            value: 'xa_canh_thuan',
            division_type: 'xã',
            short_codename: 'canh_thuan',
            parentId: 551,
          },
          {
            title: 'Xã Canh Hòa',
            code: 22012,
            value: 'xa_canh_hoa',
            division_type: 'xã',
            short_codename: 'canh_hoa',
            parentId: 551,
          },
        ],
        parentId: 52,
      },
    ],
  },
  {
    title: 'Tỉnh Phú Yên',
    code: 54,
    value: 'tinh_phu_yen',
    division_type: 'tỉnh',
    phone_code: 257,
    children: [
      {
        title: 'Thành phố Tuy Hoà',
        code: 555,
        value: 'thanh_pho_tuy_hoa',
        division_type: 'thành phố',
        short_codename: 'tuy_hoa',
        children: [
          {
            title: 'Phường 1',
            code: 22015,
            value: 'phuong_1',
            division_type: 'phường',
            short_codename: 'phuong_1',
            parentId: 555,
          },
          {
            title: 'Phường 8',
            code: 22018,
            value: 'phuong_8',
            division_type: 'phường',
            short_codename: 'phuong_8',
            parentId: 555,
          },
          {
            title: 'Phường 2',
            code: 22021,
            value: 'phuong_2',
            division_type: 'phường',
            short_codename: 'phuong_2',
            parentId: 555,
          },
          {
            title: 'Phường 9',
            code: 22024,
            value: 'phuong_9',
            division_type: 'phường',
            short_codename: 'phuong_9',
            parentId: 555,
          },
          {
            title: 'Phường 3',
            code: 22027,
            value: 'phuong_3',
            division_type: 'phường',
            short_codename: 'phuong_3',
            parentId: 555,
          },
          {
            title: 'Phường 4',
            code: 22030,
            value: 'phuong_4',
            division_type: 'phường',
            short_codename: 'phuong_4',
            parentId: 555,
          },
          {
            title: 'Phường 5',
            code: 22033,
            value: 'phuong_5',
            division_type: 'phường',
            short_codename: 'phuong_5',
            parentId: 555,
          },
          {
            title: 'Phường 7',
            code: 22036,
            value: 'phuong_7',
            division_type: 'phường',
            short_codename: 'phuong_7',
            parentId: 555,
          },
          {
            title: 'Phường 6',
            code: 22039,
            value: 'phuong_6',
            division_type: 'phường',
            short_codename: 'phuong_6',
            parentId: 555,
          },
          {
            title: 'Phường Phú Thạnh',
            code: 22040,
            value: 'phuong_phu_thanh',
            division_type: 'phường',
            short_codename: 'phu_thanh',
            parentId: 555,
          },
          {
            title: 'Phường Phú Đông',
            code: 22041,
            value: 'phuong_phu_dong',
            division_type: 'phường',
            short_codename: 'phu_dong',
            parentId: 555,
          },
          {
            title: 'Xã Hòa Kiến',
            code: 22042,
            value: 'xa_hoa_kien',
            division_type: 'xã',
            short_codename: 'hoa_kien',
            parentId: 555,
          },
          {
            title: 'Xã Bình Kiến',
            code: 22045,
            value: 'xa_binh_kien',
            division_type: 'xã',
            short_codename: 'binh_kien',
            parentId: 555,
          },
          {
            title: 'Xã Bình Ngọc',
            code: 22048,
            value: 'xa_binh_ngoc',
            division_type: 'xã',
            short_codename: 'binh_ngoc',
            parentId: 555,
          },
          {
            title: 'Xã An Phú',
            code: 22162,
            value: 'xa_an_phu',
            division_type: 'xã',
            short_codename: 'an_phu',
            parentId: 555,
          },
          {
            title: 'Phường Phú Lâm',
            code: 22240,
            value: 'phuong_phu_lam',
            division_type: 'phường',
            short_codename: 'phu_lam',
            parentId: 555,
          },
        ],
        parentId: 54,
      },
      {
        title: 'Thị xã Sông Cầu',
        code: 557,
        value: 'thi_xa_song_cau',
        division_type: 'thị xã',
        short_codename: 'song_cau',
        children: [
          {
            title: 'Phường Xuân Phú',
            code: 22051,
            value: 'phuong_xuan_phu',
            division_type: 'phường',
            short_codename: 'xuan_phu',
            parentId: 557,
          },
          {
            title: 'Xã Xuân Lâm',
            code: 22052,
            value: 'xa_xuan_lam',
            division_type: 'xã',
            short_codename: 'xuan_lam',
            parentId: 557,
          },
          {
            title: 'Phường Xuân Thành',
            code: 22053,
            value: 'phuong_xuan_thanh',
            division_type: 'phường',
            short_codename: 'xuan_thanh',
            parentId: 557,
          },
          {
            title: 'Xã Xuân Hải',
            code: 22054,
            value: 'xa_xuan_hai',
            division_type: 'xã',
            short_codename: 'xuan_hai',
            parentId: 557,
          },
          {
            title: 'Xã Xuân Lộc',
            code: 22057,
            value: 'xa_xuan_loc',
            division_type: 'xã',
            short_codename: 'xuan_loc',
            parentId: 557,
          },
          {
            title: 'Xã Xuân Bình',
            code: 22060,
            value: 'xa_xuan_binh',
            division_type: 'xã',
            short_codename: 'xuan_binh',
            parentId: 557,
          },
          {
            title: 'Xã Xuân Cảnh',
            code: 22066,
            value: 'xa_xuan_canh',
            division_type: 'xã',
            short_codename: 'xuan_canh',
            parentId: 557,
          },
          {
            title: 'Xã Xuân Thịnh',
            code: 22069,
            value: 'xa_xuan_thinh',
            division_type: 'xã',
            short_codename: 'xuan_thinh',
            parentId: 557,
          },
          {
            title: 'Xã Xuân Phương',
            code: 22072,
            value: 'xa_xuan_phuong',
            division_type: 'xã',
            short_codename: 'xuan_phuong',
            parentId: 557,
          },
          {
            title: 'Phường Xuân Yên',
            code: 22073,
            value: 'phuong_xuan_yen',
            division_type: 'phường',
            short_codename: 'xuan_yen',
            parentId: 557,
          },
          {
            title: 'Xã Xuân Thọ 1',
            code: 22075,
            value: 'xa_xuan_tho_1',
            division_type: 'xã',
            short_codename: 'xuan_tho_1',
            parentId: 557,
          },
          {
            title: 'Phường Xuân Đài',
            code: 22076,
            value: 'phuong_xuan_dai',
            division_type: 'phường',
            short_codename: 'xuan_dai',
            parentId: 557,
          },
          {
            title: 'Xã Xuân Thọ 2',
            code: 22078,
            value: 'xa_xuan_tho_2',
            division_type: 'xã',
            short_codename: 'xuan_tho_2',
            parentId: 557,
          },
        ],
        parentId: 54,
      },
      {
        title: 'Huyện Đồng Xuân',
        code: 558,
        value: 'huyen_dong_xuan',
        division_type: 'huyện',
        short_codename: 'dong_xuan',
        children: [
          {
            title: 'Thị trấn La Hai',
            code: 22081,
            value: 'thi_tran_la_hai',
            division_type: 'thị trấn',
            short_codename: 'la_hai',
            parentId: 558,
          },
          {
            title: 'Xã Đa Lộc',
            code: 22084,
            value: 'xa_da_loc',
            division_type: 'xã',
            short_codename: 'da_loc',
            parentId: 558,
          },
          {
            title: 'Xã Phú Mỡ',
            code: 22087,
            value: 'xa_phu_mo',
            division_type: 'xã',
            short_codename: 'phu_mo',
            parentId: 558,
          },
          {
            title: 'Xã Xuân Lãnh',
            code: 22090,
            value: 'xa_xuan_lanh',
            division_type: 'xã',
            short_codename: 'xuan_lanh',
            parentId: 558,
          },
          {
            title: 'Xã Xuân Long',
            code: 22093,
            value: 'xa_xuan_long',
            division_type: 'xã',
            short_codename: 'xuan_long',
            parentId: 558,
          },
          {
            title: 'Xã Xuân Quang 1',
            code: 22096,
            value: 'xa_xuan_quang_1',
            division_type: 'xã',
            short_codename: 'xuan_quang_1',
            parentId: 558,
          },
          {
            title: 'Xã Xuân Sơn Bắc',
            code: 22099,
            value: 'xa_xuan_son_bac',
            division_type: 'xã',
            short_codename: 'xuan_son_bac',
            parentId: 558,
          },
          {
            title: 'Xã Xuân Quang 2',
            code: 22102,
            value: 'xa_xuan_quang_2',
            division_type: 'xã',
            short_codename: 'xuan_quang_2',
            parentId: 558,
          },
          {
            title: 'Xã Xuân Sơn Nam',
            code: 22105,
            value: 'xa_xuan_son_nam',
            division_type: 'xã',
            short_codename: 'xuan_son_nam',
            parentId: 558,
          },
          {
            title: 'Xã Xuân Quang 3',
            code: 22108,
            value: 'xa_xuan_quang_3',
            division_type: 'xã',
            short_codename: 'xuan_quang_3',
            parentId: 558,
          },
          {
            title: 'Xã Xuân Phước',
            code: 22111,
            value: 'xa_xuan_phuoc',
            division_type: 'xã',
            short_codename: 'xuan_phuoc',
            parentId: 558,
          },
        ],
        parentId: 54,
      },
      {
        title: 'Huyện Tuy An',
        code: 559,
        value: 'huyen_tuy_an',
        division_type: 'huyện',
        short_codename: 'tuy_an',
        children: [
          {
            title: 'Thị trấn Chí Thạnh',
            code: 22114,
            value: 'thi_tran_chi_thanh',
            division_type: 'thị trấn',
            short_codename: 'chi_thanh',
            parentId: 559,
          },
          {
            title: 'Xã An Dân',
            code: 22117,
            value: 'xa_an_dan',
            division_type: 'xã',
            short_codename: 'an_dan',
            parentId: 559,
          },
          {
            title: 'Xã An Ninh Tây',
            code: 22120,
            value: 'xa_an_ninh_tay',
            division_type: 'xã',
            short_codename: 'an_ninh_tay',
            parentId: 559,
          },
          {
            title: 'Xã An Ninh Đông',
            code: 22123,
            value: 'xa_an_ninh_dong',
            division_type: 'xã',
            short_codename: 'an_ninh_dong',
            parentId: 559,
          },
          {
            title: 'Xã An Thạch',
            code: 22126,
            value: 'xa_an_thach',
            division_type: 'xã',
            short_codename: 'an_thach',
            parentId: 559,
          },
          {
            title: 'Xã An Định',
            code: 22129,
            value: 'xa_an_dinh',
            division_type: 'xã',
            short_codename: 'an_dinh',
            parentId: 559,
          },
          {
            title: 'Xã An Nghiệp',
            code: 22132,
            value: 'xa_an_nghiep',
            division_type: 'xã',
            short_codename: 'an_nghiep',
            parentId: 559,
          },
          {
            title: 'Xã An Cư',
            code: 22138,
            value: 'xa_an_cu',
            division_type: 'xã',
            short_codename: 'an_cu',
            parentId: 559,
          },
          {
            title: 'Xã An Xuân',
            code: 22141,
            value: 'xa_an_xuan',
            division_type: 'xã',
            short_codename: 'an_xuan',
            parentId: 559,
          },
          {
            title: 'Xã An Lĩnh',
            code: 22144,
            value: 'xa_an_linh',
            division_type: 'xã',
            short_codename: 'an_linh',
            parentId: 559,
          },
          {
            title: 'Xã An Hòa Hải',
            code: 22147,
            value: 'xa_an_hoa_hai',
            division_type: 'xã',
            short_codename: 'an_hoa_hai',
            parentId: 559,
          },
          {
            title: 'Xã An Hiệp',
            code: 22150,
            value: 'xa_an_hiep',
            division_type: 'xã',
            short_codename: 'an_hiep',
            parentId: 559,
          },
          {
            title: 'Xã An Mỹ',
            code: 22153,
            value: 'xa_an_my',
            division_type: 'xã',
            short_codename: 'an_my',
            parentId: 559,
          },
          {
            title: 'Xã An Chấn',
            code: 22156,
            value: 'xa_an_chan',
            division_type: 'xã',
            short_codename: 'an_chan',
            parentId: 559,
          },
          {
            title: 'Xã An Thọ',
            code: 22159,
            value: 'xa_an_tho',
            division_type: 'xã',
            short_codename: 'an_tho',
            parentId: 559,
          },
        ],
        parentId: 54,
      },
      {
        title: 'Huyện Sơn Hòa',
        code: 560,
        value: 'huyen_son_hoa',
        division_type: 'huyện',
        short_codename: 'son_hoa',
        children: [
          {
            title: 'Thị trấn Củng Sơn',
            code: 22165,
            value: 'thi_tran_cung_son',
            division_type: 'thị trấn',
            short_codename: 'cung_son',
            parentId: 560,
          },
          {
            title: 'Xã Phước Tân',
            code: 22168,
            value: 'xa_phuoc_tan',
            division_type: 'xã',
            short_codename: 'phuoc_tan',
            parentId: 560,
          },
          {
            title: 'Xã Sơn Hội',
            code: 22171,
            value: 'xa_son_hoi',
            division_type: 'xã',
            short_codename: 'son_hoi',
            parentId: 560,
          },
          {
            title: 'Xã Sơn Định',
            code: 22174,
            value: 'xa_son_dinh',
            division_type: 'xã',
            short_codename: 'son_dinh',
            parentId: 560,
          },
          {
            title: 'Xã Sơn Long',
            code: 22177,
            value: 'xa_son_long',
            division_type: 'xã',
            short_codename: 'son_long',
            parentId: 560,
          },
          {
            title: 'Xã Cà Lúi',
            code: 22180,
            value: 'xa_ca_lui',
            division_type: 'xã',
            short_codename: 'ca_lui',
            parentId: 560,
          },
          {
            title: 'Xã Sơn Phước',
            code: 22183,
            value: 'xa_son_phuoc',
            division_type: 'xã',
            short_codename: 'son_phuoc',
            parentId: 560,
          },
          {
            title: 'Xã Sơn Xuân',
            code: 22186,
            value: 'xa_son_xuan',
            division_type: 'xã',
            short_codename: 'son_xuan',
            parentId: 560,
          },
          {
            title: 'Xã Sơn Nguyên',
            code: 22189,
            value: 'xa_son_nguyen',
            division_type: 'xã',
            short_codename: 'son_nguyen',
            parentId: 560,
          },
          {
            title: 'Xã Eachà Rang',
            code: 22192,
            value: 'xa_eacha_rang',
            division_type: 'xã',
            short_codename: 'eacha_rang',
            parentId: 560,
          },
          {
            title: 'Xã Krông Pa',
            code: 22195,
            value: 'xa_krong_pa',
            division_type: 'xã',
            short_codename: 'krong_pa',
            parentId: 560,
          },
          {
            title: 'Xã Suối Bạc',
            code: 22198,
            value: 'xa_suoi_bac',
            division_type: 'xã',
            short_codename: 'suoi_bac',
            parentId: 560,
          },
          {
            title: 'Xã Sơn Hà',
            code: 22201,
            value: 'xa_son_ha',
            division_type: 'xã',
            short_codename: 'son_ha',
            parentId: 560,
          },
          {
            title: 'Xã Suối Trai',
            code: 22204,
            value: 'xa_suoi_trai',
            division_type: 'xã',
            short_codename: 'suoi_trai',
            parentId: 560,
          },
        ],
        parentId: 54,
      },
      {
        title: 'Huyện Sông Hinh',
        code: 561,
        value: 'huyen_song_hinh',
        division_type: 'huyện',
        short_codename: 'song_hinh',
        children: [
          {
            title: 'Thị trấn Hai Riêng',
            code: 22207,
            value: 'thi_tran_hai_rieng',
            division_type: 'thị trấn',
            short_codename: 'hai_rieng',
            parentId: 561,
          },
          {
            title: 'Xã Ea Lâm',
            code: 22210,
            value: 'xa_ea_lam',
            division_type: 'xã',
            short_codename: 'ea_lam',
            parentId: 561,
          },
          {
            title: 'Xã Đức Bình Tây',
            code: 22213,
            value: 'xa_duc_binh_tay',
            division_type: 'xã',
            short_codename: 'duc_binh_tay',
            parentId: 561,
          },
          {
            title: 'Xã Ea Bá',
            code: 22216,
            value: 'xa_ea_ba',
            division_type: 'xã',
            short_codename: 'ea_ba',
            parentId: 561,
          },
          {
            title: 'Xã Sơn Giang',
            code: 22219,
            value: 'xa_son_giang',
            division_type: 'xã',
            short_codename: 'son_giang',
            parentId: 561,
          },
          {
            title: 'Xã Đức Bình Đông',
            code: 22222,
            value: 'xa_duc_binh_dong',
            division_type: 'xã',
            short_codename: 'duc_binh_dong',
            parentId: 561,
          },
          {
            title: 'Xã EaBar',
            code: 22225,
            value: 'xa_eabar',
            division_type: 'xã',
            short_codename: 'eabar',
            parentId: 561,
          },
          {
            title: 'Xã EaBia',
            code: 22228,
            value: 'xa_eabia',
            division_type: 'xã',
            short_codename: 'eabia',
            parentId: 561,
          },
          {
            title: 'Xã EaTrol',
            code: 22231,
            value: 'xa_eatrol',
            division_type: 'xã',
            short_codename: 'eatrol',
            parentId: 561,
          },
          {
            title: 'Xã Sông Hinh',
            code: 22234,
            value: 'xa_song_hinh',
            division_type: 'xã',
            short_codename: 'song_hinh',
            parentId: 561,
          },
          {
            title: 'Xã Ealy',
            code: 22237,
            value: 'xa_ealy',
            division_type: 'xã',
            short_codename: 'ealy',
            parentId: 561,
          },
        ],
        parentId: 54,
      },
      {
        title: 'Huyện Tây Hoà',
        code: 562,
        value: 'huyen_tay_hoa',
        division_type: 'huyện',
        short_codename: 'tay_hoa',
        children: [
          {
            title: 'Xã Sơn Thành Tây',
            code: 22249,
            value: 'xa_son_thanh_tay',
            division_type: 'xã',
            short_codename: 'son_thanh_tay',
            parentId: 562,
          },
          {
            title: 'Xã Sơn Thành Đông',
            code: 22250,
            value: 'xa_son_thanh_dong',
            division_type: 'xã',
            short_codename: 'son_thanh_dong',
            parentId: 562,
          },
          {
            title: 'Xã Hòa Bình 1',
            code: 22252,
            value: 'xa_hoa_binh_1',
            division_type: 'xã',
            short_codename: 'hoa_binh_1',
            parentId: 562,
          },
          {
            title: 'Thị trấn Phú Thứ',
            code: 22255,
            value: 'thi_tran_phu_thu',
            division_type: 'thị trấn',
            short_codename: 'phu_thu',
            parentId: 562,
          },
          {
            title: 'Xã Hòa Phong',
            code: 22264,
            value: 'xa_hoa_phong',
            division_type: 'xã',
            short_codename: 'hoa_phong',
            parentId: 562,
          },
          {
            title: 'Xã Hòa Phú',
            code: 22270,
            value: 'xa_hoa_phu',
            division_type: 'xã',
            short_codename: 'hoa_phu',
            parentId: 562,
          },
          {
            title: 'Xã Hòa Tân Tây',
            code: 22273,
            value: 'xa_hoa_tan_tay',
            division_type: 'xã',
            short_codename: 'hoa_tan_tay',
            parentId: 562,
          },
          {
            title: 'Xã Hòa Đồng',
            code: 22276,
            value: 'xa_hoa_dong',
            division_type: 'xã',
            short_codename: 'hoa_dong',
            parentId: 562,
          },
          {
            title: 'Xã Hòa Mỹ Đông',
            code: 22285,
            value: 'xa_hoa_my_dong',
            division_type: 'xã',
            short_codename: 'hoa_my_dong',
            parentId: 562,
          },
          {
            title: 'Xã Hòa Mỹ Tây',
            code: 22288,
            value: 'xa_hoa_my_tay',
            division_type: 'xã',
            short_codename: 'hoa_my_tay',
            parentId: 562,
          },
          {
            title: 'Xã Hòa Thịnh',
            code: 22294,
            value: 'xa_hoa_thinh',
            division_type: 'xã',
            short_codename: 'hoa_thinh',
            parentId: 562,
          },
        ],
        parentId: 54,
      },
      {
        title: 'Huyện Phú Hoà',
        code: 563,
        value: 'huyen_phu_hoa',
        division_type: 'huyện',
        short_codename: 'phu_hoa',
        children: [
          {
            title: 'Xã Hòa Quang Bắc',
            code: 22303,
            value: 'xa_hoa_quang_bac',
            division_type: 'xã',
            short_codename: 'hoa_quang_bac',
            parentId: 563,
          },
          {
            title: 'Xã Hòa Quang Nam',
            code: 22306,
            value: 'xa_hoa_quang_nam',
            division_type: 'xã',
            short_codename: 'hoa_quang_nam',
            parentId: 563,
          },
          {
            title: 'Xã Hòa Hội',
            code: 22309,
            value: 'xa_hoa_hoi',
            division_type: 'xã',
            short_codename: 'hoa_hoi',
            parentId: 563,
          },
          {
            title: 'Xã Hòa Trị',
            code: 22312,
            value: 'xa_hoa_tri',
            division_type: 'xã',
            short_codename: 'hoa_tri',
            parentId: 563,
          },
          {
            title: 'Xã Hòa An',
            code: 22315,
            value: 'xa_hoa_an',
            division_type: 'xã',
            short_codename: 'hoa_an',
            parentId: 563,
          },
          {
            title: 'Xã Hòa Định Đông',
            code: 22318,
            value: 'xa_hoa_dinh_dong',
            division_type: 'xã',
            short_codename: 'hoa_dinh_dong',
            parentId: 563,
          },
          {
            title: 'Thị Trấn Phú Hoà',
            code: 22319,
            value: 'thi_tran_phu_hoa',
            division_type: 'thị trấn',
            short_codename: 'phu_hoa',
            parentId: 563,
          },
          {
            title: 'Xã Hòa Định Tây',
            code: 22321,
            value: 'xa_hoa_dinh_tay',
            division_type: 'xã',
            short_codename: 'hoa_dinh_tay',
            parentId: 563,
          },
          {
            title: 'Xã Hòa Thắng',
            code: 22324,
            value: 'xa_hoa_thang',
            division_type: 'xã',
            short_codename: 'hoa_thang',
            parentId: 563,
          },
        ],
        parentId: 54,
      },
      {
        title: 'Thị xã Đông Hòa',
        code: 564,
        value: 'thi_xa_dong_hoa',
        division_type: 'thị xã',
        short_codename: 'dong_hoa',
        children: [
          {
            title: 'Xã Hòa Thành',
            code: 22243,
            value: 'xa_hoa_thanh',
            division_type: 'xã',
            short_codename: 'hoa_thanh',
            parentId: 564,
          },
          {
            title: 'Phường Hòa Hiệp Bắc',
            code: 22246,
            value: 'phuong_hoa_hiep_bac',
            division_type: 'phường',
            short_codename: 'hoa_hiep_bac',
            parentId: 564,
          },
          {
            title: 'Phường Hoà Vinh',
            code: 22258,
            value: 'phuong_hoa_vinh',
            division_type: 'phường',
            short_codename: 'hoa_vinh',
            parentId: 564,
          },
          {
            title: 'Phường Hoà Hiệp Trung',
            code: 22261,
            value: 'phuong_hoa_hiep_trung',
            division_type: 'phường',
            short_codename: 'hoa_hiep_trung',
            parentId: 564,
          },
          {
            title: 'Xã Hòa Tân Đông',
            code: 22267,
            value: 'xa_hoa_tan_dong',
            division_type: 'xã',
            short_codename: 'hoa_tan_dong',
            parentId: 564,
          },
          {
            title: 'Phường Hòa Xuân Tây',
            code: 22279,
            value: 'phuong_hoa_xuan_tay',
            division_type: 'phường',
            short_codename: 'hoa_xuan_tay',
            parentId: 564,
          },
          {
            title: 'Phường Hòa Hiệp Nam',
            code: 22282,
            value: 'phuong_hoa_hiep_nam',
            division_type: 'phường',
            short_codename: 'hoa_hiep_nam',
            parentId: 564,
          },
          {
            title: 'Xã Hòa Xuân Đông',
            code: 22291,
            value: 'xa_hoa_xuan_dong',
            division_type: 'xã',
            short_codename: 'hoa_xuan_dong',
            parentId: 564,
          },
          {
            title: 'Xã Hòa Tâm',
            code: 22297,
            value: 'xa_hoa_tam',
            division_type: 'xã',
            short_codename: 'hoa_tam',
            parentId: 564,
          },
          {
            title: 'Xã Hòa Xuân Nam',
            code: 22300,
            value: 'xa_hoa_xuan_nam',
            division_type: 'xã',
            short_codename: 'hoa_xuan_nam',
            parentId: 564,
          },
        ],
        parentId: 54,
      },
    ],
  },
  {
    title: 'Tỉnh Khánh Hòa',
    code: 56,
    value: 'tinh_khanh_hoa',
    division_type: 'tỉnh',
    phone_code: 258,
    children: [
      {
        title: 'Thành phố Nha Trang',
        code: 568,
        value: 'thanh_pho_nha_trang',
        division_type: 'thành phố',
        short_codename: 'nha_trang',
        children: [
          {
            title: 'Phường Vĩnh Hòa',
            code: 22327,
            value: 'phuong_vinh_hoa',
            division_type: 'phường',
            short_codename: 'vinh_hoa',
            parentId: 568,
          },
          {
            title: 'Phường Vĩnh Hải',
            code: 22330,
            value: 'phuong_vinh_hai',
            division_type: 'phường',
            short_codename: 'vinh_hai',
            parentId: 568,
          },
          {
            title: 'Phường Vĩnh Phước',
            code: 22333,
            value: 'phuong_vinh_phuoc',
            division_type: 'phường',
            short_codename: 'vinh_phuoc',
            parentId: 568,
          },
          {
            title: 'Phường Ngọc Hiệp',
            code: 22336,
            value: 'phuong_ngoc_hiep',
            division_type: 'phường',
            short_codename: 'ngoc_hiep',
            parentId: 568,
          },
          {
            title: 'Phường Vĩnh Thọ',
            code: 22339,
            value: 'phuong_vinh_tho',
            division_type: 'phường',
            short_codename: 'vinh_tho',
            parentId: 568,
          },
          {
            title: 'Phường Xương Huân',
            code: 22342,
            value: 'phuong_xuong_huan',
            division_type: 'phường',
            short_codename: 'xuong_huan',
            parentId: 568,
          },
          {
            title: 'Phường Vạn Thắng',
            code: 22345,
            value: 'phuong_van_thang',
            division_type: 'phường',
            short_codename: 'van_thang',
            parentId: 568,
          },
          {
            title: 'Phường Vạn Thạnh',
            code: 22348,
            value: 'phuong_van_thanh',
            division_type: 'phường',
            short_codename: 'van_thanh',
            parentId: 568,
          },
          {
            title: 'Phường Phương Sài',
            code: 22351,
            value: 'phuong_phuong_sai',
            division_type: 'phường',
            short_codename: 'phuong_sai',
            parentId: 568,
          },
          {
            title: 'Phường Phương Sơn',
            code: 22354,
            value: 'phuong_phuong_son',
            division_type: 'phường',
            short_codename: 'phuong_son',
            parentId: 568,
          },
          {
            title: 'Phường Phước Hải',
            code: 22357,
            value: 'phuong_phuoc_hai',
            division_type: 'phường',
            short_codename: 'phuoc_hai',
            parentId: 568,
          },
          {
            title: 'Phường Phước Tân',
            code: 22360,
            value: 'phuong_phuoc_tan',
            division_type: 'phường',
            short_codename: 'phuoc_tan',
            parentId: 568,
          },
          {
            title: 'Phường Lộc Thọ',
            code: 22363,
            value: 'phuong_loc_tho',
            division_type: 'phường',
            short_codename: 'loc_tho',
            parentId: 568,
          },
          {
            title: 'Phường Phước Tiến',
            code: 22366,
            value: 'phuong_phuoc_tien',
            division_type: 'phường',
            short_codename: 'phuoc_tien',
            parentId: 568,
          },
          {
            title: 'Phường Tân Lập',
            code: 22369,
            value: 'phuong_tan_lap',
            division_type: 'phường',
            short_codename: 'tan_lap',
            parentId: 568,
          },
          {
            title: 'Phường Phước Hòa',
            code: 22372,
            value: 'phuong_phuoc_hoa',
            division_type: 'phường',
            short_codename: 'phuoc_hoa',
            parentId: 568,
          },
          {
            title: 'Phường Vĩnh Nguyên',
            code: 22375,
            value: 'phuong_vinh_nguyen',
            division_type: 'phường',
            short_codename: 'vinh_nguyen',
            parentId: 568,
          },
          {
            title: 'Phường Phước Long',
            code: 22378,
            value: 'phuong_phuoc_long',
            division_type: 'phường',
            short_codename: 'phuoc_long',
            parentId: 568,
          },
          {
            title: 'Phường Vĩnh Trường',
            code: 22381,
            value: 'phuong_vinh_truong',
            division_type: 'phường',
            short_codename: 'vinh_truong',
            parentId: 568,
          },
          {
            title: 'Xã Vĩnh Lương',
            code: 22384,
            value: 'xa_vinh_luong',
            division_type: 'xã',
            short_codename: 'vinh_luong',
            parentId: 568,
          },
          {
            title: 'Xã Vĩnh Phương',
            code: 22387,
            value: 'xa_vinh_phuong',
            division_type: 'xã',
            short_codename: 'vinh_phuong',
            parentId: 568,
          },
          {
            title: 'Xã Vĩnh Ngọc',
            code: 22390,
            value: 'xa_vinh_ngoc',
            division_type: 'xã',
            short_codename: 'vinh_ngoc',
            parentId: 568,
          },
          {
            title: 'Xã Vĩnh Thạnh',
            code: 22393,
            value: 'xa_vinh_thanh',
            division_type: 'xã',
            short_codename: 'vinh_thanh',
            parentId: 568,
          },
          {
            title: 'Xã Vĩnh Trung',
            code: 22396,
            value: 'xa_vinh_trung',
            division_type: 'xã',
            short_codename: 'vinh_trung',
            parentId: 568,
          },
          {
            title: 'Xã Vĩnh Hiệp',
            code: 22399,
            value: 'xa_vinh_hiep',
            division_type: 'xã',
            short_codename: 'vinh_hiep',
            parentId: 568,
          },
          {
            title: 'Xã Vĩnh Thái',
            code: 22402,
            value: 'xa_vinh_thai',
            division_type: 'xã',
            short_codename: 'vinh_thai',
            parentId: 568,
          },
          {
            title: 'Xã Phước Đồng',
            code: 22405,
            value: 'xa_phuoc_dong',
            division_type: 'xã',
            short_codename: 'phuoc_dong',
            parentId: 568,
          },
        ],
        parentId: 56,
      },
      {
        title: 'Thành phố Cam Ranh',
        code: 569,
        value: 'thanh_pho_cam_ranh',
        division_type: 'thành phố',
        short_codename: 'cam_ranh',
        children: [
          {
            title: 'Phường Cam Nghĩa',
            code: 22408,
            value: 'phuong_cam_nghia',
            division_type: 'phường',
            short_codename: 'cam_nghia',
            parentId: 569,
          },
          {
            title: 'Phường Cam Phúc Bắc',
            code: 22411,
            value: 'phuong_cam_phuc_bac',
            division_type: 'phường',
            short_codename: 'cam_phuc_bac',
            parentId: 569,
          },
          {
            title: 'Phường Cam Phúc Nam',
            code: 22414,
            value: 'phuong_cam_phuc_nam',
            division_type: 'phường',
            short_codename: 'cam_phuc_nam',
            parentId: 569,
          },
          {
            title: 'Phường Cam Lộc',
            code: 22417,
            value: 'phuong_cam_loc',
            division_type: 'phường',
            short_codename: 'cam_loc',
            parentId: 569,
          },
          {
            title: 'Phường Cam Phú',
            code: 22420,
            value: 'phuong_cam_phu',
            division_type: 'phường',
            short_codename: 'cam_phu',
            parentId: 569,
          },
          {
            title: 'Phường Ba Ngòi',
            code: 22423,
            value: 'phuong_ba_ngoi',
            division_type: 'phường',
            short_codename: 'ba_ngoi',
            parentId: 569,
          },
          {
            title: 'Phường Cam Thuận',
            code: 22426,
            value: 'phuong_cam_thuan',
            division_type: 'phường',
            short_codename: 'cam_thuan',
            parentId: 569,
          },
          {
            title: 'Phường Cam Lợi',
            code: 22429,
            value: 'phuong_cam_loi',
            division_type: 'phường',
            short_codename: 'cam_loi',
            parentId: 569,
          },
          {
            title: 'Phường Cam Linh',
            code: 22432,
            value: 'phuong_cam_linh',
            division_type: 'phường',
            short_codename: 'cam_linh',
            parentId: 569,
          },
          {
            title: 'Xã Cam Thành Nam',
            code: 22468,
            value: 'xa_cam_thanh_nam',
            division_type: 'xã',
            short_codename: 'cam_thanh_nam',
            parentId: 569,
          },
          {
            title: 'Xã Cam Phước Đông',
            code: 22474,
            value: 'xa_cam_phuoc_dong',
            division_type: 'xã',
            short_codename: 'cam_phuoc_dong',
            parentId: 569,
          },
          {
            title: 'Xã Cam Thịnh Tây',
            code: 22477,
            value: 'xa_cam_thinh_tay',
            division_type: 'xã',
            short_codename: 'cam_thinh_tay',
            parentId: 569,
          },
          {
            title: 'Xã Cam Thịnh Đông',
            code: 22480,
            value: 'xa_cam_thinh_dong',
            division_type: 'xã',
            short_codename: 'cam_thinh_dong',
            parentId: 569,
          },
          {
            title: 'Xã Cam Lập',
            code: 22483,
            value: 'xa_cam_lap',
            division_type: 'xã',
            short_codename: 'cam_lap',
            parentId: 569,
          },
          {
            title: 'Xã Cam Bình',
            code: 22486,
            value: 'xa_cam_binh',
            division_type: 'xã',
            short_codename: 'cam_binh',
            parentId: 569,
          },
        ],
        parentId: 56,
      },
      {
        title: 'Huyện Cam Lâm',
        code: 570,
        value: 'huyen_cam_lam',
        division_type: 'huyện',
        short_codename: 'cam_lam',
        children: [
          {
            title: 'Xã Cam Tân',
            code: 22435,
            value: 'xa_cam_tan',
            division_type: 'xã',
            short_codename: 'cam_tan',
            parentId: 570,
          },
          {
            title: 'Xã Cam Hòa',
            code: 22438,
            value: 'xa_cam_hoa',
            division_type: 'xã',
            short_codename: 'cam_hoa',
            parentId: 570,
          },
          {
            title: 'Xã Cam Hải Đông',
            code: 22441,
            value: 'xa_cam_hai_dong',
            division_type: 'xã',
            short_codename: 'cam_hai_dong',
            parentId: 570,
          },
          {
            title: 'Xã Cam Hải Tây',
            code: 22444,
            value: 'xa_cam_hai_tay',
            division_type: 'xã',
            short_codename: 'cam_hai_tay',
            parentId: 570,
          },
          {
            title: 'Xã Sơn Tân',
            code: 22447,
            value: 'xa_son_tan',
            division_type: 'xã',
            short_codename: 'son_tan',
            parentId: 570,
          },
          {
            title: 'Xã Cam Hiệp Bắc',
            code: 22450,
            value: 'xa_cam_hiep_bac',
            division_type: 'xã',
            short_codename: 'cam_hiep_bac',
            parentId: 570,
          },
          {
            title: 'Thị trấn Cam Đức',
            code: 22453,
            value: 'thi_tran_cam_duc',
            division_type: 'thị trấn',
            short_codename: 'cam_duc',
            parentId: 570,
          },
          {
            title: 'Xã Cam Hiệp Nam',
            code: 22456,
            value: 'xa_cam_hiep_nam',
            division_type: 'xã',
            short_codename: 'cam_hiep_nam',
            parentId: 570,
          },
          {
            title: 'Xã Cam Phước Tây',
            code: 22459,
            value: 'xa_cam_phuoc_tay',
            division_type: 'xã',
            short_codename: 'cam_phuoc_tay',
            parentId: 570,
          },
          {
            title: 'Xã Cam Thành Bắc',
            code: 22462,
            value: 'xa_cam_thanh_bac',
            division_type: 'xã',
            short_codename: 'cam_thanh_bac',
            parentId: 570,
          },
          {
            title: 'Xã Cam An Bắc',
            code: 22465,
            value: 'xa_cam_an_bac',
            division_type: 'xã',
            short_codename: 'cam_an_bac',
            parentId: 570,
          },
          {
            title: 'Xã Cam An Nam',
            code: 22471,
            value: 'xa_cam_an_nam',
            division_type: 'xã',
            short_codename: 'cam_an_nam',
            parentId: 570,
          },
          {
            title: 'Xã Suối Cát',
            code: 22708,
            value: 'xa_suoi_cat',
            division_type: 'xã',
            short_codename: 'suoi_cat',
            parentId: 570,
          },
          {
            title: 'Xã Suối Tân',
            code: 22711,
            value: 'xa_suoi_tan',
            division_type: 'xã',
            short_codename: 'suoi_tan',
            parentId: 570,
          },
        ],
        parentId: 56,
      },
      {
        title: 'Huyện Vạn Ninh',
        code: 571,
        value: 'huyen_van_ninh',
        division_type: 'huyện',
        short_codename: 'van_ninh',
        children: [
          {
            title: 'Thị trấn Vạn Giã',
            code: 22489,
            value: 'thi_tran_van_gia',
            division_type: 'thị trấn',
            short_codename: 'van_gia',
            parentId: 571,
          },
          {
            title: 'Xã Đại Lãnh',
            code: 22492,
            value: 'xa_dai_lanh',
            division_type: 'xã',
            short_codename: 'dai_lanh',
            parentId: 571,
          },
          {
            title: 'Xã Vạn Phước',
            code: 22495,
            value: 'xa_van_phuoc',
            division_type: 'xã',
            short_codename: 'van_phuoc',
            parentId: 571,
          },
          {
            title: 'Xã Vạn Long',
            code: 22498,
            value: 'xa_van_long',
            division_type: 'xã',
            short_codename: 'van_long',
            parentId: 571,
          },
          {
            title: 'Xã Vạn Bình',
            code: 22501,
            value: 'xa_van_binh',
            division_type: 'xã',
            short_codename: 'van_binh',
            parentId: 571,
          },
          {
            title: 'Xã Vạn Thọ',
            code: 22504,
            value: 'xa_van_tho',
            division_type: 'xã',
            short_codename: 'van_tho',
            parentId: 571,
          },
          {
            title: 'Xã Vạn Khánh',
            code: 22507,
            value: 'xa_van_khanh',
            division_type: 'xã',
            short_codename: 'van_khanh',
            parentId: 571,
          },
          {
            title: 'Xã Vạn Phú',
            code: 22510,
            value: 'xa_van_phu',
            division_type: 'xã',
            short_codename: 'van_phu',
            parentId: 571,
          },
          {
            title: 'Xã Vạn Lương',
            code: 22513,
            value: 'xa_van_luong',
            division_type: 'xã',
            short_codename: 'van_luong',
            parentId: 571,
          },
          {
            title: 'Xã Vạn Thắng',
            code: 22516,
            value: 'xa_van_thang',
            division_type: 'xã',
            short_codename: 'van_thang',
            parentId: 571,
          },
          {
            title: 'Xã Vạn Thạnh',
            code: 22519,
            value: 'xa_van_thanh',
            division_type: 'xã',
            short_codename: 'van_thanh',
            parentId: 571,
          },
          {
            title: 'Xã Xuân Sơn',
            code: 22522,
            value: 'xa_xuan_son',
            division_type: 'xã',
            short_codename: 'xuan_son',
            parentId: 571,
          },
          {
            title: 'Xã Vạn Hưng',
            code: 22525,
            value: 'xa_van_hung',
            division_type: 'xã',
            short_codename: 'van_hung',
            parentId: 571,
          },
        ],
        parentId: 56,
      },
      {
        title: 'Thị xã Ninh Hòa',
        code: 572,
        value: 'thi_xa_ninh_hoa',
        division_type: 'thị xã',
        short_codename: 'ninh_hoa',
        children: [
          {
            title: 'Phường Ninh Hiệp',
            code: 22528,
            value: 'phuong_ninh_hiep',
            division_type: 'phường',
            short_codename: 'ninh_hiep',
            parentId: 572,
          },
          {
            title: 'Xã Ninh Sơn',
            code: 22531,
            value: 'xa_ninh_son',
            division_type: 'xã',
            short_codename: 'ninh_son',
            parentId: 572,
          },
          {
            title: 'Xã Ninh Tây',
            code: 22534,
            value: 'xa_ninh_tay',
            division_type: 'xã',
            short_codename: 'ninh_tay',
            parentId: 572,
          },
          {
            title: 'Xã Ninh Thượng',
            code: 22537,
            value: 'xa_ninh_thuong',
            division_type: 'xã',
            short_codename: 'ninh_thuong',
            parentId: 572,
          },
          {
            title: 'Xã Ninh An',
            code: 22540,
            value: 'xa_ninh_an',
            division_type: 'xã',
            short_codename: 'ninh_an',
            parentId: 572,
          },
          {
            title: 'Phường Ninh Hải',
            code: 22543,
            value: 'phuong_ninh_hai',
            division_type: 'phường',
            short_codename: 'ninh_hai',
            parentId: 572,
          },
          {
            title: 'Xã Ninh Thọ',
            code: 22546,
            value: 'xa_ninh_tho',
            division_type: 'xã',
            short_codename: 'ninh_tho',
            parentId: 572,
          },
          {
            title: 'Xã Ninh Trung',
            code: 22549,
            value: 'xa_ninh_trung',
            division_type: 'xã',
            short_codename: 'ninh_trung',
            parentId: 572,
          },
          {
            title: 'Xã Ninh Sim',
            code: 22552,
            value: 'xa_ninh_sim',
            division_type: 'xã',
            short_codename: 'ninh_sim',
            parentId: 572,
          },
          {
            title: 'Xã Ninh Xuân',
            code: 22555,
            value: 'xa_ninh_xuan',
            division_type: 'xã',
            short_codename: 'ninh_xuan',
            parentId: 572,
          },
          {
            title: 'Xã Ninh Thân',
            code: 22558,
            value: 'xa_ninh_than',
            division_type: 'xã',
            short_codename: 'ninh_than',
            parentId: 572,
          },
          {
            title: 'Phường Ninh Diêm',
            code: 22561,
            value: 'phuong_ninh_diem',
            division_type: 'phường',
            short_codename: 'ninh_diem',
            parentId: 572,
          },
          {
            title: 'Xã Ninh Đông',
            code: 22564,
            value: 'xa_ninh_dong',
            division_type: 'xã',
            short_codename: 'ninh_dong',
            parentId: 572,
          },
          {
            title: 'Phường Ninh Thủy',
            code: 22567,
            value: 'phuong_ninh_thuy',
            division_type: 'phường',
            short_codename: 'ninh_thuy',
            parentId: 572,
          },
          {
            title: 'Phường Ninh Đa',
            code: 22570,
            value: 'phuong_ninh_da',
            division_type: 'phường',
            short_codename: 'ninh_da',
            parentId: 572,
          },
          {
            title: 'Xã Ninh Phụng',
            code: 22573,
            value: 'xa_ninh_phung',
            division_type: 'xã',
            short_codename: 'ninh_phung',
            parentId: 572,
          },
          {
            title: 'Xã Ninh Bình',
            code: 22576,
            value: 'xa_ninh_binh',
            division_type: 'xã',
            short_codename: 'ninh_binh',
            parentId: 572,
          },
          {
            title: 'Xã Ninh Phước',
            code: 22579,
            value: 'xa_ninh_phuoc',
            division_type: 'xã',
            short_codename: 'ninh_phuoc',
            parentId: 572,
          },
          {
            title: 'Xã Ninh Phú',
            code: 22582,
            value: 'xa_ninh_phu',
            division_type: 'xã',
            short_codename: 'ninh_phu',
            parentId: 572,
          },
          {
            title: 'Xã Ninh Tân',
            code: 22585,
            value: 'xa_ninh_tan',
            division_type: 'xã',
            short_codename: 'ninh_tan',
            parentId: 572,
          },
          {
            title: 'Xã Ninh Quang',
            code: 22588,
            value: 'xa_ninh_quang',
            division_type: 'xã',
            short_codename: 'ninh_quang',
            parentId: 572,
          },
          {
            title: 'Phường Ninh Giang',
            code: 22591,
            value: 'phuong_ninh_giang',
            division_type: 'phường',
            short_codename: 'ninh_giang',
            parentId: 572,
          },
          {
            title: 'Phường Ninh Hà',
            code: 22594,
            value: 'phuong_ninh_ha',
            division_type: 'phường',
            short_codename: 'ninh_ha',
            parentId: 572,
          },
          {
            title: 'Xã Ninh Hưng',
            code: 22597,
            value: 'xa_ninh_hung',
            division_type: 'xã',
            short_codename: 'ninh_hung',
            parentId: 572,
          },
          {
            title: 'Xã Ninh Lộc',
            code: 22600,
            value: 'xa_ninh_loc',
            division_type: 'xã',
            short_codename: 'ninh_loc',
            parentId: 572,
          },
          {
            title: 'Xã Ninh Ích',
            code: 22603,
            value: 'xa_ninh_ich',
            division_type: 'xã',
            short_codename: 'ninh_ich',
            parentId: 572,
          },
          {
            title: 'Xã Ninh Vân',
            code: 22606,
            value: 'xa_ninh_van',
            division_type: 'xã',
            short_codename: 'ninh_van',
            parentId: 572,
          },
        ],
        parentId: 56,
      },
      {
        title: 'Huyện Khánh Vĩnh',
        code: 573,
        value: 'huyen_khanh_vinh',
        division_type: 'huyện',
        short_codename: 'khanh_vinh',
        children: [
          {
            title: 'Thị trấn Khánh Vĩnh',
            code: 22609,
            value: 'thi_tran_khanh_vinh',
            division_type: 'thị trấn',
            short_codename: 'khanh_vinh',
            parentId: 573,
          },
          {
            title: 'Xã Khánh Hiệp',
            code: 22612,
            value: 'xa_khanh_hiep',
            division_type: 'xã',
            short_codename: 'khanh_hiep',
            parentId: 573,
          },
          {
            title: 'Xã Khánh Bình',
            code: 22615,
            value: 'xa_khanh_binh',
            division_type: 'xã',
            short_codename: 'khanh_binh',
            parentId: 573,
          },
          {
            title: 'Xã Khánh Trung',
            code: 22618,
            value: 'xa_khanh_trung',
            division_type: 'xã',
            short_codename: 'khanh_trung',
            parentId: 573,
          },
          {
            title: 'Xã Khánh Đông',
            code: 22621,
            value: 'xa_khanh_dong',
            division_type: 'xã',
            short_codename: 'khanh_dong',
            parentId: 573,
          },
          {
            title: 'Xã Khánh Thượng',
            code: 22624,
            value: 'xa_khanh_thuong',
            division_type: 'xã',
            short_codename: 'khanh_thuong',
            parentId: 573,
          },
          {
            title: 'Xã Khánh Nam',
            code: 22627,
            value: 'xa_khanh_nam',
            division_type: 'xã',
            short_codename: 'khanh_nam',
            parentId: 573,
          },
          {
            title: 'Xã Sông Cầu',
            code: 22630,
            value: 'xa_song_cau',
            division_type: 'xã',
            short_codename: 'song_cau',
            parentId: 573,
          },
          {
            title: 'Xã Giang Ly',
            code: 22633,
            value: 'xa_giang_ly',
            division_type: 'xã',
            short_codename: 'giang_ly',
            parentId: 573,
          },
          {
            title: 'Xã Cầu Bà',
            code: 22636,
            value: 'xa_cau_ba',
            division_type: 'xã',
            short_codename: 'cau_ba',
            parentId: 573,
          },
          {
            title: 'Xã Liên Sang',
            code: 22639,
            value: 'xa_lien_sang',
            division_type: 'xã',
            short_codename: 'lien_sang',
            parentId: 573,
          },
          {
            title: 'Xã Khánh Thành',
            code: 22642,
            value: 'xa_khanh_thanh',
            division_type: 'xã',
            short_codename: 'khanh_thanh',
            parentId: 573,
          },
          {
            title: 'Xã Khánh Phú',
            code: 22645,
            value: 'xa_khanh_phu',
            division_type: 'xã',
            short_codename: 'khanh_phu',
            parentId: 573,
          },
          {
            title: 'Xã Sơn Thái',
            code: 22648,
            value: 'xa_son_thai',
            division_type: 'xã',
            short_codename: 'son_thai',
            parentId: 573,
          },
        ],
        parentId: 56,
      },
      {
        title: 'Huyện Diên Khánh',
        code: 574,
        value: 'huyen_dien_khanh',
        division_type: 'huyện',
        short_codename: 'dien_khanh',
        children: [
          {
            title: 'Thị trấn Diên Khánh',
            code: 22651,
            value: 'thi_tran_dien_khanh',
            division_type: 'thị trấn',
            short_codename: 'dien_khanh',
            parentId: 574,
          },
          {
            title: 'Xã Diên Lâm',
            code: 22654,
            value: 'xa_dien_lam',
            division_type: 'xã',
            short_codename: 'dien_lam',
            parentId: 574,
          },
          {
            title: 'Xã Diên Điền',
            code: 22657,
            value: 'xa_dien_dien',
            division_type: 'xã',
            short_codename: 'dien_dien',
            parentId: 574,
          },
          {
            title: 'Xã Diên Xuân',
            code: 22660,
            value: 'xa_dien_xuan',
            division_type: 'xã',
            short_codename: 'dien_xuan',
            parentId: 574,
          },
          {
            title: 'Xã Diên Sơn',
            code: 22663,
            value: 'xa_dien_son',
            division_type: 'xã',
            short_codename: 'dien_son',
            parentId: 574,
          },
          {
            title: 'Xã Diên Đồng',
            code: 22666,
            value: 'xa_dien_dong',
            division_type: 'xã',
            short_codename: 'dien_dong',
            parentId: 574,
          },
          {
            title: 'Xã Diên Phú',
            code: 22669,
            value: 'xa_dien_phu',
            division_type: 'xã',
            short_codename: 'dien_phu',
            parentId: 574,
          },
          {
            title: 'Xã Diên Thọ',
            code: 22672,
            value: 'xa_dien_tho',
            division_type: 'xã',
            short_codename: 'dien_tho',
            parentId: 574,
          },
          {
            title: 'Xã Diên Phước',
            code: 22675,
            value: 'xa_dien_phuoc',
            division_type: 'xã',
            short_codename: 'dien_phuoc',
            parentId: 574,
          },
          {
            title: 'Xã Diên Lạc',
            code: 22678,
            value: 'xa_dien_lac',
            division_type: 'xã',
            short_codename: 'dien_lac',
            parentId: 574,
          },
          {
            title: 'Xã Diên Tân',
            code: 22681,
            value: 'xa_dien_tan',
            division_type: 'xã',
            short_codename: 'dien_tan',
            parentId: 574,
          },
          {
            title: 'Xã Diên Hòa',
            code: 22684,
            value: 'xa_dien_hoa',
            division_type: 'xã',
            short_codename: 'dien_hoa',
            parentId: 574,
          },
          {
            title: 'Xã Diên Thạnh',
            code: 22687,
            value: 'xa_dien_thanh',
            division_type: 'xã',
            short_codename: 'dien_thanh',
            parentId: 574,
          },
          {
            title: 'Xã Diên Toàn',
            code: 22690,
            value: 'xa_dien_toan',
            division_type: 'xã',
            short_codename: 'dien_toan',
            parentId: 574,
          },
          {
            title: 'Xã Diên An',
            code: 22693,
            value: 'xa_dien_an',
            division_type: 'xã',
            short_codename: 'dien_an',
            parentId: 574,
          },
          {
            title: 'Xã Bình Lộc',
            code: 22696,
            value: 'xa_binh_loc',
            division_type: 'xã',
            short_codename: 'binh_loc',
            parentId: 574,
          },
          {
            title: 'Xã Suối Hiệp',
            code: 22702,
            value: 'xa_suoi_hiep',
            division_type: 'xã',
            short_codename: 'suoi_hiep',
            parentId: 574,
          },
          {
            title: 'Xã Suối Tiên',
            code: 22705,
            value: 'xa_suoi_tien',
            division_type: 'xã',
            short_codename: 'suoi_tien',
            parentId: 574,
          },
        ],
        parentId: 56,
      },
      {
        title: 'Huyện Khánh Sơn',
        code: 575,
        value: 'huyen_khanh_son',
        division_type: 'huyện',
        short_codename: 'khanh_son',
        children: [
          {
            title: 'Thị trấn Tô Hạp',
            code: 22714,
            value: 'thi_tran_to_hap',
            division_type: 'thị trấn',
            short_codename: 'to_hap',
            parentId: 575,
          },
          {
            title: 'Xã Thành Sơn',
            code: 22717,
            value: 'xa_thanh_son',
            division_type: 'xã',
            short_codename: 'thanh_son',
            parentId: 575,
          },
          {
            title: 'Xã Sơn Lâm',
            code: 22720,
            value: 'xa_son_lam',
            division_type: 'xã',
            short_codename: 'son_lam',
            parentId: 575,
          },
          {
            title: 'Xã Sơn Hiệp',
            code: 22723,
            value: 'xa_son_hiep',
            division_type: 'xã',
            short_codename: 'son_hiep',
            parentId: 575,
          },
          {
            title: 'Xã Sơn Bình',
            code: 22726,
            value: 'xa_son_binh',
            division_type: 'xã',
            short_codename: 'son_binh',
            parentId: 575,
          },
          {
            title: 'Xã Sơn Trung',
            code: 22729,
            value: 'xa_son_trung',
            division_type: 'xã',
            short_codename: 'son_trung',
            parentId: 575,
          },
          {
            title: 'Xã Ba Cụm Bắc',
            code: 22732,
            value: 'xa_ba_cum_bac',
            division_type: 'xã',
            short_codename: 'ba_cum_bac',
            parentId: 575,
          },
          {
            title: 'Xã Ba Cụm Nam',
            code: 22735,
            value: 'xa_ba_cum_nam',
            division_type: 'xã',
            short_codename: 'ba_cum_nam',
            parentId: 575,
          },
        ],
        parentId: 56,
      },
      {
        title: 'Huyện Trường Sa',
        code: 576,
        value: 'huyen_truong_sa',
        division_type: 'huyện',
        short_codename: 'truong_sa',
        children: [
          {
            title: 'Thị trấn Trường Sa',
            code: 22736,
            value: 'thi_tran_truong_sa',
            division_type: 'thị trấn',
            short_codename: 'truong_sa',
            parentId: 576,
          },
          {
            title: 'Xã Song Tử Tây',
            code: 22737,
            value: 'xa_song_tu_tay',
            division_type: 'xã',
            short_codename: 'song_tu_tay',
            parentId: 576,
          },
          {
            title: 'Xã Sinh Tồn',
            code: 22739,
            value: 'xa_sinh_ton',
            division_type: 'xã',
            short_codename: 'sinh_ton',
            parentId: 576,
          },
        ],
        parentId: 56,
      },
    ],
  },
  {
    title: 'Tỉnh Ninh Thuận',
    code: 58,
    value: 'tinh_ninh_thuan',
    division_type: 'tỉnh',
    phone_code: 259,
    children: [
      {
        title: 'Thành phố Phan Rang-Tháp Chàm',
        code: 582,
        value: 'thanh_pho_phan_rang_thap_cham',
        division_type: 'thành phố',
        short_codename: 'phan_rang_thap_cham',
        children: [
          {
            title: 'Phường Đô Vinh',
            code: 22738,
            value: 'phuong_do_vinh',
            division_type: 'phường',
            short_codename: 'do_vinh',
            parentId: 582,
          },
          {
            title: 'Phường Phước Mỹ',
            code: 22741,
            value: 'phuong_phuoc_my',
            division_type: 'phường',
            short_codename: 'phuoc_my',
            parentId: 582,
          },
          {
            title: 'Phường Bảo An',
            code: 22744,
            value: 'phuong_bao_an',
            division_type: 'phường',
            short_codename: 'bao_an',
            parentId: 582,
          },
          {
            title: 'Phường Phủ Hà',
            code: 22747,
            value: 'phuong_phu_ha',
            division_type: 'phường',
            short_codename: 'phu_ha',
            parentId: 582,
          },
          {
            title: 'Phường Thanh Sơn',
            code: 22750,
            value: 'phuong_thanh_son',
            division_type: 'phường',
            short_codename: 'thanh_son',
            parentId: 582,
          },
          {
            title: 'Phường Mỹ Hương',
            code: 22753,
            value: 'phuong_my_huong',
            division_type: 'phường',
            short_codename: 'my_huong',
            parentId: 582,
          },
          {
            title: 'Phường Tấn Tài',
            code: 22756,
            value: 'phuong_tan_tai',
            division_type: 'phường',
            short_codename: 'tan_tai',
            parentId: 582,
          },
          {
            title: 'Phường Kinh Dinh',
            code: 22759,
            value: 'phuong_kinh_dinh',
            division_type: 'phường',
            short_codename: 'kinh_dinh',
            parentId: 582,
          },
          {
            title: 'Phường Đạo Long',
            code: 22762,
            value: 'phuong_dao_long',
            division_type: 'phường',
            short_codename: 'dao_long',
            parentId: 582,
          },
          {
            title: 'Phường Đài Sơn',
            code: 22765,
            value: 'phuong_dai_son',
            division_type: 'phường',
            short_codename: 'dai_son',
            parentId: 582,
          },
          {
            title: 'Phường Đông Hải',
            code: 22768,
            value: 'phuong_dong_hai',
            division_type: 'phường',
            short_codename: 'dong_hai',
            parentId: 582,
          },
          {
            title: 'Phường Mỹ Đông',
            code: 22771,
            value: 'phuong_my_dong',
            division_type: 'phường',
            short_codename: 'my_dong',
            parentId: 582,
          },
          {
            title: 'Xã Thành Hải',
            code: 22774,
            value: 'xa_thanh_hai',
            division_type: 'xã',
            short_codename: 'thanh_hai',
            parentId: 582,
          },
          {
            title: 'Phường Văn Hải',
            code: 22777,
            value: 'phuong_van_hai',
            division_type: 'phường',
            short_codename: 'van_hai',
            parentId: 582,
          },
          {
            title: 'Phường Mỹ Bình',
            code: 22779,
            value: 'phuong_my_binh',
            division_type: 'phường',
            short_codename: 'my_binh',
            parentId: 582,
          },
          {
            title: 'Phường Mỹ Hải',
            code: 22780,
            value: 'phuong_my_hai',
            division_type: 'phường',
            short_codename: 'my_hai',
            parentId: 582,
          },
        ],
        parentId: 58,
      },
      {
        title: 'Huyện Bác Ái',
        code: 584,
        value: 'huyen_bac_ai',
        division_type: 'huyện',
        short_codename: 'bac_ai',
        children: [
          {
            title: 'Xã Phước Bình',
            code: 22783,
            value: 'xa_phuoc_binh',
            division_type: 'xã',
            short_codename: 'phuoc_binh',
            parentId: 584,
          },
          {
            title: 'Xã Phước Hòa',
            code: 22786,
            value: 'xa_phuoc_hoa',
            division_type: 'xã',
            short_codename: 'phuoc_hoa',
            parentId: 584,
          },
          {
            title: 'Xã Phước Tân',
            code: 22789,
            value: 'xa_phuoc_tan',
            division_type: 'xã',
            short_codename: 'phuoc_tan',
            parentId: 584,
          },
          {
            title: 'Xã Phước Tiến',
            code: 22792,
            value: 'xa_phuoc_tien',
            division_type: 'xã',
            short_codename: 'phuoc_tien',
            parentId: 584,
          },
          {
            title: 'Xã Phước Thắng',
            code: 22795,
            value: 'xa_phuoc_thang',
            division_type: 'xã',
            short_codename: 'phuoc_thang',
            parentId: 584,
          },
          {
            title: 'Xã Phước Thành',
            code: 22798,
            value: 'xa_phuoc_thanh',
            division_type: 'xã',
            short_codename: 'phuoc_thanh',
            parentId: 584,
          },
          {
            title: 'Xã Phước Đại',
            code: 22801,
            value: 'xa_phuoc_dai',
            division_type: 'xã',
            short_codename: 'phuoc_dai',
            parentId: 584,
          },
          {
            title: 'Xã Phước Chính',
            code: 22804,
            value: 'xa_phuoc_chinh',
            division_type: 'xã',
            short_codename: 'phuoc_chinh',
            parentId: 584,
          },
          {
            title: 'Xã Phước Trung',
            code: 22807,
            value: 'xa_phuoc_trung',
            division_type: 'xã',
            short_codename: 'phuoc_trung',
            parentId: 584,
          },
        ],
        parentId: 58,
      },
      {
        title: 'Huyện Ninh Sơn',
        code: 585,
        value: 'huyen_ninh_son',
        division_type: 'huyện',
        short_codename: 'ninh_son',
        children: [
          {
            title: 'Thị trấn Tân Sơn',
            code: 22810,
            value: 'thi_tran_tan_son',
            division_type: 'thị trấn',
            short_codename: 'tan_son',
            parentId: 585,
          },
          {
            title: 'Xã Lâm Sơn',
            code: 22813,
            value: 'xa_lam_son',
            division_type: 'xã',
            short_codename: 'lam_son',
            parentId: 585,
          },
          {
            title: 'Xã Lương Sơn',
            code: 22816,
            value: 'xa_luong_son',
            division_type: 'xã',
            short_codename: 'luong_son',
            parentId: 585,
          },
          {
            title: 'Xã Quảng Sơn',
            code: 22819,
            value: 'xa_quang_son',
            division_type: 'xã',
            short_codename: 'quang_son',
            parentId: 585,
          },
          {
            title: 'Xã Mỹ Sơn',
            code: 22822,
            value: 'xa_my_son',
            division_type: 'xã',
            short_codename: 'my_son',
            parentId: 585,
          },
          {
            title: 'Xã Hòa Sơn',
            code: 22825,
            value: 'xa_hoa_son',
            division_type: 'xã',
            short_codename: 'hoa_son',
            parentId: 585,
          },
          {
            title: 'Xã Ma Nới',
            code: 22828,
            value: 'xa_ma_noi',
            division_type: 'xã',
            short_codename: 'ma_noi',
            parentId: 585,
          },
          {
            title: 'Xã Nhơn Sơn',
            code: 22831,
            value: 'xa_nhon_son',
            division_type: 'xã',
            short_codename: 'nhon_son',
            parentId: 585,
          },
        ],
        parentId: 58,
      },
      {
        title: 'Huyện Ninh Hải',
        code: 586,
        value: 'huyen_ninh_hai',
        division_type: 'huyện',
        short_codename: 'ninh_hai',
        children: [
          {
            title: 'Thị trấn Khánh Hải',
            code: 22834,
            value: 'thi_tran_khanh_hai',
            division_type: 'thị trấn',
            short_codename: 'khanh_hai',
            parentId: 586,
          },
          {
            title: 'Xã Vĩnh Hải',
            code: 22846,
            value: 'xa_vinh_hai',
            division_type: 'xã',
            short_codename: 'vinh_hai',
            parentId: 586,
          },
          {
            title: 'Xã Phương Hải',
            code: 22852,
            value: 'xa_phuong_hai',
            division_type: 'xã',
            short_codename: 'phuong_hai',
            parentId: 586,
          },
          {
            title: 'Xã Tân Hải',
            code: 22855,
            value: 'xa_tan_hai',
            division_type: 'xã',
            short_codename: 'tan_hai',
            parentId: 586,
          },
          {
            title: 'Xã Xuân Hải',
            code: 22858,
            value: 'xa_xuan_hai',
            division_type: 'xã',
            short_codename: 'xuan_hai',
            parentId: 586,
          },
          {
            title: 'Xã Hộ Hải',
            code: 22861,
            value: 'xa_ho_hai',
            division_type: 'xã',
            short_codename: 'ho_hai',
            parentId: 586,
          },
          {
            title: 'Xã Tri Hải',
            code: 22864,
            value: 'xa_tri_hai',
            division_type: 'xã',
            short_codename: 'tri_hai',
            parentId: 586,
          },
          {
            title: 'Xã Nhơn Hải',
            code: 22867,
            value: 'xa_nhon_hai',
            division_type: 'xã',
            short_codename: 'nhon_hai',
            parentId: 586,
          },
          {
            title: 'Xã Thanh Hải',
            code: 22868,
            value: 'xa_thanh_hai',
            division_type: 'xã',
            short_codename: 'thanh_hai',
            parentId: 586,
          },
        ],
        parentId: 58,
      },
      {
        title: 'Huyện Ninh Phước',
        code: 587,
        value: 'huyen_ninh_phuoc',
        division_type: 'huyện',
        short_codename: 'ninh_phuoc',
        children: [
          {
            title: 'Thị trấn Phước Dân',
            code: 22870,
            value: 'thi_tran_phuoc_dan',
            division_type: 'thị trấn',
            short_codename: 'phuoc_dan',
            parentId: 587,
          },
          {
            title: 'Xã Phước Sơn',
            code: 22873,
            value: 'xa_phuoc_son',
            division_type: 'xã',
            short_codename: 'phuoc_son',
            parentId: 587,
          },
          {
            title: 'Xã Phước Thái',
            code: 22876,
            value: 'xa_phuoc_thai',
            division_type: 'xã',
            short_codename: 'phuoc_thai',
            parentId: 587,
          },
          {
            title: 'Xã Phước Hậu',
            code: 22879,
            value: 'xa_phuoc_hau',
            division_type: 'xã',
            short_codename: 'phuoc_hau',
            parentId: 587,
          },
          {
            title: 'Xã Phước Thuận',
            code: 22882,
            value: 'xa_phuoc_thuan',
            division_type: 'xã',
            short_codename: 'phuoc_thuan',
            parentId: 587,
          },
          {
            title: 'Xã An Hải',
            code: 22888,
            value: 'xa_an_hai',
            division_type: 'xã',
            short_codename: 'an_hai',
            parentId: 587,
          },
          {
            title: 'Xã Phước Hữu',
            code: 22891,
            value: 'xa_phuoc_huu',
            division_type: 'xã',
            short_codename: 'phuoc_huu',
            parentId: 587,
          },
          {
            title: 'Xã Phước Hải',
            code: 22894,
            value: 'xa_phuoc_hai',
            division_type: 'xã',
            short_codename: 'phuoc_hai',
            parentId: 587,
          },
          {
            title: 'Xã Phước Vinh',
            code: 22912,
            value: 'xa_phuoc_vinh',
            division_type: 'xã',
            short_codename: 'phuoc_vinh',
            parentId: 587,
          },
        ],
        parentId: 58,
      },
      {
        title: 'Huyện Thuận Bắc',
        code: 588,
        value: 'huyen_thuan_bac',
        division_type: 'huyện',
        short_codename: 'thuan_bac',
        children: [
          {
            title: 'Xã Phước Chiến',
            code: 22837,
            value: 'xa_phuoc_chien',
            division_type: 'xã',
            short_codename: 'phuoc_chien',
            parentId: 588,
          },
          {
            title: 'Xã Công Hải',
            code: 22840,
            value: 'xa_cong_hai',
            division_type: 'xã',
            short_codename: 'cong_hai',
            parentId: 588,
          },
          {
            title: 'Xã Phước Kháng',
            code: 22843,
            value: 'xa_phuoc_khang',
            division_type: 'xã',
            short_codename: 'phuoc_khang',
            parentId: 588,
          },
          {
            title: 'Xã Lợi Hải',
            code: 22849,
            value: 'xa_loi_hai',
            division_type: 'xã',
            short_codename: 'loi_hai',
            parentId: 588,
          },
          {
            title: 'Xã Bắc Sơn',
            code: 22853,
            value: 'xa_bac_son',
            division_type: 'xã',
            short_codename: 'bac_son',
            parentId: 588,
          },
          {
            title: 'Xã Bắc Phong',
            code: 22856,
            value: 'xa_bac_phong',
            division_type: 'xã',
            short_codename: 'bac_phong',
            parentId: 588,
          },
        ],
        parentId: 58,
      },
      {
        title: 'Huyện Thuận Nam',
        code: 589,
        value: 'huyen_thuan_nam',
        division_type: 'huyện',
        short_codename: 'thuan_nam',
        children: [
          {
            title: 'Xã Phước Hà',
            code: 22885,
            value: 'xa_phuoc_ha',
            division_type: 'xã',
            short_codename: 'phuoc_ha',
            parentId: 589,
          },
          {
            title: 'Xã Phước Nam',
            code: 22897,
            value: 'xa_phuoc_nam',
            division_type: 'xã',
            short_codename: 'phuoc_nam',
            parentId: 589,
          },
          {
            title: 'Xã Phước Ninh',
            code: 22898,
            value: 'xa_phuoc_ninh',
            division_type: 'xã',
            short_codename: 'phuoc_ninh',
            parentId: 589,
          },
          {
            title: 'Xã Nhị Hà',
            code: 22900,
            value: 'xa_nhi_ha',
            division_type: 'xã',
            short_codename: 'nhi_ha',
            parentId: 589,
          },
          {
            title: 'Xã Phước Dinh',
            code: 22903,
            value: 'xa_phuoc_dinh',
            division_type: 'xã',
            short_codename: 'phuoc_dinh',
            parentId: 589,
          },
          {
            title: 'Xã Phước Minh',
            code: 22906,
            value: 'xa_phuoc_minh',
            division_type: 'xã',
            short_codename: 'phuoc_minh',
            parentId: 589,
          },
          {
            title: 'Xã Phước Diêm',
            code: 22909,
            value: 'xa_phuoc_diem',
            division_type: 'xã',
            short_codename: 'phuoc_diem',
            parentId: 589,
          },
          {
            title: 'Xã Cà Ná',
            code: 22910,
            value: 'xa_ca_na',
            division_type: 'xã',
            short_codename: 'ca_na',
            parentId: 589,
          },
        ],
        parentId: 58,
      },
    ],
  },
  {
    title: 'Tỉnh Bình Thuận',
    code: 60,
    value: 'tinh_binh_thuan',
    division_type: 'tỉnh',
    phone_code: 252,
    children: [
      {
        title: 'Thành phố Phan Thiết',
        code: 593,
        value: 'thanh_pho_phan_thiet',
        division_type: 'thành phố',
        short_codename: 'phan_thiet',
        children: [
          {
            title: 'Phường Mũi Né',
            code: 22915,
            value: 'phuong_mui_ne',
            division_type: 'phường',
            short_codename: 'mui_ne',
            parentId: 593,
          },
          {
            title: 'Phường Hàm Tiến',
            code: 22918,
            value: 'phuong_ham_tien',
            division_type: 'phường',
            short_codename: 'ham_tien',
            parentId: 593,
          },
          {
            title: 'Phường Phú Hài',
            code: 22921,
            value: 'phuong_phu_hai',
            division_type: 'phường',
            short_codename: 'phu_hai',
            parentId: 593,
          },
          {
            title: 'Phường Phú Thủy',
            code: 22924,
            value: 'phuong_phu_thuy',
            division_type: 'phường',
            short_codename: 'phu_thuy',
            parentId: 593,
          },
          {
            title: 'Phường Phú Tài',
            code: 22927,
            value: 'phuong_phu_tai',
            division_type: 'phường',
            short_codename: 'phu_tai',
            parentId: 593,
          },
          {
            title: 'Phường Phú Trinh',
            code: 22930,
            value: 'phuong_phu_trinh',
            division_type: 'phường',
            short_codename: 'phu_trinh',
            parentId: 593,
          },
          {
            title: 'Phường Xuân An',
            code: 22933,
            value: 'phuong_xuan_an',
            division_type: 'phường',
            short_codename: 'xuan_an',
            parentId: 593,
          },
          {
            title: 'Phường Thanh Hải',
            code: 22936,
            value: 'phuong_thanh_hai',
            division_type: 'phường',
            short_codename: 'thanh_hai',
            parentId: 593,
          },
          {
            title: 'Phường Bình Hưng',
            code: 22939,
            value: 'phuong_binh_hung',
            division_type: 'phường',
            short_codename: 'binh_hung',
            parentId: 593,
          },
          {
            title: 'Phường Đức Nghĩa',
            code: 22942,
            value: 'phuong_duc_nghia',
            division_type: 'phường',
            short_codename: 'duc_nghia',
            parentId: 593,
          },
          {
            title: 'Phường Lạc Đạo',
            code: 22945,
            value: 'phuong_lac_dao',
            division_type: 'phường',
            short_codename: 'lac_dao',
            parentId: 593,
          },
          {
            title: 'Phường Đức Thắng',
            code: 22948,
            value: 'phuong_duc_thang',
            division_type: 'phường',
            short_codename: 'duc_thang',
            parentId: 593,
          },
          {
            title: 'Phường Hưng Long',
            code: 22951,
            value: 'phuong_hung_long',
            division_type: 'phường',
            short_codename: 'hung_long',
            parentId: 593,
          },
          {
            title: 'Phường Đức Long',
            code: 22954,
            value: 'phuong_duc_long',
            division_type: 'phường',
            short_codename: 'duc_long',
            parentId: 593,
          },
          {
            title: 'Xã Thiện Nghiệp',
            code: 22957,
            value: 'xa_thien_nghiep',
            division_type: 'xã',
            short_codename: 'thien_nghiep',
            parentId: 593,
          },
          {
            title: 'Xã Phong Nẫm',
            code: 22960,
            value: 'xa_phong_nam',
            division_type: 'xã',
            short_codename: 'phong_nam',
            parentId: 593,
          },
          {
            title: 'Xã Tiến Lợi',
            code: 22963,
            value: 'xa_tien_loi',
            division_type: 'xã',
            short_codename: 'tien_loi',
            parentId: 593,
          },
          {
            title: 'Xã Tiến Thành',
            code: 22966,
            value: 'xa_tien_thanh',
            division_type: 'xã',
            short_codename: 'tien_thanh',
            parentId: 593,
          },
        ],
        parentId: 60,
      },
      {
        title: 'Thị xã La Gi',
        code: 594,
        value: 'thi_xa_la_gi',
        division_type: 'thị xã',
        short_codename: 'la_gi',
        children: [
          {
            title: 'Phường Phước Hội',
            code: 23231,
            value: 'phuong_phuoc_hoi',
            division_type: 'phường',
            short_codename: 'phuoc_hoi',
            parentId: 594,
          },
          {
            title: 'Phường Phước Lộc',
            code: 23232,
            value: 'phuong_phuoc_loc',
            division_type: 'phường',
            short_codename: 'phuoc_loc',
            parentId: 594,
          },
          {
            title: 'Phường Tân Thiện',
            code: 23234,
            value: 'phuong_tan_thien',
            division_type: 'phường',
            short_codename: 'tan_thien',
            parentId: 594,
          },
          {
            title: 'Phường Tân An',
            code: 23235,
            value: 'phuong_tan_an',
            division_type: 'phường',
            short_codename: 'tan_an',
            parentId: 594,
          },
          {
            title: 'Phường Bình Tân',
            code: 23237,
            value: 'phuong_binh_tan',
            division_type: 'phường',
            short_codename: 'binh_tan',
            parentId: 594,
          },
          {
            title: 'Xã Tân Hải',
            code: 23245,
            value: 'xa_tan_hai',
            division_type: 'xã',
            short_codename: 'tan_hai',
            parentId: 594,
          },
          {
            title: 'Xã Tân Tiến',
            code: 23246,
            value: 'xa_tan_tien',
            division_type: 'xã',
            short_codename: 'tan_tien',
            parentId: 594,
          },
          {
            title: 'Xã Tân Bình',
            code: 23248,
            value: 'xa_tan_binh',
            division_type: 'xã',
            short_codename: 'tan_binh',
            parentId: 594,
          },
          {
            title: 'Xã Tân Phước',
            code: 23268,
            value: 'xa_tan_phuoc',
            division_type: 'xã',
            short_codename: 'tan_phuoc',
            parentId: 594,
          },
        ],
        parentId: 60,
      },
      {
        title: 'Huyện Tuy Phong',
        code: 595,
        value: 'huyen_tuy_phong',
        division_type: 'huyện',
        short_codename: 'tuy_phong',
        children: [
          {
            title: 'Thị trấn Liên Hương',
            code: 22969,
            value: 'thi_tran_lien_huong',
            division_type: 'thị trấn',
            short_codename: 'lien_huong',
            parentId: 595,
          },
          {
            title: 'Thị trấn Phan Rí Cửa',
            code: 22972,
            value: 'thi_tran_phan_ri_cua',
            division_type: 'thị trấn',
            short_codename: 'phan_ri_cua',
            parentId: 595,
          },
          {
            title: 'Xã Phan Dũng',
            code: 22975,
            value: 'xa_phan_dung',
            division_type: 'xã',
            short_codename: 'phan_dung',
            parentId: 595,
          },
          {
            title: 'Xã Phong Phú',
            code: 22978,
            value: 'xa_phong_phu',
            division_type: 'xã',
            short_codename: 'phong_phu',
            parentId: 595,
          },
          {
            title: 'Xã Vĩnh Hảo',
            code: 22981,
            value: 'xa_vinh_hao',
            division_type: 'xã',
            short_codename: 'vinh_hao',
            parentId: 595,
          },
          {
            title: 'Xã Vĩnh Tân',
            code: 22984,
            value: 'xa_vinh_tan',
            division_type: 'xã',
            short_codename: 'vinh_tan',
            parentId: 595,
          },
          {
            title: 'Xã Phú Lạc',
            code: 22987,
            value: 'xa_phu_lac',
            division_type: 'xã',
            short_codename: 'phu_lac',
            parentId: 595,
          },
          {
            title: 'Xã Phước Thể',
            code: 22990,
            value: 'xa_phuoc_the',
            division_type: 'xã',
            short_codename: 'phuoc_the',
            parentId: 595,
          },
          {
            title: 'Xã Hòa Minh',
            code: 22993,
            value: 'xa_hoa_minh',
            division_type: 'xã',
            short_codename: 'hoa_minh',
            parentId: 595,
          },
          {
            title: 'Xã Chí Công',
            code: 22996,
            value: 'xa_chi_cong',
            division_type: 'xã',
            short_codename: 'chi_cong',
            parentId: 595,
          },
          {
            title: 'Xã Bình Thạnh',
            code: 22999,
            value: 'xa_binh_thanh',
            division_type: 'xã',
            short_codename: 'binh_thanh',
            parentId: 595,
          },
        ],
        parentId: 60,
      },
      {
        title: 'Huyện Bắc Bình',
        code: 596,
        value: 'huyen_bac_binh',
        division_type: 'huyện',
        short_codename: 'bac_binh',
        children: [
          {
            title: 'Thị trấn Chợ Lầu',
            code: 23005,
            value: 'thi_tran_cho_lau',
            division_type: 'thị trấn',
            short_codename: 'cho_lau',
            parentId: 596,
          },
          {
            title: 'Xã Phan Sơn',
            code: 23008,
            value: 'xa_phan_son',
            division_type: 'xã',
            short_codename: 'phan_son',
            parentId: 596,
          },
          {
            title: 'Xã Phan Lâm',
            code: 23011,
            value: 'xa_phan_lam',
            division_type: 'xã',
            short_codename: 'phan_lam',
            parentId: 596,
          },
          {
            title: 'Xã Bình An',
            code: 23014,
            value: 'xa_binh_an',
            division_type: 'xã',
            short_codename: 'binh_an',
            parentId: 596,
          },
          {
            title: 'Xã Phan Điền',
            code: 23017,
            value: 'xa_phan_dien',
            division_type: 'xã',
            short_codename: 'phan_dien',
            parentId: 596,
          },
          {
            title: 'Xã Hải Ninh',
            code: 23020,
            value: 'xa_hai_ninh',
            division_type: 'xã',
            short_codename: 'hai_ninh',
            parentId: 596,
          },
          {
            title: 'Xã Sông Lũy',
            code: 23023,
            value: 'xa_song_luy',
            division_type: 'xã',
            short_codename: 'song_luy',
            parentId: 596,
          },
          {
            title: 'Xã Phan Tiến',
            code: 23026,
            value: 'xa_phan_tien',
            division_type: 'xã',
            short_codename: 'phan_tien',
            parentId: 596,
          },
          {
            title: 'Xã Sông Bình',
            code: 23029,
            value: 'xa_song_binh',
            division_type: 'xã',
            short_codename: 'song_binh',
            parentId: 596,
          },
          {
            title: 'Thị trấn Lương Sơn',
            code: 23032,
            value: 'thi_tran_luong_son',
            division_type: 'thị trấn',
            short_codename: 'luong_son',
            parentId: 596,
          },
          {
            title: 'Xã Phan Hòa',
            code: 23035,
            value: 'xa_phan_hoa',
            division_type: 'xã',
            short_codename: 'phan_hoa',
            parentId: 596,
          },
          {
            title: 'Xã Phan Thanh',
            code: 23038,
            value: 'xa_phan_thanh',
            division_type: 'xã',
            short_codename: 'phan_thanh',
            parentId: 596,
          },
          {
            title: 'Xã Hồng Thái',
            code: 23041,
            value: 'xa_hong_thai',
            division_type: 'xã',
            short_codename: 'hong_thai',
            parentId: 596,
          },
          {
            title: 'Xã Phan Hiệp',
            code: 23044,
            value: 'xa_phan_hiep',
            division_type: 'xã',
            short_codename: 'phan_hiep',
            parentId: 596,
          },
          {
            title: 'Xã Bình Tân',
            code: 23047,
            value: 'xa_binh_tan',
            division_type: 'xã',
            short_codename: 'binh_tan',
            parentId: 596,
          },
          {
            title: 'Xã Phan Rí Thành',
            code: 23050,
            value: 'xa_phan_ri_thanh',
            division_type: 'xã',
            short_codename: 'phan_ri_thanh',
            parentId: 596,
          },
          {
            title: 'Xã Hòa Thắng',
            code: 23053,
            value: 'xa_hoa_thang',
            division_type: 'xã',
            short_codename: 'hoa_thang',
            parentId: 596,
          },
          {
            title: 'Xã Hồng Phong',
            code: 23056,
            value: 'xa_hong_phong',
            division_type: 'xã',
            short_codename: 'hong_phong',
            parentId: 596,
          },
        ],
        parentId: 60,
      },
      {
        title: 'Huyện Hàm Thuận Bắc',
        code: 597,
        value: 'huyen_ham_thuan_bac',
        division_type: 'huyện',
        short_codename: 'ham_thuan_bac',
        children: [
          {
            title: 'Thị trấn Ma Lâm',
            code: 23059,
            value: 'thi_tran_ma_lam',
            division_type: 'thị trấn',
            short_codename: 'ma_lam',
            parentId: 597,
          },
          {
            title: 'Thị trấn Phú Long',
            code: 23062,
            value: 'thi_tran_phu_long',
            division_type: 'thị trấn',
            short_codename: 'phu_long',
            parentId: 597,
          },
          {
            title: 'Xã La Dạ',
            code: 23065,
            value: 'xa_la_da',
            division_type: 'xã',
            short_codename: 'la_da',
            parentId: 597,
          },
          {
            title: 'Xã Đông Tiến',
            code: 23068,
            value: 'xa_dong_tien',
            division_type: 'xã',
            short_codename: 'dong_tien',
            parentId: 597,
          },
          {
            title: 'Xã Thuận Hòa',
            code: 23071,
            value: 'xa_thuan_hoa',
            division_type: 'xã',
            short_codename: 'thuan_hoa',
            parentId: 597,
          },
          {
            title: 'Xã Đông Giang',
            code: 23074,
            value: 'xa_dong_giang',
            division_type: 'xã',
            short_codename: 'dong_giang',
            parentId: 597,
          },
          {
            title: 'Xã Hàm Phú',
            code: 23077,
            value: 'xa_ham_phu',
            division_type: 'xã',
            short_codename: 'ham_phu',
            parentId: 597,
          },
          {
            title: 'Xã Hồng Liêm',
            code: 23080,
            value: 'xa_hong_liem',
            division_type: 'xã',
            short_codename: 'hong_liem',
            parentId: 597,
          },
          {
            title: 'Xã Thuận Minh',
            code: 23083,
            value: 'xa_thuan_minh',
            division_type: 'xã',
            short_codename: 'thuan_minh',
            parentId: 597,
          },
          {
            title: 'Xã Hồng Sơn',
            code: 23086,
            value: 'xa_hong_son',
            division_type: 'xã',
            short_codename: 'hong_son',
            parentId: 597,
          },
          {
            title: 'Xã Hàm Trí',
            code: 23089,
            value: 'xa_ham_tri',
            division_type: 'xã',
            short_codename: 'ham_tri',
            parentId: 597,
          },
          {
            title: 'Xã Hàm Đức',
            code: 23092,
            value: 'xa_ham_duc',
            division_type: 'xã',
            short_codename: 'ham_duc',
            parentId: 597,
          },
          {
            title: 'Xã Hàm Liêm',
            code: 23095,
            value: 'xa_ham_liem',
            division_type: 'xã',
            short_codename: 'ham_liem',
            parentId: 597,
          },
          {
            title: 'Xã Hàm Chính',
            code: 23098,
            value: 'xa_ham_chinh',
            division_type: 'xã',
            short_codename: 'ham_chinh',
            parentId: 597,
          },
          {
            title: 'Xã Hàm Hiệp',
            code: 23101,
            value: 'xa_ham_hiep',
            division_type: 'xã',
            short_codename: 'ham_hiep',
            parentId: 597,
          },
          {
            title: 'Xã Hàm Thắng',
            code: 23104,
            value: 'xa_ham_thang',
            division_type: 'xã',
            short_codename: 'ham_thang',
            parentId: 597,
          },
          {
            title: 'Xã Đa Mi',
            code: 23107,
            value: 'xa_da_mi',
            division_type: 'xã',
            short_codename: 'da_mi',
            parentId: 597,
          },
        ],
        parentId: 60,
      },
      {
        title: 'Huyện Hàm Thuận Nam',
        code: 598,
        value: 'huyen_ham_thuan_nam',
        division_type: 'huyện',
        short_codename: 'ham_thuan_nam',
        children: [
          {
            title: 'Thị trấn Thuận Nam',
            code: 23110,
            value: 'thi_tran_thuan_nam',
            division_type: 'thị trấn',
            short_codename: 'thuan_nam',
            parentId: 598,
          },
          {
            title: 'Xã Mỹ Thạnh',
            code: 23113,
            value: 'xa_my_thanh',
            division_type: 'xã',
            short_codename: 'my_thanh',
            parentId: 598,
          },
          {
            title: 'Xã Hàm Cần',
            code: 23116,
            value: 'xa_ham_can',
            division_type: 'xã',
            short_codename: 'ham_can',
            parentId: 598,
          },
          {
            title: 'Xã Mương Mán',
            code: 23119,
            value: 'xa_muong_man',
            division_type: 'xã',
            short_codename: 'muong_man',
            parentId: 598,
          },
          {
            title: 'Xã Hàm Thạnh',
            code: 23122,
            value: 'xa_ham_thanh',
            division_type: 'xã',
            short_codename: 'ham_thanh',
            parentId: 598,
          },
          {
            title: 'Xã Hàm Kiệm',
            code: 23125,
            value: 'xa_ham_kiem',
            division_type: 'xã',
            short_codename: 'ham_kiem',
            parentId: 598,
          },
          {
            title: 'Xã Hàm Cường',
            code: 23128,
            value: 'xa_ham_cuong',
            division_type: 'xã',
            short_codename: 'ham_cuong',
            parentId: 598,
          },
          {
            title: 'Xã Hàm Mỹ',
            code: 23131,
            value: 'xa_ham_my',
            division_type: 'xã',
            short_codename: 'ham_my',
            parentId: 598,
          },
          {
            title: 'Xã Tân Lập',
            code: 23134,
            value: 'xa_tan_lap',
            division_type: 'xã',
            short_codename: 'tan_lap',
            parentId: 598,
          },
          {
            title: 'Xã Hàm Minh',
            code: 23137,
            value: 'xa_ham_minh',
            division_type: 'xã',
            short_codename: 'ham_minh',
            parentId: 598,
          },
          {
            title: 'Xã Thuận Quí',
            code: 23140,
            value: 'xa_thuan_qui',
            division_type: 'xã',
            short_codename: 'thuan_qui',
            parentId: 598,
          },
          {
            title: 'Xã Tân Thuận',
            code: 23143,
            value: 'xa_tan_thuan',
            division_type: 'xã',
            short_codename: 'tan_thuan',
            parentId: 598,
          },
          {
            title: 'Xã Tân Thành',
            code: 23146,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 598,
          },
        ],
        parentId: 60,
      },
      {
        title: 'Huyện Tánh Linh',
        code: 599,
        value: 'huyen_tanh_linh',
        division_type: 'huyện',
        short_codename: 'tanh_linh',
        children: [
          {
            title: 'Thị trấn Lạc Tánh',
            code: 23149,
            value: 'thi_tran_lac_tanh',
            division_type: 'thị trấn',
            short_codename: 'lac_tanh',
            parentId: 599,
          },
          {
            title: 'Xã Bắc Ruộng',
            code: 23152,
            value: 'xa_bac_ruong',
            division_type: 'xã',
            short_codename: 'bac_ruong',
            parentId: 599,
          },
          {
            title: 'Xã Nghị Đức',
            code: 23158,
            value: 'xa_nghi_duc',
            division_type: 'xã',
            short_codename: 'nghi_duc',
            parentId: 599,
          },
          {
            title: 'Xã La Ngâu',
            code: 23161,
            value: 'xa_la_ngau',
            division_type: 'xã',
            short_codename: 'la_ngau',
            parentId: 599,
          },
          {
            title: 'Xã Huy Khiêm',
            code: 23164,
            value: 'xa_huy_khiem',
            division_type: 'xã',
            short_codename: 'huy_khiem',
            parentId: 599,
          },
          {
            title: 'Xã Măng Tố',
            code: 23167,
            value: 'xa_mang_to',
            division_type: 'xã',
            short_codename: 'mang_to',
            parentId: 599,
          },
          {
            title: 'Xã Đức Phú',
            code: 23170,
            value: 'xa_duc_phu',
            division_type: 'xã',
            short_codename: 'duc_phu',
            parentId: 599,
          },
          {
            title: 'Xã Đồng Kho',
            code: 23173,
            value: 'xa_dong_kho',
            division_type: 'xã',
            short_codename: 'dong_kho',
            parentId: 599,
          },
          {
            title: 'Xã Gia An',
            code: 23176,
            value: 'xa_gia_an',
            division_type: 'xã',
            short_codename: 'gia_an',
            parentId: 599,
          },
          {
            title: 'Xã Đức Bình',
            code: 23179,
            value: 'xa_duc_binh',
            division_type: 'xã',
            short_codename: 'duc_binh',
            parentId: 599,
          },
          {
            title: 'Xã Gia Huynh',
            code: 23182,
            value: 'xa_gia_huynh',
            division_type: 'xã',
            short_codename: 'gia_huynh',
            parentId: 599,
          },
          {
            title: 'Xã Đức Thuận',
            code: 23185,
            value: 'xa_duc_thuan',
            division_type: 'xã',
            short_codename: 'duc_thuan',
            parentId: 599,
          },
          {
            title: 'Xã Suối Kiết',
            code: 23188,
            value: 'xa_suoi_kiet',
            division_type: 'xã',
            short_codename: 'suoi_kiet',
            parentId: 599,
          },
        ],
        parentId: 60,
      },
      {
        title: 'Huyện Đức Linh',
        code: 600,
        value: 'huyen_duc_linh',
        division_type: 'huyện',
        short_codename: 'duc_linh',
        children: [
          {
            title: 'Thị trấn Võ Xu',
            code: 23191,
            value: 'thi_tran_vo_xu',
            division_type: 'thị trấn',
            short_codename: 'vo_xu',
            parentId: 600,
          },
          {
            title: 'Thị trấn Đức Tài',
            code: 23194,
            value: 'thi_tran_duc_tai',
            division_type: 'thị trấn',
            short_codename: 'duc_tai',
            parentId: 600,
          },
          {
            title: 'Xã Đa Kai',
            code: 23197,
            value: 'xa_da_kai',
            division_type: 'xã',
            short_codename: 'da_kai',
            parentId: 600,
          },
          {
            title: 'Xã Sùng Nhơn',
            code: 23200,
            value: 'xa_sung_nhon',
            division_type: 'xã',
            short_codename: 'sung_nhon',
            parentId: 600,
          },
          {
            title: 'Xã Mê Pu',
            code: 23203,
            value: 'xa_me_pu',
            division_type: 'xã',
            short_codename: 'me_pu',
            parentId: 600,
          },
          {
            title: 'Xã Nam Chính',
            code: 23206,
            value: 'xa_nam_chinh',
            division_type: 'xã',
            short_codename: 'nam_chinh',
            parentId: 600,
          },
          {
            title: 'Xã Đức Hạnh',
            code: 23212,
            value: 'xa_duc_hanh',
            division_type: 'xã',
            short_codename: 'duc_hanh',
            parentId: 600,
          },
          {
            title: 'Xã Đức Tín',
            code: 23215,
            value: 'xa_duc_tin',
            division_type: 'xã',
            short_codename: 'duc_tin',
            parentId: 600,
          },
          {
            title: 'Xã Vũ Hoà',
            code: 23218,
            value: 'xa_vu_hoa',
            division_type: 'xã',
            short_codename: 'vu_hoa',
            parentId: 600,
          },
          {
            title: 'Xã Tân Hà',
            code: 23221,
            value: 'xa_tan_ha',
            division_type: 'xã',
            short_codename: 'tan_ha',
            parentId: 600,
          },
          {
            title: 'Xã Đông Hà',
            code: 23224,
            value: 'xa_dong_ha',
            division_type: 'xã',
            short_codename: 'dong_ha',
            parentId: 600,
          },
          {
            title: 'Xã Trà Tân',
            code: 23227,
            value: 'xa_tra_tan',
            division_type: 'xã',
            short_codename: 'tra_tan',
            parentId: 600,
          },
        ],
        parentId: 60,
      },
      {
        title: 'Huyện Hàm Tân',
        code: 601,
        value: 'huyen_ham_tan',
        division_type: 'huyện',
        short_codename: 'ham_tan',
        children: [
          {
            title: 'Thị trấn Tân Minh',
            code: 23230,
            value: 'thi_tran_tan_minh',
            division_type: 'thị trấn',
            short_codename: 'tan_minh',
            parentId: 601,
          },
          {
            title: 'Thị trấn Tân Nghĩa',
            code: 23236,
            value: 'thi_tran_tan_nghia',
            division_type: 'thị trấn',
            short_codename: 'tan_nghia',
            parentId: 601,
          },
          {
            title: 'Xã Sông Phan',
            code: 23239,
            value: 'xa_song_phan',
            division_type: 'xã',
            short_codename: 'song_phan',
            parentId: 601,
          },
          {
            title: 'Xã Tân Phúc',
            code: 23242,
            value: 'xa_tan_phuc',
            division_type: 'xã',
            short_codename: 'tan_phuc',
            parentId: 601,
          },
          {
            title: 'Xã Tân Đức',
            code: 23251,
            value: 'xa_tan_duc',
            division_type: 'xã',
            short_codename: 'tan_duc',
            parentId: 601,
          },
          {
            title: 'Xã Tân Thắng',
            code: 23254,
            value: 'xa_tan_thang',
            division_type: 'xã',
            short_codename: 'tan_thang',
            parentId: 601,
          },
          {
            title: 'Xã Thắng Hải',
            code: 23255,
            value: 'xa_thang_hai',
            division_type: 'xã',
            short_codename: 'thang_hai',
            parentId: 601,
          },
          {
            title: 'Xã Tân Hà',
            code: 23257,
            value: 'xa_tan_ha',
            division_type: 'xã',
            short_codename: 'tan_ha',
            parentId: 601,
          },
          {
            title: 'Xã Tân Xuân',
            code: 23260,
            value: 'xa_tan_xuan',
            division_type: 'xã',
            short_codename: 'tan_xuan',
            parentId: 601,
          },
          {
            title: 'Xã Sơn Mỹ',
            code: 23266,
            value: 'xa_son_my',
            division_type: 'xã',
            short_codename: 'son_my',
            parentId: 601,
          },
        ],
        parentId: 60,
      },
      {
        title: 'Huyện Phú Quí',
        code: 602,
        value: 'huyen_phu_qui',
        division_type: 'huyện',
        short_codename: 'phu_qui',
        children: [
          {
            title: 'Xã Ngũ Phụng',
            code: 23272,
            value: 'xa_ngu_phung',
            division_type: 'xã',
            short_codename: 'ngu_phung',
            parentId: 602,
          },
          {
            title: 'Xã Long Hải',
            code: 23275,
            value: 'xa_long_hai',
            division_type: 'xã',
            short_codename: 'long_hai',
            parentId: 602,
          },
          {
            title: 'Xã Tam Thanh',
            code: 23278,
            value: 'xa_tam_thanh',
            division_type: 'xã',
            short_codename: 'tam_thanh',
            parentId: 602,
          },
        ],
        parentId: 60,
      },
    ],
  },
  {
    title: 'Tỉnh Kon Tum',
    code: 62,
    value: 'tinh_kon_tum',
    division_type: 'tỉnh',
    phone_code: 260,
    children: [
      {
        title: 'Thành phố Kon Tum',
        code: 608,
        value: 'thanh_pho_kon_tum',
        division_type: 'thành phố',
        short_codename: 'kon_tum',
        children: [
          {
            title: 'Phường Quang Trung',
            code: 23281,
            value: 'phuong_quang_trung',
            division_type: 'phường',
            short_codename: 'quang_trung',
            parentId: 608,
          },
          {
            title: 'Phường Duy Tân',
            code: 23284,
            value: 'phuong_duy_tan',
            division_type: 'phường',
            short_codename: 'duy_tan',
            parentId: 608,
          },
          {
            title: 'Phường Quyết Thắng',
            code: 23287,
            value: 'phuong_quyet_thang',
            division_type: 'phường',
            short_codename: 'quyet_thang',
            parentId: 608,
          },
          {
            title: 'Phường Trường Chinh',
            code: 23290,
            value: 'phuong_truong_chinh',
            division_type: 'phường',
            short_codename: 'truong_chinh',
            parentId: 608,
          },
          {
            title: 'Phường Thắng Lợi',
            code: 23293,
            value: 'phuong_thang_loi',
            division_type: 'phường',
            short_codename: 'thang_loi',
            parentId: 608,
          },
          {
            title: 'Phường Ngô Mây',
            code: 23296,
            value: 'phuong_ngo_may',
            division_type: 'phường',
            short_codename: 'ngo_may',
            parentId: 608,
          },
          {
            title: 'Phường Thống Nhất',
            code: 23299,
            value: 'phuong_thong_nhat',
            division_type: 'phường',
            short_codename: 'thong_nhat',
            parentId: 608,
          },
          {
            title: 'Phường Lê Lợi',
            code: 23302,
            value: 'phuong_le_loi',
            division_type: 'phường',
            short_codename: 'le_loi',
            parentId: 608,
          },
          {
            title: 'Phường Nguyễn Trãi',
            code: 23305,
            value: 'phuong_nguyen_trai',
            division_type: 'phường',
            short_codename: 'nguyen_trai',
            parentId: 608,
          },
          {
            title: 'Phường Trần Hưng Đạo',
            code: 23308,
            value: 'phuong_tran_hung_dao',
            division_type: 'phường',
            short_codename: 'tran_hung_dao',
            parentId: 608,
          },
          {
            title: 'Xã Đắk Cấm',
            code: 23311,
            value: 'xa_dak_cam',
            division_type: 'xã',
            short_codename: 'dak_cam',
            parentId: 608,
          },
          {
            title: 'Xã Kroong',
            code: 23314,
            value: 'xa_kroong',
            division_type: 'xã',
            short_codename: 'kroong',
            parentId: 608,
          },
          {
            title: 'Xã Ngọk Bay',
            code: 23317,
            value: 'xa_ngok_bay',
            division_type: 'xã',
            short_codename: 'ngok_bay',
            parentId: 608,
          },
          {
            title: 'Xã Vinh Quang',
            code: 23320,
            value: 'xa_vinh_quang',
            division_type: 'xã',
            short_codename: 'vinh_quang',
            parentId: 608,
          },
          {
            title: 'Xã Đắk Blà',
            code: 23323,
            value: 'xa_dak_bla',
            division_type: 'xã',
            short_codename: 'dak_bla',
            parentId: 608,
          },
          {
            title: 'Xã Ia Chim',
            code: 23326,
            value: 'xa_ia_chim',
            division_type: 'xã',
            short_codename: 'ia_chim',
            parentId: 608,
          },
          {
            title: 'Xã Đăk Năng',
            code: 23327,
            value: 'xa_dak_nang',
            division_type: 'xã',
            short_codename: 'dak_nang',
            parentId: 608,
          },
          {
            title: 'Xã Đoàn Kết',
            code: 23329,
            value: 'xa_doan_ket',
            division_type: 'xã',
            short_codename: 'doan_ket',
            parentId: 608,
          },
          {
            title: 'Xã Chư Hreng',
            code: 23332,
            value: 'xa_chu_hreng',
            division_type: 'xã',
            short_codename: 'chu_hreng',
            parentId: 608,
          },
          {
            title: 'Xã Đắk Rơ Wa',
            code: 23335,
            value: 'xa_dak_ro_wa',
            division_type: 'xã',
            short_codename: 'dak_ro_wa',
            parentId: 608,
          },
          {
            title: 'Xã Hòa Bình',
            code: 23338,
            value: 'xa_hoa_binh',
            division_type: 'xã',
            short_codename: 'hoa_binh',
            parentId: 608,
          },
        ],
        parentId: 62,
      },
      {
        title: 'Huyện Đắk Glei',
        code: 610,
        value: 'huyen_dak_glei',
        division_type: 'huyện',
        short_codename: 'dak_glei',
        children: [
          {
            title: 'Thị trấn Đắk Glei',
            code: 23341,
            value: 'thi_tran_dak_glei',
            division_type: 'thị trấn',
            short_codename: 'dak_glei',
            parentId: 610,
          },
          {
            title: 'Xã Đắk Blô',
            code: 23344,
            value: 'xa_dak_blo',
            division_type: 'xã',
            short_codename: 'dak_blo',
            parentId: 610,
          },
          {
            title: 'Xã Đắk Man',
            code: 23347,
            value: 'xa_dak_man',
            division_type: 'xã',
            short_codename: 'dak_man',
            parentId: 610,
          },
          {
            title: 'Xã Đắk Nhoong',
            code: 23350,
            value: 'xa_dak_nhoong',
            division_type: 'xã',
            short_codename: 'dak_nhoong',
            parentId: 610,
          },
          {
            title: 'Xã Đắk Pék',
            code: 23353,
            value: 'xa_dak_pek',
            division_type: 'xã',
            short_codename: 'dak_pek',
            parentId: 610,
          },
          {
            title: 'Xã Đắk Choong',
            code: 23356,
            value: 'xa_dak_choong',
            division_type: 'xã',
            short_codename: 'dak_choong',
            parentId: 610,
          },
          {
            title: 'Xã Xốp',
            code: 23359,
            value: 'xa_xop',
            division_type: 'xã',
            short_codename: 'xop',
            parentId: 610,
          },
          {
            title: 'Xã Mường Hoong',
            code: 23362,
            value: 'xa_muong_hoong',
            division_type: 'xã',
            short_codename: 'muong_hoong',
            parentId: 610,
          },
          {
            title: 'Xã Ngọc Linh',
            code: 23365,
            value: 'xa_ngoc_linh',
            division_type: 'xã',
            short_codename: 'ngoc_linh',
            parentId: 610,
          },
          {
            title: 'Xã Đắk Long',
            code: 23368,
            value: 'xa_dak_long',
            division_type: 'xã',
            short_codename: 'dak_long',
            parentId: 610,
          },
          {
            title: 'Xã Đắk KRoong',
            code: 23371,
            value: 'xa_dak_kroong',
            division_type: 'xã',
            short_codename: 'dak_kroong',
            parentId: 610,
          },
          {
            title: 'Xã Đắk Môn',
            code: 23374,
            value: 'xa_dak_mon',
            division_type: 'xã',
            short_codename: 'dak_mon',
            parentId: 610,
          },
        ],
        parentId: 62,
      },
      {
        title: 'Huyện Ngọc Hồi',
        code: 611,
        value: 'huyen_ngoc_hoi',
        division_type: 'huyện',
        short_codename: 'ngoc_hoi',
        children: [
          {
            title: 'Thị trấn Plei Kần',
            code: 23377,
            value: 'thi_tran_plei_kan',
            division_type: 'thị trấn',
            short_codename: 'plei_kan',
            parentId: 611,
          },
          {
            title: 'Xã Đắk Ang',
            code: 23380,
            value: 'xa_dak_ang',
            division_type: 'xã',
            short_codename: 'dak_ang',
            parentId: 611,
          },
          {
            title: 'Xã Đắk Dục',
            code: 23383,
            value: 'xa_dak_duc',
            division_type: 'xã',
            short_codename: 'dak_duc',
            parentId: 611,
          },
          {
            title: 'Xã Đắk Nông',
            code: 23386,
            value: 'xa_dak_nong',
            division_type: 'xã',
            short_codename: 'dak_nong',
            parentId: 611,
          },
          {
            title: 'Xã Đắk Xú',
            code: 23389,
            value: 'xa_dak_xu',
            division_type: 'xã',
            short_codename: 'dak_xu',
            parentId: 611,
          },
          {
            title: 'Xã Đắk Kan',
            code: 23392,
            value: 'xa_dak_kan',
            division_type: 'xã',
            short_codename: 'dak_kan',
            parentId: 611,
          },
          {
            title: 'Xã Bờ Y',
            code: 23395,
            value: 'xa_bo_y',
            division_type: 'xã',
            short_codename: 'bo_y',
            parentId: 611,
          },
          {
            title: 'Xã Sa Loong',
            code: 23398,
            value: 'xa_sa_loong',
            division_type: 'xã',
            short_codename: 'sa_loong',
            parentId: 611,
          },
        ],
        parentId: 62,
      },
      {
        title: 'Huyện Đắk Tô',
        code: 612,
        value: 'huyen_dak_to',
        division_type: 'huyện',
        short_codename: 'dak_to',
        children: [
          {
            title: 'Thị trấn Đắk Tô',
            code: 23401,
            value: 'thi_tran_dak_to',
            division_type: 'thị trấn',
            short_codename: 'dak_to',
            parentId: 612,
          },
          {
            title: 'Xã Đắk Rơ Nga',
            code: 23427,
            value: 'xa_dak_ro_nga',
            division_type: 'xã',
            short_codename: 'dak_ro_nga',
            parentId: 612,
          },
          {
            title: 'Xã Ngọk Tụ',
            code: 23428,
            value: 'xa_ngok_tu',
            division_type: 'xã',
            short_codename: 'ngok_tu',
            parentId: 612,
          },
          {
            title: 'Xã Đắk Trăm',
            code: 23430,
            value: 'xa_dak_tram',
            division_type: 'xã',
            short_codename: 'dak_tram',
            parentId: 612,
          },
          {
            title: 'Xã Văn Lem',
            code: 23431,
            value: 'xa_van_lem',
            division_type: 'xã',
            short_codename: 'van_lem',
            parentId: 612,
          },
          {
            title: 'Xã Kon Đào',
            code: 23434,
            value: 'xa_kon_dao',
            division_type: 'xã',
            short_codename: 'kon_dao',
            parentId: 612,
          },
          {
            title: 'Xã Tân Cảnh',
            code: 23437,
            value: 'xa_tan_canh',
            division_type: 'xã',
            short_codename: 'tan_canh',
            parentId: 612,
          },
          {
            title: 'Xã Diên Bình',
            code: 23440,
            value: 'xa_dien_binh',
            division_type: 'xã',
            short_codename: 'dien_binh',
            parentId: 612,
          },
          {
            title: 'Xã Pô Kô',
            code: 23443,
            value: 'xa_po_ko',
            division_type: 'xã',
            short_codename: 'po_ko',
            parentId: 612,
          },
        ],
        parentId: 62,
      },
      {
        title: 'Huyện Kon Plông',
        code: 613,
        value: 'huyen_kon_plong',
        division_type: 'huyện',
        short_codename: 'kon_plong',
        children: [
          {
            title: 'Xã Đắk Nên',
            code: 23452,
            value: 'xa_dak_nen',
            division_type: 'xã',
            short_codename: 'dak_nen',
            parentId: 613,
          },
          {
            title: 'Xã Đắk Ring',
            code: 23455,
            value: 'xa_dak_ring',
            division_type: 'xã',
            short_codename: 'dak_ring',
            parentId: 613,
          },
          {
            title: 'Xã Măng Buk',
            code: 23458,
            value: 'xa_mang_buk',
            division_type: 'xã',
            short_codename: 'mang_buk',
            parentId: 613,
          },
          {
            title: 'Xã Đắk Tăng',
            code: 23461,
            value: 'xa_dak_tang',
            division_type: 'xã',
            short_codename: 'dak_tang',
            parentId: 613,
          },
          {
            title: 'Xã Ngok Tem',
            code: 23464,
            value: 'xa_ngok_tem',
            division_type: 'xã',
            short_codename: 'ngok_tem',
            parentId: 613,
          },
          {
            title: 'Xã Pờ Ê',
            code: 23467,
            value: 'xa_po_e',
            division_type: 'xã',
            short_codename: 'po_e',
            parentId: 613,
          },
          {
            title: 'Xã Măng Cành',
            code: 23470,
            value: 'xa_mang_canh',
            division_type: 'xã',
            short_codename: 'mang_canh',
            parentId: 613,
          },
          {
            title: 'Thị trấn Măng Đen',
            code: 23473,
            value: 'thi_tran_mang_den',
            division_type: 'thị trấn',
            short_codename: 'mang_den',
            parentId: 613,
          },
          {
            title: 'Xã Hiếu',
            code: 23476,
            value: 'xa_hieu',
            division_type: 'xã',
            short_codename: 'hieu',
            parentId: 613,
          },
        ],
        parentId: 62,
      },
      {
        title: 'Huyện Kon Rẫy',
        code: 614,
        value: 'huyen_kon_ray',
        division_type: 'huyện',
        short_codename: 'kon_ray',
        children: [
          {
            title: 'Thị trấn Đắk Rve',
            code: 23479,
            value: 'thi_tran_dak_rve',
            division_type: 'thị trấn',
            short_codename: 'dak_rve',
            parentId: 614,
          },
          {
            title: 'Xã Đắk Kôi',
            code: 23482,
            value: 'xa_dak_koi',
            division_type: 'xã',
            short_codename: 'dak_koi',
            parentId: 614,
          },
          {
            title: 'Xã Đắk Tơ Lung',
            code: 23485,
            value: 'xa_dak_to_lung',
            division_type: 'xã',
            short_codename: 'dak_to_lung',
            parentId: 614,
          },
          {
            title: 'Xã Đắk Ruồng',
            code: 23488,
            value: 'xa_dak_ruong',
            division_type: 'xã',
            short_codename: 'dak_ruong',
            parentId: 614,
          },
          {
            title: 'Xã Đắk Pne',
            code: 23491,
            value: 'xa_dak_pne',
            division_type: 'xã',
            short_codename: 'dak_pne',
            parentId: 614,
          },
          {
            title: 'Xã Đắk Tờ Re',
            code: 23494,
            value: 'xa_dak_to_re',
            division_type: 'xã',
            short_codename: 'dak_to_re',
            parentId: 614,
          },
          {
            title: 'Xã Tân Lập',
            code: 23497,
            value: 'xa_tan_lap',
            division_type: 'xã',
            short_codename: 'tan_lap',
            parentId: 614,
          },
        ],
        parentId: 62,
      },
      {
        title: 'Huyện Đắk Hà',
        code: 615,
        value: 'huyen_dak_ha',
        division_type: 'huyện',
        short_codename: 'dak_ha',
        children: [
          {
            title: 'Thị trấn Đắk Hà',
            code: 23500,
            value: 'thi_tran_dak_ha',
            division_type: 'thị trấn',
            short_codename: 'dak_ha',
            parentId: 615,
          },
          {
            title: 'Xã Đắk PXi',
            code: 23503,
            value: 'xa_dak_pxi',
            division_type: 'xã',
            short_codename: 'dak_pxi',
            parentId: 615,
          },
          {
            title: 'Xã Đăk Long',
            code: 23504,
            value: 'xa_dak_long',
            division_type: 'xã',
            short_codename: 'dak_long',
            parentId: 615,
          },
          {
            title: 'Xã Đắk HRing',
            code: 23506,
            value: 'xa_dak_hring',
            division_type: 'xã',
            short_codename: 'dak_hring',
            parentId: 615,
          },
          {
            title: 'Xã Đắk Ui',
            code: 23509,
            value: 'xa_dak_ui',
            division_type: 'xã',
            short_codename: 'dak_ui',
            parentId: 615,
          },
          {
            title: 'Xã Đăk Ngọk',
            code: 23510,
            value: 'xa_dak_ngok',
            division_type: 'xã',
            short_codename: 'dak_ngok',
            parentId: 615,
          },
          {
            title: 'Xã Đắk Mar',
            code: 23512,
            value: 'xa_dak_mar',
            division_type: 'xã',
            short_codename: 'dak_mar',
            parentId: 615,
          },
          {
            title: 'Xã Ngok Wang',
            code: 23515,
            value: 'xa_ngok_wang',
            division_type: 'xã',
            short_codename: 'ngok_wang',
            parentId: 615,
          },
          {
            title: 'Xã Ngok Réo',
            code: 23518,
            value: 'xa_ngok_reo',
            division_type: 'xã',
            short_codename: 'ngok_reo',
            parentId: 615,
          },
          {
            title: 'Xã Hà Mòn',
            code: 23521,
            value: 'xa_ha_mon',
            division_type: 'xã',
            short_codename: 'ha_mon',
            parentId: 615,
          },
          {
            title: 'Xã Đắk La',
            code: 23524,
            value: 'xa_dak_la',
            division_type: 'xã',
            short_codename: 'dak_la',
            parentId: 615,
          },
        ],
        parentId: 62,
      },
      {
        title: 'Huyện Sa Thầy',
        code: 616,
        value: 'huyen_sa_thay',
        division_type: 'huyện',
        short_codename: 'sa_thay',
        children: [
          {
            title: 'Thị trấn Sa Thầy',
            code: 23527,
            value: 'thi_tran_sa_thay',
            division_type: 'thị trấn',
            short_codename: 'sa_thay',
            parentId: 616,
          },
          {
            title: 'Xã Rơ Kơi',
            code: 23530,
            value: 'xa_ro_koi',
            division_type: 'xã',
            short_codename: 'ro_koi',
            parentId: 616,
          },
          {
            title: 'Xã Sa Nhơn',
            code: 23533,
            value: 'xa_sa_nhon',
            division_type: 'xã',
            short_codename: 'sa_nhon',
            parentId: 616,
          },
          {
            title: 'Xã Hơ Moong',
            code: 23534,
            value: 'xa_ho_moong',
            division_type: 'xã',
            short_codename: 'ho_moong',
            parentId: 616,
          },
          {
            title: 'Xã Mô Rai',
            code: 23536,
            value: 'xa_mo_rai',
            division_type: 'xã',
            short_codename: 'mo_rai',
            parentId: 616,
          },
          {
            title: 'Xã Sa Sơn',
            code: 23539,
            value: 'xa_sa_son',
            division_type: 'xã',
            short_codename: 'sa_son',
            parentId: 616,
          },
          {
            title: 'Xã Sa Nghĩa',
            code: 23542,
            value: 'xa_sa_nghia',
            division_type: 'xã',
            short_codename: 'sa_nghia',
            parentId: 616,
          },
          {
            title: 'Xã Sa Bình',
            code: 23545,
            value: 'xa_sa_binh',
            division_type: 'xã',
            short_codename: 'sa_binh',
            parentId: 616,
          },
          {
            title: 'Xã Ya Xiêr',
            code: 23548,
            value: 'xa_ya_xier',
            division_type: 'xã',
            short_codename: 'ya_xier',
            parentId: 616,
          },
          {
            title: 'Xã Ya Tăng',
            code: 23551,
            value: 'xa_ya_tang',
            division_type: 'xã',
            short_codename: 'ya_tang',
            parentId: 616,
          },
          {
            title: 'Xã Ya ly',
            code: 23554,
            value: 'xa_ya_ly',
            division_type: 'xã',
            short_codename: 'ya_ly',
            parentId: 616,
          },
        ],
        parentId: 62,
      },
      {
        title: 'Huyện Tu Mơ Rông',
        code: 617,
        value: 'huyen_tu_mo_rong',
        division_type: 'huyện',
        short_codename: 'tu_mo_rong',
        children: [
          {
            title: 'Xã Ngọc Lây',
            code: 23404,
            value: 'xa_ngoc_lay',
            division_type: 'xã',
            short_codename: 'ngoc_lay',
            parentId: 617,
          },
          {
            title: 'Xã Đắk Na',
            code: 23407,
            value: 'xa_dak_na',
            division_type: 'xã',
            short_codename: 'dak_na',
            parentId: 617,
          },
          {
            title: 'Xã Măng Ri',
            code: 23410,
            value: 'xa_mang_ri',
            division_type: 'xã',
            short_codename: 'mang_ri',
            parentId: 617,
          },
          {
            title: 'Xã Ngọc Yêu',
            code: 23413,
            value: 'xa_ngoc_yeu',
            division_type: 'xã',
            short_codename: 'ngoc_yeu',
            parentId: 617,
          },
          {
            title: 'Xã Đắk Sao',
            code: 23416,
            value: 'xa_dak_sao',
            division_type: 'xã',
            short_codename: 'dak_sao',
            parentId: 617,
          },
          {
            title: 'Xã Đắk Rơ Ông',
            code: 23417,
            value: 'xa_dak_ro_ong',
            division_type: 'xã',
            short_codename: 'dak_ro_ong',
            parentId: 617,
          },
          {
            title: 'Xã Đắk Tờ Kan',
            code: 23419,
            value: 'xa_dak_to_kan',
            division_type: 'xã',
            short_codename: 'dak_to_kan',
            parentId: 617,
          },
          {
            title: 'Xã Tu Mơ Rông',
            code: 23422,
            value: 'xa_tu_mo_rong',
            division_type: 'xã',
            short_codename: 'tu_mo_rong',
            parentId: 617,
          },
          {
            title: 'Xã Đắk Hà',
            code: 23425,
            value: 'xa_dak_ha',
            division_type: 'xã',
            short_codename: 'dak_ha',
            parentId: 617,
          },
          {
            title: 'Xã Tê Xăng',
            code: 23446,
            value: 'xa_te_xang',
            division_type: 'xã',
            short_codename: 'te_xang',
            parentId: 617,
          },
          {
            title: 'Xã Văn Xuôi',
            code: 23449,
            value: 'xa_van_xuoi',
            division_type: 'xã',
            short_codename: 'van_xuoi',
            parentId: 617,
          },
        ],
        parentId: 62,
      },
      {
        title: "Huyện Ia H' Drai",
        code: 618,
        value: 'huyen_ia_h_drai',
        division_type: 'huyện',
        short_codename: 'ia_h_drai',
        children: [
          {
            title: 'Xã Ia Đal',
            code: 23535,
            value: 'xa_ia_dal',
            division_type: 'xã',
            short_codename: 'ia_dal',
            parentId: 618,
          },
          {
            title: 'Xã Ia Dom',
            code: 23537,
            value: 'xa_ia_dom',
            division_type: 'xã',
            short_codename: 'ia_dom',
            parentId: 618,
          },
          {
            title: 'Xã Ia Tơi',
            code: 23538,
            value: 'xa_ia_toi',
            division_type: 'xã',
            short_codename: 'ia_toi',
            parentId: 618,
          },
        ],
        parentId: 62,
      },
    ],
  },
  {
    title: 'Tỉnh Gia Lai',
    code: 64,
    value: 'tinh_gia_lai',
    division_type: 'tỉnh',
    phone_code: 269,
    children: [
      {
        title: 'Thành phố Pleiku',
        code: 622,
        value: 'thanh_pho_pleiku',
        division_type: 'thành phố',
        short_codename: 'pleiku',
        children: [
          {
            title: 'Phường Yên Đỗ',
            code: 23557,
            value: 'phuong_yen_do',
            division_type: 'phường',
            short_codename: 'yen_do',
            parentId: 622,
          },
          {
            title: 'Phường Diên Hồng',
            code: 23560,
            value: 'phuong_dien_hong',
            division_type: 'phường',
            short_codename: 'dien_hong',
            parentId: 622,
          },
          {
            title: 'Phường Ia Kring',
            code: 23563,
            value: 'phuong_ia_kring',
            division_type: 'phường',
            short_codename: 'ia_kring',
            parentId: 622,
          },
          {
            title: 'Phường Hội Thương',
            code: 23566,
            value: 'phuong_hoi_thuong',
            division_type: 'phường',
            short_codename: 'hoi_thuong',
            parentId: 622,
          },
          {
            title: 'Phường Hội Phú',
            code: 23569,
            value: 'phuong_hoi_phu',
            division_type: 'phường',
            short_codename: 'hoi_phu',
            parentId: 622,
          },
          {
            title: 'Phường Phù Đổng',
            code: 23570,
            value: 'phuong_phu_dong',
            division_type: 'phường',
            short_codename: 'phu_dong',
            parentId: 622,
          },
          {
            title: 'Phường Hoa Lư',
            code: 23572,
            value: 'phuong_hoa_lu',
            division_type: 'phường',
            short_codename: 'hoa_lu',
            parentId: 622,
          },
          {
            title: 'Phường Tây Sơn',
            code: 23575,
            value: 'phuong_tay_son',
            division_type: 'phường',
            short_codename: 'tay_son',
            parentId: 622,
          },
          {
            title: 'Phường Thống Nhất',
            code: 23578,
            value: 'phuong_thong_nhat',
            division_type: 'phường',
            short_codename: 'thong_nhat',
            parentId: 622,
          },
          {
            title: 'Phường Đống Đa',
            code: 23579,
            value: 'phuong_dong_da',
            division_type: 'phường',
            short_codename: 'dong_da',
            parentId: 622,
          },
          {
            title: 'Phường Trà Bá',
            code: 23581,
            value: 'phuong_tra_ba',
            division_type: 'phường',
            short_codename: 'tra_ba',
            parentId: 622,
          },
          {
            title: 'Phường Thắng Lợi',
            code: 23582,
            value: 'phuong_thang_loi',
            division_type: 'phường',
            short_codename: 'thang_loi',
            parentId: 622,
          },
          {
            title: 'Phường Yên Thế',
            code: 23584,
            value: 'phuong_yen_the',
            division_type: 'phường',
            short_codename: 'yen_the',
            parentId: 622,
          },
          {
            title: 'Phường Chi Lăng',
            code: 23586,
            value: 'phuong_chi_lang',
            division_type: 'phường',
            short_codename: 'chi_lang',
            parentId: 622,
          },
          {
            title: 'Xã Biển Hồ',
            code: 23590,
            value: 'xa_bien_ho',
            division_type: 'xã',
            short_codename: 'bien_ho',
            parentId: 622,
          },
          {
            title: 'Xã Tân Sơn',
            code: 23593,
            value: 'xa_tan_son',
            division_type: 'xã',
            short_codename: 'tan_son',
            parentId: 622,
          },
          {
            title: 'Xã Trà Đa',
            code: 23596,
            value: 'xa_tra_da',
            division_type: 'xã',
            short_codename: 'tra_da',
            parentId: 622,
          },
          {
            title: 'Xã Chư Á',
            code: 23599,
            value: 'xa_chu_a',
            division_type: 'xã',
            short_codename: 'chu_a',
            parentId: 622,
          },
          {
            title: 'Xã An Phú',
            code: 23602,
            value: 'xa_an_phu',
            division_type: 'xã',
            short_codename: 'an_phu',
            parentId: 622,
          },
          {
            title: 'Xã Diên Phú',
            code: 23605,
            value: 'xa_dien_phu',
            division_type: 'xã',
            short_codename: 'dien_phu',
            parentId: 622,
          },
          {
            title: 'Xã Ia Kênh',
            code: 23608,
            value: 'xa_ia_kenh',
            division_type: 'xã',
            short_codename: 'ia_kenh',
            parentId: 622,
          },
          {
            title: 'Xã Gào',
            code: 23611,
            value: 'xa_gao',
            division_type: 'xã',
            short_codename: 'gao',
            parentId: 622,
          },
        ],
        parentId: 64,
      },
      {
        title: 'Thị xã An Khê',
        code: 623,
        value: 'thi_xa_an_khe',
        division_type: 'thị xã',
        short_codename: 'an_khe',
        children: [
          {
            title: 'Phường An Bình',
            code: 23614,
            value: 'phuong_an_binh',
            division_type: 'phường',
            short_codename: 'an_binh',
            parentId: 623,
          },
          {
            title: 'Phường Tây Sơn',
            code: 23617,
            value: 'phuong_tay_son',
            division_type: 'phường',
            short_codename: 'tay_son',
            parentId: 623,
          },
          {
            title: 'Phường An Phú',
            code: 23620,
            value: 'phuong_an_phu',
            division_type: 'phường',
            short_codename: 'an_phu',
            parentId: 623,
          },
          {
            title: 'Phường An Tân',
            code: 23623,
            value: 'phuong_an_tan',
            division_type: 'phường',
            short_codename: 'an_tan',
            parentId: 623,
          },
          {
            title: 'Xã Tú An',
            code: 23626,
            value: 'xa_tu_an',
            division_type: 'xã',
            short_codename: 'tu_an',
            parentId: 623,
          },
          {
            title: 'Xã Xuân An',
            code: 23627,
            value: 'xa_xuan_an',
            division_type: 'xã',
            short_codename: 'xuan_an',
            parentId: 623,
          },
          {
            title: 'Xã Cửu An',
            code: 23629,
            value: 'xa_cuu_an',
            division_type: 'xã',
            short_codename: 'cuu_an',
            parentId: 623,
          },
          {
            title: 'Phường An Phước',
            code: 23630,
            value: 'phuong_an_phuoc',
            division_type: 'phường',
            short_codename: 'an_phuoc',
            parentId: 623,
          },
          {
            title: 'Xã Song An',
            code: 23632,
            value: 'xa_song_an',
            division_type: 'xã',
            short_codename: 'song_an',
            parentId: 623,
          },
          {
            title: 'Phường Ngô Mây',
            code: 23633,
            value: 'phuong_ngo_may',
            division_type: 'phường',
            short_codename: 'ngo_may',
            parentId: 623,
          },
          {
            title: 'Xã Thành An',
            code: 23635,
            value: 'xa_thanh_an',
            division_type: 'xã',
            short_codename: 'thanh_an',
            parentId: 623,
          },
        ],
        parentId: 64,
      },
      {
        title: 'Thị xã Ayun Pa',
        code: 624,
        value: 'thi_xa_ayun_pa',
        division_type: 'thị xã',
        short_codename: 'ayun_pa',
        children: [
          {
            title: 'Phường Cheo Reo',
            code: 24041,
            value: 'phuong_cheo_reo',
            division_type: 'phường',
            short_codename: 'cheo_reo',
            parentId: 624,
          },
          {
            title: 'Phường Hòa Bình',
            code: 24042,
            value: 'phuong_hoa_binh',
            division_type: 'phường',
            short_codename: 'hoa_binh',
            parentId: 624,
          },
          {
            title: 'Phường Đoàn Kết',
            code: 24044,
            value: 'phuong_doan_ket',
            division_type: 'phường',
            short_codename: 'doan_ket',
            parentId: 624,
          },
          {
            title: 'Phường Sông Bờ',
            code: 24045,
            value: 'phuong_song_bo',
            division_type: 'phường',
            short_codename: 'song_bo',
            parentId: 624,
          },
          {
            title: 'Xã Ia RBol',
            code: 24064,
            value: 'xa_ia_rbol',
            division_type: 'xã',
            short_codename: 'ia_rbol',
            parentId: 624,
          },
          {
            title: 'Xã Chư Băh',
            code: 24065,
            value: 'xa_chu_bah',
            division_type: 'xã',
            short_codename: 'chu_bah',
            parentId: 624,
          },
          {
            title: 'Xã Ia RTô',
            code: 24070,
            value: 'xa_ia_rto',
            division_type: 'xã',
            short_codename: 'ia_rto',
            parentId: 624,
          },
          {
            title: 'Xã Ia Sao',
            code: 24073,
            value: 'xa_ia_sao',
            division_type: 'xã',
            short_codename: 'ia_sao',
            parentId: 624,
          },
        ],
        parentId: 64,
      },
      {
        title: 'Huyện KBang',
        code: 625,
        value: 'huyen_kbang',
        division_type: 'huyện',
        short_codename: 'kbang',
        children: [
          {
            title: 'Thị trấn KBang',
            code: 23638,
            value: 'thi_tran_kbang',
            division_type: 'thị trấn',
            short_codename: 'kbang',
            parentId: 625,
          },
          {
            title: 'Xã Kon Pne',
            code: 23641,
            value: 'xa_kon_pne',
            division_type: 'xã',
            short_codename: 'kon_pne',
            parentId: 625,
          },
          {
            title: 'Xã Đăk Roong',
            code: 23644,
            value: 'xa_dak_roong',
            division_type: 'xã',
            short_codename: 'dak_roong',
            parentId: 625,
          },
          {
            title: 'Xã Sơn Lang',
            code: 23647,
            value: 'xa_son_lang',
            division_type: 'xã',
            short_codename: 'son_lang',
            parentId: 625,
          },
          {
            title: 'Xã KRong',
            code: 23650,
            value: 'xa_krong',
            division_type: 'xã',
            short_codename: 'krong',
            parentId: 625,
          },
          {
            title: 'Xã Sơ Pai',
            code: 23653,
            value: 'xa_so_pai',
            division_type: 'xã',
            short_codename: 'so_pai',
            parentId: 625,
          },
          {
            title: 'Xã Lơ Ku',
            code: 23656,
            value: 'xa_lo_ku',
            division_type: 'xã',
            short_codename: 'lo_ku',
            parentId: 625,
          },
          {
            title: 'Xã Đông',
            code: 23659,
            value: 'xa_dong',
            division_type: 'xã',
            short_codename: 'dong',
            parentId: 625,
          },
          {
            title: 'Xã Đak SMar',
            code: 23660,
            value: 'xa_dak_smar',
            division_type: 'xã',
            short_codename: 'dak_smar',
            parentId: 625,
          },
          {
            title: 'Xã Nghĩa An',
            code: 23662,
            value: 'xa_nghia_an',
            division_type: 'xã',
            short_codename: 'nghia_an',
            parentId: 625,
          },
          {
            title: 'Xã Tơ Tung',
            code: 23665,
            value: 'xa_to_tung',
            division_type: 'xã',
            short_codename: 'to_tung',
            parentId: 625,
          },
          {
            title: 'Xã Kông Lơng Khơng',
            code: 23668,
            value: 'xa_kong_long_khong',
            division_type: 'xã',
            short_codename: 'kong_long_khong',
            parentId: 625,
          },
          {
            title: 'Xã Kông Pla',
            code: 23671,
            value: 'xa_kong_pla',
            division_type: 'xã',
            short_codename: 'kong_pla',
            parentId: 625,
          },
          {
            title: 'Xã Đăk HLơ',
            code: 23674,
            value: 'xa_dak_hlo',
            division_type: 'xã',
            short_codename: 'dak_hlo',
            parentId: 625,
          },
        ],
        parentId: 64,
      },
      {
        title: 'Huyện Đăk Đoa',
        code: 626,
        value: 'huyen_dak_doa',
        division_type: 'huyện',
        short_codename: 'dak_doa',
        children: [
          {
            title: 'Thị trấn Đăk Đoa',
            code: 23677,
            value: 'thi_tran_dak_doa',
            division_type: 'thị trấn',
            short_codename: 'dak_doa',
            parentId: 626,
          },
          {
            title: 'Xã Hà Đông',
            code: 23680,
            value: 'xa_ha_dong',
            division_type: 'xã',
            short_codename: 'ha_dong',
            parentId: 626,
          },
          {
            title: 'Xã Đăk Sơmei',
            code: 23683,
            value: 'xa_dak_somei',
            division_type: 'xã',
            short_codename: 'dak_somei',
            parentId: 626,
          },
          {
            title: 'Xã Đăk Krong',
            code: 23684,
            value: 'xa_dak_krong',
            division_type: 'xã',
            short_codename: 'dak_krong',
            parentId: 626,
          },
          {
            title: 'Xã Hải Yang',
            code: 23686,
            value: 'xa_hai_yang',
            division_type: 'xã',
            short_codename: 'hai_yang',
            parentId: 626,
          },
          {
            title: 'Xã Kon Gang',
            code: 23689,
            value: 'xa_kon_gang',
            division_type: 'xã',
            short_codename: 'kon_gang',
            parentId: 626,
          },
          {
            title: 'Xã Hà Bầu',
            code: 23692,
            value: 'xa_ha_bau',
            division_type: 'xã',
            short_codename: 'ha_bau',
            parentId: 626,
          },
          {
            title: 'Xã Nam Yang',
            code: 23695,
            value: 'xa_nam_yang',
            division_type: 'xã',
            short_codename: 'nam_yang',
            parentId: 626,
          },
          {
            title: "Xã K' Dang",
            code: 23698,
            value: 'xa_k_dang',
            division_type: 'xã',
            short_codename: 'k_dang',
            parentId: 626,
          },
          {
            title: "Xã H' Neng",
            code: 23701,
            value: 'xa_h_neng',
            division_type: 'xã',
            short_codename: 'h_neng',
            parentId: 626,
          },
          {
            title: 'Xã Tân Bình',
            code: 23704,
            value: 'xa_tan_binh',
            division_type: 'xã',
            short_codename: 'tan_binh',
            parentId: 626,
          },
          {
            title: 'Xã Glar',
            code: 23707,
            value: 'xa_glar',
            division_type: 'xã',
            short_codename: 'glar',
            parentId: 626,
          },
          {
            title: 'Xã A Dơk',
            code: 23710,
            value: 'xa_a_dok',
            division_type: 'xã',
            short_codename: 'a_dok',
            parentId: 626,
          },
          {
            title: 'Xã Trang',
            code: 23713,
            value: 'xa_trang',
            division_type: 'xã',
            short_codename: 'trang',
            parentId: 626,
          },
          {
            title: 'Xã HNol',
            code: 23714,
            value: 'xa_hnol',
            division_type: 'xã',
            short_codename: 'hnol',
            parentId: 626,
          },
          {
            title: 'Xã Ia Pết',
            code: 23716,
            value: 'xa_ia_pet',
            division_type: 'xã',
            short_codename: 'ia_pet',
            parentId: 626,
          },
          {
            title: 'Xã Ia Băng',
            code: 23719,
            value: 'xa_ia_bang',
            division_type: 'xã',
            short_codename: 'ia_bang',
            parentId: 626,
          },
        ],
        parentId: 64,
      },
      {
        title: 'Huyện Chư Păh',
        code: 627,
        value: 'huyen_chu_pah',
        division_type: 'huyện',
        short_codename: 'chu_pah',
        children: [
          {
            title: 'Thị trấn Phú Hòa',
            code: 23722,
            value: 'thi_tran_phu_hoa',
            division_type: 'thị trấn',
            short_codename: 'phu_hoa',
            parentId: 627,
          },
          {
            title: 'Xã Hà Tây',
            code: 23725,
            value: 'xa_ha_tay',
            division_type: 'xã',
            short_codename: 'ha_tay',
            parentId: 627,
          },
          {
            title: 'Xã Ia Khươl',
            code: 23728,
            value: 'xa_ia_khuol',
            division_type: 'xã',
            short_codename: 'ia_khuol',
            parentId: 627,
          },
          {
            title: 'Xã Ia Phí',
            code: 23731,
            value: 'xa_ia_phi',
            division_type: 'xã',
            short_codename: 'ia_phi',
            parentId: 627,
          },
          {
            title: 'Thị trấn Ia Ly',
            code: 23734,
            value: 'thi_tran_ia_ly',
            division_type: 'thị trấn',
            short_codename: 'ia_ly',
            parentId: 627,
          },
          {
            title: 'Xã Ia Mơ Nông',
            code: 23737,
            value: 'xa_ia_mo_nong',
            division_type: 'xã',
            short_codename: 'ia_mo_nong',
            parentId: 627,
          },
          {
            title: 'Xã Ia Kreng',
            code: 23738,
            value: 'xa_ia_kreng',
            division_type: 'xã',
            short_codename: 'ia_kreng',
            parentId: 627,
          },
          {
            title: 'Xã Đăk Tơ Ver',
            code: 23740,
            value: 'xa_dak_to_ver',
            division_type: 'xã',
            short_codename: 'dak_to_ver',
            parentId: 627,
          },
          {
            title: 'Xã Hòa Phú',
            code: 23743,
            value: 'xa_hoa_phu',
            division_type: 'xã',
            short_codename: 'hoa_phu',
            parentId: 627,
          },
          {
            title: 'Xã Chư Đăng Ya',
            code: 23746,
            value: 'xa_chu_dang_ya',
            division_type: 'xã',
            short_codename: 'chu_dang_ya',
            parentId: 627,
          },
          {
            title: 'Xã Ia Ka',
            code: 23749,
            value: 'xa_ia_ka',
            division_type: 'xã',
            short_codename: 'ia_ka',
            parentId: 627,
          },
          {
            title: 'Xã Ia Nhin',
            code: 23752,
            value: 'xa_ia_nhin',
            division_type: 'xã',
            short_codename: 'ia_nhin',
            parentId: 627,
          },
          {
            title: 'Xã Nghĩa Hòa',
            code: 23755,
            value: 'xa_nghia_hoa',
            division_type: 'xã',
            short_codename: 'nghia_hoa',
            parentId: 627,
          },
          {
            title: 'Xã Nghĩa Hưng',
            code: 23761,
            value: 'xa_nghia_hung',
            division_type: 'xã',
            short_codename: 'nghia_hung',
            parentId: 627,
          },
        ],
        parentId: 64,
      },
      {
        title: 'Huyện Ia Grai',
        code: 628,
        value: 'huyen_ia_grai',
        division_type: 'huyện',
        short_codename: 'ia_grai',
        children: [
          {
            title: 'Thị trấn Ia Kha',
            code: 23764,
            value: 'thi_tran_ia_kha',
            division_type: 'thị trấn',
            short_codename: 'ia_kha',
            parentId: 628,
          },
          {
            title: 'Xã Ia Sao',
            code: 23767,
            value: 'xa_ia_sao',
            division_type: 'xã',
            short_codename: 'ia_sao',
            parentId: 628,
          },
          {
            title: 'Xã Ia Yok',
            code: 23768,
            value: 'xa_ia_yok',
            division_type: 'xã',
            short_codename: 'ia_yok',
            parentId: 628,
          },
          {
            title: 'Xã Ia Hrung',
            code: 23770,
            value: 'xa_ia_hrung',
            division_type: 'xã',
            short_codename: 'ia_hrung',
            parentId: 628,
          },
          {
            title: 'Xã Ia Bă',
            code: 23771,
            value: 'xa_ia_ba',
            division_type: 'xã',
            short_codename: 'ia_ba',
            parentId: 628,
          },
          {
            title: 'Xã Ia Khai',
            code: 23773,
            value: 'xa_ia_khai',
            division_type: 'xã',
            short_codename: 'ia_khai',
            parentId: 628,
          },
          {
            title: 'Xã Ia KRai',
            code: 23776,
            value: 'xa_ia_krai',
            division_type: 'xã',
            short_codename: 'ia_krai',
            parentId: 628,
          },
          {
            title: 'Xã Ia Grăng',
            code: 23778,
            value: 'xa_ia_grang',
            division_type: 'xã',
            short_codename: 'ia_grang',
            parentId: 628,
          },
          {
            title: 'Xã Ia Tô',
            code: 23779,
            value: 'xa_ia_to',
            division_type: 'xã',
            short_codename: 'ia_to',
            parentId: 628,
          },
          {
            title: 'Xã Ia O',
            code: 23782,
            value: 'xa_ia_o',
            division_type: 'xã',
            short_codename: 'ia_o',
            parentId: 628,
          },
          {
            title: 'Xã Ia Dêr',
            code: 23785,
            value: 'xa_ia_der',
            division_type: 'xã',
            short_codename: 'ia_der',
            parentId: 628,
          },
          {
            title: 'Xã Ia Chia',
            code: 23788,
            value: 'xa_ia_chia',
            division_type: 'xã',
            short_codename: 'ia_chia',
            parentId: 628,
          },
          {
            title: 'Xã Ia Pếch',
            code: 23791,
            value: 'xa_ia_pech',
            division_type: 'xã',
            short_codename: 'ia_pech',
            parentId: 628,
          },
        ],
        parentId: 64,
      },
      {
        title: 'Huyện Mang Yang',
        code: 629,
        value: 'huyen_mang_yang',
        division_type: 'huyện',
        short_codename: 'mang_yang',
        children: [
          {
            title: 'Thị trấn Kon Dơng',
            code: 23794,
            value: 'thi_tran_kon_dong',
            division_type: 'thị trấn',
            short_codename: 'kon_dong',
            parentId: 629,
          },
          {
            title: 'Xã Ayun',
            code: 23797,
            value: 'xa_ayun',
            division_type: 'xã',
            short_codename: 'ayun',
            parentId: 629,
          },
          {
            title: 'Xã Đak Jơ Ta',
            code: 23798,
            value: 'xa_dak_jo_ta',
            division_type: 'xã',
            short_codename: 'dak_jo_ta',
            parentId: 629,
          },
          {
            title: 'Xã Đak Ta Ley',
            code: 23799,
            value: 'xa_dak_ta_ley',
            division_type: 'xã',
            short_codename: 'dak_ta_ley',
            parentId: 629,
          },
          {
            title: 'Xã Hra',
            code: 23800,
            value: 'xa_hra',
            division_type: 'xã',
            short_codename: 'hra',
            parentId: 629,
          },
          {
            title: 'Xã Đăk Yă',
            code: 23803,
            value: 'xa_dak_ya',
            division_type: 'xã',
            short_codename: 'dak_ya',
            parentId: 629,
          },
          {
            title: 'Xã Đăk Djrăng',
            code: 23806,
            value: 'xa_dak_djrang',
            division_type: 'xã',
            short_codename: 'dak_djrang',
            parentId: 629,
          },
          {
            title: 'Xã Lơ Pang',
            code: 23809,
            value: 'xa_lo_pang',
            division_type: 'xã',
            short_codename: 'lo_pang',
            parentId: 629,
          },
          {
            title: 'Xã Kon Thụp',
            code: 23812,
            value: 'xa_kon_thup',
            division_type: 'xã',
            short_codename: 'kon_thup',
            parentId: 629,
          },
          {
            title: 'Xã Đê Ar',
            code: 23815,
            value: 'xa_de_ar',
            division_type: 'xã',
            short_codename: 'de_ar',
            parentId: 629,
          },
          {
            title: 'Xã Kon Chiêng',
            code: 23818,
            value: 'xa_kon_chieng',
            division_type: 'xã',
            short_codename: 'kon_chieng',
            parentId: 629,
          },
          {
            title: 'Xã Đăk Trôi',
            code: 23821,
            value: 'xa_dak_troi',
            division_type: 'xã',
            short_codename: 'dak_troi',
            parentId: 629,
          },
        ],
        parentId: 64,
      },
      {
        title: 'Huyện Kông Chro',
        code: 630,
        value: 'huyen_kong_chro',
        division_type: 'huyện',
        short_codename: 'kong_chro',
        children: [
          {
            title: 'Thị trấn Kông Chro',
            code: 23824,
            value: 'thi_tran_kong_chro',
            division_type: 'thị trấn',
            short_codename: 'kong_chro',
            parentId: 630,
          },
          {
            title: 'Xã Chư Krêy',
            code: 23827,
            value: 'xa_chu_krey',
            division_type: 'xã',
            short_codename: 'chu_krey',
            parentId: 630,
          },
          {
            title: 'Xã An Trung',
            code: 23830,
            value: 'xa_an_trung',
            division_type: 'xã',
            short_codename: 'an_trung',
            parentId: 630,
          },
          {
            title: 'Xã Kông Yang',
            code: 23833,
            value: 'xa_kong_yang',
            division_type: 'xã',
            short_codename: 'kong_yang',
            parentId: 630,
          },
          {
            title: 'Xã Đăk Tơ Pang',
            code: 23836,
            value: 'xa_dak_to_pang',
            division_type: 'xã',
            short_codename: 'dak_to_pang',
            parentId: 630,
          },
          {
            title: 'Xã SRó',
            code: 23839,
            value: 'xa_sro',
            division_type: 'xã',
            short_codename: 'sro',
            parentId: 630,
          },
          {
            title: 'Xã Đắk Kơ Ning',
            code: 23840,
            value: 'xa_dak_ko_ning',
            division_type: 'xã',
            short_codename: 'dak_ko_ning',
            parentId: 630,
          },
          {
            title: 'Xã Đăk Song',
            code: 23842,
            value: 'xa_dak_song',
            division_type: 'xã',
            short_codename: 'dak_song',
            parentId: 630,
          },
          {
            title: 'Xã Đăk Pling',
            code: 23843,
            value: 'xa_dak_pling',
            division_type: 'xã',
            short_codename: 'dak_pling',
            parentId: 630,
          },
          {
            title: 'Xã Yang Trung',
            code: 23845,
            value: 'xa_yang_trung',
            division_type: 'xã',
            short_codename: 'yang_trung',
            parentId: 630,
          },
          {
            title: 'Xã Đăk Pơ Pho',
            code: 23846,
            value: 'xa_dak_po_pho',
            division_type: 'xã',
            short_codename: 'dak_po_pho',
            parentId: 630,
          },
          {
            title: 'Xã Ya Ma',
            code: 23848,
            value: 'xa_ya_ma',
            division_type: 'xã',
            short_codename: 'ya_ma',
            parentId: 630,
          },
          {
            title: 'Xã Chơ Long',
            code: 23851,
            value: 'xa_cho_long',
            division_type: 'xã',
            short_codename: 'cho_long',
            parentId: 630,
          },
          {
            title: 'Xã Yang Nam',
            code: 23854,
            value: 'xa_yang_nam',
            division_type: 'xã',
            short_codename: 'yang_nam',
            parentId: 630,
          },
        ],
        parentId: 64,
      },
      {
        title: 'Huyện Đức Cơ',
        code: 631,
        value: 'huyen_duc_co',
        division_type: 'huyện',
        short_codename: 'duc_co',
        children: [
          {
            title: 'Thị trấn Chư Ty',
            code: 23857,
            value: 'thi_tran_chu_ty',
            division_type: 'thị trấn',
            short_codename: 'chu_ty',
            parentId: 631,
          },
          {
            title: 'Xã Ia Dơk',
            code: 23860,
            value: 'xa_ia_dok',
            division_type: 'xã',
            short_codename: 'ia_dok',
            parentId: 631,
          },
          {
            title: 'Xã Ia Krêl',
            code: 23863,
            value: 'xa_ia_krel',
            division_type: 'xã',
            short_codename: 'ia_krel',
            parentId: 631,
          },
          {
            title: 'Xã Ia Din',
            code: 23866,
            value: 'xa_ia_din',
            division_type: 'xã',
            short_codename: 'ia_din',
            parentId: 631,
          },
          {
            title: 'Xã Ia Kla',
            code: 23869,
            value: 'xa_ia_kla',
            division_type: 'xã',
            short_codename: 'ia_kla',
            parentId: 631,
          },
          {
            title: 'Xã Ia Dom',
            code: 23872,
            value: 'xa_ia_dom',
            division_type: 'xã',
            short_codename: 'ia_dom',
            parentId: 631,
          },
          {
            title: 'Xã Ia Lang',
            code: 23875,
            value: 'xa_ia_lang',
            division_type: 'xã',
            short_codename: 'ia_lang',
            parentId: 631,
          },
          {
            title: 'Xã Ia Kriêng',
            code: 23878,
            value: 'xa_ia_krieng',
            division_type: 'xã',
            short_codename: 'ia_krieng',
            parentId: 631,
          },
          {
            title: 'Xã Ia Pnôn',
            code: 23881,
            value: 'xa_ia_pnon',
            division_type: 'xã',
            short_codename: 'ia_pnon',
            parentId: 631,
          },
          {
            title: 'Xã Ia Nan',
            code: 23884,
            value: 'xa_ia_nan',
            division_type: 'xã',
            short_codename: 'ia_nan',
            parentId: 631,
          },
        ],
        parentId: 64,
      },
      {
        title: 'Huyện Chư Prông',
        code: 632,
        value: 'huyen_chu_prong',
        division_type: 'huyện',
        short_codename: 'chu_prong',
        children: [
          {
            title: 'Thị trấn Chư Prông',
            code: 23887,
            value: 'thi_tran_chu_prong',
            division_type: 'thị trấn',
            short_codename: 'chu_prong',
            parentId: 632,
          },
          {
            title: 'Xã Ia Kly',
            code: 23888,
            value: 'xa_ia_kly',
            division_type: 'xã',
            short_codename: 'ia_kly',
            parentId: 632,
          },
          {
            title: 'Xã Bình Giáo',
            code: 23890,
            value: 'xa_binh_giao',
            division_type: 'xã',
            short_codename: 'binh_giao',
            parentId: 632,
          },
          {
            title: 'Xã Ia Drăng',
            code: 23893,
            value: 'xa_ia_drang',
            division_type: 'xã',
            short_codename: 'ia_drang',
            parentId: 632,
          },
          {
            title: 'Xã Thăng Hưng',
            code: 23896,
            value: 'xa_thang_hung',
            division_type: 'xã',
            short_codename: 'thang_hung',
            parentId: 632,
          },
          {
            title: 'Xã Bàu Cạn',
            code: 23899,
            value: 'xa_bau_can',
            division_type: 'xã',
            short_codename: 'bau_can',
            parentId: 632,
          },
          {
            title: 'Xã Ia Phìn',
            code: 23902,
            value: 'xa_ia_phin',
            division_type: 'xã',
            short_codename: 'ia_phin',
            parentId: 632,
          },
          {
            title: 'Xã Ia Băng',
            code: 23905,
            value: 'xa_ia_bang',
            division_type: 'xã',
            short_codename: 'xa_ia_bang',
            parentId: 632,
          },
          {
            title: 'Xã Ia Tôr',
            code: 23908,
            value: 'xa_ia_tor',
            division_type: 'xã',
            short_codename: 'ia_tor',
            parentId: 632,
          },
          {
            title: 'Xã Ia Boòng',
            code: 23911,
            value: 'xa_ia_boong',
            division_type: 'xã',
            short_codename: 'ia_boong',
            parentId: 632,
          },
          {
            title: 'Xã Ia O',
            code: 23914,
            value: 'xa_ia_o',
            division_type: 'xã',
            short_codename: 'ia_o',
            parentId: 632,
          },
          {
            title: 'Xã Ia Púch',
            code: 23917,
            value: 'xa_ia_puch',
            division_type: 'xã',
            short_codename: 'ia_puch',
            parentId: 632,
          },
          {
            title: 'Xã Ia Me',
            code: 23920,
            value: 'xa_ia_me',
            division_type: 'xã',
            short_codename: 'ia_me',
            parentId: 632,
          },
          {
            title: 'Xã Ia Vê',
            code: 23923,
            value: 'xa_ia_ve',
            division_type: 'xã',
            short_codename: 'ia_ve',
            parentId: 632,
          },
          {
            title: 'Xã Ia Bang',
            code: 23924,
            value: 'xa_ia_bang',
            division_type: 'xã',
            short_codename: 'xa_ia_bang',
            parentId: 632,
          },
          {
            title: 'Xã Ia Pia',
            code: 23926,
            value: 'xa_ia_pia',
            division_type: 'xã',
            short_codename: 'ia_pia',
            parentId: 632,
          },
          {
            title: 'Xã Ia Ga',
            code: 23929,
            value: 'xa_ia_ga',
            division_type: 'xã',
            short_codename: 'ia_ga',
            parentId: 632,
          },
          {
            title: 'Xã Ia Lâu',
            code: 23932,
            value: 'xa_ia_lau',
            division_type: 'xã',
            short_codename: 'ia_lau',
            parentId: 632,
          },
          {
            title: 'Xã Ia Piơr',
            code: 23935,
            value: 'xa_ia_pior',
            division_type: 'xã',
            short_codename: 'ia_pior',
            parentId: 632,
          },
          {
            title: 'Xã Ia Mơ',
            code: 23938,
            value: 'xa_ia_mo',
            division_type: 'xã',
            short_codename: 'ia_mo',
            parentId: 632,
          },
        ],
        parentId: 64,
      },
      {
        title: 'Huyện Chư Sê',
        code: 633,
        value: 'huyen_chu_se',
        division_type: 'huyện',
        short_codename: 'chu_se',
        children: [
          {
            title: 'Thị trấn Chư Sê',
            code: 23941,
            value: 'thi_tran_chu_se',
            division_type: 'thị trấn',
            short_codename: 'chu_se',
            parentId: 633,
          },
          {
            title: 'Xã Ia Tiêm',
            code: 23944,
            value: 'xa_ia_tiem',
            division_type: 'xã',
            short_codename: 'ia_tiem',
            parentId: 633,
          },
          {
            title: 'Xã Chư Pơng',
            code: 23945,
            value: 'xa_chu_pong',
            division_type: 'xã',
            short_codename: 'chu_pong',
            parentId: 633,
          },
          {
            title: 'Xã Bar Măih',
            code: 23946,
            value: 'xa_bar_maih',
            division_type: 'xã',
            short_codename: 'bar_maih',
            parentId: 633,
          },
          {
            title: 'Xã Bờ Ngoong',
            code: 23947,
            value: 'xa_bo_ngoong',
            division_type: 'xã',
            short_codename: 'bo_ngoong',
            parentId: 633,
          },
          {
            title: 'Xã Ia Glai',
            code: 23950,
            value: 'xa_ia_glai',
            division_type: 'xã',
            short_codename: 'ia_glai',
            parentId: 633,
          },
          {
            title: 'Xã AL Bá',
            code: 23953,
            value: 'xa_al_ba',
            division_type: 'xã',
            short_codename: 'al_ba',
            parentId: 633,
          },
          {
            title: 'Xã Kông HTok',
            code: 23954,
            value: 'xa_kong_htok',
            division_type: 'xã',
            short_codename: 'kong_htok',
            parentId: 633,
          },
          {
            title: 'Xã AYun',
            code: 23956,
            value: 'xa_ayun',
            division_type: 'xã',
            short_codename: 'ayun',
            parentId: 633,
          },
          {
            title: 'Xã Ia HLốp',
            code: 23959,
            value: 'xa_ia_hlop',
            division_type: 'xã',
            short_codename: 'ia_hlop',
            parentId: 633,
          },
          {
            title: 'Xã Ia Blang',
            code: 23962,
            value: 'xa_ia_blang',
            division_type: 'xã',
            short_codename: 'ia_blang',
            parentId: 633,
          },
          {
            title: 'Xã Dun',
            code: 23965,
            value: 'xa_dun',
            division_type: 'xã',
            short_codename: 'dun',
            parentId: 633,
          },
          {
            title: 'Xã Ia Pal',
            code: 23966,
            value: 'xa_ia_pal',
            division_type: 'xã',
            short_codename: 'ia_pal',
            parentId: 633,
          },
          {
            title: 'Xã H Bông',
            code: 23968,
            value: 'xa_h_bong',
            division_type: 'xã',
            short_codename: 'h_bong',
            parentId: 633,
          },
          {
            title: 'Xã Ia Ko',
            code: 23977,
            value: 'xa_ia_ko',
            division_type: 'xã',
            short_codename: 'ia_ko',
            parentId: 633,
          },
        ],
        parentId: 64,
      },
      {
        title: 'Huyện Đăk Pơ',
        code: 634,
        value: 'huyen_dak_po',
        division_type: 'huyện',
        short_codename: 'dak_po',
        children: [
          {
            title: 'Xã Hà Tam',
            code: 23989,
            value: 'xa_ha_tam',
            division_type: 'xã',
            short_codename: 'ha_tam',
            parentId: 634,
          },
          {
            title: 'Xã An Thành',
            code: 23992,
            value: 'xa_an_thanh',
            division_type: 'xã',
            short_codename: 'an_thanh',
            parentId: 634,
          },
          {
            title: 'Thị trấn Đak Pơ',
            code: 23995,
            value: 'thi_tran_dak_po',
            division_type: 'thị trấn',
            short_codename: 'dak_po',
            parentId: 634,
          },
          {
            title: 'Xã Yang Bắc',
            code: 23998,
            value: 'xa_yang_bac',
            division_type: 'xã',
            short_codename: 'yang_bac',
            parentId: 634,
          },
          {
            title: 'Xã Cư An',
            code: 24001,
            value: 'xa_cu_an',
            division_type: 'xã',
            short_codename: 'cu_an',
            parentId: 634,
          },
          {
            title: 'Xã Tân An',
            code: 24004,
            value: 'xa_tan_an',
            division_type: 'xã',
            short_codename: 'tan_an',
            parentId: 634,
          },
          {
            title: 'Xã Phú An',
            code: 24007,
            value: 'xa_phu_an',
            division_type: 'xã',
            short_codename: 'phu_an',
            parentId: 634,
          },
          {
            title: 'Xã Ya Hội',
            code: 24010,
            value: 'xa_ya_hoi',
            division_type: 'xã',
            short_codename: 'ya_hoi',
            parentId: 634,
          },
        ],
        parentId: 64,
      },
      {
        title: 'Huyện Ia Pa',
        code: 635,
        value: 'huyen_ia_pa',
        division_type: 'huyện',
        short_codename: 'ia_pa',
        children: [
          {
            title: 'Xã Pờ Tó',
            code: 24013,
            value: 'xa_po_to',
            division_type: 'xã',
            short_codename: 'po_to',
            parentId: 635,
          },
          {
            title: 'Xã Chư Răng',
            code: 24016,
            value: 'xa_chu_rang',
            division_type: 'xã',
            short_codename: 'chu_rang',
            parentId: 635,
          },
          {
            title: 'Xã Ia KDăm',
            code: 24019,
            value: 'xa_ia_kdam',
            division_type: 'xã',
            short_codename: 'ia_kdam',
            parentId: 635,
          },
          {
            title: 'Xã Kim Tân',
            code: 24022,
            value: 'xa_kim_tan',
            division_type: 'xã',
            short_codename: 'kim_tan',
            parentId: 635,
          },
          {
            title: 'Xã Chư Mố',
            code: 24025,
            value: 'xa_chu_mo',
            division_type: 'xã',
            short_codename: 'chu_mo',
            parentId: 635,
          },
          {
            title: 'Xã Ia Tul',
            code: 24028,
            value: 'xa_ia_tul',
            division_type: 'xã',
            short_codename: 'ia_tul',
            parentId: 635,
          },
          {
            title: 'Xã Ia Ma Rơn',
            code: 24031,
            value: 'xa_ia_ma_ron',
            division_type: 'xã',
            short_codename: 'ia_ma_ron',
            parentId: 635,
          },
          {
            title: 'Xã Ia Broăi',
            code: 24034,
            value: 'xa_ia_broai',
            division_type: 'xã',
            short_codename: 'ia_broai',
            parentId: 635,
          },
          {
            title: 'Xã Ia Trok',
            code: 24037,
            value: 'xa_ia_trok',
            division_type: 'xã',
            short_codename: 'ia_trok',
            parentId: 635,
          },
        ],
        parentId: 64,
      },
      {
        title: 'Huyện Krông Pa',
        code: 637,
        value: 'huyen_krong_pa',
        division_type: 'huyện',
        short_codename: 'krong_pa',
        children: [
          {
            title: 'Thị trấn Phú Túc',
            code: 24076,
            value: 'thi_tran_phu_tuc',
            division_type: 'thị trấn',
            short_codename: 'phu_tuc',
            parentId: 637,
          },
          {
            title: 'Xã Ia RSai',
            code: 24079,
            value: 'xa_ia_rsai',
            division_type: 'xã',
            short_codename: 'ia_rsai',
            parentId: 637,
          },
          {
            title: 'Xã Ia RSươm',
            code: 24082,
            value: 'xa_ia_rsuom',
            division_type: 'xã',
            short_codename: 'ia_rsuom',
            parentId: 637,
          },
          {
            title: 'Xã Chư Gu',
            code: 24085,
            value: 'xa_chu_gu',
            division_type: 'xã',
            short_codename: 'chu_gu',
            parentId: 637,
          },
          {
            title: 'Xã Đất Bằng',
            code: 24088,
            value: 'xa_dat_bang',
            division_type: 'xã',
            short_codename: 'dat_bang',
            parentId: 637,
          },
          {
            title: 'Xã Ia Mláh',
            code: 24091,
            value: 'xa_ia_mlah',
            division_type: 'xã',
            short_codename: 'ia_mlah',
            parentId: 637,
          },
          {
            title: 'Xã Chư Drăng',
            code: 24094,
            value: 'xa_chu_drang',
            division_type: 'xã',
            short_codename: 'chu_drang',
            parentId: 637,
          },
          {
            title: 'Xã Phú Cần',
            code: 24097,
            value: 'xa_phu_can',
            division_type: 'xã',
            short_codename: 'phu_can',
            parentId: 637,
          },
          {
            title: 'Xã Ia HDreh',
            code: 24100,
            value: 'xa_ia_hdreh',
            division_type: 'xã',
            short_codename: 'ia_hdreh',
            parentId: 637,
          },
          {
            title: 'Xã Ia RMok',
            code: 24103,
            value: 'xa_ia_rmok',
            division_type: 'xã',
            short_codename: 'ia_rmok',
            parentId: 637,
          },
          {
            title: 'Xã Chư Ngọc',
            code: 24106,
            value: 'xa_chu_ngoc',
            division_type: 'xã',
            short_codename: 'chu_ngoc',
            parentId: 637,
          },
          {
            title: 'Xã Uar',
            code: 24109,
            value: 'xa_uar',
            division_type: 'xã',
            short_codename: 'uar',
            parentId: 637,
          },
          {
            title: 'Xã Chư Rcăm',
            code: 24112,
            value: 'xa_chu_rcam',
            division_type: 'xã',
            short_codename: 'chu_rcam',
            parentId: 637,
          },
          {
            title: 'Xã Krông Năng',
            code: 24115,
            value: 'xa_krong_nang',
            division_type: 'xã',
            short_codename: 'krong_nang',
            parentId: 637,
          },
        ],
        parentId: 64,
      },
      {
        title: 'Huyện Phú Thiện',
        code: 638,
        value: 'huyen_phu_thien',
        division_type: 'huyện',
        short_codename: 'phu_thien',
        children: [
          {
            title: 'Thị trấn Phú Thiện',
            code: 24043,
            value: 'thi_tran_phu_thien',
            division_type: 'thị trấn',
            short_codename: 'phu_thien',
            parentId: 638,
          },
          {
            title: 'Xã Chư A Thai',
            code: 24046,
            value: 'xa_chu_a_thai',
            division_type: 'xã',
            short_codename: 'chu_a_thai',
            parentId: 638,
          },
          {
            title: 'Xã Ayun Hạ',
            code: 24048,
            value: 'xa_ayun_ha',
            division_type: 'xã',
            short_codename: 'ayun_ha',
            parentId: 638,
          },
          {
            title: 'Xã Ia Ake',
            code: 24049,
            value: 'xa_ia_ake',
            division_type: 'xã',
            short_codename: 'ia_ake',
            parentId: 638,
          },
          {
            title: 'Xã Ia Sol',
            code: 24052,
            value: 'xa_ia_sol',
            division_type: 'xã',
            short_codename: 'ia_sol',
            parentId: 638,
          },
          {
            title: 'Xã Ia Piar',
            code: 24055,
            value: 'xa_ia_piar',
            division_type: 'xã',
            short_codename: 'ia_piar',
            parentId: 638,
          },
          {
            title: 'Xã Ia Peng',
            code: 24058,
            value: 'xa_ia_peng',
            division_type: 'xã',
            short_codename: 'ia_peng',
            parentId: 638,
          },
          {
            title: 'Xã Chrôh Pơnan',
            code: 24060,
            value: 'xa_chroh_ponan',
            division_type: 'xã',
            short_codename: 'chroh_ponan',
            parentId: 638,
          },
          {
            title: 'Xã Ia Hiao',
            code: 24061,
            value: 'xa_ia_hiao',
            division_type: 'xã',
            short_codename: 'ia_hiao',
            parentId: 638,
          },
          {
            title: 'Xã Ia Yeng',
            code: 24067,
            value: 'xa_ia_yeng',
            division_type: 'xã',
            short_codename: 'ia_yeng',
            parentId: 638,
          },
        ],
        parentId: 64,
      },
      {
        title: 'Huyện Chư Pưh',
        code: 639,
        value: 'huyen_chu_puh',
        division_type: 'huyện',
        short_codename: 'chu_puh',
        children: [
          {
            title: 'Thị trấn Nhơn Hoà',
            code: 23942,
            value: 'thi_tran_nhon_hoa',
            division_type: 'thị trấn',
            short_codename: 'nhon_hoa',
            parentId: 639,
          },
          {
            title: 'Xã Ia Hrú',
            code: 23971,
            value: 'xa_ia_hru',
            division_type: 'xã',
            short_codename: 'ia_hru',
            parentId: 639,
          },
          {
            title: 'Xã Ia Rong',
            code: 23972,
            value: 'xa_ia_rong',
            division_type: 'xã',
            short_codename: 'ia_rong',
            parentId: 639,
          },
          {
            title: 'Xã Ia Dreng',
            code: 23974,
            value: 'xa_ia_dreng',
            division_type: 'xã',
            short_codename: 'ia_dreng',
            parentId: 639,
          },
          {
            title: 'Xã Ia Hla',
            code: 23978,
            value: 'xa_ia_hla',
            division_type: 'xã',
            short_codename: 'ia_hla',
            parentId: 639,
          },
          {
            title: 'Xã Chư Don',
            code: 23980,
            value: 'xa_chu_don',
            division_type: 'xã',
            short_codename: 'chu_don',
            parentId: 639,
          },
          {
            title: 'Xã Ia Phang',
            code: 23983,
            value: 'xa_ia_phang',
            division_type: 'xã',
            short_codename: 'ia_phang',
            parentId: 639,
          },
          {
            title: 'Xã Ia Le',
            code: 23986,
            value: 'xa_ia_le',
            division_type: 'xã',
            short_codename: 'ia_le',
            parentId: 639,
          },
          {
            title: 'Xã Ia BLứ',
            code: 23987,
            value: 'xa_ia_blu',
            division_type: 'xã',
            short_codename: 'ia_blu',
            parentId: 639,
          },
        ],
        parentId: 64,
      },
    ],
  },
  {
    title: 'Tỉnh Đắk Lắk',
    code: 66,
    value: 'tinh_dak_lak',
    division_type: 'tỉnh',
    phone_code: 262,
    children: [
      {
        title: 'Thành phố Buôn Ma Thuột',
        code: 643,
        value: 'thanh_pho_buon_ma_thuot',
        division_type: 'thành phố',
        short_codename: 'buon_ma_thuot',
        children: [
          {
            title: 'Phường Tân Lập',
            code: 24118,
            value: 'phuong_tan_lap',
            division_type: 'phường',
            short_codename: 'tan_lap',
            parentId: 643,
          },
          {
            title: 'Phường Tân Hòa',
            code: 24121,
            value: 'phuong_tan_hoa',
            division_type: 'phường',
            short_codename: 'tan_hoa',
            parentId: 643,
          },
          {
            title: 'Phường Tân An',
            code: 24124,
            value: 'phuong_tan_an',
            division_type: 'phường',
            short_codename: 'tan_an',
            parentId: 643,
          },
          {
            title: 'Phường Thống Nhất',
            code: 24127,
            value: 'phuong_thong_nhat',
            division_type: 'phường',
            short_codename: 'thong_nhat',
            parentId: 643,
          },
          {
            title: 'Phường Thành Nhất',
            code: 24130,
            value: 'phuong_thanh_nhat',
            division_type: 'phường',
            short_codename: 'thanh_nhat',
            parentId: 643,
          },
          {
            title: 'Phường Thắng Lợi',
            code: 24133,
            value: 'phuong_thang_loi',
            division_type: 'phường',
            short_codename: 'thang_loi',
            parentId: 643,
          },
          {
            title: 'Phường Tân Lợi',
            code: 24136,
            value: 'phuong_tan_loi',
            division_type: 'phường',
            short_codename: 'tan_loi',
            parentId: 643,
          },
          {
            title: 'Phường Thành Công',
            code: 24139,
            value: 'phuong_thanh_cong',
            division_type: 'phường',
            short_codename: 'thanh_cong',
            parentId: 643,
          },
          {
            title: 'Phường Tân Thành',
            code: 24142,
            value: 'phuong_tan_thanh',
            division_type: 'phường',
            short_codename: 'tan_thanh',
            parentId: 643,
          },
          {
            title: 'Phường Tân Tiến',
            code: 24145,
            value: 'phuong_tan_tien',
            division_type: 'phường',
            short_codename: 'tan_tien',
            parentId: 643,
          },
          {
            title: 'Phường Tự An',
            code: 24148,
            value: 'phuong_tu_an',
            division_type: 'phường',
            short_codename: 'tu_an',
            parentId: 643,
          },
          {
            title: 'Phường Ea Tam',
            code: 24151,
            value: 'phuong_ea_tam',
            division_type: 'phường',
            short_codename: 'ea_tam',
            parentId: 643,
          },
          {
            title: 'Phường Khánh Xuân',
            code: 24154,
            value: 'phuong_khanh_xuan',
            division_type: 'phường',
            short_codename: 'khanh_xuan',
            parentId: 643,
          },
          {
            title: 'Xã Hòa Thuận',
            code: 24157,
            value: 'xa_hoa_thuan',
            division_type: 'xã',
            short_codename: 'hoa_thuan',
            parentId: 643,
          },
          {
            title: 'Xã Cư ÊBur',
            code: 24160,
            value: 'xa_cu_ebur',
            division_type: 'xã',
            short_codename: 'cu_ebur',
            parentId: 643,
          },
          {
            title: 'Xã Ea Tu',
            code: 24163,
            value: 'xa_ea_tu',
            division_type: 'xã',
            short_codename: 'ea_tu',
            parentId: 643,
          },
          {
            title: 'Xã Hòa Thắng',
            code: 24166,
            value: 'xa_hoa_thang',
            division_type: 'xã',
            short_codename: 'hoa_thang',
            parentId: 643,
          },
          {
            title: 'Xã Ea Kao',
            code: 24169,
            value: 'xa_ea_kao',
            division_type: 'xã',
            short_codename: 'ea_kao',
            parentId: 643,
          },
          {
            title: 'Xã Hòa Phú',
            code: 24172,
            value: 'xa_hoa_phu',
            division_type: 'xã',
            short_codename: 'hoa_phu',
            parentId: 643,
          },
          {
            title: 'Xã Hòa Khánh',
            code: 24175,
            value: 'xa_hoa_khanh',
            division_type: 'xã',
            short_codename: 'hoa_khanh',
            parentId: 643,
          },
          {
            title: 'Xã Hòa Xuân',
            code: 24178,
            value: 'xa_hoa_xuan',
            division_type: 'xã',
            short_codename: 'hoa_xuan',
            parentId: 643,
          },
        ],
        parentId: 66,
      },
      {
        title: 'Thị Xã Buôn Hồ',
        code: 644,
        value: 'thi_xa_buon_ho',
        division_type: 'thị xã',
        short_codename: 'buon_ho',
        children: [
          {
            title: 'Phường An Lạc',
            code: 24305,
            value: 'phuong_an_lac',
            division_type: 'phường',
            short_codename: 'an_lac',
            parentId: 644,
          },
          {
            title: 'Phường An Bình',
            code: 24308,
            value: 'phuong_an_binh',
            division_type: 'phường',
            short_codename: 'an_binh',
            parentId: 644,
          },
          {
            title: 'Phường Thiện An',
            code: 24311,
            value: 'phuong_thien_an',
            division_type: 'phường',
            short_codename: 'thien_an',
            parentId: 644,
          },
          {
            title: 'Phường Đạt Hiếu',
            code: 24318,
            value: 'phuong_dat_hieu',
            division_type: 'phường',
            short_codename: 'dat_hieu',
            parentId: 644,
          },
          {
            title: 'Phường Đoàn Kết',
            code: 24322,
            value: 'phuong_doan_ket',
            division_type: 'phường',
            short_codename: 'doan_ket',
            parentId: 644,
          },
          {
            title: 'Xã Ea Blang',
            code: 24325,
            value: 'xa_ea_blang',
            division_type: 'xã',
            short_codename: 'ea_blang',
            parentId: 644,
          },
          {
            title: 'Xã Ea Drông',
            code: 24328,
            value: 'xa_ea_drong',
            division_type: 'xã',
            short_codename: 'ea_drong',
            parentId: 644,
          },
          {
            title: 'Phường Thống Nhất',
            code: 24331,
            value: 'phuong_thong_nhat',
            division_type: 'phường',
            short_codename: 'thong_nhat',
            parentId: 644,
          },
          {
            title: 'Phường Bình Tân',
            code: 24332,
            value: 'phuong_binh_tan',
            division_type: 'phường',
            short_codename: 'binh_tan',
            parentId: 644,
          },
          {
            title: 'Xã Ea Siên',
            code: 24334,
            value: 'xa_ea_sien',
            division_type: 'xã',
            short_codename: 'ea_sien',
            parentId: 644,
          },
          {
            title: 'Xã Bình Thuận',
            code: 24337,
            value: 'xa_binh_thuan',
            division_type: 'xã',
            short_codename: 'binh_thuan',
            parentId: 644,
          },
          {
            title: 'Xã Cư Bao',
            code: 24340,
            value: 'xa_cu_bao',
            division_type: 'xã',
            short_codename: 'cu_bao',
            parentId: 644,
          },
        ],
        parentId: 66,
      },
      {
        title: "Huyện Ea H'leo",
        code: 645,
        value: 'huyen_ea_hleo',
        division_type: 'huyện',
        short_codename: 'ea_hleo',
        children: [
          {
            title: 'Thị trấn Ea Drăng',
            code: 24181,
            value: 'thi_tran_ea_drang',
            division_type: 'thị trấn',
            short_codename: 'ea_drang',
            parentId: 645,
          },
          {
            title: "Xã Ea H'leo",
            code: 24184,
            value: 'xa_ea_hleo',
            division_type: 'xã',
            short_codename: 'ea_hleo',
            parentId: 645,
          },
          {
            title: 'Xã Ea Sol',
            code: 24187,
            value: 'xa_ea_sol',
            division_type: 'xã',
            short_codename: 'ea_sol',
            parentId: 645,
          },
          {
            title: 'Xã Ea Ral',
            code: 24190,
            value: 'xa_ea_ral',
            division_type: 'xã',
            short_codename: 'ea_ral',
            parentId: 645,
          },
          {
            title: 'Xã Ea Wy',
            code: 24193,
            value: 'xa_ea_wy',
            division_type: 'xã',
            short_codename: 'ea_wy',
            parentId: 645,
          },
          {
            title: 'Xã Cư A Mung',
            code: 24194,
            value: 'xa_cu_a_mung',
            division_type: 'xã',
            short_codename: 'cu_a_mung',
            parentId: 645,
          },
          {
            title: 'Xã Cư Mốt',
            code: 24196,
            value: 'xa_cu_mot',
            division_type: 'xã',
            short_codename: 'cu_mot',
            parentId: 645,
          },
          {
            title: 'Xã Ea Hiao',
            code: 24199,
            value: 'xa_ea_hiao',
            division_type: 'xã',
            short_codename: 'ea_hiao',
            parentId: 645,
          },
          {
            title: 'Xã Ea Khal',
            code: 24202,
            value: 'xa_ea_khal',
            division_type: 'xã',
            short_codename: 'ea_khal',
            parentId: 645,
          },
          {
            title: 'Xã Dliê Yang',
            code: 24205,
            value: 'xa_dlie_yang',
            division_type: 'xã',
            short_codename: 'dlie_yang',
            parentId: 645,
          },
          {
            title: 'Xã Ea Tir',
            code: 24207,
            value: 'xa_ea_tir',
            division_type: 'xã',
            short_codename: 'ea_tir',
            parentId: 645,
          },
          {
            title: 'Xã Ea Nam',
            code: 24208,
            value: 'xa_ea_nam',
            division_type: 'xã',
            short_codename: 'ea_nam',
            parentId: 645,
          },
        ],
        parentId: 66,
      },
      {
        title: 'Huyện Ea Súp',
        code: 646,
        value: 'huyen_ea_sup',
        division_type: 'huyện',
        short_codename: 'ea_sup',
        children: [
          {
            title: 'Thị trấn Ea Súp',
            code: 24211,
            value: 'thi_tran_ea_sup',
            division_type: 'thị trấn',
            short_codename: 'ea_sup',
            parentId: 646,
          },
          {
            title: 'Xã Ia Lốp',
            code: 24214,
            value: 'xa_ia_lop',
            division_type: 'xã',
            short_codename: 'ia_lop',
            parentId: 646,
          },
          {
            title: 'Xã Ia JLơi',
            code: 24215,
            value: 'xa_ia_jloi',
            division_type: 'xã',
            short_codename: 'ia_jloi',
            parentId: 646,
          },
          {
            title: 'Xã Ea Rốk',
            code: 24217,
            value: 'xa_ea_rok',
            division_type: 'xã',
            short_codename: 'ea_rok',
            parentId: 646,
          },
          {
            title: 'Xã Ya Tờ Mốt',
            code: 24220,
            value: 'xa_ya_to_mot',
            division_type: 'xã',
            short_codename: 'ya_to_mot',
            parentId: 646,
          },
          {
            title: 'Xã Ia RVê',
            code: 24221,
            value: 'xa_ia_rve',
            division_type: 'xã',
            short_codename: 'ia_rve',
            parentId: 646,
          },
          {
            title: 'Xã Ea Lê',
            code: 24223,
            value: 'xa_ea_le',
            division_type: 'xã',
            short_codename: 'ea_le',
            parentId: 646,
          },
          {
            title: 'Xã Cư KBang',
            code: 24226,
            value: 'xa_cu_kbang',
            division_type: 'xã',
            short_codename: 'cu_kbang',
            parentId: 646,
          },
          {
            title: 'Xã Ea Bung',
            code: 24229,
            value: 'xa_ea_bung',
            division_type: 'xã',
            short_codename: 'ea_bung',
            parentId: 646,
          },
          {
            title: "Xã Cư M'Lan",
            code: 24232,
            value: 'xa_cu_mlan',
            division_type: 'xã',
            short_codename: 'cu_mlan',
            parentId: 646,
          },
        ],
        parentId: 66,
      },
      {
        title: 'Huyện Buôn Đôn',
        code: 647,
        value: 'huyen_buon_don',
        division_type: 'huyện',
        short_codename: 'buon_don',
        children: [
          {
            title: 'Xã Krông Na',
            code: 24235,
            value: 'xa_krong_na',
            division_type: 'xã',
            short_codename: 'krong_na',
            parentId: 647,
          },
          {
            title: 'Xã Ea Huar',
            code: 24238,
            value: 'xa_ea_huar',
            division_type: 'xã',
            short_codename: 'ea_huar',
            parentId: 647,
          },
          {
            title: 'Xã Ea Wer',
            code: 24241,
            value: 'xa_ea_wer',
            division_type: 'xã',
            short_codename: 'ea_wer',
            parentId: 647,
          },
          {
            title: 'Xã Tân Hoà',
            code: 24244,
            value: 'xa_tan_hoa',
            division_type: 'xã',
            short_codename: 'tan_hoa',
            parentId: 647,
          },
          {
            title: 'Xã Cuôr KNia',
            code: 24247,
            value: 'xa_cuor_knia',
            division_type: 'xã',
            short_codename: 'cuor_knia',
            parentId: 647,
          },
          {
            title: 'Xã Ea Bar',
            code: 24250,
            value: 'xa_ea_bar',
            division_type: 'xã',
            short_codename: 'ea_bar',
            parentId: 647,
          },
          {
            title: 'Xã Ea Nuôl',
            code: 24253,
            value: 'xa_ea_nuol',
            division_type: 'xã',
            short_codename: 'ea_nuol',
            parentId: 647,
          },
        ],
        parentId: 66,
      },
      {
        title: "Huyện Cư M'gar",
        code: 648,
        value: 'huyen_cu_mgar',
        division_type: 'huyện',
        short_codename: 'cu_mgar',
        children: [
          {
            title: 'Thị trấn Ea Pốk',
            code: 24256,
            value: 'thi_tran_ea_pok',
            division_type: 'thị trấn',
            short_codename: 'ea_pok',
            parentId: 648,
          },
          {
            title: 'Thị trấn Quảng Phú',
            code: 24259,
            value: 'thi_tran_quang_phu',
            division_type: 'thị trấn',
            short_codename: 'quang_phu',
            parentId: 648,
          },
          {
            title: 'Xã Quảng Tiến',
            code: 24262,
            value: 'xa_quang_tien',
            division_type: 'xã',
            short_codename: 'quang_tien',
            parentId: 648,
          },
          {
            title: 'Xã Ea Kuêh',
            code: 24264,
            value: 'xa_ea_kueh',
            division_type: 'xã',
            short_codename: 'ea_kueh',
            parentId: 648,
          },
          {
            title: 'Xã Ea Kiết',
            code: 24265,
            value: 'xa_ea_kiet',
            division_type: 'xã',
            short_codename: 'ea_kiet',
            parentId: 648,
          },
          {
            title: 'Xã Ea Tar',
            code: 24268,
            value: 'xa_ea_tar',
            division_type: 'xã',
            short_codename: 'ea_tar',
            parentId: 648,
          },
          {
            title: "Xã Cư Dliê M'nông",
            code: 24271,
            value: 'xa_cu_dlie_mnong',
            division_type: 'xã',
            short_codename: 'cu_dlie_mnong',
            parentId: 648,
          },
          {
            title: "Xã Ea H'đinh",
            code: 24274,
            value: 'xa_ea_hdinh',
            division_type: 'xã',
            short_codename: 'ea_hdinh',
            parentId: 648,
          },
          {
            title: 'Xã Ea Tul',
            code: 24277,
            value: 'xa_ea_tul',
            division_type: 'xã',
            short_codename: 'ea_tul',
            parentId: 648,
          },
          {
            title: 'Xã Ea KPam',
            code: 24280,
            value: 'xa_ea_kpam',
            division_type: 'xã',
            short_codename: 'ea_kpam',
            parentId: 648,
          },
          {
            title: "Xã Ea M'DRóh",
            code: 24283,
            value: 'xa_ea_mdroh',
            division_type: 'xã',
            short_codename: 'ea_mdroh',
            parentId: 648,
          },
          {
            title: 'Xã Quảng Hiệp',
            code: 24286,
            value: 'xa_quang_hiep',
            division_type: 'xã',
            short_codename: 'quang_hiep',
            parentId: 648,
          },
          {
            title: "Xã Cư M'gar",
            code: 24289,
            value: 'xa_cu_mgar',
            division_type: 'xã',
            short_codename: 'cu_mgar',
            parentId: 648,
          },
          {
            title: "Xã Ea D'Rơng",
            code: 24292,
            value: 'xa_ea_drong',
            division_type: 'xã',
            short_codename: 'ea_drong',
            parentId: 648,
          },
          {
            title: "Xã Ea M'nang",
            code: 24295,
            value: 'xa_ea_mnang',
            division_type: 'xã',
            short_codename: 'ea_mnang',
            parentId: 648,
          },
          {
            title: 'Xã Cư Suê',
            code: 24298,
            value: 'xa_cu_sue',
            division_type: 'xã',
            short_codename: 'cu_sue',
            parentId: 648,
          },
          {
            title: 'Xã Cuor Đăng',
            code: 24301,
            value: 'xa_cuor_dang',
            division_type: 'xã',
            short_codename: 'cuor_dang',
            parentId: 648,
          },
        ],
        parentId: 66,
      },
      {
        title: 'Huyện Krông Búk',
        code: 649,
        value: 'huyen_krong_buk',
        division_type: 'huyện',
        short_codename: 'krong_buk',
        children: [
          {
            title: 'Xã Cư Né',
            code: 24307,
            value: 'xa_cu_ne',
            division_type: 'xã',
            short_codename: 'cu_ne',
            parentId: 649,
          },
          {
            title: 'Xã Chư KBô',
            code: 24310,
            value: 'xa_chu_kbo',
            division_type: 'xã',
            short_codename: 'chu_kbo',
            parentId: 649,
          },
          {
            title: 'Xã Cư Pơng',
            code: 24313,
            value: 'xa_cu_pong',
            division_type: 'xã',
            short_codename: 'cu_pong',
            parentId: 649,
          },
          {
            title: 'Xã Ea Sin',
            code: 24314,
            value: 'xa_ea_sin',
            division_type: 'xã',
            short_codename: 'ea_sin',
            parentId: 649,
          },
          {
            title: 'Xã Pơng Drang',
            code: 24316,
            value: 'xa_pong_drang',
            division_type: 'xã',
            short_codename: 'pong_drang',
            parentId: 649,
          },
          {
            title: 'Xã Tân Lập',
            code: 24317,
            value: 'xa_tan_lap',
            division_type: 'xã',
            short_codename: 'tan_lap',
            parentId: 649,
          },
          {
            title: 'Xã Ea Ngai',
            code: 24319,
            value: 'xa_ea_ngai',
            division_type: 'xã',
            short_codename: 'ea_ngai',
            parentId: 649,
          },
        ],
        parentId: 66,
      },
      {
        title: 'Huyện Krông Năng',
        code: 650,
        value: 'huyen_krong_nang',
        division_type: 'huyện',
        short_codename: 'krong_nang',
        children: [
          {
            title: 'Thị trấn Krông Năng',
            code: 24343,
            value: 'thi_tran_krong_nang',
            division_type: 'thị trấn',
            short_codename: 'krong_nang',
            parentId: 650,
          },
          {
            title: 'Xã ĐLiê Ya',
            code: 24346,
            value: 'xa_dlie_ya',
            division_type: 'xã',
            short_codename: 'dlie_ya',
            parentId: 650,
          },
          {
            title: 'Xã Ea Tóh',
            code: 24349,
            value: 'xa_ea_toh',
            division_type: 'xã',
            short_codename: 'ea_toh',
            parentId: 650,
          },
          {
            title: 'Xã Ea Tam',
            code: 24352,
            value: 'xa_ea_tam',
            division_type: 'xã',
            short_codename: 'ea_tam',
            parentId: 650,
          },
          {
            title: 'Xã Phú Lộc',
            code: 24355,
            value: 'xa_phu_loc',
            division_type: 'xã',
            short_codename: 'phu_loc',
            parentId: 650,
          },
          {
            title: 'Xã Tam Giang',
            code: 24358,
            value: 'xa_tam_giang',
            division_type: 'xã',
            short_codename: 'tam_giang',
            parentId: 650,
          },
          {
            title: 'Xã Ea Puk',
            code: 24359,
            value: 'xa_ea_puk',
            division_type: 'xã',
            short_codename: 'ea_puk',
            parentId: 650,
          },
          {
            title: 'Xã Ea Dăh',
            code: 24360,
            value: 'xa_ea_dah',
            division_type: 'xã',
            short_codename: 'ea_dah',
            parentId: 650,
          },
          {
            title: 'Xã Ea Hồ',
            code: 24361,
            value: 'xa_ea_ho',
            division_type: 'xã',
            short_codename: 'ea_ho',
            parentId: 650,
          },
          {
            title: 'Xã Phú Xuân',
            code: 24364,
            value: 'xa_phu_xuan',
            division_type: 'xã',
            short_codename: 'phu_xuan',
            parentId: 650,
          },
          {
            title: 'Xã Cư Klông',
            code: 24367,
            value: 'xa_cu_klong',
            division_type: 'xã',
            short_codename: 'cu_klong',
            parentId: 650,
          },
          {
            title: 'Xã Ea Tân',
            code: 24370,
            value: 'xa_ea_tan',
            division_type: 'xã',
            short_codename: 'ea_tan',
            parentId: 650,
          },
        ],
        parentId: 66,
      },
      {
        title: 'Huyện Ea Kar',
        code: 651,
        value: 'huyen_ea_kar',
        division_type: 'huyện',
        short_codename: 'ea_kar',
        children: [
          {
            title: 'Thị trấn Ea Kar',
            code: 24373,
            value: 'thi_tran_ea_kar',
            division_type: 'thị trấn',
            short_codename: 'ea_kar',
            parentId: 651,
          },
          {
            title: 'Thị trấn Ea Knốp',
            code: 24376,
            value: 'thi_tran_ea_knop',
            division_type: 'thị trấn',
            short_codename: 'ea_knop',
            parentId: 651,
          },
          {
            title: 'Xã Ea Sô',
            code: 24379,
            value: 'xa_ea_so',
            division_type: 'xã',
            short_codename: 'ea_so',
            parentId: 651,
          },
          {
            title: 'Xã Ea Sar',
            code: 24380,
            value: 'xa_ea_sar',
            division_type: 'xã',
            short_codename: 'ea_sar',
            parentId: 651,
          },
          {
            title: 'Xã Xuân Phú',
            code: 24382,
            value: 'xa_xuan_phu',
            division_type: 'xã',
            short_codename: 'xuan_phu',
            parentId: 651,
          },
          {
            title: 'Xã Cư Huê',
            code: 24385,
            value: 'xa_cu_hue',
            division_type: 'xã',
            short_codename: 'cu_hue',
            parentId: 651,
          },
          {
            title: 'Xã Ea Tih',
            code: 24388,
            value: 'xa_ea_tih',
            division_type: 'xã',
            short_codename: 'ea_tih',
            parentId: 651,
          },
          {
            title: 'Xã Ea Đar',
            code: 24391,
            value: 'xa_ea_dar',
            division_type: 'xã',
            short_codename: 'ea_dar',
            parentId: 651,
          },
          {
            title: 'Xã Ea Kmút',
            code: 24394,
            value: 'xa_ea_kmut',
            division_type: 'xã',
            short_codename: 'ea_kmut',
            parentId: 651,
          },
          {
            title: 'Xã Cư Ni',
            code: 24397,
            value: 'xa_cu_ni',
            division_type: 'xã',
            short_codename: 'cu_ni',
            parentId: 651,
          },
          {
            title: 'Xã Ea Păl',
            code: 24400,
            value: 'xa_ea_pal',
            division_type: 'xã',
            short_codename: 'ea_pal',
            parentId: 651,
          },
          {
            title: 'Xã Cư Prông',
            code: 24401,
            value: 'xa_cu_prong',
            division_type: 'xã',
            short_codename: 'cu_prong',
            parentId: 651,
          },
          {
            title: 'Xã Ea Ô',
            code: 24403,
            value: 'xa_ea_o',
            division_type: 'xã',
            short_codename: 'ea_o',
            parentId: 651,
          },
          {
            title: 'Xã Cư ELang',
            code: 24404,
            value: 'xa_cu_elang',
            division_type: 'xã',
            short_codename: 'cu_elang',
            parentId: 651,
          },
          {
            title: 'Xã Cư Bông',
            code: 24406,
            value: 'xa_cu_bong',
            division_type: 'xã',
            short_codename: 'cu_bong',
            parentId: 651,
          },
          {
            title: 'Xã Cư Jang',
            code: 24409,
            value: 'xa_cu_jang',
            division_type: 'xã',
            short_codename: 'cu_jang',
            parentId: 651,
          },
        ],
        parentId: 66,
      },
      {
        title: "Huyện M'Đrắk",
        code: 652,
        value: 'huyen_mdrak',
        division_type: 'huyện',
        short_codename: 'mdrak',
        children: [
          {
            title: "Thị trấn M'Đrắk",
            code: 24412,
            value: 'thi_tran_mdrak',
            division_type: 'thị trấn',
            short_codename: 'mdrak',
            parentId: 652,
          },
          {
            title: 'Xã Cư Prao',
            code: 24415,
            value: 'xa_cu_prao',
            division_type: 'xã',
            short_codename: 'cu_prao',
            parentId: 652,
          },
          {
            title: 'Xã Ea Pil',
            code: 24418,
            value: 'xa_ea_pil',
            division_type: 'xã',
            short_codename: 'ea_pil',
            parentId: 652,
          },
          {
            title: 'Xã Ea Lai',
            code: 24421,
            value: 'xa_ea_lai',
            division_type: 'xã',
            short_codename: 'ea_lai',
            parentId: 652,
          },
          {
            title: "Xã Ea H'MLay",
            code: 24424,
            value: 'xa_ea_hmlay',
            division_type: 'xã',
            short_codename: 'ea_hmlay',
            parentId: 652,
          },
          {
            title: 'Xã Krông Jing',
            code: 24427,
            value: 'xa_krong_jing',
            division_type: 'xã',
            short_codename: 'krong_jing',
            parentId: 652,
          },
          {
            title: "Xã Ea M' Doal",
            code: 24430,
            value: 'xa_ea_m_doal',
            division_type: 'xã',
            short_codename: 'ea_m_doal',
            parentId: 652,
          },
          {
            title: 'Xã Ea Riêng',
            code: 24433,
            value: 'xa_ea_rieng',
            division_type: 'xã',
            short_codename: 'ea_rieng',
            parentId: 652,
          },
          {
            title: "Xã Cư M'ta",
            code: 24436,
            value: 'xa_cu_mta',
            division_type: 'xã',
            short_codename: 'cu_mta',
            parentId: 652,
          },
          {
            title: 'Xã Cư K Róa',
            code: 24439,
            value: 'xa_cu_k_roa',
            division_type: 'xã',
            short_codename: 'cu_k_roa',
            parentId: 652,
          },
          {
            title: 'Xã Krông Á',
            code: 24442,
            value: 'xa_krong_a',
            division_type: 'xã',
            short_codename: 'krong_a',
            parentId: 652,
          },
          {
            title: 'Xã Cư San',
            code: 24444,
            value: 'xa_cu_san',
            division_type: 'xã',
            short_codename: 'cu_san',
            parentId: 652,
          },
          {
            title: 'Xã Ea Trang',
            code: 24445,
            value: 'xa_ea_trang',
            division_type: 'xã',
            short_codename: 'ea_trang',
            parentId: 652,
          },
        ],
        parentId: 66,
      },
      {
        title: 'Huyện Krông Bông',
        code: 653,
        value: 'huyen_krong_bong',
        division_type: 'huyện',
        short_codename: 'krong_bong',
        children: [
          {
            title: 'Thị trấn Krông Kmar',
            code: 24448,
            value: 'thi_tran_krong_kmar',
            division_type: 'thị trấn',
            short_codename: 'krong_kmar',
            parentId: 653,
          },
          {
            title: 'Xã Dang Kang',
            code: 24451,
            value: 'xa_dang_kang',
            division_type: 'xã',
            short_codename: 'dang_kang',
            parentId: 653,
          },
          {
            title: 'Xã Cư KTy',
            code: 24454,
            value: 'xa_cu_kty',
            division_type: 'xã',
            short_codename: 'cu_kty',
            parentId: 653,
          },
          {
            title: 'Xã Hòa Thành',
            code: 24457,
            value: 'xa_hoa_thanh',
            division_type: 'xã',
            short_codename: 'hoa_thanh',
            parentId: 653,
          },
          {
            title: 'Xã Hòa Tân',
            code: 24460,
            value: 'xa_hoa_tan',
            division_type: 'xã',
            short_codename: 'hoa_tan',
            parentId: 653,
          },
          {
            title: 'Xã Hòa Phong',
            code: 24463,
            value: 'xa_hoa_phong',
            division_type: 'xã',
            short_codename: 'hoa_phong',
            parentId: 653,
          },
          {
            title: 'Xã Hòa Lễ',
            code: 24466,
            value: 'xa_hoa_le',
            division_type: 'xã',
            short_codename: 'hoa_le',
            parentId: 653,
          },
          {
            title: 'Xã Yang Reh',
            code: 24469,
            value: 'xa_yang_reh',
            division_type: 'xã',
            short_codename: 'yang_reh',
            parentId: 653,
          },
          {
            title: 'Xã Ea Trul',
            code: 24472,
            value: 'xa_ea_trul',
            division_type: 'xã',
            short_codename: 'ea_trul',
            parentId: 653,
          },
          {
            title: 'Xã Khuê Ngọc Điền',
            code: 24475,
            value: 'xa_khue_ngoc_dien',
            division_type: 'xã',
            short_codename: 'khue_ngoc_dien',
            parentId: 653,
          },
          {
            title: 'Xã Cư Pui',
            code: 24478,
            value: 'xa_cu_pui',
            division_type: 'xã',
            short_codename: 'cu_pui',
            parentId: 653,
          },
          {
            title: 'Xã Hòa Sơn',
            code: 24481,
            value: 'xa_hoa_son',
            division_type: 'xã',
            short_codename: 'hoa_son',
            parentId: 653,
          },
          {
            title: 'Xã Cư Drăm',
            code: 24484,
            value: 'xa_cu_dram',
            division_type: 'xã',
            short_codename: 'cu_dram',
            parentId: 653,
          },
          {
            title: 'Xã Yang Mao',
            code: 24487,
            value: 'xa_yang_mao',
            division_type: 'xã',
            short_codename: 'yang_mao',
            parentId: 653,
          },
        ],
        parentId: 66,
      },
      {
        title: 'Huyện Krông Pắc',
        code: 654,
        value: 'huyen_krong_pac',
        division_type: 'huyện',
        short_codename: 'krong_pac',
        children: [
          {
            title: 'Thị trấn Phước An',
            code: 24490,
            value: 'thi_tran_phuoc_an',
            division_type: 'thị trấn',
            short_codename: 'phuoc_an',
            parentId: 654,
          },
          {
            title: 'Xã KRông Búk',
            code: 24493,
            value: 'xa_krong_buk',
            division_type: 'xã',
            short_codename: 'krong_buk',
            parentId: 654,
          },
          {
            title: 'Xã Ea Kly',
            code: 24496,
            value: 'xa_ea_kly',
            division_type: 'xã',
            short_codename: 'ea_kly',
            parentId: 654,
          },
          {
            title: 'Xã Ea Kênh',
            code: 24499,
            value: 'xa_ea_kenh',
            division_type: 'xã',
            short_codename: 'ea_kenh',
            parentId: 654,
          },
          {
            title: 'Xã Ea Phê',
            code: 24502,
            value: 'xa_ea_phe',
            division_type: 'xã',
            short_codename: 'ea_phe',
            parentId: 654,
          },
          {
            title: 'Xã Ea KNuec',
            code: 24505,
            value: 'xa_ea_knuec',
            division_type: 'xã',
            short_codename: 'ea_knuec',
            parentId: 654,
          },
          {
            title: 'Xã Ea Yông',
            code: 24508,
            value: 'xa_ea_yong',
            division_type: 'xã',
            short_codename: 'ea_yong',
            parentId: 654,
          },
          {
            title: 'Xã Hòa An',
            code: 24511,
            value: 'xa_hoa_an',
            division_type: 'xã',
            short_codename: 'hoa_an',
            parentId: 654,
          },
          {
            title: 'Xã Ea Kuăng',
            code: 24514,
            value: 'xa_ea_kuang',
            division_type: 'xã',
            short_codename: 'ea_kuang',
            parentId: 654,
          },
          {
            title: 'Xã Hòa Đông',
            code: 24517,
            value: 'xa_hoa_dong',
            division_type: 'xã',
            short_codename: 'hoa_dong',
            parentId: 654,
          },
          {
            title: 'Xã Ea Hiu',
            code: 24520,
            value: 'xa_ea_hiu',
            division_type: 'xã',
            short_codename: 'ea_hiu',
            parentId: 654,
          },
          {
            title: 'Xã Hòa Tiến',
            code: 24523,
            value: 'xa_hoa_tien',
            division_type: 'xã',
            short_codename: 'hoa_tien',
            parentId: 654,
          },
          {
            title: 'Xã Tân Tiến',
            code: 24526,
            value: 'xa_tan_tien',
            division_type: 'xã',
            short_codename: 'tan_tien',
            parentId: 654,
          },
          {
            title: 'Xã Vụ Bổn',
            code: 24529,
            value: 'xa_vu_bon',
            division_type: 'xã',
            short_codename: 'vu_bon',
            parentId: 654,
          },
          {
            title: 'Xã Ea Uy',
            code: 24532,
            value: 'xa_ea_uy',
            division_type: 'xã',
            short_codename: 'ea_uy',
            parentId: 654,
          },
          {
            title: 'Xã Ea Yiêng',
            code: 24535,
            value: 'xa_ea_yieng',
            division_type: 'xã',
            short_codename: 'ea_yieng',
            parentId: 654,
          },
        ],
        parentId: 66,
      },
      {
        title: 'Huyện Krông A Na',
        code: 655,
        value: 'huyen_krong_a_na',
        division_type: 'huyện',
        short_codename: 'krong_a_na',
        children: [
          {
            title: 'Thị trấn Buôn Trấp',
            code: 24538,
            value: 'thi_tran_buon_trap',
            division_type: 'thị trấn',
            short_codename: 'buon_trap',
            parentId: 655,
          },
          {
            title: 'Xã Dray Sáp',
            code: 24556,
            value: 'xa_dray_sap',
            division_type: 'xã',
            short_codename: 'dray_sap',
            parentId: 655,
          },
          {
            title: 'Xã Ea Na',
            code: 24559,
            value: 'xa_ea_na',
            division_type: 'xã',
            short_codename: 'ea_na',
            parentId: 655,
          },
          {
            title: 'Xã Ea Bông',
            code: 24565,
            value: 'xa_ea_bong',
            division_type: 'xã',
            short_codename: 'ea_bong',
            parentId: 655,
          },
          {
            title: 'Xã Băng A Drênh',
            code: 24568,
            value: 'xa_bang_a_drenh',
            division_type: 'xã',
            short_codename: 'bang_a_drenh',
            parentId: 655,
          },
          {
            title: 'Xã Dur KMăl',
            code: 24571,
            value: 'xa_dur_kmal',
            division_type: 'xã',
            short_codename: 'dur_kmal',
            parentId: 655,
          },
          {
            title: 'Xã Bình Hòa',
            code: 24574,
            value: 'xa_binh_hoa',
            division_type: 'xã',
            short_codename: 'binh_hoa',
            parentId: 655,
          },
          {
            title: 'Xã Quảng Điền',
            code: 24577,
            value: 'xa_quang_dien',
            division_type: 'xã',
            short_codename: 'quang_dien',
            parentId: 655,
          },
        ],
        parentId: 66,
      },
      {
        title: 'Huyện Lắk',
        code: 656,
        value: 'huyen_lak',
        division_type: 'huyện',
        short_codename: 'lak',
        children: [
          {
            title: 'Thị trấn Liên Sơn',
            code: 24580,
            value: 'thi_tran_lien_son',
            division_type: 'thị trấn',
            short_codename: 'lien_son',
            parentId: 656,
          },
          {
            title: 'Xã Yang Tao',
            code: 24583,
            value: 'xa_yang_tao',
            division_type: 'xã',
            short_codename: 'yang_tao',
            parentId: 656,
          },
          {
            title: 'Xã Bông Krang',
            code: 24586,
            value: 'xa_bong_krang',
            division_type: 'xã',
            short_codename: 'bong_krang',
            parentId: 656,
          },
          {
            title: 'Xã Đắk Liêng',
            code: 24589,
            value: 'xa_dak_lieng',
            division_type: 'xã',
            short_codename: 'dak_lieng',
            parentId: 656,
          },
          {
            title: 'Xã Buôn Triết',
            code: 24592,
            value: 'xa_buon_triet',
            division_type: 'xã',
            short_codename: 'buon_triet',
            parentId: 656,
          },
          {
            title: 'Xã Buôn Tría',
            code: 24595,
            value: 'xa_buon_tria',
            division_type: 'xã',
            short_codename: 'buon_tria',
            parentId: 656,
          },
          {
            title: 'Xã Đắk Phơi',
            code: 24598,
            value: 'xa_dak_phoi',
            division_type: 'xã',
            short_codename: 'dak_phoi',
            parentId: 656,
          },
          {
            title: 'Xã Đắk Nuê',
            code: 24601,
            value: 'xa_dak_nue',
            division_type: 'xã',
            short_codename: 'dak_nue',
            parentId: 656,
          },
          {
            title: 'Xã Krông Nô',
            code: 24604,
            value: 'xa_krong_no',
            division_type: 'xã',
            short_codename: 'krong_no',
            parentId: 656,
          },
          {
            title: 'Xã Nam Ka',
            code: 24607,
            value: 'xa_nam_ka',
            division_type: 'xã',
            short_codename: 'nam_ka',
            parentId: 656,
          },
          {
            title: "Xã Ea R'Bin",
            code: 24610,
            value: 'xa_ea_rbin',
            division_type: 'xã',
            short_codename: 'ea_rbin',
            parentId: 656,
          },
        ],
        parentId: 66,
      },
      {
        title: 'Huyện Cư Kuin',
        code: 657,
        value: 'huyen_cu_kuin',
        division_type: 'huyện',
        short_codename: 'cu_kuin',
        children: [
          {
            title: 'Xã Ea Ning',
            code: 24540,
            value: 'xa_ea_ning',
            division_type: 'xã',
            short_codename: 'ea_ning',
            parentId: 657,
          },
          {
            title: 'Xã Cư Ê Wi',
            code: 24541,
            value: 'xa_cu_e_wi',
            division_type: 'xã',
            short_codename: 'cu_e_wi',
            parentId: 657,
          },
          {
            title: 'Xã Ea Ktur',
            code: 24544,
            value: 'xa_ea_ktur',
            division_type: 'xã',
            short_codename: 'ea_ktur',
            parentId: 657,
          },
          {
            title: 'Xã Ea Tiêu',
            code: 24547,
            value: 'xa_ea_tieu',
            division_type: 'xã',
            short_codename: 'ea_tieu',
            parentId: 657,
          },
          {
            title: 'Xã Ea BHốk',
            code: 24550,
            value: 'xa_ea_bhok',
            division_type: 'xã',
            short_codename: 'ea_bhok',
            parentId: 657,
          },
          {
            title: 'Xã Ea Hu',
            code: 24553,
            value: 'xa_ea_hu',
            division_type: 'xã',
            short_codename: 'ea_hu',
            parentId: 657,
          },
          {
            title: 'Xã Dray Bhăng',
            code: 24561,
            value: 'xa_dray_bhang',
            division_type: 'xã',
            short_codename: 'dray_bhang',
            parentId: 657,
          },
          {
            title: 'Xã Hòa Hiệp',
            code: 24562,
            value: 'xa_hoa_hiep',
            division_type: 'xã',
            short_codename: 'hoa_hiep',
            parentId: 657,
          },
        ],
        parentId: 66,
      },
    ],
  },
  {
    title: 'Tỉnh Đắk Nông',
    code: 67,
    value: 'tinh_dak_nong',
    division_type: 'tỉnh',
    phone_code: 261,
    children: [
      {
        title: 'Thành phố Gia Nghĩa',
        code: 660,
        value: 'thanh_pho_gia_nghia',
        division_type: 'thành phố',
        short_codename: 'gia_nghia',
        children: [
          {
            title: 'Phường Nghĩa Đức',
            code: 24611,
            value: 'phuong_nghia_duc',
            division_type: 'phường',
            short_codename: 'nghia_duc',
            parentId: 660,
          },
          {
            title: 'Phường Nghĩa Thành',
            code: 24612,
            value: 'phuong_nghia_thanh',
            division_type: 'phường',
            short_codename: 'nghia_thanh',
            parentId: 660,
          },
          {
            title: 'Phường Nghĩa Phú',
            code: 24614,
            value: 'phuong_nghia_phu',
            division_type: 'phường',
            short_codename: 'nghia_phu',
            parentId: 660,
          },
          {
            title: 'Phường Nghĩa Tân',
            code: 24615,
            value: 'phuong_nghia_tan',
            division_type: 'phường',
            short_codename: 'nghia_tan',
            parentId: 660,
          },
          {
            title: 'Phường Nghĩa Trung',
            code: 24617,
            value: 'phuong_nghia_trung',
            division_type: 'phường',
            short_codename: 'nghia_trung',
            parentId: 660,
          },
          {
            title: "Xã Đăk R'Moan",
            code: 24618,
            value: 'xa_dak_rmoan',
            division_type: 'xã',
            short_codename: 'dak_rmoan',
            parentId: 660,
          },
          {
            title: 'Phường Quảng Thành',
            code: 24619,
            value: 'phuong_quang_thanh',
            division_type: 'phường',
            short_codename: 'quang_thanh',
            parentId: 660,
          },
          {
            title: 'Xã Đắk Nia',
            code: 24628,
            value: 'xa_dak_nia',
            division_type: 'xã',
            short_codename: 'dak_nia',
            parentId: 660,
          },
        ],
        parentId: 67,
      },
      {
        title: 'Huyện Đăk Glong',
        code: 661,
        value: 'huyen_dak_glong',
        division_type: 'huyện',
        short_codename: 'dak_glong',
        children: [
          {
            title: 'Xã Quảng Sơn',
            code: 24616,
            value: 'xa_quang_son',
            division_type: 'xã',
            short_codename: 'quang_son',
            parentId: 661,
          },
          {
            title: 'Xã Quảng Hoà',
            code: 24620,
            value: 'xa_quang_hoa',
            division_type: 'xã',
            short_codename: 'quang_hoa',
            parentId: 661,
          },
          {
            title: 'Xã Đắk Ha',
            code: 24622,
            value: 'xa_dak_ha',
            division_type: 'xã',
            short_codename: 'dak_ha',
            parentId: 661,
          },
          {
            title: "Xã Đắk R'Măng",
            code: 24625,
            value: 'xa_dak_rmang',
            division_type: 'xã',
            short_codename: 'dak_rmang',
            parentId: 661,
          },
          {
            title: 'Xã Quảng Khê',
            code: 24631,
            value: 'xa_quang_khe',
            division_type: 'xã',
            short_codename: 'quang_khe',
            parentId: 661,
          },
          {
            title: 'Xã Đắk Plao',
            code: 24634,
            value: 'xa_dak_plao',
            division_type: 'xã',
            short_codename: 'dak_plao',
            parentId: 661,
          },
          {
            title: 'Xã Đắk Som',
            code: 24637,
            value: 'xa_dak_som',
            division_type: 'xã',
            short_codename: 'dak_som',
            parentId: 661,
          },
        ],
        parentId: 67,
      },
      {
        title: 'Huyện Cư Jút',
        code: 662,
        value: 'huyen_cu_jut',
        division_type: 'huyện',
        short_codename: 'cu_jut',
        children: [
          {
            title: "Thị trấn Ea T'Ling",
            code: 24640,
            value: 'thi_tran_ea_tling',
            division_type: 'thị trấn',
            short_codename: 'ea_tling',
            parentId: 662,
          },
          {
            title: 'Xã Đắk Wil',
            code: 24643,
            value: 'xa_dak_wil',
            division_type: 'xã',
            short_codename: 'dak_wil',
            parentId: 662,
          },
          {
            title: 'Xã Ea Pô',
            code: 24646,
            value: 'xa_ea_po',
            division_type: 'xã',
            short_codename: 'ea_po',
            parentId: 662,
          },
          {
            title: 'Xã Nam Dong',
            code: 24649,
            value: 'xa_nam_dong',
            division_type: 'xã',
            short_codename: 'nam_dong',
            parentId: 662,
          },
          {
            title: 'Xã Đắk DRông',
            code: 24652,
            value: 'xa_dak_drong',
            division_type: 'xã',
            short_codename: 'dak_drong',
            parentId: 662,
          },
          {
            title: 'Xã Tâm Thắng',
            code: 24655,
            value: 'xa_tam_thang',
            division_type: 'xã',
            short_codename: 'tam_thang',
            parentId: 662,
          },
          {
            title: 'Xã Cư Knia',
            code: 24658,
            value: 'xa_cu_knia',
            division_type: 'xã',
            short_codename: 'cu_knia',
            parentId: 662,
          },
          {
            title: 'Xã Trúc Sơn',
            code: 24661,
            value: 'xa_truc_son',
            division_type: 'xã',
            short_codename: 'truc_son',
            parentId: 662,
          },
        ],
        parentId: 67,
      },
      {
        title: 'Huyện Đắk Mil',
        code: 663,
        value: 'huyen_dak_mil',
        division_type: 'huyện',
        short_codename: 'dak_mil',
        children: [
          {
            title: 'Thị trấn Đắk Mil',
            code: 24664,
            value: 'thi_tran_dak_mil',
            division_type: 'thị trấn',
            short_codename: 'dak_mil',
            parentId: 663,
          },
          {
            title: 'Xã Đắk Lao',
            code: 24667,
            value: 'xa_dak_lao',
            division_type: 'xã',
            short_codename: 'dak_lao',
            parentId: 663,
          },
          {
            title: "Xã Đắk R'La",
            code: 24670,
            value: 'xa_dak_rla',
            division_type: 'xã',
            short_codename: 'dak_rla',
            parentId: 663,
          },
          {
            title: 'Xã Đắk Gằn',
            code: 24673,
            value: 'xa_dak_gan',
            division_type: 'xã',
            short_codename: 'dak_gan',
            parentId: 663,
          },
          {
            title: 'Xã Đức Mạnh',
            code: 24676,
            value: 'xa_duc_manh',
            division_type: 'xã',
            short_codename: 'duc_manh',
            parentId: 663,
          },
          {
            title: "Xã Đắk N'Drót",
            code: 24677,
            value: 'xa_dak_ndrot',
            division_type: 'xã',
            short_codename: 'dak_ndrot',
            parentId: 663,
          },
          {
            title: 'Xã Long Sơn',
            code: 24678,
            value: 'xa_long_son',
            division_type: 'xã',
            short_codename: 'long_son',
            parentId: 663,
          },
          {
            title: 'Xã Đắk Sắk',
            code: 24679,
            value: 'xa_dak_sak',
            division_type: 'xã',
            short_codename: 'dak_sak',
            parentId: 663,
          },
          {
            title: 'Xã Thuận An',
            code: 24682,
            value: 'xa_thuan_an',
            division_type: 'xã',
            short_codename: 'thuan_an',
            parentId: 663,
          },
          {
            title: 'Xã Đức Minh',
            code: 24685,
            value: 'xa_duc_minh',
            division_type: 'xã',
            short_codename: 'duc_minh',
            parentId: 663,
          },
        ],
        parentId: 67,
      },
      {
        title: 'Huyện Krông Nô',
        code: 664,
        value: 'huyen_krong_no',
        division_type: 'huyện',
        short_codename: 'krong_no',
        children: [
          {
            title: 'Thị trấn Đắk Mâm',
            code: 24688,
            value: 'thi_tran_dak_mam',
            division_type: 'thị trấn',
            short_codename: 'dak_mam',
            parentId: 664,
          },
          {
            title: 'Xã Đắk Sôr',
            code: 24691,
            value: 'xa_dak_sor',
            division_type: 'xã',
            short_codename: 'dak_sor',
            parentId: 664,
          },
          {
            title: 'Xã Nam Xuân',
            code: 24692,
            value: 'xa_nam_xuan',
            division_type: 'xã',
            short_codename: 'nam_xuan',
            parentId: 664,
          },
          {
            title: 'Xã Buôn Choah',
            code: 24694,
            value: 'xa_buon_choah',
            division_type: 'xã',
            short_codename: 'buon_choah',
            parentId: 664,
          },
          {
            title: 'Xã Nam Đà',
            code: 24697,
            value: 'xa_nam_da',
            division_type: 'xã',
            short_codename: 'nam_da',
            parentId: 664,
          },
          {
            title: 'Xã Tân Thành',
            code: 24699,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 664,
          },
          {
            title: 'Xã Đắk Drô',
            code: 24700,
            value: 'xa_dak_dro',
            division_type: 'xã',
            short_codename: 'dak_dro',
            parentId: 664,
          },
          {
            title: 'Xã Nâm Nung',
            code: 24703,
            value: 'xa_nam_nung',
            division_type: 'xã',
            short_codename: 'nam_nung',
            parentId: 664,
          },
          {
            title: 'Xã Đức Xuyên',
            code: 24706,
            value: 'xa_duc_xuyen',
            division_type: 'xã',
            short_codename: 'duc_xuyen',
            parentId: 664,
          },
          {
            title: 'Xã Đắk Nang',
            code: 24709,
            value: 'xa_dak_nang',
            division_type: 'xã',
            short_codename: 'dak_nang',
            parentId: 664,
          },
          {
            title: 'Xã Quảng Phú',
            code: 24712,
            value: 'xa_quang_phu',
            division_type: 'xã',
            short_codename: 'quang_phu',
            parentId: 664,
          },
          {
            title: "Xã Nâm N'Đir",
            code: 24715,
            value: 'xa_nam_ndir',
            division_type: 'xã',
            short_codename: 'nam_ndir',
            parentId: 664,
          },
        ],
        parentId: 67,
      },
      {
        title: 'Huyện Đắk Song',
        code: 665,
        value: 'huyen_dak_song',
        division_type: 'huyện',
        short_codename: 'dak_song',
        children: [
          {
            title: 'Thị trấn Đức An',
            code: 24717,
            value: 'thi_tran_duc_an',
            division_type: 'thị trấn',
            short_codename: 'duc_an',
            parentId: 665,
          },
          {
            title: 'Xã Đắk Môl',
            code: 24718,
            value: 'xa_dak_mol',
            division_type: 'xã',
            short_codename: 'dak_mol',
            parentId: 665,
          },
          {
            title: 'Xã Đắk Hòa',
            code: 24719,
            value: 'xa_dak_hoa',
            division_type: 'xã',
            short_codename: 'dak_hoa',
            parentId: 665,
          },
          {
            title: 'Xã Nam Bình',
            code: 24721,
            value: 'xa_nam_binh',
            division_type: 'xã',
            short_codename: 'nam_binh',
            parentId: 665,
          },
          {
            title: 'Xã Thuận Hà',
            code: 24722,
            value: 'xa_thuan_ha',
            division_type: 'xã',
            short_codename: 'thuan_ha',
            parentId: 665,
          },
          {
            title: 'Xã Thuận Hạnh',
            code: 24724,
            value: 'xa_thuan_hanh',
            division_type: 'xã',
            short_codename: 'thuan_hanh',
            parentId: 665,
          },
          {
            title: "Xã Đắk N'Dung",
            code: 24727,
            value: 'xa_dak_ndung',
            division_type: 'xã',
            short_codename: 'dak_ndung',
            parentId: 665,
          },
          {
            title: "Xã Nâm N'Jang",
            code: 24728,
            value: 'xa_nam_njang',
            division_type: 'xã',
            short_codename: 'nam_njang',
            parentId: 665,
          },
          {
            title: 'Xã Trường Xuân',
            code: 24730,
            value: 'xa_truong_xuan',
            division_type: 'xã',
            short_codename: 'truong_xuan',
            parentId: 665,
          },
        ],
        parentId: 67,
      },
      {
        title: "Huyện Đắk R'Lấp",
        code: 666,
        value: 'huyen_dak_rlap',
        division_type: 'huyện',
        short_codename: 'dak_rlap',
        children: [
          {
            title: 'Thị trấn Kiến Đức',
            code: 24733,
            value: 'thi_tran_kien_duc',
            division_type: 'thị trấn',
            short_codename: 'kien_duc',
            parentId: 666,
          },
          {
            title: 'Xã Quảng Tín',
            code: 24745,
            value: 'xa_quang_tin',
            division_type: 'xã',
            short_codename: 'quang_tin',
            parentId: 666,
          },
          {
            title: 'Xã Đắk Wer',
            code: 24750,
            value: 'xa_dak_wer',
            division_type: 'xã',
            short_codename: 'dak_wer',
            parentId: 666,
          },
          {
            title: 'Xã Nhân Cơ',
            code: 24751,
            value: 'xa_nhan_co',
            division_type: 'xã',
            short_codename: 'nhan_co',
            parentId: 666,
          },
          {
            title: 'Xã Kiến Thành',
            code: 24754,
            value: 'xa_kien_thanh',
            division_type: 'xã',
            short_codename: 'kien_thanh',
            parentId: 666,
          },
          {
            title: 'Xã Nghĩa Thắng',
            code: 24756,
            value: 'xa_nghia_thang',
            division_type: 'xã',
            short_codename: 'nghia_thang',
            parentId: 666,
          },
          {
            title: 'Xã Đạo Nghĩa',
            code: 24757,
            value: 'xa_dao_nghia',
            division_type: 'xã',
            short_codename: 'dao_nghia',
            parentId: 666,
          },
          {
            title: 'Xã Đắk Sin',
            code: 24760,
            value: 'xa_dak_sin',
            division_type: 'xã',
            short_codename: 'dak_sin',
            parentId: 666,
          },
          {
            title: 'Xã Hưng Bình',
            code: 24761,
            value: 'xa_hung_binh',
            division_type: 'xã',
            short_codename: 'hung_binh',
            parentId: 666,
          },
          {
            title: 'Xã Đắk Ru',
            code: 24763,
            value: 'xa_dak_ru',
            division_type: 'xã',
            short_codename: 'dak_ru',
            parentId: 666,
          },
          {
            title: 'Xã Nhân Đạo',
            code: 24766,
            value: 'xa_nhan_dao',
            division_type: 'xã',
            short_codename: 'nhan_dao',
            parentId: 666,
          },
        ],
        parentId: 67,
      },
      {
        title: 'Huyện Tuy Đức',
        code: 667,
        value: 'huyen_tuy_duc',
        division_type: 'huyện',
        short_codename: 'tuy_duc',
        children: [
          {
            title: 'Xã Quảng Trực',
            code: 24736,
            value: 'xa_quang_truc',
            division_type: 'xã',
            short_codename: 'quang_truc',
            parentId: 667,
          },
          {
            title: 'Xã Đắk Búk So',
            code: 24739,
            value: 'xa_dak_buk_so',
            division_type: 'xã',
            short_codename: 'dak_buk_so',
            parentId: 667,
          },
          {
            title: 'Xã Quảng Tâm',
            code: 24740,
            value: 'xa_quang_tam',
            division_type: 'xã',
            short_codename: 'quang_tam',
            parentId: 667,
          },
          {
            title: "Xã Đắk R'Tíh",
            code: 24742,
            value: 'xa_dak_rtih',
            division_type: 'xã',
            short_codename: 'dak_rtih',
            parentId: 667,
          },
          {
            title: 'Xã Đắk Ngo',
            code: 24746,
            value: 'xa_dak_ngo',
            division_type: 'xã',
            short_codename: 'dak_ngo',
            parentId: 667,
          },
          {
            title: 'Xã Quảng Tân',
            code: 24748,
            value: 'xa_quang_tan',
            division_type: 'xã',
            short_codename: 'quang_tan',
            parentId: 667,
          },
        ],
        parentId: 67,
      },
    ],
  },
  {
    title: 'Tỉnh Lâm Đồng',
    code: 68,
    value: 'tinh_lam_dong',
    division_type: 'tỉnh',
    phone_code: 263,
    children: [
      {
        title: 'Thành phố Đà Lạt',
        code: 672,
        value: 'thanh_pho_da_lat',
        division_type: 'thành phố',
        short_codename: 'da_lat',
        children: [
          {
            title: 'Phường 7',
            code: 24769,
            value: 'phuong_7',
            division_type: 'phường',
            short_codename: 'phuong_7',
            parentId: 672,
          },
          {
            title: 'Phường 8',
            code: 24772,
            value: 'phuong_8',
            division_type: 'phường',
            short_codename: 'phuong_8',
            parentId: 672,
          },
          {
            title: 'Phường 12',
            code: 24775,
            value: 'phuong_12',
            division_type: 'phường',
            short_codename: 'phuong_12',
            parentId: 672,
          },
          {
            title: 'Phường 9',
            code: 24778,
            value: 'phuong_9',
            division_type: 'phường',
            short_codename: 'phuong_9',
            parentId: 672,
          },
          {
            title: 'Phường 2',
            code: 24781,
            value: 'phuong_2',
            division_type: 'phường',
            short_codename: 'phuong_2',
            parentId: 672,
          },
          {
            title: 'Phường 1',
            code: 24784,
            value: 'phuong_1',
            division_type: 'phường',
            short_codename: 'phuong_1',
            parentId: 672,
          },
          {
            title: 'Phường 6',
            code: 24787,
            value: 'phuong_6',
            division_type: 'phường',
            short_codename: 'phuong_6',
            parentId: 672,
          },
          {
            title: 'Phường 5',
            code: 24790,
            value: 'phuong_5',
            division_type: 'phường',
            short_codename: 'phuong_5',
            parentId: 672,
          },
          {
            title: 'Phường 4',
            code: 24793,
            value: 'phuong_4',
            division_type: 'phường',
            short_codename: 'phuong_4',
            parentId: 672,
          },
          {
            title: 'Phường 10',
            code: 24796,
            value: 'phuong_10',
            division_type: 'phường',
            short_codename: 'phuong_10',
            parentId: 672,
          },
          {
            title: 'Phường 11',
            code: 24799,
            value: 'phuong_11',
            division_type: 'phường',
            short_codename: 'phuong_11',
            parentId: 672,
          },
          {
            title: 'Phường 3',
            code: 24802,
            value: 'phuong_3',
            division_type: 'phường',
            short_codename: 'phuong_3',
            parentId: 672,
          },
          {
            title: 'Xã Xuân Thọ',
            code: 24805,
            value: 'xa_xuan_tho',
            division_type: 'xã',
            short_codename: 'xuan_tho',
            parentId: 672,
          },
          {
            title: 'Xã Tà Nung',
            code: 24808,
            value: 'xa_ta_nung',
            division_type: 'xã',
            short_codename: 'ta_nung',
            parentId: 672,
          },
          {
            title: 'Xã Trạm Hành',
            code: 24810,
            value: 'xa_tram_hanh',
            division_type: 'xã',
            short_codename: 'tram_hanh',
            parentId: 672,
          },
          {
            title: 'Xã Xuân Trường',
            code: 24811,
            value: 'xa_xuan_truong',
            division_type: 'xã',
            short_codename: 'xuan_truong',
            parentId: 672,
          },
        ],
        parentId: 68,
      },
      {
        title: 'Thành phố Bảo Lộc',
        code: 673,
        value: 'thanh_pho_bao_loc',
        division_type: 'thành phố',
        short_codename: 'bao_loc',
        children: [
          {
            title: 'Phường Lộc Phát',
            code: 24814,
            value: 'phuong_loc_phat',
            division_type: 'phường',
            short_codename: 'loc_phat',
            parentId: 673,
          },
          {
            title: 'Phường Lộc Tiến',
            code: 24817,
            value: 'phuong_loc_tien',
            division_type: 'phường',
            short_codename: 'loc_tien',
            parentId: 673,
          },
          {
            title: 'Phường 2',
            code: 24820,
            value: 'phuong_2',
            division_type: 'phường',
            short_codename: 'phuong_2',
            parentId: 673,
          },
          {
            title: 'Phường 1',
            code: 24823,
            value: 'phuong_1',
            division_type: 'phường',
            short_codename: 'phuong_1',
            parentId: 673,
          },
          {
            title: "Phường B'lao",
            code: 24826,
            value: 'phuong_blao',
            division_type: 'phường',
            short_codename: 'blao',
            parentId: 673,
          },
          {
            title: 'Phường Lộc Sơn',
            code: 24829,
            value: 'phuong_loc_son',
            division_type: 'phường',
            short_codename: 'loc_son',
            parentId: 673,
          },
          {
            title: 'Xã Đạm Bri',
            code: 24832,
            value: 'xa_dam_bri',
            division_type: 'xã',
            short_codename: 'dam_bri',
            parentId: 673,
          },
          {
            title: 'Xã Lộc Thanh',
            code: 24835,
            value: 'xa_loc_thanh',
            division_type: 'xã',
            short_codename: 'loc_thanh',
            parentId: 673,
          },
          {
            title: 'Xã Lộc Nga',
            code: 24838,
            value: 'xa_loc_nga',
            division_type: 'xã',
            short_codename: 'loc_nga',
            parentId: 673,
          },
          {
            title: 'Xã Lộc Châu',
            code: 24841,
            value: 'xa_loc_chau',
            division_type: 'xã',
            short_codename: 'loc_chau',
            parentId: 673,
          },
          {
            title: 'Xã Đại Lào',
            code: 24844,
            value: 'xa_dai_lao',
            division_type: 'xã',
            short_codename: 'dai_lao',
            parentId: 673,
          },
        ],
        parentId: 68,
      },
      {
        title: 'Huyện Đam Rông',
        code: 674,
        value: 'huyen_dam_rong',
        division_type: 'huyện',
        short_codename: 'dam_rong',
        children: [
          {
            title: 'Xã Đạ Tông',
            code: 24853,
            value: 'xa_da_tong',
            division_type: 'xã',
            short_codename: 'da_tong',
            parentId: 674,
          },
          {
            title: 'Xã Đạ Long',
            code: 24856,
            value: 'xa_da_long',
            division_type: 'xã',
            short_codename: 'da_long',
            parentId: 674,
          },
          {
            title: "Xã Đạ M' Rong",
            code: 24859,
            value: 'xa_da_m_rong',
            division_type: 'xã',
            short_codename: 'da_m_rong',
            parentId: 674,
          },
          {
            title: 'Xã Liêng Srônh',
            code: 24874,
            value: 'xa_lieng_sronh',
            division_type: 'xã',
            short_codename: 'lieng_sronh',
            parentId: 674,
          },
          {
            title: 'Xã Đạ Rsal',
            code: 24875,
            value: 'xa_da_rsal',
            division_type: 'xã',
            short_codename: 'da_rsal',
            parentId: 674,
          },
          {
            title: 'Xã Rô Men',
            code: 24877,
            value: 'xa_ro_men',
            division_type: 'xã',
            short_codename: 'ro_men',
            parentId: 674,
          },
          {
            title: 'Xã Phi Liêng',
            code: 24886,
            value: 'xa_phi_lieng',
            division_type: 'xã',
            short_codename: 'phi_lieng',
            parentId: 674,
          },
          {
            title: "Xã Đạ K' Nàng",
            code: 24889,
            value: 'xa_da_k_nang',
            division_type: 'xã',
            short_codename: 'da_k_nang',
            parentId: 674,
          },
        ],
        parentId: 68,
      },
      {
        title: 'Huyện Lạc Dương',
        code: 675,
        value: 'huyen_lac_duong',
        division_type: 'huyện',
        short_codename: 'lac_duong',
        children: [
          {
            title: 'Thị trấn Lạc Dương',
            code: 24846,
            value: 'thi_tran_lac_duong',
            division_type: 'thị trấn',
            short_codename: 'lac_duong',
            parentId: 675,
          },
          {
            title: 'Xã Đạ Chais',
            code: 24847,
            value: 'xa_da_chais',
            division_type: 'xã',
            short_codename: 'da_chais',
            parentId: 675,
          },
          {
            title: 'Xã Đạ Nhim',
            code: 24848,
            value: 'xa_da_nhim',
            division_type: 'xã',
            short_codename: 'da_nhim',
            parentId: 675,
          },
          {
            title: 'Xã Đưng KNớ',
            code: 24850,
            value: 'xa_dung_kno',
            division_type: 'xã',
            short_codename: 'dung_kno',
            parentId: 675,
          },
          {
            title: 'Xã Lát',
            code: 24862,
            value: 'xa_lat',
            division_type: 'xã',
            short_codename: 'lat',
            parentId: 675,
          },
          {
            title: 'Xã Đạ Sar',
            code: 24865,
            value: 'xa_da_sar',
            division_type: 'xã',
            short_codename: 'da_sar',
            parentId: 675,
          },
        ],
        parentId: 68,
      },
      {
        title: 'Huyện Lâm Hà',
        code: 676,
        value: 'huyen_lam_ha',
        division_type: 'huyện',
        short_codename: 'lam_ha',
        children: [
          {
            title: 'Thị trấn Nam Ban',
            code: 24868,
            value: 'thi_tran_nam_ban',
            division_type: 'thị trấn',
            short_codename: 'nam_ban',
            parentId: 676,
          },
          {
            title: 'Thị trấn Đinh Văn',
            code: 24871,
            value: 'thi_tran_dinh_van',
            division_type: 'thị trấn',
            short_codename: 'dinh_van',
            parentId: 676,
          },
          {
            title: 'Xã Phú Sơn',
            code: 24880,
            value: 'xa_phu_son',
            division_type: 'xã',
            short_codename: 'phu_son',
            parentId: 676,
          },
          {
            title: 'Xã Phi Tô',
            code: 24883,
            value: 'xa_phi_to',
            division_type: 'xã',
            short_codename: 'phi_to',
            parentId: 676,
          },
          {
            title: 'Xã Mê Linh',
            code: 24892,
            value: 'xa_me_linh',
            division_type: 'xã',
            short_codename: 'me_linh',
            parentId: 676,
          },
          {
            title: 'Xã Đạ Đờn',
            code: 24895,
            value: 'xa_da_don',
            division_type: 'xã',
            short_codename: 'da_don',
            parentId: 676,
          },
          {
            title: 'Xã Phúc Thọ',
            code: 24898,
            value: 'xa_phuc_tho',
            division_type: 'xã',
            short_codename: 'phuc_tho',
            parentId: 676,
          },
          {
            title: 'Xã Đông Thanh',
            code: 24901,
            value: 'xa_dong_thanh',
            division_type: 'xã',
            short_codename: 'dong_thanh',
            parentId: 676,
          },
          {
            title: 'Xã Gia Lâm',
            code: 24904,
            value: 'xa_gia_lam',
            division_type: 'xã',
            short_codename: 'gia_lam',
            parentId: 676,
          },
          {
            title: 'Xã Tân Thanh',
            code: 24907,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 676,
          },
          {
            title: 'Xã Tân Văn',
            code: 24910,
            value: 'xa_tan_van',
            division_type: 'xã',
            short_codename: 'tan_van',
            parentId: 676,
          },
          {
            title: 'Xã Hoài Đức',
            code: 24913,
            value: 'xa_hoai_duc',
            division_type: 'xã',
            short_codename: 'hoai_duc',
            parentId: 676,
          },
          {
            title: 'Xã Tân Hà',
            code: 24916,
            value: 'xa_tan_ha',
            division_type: 'xã',
            short_codename: 'tan_ha',
            parentId: 676,
          },
          {
            title: 'Xã Liên Hà',
            code: 24919,
            value: 'xa_lien_ha',
            division_type: 'xã',
            short_codename: 'lien_ha',
            parentId: 676,
          },
          {
            title: 'Xã Đan Phượng',
            code: 24922,
            value: 'xa_dan_phuong',
            division_type: 'xã',
            short_codename: 'dan_phuong',
            parentId: 676,
          },
          {
            title: 'Xã Nam Hà',
            code: 24925,
            value: 'xa_nam_ha',
            division_type: 'xã',
            short_codename: 'nam_ha',
            parentId: 676,
          },
        ],
        parentId: 68,
      },
      {
        title: 'Huyện Đơn Dương',
        code: 677,
        value: 'huyen_don_duong',
        division_type: 'huyện',
        short_codename: 'don_duong',
        children: [
          {
            title: "Thị trấn D'Ran",
            code: 24928,
            value: 'thi_tran_dran',
            division_type: 'thị trấn',
            short_codename: 'dran',
            parentId: 677,
          },
          {
            title: 'Thị trấn Thạnh Mỹ',
            code: 24931,
            value: 'thi_tran_thanh_my',
            division_type: 'thị trấn',
            short_codename: 'thanh_my',
            parentId: 677,
          },
          {
            title: 'Xã Lạc Xuân',
            code: 24934,
            value: 'xa_lac_xuan',
            division_type: 'xã',
            short_codename: 'lac_xuan',
            parentId: 677,
          },
          {
            title: 'Xã Đạ Ròn',
            code: 24937,
            value: 'xa_da_ron',
            division_type: 'xã',
            short_codename: 'da_ron',
            parentId: 677,
          },
          {
            title: 'Xã Lạc Lâm',
            code: 24940,
            value: 'xa_lac_lam',
            division_type: 'xã',
            short_codename: 'lac_lam',
            parentId: 677,
          },
          {
            title: 'Xã Ka Đô',
            code: 24943,
            value: 'xa_ka_do',
            division_type: 'xã',
            short_codename: 'ka_do',
            parentId: 677,
          },
          {
            title: 'Xã Quảng Lập',
            code: 24946,
            value: 'xa_quang_lap',
            division_type: 'xã',
            short_codename: 'quang_lap',
            parentId: 677,
          },
          {
            title: 'Xã Ka Đơn',
            code: 24949,
            value: 'xa_ka_don',
            division_type: 'xã',
            short_codename: 'ka_don',
            parentId: 677,
          },
          {
            title: 'Xã Tu Tra',
            code: 24952,
            value: 'xa_tu_tra',
            division_type: 'xã',
            short_codename: 'tu_tra',
            parentId: 677,
          },
          {
            title: 'Xã Pró',
            code: 24955,
            value: 'xa_pro',
            division_type: 'xã',
            short_codename: 'pro',
            parentId: 677,
          },
        ],
        parentId: 68,
      },
      {
        title: 'Huyện Đức Trọng',
        code: 678,
        value: 'huyen_duc_trong',
        division_type: 'huyện',
        short_codename: 'duc_trong',
        children: [
          {
            title: 'Thị trấn Liên Nghĩa',
            code: 24958,
            value: 'thi_tran_lien_nghia',
            division_type: 'thị trấn',
            short_codename: 'lien_nghia',
            parentId: 678,
          },
          {
            title: 'Xã Hiệp An',
            code: 24961,
            value: 'xa_hiep_an',
            division_type: 'xã',
            short_codename: 'hiep_an',
            parentId: 678,
          },
          {
            title: 'Xã Liên Hiệp',
            code: 24964,
            value: 'xa_lien_hiep',
            division_type: 'xã',
            short_codename: 'lien_hiep',
            parentId: 678,
          },
          {
            title: 'Xã Hiệp Thạnh',
            code: 24967,
            value: 'xa_hiep_thanh',
            division_type: 'xã',
            short_codename: 'hiep_thanh',
            parentId: 678,
          },
          {
            title: 'Xã Bình Thạnh',
            code: 24970,
            value: 'xa_binh_thanh',
            division_type: 'xã',
            short_codename: 'binh_thanh',
            parentId: 678,
          },
          {
            title: "Xã N'Thol Hạ",
            code: 24973,
            value: 'xa_nthol_ha',
            division_type: 'xã',
            short_codename: 'nthol_ha',
            parentId: 678,
          },
          {
            title: 'Xã Tân Hội',
            code: 24976,
            value: 'xa_tan_hoi',
            division_type: 'xã',
            short_codename: 'tan_hoi',
            parentId: 678,
          },
          {
            title: 'Xã Tân Thành',
            code: 24979,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 678,
          },
          {
            title: 'Xã Phú Hội',
            code: 24982,
            value: 'xa_phu_hoi',
            division_type: 'xã',
            short_codename: 'phu_hoi',
            parentId: 678,
          },
          {
            title: 'Xã Ninh Gia',
            code: 24985,
            value: 'xa_ninh_gia',
            division_type: 'xã',
            short_codename: 'ninh_gia',
            parentId: 678,
          },
          {
            title: 'Xã Tà Năng',
            code: 24988,
            value: 'xa_ta_nang',
            division_type: 'xã',
            short_codename: 'ta_nang',
            parentId: 678,
          },
          {
            title: 'Xã Đa Quyn',
            code: 24989,
            value: 'xa_da_quyn',
            division_type: 'xã',
            short_codename: 'da_quyn',
            parentId: 678,
          },
          {
            title: 'Xã Tà Hine',
            code: 24991,
            value: 'xa_ta_hine',
            division_type: 'xã',
            short_codename: 'ta_hine',
            parentId: 678,
          },
          {
            title: 'Xã Đà Loan',
            code: 24994,
            value: 'xa_da_loan',
            division_type: 'xã',
            short_codename: 'da_loan',
            parentId: 678,
          },
          {
            title: 'Xã Ninh Loan',
            code: 24997,
            value: 'xa_ninh_loan',
            division_type: 'xã',
            short_codename: 'ninh_loan',
            parentId: 678,
          },
        ],
        parentId: 68,
      },
      {
        title: 'Huyện Di Linh',
        code: 679,
        value: 'huyen_di_linh',
        division_type: 'huyện',
        short_codename: 'di_linh',
        children: [
          {
            title: 'Thị trấn Di Linh',
            code: 25000,
            value: 'thi_tran_di_linh',
            division_type: 'thị trấn',
            short_codename: 'di_linh',
            parentId: 679,
          },
          {
            title: 'Xã Đinh Trang Thượng',
            code: 25003,
            value: 'xa_dinh_trang_thuong',
            division_type: 'xã',
            short_codename: 'dinh_trang_thuong',
            parentId: 679,
          },
          {
            title: 'Xã Tân Thượng',
            code: 25006,
            value: 'xa_tan_thuong',
            division_type: 'xã',
            short_codename: 'tan_thuong',
            parentId: 679,
          },
          {
            title: 'Xã Tân Lâm',
            code: 25007,
            value: 'xa_tan_lam',
            division_type: 'xã',
            short_codename: 'tan_lam',
            parentId: 679,
          },
          {
            title: 'Xã Tân Châu',
            code: 25009,
            value: 'xa_tan_chau',
            division_type: 'xã',
            short_codename: 'tan_chau',
            parentId: 679,
          },
          {
            title: 'Xã Tân Nghĩa',
            code: 25012,
            value: 'xa_tan_nghia',
            division_type: 'xã',
            short_codename: 'tan_nghia',
            parentId: 679,
          },
          {
            title: 'Xã Gia Hiệp',
            code: 25015,
            value: 'xa_gia_hiep',
            division_type: 'xã',
            short_codename: 'gia_hiep',
            parentId: 679,
          },
          {
            title: 'Xã Đinh Lạc',
            code: 25018,
            value: 'xa_dinh_lac',
            division_type: 'xã',
            short_codename: 'dinh_lac',
            parentId: 679,
          },
          {
            title: 'Xã Tam Bố',
            code: 25021,
            value: 'xa_tam_bo',
            division_type: 'xã',
            short_codename: 'tam_bo',
            parentId: 679,
          },
          {
            title: 'Xã Đinh Trang Hòa',
            code: 25024,
            value: 'xa_dinh_trang_hoa',
            division_type: 'xã',
            short_codename: 'dinh_trang_hoa',
            parentId: 679,
          },
          {
            title: 'Xã Liên Đầm',
            code: 25027,
            value: 'xa_lien_dam',
            division_type: 'xã',
            short_codename: 'lien_dam',
            parentId: 679,
          },
          {
            title: 'Xã Gung Ré',
            code: 25030,
            value: 'xa_gung_re',
            division_type: 'xã',
            short_codename: 'gung_re',
            parentId: 679,
          },
          {
            title: 'Xã Bảo Thuận',
            code: 25033,
            value: 'xa_bao_thuan',
            division_type: 'xã',
            short_codename: 'bao_thuan',
            parentId: 679,
          },
          {
            title: 'Xã Hòa Ninh',
            code: 25036,
            value: 'xa_hoa_ninh',
            division_type: 'xã',
            short_codename: 'hoa_ninh',
            parentId: 679,
          },
          {
            title: 'Xã Hòa Trung',
            code: 25039,
            value: 'xa_hoa_trung',
            division_type: 'xã',
            short_codename: 'hoa_trung',
            parentId: 679,
          },
          {
            title: 'Xã Hòa Nam',
            code: 25042,
            value: 'xa_hoa_nam',
            division_type: 'xã',
            short_codename: 'hoa_nam',
            parentId: 679,
          },
          {
            title: 'Xã Hòa Bắc',
            code: 25045,
            value: 'xa_hoa_bac',
            division_type: 'xã',
            short_codename: 'hoa_bac',
            parentId: 679,
          },
          {
            title: 'Xã Sơn Điền',
            code: 25048,
            value: 'xa_son_dien',
            division_type: 'xã',
            short_codename: 'son_dien',
            parentId: 679,
          },
          {
            title: 'Xã Gia Bắc',
            code: 25051,
            value: 'xa_gia_bac',
            division_type: 'xã',
            short_codename: 'gia_bac',
            parentId: 679,
          },
        ],
        parentId: 68,
      },
      {
        title: 'Huyện Bảo Lâm',
        code: 680,
        value: 'huyen_bao_lam',
        division_type: 'huyện',
        short_codename: 'bao_lam',
        children: [
          {
            title: 'Thị trấn Lộc Thắng',
            code: 25054,
            value: 'thi_tran_loc_thang',
            division_type: 'thị trấn',
            short_codename: 'loc_thang',
            parentId: 680,
          },
          {
            title: 'Xã Lộc Bảo',
            code: 25057,
            value: 'xa_loc_bao',
            division_type: 'xã',
            short_codename: 'loc_bao',
            parentId: 680,
          },
          {
            title: 'Xã Lộc Lâm',
            code: 25060,
            value: 'xa_loc_lam',
            division_type: 'xã',
            short_codename: 'loc_lam',
            parentId: 680,
          },
          {
            title: 'Xã Lộc Phú',
            code: 25063,
            value: 'xa_loc_phu',
            division_type: 'xã',
            short_codename: 'loc_phu',
            parentId: 680,
          },
          {
            title: 'Xã Lộc Bắc',
            code: 25066,
            value: 'xa_loc_bac',
            division_type: 'xã',
            short_codename: 'loc_bac',
            parentId: 680,
          },
          {
            title: "Xã B' Lá",
            code: 25069,
            value: 'xa_b_la',
            division_type: 'xã',
            short_codename: 'b_la',
            parentId: 680,
          },
          {
            title: 'Xã Lộc Ngãi',
            code: 25072,
            value: 'xa_loc_ngai',
            division_type: 'xã',
            short_codename: 'loc_ngai',
            parentId: 680,
          },
          {
            title: 'Xã Lộc Quảng',
            code: 25075,
            value: 'xa_loc_quang',
            division_type: 'xã',
            short_codename: 'loc_quang',
            parentId: 680,
          },
          {
            title: 'Xã Lộc Tân',
            code: 25078,
            value: 'xa_loc_tan',
            division_type: 'xã',
            short_codename: 'loc_tan',
            parentId: 680,
          },
          {
            title: 'Xã Lộc Đức',
            code: 25081,
            value: 'xa_loc_duc',
            division_type: 'xã',
            short_codename: 'loc_duc',
            parentId: 680,
          },
          {
            title: 'Xã Lộc An',
            code: 25084,
            value: 'xa_loc_an',
            division_type: 'xã',
            short_codename: 'loc_an',
            parentId: 680,
          },
          {
            title: 'Xã Tân Lạc',
            code: 25087,
            value: 'xa_tan_lac',
            division_type: 'xã',
            short_codename: 'tan_lac',
            parentId: 680,
          },
          {
            title: 'Xã Lộc Thành',
            code: 25090,
            value: 'xa_loc_thanh',
            division_type: 'xã',
            short_codename: 'loc_thanh',
            parentId: 680,
          },
          {
            title: 'Xã Lộc Nam',
            code: 25093,
            value: 'xa_loc_nam',
            division_type: 'xã',
            short_codename: 'loc_nam',
            parentId: 680,
          },
        ],
        parentId: 68,
      },
      {
        title: 'Huyện Đạ Huoai',
        code: 681,
        value: 'huyen_da_huoai',
        division_type: 'huyện',
        short_codename: 'da_huoai',
        children: [
          {
            title: "Thị trấn Đạ M'ri",
            code: 25096,
            value: 'thi_tran_da_mri',
            division_type: 'thị trấn',
            short_codename: 'da_mri',
            parentId: 681,
          },
          {
            title: 'Thị trấn Ma Đa Guôi',
            code: 25099,
            value: 'thi_tran_ma_da_guoi',
            division_type: 'thị trấn',
            short_codename: 'ma_da_guoi',
            parentId: 681,
          },
          {
            title: 'Xã Hà Lâm',
            code: 25105,
            value: 'xa_ha_lam',
            division_type: 'xã',
            short_codename: 'ha_lam',
            parentId: 681,
          },
          {
            title: 'Xã Đạ Tồn',
            code: 25108,
            value: 'xa_da_ton',
            division_type: 'xã',
            short_codename: 'da_ton',
            parentId: 681,
          },
          {
            title: 'Xã Đạ Oai',
            code: 25111,
            value: 'xa_da_oai',
            division_type: 'xã',
            short_codename: 'da_oai',
            parentId: 681,
          },
          {
            title: 'Xã Đạ Ploa',
            code: 25114,
            value: 'xa_da_ploa',
            division_type: 'xã',
            short_codename: 'da_ploa',
            parentId: 681,
          },
          {
            title: 'Xã Ma Đa Guôi',
            code: 25117,
            value: 'xa_ma_da_guoi',
            division_type: 'xã',
            short_codename: 'xa_ma_da_guoi',
            parentId: 681,
          },
          {
            title: 'Xã Đoàn Kết',
            code: 25120,
            value: 'xa_doan_ket',
            division_type: 'xã',
            short_codename: 'doan_ket',
            parentId: 681,
          },
          {
            title: 'Xã Phước Lộc',
            code: 25123,
            value: 'xa_phuoc_loc',
            division_type: 'xã',
            short_codename: 'phuoc_loc',
            parentId: 681,
          },
        ],
        parentId: 68,
      },
      {
        title: 'Huyện Đạ Tẻh',
        code: 682,
        value: 'huyen_da_teh',
        division_type: 'huyện',
        short_codename: 'da_teh',
        children: [
          {
            title: 'Thị trấn Đạ Tẻh',
            code: 25126,
            value: 'thi_tran_da_teh',
            division_type: 'thị trấn',
            short_codename: 'da_teh',
            parentId: 682,
          },
          {
            title: 'Xã An Nhơn',
            code: 25129,
            value: 'xa_an_nhon',
            division_type: 'xã',
            short_codename: 'an_nhon',
            parentId: 682,
          },
          {
            title: 'Xã Quốc Oai',
            code: 25132,
            value: 'xa_quoc_oai',
            division_type: 'xã',
            short_codename: 'quoc_oai',
            parentId: 682,
          },
          {
            title: 'Xã Mỹ Đức',
            code: 25135,
            value: 'xa_my_duc',
            division_type: 'xã',
            short_codename: 'my_duc',
            parentId: 682,
          },
          {
            title: 'Xã Quảng Trị',
            code: 25138,
            value: 'xa_quang_tri',
            division_type: 'xã',
            short_codename: 'quang_tri',
            parentId: 682,
          },
          {
            title: 'Xã Đạ Lây',
            code: 25141,
            value: 'xa_da_lay',
            division_type: 'xã',
            short_codename: 'da_lay',
            parentId: 682,
          },
          {
            title: 'Xã Triệu Hải',
            code: 25147,
            value: 'xa_trieu_hai',
            division_type: 'xã',
            short_codename: 'trieu_hai',
            parentId: 682,
          },
          {
            title: 'Xã Đạ Kho',
            code: 25153,
            value: 'xa_da_kho',
            division_type: 'xã',
            short_codename: 'da_kho',
            parentId: 682,
          },
          {
            title: 'Xã Đạ Pal',
            code: 25156,
            value: 'xa_da_pal',
            division_type: 'xã',
            short_codename: 'da_pal',
            parentId: 682,
          },
        ],
        parentId: 68,
      },
      {
        title: 'Huyện Cát Tiên',
        code: 683,
        value: 'huyen_cat_tien',
        division_type: 'huyện',
        short_codename: 'cat_tien',
        children: [
          {
            title: 'Thị trấn Cát Tiên',
            code: 25159,
            value: 'thi_tran_cat_tien',
            division_type: 'thị trấn',
            short_codename: 'cat_tien',
            parentId: 683,
          },
          {
            title: 'Xã Tiên Hoàng',
            code: 25162,
            value: 'xa_tien_hoang',
            division_type: 'xã',
            short_codename: 'tien_hoang',
            parentId: 683,
          },
          {
            title: 'Xã Phước Cát 2',
            code: 25165,
            value: 'xa_phuoc_cat_2',
            division_type: 'xã',
            short_codename: 'phuoc_cat_2',
            parentId: 683,
          },
          {
            title: 'Xã Gia Viễn',
            code: 25168,
            value: 'xa_gia_vien',
            division_type: 'xã',
            short_codename: 'gia_vien',
            parentId: 683,
          },
          {
            title: 'Xã Nam Ninh',
            code: 25171,
            value: 'xa_nam_ninh',
            division_type: 'xã',
            short_codename: 'nam_ninh',
            parentId: 683,
          },
          {
            title: 'Thị trấn Phước Cát',
            code: 25180,
            value: 'thi_tran_phuoc_cat',
            division_type: 'thị trấn',
            short_codename: 'phuoc_cat',
            parentId: 683,
          },
          {
            title: 'Xã Đức Phổ',
            code: 25183,
            value: 'xa_duc_pho',
            division_type: 'xã',
            short_codename: 'duc_pho',
            parentId: 683,
          },
          {
            title: 'Xã Quảng Ngãi',
            code: 25189,
            value: 'xa_quang_ngai',
            division_type: 'xã',
            short_codename: 'quang_ngai',
            parentId: 683,
          },
          {
            title: 'Xã Đồng Nai Thượng',
            code: 25192,
            value: 'xa_dong_nai_thuong',
            division_type: 'xã',
            short_codename: 'dong_nai_thuong',
            parentId: 683,
          },
        ],
        parentId: 68,
      },
    ],
  },
  {
    title: 'Tỉnh Bình Phước',
    code: 70,
    value: 'tinh_binh_phuoc',
    division_type: 'tỉnh',
    phone_code: 271,
    children: [
      {
        title: 'Thị xã Phước Long',
        code: 688,
        value: 'thi_xa_phuoc_long',
        division_type: 'thị xã',
        short_codename: 'phuoc_long',
        children: [
          {
            title: 'Phường Thác Mơ',
            code: 25216,
            value: 'phuong_thac_mo',
            division_type: 'phường',
            short_codename: 'thac_mo',
            parentId: 688,
          },
          {
            title: 'Phường Long Thủy',
            code: 25217,
            value: 'phuong_long_thuy',
            division_type: 'phường',
            short_codename: 'long_thuy',
            parentId: 688,
          },
          {
            title: 'Phường Phước Bình',
            code: 25219,
            value: 'phuong_phuoc_binh',
            division_type: 'phường',
            short_codename: 'phuoc_binh',
            parentId: 688,
          },
          {
            title: 'Phường Long Phước',
            code: 25220,
            value: 'phuong_long_phuoc',
            division_type: 'phường',
            short_codename: 'long_phuoc',
            parentId: 688,
          },
          {
            title: 'Phường Sơn Giang',
            code: 25237,
            value: 'phuong_son_giang',
            division_type: 'phường',
            short_codename: 'son_giang',
            parentId: 688,
          },
          {
            title: 'Xã Long Giang',
            code: 25245,
            value: 'xa_long_giang',
            division_type: 'xã',
            short_codename: 'long_giang',
            parentId: 688,
          },
          {
            title: 'Xã Phước Tín',
            code: 25249,
            value: 'xa_phuoc_tin',
            division_type: 'xã',
            short_codename: 'phuoc_tin',
            parentId: 688,
          },
        ],
        parentId: 70,
      },
      {
        title: 'Thành phố Đồng Xoài',
        code: 689,
        value: 'thanh_pho_dong_xoai',
        division_type: 'thành phố',
        short_codename: 'dong_xoai',
        children: [
          {
            title: 'Phường Tân Phú',
            code: 25195,
            value: 'phuong_tan_phu',
            division_type: 'phường',
            short_codename: 'tan_phu',
            parentId: 689,
          },
          {
            title: 'Phường Tân Đồng',
            code: 25198,
            value: 'phuong_tan_dong',
            division_type: 'phường',
            short_codename: 'tan_dong',
            parentId: 689,
          },
          {
            title: 'Phường Tân Bình',
            code: 25201,
            value: 'phuong_tan_binh',
            division_type: 'phường',
            short_codename: 'tan_binh',
            parentId: 689,
          },
          {
            title: 'Phường Tân Xuân',
            code: 25204,
            value: 'phuong_tan_xuan',
            division_type: 'phường',
            short_codename: 'tan_xuan',
            parentId: 689,
          },
          {
            title: 'Phường Tân Thiện',
            code: 25205,
            value: 'phuong_tan_thien',
            division_type: 'phường',
            short_codename: 'tan_thien',
            parentId: 689,
          },
          {
            title: 'Xã Tân Thành',
            code: 25207,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 689,
          },
          {
            title: 'Phường Tiến Thành',
            code: 25210,
            value: 'phuong_tien_thanh',
            division_type: 'phường',
            short_codename: 'tien_thanh',
            parentId: 689,
          },
          {
            title: 'Xã Tiến Hưng',
            code: 25213,
            value: 'xa_tien_hung',
            division_type: 'xã',
            short_codename: 'tien_hung',
            parentId: 689,
          },
        ],
        parentId: 70,
      },
      {
        title: 'Thị xã Bình Long',
        code: 690,
        value: 'thi_xa_binh_long',
        division_type: 'thị xã',
        short_codename: 'binh_long',
        children: [
          {
            title: 'Phường Hưng Chiến',
            code: 25320,
            value: 'phuong_hung_chien',
            division_type: 'phường',
            short_codename: 'hung_chien',
            parentId: 690,
          },
          {
            title: 'Phường An Lộc',
            code: 25324,
            value: 'phuong_an_loc',
            division_type: 'phường',
            short_codename: 'an_loc',
            parentId: 690,
          },
          {
            title: 'Phường Phú Thịnh',
            code: 25325,
            value: 'phuong_phu_thinh',
            division_type: 'phường',
            short_codename: 'phu_thinh',
            parentId: 690,
          },
          {
            title: 'Phường Phú Đức',
            code: 25326,
            value: 'phuong_phu_duc',
            division_type: 'phường',
            short_codename: 'phu_duc',
            parentId: 690,
          },
          {
            title: 'Xã Thanh Lương',
            code: 25333,
            value: 'xa_thanh_luong',
            division_type: 'xã',
            short_codename: 'thanh_luong',
            parentId: 690,
          },
          {
            title: 'Xã Thanh Phú',
            code: 25336,
            value: 'xa_thanh_phu',
            division_type: 'xã',
            short_codename: 'thanh_phu',
            parentId: 690,
          },
        ],
        parentId: 70,
      },
      {
        title: 'Huyện Bù Gia Mập',
        code: 691,
        value: 'huyen_bu_gia_map',
        division_type: 'huyện',
        short_codename: 'bu_gia_map',
        children: [
          {
            title: 'Xã Bù Gia Mập',
            code: 25222,
            value: 'xa_bu_gia_map',
            division_type: 'xã',
            short_codename: 'bu_gia_map',
            parentId: 691,
          },
          {
            title: 'Xã Đak Ơ',
            code: 25225,
            value: 'xa_dak_o',
            division_type: 'xã',
            short_codename: 'dak_o',
            parentId: 691,
          },
          {
            title: 'Xã Đức Hạnh',
            code: 25228,
            value: 'xa_duc_hanh',
            division_type: 'xã',
            short_codename: 'duc_hanh',
            parentId: 691,
          },
          {
            title: 'Xã Phú Văn',
            code: 25229,
            value: 'xa_phu_van',
            division_type: 'xã',
            short_codename: 'phu_van',
            parentId: 691,
          },
          {
            title: 'Xã Đa Kia',
            code: 25231,
            value: 'xa_da_kia',
            division_type: 'xã',
            short_codename: 'da_kia',
            parentId: 691,
          },
          {
            title: 'Xã Phước Minh',
            code: 25232,
            value: 'xa_phuoc_minh',
            division_type: 'xã',
            short_codename: 'phuoc_minh',
            parentId: 691,
          },
          {
            title: 'Xã Bình Thắng',
            code: 25234,
            value: 'xa_binh_thang',
            division_type: 'xã',
            short_codename: 'binh_thang',
            parentId: 691,
          },
          {
            title: 'Xã Phú Nghĩa',
            code: 25267,
            value: 'xa_phu_nghia',
            division_type: 'xã',
            short_codename: 'phu_nghia',
            parentId: 691,
          },
        ],
        parentId: 70,
      },
      {
        title: 'Huyện Lộc Ninh',
        code: 692,
        value: 'huyen_loc_ninh',
        division_type: 'huyện',
        short_codename: 'loc_ninh',
        children: [
          {
            title: 'Thị trấn Lộc Ninh',
            code: 25270,
            value: 'thi_tran_loc_ninh',
            division_type: 'thị trấn',
            short_codename: 'loc_ninh',
            parentId: 692,
          },
          {
            title: 'Xã Lộc Hòa',
            code: 25273,
            value: 'xa_loc_hoa',
            division_type: 'xã',
            short_codename: 'loc_hoa',
            parentId: 692,
          },
          {
            title: 'Xã Lộc An',
            code: 25276,
            value: 'xa_loc_an',
            division_type: 'xã',
            short_codename: 'loc_an',
            parentId: 692,
          },
          {
            title: 'Xã Lộc Tấn',
            code: 25279,
            value: 'xa_loc_tan',
            division_type: 'xã',
            short_codename: 'loc_tan',
            parentId: 692,
          },
          {
            title: 'Xã Lộc Thạnh',
            code: 25280,
            value: 'xa_loc_thanh',
            division_type: 'xã',
            short_codename: 'xa_loc_thanh',
            parentId: 692,
          },
          {
            title: 'Xã Lộc Hiệp',
            code: 25282,
            value: 'xa_loc_hiep',
            division_type: 'xã',
            short_codename: 'loc_hiep',
            parentId: 692,
          },
          {
            title: 'Xã Lộc Thiện',
            code: 25285,
            value: 'xa_loc_thien',
            division_type: 'xã',
            short_codename: 'loc_thien',
            parentId: 692,
          },
          {
            title: 'Xã Lộc Thuận',
            code: 25288,
            value: 'xa_loc_thuan',
            division_type: 'xã',
            short_codename: 'loc_thuan',
            parentId: 692,
          },
          {
            title: 'Xã Lộc Quang',
            code: 25291,
            value: 'xa_loc_quang',
            division_type: 'xã',
            short_codename: 'loc_quang',
            parentId: 692,
          },
          {
            title: 'Xã Lộc Phú',
            code: 25292,
            value: 'xa_loc_phu',
            division_type: 'xã',
            short_codename: 'loc_phu',
            parentId: 692,
          },
          {
            title: 'Xã Lộc Thành',
            code: 25294,
            value: 'xa_loc_thanh',
            division_type: 'xã',
            short_codename: 'xa_loc_thanh',
            parentId: 692,
          },
          {
            title: 'Xã Lộc Thái',
            code: 25297,
            value: 'xa_loc_thai',
            division_type: 'xã',
            short_codename: 'loc_thai',
            parentId: 692,
          },
          {
            title: 'Xã Lộc Điền',
            code: 25300,
            value: 'xa_loc_dien',
            division_type: 'xã',
            short_codename: 'loc_dien',
            parentId: 692,
          },
          {
            title: 'Xã Lộc Hưng',
            code: 25303,
            value: 'xa_loc_hung',
            division_type: 'xã',
            short_codename: 'loc_hung',
            parentId: 692,
          },
          {
            title: 'Xã Lộc Thịnh',
            code: 25305,
            value: 'xa_loc_thinh',
            division_type: 'xã',
            short_codename: 'loc_thinh',
            parentId: 692,
          },
          {
            title: 'Xã Lộc Khánh',
            code: 25306,
            value: 'xa_loc_khanh',
            division_type: 'xã',
            short_codename: 'loc_khanh',
            parentId: 692,
          },
        ],
        parentId: 70,
      },
      {
        title: 'Huyện Bù Đốp',
        code: 693,
        value: 'huyen_bu_dop',
        division_type: 'huyện',
        short_codename: 'bu_dop',
        children: [
          {
            title: 'Thị trấn Thanh Bình',
            code: 25308,
            value: 'thi_tran_thanh_binh',
            division_type: 'thị trấn',
            short_codename: 'thanh_binh',
            parentId: 693,
          },
          {
            title: 'Xã Hưng Phước',
            code: 25309,
            value: 'xa_hung_phuoc',
            division_type: 'xã',
            short_codename: 'hung_phuoc',
            parentId: 693,
          },
          {
            title: 'Xã Phước Thiện',
            code: 25310,
            value: 'xa_phuoc_thien',
            division_type: 'xã',
            short_codename: 'phuoc_thien',
            parentId: 693,
          },
          {
            title: 'Xã Thiện Hưng',
            code: 25312,
            value: 'xa_thien_hung',
            division_type: 'xã',
            short_codename: 'thien_hung',
            parentId: 693,
          },
          {
            title: 'Xã Thanh Hòa',
            code: 25315,
            value: 'xa_thanh_hoa',
            division_type: 'xã',
            short_codename: 'thanh_hoa',
            parentId: 693,
          },
          {
            title: 'Xã Tân Thành',
            code: 25318,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 693,
          },
          {
            title: 'Xã Tân Tiến',
            code: 25321,
            value: 'xa_tan_tien',
            division_type: 'xã',
            short_codename: 'tan_tien',
            parentId: 693,
          },
        ],
        parentId: 70,
      },
      {
        title: 'Huyện Hớn Quản',
        code: 694,
        value: 'huyen_hon_quan',
        division_type: 'huyện',
        short_codename: 'hon_quan',
        children: [
          {
            title: 'Xã Thanh An',
            code: 25327,
            value: 'xa_thanh_an',
            division_type: 'xã',
            short_codename: 'thanh_an',
            parentId: 694,
          },
          {
            title: 'Xã An Khương',
            code: 25330,
            value: 'xa_an_khuong',
            division_type: 'xã',
            short_codename: 'an_khuong',
            parentId: 694,
          },
          {
            title: 'Xã An Phú',
            code: 25339,
            value: 'xa_an_phu',
            division_type: 'xã',
            short_codename: 'an_phu',
            parentId: 694,
          },
          {
            title: 'Xã Tân Lợi',
            code: 25342,
            value: 'xa_tan_loi',
            division_type: 'xã',
            short_codename: 'tan_loi',
            parentId: 694,
          },
          {
            title: 'Xã Tân Hưng',
            code: 25345,
            value: 'xa_tan_hung',
            division_type: 'xã',
            short_codename: 'tan_hung',
            parentId: 694,
          },
          {
            title: 'Xã Minh Đức',
            code: 25348,
            value: 'xa_minh_duc',
            division_type: 'xã',
            short_codename: 'minh_duc',
            parentId: 694,
          },
          {
            title: 'Xã Minh Tâm',
            code: 25349,
            value: 'xa_minh_tam',
            division_type: 'xã',
            short_codename: 'minh_tam',
            parentId: 694,
          },
          {
            title: 'Xã Phước An',
            code: 25351,
            value: 'xa_phuoc_an',
            division_type: 'xã',
            short_codename: 'phuoc_an',
            parentId: 694,
          },
          {
            title: 'Xã Thanh Bình',
            code: 25354,
            value: 'xa_thanh_binh',
            division_type: 'xã',
            short_codename: 'thanh_binh',
            parentId: 694,
          },
          {
            title: 'Thị trấn Tân Khai',
            code: 25357,
            value: 'thi_tran_tan_khai',
            division_type: 'thị trấn',
            short_codename: 'tan_khai',
            parentId: 694,
          },
          {
            title: 'Xã Đồng Nơ',
            code: 25360,
            value: 'xa_dong_no',
            division_type: 'xã',
            short_codename: 'dong_no',
            parentId: 694,
          },
          {
            title: 'Xã Tân Hiệp',
            code: 25361,
            value: 'xa_tan_hiep',
            division_type: 'xã',
            short_codename: 'tan_hiep',
            parentId: 694,
          },
          {
            title: 'Xã Tân Quan',
            code: 25438,
            value: 'xa_tan_quan',
            division_type: 'xã',
            short_codename: 'tan_quan',
            parentId: 694,
          },
        ],
        parentId: 70,
      },
      {
        title: 'Huyện Đồng Phú',
        code: 695,
        value: 'huyen_dong_phu',
        division_type: 'huyện',
        short_codename: 'dong_phu',
        children: [
          {
            title: 'Thị trấn Tân Phú',
            code: 25363,
            value: 'thi_tran_tan_phu',
            division_type: 'thị trấn',
            short_codename: 'tan_phu',
            parentId: 695,
          },
          {
            title: 'Xã Thuận Lợi',
            code: 25366,
            value: 'xa_thuan_loi',
            division_type: 'xã',
            short_codename: 'thuan_loi',
            parentId: 695,
          },
          {
            title: 'Xã Đồng Tâm',
            code: 25369,
            value: 'xa_dong_tam',
            division_type: 'xã',
            short_codename: 'dong_tam',
            parentId: 695,
          },
          {
            title: 'Xã Tân Phước',
            code: 25372,
            value: 'xa_tan_phuoc',
            division_type: 'xã',
            short_codename: 'tan_phuoc',
            parentId: 695,
          },
          {
            title: 'Xã Tân Hưng',
            code: 25375,
            value: 'xa_tan_hung',
            division_type: 'xã',
            short_codename: 'tan_hung',
            parentId: 695,
          },
          {
            title: 'Xã Tân Lợi',
            code: 25378,
            value: 'xa_tan_loi',
            division_type: 'xã',
            short_codename: 'tan_loi',
            parentId: 695,
          },
          {
            title: 'Xã Tân Lập',
            code: 25381,
            value: 'xa_tan_lap',
            division_type: 'xã',
            short_codename: 'tan_lap',
            parentId: 695,
          },
          {
            title: 'Xã Tân Hòa',
            code: 25384,
            value: 'xa_tan_hoa',
            division_type: 'xã',
            short_codename: 'tan_hoa',
            parentId: 695,
          },
          {
            title: 'Xã Thuận Phú',
            code: 25387,
            value: 'xa_thuan_phu',
            division_type: 'xã',
            short_codename: 'thuan_phu',
            parentId: 695,
          },
          {
            title: 'Xã Đồng Tiến',
            code: 25390,
            value: 'xa_dong_tien',
            division_type: 'xã',
            short_codename: 'dong_tien',
            parentId: 695,
          },
          {
            title: 'Xã Tân Tiến',
            code: 25393,
            value: 'xa_tan_tien',
            division_type: 'xã',
            short_codename: 'tan_tien',
            parentId: 695,
          },
        ],
        parentId: 70,
      },
      {
        title: 'Huyện Bù Đăng',
        code: 696,
        value: 'huyen_bu_dang',
        division_type: 'huyện',
        short_codename: 'bu_dang',
        children: [
          {
            title: 'Thị trấn Đức Phong',
            code: 25396,
            value: 'thi_tran_duc_phong',
            division_type: 'thị trấn',
            short_codename: 'duc_phong',
            parentId: 696,
          },
          {
            title: 'Xã Đường 10',
            code: 25398,
            value: 'xa_duong_10',
            division_type: 'xã',
            short_codename: 'duong_10',
            parentId: 696,
          },
          {
            title: 'Xã Đak Nhau',
            code: 25399,
            value: 'xa_dak_nhau',
            division_type: 'xã',
            short_codename: 'dak_nhau',
            parentId: 696,
          },
          {
            title: 'Xã Phú Sơn',
            code: 25400,
            value: 'xa_phu_son',
            division_type: 'xã',
            short_codename: 'phu_son',
            parentId: 696,
          },
          {
            title: 'Xã Thọ Sơn',
            code: 25402,
            value: 'xa_tho_son',
            division_type: 'xã',
            short_codename: 'tho_son',
            parentId: 696,
          },
          {
            title: 'Xã Bình Minh',
            code: 25404,
            value: 'xa_binh_minh',
            division_type: 'xã',
            short_codename: 'binh_minh',
            parentId: 696,
          },
          {
            title: 'Xã Bom Bo',
            code: 25405,
            value: 'xa_bom_bo',
            division_type: 'xã',
            short_codename: 'bom_bo',
            parentId: 696,
          },
          {
            title: 'Xã Minh Hưng',
            code: 25408,
            value: 'xa_minh_hung',
            division_type: 'xã',
            short_codename: 'minh_hung',
            parentId: 696,
          },
          {
            title: 'Xã Đoàn Kết',
            code: 25411,
            value: 'xa_doan_ket',
            division_type: 'xã',
            short_codename: 'doan_ket',
            parentId: 696,
          },
          {
            title: 'Xã Đồng Nai',
            code: 25414,
            value: 'xa_dong_nai',
            division_type: 'xã',
            short_codename: 'dong_nai',
            parentId: 696,
          },
          {
            title: 'Xã Đức Liễu',
            code: 25417,
            value: 'xa_duc_lieu',
            division_type: 'xã',
            short_codename: 'duc_lieu',
            parentId: 696,
          },
          {
            title: 'Xã Thống Nhất',
            code: 25420,
            value: 'xa_thong_nhat',
            division_type: 'xã',
            short_codename: 'thong_nhat',
            parentId: 696,
          },
          {
            title: 'Xã Nghĩa Trung',
            code: 25423,
            value: 'xa_nghia_trung',
            division_type: 'xã',
            short_codename: 'nghia_trung',
            parentId: 696,
          },
          {
            title: 'Xã Nghĩa Bình',
            code: 25424,
            value: 'xa_nghia_binh',
            division_type: 'xã',
            short_codename: 'nghia_binh',
            parentId: 696,
          },
          {
            title: 'Xã Đăng Hà',
            code: 25426,
            value: 'xa_dang_ha',
            division_type: 'xã',
            short_codename: 'dang_ha',
            parentId: 696,
          },
          {
            title: 'Xã Phước Sơn',
            code: 25429,
            value: 'xa_phuoc_son',
            division_type: 'xã',
            short_codename: 'phuoc_son',
            parentId: 696,
          },
        ],
        parentId: 70,
      },
      {
        title: 'Huyện Chơn Thành',
        code: 697,
        value: 'huyen_chon_thanh',
        division_type: 'huyện',
        short_codename: 'chon_thanh',
        children: [
          {
            title: 'Thị trấn Chơn Thành',
            code: 25432,
            value: 'thi_tran_chon_thanh',
            division_type: 'thị trấn',
            short_codename: 'chon_thanh',
            parentId: 697,
          },
          {
            title: 'Xã Thành Tâm',
            code: 25433,
            value: 'xa_thanh_tam',
            division_type: 'xã',
            short_codename: 'thanh_tam',
            parentId: 697,
          },
          {
            title: 'Xã Minh Lập',
            code: 25435,
            value: 'xa_minh_lap',
            division_type: 'xã',
            short_codename: 'minh_lap',
            parentId: 697,
          },
          {
            title: 'Xã Quang Minh',
            code: 25439,
            value: 'xa_quang_minh',
            division_type: 'xã',
            short_codename: 'quang_minh',
            parentId: 697,
          },
          {
            title: 'Xã Minh Hưng',
            code: 25441,
            value: 'xa_minh_hung',
            division_type: 'xã',
            short_codename: 'minh_hung',
            parentId: 697,
          },
          {
            title: 'Xã Minh Long',
            code: 25444,
            value: 'xa_minh_long',
            division_type: 'xã',
            short_codename: 'minh_long',
            parentId: 697,
          },
          {
            title: 'Xã Minh Thành',
            code: 25447,
            value: 'xa_minh_thanh',
            division_type: 'xã',
            short_codename: 'minh_thanh',
            parentId: 697,
          },
          {
            title: 'Xã Nha Bích',
            code: 25450,
            value: 'xa_nha_bich',
            division_type: 'xã',
            short_codename: 'nha_bich',
            parentId: 697,
          },
          {
            title: 'Xã Minh Thắng',
            code: 25453,
            value: 'xa_minh_thang',
            division_type: 'xã',
            short_codename: 'minh_thang',
            parentId: 697,
          },
        ],
        parentId: 70,
      },
      {
        title: 'Huyện Phú Riềng',
        code: 698,
        value: 'huyen_phu_rieng',
        division_type: 'huyện',
        short_codename: 'phu_rieng',
        children: [
          {
            title: 'Xã Long Bình',
            code: 25240,
            value: 'xa_long_binh',
            division_type: 'xã',
            short_codename: 'long_binh',
            parentId: 698,
          },
          {
            title: 'Xã Bình Tân',
            code: 25243,
            value: 'xa_binh_tan',
            division_type: 'xã',
            short_codename: 'binh_tan',
            parentId: 698,
          },
          {
            title: 'Xã Bình Sơn',
            code: 25244,
            value: 'xa_binh_son',
            division_type: 'xã',
            short_codename: 'binh_son',
            parentId: 698,
          },
          {
            title: 'Xã Long Hưng',
            code: 25246,
            value: 'xa_long_hung',
            division_type: 'xã',
            short_codename: 'long_hung',
            parentId: 698,
          },
          {
            title: 'Xã Phước Tân',
            code: 25250,
            value: 'xa_phuoc_tan',
            division_type: 'xã',
            short_codename: 'phuoc_tan',
            parentId: 698,
          },
          {
            title: 'Xã Bù Nho',
            code: 25252,
            value: 'xa_bu_nho',
            division_type: 'xã',
            short_codename: 'bu_nho',
            parentId: 698,
          },
          {
            title: 'Xã Long Hà',
            code: 25255,
            value: 'xa_long_ha',
            division_type: 'xã',
            short_codename: 'long_ha',
            parentId: 698,
          },
          {
            title: 'Xã Long Tân',
            code: 25258,
            value: 'xa_long_tan',
            division_type: 'xã',
            short_codename: 'long_tan',
            parentId: 698,
          },
          {
            title: 'Xã Phú Trung',
            code: 25261,
            value: 'xa_phu_trung',
            division_type: 'xã',
            short_codename: 'phu_trung',
            parentId: 698,
          },
          {
            title: 'Xã Phú Riềng',
            code: 25264,
            value: 'xa_phu_rieng',
            division_type: 'xã',
            short_codename: 'phu_rieng',
            parentId: 698,
          },
        ],
        parentId: 70,
      },
    ],
  },
  {
    title: 'Tỉnh Tây Ninh',
    code: 72,
    value: 'tinh_tay_ninh',
    division_type: 'tỉnh',
    phone_code: 276,
    children: [
      {
        title: 'Thành phố Tây Ninh',
        code: 703,
        value: 'thanh_pho_tay_ninh',
        division_type: 'thành phố',
        short_codename: 'tay_ninh',
        children: [
          {
            title: 'Phường 1',
            code: 25456,
            value: 'phuong_1',
            division_type: 'phường',
            short_codename: 'phuong_1',
            parentId: 703,
          },
          {
            title: 'Phường 3',
            code: 25459,
            value: 'phuong_3',
            division_type: 'phường',
            short_codename: 'phuong_3',
            parentId: 703,
          },
          {
            title: 'Phường 4',
            code: 25462,
            value: 'phuong_4',
            division_type: 'phường',
            short_codename: 'phuong_4',
            parentId: 703,
          },
          {
            title: 'Phường Hiệp Ninh',
            code: 25465,
            value: 'phuong_hiep_ninh',
            division_type: 'phường',
            short_codename: 'hiep_ninh',
            parentId: 703,
          },
          {
            title: 'Phường 2',
            code: 25468,
            value: 'phuong_2',
            division_type: 'phường',
            short_codename: 'phuong_2',
            parentId: 703,
          },
          {
            title: 'Xã Thạnh Tân',
            code: 25471,
            value: 'xa_thanh_tan',
            division_type: 'xã',
            short_codename: 'thanh_tan',
            parentId: 703,
          },
          {
            title: 'Xã Tân Bình',
            code: 25474,
            value: 'xa_tan_binh',
            division_type: 'xã',
            short_codename: 'tan_binh',
            parentId: 703,
          },
          {
            title: 'Xã Bình Minh',
            code: 25477,
            value: 'xa_binh_minh',
            division_type: 'xã',
            short_codename: 'binh_minh',
            parentId: 703,
          },
          {
            title: 'Phường Ninh Sơn',
            code: 25480,
            value: 'phuong_ninh_son',
            division_type: 'phường',
            short_codename: 'ninh_son',
            parentId: 703,
          },
          {
            title: 'Phường Ninh Thạnh',
            code: 25483,
            value: 'phuong_ninh_thanh',
            division_type: 'phường',
            short_codename: 'ninh_thanh',
            parentId: 703,
          },
        ],
        parentId: 72,
      },
      {
        title: 'Huyện Tân Biên',
        code: 705,
        value: 'huyen_tan_bien',
        division_type: 'huyện',
        short_codename: 'tan_bien',
        children: [
          {
            title: 'Thị trấn Tân Biên',
            code: 25486,
            value: 'thi_tran_tan_bien',
            division_type: 'thị trấn',
            short_codename: 'tan_bien',
            parentId: 705,
          },
          {
            title: 'Xã Tân Lập',
            code: 25489,
            value: 'xa_tan_lap',
            division_type: 'xã',
            short_codename: 'tan_lap',
            parentId: 705,
          },
          {
            title: 'Xã Thạnh Bắc',
            code: 25492,
            value: 'xa_thanh_bac',
            division_type: 'xã',
            short_codename: 'thanh_bac',
            parentId: 705,
          },
          {
            title: 'Xã Tân Bình',
            code: 25495,
            value: 'xa_tan_binh',
            division_type: 'xã',
            short_codename: 'tan_binh',
            parentId: 705,
          },
          {
            title: 'Xã Thạnh Bình',
            code: 25498,
            value: 'xa_thanh_binh',
            division_type: 'xã',
            short_codename: 'thanh_binh',
            parentId: 705,
          },
          {
            title: 'Xã Thạnh Tây',
            code: 25501,
            value: 'xa_thanh_tay',
            division_type: 'xã',
            short_codename: 'thanh_tay',
            parentId: 705,
          },
          {
            title: 'Xã Hòa Hiệp',
            code: 25504,
            value: 'xa_hoa_hiep',
            division_type: 'xã',
            short_codename: 'hoa_hiep',
            parentId: 705,
          },
          {
            title: 'Xã Tân Phong',
            code: 25507,
            value: 'xa_tan_phong',
            division_type: 'xã',
            short_codename: 'tan_phong',
            parentId: 705,
          },
          {
            title: 'Xã Mỏ Công',
            code: 25510,
            value: 'xa_mo_cong',
            division_type: 'xã',
            short_codename: 'mo_cong',
            parentId: 705,
          },
          {
            title: 'Xã Trà Vong',
            code: 25513,
            value: 'xa_tra_vong',
            division_type: 'xã',
            short_codename: 'tra_vong',
            parentId: 705,
          },
        ],
        parentId: 72,
      },
      {
        title: 'Huyện Tân Châu',
        code: 706,
        value: 'huyen_tan_chau',
        division_type: 'huyện',
        short_codename: 'tan_chau',
        children: [
          {
            title: 'Thị trấn Tân Châu',
            code: 25516,
            value: 'thi_tran_tan_chau',
            division_type: 'thị trấn',
            short_codename: 'tan_chau',
            parentId: 706,
          },
          {
            title: 'Xã Tân Hà',
            code: 25519,
            value: 'xa_tan_ha',
            division_type: 'xã',
            short_codename: 'tan_ha',
            parentId: 706,
          },
          {
            title: 'Xã Tân Đông',
            code: 25522,
            value: 'xa_tan_dong',
            division_type: 'xã',
            short_codename: 'tan_dong',
            parentId: 706,
          },
          {
            title: 'Xã Tân Hội',
            code: 25525,
            value: 'xa_tan_hoi',
            division_type: 'xã',
            short_codename: 'tan_hoi',
            parentId: 706,
          },
          {
            title: 'Xã Tân Hòa',
            code: 25528,
            value: 'xa_tan_hoa',
            division_type: 'xã',
            short_codename: 'tan_hoa',
            parentId: 706,
          },
          {
            title: 'Xã Suối Ngô',
            code: 25531,
            value: 'xa_suoi_ngo',
            division_type: 'xã',
            short_codename: 'suoi_ngo',
            parentId: 706,
          },
          {
            title: 'Xã Suối Dây',
            code: 25534,
            value: 'xa_suoi_day',
            division_type: 'xã',
            short_codename: 'suoi_day',
            parentId: 706,
          },
          {
            title: 'Xã Tân Hiệp',
            code: 25537,
            value: 'xa_tan_hiep',
            division_type: 'xã',
            short_codename: 'tan_hiep',
            parentId: 706,
          },
          {
            title: 'Xã Thạnh Đông',
            code: 25540,
            value: 'xa_thanh_dong',
            division_type: 'xã',
            short_codename: 'thanh_dong',
            parentId: 706,
          },
          {
            title: 'Xã Tân Thành',
            code: 25543,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 706,
          },
          {
            title: 'Xã Tân Phú',
            code: 25546,
            value: 'xa_tan_phu',
            division_type: 'xã',
            short_codename: 'tan_phu',
            parentId: 706,
          },
          {
            title: 'Xã Tân Hưng',
            code: 25549,
            value: 'xa_tan_hung',
            division_type: 'xã',
            short_codename: 'tan_hung',
            parentId: 706,
          },
        ],
        parentId: 72,
      },
      {
        title: 'Huyện Dương Minh Châu',
        code: 707,
        value: 'huyen_duong_minh_chau',
        division_type: 'huyện',
        short_codename: 'duong_minh_chau',
        children: [
          {
            title: 'Thị trấn Dương Minh Châu',
            code: 25552,
            value: 'thi_tran_duong_minh_chau',
            division_type: 'thị trấn',
            short_codename: 'duong_minh_chau',
            parentId: 707,
          },
          {
            title: 'Xã Suối Đá',
            code: 25555,
            value: 'xa_suoi_da',
            division_type: 'xã',
            short_codename: 'suoi_da',
            parentId: 707,
          },
          {
            title: 'Xã Phan',
            code: 25558,
            value: 'xa_phan',
            division_type: 'xã',
            short_codename: 'phan',
            parentId: 707,
          },
          {
            title: 'Xã Phước Ninh',
            code: 25561,
            value: 'xa_phuoc_ninh',
            division_type: 'xã',
            short_codename: 'phuoc_ninh',
            parentId: 707,
          },
          {
            title: 'Xã Phước Minh',
            code: 25564,
            value: 'xa_phuoc_minh',
            division_type: 'xã',
            short_codename: 'phuoc_minh',
            parentId: 707,
          },
          {
            title: 'Xã Bàu Năng',
            code: 25567,
            value: 'xa_bau_nang',
            division_type: 'xã',
            short_codename: 'bau_nang',
            parentId: 707,
          },
          {
            title: 'Xã Chà Là',
            code: 25570,
            value: 'xa_cha_la',
            division_type: 'xã',
            short_codename: 'cha_la',
            parentId: 707,
          },
          {
            title: 'Xã Cầu Khởi',
            code: 25573,
            value: 'xa_cau_khoi',
            division_type: 'xã',
            short_codename: 'cau_khoi',
            parentId: 707,
          },
          {
            title: 'Xã Bến Củi',
            code: 25576,
            value: 'xa_ben_cui',
            division_type: 'xã',
            short_codename: 'ben_cui',
            parentId: 707,
          },
          {
            title: 'Xã Lộc Ninh',
            code: 25579,
            value: 'xa_loc_ninh',
            division_type: 'xã',
            short_codename: 'loc_ninh',
            parentId: 707,
          },
          {
            title: 'Xã Truông Mít',
            code: 25582,
            value: 'xa_truong_mit',
            division_type: 'xã',
            short_codename: 'truong_mit',
            parentId: 707,
          },
        ],
        parentId: 72,
      },
      {
        title: 'Huyện Châu Thành',
        code: 708,
        value: 'huyen_chau_thanh',
        division_type: 'huyện',
        short_codename: 'chau_thanh',
        children: [
          {
            title: 'Thị trấn Châu Thành',
            code: 25585,
            value: 'thi_tran_chau_thanh',
            division_type: 'thị trấn',
            short_codename: 'chau_thanh',
            parentId: 708,
          },
          {
            title: 'Xã Hảo Đước',
            code: 25588,
            value: 'xa_hao_duoc',
            division_type: 'xã',
            short_codename: 'hao_duoc',
            parentId: 708,
          },
          {
            title: 'Xã Phước Vinh',
            code: 25591,
            value: 'xa_phuoc_vinh',
            division_type: 'xã',
            short_codename: 'phuoc_vinh',
            parentId: 708,
          },
          {
            title: 'Xã Đồng Khởi',
            code: 25594,
            value: 'xa_dong_khoi',
            division_type: 'xã',
            short_codename: 'dong_khoi',
            parentId: 708,
          },
          {
            title: 'Xã Thái Bình',
            code: 25597,
            value: 'xa_thai_binh',
            division_type: 'xã',
            short_codename: 'thai_binh',
            parentId: 708,
          },
          {
            title: 'Xã An Cơ',
            code: 25600,
            value: 'xa_an_co',
            division_type: 'xã',
            short_codename: 'an_co',
            parentId: 708,
          },
          {
            title: 'Xã Biên Giới',
            code: 25603,
            value: 'xa_bien_gioi',
            division_type: 'xã',
            short_codename: 'bien_gioi',
            parentId: 708,
          },
          {
            title: 'Xã Hòa Thạnh',
            code: 25606,
            value: 'xa_hoa_thanh',
            division_type: 'xã',
            short_codename: 'hoa_thanh',
            parentId: 708,
          },
          {
            title: 'Xã Trí Bình',
            code: 25609,
            value: 'xa_tri_binh',
            division_type: 'xã',
            short_codename: 'tri_binh',
            parentId: 708,
          },
          {
            title: 'Xã Hòa Hội',
            code: 25612,
            value: 'xa_hoa_hoi',
            division_type: 'xã',
            short_codename: 'hoa_hoi',
            parentId: 708,
          },
          {
            title: 'Xã An Bình',
            code: 25615,
            value: 'xa_an_binh',
            division_type: 'xã',
            short_codename: 'an_binh',
            parentId: 708,
          },
          {
            title: 'Xã Thanh Điền',
            code: 25618,
            value: 'xa_thanh_dien',
            division_type: 'xã',
            short_codename: 'thanh_dien',
            parentId: 708,
          },
          {
            title: 'Xã Thành Long',
            code: 25621,
            value: 'xa_thanh_long',
            division_type: 'xã',
            short_codename: 'thanh_long',
            parentId: 708,
          },
          {
            title: 'Xã Ninh Điền',
            code: 25624,
            value: 'xa_ninh_dien',
            division_type: 'xã',
            short_codename: 'ninh_dien',
            parentId: 708,
          },
          {
            title: 'Xã Long Vĩnh',
            code: 25627,
            value: 'xa_long_vinh',
            division_type: 'xã',
            short_codename: 'long_vinh',
            parentId: 708,
          },
        ],
        parentId: 72,
      },
      {
        title: 'Thị xã Hòa Thành',
        code: 709,
        value: 'thi_xa_hoa_thanh',
        division_type: 'thị xã',
        short_codename: 'hoa_thanh',
        children: [
          {
            title: 'Phường Long Hoa',
            code: 25630,
            value: 'phuong_long_hoa',
            division_type: 'phường',
            short_codename: 'long_hoa',
            parentId: 709,
          },
          {
            title: 'Phường Hiệp Tân',
            code: 25633,
            value: 'phuong_hiep_tan',
            division_type: 'phường',
            short_codename: 'hiep_tan',
            parentId: 709,
          },
          {
            title: 'Phường Long Thành Bắc',
            code: 25636,
            value: 'phuong_long_thanh_bac',
            division_type: 'phường',
            short_codename: 'long_thanh_bac',
            parentId: 709,
          },
          {
            title: 'Xã Trường Hòa',
            code: 25639,
            value: 'xa_truong_hoa',
            division_type: 'xã',
            short_codename: 'truong_hoa',
            parentId: 709,
          },
          {
            title: 'Xã Trường Đông',
            code: 25642,
            value: 'xa_truong_dong',
            division_type: 'xã',
            short_codename: 'truong_dong',
            parentId: 709,
          },
          {
            title: 'Phường Long Thành Trung',
            code: 25645,
            value: 'phuong_long_thanh_trung',
            division_type: 'phường',
            short_codename: 'long_thanh_trung',
            parentId: 709,
          },
          {
            title: 'Xã Trường Tây',
            code: 25648,
            value: 'xa_truong_tay',
            division_type: 'xã',
            short_codename: 'truong_tay',
            parentId: 709,
          },
          {
            title: 'Xã Long Thành Nam',
            code: 25651,
            value: 'xa_long_thanh_nam',
            division_type: 'xã',
            short_codename: 'long_thanh_nam',
            parentId: 709,
          },
        ],
        parentId: 72,
      },
      {
        title: 'Huyện Gò Dầu',
        code: 710,
        value: 'huyen_go_dau',
        division_type: 'huyện',
        short_codename: 'go_dau',
        children: [
          {
            title: 'Thị trấn Gò Dầu',
            code: 25654,
            value: 'thi_tran_go_dau',
            division_type: 'thị trấn',
            short_codename: 'go_dau',
            parentId: 710,
          },
          {
            title: 'Xã Thạnh Đức',
            code: 25657,
            value: 'xa_thanh_duc',
            division_type: 'xã',
            short_codename: 'thanh_duc',
            parentId: 710,
          },
          {
            title: 'Xã Cẩm Giang',
            code: 25660,
            value: 'xa_cam_giang',
            division_type: 'xã',
            short_codename: 'cam_giang',
            parentId: 710,
          },
          {
            title: 'Xã Hiệp Thạnh',
            code: 25663,
            value: 'xa_hiep_thanh',
            division_type: 'xã',
            short_codename: 'hiep_thanh',
            parentId: 710,
          },
          {
            title: 'Xã Bàu Đồn',
            code: 25666,
            value: 'xa_bau_don',
            division_type: 'xã',
            short_codename: 'bau_don',
            parentId: 710,
          },
          {
            title: 'Xã Phước Thạnh',
            code: 25669,
            value: 'xa_phuoc_thanh',
            division_type: 'xã',
            short_codename: 'phuoc_thanh',
            parentId: 710,
          },
          {
            title: 'Xã Phước Đông',
            code: 25672,
            value: 'xa_phuoc_dong',
            division_type: 'xã',
            short_codename: 'phuoc_dong',
            parentId: 710,
          },
          {
            title: 'Xã Phước Trạch',
            code: 25675,
            value: 'xa_phuoc_trach',
            division_type: 'xã',
            short_codename: 'phuoc_trach',
            parentId: 710,
          },
          {
            title: 'Xã Thanh Phước',
            code: 25678,
            value: 'xa_thanh_phuoc',
            division_type: 'xã',
            short_codename: 'thanh_phuoc',
            parentId: 710,
          },
        ],
        parentId: 72,
      },
      {
        title: 'Huyện Bến Cầu',
        code: 711,
        value: 'huyen_ben_cau',
        division_type: 'huyện',
        short_codename: 'ben_cau',
        children: [
          {
            title: 'Thị trấn Bến Cầu',
            code: 25681,
            value: 'thi_tran_ben_cau',
            division_type: 'thị trấn',
            short_codename: 'ben_cau',
            parentId: 711,
          },
          {
            title: 'Xã Long Chữ',
            code: 25684,
            value: 'xa_long_chu',
            division_type: 'xã',
            short_codename: 'long_chu',
            parentId: 711,
          },
          {
            title: 'Xã Long Phước',
            code: 25687,
            value: 'xa_long_phuoc',
            division_type: 'xã',
            short_codename: 'long_phuoc',
            parentId: 711,
          },
          {
            title: 'Xã Long Giang',
            code: 25690,
            value: 'xa_long_giang',
            division_type: 'xã',
            short_codename: 'long_giang',
            parentId: 711,
          },
          {
            title: 'Xã Tiên Thuận',
            code: 25693,
            value: 'xa_tien_thuan',
            division_type: 'xã',
            short_codename: 'tien_thuan',
            parentId: 711,
          },
          {
            title: 'Xã Long Khánh',
            code: 25696,
            value: 'xa_long_khanh',
            division_type: 'xã',
            short_codename: 'long_khanh',
            parentId: 711,
          },
          {
            title: 'Xã Lợi Thuận',
            code: 25699,
            value: 'xa_loi_thuan',
            division_type: 'xã',
            short_codename: 'loi_thuan',
            parentId: 711,
          },
          {
            title: 'Xã Long Thuận',
            code: 25702,
            value: 'xa_long_thuan',
            division_type: 'xã',
            short_codename: 'long_thuan',
            parentId: 711,
          },
          {
            title: 'Xã An Thạnh',
            code: 25705,
            value: 'xa_an_thanh',
            division_type: 'xã',
            short_codename: 'an_thanh',
            parentId: 711,
          },
        ],
        parentId: 72,
      },
      {
        title: 'Thị xã Trảng Bàng',
        code: 712,
        value: 'thi_xa_trang_bang',
        division_type: 'thị xã',
        short_codename: 'trang_bang',
        children: [
          {
            title: 'Phường Trảng Bàng',
            code: 25708,
            value: 'phuong_trang_bang',
            division_type: 'phường',
            short_codename: 'trang_bang',
            parentId: 712,
          },
          {
            title: 'Xã Đôn Thuận',
            code: 25711,
            value: 'xa_don_thuan',
            division_type: 'xã',
            short_codename: 'don_thuan',
            parentId: 712,
          },
          {
            title: 'Xã Hưng Thuận',
            code: 25714,
            value: 'xa_hung_thuan',
            division_type: 'xã',
            short_codename: 'hung_thuan',
            parentId: 712,
          },
          {
            title: 'Phường Lộc Hưng',
            code: 25717,
            value: 'phuong_loc_hung',
            division_type: 'phường',
            short_codename: 'loc_hung',
            parentId: 712,
          },
          {
            title: 'Phường Gia Lộc',
            code: 25720,
            value: 'phuong_gia_loc',
            division_type: 'phường',
            short_codename: 'gia_loc',
            parentId: 712,
          },
          {
            title: 'Phường Gia Bình',
            code: 25723,
            value: 'phuong_gia_binh',
            division_type: 'phường',
            short_codename: 'gia_binh',
            parentId: 712,
          },
          {
            title: 'Xã Phước Bình',
            code: 25729,
            value: 'xa_phuoc_binh',
            division_type: 'xã',
            short_codename: 'phuoc_binh',
            parentId: 712,
          },
          {
            title: 'Phường An Tịnh',
            code: 25732,
            value: 'phuong_an_tinh',
            division_type: 'phường',
            short_codename: 'an_tinh',
            parentId: 712,
          },
          {
            title: 'Phường An Hòa',
            code: 25735,
            value: 'phuong_an_hoa',
            division_type: 'phường',
            short_codename: 'an_hoa',
            parentId: 712,
          },
          {
            title: 'Xã Phước Chỉ',
            code: 25738,
            value: 'xa_phuoc_chi',
            division_type: 'xã',
            short_codename: 'phuoc_chi',
            parentId: 712,
          },
        ],
        parentId: 72,
      },
    ],
  },
  {
    title: 'Tỉnh Bình Dương',
    code: 74,
    value: 'tinh_binh_duong',
    division_type: 'tỉnh',
    phone_code: 274,
    children: [
      {
        title: 'Thành phố Thủ Dầu Một',
        code: 718,
        value: 'thanh_pho_thu_dau_mot',
        division_type: 'thành phố',
        short_codename: 'thu_dau_mot',
        children: [
          {
            title: 'Phường Hiệp Thành',
            code: 25741,
            value: 'phuong_hiep_thanh',
            division_type: 'phường',
            short_codename: 'hiep_thanh',
            parentId: 718,
          },
          {
            title: 'Phường Phú Lợi',
            code: 25744,
            value: 'phuong_phu_loi',
            division_type: 'phường',
            short_codename: 'phu_loi',
            parentId: 718,
          },
          {
            title: 'Phường Phú Cường',
            code: 25747,
            value: 'phuong_phu_cuong',
            division_type: 'phường',
            short_codename: 'phu_cuong',
            parentId: 718,
          },
          {
            title: 'Phường Phú Hòa',
            code: 25750,
            value: 'phuong_phu_hoa',
            division_type: 'phường',
            short_codename: 'phu_hoa',
            parentId: 718,
          },
          {
            title: 'Phường Phú Thọ',
            code: 25753,
            value: 'phuong_phu_tho',
            division_type: 'phường',
            short_codename: 'phu_tho',
            parentId: 718,
          },
          {
            title: 'Phường Chánh Nghĩa',
            code: 25756,
            value: 'phuong_chanh_nghia',
            division_type: 'phường',
            short_codename: 'chanh_nghia',
            parentId: 718,
          },
          {
            title: 'Phường Định Hoà',
            code: 25759,
            value: 'phuong_dinh_hoa',
            division_type: 'phường',
            short_codename: 'dinh_hoa',
            parentId: 718,
          },
          {
            title: 'Phường Hoà Phú',
            code: 25760,
            value: 'phuong_hoa_phu',
            division_type: 'phường',
            short_codename: 'hoa_phu',
            parentId: 718,
          },
          {
            title: 'Phường Phú Mỹ',
            code: 25762,
            value: 'phuong_phu_my',
            division_type: 'phường',
            short_codename: 'phu_my',
            parentId: 718,
          },
          {
            title: 'Phường Phú Tân',
            code: 25763,
            value: 'phuong_phu_tan',
            division_type: 'phường',
            short_codename: 'phu_tan',
            parentId: 718,
          },
          {
            title: 'Phường Tân An',
            code: 25765,
            value: 'phuong_tan_an',
            division_type: 'phường',
            short_codename: 'tan_an',
            parentId: 718,
          },
          {
            title: 'Phường Hiệp An',
            code: 25768,
            value: 'phuong_hiep_an',
            division_type: 'phường',
            short_codename: 'hiep_an',
            parentId: 718,
          },
          {
            title: 'Phường Tương Bình Hiệp',
            code: 25771,
            value: 'phuong_tuong_binh_hiep',
            division_type: 'phường',
            short_codename: 'tuong_binh_hiep',
            parentId: 718,
          },
          {
            title: 'Phường Chánh Mỹ',
            code: 25774,
            value: 'phuong_chanh_my',
            division_type: 'phường',
            short_codename: 'chanh_my',
            parentId: 718,
          },
        ],
        parentId: 74,
      },
      {
        title: 'Huyện Bàu Bàng',
        code: 719,
        value: 'huyen_bau_bang',
        division_type: 'huyện',
        short_codename: 'bau_bang',
        children: [
          {
            title: 'Xã Trừ Văn Thố',
            code: 25816,
            value: 'xa_tru_van_tho',
            division_type: 'xã',
            short_codename: 'tru_van_tho',
            parentId: 719,
          },
          {
            title: 'Xã Cây Trường II',
            code: 25819,
            value: 'xa_cay_truong_ii',
            division_type: 'xã',
            short_codename: 'cay_truong_ii',
            parentId: 719,
          },
          {
            title: 'Thị trấn Lai Uyên',
            code: 25822,
            value: 'thi_tran_lai_uyen',
            division_type: 'thị trấn',
            short_codename: 'lai_uyen',
            parentId: 719,
          },
          {
            title: 'Xã Tân Hưng',
            code: 25825,
            value: 'xa_tan_hung',
            division_type: 'xã',
            short_codename: 'tan_hung',
            parentId: 719,
          },
          {
            title: 'Xã Long Nguyên',
            code: 25828,
            value: 'xa_long_nguyen',
            division_type: 'xã',
            short_codename: 'long_nguyen',
            parentId: 719,
          },
          {
            title: 'Xã Hưng Hòa',
            code: 25831,
            value: 'xa_hung_hoa',
            division_type: 'xã',
            short_codename: 'hung_hoa',
            parentId: 719,
          },
          {
            title: 'Xã Lai Hưng',
            code: 25834,
            value: 'xa_lai_hung',
            division_type: 'xã',
            short_codename: 'lai_hung',
            parentId: 719,
          },
        ],
        parentId: 74,
      },
      {
        title: 'Huyện Dầu Tiếng',
        code: 720,
        value: 'huyen_dau_tieng',
        division_type: 'huyện',
        short_codename: 'dau_tieng',
        children: [
          {
            title: 'Thị trấn Dầu Tiếng',
            code: 25777,
            value: 'thi_tran_dau_tieng',
            division_type: 'thị trấn',
            short_codename: 'dau_tieng',
            parentId: 720,
          },
          {
            title: 'Xã Minh Hoà',
            code: 25780,
            value: 'xa_minh_hoa',
            division_type: 'xã',
            short_codename: 'minh_hoa',
            parentId: 720,
          },
          {
            title: 'Xã Minh Thạnh',
            code: 25783,
            value: 'xa_minh_thanh',
            division_type: 'xã',
            short_codename: 'minh_thanh',
            parentId: 720,
          },
          {
            title: 'Xã Minh Tân',
            code: 25786,
            value: 'xa_minh_tan',
            division_type: 'xã',
            short_codename: 'minh_tan',
            parentId: 720,
          },
          {
            title: 'Xã Định An',
            code: 25789,
            value: 'xa_dinh_an',
            division_type: 'xã',
            short_codename: 'dinh_an',
            parentId: 720,
          },
          {
            title: 'Xã Long Hoà',
            code: 25792,
            value: 'xa_long_hoa',
            division_type: 'xã',
            short_codename: 'long_hoa',
            parentId: 720,
          },
          {
            title: 'Xã Định Thành',
            code: 25795,
            value: 'xa_dinh_thanh',
            division_type: 'xã',
            short_codename: 'dinh_thanh',
            parentId: 720,
          },
          {
            title: 'Xã Định Hiệp',
            code: 25798,
            value: 'xa_dinh_hiep',
            division_type: 'xã',
            short_codename: 'dinh_hiep',
            parentId: 720,
          },
          {
            title: 'Xã An Lập',
            code: 25801,
            value: 'xa_an_lap',
            division_type: 'xã',
            short_codename: 'an_lap',
            parentId: 720,
          },
          {
            title: 'Xã Long Tân',
            code: 25804,
            value: 'xa_long_tan',
            division_type: 'xã',
            short_codename: 'long_tan',
            parentId: 720,
          },
          {
            title: 'Xã Thanh An',
            code: 25807,
            value: 'xa_thanh_an',
            division_type: 'xã',
            short_codename: 'thanh_an',
            parentId: 720,
          },
          {
            title: 'Xã Thanh Tuyền',
            code: 25810,
            value: 'xa_thanh_tuyen',
            division_type: 'xã',
            short_codename: 'thanh_tuyen',
            parentId: 720,
          },
        ],
        parentId: 74,
      },
      {
        title: 'Thị xã Bến Cát',
        code: 721,
        value: 'thi_xa_ben_cat',
        division_type: 'thị xã',
        short_codename: 'ben_cat',
        children: [
          {
            title: 'Phường Mỹ Phước',
            code: 25813,
            value: 'phuong_my_phuoc',
            division_type: 'phường',
            short_codename: 'my_phuoc',
            parentId: 721,
          },
          {
            title: 'Phường Chánh Phú Hòa',
            code: 25837,
            value: 'phuong_chanh_phu_hoa',
            division_type: 'phường',
            short_codename: 'chanh_phu_hoa',
            parentId: 721,
          },
          {
            title: 'Xã An Điền',
            code: 25840,
            value: 'xa_an_dien',
            division_type: 'xã',
            short_codename: 'an_dien',
            parentId: 721,
          },
          {
            title: 'Xã An Tây',
            code: 25843,
            value: 'xa_an_tay',
            division_type: 'xã',
            short_codename: 'an_tay',
            parentId: 721,
          },
          {
            title: 'Phường Thới Hòa',
            code: 25846,
            value: 'phuong_thoi_hoa',
            division_type: 'phường',
            short_codename: 'thoi_hoa',
            parentId: 721,
          },
          {
            title: 'Phường Hòa Lợi',
            code: 25849,
            value: 'phuong_hoa_loi',
            division_type: 'phường',
            short_codename: 'hoa_loi',
            parentId: 721,
          },
          {
            title: 'Phường Tân Định',
            code: 25852,
            value: 'phuong_tan_dinh',
            division_type: 'phường',
            short_codename: 'tan_dinh',
            parentId: 721,
          },
          {
            title: 'Xã Phú An',
            code: 25855,
            value: 'xa_phu_an',
            division_type: 'xã',
            short_codename: 'phu_an',
            parentId: 721,
          },
        ],
        parentId: 74,
      },
      {
        title: 'Huyện Phú Giáo',
        code: 722,
        value: 'huyen_phu_giao',
        division_type: 'huyện',
        short_codename: 'phu_giao',
        children: [
          {
            title: 'Thị trấn Phước Vĩnh',
            code: 25858,
            value: 'thi_tran_phuoc_vinh',
            division_type: 'thị trấn',
            short_codename: 'phuoc_vinh',
            parentId: 722,
          },
          {
            title: 'Xã An Linh',
            code: 25861,
            value: 'xa_an_linh',
            division_type: 'xã',
            short_codename: 'an_linh',
            parentId: 722,
          },
          {
            title: 'Xã Phước Sang',
            code: 25864,
            value: 'xa_phuoc_sang',
            division_type: 'xã',
            short_codename: 'phuoc_sang',
            parentId: 722,
          },
          {
            title: 'Xã An Thái',
            code: 25865,
            value: 'xa_an_thai',
            division_type: 'xã',
            short_codename: 'an_thai',
            parentId: 722,
          },
          {
            title: 'Xã An Long',
            code: 25867,
            value: 'xa_an_long',
            division_type: 'xã',
            short_codename: 'an_long',
            parentId: 722,
          },
          {
            title: 'Xã An Bình',
            code: 25870,
            value: 'xa_an_binh',
            division_type: 'xã',
            short_codename: 'an_binh',
            parentId: 722,
          },
          {
            title: 'Xã Tân Hiệp',
            code: 25873,
            value: 'xa_tan_hiep',
            division_type: 'xã',
            short_codename: 'tan_hiep',
            parentId: 722,
          },
          {
            title: 'Xã Tam Lập',
            code: 25876,
            value: 'xa_tam_lap',
            division_type: 'xã',
            short_codename: 'tam_lap',
            parentId: 722,
          },
          {
            title: 'Xã Tân Long',
            code: 25879,
            value: 'xa_tan_long',
            division_type: 'xã',
            short_codename: 'tan_long',
            parentId: 722,
          },
          {
            title: 'Xã Vĩnh Hoà',
            code: 25882,
            value: 'xa_vinh_hoa',
            division_type: 'xã',
            short_codename: 'vinh_hoa',
            parentId: 722,
          },
          {
            title: 'Xã Phước Hoà',
            code: 25885,
            value: 'xa_phuoc_hoa',
            division_type: 'xã',
            short_codename: 'phuoc_hoa',
            parentId: 722,
          },
        ],
        parentId: 74,
      },
      {
        title: 'Thị xã Tân Uyên',
        code: 723,
        value: 'thi_xa_tan_uyen',
        division_type: 'thị xã',
        short_codename: 'tan_uyen',
        children: [
          {
            title: 'Phường Uyên Hưng',
            code: 25888,
            value: 'phuong_uyen_hung',
            division_type: 'phường',
            short_codename: 'uyen_hung',
            parentId: 723,
          },
          {
            title: 'Phường Tân Phước Khánh',
            code: 25891,
            value: 'phuong_tan_phuoc_khanh',
            division_type: 'phường',
            short_codename: 'tan_phuoc_khanh',
            parentId: 723,
          },
          {
            title: 'Phường Vĩnh Tân',
            code: 25912,
            value: 'phuong_vinh_tan',
            division_type: 'phường',
            short_codename: 'vinh_tan',
            parentId: 723,
          },
          {
            title: 'Phường Hội Nghĩa',
            code: 25915,
            value: 'phuong_hoi_nghia',
            division_type: 'phường',
            short_codename: 'hoi_nghia',
            parentId: 723,
          },
          {
            title: 'Phường Tân Hiệp',
            code: 25920,
            value: 'phuong_tan_hiep',
            division_type: 'phường',
            short_codename: 'tan_hiep',
            parentId: 723,
          },
          {
            title: 'Phường Khánh Bình',
            code: 25921,
            value: 'phuong_khanh_binh',
            division_type: 'phường',
            short_codename: 'khanh_binh',
            parentId: 723,
          },
          {
            title: 'Phường Phú Chánh',
            code: 25924,
            value: 'phuong_phu_chanh',
            division_type: 'phường',
            short_codename: 'phu_chanh',
            parentId: 723,
          },
          {
            title: 'Xã Bạch Đằng',
            code: 25930,
            value: 'xa_bach_dang',
            division_type: 'xã',
            short_codename: 'bach_dang',
            parentId: 723,
          },
          {
            title: 'Phường Tân Vĩnh Hiệp',
            code: 25933,
            value: 'phuong_tan_vinh_hiep',
            division_type: 'phường',
            short_codename: 'tan_vinh_hiep',
            parentId: 723,
          },
          {
            title: 'Phường Thạnh Phước',
            code: 25936,
            value: 'phuong_thanh_phuoc',
            division_type: 'phường',
            short_codename: 'thanh_phuoc',
            parentId: 723,
          },
          {
            title: 'Xã Thạnh Hội',
            code: 25937,
            value: 'xa_thanh_hoi',
            division_type: 'xã',
            short_codename: 'thanh_hoi',
            parentId: 723,
          },
          {
            title: 'Phường Thái Hòa',
            code: 25939,
            value: 'phuong_thai_hoa',
            division_type: 'phường',
            short_codename: 'thai_hoa',
            parentId: 723,
          },
        ],
        parentId: 74,
      },
      {
        title: 'Thành phố Dĩ An',
        code: 724,
        value: 'thanh_pho_di_an',
        division_type: 'thành phố',
        short_codename: 'di_an',
        children: [
          {
            title: 'Phường Dĩ An',
            code: 25942,
            value: 'phuong_di_an',
            division_type: 'phường',
            short_codename: 'di_an',
            parentId: 724,
          },
          {
            title: 'Phường Tân Bình',
            code: 25945,
            value: 'phuong_tan_binh',
            division_type: 'phường',
            short_codename: 'tan_binh',
            parentId: 724,
          },
          {
            title: 'Phường Tân Đông Hiệp',
            code: 25948,
            value: 'phuong_tan_dong_hiep',
            division_type: 'phường',
            short_codename: 'tan_dong_hiep',
            parentId: 724,
          },
          {
            title: 'Phường Bình An',
            code: 25951,
            value: 'phuong_binh_an',
            division_type: 'phường',
            short_codename: 'binh_an',
            parentId: 724,
          },
          {
            title: 'Phường Bình Thắng',
            code: 25954,
            value: 'phuong_binh_thang',
            division_type: 'phường',
            short_codename: 'binh_thang',
            parentId: 724,
          },
          {
            title: 'Phường Đông Hòa',
            code: 25957,
            value: 'phuong_dong_hoa',
            division_type: 'phường',
            short_codename: 'dong_hoa',
            parentId: 724,
          },
          {
            title: 'Phường An Bình',
            code: 25960,
            value: 'phuong_an_binh',
            division_type: 'phường',
            short_codename: 'an_binh',
            parentId: 724,
          },
        ],
        parentId: 74,
      },
      {
        title: 'Thành phố Thuận An',
        code: 725,
        value: 'thanh_pho_thuan_an',
        division_type: 'thành phố',
        short_codename: 'thuan_an',
        children: [
          {
            title: 'Phường An Thạnh',
            code: 25963,
            value: 'phuong_an_thanh',
            division_type: 'phường',
            short_codename: 'an_thanh',
            parentId: 725,
          },
          {
            title: 'Phường Lái Thiêu',
            code: 25966,
            value: 'phuong_lai_thieu',
            division_type: 'phường',
            short_codename: 'lai_thieu',
            parentId: 725,
          },
          {
            title: 'Phường Bình Chuẩn',
            code: 25969,
            value: 'phuong_binh_chuan',
            division_type: 'phường',
            short_codename: 'binh_chuan',
            parentId: 725,
          },
          {
            title: 'Phường Thuận Giao',
            code: 25972,
            value: 'phuong_thuan_giao',
            division_type: 'phường',
            short_codename: 'thuan_giao',
            parentId: 725,
          },
          {
            title: 'Phường An Phú',
            code: 25975,
            value: 'phuong_an_phu',
            division_type: 'phường',
            short_codename: 'an_phu',
            parentId: 725,
          },
          {
            title: 'Phường Hưng Định',
            code: 25978,
            value: 'phuong_hung_dinh',
            division_type: 'phường',
            short_codename: 'hung_dinh',
            parentId: 725,
          },
          {
            title: 'Xã An Sơn',
            code: 25981,
            value: 'xa_an_son',
            division_type: 'xã',
            short_codename: 'an_son',
            parentId: 725,
          },
          {
            title: 'Phường Bình Nhâm',
            code: 25984,
            value: 'phuong_binh_nham',
            division_type: 'phường',
            short_codename: 'binh_nham',
            parentId: 725,
          },
          {
            title: 'Phường Bình Hòa',
            code: 25987,
            value: 'phuong_binh_hoa',
            division_type: 'phường',
            short_codename: 'binh_hoa',
            parentId: 725,
          },
          {
            title: 'Phường Vĩnh Phú',
            code: 25990,
            value: 'phuong_vinh_phu',
            division_type: 'phường',
            short_codename: 'vinh_phu',
            parentId: 725,
          },
        ],
        parentId: 74,
      },
      {
        title: 'Huyện Bắc Tân Uyên',
        code: 726,
        value: 'huyen_bac_tan_uyen',
        division_type: 'huyện',
        short_codename: 'bac_tan_uyen',
        children: [
          {
            title: 'Xã Tân Định',
            code: 25894,
            value: 'xa_tan_dinh',
            division_type: 'xã',
            short_codename: 'tan_dinh',
            parentId: 726,
          },
          {
            title: 'Xã Bình Mỹ',
            code: 25897,
            value: 'xa_binh_my',
            division_type: 'xã',
            short_codename: 'binh_my',
            parentId: 726,
          },
          {
            title: 'Thị trấn Tân Bình',
            code: 25900,
            value: 'thi_tran_tan_binh',
            division_type: 'thị trấn',
            short_codename: 'tan_binh',
            parentId: 726,
          },
          {
            title: 'Xã Tân Lập',
            code: 25903,
            value: 'xa_tan_lap',
            division_type: 'xã',
            short_codename: 'tan_lap',
            parentId: 726,
          },
          {
            title: 'Thị trấn Tân Thành',
            code: 25906,
            value: 'thi_tran_tan_thanh',
            division_type: 'thị trấn',
            short_codename: 'tan_thanh',
            parentId: 726,
          },
          {
            title: 'Xã Đất Cuốc',
            code: 25907,
            value: 'xa_dat_cuoc',
            division_type: 'xã',
            short_codename: 'dat_cuoc',
            parentId: 726,
          },
          {
            title: 'Xã Hiếu Liêm',
            code: 25908,
            value: 'xa_hieu_liem',
            division_type: 'xã',
            short_codename: 'hieu_liem',
            parentId: 726,
          },
          {
            title: 'Xã Lạc An',
            code: 25909,
            value: 'xa_lac_an',
            division_type: 'xã',
            short_codename: 'lac_an',
            parentId: 726,
          },
          {
            title: 'Xã Tân Mỹ',
            code: 25918,
            value: 'xa_tan_my',
            division_type: 'xã',
            short_codename: 'tan_my',
            parentId: 726,
          },
          {
            title: 'Xã Thường Tân',
            code: 25927,
            value: 'xa_thuong_tan',
            division_type: 'xã',
            short_codename: 'thuong_tan',
            parentId: 726,
          },
        ],
        parentId: 74,
      },
    ],
  },
  {
    title: 'Tỉnh Đồng Nai',
    code: 75,
    value: 'tinh_dong_nai',
    division_type: 'tỉnh',
    phone_code: 251,
    children: [
      {
        title: 'Thành phố Biên Hòa',
        code: 731,
        value: 'thanh_pho_bien_hoa',
        division_type: 'thành phố',
        short_codename: 'bien_hoa',
        children: [
          {
            title: 'Phường Trảng Dài',
            code: 25993,
            value: 'phuong_trang_dai',
            division_type: 'phường',
            short_codename: 'trang_dai',
            parentId: 731,
          },
          {
            title: 'Phường Tân Phong',
            code: 25996,
            value: 'phuong_tan_phong',
            division_type: 'phường',
            short_codename: 'tan_phong',
            parentId: 731,
          },
          {
            title: 'Phường Tân Biên',
            code: 25999,
            value: 'phuong_tan_bien',
            division_type: 'phường',
            short_codename: 'tan_bien',
            parentId: 731,
          },
          {
            title: 'Phường Hố Nai',
            code: 26002,
            value: 'phuong_ho_nai',
            division_type: 'phường',
            short_codename: 'ho_nai',
            parentId: 731,
          },
          {
            title: 'Phường Tân Hòa',
            code: 26005,
            value: 'phuong_tan_hoa',
            division_type: 'phường',
            short_codename: 'tan_hoa',
            parentId: 731,
          },
          {
            title: 'Phường Tân Hiệp',
            code: 26008,
            value: 'phuong_tan_hiep',
            division_type: 'phường',
            short_codename: 'tan_hiep',
            parentId: 731,
          },
          {
            title: 'Phường Bửu Long',
            code: 26011,
            value: 'phuong_buu_long',
            division_type: 'phường',
            short_codename: 'buu_long',
            parentId: 731,
          },
          {
            title: 'Phường Tân Tiến',
            code: 26014,
            value: 'phuong_tan_tien',
            division_type: 'phường',
            short_codename: 'tan_tien',
            parentId: 731,
          },
          {
            title: 'Phường Tam Hiệp',
            code: 26017,
            value: 'phuong_tam_hiep',
            division_type: 'phường',
            short_codename: 'tam_hiep',
            parentId: 731,
          },
          {
            title: 'Phường Long Bình',
            code: 26020,
            value: 'phuong_long_binh',
            division_type: 'phường',
            short_codename: 'long_binh',
            parentId: 731,
          },
          {
            title: 'Phường Quang Vinh',
            code: 26023,
            value: 'phuong_quang_vinh',
            division_type: 'phường',
            short_codename: 'quang_vinh',
            parentId: 731,
          },
          {
            title: 'Phường Tân Mai',
            code: 26026,
            value: 'phuong_tan_mai',
            division_type: 'phường',
            short_codename: 'tan_mai',
            parentId: 731,
          },
          {
            title: 'Phường Thống Nhất',
            code: 26029,
            value: 'phuong_thong_nhat',
            division_type: 'phường',
            short_codename: 'thong_nhat',
            parentId: 731,
          },
          {
            title: 'Phường Trung Dũng',
            code: 26032,
            value: 'phuong_trung_dung',
            division_type: 'phường',
            short_codename: 'trung_dung',
            parentId: 731,
          },
          {
            title: 'Phường Tam Hòa',
            code: 26035,
            value: 'phuong_tam_hoa',
            division_type: 'phường',
            short_codename: 'tam_hoa',
            parentId: 731,
          },
          {
            title: 'Phường Hòa Bình',
            code: 26038,
            value: 'phuong_hoa_binh',
            division_type: 'phường',
            short_codename: 'hoa_binh',
            parentId: 731,
          },
          {
            title: 'Phường Quyết Thắng',
            code: 26041,
            value: 'phuong_quyet_thang',
            division_type: 'phường',
            short_codename: 'quyet_thang',
            parentId: 731,
          },
          {
            title: 'Phường Thanh Bình',
            code: 26044,
            value: 'phuong_thanh_binh',
            division_type: 'phường',
            short_codename: 'thanh_binh',
            parentId: 731,
          },
          {
            title: 'Phường Bình Đa',
            code: 26047,
            value: 'phuong_binh_da',
            division_type: 'phường',
            short_codename: 'binh_da',
            parentId: 731,
          },
          {
            title: 'Phường An Bình',
            code: 26050,
            value: 'phuong_an_binh',
            division_type: 'phường',
            short_codename: 'an_binh',
            parentId: 731,
          },
          {
            title: 'Phường Bửu Hòa',
            code: 26053,
            value: 'phuong_buu_hoa',
            division_type: 'phường',
            short_codename: 'buu_hoa',
            parentId: 731,
          },
          {
            title: 'Phường Long Bình Tân',
            code: 26056,
            value: 'phuong_long_binh_tan',
            division_type: 'phường',
            short_codename: 'long_binh_tan',
            parentId: 731,
          },
          {
            title: 'Phường Tân Vạn',
            code: 26059,
            value: 'phuong_tan_van',
            division_type: 'phường',
            short_codename: 'tan_van',
            parentId: 731,
          },
          {
            title: 'Phường Tân Hạnh',
            code: 26062,
            value: 'phuong_tan_hanh',
            division_type: 'phường',
            short_codename: 'tan_hanh',
            parentId: 731,
          },
          {
            title: 'Phường Hiệp Hòa',
            code: 26065,
            value: 'phuong_hiep_hoa',
            division_type: 'phường',
            short_codename: 'hiep_hoa',
            parentId: 731,
          },
          {
            title: 'Phường Hóa An',
            code: 26068,
            value: 'phuong_hoa_an',
            division_type: 'phường',
            short_codename: 'hoa_an',
            parentId: 731,
          },
          {
            title: 'Phường An Hòa',
            code: 26371,
            value: 'phuong_an_hoa',
            division_type: 'phường',
            short_codename: 'an_hoa',
            parentId: 731,
          },
          {
            title: 'Phường Tam Phước',
            code: 26374,
            value: 'phuong_tam_phuoc',
            division_type: 'phường',
            short_codename: 'tam_phuoc',
            parentId: 731,
          },
          {
            title: 'Phường Phước Tân',
            code: 26377,
            value: 'phuong_phuoc_tan',
            division_type: 'phường',
            short_codename: 'phuoc_tan',
            parentId: 731,
          },
          {
            title: 'Xã Long Hưng',
            code: 26380,
            value: 'xa_long_hung',
            division_type: 'xã',
            short_codename: 'long_hung',
            parentId: 731,
          },
        ],
        parentId: 75,
      },
      {
        title: 'Thành phố Long Khánh',
        code: 732,
        value: 'thanh_pho_long_khanh',
        division_type: 'thành phố',
        short_codename: 'long_khanh',
        children: [
          {
            title: 'Phường Xuân Trung',
            code: 26071,
            value: 'phuong_xuan_trung',
            division_type: 'phường',
            short_codename: 'xuan_trung',
            parentId: 732,
          },
          {
            title: 'Phường Xuân Thanh',
            code: 26074,
            value: 'phuong_xuan_thanh',
            division_type: 'phường',
            short_codename: 'xuan_thanh',
            parentId: 732,
          },
          {
            title: 'Phường Xuân Bình',
            code: 26077,
            value: 'phuong_xuan_binh',
            division_type: 'phường',
            short_codename: 'xuan_binh',
            parentId: 732,
          },
          {
            title: 'Phường Xuân An',
            code: 26080,
            value: 'phuong_xuan_an',
            division_type: 'phường',
            short_codename: 'xuan_an',
            parentId: 732,
          },
          {
            title: 'Phường Xuân Hoà',
            code: 26083,
            value: 'phuong_xuan_hoa',
            division_type: 'phường',
            short_codename: 'xuan_hoa',
            parentId: 732,
          },
          {
            title: 'Phường Phú Bình',
            code: 26086,
            value: 'phuong_phu_binh',
            division_type: 'phường',
            short_codename: 'phu_binh',
            parentId: 732,
          },
          {
            title: 'Xã Bình Lộc',
            code: 26089,
            value: 'xa_binh_loc',
            division_type: 'xã',
            short_codename: 'binh_loc',
            parentId: 732,
          },
          {
            title: 'Xã Bảo Quang',
            code: 26092,
            value: 'xa_bao_quang',
            division_type: 'xã',
            short_codename: 'bao_quang',
            parentId: 732,
          },
          {
            title: 'Phường Suối Tre',
            code: 26095,
            value: 'phuong_suoi_tre',
            division_type: 'phường',
            short_codename: 'suoi_tre',
            parentId: 732,
          },
          {
            title: 'Phường Bảo Vinh',
            code: 26098,
            value: 'phuong_bao_vinh',
            division_type: 'phường',
            short_codename: 'bao_vinh',
            parentId: 732,
          },
          {
            title: 'Phường Xuân Lập',
            code: 26101,
            value: 'phuong_xuan_lap',
            division_type: 'phường',
            short_codename: 'xuan_lap',
            parentId: 732,
          },
          {
            title: 'Phường Bàu Sen',
            code: 26104,
            value: 'phuong_bau_sen',
            division_type: 'phường',
            short_codename: 'bau_sen',
            parentId: 732,
          },
          {
            title: 'Xã Bàu Trâm',
            code: 26107,
            value: 'xa_bau_tram',
            division_type: 'xã',
            short_codename: 'bau_tram',
            parentId: 732,
          },
          {
            title: 'Phường Xuân Tân',
            code: 26110,
            value: 'phuong_xuan_tan',
            division_type: 'phường',
            short_codename: 'xuan_tan',
            parentId: 732,
          },
          {
            title: 'Xã Hàng Gòn',
            code: 26113,
            value: 'xa_hang_gon',
            division_type: 'xã',
            short_codename: 'hang_gon',
            parentId: 732,
          },
        ],
        parentId: 75,
      },
      {
        title: 'Huyện Tân Phú',
        code: 734,
        value: 'huyen_tan_phu',
        division_type: 'huyện',
        short_codename: 'tan_phu',
        children: [
          {
            title: 'Thị trấn Tân Phú',
            code: 26116,
            value: 'thi_tran_tan_phu',
            division_type: 'thị trấn',
            short_codename: 'tan_phu',
            parentId: 734,
          },
          {
            title: 'Xã Dak Lua',
            code: 26119,
            value: 'xa_dak_lua',
            division_type: 'xã',
            short_codename: 'dak_lua',
            parentId: 734,
          },
          {
            title: 'Xã Nam Cát Tiên',
            code: 26122,
            value: 'xa_nam_cat_tien',
            division_type: 'xã',
            short_codename: 'nam_cat_tien',
            parentId: 734,
          },
          {
            title: 'Xã Phú An',
            code: 26125,
            value: 'xa_phu_an',
            division_type: 'xã',
            short_codename: 'phu_an',
            parentId: 734,
          },
          {
            title: 'Xã Núi Tượng',
            code: 26128,
            value: 'xa_nui_tuong',
            division_type: 'xã',
            short_codename: 'nui_tuong',
            parentId: 734,
          },
          {
            title: 'Xã Tà Lài',
            code: 26131,
            value: 'xa_ta_lai',
            division_type: 'xã',
            short_codename: 'ta_lai',
            parentId: 734,
          },
          {
            title: 'Xã Phú Lập',
            code: 26134,
            value: 'xa_phu_lap',
            division_type: 'xã',
            short_codename: 'phu_lap',
            parentId: 734,
          },
          {
            title: 'Xã Phú Sơn',
            code: 26137,
            value: 'xa_phu_son',
            division_type: 'xã',
            short_codename: 'phu_son',
            parentId: 734,
          },
          {
            title: 'Xã Phú Thịnh',
            code: 26140,
            value: 'xa_phu_thinh',
            division_type: 'xã',
            short_codename: 'phu_thinh',
            parentId: 734,
          },
          {
            title: 'Xã Thanh Sơn',
            code: 26143,
            value: 'xa_thanh_son',
            division_type: 'xã',
            short_codename: 'thanh_son',
            parentId: 734,
          },
          {
            title: 'Xã Phú Trung',
            code: 26146,
            value: 'xa_phu_trung',
            division_type: 'xã',
            short_codename: 'phu_trung',
            parentId: 734,
          },
          {
            title: 'Xã Phú Xuân',
            code: 26149,
            value: 'xa_phu_xuan',
            division_type: 'xã',
            short_codename: 'phu_xuan',
            parentId: 734,
          },
          {
            title: 'Xã Phú Lộc',
            code: 26152,
            value: 'xa_phu_loc',
            division_type: 'xã',
            short_codename: 'phu_loc',
            parentId: 734,
          },
          {
            title: 'Xã Phú Lâm',
            code: 26155,
            value: 'xa_phu_lam',
            division_type: 'xã',
            short_codename: 'phu_lam',
            parentId: 734,
          },
          {
            title: 'Xã Phú Bình',
            code: 26158,
            value: 'xa_phu_binh',
            division_type: 'xã',
            short_codename: 'phu_binh',
            parentId: 734,
          },
          {
            title: 'Xã Phú Thanh',
            code: 26161,
            value: 'xa_phu_thanh',
            division_type: 'xã',
            short_codename: 'phu_thanh',
            parentId: 734,
          },
          {
            title: 'Xã Trà Cổ',
            code: 26164,
            value: 'xa_tra_co',
            division_type: 'xã',
            short_codename: 'tra_co',
            parentId: 734,
          },
          {
            title: 'Xã Phú Điền',
            code: 26167,
            value: 'xa_phu_dien',
            division_type: 'xã',
            short_codename: 'phu_dien',
            parentId: 734,
          },
        ],
        parentId: 75,
      },
      {
        title: 'Huyện Vĩnh Cửu',
        code: 735,
        value: 'huyen_vinh_cuu',
        division_type: 'huyện',
        short_codename: 'vinh_cuu',
        children: [
          {
            title: 'Thị trấn Vĩnh An',
            code: 26170,
            value: 'thi_tran_vinh_an',
            division_type: 'thị trấn',
            short_codename: 'vinh_an',
            parentId: 735,
          },
          {
            title: 'Xã Phú Lý',
            code: 26173,
            value: 'xa_phu_ly',
            division_type: 'xã',
            short_codename: 'phu_ly',
            parentId: 735,
          },
          {
            title: 'Xã Trị An',
            code: 26176,
            value: 'xa_tri_an',
            division_type: 'xã',
            short_codename: 'tri_an',
            parentId: 735,
          },
          {
            title: 'Xã Tân An',
            code: 26179,
            value: 'xa_tan_an',
            division_type: 'xã',
            short_codename: 'tan_an',
            parentId: 735,
          },
          {
            title: 'Xã Vĩnh Tân',
            code: 26182,
            value: 'xa_vinh_tan',
            division_type: 'xã',
            short_codename: 'vinh_tan',
            parentId: 735,
          },
          {
            title: 'Xã Bình Lợi',
            code: 26185,
            value: 'xa_binh_loi',
            division_type: 'xã',
            short_codename: 'binh_loi',
            parentId: 735,
          },
          {
            title: 'Xã Thạnh Phú',
            code: 26188,
            value: 'xa_thanh_phu',
            division_type: 'xã',
            short_codename: 'thanh_phu',
            parentId: 735,
          },
          {
            title: 'Xã Thiện Tân',
            code: 26191,
            value: 'xa_thien_tan',
            division_type: 'xã',
            short_codename: 'thien_tan',
            parentId: 735,
          },
          {
            title: 'Xã Tân Bình',
            code: 26194,
            value: 'xa_tan_binh',
            division_type: 'xã',
            short_codename: 'tan_binh',
            parentId: 735,
          },
          {
            title: 'Xã Bình Hòa',
            code: 26197,
            value: 'xa_binh_hoa',
            division_type: 'xã',
            short_codename: 'binh_hoa',
            parentId: 735,
          },
          {
            title: 'Xã Mã Đà',
            code: 26200,
            value: 'xa_ma_da',
            division_type: 'xã',
            short_codename: 'ma_da',
            parentId: 735,
          },
          {
            title: 'Xã Hiếu Liêm',
            code: 26203,
            value: 'xa_hieu_liem',
            division_type: 'xã',
            short_codename: 'hieu_liem',
            parentId: 735,
          },
        ],
        parentId: 75,
      },
      {
        title: 'Huyện Định Quán',
        code: 736,
        value: 'huyen_dinh_quan',
        division_type: 'huyện',
        short_codename: 'dinh_quan',
        children: [
          {
            title: 'Thị trấn Định Quán',
            code: 26206,
            value: 'thi_tran_dinh_quan',
            division_type: 'thị trấn',
            short_codename: 'dinh_quan',
            parentId: 736,
          },
          {
            title: 'Xã Thanh Sơn',
            code: 26209,
            value: 'xa_thanh_son',
            division_type: 'xã',
            short_codename: 'thanh_son',
            parentId: 736,
          },
          {
            title: 'Xã Phú Tân',
            code: 26212,
            value: 'xa_phu_tan',
            division_type: 'xã',
            short_codename: 'phu_tan',
            parentId: 736,
          },
          {
            title: 'Xã Phú Vinh',
            code: 26215,
            value: 'xa_phu_vinh',
            division_type: 'xã',
            short_codename: 'phu_vinh',
            parentId: 736,
          },
          {
            title: 'Xã Phú Lợi',
            code: 26218,
            value: 'xa_phu_loi',
            division_type: 'xã',
            short_codename: 'phu_loi',
            parentId: 736,
          },
          {
            title: 'Xã Phú Hòa',
            code: 26221,
            value: 'xa_phu_hoa',
            division_type: 'xã',
            short_codename: 'phu_hoa',
            parentId: 736,
          },
          {
            title: 'Xã Ngọc Định',
            code: 26224,
            value: 'xa_ngoc_dinh',
            division_type: 'xã',
            short_codename: 'ngoc_dinh',
            parentId: 736,
          },
          {
            title: 'Xã La Ngà',
            code: 26227,
            value: 'xa_la_nga',
            division_type: 'xã',
            short_codename: 'la_nga',
            parentId: 736,
          },
          {
            title: 'Xã Gia Canh',
            code: 26230,
            value: 'xa_gia_canh',
            division_type: 'xã',
            short_codename: 'gia_canh',
            parentId: 736,
          },
          {
            title: 'Xã Phú Ngọc',
            code: 26233,
            value: 'xa_phu_ngoc',
            division_type: 'xã',
            short_codename: 'phu_ngoc',
            parentId: 736,
          },
          {
            title: 'Xã Phú Cường',
            code: 26236,
            value: 'xa_phu_cuong',
            division_type: 'xã',
            short_codename: 'phu_cuong',
            parentId: 736,
          },
          {
            title: 'Xã Túc Trưng',
            code: 26239,
            value: 'xa_tuc_trung',
            division_type: 'xã',
            short_codename: 'tuc_trung',
            parentId: 736,
          },
          {
            title: 'Xã Phú Túc',
            code: 26242,
            value: 'xa_phu_tuc',
            division_type: 'xã',
            short_codename: 'phu_tuc',
            parentId: 736,
          },
          {
            title: 'Xã Suối Nho',
            code: 26245,
            value: 'xa_suoi_nho',
            division_type: 'xã',
            short_codename: 'suoi_nho',
            parentId: 736,
          },
        ],
        parentId: 75,
      },
      {
        title: 'Huyện Trảng Bom',
        code: 737,
        value: 'huyen_trang_bom',
        division_type: 'huyện',
        short_codename: 'trang_bom',
        children: [
          {
            title: 'Thị trấn Trảng Bom',
            code: 26248,
            value: 'thi_tran_trang_bom',
            division_type: 'thị trấn',
            short_codename: 'trang_bom',
            parentId: 737,
          },
          {
            title: 'Xã Thanh Bình',
            code: 26251,
            value: 'xa_thanh_binh',
            division_type: 'xã',
            short_codename: 'thanh_binh',
            parentId: 737,
          },
          {
            title: 'Xã Cây Gáo',
            code: 26254,
            value: 'xa_cay_gao',
            division_type: 'xã',
            short_codename: 'cay_gao',
            parentId: 737,
          },
          {
            title: 'Xã Bàu Hàm',
            code: 26257,
            value: 'xa_bau_ham',
            division_type: 'xã',
            short_codename: 'bau_ham',
            parentId: 737,
          },
          {
            title: 'Xã Sông Thao',
            code: 26260,
            value: 'xa_song_thao',
            division_type: 'xã',
            short_codename: 'song_thao',
            parentId: 737,
          },
          {
            title: 'Xã Sông Trầu',
            code: 26263,
            value: 'xa_song_trau',
            division_type: 'xã',
            short_codename: 'song_trau',
            parentId: 737,
          },
          {
            title: 'Xã Đông Hoà',
            code: 26266,
            value: 'xa_dong_hoa',
            division_type: 'xã',
            short_codename: 'dong_hoa',
            parentId: 737,
          },
          {
            title: 'Xã Bắc Sơn',
            code: 26269,
            value: 'xa_bac_son',
            division_type: 'xã',
            short_codename: 'bac_son',
            parentId: 737,
          },
          {
            title: 'Xã Hố Nai 3',
            code: 26272,
            value: 'xa_ho_nai_3',
            division_type: 'xã',
            short_codename: 'ho_nai_3',
            parentId: 737,
          },
          {
            title: 'Xã Tây Hoà',
            code: 26275,
            value: 'xa_tay_hoa',
            division_type: 'xã',
            short_codename: 'tay_hoa',
            parentId: 737,
          },
          {
            title: 'Xã Bình Minh',
            code: 26278,
            value: 'xa_binh_minh',
            division_type: 'xã',
            short_codename: 'binh_minh',
            parentId: 737,
          },
          {
            title: 'Xã Trung Hoà',
            code: 26281,
            value: 'xa_trung_hoa',
            division_type: 'xã',
            short_codename: 'trung_hoa',
            parentId: 737,
          },
          {
            title: 'Xã Đồi 61',
            code: 26284,
            value: 'xa_doi_61',
            division_type: 'xã',
            short_codename: 'doi_61',
            parentId: 737,
          },
          {
            title: 'Xã Hưng Thịnh',
            code: 26287,
            value: 'xa_hung_thinh',
            division_type: 'xã',
            short_codename: 'hung_thinh',
            parentId: 737,
          },
          {
            title: 'Xã Quảng Tiến',
            code: 26290,
            value: 'xa_quang_tien',
            division_type: 'xã',
            short_codename: 'quang_tien',
            parentId: 737,
          },
          {
            title: 'Xã Giang Điền',
            code: 26293,
            value: 'xa_giang_dien',
            division_type: 'xã',
            short_codename: 'giang_dien',
            parentId: 737,
          },
          {
            title: 'Xã An Viễn',
            code: 26296,
            value: 'xa_an_vien',
            division_type: 'xã',
            short_codename: 'an_vien',
            parentId: 737,
          },
        ],
        parentId: 75,
      },
      {
        title: 'Huyện Thống Nhất',
        code: 738,
        value: 'huyen_thong_nhat',
        division_type: 'huyện',
        short_codename: 'thong_nhat',
        children: [
          {
            title: 'Xã Gia Tân 1',
            code: 26299,
            value: 'xa_gia_tan_1',
            division_type: 'xã',
            short_codename: 'gia_tan_1',
            parentId: 738,
          },
          {
            title: 'Xã Gia Tân 2',
            code: 26302,
            value: 'xa_gia_tan_2',
            division_type: 'xã',
            short_codename: 'gia_tan_2',
            parentId: 738,
          },
          {
            title: 'Xã Gia Tân 3',
            code: 26305,
            value: 'xa_gia_tan_3',
            division_type: 'xã',
            short_codename: 'gia_tan_3',
            parentId: 738,
          },
          {
            title: 'Xã Gia Kiệm',
            code: 26308,
            value: 'xa_gia_kiem',
            division_type: 'xã',
            short_codename: 'gia_kiem',
            parentId: 738,
          },
          {
            title: 'Xã Quang Trung',
            code: 26311,
            value: 'xa_quang_trung',
            division_type: 'xã',
            short_codename: 'quang_trung',
            parentId: 738,
          },
          {
            title: 'Xã Bàu Hàm 2',
            code: 26314,
            value: 'xa_bau_ham_2',
            division_type: 'xã',
            short_codename: 'bau_ham_2',
            parentId: 738,
          },
          {
            title: 'Xã Hưng Lộc',
            code: 26317,
            value: 'xa_hung_loc',
            division_type: 'xã',
            short_codename: 'hung_loc',
            parentId: 738,
          },
          {
            title: 'Xã Lộ 25',
            code: 26320,
            value: 'xa_lo_25',
            division_type: 'xã',
            short_codename: 'lo_25',
            parentId: 738,
          },
          {
            title: 'Xã Xuân Thiện',
            code: 26323,
            value: 'xa_xuan_thien',
            division_type: 'xã',
            short_codename: 'xuan_thien',
            parentId: 738,
          },
          {
            title: 'Thị trấn Dầu Giây',
            code: 26326,
            value: 'thi_tran_dau_giay',
            division_type: 'thị trấn',
            short_codename: 'dau_giay',
            parentId: 738,
          },
        ],
        parentId: 75,
      },
      {
        title: 'Huyện Cẩm Mỹ',
        code: 739,
        value: 'huyen_cam_my',
        division_type: 'huyện',
        short_codename: 'cam_my',
        children: [
          {
            title: 'Xã Sông Nhạn',
            code: 26329,
            value: 'xa_song_nhan',
            division_type: 'xã',
            short_codename: 'song_nhan',
            parentId: 739,
          },
          {
            title: 'Xã Xuân Quế',
            code: 26332,
            value: 'xa_xuan_que',
            division_type: 'xã',
            short_codename: 'xuan_que',
            parentId: 739,
          },
          {
            title: 'Xã Nhân Nghĩa',
            code: 26335,
            value: 'xa_nhan_nghia',
            division_type: 'xã',
            short_codename: 'nhan_nghia',
            parentId: 739,
          },
          {
            title: 'Xã Xuân Đường',
            code: 26338,
            value: 'xa_xuan_duong',
            division_type: 'xã',
            short_codename: 'xuan_duong',
            parentId: 739,
          },
          {
            title: 'Xã Long Giao',
            code: 26341,
            value: 'xa_long_giao',
            division_type: 'xã',
            short_codename: 'long_giao',
            parentId: 739,
          },
          {
            title: 'Xã Xuân Mỹ',
            code: 26344,
            value: 'xa_xuan_my',
            division_type: 'xã',
            short_codename: 'xuan_my',
            parentId: 739,
          },
          {
            title: 'Xã Thừa Đức',
            code: 26347,
            value: 'xa_thua_duc',
            division_type: 'xã',
            short_codename: 'thua_duc',
            parentId: 739,
          },
          {
            title: 'Xã Bảo Bình',
            code: 26350,
            value: 'xa_bao_binh',
            division_type: 'xã',
            short_codename: 'bao_binh',
            parentId: 739,
          },
          {
            title: 'Xã Xuân Bảo',
            code: 26353,
            value: 'xa_xuan_bao',
            division_type: 'xã',
            short_codename: 'xuan_bao',
            parentId: 739,
          },
          {
            title: 'Xã Xuân Tây',
            code: 26356,
            value: 'xa_xuan_tay',
            division_type: 'xã',
            short_codename: 'xuan_tay',
            parentId: 739,
          },
          {
            title: 'Xã Xuân Đông',
            code: 26359,
            value: 'xa_xuan_dong',
            division_type: 'xã',
            short_codename: 'xuan_dong',
            parentId: 739,
          },
          {
            title: 'Xã Sông Ray',
            code: 26362,
            value: 'xa_song_ray',
            division_type: 'xã',
            short_codename: 'song_ray',
            parentId: 739,
          },
          {
            title: 'Xã Lâm San',
            code: 26365,
            value: 'xa_lam_san',
            division_type: 'xã',
            short_codename: 'lam_san',
            parentId: 739,
          },
        ],
        parentId: 75,
      },
      {
        title: 'Huyện Long Thành',
        code: 740,
        value: 'huyen_long_thanh',
        division_type: 'huyện',
        short_codename: 'long_thanh',
        children: [
          {
            title: 'Thị trấn Long Thành',
            code: 26368,
            value: 'thi_tran_long_thanh',
            division_type: 'thị trấn',
            short_codename: 'long_thanh',
            parentId: 740,
          },
          {
            title: 'Xã An Phước',
            code: 26383,
            value: 'xa_an_phuoc',
            division_type: 'xã',
            short_codename: 'an_phuoc',
            parentId: 740,
          },
          {
            title: 'Xã Bình An',
            code: 26386,
            value: 'xa_binh_an',
            division_type: 'xã',
            short_codename: 'binh_an',
            parentId: 740,
          },
          {
            title: 'Xã Long Đức',
            code: 26389,
            value: 'xa_long_duc',
            division_type: 'xã',
            short_codename: 'long_duc',
            parentId: 740,
          },
          {
            title: 'Xã Lộc An',
            code: 26392,
            value: 'xa_loc_an',
            division_type: 'xã',
            short_codename: 'loc_an',
            parentId: 740,
          },
          {
            title: 'Xã Bình Sơn',
            code: 26395,
            value: 'xa_binh_son',
            division_type: 'xã',
            short_codename: 'binh_son',
            parentId: 740,
          },
          {
            title: 'Xã Tam An',
            code: 26398,
            value: 'xa_tam_an',
            division_type: 'xã',
            short_codename: 'tam_an',
            parentId: 740,
          },
          {
            title: 'Xã Cẩm Đường',
            code: 26401,
            value: 'xa_cam_duong',
            division_type: 'xã',
            short_codename: 'cam_duong',
            parentId: 740,
          },
          {
            title: 'Xã Long An',
            code: 26404,
            value: 'xa_long_an',
            division_type: 'xã',
            short_codename: 'long_an',
            parentId: 740,
          },
          {
            title: 'Xã Bàu Cạn',
            code: 26410,
            value: 'xa_bau_can',
            division_type: 'xã',
            short_codename: 'bau_can',
            parentId: 740,
          },
          {
            title: 'Xã Long Phước',
            code: 26413,
            value: 'xa_long_phuoc',
            division_type: 'xã',
            short_codename: 'long_phuoc',
            parentId: 740,
          },
          {
            title: 'Xã Phước Bình',
            code: 26416,
            value: 'xa_phuoc_binh',
            division_type: 'xã',
            short_codename: 'phuoc_binh',
            parentId: 740,
          },
          {
            title: 'Xã Tân Hiệp',
            code: 26419,
            value: 'xa_tan_hiep',
            division_type: 'xã',
            short_codename: 'tan_hiep',
            parentId: 740,
          },
          {
            title: 'Xã Phước Thái',
            code: 26422,
            value: 'xa_phuoc_thai',
            division_type: 'xã',
            short_codename: 'phuoc_thai',
            parentId: 740,
          },
        ],
        parentId: 75,
      },
      {
        title: 'Huyện Xuân Lộc',
        code: 741,
        value: 'huyen_xuan_loc',
        division_type: 'huyện',
        short_codename: 'xuan_loc',
        children: [
          {
            title: 'Thị trấn Gia Ray',
            code: 26425,
            value: 'thi_tran_gia_ray',
            division_type: 'thị trấn',
            short_codename: 'gia_ray',
            parentId: 741,
          },
          {
            title: 'Xã Xuân Bắc',
            code: 26428,
            value: 'xa_xuan_bac',
            division_type: 'xã',
            short_codename: 'xuan_bac',
            parentId: 741,
          },
          {
            title: 'Xã Suối Cao',
            code: 26431,
            value: 'xa_suoi_cao',
            division_type: 'xã',
            short_codename: 'suoi_cao',
            parentId: 741,
          },
          {
            title: 'Xã Xuân Thành',
            code: 26434,
            value: 'xa_xuan_thanh',
            division_type: 'xã',
            short_codename: 'xuan_thanh',
            parentId: 741,
          },
          {
            title: 'Xã Xuân Thọ',
            code: 26437,
            value: 'xa_xuan_tho',
            division_type: 'xã',
            short_codename: 'xuan_tho',
            parentId: 741,
          },
          {
            title: 'Xã Xuân Trường',
            code: 26440,
            value: 'xa_xuan_truong',
            division_type: 'xã',
            short_codename: 'xuan_truong',
            parentId: 741,
          },
          {
            title: 'Xã Xuân Hòa',
            code: 26443,
            value: 'xa_xuan_hoa',
            division_type: 'xã',
            short_codename: 'xuan_hoa',
            parentId: 741,
          },
          {
            title: 'Xã Xuân Hưng',
            code: 26446,
            value: 'xa_xuan_hung',
            division_type: 'xã',
            short_codename: 'xuan_hung',
            parentId: 741,
          },
          {
            title: 'Xã Xuân Tâm',
            code: 26449,
            value: 'xa_xuan_tam',
            division_type: 'xã',
            short_codename: 'xuan_tam',
            parentId: 741,
          },
          {
            title: 'Xã Suối Cát',
            code: 26452,
            value: 'xa_suoi_cat',
            division_type: 'xã',
            short_codename: 'suoi_cat',
            parentId: 741,
          },
          {
            title: 'Xã Xuân Hiệp',
            code: 26455,
            value: 'xa_xuan_hiep',
            division_type: 'xã',
            short_codename: 'xuan_hiep',
            parentId: 741,
          },
          {
            title: 'Xã Xuân Phú',
            code: 26458,
            value: 'xa_xuan_phu',
            division_type: 'xã',
            short_codename: 'xuan_phu',
            parentId: 741,
          },
          {
            title: 'Xã Xuân Định',
            code: 26461,
            value: 'xa_xuan_dinh',
            division_type: 'xã',
            short_codename: 'xuan_dinh',
            parentId: 741,
          },
          {
            title: 'Xã Bảo Hoà',
            code: 26464,
            value: 'xa_bao_hoa',
            division_type: 'xã',
            short_codename: 'bao_hoa',
            parentId: 741,
          },
          {
            title: 'Xã Lang Minh',
            code: 26467,
            value: 'xa_lang_minh',
            division_type: 'xã',
            short_codename: 'lang_minh',
            parentId: 741,
          },
        ],
        parentId: 75,
      },
      {
        title: 'Huyện Nhơn Trạch',
        code: 742,
        value: 'huyen_nhon_trach',
        division_type: 'huyện',
        short_codename: 'nhon_trach',
        children: [
          {
            title: 'Xã Phước Thiền',
            code: 26470,
            value: 'xa_phuoc_thien',
            division_type: 'xã',
            short_codename: 'phuoc_thien',
            parentId: 742,
          },
          {
            title: 'Xã Long Tân',
            code: 26473,
            value: 'xa_long_tan',
            division_type: 'xã',
            short_codename: 'long_tan',
            parentId: 742,
          },
          {
            title: 'Xã Đại Phước',
            code: 26476,
            value: 'xa_dai_phuoc',
            division_type: 'xã',
            short_codename: 'dai_phuoc',
            parentId: 742,
          },
          {
            title: 'Thị trấn Hiệp Phước',
            code: 26479,
            value: 'thi_tran_hiep_phuoc',
            division_type: 'thị trấn',
            short_codename: 'hiep_phuoc',
            parentId: 742,
          },
          {
            title: 'Xã Phú Hữu',
            code: 26482,
            value: 'xa_phu_huu',
            division_type: 'xã',
            short_codename: 'phu_huu',
            parentId: 742,
          },
          {
            title: 'Xã Phú Hội',
            code: 26485,
            value: 'xa_phu_hoi',
            division_type: 'xã',
            short_codename: 'phu_hoi',
            parentId: 742,
          },
          {
            title: 'Xã Phú Thạnh',
            code: 26488,
            value: 'xa_phu_thanh',
            division_type: 'xã',
            short_codename: 'phu_thanh',
            parentId: 742,
          },
          {
            title: 'Xã Phú Đông',
            code: 26491,
            value: 'xa_phu_dong',
            division_type: 'xã',
            short_codename: 'phu_dong',
            parentId: 742,
          },
          {
            title: 'Xã Long Thọ',
            code: 26494,
            value: 'xa_long_tho',
            division_type: 'xã',
            short_codename: 'long_tho',
            parentId: 742,
          },
          {
            title: 'Xã Vĩnh Thanh',
            code: 26497,
            value: 'xa_vinh_thanh',
            division_type: 'xã',
            short_codename: 'vinh_thanh',
            parentId: 742,
          },
          {
            title: 'Xã Phước Khánh',
            code: 26500,
            value: 'xa_phuoc_khanh',
            division_type: 'xã',
            short_codename: 'phuoc_khanh',
            parentId: 742,
          },
          {
            title: 'Xã Phước An',
            code: 26503,
            value: 'xa_phuoc_an',
            division_type: 'xã',
            short_codename: 'phuoc_an',
            parentId: 742,
          },
        ],
        parentId: 75,
      },
    ],
  },
  {
    title: 'Tỉnh Bà Rịa - Vũng Tàu',
    code: 77,
    value: 'tinh_ba_ria_vung_tau',
    division_type: 'tỉnh',
    phone_code: 254,
    children: [
      {
        title: 'Thành phố Vũng Tàu',
        code: 747,
        value: 'thanh_pho_vung_tau',
        division_type: 'thành phố',
        short_codename: 'vung_tau',
        children: [
          {
            title: 'Phường 1',
            code: 26506,
            value: 'phuong_1',
            division_type: 'phường',
            short_codename: 'phuong_1',
            parentId: 747,
          },
          {
            title: 'Phường Thắng Tam',
            code: 26508,
            value: 'phuong_thang_tam',
            division_type: 'phường',
            short_codename: 'thang_tam',
            parentId: 747,
          },
          {
            title: 'Phường 2',
            code: 26509,
            value: 'phuong_2',
            division_type: 'phường',
            short_codename: 'phuong_2',
            parentId: 747,
          },
          {
            title: 'Phường 3',
            code: 26512,
            value: 'phuong_3',
            division_type: 'phường',
            short_codename: 'phuong_3',
            parentId: 747,
          },
          {
            title: 'Phường 4',
            code: 26515,
            value: 'phuong_4',
            division_type: 'phường',
            short_codename: 'phuong_4',
            parentId: 747,
          },
          {
            title: 'Phường 5',
            code: 26518,
            value: 'phuong_5',
            division_type: 'phường',
            short_codename: 'phuong_5',
            parentId: 747,
          },
          {
            title: 'Phường Thắng Nhì',
            code: 26521,
            value: 'phuong_thang_nhi',
            division_type: 'phường',
            short_codename: 'thang_nhi',
            parentId: 747,
          },
          {
            title: 'Phường 7',
            code: 26524,
            value: 'phuong_7',
            division_type: 'phường',
            short_codename: 'phuong_7',
            parentId: 747,
          },
          {
            title: 'Phường Nguyễn An Ninh',
            code: 26526,
            value: 'phuong_nguyen_an_ninh',
            division_type: 'phường',
            short_codename: 'nguyen_an_ninh',
            parentId: 747,
          },
          {
            title: 'Phường 8',
            code: 26527,
            value: 'phuong_8',
            division_type: 'phường',
            short_codename: 'phuong_8',
            parentId: 747,
          },
          {
            title: 'Phường 9',
            code: 26530,
            value: 'phuong_9',
            division_type: 'phường',
            short_codename: 'phuong_9',
            parentId: 747,
          },
          {
            title: 'Phường Thắng Nhất',
            code: 26533,
            value: 'phuong_thang_nhat',
            division_type: 'phường',
            short_codename: 'thang_nhat',
            parentId: 747,
          },
          {
            title: 'Phường Rạch Dừa',
            code: 26535,
            value: 'phuong_rach_dua',
            division_type: 'phường',
            short_codename: 'rach_dua',
            parentId: 747,
          },
          {
            title: 'Phường 10',
            code: 26536,
            value: 'phuong_10',
            division_type: 'phường',
            short_codename: 'phuong_10',
            parentId: 747,
          },
          {
            title: 'Phường 11',
            code: 26539,
            value: 'phuong_11',
            division_type: 'phường',
            short_codename: 'phuong_11',
            parentId: 747,
          },
          {
            title: 'Phường 12',
            code: 26542,
            value: 'phuong_12',
            division_type: 'phường',
            short_codename: 'phuong_12',
            parentId: 747,
          },
          {
            title: 'Xã Long Sơn',
            code: 26545,
            value: 'xa_long_son',
            division_type: 'xã',
            short_codename: 'long_son',
            parentId: 747,
          },
        ],
        parentId: 77,
      },
      {
        title: 'Thành phố Bà Rịa',
        code: 748,
        value: 'thanh_pho_ba_ria',
        division_type: 'thành phố',
        short_codename: 'ba_ria',
        children: [
          {
            title: 'Phường Phước Hưng',
            code: 26548,
            value: 'phuong_phuoc_hung',
            division_type: 'phường',
            short_codename: 'phuoc_hung',
            parentId: 748,
          },
          {
            title: 'Phường Phước Hiệp',
            code: 26551,
            value: 'phuong_phuoc_hiep',
            division_type: 'phường',
            short_codename: 'phuoc_hiep',
            parentId: 748,
          },
          {
            title: 'Phường Phước Nguyên',
            code: 26554,
            value: 'phuong_phuoc_nguyen',
            division_type: 'phường',
            short_codename: 'phuoc_nguyen',
            parentId: 748,
          },
          {
            title: 'Phường Long Toàn',
            code: 26557,
            value: 'phuong_long_toan',
            division_type: 'phường',
            short_codename: 'long_toan',
            parentId: 748,
          },
          {
            title: 'Phường Long Tâm',
            code: 26558,
            value: 'phuong_long_tam',
            division_type: 'phường',
            short_codename: 'long_tam',
            parentId: 748,
          },
          {
            title: 'Phường Phước Trung',
            code: 26560,
            value: 'phuong_phuoc_trung',
            division_type: 'phường',
            short_codename: 'phuoc_trung',
            parentId: 748,
          },
          {
            title: 'Phường Long Hương',
            code: 26563,
            value: 'phuong_long_huong',
            division_type: 'phường',
            short_codename: 'long_huong',
            parentId: 748,
          },
          {
            title: 'Phường Kim Dinh',
            code: 26566,
            value: 'phuong_kim_dinh',
            division_type: 'phường',
            short_codename: 'kim_dinh',
            parentId: 748,
          },
          {
            title: 'Xã Tân Hưng',
            code: 26567,
            value: 'xa_tan_hung',
            division_type: 'xã',
            short_codename: 'tan_hung',
            parentId: 748,
          },
          {
            title: 'Xã Long Phước',
            code: 26569,
            value: 'xa_long_phuoc',
            division_type: 'xã',
            short_codename: 'long_phuoc',
            parentId: 748,
          },
          {
            title: 'Xã Hoà Long',
            code: 26572,
            value: 'xa_hoa_long',
            division_type: 'xã',
            short_codename: 'hoa_long',
            parentId: 748,
          },
        ],
        parentId: 77,
      },
      {
        title: 'Huyện Châu Đức',
        code: 750,
        value: 'huyen_chau_duc',
        division_type: 'huyện',
        short_codename: 'chau_duc',
        children: [
          {
            title: 'Xã Bàu Chinh',
            code: 26574,
            value: 'xa_bau_chinh',
            division_type: 'xã',
            short_codename: 'bau_chinh',
            parentId: 750,
          },
          {
            title: 'Thị trấn Ngãi Giao',
            code: 26575,
            value: 'thi_tran_ngai_giao',
            division_type: 'thị trấn',
            short_codename: 'ngai_giao',
            parentId: 750,
          },
          {
            title: 'Xã Bình Ba',
            code: 26578,
            value: 'xa_binh_ba',
            division_type: 'xã',
            short_codename: 'binh_ba',
            parentId: 750,
          },
          {
            title: 'Xã Suối Nghệ',
            code: 26581,
            value: 'xa_suoi_nghe',
            division_type: 'xã',
            short_codename: 'suoi_nghe',
            parentId: 750,
          },
          {
            title: 'Xã Xuân Sơn',
            code: 26584,
            value: 'xa_xuan_son',
            division_type: 'xã',
            short_codename: 'xuan_son',
            parentId: 750,
          },
          {
            title: 'Xã Sơn Bình',
            code: 26587,
            value: 'xa_son_binh',
            division_type: 'xã',
            short_codename: 'son_binh',
            parentId: 750,
          },
          {
            title: 'Xã Bình Giã',
            code: 26590,
            value: 'xa_binh_gia',
            division_type: 'xã',
            short_codename: 'binh_gia',
            parentId: 750,
          },
          {
            title: 'Xã Bình Trung',
            code: 26593,
            value: 'xa_binh_trung',
            division_type: 'xã',
            short_codename: 'binh_trung',
            parentId: 750,
          },
          {
            title: 'Xã Xà Bang',
            code: 26596,
            value: 'xa_xa_bang',
            division_type: 'xã',
            short_codename: 'xa_bang',
            parentId: 750,
          },
          {
            title: 'Xã Cù Bị',
            code: 26599,
            value: 'xa_cu_bi',
            division_type: 'xã',
            short_codename: 'cu_bi',
            parentId: 750,
          },
          {
            title: 'Xã Láng Lớn',
            code: 26602,
            value: 'xa_lang_lon',
            division_type: 'xã',
            short_codename: 'lang_lon',
            parentId: 750,
          },
          {
            title: 'Xã Quảng Thành',
            code: 26605,
            value: 'xa_quang_thanh',
            division_type: 'xã',
            short_codename: 'quang_thanh',
            parentId: 750,
          },
          {
            title: 'Xã Kim Long',
            code: 26608,
            value: 'xa_kim_long',
            division_type: 'xã',
            short_codename: 'kim_long',
            parentId: 750,
          },
          {
            title: 'Xã Suối Rao',
            code: 26611,
            value: 'xa_suoi_rao',
            division_type: 'xã',
            short_codename: 'suoi_rao',
            parentId: 750,
          },
          {
            title: 'Xã Đá Bạc',
            code: 26614,
            value: 'xa_da_bac',
            division_type: 'xã',
            short_codename: 'da_bac',
            parentId: 750,
          },
          {
            title: 'Xã Nghĩa Thành',
            code: 26617,
            value: 'xa_nghia_thanh',
            division_type: 'xã',
            short_codename: 'nghia_thanh',
            parentId: 750,
          },
        ],
        parentId: 77,
      },
      {
        title: 'Huyện Xuyên Mộc',
        code: 751,
        value: 'huyen_xuyen_moc',
        division_type: 'huyện',
        short_codename: 'xuyen_moc',
        children: [
          {
            title: 'Thị trấn Phước Bửu',
            code: 26620,
            value: 'thi_tran_phuoc_buu',
            division_type: 'thị trấn',
            short_codename: 'phuoc_buu',
            parentId: 751,
          },
          {
            title: 'Xã Phước Thuận',
            code: 26623,
            value: 'xa_phuoc_thuan',
            division_type: 'xã',
            short_codename: 'phuoc_thuan',
            parentId: 751,
          },
          {
            title: 'Xã Phước Tân',
            code: 26626,
            value: 'xa_phuoc_tan',
            division_type: 'xã',
            short_codename: 'phuoc_tan',
            parentId: 751,
          },
          {
            title: 'Xã Xuyên Mộc',
            code: 26629,
            value: 'xa_xuyen_moc',
            division_type: 'xã',
            short_codename: 'xuyen_moc',
            parentId: 751,
          },
          {
            title: 'Xã Bông Trang',
            code: 26632,
            value: 'xa_bong_trang',
            division_type: 'xã',
            short_codename: 'bong_trang',
            parentId: 751,
          },
          {
            title: 'Xã Tân Lâm',
            code: 26635,
            value: 'xa_tan_lam',
            division_type: 'xã',
            short_codename: 'tan_lam',
            parentId: 751,
          },
          {
            title: 'Xã Bàu Lâm',
            code: 26638,
            value: 'xa_bau_lam',
            division_type: 'xã',
            short_codename: 'bau_lam',
            parentId: 751,
          },
          {
            title: 'Xã Hòa Bình',
            code: 26641,
            value: 'xa_hoa_binh',
            division_type: 'xã',
            short_codename: 'hoa_binh',
            parentId: 751,
          },
          {
            title: 'Xã Hòa Hưng',
            code: 26644,
            value: 'xa_hoa_hung',
            division_type: 'xã',
            short_codename: 'hoa_hung',
            parentId: 751,
          },
          {
            title: 'Xã Hòa Hiệp',
            code: 26647,
            value: 'xa_hoa_hiep',
            division_type: 'xã',
            short_codename: 'hoa_hiep',
            parentId: 751,
          },
          {
            title: 'Xã Hòa Hội',
            code: 26650,
            value: 'xa_hoa_hoi',
            division_type: 'xã',
            short_codename: 'hoa_hoi',
            parentId: 751,
          },
          {
            title: 'Xã Bưng Riềng',
            code: 26653,
            value: 'xa_bung_rieng',
            division_type: 'xã',
            short_codename: 'bung_rieng',
            parentId: 751,
          },
          {
            title: 'Xã Bình Châu',
            code: 26656,
            value: 'xa_binh_chau',
            division_type: 'xã',
            short_codename: 'binh_chau',
            parentId: 751,
          },
        ],
        parentId: 77,
      },
      {
        title: 'Huyện Long Điền',
        code: 752,
        value: 'huyen_long_dien',
        division_type: 'huyện',
        short_codename: 'long_dien',
        children: [
          {
            title: 'Thị trấn Long Điền',
            code: 26659,
            value: 'thi_tran_long_dien',
            division_type: 'thị trấn',
            short_codename: 'long_dien',
            parentId: 752,
          },
          {
            title: 'Thị trấn Long Hải',
            code: 26662,
            value: 'thi_tran_long_hai',
            division_type: 'thị trấn',
            short_codename: 'long_hai',
            parentId: 752,
          },
          {
            title: 'Xã An Ngãi',
            code: 26665,
            value: 'xa_an_ngai',
            division_type: 'xã',
            short_codename: 'an_ngai',
            parentId: 752,
          },
          {
            title: 'Xã Tam Phước',
            code: 26668,
            value: 'xa_tam_phuoc',
            division_type: 'xã',
            short_codename: 'tam_phuoc',
            parentId: 752,
          },
          {
            title: 'Xã An Nhứt',
            code: 26671,
            value: 'xa_an_nhut',
            division_type: 'xã',
            short_codename: 'an_nhut',
            parentId: 752,
          },
          {
            title: 'Xã Phước Tỉnh',
            code: 26674,
            value: 'xa_phuoc_tinh',
            division_type: 'xã',
            short_codename: 'phuoc_tinh',
            parentId: 752,
          },
          {
            title: 'Xã Phước Hưng',
            code: 26677,
            value: 'xa_phuoc_hung',
            division_type: 'xã',
            short_codename: 'phuoc_hung',
            parentId: 752,
          },
        ],
        parentId: 77,
      },
      {
        title: 'Huyện Đất Đỏ',
        code: 753,
        value: 'huyen_dat_do',
        division_type: 'huyện',
        short_codename: 'dat_do',
        children: [
          {
            title: 'Thị trấn Đất Đỏ',
            code: 26680,
            value: 'thi_tran_dat_do',
            division_type: 'thị trấn',
            short_codename: 'dat_do',
            parentId: 753,
          },
          {
            title: 'Xã Phước Long Thọ',
            code: 26683,
            value: 'xa_phuoc_long_tho',
            division_type: 'xã',
            short_codename: 'phuoc_long_tho',
            parentId: 753,
          },
          {
            title: 'Xã Phước Hội',
            code: 26686,
            value: 'xa_phuoc_hoi',
            division_type: 'xã',
            short_codename: 'phuoc_hoi',
            parentId: 753,
          },
          {
            title: 'Xã Long Mỹ',
            code: 26689,
            value: 'xa_long_my',
            division_type: 'xã',
            short_codename: 'long_my',
            parentId: 753,
          },
          {
            title: 'Thị trấn Phước Hải',
            code: 26692,
            value: 'thi_tran_phuoc_hai',
            division_type: 'thị trấn',
            short_codename: 'phuoc_hai',
            parentId: 753,
          },
          {
            title: 'Xã Long Tân',
            code: 26695,
            value: 'xa_long_tan',
            division_type: 'xã',
            short_codename: 'long_tan',
            parentId: 753,
          },
          {
            title: 'Xã Láng Dài',
            code: 26698,
            value: 'xa_lang_dai',
            division_type: 'xã',
            short_codename: 'lang_dai',
            parentId: 753,
          },
          {
            title: 'Xã Lộc An',
            code: 26701,
            value: 'xa_loc_an',
            division_type: 'xã',
            short_codename: 'loc_an',
            parentId: 753,
          },
        ],
        parentId: 77,
      },
      {
        title: 'Thị xã Phú Mỹ',
        code: 754,
        value: 'thi_xa_phu_my',
        division_type: 'thị xã',
        short_codename: 'phu_my',
        children: [
          {
            title: 'Phường Phú Mỹ',
            code: 26704,
            value: 'phuong_phu_my',
            division_type: 'phường',
            short_codename: 'phu_my',
            parentId: 754,
          },
          {
            title: 'Xã Tân Hoà',
            code: 26707,
            value: 'xa_tan_hoa',
            division_type: 'xã',
            short_codename: 'tan_hoa',
            parentId: 754,
          },
          {
            title: 'Xã Tân Hải',
            code: 26710,
            value: 'xa_tan_hai',
            division_type: 'xã',
            short_codename: 'tan_hai',
            parentId: 754,
          },
          {
            title: 'Phường Phước Hoà',
            code: 26713,
            value: 'phuong_phuoc_hoa',
            division_type: 'phường',
            short_codename: 'phuoc_hoa',
            parentId: 754,
          },
          {
            title: 'Phường Tân Phước',
            code: 26716,
            value: 'phuong_tan_phuoc',
            division_type: 'phường',
            short_codename: 'tan_phuoc',
            parentId: 754,
          },
          {
            title: 'Phường Mỹ Xuân',
            code: 26719,
            value: 'phuong_my_xuan',
            division_type: 'phường',
            short_codename: 'my_xuan',
            parentId: 754,
          },
          {
            title: 'Xã Sông Xoài',
            code: 26722,
            value: 'xa_song_xoai',
            division_type: 'xã',
            short_codename: 'song_xoai',
            parentId: 754,
          },
          {
            title: 'Phường Hắc Dịch',
            code: 26725,
            value: 'phuong_hac_dich',
            division_type: 'phường',
            short_codename: 'hac_dich',
            parentId: 754,
          },
          {
            title: 'Xã Châu Pha',
            code: 26728,
            value: 'xa_chau_pha',
            division_type: 'xã',
            short_codename: 'chau_pha',
            parentId: 754,
          },
          {
            title: 'Xã Tóc Tiên',
            code: 26731,
            value: 'xa_toc_tien',
            division_type: 'xã',
            short_codename: 'toc_tien',
            parentId: 754,
          },
        ],
        parentId: 77,
      },
      {
        title: 'Huyện Côn Đảo',
        code: 755,
        value: 'huyen_con_dao',
        division_type: 'huyện',
        short_codename: 'con_dao',
        children: [],
        parentId: 77,
      },
    ],
  },
  {
    title: 'Thành phố Hồ Chí Minh',
    code: 79,
    value: 'thanh_pho_ho_chi_minh',
    division_type: 'thành phố trung ương',
    phone_code: 28,
    children: [
      {
        title: 'Quận 1',
        code: 760,
        value: 'quan_1',
        division_type: 'quận',
        short_codename: 'quan_1',
        children: [
          {
            title: 'Phường Tân Định',
            code: 26734,
            value: 'phuong_tan_dinh',
            division_type: 'phường',
            short_codename: 'tan_dinh',
            parentId: 760,
          },
          {
            title: 'Phường Đa Kao',
            code: 26737,
            value: 'phuong_da_kao',
            division_type: 'phường',
            short_codename: 'da_kao',
            parentId: 760,
          },
          {
            title: 'Phường Bến Nghé',
            code: 26740,
            value: 'phuong_ben_nghe',
            division_type: 'phường',
            short_codename: 'ben_nghe',
            parentId: 760,
          },
          {
            title: 'Phường Bến Thành',
            code: 26743,
            value: 'phuong_ben_thanh',
            division_type: 'phường',
            short_codename: 'ben_thanh',
            parentId: 760,
          },
          {
            title: 'Phường Nguyễn Thái Bình',
            code: 26746,
            value: 'phuong_nguyen_thai_binh',
            division_type: 'phường',
            short_codename: 'nguyen_thai_binh',
            parentId: 760,
          },
          {
            title: 'Phường Phạm Ngũ Lão',
            code: 26749,
            value: 'phuong_pham_ngu_lao',
            division_type: 'phường',
            short_codename: 'pham_ngu_lao',
            parentId: 760,
          },
          {
            title: 'Phường Cầu Ông Lãnh',
            code: 26752,
            value: 'phuong_cau_ong_lanh',
            division_type: 'phường',
            short_codename: 'cau_ong_lanh',
            parentId: 760,
          },
          {
            title: 'Phường Cô Giang',
            code: 26755,
            value: 'phuong_co_giang',
            division_type: 'phường',
            short_codename: 'co_giang',
            parentId: 760,
          },
          {
            title: 'Phường Nguyễn Cư Trinh',
            code: 26758,
            value: 'phuong_nguyen_cu_trinh',
            division_type: 'phường',
            short_codename: 'nguyen_cu_trinh',
            parentId: 760,
          },
          {
            title: 'Phường Cầu Kho',
            code: 26761,
            value: 'phuong_cau_kho',
            division_type: 'phường',
            short_codename: 'cau_kho',
            parentId: 760,
          },
        ],
        parentId: 79,
      },
      {
        title: 'Quận 12',
        code: 761,
        value: 'quan_12',
        division_type: 'quận',
        short_codename: 'quan_12',
        children: [
          {
            title: 'Phường Thạnh Xuân',
            code: 26764,
            value: 'phuong_thanh_xuan',
            division_type: 'phường',
            short_codename: 'thanh_xuan',
            parentId: 761,
          },
          {
            title: 'Phường Thạnh Lộc',
            code: 26767,
            value: 'phuong_thanh_loc',
            division_type: 'phường',
            short_codename: 'thanh_loc',
            parentId: 761,
          },
          {
            title: 'Phường Hiệp Thành',
            code: 26770,
            value: 'phuong_hiep_thanh',
            division_type: 'phường',
            short_codename: 'hiep_thanh',
            parentId: 761,
          },
          {
            title: 'Phường Thới An',
            code: 26773,
            value: 'phuong_thoi_an',
            division_type: 'phường',
            short_codename: 'thoi_an',
            parentId: 761,
          },
          {
            title: 'Phường Tân Chánh Hiệp',
            code: 26776,
            value: 'phuong_tan_chanh_hiep',
            division_type: 'phường',
            short_codename: 'tan_chanh_hiep',
            parentId: 761,
          },
          {
            title: 'Phường An Phú Đông',
            code: 26779,
            value: 'phuong_an_phu_dong',
            division_type: 'phường',
            short_codename: 'an_phu_dong',
            parentId: 761,
          },
          {
            title: 'Phường Tân Thới Hiệp',
            code: 26782,
            value: 'phuong_tan_thoi_hiep',
            division_type: 'phường',
            short_codename: 'tan_thoi_hiep',
            parentId: 761,
          },
          {
            title: 'Phường Trung Mỹ Tây',
            code: 26785,
            value: 'phuong_trung_my_tay',
            division_type: 'phường',
            short_codename: 'trung_my_tay',
            parentId: 761,
          },
          {
            title: 'Phường Tân Hưng Thuận',
            code: 26787,
            value: 'phuong_tan_hung_thuan',
            division_type: 'phường',
            short_codename: 'tan_hung_thuan',
            parentId: 761,
          },
          {
            title: 'Phường Đông Hưng Thuận',
            code: 26788,
            value: 'phuong_dong_hung_thuan',
            division_type: 'phường',
            short_codename: 'dong_hung_thuan',
            parentId: 761,
          },
          {
            title: 'Phường Tân Thới Nhất',
            code: 26791,
            value: 'phuong_tan_thoi_nhat',
            division_type: 'phường',
            short_codename: 'tan_thoi_nhat',
            parentId: 761,
          },
        ],
        parentId: 79,
      },
      {
        title: 'Quận Gò Vấp',
        code: 764,
        value: 'quan_go_vap',
        division_type: 'quận',
        short_codename: 'go_vap',
        children: [
          {
            title: 'Phường 15',
            code: 26869,
            value: 'phuong_15',
            division_type: 'phường',
            short_codename: 'phuong_15',
            parentId: 764,
          },
          {
            title: 'Phường 13',
            code: 26872,
            value: 'phuong_13',
            division_type: 'phường',
            short_codename: 'phuong_13',
            parentId: 764,
          },
          {
            title: 'Phường 17',
            code: 26875,
            value: 'phuong_17',
            division_type: 'phường',
            short_codename: 'phuong_17',
            parentId: 764,
          },
          {
            title: 'Phường 6',
            code: 26876,
            value: 'phuong_6',
            division_type: 'phường',
            short_codename: 'phuong_6',
            parentId: 764,
          },
          {
            title: 'Phường 16',
            code: 26878,
            value: 'phuong_16',
            division_type: 'phường',
            short_codename: 'phuong_16',
            parentId: 764,
          },
          {
            title: 'Phường 12',
            code: 26881,
            value: 'phuong_12',
            division_type: 'phường',
            short_codename: 'phuong_12',
            parentId: 764,
          },
          {
            title: 'Phường 14',
            code: 26882,
            value: 'phuong_14',
            division_type: 'phường',
            short_codename: 'phuong_14',
            parentId: 764,
          },
          {
            title: 'Phường 10',
            code: 26884,
            value: 'phuong_10',
            division_type: 'phường',
            short_codename: 'phuong_10',
            parentId: 764,
          },
          {
            title: 'Phường 05',
            code: 26887,
            value: 'phuong_05',
            division_type: 'phường',
            short_codename: 'phuong_05',
            parentId: 764,
          },
          {
            title: 'Phường 07',
            code: 26890,
            value: 'phuong_07',
            division_type: 'phường',
            short_codename: 'phuong_07',
            parentId: 764,
          },
          {
            title: 'Phường 04',
            code: 26893,
            value: 'phuong_04',
            division_type: 'phường',
            short_codename: 'phuong_04',
            parentId: 764,
          },
          {
            title: 'Phường 01',
            code: 26896,
            value: 'phuong_01',
            division_type: 'phường',
            short_codename: 'phuong_01',
            parentId: 764,
          },
          {
            title: 'Phường 9',
            code: 26897,
            value: 'phuong_9',
            division_type: 'phường',
            short_codename: 'phuong_9',
            parentId: 764,
          },
          {
            title: 'Phường 8',
            code: 26898,
            value: 'phuong_8',
            division_type: 'phường',
            short_codename: 'phuong_8',
            parentId: 764,
          },
          {
            title: 'Phường 11',
            code: 26899,
            value: 'phuong_11',
            division_type: 'phường',
            short_codename: 'phuong_11',
            parentId: 764,
          },
          {
            title: 'Phường 03',
            code: 26902,
            value: 'phuong_03',
            division_type: 'phường',
            short_codename: 'phuong_03',
            parentId: 764,
          },
        ],
        parentId: 79,
      },
      {
        title: 'Quận Bình Thạnh',
        code: 765,
        value: 'quan_binh_thanh',
        division_type: 'quận',
        short_codename: 'binh_thanh',
        children: [
          {
            title: 'Phường 13',
            code: 26905,
            value: 'phuong_13',
            division_type: 'phường',
            short_codename: 'phuong_13',
            parentId: 765,
          },
          {
            title: 'Phường 11',
            code: 26908,
            value: 'phuong_11',
            division_type: 'phường',
            short_codename: 'phuong_11',
            parentId: 765,
          },
          {
            title: 'Phường 27',
            code: 26911,
            value: 'phuong_27',
            division_type: 'phường',
            short_codename: 'phuong_27',
            parentId: 765,
          },
          {
            title: 'Phường 26',
            code: 26914,
            value: 'phuong_26',
            division_type: 'phường',
            short_codename: 'phuong_26',
            parentId: 765,
          },
          {
            title: 'Phường 12',
            code: 26917,
            value: 'phuong_12',
            division_type: 'phường',
            short_codename: 'phuong_12',
            parentId: 765,
          },
          {
            title: 'Phường 25',
            code: 26920,
            value: 'phuong_25',
            division_type: 'phường',
            short_codename: 'phuong_25',
            parentId: 765,
          },
          {
            title: 'Phường 05',
            code: 26923,
            value: 'phuong_05',
            division_type: 'phường',
            short_codename: 'phuong_05',
            parentId: 765,
          },
          {
            title: 'Phường 07',
            code: 26926,
            value: 'phuong_07',
            division_type: 'phường',
            short_codename: 'phuong_07',
            parentId: 765,
          },
          {
            title: 'Phường 24',
            code: 26929,
            value: 'phuong_24',
            division_type: 'phường',
            short_codename: 'phuong_24',
            parentId: 765,
          },
          {
            title: 'Phường 06',
            code: 26932,
            value: 'phuong_06',
            division_type: 'phường',
            short_codename: 'phuong_06',
            parentId: 765,
          },
          {
            title: 'Phường 14',
            code: 26935,
            value: 'phuong_14',
            division_type: 'phường',
            short_codename: 'phuong_14',
            parentId: 765,
          },
          {
            title: 'Phường 15',
            code: 26938,
            value: 'phuong_15',
            division_type: 'phường',
            short_codename: 'phuong_15',
            parentId: 765,
          },
          {
            title: 'Phường 02',
            code: 26941,
            value: 'phuong_02',
            division_type: 'phường',
            short_codename: 'phuong_02',
            parentId: 765,
          },
          {
            title: 'Phường 01',
            code: 26944,
            value: 'phuong_01',
            division_type: 'phường',
            short_codename: 'phuong_01',
            parentId: 765,
          },
          {
            title: 'Phường 03',
            code: 26947,
            value: 'phuong_03',
            division_type: 'phường',
            short_codename: 'phuong_03',
            parentId: 765,
          },
          {
            title: 'Phường 17',
            code: 26950,
            value: 'phuong_17',
            division_type: 'phường',
            short_codename: 'phuong_17',
            parentId: 765,
          },
          {
            title: 'Phường 21',
            code: 26953,
            value: 'phuong_21',
            division_type: 'phường',
            short_codename: 'phuong_21',
            parentId: 765,
          },
          {
            title: 'Phường 22',
            code: 26956,
            value: 'phuong_22',
            division_type: 'phường',
            short_codename: 'phuong_22',
            parentId: 765,
          },
          {
            title: 'Phường 19',
            code: 26959,
            value: 'phuong_19',
            division_type: 'phường',
            short_codename: 'phuong_19',
            parentId: 765,
          },
          {
            title: 'Phường 28',
            code: 26962,
            value: 'phuong_28',
            division_type: 'phường',
            short_codename: 'phuong_28',
            parentId: 765,
          },
        ],
        parentId: 79,
      },
      {
        title: 'Quận Tân Bình',
        code: 766,
        value: 'quan_tan_binh',
        division_type: 'quận',
        short_codename: 'tan_binh',
        children: [
          {
            title: 'Phường 02',
            code: 26965,
            value: 'phuong_02',
            division_type: 'phường',
            short_codename: 'phuong_02',
            parentId: 766,
          },
          {
            title: 'Phường 04',
            code: 26968,
            value: 'phuong_04',
            division_type: 'phường',
            short_codename: 'phuong_04',
            parentId: 766,
          },
          {
            title: 'Phường 12',
            code: 26971,
            value: 'phuong_12',
            division_type: 'phường',
            short_codename: 'phuong_12',
            parentId: 766,
          },
          {
            title: 'Phường 13',
            code: 26974,
            value: 'phuong_13',
            division_type: 'phường',
            short_codename: 'phuong_13',
            parentId: 766,
          },
          {
            title: 'Phường 01',
            code: 26977,
            value: 'phuong_01',
            division_type: 'phường',
            short_codename: 'phuong_01',
            parentId: 766,
          },
          {
            title: 'Phường 03',
            code: 26980,
            value: 'phuong_03',
            division_type: 'phường',
            short_codename: 'phuong_03',
            parentId: 766,
          },
          {
            title: 'Phường 11',
            code: 26983,
            value: 'phuong_11',
            division_type: 'phường',
            short_codename: 'phuong_11',
            parentId: 766,
          },
          {
            title: 'Phường 07',
            code: 26986,
            value: 'phuong_07',
            division_type: 'phường',
            short_codename: 'phuong_07',
            parentId: 766,
          },
          {
            title: 'Phường 05',
            code: 26989,
            value: 'phuong_05',
            division_type: 'phường',
            short_codename: 'phuong_05',
            parentId: 766,
          },
          {
            title: 'Phường 10',
            code: 26992,
            value: 'phuong_10',
            division_type: 'phường',
            short_codename: 'phuong_10',
            parentId: 766,
          },
          {
            title: 'Phường 06',
            code: 26995,
            value: 'phuong_06',
            division_type: 'phường',
            short_codename: 'phuong_06',
            parentId: 766,
          },
          {
            title: 'Phường 08',
            code: 26998,
            value: 'phuong_08',
            division_type: 'phường',
            short_codename: 'phuong_08',
            parentId: 766,
          },
          {
            title: 'Phường 09',
            code: 27001,
            value: 'phuong_09',
            division_type: 'phường',
            short_codename: 'phuong_09',
            parentId: 766,
          },
          {
            title: 'Phường 14',
            code: 27004,
            value: 'phuong_14',
            division_type: 'phường',
            short_codename: 'phuong_14',
            parentId: 766,
          },
          {
            title: 'Phường 15',
            code: 27007,
            value: 'phuong_15',
            division_type: 'phường',
            short_codename: 'phuong_15',
            parentId: 766,
          },
        ],
        parentId: 79,
      },
      {
        title: 'Quận Tân Phú',
        code: 767,
        value: 'quan_tan_phu',
        division_type: 'quận',
        short_codename: 'tan_phu',
        children: [
          {
            title: 'Phường Tân Sơn Nhì',
            code: 27010,
            value: 'phuong_tan_son_nhi',
            division_type: 'phường',
            short_codename: 'tan_son_nhi',
            parentId: 767,
          },
          {
            title: 'Phường Tây Thạnh',
            code: 27013,
            value: 'phuong_tay_thanh',
            division_type: 'phường',
            short_codename: 'tay_thanh',
            parentId: 767,
          },
          {
            title: 'Phường Sơn Kỳ',
            code: 27016,
            value: 'phuong_son_ky',
            division_type: 'phường',
            short_codename: 'son_ky',
            parentId: 767,
          },
          {
            title: 'Phường Tân Quý',
            code: 27019,
            value: 'phuong_tan_quy',
            division_type: 'phường',
            short_codename: 'tan_quy',
            parentId: 767,
          },
          {
            title: 'Phường Tân Thành',
            code: 27022,
            value: 'phuong_tan_thanh',
            division_type: 'phường',
            short_codename: 'tan_thanh',
            parentId: 767,
          },
          {
            title: 'Phường Phú Thọ Hòa',
            code: 27025,
            value: 'phuong_phu_tho_hoa',
            division_type: 'phường',
            short_codename: 'phu_tho_hoa',
            parentId: 767,
          },
          {
            title: 'Phường Phú Thạnh',
            code: 27028,
            value: 'phuong_phu_thanh',
            division_type: 'phường',
            short_codename: 'phu_thanh',
            parentId: 767,
          },
          {
            title: 'Phường Phú Trung',
            code: 27031,
            value: 'phuong_phu_trung',
            division_type: 'phường',
            short_codename: 'phu_trung',
            parentId: 767,
          },
          {
            title: 'Phường Hòa Thạnh',
            code: 27034,
            value: 'phuong_hoa_thanh',
            division_type: 'phường',
            short_codename: 'hoa_thanh',
            parentId: 767,
          },
          {
            title: 'Phường Hiệp Tân',
            code: 27037,
            value: 'phuong_hiep_tan',
            division_type: 'phường',
            short_codename: 'hiep_tan',
            parentId: 767,
          },
          {
            title: 'Phường Tân Thới Hòa',
            code: 27040,
            value: 'phuong_tan_thoi_hoa',
            division_type: 'phường',
            short_codename: 'tan_thoi_hoa',
            parentId: 767,
          },
        ],
        parentId: 79,
      },
      {
        title: 'Quận Phú Nhuận',
        code: 768,
        value: 'quan_phu_nhuan',
        division_type: 'quận',
        short_codename: 'phu_nhuan',
        children: [
          {
            title: 'Phường 04',
            code: 27043,
            value: 'phuong_04',
            division_type: 'phường',
            short_codename: 'phuong_04',
            parentId: 768,
          },
          {
            title: 'Phường 05',
            code: 27046,
            value: 'phuong_05',
            division_type: 'phường',
            short_codename: 'phuong_05',
            parentId: 768,
          },
          {
            title: 'Phường 09',
            code: 27049,
            value: 'phuong_09',
            division_type: 'phường',
            short_codename: 'phuong_09',
            parentId: 768,
          },
          {
            title: 'Phường 07',
            code: 27052,
            value: 'phuong_07',
            division_type: 'phường',
            short_codename: 'phuong_07',
            parentId: 768,
          },
          {
            title: 'Phường 03',
            code: 27055,
            value: 'phuong_03',
            division_type: 'phường',
            short_codename: 'phuong_03',
            parentId: 768,
          },
          {
            title: 'Phường 01',
            code: 27058,
            value: 'phuong_01',
            division_type: 'phường',
            short_codename: 'phuong_01',
            parentId: 768,
          },
          {
            title: 'Phường 02',
            code: 27061,
            value: 'phuong_02',
            division_type: 'phường',
            short_codename: 'phuong_02',
            parentId: 768,
          },
          {
            title: 'Phường 08',
            code: 27064,
            value: 'phuong_08',
            division_type: 'phường',
            short_codename: 'phuong_08',
            parentId: 768,
          },
          {
            title: 'Phường 15',
            code: 27067,
            value: 'phuong_15',
            division_type: 'phường',
            short_codename: 'phuong_15',
            parentId: 768,
          },
          {
            title: 'Phường 10',
            code: 27070,
            value: 'phuong_10',
            division_type: 'phường',
            short_codename: 'phuong_10',
            parentId: 768,
          },
          {
            title: 'Phường 11',
            code: 27073,
            value: 'phuong_11',
            division_type: 'phường',
            short_codename: 'phuong_11',
            parentId: 768,
          },
          {
            title: 'Phường 17',
            code: 27076,
            value: 'phuong_17',
            division_type: 'phường',
            short_codename: 'phuong_17',
            parentId: 768,
          },
          {
            title: 'Phường 13',
            code: 27085,
            value: 'phuong_13',
            division_type: 'phường',
            short_codename: 'phuong_13',
            parentId: 768,
          },
        ],
        parentId: 79,
      },
      {
        title: 'Thành phố Thủ Đức',
        code: 769,
        value: 'thanh_pho_thu_duc',
        division_type: 'thành phố',
        short_codename: 'thu_duc',
        children: [
          {
            title: 'Phường Linh Xuân',
            code: 26794,
            value: 'phuong_linh_xuan',
            division_type: 'phường',
            short_codename: 'linh_xuan',
            parentId: 769,
          },
          {
            title: 'Phường Bình Chiểu',
            code: 26797,
            value: 'phuong_binh_chieu',
            division_type: 'phường',
            short_codename: 'binh_chieu',
            parentId: 769,
          },
          {
            title: 'Phường Linh Trung',
            code: 26800,
            value: 'phuong_linh_trung',
            division_type: 'phường',
            short_codename: 'linh_trung',
            parentId: 769,
          },
          {
            title: 'Phường Tam Bình',
            code: 26803,
            value: 'phuong_tam_binh',
            division_type: 'phường',
            short_codename: 'tam_binh',
            parentId: 769,
          },
          {
            title: 'Phường Tam Phú',
            code: 26806,
            value: 'phuong_tam_phu',
            division_type: 'phường',
            short_codename: 'tam_phu',
            parentId: 769,
          },
          {
            title: 'Phường Hiệp Bình Phước',
            code: 26809,
            value: 'phuong_hiep_binh_phuoc',
            division_type: 'phường',
            short_codename: 'hiep_binh_phuoc',
            parentId: 769,
          },
          {
            title: 'Phường Hiệp Bình Chánh',
            code: 26812,
            value: 'phuong_hiep_binh_chanh',
            division_type: 'phường',
            short_codename: 'hiep_binh_chanh',
            parentId: 769,
          },
          {
            title: 'Phường Linh Chiểu',
            code: 26815,
            value: 'phuong_linh_chieu',
            division_type: 'phường',
            short_codename: 'linh_chieu',
            parentId: 769,
          },
          {
            title: 'Phường Linh Tây',
            code: 26818,
            value: 'phuong_linh_tay',
            division_type: 'phường',
            short_codename: 'linh_tay',
            parentId: 769,
          },
          {
            title: 'Phường Linh Đông',
            code: 26821,
            value: 'phuong_linh_dong',
            division_type: 'phường',
            short_codename: 'linh_dong',
            parentId: 769,
          },
          {
            title: 'Phường Bình Thọ',
            code: 26824,
            value: 'phuong_binh_tho',
            division_type: 'phường',
            short_codename: 'binh_tho',
            parentId: 769,
          },
          {
            title: 'Phường Trường Thọ',
            code: 26827,
            value: 'phuong_truong_tho',
            division_type: 'phường',
            short_codename: 'truong_tho',
            parentId: 769,
          },
          {
            title: 'Phường Long Bình',
            code: 26830,
            value: 'phuong_long_binh',
            division_type: 'phường',
            short_codename: 'long_binh',
            parentId: 769,
          },
          {
            title: 'Phường Long Thạnh Mỹ',
            code: 26833,
            value: 'phuong_long_thanh_my',
            division_type: 'phường',
            short_codename: 'long_thanh_my',
            parentId: 769,
          },
          {
            title: 'Phường Tân Phú',
            code: 26836,
            value: 'phuong_tan_phu',
            division_type: 'phường',
            short_codename: 'tan_phu',
            parentId: 769,
          },
          {
            title: 'Phường Hiệp Phú',
            code: 26839,
            value: 'phuong_hiep_phu',
            division_type: 'phường',
            short_codename: 'hiep_phu',
            parentId: 769,
          },
          {
            title: 'Phường Tăng Nhơn Phú A',
            code: 26842,
            value: 'phuong_tang_nhon_phu_a',
            division_type: 'phường',
            short_codename: 'tang_nhon_phu_a',
            parentId: 769,
          },
          {
            title: 'Phường Tăng Nhơn Phú B',
            code: 26845,
            value: 'phuong_tang_nhon_phu_b',
            division_type: 'phường',
            short_codename: 'tang_nhon_phu_b',
            parentId: 769,
          },
          {
            title: 'Phường Phước Long B',
            code: 26848,
            value: 'phuong_phuoc_long_b',
            division_type: 'phường',
            short_codename: 'phuoc_long_b',
            parentId: 769,
          },
          {
            title: 'Phường Phước Long A',
            code: 26851,
            value: 'phuong_phuoc_long_a',
            division_type: 'phường',
            short_codename: 'phuoc_long_a',
            parentId: 769,
          },
          {
            title: 'Phường Trường Thạnh',
            code: 26854,
            value: 'phuong_truong_thanh',
            division_type: 'phường',
            short_codename: 'truong_thanh',
            parentId: 769,
          },
          {
            title: 'Phường Long Phước',
            code: 26857,
            value: 'phuong_long_phuoc',
            division_type: 'phường',
            short_codename: 'long_phuoc',
            parentId: 769,
          },
          {
            title: 'Phường Long Trường',
            code: 26860,
            value: 'phuong_long_truong',
            division_type: 'phường',
            short_codename: 'long_truong',
            parentId: 769,
          },
          {
            title: 'Phường Phước Bình',
            code: 26863,
            value: 'phuong_phuoc_binh',
            division_type: 'phường',
            short_codename: 'phuoc_binh',
            parentId: 769,
          },
          {
            title: 'Phường Phú Hữu',
            code: 26866,
            value: 'phuong_phu_huu',
            division_type: 'phường',
            short_codename: 'phu_huu',
            parentId: 769,
          },
          {
            title: 'Phường Thảo Điền',
            code: 27088,
            value: 'phuong_thao_dien',
            division_type: 'phường',
            short_codename: 'thao_dien',
            parentId: 769,
          },
          {
            title: 'Phường An Phú',
            code: 27091,
            value: 'phuong_an_phu',
            division_type: 'phường',
            short_codename: 'an_phu',
            parentId: 769,
          },
          {
            title: 'Phường An Khánh',
            code: 27094,
            value: 'phuong_an_khanh',
            division_type: 'phường',
            short_codename: 'an_khanh',
            parentId: 769,
          },
          {
            title: 'Phường Bình Trưng Đông',
            code: 27097,
            value: 'phuong_binh_trung_dong',
            division_type: 'phường',
            short_codename: 'binh_trung_dong',
            parentId: 769,
          },
          {
            title: 'Phường Bình Trưng Tây',
            code: 27100,
            value: 'phuong_binh_trung_tay',
            division_type: 'phường',
            short_codename: 'binh_trung_tay',
            parentId: 769,
          },
          {
            title: 'Phường Cát Lái',
            code: 27109,
            value: 'phuong_cat_lai',
            division_type: 'phường',
            short_codename: 'cat_lai',
            parentId: 769,
          },
          {
            title: 'Phường Thạnh Mỹ Lợi',
            code: 27112,
            value: 'phuong_thanh_my_loi',
            division_type: 'phường',
            short_codename: 'thanh_my_loi',
            parentId: 769,
          },
          {
            title: 'Phường An Lợi Đông',
            code: 27115,
            value: 'phuong_an_loi_dong',
            division_type: 'phường',
            short_codename: 'an_loi_dong',
            parentId: 769,
          },
          {
            title: 'Phường Thủ Thiêm',
            code: 27118,
            value: 'phuong_thu_thiem',
            division_type: 'phường',
            short_codename: 'thu_thiem',
            parentId: 769,
          },
        ],
        parentId: 79,
      },
      {
        title: 'Quận 3',
        code: 770,
        value: 'quan_3',
        division_type: 'quận',
        short_codename: 'quan_3',
        children: [
          {
            title: 'Phường 14',
            code: 27127,
            value: 'phuong_14',
            division_type: 'phường',
            short_codename: 'phuong_14',
            parentId: 770,
          },
          {
            title: 'Phường 12',
            code: 27130,
            value: 'phuong_12',
            division_type: 'phường',
            short_codename: 'phuong_12',
            parentId: 770,
          },
          {
            title: 'Phường 11',
            code: 27133,
            value: 'phuong_11',
            division_type: 'phường',
            short_codename: 'phuong_11',
            parentId: 770,
          },
          {
            title: 'Phường 13',
            code: 27136,
            value: 'phuong_13',
            division_type: 'phường',
            short_codename: 'phuong_13',
            parentId: 770,
          },
          {
            title: 'Phường Võ Thị Sáu',
            code: 27139,
            value: 'phuong_vo_thi_sau',
            division_type: 'phường',
            short_codename: 'vo_thi_sau',
            parentId: 770,
          },
          {
            title: 'Phường 09',
            code: 27142,
            value: 'phuong_09',
            division_type: 'phường',
            short_codename: 'phuong_09',
            parentId: 770,
          },
          {
            title: 'Phường 10',
            code: 27145,
            value: 'phuong_10',
            division_type: 'phường',
            short_codename: 'phuong_10',
            parentId: 770,
          },
          {
            title: 'Phường 04',
            code: 27148,
            value: 'phuong_04',
            division_type: 'phường',
            short_codename: 'phuong_04',
            parentId: 770,
          },
          {
            title: 'Phường 05',
            code: 27151,
            value: 'phuong_05',
            division_type: 'phường',
            short_codename: 'phuong_05',
            parentId: 770,
          },
          {
            title: 'Phường 03',
            code: 27154,
            value: 'phuong_03',
            division_type: 'phường',
            short_codename: 'phuong_03',
            parentId: 770,
          },
          {
            title: 'Phường 02',
            code: 27157,
            value: 'phuong_02',
            division_type: 'phường',
            short_codename: 'phuong_02',
            parentId: 770,
          },
          {
            title: 'Phường 01',
            code: 27160,
            value: 'phuong_01',
            division_type: 'phường',
            short_codename: 'phuong_01',
            parentId: 770,
          },
        ],
        parentId: 79,
      },
      {
        title: 'Quận 10',
        code: 771,
        value: 'quan_10',
        division_type: 'quận',
        short_codename: 'quan_10',
        children: [
          {
            title: 'Phường 15',
            code: 27163,
            value: 'phuong_15',
            division_type: 'phường',
            short_codename: 'phuong_15',
            parentId: 771,
          },
          {
            title: 'Phường 13',
            code: 27166,
            value: 'phuong_13',
            division_type: 'phường',
            short_codename: 'phuong_13',
            parentId: 771,
          },
          {
            title: 'Phường 14',
            code: 27169,
            value: 'phuong_14',
            division_type: 'phường',
            short_codename: 'phuong_14',
            parentId: 771,
          },
          {
            title: 'Phường 12',
            code: 27172,
            value: 'phuong_12',
            division_type: 'phường',
            short_codename: 'phuong_12',
            parentId: 771,
          },
          {
            title: 'Phường 11',
            code: 27175,
            value: 'phuong_11',
            division_type: 'phường',
            short_codename: 'phuong_11',
            parentId: 771,
          },
          {
            title: 'Phường 10',
            code: 27178,
            value: 'phuong_10',
            division_type: 'phường',
            short_codename: 'phuong_10',
            parentId: 771,
          },
          {
            title: 'Phường 09',
            code: 27181,
            value: 'phuong_09',
            division_type: 'phường',
            short_codename: 'phuong_09',
            parentId: 771,
          },
          {
            title: 'Phường 01',
            code: 27184,
            value: 'phuong_01',
            division_type: 'phường',
            short_codename: 'phuong_01',
            parentId: 771,
          },
          {
            title: 'Phường 08',
            code: 27187,
            value: 'phuong_08',
            division_type: 'phường',
            short_codename: 'phuong_08',
            parentId: 771,
          },
          {
            title: 'Phường 02',
            code: 27190,
            value: 'phuong_02',
            division_type: 'phường',
            short_codename: 'phuong_02',
            parentId: 771,
          },
          {
            title: 'Phường 04',
            code: 27193,
            value: 'phuong_04',
            division_type: 'phường',
            short_codename: 'phuong_04',
            parentId: 771,
          },
          {
            title: 'Phường 07',
            code: 27196,
            value: 'phuong_07',
            division_type: 'phường',
            short_codename: 'phuong_07',
            parentId: 771,
          },
          {
            title: 'Phường 05',
            code: 27199,
            value: 'phuong_05',
            division_type: 'phường',
            short_codename: 'phuong_05',
            parentId: 771,
          },
          {
            title: 'Phường 06',
            code: 27202,
            value: 'phuong_06',
            division_type: 'phường',
            short_codename: 'phuong_06',
            parentId: 771,
          },
        ],
        parentId: 79,
      },
      {
        title: 'Quận 11',
        code: 772,
        value: 'quan_11',
        division_type: 'quận',
        short_codename: 'quan_11',
        children: [
          {
            title: 'Phường 15',
            code: 27208,
            value: 'phuong_15',
            division_type: 'phường',
            short_codename: 'phuong_15',
            parentId: 772,
          },
          {
            title: 'Phường 05',
            code: 27211,
            value: 'phuong_05',
            division_type: 'phường',
            short_codename: 'phuong_05',
            parentId: 772,
          },
          {
            title: 'Phường 14',
            code: 27214,
            value: 'phuong_14',
            division_type: 'phường',
            short_codename: 'phuong_14',
            parentId: 772,
          },
          {
            title: 'Phường 11',
            code: 27217,
            value: 'phuong_11',
            division_type: 'phường',
            short_codename: 'phuong_11',
            parentId: 772,
          },
          {
            title: 'Phường 03',
            code: 27220,
            value: 'phuong_03',
            division_type: 'phường',
            short_codename: 'phuong_03',
            parentId: 772,
          },
          {
            title: 'Phường 10',
            code: 27223,
            value: 'phuong_10',
            division_type: 'phường',
            short_codename: 'phuong_10',
            parentId: 772,
          },
          {
            title: 'Phường 13',
            code: 27226,
            value: 'phuong_13',
            division_type: 'phường',
            short_codename: 'phuong_13',
            parentId: 772,
          },
          {
            title: 'Phường 08',
            code: 27229,
            value: 'phuong_08',
            division_type: 'phường',
            short_codename: 'phuong_08',
            parentId: 772,
          },
          {
            title: 'Phường 09',
            code: 27232,
            value: 'phuong_09',
            division_type: 'phường',
            short_codename: 'phuong_09',
            parentId: 772,
          },
          {
            title: 'Phường 12',
            code: 27235,
            value: 'phuong_12',
            division_type: 'phường',
            short_codename: 'phuong_12',
            parentId: 772,
          },
          {
            title: 'Phường 07',
            code: 27238,
            value: 'phuong_07',
            division_type: 'phường',
            short_codename: 'phuong_07',
            parentId: 772,
          },
          {
            title: 'Phường 06',
            code: 27241,
            value: 'phuong_06',
            division_type: 'phường',
            short_codename: 'phuong_06',
            parentId: 772,
          },
          {
            title: 'Phường 04',
            code: 27244,
            value: 'phuong_04',
            division_type: 'phường',
            short_codename: 'phuong_04',
            parentId: 772,
          },
          {
            title: 'Phường 01',
            code: 27247,
            value: 'phuong_01',
            division_type: 'phường',
            short_codename: 'phuong_01',
            parentId: 772,
          },
          {
            title: 'Phường 02',
            code: 27250,
            value: 'phuong_02',
            division_type: 'phường',
            short_codename: 'phuong_02',
            parentId: 772,
          },
          {
            title: 'Phường 16',
            code: 27253,
            value: 'phuong_16',
            division_type: 'phường',
            short_codename: 'phuong_16',
            parentId: 772,
          },
        ],
        parentId: 79,
      },
      {
        title: 'Quận 4',
        code: 773,
        value: 'quan_4',
        division_type: 'quận',
        short_codename: 'quan_4',
        children: [
          {
            title: 'Phường 13',
            code: 27259,
            value: 'phuong_13',
            division_type: 'phường',
            short_codename: 'phuong_13',
            parentId: 773,
          },
          {
            title: 'Phường 09',
            code: 27262,
            value: 'phuong_09',
            division_type: 'phường',
            short_codename: 'phuong_09',
            parentId: 773,
          },
          {
            title: 'Phường 06',
            code: 27265,
            value: 'phuong_06',
            division_type: 'phường',
            short_codename: 'phuong_06',
            parentId: 773,
          },
          {
            title: 'Phường 08',
            code: 27268,
            value: 'phuong_08',
            division_type: 'phường',
            short_codename: 'phuong_08',
            parentId: 773,
          },
          {
            title: 'Phường 10',
            code: 27271,
            value: 'phuong_10',
            division_type: 'phường',
            short_codename: 'phuong_10',
            parentId: 773,
          },
          {
            title: 'Phường 18',
            code: 27277,
            value: 'phuong_18',
            division_type: 'phường',
            short_codename: 'phuong_18',
            parentId: 773,
          },
          {
            title: 'Phường 14',
            code: 27280,
            value: 'phuong_14',
            division_type: 'phường',
            short_codename: 'phuong_14',
            parentId: 773,
          },
          {
            title: 'Phường 04',
            code: 27283,
            value: 'phuong_04',
            division_type: 'phường',
            short_codename: 'phuong_04',
            parentId: 773,
          },
          {
            title: 'Phường 03',
            code: 27286,
            value: 'phuong_03',
            division_type: 'phường',
            short_codename: 'phuong_03',
            parentId: 773,
          },
          {
            title: 'Phường 16',
            code: 27289,
            value: 'phuong_16',
            division_type: 'phường',
            short_codename: 'phuong_16',
            parentId: 773,
          },
          {
            title: 'Phường 02',
            code: 27292,
            value: 'phuong_02',
            division_type: 'phường',
            short_codename: 'phuong_02',
            parentId: 773,
          },
          {
            title: 'Phường 15',
            code: 27295,
            value: 'phuong_15',
            division_type: 'phường',
            short_codename: 'phuong_15',
            parentId: 773,
          },
          {
            title: 'Phường 01',
            code: 27298,
            value: 'phuong_01',
            division_type: 'phường',
            short_codename: 'phuong_01',
            parentId: 773,
          },
        ],
        parentId: 79,
      },
      {
        title: 'Quận 5',
        code: 774,
        value: 'quan_5',
        division_type: 'quận',
        short_codename: 'quan_5',
        children: [
          {
            title: 'Phường 04',
            code: 27301,
            value: 'phuong_04',
            division_type: 'phường',
            short_codename: 'phuong_04',
            parentId: 774,
          },
          {
            title: 'Phường 09',
            code: 27304,
            value: 'phuong_09',
            division_type: 'phường',
            short_codename: 'phuong_09',
            parentId: 774,
          },
          {
            title: 'Phường 03',
            code: 27307,
            value: 'phuong_03',
            division_type: 'phường',
            short_codename: 'phuong_03',
            parentId: 774,
          },
          {
            title: 'Phường 12',
            code: 27310,
            value: 'phuong_12',
            division_type: 'phường',
            short_codename: 'phuong_12',
            parentId: 774,
          },
          {
            title: 'Phường 02',
            code: 27313,
            value: 'phuong_02',
            division_type: 'phường',
            short_codename: 'phuong_02',
            parentId: 774,
          },
          {
            title: 'Phường 08',
            code: 27316,
            value: 'phuong_08',
            division_type: 'phường',
            short_codename: 'phuong_08',
            parentId: 774,
          },
          {
            title: 'Phường 07',
            code: 27322,
            value: 'phuong_07',
            division_type: 'phường',
            short_codename: 'phuong_07',
            parentId: 774,
          },
          {
            title: 'Phường 01',
            code: 27325,
            value: 'phuong_01',
            division_type: 'phường',
            short_codename: 'phuong_01',
            parentId: 774,
          },
          {
            title: 'Phường 11',
            code: 27328,
            value: 'phuong_11',
            division_type: 'phường',
            short_codename: 'phuong_11',
            parentId: 774,
          },
          {
            title: 'Phường 14',
            code: 27331,
            value: 'phuong_14',
            division_type: 'phường',
            short_codename: 'phuong_14',
            parentId: 774,
          },
          {
            title: 'Phường 05',
            code: 27334,
            value: 'phuong_05',
            division_type: 'phường',
            short_codename: 'phuong_05',
            parentId: 774,
          },
          {
            title: 'Phường 06',
            code: 27337,
            value: 'phuong_06',
            division_type: 'phường',
            short_codename: 'phuong_06',
            parentId: 774,
          },
          {
            title: 'Phường 10',
            code: 27340,
            value: 'phuong_10',
            division_type: 'phường',
            short_codename: 'phuong_10',
            parentId: 774,
          },
          {
            title: 'Phường 13',
            code: 27343,
            value: 'phuong_13',
            division_type: 'phường',
            short_codename: 'phuong_13',
            parentId: 774,
          },
        ],
        parentId: 79,
      },
      {
        title: 'Quận 6',
        code: 775,
        value: 'quan_6',
        division_type: 'quận',
        short_codename: 'quan_6',
        children: [
          {
            title: 'Phường 14',
            code: 27346,
            value: 'phuong_14',
            division_type: 'phường',
            short_codename: 'phuong_14',
            parentId: 775,
          },
          {
            title: 'Phường 13',
            code: 27349,
            value: 'phuong_13',
            division_type: 'phường',
            short_codename: 'phuong_13',
            parentId: 775,
          },
          {
            title: 'Phường 09',
            code: 27352,
            value: 'phuong_09',
            division_type: 'phường',
            short_codename: 'phuong_09',
            parentId: 775,
          },
          {
            title: 'Phường 06',
            code: 27355,
            value: 'phuong_06',
            division_type: 'phường',
            short_codename: 'phuong_06',
            parentId: 775,
          },
          {
            title: 'Phường 12',
            code: 27358,
            value: 'phuong_12',
            division_type: 'phường',
            short_codename: 'phuong_12',
            parentId: 775,
          },
          {
            title: 'Phường 05',
            code: 27361,
            value: 'phuong_05',
            division_type: 'phường',
            short_codename: 'phuong_05',
            parentId: 775,
          },
          {
            title: 'Phường 11',
            code: 27364,
            value: 'phuong_11',
            division_type: 'phường',
            short_codename: 'phuong_11',
            parentId: 775,
          },
          {
            title: 'Phường 02',
            code: 27367,
            value: 'phuong_02',
            division_type: 'phường',
            short_codename: 'phuong_02',
            parentId: 775,
          },
          {
            title: 'Phường 01',
            code: 27370,
            value: 'phuong_01',
            division_type: 'phường',
            short_codename: 'phuong_01',
            parentId: 775,
          },
          {
            title: 'Phường 04',
            code: 27373,
            value: 'phuong_04',
            division_type: 'phường',
            short_codename: 'phuong_04',
            parentId: 775,
          },
          {
            title: 'Phường 08',
            code: 27376,
            value: 'phuong_08',
            division_type: 'phường',
            short_codename: 'phuong_08',
            parentId: 775,
          },
          {
            title: 'Phường 03',
            code: 27379,
            value: 'phuong_03',
            division_type: 'phường',
            short_codename: 'phuong_03',
            parentId: 775,
          },
          {
            title: 'Phường 07',
            code: 27382,
            value: 'phuong_07',
            division_type: 'phường',
            short_codename: 'phuong_07',
            parentId: 775,
          },
          {
            title: 'Phường 10',
            code: 27385,
            value: 'phuong_10',
            division_type: 'phường',
            short_codename: 'phuong_10',
            parentId: 775,
          },
        ],
        parentId: 79,
      },
      {
        title: 'Quận 8',
        code: 776,
        value: 'quan_8',
        division_type: 'quận',
        short_codename: 'quan_8',
        children: [
          {
            title: 'Phường 08',
            code: 27388,
            value: 'phuong_08',
            division_type: 'phường',
            short_codename: 'phuong_08',
            parentId: 776,
          },
          {
            title: 'Phường 02',
            code: 27391,
            value: 'phuong_02',
            division_type: 'phường',
            short_codename: 'phuong_02',
            parentId: 776,
          },
          {
            title: 'Phường 01',
            code: 27394,
            value: 'phuong_01',
            division_type: 'phường',
            short_codename: 'phuong_01',
            parentId: 776,
          },
          {
            title: 'Phường 03',
            code: 27397,
            value: 'phuong_03',
            division_type: 'phường',
            short_codename: 'phuong_03',
            parentId: 776,
          },
          {
            title: 'Phường 11',
            code: 27400,
            value: 'phuong_11',
            division_type: 'phường',
            short_codename: 'phuong_11',
            parentId: 776,
          },
          {
            title: 'Phường 09',
            code: 27403,
            value: 'phuong_09',
            division_type: 'phường',
            short_codename: 'phuong_09',
            parentId: 776,
          },
          {
            title: 'Phường 10',
            code: 27406,
            value: 'phuong_10',
            division_type: 'phường',
            short_codename: 'phuong_10',
            parentId: 776,
          },
          {
            title: 'Phường 04',
            code: 27409,
            value: 'phuong_04',
            division_type: 'phường',
            short_codename: 'phuong_04',
            parentId: 776,
          },
          {
            title: 'Phường 13',
            code: 27412,
            value: 'phuong_13',
            division_type: 'phường',
            short_codename: 'phuong_13',
            parentId: 776,
          },
          {
            title: 'Phường 12',
            code: 27415,
            value: 'phuong_12',
            division_type: 'phường',
            short_codename: 'phuong_12',
            parentId: 776,
          },
          {
            title: 'Phường 05',
            code: 27418,
            value: 'phuong_05',
            division_type: 'phường',
            short_codename: 'phuong_05',
            parentId: 776,
          },
          {
            title: 'Phường 14',
            code: 27421,
            value: 'phuong_14',
            division_type: 'phường',
            short_codename: 'phuong_14',
            parentId: 776,
          },
          {
            title: 'Phường 06',
            code: 27424,
            value: 'phuong_06',
            division_type: 'phường',
            short_codename: 'phuong_06',
            parentId: 776,
          },
          {
            title: 'Phường 15',
            code: 27427,
            value: 'phuong_15',
            division_type: 'phường',
            short_codename: 'phuong_15',
            parentId: 776,
          },
          {
            title: 'Phường 16',
            code: 27430,
            value: 'phuong_16',
            division_type: 'phường',
            short_codename: 'phuong_16',
            parentId: 776,
          },
          {
            title: 'Phường 07',
            code: 27433,
            value: 'phuong_07',
            division_type: 'phường',
            short_codename: 'phuong_07',
            parentId: 776,
          },
        ],
        parentId: 79,
      },
      {
        title: 'Quận Bình Tân',
        code: 777,
        value: 'quan_binh_tan',
        division_type: 'quận',
        short_codename: 'binh_tan',
        children: [
          {
            title: 'Phường Bình Hưng Hòa',
            code: 27436,
            value: 'phuong_binh_hung_hoa',
            division_type: 'phường',
            short_codename: 'binh_hung_hoa',
            parentId: 777,
          },
          {
            title: 'Phường Bình Hưng Hoà A',
            code: 27439,
            value: 'phuong_binh_hung_hoa_a',
            division_type: 'phường',
            short_codename: 'binh_hung_hoa_a',
            parentId: 777,
          },
          {
            title: 'Phường Bình Hưng Hoà B',
            code: 27442,
            value: 'phuong_binh_hung_hoa_b',
            division_type: 'phường',
            short_codename: 'binh_hung_hoa_b',
            parentId: 777,
          },
          {
            title: 'Phường Bình Trị Đông',
            code: 27445,
            value: 'phuong_binh_tri_dong',
            division_type: 'phường',
            short_codename: 'binh_tri_dong',
            parentId: 777,
          },
          {
            title: 'Phường Bình Trị Đông A',
            code: 27448,
            value: 'phuong_binh_tri_dong_a',
            division_type: 'phường',
            short_codename: 'binh_tri_dong_a',
            parentId: 777,
          },
          {
            title: 'Phường Bình Trị Đông B',
            code: 27451,
            value: 'phuong_binh_tri_dong_b',
            division_type: 'phường',
            short_codename: 'binh_tri_dong_b',
            parentId: 777,
          },
          {
            title: 'Phường Tân Tạo',
            code: 27454,
            value: 'phuong_tan_tao',
            division_type: 'phường',
            short_codename: 'tan_tao',
            parentId: 777,
          },
          {
            title: 'Phường Tân Tạo A',
            code: 27457,
            value: 'phuong_tan_tao_a',
            division_type: 'phường',
            short_codename: 'tan_tao_a',
            parentId: 777,
          },
          {
            title: 'Phường An Lạc',
            code: 27460,
            value: 'phuong_an_lac',
            division_type: 'phường',
            short_codename: 'an_lac',
            parentId: 777,
          },
          {
            title: 'Phường An Lạc A',
            code: 27463,
            value: 'phuong_an_lac_a',
            division_type: 'phường',
            short_codename: 'an_lac_a',
            parentId: 777,
          },
        ],
        parentId: 79,
      },
      {
        title: 'Quận 7',
        code: 778,
        value: 'quan_7',
        division_type: 'quận',
        short_codename: 'quan_7',
        children: [
          {
            title: 'Phường Tân Thuận Đông',
            code: 27466,
            value: 'phuong_tan_thuan_dong',
            division_type: 'phường',
            short_codename: 'tan_thuan_dong',
            parentId: 778,
          },
          {
            title: 'Phường Tân Thuận Tây',
            code: 27469,
            value: 'phuong_tan_thuan_tay',
            division_type: 'phường',
            short_codename: 'tan_thuan_tay',
            parentId: 778,
          },
          {
            title: 'Phường Tân Kiểng',
            code: 27472,
            value: 'phuong_tan_kieng',
            division_type: 'phường',
            short_codename: 'tan_kieng',
            parentId: 778,
          },
          {
            title: 'Phường Tân Hưng',
            code: 27475,
            value: 'phuong_tan_hung',
            division_type: 'phường',
            short_codename: 'tan_hung',
            parentId: 778,
          },
          {
            title: 'Phường Bình Thuận',
            code: 27478,
            value: 'phuong_binh_thuan',
            division_type: 'phường',
            short_codename: 'binh_thuan',
            parentId: 778,
          },
          {
            title: 'Phường Tân Quy',
            code: 27481,
            value: 'phuong_tan_quy',
            division_type: 'phường',
            short_codename: 'tan_quy',
            parentId: 778,
          },
          {
            title: 'Phường Phú Thuận',
            code: 27484,
            value: 'phuong_phu_thuan',
            division_type: 'phường',
            short_codename: 'phu_thuan',
            parentId: 778,
          },
          {
            title: 'Phường Tân Phú',
            code: 27487,
            value: 'phuong_tan_phu',
            division_type: 'phường',
            short_codename: 'tan_phu',
            parentId: 778,
          },
          {
            title: 'Phường Tân Phong',
            code: 27490,
            value: 'phuong_tan_phong',
            division_type: 'phường',
            short_codename: 'tan_phong',
            parentId: 778,
          },
          {
            title: 'Phường Phú Mỹ',
            code: 27493,
            value: 'phuong_phu_my',
            division_type: 'phường',
            short_codename: 'phu_my',
            parentId: 778,
          },
        ],
        parentId: 79,
      },
      {
        title: 'Huyện Củ Chi',
        code: 783,
        value: 'huyen_cu_chi',
        division_type: 'huyện',
        short_codename: 'cu_chi',
        children: [
          {
            title: 'Thị trấn Củ Chi',
            code: 27496,
            value: 'thi_tran_cu_chi',
            division_type: 'thị trấn',
            short_codename: 'cu_chi',
            parentId: 783,
          },
          {
            title: 'Xã Phú Mỹ Hưng',
            code: 27499,
            value: 'xa_phu_my_hung',
            division_type: 'xã',
            short_codename: 'phu_my_hung',
            parentId: 783,
          },
          {
            title: 'Xã An Phú',
            code: 27502,
            value: 'xa_an_phu',
            division_type: 'xã',
            short_codename: 'an_phu',
            parentId: 783,
          },
          {
            title: 'Xã Trung Lập Thượng',
            code: 27505,
            value: 'xa_trung_lap_thuong',
            division_type: 'xã',
            short_codename: 'trung_lap_thuong',
            parentId: 783,
          },
          {
            title: 'Xã An Nhơn Tây',
            code: 27508,
            value: 'xa_an_nhon_tay',
            division_type: 'xã',
            short_codename: 'an_nhon_tay',
            parentId: 783,
          },
          {
            title: 'Xã Nhuận Đức',
            code: 27511,
            value: 'xa_nhuan_duc',
            division_type: 'xã',
            short_codename: 'nhuan_duc',
            parentId: 783,
          },
          {
            title: 'Xã Phạm Văn Cội',
            code: 27514,
            value: 'xa_pham_van_coi',
            division_type: 'xã',
            short_codename: 'pham_van_coi',
            parentId: 783,
          },
          {
            title: 'Xã Phú Hòa Đông',
            code: 27517,
            value: 'xa_phu_hoa_dong',
            division_type: 'xã',
            short_codename: 'phu_hoa_dong',
            parentId: 783,
          },
          {
            title: 'Xã Trung Lập Hạ',
            code: 27520,
            value: 'xa_trung_lap_ha',
            division_type: 'xã',
            short_codename: 'trung_lap_ha',
            parentId: 783,
          },
          {
            title: 'Xã Trung An',
            code: 27523,
            value: 'xa_trung_an',
            division_type: 'xã',
            short_codename: 'trung_an',
            parentId: 783,
          },
          {
            title: 'Xã Phước Thạnh',
            code: 27526,
            value: 'xa_phuoc_thanh',
            division_type: 'xã',
            short_codename: 'phuoc_thanh',
            parentId: 783,
          },
          {
            title: 'Xã Phước Hiệp',
            code: 27529,
            value: 'xa_phuoc_hiep',
            division_type: 'xã',
            short_codename: 'phuoc_hiep',
            parentId: 783,
          },
          {
            title: 'Xã Tân An Hội',
            code: 27532,
            value: 'xa_tan_an_hoi',
            division_type: 'xã',
            short_codename: 'tan_an_hoi',
            parentId: 783,
          },
          {
            title: 'Xã Phước Vĩnh An',
            code: 27535,
            value: 'xa_phuoc_vinh_an',
            division_type: 'xã',
            short_codename: 'phuoc_vinh_an',
            parentId: 783,
          },
          {
            title: 'Xã Thái Mỹ',
            code: 27538,
            value: 'xa_thai_my',
            division_type: 'xã',
            short_codename: 'thai_my',
            parentId: 783,
          },
          {
            title: 'Xã Tân Thạnh Tây',
            code: 27541,
            value: 'xa_tan_thanh_tay',
            division_type: 'xã',
            short_codename: 'tan_thanh_tay',
            parentId: 783,
          },
          {
            title: 'Xã Hòa Phú',
            code: 27544,
            value: 'xa_hoa_phu',
            division_type: 'xã',
            short_codename: 'hoa_phu',
            parentId: 783,
          },
          {
            title: 'Xã Tân Thạnh Đông',
            code: 27547,
            value: 'xa_tan_thanh_dong',
            division_type: 'xã',
            short_codename: 'tan_thanh_dong',
            parentId: 783,
          },
          {
            title: 'Xã Bình Mỹ',
            code: 27550,
            value: 'xa_binh_my',
            division_type: 'xã',
            short_codename: 'binh_my',
            parentId: 783,
          },
          {
            title: 'Xã Tân Phú Trung',
            code: 27553,
            value: 'xa_tan_phu_trung',
            division_type: 'xã',
            short_codename: 'tan_phu_trung',
            parentId: 783,
          },
          {
            title: 'Xã Tân Thông Hội',
            code: 27556,
            value: 'xa_tan_thong_hoi',
            division_type: 'xã',
            short_codename: 'tan_thong_hoi',
            parentId: 783,
          },
        ],
        parentId: 79,
      },
      {
        title: 'Huyện Hóc Môn',
        code: 784,
        value: 'huyen_hoc_mon',
        division_type: 'huyện',
        short_codename: 'hoc_mon',
        children: [
          {
            title: 'Thị trấn Hóc Môn',
            code: 27559,
            value: 'thi_tran_hoc_mon',
            division_type: 'thị trấn',
            short_codename: 'hoc_mon',
            parentId: 784,
          },
          {
            title: 'Xã Tân Hiệp',
            code: 27562,
            value: 'xa_tan_hiep',
            division_type: 'xã',
            short_codename: 'tan_hiep',
            parentId: 784,
          },
          {
            title: 'Xã Nhị Bình',
            code: 27565,
            value: 'xa_nhi_binh',
            division_type: 'xã',
            short_codename: 'nhi_binh',
            parentId: 784,
          },
          {
            title: 'Xã Đông Thạnh',
            code: 27568,
            value: 'xa_dong_thanh',
            division_type: 'xã',
            short_codename: 'dong_thanh',
            parentId: 784,
          },
          {
            title: 'Xã Tân Thới Nhì',
            code: 27571,
            value: 'xa_tan_thoi_nhi',
            division_type: 'xã',
            short_codename: 'tan_thoi_nhi',
            parentId: 784,
          },
          {
            title: 'Xã Thới Tam Thôn',
            code: 27574,
            value: 'xa_thoi_tam_thon',
            division_type: 'xã',
            short_codename: 'thoi_tam_thon',
            parentId: 784,
          },
          {
            title: 'Xã Xuân Thới Sơn',
            code: 27577,
            value: 'xa_xuan_thoi_son',
            division_type: 'xã',
            short_codename: 'xuan_thoi_son',
            parentId: 784,
          },
          {
            title: 'Xã Tân Xuân',
            code: 27580,
            value: 'xa_tan_xuan',
            division_type: 'xã',
            short_codename: 'tan_xuan',
            parentId: 784,
          },
          {
            title: 'Xã Xuân Thới Đông',
            code: 27583,
            value: 'xa_xuan_thoi_dong',
            division_type: 'xã',
            short_codename: 'xuan_thoi_dong',
            parentId: 784,
          },
          {
            title: 'Xã Trung Chánh',
            code: 27586,
            value: 'xa_trung_chanh',
            division_type: 'xã',
            short_codename: 'trung_chanh',
            parentId: 784,
          },
          {
            title: 'Xã Xuân Thới Thượng',
            code: 27589,
            value: 'xa_xuan_thoi_thuong',
            division_type: 'xã',
            short_codename: 'xuan_thoi_thuong',
            parentId: 784,
          },
          {
            title: 'Xã Bà Điểm',
            code: 27592,
            value: 'xa_ba_diem',
            division_type: 'xã',
            short_codename: 'ba_diem',
            parentId: 784,
          },
        ],
        parentId: 79,
      },
      {
        title: 'Huyện Bình Chánh',
        code: 785,
        value: 'huyen_binh_chanh',
        division_type: 'huyện',
        short_codename: 'binh_chanh',
        children: [
          {
            title: 'Thị trấn Tân Túc',
            code: 27595,
            value: 'thi_tran_tan_tuc',
            division_type: 'thị trấn',
            short_codename: 'tan_tuc',
            parentId: 785,
          },
          {
            title: 'Xã Phạm Văn Hai',
            code: 27598,
            value: 'xa_pham_van_hai',
            division_type: 'xã',
            short_codename: 'pham_van_hai',
            parentId: 785,
          },
          {
            title: 'Xã Vĩnh Lộc A',
            code: 27601,
            value: 'xa_vinh_loc_a',
            division_type: 'xã',
            short_codename: 'vinh_loc_a',
            parentId: 785,
          },
          {
            title: 'Xã Vĩnh Lộc B',
            code: 27604,
            value: 'xa_vinh_loc_b',
            division_type: 'xã',
            short_codename: 'vinh_loc_b',
            parentId: 785,
          },
          {
            title: 'Xã Bình Lợi',
            code: 27607,
            value: 'xa_binh_loi',
            division_type: 'xã',
            short_codename: 'binh_loi',
            parentId: 785,
          },
          {
            title: 'Xã Lê Minh Xuân',
            code: 27610,
            value: 'xa_le_minh_xuan',
            division_type: 'xã',
            short_codename: 'le_minh_xuan',
            parentId: 785,
          },
          {
            title: 'Xã Tân Nhựt',
            code: 27613,
            value: 'xa_tan_nhut',
            division_type: 'xã',
            short_codename: 'tan_nhut',
            parentId: 785,
          },
          {
            title: 'Xã Tân Kiên',
            code: 27616,
            value: 'xa_tan_kien',
            division_type: 'xã',
            short_codename: 'tan_kien',
            parentId: 785,
          },
          {
            title: 'Xã Bình Hưng',
            code: 27619,
            value: 'xa_binh_hung',
            division_type: 'xã',
            short_codename: 'binh_hung',
            parentId: 785,
          },
          {
            title: 'Xã Phong Phú',
            code: 27622,
            value: 'xa_phong_phu',
            division_type: 'xã',
            short_codename: 'phong_phu',
            parentId: 785,
          },
          {
            title: 'Xã An Phú Tây',
            code: 27625,
            value: 'xa_an_phu_tay',
            division_type: 'xã',
            short_codename: 'an_phu_tay',
            parentId: 785,
          },
          {
            title: 'Xã Hưng Long',
            code: 27628,
            value: 'xa_hung_long',
            division_type: 'xã',
            short_codename: 'hung_long',
            parentId: 785,
          },
          {
            title: 'Xã Đa Phước',
            code: 27631,
            value: 'xa_da_phuoc',
            division_type: 'xã',
            short_codename: 'da_phuoc',
            parentId: 785,
          },
          {
            title: 'Xã Tân Quý Tây',
            code: 27634,
            value: 'xa_tan_quy_tay',
            division_type: 'xã',
            short_codename: 'tan_quy_tay',
            parentId: 785,
          },
          {
            title: 'Xã Bình Chánh',
            code: 27637,
            value: 'xa_binh_chanh',
            division_type: 'xã',
            short_codename: 'binh_chanh',
            parentId: 785,
          },
          {
            title: 'Xã Quy Đức',
            code: 27640,
            value: 'xa_quy_duc',
            division_type: 'xã',
            short_codename: 'quy_duc',
            parentId: 785,
          },
        ],
        parentId: 79,
      },
      {
        title: 'Huyện Nhà Bè',
        code: 786,
        value: 'huyen_nha_be',
        division_type: 'huyện',
        short_codename: 'nha_be',
        children: [
          {
            title: 'Thị trấn Nhà Bè',
            code: 27643,
            value: 'thi_tran_nha_be',
            division_type: 'thị trấn',
            short_codename: 'nha_be',
            parentId: 786,
          },
          {
            title: 'Xã Phước Kiển',
            code: 27646,
            value: 'xa_phuoc_kien',
            division_type: 'xã',
            short_codename: 'phuoc_kien',
            parentId: 786,
          },
          {
            title: 'Xã Phước Lộc',
            code: 27649,
            value: 'xa_phuoc_loc',
            division_type: 'xã',
            short_codename: 'phuoc_loc',
            parentId: 786,
          },
          {
            title: 'Xã Nhơn Đức',
            code: 27652,
            value: 'xa_nhon_duc',
            division_type: 'xã',
            short_codename: 'nhon_duc',
            parentId: 786,
          },
          {
            title: 'Xã Phú Xuân',
            code: 27655,
            value: 'xa_phu_xuan',
            division_type: 'xã',
            short_codename: 'phu_xuan',
            parentId: 786,
          },
          {
            title: 'Xã Long Thới',
            code: 27658,
            value: 'xa_long_thoi',
            division_type: 'xã',
            short_codename: 'long_thoi',
            parentId: 786,
          },
          {
            title: 'Xã Hiệp Phước',
            code: 27661,
            value: 'xa_hiep_phuoc',
            division_type: 'xã',
            short_codename: 'hiep_phuoc',
            parentId: 786,
          },
        ],
        parentId: 79,
      },
      {
        title: 'Huyện Cần Giờ',
        code: 787,
        value: 'huyen_can_gio',
        division_type: 'huyện',
        short_codename: 'can_gio',
        children: [
          {
            title: 'Thị trấn Cần Thạnh',
            code: 27664,
            value: 'thi_tran_can_thanh',
            division_type: 'thị trấn',
            short_codename: 'can_thanh',
            parentId: 787,
          },
          {
            title: 'Xã Bình Khánh',
            code: 27667,
            value: 'xa_binh_khanh',
            division_type: 'xã',
            short_codename: 'binh_khanh',
            parentId: 787,
          },
          {
            title: 'Xã Tam Thôn Hiệp',
            code: 27670,
            value: 'xa_tam_thon_hiep',
            division_type: 'xã',
            short_codename: 'tam_thon_hiep',
            parentId: 787,
          },
          {
            title: 'Xã An Thới Đông',
            code: 27673,
            value: 'xa_an_thoi_dong',
            division_type: 'xã',
            short_codename: 'an_thoi_dong',
            parentId: 787,
          },
          {
            title: 'Xã Thạnh An',
            code: 27676,
            value: 'xa_thanh_an',
            division_type: 'xã',
            short_codename: 'thanh_an',
            parentId: 787,
          },
          {
            title: 'Xã Long Hòa',
            code: 27679,
            value: 'xa_long_hoa',
            division_type: 'xã',
            short_codename: 'long_hoa',
            parentId: 787,
          },
          {
            title: 'Xã Lý Nhơn',
            code: 27682,
            value: 'xa_ly_nhon',
            division_type: 'xã',
            short_codename: 'ly_nhon',
            parentId: 787,
          },
        ],
        parentId: 79,
      },
    ],
  },
  {
    title: 'Tỉnh Long An',
    code: 80,
    value: 'tinh_long_an',
    division_type: 'tỉnh',
    phone_code: 272,
    children: [
      {
        title: 'Thành phố Tân An',
        code: 794,
        value: 'thanh_pho_tan_an',
        division_type: 'thành phố',
        short_codename: 'tan_an',
        children: [
          {
            title: 'Phường 5',
            code: 27685,
            value: 'phuong_5',
            division_type: 'phường',
            short_codename: 'phuong_5',
            parentId: 794,
          },
          {
            title: 'Phường 2',
            code: 27688,
            value: 'phuong_2',
            division_type: 'phường',
            short_codename: 'phuong_2',
            parentId: 794,
          },
          {
            title: 'Phường 4',
            code: 27691,
            value: 'phuong_4',
            division_type: 'phường',
            short_codename: 'phuong_4',
            parentId: 794,
          },
          {
            title: 'Phường Tân Khánh',
            code: 27692,
            value: 'phuong_tan_khanh',
            division_type: 'phường',
            short_codename: 'tan_khanh',
            parentId: 794,
          },
          {
            title: 'Phường 1',
            code: 27694,
            value: 'phuong_1',
            division_type: 'phường',
            short_codename: 'phuong_1',
            parentId: 794,
          },
          {
            title: 'Phường 3',
            code: 27697,
            value: 'phuong_3',
            division_type: 'phường',
            short_codename: 'phuong_3',
            parentId: 794,
          },
          {
            title: 'Phường 7',
            code: 27698,
            value: 'phuong_7',
            division_type: 'phường',
            short_codename: 'phuong_7',
            parentId: 794,
          },
          {
            title: 'Phường 6',
            code: 27700,
            value: 'phuong_6',
            division_type: 'phường',
            short_codename: 'phuong_6',
            parentId: 794,
          },
          {
            title: 'Xã Hướng Thọ Phú',
            code: 27703,
            value: 'xa_huong_tho_phu',
            division_type: 'xã',
            short_codename: 'huong_tho_phu',
            parentId: 794,
          },
          {
            title: 'Xã Nhơn Thạnh Trung',
            code: 27706,
            value: 'xa_nhon_thanh_trung',
            division_type: 'xã',
            short_codename: 'nhon_thanh_trung',
            parentId: 794,
          },
          {
            title: 'Xã Lợi Bình Nhơn',
            code: 27709,
            value: 'xa_loi_binh_nhon',
            division_type: 'xã',
            short_codename: 'loi_binh_nhon',
            parentId: 794,
          },
          {
            title: 'Xã Bình Tâm',
            code: 27712,
            value: 'xa_binh_tam',
            division_type: 'xã',
            short_codename: 'binh_tam',
            parentId: 794,
          },
          {
            title: 'Phường Khánh Hậu',
            code: 27715,
            value: 'phuong_khanh_hau',
            division_type: 'phường',
            short_codename: 'khanh_hau',
            parentId: 794,
          },
          {
            title: 'Xã An Vĩnh Ngãi',
            code: 27718,
            value: 'xa_an_vinh_ngai',
            division_type: 'xã',
            short_codename: 'an_vinh_ngai',
            parentId: 794,
          },
        ],
        parentId: 80,
      },
      {
        title: 'Thị xã Kiến Tường',
        code: 795,
        value: 'thi_xa_kien_tuong',
        division_type: 'thị xã',
        short_codename: 'kien_tuong',
        children: [
          {
            title: 'Phường 1',
            code: 27787,
            value: 'phuong_1',
            division_type: 'phường',
            short_codename: 'phuong_1',
            parentId: 795,
          },
          {
            title: 'Phường 2',
            code: 27788,
            value: 'phuong_2',
            division_type: 'phường',
            short_codename: 'phuong_2',
            parentId: 795,
          },
          {
            title: 'Xã Thạnh Trị',
            code: 27790,
            value: 'xa_thanh_tri',
            division_type: 'xã',
            short_codename: 'thanh_tri',
            parentId: 795,
          },
          {
            title: 'Xã Bình Hiệp',
            code: 27793,
            value: 'xa_binh_hiep',
            division_type: 'xã',
            short_codename: 'binh_hiep',
            parentId: 795,
          },
          {
            title: 'Xã Bình Tân',
            code: 27799,
            value: 'xa_binh_tan',
            division_type: 'xã',
            short_codename: 'binh_tan',
            parentId: 795,
          },
          {
            title: 'Xã Tuyên Thạnh',
            code: 27805,
            value: 'xa_tuyen_thanh',
            division_type: 'xã',
            short_codename: 'tuyen_thanh',
            parentId: 795,
          },
          {
            title: 'Phường 3',
            code: 27806,
            value: 'phuong_3',
            division_type: 'phường',
            short_codename: 'phuong_3',
            parentId: 795,
          },
          {
            title: 'Xã Thạnh Hưng',
            code: 27817,
            value: 'xa_thanh_hung',
            division_type: 'xã',
            short_codename: 'thanh_hung',
            parentId: 795,
          },
        ],
        parentId: 80,
      },
      {
        title: 'Huyện Tân Hưng',
        code: 796,
        value: 'huyen_tan_hung',
        division_type: 'huyện',
        short_codename: 'tan_hung',
        children: [
          {
            title: 'Thị trấn Tân Hưng',
            code: 27721,
            value: 'thi_tran_tan_hung',
            division_type: 'thị trấn',
            short_codename: 'tan_hung',
            parentId: 796,
          },
          {
            title: 'Xã Hưng Hà',
            code: 27724,
            value: 'xa_hung_ha',
            division_type: 'xã',
            short_codename: 'hung_ha',
            parentId: 796,
          },
          {
            title: 'Xã Hưng Điền B',
            code: 27727,
            value: 'xa_hung_dien_b',
            division_type: 'xã',
            short_codename: 'hung_dien_b',
            parentId: 796,
          },
          {
            title: 'Xã Hưng Điền',
            code: 27730,
            value: 'xa_hung_dien',
            division_type: 'xã',
            short_codename: 'hung_dien',
            parentId: 796,
          },
          {
            title: 'Xã Thạnh Hưng',
            code: 27733,
            value: 'xa_thanh_hung',
            division_type: 'xã',
            short_codename: 'thanh_hung',
            parentId: 796,
          },
          {
            title: 'Xã Hưng Thạnh',
            code: 27736,
            value: 'xa_hung_thanh',
            division_type: 'xã',
            short_codename: 'hung_thanh',
            parentId: 796,
          },
          {
            title: 'Xã Vĩnh Thạnh',
            code: 27739,
            value: 'xa_vinh_thanh',
            division_type: 'xã',
            short_codename: 'vinh_thanh',
            parentId: 796,
          },
          {
            title: 'Xã Vĩnh Châu B',
            code: 27742,
            value: 'xa_vinh_chau_b',
            division_type: 'xã',
            short_codename: 'vinh_chau_b',
            parentId: 796,
          },
          {
            title: 'Xã Vĩnh Lợi',
            code: 27745,
            value: 'xa_vinh_loi',
            division_type: 'xã',
            short_codename: 'vinh_loi',
            parentId: 796,
          },
          {
            title: 'Xã Vĩnh Đại',
            code: 27748,
            value: 'xa_vinh_dai',
            division_type: 'xã',
            short_codename: 'vinh_dai',
            parentId: 796,
          },
          {
            title: 'Xã Vĩnh Châu A',
            code: 27751,
            value: 'xa_vinh_chau_a',
            division_type: 'xã',
            short_codename: 'vinh_chau_a',
            parentId: 796,
          },
          {
            title: 'Xã Vĩnh Bửu',
            code: 27754,
            value: 'xa_vinh_buu',
            division_type: 'xã',
            short_codename: 'vinh_buu',
            parentId: 796,
          },
        ],
        parentId: 80,
      },
      {
        title: 'Huyện Vĩnh Hưng',
        code: 797,
        value: 'huyen_vinh_hung',
        division_type: 'huyện',
        short_codename: 'vinh_hung',
        children: [
          {
            title: 'Thị trấn Vĩnh Hưng',
            code: 27757,
            value: 'thi_tran_vinh_hung',
            division_type: 'thị trấn',
            short_codename: 'vinh_hung',
            parentId: 797,
          },
          {
            title: 'Xã Hưng Điền A',
            code: 27760,
            value: 'xa_hung_dien_a',
            division_type: 'xã',
            short_codename: 'hung_dien_a',
            parentId: 797,
          },
          {
            title: 'Xã Khánh Hưng',
            code: 27763,
            value: 'xa_khanh_hung',
            division_type: 'xã',
            short_codename: 'khanh_hung',
            parentId: 797,
          },
          {
            title: 'Xã Thái Trị',
            code: 27766,
            value: 'xa_thai_tri',
            division_type: 'xã',
            short_codename: 'thai_tri',
            parentId: 797,
          },
          {
            title: 'Xã Vĩnh Trị',
            code: 27769,
            value: 'xa_vinh_tri',
            division_type: 'xã',
            short_codename: 'vinh_tri',
            parentId: 797,
          },
          {
            title: 'Xã Thái Bình Trung',
            code: 27772,
            value: 'xa_thai_binh_trung',
            division_type: 'xã',
            short_codename: 'thai_binh_trung',
            parentId: 797,
          },
          {
            title: 'Xã Vĩnh Bình',
            code: 27775,
            value: 'xa_vinh_binh',
            division_type: 'xã',
            short_codename: 'vinh_binh',
            parentId: 797,
          },
          {
            title: 'Xã Vĩnh Thuận',
            code: 27778,
            value: 'xa_vinh_thuan',
            division_type: 'xã',
            short_codename: 'vinh_thuan',
            parentId: 797,
          },
          {
            title: 'Xã Tuyên Bình',
            code: 27781,
            value: 'xa_tuyen_binh',
            division_type: 'xã',
            short_codename: 'tuyen_binh',
            parentId: 797,
          },
          {
            title: 'Xã Tuyên Bình Tây',
            code: 27784,
            value: 'xa_tuyen_binh_tay',
            division_type: 'xã',
            short_codename: 'tuyen_binh_tay',
            parentId: 797,
          },
        ],
        parentId: 80,
      },
      {
        title: 'Huyện Mộc Hóa',
        code: 798,
        value: 'huyen_moc_hoa',
        division_type: 'huyện',
        short_codename: 'moc_hoa',
        children: [
          {
            title: 'Xã Bình Hòa Tây',
            code: 27796,
            value: 'xa_binh_hoa_tay',
            division_type: 'xã',
            short_codename: 'binh_hoa_tay',
            parentId: 798,
          },
          {
            title: 'Xã Bình Thạnh',
            code: 27802,
            value: 'xa_binh_thanh',
            division_type: 'xã',
            short_codename: 'binh_thanh',
            parentId: 798,
          },
          {
            title: 'Xã Bình Hòa Trung',
            code: 27808,
            value: 'xa_binh_hoa_trung',
            division_type: 'xã',
            short_codename: 'binh_hoa_trung',
            parentId: 798,
          },
          {
            title: 'Xã Bình Hòa Đông',
            code: 27811,
            value: 'xa_binh_hoa_dong',
            division_type: 'xã',
            short_codename: 'binh_hoa_dong',
            parentId: 798,
          },
          {
            title: 'Thị trấn Bình Phong Thạnh',
            code: 27814,
            value: 'thi_tran_binh_phong_thanh',
            division_type: 'thị trấn',
            short_codename: 'binh_phong_thanh',
            parentId: 798,
          },
          {
            title: 'Xã Tân Lập',
            code: 27820,
            value: 'xa_tan_lap',
            division_type: 'xã',
            short_codename: 'tan_lap',
            parentId: 798,
          },
          {
            title: 'Xã Tân Thành',
            code: 27823,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 798,
          },
        ],
        parentId: 80,
      },
      {
        title: 'Huyện Tân Thạnh',
        code: 799,
        value: 'huyen_tan_thanh',
        division_type: 'huyện',
        short_codename: 'tan_thanh',
        children: [
          {
            title: 'Thị trấn Tân Thạnh',
            code: 27826,
            value: 'thi_tran_tan_thanh',
            division_type: 'thị trấn',
            short_codename: 'tan_thanh',
            parentId: 799,
          },
          {
            title: 'Xã Bắc Hòa',
            code: 27829,
            value: 'xa_bac_hoa',
            division_type: 'xã',
            short_codename: 'bac_hoa',
            parentId: 799,
          },
          {
            title: 'Xã Hậu Thạnh Tây',
            code: 27832,
            value: 'xa_hau_thanh_tay',
            division_type: 'xã',
            short_codename: 'hau_thanh_tay',
            parentId: 799,
          },
          {
            title: 'Xã Nhơn Hòa Lập',
            code: 27835,
            value: 'xa_nhon_hoa_lap',
            division_type: 'xã',
            short_codename: 'nhon_hoa_lap',
            parentId: 799,
          },
          {
            title: 'Xã Tân Lập',
            code: 27838,
            value: 'xa_tan_lap',
            division_type: 'xã',
            short_codename: 'tan_lap',
            parentId: 799,
          },
          {
            title: 'Xã Hậu Thạnh Đông',
            code: 27841,
            value: 'xa_hau_thanh_dong',
            division_type: 'xã',
            short_codename: 'hau_thanh_dong',
            parentId: 799,
          },
          {
            title: 'Xã Nhơn Hoà',
            code: 27844,
            value: 'xa_nhon_hoa',
            division_type: 'xã',
            short_codename: 'nhon_hoa',
            parentId: 799,
          },
          {
            title: 'Xã Kiến Bình',
            code: 27847,
            value: 'xa_kien_binh',
            division_type: 'xã',
            short_codename: 'kien_binh',
            parentId: 799,
          },
          {
            title: 'Xã Tân Thành',
            code: 27850,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'xa_tan_thanh',
            parentId: 799,
          },
          {
            title: 'Xã Tân Bình',
            code: 27853,
            value: 'xa_tan_binh',
            division_type: 'xã',
            short_codename: 'tan_binh',
            parentId: 799,
          },
          {
            title: 'Xã Tân Ninh',
            code: 27856,
            value: 'xa_tan_ninh',
            division_type: 'xã',
            short_codename: 'tan_ninh',
            parentId: 799,
          },
          {
            title: 'Xã Nhơn Ninh',
            code: 27859,
            value: 'xa_nhon_ninh',
            division_type: 'xã',
            short_codename: 'nhon_ninh',
            parentId: 799,
          },
          {
            title: 'Xã Tân Hòa',
            code: 27862,
            value: 'xa_tan_hoa',
            division_type: 'xã',
            short_codename: 'tan_hoa',
            parentId: 799,
          },
        ],
        parentId: 80,
      },
      {
        title: 'Huyện Thạnh Hóa',
        code: 800,
        value: 'huyen_thanh_hoa',
        division_type: 'huyện',
        short_codename: 'thanh_hoa',
        children: [
          {
            title: 'Thị trấn Thạnh Hóa',
            code: 27865,
            value: 'thi_tran_thanh_hoa',
            division_type: 'thị trấn',
            short_codename: 'thanh_hoa',
            parentId: 800,
          },
          {
            title: 'Xã Tân Hiệp',
            code: 27868,
            value: 'xa_tan_hiep',
            division_type: 'xã',
            short_codename: 'tan_hiep',
            parentId: 800,
          },
          {
            title: 'Xã Thuận Bình',
            code: 27871,
            value: 'xa_thuan_binh',
            division_type: 'xã',
            short_codename: 'thuan_binh',
            parentId: 800,
          },
          {
            title: 'Xã Thạnh Phước',
            code: 27874,
            value: 'xa_thanh_phuoc',
            division_type: 'xã',
            short_codename: 'thanh_phuoc',
            parentId: 800,
          },
          {
            title: 'Xã Thạnh Phú',
            code: 27877,
            value: 'xa_thanh_phu',
            division_type: 'xã',
            short_codename: 'thanh_phu',
            parentId: 800,
          },
          {
            title: 'Xã Thuận Nghĩa Hòa',
            code: 27880,
            value: 'xa_thuan_nghia_hoa',
            division_type: 'xã',
            short_codename: 'thuan_nghia_hoa',
            parentId: 800,
          },
          {
            title: 'Xã Thủy Đông',
            code: 27883,
            value: 'xa_thuy_dong',
            division_type: 'xã',
            short_codename: 'thuy_dong',
            parentId: 800,
          },
          {
            title: 'Xã Thủy Tây',
            code: 27886,
            value: 'xa_thuy_tay',
            division_type: 'xã',
            short_codename: 'thuy_tay',
            parentId: 800,
          },
          {
            title: 'Xã Tân Tây',
            code: 27889,
            value: 'xa_tan_tay',
            division_type: 'xã',
            short_codename: 'tan_tay',
            parentId: 800,
          },
          {
            title: 'Xã Tân Đông',
            code: 27892,
            value: 'xa_tan_dong',
            division_type: 'xã',
            short_codename: 'tan_dong',
            parentId: 800,
          },
          {
            title: 'Xã Thạnh An',
            code: 27895,
            value: 'xa_thanh_an',
            division_type: 'xã',
            short_codename: 'thanh_an',
            parentId: 800,
          },
        ],
        parentId: 80,
      },
      {
        title: 'Huyện Đức Huệ',
        code: 801,
        value: 'huyen_duc_hue',
        division_type: 'huyện',
        short_codename: 'duc_hue',
        children: [
          {
            title: 'Thị trấn Đông Thành',
            code: 27898,
            value: 'thi_tran_dong_thanh',
            division_type: 'thị trấn',
            short_codename: 'dong_thanh',
            parentId: 801,
          },
          {
            title: 'Xã Mỹ Quý Đông',
            code: 27901,
            value: 'xa_my_quy_dong',
            division_type: 'xã',
            short_codename: 'my_quy_dong',
            parentId: 801,
          },
          {
            title: 'Xã Mỹ Thạnh Bắc',
            code: 27904,
            value: 'xa_my_thanh_bac',
            division_type: 'xã',
            short_codename: 'my_thanh_bac',
            parentId: 801,
          },
          {
            title: 'Xã Mỹ Quý Tây',
            code: 27907,
            value: 'xa_my_quy_tay',
            division_type: 'xã',
            short_codename: 'my_quy_tay',
            parentId: 801,
          },
          {
            title: 'Xã Mỹ Thạnh Tây',
            code: 27910,
            value: 'xa_my_thanh_tay',
            division_type: 'xã',
            short_codename: 'my_thanh_tay',
            parentId: 801,
          },
          {
            title: 'Xã Mỹ Thạnh Đông',
            code: 27913,
            value: 'xa_my_thanh_dong',
            division_type: 'xã',
            short_codename: 'my_thanh_dong',
            parentId: 801,
          },
          {
            title: 'Xã Bình Thành',
            code: 27916,
            value: 'xa_binh_thanh',
            division_type: 'xã',
            short_codename: 'binh_thanh',
            parentId: 801,
          },
          {
            title: 'Xã Bình Hòa Bắc',
            code: 27919,
            value: 'xa_binh_hoa_bac',
            division_type: 'xã',
            short_codename: 'binh_hoa_bac',
            parentId: 801,
          },
          {
            title: 'Xã Bình Hòa Hưng',
            code: 27922,
            value: 'xa_binh_hoa_hung',
            division_type: 'xã',
            short_codename: 'binh_hoa_hung',
            parentId: 801,
          },
          {
            title: 'Xã Bình Hòa Nam',
            code: 27925,
            value: 'xa_binh_hoa_nam',
            division_type: 'xã',
            short_codename: 'binh_hoa_nam',
            parentId: 801,
          },
          {
            title: 'Xã Mỹ Bình',
            code: 27928,
            value: 'xa_my_binh',
            division_type: 'xã',
            short_codename: 'my_binh',
            parentId: 801,
          },
        ],
        parentId: 80,
      },
      {
        title: 'Huyện Đức Hòa',
        code: 802,
        value: 'huyen_duc_hoa',
        division_type: 'huyện',
        short_codename: 'duc_hoa',
        children: [
          {
            title: 'Thị trấn Hậu Nghĩa',
            code: 27931,
            value: 'thi_tran_hau_nghia',
            division_type: 'thị trấn',
            short_codename: 'hau_nghia',
            parentId: 802,
          },
          {
            title: 'Thị trấn Hiệp Hòa',
            code: 27934,
            value: 'thi_tran_hiep_hoa',
            division_type: 'thị trấn',
            short_codename: 'hiep_hoa',
            parentId: 802,
          },
          {
            title: 'Thị trấn Đức Hòa',
            code: 27937,
            value: 'thi_tran_duc_hoa',
            division_type: 'thị trấn',
            short_codename: 'duc_hoa',
            parentId: 802,
          },
          {
            title: 'Xã Lộc Giang',
            code: 27940,
            value: 'xa_loc_giang',
            division_type: 'xã',
            short_codename: 'loc_giang',
            parentId: 802,
          },
          {
            title: 'Xã An Ninh Đông',
            code: 27943,
            value: 'xa_an_ninh_dong',
            division_type: 'xã',
            short_codename: 'an_ninh_dong',
            parentId: 802,
          },
          {
            title: 'Xã An Ninh Tây',
            code: 27946,
            value: 'xa_an_ninh_tay',
            division_type: 'xã',
            short_codename: 'an_ninh_tay',
            parentId: 802,
          },
          {
            title: 'Xã Tân Mỹ',
            code: 27949,
            value: 'xa_tan_my',
            division_type: 'xã',
            short_codename: 'tan_my',
            parentId: 802,
          },
          {
            title: 'Xã Hiệp Hòa',
            code: 27952,
            value: 'xa_hiep_hoa',
            division_type: 'xã',
            short_codename: 'xa_hiep_hoa',
            parentId: 802,
          },
          {
            title: 'Xã Đức Lập Thượng',
            code: 27955,
            value: 'xa_duc_lap_thuong',
            division_type: 'xã',
            short_codename: 'duc_lap_thuong',
            parentId: 802,
          },
          {
            title: 'Xã Đức Lập Hạ',
            code: 27958,
            value: 'xa_duc_lap_ha',
            division_type: 'xã',
            short_codename: 'duc_lap_ha',
            parentId: 802,
          },
          {
            title: 'Xã Tân Phú',
            code: 27961,
            value: 'xa_tan_phu',
            division_type: 'xã',
            short_codename: 'tan_phu',
            parentId: 802,
          },
          {
            title: 'Xã Mỹ Hạnh Bắc',
            code: 27964,
            value: 'xa_my_hanh_bac',
            division_type: 'xã',
            short_codename: 'my_hanh_bac',
            parentId: 802,
          },
          {
            title: 'Xã Đức Hòa Thượng',
            code: 27967,
            value: 'xa_duc_hoa_thuong',
            division_type: 'xã',
            short_codename: 'duc_hoa_thuong',
            parentId: 802,
          },
          {
            title: 'Xã Hòa Khánh Tây',
            code: 27970,
            value: 'xa_hoa_khanh_tay',
            division_type: 'xã',
            short_codename: 'hoa_khanh_tay',
            parentId: 802,
          },
          {
            title: 'Xã Hòa Khánh Đông',
            code: 27973,
            value: 'xa_hoa_khanh_dong',
            division_type: 'xã',
            short_codename: 'hoa_khanh_dong',
            parentId: 802,
          },
          {
            title: 'Xã Mỹ Hạnh Nam',
            code: 27976,
            value: 'xa_my_hanh_nam',
            division_type: 'xã',
            short_codename: 'my_hanh_nam',
            parentId: 802,
          },
          {
            title: 'Xã Hòa Khánh Nam',
            code: 27979,
            value: 'xa_hoa_khanh_nam',
            division_type: 'xã',
            short_codename: 'hoa_khanh_nam',
            parentId: 802,
          },
          {
            title: 'Xã Đức Hòa Đông',
            code: 27982,
            value: 'xa_duc_hoa_dong',
            division_type: 'xã',
            short_codename: 'duc_hoa_dong',
            parentId: 802,
          },
          {
            title: 'Xã Đức Hòa Hạ',
            code: 27985,
            value: 'xa_duc_hoa_ha',
            division_type: 'xã',
            short_codename: 'duc_hoa_ha',
            parentId: 802,
          },
          {
            title: 'Xã Hựu Thạnh',
            code: 27988,
            value: 'xa_huu_thanh',
            division_type: 'xã',
            short_codename: 'huu_thanh',
            parentId: 802,
          },
        ],
        parentId: 80,
      },
      {
        title: 'Huyện Bến Lức',
        code: 803,
        value: 'huyen_ben_luc',
        division_type: 'huyện',
        short_codename: 'ben_luc',
        children: [
          {
            title: 'Thị trấn Bến Lức',
            code: 27991,
            value: 'thi_tran_ben_luc',
            division_type: 'thị trấn',
            short_codename: 'ben_luc',
            parentId: 803,
          },
          {
            title: 'Xã Thạnh Lợi',
            code: 27994,
            value: 'xa_thanh_loi',
            division_type: 'xã',
            short_codename: 'thanh_loi',
            parentId: 803,
          },
          {
            title: 'Xã Lương Bình',
            code: 27997,
            value: 'xa_luong_binh',
            division_type: 'xã',
            short_codename: 'luong_binh',
            parentId: 803,
          },
          {
            title: 'Xã Thạnh Hòa',
            code: 28000,
            value: 'xa_thanh_hoa',
            division_type: 'xã',
            short_codename: 'thanh_hoa',
            parentId: 803,
          },
          {
            title: 'Xã Lương Hòa',
            code: 28003,
            value: 'xa_luong_hoa',
            division_type: 'xã',
            short_codename: 'luong_hoa',
            parentId: 803,
          },
          {
            title: 'Xã Tân Hòa',
            code: 28006,
            value: 'xa_tan_hoa',
            division_type: 'xã',
            short_codename: 'tan_hoa',
            parentId: 803,
          },
          {
            title: 'Xã Tân Bửu',
            code: 28009,
            value: 'xa_tan_buu',
            division_type: 'xã',
            short_codename: 'tan_buu',
            parentId: 803,
          },
          {
            title: 'Xã An Thạnh',
            code: 28012,
            value: 'xa_an_thanh',
            division_type: 'xã',
            short_codename: 'an_thanh',
            parentId: 803,
          },
          {
            title: 'Xã Bình Đức',
            code: 28015,
            value: 'xa_binh_duc',
            division_type: 'xã',
            short_codename: 'binh_duc',
            parentId: 803,
          },
          {
            title: 'Xã Mỹ Yên',
            code: 28018,
            value: 'xa_my_yen',
            division_type: 'xã',
            short_codename: 'my_yen',
            parentId: 803,
          },
          {
            title: 'Xã Thanh Phú',
            code: 28021,
            value: 'xa_thanh_phu',
            division_type: 'xã',
            short_codename: 'thanh_phu',
            parentId: 803,
          },
          {
            title: 'Xã Long Hiệp',
            code: 28024,
            value: 'xa_long_hiep',
            division_type: 'xã',
            short_codename: 'long_hiep',
            parentId: 803,
          },
          {
            title: 'Xã Thạnh Đức',
            code: 28027,
            value: 'xa_thanh_duc',
            division_type: 'xã',
            short_codename: 'thanh_duc',
            parentId: 803,
          },
          {
            title: 'Xã Phước Lợi',
            code: 28030,
            value: 'xa_phuoc_loi',
            division_type: 'xã',
            short_codename: 'phuoc_loi',
            parentId: 803,
          },
          {
            title: 'Xã Nhựt Chánh',
            code: 28033,
            value: 'xa_nhut_chanh',
            division_type: 'xã',
            short_codename: 'nhut_chanh',
            parentId: 803,
          },
        ],
        parentId: 80,
      },
      {
        title: 'Huyện Thủ Thừa',
        code: 804,
        value: 'huyen_thu_thua',
        division_type: 'huyện',
        short_codename: 'thu_thua',
        children: [
          {
            title: 'Thị trấn Thủ Thừa',
            code: 28036,
            value: 'thi_tran_thu_thua',
            division_type: 'thị trấn',
            short_codename: 'thu_thua',
            parentId: 804,
          },
          {
            title: 'Xã Long Thạnh',
            code: 28039,
            value: 'xa_long_thanh',
            division_type: 'xã',
            short_codename: 'long_thanh',
            parentId: 804,
          },
          {
            title: 'Xã Tân Thành',
            code: 28042,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 804,
          },
          {
            title: 'Xã Long Thuận',
            code: 28045,
            value: 'xa_long_thuan',
            division_type: 'xã',
            short_codename: 'long_thuan',
            parentId: 804,
          },
          {
            title: 'Xã Mỹ Lạc',
            code: 28048,
            value: 'xa_my_lac',
            division_type: 'xã',
            short_codename: 'my_lac',
            parentId: 804,
          },
          {
            title: 'Xã Mỹ Thạnh',
            code: 28051,
            value: 'xa_my_thanh',
            division_type: 'xã',
            short_codename: 'my_thanh',
            parentId: 804,
          },
          {
            title: 'Xã Bình An',
            code: 28054,
            value: 'xa_binh_an',
            division_type: 'xã',
            short_codename: 'binh_an',
            parentId: 804,
          },
          {
            title: 'Xã Nhị Thành',
            code: 28057,
            value: 'xa_nhi_thanh',
            division_type: 'xã',
            short_codename: 'nhi_thanh',
            parentId: 804,
          },
          {
            title: 'Xã Mỹ An',
            code: 28060,
            value: 'xa_my_an',
            division_type: 'xã',
            short_codename: 'my_an',
            parentId: 804,
          },
          {
            title: 'Xã Bình Thạnh',
            code: 28063,
            value: 'xa_binh_thanh',
            division_type: 'xã',
            short_codename: 'binh_thanh',
            parentId: 804,
          },
          {
            title: 'Xã Mỹ Phú',
            code: 28066,
            value: 'xa_my_phu',
            division_type: 'xã',
            short_codename: 'my_phu',
            parentId: 804,
          },
          {
            title: 'Xã Tân Long',
            code: 28072,
            value: 'xa_tan_long',
            division_type: 'xã',
            short_codename: 'tan_long',
            parentId: 804,
          },
        ],
        parentId: 80,
      },
      {
        title: 'Huyện Tân Trụ',
        code: 805,
        value: 'huyen_tan_tru',
        division_type: 'huyện',
        short_codename: 'tan_tru',
        children: [
          {
            title: 'Thị trấn Tân Trụ',
            code: 28075,
            value: 'thi_tran_tan_tru',
            division_type: 'thị trấn',
            short_codename: 'tan_tru',
            parentId: 805,
          },
          {
            title: 'Xã Tân Bình',
            code: 28078,
            value: 'xa_tan_binh',
            division_type: 'xã',
            short_codename: 'tan_binh',
            parentId: 805,
          },
          {
            title: 'Xã Quê Mỹ Thạnh',
            code: 28084,
            value: 'xa_que_my_thanh',
            division_type: 'xã',
            short_codename: 'que_my_thanh',
            parentId: 805,
          },
          {
            title: 'Xã Lạc Tấn',
            code: 28087,
            value: 'xa_lac_tan',
            division_type: 'xã',
            short_codename: 'lac_tan',
            parentId: 805,
          },
          {
            title: 'Xã Bình Trinh Đông',
            code: 28090,
            value: 'xa_binh_trinh_dong',
            division_type: 'xã',
            short_codename: 'binh_trinh_dong',
            parentId: 805,
          },
          {
            title: 'Xã Tân Phước Tây',
            code: 28093,
            value: 'xa_tan_phuoc_tay',
            division_type: 'xã',
            short_codename: 'tan_phuoc_tay',
            parentId: 805,
          },
          {
            title: 'Xã Bình Lãng',
            code: 28096,
            value: 'xa_binh_lang',
            division_type: 'xã',
            short_codename: 'binh_lang',
            parentId: 805,
          },
          {
            title: 'Xã Bình Tịnh',
            code: 28099,
            value: 'xa_binh_tinh',
            division_type: 'xã',
            short_codename: 'binh_tinh',
            parentId: 805,
          },
          {
            title: 'Xã Đức Tân',
            code: 28102,
            value: 'xa_duc_tan',
            division_type: 'xã',
            short_codename: 'duc_tan',
            parentId: 805,
          },
          {
            title: 'Xã Nhựt Ninh',
            code: 28105,
            value: 'xa_nhut_ninh',
            division_type: 'xã',
            short_codename: 'nhut_ninh',
            parentId: 805,
          },
        ],
        parentId: 80,
      },
      {
        title: 'Huyện Cần Đước',
        code: 806,
        value: 'huyen_can_duoc',
        division_type: 'huyện',
        short_codename: 'can_duoc',
        children: [
          {
            title: 'Thị trấn Cần Đước',
            code: 28108,
            value: 'thi_tran_can_duoc',
            division_type: 'thị trấn',
            short_codename: 'can_duoc',
            parentId: 806,
          },
          {
            title: 'Xã Long Trạch',
            code: 28111,
            value: 'xa_long_trach',
            division_type: 'xã',
            short_codename: 'long_trach',
            parentId: 806,
          },
          {
            title: 'Xã Long Khê',
            code: 28114,
            value: 'xa_long_khe',
            division_type: 'xã',
            short_codename: 'long_khe',
            parentId: 806,
          },
          {
            title: 'Xã Long Định',
            code: 28117,
            value: 'xa_long_dinh',
            division_type: 'xã',
            short_codename: 'long_dinh',
            parentId: 806,
          },
          {
            title: 'Xã Phước Vân',
            code: 28120,
            value: 'xa_phuoc_van',
            division_type: 'xã',
            short_codename: 'phuoc_van',
            parentId: 806,
          },
          {
            title: 'Xã Long Hòa',
            code: 28123,
            value: 'xa_long_hoa',
            division_type: 'xã',
            short_codename: 'long_hoa',
            parentId: 806,
          },
          {
            title: 'Xã Long Cang',
            code: 28126,
            value: 'xa_long_cang',
            division_type: 'xã',
            short_codename: 'long_cang',
            parentId: 806,
          },
          {
            title: 'Xã Long Sơn',
            code: 28129,
            value: 'xa_long_son',
            division_type: 'xã',
            short_codename: 'long_son',
            parentId: 806,
          },
          {
            title: 'Xã Tân Trạch',
            code: 28132,
            value: 'xa_tan_trach',
            division_type: 'xã',
            short_codename: 'tan_trach',
            parentId: 806,
          },
          {
            title: 'Xã Mỹ Lệ',
            code: 28135,
            value: 'xa_my_le',
            division_type: 'xã',
            short_codename: 'my_le',
            parentId: 806,
          },
          {
            title: 'Xã Tân Lân',
            code: 28138,
            value: 'xa_tan_lan',
            division_type: 'xã',
            short_codename: 'tan_lan',
            parentId: 806,
          },
          {
            title: 'Xã Phước Tuy',
            code: 28141,
            value: 'xa_phuoc_tuy',
            division_type: 'xã',
            short_codename: 'phuoc_tuy',
            parentId: 806,
          },
          {
            title: 'Xã Long Hựu Đông',
            code: 28144,
            value: 'xa_long_huu_dong',
            division_type: 'xã',
            short_codename: 'long_huu_dong',
            parentId: 806,
          },
          {
            title: 'Xã Tân Ân',
            code: 28147,
            value: 'xa_tan_an',
            division_type: 'xã',
            short_codename: 'tan_an',
            parentId: 806,
          },
          {
            title: 'Xã Phước Đông',
            code: 28150,
            value: 'xa_phuoc_dong',
            division_type: 'xã',
            short_codename: 'phuoc_dong',
            parentId: 806,
          },
          {
            title: 'Xã Long Hựu Tây',
            code: 28153,
            value: 'xa_long_huu_tay',
            division_type: 'xã',
            short_codename: 'long_huu_tay',
            parentId: 806,
          },
          {
            title: 'Xã Tân Chánh',
            code: 28156,
            value: 'xa_tan_chanh',
            division_type: 'xã',
            short_codename: 'tan_chanh',
            parentId: 806,
          },
        ],
        parentId: 80,
      },
      {
        title: 'Huyện Cần Giuộc',
        code: 807,
        value: 'huyen_can_giuoc',
        division_type: 'huyện',
        short_codename: 'can_giuoc',
        children: [
          {
            title: 'Thị trấn Cần Giuộc',
            code: 28159,
            value: 'thi_tran_can_giuoc',
            division_type: 'thị trấn',
            short_codename: 'can_giuoc',
            parentId: 807,
          },
          {
            title: 'Xã Phước Lý',
            code: 28162,
            value: 'xa_phuoc_ly',
            division_type: 'xã',
            short_codename: 'phuoc_ly',
            parentId: 807,
          },
          {
            title: 'Xã Long Thượng',
            code: 28165,
            value: 'xa_long_thuong',
            division_type: 'xã',
            short_codename: 'long_thuong',
            parentId: 807,
          },
          {
            title: 'Xã Long Hậu',
            code: 28168,
            value: 'xa_long_hau',
            division_type: 'xã',
            short_codename: 'long_hau',
            parentId: 807,
          },
          {
            title: 'Xã Phước Hậu',
            code: 28174,
            value: 'xa_phuoc_hau',
            division_type: 'xã',
            short_codename: 'phuoc_hau',
            parentId: 807,
          },
          {
            title: 'Xã Mỹ Lộc',
            code: 28177,
            value: 'xa_my_loc',
            division_type: 'xã',
            short_codename: 'my_loc',
            parentId: 807,
          },
          {
            title: 'Xã Phước Lại',
            code: 28180,
            value: 'xa_phuoc_lai',
            division_type: 'xã',
            short_codename: 'phuoc_lai',
            parentId: 807,
          },
          {
            title: 'Xã Phước Lâm',
            code: 28183,
            value: 'xa_phuoc_lam',
            division_type: 'xã',
            short_codename: 'phuoc_lam',
            parentId: 807,
          },
          {
            title: 'Xã Thuận Thành',
            code: 28189,
            value: 'xa_thuan_thanh',
            division_type: 'xã',
            short_codename: 'thuan_thanh',
            parentId: 807,
          },
          {
            title: 'Xã Phước Vĩnh Tây',
            code: 28192,
            value: 'xa_phuoc_vinh_tay',
            division_type: 'xã',
            short_codename: 'phuoc_vinh_tay',
            parentId: 807,
          },
          {
            title: 'Xã Phước Vĩnh Đông',
            code: 28195,
            value: 'xa_phuoc_vinh_dong',
            division_type: 'xã',
            short_codename: 'phuoc_vinh_dong',
            parentId: 807,
          },
          {
            title: 'Xã Long An',
            code: 28198,
            value: 'xa_long_an',
            division_type: 'xã',
            short_codename: 'long_an',
            parentId: 807,
          },
          {
            title: 'Xã Long Phụng',
            code: 28201,
            value: 'xa_long_phung',
            division_type: 'xã',
            short_codename: 'long_phung',
            parentId: 807,
          },
          {
            title: 'Xã Đông Thạnh',
            code: 28204,
            value: 'xa_dong_thanh',
            division_type: 'xã',
            short_codename: 'dong_thanh',
            parentId: 807,
          },
          {
            title: 'Xã Tân Tập',
            code: 28207,
            value: 'xa_tan_tap',
            division_type: 'xã',
            short_codename: 'tan_tap',
            parentId: 807,
          },
        ],
        parentId: 80,
      },
      {
        title: 'Huyện Châu Thành',
        code: 808,
        value: 'huyen_chau_thanh',
        division_type: 'huyện',
        short_codename: 'chau_thanh',
        children: [
          {
            title: 'Thị trấn Tầm Vu',
            code: 28210,
            value: 'thi_tran_tam_vu',
            division_type: 'thị trấn',
            short_codename: 'tam_vu',
            parentId: 808,
          },
          {
            title: 'Xã Bình Quới',
            code: 28213,
            value: 'xa_binh_quoi',
            division_type: 'xã',
            short_codename: 'binh_quoi',
            parentId: 808,
          },
          {
            title: 'Xã Hòa Phú',
            code: 28216,
            value: 'xa_hoa_phu',
            division_type: 'xã',
            short_codename: 'hoa_phu',
            parentId: 808,
          },
          {
            title: 'Xã Phú Ngãi Trị',
            code: 28219,
            value: 'xa_phu_ngai_tri',
            division_type: 'xã',
            short_codename: 'phu_ngai_tri',
            parentId: 808,
          },
          {
            title: 'Xã Vĩnh Công',
            code: 28222,
            value: 'xa_vinh_cong',
            division_type: 'xã',
            short_codename: 'vinh_cong',
            parentId: 808,
          },
          {
            title: 'Xã Thuận Mỹ',
            code: 28225,
            value: 'xa_thuan_my',
            division_type: 'xã',
            short_codename: 'thuan_my',
            parentId: 808,
          },
          {
            title: 'Xã Hiệp Thạnh',
            code: 28228,
            value: 'xa_hiep_thanh',
            division_type: 'xã',
            short_codename: 'hiep_thanh',
            parentId: 808,
          },
          {
            title: 'Xã Phước Tân Hưng',
            code: 28231,
            value: 'xa_phuoc_tan_hung',
            division_type: 'xã',
            short_codename: 'phuoc_tan_hung',
            parentId: 808,
          },
          {
            title: 'Xã Thanh Phú Long',
            code: 28234,
            value: 'xa_thanh_phu_long',
            division_type: 'xã',
            short_codename: 'thanh_phu_long',
            parentId: 808,
          },
          {
            title: 'Xã Dương Xuân Hội',
            code: 28237,
            value: 'xa_duong_xuan_hoi',
            division_type: 'xã',
            short_codename: 'duong_xuan_hoi',
            parentId: 808,
          },
          {
            title: 'Xã An Lục Long',
            code: 28240,
            value: 'xa_an_luc_long',
            division_type: 'xã',
            short_codename: 'an_luc_long',
            parentId: 808,
          },
          {
            title: 'Xã Long Trì',
            code: 28243,
            value: 'xa_long_tri',
            division_type: 'xã',
            short_codename: 'long_tri',
            parentId: 808,
          },
          {
            title: 'Xã Thanh Vĩnh Đông',
            code: 28246,
            value: 'xa_thanh_vinh_dong',
            division_type: 'xã',
            short_codename: 'thanh_vinh_dong',
            parentId: 808,
          },
        ],
        parentId: 80,
      },
    ],
  },
  {
    title: 'Tỉnh Tiền Giang',
    code: 82,
    value: 'tinh_tien_giang',
    division_type: 'tỉnh',
    phone_code: 273,
    children: [
      {
        title: 'Thành phố Mỹ Tho',
        code: 815,
        value: 'thanh_pho_my_tho',
        division_type: 'thành phố',
        short_codename: 'my_tho',
        children: [
          {
            title: 'Phường 5',
            code: 28249,
            value: 'phuong_5',
            division_type: 'phường',
            short_codename: 'phuong_5',
            parentId: 815,
          },
          {
            title: 'Phường 4',
            code: 28252,
            value: 'phuong_4',
            division_type: 'phường',
            short_codename: 'phuong_4',
            parentId: 815,
          },
          {
            title: 'Phường 7',
            code: 28255,
            value: 'phuong_7',
            division_type: 'phường',
            short_codename: 'phuong_7',
            parentId: 815,
          },
          {
            title: 'Phường 3',
            code: 28258,
            value: 'phuong_3',
            division_type: 'phường',
            short_codename: 'phuong_3',
            parentId: 815,
          },
          {
            title: 'Phường 1',
            code: 28261,
            value: 'phuong_1',
            division_type: 'phường',
            short_codename: 'phuong_1',
            parentId: 815,
          },
          {
            title: 'Phường 2',
            code: 28264,
            value: 'phuong_2',
            division_type: 'phường',
            short_codename: 'phuong_2',
            parentId: 815,
          },
          {
            title: 'Phường 8',
            code: 28267,
            value: 'phuong_8',
            division_type: 'phường',
            short_codename: 'phuong_8',
            parentId: 815,
          },
          {
            title: 'Phường 6',
            code: 28270,
            value: 'phuong_6',
            division_type: 'phường',
            short_codename: 'phuong_6',
            parentId: 815,
          },
          {
            title: 'Phường 9',
            code: 28273,
            value: 'phuong_9',
            division_type: 'phường',
            short_codename: 'phuong_9',
            parentId: 815,
          },
          {
            title: 'Phường 10',
            code: 28276,
            value: 'phuong_10',
            division_type: 'phường',
            short_codename: 'phuong_10',
            parentId: 815,
          },
          {
            title: 'Phường Tân Long',
            code: 28279,
            value: 'phuong_tan_long',
            division_type: 'phường',
            short_codename: 'tan_long',
            parentId: 815,
          },
          {
            title: 'Xã Đạo Thạnh',
            code: 28282,
            value: 'xa_dao_thanh',
            division_type: 'xã',
            short_codename: 'dao_thanh',
            parentId: 815,
          },
          {
            title: 'Xã Trung An',
            code: 28285,
            value: 'xa_trung_an',
            division_type: 'xã',
            short_codename: 'trung_an',
            parentId: 815,
          },
          {
            title: 'Xã Mỹ Phong',
            code: 28288,
            value: 'xa_my_phong',
            division_type: 'xã',
            short_codename: 'my_phong',
            parentId: 815,
          },
          {
            title: 'Xã Tân Mỹ Chánh',
            code: 28291,
            value: 'xa_tan_my_chanh',
            division_type: 'xã',
            short_codename: 'tan_my_chanh',
            parentId: 815,
          },
          {
            title: 'Xã Phước Thạnh',
            code: 28567,
            value: 'xa_phuoc_thanh',
            division_type: 'xã',
            short_codename: 'phuoc_thanh',
            parentId: 815,
          },
          {
            title: 'Xã Thới Sơn',
            code: 28591,
            value: 'xa_thoi_son',
            division_type: 'xã',
            short_codename: 'thoi_son',
            parentId: 815,
          },
        ],
        parentId: 82,
      },
      {
        title: 'Thị xã Gò Công',
        code: 816,
        value: 'thi_xa_go_cong',
        division_type: 'thị xã',
        short_codename: 'go_cong',
        children: [
          {
            title: 'Phường 3',
            code: 28294,
            value: 'phuong_3',
            division_type: 'phường',
            short_codename: 'phuong_3',
            parentId: 816,
          },
          {
            title: 'Phường 2',
            code: 28297,
            value: 'phuong_2',
            division_type: 'phường',
            short_codename: 'phuong_2',
            parentId: 816,
          },
          {
            title: 'Phường 4',
            code: 28300,
            value: 'phuong_4',
            division_type: 'phường',
            short_codename: 'phuong_4',
            parentId: 816,
          },
          {
            title: 'Phường 1',
            code: 28303,
            value: 'phuong_1',
            division_type: 'phường',
            short_codename: 'phuong_1',
            parentId: 816,
          },
          {
            title: 'Phường 5',
            code: 28306,
            value: 'phuong_5',
            division_type: 'phường',
            short_codename: 'phuong_5',
            parentId: 816,
          },
          {
            title: 'Xã Long Hưng',
            code: 28309,
            value: 'xa_long_hung',
            division_type: 'xã',
            short_codename: 'long_hung',
            parentId: 816,
          },
          {
            title: 'Xã Long Thuận',
            code: 28312,
            value: 'xa_long_thuan',
            division_type: 'xã',
            short_codename: 'long_thuan',
            parentId: 816,
          },
          {
            title: 'Xã Long Chánh',
            code: 28315,
            value: 'xa_long_chanh',
            division_type: 'xã',
            short_codename: 'long_chanh',
            parentId: 816,
          },
          {
            title: 'Xã Long Hòa',
            code: 28318,
            value: 'xa_long_hoa',
            division_type: 'xã',
            short_codename: 'long_hoa',
            parentId: 816,
          },
          {
            title: 'Xã Bình Đông',
            code: 28708,
            value: 'xa_binh_dong',
            division_type: 'xã',
            short_codename: 'binh_dong',
            parentId: 816,
          },
          {
            title: 'Xã Bình Xuân',
            code: 28717,
            value: 'xa_binh_xuan',
            division_type: 'xã',
            short_codename: 'binh_xuan',
            parentId: 816,
          },
          {
            title: 'Xã Tân Trung',
            code: 28729,
            value: 'xa_tan_trung',
            division_type: 'xã',
            short_codename: 'tan_trung',
            parentId: 816,
          },
        ],
        parentId: 82,
      },
      {
        title: 'Thị xã Cai Lậy',
        code: 817,
        value: 'thi_xa_cai_lay',
        division_type: 'thị xã',
        short_codename: 'cai_lay',
        children: [
          {
            title: 'Phường 1',
            code: 28435,
            value: 'phuong_1',
            division_type: 'phường',
            short_codename: 'phuong_1',
            parentId: 817,
          },
          {
            title: 'Phường 2',
            code: 28436,
            value: 'phuong_2',
            division_type: 'phường',
            short_codename: 'phuong_2',
            parentId: 817,
          },
          {
            title: 'Phường 3',
            code: 28437,
            value: 'phuong_3',
            division_type: 'phường',
            short_codename: 'phuong_3',
            parentId: 817,
          },
          {
            title: 'Phường 4',
            code: 28439,
            value: 'phuong_4',
            division_type: 'phường',
            short_codename: 'phuong_4',
            parentId: 817,
          },
          {
            title: 'Phường 5',
            code: 28440,
            value: 'phuong_5',
            division_type: 'phường',
            short_codename: 'phuong_5',
            parentId: 817,
          },
          {
            title: 'Xã Mỹ Phước Tây',
            code: 28447,
            value: 'xa_my_phuoc_tay',
            division_type: 'xã',
            short_codename: 'my_phuoc_tay',
            parentId: 817,
          },
          {
            title: 'Xã Mỹ Hạnh Đông',
            code: 28450,
            value: 'xa_my_hanh_dong',
            division_type: 'xã',
            short_codename: 'my_hanh_dong',
            parentId: 817,
          },
          {
            title: 'Xã Mỹ Hạnh Trung',
            code: 28453,
            value: 'xa_my_hanh_trung',
            division_type: 'xã',
            short_codename: 'my_hanh_trung',
            parentId: 817,
          },
          {
            title: 'Xã Tân Phú',
            code: 28459,
            value: 'xa_tan_phu',
            division_type: 'xã',
            short_codename: 'tan_phu',
            parentId: 817,
          },
          {
            title: 'Xã Tân Bình',
            code: 28462,
            value: 'xa_tan_binh',
            division_type: 'xã',
            short_codename: 'tan_binh',
            parentId: 817,
          },
          {
            title: 'Xã Tân Hội',
            code: 28468,
            value: 'xa_tan_hoi',
            division_type: 'xã',
            short_codename: 'tan_hoi',
            parentId: 817,
          },
          {
            title: 'Phường Nhị Mỹ',
            code: 28474,
            value: 'phuong_nhi_my',
            division_type: 'phường',
            short_codename: 'nhi_my',
            parentId: 817,
          },
          {
            title: 'Xã Nhị Quý',
            code: 28477,
            value: 'xa_nhi_quy',
            division_type: 'xã',
            short_codename: 'nhi_quy',
            parentId: 817,
          },
          {
            title: 'Xã Thanh Hòa',
            code: 28480,
            value: 'xa_thanh_hoa',
            division_type: 'xã',
            short_codename: 'thanh_hoa',
            parentId: 817,
          },
          {
            title: 'Xã Phú Quý',
            code: 28483,
            value: 'xa_phu_quy',
            division_type: 'xã',
            short_codename: 'phu_quy',
            parentId: 817,
          },
          {
            title: 'Xã Long Khánh',
            code: 28486,
            value: 'xa_long_khanh',
            division_type: 'xã',
            short_codename: 'long_khanh',
            parentId: 817,
          },
        ],
        parentId: 82,
      },
      {
        title: 'Huyện Tân Phước',
        code: 818,
        value: 'huyen_tan_phuoc',
        division_type: 'huyện',
        short_codename: 'tan_phuoc',
        children: [
          {
            title: 'Thị trấn Mỹ Phước',
            code: 28321,
            value: 'thi_tran_my_phuoc',
            division_type: 'thị trấn',
            short_codename: 'my_phuoc',
            parentId: 818,
          },
          {
            title: 'Xã Tân Hòa Đông',
            code: 28324,
            value: 'xa_tan_hoa_dong',
            division_type: 'xã',
            short_codename: 'tan_hoa_dong',
            parentId: 818,
          },
          {
            title: 'Xã Thạnh Tân',
            code: 28327,
            value: 'xa_thanh_tan',
            division_type: 'xã',
            short_codename: 'thanh_tan',
            parentId: 818,
          },
          {
            title: 'Xã Thạnh Mỹ',
            code: 28330,
            value: 'xa_thanh_my',
            division_type: 'xã',
            short_codename: 'thanh_my',
            parentId: 818,
          },
          {
            title: 'Xã Thạnh Hoà',
            code: 28333,
            value: 'xa_thanh_hoa',
            division_type: 'xã',
            short_codename: 'thanh_hoa',
            parentId: 818,
          },
          {
            title: 'Xã Phú Mỹ',
            code: 28336,
            value: 'xa_phu_my',
            division_type: 'xã',
            short_codename: 'phu_my',
            parentId: 818,
          },
          {
            title: 'Xã Tân Hòa Thành',
            code: 28339,
            value: 'xa_tan_hoa_thanh',
            division_type: 'xã',
            short_codename: 'tan_hoa_thanh',
            parentId: 818,
          },
          {
            title: 'Xã Hưng Thạnh',
            code: 28342,
            value: 'xa_hung_thanh',
            division_type: 'xã',
            short_codename: 'hung_thanh',
            parentId: 818,
          },
          {
            title: 'Xã Tân Lập 1',
            code: 28345,
            value: 'xa_tan_lap_1',
            division_type: 'xã',
            short_codename: 'tan_lap_1',
            parentId: 818,
          },
          {
            title: 'Xã Tân Hòa Tây',
            code: 28348,
            value: 'xa_tan_hoa_tay',
            division_type: 'xã',
            short_codename: 'tan_hoa_tay',
            parentId: 818,
          },
          {
            title: 'Xã Tân Lập 2',
            code: 28354,
            value: 'xa_tan_lap_2',
            division_type: 'xã',
            short_codename: 'tan_lap_2',
            parentId: 818,
          },
          {
            title: 'Xã Phước Lập',
            code: 28357,
            value: 'xa_phuoc_lap',
            division_type: 'xã',
            short_codename: 'phuoc_lap',
            parentId: 818,
          },
        ],
        parentId: 82,
      },
      {
        title: 'Huyện Cái Bè',
        code: 819,
        value: 'huyen_cai_be',
        division_type: 'huyện',
        short_codename: 'cai_be',
        children: [
          {
            title: 'Thị trấn Cái Bè',
            code: 28360,
            value: 'thi_tran_cai_be',
            division_type: 'thị trấn',
            short_codename: 'cai_be',
            parentId: 819,
          },
          {
            title: 'Xã Hậu Mỹ Bắc B',
            code: 28363,
            value: 'xa_hau_my_bac_b',
            division_type: 'xã',
            short_codename: 'hau_my_bac_b',
            parentId: 819,
          },
          {
            title: 'Xã Hậu Mỹ Bắc A',
            code: 28366,
            value: 'xa_hau_my_bac_a',
            division_type: 'xã',
            short_codename: 'hau_my_bac_a',
            parentId: 819,
          },
          {
            title: 'Xã Mỹ Trung',
            code: 28369,
            value: 'xa_my_trung',
            division_type: 'xã',
            short_codename: 'my_trung',
            parentId: 819,
          },
          {
            title: 'Xã Hậu Mỹ Trinh',
            code: 28372,
            value: 'xa_hau_my_trinh',
            division_type: 'xã',
            short_codename: 'hau_my_trinh',
            parentId: 819,
          },
          {
            title: 'Xã Hậu Mỹ Phú',
            code: 28375,
            value: 'xa_hau_my_phu',
            division_type: 'xã',
            short_codename: 'hau_my_phu',
            parentId: 819,
          },
          {
            title: 'Xã Mỹ Tân',
            code: 28378,
            value: 'xa_my_tan',
            division_type: 'xã',
            short_codename: 'my_tan',
            parentId: 819,
          },
          {
            title: 'Xã Mỹ Lợi B',
            code: 28381,
            value: 'xa_my_loi_b',
            division_type: 'xã',
            short_codename: 'my_loi_b',
            parentId: 819,
          },
          {
            title: 'Xã Thiện Trung',
            code: 28384,
            value: 'xa_thien_trung',
            division_type: 'xã',
            short_codename: 'thien_trung',
            parentId: 819,
          },
          {
            title: 'Xã Mỹ Hội',
            code: 28387,
            value: 'xa_my_hoi',
            division_type: 'xã',
            short_codename: 'my_hoi',
            parentId: 819,
          },
          {
            title: 'Xã An Cư',
            code: 28390,
            value: 'xa_an_cu',
            division_type: 'xã',
            short_codename: 'an_cu',
            parentId: 819,
          },
          {
            title: 'Xã Hậu Thành',
            code: 28393,
            value: 'xa_hau_thanh',
            division_type: 'xã',
            short_codename: 'hau_thanh',
            parentId: 819,
          },
          {
            title: 'Xã Mỹ Lợi A',
            code: 28396,
            value: 'xa_my_loi_a',
            division_type: 'xã',
            short_codename: 'my_loi_a',
            parentId: 819,
          },
          {
            title: 'Xã Hòa Khánh',
            code: 28399,
            value: 'xa_hoa_khanh',
            division_type: 'xã',
            short_codename: 'hoa_khanh',
            parentId: 819,
          },
          {
            title: 'Xã Thiện Trí',
            code: 28402,
            value: 'xa_thien_tri',
            division_type: 'xã',
            short_codename: 'thien_tri',
            parentId: 819,
          },
          {
            title: 'Xã Mỹ Đức Đông',
            code: 28405,
            value: 'xa_my_duc_dong',
            division_type: 'xã',
            short_codename: 'my_duc_dong',
            parentId: 819,
          },
          {
            title: 'Xã Mỹ Đức Tây',
            code: 28408,
            value: 'xa_my_duc_tay',
            division_type: 'xã',
            short_codename: 'my_duc_tay',
            parentId: 819,
          },
          {
            title: 'Xã Đông Hòa Hiệp',
            code: 28411,
            value: 'xa_dong_hoa_hiep',
            division_type: 'xã',
            short_codename: 'dong_hoa_hiep',
            parentId: 819,
          },
          {
            title: 'Xã An Thái Đông',
            code: 28414,
            value: 'xa_an_thai_dong',
            division_type: 'xã',
            short_codename: 'an_thai_dong',
            parentId: 819,
          },
          {
            title: 'Xã Tân Hưng',
            code: 28417,
            value: 'xa_tan_hung',
            division_type: 'xã',
            short_codename: 'tan_hung',
            parentId: 819,
          },
          {
            title: 'Xã Mỹ Lương',
            code: 28420,
            value: 'xa_my_luong',
            division_type: 'xã',
            short_codename: 'my_luong',
            parentId: 819,
          },
          {
            title: 'Xã Tân Thanh',
            code: 28423,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 819,
          },
          {
            title: 'Xã An Thái Trung',
            code: 28426,
            value: 'xa_an_thai_trung',
            division_type: 'xã',
            short_codename: 'an_thai_trung',
            parentId: 819,
          },
          {
            title: 'Xã An Hữu',
            code: 28429,
            value: 'xa_an_huu',
            division_type: 'xã',
            short_codename: 'an_huu',
            parentId: 819,
          },
          {
            title: 'Xã Hòa Hưng',
            code: 28432,
            value: 'xa_hoa_hung',
            division_type: 'xã',
            short_codename: 'hoa_hung',
            parentId: 819,
          },
        ],
        parentId: 82,
      },
      {
        title: 'Huyện Cai Lậy',
        code: 820,
        value: 'huyen_cai_lay',
        division_type: 'huyện',
        short_codename: 'huyen_cai_lay',
        children: [
          {
            title: 'Xã Thạnh Lộc',
            code: 28438,
            value: 'xa_thanh_loc',
            division_type: 'xã',
            short_codename: 'thanh_loc',
            parentId: 820,
          },
          {
            title: 'Xã Mỹ Thành Bắc',
            code: 28441,
            value: 'xa_my_thanh_bac',
            division_type: 'xã',
            short_codename: 'my_thanh_bac',
            parentId: 820,
          },
          {
            title: 'Xã Phú Cường',
            code: 28444,
            value: 'xa_phu_cuong',
            division_type: 'xã',
            short_codename: 'phu_cuong',
            parentId: 820,
          },
          {
            title: 'Xã Mỹ Thành Nam',
            code: 28456,
            value: 'xa_my_thanh_nam',
            division_type: 'xã',
            short_codename: 'my_thanh_nam',
            parentId: 820,
          },
          {
            title: 'Xã Phú Nhuận',
            code: 28465,
            value: 'xa_phu_nhuan',
            division_type: 'xã',
            short_codename: 'phu_nhuan',
            parentId: 820,
          },
          {
            title: 'Xã Bình Phú',
            code: 28471,
            value: 'xa_binh_phu',
            division_type: 'xã',
            short_codename: 'binh_phu',
            parentId: 820,
          },
          {
            title: 'Xã Cẩm Sơn',
            code: 28489,
            value: 'xa_cam_son',
            division_type: 'xã',
            short_codename: 'cam_son',
            parentId: 820,
          },
          {
            title: 'Xã Phú An',
            code: 28492,
            value: 'xa_phu_an',
            division_type: 'xã',
            short_codename: 'phu_an',
            parentId: 820,
          },
          {
            title: 'Xã Mỹ Long',
            code: 28495,
            value: 'xa_my_long',
            division_type: 'xã',
            short_codename: 'my_long',
            parentId: 820,
          },
          {
            title: 'Xã Long Tiên',
            code: 28498,
            value: 'xa_long_tien',
            division_type: 'xã',
            short_codename: 'long_tien',
            parentId: 820,
          },
          {
            title: 'Xã Hiệp Đức',
            code: 28501,
            value: 'xa_hiep_duc',
            division_type: 'xã',
            short_codename: 'hiep_duc',
            parentId: 820,
          },
          {
            title: 'Xã Long Trung',
            code: 28504,
            value: 'xa_long_trung',
            division_type: 'xã',
            short_codename: 'long_trung',
            parentId: 820,
          },
          {
            title: 'Xã Hội Xuân',
            code: 28507,
            value: 'xa_hoi_xuan',
            division_type: 'xã',
            short_codename: 'hoi_xuan',
            parentId: 820,
          },
          {
            title: 'Xã Tân Phong',
            code: 28510,
            value: 'xa_tan_phong',
            division_type: 'xã',
            short_codename: 'tan_phong',
            parentId: 820,
          },
          {
            title: 'Xã Tam Bình',
            code: 28513,
            value: 'xa_tam_binh',
            division_type: 'xã',
            short_codename: 'tam_binh',
            parentId: 820,
          },
          {
            title: 'Xã Ngũ Hiệp',
            code: 28516,
            value: 'xa_ngu_hiep',
            division_type: 'xã',
            short_codename: 'ngu_hiep',
            parentId: 820,
          },
        ],
        parentId: 82,
      },
      {
        title: 'Huyện Châu Thành',
        code: 821,
        value: 'huyen_chau_thanh',
        division_type: 'huyện',
        short_codename: 'chau_thanh',
        children: [
          {
            title: 'Thị trấn Tân Hiệp',
            code: 28519,
            value: 'thi_tran_tan_hiep',
            division_type: 'thị trấn',
            short_codename: 'tan_hiep',
            parentId: 821,
          },
          {
            title: 'Xã Tân Hội Đông',
            code: 28522,
            value: 'xa_tan_hoi_dong',
            division_type: 'xã',
            short_codename: 'tan_hoi_dong',
            parentId: 821,
          },
          {
            title: 'Xã Tân Hương',
            code: 28525,
            value: 'xa_tan_huong',
            division_type: 'xã',
            short_codename: 'tan_huong',
            parentId: 821,
          },
          {
            title: 'Xã Tân Lý Đông',
            code: 28528,
            value: 'xa_tan_ly_dong',
            division_type: 'xã',
            short_codename: 'tan_ly_dong',
            parentId: 821,
          },
          {
            title: 'Xã Tân Lý Tây',
            code: 28531,
            value: 'xa_tan_ly_tay',
            division_type: 'xã',
            short_codename: 'tan_ly_tay',
            parentId: 821,
          },
          {
            title: 'Xã Thân Cửu Nghĩa',
            code: 28534,
            value: 'xa_than_cuu_nghia',
            division_type: 'xã',
            short_codename: 'than_cuu_nghia',
            parentId: 821,
          },
          {
            title: 'Xã Tam Hiệp',
            code: 28537,
            value: 'xa_tam_hiep',
            division_type: 'xã',
            short_codename: 'tam_hiep',
            parentId: 821,
          },
          {
            title: 'Xã Điềm Hy',
            code: 28540,
            value: 'xa_diem_hy',
            division_type: 'xã',
            short_codename: 'diem_hy',
            parentId: 821,
          },
          {
            title: 'Xã Nhị Bình',
            code: 28543,
            value: 'xa_nhi_binh',
            division_type: 'xã',
            short_codename: 'nhi_binh',
            parentId: 821,
          },
          {
            title: 'Xã Dưỡng Điềm',
            code: 28546,
            value: 'xa_duong_diem',
            division_type: 'xã',
            short_codename: 'duong_diem',
            parentId: 821,
          },
          {
            title: 'Xã Đông Hòa',
            code: 28549,
            value: 'xa_dong_hoa',
            division_type: 'xã',
            short_codename: 'dong_hoa',
            parentId: 821,
          },
          {
            title: 'Xã Long Định',
            code: 28552,
            value: 'xa_long_dinh',
            division_type: 'xã',
            short_codename: 'long_dinh',
            parentId: 821,
          },
          {
            title: 'Xã Hữu Đạo',
            code: 28555,
            value: 'xa_huu_dao',
            division_type: 'xã',
            short_codename: 'huu_dao',
            parentId: 821,
          },
          {
            title: 'Xã Long An',
            code: 28558,
            value: 'xa_long_an',
            division_type: 'xã',
            short_codename: 'long_an',
            parentId: 821,
          },
          {
            title: 'Xã Long Hưng',
            code: 28561,
            value: 'xa_long_hung',
            division_type: 'xã',
            short_codename: 'long_hung',
            parentId: 821,
          },
          {
            title: 'Xã Bình Trưng',
            code: 28564,
            value: 'xa_binh_trung',
            division_type: 'xã',
            short_codename: 'binh_trung',
            parentId: 821,
          },
          {
            title: 'Xã Thạnh Phú',
            code: 28570,
            value: 'xa_thanh_phu',
            division_type: 'xã',
            short_codename: 'thanh_phu',
            parentId: 821,
          },
          {
            title: 'Xã Bàn Long',
            code: 28573,
            value: 'xa_ban_long',
            division_type: 'xã',
            short_codename: 'ban_long',
            parentId: 821,
          },
          {
            title: 'Xã Vĩnh Kim',
            code: 28576,
            value: 'xa_vinh_kim',
            division_type: 'xã',
            short_codename: 'vinh_kim',
            parentId: 821,
          },
          {
            title: 'Xã Bình Đức',
            code: 28579,
            value: 'xa_binh_duc',
            division_type: 'xã',
            short_codename: 'binh_duc',
            parentId: 821,
          },
          {
            title: 'Xã Song Thuận',
            code: 28582,
            value: 'xa_song_thuan',
            division_type: 'xã',
            short_codename: 'song_thuan',
            parentId: 821,
          },
          {
            title: 'Xã Kim Sơn',
            code: 28585,
            value: 'xa_kim_son',
            division_type: 'xã',
            short_codename: 'kim_son',
            parentId: 821,
          },
          {
            title: 'Xã Phú Phong',
            code: 28588,
            value: 'xa_phu_phong',
            division_type: 'xã',
            short_codename: 'phu_phong',
            parentId: 821,
          },
        ],
        parentId: 82,
      },
      {
        title: 'Huyện Chợ Gạo',
        code: 822,
        value: 'huyen_cho_gao',
        division_type: 'huyện',
        short_codename: 'cho_gao',
        children: [
          {
            title: 'Thị trấn Chợ Gạo',
            code: 28594,
            value: 'thi_tran_cho_gao',
            division_type: 'thị trấn',
            short_codename: 'cho_gao',
            parentId: 822,
          },
          {
            title: 'Xã Trung Hòa',
            code: 28597,
            value: 'xa_trung_hoa',
            division_type: 'xã',
            short_codename: 'trung_hoa',
            parentId: 822,
          },
          {
            title: 'Xã Hòa Tịnh',
            code: 28600,
            value: 'xa_hoa_tinh',
            division_type: 'xã',
            short_codename: 'hoa_tinh',
            parentId: 822,
          },
          {
            title: 'Xã Mỹ Tịnh An',
            code: 28603,
            value: 'xa_my_tinh_an',
            division_type: 'xã',
            short_codename: 'my_tinh_an',
            parentId: 822,
          },
          {
            title: 'Xã Tân Bình Thạnh',
            code: 28606,
            value: 'xa_tan_binh_thanh',
            division_type: 'xã',
            short_codename: 'tan_binh_thanh',
            parentId: 822,
          },
          {
            title: 'Xã Phú Kiết',
            code: 28609,
            value: 'xa_phu_kiet',
            division_type: 'xã',
            short_codename: 'phu_kiet',
            parentId: 822,
          },
          {
            title: 'Xã Lương Hòa Lạc',
            code: 28612,
            value: 'xa_luong_hoa_lac',
            division_type: 'xã',
            short_codename: 'luong_hoa_lac',
            parentId: 822,
          },
          {
            title: 'Xã Thanh Bình',
            code: 28615,
            value: 'xa_thanh_binh',
            division_type: 'xã',
            short_codename: 'thanh_binh',
            parentId: 822,
          },
          {
            title: 'Xã Quơn Long',
            code: 28618,
            value: 'xa_quon_long',
            division_type: 'xã',
            short_codename: 'quon_long',
            parentId: 822,
          },
          {
            title: 'Xã Bình Phục Nhứt',
            code: 28621,
            value: 'xa_binh_phuc_nhut',
            division_type: 'xã',
            short_codename: 'binh_phuc_nhut',
            parentId: 822,
          },
          {
            title: 'Xã Đăng Hưng Phước',
            code: 28624,
            value: 'xa_dang_hung_phuoc',
            division_type: 'xã',
            short_codename: 'dang_hung_phuoc',
            parentId: 822,
          },
          {
            title: 'Xã Tân Thuận Bình',
            code: 28627,
            value: 'xa_tan_thuan_binh',
            division_type: 'xã',
            short_codename: 'tan_thuan_binh',
            parentId: 822,
          },
          {
            title: 'Xã Song Bình',
            code: 28630,
            value: 'xa_song_binh',
            division_type: 'xã',
            short_codename: 'song_binh',
            parentId: 822,
          },
          {
            title: 'Xã Bình Phan',
            code: 28633,
            value: 'xa_binh_phan',
            division_type: 'xã',
            short_codename: 'binh_phan',
            parentId: 822,
          },
          {
            title: 'Xã Long Bình Điền',
            code: 28636,
            value: 'xa_long_binh_dien',
            division_type: 'xã',
            short_codename: 'long_binh_dien',
            parentId: 822,
          },
          {
            title: 'Xã An Thạnh Thủy',
            code: 28639,
            value: 'xa_an_thanh_thuy',
            division_type: 'xã',
            short_codename: 'an_thanh_thuy',
            parentId: 822,
          },
          {
            title: 'Xã Xuân Đông',
            code: 28642,
            value: 'xa_xuan_dong',
            division_type: 'xã',
            short_codename: 'xuan_dong',
            parentId: 822,
          },
          {
            title: 'Xã Hòa Định',
            code: 28645,
            value: 'xa_hoa_dinh',
            division_type: 'xã',
            short_codename: 'hoa_dinh',
            parentId: 822,
          },
          {
            title: 'Xã Bình Ninh',
            code: 28648,
            value: 'xa_binh_ninh',
            division_type: 'xã',
            short_codename: 'binh_ninh',
            parentId: 822,
          },
        ],
        parentId: 82,
      },
      {
        title: 'Huyện Gò Công Tây',
        code: 823,
        value: 'huyen_go_cong_tay',
        division_type: 'huyện',
        short_codename: 'go_cong_tay',
        children: [
          {
            title: 'Thị trấn Vĩnh Bình',
            code: 28651,
            value: 'thi_tran_vinh_binh',
            division_type: 'thị trấn',
            short_codename: 'vinh_binh',
            parentId: 823,
          },
          {
            title: 'Xã Đồng Sơn',
            code: 28654,
            value: 'xa_dong_son',
            division_type: 'xã',
            short_codename: 'dong_son',
            parentId: 823,
          },
          {
            title: 'Xã Bình Phú',
            code: 28657,
            value: 'xa_binh_phu',
            division_type: 'xã',
            short_codename: 'binh_phu',
            parentId: 823,
          },
          {
            title: 'Xã Đồng Thạnh',
            code: 28660,
            value: 'xa_dong_thanh',
            division_type: 'xã',
            short_codename: 'dong_thanh',
            parentId: 823,
          },
          {
            title: 'Xã Thành Công',
            code: 28663,
            value: 'xa_thanh_cong',
            division_type: 'xã',
            short_codename: 'thanh_cong',
            parentId: 823,
          },
          {
            title: 'Xã Bình Nhì',
            code: 28666,
            value: 'xa_binh_nhi',
            division_type: 'xã',
            short_codename: 'binh_nhi',
            parentId: 823,
          },
          {
            title: 'Xã Yên Luông',
            code: 28669,
            value: 'xa_yen_luong',
            division_type: 'xã',
            short_codename: 'yen_luong',
            parentId: 823,
          },
          {
            title: 'Xã Thạnh Trị',
            code: 28672,
            value: 'xa_thanh_tri',
            division_type: 'xã',
            short_codename: 'thanh_tri',
            parentId: 823,
          },
          {
            title: 'Xã Thạnh Nhựt',
            code: 28675,
            value: 'xa_thanh_nhut',
            division_type: 'xã',
            short_codename: 'thanh_nhut',
            parentId: 823,
          },
          {
            title: 'Xã Long Vĩnh',
            code: 28678,
            value: 'xa_long_vinh',
            division_type: 'xã',
            short_codename: 'long_vinh',
            parentId: 823,
          },
          {
            title: 'Xã Bình Tân',
            code: 28681,
            value: 'xa_binh_tan',
            division_type: 'xã',
            short_codename: 'binh_tan',
            parentId: 823,
          },
          {
            title: 'Xã Vĩnh Hựu',
            code: 28684,
            value: 'xa_vinh_huu',
            division_type: 'xã',
            short_codename: 'vinh_huu',
            parentId: 823,
          },
          {
            title: 'Xã Long Bình',
            code: 28687,
            value: 'xa_long_binh',
            division_type: 'xã',
            short_codename: 'long_binh',
            parentId: 823,
          },
        ],
        parentId: 82,
      },
      {
        title: 'Huyện Gò Công Đông',
        code: 824,
        value: 'huyen_go_cong_dong',
        division_type: 'huyện',
        short_codename: 'go_cong_dong',
        children: [
          {
            title: 'Thị trấn Tân Hòa',
            code: 28702,
            value: 'thi_tran_tan_hoa',
            division_type: 'thị trấn',
            short_codename: 'tan_hoa',
            parentId: 824,
          },
          {
            title: 'Xã Tăng Hoà',
            code: 28705,
            value: 'xa_tang_hoa',
            division_type: 'xã',
            short_codename: 'tang_hoa',
            parentId: 824,
          },
          {
            title: 'Xã Tân Phước',
            code: 28711,
            value: 'xa_tan_phuoc',
            division_type: 'xã',
            short_codename: 'tan_phuoc',
            parentId: 824,
          },
          {
            title: 'Xã Gia Thuận',
            code: 28714,
            value: 'xa_gia_thuan',
            division_type: 'xã',
            short_codename: 'gia_thuan',
            parentId: 824,
          },
          {
            title: 'Thị trấn Vàm Láng',
            code: 28720,
            value: 'thi_tran_vam_lang',
            division_type: 'thị trấn',
            short_codename: 'vam_lang',
            parentId: 824,
          },
          {
            title: 'Xã Tân Tây',
            code: 28723,
            value: 'xa_tan_tay',
            division_type: 'xã',
            short_codename: 'tan_tay',
            parentId: 824,
          },
          {
            title: 'Xã Kiểng Phước',
            code: 28726,
            value: 'xa_kieng_phuoc',
            division_type: 'xã',
            short_codename: 'kieng_phuoc',
            parentId: 824,
          },
          {
            title: 'Xã Tân Đông',
            code: 28732,
            value: 'xa_tan_dong',
            division_type: 'xã',
            short_codename: 'tan_dong',
            parentId: 824,
          },
          {
            title: 'Xã Bình Ân',
            code: 28735,
            value: 'xa_binh_an',
            division_type: 'xã',
            short_codename: 'binh_an',
            parentId: 824,
          },
          {
            title: 'Xã Tân Điền',
            code: 28738,
            value: 'xa_tan_dien',
            division_type: 'xã',
            short_codename: 'tan_dien',
            parentId: 824,
          },
          {
            title: 'Xã Bình Nghị',
            code: 28741,
            value: 'xa_binh_nghi',
            division_type: 'xã',
            short_codename: 'binh_nghi',
            parentId: 824,
          },
          {
            title: 'Xã Phước Trung',
            code: 28744,
            value: 'xa_phuoc_trung',
            division_type: 'xã',
            short_codename: 'phuoc_trung',
            parentId: 824,
          },
          {
            title: 'Xã Tân Thành',
            code: 28747,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 824,
          },
        ],
        parentId: 82,
      },
      {
        title: 'Huyện Tân Phú Đông',
        code: 825,
        value: 'huyen_tan_phu_dong',
        division_type: 'huyện',
        short_codename: 'tan_phu_dong',
        children: [
          {
            title: 'Xã Tân Thới',
            code: 28690,
            value: 'xa_tan_thoi',
            division_type: 'xã',
            short_codename: 'tan_thoi',
            parentId: 825,
          },
          {
            title: 'Xã Tân Phú',
            code: 28693,
            value: 'xa_tan_phu',
            division_type: 'xã',
            short_codename: 'tan_phu',
            parentId: 825,
          },
          {
            title: 'Xã Phú Thạnh',
            code: 28696,
            value: 'xa_phu_thanh',
            division_type: 'xã',
            short_codename: 'phu_thanh',
            parentId: 825,
          },
          {
            title: 'Xã Tân Thạnh',
            code: 28699,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 825,
          },
          {
            title: 'Xã Phú Đông',
            code: 28750,
            value: 'xa_phu_dong',
            division_type: 'xã',
            short_codename: 'phu_dong',
            parentId: 825,
          },
          {
            title: 'Xã Phú Tân',
            code: 28753,
            value: 'xa_phu_tan',
            division_type: 'xã',
            short_codename: 'phu_tan',
            parentId: 825,
          },
        ],
        parentId: 82,
      },
    ],
  },
  {
    title: 'Tỉnh Bến Tre',
    code: 83,
    value: 'tinh_ben_tre',
    division_type: 'tỉnh',
    phone_code: 275,
    children: [
      {
        title: 'Thành phố Bến Tre',
        code: 829,
        value: 'thanh_pho_ben_tre',
        division_type: 'thành phố',
        short_codename: 'ben_tre',
        children: [
          {
            title: 'Phường Phú Khương',
            code: 28756,
            value: 'phuong_phu_khuong',
            division_type: 'phường',
            short_codename: 'phu_khuong',
            parentId: 829,
          },
          {
            title: 'Phường Phú Tân',
            code: 28757,
            value: 'phuong_phu_tan',
            division_type: 'phường',
            short_codename: 'phu_tan',
            parentId: 829,
          },
          {
            title: 'Phường 8',
            code: 28759,
            value: 'phuong_8',
            division_type: 'phường',
            short_codename: 'phuong_8',
            parentId: 829,
          },
          {
            title: 'Phường 6',
            code: 28762,
            value: 'phuong_6',
            division_type: 'phường',
            short_codename: 'phuong_6',
            parentId: 829,
          },
          {
            title: 'Phường 4',
            code: 28765,
            value: 'phuong_4',
            division_type: 'phường',
            short_codename: 'phuong_4',
            parentId: 829,
          },
          {
            title: 'Phường 5',
            code: 28768,
            value: 'phuong_5',
            division_type: 'phường',
            short_codename: 'phuong_5',
            parentId: 829,
          },
          {
            title: 'Phường An Hội',
            code: 28777,
            value: 'phuong_an_hoi',
            division_type: 'phường',
            short_codename: 'an_hoi',
            parentId: 829,
          },
          {
            title: 'Phường 7',
            code: 28780,
            value: 'phuong_7',
            division_type: 'phường',
            short_codename: 'phuong_7',
            parentId: 829,
          },
          {
            title: 'Xã Sơn Đông',
            code: 28783,
            value: 'xa_son_dong',
            division_type: 'xã',
            short_codename: 'son_dong',
            parentId: 829,
          },
          {
            title: 'Xã Phú Hưng',
            code: 28786,
            value: 'xa_phu_hung',
            division_type: 'xã',
            short_codename: 'phu_hung',
            parentId: 829,
          },
          {
            title: 'Xã Bình Phú',
            code: 28789,
            value: 'xa_binh_phu',
            division_type: 'xã',
            short_codename: 'binh_phu',
            parentId: 829,
          },
          {
            title: 'Xã Mỹ Thạnh An',
            code: 28792,
            value: 'xa_my_thanh_an',
            division_type: 'xã',
            short_codename: 'my_thanh_an',
            parentId: 829,
          },
          {
            title: 'Xã Nhơn Thạnh',
            code: 28795,
            value: 'xa_nhon_thanh',
            division_type: 'xã',
            short_codename: 'nhon_thanh',
            parentId: 829,
          },
          {
            title: 'Xã Phú Nhuận',
            code: 28798,
            value: 'xa_phu_nhuan',
            division_type: 'xã',
            short_codename: 'phu_nhuan',
            parentId: 829,
          },
        ],
        parentId: 83,
      },
      {
        title: 'Huyện Châu Thành',
        code: 831,
        value: 'huyen_chau_thanh',
        division_type: 'huyện',
        short_codename: 'chau_thanh',
        children: [
          {
            title: 'Thị trấn Châu Thành',
            code: 28801,
            value: 'thi_tran_chau_thanh',
            division_type: 'thị trấn',
            short_codename: 'chau_thanh',
            parentId: 831,
          },
          {
            title: 'Xã Tân Thạch',
            code: 28804,
            value: 'xa_tan_thach',
            division_type: 'xã',
            short_codename: 'tan_thach',
            parentId: 831,
          },
          {
            title: 'Xã Qưới Sơn',
            code: 28807,
            value: 'xa_quoi_son',
            division_type: 'xã',
            short_codename: 'quoi_son',
            parentId: 831,
          },
          {
            title: 'Xã An Khánh',
            code: 28810,
            value: 'xa_an_khanh',
            division_type: 'xã',
            short_codename: 'an_khanh',
            parentId: 831,
          },
          {
            title: 'Xã Giao Long',
            code: 28813,
            value: 'xa_giao_long',
            division_type: 'xã',
            short_codename: 'giao_long',
            parentId: 831,
          },
          {
            title: 'Xã Phú Túc',
            code: 28819,
            value: 'xa_phu_tuc',
            division_type: 'xã',
            short_codename: 'phu_tuc',
            parentId: 831,
          },
          {
            title: 'Xã Phú Đức',
            code: 28822,
            value: 'xa_phu_duc',
            division_type: 'xã',
            short_codename: 'phu_duc',
            parentId: 831,
          },
          {
            title: 'Xã Phú An Hòa',
            code: 28825,
            value: 'xa_phu_an_hoa',
            division_type: 'xã',
            short_codename: 'phu_an_hoa',
            parentId: 831,
          },
          {
            title: 'Xã An Phước',
            code: 28828,
            value: 'xa_an_phuoc',
            division_type: 'xã',
            short_codename: 'an_phuoc',
            parentId: 831,
          },
          {
            title: 'Xã Tam Phước',
            code: 28831,
            value: 'xa_tam_phuoc',
            division_type: 'xã',
            short_codename: 'tam_phuoc',
            parentId: 831,
          },
          {
            title: 'Xã Thành Triệu',
            code: 28834,
            value: 'xa_thanh_trieu',
            division_type: 'xã',
            short_codename: 'thanh_trieu',
            parentId: 831,
          },
          {
            title: 'Xã Tường Đa',
            code: 28837,
            value: 'xa_tuong_da',
            division_type: 'xã',
            short_codename: 'tuong_da',
            parentId: 831,
          },
          {
            title: 'Xã Tân Phú',
            code: 28840,
            value: 'xa_tan_phu',
            division_type: 'xã',
            short_codename: 'tan_phu',
            parentId: 831,
          },
          {
            title: 'Xã Quới Thành',
            code: 28843,
            value: 'xa_quoi_thanh',
            division_type: 'xã',
            short_codename: 'quoi_thanh',
            parentId: 831,
          },
          {
            title: 'Xã Phước Thạnh',
            code: 28846,
            value: 'xa_phuoc_thanh',
            division_type: 'xã',
            short_codename: 'phuoc_thanh',
            parentId: 831,
          },
          {
            title: 'Xã An Hóa',
            code: 28849,
            value: 'xa_an_hoa',
            division_type: 'xã',
            short_codename: 'an_hoa',
            parentId: 831,
          },
          {
            title: 'Xã Tiên Long',
            code: 28852,
            value: 'xa_tien_long',
            division_type: 'xã',
            short_codename: 'tien_long',
            parentId: 831,
          },
          {
            title: 'Xã An Hiệp',
            code: 28855,
            value: 'xa_an_hiep',
            division_type: 'xã',
            short_codename: 'an_hiep',
            parentId: 831,
          },
          {
            title: 'Xã Hữu Định',
            code: 28858,
            value: 'xa_huu_dinh',
            division_type: 'xã',
            short_codename: 'huu_dinh',
            parentId: 831,
          },
          {
            title: 'Xã Tiên Thủy',
            code: 28861,
            value: 'xa_tien_thuy',
            division_type: 'xã',
            short_codename: 'tien_thuy',
            parentId: 831,
          },
          {
            title: 'Xã Sơn Hòa',
            code: 28864,
            value: 'xa_son_hoa',
            division_type: 'xã',
            short_codename: 'son_hoa',
            parentId: 831,
          },
        ],
        parentId: 83,
      },
      {
        title: 'Huyện Chợ Lách',
        code: 832,
        value: 'huyen_cho_lach',
        division_type: 'huyện',
        short_codename: 'cho_lach',
        children: [
          {
            title: 'Thị trấn Chợ Lách',
            code: 28870,
            value: 'thi_tran_cho_lach',
            division_type: 'thị trấn',
            short_codename: 'cho_lach',
            parentId: 832,
          },
          {
            title: 'Xã Phú Phụng',
            code: 28873,
            value: 'xa_phu_phung',
            division_type: 'xã',
            short_codename: 'phu_phung',
            parentId: 832,
          },
          {
            title: 'Xã Sơn Định',
            code: 28876,
            value: 'xa_son_dinh',
            division_type: 'xã',
            short_codename: 'son_dinh',
            parentId: 832,
          },
          {
            title: 'Xã Vĩnh Bình',
            code: 28879,
            value: 'xa_vinh_binh',
            division_type: 'xã',
            short_codename: 'vinh_binh',
            parentId: 832,
          },
          {
            title: 'Xã Hòa Nghĩa',
            code: 28882,
            value: 'xa_hoa_nghia',
            division_type: 'xã',
            short_codename: 'hoa_nghia',
            parentId: 832,
          },
          {
            title: 'Xã Long Thới',
            code: 28885,
            value: 'xa_long_thoi',
            division_type: 'xã',
            short_codename: 'long_thoi',
            parentId: 832,
          },
          {
            title: 'Xã Phú Sơn',
            code: 28888,
            value: 'xa_phu_son',
            division_type: 'xã',
            short_codename: 'phu_son',
            parentId: 832,
          },
          {
            title: 'Xã Tân Thiềng',
            code: 28891,
            value: 'xa_tan_thieng',
            division_type: 'xã',
            short_codename: 'tan_thieng',
            parentId: 832,
          },
          {
            title: 'Xã Vĩnh Thành',
            code: 28894,
            value: 'xa_vinh_thanh',
            division_type: 'xã',
            short_codename: 'vinh_thanh',
            parentId: 832,
          },
          {
            title: 'Xã Vĩnh Hòa',
            code: 28897,
            value: 'xa_vinh_hoa',
            division_type: 'xã',
            short_codename: 'vinh_hoa',
            parentId: 832,
          },
          {
            title: 'Xã Hưng Khánh Trung B',
            code: 28900,
            value: 'xa_hung_khanh_trung_b',
            division_type: 'xã',
            short_codename: 'hung_khanh_trung_b',
            parentId: 832,
          },
        ],
        parentId: 83,
      },
      {
        title: 'Huyện Mỏ Cày Nam',
        code: 833,
        value: 'huyen_mo_cay_nam',
        division_type: 'huyện',
        short_codename: 'mo_cay_nam',
        children: [
          {
            title: 'Thị trấn Mỏ Cày',
            code: 28903,
            value: 'thi_tran_mo_cay',
            division_type: 'thị trấn',
            short_codename: 'mo_cay',
            parentId: 833,
          },
          {
            title: 'Xã Định Thủy',
            code: 28930,
            value: 'xa_dinh_thuy',
            division_type: 'xã',
            short_codename: 'dinh_thuy',
            parentId: 833,
          },
          {
            title: 'Xã Đa Phước Hội',
            code: 28939,
            value: 'xa_da_phuoc_hoi',
            division_type: 'xã',
            short_codename: 'da_phuoc_hoi',
            parentId: 833,
          },
          {
            title: 'Xã Tân Hội',
            code: 28940,
            value: 'xa_tan_hoi',
            division_type: 'xã',
            short_codename: 'tan_hoi',
            parentId: 833,
          },
          {
            title: 'Xã Phước Hiệp',
            code: 28942,
            value: 'xa_phuoc_hiep',
            division_type: 'xã',
            short_codename: 'phuoc_hiep',
            parentId: 833,
          },
          {
            title: 'Xã Bình Khánh',
            code: 28945,
            value: 'xa_binh_khanh',
            division_type: 'xã',
            short_codename: 'binh_khanh',
            parentId: 833,
          },
          {
            title: 'Xã An Thạnh',
            code: 28951,
            value: 'xa_an_thanh',
            division_type: 'xã',
            short_codename: 'an_thanh',
            parentId: 833,
          },
          {
            title: 'Xã An Định',
            code: 28957,
            value: 'xa_an_dinh',
            division_type: 'xã',
            short_codename: 'an_dinh',
            parentId: 833,
          },
          {
            title: 'Xã Thành Thới B',
            code: 28960,
            value: 'xa_thanh_thoi_b',
            division_type: 'xã',
            short_codename: 'thanh_thoi_b',
            parentId: 833,
          },
          {
            title: 'Xã Tân Trung',
            code: 28963,
            value: 'xa_tan_trung',
            division_type: 'xã',
            short_codename: 'tan_trung',
            parentId: 833,
          },
          {
            title: 'Xã An Thới',
            code: 28966,
            value: 'xa_an_thoi',
            division_type: 'xã',
            short_codename: 'an_thoi',
            parentId: 833,
          },
          {
            title: 'Xã Thành Thới A',
            code: 28969,
            value: 'xa_thanh_thoi_a',
            division_type: 'xã',
            short_codename: 'thanh_thoi_a',
            parentId: 833,
          },
          {
            title: 'Xã Minh Đức',
            code: 28972,
            value: 'xa_minh_duc',
            division_type: 'xã',
            short_codename: 'minh_duc',
            parentId: 833,
          },
          {
            title: 'Xã Ngãi Đăng',
            code: 28975,
            value: 'xa_ngai_dang',
            division_type: 'xã',
            short_codename: 'ngai_dang',
            parentId: 833,
          },
          {
            title: 'Xã Cẩm Sơn',
            code: 28978,
            value: 'xa_cam_son',
            division_type: 'xã',
            short_codename: 'cam_son',
            parentId: 833,
          },
          {
            title: 'Xã Hương Mỹ',
            code: 28981,
            value: 'xa_huong_my',
            division_type: 'xã',
            short_codename: 'huong_my',
            parentId: 833,
          },
        ],
        parentId: 83,
      },
      {
        title: 'Huyện Giồng Trôm',
        code: 834,
        value: 'huyen_giong_trom',
        division_type: 'huyện',
        short_codename: 'giong_trom',
        children: [
          {
            title: 'Thị trấn Giồng Trôm',
            code: 28984,
            value: 'thi_tran_giong_trom',
            division_type: 'thị trấn',
            short_codename: 'giong_trom',
            parentId: 834,
          },
          {
            title: 'Xã Phong Nẫm',
            code: 28987,
            value: 'xa_phong_nam',
            division_type: 'xã',
            short_codename: 'phong_nam',
            parentId: 834,
          },
          {
            title: 'Xã Mỹ Thạnh',
            code: 28993,
            value: 'xa_my_thanh',
            division_type: 'xã',
            short_codename: 'my_thanh',
            parentId: 834,
          },
          {
            title: 'Xã Châu Hòa',
            code: 28996,
            value: 'xa_chau_hoa',
            division_type: 'xã',
            short_codename: 'chau_hoa',
            parentId: 834,
          },
          {
            title: 'Xã Lương Hòa',
            code: 28999,
            value: 'xa_luong_hoa',
            division_type: 'xã',
            short_codename: 'luong_hoa',
            parentId: 834,
          },
          {
            title: 'Xã Lương Quới',
            code: 29002,
            value: 'xa_luong_quoi',
            division_type: 'xã',
            short_codename: 'luong_quoi',
            parentId: 834,
          },
          {
            title: 'Xã Lương Phú',
            code: 29005,
            value: 'xa_luong_phu',
            division_type: 'xã',
            short_codename: 'luong_phu',
            parentId: 834,
          },
          {
            title: 'Xã Châu Bình',
            code: 29008,
            value: 'xa_chau_binh',
            division_type: 'xã',
            short_codename: 'chau_binh',
            parentId: 834,
          },
          {
            title: 'Xã Thuận Điền',
            code: 29011,
            value: 'xa_thuan_dien',
            division_type: 'xã',
            short_codename: 'thuan_dien',
            parentId: 834,
          },
          {
            title: 'Xã Sơn Phú',
            code: 29014,
            value: 'xa_son_phu',
            division_type: 'xã',
            short_codename: 'son_phu',
            parentId: 834,
          },
          {
            title: 'Xã Bình Hoà',
            code: 29017,
            value: 'xa_binh_hoa',
            division_type: 'xã',
            short_codename: 'binh_hoa',
            parentId: 834,
          },
          {
            title: 'Xã Phước Long',
            code: 29020,
            value: 'xa_phuoc_long',
            division_type: 'xã',
            short_codename: 'phuoc_long',
            parentId: 834,
          },
          {
            title: 'Xã Hưng Phong',
            code: 29023,
            value: 'xa_hung_phong',
            division_type: 'xã',
            short_codename: 'hung_phong',
            parentId: 834,
          },
          {
            title: 'Xã Long Mỹ',
            code: 29026,
            value: 'xa_long_my',
            division_type: 'xã',
            short_codename: 'long_my',
            parentId: 834,
          },
          {
            title: 'Xã Tân Hào',
            code: 29029,
            value: 'xa_tan_hao',
            division_type: 'xã',
            short_codename: 'tan_hao',
            parentId: 834,
          },
          {
            title: 'Xã Bình Thành',
            code: 29032,
            value: 'xa_binh_thanh',
            division_type: 'xã',
            short_codename: 'binh_thanh',
            parentId: 834,
          },
          {
            title: 'Xã Tân Thanh',
            code: 29035,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 834,
          },
          {
            title: 'Xã Tân Lợi Thạnh',
            code: 29038,
            value: 'xa_tan_loi_thanh',
            division_type: 'xã',
            short_codename: 'tan_loi_thanh',
            parentId: 834,
          },
          {
            title: 'Xã Thạnh Phú Đông',
            code: 29041,
            value: 'xa_thanh_phu_dong',
            division_type: 'xã',
            short_codename: 'thanh_phu_dong',
            parentId: 834,
          },
          {
            title: 'Xã Hưng Nhượng',
            code: 29044,
            value: 'xa_hung_nhuong',
            division_type: 'xã',
            short_codename: 'hung_nhuong',
            parentId: 834,
          },
          {
            title: 'Xã Hưng Lễ',
            code: 29047,
            value: 'xa_hung_le',
            division_type: 'xã',
            short_codename: 'hung_le',
            parentId: 834,
          },
        ],
        parentId: 83,
      },
      {
        title: 'Huyện Bình Đại',
        code: 835,
        value: 'huyen_binh_dai',
        division_type: 'huyện',
        short_codename: 'binh_dai',
        children: [
          {
            title: 'Thị trấn Bình Đại',
            code: 29050,
            value: 'thi_tran_binh_dai',
            division_type: 'thị trấn',
            short_codename: 'binh_dai',
            parentId: 835,
          },
          {
            title: 'Xã Tam Hiệp',
            code: 29053,
            value: 'xa_tam_hiep',
            division_type: 'xã',
            short_codename: 'tam_hiep',
            parentId: 835,
          },
          {
            title: 'Xã Long Định',
            code: 29056,
            value: 'xa_long_dinh',
            division_type: 'xã',
            short_codename: 'long_dinh',
            parentId: 835,
          },
          {
            title: 'Xã Long Hòa',
            code: 29059,
            value: 'xa_long_hoa',
            division_type: 'xã',
            short_codename: 'long_hoa',
            parentId: 835,
          },
          {
            title: 'Xã Phú Thuận',
            code: 29062,
            value: 'xa_phu_thuan',
            division_type: 'xã',
            short_codename: 'phu_thuan',
            parentId: 835,
          },
          {
            title: 'Xã Vang Quới Tây',
            code: 29065,
            value: 'xa_vang_quoi_tay',
            division_type: 'xã',
            short_codename: 'vang_quoi_tay',
            parentId: 835,
          },
          {
            title: 'Xã Vang Quới Đông',
            code: 29068,
            value: 'xa_vang_quoi_dong',
            division_type: 'xã',
            short_codename: 'vang_quoi_dong',
            parentId: 835,
          },
          {
            title: 'Xã Châu Hưng',
            code: 29071,
            value: 'xa_chau_hung',
            division_type: 'xã',
            short_codename: 'chau_hung',
            parentId: 835,
          },
          {
            title: 'Xã Phú Vang',
            code: 29074,
            value: 'xa_phu_vang',
            division_type: 'xã',
            short_codename: 'phu_vang',
            parentId: 835,
          },
          {
            title: 'Xã Lộc Thuận',
            code: 29077,
            value: 'xa_loc_thuan',
            division_type: 'xã',
            short_codename: 'loc_thuan',
            parentId: 835,
          },
          {
            title: 'Xã Định Trung',
            code: 29080,
            value: 'xa_dinh_trung',
            division_type: 'xã',
            short_codename: 'dinh_trung',
            parentId: 835,
          },
          {
            title: 'Xã Thới Lai',
            code: 29083,
            value: 'xa_thoi_lai',
            division_type: 'xã',
            short_codename: 'thoi_lai',
            parentId: 835,
          },
          {
            title: 'Xã Bình Thới',
            code: 29086,
            value: 'xa_binh_thoi',
            division_type: 'xã',
            short_codename: 'binh_thoi',
            parentId: 835,
          },
          {
            title: 'Xã Phú Long',
            code: 29089,
            value: 'xa_phu_long',
            division_type: 'xã',
            short_codename: 'phu_long',
            parentId: 835,
          },
          {
            title: 'Xã Bình Thắng',
            code: 29092,
            value: 'xa_binh_thang',
            division_type: 'xã',
            short_codename: 'binh_thang',
            parentId: 835,
          },
          {
            title: 'Xã Thạnh Trị',
            code: 29095,
            value: 'xa_thanh_tri',
            division_type: 'xã',
            short_codename: 'thanh_tri',
            parentId: 835,
          },
          {
            title: 'Xã Đại Hòa Lộc',
            code: 29098,
            value: 'xa_dai_hoa_loc',
            division_type: 'xã',
            short_codename: 'dai_hoa_loc',
            parentId: 835,
          },
          {
            title: 'Xã Thừa Đức',
            code: 29101,
            value: 'xa_thua_duc',
            division_type: 'xã',
            short_codename: 'thua_duc',
            parentId: 835,
          },
          {
            title: 'Xã Thạnh Phước',
            code: 29104,
            value: 'xa_thanh_phuoc',
            division_type: 'xã',
            short_codename: 'thanh_phuoc',
            parentId: 835,
          },
          {
            title: 'Xã Thới Thuận',
            code: 29107,
            value: 'xa_thoi_thuan',
            division_type: 'xã',
            short_codename: 'thoi_thuan',
            parentId: 835,
          },
        ],
        parentId: 83,
      },
      {
        title: 'Huyện Ba Tri',
        code: 836,
        value: 'huyen_ba_tri',
        division_type: 'huyện',
        short_codename: 'ba_tri',
        children: [
          {
            title: 'Thị trấn Ba Tri',
            code: 29110,
            value: 'thi_tran_ba_tri',
            division_type: 'thị trấn',
            short_codename: 'ba_tri',
            parentId: 836,
          },
          {
            title: 'Xã Tân Mỹ',
            code: 29113,
            value: 'xa_tan_my',
            division_type: 'xã',
            short_codename: 'tan_my',
            parentId: 836,
          },
          {
            title: 'Xã Mỹ Hòa',
            code: 29116,
            value: 'xa_my_hoa',
            division_type: 'xã',
            short_codename: 'my_hoa',
            parentId: 836,
          },
          {
            title: 'Xã Tân Xuân',
            code: 29119,
            value: 'xa_tan_xuan',
            division_type: 'xã',
            short_codename: 'tan_xuan',
            parentId: 836,
          },
          {
            title: 'Xã Mỹ Chánh',
            code: 29122,
            value: 'xa_my_chanh',
            division_type: 'xã',
            short_codename: 'my_chanh',
            parentId: 836,
          },
          {
            title: 'Xã Bảo Thạnh',
            code: 29125,
            value: 'xa_bao_thanh',
            division_type: 'xã',
            short_codename: 'bao_thanh',
            parentId: 836,
          },
          {
            title: 'Xã An Phú Trung',
            code: 29128,
            value: 'xa_an_phu_trung',
            division_type: 'xã',
            short_codename: 'an_phu_trung',
            parentId: 836,
          },
          {
            title: 'Xã Mỹ Thạnh',
            code: 29131,
            value: 'xa_my_thanh',
            division_type: 'xã',
            short_codename: 'my_thanh',
            parentId: 836,
          },
          {
            title: 'Xã Mỹ Nhơn',
            code: 29134,
            value: 'xa_my_nhon',
            division_type: 'xã',
            short_codename: 'my_nhon',
            parentId: 836,
          },
          {
            title: 'Xã Phước Ngãi',
            code: 29137,
            value: 'xa_phuoc_ngai',
            division_type: 'xã',
            short_codename: 'phuoc_ngai',
            parentId: 836,
          },
          {
            title: 'Xã An Ngãi Trung',
            code: 29143,
            value: 'xa_an_ngai_trung',
            division_type: 'xã',
            short_codename: 'an_ngai_trung',
            parentId: 836,
          },
          {
            title: 'Xã Phú Lễ',
            code: 29146,
            value: 'xa_phu_le',
            division_type: 'xã',
            short_codename: 'phu_le',
            parentId: 836,
          },
          {
            title: 'Xã An Bình Tây',
            code: 29149,
            value: 'xa_an_binh_tay',
            division_type: 'xã',
            short_codename: 'an_binh_tay',
            parentId: 836,
          },
          {
            title: 'Xã Bảo Thuận',
            code: 29152,
            value: 'xa_bao_thuan',
            division_type: 'xã',
            short_codename: 'bao_thuan',
            parentId: 836,
          },
          {
            title: 'Xã Tân Hưng',
            code: 29155,
            value: 'xa_tan_hung',
            division_type: 'xã',
            short_codename: 'tan_hung',
            parentId: 836,
          },
          {
            title: 'Xã An Ngãi Tây',
            code: 29158,
            value: 'xa_an_ngai_tay',
            division_type: 'xã',
            short_codename: 'an_ngai_tay',
            parentId: 836,
          },
          {
            title: 'Xã An Hiệp',
            code: 29161,
            value: 'xa_an_hiep',
            division_type: 'xã',
            short_codename: 'an_hiep',
            parentId: 836,
          },
          {
            title: 'Xã Vĩnh Hòa',
            code: 29164,
            value: 'xa_vinh_hoa',
            division_type: 'xã',
            short_codename: 'vinh_hoa',
            parentId: 836,
          },
          {
            title: 'Xã Tân Thủy',
            code: 29167,
            value: 'xa_tan_thuy',
            division_type: 'xã',
            short_codename: 'tan_thuy',
            parentId: 836,
          },
          {
            title: 'Xã Vĩnh An',
            code: 29170,
            value: 'xa_vinh_an',
            division_type: 'xã',
            short_codename: 'vinh_an',
            parentId: 836,
          },
          {
            title: 'Xã An Đức',
            code: 29173,
            value: 'xa_an_duc',
            division_type: 'xã',
            short_codename: 'an_duc',
            parentId: 836,
          },
          {
            title: 'Xã An Hòa Tây',
            code: 29176,
            value: 'xa_an_hoa_tay',
            division_type: 'xã',
            short_codename: 'an_hoa_tay',
            parentId: 836,
          },
          {
            title: 'Xã An Thủy',
            code: 29179,
            value: 'xa_an_thuy',
            division_type: 'xã',
            short_codename: 'an_thuy',
            parentId: 836,
          },
        ],
        parentId: 83,
      },
      {
        title: 'Huyện Thạnh Phú',
        code: 837,
        value: 'huyen_thanh_phu',
        division_type: 'huyện',
        short_codename: 'thanh_phu',
        children: [
          {
            title: 'Thị trấn Thạnh Phú',
            code: 29182,
            value: 'thi_tran_thanh_phu',
            division_type: 'thị trấn',
            short_codename: 'thanh_phu',
            parentId: 837,
          },
          {
            title: 'Xã Phú Khánh',
            code: 29185,
            value: 'xa_phu_khanh',
            division_type: 'xã',
            short_codename: 'phu_khanh',
            parentId: 837,
          },
          {
            title: 'Xã Đại Điền',
            code: 29188,
            value: 'xa_dai_dien',
            division_type: 'xã',
            short_codename: 'dai_dien',
            parentId: 837,
          },
          {
            title: 'Xã Quới Điền',
            code: 29191,
            value: 'xa_quoi_dien',
            division_type: 'xã',
            short_codename: 'quoi_dien',
            parentId: 837,
          },
          {
            title: 'Xã Tân Phong',
            code: 29194,
            value: 'xa_tan_phong',
            division_type: 'xã',
            short_codename: 'tan_phong',
            parentId: 837,
          },
          {
            title: 'Xã Mỹ Hưng',
            code: 29197,
            value: 'xa_my_hung',
            division_type: 'xã',
            short_codename: 'my_hung',
            parentId: 837,
          },
          {
            title: 'Xã An Thạnh',
            code: 29200,
            value: 'xa_an_thanh',
            division_type: 'xã',
            short_codename: 'an_thanh',
            parentId: 837,
          },
          {
            title: 'Xã Thới Thạnh',
            code: 29203,
            value: 'xa_thoi_thanh',
            division_type: 'xã',
            short_codename: 'thoi_thanh',
            parentId: 837,
          },
          {
            title: 'Xã Hòa Lợi',
            code: 29206,
            value: 'xa_hoa_loi',
            division_type: 'xã',
            short_codename: 'hoa_loi',
            parentId: 837,
          },
          {
            title: 'Xã An Điền',
            code: 29209,
            value: 'xa_an_dien',
            division_type: 'xã',
            short_codename: 'an_dien',
            parentId: 837,
          },
          {
            title: 'Xã Bình Thạnh',
            code: 29212,
            value: 'xa_binh_thanh',
            division_type: 'xã',
            short_codename: 'binh_thanh',
            parentId: 837,
          },
          {
            title: 'Xã An Thuận',
            code: 29215,
            value: 'xa_an_thuan',
            division_type: 'xã',
            short_codename: 'an_thuan',
            parentId: 837,
          },
          {
            title: 'Xã An Quy',
            code: 29218,
            value: 'xa_an_quy',
            division_type: 'xã',
            short_codename: 'an_quy',
            parentId: 837,
          },
          {
            title: 'Xã Thạnh Hải',
            code: 29221,
            value: 'xa_thanh_hai',
            division_type: 'xã',
            short_codename: 'thanh_hai',
            parentId: 837,
          },
          {
            title: 'Xã An Nhơn',
            code: 29224,
            value: 'xa_an_nhon',
            division_type: 'xã',
            short_codename: 'an_nhon',
            parentId: 837,
          },
          {
            title: 'Xã Giao Thạnh',
            code: 29227,
            value: 'xa_giao_thanh',
            division_type: 'xã',
            short_codename: 'giao_thanh',
            parentId: 837,
          },
          {
            title: 'Xã Thạnh Phong',
            code: 29230,
            value: 'xa_thanh_phong',
            division_type: 'xã',
            short_codename: 'thanh_phong',
            parentId: 837,
          },
          {
            title: 'Xã Mỹ An',
            code: 29233,
            value: 'xa_my_an',
            division_type: 'xã',
            short_codename: 'my_an',
            parentId: 837,
          },
        ],
        parentId: 83,
      },
      {
        title: 'Huyện Mỏ Cày Bắc',
        code: 838,
        value: 'huyen_mo_cay_bac',
        division_type: 'huyện',
        short_codename: 'mo_cay_bac',
        children: [
          {
            title: 'Xã Phú Mỹ',
            code: 28889,
            value: 'xa_phu_my',
            division_type: 'xã',
            short_codename: 'phu_my',
            parentId: 838,
          },
          {
            title: 'Xã Hưng Khánh Trung A',
            code: 28901,
            value: 'xa_hung_khanh_trung_a',
            division_type: 'xã',
            short_codename: 'hung_khanh_trung_a',
            parentId: 838,
          },
          {
            title: 'Xã Thanh Tân',
            code: 28906,
            value: 'xa_thanh_tan',
            division_type: 'xã',
            short_codename: 'thanh_tan',
            parentId: 838,
          },
          {
            title: 'Xã Thạnh Ngãi',
            code: 28909,
            value: 'xa_thanh_ngai',
            division_type: 'xã',
            short_codename: 'thanh_ngai',
            parentId: 838,
          },
          {
            title: 'Xã Tân Phú Tây',
            code: 28912,
            value: 'xa_tan_phu_tay',
            division_type: 'xã',
            short_codename: 'tan_phu_tay',
            parentId: 838,
          },
          {
            title: 'Xã Phước Mỹ Trung',
            code: 28915,
            value: 'xa_phuoc_my_trung',
            division_type: 'xã',
            short_codename: 'phuoc_my_trung',
            parentId: 838,
          },
          {
            title: 'Xã Tân Thành Bình',
            code: 28918,
            value: 'xa_tan_thanh_binh',
            division_type: 'xã',
            short_codename: 'tan_thanh_binh',
            parentId: 838,
          },
          {
            title: 'Xã Thành An',
            code: 28921,
            value: 'xa_thanh_an',
            division_type: 'xã',
            short_codename: 'thanh_an',
            parentId: 838,
          },
          {
            title: 'Xã Hòa Lộc',
            code: 28924,
            value: 'xa_hoa_loc',
            division_type: 'xã',
            short_codename: 'hoa_loc',
            parentId: 838,
          },
          {
            title: 'Xã Tân Thanh Tây',
            code: 28927,
            value: 'xa_tan_thanh_tay',
            division_type: 'xã',
            short_codename: 'tan_thanh_tay',
            parentId: 838,
          },
          {
            title: 'Xã Tân Bình',
            code: 28933,
            value: 'xa_tan_binh',
            division_type: 'xã',
            short_codename: 'tan_binh',
            parentId: 838,
          },
          {
            title: 'Xã Nhuận Phú Tân',
            code: 28936,
            value: 'xa_nhuan_phu_tan',
            division_type: 'xã',
            short_codename: 'nhuan_phu_tan',
            parentId: 838,
          },
          {
            title: 'Xã Khánh Thạnh Tân',
            code: 28948,
            value: 'xa_khanh_thanh_tan',
            division_type: 'xã',
            short_codename: 'khanh_thanh_tan',
            parentId: 838,
          },
        ],
        parentId: 83,
      },
    ],
  },
  {
    title: 'Tỉnh Trà Vinh',
    code: 84,
    value: 'tinh_tra_vinh',
    division_type: 'tỉnh',
    phone_code: 294,
    children: [
      {
        title: 'Thành phố Trà Vinh',
        code: 842,
        value: 'thanh_pho_tra_vinh',
        division_type: 'thành phố',
        short_codename: 'tra_vinh',
        children: [
          {
            title: 'Phường 4',
            code: 29236,
            value: 'phuong_4',
            division_type: 'phường',
            short_codename: 'phuong_4',
            parentId: 842,
          },
          {
            title: 'Phường 1',
            code: 29239,
            value: 'phuong_1',
            division_type: 'phường',
            short_codename: 'phuong_1',
            parentId: 842,
          },
          {
            title: 'Phường 3',
            code: 29242,
            value: 'phuong_3',
            division_type: 'phường',
            short_codename: 'phuong_3',
            parentId: 842,
          },
          {
            title: 'Phường 2',
            code: 29245,
            value: 'phuong_2',
            division_type: 'phường',
            short_codename: 'phuong_2',
            parentId: 842,
          },
          {
            title: 'Phường 5',
            code: 29248,
            value: 'phuong_5',
            division_type: 'phường',
            short_codename: 'phuong_5',
            parentId: 842,
          },
          {
            title: 'Phường 6',
            code: 29251,
            value: 'phuong_6',
            division_type: 'phường',
            short_codename: 'phuong_6',
            parentId: 842,
          },
          {
            title: 'Phường 7',
            code: 29254,
            value: 'phuong_7',
            division_type: 'phường',
            short_codename: 'phuong_7',
            parentId: 842,
          },
          {
            title: 'Phường 8',
            code: 29257,
            value: 'phuong_8',
            division_type: 'phường',
            short_codename: 'phuong_8',
            parentId: 842,
          },
          {
            title: 'Phường 9',
            code: 29260,
            value: 'phuong_9',
            division_type: 'phường',
            short_codename: 'phuong_9',
            parentId: 842,
          },
          {
            title: 'Xã Long Đức',
            code: 29263,
            value: 'xa_long_duc',
            division_type: 'xã',
            short_codename: 'long_duc',
            parentId: 842,
          },
        ],
        parentId: 84,
      },
      {
        title: 'Huyện Càng Long',
        code: 844,
        value: 'huyen_cang_long',
        division_type: 'huyện',
        short_codename: 'cang_long',
        children: [
          {
            title: 'Thị trấn Càng Long',
            code: 29266,
            value: 'thi_tran_cang_long',
            division_type: 'thị trấn',
            short_codename: 'cang_long',
            parentId: 844,
          },
          {
            title: 'Xã Mỹ Cẩm',
            code: 29269,
            value: 'xa_my_cam',
            division_type: 'xã',
            short_codename: 'my_cam',
            parentId: 844,
          },
          {
            title: 'Xã An Trường A',
            code: 29272,
            value: 'xa_an_truong_a',
            division_type: 'xã',
            short_codename: 'an_truong_a',
            parentId: 844,
          },
          {
            title: 'Xã An Trường',
            code: 29275,
            value: 'xa_an_truong',
            division_type: 'xã',
            short_codename: 'an_truong',
            parentId: 844,
          },
          {
            title: 'Xã Huyền Hội',
            code: 29278,
            value: 'xa_huyen_hoi',
            division_type: 'xã',
            short_codename: 'huyen_hoi',
            parentId: 844,
          },
          {
            title: 'Xã Tân An',
            code: 29281,
            value: 'xa_tan_an',
            division_type: 'xã',
            short_codename: 'tan_an',
            parentId: 844,
          },
          {
            title: 'Xã Tân Bình',
            code: 29284,
            value: 'xa_tan_binh',
            division_type: 'xã',
            short_codename: 'tan_binh',
            parentId: 844,
          },
          {
            title: 'Xã Bình Phú',
            code: 29287,
            value: 'xa_binh_phu',
            division_type: 'xã',
            short_codename: 'binh_phu',
            parentId: 844,
          },
          {
            title: 'Xã Phương Thạnh',
            code: 29290,
            value: 'xa_phuong_thanh',
            division_type: 'xã',
            short_codename: 'phuong_thanh',
            parentId: 844,
          },
          {
            title: 'Xã Đại Phúc',
            code: 29293,
            value: 'xa_dai_phuc',
            division_type: 'xã',
            short_codename: 'dai_phuc',
            parentId: 844,
          },
          {
            title: 'Xã Đại Phước',
            code: 29296,
            value: 'xa_dai_phuoc',
            division_type: 'xã',
            short_codename: 'dai_phuoc',
            parentId: 844,
          },
          {
            title: 'Xã Nhị Long Phú',
            code: 29299,
            value: 'xa_nhi_long_phu',
            division_type: 'xã',
            short_codename: 'nhi_long_phu',
            parentId: 844,
          },
          {
            title: 'Xã Nhị Long',
            code: 29302,
            value: 'xa_nhi_long',
            division_type: 'xã',
            short_codename: 'nhi_long',
            parentId: 844,
          },
          {
            title: 'Xã Đức Mỹ',
            code: 29305,
            value: 'xa_duc_my',
            division_type: 'xã',
            short_codename: 'duc_my',
            parentId: 844,
          },
        ],
        parentId: 84,
      },
      {
        title: 'Huyện Cầu Kè',
        code: 845,
        value: 'huyen_cau_ke',
        division_type: 'huyện',
        short_codename: 'cau_ke',
        children: [
          {
            title: 'Thị trấn Cầu Kè',
            code: 29308,
            value: 'thi_tran_cau_ke',
            division_type: 'thị trấn',
            short_codename: 'cau_ke',
            parentId: 845,
          },
          {
            title: 'Xã Hòa Ân',
            code: 29311,
            value: 'xa_hoa_an',
            division_type: 'xã',
            short_codename: 'hoa_an',
            parentId: 845,
          },
          {
            title: 'Xã Châu Điền',
            code: 29314,
            value: 'xa_chau_dien',
            division_type: 'xã',
            short_codename: 'chau_dien',
            parentId: 845,
          },
          {
            title: 'Xã An Phú Tân',
            code: 29317,
            value: 'xa_an_phu_tan',
            division_type: 'xã',
            short_codename: 'an_phu_tan',
            parentId: 845,
          },
          {
            title: 'Xã Hoà Tân',
            code: 29320,
            value: 'xa_hoa_tan',
            division_type: 'xã',
            short_codename: 'hoa_tan',
            parentId: 845,
          },
          {
            title: 'Xã Ninh Thới',
            code: 29323,
            value: 'xa_ninh_thoi',
            division_type: 'xã',
            short_codename: 'ninh_thoi',
            parentId: 845,
          },
          {
            title: 'Xã Phong Phú',
            code: 29326,
            value: 'xa_phong_phu',
            division_type: 'xã',
            short_codename: 'phong_phu',
            parentId: 845,
          },
          {
            title: 'Xã Phong Thạnh',
            code: 29329,
            value: 'xa_phong_thanh',
            division_type: 'xã',
            short_codename: 'phong_thanh',
            parentId: 845,
          },
          {
            title: 'Xã Tam Ngãi',
            code: 29332,
            value: 'xa_tam_ngai',
            division_type: 'xã',
            short_codename: 'tam_ngai',
            parentId: 845,
          },
          {
            title: 'Xã Thông Hòa',
            code: 29335,
            value: 'xa_thong_hoa',
            division_type: 'xã',
            short_codename: 'thong_hoa',
            parentId: 845,
          },
          {
            title: 'Xã Thạnh Phú',
            code: 29338,
            value: 'xa_thanh_phu',
            division_type: 'xã',
            short_codename: 'thanh_phu',
            parentId: 845,
          },
        ],
        parentId: 84,
      },
      {
        title: 'Huyện Tiểu Cần',
        code: 846,
        value: 'huyen_tieu_can',
        division_type: 'huyện',
        short_codename: 'tieu_can',
        children: [
          {
            title: 'Thị trấn Tiểu Cần',
            code: 29341,
            value: 'thi_tran_tieu_can',
            division_type: 'thị trấn',
            short_codename: 'tieu_can',
            parentId: 846,
          },
          {
            title: 'Thị trấn Cầu Quan',
            code: 29344,
            value: 'thi_tran_cau_quan',
            division_type: 'thị trấn',
            short_codename: 'cau_quan',
            parentId: 846,
          },
          {
            title: 'Xã Phú Cần',
            code: 29347,
            value: 'xa_phu_can',
            division_type: 'xã',
            short_codename: 'phu_can',
            parentId: 846,
          },
          {
            title: 'Xã Hiếu Tử',
            code: 29350,
            value: 'xa_hieu_tu',
            division_type: 'xã',
            short_codename: 'hieu_tu',
            parentId: 846,
          },
          {
            title: 'Xã Hiếu Trung',
            code: 29353,
            value: 'xa_hieu_trung',
            division_type: 'xã',
            short_codename: 'hieu_trung',
            parentId: 846,
          },
          {
            title: 'Xã Long Thới',
            code: 29356,
            value: 'xa_long_thoi',
            division_type: 'xã',
            short_codename: 'long_thoi',
            parentId: 846,
          },
          {
            title: 'Xã Hùng Hòa',
            code: 29359,
            value: 'xa_hung_hoa',
            division_type: 'xã',
            short_codename: 'hung_hoa',
            parentId: 846,
          },
          {
            title: 'Xã Tân Hùng',
            code: 29362,
            value: 'xa_tan_hung',
            division_type: 'xã',
            short_codename: 'tan_hung',
            parentId: 846,
          },
          {
            title: 'Xã Tập Ngãi',
            code: 29365,
            value: 'xa_tap_ngai',
            division_type: 'xã',
            short_codename: 'tap_ngai',
            parentId: 846,
          },
          {
            title: 'Xã Ngãi Hùng',
            code: 29368,
            value: 'xa_ngai_hung',
            division_type: 'xã',
            short_codename: 'ngai_hung',
            parentId: 846,
          },
          {
            title: 'Xã Tân Hòa',
            code: 29371,
            value: 'xa_tan_hoa',
            division_type: 'xã',
            short_codename: 'tan_hoa',
            parentId: 846,
          },
        ],
        parentId: 84,
      },
      {
        title: 'Huyện Châu Thành',
        code: 847,
        value: 'huyen_chau_thanh',
        division_type: 'huyện',
        short_codename: 'chau_thanh',
        children: [
          {
            title: 'Thị trấn Châu Thành',
            code: 29374,
            value: 'thi_tran_chau_thanh',
            division_type: 'thị trấn',
            short_codename: 'chau_thanh',
            parentId: 847,
          },
          {
            title: 'Xã Đa Lộc',
            code: 29377,
            value: 'xa_da_loc',
            division_type: 'xã',
            short_codename: 'da_loc',
            parentId: 847,
          },
          {
            title: 'Xã Mỹ Chánh',
            code: 29380,
            value: 'xa_my_chanh',
            division_type: 'xã',
            short_codename: 'my_chanh',
            parentId: 847,
          },
          {
            title: 'Xã Thanh Mỹ',
            code: 29383,
            value: 'xa_thanh_my',
            division_type: 'xã',
            short_codename: 'thanh_my',
            parentId: 847,
          },
          {
            title: 'Xã Lương Hoà A',
            code: 29386,
            value: 'xa_luong_hoa_a',
            division_type: 'xã',
            short_codename: 'luong_hoa_a',
            parentId: 847,
          },
          {
            title: 'Xã Lương Hòa',
            code: 29389,
            value: 'xa_luong_hoa',
            division_type: 'xã',
            short_codename: 'luong_hoa',
            parentId: 847,
          },
          {
            title: 'Xã Song Lộc',
            code: 29392,
            value: 'xa_song_loc',
            division_type: 'xã',
            short_codename: 'song_loc',
            parentId: 847,
          },
          {
            title: 'Xã Nguyệt Hóa',
            code: 29395,
            value: 'xa_nguyet_hoa',
            division_type: 'xã',
            short_codename: 'nguyet_hoa',
            parentId: 847,
          },
          {
            title: 'Xã Hòa Thuận',
            code: 29398,
            value: 'xa_hoa_thuan',
            division_type: 'xã',
            short_codename: 'hoa_thuan',
            parentId: 847,
          },
          {
            title: 'Xã Hòa Lợi',
            code: 29401,
            value: 'xa_hoa_loi',
            division_type: 'xã',
            short_codename: 'hoa_loi',
            parentId: 847,
          },
          {
            title: 'Xã Phước Hảo',
            code: 29404,
            value: 'xa_phuoc_hao',
            division_type: 'xã',
            short_codename: 'phuoc_hao',
            parentId: 847,
          },
          {
            title: 'Xã Hưng Mỹ',
            code: 29407,
            value: 'xa_hung_my',
            division_type: 'xã',
            short_codename: 'hung_my',
            parentId: 847,
          },
          {
            title: 'Xã Hòa Minh',
            code: 29410,
            value: 'xa_hoa_minh',
            division_type: 'xã',
            short_codename: 'hoa_minh',
            parentId: 847,
          },
          {
            title: 'Xã Long Hòa',
            code: 29413,
            value: 'xa_long_hoa',
            division_type: 'xã',
            short_codename: 'long_hoa',
            parentId: 847,
          },
        ],
        parentId: 84,
      },
      {
        title: 'Huyện Cầu Ngang',
        code: 848,
        value: 'huyen_cau_ngang',
        division_type: 'huyện',
        short_codename: 'cau_ngang',
        children: [
          {
            title: 'Thị trấn Cầu Ngang',
            code: 29416,
            value: 'thi_tran_cau_ngang',
            division_type: 'thị trấn',
            short_codename: 'cau_ngang',
            parentId: 848,
          },
          {
            title: 'Thị trấn Mỹ Long',
            code: 29419,
            value: 'thi_tran_my_long',
            division_type: 'thị trấn',
            short_codename: 'my_long',
            parentId: 848,
          },
          {
            title: 'Xã Mỹ Long Bắc',
            code: 29422,
            value: 'xa_my_long_bac',
            division_type: 'xã',
            short_codename: 'my_long_bac',
            parentId: 848,
          },
          {
            title: 'Xã Mỹ Long Nam',
            code: 29425,
            value: 'xa_my_long_nam',
            division_type: 'xã',
            short_codename: 'my_long_nam',
            parentId: 848,
          },
          {
            title: 'Xã Mỹ Hòa',
            code: 29428,
            value: 'xa_my_hoa',
            division_type: 'xã',
            short_codename: 'my_hoa',
            parentId: 848,
          },
          {
            title: 'Xã Vĩnh Kim',
            code: 29431,
            value: 'xa_vinh_kim',
            division_type: 'xã',
            short_codename: 'vinh_kim',
            parentId: 848,
          },
          {
            title: 'Xã Kim Hòa',
            code: 29434,
            value: 'xa_kim_hoa',
            division_type: 'xã',
            short_codename: 'kim_hoa',
            parentId: 848,
          },
          {
            title: 'Xã Hiệp Hòa',
            code: 29437,
            value: 'xa_hiep_hoa',
            division_type: 'xã',
            short_codename: 'hiep_hoa',
            parentId: 848,
          },
          {
            title: 'Xã Thuận Hòa',
            code: 29440,
            value: 'xa_thuan_hoa',
            division_type: 'xã',
            short_codename: 'thuan_hoa',
            parentId: 848,
          },
          {
            title: 'Xã Long Sơn',
            code: 29443,
            value: 'xa_long_son',
            division_type: 'xã',
            short_codename: 'long_son',
            parentId: 848,
          },
          {
            title: 'Xã Nhị Trường',
            code: 29446,
            value: 'xa_nhi_truong',
            division_type: 'xã',
            short_codename: 'nhi_truong',
            parentId: 848,
          },
          {
            title: 'Xã Trường Thọ',
            code: 29449,
            value: 'xa_truong_tho',
            division_type: 'xã',
            short_codename: 'truong_tho',
            parentId: 848,
          },
          {
            title: 'Xã Hiệp Mỹ Đông',
            code: 29452,
            value: 'xa_hiep_my_dong',
            division_type: 'xã',
            short_codename: 'hiep_my_dong',
            parentId: 848,
          },
          {
            title: 'Xã Hiệp Mỹ Tây',
            code: 29455,
            value: 'xa_hiep_my_tay',
            division_type: 'xã',
            short_codename: 'hiep_my_tay',
            parentId: 848,
          },
          {
            title: 'Xã Thạnh Hòa Sơn',
            code: 29458,
            value: 'xa_thanh_hoa_son',
            division_type: 'xã',
            short_codename: 'thanh_hoa_son',
            parentId: 848,
          },
        ],
        parentId: 84,
      },
      {
        title: 'Huyện Trà Cú',
        code: 849,
        value: 'huyen_tra_cu',
        division_type: 'huyện',
        short_codename: 'tra_cu',
        children: [
          {
            title: 'Thị trấn Trà Cú',
            code: 29461,
            value: 'thi_tran_tra_cu',
            division_type: 'thị trấn',
            short_codename: 'tra_cu',
            parentId: 849,
          },
          {
            title: 'Thị trấn Định An',
            code: 29462,
            value: 'thi_tran_dinh_an',
            division_type: 'thị trấn',
            short_codename: 'dinh_an',
            parentId: 849,
          },
          {
            title: 'Xã Phước Hưng',
            code: 29464,
            value: 'xa_phuoc_hung',
            division_type: 'xã',
            short_codename: 'phuoc_hung',
            parentId: 849,
          },
          {
            title: 'Xã Tập Sơn',
            code: 29467,
            value: 'xa_tap_son',
            division_type: 'xã',
            short_codename: 'tap_son',
            parentId: 849,
          },
          {
            title: 'Xã Tân Sơn',
            code: 29470,
            value: 'xa_tan_son',
            division_type: 'xã',
            short_codename: 'tan_son',
            parentId: 849,
          },
          {
            title: 'Xã An Quảng Hữu',
            code: 29473,
            value: 'xa_an_quang_huu',
            division_type: 'xã',
            short_codename: 'an_quang_huu',
            parentId: 849,
          },
          {
            title: 'Xã Lưu Nghiệp Anh',
            code: 29476,
            value: 'xa_luu_nghiep_anh',
            division_type: 'xã',
            short_codename: 'luu_nghiep_anh',
            parentId: 849,
          },
          {
            title: 'Xã Ngãi Xuyên',
            code: 29479,
            value: 'xa_ngai_xuyen',
            division_type: 'xã',
            short_codename: 'ngai_xuyen',
            parentId: 849,
          },
          {
            title: 'Xã Kim Sơn',
            code: 29482,
            value: 'xa_kim_son',
            division_type: 'xã',
            short_codename: 'kim_son',
            parentId: 849,
          },
          {
            title: 'Xã Thanh Sơn',
            code: 29485,
            value: 'xa_thanh_son',
            division_type: 'xã',
            short_codename: 'thanh_son',
            parentId: 849,
          },
          {
            title: 'Xã Hàm Giang',
            code: 29488,
            value: 'xa_ham_giang',
            division_type: 'xã',
            short_codename: 'ham_giang',
            parentId: 849,
          },
          {
            title: 'Xã Hàm Tân',
            code: 29489,
            value: 'xa_ham_tan',
            division_type: 'xã',
            short_codename: 'ham_tan',
            parentId: 849,
          },
          {
            title: 'Xã Đại An',
            code: 29491,
            value: 'xa_dai_an',
            division_type: 'xã',
            short_codename: 'dai_an',
            parentId: 849,
          },
          {
            title: 'Xã Định An',
            code: 29494,
            value: 'xa_dinh_an',
            division_type: 'xã',
            short_codename: 'xa_dinh_an',
            parentId: 849,
          },
          {
            title: 'Xã Ngọc Biên',
            code: 29503,
            value: 'xa_ngoc_bien',
            division_type: 'xã',
            short_codename: 'ngoc_bien',
            parentId: 849,
          },
          {
            title: 'Xã Long Hiệp',
            code: 29506,
            value: 'xa_long_hiep',
            division_type: 'xã',
            short_codename: 'long_hiep',
            parentId: 849,
          },
          {
            title: 'Xã Tân Hiệp',
            code: 29509,
            value: 'xa_tan_hiep',
            division_type: 'xã',
            short_codename: 'tan_hiep',
            parentId: 849,
          },
        ],
        parentId: 84,
      },
      {
        title: 'Huyện Duyên Hải',
        code: 850,
        value: 'huyen_duyen_hai',
        division_type: 'huyện',
        short_codename: 'huyen_duyen_hai',
        children: [
          {
            title: 'Xã Đôn Xuân',
            code: 29497,
            value: 'xa_don_xuan',
            division_type: 'xã',
            short_codename: 'don_xuan',
            parentId: 850,
          },
          {
            title: 'Xã Đôn Châu',
            code: 29500,
            value: 'xa_don_chau',
            division_type: 'xã',
            short_codename: 'don_chau',
            parentId: 850,
          },
          {
            title: 'Thị trấn Long Thành',
            code: 29513,
            value: 'thi_tran_long_thanh',
            division_type: 'thị trấn',
            short_codename: 'long_thanh',
            parentId: 850,
          },
          {
            title: 'Xã Long Khánh',
            code: 29521,
            value: 'xa_long_khanh',
            division_type: 'xã',
            short_codename: 'long_khanh',
            parentId: 850,
          },
          {
            title: 'Xã Ngũ Lạc',
            code: 29530,
            value: 'xa_ngu_lac',
            division_type: 'xã',
            short_codename: 'ngu_lac',
            parentId: 850,
          },
          {
            title: 'Xã Long Vĩnh',
            code: 29533,
            value: 'xa_long_vinh',
            division_type: 'xã',
            short_codename: 'long_vinh',
            parentId: 850,
          },
          {
            title: 'Xã Đông Hải',
            code: 29536,
            value: 'xa_dong_hai',
            division_type: 'xã',
            short_codename: 'dong_hai',
            parentId: 850,
          },
        ],
        parentId: 84,
      },
      {
        title: 'Thị xã Duyên Hải',
        code: 851,
        value: 'thi_xa_duyen_hai',
        division_type: 'thị xã',
        short_codename: 'duyen_hai',
        children: [
          {
            title: 'Phường 1',
            code: 29512,
            value: 'phuong_1',
            division_type: 'phường',
            short_codename: 'phuong_1',
            parentId: 851,
          },
          {
            title: 'Xã Long Toàn',
            code: 29515,
            value: 'xa_long_toan',
            division_type: 'xã',
            short_codename: 'long_toan',
            parentId: 851,
          },
          {
            title: 'Phường 2',
            code: 29516,
            value: 'phuong_2',
            division_type: 'phường',
            short_codename: 'phuong_2',
            parentId: 851,
          },
          {
            title: 'Xã Long Hữu',
            code: 29518,
            value: 'xa_long_huu',
            division_type: 'xã',
            short_codename: 'long_huu',
            parentId: 851,
          },
          {
            title: 'Xã Dân Thành',
            code: 29524,
            value: 'xa_dan_thanh',
            division_type: 'xã',
            short_codename: 'dan_thanh',
            parentId: 851,
          },
          {
            title: 'Xã Trường Long Hòa',
            code: 29527,
            value: 'xa_truong_long_hoa',
            division_type: 'xã',
            short_codename: 'truong_long_hoa',
            parentId: 851,
          },
          {
            title: 'Xã Hiệp Thạnh',
            code: 29539,
            value: 'xa_hiep_thanh',
            division_type: 'xã',
            short_codename: 'hiep_thanh',
            parentId: 851,
          },
        ],
        parentId: 84,
      },
    ],
  },
  {
    title: 'Tỉnh Vĩnh Long',
    code: 86,
    value: 'tinh_vinh_long',
    division_type: 'tỉnh',
    phone_code: 270,
    children: [
      {
        title: 'Thành phố Vĩnh Long',
        code: 855,
        value: 'thanh_pho_vinh_long',
        division_type: 'thành phố',
        short_codename: 'vinh_long',
        children: [
          {
            title: 'Phường 9',
            code: 29542,
            value: 'phuong_9',
            division_type: 'phường',
            short_codename: 'phuong_9',
            parentId: 855,
          },
          {
            title: 'Phường 5',
            code: 29545,
            value: 'phuong_5',
            division_type: 'phường',
            short_codename: 'phuong_5',
            parentId: 855,
          },
          {
            title: 'Phường 1',
            code: 29548,
            value: 'phuong_1',
            division_type: 'phường',
            short_codename: 'phuong_1',
            parentId: 855,
          },
          {
            title: 'Phường 2',
            code: 29551,
            value: 'phuong_2',
            division_type: 'phường',
            short_codename: 'phuong_2',
            parentId: 855,
          },
          {
            title: 'Phường 4',
            code: 29554,
            value: 'phuong_4',
            division_type: 'phường',
            short_codename: 'phuong_4',
            parentId: 855,
          },
          {
            title: 'Phường 3',
            code: 29557,
            value: 'phuong_3',
            division_type: 'phường',
            short_codename: 'phuong_3',
            parentId: 855,
          },
          {
            title: 'Phường 8',
            code: 29560,
            value: 'phuong_8',
            division_type: 'phường',
            short_codename: 'phuong_8',
            parentId: 855,
          },
          {
            title: 'Phường Tân Ngãi',
            code: 29563,
            value: 'phuong_tan_ngai',
            division_type: 'phường',
            short_codename: 'tan_ngai',
            parentId: 855,
          },
          {
            title: 'Phường Tân Hòa',
            code: 29566,
            value: 'phuong_tan_hoa',
            division_type: 'phường',
            short_codename: 'tan_hoa',
            parentId: 855,
          },
          {
            title: 'Phường Tân Hội',
            code: 29569,
            value: 'phuong_tan_hoi',
            division_type: 'phường',
            short_codename: 'tan_hoi',
            parentId: 855,
          },
          {
            title: 'Phường Trường An',
            code: 29572,
            value: 'phuong_truong_an',
            division_type: 'phường',
            short_codename: 'truong_an',
            parentId: 855,
          },
        ],
        parentId: 86,
      },
      {
        title: 'Huyện Long Hồ',
        code: 857,
        value: 'huyen_long_ho',
        division_type: 'huyện',
        short_codename: 'long_ho',
        children: [
          {
            title: 'Thị trấn Long Hồ',
            code: 29575,
            value: 'thi_tran_long_ho',
            division_type: 'thị trấn',
            short_codename: 'long_ho',
            parentId: 857,
          },
          {
            title: 'Xã Đồng Phú',
            code: 29578,
            value: 'xa_dong_phu',
            division_type: 'xã',
            short_codename: 'dong_phu',
            parentId: 857,
          },
          {
            title: 'Xã Bình Hòa Phước',
            code: 29581,
            value: 'xa_binh_hoa_phuoc',
            division_type: 'xã',
            short_codename: 'binh_hoa_phuoc',
            parentId: 857,
          },
          {
            title: 'Xã Hòa Ninh',
            code: 29584,
            value: 'xa_hoa_ninh',
            division_type: 'xã',
            short_codename: 'hoa_ninh',
            parentId: 857,
          },
          {
            title: 'Xã An Bình',
            code: 29587,
            value: 'xa_an_binh',
            division_type: 'xã',
            short_codename: 'an_binh',
            parentId: 857,
          },
          {
            title: 'Xã Thanh Đức',
            code: 29590,
            value: 'xa_thanh_duc',
            division_type: 'xã',
            short_codename: 'thanh_duc',
            parentId: 857,
          },
          {
            title: 'Xã Tân Hạnh',
            code: 29593,
            value: 'xa_tan_hanh',
            division_type: 'xã',
            short_codename: 'tan_hanh',
            parentId: 857,
          },
          {
            title: 'Xã Phước Hậu',
            code: 29596,
            value: 'xa_phuoc_hau',
            division_type: 'xã',
            short_codename: 'phuoc_hau',
            parentId: 857,
          },
          {
            title: 'Xã Long Phước',
            code: 29599,
            value: 'xa_long_phuoc',
            division_type: 'xã',
            short_codename: 'long_phuoc',
            parentId: 857,
          },
          {
            title: 'Xã Phú Đức',
            code: 29602,
            value: 'xa_phu_duc',
            division_type: 'xã',
            short_codename: 'phu_duc',
            parentId: 857,
          },
          {
            title: 'Xã Lộc Hòa',
            code: 29605,
            value: 'xa_loc_hoa',
            division_type: 'xã',
            short_codename: 'loc_hoa',
            parentId: 857,
          },
          {
            title: 'Xã Long An',
            code: 29608,
            value: 'xa_long_an',
            division_type: 'xã',
            short_codename: 'long_an',
            parentId: 857,
          },
          {
            title: 'Xã Phú Quới',
            code: 29611,
            value: 'xa_phu_quoi',
            division_type: 'xã',
            short_codename: 'phu_quoi',
            parentId: 857,
          },
          {
            title: 'Xã Thạnh Quới',
            code: 29614,
            value: 'xa_thanh_quoi',
            division_type: 'xã',
            short_codename: 'thanh_quoi',
            parentId: 857,
          },
          {
            title: 'Xã Hòa Phú',
            code: 29617,
            value: 'xa_hoa_phu',
            division_type: 'xã',
            short_codename: 'hoa_phu',
            parentId: 857,
          },
        ],
        parentId: 86,
      },
      {
        title: 'Huyện Mang Thít',
        code: 858,
        value: 'huyen_mang_thit',
        division_type: 'huyện',
        short_codename: 'mang_thit',
        children: [
          {
            title: 'Xã Mỹ An',
            code: 29623,
            value: 'xa_my_an',
            division_type: 'xã',
            short_codename: 'my_an',
            parentId: 858,
          },
          {
            title: 'Xã Mỹ Phước',
            code: 29626,
            value: 'xa_my_phuoc',
            division_type: 'xã',
            short_codename: 'my_phuoc',
            parentId: 858,
          },
          {
            title: 'Xã An Phước',
            code: 29629,
            value: 'xa_an_phuoc',
            division_type: 'xã',
            short_codename: 'an_phuoc',
            parentId: 858,
          },
          {
            title: 'Xã Nhơn Phú',
            code: 29632,
            value: 'xa_nhon_phu',
            division_type: 'xã',
            short_codename: 'nhon_phu',
            parentId: 858,
          },
          {
            title: 'Xã Long Mỹ',
            code: 29635,
            value: 'xa_long_my',
            division_type: 'xã',
            short_codename: 'long_my',
            parentId: 858,
          },
          {
            title: 'Xã Hòa Tịnh',
            code: 29638,
            value: 'xa_hoa_tinh',
            division_type: 'xã',
            short_codename: 'hoa_tinh',
            parentId: 858,
          },
          {
            title: 'Thị trấn Cái Nhum',
            code: 29641,
            value: 'thi_tran_cai_nhum',
            division_type: 'thị trấn',
            short_codename: 'cai_nhum',
            parentId: 858,
          },
          {
            title: 'Xã Bình Phước',
            code: 29644,
            value: 'xa_binh_phuoc',
            division_type: 'xã',
            short_codename: 'binh_phuoc',
            parentId: 858,
          },
          {
            title: 'Xã Chánh An',
            code: 29647,
            value: 'xa_chanh_an',
            division_type: 'xã',
            short_codename: 'chanh_an',
            parentId: 858,
          },
          {
            title: 'Xã Tân An Hội',
            code: 29650,
            value: 'xa_tan_an_hoi',
            division_type: 'xã',
            short_codename: 'tan_an_hoi',
            parentId: 858,
          },
          {
            title: 'Xã Tân Long',
            code: 29653,
            value: 'xa_tan_long',
            division_type: 'xã',
            short_codename: 'tan_long',
            parentId: 858,
          },
          {
            title: 'Xã Tân Long Hội',
            code: 29656,
            value: 'xa_tan_long_hoi',
            division_type: 'xã',
            short_codename: 'tan_long_hoi',
            parentId: 858,
          },
        ],
        parentId: 86,
      },
      {
        title: 'Huyện Vũng Liêm',
        code: 859,
        value: 'huyen_vung_liem',
        division_type: 'huyện',
        short_codename: 'vung_liem',
        children: [
          {
            title: 'Thị trấn Vũng Liêm',
            code: 29659,
            value: 'thi_tran_vung_liem',
            division_type: 'thị trấn',
            short_codename: 'vung_liem',
            parentId: 859,
          },
          {
            title: 'Xã Tân Quới Trung',
            code: 29662,
            value: 'xa_tan_quoi_trung',
            division_type: 'xã',
            short_codename: 'tan_quoi_trung',
            parentId: 859,
          },
          {
            title: 'Xã Quới Thiện',
            code: 29665,
            value: 'xa_quoi_thien',
            division_type: 'xã',
            short_codename: 'quoi_thien',
            parentId: 859,
          },
          {
            title: 'Xã Quới An',
            code: 29668,
            value: 'xa_quoi_an',
            division_type: 'xã',
            short_codename: 'quoi_an',
            parentId: 859,
          },
          {
            title: 'Xã Trung Chánh',
            code: 29671,
            value: 'xa_trung_chanh',
            division_type: 'xã',
            short_codename: 'trung_chanh',
            parentId: 859,
          },
          {
            title: 'Xã Tân An Luông',
            code: 29674,
            value: 'xa_tan_an_luong',
            division_type: 'xã',
            short_codename: 'tan_an_luong',
            parentId: 859,
          },
          {
            title: 'Xã Thanh Bình',
            code: 29677,
            value: 'xa_thanh_binh',
            division_type: 'xã',
            short_codename: 'thanh_binh',
            parentId: 859,
          },
          {
            title: 'Xã Trung Thành Tây',
            code: 29680,
            value: 'xa_trung_thanh_tay',
            division_type: 'xã',
            short_codename: 'trung_thanh_tay',
            parentId: 859,
          },
          {
            title: 'Xã Trung Hiệp',
            code: 29683,
            value: 'xa_trung_hiep',
            division_type: 'xã',
            short_codename: 'trung_hiep',
            parentId: 859,
          },
          {
            title: 'Xã Hiếu Phụng',
            code: 29686,
            value: 'xa_hieu_phung',
            division_type: 'xã',
            short_codename: 'hieu_phung',
            parentId: 859,
          },
          {
            title: 'Xã Trung Thành Đông',
            code: 29689,
            value: 'xa_trung_thanh_dong',
            division_type: 'xã',
            short_codename: 'trung_thanh_dong',
            parentId: 859,
          },
          {
            title: 'Xã Trung Thành',
            code: 29692,
            value: 'xa_trung_thanh',
            division_type: 'xã',
            short_codename: 'trung_thanh',
            parentId: 859,
          },
          {
            title: 'Xã Trung Hiếu',
            code: 29695,
            value: 'xa_trung_hieu',
            division_type: 'xã',
            short_codename: 'trung_hieu',
            parentId: 859,
          },
          {
            title: 'Xã Trung Ngãi',
            code: 29698,
            value: 'xa_trung_ngai',
            division_type: 'xã',
            short_codename: 'trung_ngai',
            parentId: 859,
          },
          {
            title: 'Xã Hiếu Thuận',
            code: 29701,
            value: 'xa_hieu_thuan',
            division_type: 'xã',
            short_codename: 'hieu_thuan',
            parentId: 859,
          },
          {
            title: 'Xã Trung Nghĩa',
            code: 29704,
            value: 'xa_trung_nghia',
            division_type: 'xã',
            short_codename: 'trung_nghia',
            parentId: 859,
          },
          {
            title: 'Xã Trung An',
            code: 29707,
            value: 'xa_trung_an',
            division_type: 'xã',
            short_codename: 'trung_an',
            parentId: 859,
          },
          {
            title: 'Xã Hiếu Nhơn',
            code: 29710,
            value: 'xa_hieu_nhon',
            division_type: 'xã',
            short_codename: 'hieu_nhon',
            parentId: 859,
          },
          {
            title: 'Xã Hiếu Thành',
            code: 29713,
            value: 'xa_hieu_thanh',
            division_type: 'xã',
            short_codename: 'hieu_thanh',
            parentId: 859,
          },
          {
            title: 'Xã Hiếu Nghĩa',
            code: 29716,
            value: 'xa_hieu_nghia',
            division_type: 'xã',
            short_codename: 'hieu_nghia',
            parentId: 859,
          },
        ],
        parentId: 86,
      },
      {
        title: 'Huyện Tam Bình',
        code: 860,
        value: 'huyen_tam_binh',
        division_type: 'huyện',
        short_codename: 'tam_binh',
        children: [
          {
            title: 'Thị trấn Tam Bình',
            code: 29719,
            value: 'thi_tran_tam_binh',
            division_type: 'thị trấn',
            short_codename: 'tam_binh',
            parentId: 860,
          },
          {
            title: 'Xã Tân Lộc',
            code: 29722,
            value: 'xa_tan_loc',
            division_type: 'xã',
            short_codename: 'tan_loc',
            parentId: 860,
          },
          {
            title: 'Xã Phú Thịnh',
            code: 29725,
            value: 'xa_phu_thinh',
            division_type: 'xã',
            short_codename: 'phu_thinh',
            parentId: 860,
          },
          {
            title: 'Xã Hậu Lộc',
            code: 29728,
            value: 'xa_hau_loc',
            division_type: 'xã',
            short_codename: 'hau_loc',
            parentId: 860,
          },
          {
            title: 'Xã Hòa Thạnh',
            code: 29731,
            value: 'xa_hoa_thanh',
            division_type: 'xã',
            short_codename: 'hoa_thanh',
            parentId: 860,
          },
          {
            title: 'Xã Hoà Lộc',
            code: 29734,
            value: 'xa_hoa_loc',
            division_type: 'xã',
            short_codename: 'hoa_loc',
            parentId: 860,
          },
          {
            title: 'Xã Phú Lộc',
            code: 29737,
            value: 'xa_phu_loc',
            division_type: 'xã',
            short_codename: 'phu_loc',
            parentId: 860,
          },
          {
            title: 'Xã Song Phú',
            code: 29740,
            value: 'xa_song_phu',
            division_type: 'xã',
            short_codename: 'song_phu',
            parentId: 860,
          },
          {
            title: 'Xã Hòa Hiệp',
            code: 29743,
            value: 'xa_hoa_hiep',
            division_type: 'xã',
            short_codename: 'hoa_hiep',
            parentId: 860,
          },
          {
            title: 'Xã Mỹ Lộc',
            code: 29746,
            value: 'xa_my_loc',
            division_type: 'xã',
            short_codename: 'my_loc',
            parentId: 860,
          },
          {
            title: 'Xã Tân Phú',
            code: 29749,
            value: 'xa_tan_phu',
            division_type: 'xã',
            short_codename: 'tan_phu',
            parentId: 860,
          },
          {
            title: 'Xã Long Phú',
            code: 29752,
            value: 'xa_long_phu',
            division_type: 'xã',
            short_codename: 'long_phu',
            parentId: 860,
          },
          {
            title: 'Xã Mỹ Thạnh Trung',
            code: 29755,
            value: 'xa_my_thanh_trung',
            division_type: 'xã',
            short_codename: 'my_thanh_trung',
            parentId: 860,
          },
          {
            title: 'Xã Tường Lộc',
            code: 29758,
            value: 'xa_tuong_loc',
            division_type: 'xã',
            short_codename: 'tuong_loc',
            parentId: 860,
          },
          {
            title: 'Xã Loan Mỹ',
            code: 29761,
            value: 'xa_loan_my',
            division_type: 'xã',
            short_codename: 'loan_my',
            parentId: 860,
          },
          {
            title: 'Xã Ngãi Tứ',
            code: 29764,
            value: 'xa_ngai_tu',
            division_type: 'xã',
            short_codename: 'ngai_tu',
            parentId: 860,
          },
          {
            title: 'Xã Bình Ninh',
            code: 29767,
            value: 'xa_binh_ninh',
            division_type: 'xã',
            short_codename: 'binh_ninh',
            parentId: 860,
          },
        ],
        parentId: 86,
      },
      {
        title: 'Thị xã Bình Minh',
        code: 861,
        value: 'thi_xa_binh_minh',
        division_type: 'thị xã',
        short_codename: 'binh_minh',
        children: [
          {
            title: 'Phường Cái Vồn',
            code: 29770,
            value: 'phuong_cai_von',
            division_type: 'phường',
            short_codename: 'cai_von',
            parentId: 861,
          },
          {
            title: 'Phường Thành Phước',
            code: 29771,
            value: 'phuong_thanh_phuoc',
            division_type: 'phường',
            short_codename: 'thanh_phuoc',
            parentId: 861,
          },
          {
            title: 'Xã Thuận An',
            code: 29806,
            value: 'xa_thuan_an',
            division_type: 'xã',
            short_codename: 'thuan_an',
            parentId: 861,
          },
          {
            title: 'Xã Đông Thạnh',
            code: 29809,
            value: 'xa_dong_thanh',
            division_type: 'xã',
            short_codename: 'xa_dong_thanh',
            parentId: 861,
          },
          {
            title: 'Xã Đông Bình',
            code: 29812,
            value: 'xa_dong_binh',
            division_type: 'xã',
            short_codename: 'dong_binh',
            parentId: 861,
          },
          {
            title: 'Phường Đông Thuận',
            code: 29813,
            value: 'phuong_dong_thuan',
            division_type: 'phường',
            short_codename: 'dong_thuan',
            parentId: 861,
          },
          {
            title: 'Xã Mỹ Hòa',
            code: 29815,
            value: 'xa_my_hoa',
            division_type: 'xã',
            short_codename: 'my_hoa',
            parentId: 861,
          },
          {
            title: 'Xã Đông Thành',
            code: 29818,
            value: 'xa_dong_thanh',
            division_type: 'xã',
            short_codename: 'xa_dong_thanh',
            parentId: 861,
          },
        ],
        parentId: 86,
      },
      {
        title: 'Huyện Trà Ôn',
        code: 862,
        value: 'huyen_tra_on',
        division_type: 'huyện',
        short_codename: 'tra_on',
        children: [
          {
            title: 'Thị trấn Trà Ôn',
            code: 29821,
            value: 'thi_tran_tra_on',
            division_type: 'thị trấn',
            short_codename: 'tra_on',
            parentId: 862,
          },
          {
            title: 'Xã Xuân Hiệp',
            code: 29824,
            value: 'xa_xuan_hiep',
            division_type: 'xã',
            short_codename: 'xuan_hiep',
            parentId: 862,
          },
          {
            title: 'Xã Nhơn Bình',
            code: 29827,
            value: 'xa_nhon_binh',
            division_type: 'xã',
            short_codename: 'nhon_binh',
            parentId: 862,
          },
          {
            title: 'Xã Hòa Bình',
            code: 29830,
            value: 'xa_hoa_binh',
            division_type: 'xã',
            short_codename: 'hoa_binh',
            parentId: 862,
          },
          {
            title: 'Xã Thới Hòa',
            code: 29833,
            value: 'xa_thoi_hoa',
            division_type: 'xã',
            short_codename: 'thoi_hoa',
            parentId: 862,
          },
          {
            title: 'Xã Trà Côn',
            code: 29836,
            value: 'xa_tra_con',
            division_type: 'xã',
            short_codename: 'tra_con',
            parentId: 862,
          },
          {
            title: 'Xã Tân Mỹ',
            code: 29839,
            value: 'xa_tan_my',
            division_type: 'xã',
            short_codename: 'tan_my',
            parentId: 862,
          },
          {
            title: 'Xã Hựu Thành',
            code: 29842,
            value: 'xa_huu_thanh',
            division_type: 'xã',
            short_codename: 'huu_thanh',
            parentId: 862,
          },
          {
            title: 'Xã Vĩnh Xuân',
            code: 29845,
            value: 'xa_vinh_xuan',
            division_type: 'xã',
            short_codename: 'vinh_xuan',
            parentId: 862,
          },
          {
            title: 'Xã Thuận Thới',
            code: 29848,
            value: 'xa_thuan_thoi',
            division_type: 'xã',
            short_codename: 'thuan_thoi',
            parentId: 862,
          },
          {
            title: 'Xã Phú Thành',
            code: 29851,
            value: 'xa_phu_thanh',
            division_type: 'xã',
            short_codename: 'phu_thanh',
            parentId: 862,
          },
          {
            title: 'Xã Thiện Mỹ',
            code: 29854,
            value: 'xa_thien_my',
            division_type: 'xã',
            short_codename: 'thien_my',
            parentId: 862,
          },
          {
            title: 'Xã Lục Sỹ Thành',
            code: 29857,
            value: 'xa_luc_sy_thanh',
            division_type: 'xã',
            short_codename: 'luc_sy_thanh',
            parentId: 862,
          },
          {
            title: 'Xã Tích Thiện',
            code: 29860,
            value: 'xa_tich_thien',
            division_type: 'xã',
            short_codename: 'tich_thien',
            parentId: 862,
          },
        ],
        parentId: 86,
      },
      {
        title: 'Huyện Bình Tân',
        code: 863,
        value: 'huyen_binh_tan',
        division_type: 'huyện',
        short_codename: 'binh_tan',
        children: [
          {
            title: 'Xã Tân Hưng',
            code: 29773,
            value: 'xa_tan_hung',
            division_type: 'xã',
            short_codename: 'tan_hung',
            parentId: 863,
          },
          {
            title: 'Xã Tân Thành',
            code: 29776,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 863,
          },
          {
            title: 'Xã Thành Trung',
            code: 29779,
            value: 'xa_thanh_trung',
            division_type: 'xã',
            short_codename: 'thanh_trung',
            parentId: 863,
          },
          {
            title: 'Xã Tân An Thạnh',
            code: 29782,
            value: 'xa_tan_an_thanh',
            division_type: 'xã',
            short_codename: 'tan_an_thanh',
            parentId: 863,
          },
          {
            title: 'Xã Tân Lược',
            code: 29785,
            value: 'xa_tan_luoc',
            division_type: 'xã',
            short_codename: 'tan_luoc',
            parentId: 863,
          },
          {
            title: 'Xã Nguyễn Văn Thảnh',
            code: 29788,
            value: 'xa_nguyen_van_thanh',
            division_type: 'xã',
            short_codename: 'nguyen_van_thanh',
            parentId: 863,
          },
          {
            title: 'Xã Thành Lợi',
            code: 29791,
            value: 'xa_thanh_loi',
            division_type: 'xã',
            short_codename: 'thanh_loi',
            parentId: 863,
          },
          {
            title: 'Xã Mỹ Thuận',
            code: 29794,
            value: 'xa_my_thuan',
            division_type: 'xã',
            short_codename: 'my_thuan',
            parentId: 863,
          },
          {
            title: 'Xã Tân Bình',
            code: 29797,
            value: 'xa_tan_binh',
            division_type: 'xã',
            short_codename: 'tan_binh',
            parentId: 863,
          },
          {
            title: 'Thị trấn Tân Quới',
            code: 29800,
            value: 'thi_tran_tan_quoi',
            division_type: 'thị trấn',
            short_codename: 'tan_quoi',
            parentId: 863,
          },
        ],
        parentId: 86,
      },
    ],
  },
  {
    title: 'Tỉnh Đồng Tháp',
    code: 87,
    value: 'tinh_dong_thap',
    division_type: 'tỉnh',
    phone_code: 277,
    children: [
      {
        title: 'Thành phố Cao Lãnh',
        code: 866,
        value: 'thanh_pho_cao_lanh',
        division_type: 'thành phố',
        short_codename: 'cao_lanh',
        children: [
          {
            title: 'Phường 11',
            code: 29863,
            value: 'phuong_11',
            division_type: 'phường',
            short_codename: 'phuong_11',
            parentId: 866,
          },
          {
            title: 'Phường 1',
            code: 29866,
            value: 'phuong_1',
            division_type: 'phường',
            short_codename: 'phuong_1',
            parentId: 866,
          },
          {
            title: 'Phường 2',
            code: 29869,
            value: 'phuong_2',
            division_type: 'phường',
            short_codename: 'phuong_2',
            parentId: 866,
          },
          {
            title: 'Phường 4',
            code: 29872,
            value: 'phuong_4',
            division_type: 'phường',
            short_codename: 'phuong_4',
            parentId: 866,
          },
          {
            title: 'Phường 3',
            code: 29875,
            value: 'phuong_3',
            division_type: 'phường',
            short_codename: 'phuong_3',
            parentId: 866,
          },
          {
            title: 'Phường 6',
            code: 29878,
            value: 'phuong_6',
            division_type: 'phường',
            short_codename: 'phuong_6',
            parentId: 866,
          },
          {
            title: 'Xã Mỹ Ngãi',
            code: 29881,
            value: 'xa_my_ngai',
            division_type: 'xã',
            short_codename: 'my_ngai',
            parentId: 866,
          },
          {
            title: 'Xã Mỹ Tân',
            code: 29884,
            value: 'xa_my_tan',
            division_type: 'xã',
            short_codename: 'my_tan',
            parentId: 866,
          },
          {
            title: 'Xã Mỹ Trà',
            code: 29887,
            value: 'xa_my_tra',
            division_type: 'xã',
            short_codename: 'my_tra',
            parentId: 866,
          },
          {
            title: 'Phường Mỹ Phú',
            code: 29888,
            value: 'phuong_my_phu',
            division_type: 'phường',
            short_codename: 'my_phu',
            parentId: 866,
          },
          {
            title: 'Xã Tân Thuận Tây',
            code: 29890,
            value: 'xa_tan_thuan_tay',
            division_type: 'xã',
            short_codename: 'tan_thuan_tay',
            parentId: 866,
          },
          {
            title: 'Phường Hoà Thuận',
            code: 29892,
            value: 'phuong_hoa_thuan',
            division_type: 'phường',
            short_codename: 'hoa_thuan',
            parentId: 866,
          },
          {
            title: 'Xã Hòa An',
            code: 29893,
            value: 'xa_hoa_an',
            division_type: 'xã',
            short_codename: 'hoa_an',
            parentId: 866,
          },
          {
            title: 'Xã Tân Thuận Đông',
            code: 29896,
            value: 'xa_tan_thuan_dong',
            division_type: 'xã',
            short_codename: 'tan_thuan_dong',
            parentId: 866,
          },
          {
            title: 'Xã Tịnh Thới',
            code: 29899,
            value: 'xa_tinh_thoi',
            division_type: 'xã',
            short_codename: 'tinh_thoi',
            parentId: 866,
          },
        ],
        parentId: 87,
      },
      {
        title: 'Thành phố Sa Đéc',
        code: 867,
        value: 'thanh_pho_sa_dec',
        division_type: 'thành phố',
        short_codename: 'sa_dec',
        children: [
          {
            title: 'Phường 3',
            code: 29902,
            value: 'phuong_3',
            division_type: 'phường',
            short_codename: 'phuong_3',
            parentId: 867,
          },
          {
            title: 'Phường 1',
            code: 29905,
            value: 'phuong_1',
            division_type: 'phường',
            short_codename: 'phuong_1',
            parentId: 867,
          },
          {
            title: 'Phường 4',
            code: 29908,
            value: 'phuong_4',
            division_type: 'phường',
            short_codename: 'phuong_4',
            parentId: 867,
          },
          {
            title: 'Phường 2',
            code: 29911,
            value: 'phuong_2',
            division_type: 'phường',
            short_codename: 'phuong_2',
            parentId: 867,
          },
          {
            title: 'Xã Tân Khánh Đông',
            code: 29914,
            value: 'xa_tan_khanh_dong',
            division_type: 'xã',
            short_codename: 'tan_khanh_dong',
            parentId: 867,
          },
          {
            title: 'Phường Tân Quy Đông',
            code: 29917,
            value: 'phuong_tan_quy_dong',
            division_type: 'phường',
            short_codename: 'tan_quy_dong',
            parentId: 867,
          },
          {
            title: 'Phường An Hoà',
            code: 29919,
            value: 'phuong_an_hoa',
            division_type: 'phường',
            short_codename: 'an_hoa',
            parentId: 867,
          },
          {
            title: 'Xã Tân Quy Tây',
            code: 29920,
            value: 'xa_tan_quy_tay',
            division_type: 'xã',
            short_codename: 'tan_quy_tay',
            parentId: 867,
          },
          {
            title: 'Xã Tân Phú Đông',
            code: 29923,
            value: 'xa_tan_phu_dong',
            division_type: 'xã',
            short_codename: 'tan_phu_dong',
            parentId: 867,
          },
        ],
        parentId: 87,
      },
      {
        title: 'Thành phố Hồng Ngự',
        code: 868,
        value: 'thanh_pho_hong_ngu',
        division_type: 'thành phố',
        short_codename: 'hong_ngu',
        children: [
          {
            title: 'Phường An Lộc',
            code: 29954,
            value: 'phuong_an_loc',
            division_type: 'phường',
            short_codename: 'an_loc',
            parentId: 868,
          },
          {
            title: 'Phường An Thạnh',
            code: 29955,
            value: 'phuong_an_thanh',
            division_type: 'phường',
            short_codename: 'an_thanh',
            parentId: 868,
          },
          {
            title: 'Xã Bình Thạnh',
            code: 29959,
            value: 'xa_binh_thanh',
            division_type: 'xã',
            short_codename: 'binh_thanh',
            parentId: 868,
          },
          {
            title: 'Xã Tân Hội',
            code: 29965,
            value: 'xa_tan_hoi',
            division_type: 'xã',
            short_codename: 'tan_hoi',
            parentId: 868,
          },
          {
            title: 'Phường An Lạc',
            code: 29978,
            value: 'phuong_an_lac',
            division_type: 'phường',
            short_codename: 'an_lac',
            parentId: 868,
          },
          {
            title: 'Phường An Bình B',
            code: 29986,
            value: 'phuong_an_binh_b',
            division_type: 'phường',
            short_codename: 'an_binh_b',
            parentId: 868,
          },
          {
            title: 'Phường An Bình A',
            code: 29989,
            value: 'phuong_an_binh_a',
            division_type: 'phường',
            short_codename: 'an_binh_a',
            parentId: 868,
          },
        ],
        parentId: 87,
      },
      {
        title: 'Huyện Tân Hồng',
        code: 869,
        value: 'huyen_tan_hong',
        division_type: 'huyện',
        short_codename: 'tan_hong',
        children: [
          {
            title: 'Thị trấn Sa Rài',
            code: 29926,
            value: 'thi_tran_sa_rai',
            division_type: 'thị trấn',
            short_codename: 'sa_rai',
            parentId: 869,
          },
          {
            title: 'Xã Tân Hộ Cơ',
            code: 29929,
            value: 'xa_tan_ho_co',
            division_type: 'xã',
            short_codename: 'tan_ho_co',
            parentId: 869,
          },
          {
            title: 'Xã Thông Bình',
            code: 29932,
            value: 'xa_thong_binh',
            division_type: 'xã',
            short_codename: 'thong_binh',
            parentId: 869,
          },
          {
            title: 'Xã Bình Phú',
            code: 29935,
            value: 'xa_binh_phu',
            division_type: 'xã',
            short_codename: 'binh_phu',
            parentId: 869,
          },
          {
            title: 'Xã Tân Thành A',
            code: 29938,
            value: 'xa_tan_thanh_a',
            division_type: 'xã',
            short_codename: 'tan_thanh_a',
            parentId: 869,
          },
          {
            title: 'Xã Tân Thành B',
            code: 29941,
            value: 'xa_tan_thanh_b',
            division_type: 'xã',
            short_codename: 'tan_thanh_b',
            parentId: 869,
          },
          {
            title: 'Xã Tân Phước',
            code: 29944,
            value: 'xa_tan_phuoc',
            division_type: 'xã',
            short_codename: 'tan_phuoc',
            parentId: 869,
          },
          {
            title: 'Xã Tân Công Chí',
            code: 29947,
            value: 'xa_tan_cong_chi',
            division_type: 'xã',
            short_codename: 'tan_cong_chi',
            parentId: 869,
          },
          {
            title: 'Xã An Phước',
            code: 29950,
            value: 'xa_an_phuoc',
            division_type: 'xã',
            short_codename: 'an_phuoc',
            parentId: 869,
          },
        ],
        parentId: 87,
      },
      {
        title: 'Huyện Hồng Ngự',
        code: 870,
        value: 'huyen_hong_ngu',
        division_type: 'huyện',
        short_codename: 'huyen_hong_ngu',
        children: [
          {
            title: 'Xã Thường Phước 1',
            code: 29956,
            value: 'xa_thuong_phuoc_1',
            division_type: 'xã',
            short_codename: 'thuong_phuoc_1',
            parentId: 870,
          },
          {
            title: 'Xã Thường Thới Hậu A',
            code: 29962,
            value: 'xa_thuong_thoi_hau_a',
            division_type: 'xã',
            short_codename: 'thuong_thoi_hau_a',
            parentId: 870,
          },
          {
            title: 'Thị trấn Thường Thới Tiền',
            code: 29971,
            value: 'thi_tran_thuong_thoi_tien',
            division_type: 'thị trấn',
            short_codename: 'thuong_thoi_tien',
            parentId: 870,
          },
          {
            title: 'Xã Thường Phước 2',
            code: 29974,
            value: 'xa_thuong_phuoc_2',
            division_type: 'xã',
            short_codename: 'thuong_phuoc_2',
            parentId: 870,
          },
          {
            title: 'Xã Thường Lạc',
            code: 29977,
            value: 'xa_thuong_lac',
            division_type: 'xã',
            short_codename: 'thuong_lac',
            parentId: 870,
          },
          {
            title: 'Xã Long Khánh A',
            code: 29980,
            value: 'xa_long_khanh_a',
            division_type: 'xã',
            short_codename: 'long_khanh_a',
            parentId: 870,
          },
          {
            title: 'Xã Long Khánh B',
            code: 29983,
            value: 'xa_long_khanh_b',
            division_type: 'xã',
            short_codename: 'long_khanh_b',
            parentId: 870,
          },
          {
            title: 'Xã Long Thuận',
            code: 29992,
            value: 'xa_long_thuan',
            division_type: 'xã',
            short_codename: 'long_thuan',
            parentId: 870,
          },
          {
            title: 'Xã Phú Thuận B',
            code: 29995,
            value: 'xa_phu_thuan_b',
            division_type: 'xã',
            short_codename: 'phu_thuan_b',
            parentId: 870,
          },
          {
            title: 'Xã Phú Thuận A',
            code: 29998,
            value: 'xa_phu_thuan_a',
            division_type: 'xã',
            short_codename: 'phu_thuan_a',
            parentId: 870,
          },
        ],
        parentId: 87,
      },
      {
        title: 'Huyện Tam Nông',
        code: 871,
        value: 'huyen_tam_nong',
        division_type: 'huyện',
        short_codename: 'tam_nong',
        children: [
          {
            title: 'Thị trấn Tràm Chim',
            code: 30001,
            value: 'thi_tran_tram_chim',
            division_type: 'thị trấn',
            short_codename: 'tram_chim',
            parentId: 871,
          },
          {
            title: 'Xã Hoà Bình',
            code: 30004,
            value: 'xa_hoa_binh',
            division_type: 'xã',
            short_codename: 'hoa_binh',
            parentId: 871,
          },
          {
            title: 'Xã Tân Công Sính',
            code: 30007,
            value: 'xa_tan_cong_sinh',
            division_type: 'xã',
            short_codename: 'tan_cong_sinh',
            parentId: 871,
          },
          {
            title: 'Xã Phú Hiệp',
            code: 30010,
            value: 'xa_phu_hiep',
            division_type: 'xã',
            short_codename: 'phu_hiep',
            parentId: 871,
          },
          {
            title: 'Xã Phú Đức',
            code: 30013,
            value: 'xa_phu_duc',
            division_type: 'xã',
            short_codename: 'phu_duc',
            parentId: 871,
          },
          {
            title: 'Xã Phú Thành B',
            code: 30016,
            value: 'xa_phu_thanh_b',
            division_type: 'xã',
            short_codename: 'phu_thanh_b',
            parentId: 871,
          },
          {
            title: 'Xã An Hòa',
            code: 30019,
            value: 'xa_an_hoa',
            division_type: 'xã',
            short_codename: 'an_hoa',
            parentId: 871,
          },
          {
            title: 'Xã An Long',
            code: 30022,
            value: 'xa_an_long',
            division_type: 'xã',
            short_codename: 'an_long',
            parentId: 871,
          },
          {
            title: 'Xã Phú Cường',
            code: 30025,
            value: 'xa_phu_cuong',
            division_type: 'xã',
            short_codename: 'phu_cuong',
            parentId: 871,
          },
          {
            title: 'Xã Phú Ninh',
            code: 30028,
            value: 'xa_phu_ninh',
            division_type: 'xã',
            short_codename: 'phu_ninh',
            parentId: 871,
          },
          {
            title: 'Xã Phú Thọ',
            code: 30031,
            value: 'xa_phu_tho',
            division_type: 'xã',
            short_codename: 'phu_tho',
            parentId: 871,
          },
          {
            title: 'Xã Phú Thành A',
            code: 30034,
            value: 'xa_phu_thanh_a',
            division_type: 'xã',
            short_codename: 'phu_thanh_a',
            parentId: 871,
          },
        ],
        parentId: 87,
      },
      {
        title: 'Huyện Tháp Mười',
        code: 872,
        value: 'huyen_thap_muoi',
        division_type: 'huyện',
        short_codename: 'thap_muoi',
        children: [
          {
            title: 'Thị trấn Mỹ An',
            code: 30037,
            value: 'thi_tran_my_an',
            division_type: 'thị trấn',
            short_codename: 'my_an',
            parentId: 872,
          },
          {
            title: 'Xã Thạnh Lợi',
            code: 30040,
            value: 'xa_thanh_loi',
            division_type: 'xã',
            short_codename: 'thanh_loi',
            parentId: 872,
          },
          {
            title: 'Xã Hưng Thạnh',
            code: 30043,
            value: 'xa_hung_thanh',
            division_type: 'xã',
            short_codename: 'hung_thanh',
            parentId: 872,
          },
          {
            title: 'Xã Trường Xuân',
            code: 30046,
            value: 'xa_truong_xuan',
            division_type: 'xã',
            short_codename: 'truong_xuan',
            parentId: 872,
          },
          {
            title: 'Xã Tân Kiều',
            code: 30049,
            value: 'xa_tan_kieu',
            division_type: 'xã',
            short_codename: 'tan_kieu',
            parentId: 872,
          },
          {
            title: 'Xã Mỹ Hòa',
            code: 30052,
            value: 'xa_my_hoa',
            division_type: 'xã',
            short_codename: 'my_hoa',
            parentId: 872,
          },
          {
            title: 'Xã Mỹ Quý',
            code: 30055,
            value: 'xa_my_quy',
            division_type: 'xã',
            short_codename: 'my_quy',
            parentId: 872,
          },
          {
            title: 'Xã Mỹ Đông',
            code: 30058,
            value: 'xa_my_dong',
            division_type: 'xã',
            short_codename: 'my_dong',
            parentId: 872,
          },
          {
            title: 'Xã Đốc Binh Kiều',
            code: 30061,
            value: 'xa_doc_binh_kieu',
            division_type: 'xã',
            short_codename: 'doc_binh_kieu',
            parentId: 872,
          },
          {
            title: 'Xã Mỹ An',
            code: 30064,
            value: 'xa_my_an',
            division_type: 'xã',
            short_codename: 'xa_my_an',
            parentId: 872,
          },
          {
            title: 'Xã Phú Điền',
            code: 30067,
            value: 'xa_phu_dien',
            division_type: 'xã',
            short_codename: 'phu_dien',
            parentId: 872,
          },
          {
            title: 'Xã Láng Biển',
            code: 30070,
            value: 'xa_lang_bien',
            division_type: 'xã',
            short_codename: 'lang_bien',
            parentId: 872,
          },
          {
            title: 'Xã Thanh Mỹ',
            code: 30073,
            value: 'xa_thanh_my',
            division_type: 'xã',
            short_codename: 'thanh_my',
            parentId: 872,
          },
        ],
        parentId: 87,
      },
      {
        title: 'Huyện Cao Lãnh',
        code: 873,
        value: 'huyen_cao_lanh',
        division_type: 'huyện',
        short_codename: 'huyen_cao_lanh',
        children: [
          {
            title: 'Thị trấn Mỹ Thọ',
            code: 30076,
            value: 'thi_tran_my_tho',
            division_type: 'thị trấn',
            short_codename: 'my_tho',
            parentId: 873,
          },
          {
            title: 'Xã Gáo Giồng',
            code: 30079,
            value: 'xa_gao_giong',
            division_type: 'xã',
            short_codename: 'gao_giong',
            parentId: 873,
          },
          {
            title: 'Xã Phương Thịnh',
            code: 30082,
            value: 'xa_phuong_thinh',
            division_type: 'xã',
            short_codename: 'phuong_thinh',
            parentId: 873,
          },
          {
            title: 'Xã Ba Sao',
            code: 30085,
            value: 'xa_ba_sao',
            division_type: 'xã',
            short_codename: 'ba_sao',
            parentId: 873,
          },
          {
            title: 'Xã Phong Mỹ',
            code: 30088,
            value: 'xa_phong_my',
            division_type: 'xã',
            short_codename: 'phong_my',
            parentId: 873,
          },
          {
            title: 'Xã Tân Nghĩa',
            code: 30091,
            value: 'xa_tan_nghia',
            division_type: 'xã',
            short_codename: 'tan_nghia',
            parentId: 873,
          },
          {
            title: 'Xã Phương Trà',
            code: 30094,
            value: 'xa_phuong_tra',
            division_type: 'xã',
            short_codename: 'phuong_tra',
            parentId: 873,
          },
          {
            title: 'Xã Nhị Mỹ',
            code: 30097,
            value: 'xa_nhi_my',
            division_type: 'xã',
            short_codename: 'nhi_my',
            parentId: 873,
          },
          {
            title: 'Xã Mỹ Thọ',
            code: 30100,
            value: 'xa_my_tho',
            division_type: 'xã',
            short_codename: 'xa_my_tho',
            parentId: 873,
          },
          {
            title: 'Xã Tân Hội Trung',
            code: 30103,
            value: 'xa_tan_hoi_trung',
            division_type: 'xã',
            short_codename: 'tan_hoi_trung',
            parentId: 873,
          },
          {
            title: 'Xã An Bình',
            code: 30106,
            value: 'xa_an_binh',
            division_type: 'xã',
            short_codename: 'an_binh',
            parentId: 873,
          },
          {
            title: 'Xã Mỹ Hội',
            code: 30109,
            value: 'xa_my_hoi',
            division_type: 'xã',
            short_codename: 'my_hoi',
            parentId: 873,
          },
          {
            title: 'Xã Mỹ Hiệp',
            code: 30112,
            value: 'xa_my_hiep',
            division_type: 'xã',
            short_codename: 'my_hiep',
            parentId: 873,
          },
          {
            title: 'Xã Mỹ Long',
            code: 30115,
            value: 'xa_my_long',
            division_type: 'xã',
            short_codename: 'my_long',
            parentId: 873,
          },
          {
            title: 'Xã Bình Hàng Trung',
            code: 30118,
            value: 'xa_binh_hang_trung',
            division_type: 'xã',
            short_codename: 'binh_hang_trung',
            parentId: 873,
          },
          {
            title: 'Xã Mỹ Xương',
            code: 30121,
            value: 'xa_my_xuong',
            division_type: 'xã',
            short_codename: 'my_xuong',
            parentId: 873,
          },
          {
            title: 'Xã Bình Hàng Tây',
            code: 30124,
            value: 'xa_binh_hang_tay',
            division_type: 'xã',
            short_codename: 'binh_hang_tay',
            parentId: 873,
          },
          {
            title: 'Xã Bình Thạnh',
            code: 30127,
            value: 'xa_binh_thanh',
            division_type: 'xã',
            short_codename: 'binh_thanh',
            parentId: 873,
          },
        ],
        parentId: 87,
      },
      {
        title: 'Huyện Thanh Bình',
        code: 874,
        value: 'huyen_thanh_binh',
        division_type: 'huyện',
        short_codename: 'thanh_binh',
        children: [
          {
            title: 'Thị trấn Thanh Bình',
            code: 30130,
            value: 'thi_tran_thanh_binh',
            division_type: 'thị trấn',
            short_codename: 'thanh_binh',
            parentId: 874,
          },
          {
            title: 'Xã Tân Quới',
            code: 30133,
            value: 'xa_tan_quoi',
            division_type: 'xã',
            short_codename: 'tan_quoi',
            parentId: 874,
          },
          {
            title: 'Xã Tân Hòa',
            code: 30136,
            value: 'xa_tan_hoa',
            division_type: 'xã',
            short_codename: 'tan_hoa',
            parentId: 874,
          },
          {
            title: 'Xã An Phong',
            code: 30139,
            value: 'xa_an_phong',
            division_type: 'xã',
            short_codename: 'an_phong',
            parentId: 874,
          },
          {
            title: 'Xã Phú Lợi',
            code: 30142,
            value: 'xa_phu_loi',
            division_type: 'xã',
            short_codename: 'phu_loi',
            parentId: 874,
          },
          {
            title: 'Xã Tân Mỹ',
            code: 30145,
            value: 'xa_tan_my',
            division_type: 'xã',
            short_codename: 'tan_my',
            parentId: 874,
          },
          {
            title: 'Xã Bình Tấn',
            code: 30148,
            value: 'xa_binh_tan',
            division_type: 'xã',
            short_codename: 'binh_tan',
            parentId: 874,
          },
          {
            title: 'Xã Tân Huề',
            code: 30151,
            value: 'xa_tan_hue',
            division_type: 'xã',
            short_codename: 'tan_hue',
            parentId: 874,
          },
          {
            title: 'Xã Tân Bình',
            code: 30154,
            value: 'xa_tan_binh',
            division_type: 'xã',
            short_codename: 'tan_binh',
            parentId: 874,
          },
          {
            title: 'Xã Tân Thạnh',
            code: 30157,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 874,
          },
          {
            title: 'Xã Tân Phú',
            code: 30160,
            value: 'xa_tan_phu',
            division_type: 'xã',
            short_codename: 'tan_phu',
            parentId: 874,
          },
          {
            title: 'Xã Bình Thành',
            code: 30163,
            value: 'xa_binh_thanh',
            division_type: 'xã',
            short_codename: 'binh_thanh',
            parentId: 874,
          },
          {
            title: 'Xã Tân Long',
            code: 30166,
            value: 'xa_tan_long',
            division_type: 'xã',
            short_codename: 'tan_long',
            parentId: 874,
          },
        ],
        parentId: 87,
      },
      {
        title: 'Huyện Lấp Vò',
        code: 875,
        value: 'huyen_lap_vo',
        division_type: 'huyện',
        short_codename: 'lap_vo',
        children: [
          {
            title: 'Thị trấn Lấp Vò',
            code: 30169,
            value: 'thi_tran_lap_vo',
            division_type: 'thị trấn',
            short_codename: 'lap_vo',
            parentId: 875,
          },
          {
            title: 'Xã Mỹ An Hưng A',
            code: 30172,
            value: 'xa_my_an_hung_a',
            division_type: 'xã',
            short_codename: 'my_an_hung_a',
            parentId: 875,
          },
          {
            title: 'Xã Tân Mỹ',
            code: 30175,
            value: 'xa_tan_my',
            division_type: 'xã',
            short_codename: 'tan_my',
            parentId: 875,
          },
          {
            title: 'Xã Mỹ An Hưng B',
            code: 30178,
            value: 'xa_my_an_hung_b',
            division_type: 'xã',
            short_codename: 'my_an_hung_b',
            parentId: 875,
          },
          {
            title: 'Xã Tân Khánh Trung',
            code: 30181,
            value: 'xa_tan_khanh_trung',
            division_type: 'xã',
            short_codename: 'tan_khanh_trung',
            parentId: 875,
          },
          {
            title: 'Xã Long Hưng A',
            code: 30184,
            value: 'xa_long_hung_a',
            division_type: 'xã',
            short_codename: 'long_hung_a',
            parentId: 875,
          },
          {
            title: 'Xã Vĩnh Thạnh',
            code: 30187,
            value: 'xa_vinh_thanh',
            division_type: 'xã',
            short_codename: 'vinh_thanh',
            parentId: 875,
          },
          {
            title: 'Xã Long Hưng B',
            code: 30190,
            value: 'xa_long_hung_b',
            division_type: 'xã',
            short_codename: 'long_hung_b',
            parentId: 875,
          },
          {
            title: 'Xã Bình Thành',
            code: 30193,
            value: 'xa_binh_thanh',
            division_type: 'xã',
            short_codename: 'binh_thanh',
            parentId: 875,
          },
          {
            title: 'Xã Định An',
            code: 30196,
            value: 'xa_dinh_an',
            division_type: 'xã',
            short_codename: 'dinh_an',
            parentId: 875,
          },
          {
            title: 'Xã Định Yên',
            code: 30199,
            value: 'xa_dinh_yen',
            division_type: 'xã',
            short_codename: 'dinh_yen',
            parentId: 875,
          },
          {
            title: 'Xã Hội An Đông',
            code: 30202,
            value: 'xa_hoi_an_dong',
            division_type: 'xã',
            short_codename: 'hoi_an_dong',
            parentId: 875,
          },
          {
            title: 'Xã Bình Thạnh Trung',
            code: 30205,
            value: 'xa_binh_thanh_trung',
            division_type: 'xã',
            short_codename: 'binh_thanh_trung',
            parentId: 875,
          },
        ],
        parentId: 87,
      },
      {
        title: 'Huyện Lai Vung',
        code: 876,
        value: 'huyen_lai_vung',
        division_type: 'huyện',
        short_codename: 'lai_vung',
        children: [
          {
            title: 'Thị trấn Lai Vung',
            code: 30208,
            value: 'thi_tran_lai_vung',
            division_type: 'thị trấn',
            short_codename: 'lai_vung',
            parentId: 876,
          },
          {
            title: 'Xã Tân Dương',
            code: 30211,
            value: 'xa_tan_duong',
            division_type: 'xã',
            short_codename: 'tan_duong',
            parentId: 876,
          },
          {
            title: 'Xã Hòa Thành',
            code: 30214,
            value: 'xa_hoa_thanh',
            division_type: 'xã',
            short_codename: 'hoa_thanh',
            parentId: 876,
          },
          {
            title: 'Xã Long Hậu',
            code: 30217,
            value: 'xa_long_hau',
            division_type: 'xã',
            short_codename: 'long_hau',
            parentId: 876,
          },
          {
            title: 'Xã Tân Phước',
            code: 30220,
            value: 'xa_tan_phuoc',
            division_type: 'xã',
            short_codename: 'tan_phuoc',
            parentId: 876,
          },
          {
            title: 'Xã Hòa Long',
            code: 30223,
            value: 'xa_hoa_long',
            division_type: 'xã',
            short_codename: 'hoa_long',
            parentId: 876,
          },
          {
            title: 'Xã Tân Thành',
            code: 30226,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 876,
          },
          {
            title: 'Xã Long Thắng',
            code: 30229,
            value: 'xa_long_thang',
            division_type: 'xã',
            short_codename: 'long_thang',
            parentId: 876,
          },
          {
            title: 'Xã Vĩnh Thới',
            code: 30232,
            value: 'xa_vinh_thoi',
            division_type: 'xã',
            short_codename: 'vinh_thoi',
            parentId: 876,
          },
          {
            title: 'Xã Tân Hòa',
            code: 30235,
            value: 'xa_tan_hoa',
            division_type: 'xã',
            short_codename: 'tan_hoa',
            parentId: 876,
          },
          {
            title: 'Xã Định Hòa',
            code: 30238,
            value: 'xa_dinh_hoa',
            division_type: 'xã',
            short_codename: 'dinh_hoa',
            parentId: 876,
          },
          {
            title: 'Xã Phong Hòa',
            code: 30241,
            value: 'xa_phong_hoa',
            division_type: 'xã',
            short_codename: 'phong_hoa',
            parentId: 876,
          },
        ],
        parentId: 87,
      },
      {
        title: 'Huyện Châu Thành',
        code: 877,
        value: 'huyen_chau_thanh',
        division_type: 'huyện',
        short_codename: 'chau_thanh',
        children: [
          {
            title: 'Thị trấn Cái Tàu Hạ',
            code: 30244,
            value: 'thi_tran_cai_tau_ha',
            division_type: 'thị trấn',
            short_codename: 'cai_tau_ha',
            parentId: 877,
          },
          {
            title: 'Xã An Hiệp',
            code: 30247,
            value: 'xa_an_hiep',
            division_type: 'xã',
            short_codename: 'an_hiep',
            parentId: 877,
          },
          {
            title: 'Xã An Nhơn',
            code: 30250,
            value: 'xa_an_nhon',
            division_type: 'xã',
            short_codename: 'an_nhon',
            parentId: 877,
          },
          {
            title: 'Xã Tân Nhuận Đông',
            code: 30253,
            value: 'xa_tan_nhuan_dong',
            division_type: 'xã',
            short_codename: 'tan_nhuan_dong',
            parentId: 877,
          },
          {
            title: 'Xã Tân Bình',
            code: 30256,
            value: 'xa_tan_binh',
            division_type: 'xã',
            short_codename: 'tan_binh',
            parentId: 877,
          },
          {
            title: 'Xã Tân Phú Trung',
            code: 30259,
            value: 'xa_tan_phu_trung',
            division_type: 'xã',
            short_codename: 'tan_phu_trung',
            parentId: 877,
          },
          {
            title: 'Xã Phú Long',
            code: 30262,
            value: 'xa_phu_long',
            division_type: 'xã',
            short_codename: 'phu_long',
            parentId: 877,
          },
          {
            title: 'Xã An Phú Thuận',
            code: 30265,
            value: 'xa_an_phu_thuan',
            division_type: 'xã',
            short_codename: 'an_phu_thuan',
            parentId: 877,
          },
          {
            title: 'Xã Phú Hựu',
            code: 30268,
            value: 'xa_phu_huu',
            division_type: 'xã',
            short_codename: 'phu_huu',
            parentId: 877,
          },
          {
            title: 'Xã An Khánh',
            code: 30271,
            value: 'xa_an_khanh',
            division_type: 'xã',
            short_codename: 'an_khanh',
            parentId: 877,
          },
          {
            title: 'Xã Tân Phú',
            code: 30274,
            value: 'xa_tan_phu',
            division_type: 'xã',
            short_codename: 'tan_phu',
            parentId: 877,
          },
          {
            title: 'Xã Hòa Tân',
            code: 30277,
            value: 'xa_hoa_tan',
            division_type: 'xã',
            short_codename: 'hoa_tan',
            parentId: 877,
          },
        ],
        parentId: 87,
      },
    ],
  },
  {
    title: 'Tỉnh An Giang',
    code: 89,
    value: 'tinh_an_giang',
    division_type: 'tỉnh',
    phone_code: 296,
    children: [
      {
        title: 'Thành phố Long Xuyên',
        code: 883,
        value: 'thanh_pho_long_xuyen',
        division_type: 'thành phố',
        short_codename: 'long_xuyen',
        children: [
          {
            title: 'Phường Mỹ Bình',
            code: 30280,
            value: 'phuong_my_binh',
            division_type: 'phường',
            short_codename: 'my_binh',
            parentId: 883,
          },
          {
            title: 'Phường Mỹ Long',
            code: 30283,
            value: 'phuong_my_long',
            division_type: 'phường',
            short_codename: 'my_long',
            parentId: 883,
          },
          {
            title: 'Phường Đông Xuyên',
            code: 30285,
            value: 'phuong_dong_xuyen',
            division_type: 'phường',
            short_codename: 'dong_xuyen',
            parentId: 883,
          },
          {
            title: 'Phường Mỹ Xuyên',
            code: 30286,
            value: 'phuong_my_xuyen',
            division_type: 'phường',
            short_codename: 'my_xuyen',
            parentId: 883,
          },
          {
            title: 'Phường Bình Đức',
            code: 30289,
            value: 'phuong_binh_duc',
            division_type: 'phường',
            short_codename: 'binh_duc',
            parentId: 883,
          },
          {
            title: 'Phường Bình Khánh',
            code: 30292,
            value: 'phuong_binh_khanh',
            division_type: 'phường',
            short_codename: 'binh_khanh',
            parentId: 883,
          },
          {
            title: 'Phường Mỹ Phước',
            code: 30295,
            value: 'phuong_my_phuoc',
            division_type: 'phường',
            short_codename: 'my_phuoc',
            parentId: 883,
          },
          {
            title: 'Phường Mỹ Quý',
            code: 30298,
            value: 'phuong_my_quy',
            division_type: 'phường',
            short_codename: 'my_quy',
            parentId: 883,
          },
          {
            title: 'Phường Mỹ Thới',
            code: 30301,
            value: 'phuong_my_thoi',
            division_type: 'phường',
            short_codename: 'my_thoi',
            parentId: 883,
          },
          {
            title: 'Phường Mỹ Thạnh',
            code: 30304,
            value: 'phuong_my_thanh',
            division_type: 'phường',
            short_codename: 'my_thanh',
            parentId: 883,
          },
          {
            title: 'Phường Mỹ Hòa',
            code: 30307,
            value: 'phuong_my_hoa',
            division_type: 'phường',
            short_codename: 'my_hoa',
            parentId: 883,
          },
          {
            title: 'Xã Mỹ Khánh',
            code: 30310,
            value: 'xa_my_khanh',
            division_type: 'xã',
            short_codename: 'my_khanh',
            parentId: 883,
          },
          {
            title: 'Xã Mỹ Hoà Hưng',
            code: 30313,
            value: 'xa_my_hoa_hung',
            division_type: 'xã',
            short_codename: 'my_hoa_hung',
            parentId: 883,
          },
        ],
        parentId: 89,
      },
      {
        title: 'Thành phố Châu Đốc',
        code: 884,
        value: 'thanh_pho_chau_doc',
        division_type: 'thành phố',
        short_codename: 'chau_doc',
        children: [
          {
            title: 'Phường Châu Phú B',
            code: 30316,
            value: 'phuong_chau_phu_b',
            division_type: 'phường',
            short_codename: 'chau_phu_b',
            parentId: 884,
          },
          {
            title: 'Phường Châu Phú A',
            code: 30319,
            value: 'phuong_chau_phu_a',
            division_type: 'phường',
            short_codename: 'chau_phu_a',
            parentId: 884,
          },
          {
            title: 'Phường Vĩnh Mỹ',
            code: 30322,
            value: 'phuong_vinh_my',
            division_type: 'phường',
            short_codename: 'vinh_my',
            parentId: 884,
          },
          {
            title: 'Phường Núi Sam',
            code: 30325,
            value: 'phuong_nui_sam',
            division_type: 'phường',
            short_codename: 'nui_sam',
            parentId: 884,
          },
          {
            title: 'Phường Vĩnh Ngươn',
            code: 30328,
            value: 'phuong_vinh_nguon',
            division_type: 'phường',
            short_codename: 'vinh_nguon',
            parentId: 884,
          },
          {
            title: 'Xã Vĩnh Tế',
            code: 30331,
            value: 'xa_vinh_te',
            division_type: 'xã',
            short_codename: 'vinh_te',
            parentId: 884,
          },
          {
            title: 'Xã Vĩnh Châu',
            code: 30334,
            value: 'xa_vinh_chau',
            division_type: 'xã',
            short_codename: 'vinh_chau',
            parentId: 884,
          },
        ],
        parentId: 89,
      },
      {
        title: 'Huyện An Phú',
        code: 886,
        value: 'huyen_an_phu',
        division_type: 'huyện',
        short_codename: 'an_phu',
        children: [
          {
            title: 'Thị trấn An Phú',
            code: 30337,
            value: 'thi_tran_an_phu',
            division_type: 'thị trấn',
            short_codename: 'an_phu',
            parentId: 886,
          },
          {
            title: 'Xã Khánh An',
            code: 30340,
            value: 'xa_khanh_an',
            division_type: 'xã',
            short_codename: 'khanh_an',
            parentId: 886,
          },
          {
            title: 'Thị Trấn Long Bình',
            code: 30341,
            value: 'thi_tran_long_binh',
            division_type: 'thị trấn',
            short_codename: 'long_binh',
            parentId: 886,
          },
          {
            title: 'Xã Khánh Bình',
            code: 30343,
            value: 'xa_khanh_binh',
            division_type: 'xã',
            short_codename: 'khanh_binh',
            parentId: 886,
          },
          {
            title: 'Xã Quốc Thái',
            code: 30346,
            value: 'xa_quoc_thai',
            division_type: 'xã',
            short_codename: 'quoc_thai',
            parentId: 886,
          },
          {
            title: 'Xã Nhơn Hội',
            code: 30349,
            value: 'xa_nhon_hoi',
            division_type: 'xã',
            short_codename: 'nhon_hoi',
            parentId: 886,
          },
          {
            title: 'Xã Phú Hữu',
            code: 30352,
            value: 'xa_phu_huu',
            division_type: 'xã',
            short_codename: 'phu_huu',
            parentId: 886,
          },
          {
            title: 'Xã Phú Hội',
            code: 30355,
            value: 'xa_phu_hoi',
            division_type: 'xã',
            short_codename: 'phu_hoi',
            parentId: 886,
          },
          {
            title: 'Xã Phước Hưng',
            code: 30358,
            value: 'xa_phuoc_hung',
            division_type: 'xã',
            short_codename: 'phuoc_hung',
            parentId: 886,
          },
          {
            title: 'Xã Vĩnh Lộc',
            code: 30361,
            value: 'xa_vinh_loc',
            division_type: 'xã',
            short_codename: 'vinh_loc',
            parentId: 886,
          },
          {
            title: 'Xã Vĩnh Hậu',
            code: 30364,
            value: 'xa_vinh_hau',
            division_type: 'xã',
            short_codename: 'vinh_hau',
            parentId: 886,
          },
          {
            title: 'Xã Vĩnh Trường',
            code: 30367,
            value: 'xa_vinh_truong',
            division_type: 'xã',
            short_codename: 'vinh_truong',
            parentId: 886,
          },
          {
            title: 'Xã Vĩnh Hội Đông',
            code: 30370,
            value: 'xa_vinh_hoi_dong',
            division_type: 'xã',
            short_codename: 'vinh_hoi_dong',
            parentId: 886,
          },
          {
            title: 'Xã Đa Phước',
            code: 30373,
            value: 'xa_da_phuoc',
            division_type: 'xã',
            short_codename: 'da_phuoc',
            parentId: 886,
          },
        ],
        parentId: 89,
      },
      {
        title: 'Thị xã Tân Châu',
        code: 887,
        value: 'thi_xa_tan_chau',
        division_type: 'thị xã',
        short_codename: 'tan_chau',
        children: [
          {
            title: 'Phường Long Thạnh',
            code: 30376,
            value: 'phuong_long_thanh',
            division_type: 'phường',
            short_codename: 'long_thanh',
            parentId: 887,
          },
          {
            title: 'Phường Long Hưng',
            code: 30377,
            value: 'phuong_long_hung',
            division_type: 'phường',
            short_codename: 'long_hung',
            parentId: 887,
          },
          {
            title: 'Phường Long Châu',
            code: 30378,
            value: 'phuong_long_chau',
            division_type: 'phường',
            short_codename: 'long_chau',
            parentId: 887,
          },
          {
            title: 'Xã Phú Lộc',
            code: 30379,
            value: 'xa_phu_loc',
            division_type: 'xã',
            short_codename: 'phu_loc',
            parentId: 887,
          },
          {
            title: 'Xã Vĩnh Xương',
            code: 30382,
            value: 'xa_vinh_xuong',
            division_type: 'xã',
            short_codename: 'vinh_xuong',
            parentId: 887,
          },
          {
            title: 'Xã Vĩnh Hòa',
            code: 30385,
            value: 'xa_vinh_hoa',
            division_type: 'xã',
            short_codename: 'vinh_hoa',
            parentId: 887,
          },
          {
            title: 'Xã Tân Thạnh',
            code: 30387,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 887,
          },
          {
            title: 'Xã Tân An',
            code: 30388,
            value: 'xa_tan_an',
            division_type: 'xã',
            short_codename: 'tan_an',
            parentId: 887,
          },
          {
            title: 'Xã Long An',
            code: 30391,
            value: 'xa_long_an',
            division_type: 'xã',
            short_codename: 'long_an',
            parentId: 887,
          },
          {
            title: 'Phường Long Phú',
            code: 30394,
            value: 'phuong_long_phu',
            division_type: 'phường',
            short_codename: 'long_phu',
            parentId: 887,
          },
          {
            title: 'Xã Châu Phong',
            code: 30397,
            value: 'xa_chau_phong',
            division_type: 'xã',
            short_codename: 'chau_phong',
            parentId: 887,
          },
          {
            title: 'Xã Phú Vĩnh',
            code: 30400,
            value: 'xa_phu_vinh',
            division_type: 'xã',
            short_codename: 'phu_vinh',
            parentId: 887,
          },
          {
            title: 'Xã Lê Chánh',
            code: 30403,
            value: 'xa_le_chanh',
            division_type: 'xã',
            short_codename: 'le_chanh',
            parentId: 887,
          },
          {
            title: 'Phường Long Sơn',
            code: 30412,
            value: 'phuong_long_son',
            division_type: 'phường',
            short_codename: 'long_son',
            parentId: 887,
          },
        ],
        parentId: 89,
      },
      {
        title: 'Huyện Phú Tân',
        code: 888,
        value: 'huyen_phu_tan',
        division_type: 'huyện',
        short_codename: 'phu_tan',
        children: [
          {
            title: 'Thị trấn Phú Mỹ',
            code: 30406,
            value: 'thi_tran_phu_my',
            division_type: 'thị trấn',
            short_codename: 'phu_my',
            parentId: 888,
          },
          {
            title: 'Thị trấn Chợ Vàm',
            code: 30409,
            value: 'thi_tran_cho_vam',
            division_type: 'thị trấn',
            short_codename: 'cho_vam',
            parentId: 888,
          },
          {
            title: 'Xã Long Hoà',
            code: 30415,
            value: 'xa_long_hoa',
            division_type: 'xã',
            short_codename: 'long_hoa',
            parentId: 888,
          },
          {
            title: 'Xã Phú Long',
            code: 30418,
            value: 'xa_phu_long',
            division_type: 'xã',
            short_codename: 'phu_long',
            parentId: 888,
          },
          {
            title: 'Xã Phú Lâm',
            code: 30421,
            value: 'xa_phu_lam',
            division_type: 'xã',
            short_codename: 'phu_lam',
            parentId: 888,
          },
          {
            title: 'Xã Phú Hiệp',
            code: 30424,
            value: 'xa_phu_hiep',
            division_type: 'xã',
            short_codename: 'phu_hiep',
            parentId: 888,
          },
          {
            title: 'Xã Phú Thạnh',
            code: 30427,
            value: 'xa_phu_thanh',
            division_type: 'xã',
            short_codename: 'xa_phu_thanh',
            parentId: 888,
          },
          {
            title: 'Xã Hoà Lạc',
            code: 30430,
            value: 'xa_hoa_lac',
            division_type: 'xã',
            short_codename: 'hoa_lac',
            parentId: 888,
          },
          {
            title: 'Xã Phú Thành',
            code: 30433,
            value: 'xa_phu_thanh',
            division_type: 'xã',
            short_codename: 'xa_phu_thanh',
            parentId: 888,
          },
          {
            title: 'Xã Phú An',
            code: 30436,
            value: 'xa_phu_an',
            division_type: 'xã',
            short_codename: 'phu_an',
            parentId: 888,
          },
          {
            title: 'Xã Phú Xuân',
            code: 30439,
            value: 'xa_phu_xuan',
            division_type: 'xã',
            short_codename: 'phu_xuan',
            parentId: 888,
          },
          {
            title: 'Xã Hiệp Xương',
            code: 30442,
            value: 'xa_hiep_xuong',
            division_type: 'xã',
            short_codename: 'hiep_xuong',
            parentId: 888,
          },
          {
            title: 'Xã Phú Bình',
            code: 30445,
            value: 'xa_phu_binh',
            division_type: 'xã',
            short_codename: 'phu_binh',
            parentId: 888,
          },
          {
            title: 'Xã Phú Thọ',
            code: 30448,
            value: 'xa_phu_tho',
            division_type: 'xã',
            short_codename: 'phu_tho',
            parentId: 888,
          },
          {
            title: 'Xã Phú Hưng',
            code: 30451,
            value: 'xa_phu_hung',
            division_type: 'xã',
            short_codename: 'phu_hung',
            parentId: 888,
          },
          {
            title: 'Xã Bình Thạnh Đông',
            code: 30454,
            value: 'xa_binh_thanh_dong',
            division_type: 'xã',
            short_codename: 'binh_thanh_dong',
            parentId: 888,
          },
          {
            title: 'Xã Tân Hòa',
            code: 30457,
            value: 'xa_tan_hoa',
            division_type: 'xã',
            short_codename: 'tan_hoa',
            parentId: 888,
          },
          {
            title: 'Xã Tân Trung',
            code: 30460,
            value: 'xa_tan_trung',
            division_type: 'xã',
            short_codename: 'tan_trung',
            parentId: 888,
          },
        ],
        parentId: 89,
      },
      {
        title: 'Huyện Châu Phú',
        code: 889,
        value: 'huyen_chau_phu',
        division_type: 'huyện',
        short_codename: 'chau_phu',
        children: [
          {
            title: 'Thị trấn Cái Dầu',
            code: 30463,
            value: 'thi_tran_cai_dau',
            division_type: 'thị trấn',
            short_codename: 'cai_dau',
            parentId: 889,
          },
          {
            title: 'Xã Khánh Hòa',
            code: 30466,
            value: 'xa_khanh_hoa',
            division_type: 'xã',
            short_codename: 'khanh_hoa',
            parentId: 889,
          },
          {
            title: 'Xã Mỹ Đức',
            code: 30469,
            value: 'xa_my_duc',
            division_type: 'xã',
            short_codename: 'my_duc',
            parentId: 889,
          },
          {
            title: 'Xã Mỹ Phú',
            code: 30472,
            value: 'xa_my_phu',
            division_type: 'xã',
            short_codename: 'my_phu',
            parentId: 889,
          },
          {
            title: 'Xã Ô Long Vỹ',
            code: 30475,
            value: 'xa_o_long_vy',
            division_type: 'xã',
            short_codename: 'o_long_vy',
            parentId: 889,
          },
          {
            title: 'Thị trấn Vĩnh Thạnh Trung',
            code: 30478,
            value: 'thi_tran_vinh_thanh_trung',
            division_type: 'thị trấn',
            short_codename: 'vinh_thanh_trung',
            parentId: 889,
          },
          {
            title: 'Xã Thạnh Mỹ Tây',
            code: 30481,
            value: 'xa_thanh_my_tay',
            division_type: 'xã',
            short_codename: 'thanh_my_tay',
            parentId: 889,
          },
          {
            title: 'Xã Bình Long',
            code: 30484,
            value: 'xa_binh_long',
            division_type: 'xã',
            short_codename: 'binh_long',
            parentId: 889,
          },
          {
            title: 'Xã Bình Mỹ',
            code: 30487,
            value: 'xa_binh_my',
            division_type: 'xã',
            short_codename: 'binh_my',
            parentId: 889,
          },
          {
            title: 'Xã Bình Thủy',
            code: 30490,
            value: 'xa_binh_thuy',
            division_type: 'xã',
            short_codename: 'binh_thuy',
            parentId: 889,
          },
          {
            title: 'Xã Đào Hữu Cảnh',
            code: 30493,
            value: 'xa_dao_huu_canh',
            division_type: 'xã',
            short_codename: 'dao_huu_canh',
            parentId: 889,
          },
          {
            title: 'Xã Bình Phú',
            code: 30496,
            value: 'xa_binh_phu',
            division_type: 'xã',
            short_codename: 'binh_phu',
            parentId: 889,
          },
          {
            title: 'Xã Bình Chánh',
            code: 30499,
            value: 'xa_binh_chanh',
            division_type: 'xã',
            short_codename: 'binh_chanh',
            parentId: 889,
          },
        ],
        parentId: 89,
      },
      {
        title: 'Huyện Tịnh Biên',
        code: 890,
        value: 'huyen_tinh_bien',
        division_type: 'huyện',
        short_codename: 'tinh_bien',
        children: [
          {
            title: 'Thị trấn Nhà Bàng',
            code: 30502,
            value: 'thi_tran_nha_bang',
            division_type: 'thị trấn',
            short_codename: 'nha_bang',
            parentId: 890,
          },
          {
            title: 'Thị trấn Chi Lăng',
            code: 30505,
            value: 'thi_tran_chi_lang',
            division_type: 'thị trấn',
            short_codename: 'chi_lang',
            parentId: 890,
          },
          {
            title: 'Xã Núi Voi',
            code: 30508,
            value: 'xa_nui_voi',
            division_type: 'xã',
            short_codename: 'nui_voi',
            parentId: 890,
          },
          {
            title: 'Xã Nhơn Hưng',
            code: 30511,
            value: 'xa_nhon_hung',
            division_type: 'xã',
            short_codename: 'nhon_hung',
            parentId: 890,
          },
          {
            title: 'Xã An Phú',
            code: 30514,
            value: 'xa_an_phu',
            division_type: 'xã',
            short_codename: 'an_phu',
            parentId: 890,
          },
          {
            title: 'Xã Thới Sơn',
            code: 30517,
            value: 'xa_thoi_son',
            division_type: 'xã',
            short_codename: 'thoi_son',
            parentId: 890,
          },
          {
            title: 'Thị trấn Tịnh Biên',
            code: 30520,
            value: 'thi_tran_tinh_bien',
            division_type: 'thị trấn',
            short_codename: 'tinh_bien',
            parentId: 890,
          },
          {
            title: 'Xã Văn Giáo',
            code: 30523,
            value: 'xa_van_giao',
            division_type: 'xã',
            short_codename: 'van_giao',
            parentId: 890,
          },
          {
            title: 'Xã An Cư',
            code: 30526,
            value: 'xa_an_cu',
            division_type: 'xã',
            short_codename: 'an_cu',
            parentId: 890,
          },
          {
            title: 'Xã An Nông',
            code: 30529,
            value: 'xa_an_nong',
            division_type: 'xã',
            short_codename: 'an_nong',
            parentId: 890,
          },
          {
            title: 'Xã Vĩnh Trung',
            code: 30532,
            value: 'xa_vinh_trung',
            division_type: 'xã',
            short_codename: 'vinh_trung',
            parentId: 890,
          },
          {
            title: 'Xã Tân Lợi',
            code: 30535,
            value: 'xa_tan_loi',
            division_type: 'xã',
            short_codename: 'tan_loi',
            parentId: 890,
          },
          {
            title: 'Xã An Hảo',
            code: 30538,
            value: 'xa_an_hao',
            division_type: 'xã',
            short_codename: 'an_hao',
            parentId: 890,
          },
          {
            title: 'Xã Tân Lập',
            code: 30541,
            value: 'xa_tan_lap',
            division_type: 'xã',
            short_codename: 'tan_lap',
            parentId: 890,
          },
        ],
        parentId: 89,
      },
      {
        title: 'Huyện Tri Tôn',
        code: 891,
        value: 'huyen_tri_ton',
        division_type: 'huyện',
        short_codename: 'tri_ton',
        children: [
          {
            title: 'Thị trấn Tri Tôn',
            code: 30544,
            value: 'thi_tran_tri_ton',
            division_type: 'thị trấn',
            short_codename: 'tri_ton',
            parentId: 891,
          },
          {
            title: 'Thị trấn Ba Chúc',
            code: 30547,
            value: 'thi_tran_ba_chuc',
            division_type: 'thị trấn',
            short_codename: 'ba_chuc',
            parentId: 891,
          },
          {
            title: 'Xã Lạc Quới',
            code: 30550,
            value: 'xa_lac_quoi',
            division_type: 'xã',
            short_codename: 'lac_quoi',
            parentId: 891,
          },
          {
            title: 'Xã Lê Trì',
            code: 30553,
            value: 'xa_le_tri',
            division_type: 'xã',
            short_codename: 'le_tri',
            parentId: 891,
          },
          {
            title: 'Xã Vĩnh Gia',
            code: 30556,
            value: 'xa_vinh_gia',
            division_type: 'xã',
            short_codename: 'vinh_gia',
            parentId: 891,
          },
          {
            title: 'Xã Vĩnh Phước',
            code: 30559,
            value: 'xa_vinh_phuoc',
            division_type: 'xã',
            short_codename: 'vinh_phuoc',
            parentId: 891,
          },
          {
            title: 'Xã Châu Lăng',
            code: 30562,
            value: 'xa_chau_lang',
            division_type: 'xã',
            short_codename: 'chau_lang',
            parentId: 891,
          },
          {
            title: 'Xã Lương Phi',
            code: 30565,
            value: 'xa_luong_phi',
            division_type: 'xã',
            short_codename: 'luong_phi',
            parentId: 891,
          },
          {
            title: 'Xã Lương An Trà',
            code: 30568,
            value: 'xa_luong_an_tra',
            division_type: 'xã',
            short_codename: 'luong_an_tra',
            parentId: 891,
          },
          {
            title: 'Xã Tà Đảnh',
            code: 30571,
            value: 'xa_ta_danh',
            division_type: 'xã',
            short_codename: 'ta_danh',
            parentId: 891,
          },
          {
            title: 'Xã Núi Tô',
            code: 30574,
            value: 'xa_nui_to',
            division_type: 'xã',
            short_codename: 'nui_to',
            parentId: 891,
          },
          {
            title: 'Xã An Tức',
            code: 30577,
            value: 'xa_an_tuc',
            division_type: 'xã',
            short_codename: 'an_tuc',
            parentId: 891,
          },
          {
            title: 'Thị trấn Cô Tô',
            code: 30580,
            value: 'thi_tran_co_to',
            division_type: 'thị trấn',
            short_codename: 'co_to',
            parentId: 891,
          },
          {
            title: 'Xã Tân Tuyến',
            code: 30583,
            value: 'xa_tan_tuyen',
            division_type: 'xã',
            short_codename: 'tan_tuyen',
            parentId: 891,
          },
          {
            title: 'Xã Ô Lâm',
            code: 30586,
            value: 'xa_o_lam',
            division_type: 'xã',
            short_codename: 'o_lam',
            parentId: 891,
          },
        ],
        parentId: 89,
      },
      {
        title: 'Huyện Châu Thành',
        code: 892,
        value: 'huyen_chau_thanh',
        division_type: 'huyện',
        short_codename: 'chau_thanh',
        children: [
          {
            title: 'Thị trấn An Châu',
            code: 30589,
            value: 'thi_tran_an_chau',
            division_type: 'thị trấn',
            short_codename: 'an_chau',
            parentId: 892,
          },
          {
            title: 'Xã An Hòa',
            code: 30592,
            value: 'xa_an_hoa',
            division_type: 'xã',
            short_codename: 'an_hoa',
            parentId: 892,
          },
          {
            title: 'Xã Cần Đăng',
            code: 30595,
            value: 'xa_can_dang',
            division_type: 'xã',
            short_codename: 'can_dang',
            parentId: 892,
          },
          {
            title: 'Xã Vĩnh Hanh',
            code: 30598,
            value: 'xa_vinh_hanh',
            division_type: 'xã',
            short_codename: 'vinh_hanh',
            parentId: 892,
          },
          {
            title: 'Xã Bình Thạnh',
            code: 30601,
            value: 'xa_binh_thanh',
            division_type: 'xã',
            short_codename: 'binh_thanh',
            parentId: 892,
          },
          {
            title: 'Thị trấn Vĩnh Bình',
            code: 30604,
            value: 'thi_tran_vinh_binh',
            division_type: 'thị trấn',
            short_codename: 'vinh_binh',
            parentId: 892,
          },
          {
            title: 'Xã Bình Hòa',
            code: 30607,
            value: 'xa_binh_hoa',
            division_type: 'xã',
            short_codename: 'binh_hoa',
            parentId: 892,
          },
          {
            title: 'Xã Vĩnh An',
            code: 30610,
            value: 'xa_vinh_an',
            division_type: 'xã',
            short_codename: 'vinh_an',
            parentId: 892,
          },
          {
            title: 'Xã Hòa Bình Thạnh',
            code: 30613,
            value: 'xa_hoa_binh_thanh',
            division_type: 'xã',
            short_codename: 'hoa_binh_thanh',
            parentId: 892,
          },
          {
            title: 'Xã Vĩnh Lợi',
            code: 30616,
            value: 'xa_vinh_loi',
            division_type: 'xã',
            short_codename: 'vinh_loi',
            parentId: 892,
          },
          {
            title: 'Xã Vĩnh Nhuận',
            code: 30619,
            value: 'xa_vinh_nhuan',
            division_type: 'xã',
            short_codename: 'vinh_nhuan',
            parentId: 892,
          },
          {
            title: 'Xã Tân Phú',
            code: 30622,
            value: 'xa_tan_phu',
            division_type: 'xã',
            short_codename: 'tan_phu',
            parentId: 892,
          },
          {
            title: 'Xã Vĩnh Thành',
            code: 30625,
            value: 'xa_vinh_thanh',
            division_type: 'xã',
            short_codename: 'vinh_thanh',
            parentId: 892,
          },
        ],
        parentId: 89,
      },
      {
        title: 'Huyện Chợ Mới',
        code: 893,
        value: 'huyen_cho_moi',
        division_type: 'huyện',
        short_codename: 'cho_moi',
        children: [
          {
            title: 'Thị trấn Chợ Mới',
            code: 30628,
            value: 'thi_tran_cho_moi',
            division_type: 'thị trấn',
            short_codename: 'cho_moi',
            parentId: 893,
          },
          {
            title: 'Thị trấn Mỹ Luông',
            code: 30631,
            value: 'thi_tran_my_luong',
            division_type: 'thị trấn',
            short_codename: 'my_luong',
            parentId: 893,
          },
          {
            title: 'Xã Kiến An',
            code: 30634,
            value: 'xa_kien_an',
            division_type: 'xã',
            short_codename: 'kien_an',
            parentId: 893,
          },
          {
            title: 'Xã Mỹ Hội Đông',
            code: 30637,
            value: 'xa_my_hoi_dong',
            division_type: 'xã',
            short_codename: 'my_hoi_dong',
            parentId: 893,
          },
          {
            title: 'Xã Long Điền A',
            code: 30640,
            value: 'xa_long_dien_a',
            division_type: 'xã',
            short_codename: 'long_dien_a',
            parentId: 893,
          },
          {
            title: 'Xã Tấn Mỹ',
            code: 30643,
            value: 'xa_tan_my',
            division_type: 'xã',
            short_codename: 'tan_my',
            parentId: 893,
          },
          {
            title: 'Xã Long Điền B',
            code: 30646,
            value: 'xa_long_dien_b',
            division_type: 'xã',
            short_codename: 'long_dien_b',
            parentId: 893,
          },
          {
            title: 'Xã Kiến Thành',
            code: 30649,
            value: 'xa_kien_thanh',
            division_type: 'xã',
            short_codename: 'kien_thanh',
            parentId: 893,
          },
          {
            title: 'Xã Mỹ Hiệp',
            code: 30652,
            value: 'xa_my_hiep',
            division_type: 'xã',
            short_codename: 'my_hiep',
            parentId: 893,
          },
          {
            title: 'Xã Mỹ An',
            code: 30655,
            value: 'xa_my_an',
            division_type: 'xã',
            short_codename: 'my_an',
            parentId: 893,
          },
          {
            title: 'Xã Nhơn Mỹ',
            code: 30658,
            value: 'xa_nhon_my',
            division_type: 'xã',
            short_codename: 'nhon_my',
            parentId: 893,
          },
          {
            title: 'Xã Long Giang',
            code: 30661,
            value: 'xa_long_giang',
            division_type: 'xã',
            short_codename: 'long_giang',
            parentId: 893,
          },
          {
            title: 'Xã Long Kiến',
            code: 30664,
            value: 'xa_long_kien',
            division_type: 'xã',
            short_codename: 'long_kien',
            parentId: 893,
          },
          {
            title: 'Xã Bình Phước Xuân',
            code: 30667,
            value: 'xa_binh_phuoc_xuan',
            division_type: 'xã',
            short_codename: 'binh_phuoc_xuan',
            parentId: 893,
          },
          {
            title: 'Xã An Thạnh Trung',
            code: 30670,
            value: 'xa_an_thanh_trung',
            division_type: 'xã',
            short_codename: 'an_thanh_trung',
            parentId: 893,
          },
          {
            title: 'Xã Hội An',
            code: 30673,
            value: 'xa_hoi_an',
            division_type: 'xã',
            short_codename: 'hoi_an',
            parentId: 893,
          },
          {
            title: 'Xã Hòa Bình',
            code: 30676,
            value: 'xa_hoa_binh',
            division_type: 'xã',
            short_codename: 'hoa_binh',
            parentId: 893,
          },
          {
            title: 'Xã Hòa An',
            code: 30679,
            value: 'xa_hoa_an',
            division_type: 'xã',
            short_codename: 'hoa_an',
            parentId: 893,
          },
        ],
        parentId: 89,
      },
      {
        title: 'Huyện Thoại Sơn',
        code: 894,
        value: 'huyen_thoai_son',
        division_type: 'huyện',
        short_codename: 'thoai_son',
        children: [
          {
            title: 'Thị trấn Núi Sập',
            code: 30682,
            value: 'thi_tran_nui_sap',
            division_type: 'thị trấn',
            short_codename: 'nui_sap',
            parentId: 894,
          },
          {
            title: 'Thị trấn Phú Hoà',
            code: 30685,
            value: 'thi_tran_phu_hoa',
            division_type: 'thị trấn',
            short_codename: 'phu_hoa',
            parentId: 894,
          },
          {
            title: 'Thị Trấn Óc Eo',
            code: 30688,
            value: 'thi_tran_oc_eo',
            division_type: 'thị trấn',
            short_codename: 'oc_eo',
            parentId: 894,
          },
          {
            title: 'Xã Tây Phú',
            code: 30691,
            value: 'xa_tay_phu',
            division_type: 'xã',
            short_codename: 'tay_phu',
            parentId: 894,
          },
          {
            title: 'Xã An Bình',
            code: 30692,
            value: 'xa_an_binh',
            division_type: 'xã',
            short_codename: 'an_binh',
            parentId: 894,
          },
          {
            title: 'Xã Vĩnh Phú',
            code: 30694,
            value: 'xa_vinh_phu',
            division_type: 'xã',
            short_codename: 'vinh_phu',
            parentId: 894,
          },
          {
            title: 'Xã Vĩnh Trạch',
            code: 30697,
            value: 'xa_vinh_trach',
            division_type: 'xã',
            short_codename: 'vinh_trach',
            parentId: 894,
          },
          {
            title: 'Xã Phú Thuận',
            code: 30700,
            value: 'xa_phu_thuan',
            division_type: 'xã',
            short_codename: 'phu_thuan',
            parentId: 894,
          },
          {
            title: 'Xã Vĩnh Chánh',
            code: 30703,
            value: 'xa_vinh_chanh',
            division_type: 'xã',
            short_codename: 'vinh_chanh',
            parentId: 894,
          },
          {
            title: 'Xã Định Mỹ',
            code: 30706,
            value: 'xa_dinh_my',
            division_type: 'xã',
            short_codename: 'dinh_my',
            parentId: 894,
          },
          {
            title: 'Xã Định Thành',
            code: 30709,
            value: 'xa_dinh_thanh',
            division_type: 'xã',
            short_codename: 'dinh_thanh',
            parentId: 894,
          },
          {
            title: 'Xã Mỹ Phú Đông',
            code: 30712,
            value: 'xa_my_phu_dong',
            division_type: 'xã',
            short_codename: 'my_phu_dong',
            parentId: 894,
          },
          {
            title: 'Xã Vọng Đông',
            code: 30715,
            value: 'xa_vong_dong',
            division_type: 'xã',
            short_codename: 'vong_dong',
            parentId: 894,
          },
          {
            title: 'Xã Vĩnh Khánh',
            code: 30718,
            value: 'xa_vinh_khanh',
            division_type: 'xã',
            short_codename: 'vinh_khanh',
            parentId: 894,
          },
          {
            title: 'Xã Thoại Giang',
            code: 30721,
            value: 'xa_thoai_giang',
            division_type: 'xã',
            short_codename: 'thoai_giang',
            parentId: 894,
          },
          {
            title: 'Xã Bình Thành',
            code: 30724,
            value: 'xa_binh_thanh',
            division_type: 'xã',
            short_codename: 'binh_thanh',
            parentId: 894,
          },
          {
            title: 'Xã Vọng Thê',
            code: 30727,
            value: 'xa_vong_the',
            division_type: 'xã',
            short_codename: 'vong_the',
            parentId: 894,
          },
        ],
        parentId: 89,
      },
    ],
  },
  {
    title: 'Tỉnh Kiên Giang',
    code: 91,
    value: 'tinh_kien_giang',
    division_type: 'tỉnh',
    phone_code: 297,
    children: [
      {
        title: 'Thành phố Rạch Giá',
        code: 899,
        value: 'thanh_pho_rach_gia',
        division_type: 'thành phố',
        short_codename: 'rach_gia',
        children: [
          {
            title: 'Phường Vĩnh Thanh Vân',
            code: 30730,
            value: 'phuong_vinh_thanh_van',
            division_type: 'phường',
            short_codename: 'vinh_thanh_van',
            parentId: 899,
          },
          {
            title: 'Phường Vĩnh Thanh',
            code: 30733,
            value: 'phuong_vinh_thanh',
            division_type: 'phường',
            short_codename: 'vinh_thanh',
            parentId: 899,
          },
          {
            title: 'Phường Vĩnh Quang',
            code: 30736,
            value: 'phuong_vinh_quang',
            division_type: 'phường',
            short_codename: 'vinh_quang',
            parentId: 899,
          },
          {
            title: 'Phường Vĩnh Hiệp',
            code: 30739,
            value: 'phuong_vinh_hiep',
            division_type: 'phường',
            short_codename: 'vinh_hiep',
            parentId: 899,
          },
          {
            title: 'Phường Vĩnh Bảo',
            code: 30742,
            value: 'phuong_vinh_bao',
            division_type: 'phường',
            short_codename: 'vinh_bao',
            parentId: 899,
          },
          {
            title: 'Phường Vĩnh Lạc',
            code: 30745,
            value: 'phuong_vinh_lac',
            division_type: 'phường',
            short_codename: 'vinh_lac',
            parentId: 899,
          },
          {
            title: 'Phường An Hòa',
            code: 30748,
            value: 'phuong_an_hoa',
            division_type: 'phường',
            short_codename: 'an_hoa',
            parentId: 899,
          },
          {
            title: 'Phường An Bình',
            code: 30751,
            value: 'phuong_an_binh',
            division_type: 'phường',
            short_codename: 'an_binh',
            parentId: 899,
          },
          {
            title: 'Phường Rạch Sỏi',
            code: 30754,
            value: 'phuong_rach_soi',
            division_type: 'phường',
            short_codename: 'rach_soi',
            parentId: 899,
          },
          {
            title: 'Phường Vĩnh Lợi',
            code: 30757,
            value: 'phuong_vinh_loi',
            division_type: 'phường',
            short_codename: 'vinh_loi',
            parentId: 899,
          },
          {
            title: 'Phường Vĩnh Thông',
            code: 30760,
            value: 'phuong_vinh_thong',
            division_type: 'phường',
            short_codename: 'vinh_thong',
            parentId: 899,
          },
          {
            title: 'Xã Phi Thông',
            code: 30763,
            value: 'xa_phi_thong',
            division_type: 'xã',
            short_codename: 'phi_thong',
            parentId: 899,
          },
        ],
        parentId: 91,
      },
      {
        title: 'Thành phố Hà Tiên',
        code: 900,
        value: 'thanh_pho_ha_tien',
        division_type: 'thành phố',
        short_codename: 'ha_tien',
        children: [
          {
            title: 'Phường Tô Châu',
            code: 30766,
            value: 'phuong_to_chau',
            division_type: 'phường',
            short_codename: 'to_chau',
            parentId: 900,
          },
          {
            title: 'Phường Đông Hồ',
            code: 30769,
            value: 'phuong_dong_ho',
            division_type: 'phường',
            short_codename: 'dong_ho',
            parentId: 900,
          },
          {
            title: 'Phường Bình San',
            code: 30772,
            value: 'phuong_binh_san',
            division_type: 'phường',
            short_codename: 'binh_san',
            parentId: 900,
          },
          {
            title: 'Phường Pháo Đài',
            code: 30775,
            value: 'phuong_phao_dai',
            division_type: 'phường',
            short_codename: 'phao_dai',
            parentId: 900,
          },
          {
            title: 'Phường Mỹ Đức',
            code: 30778,
            value: 'phuong_my_duc',
            division_type: 'phường',
            short_codename: 'my_duc',
            parentId: 900,
          },
          {
            title: 'Xã Tiên Hải',
            code: 30781,
            value: 'xa_tien_hai',
            division_type: 'xã',
            short_codename: 'tien_hai',
            parentId: 900,
          },
          {
            title: 'Xã Thuận Yên',
            code: 30784,
            value: 'xa_thuan_yen',
            division_type: 'xã',
            short_codename: 'thuan_yen',
            parentId: 900,
          },
        ],
        parentId: 91,
      },
      {
        title: 'Huyện Kiên Lương',
        code: 902,
        value: 'huyen_kien_luong',
        division_type: 'huyện',
        short_codename: 'kien_luong',
        children: [
          {
            title: 'Thị trấn Kiên Lương',
            code: 30787,
            value: 'thi_tran_kien_luong',
            division_type: 'thị trấn',
            short_codename: 'kien_luong',
            parentId: 902,
          },
          {
            title: 'Xã Kiên Bình',
            code: 30790,
            value: 'xa_kien_binh',
            division_type: 'xã',
            short_codename: 'kien_binh',
            parentId: 902,
          },
          {
            title: 'Xã Hòa Điền',
            code: 30802,
            value: 'xa_hoa_dien',
            division_type: 'xã',
            short_codename: 'hoa_dien',
            parentId: 902,
          },
          {
            title: 'Xã Dương Hòa',
            code: 30805,
            value: 'xa_duong_hoa',
            division_type: 'xã',
            short_codename: 'duong_hoa',
            parentId: 902,
          },
          {
            title: 'Xã Bình An',
            code: 30808,
            value: 'xa_binh_an',
            division_type: 'xã',
            short_codename: 'binh_an',
            parentId: 902,
          },
          {
            title: 'Xã Bình Trị',
            code: 30809,
            value: 'xa_binh_tri',
            division_type: 'xã',
            short_codename: 'binh_tri',
            parentId: 902,
          },
          {
            title: 'Xã Sơn Hải',
            code: 30811,
            value: 'xa_son_hai',
            division_type: 'xã',
            short_codename: 'son_hai',
            parentId: 902,
          },
          {
            title: 'Xã Hòn Nghệ',
            code: 30814,
            value: 'xa_hon_nghe',
            division_type: 'xã',
            short_codename: 'hon_nghe',
            parentId: 902,
          },
        ],
        parentId: 91,
      },
      {
        title: 'Huyện Hòn Đất',
        code: 903,
        value: 'huyen_hon_dat',
        division_type: 'huyện',
        short_codename: 'hon_dat',
        children: [
          {
            title: 'Thị trấn Hòn Đất',
            code: 30817,
            value: 'thi_tran_hon_dat',
            division_type: 'thị trấn',
            short_codename: 'hon_dat',
            parentId: 903,
          },
          {
            title: 'Thị trấn Sóc Sơn',
            code: 30820,
            value: 'thi_tran_soc_son',
            division_type: 'thị trấn',
            short_codename: 'soc_son',
            parentId: 903,
          },
          {
            title: 'Xã Bình Sơn',
            code: 30823,
            value: 'xa_binh_son',
            division_type: 'xã',
            short_codename: 'binh_son',
            parentId: 903,
          },
          {
            title: 'Xã Bình Giang',
            code: 30826,
            value: 'xa_binh_giang',
            division_type: 'xã',
            short_codename: 'binh_giang',
            parentId: 903,
          },
          {
            title: 'Xã Mỹ Thái',
            code: 30828,
            value: 'xa_my_thai',
            division_type: 'xã',
            short_codename: 'my_thai',
            parentId: 903,
          },
          {
            title: 'Xã Nam Thái Sơn',
            code: 30829,
            value: 'xa_nam_thai_son',
            division_type: 'xã',
            short_codename: 'nam_thai_son',
            parentId: 903,
          },
          {
            title: 'Xã Mỹ Hiệp Sơn',
            code: 30832,
            value: 'xa_my_hiep_son',
            division_type: 'xã',
            short_codename: 'my_hiep_son',
            parentId: 903,
          },
          {
            title: 'Xã Sơn Kiên',
            code: 30835,
            value: 'xa_son_kien',
            division_type: 'xã',
            short_codename: 'son_kien',
            parentId: 903,
          },
          {
            title: 'Xã Sơn Bình',
            code: 30836,
            value: 'xa_son_binh',
            division_type: 'xã',
            short_codename: 'son_binh',
            parentId: 903,
          },
          {
            title: 'Xã Mỹ Thuận',
            code: 30838,
            value: 'xa_my_thuan',
            division_type: 'xã',
            short_codename: 'my_thuan',
            parentId: 903,
          },
          {
            title: 'Xã Lình Huỳnh',
            code: 30840,
            value: 'xa_linh_huynh',
            division_type: 'xã',
            short_codename: 'linh_huynh',
            parentId: 903,
          },
          {
            title: 'Xã Thổ Sơn',
            code: 30841,
            value: 'xa_tho_son',
            division_type: 'xã',
            short_codename: 'tho_son',
            parentId: 903,
          },
          {
            title: 'Xã Mỹ Lâm',
            code: 30844,
            value: 'xa_my_lam',
            division_type: 'xã',
            short_codename: 'my_lam',
            parentId: 903,
          },
          {
            title: 'Xã Mỹ Phước',
            code: 30847,
            value: 'xa_my_phuoc',
            division_type: 'xã',
            short_codename: 'my_phuoc',
            parentId: 903,
          },
        ],
        parentId: 91,
      },
      {
        title: 'Huyện Tân Hiệp',
        code: 904,
        value: 'huyen_tan_hiep',
        division_type: 'huyện',
        short_codename: 'tan_hiep',
        children: [
          {
            title: 'Thị trấn Tân Hiệp',
            code: 30850,
            value: 'thi_tran_tan_hiep',
            division_type: 'thị trấn',
            short_codename: 'tan_hiep',
            parentId: 904,
          },
          {
            title: 'Xã Tân Hội',
            code: 30853,
            value: 'xa_tan_hoi',
            division_type: 'xã',
            short_codename: 'tan_hoi',
            parentId: 904,
          },
          {
            title: 'Xã Tân Thành',
            code: 30856,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 904,
          },
          {
            title: 'Xã Tân Hiệp B',
            code: 30859,
            value: 'xa_tan_hiep_b',
            division_type: 'xã',
            short_codename: 'tan_hiep_b',
            parentId: 904,
          },
          {
            title: 'Xã Tân Hoà',
            code: 30860,
            value: 'xa_tan_hoa',
            division_type: 'xã',
            short_codename: 'tan_hoa',
            parentId: 904,
          },
          {
            title: 'Xã Thạnh Đông B',
            code: 30862,
            value: 'xa_thanh_dong_b',
            division_type: 'xã',
            short_codename: 'thanh_dong_b',
            parentId: 904,
          },
          {
            title: 'Xã Thạnh Đông',
            code: 30865,
            value: 'xa_thanh_dong',
            division_type: 'xã',
            short_codename: 'thanh_dong',
            parentId: 904,
          },
          {
            title: 'Xã Tân Hiệp A',
            code: 30868,
            value: 'xa_tan_hiep_a',
            division_type: 'xã',
            short_codename: 'tan_hiep_a',
            parentId: 904,
          },
          {
            title: 'Xã Tân An',
            code: 30871,
            value: 'xa_tan_an',
            division_type: 'xã',
            short_codename: 'tan_an',
            parentId: 904,
          },
          {
            title: 'Xã Thạnh Đông A',
            code: 30874,
            value: 'xa_thanh_dong_a',
            division_type: 'xã',
            short_codename: 'thanh_dong_a',
            parentId: 904,
          },
          {
            title: 'Xã Thạnh Trị',
            code: 30877,
            value: 'xa_thanh_tri',
            division_type: 'xã',
            short_codename: 'thanh_tri',
            parentId: 904,
          },
        ],
        parentId: 91,
      },
      {
        title: 'Huyện Châu Thành',
        code: 905,
        value: 'huyen_chau_thanh',
        division_type: 'huyện',
        short_codename: 'chau_thanh',
        children: [
          {
            title: 'Thị trấn Minh Lương',
            code: 30880,
            value: 'thi_tran_minh_luong',
            division_type: 'thị trấn',
            short_codename: 'minh_luong',
            parentId: 905,
          },
          {
            title: 'Xã Mong Thọ A',
            code: 30883,
            value: 'xa_mong_tho_a',
            division_type: 'xã',
            short_codename: 'mong_tho_a',
            parentId: 905,
          },
          {
            title: 'Xã Mong Thọ B',
            code: 30886,
            value: 'xa_mong_tho_b',
            division_type: 'xã',
            short_codename: 'mong_tho_b',
            parentId: 905,
          },
          {
            title: 'Xã Mong Thọ',
            code: 30887,
            value: 'xa_mong_tho',
            division_type: 'xã',
            short_codename: 'mong_tho',
            parentId: 905,
          },
          {
            title: 'Xã Giục Tượng',
            code: 30889,
            value: 'xa_giuc_tuong',
            division_type: 'xã',
            short_codename: 'giuc_tuong',
            parentId: 905,
          },
          {
            title: 'Xã Vĩnh Hòa Hiệp',
            code: 30892,
            value: 'xa_vinh_hoa_hiep',
            division_type: 'xã',
            short_codename: 'vinh_hoa_hiep',
            parentId: 905,
          },
          {
            title: 'Xã Vĩnh Hoà Phú',
            code: 30893,
            value: 'xa_vinh_hoa_phu',
            division_type: 'xã',
            short_codename: 'vinh_hoa_phu',
            parentId: 905,
          },
          {
            title: 'Xã Minh Hòa',
            code: 30895,
            value: 'xa_minh_hoa',
            division_type: 'xã',
            short_codename: 'minh_hoa',
            parentId: 905,
          },
          {
            title: 'Xã Bình An',
            code: 30898,
            value: 'xa_binh_an',
            division_type: 'xã',
            short_codename: 'binh_an',
            parentId: 905,
          },
          {
            title: 'Xã Thạnh Lộc',
            code: 30901,
            value: 'xa_thanh_loc',
            division_type: 'xã',
            short_codename: 'thanh_loc',
            parentId: 905,
          },
        ],
        parentId: 91,
      },
      {
        title: 'Huyện Giồng Riềng',
        code: 906,
        value: 'huyen_giong_rieng',
        division_type: 'huyện',
        short_codename: 'giong_rieng',
        children: [
          {
            title: 'Thị Trấn Giồng Riềng',
            code: 30904,
            value: 'thi_tran_giong_rieng',
            division_type: 'thị trấn',
            short_codename: 'giong_rieng',
            parentId: 906,
          },
          {
            title: 'Xã Thạnh Hưng',
            code: 30907,
            value: 'xa_thanh_hung',
            division_type: 'xã',
            short_codename: 'thanh_hung',
            parentId: 906,
          },
          {
            title: 'Xã Thạnh Phước',
            code: 30910,
            value: 'xa_thanh_phuoc',
            division_type: 'xã',
            short_codename: 'thanh_phuoc',
            parentId: 906,
          },
          {
            title: 'Xã Thạnh Lộc',
            code: 30913,
            value: 'xa_thanh_loc',
            division_type: 'xã',
            short_codename: 'thanh_loc',
            parentId: 906,
          },
          {
            title: 'Xã Thạnh Hòa',
            code: 30916,
            value: 'xa_thanh_hoa',
            division_type: 'xã',
            short_codename: 'thanh_hoa',
            parentId: 906,
          },
          {
            title: 'Xã Thạnh Bình',
            code: 30917,
            value: 'xa_thanh_binh',
            division_type: 'xã',
            short_codename: 'thanh_binh',
            parentId: 906,
          },
          {
            title: 'Xã Bàn Thạch',
            code: 30919,
            value: 'xa_ban_thach',
            division_type: 'xã',
            short_codename: 'ban_thach',
            parentId: 906,
          },
          {
            title: 'Xã Bàn Tân Định',
            code: 30922,
            value: 'xa_ban_tan_dinh',
            division_type: 'xã',
            short_codename: 'ban_tan_dinh',
            parentId: 906,
          },
          {
            title: 'Xã Ngọc Thành',
            code: 30925,
            value: 'xa_ngoc_thanh',
            division_type: 'xã',
            short_codename: 'ngoc_thanh',
            parentId: 906,
          },
          {
            title: 'Xã Ngọc Chúc',
            code: 30928,
            value: 'xa_ngoc_chuc',
            division_type: 'xã',
            short_codename: 'ngoc_chuc',
            parentId: 906,
          },
          {
            title: 'Xã Ngọc Thuận',
            code: 30931,
            value: 'xa_ngoc_thuan',
            division_type: 'xã',
            short_codename: 'ngoc_thuan',
            parentId: 906,
          },
          {
            title: 'Xã Hòa Hưng',
            code: 30934,
            value: 'xa_hoa_hung',
            division_type: 'xã',
            short_codename: 'hoa_hung',
            parentId: 906,
          },
          {
            title: 'Xã Hoà Lợi',
            code: 30937,
            value: 'xa_hoa_loi',
            division_type: 'xã',
            short_codename: 'hoa_loi',
            parentId: 906,
          },
          {
            title: 'Xã Hoà An',
            code: 30940,
            value: 'xa_hoa_an',
            division_type: 'xã',
            short_codename: 'hoa_an',
            parentId: 906,
          },
          {
            title: 'Xã Long Thạnh',
            code: 30943,
            value: 'xa_long_thanh',
            division_type: 'xã',
            short_codename: 'long_thanh',
            parentId: 906,
          },
          {
            title: 'Xã Vĩnh Thạnh',
            code: 30946,
            value: 'xa_vinh_thanh',
            division_type: 'xã',
            short_codename: 'vinh_thanh',
            parentId: 906,
          },
          {
            title: 'Xã Vĩnh Phú',
            code: 30947,
            value: 'xa_vinh_phu',
            division_type: 'xã',
            short_codename: 'vinh_phu',
            parentId: 906,
          },
          {
            title: 'Xã Hòa Thuận',
            code: 30949,
            value: 'xa_hoa_thuan',
            division_type: 'xã',
            short_codename: 'hoa_thuan',
            parentId: 906,
          },
          {
            title: 'Xã Ngọc Hoà',
            code: 30950,
            value: 'xa_ngoc_hoa',
            division_type: 'xã',
            short_codename: 'ngoc_hoa',
            parentId: 906,
          },
        ],
        parentId: 91,
      },
      {
        title: 'Huyện Gò Quao',
        code: 907,
        value: 'huyen_go_quao',
        division_type: 'huyện',
        short_codename: 'go_quao',
        children: [
          {
            title: 'Thị trấn Gò Quao',
            code: 30952,
            value: 'thi_tran_go_quao',
            division_type: 'thị trấn',
            short_codename: 'go_quao',
            parentId: 907,
          },
          {
            title: 'Xã Vĩnh Hòa Hưng Bắc',
            code: 30955,
            value: 'xa_vinh_hoa_hung_bac',
            division_type: 'xã',
            short_codename: 'vinh_hoa_hung_bac',
            parentId: 907,
          },
          {
            title: 'Xã Định Hòa',
            code: 30958,
            value: 'xa_dinh_hoa',
            division_type: 'xã',
            short_codename: 'dinh_hoa',
            parentId: 907,
          },
          {
            title: 'Xã Thới Quản',
            code: 30961,
            value: 'xa_thoi_quan',
            division_type: 'xã',
            short_codename: 'thoi_quan',
            parentId: 907,
          },
          {
            title: 'Xã Định An',
            code: 30964,
            value: 'xa_dinh_an',
            division_type: 'xã',
            short_codename: 'dinh_an',
            parentId: 907,
          },
          {
            title: 'Xã Thủy Liễu',
            code: 30967,
            value: 'xa_thuy_lieu',
            division_type: 'xã',
            short_codename: 'thuy_lieu',
            parentId: 907,
          },
          {
            title: 'Xã Vĩnh Hòa Hưng Nam',
            code: 30970,
            value: 'xa_vinh_hoa_hung_nam',
            division_type: 'xã',
            short_codename: 'vinh_hoa_hung_nam',
            parentId: 907,
          },
          {
            title: 'Xã Vĩnh Phước A',
            code: 30973,
            value: 'xa_vinh_phuoc_a',
            division_type: 'xã',
            short_codename: 'vinh_phuoc_a',
            parentId: 907,
          },
          {
            title: 'Xã Vĩnh Phước B',
            code: 30976,
            value: 'xa_vinh_phuoc_b',
            division_type: 'xã',
            short_codename: 'vinh_phuoc_b',
            parentId: 907,
          },
          {
            title: 'Xã Vĩnh Tuy',
            code: 30979,
            value: 'xa_vinh_tuy',
            division_type: 'xã',
            short_codename: 'vinh_tuy',
            parentId: 907,
          },
          {
            title: 'Xã Vĩnh Thắng',
            code: 30982,
            value: 'xa_vinh_thang',
            division_type: 'xã',
            short_codename: 'vinh_thang',
            parentId: 907,
          },
        ],
        parentId: 91,
      },
      {
        title: 'Huyện An Biên',
        code: 908,
        value: 'huyen_an_bien',
        division_type: 'huyện',
        short_codename: 'an_bien',
        children: [
          {
            title: 'Thị trấn Thứ Ba',
            code: 30985,
            value: 'thi_tran_thu_ba',
            division_type: 'thị trấn',
            short_codename: 'thu_ba',
            parentId: 908,
          },
          {
            title: 'Xã Tây Yên',
            code: 30988,
            value: 'xa_tay_yen',
            division_type: 'xã',
            short_codename: 'tay_yen',
            parentId: 908,
          },
          {
            title: 'Xã Tây Yên A',
            code: 30991,
            value: 'xa_tay_yen_a',
            division_type: 'xã',
            short_codename: 'tay_yen_a',
            parentId: 908,
          },
          {
            title: 'Xã Nam Yên',
            code: 30994,
            value: 'xa_nam_yen',
            division_type: 'xã',
            short_codename: 'nam_yen',
            parentId: 908,
          },
          {
            title: 'Xã Hưng Yên',
            code: 30997,
            value: 'xa_hung_yen',
            division_type: 'xã',
            short_codename: 'hung_yen',
            parentId: 908,
          },
          {
            title: 'Xã Nam Thái',
            code: 31000,
            value: 'xa_nam_thai',
            division_type: 'xã',
            short_codename: 'nam_thai',
            parentId: 908,
          },
          {
            title: 'Xã Nam Thái A',
            code: 31003,
            value: 'xa_nam_thai_a',
            division_type: 'xã',
            short_codename: 'nam_thai_a',
            parentId: 908,
          },
          {
            title: 'Xã Đông Thái',
            code: 31006,
            value: 'xa_dong_thai',
            division_type: 'xã',
            short_codename: 'dong_thai',
            parentId: 908,
          },
          {
            title: 'Xã Đông Yên',
            code: 31009,
            value: 'xa_dong_yen',
            division_type: 'xã',
            short_codename: 'dong_yen',
            parentId: 908,
          },
        ],
        parentId: 91,
      },
      {
        title: 'Huyện An Minh',
        code: 909,
        value: 'huyen_an_minh',
        division_type: 'huyện',
        short_codename: 'an_minh',
        children: [
          {
            title: 'Thị trấn Thứ Mười Một',
            code: 31018,
            value: 'thi_tran_thu_muoi_mot',
            division_type: 'thị trấn',
            short_codename: 'thu_muoi_mot',
            parentId: 909,
          },
          {
            title: 'Xã Thuận Hoà',
            code: 31021,
            value: 'xa_thuan_hoa',
            division_type: 'xã',
            short_codename: 'thuan_hoa',
            parentId: 909,
          },
          {
            title: 'Xã Đông Hòa',
            code: 31024,
            value: 'xa_dong_hoa',
            division_type: 'xã',
            short_codename: 'dong_hoa',
            parentId: 909,
          },
          {
            title: 'Xã Đông Thạnh',
            code: 31030,
            value: 'xa_dong_thanh',
            division_type: 'xã',
            short_codename: 'dong_thanh',
            parentId: 909,
          },
          {
            title: 'Xã Tân Thạnh',
            code: 31031,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 909,
          },
          {
            title: 'Xã Đông Hưng',
            code: 31033,
            value: 'xa_dong_hung',
            division_type: 'xã',
            short_codename: 'dong_hung',
            parentId: 909,
          },
          {
            title: 'Xã Đông Hưng A',
            code: 31036,
            value: 'xa_dong_hung_a',
            division_type: 'xã',
            short_codename: 'dong_hung_a',
            parentId: 909,
          },
          {
            title: 'Xã Đông Hưng B',
            code: 31039,
            value: 'xa_dong_hung_b',
            division_type: 'xã',
            short_codename: 'dong_hung_b',
            parentId: 909,
          },
          {
            title: 'Xã Vân Khánh',
            code: 31042,
            value: 'xa_van_khanh',
            division_type: 'xã',
            short_codename: 'van_khanh',
            parentId: 909,
          },
          {
            title: 'Xã Vân Khánh Đông',
            code: 31045,
            value: 'xa_van_khanh_dong',
            division_type: 'xã',
            short_codename: 'van_khanh_dong',
            parentId: 909,
          },
          {
            title: 'Xã Vân Khánh Tây',
            code: 31048,
            value: 'xa_van_khanh_tay',
            division_type: 'xã',
            short_codename: 'van_khanh_tay',
            parentId: 909,
          },
        ],
        parentId: 91,
      },
      {
        title: 'Huyện Vĩnh Thuận',
        code: 910,
        value: 'huyen_vinh_thuan',
        division_type: 'huyện',
        short_codename: 'vinh_thuan',
        children: [
          {
            title: 'Thị trấn Vĩnh Thuận',
            code: 31051,
            value: 'thi_tran_vinh_thuan',
            division_type: 'thị trấn',
            short_codename: 'vinh_thuan',
            parentId: 910,
          },
          {
            title: 'Xã Vĩnh Bình Bắc',
            code: 31060,
            value: 'xa_vinh_binh_bac',
            division_type: 'xã',
            short_codename: 'vinh_binh_bac',
            parentId: 910,
          },
          {
            title: 'Xã Vĩnh Bình Nam',
            code: 31063,
            value: 'xa_vinh_binh_nam',
            division_type: 'xã',
            short_codename: 'vinh_binh_nam',
            parentId: 910,
          },
          {
            title: 'Xã Bình Minh',
            code: 31064,
            value: 'xa_binh_minh',
            division_type: 'xã',
            short_codename: 'binh_minh',
            parentId: 910,
          },
          {
            title: 'Xã Vĩnh Thuận',
            code: 31069,
            value: 'xa_vinh_thuan',
            division_type: 'xã',
            short_codename: 'xa_vinh_thuan',
            parentId: 910,
          },
          {
            title: 'Xã Tân Thuận',
            code: 31072,
            value: 'xa_tan_thuan',
            division_type: 'xã',
            short_codename: 'tan_thuan',
            parentId: 910,
          },
          {
            title: 'Xã Phong Đông',
            code: 31074,
            value: 'xa_phong_dong',
            division_type: 'xã',
            short_codename: 'phong_dong',
            parentId: 910,
          },
          {
            title: 'Xã Vĩnh Phong',
            code: 31075,
            value: 'xa_vinh_phong',
            division_type: 'xã',
            short_codename: 'vinh_phong',
            parentId: 910,
          },
        ],
        parentId: 91,
      },
      {
        title: 'Thành phố Phú Quốc',
        code: 911,
        value: 'thanh_pho_phu_quoc',
        division_type: 'thành phố',
        short_codename: 'phu_quoc',
        children: [
          {
            title: 'Phường Dương Đông',
            code: 31078,
            value: 'phuong_duong_dong',
            division_type: 'phường',
            short_codename: 'duong_dong',
            parentId: 911,
          },
          {
            title: 'Phường An Thới',
            code: 31081,
            value: 'phuong_an_thoi',
            division_type: 'phường',
            short_codename: 'an_thoi',
            parentId: 911,
          },
          {
            title: 'Xã Cửa Cạn',
            code: 31084,
            value: 'xa_cua_can',
            division_type: 'xã',
            short_codename: 'cua_can',
            parentId: 911,
          },
          {
            title: 'Xã Gành Dầu',
            code: 31087,
            value: 'xa_ganh_dau',
            division_type: 'xã',
            short_codename: 'ganh_dau',
            parentId: 911,
          },
          {
            title: 'Xã Cửa Dương',
            code: 31090,
            value: 'xa_cua_duong',
            division_type: 'xã',
            short_codename: 'cua_duong',
            parentId: 911,
          },
          {
            title: 'Xã Hàm Ninh',
            code: 31093,
            value: 'xa_ham_ninh',
            division_type: 'xã',
            short_codename: 'ham_ninh',
            parentId: 911,
          },
          {
            title: 'Xã Dương Tơ',
            code: 31096,
            value: 'xa_duong_to',
            division_type: 'xã',
            short_codename: 'duong_to',
            parentId: 911,
          },
          {
            title: 'Xã Bãi Thơm',
            code: 31102,
            value: 'xa_bai_thom',
            division_type: 'xã',
            short_codename: 'bai_thom',
            parentId: 911,
          },
          {
            title: 'Xã Thổ Châu',
            code: 31105,
            value: 'xa_tho_chau',
            division_type: 'xã',
            short_codename: 'tho_chau',
            parentId: 911,
          },
        ],
        parentId: 91,
      },
      {
        title: 'Huyện Kiên Hải',
        code: 912,
        value: 'huyen_kien_hai',
        division_type: 'huyện',
        short_codename: 'kien_hai',
        children: [
          {
            title: 'Xã Hòn Tre',
            code: 31108,
            value: 'xa_hon_tre',
            division_type: 'xã',
            short_codename: 'hon_tre',
            parentId: 912,
          },
          {
            title: 'Xã Lại Sơn',
            code: 31111,
            value: 'xa_lai_son',
            division_type: 'xã',
            short_codename: 'lai_son',
            parentId: 912,
          },
          {
            title: 'Xã An Sơn',
            code: 31114,
            value: 'xa_an_son',
            division_type: 'xã',
            short_codename: 'an_son',
            parentId: 912,
          },
          {
            title: 'Xã Nam Du',
            code: 31115,
            value: 'xa_nam_du',
            division_type: 'xã',
            short_codename: 'nam_du',
            parentId: 912,
          },
        ],
        parentId: 91,
      },
      {
        title: 'Huyện U Minh Thượng',
        code: 913,
        value: 'huyen_u_minh_thuong',
        division_type: 'huyện',
        short_codename: 'u_minh_thuong',
        children: [
          {
            title: 'Xã Thạnh Yên',
            code: 31012,
            value: 'xa_thanh_yen',
            division_type: 'xã',
            short_codename: 'thanh_yen',
            parentId: 913,
          },
          {
            title: 'Xã Thạnh Yên A',
            code: 31015,
            value: 'xa_thanh_yen_a',
            division_type: 'xã',
            short_codename: 'thanh_yen_a',
            parentId: 913,
          },
          {
            title: 'Xã An Minh Bắc',
            code: 31027,
            value: 'xa_an_minh_bac',
            division_type: 'xã',
            short_codename: 'an_minh_bac',
            parentId: 913,
          },
          {
            title: 'Xã Vĩnh Hòa',
            code: 31054,
            value: 'xa_vinh_hoa',
            division_type: 'xã',
            short_codename: 'vinh_hoa',
            parentId: 913,
          },
          {
            title: 'Xã Hoà Chánh',
            code: 31057,
            value: 'xa_hoa_chanh',
            division_type: 'xã',
            short_codename: 'hoa_chanh',
            parentId: 913,
          },
          {
            title: 'Xã Minh Thuận',
            code: 31066,
            value: 'xa_minh_thuan',
            division_type: 'xã',
            short_codename: 'minh_thuan',
            parentId: 913,
          },
        ],
        parentId: 91,
      },
      {
        title: 'Huyện Giang Thành',
        code: 914,
        value: 'huyen_giang_thanh',
        division_type: 'huyện',
        short_codename: 'giang_thanh',
        children: [
          {
            title: 'Xã Vĩnh Phú',
            code: 30791,
            value: 'xa_vinh_phu',
            division_type: 'xã',
            short_codename: 'vinh_phu',
            parentId: 914,
          },
          {
            title: 'Xã Vĩnh Điều',
            code: 30793,
            value: 'xa_vinh_dieu',
            division_type: 'xã',
            short_codename: 'vinh_dieu',
            parentId: 914,
          },
          {
            title: 'Xã Tân Khánh Hòa',
            code: 30796,
            value: 'xa_tan_khanh_hoa',
            division_type: 'xã',
            short_codename: 'tan_khanh_hoa',
            parentId: 914,
          },
          {
            title: 'Xã Phú Lợi',
            code: 30797,
            value: 'xa_phu_loi',
            division_type: 'xã',
            short_codename: 'phu_loi',
            parentId: 914,
          },
          {
            title: 'Xã Phú Mỹ',
            code: 30799,
            value: 'xa_phu_my',
            division_type: 'xã',
            short_codename: 'phu_my',
            parentId: 914,
          },
        ],
        parentId: 91,
      },
    ],
  },
  {
    title: 'Thành phố Cần Thơ',
    code: 92,
    value: 'thanh_pho_can_tho',
    division_type: 'thành phố trung ương',
    phone_code: 292,
    children: [
      {
        title: 'Quận Ninh Kiều',
        code: 916,
        value: 'quan_ninh_kieu',
        division_type: 'quận',
        short_codename: 'ninh_kieu',
        children: [
          {
            title: 'Phường Cái Khế',
            code: 31117,
            value: 'phuong_cai_khe',
            division_type: 'phường',
            short_codename: 'cai_khe',
            parentId: 916,
          },
          {
            title: 'Phường An Hòa',
            code: 31120,
            value: 'phuong_an_hoa',
            division_type: 'phường',
            short_codename: 'an_hoa',
            parentId: 916,
          },
          {
            title: 'Phường Thới Bình',
            code: 31123,
            value: 'phuong_thoi_binh',
            division_type: 'phường',
            short_codename: 'thoi_binh',
            parentId: 916,
          },
          {
            title: 'Phường An Nghiệp',
            code: 31126,
            value: 'phuong_an_nghiep',
            division_type: 'phường',
            short_codename: 'an_nghiep',
            parentId: 916,
          },
          {
            title: 'Phường An Cư',
            code: 31129,
            value: 'phuong_an_cu',
            division_type: 'phường',
            short_codename: 'an_cu',
            parentId: 916,
          },
          {
            title: 'Phường Tân An',
            code: 31135,
            value: 'phuong_tan_an',
            division_type: 'phường',
            short_codename: 'tan_an',
            parentId: 916,
          },
          {
            title: 'Phường An Phú',
            code: 31141,
            value: 'phuong_an_phu',
            division_type: 'phường',
            short_codename: 'an_phu',
            parentId: 916,
          },
          {
            title: 'Phường Xuân Khánh',
            code: 31144,
            value: 'phuong_xuan_khanh',
            division_type: 'phường',
            short_codename: 'xuan_khanh',
            parentId: 916,
          },
          {
            title: 'Phường Hưng Lợi',
            code: 31147,
            value: 'phuong_hung_loi',
            division_type: 'phường',
            short_codename: 'hung_loi',
            parentId: 916,
          },
          {
            title: 'Phường An Khánh',
            code: 31149,
            value: 'phuong_an_khanh',
            division_type: 'phường',
            short_codename: 'an_khanh',
            parentId: 916,
          },
          {
            title: 'Phường An Bình',
            code: 31150,
            value: 'phuong_an_binh',
            division_type: 'phường',
            short_codename: 'an_binh',
            parentId: 916,
          },
        ],
        parentId: 92,
      },
      {
        title: 'Quận Ô Môn',
        code: 917,
        value: 'quan_o_mon',
        division_type: 'quận',
        short_codename: 'o_mon',
        children: [
          {
            title: 'Phường Châu Văn Liêm',
            code: 31153,
            value: 'phuong_chau_van_liem',
            division_type: 'phường',
            short_codename: 'chau_van_liem',
            parentId: 917,
          },
          {
            title: 'Phường Thới Hòa',
            code: 31154,
            value: 'phuong_thoi_hoa',
            division_type: 'phường',
            short_codename: 'thoi_hoa',
            parentId: 917,
          },
          {
            title: 'Phường Thới Long',
            code: 31156,
            value: 'phuong_thoi_long',
            division_type: 'phường',
            short_codename: 'thoi_long',
            parentId: 917,
          },
          {
            title: 'Phường Long Hưng',
            code: 31157,
            value: 'phuong_long_hung',
            division_type: 'phường',
            short_codename: 'long_hung',
            parentId: 917,
          },
          {
            title: 'Phường Thới An',
            code: 31159,
            value: 'phuong_thoi_an',
            division_type: 'phường',
            short_codename: 'thoi_an',
            parentId: 917,
          },
          {
            title: 'Phường Phước Thới',
            code: 31162,
            value: 'phuong_phuoc_thoi',
            division_type: 'phường',
            short_codename: 'phuoc_thoi',
            parentId: 917,
          },
          {
            title: 'Phường Trường Lạc',
            code: 31165,
            value: 'phuong_truong_lac',
            division_type: 'phường',
            short_codename: 'truong_lac',
            parentId: 917,
          },
        ],
        parentId: 92,
      },
      {
        title: 'Quận Bình Thuỷ',
        code: 918,
        value: 'quan_binh_thuy',
        division_type: 'quận',
        short_codename: 'binh_thuy',
        children: [
          {
            title: 'Phường Bình Thủy',
            code: 31168,
            value: 'phuong_binh_thuy',
            division_type: 'phường',
            short_codename: 'binh_thuy',
            parentId: 918,
          },
          {
            title: 'Phường Trà An',
            code: 31169,
            value: 'phuong_tra_an',
            division_type: 'phường',
            short_codename: 'tra_an',
            parentId: 918,
          },
          {
            title: 'Phường Trà Nóc',
            code: 31171,
            value: 'phuong_tra_noc',
            division_type: 'phường',
            short_codename: 'tra_noc',
            parentId: 918,
          },
          {
            title: 'Phường Thới An Đông',
            code: 31174,
            value: 'phuong_thoi_an_dong',
            division_type: 'phường',
            short_codename: 'thoi_an_dong',
            parentId: 918,
          },
          {
            title: 'Phường An Thới',
            code: 31177,
            value: 'phuong_an_thoi',
            division_type: 'phường',
            short_codename: 'an_thoi',
            parentId: 918,
          },
          {
            title: 'Phường Bùi Hữu Nghĩa',
            code: 31178,
            value: 'phuong_bui_huu_nghia',
            division_type: 'phường',
            short_codename: 'bui_huu_nghia',
            parentId: 918,
          },
          {
            title: 'Phường Long Hòa',
            code: 31180,
            value: 'phuong_long_hoa',
            division_type: 'phường',
            short_codename: 'long_hoa',
            parentId: 918,
          },
          {
            title: 'Phường Long Tuyền',
            code: 31183,
            value: 'phuong_long_tuyen',
            division_type: 'phường',
            short_codename: 'long_tuyen',
            parentId: 918,
          },
        ],
        parentId: 92,
      },
      {
        title: 'Quận Cái Răng',
        code: 919,
        value: 'quan_cai_rang',
        division_type: 'quận',
        short_codename: 'cai_rang',
        children: [
          {
            title: 'Phường Lê Bình',
            code: 31186,
            value: 'phuong_le_binh',
            division_type: 'phường',
            short_codename: 'le_binh',
            parentId: 919,
          },
          {
            title: 'Phường Hưng Phú',
            code: 31189,
            value: 'phuong_hung_phu',
            division_type: 'phường',
            short_codename: 'hung_phu',
            parentId: 919,
          },
          {
            title: 'Phường Hưng Thạnh',
            code: 31192,
            value: 'phuong_hung_thanh',
            division_type: 'phường',
            short_codename: 'hung_thanh',
            parentId: 919,
          },
          {
            title: 'Phường Ba Láng',
            code: 31195,
            value: 'phuong_ba_lang',
            division_type: 'phường',
            short_codename: 'ba_lang',
            parentId: 919,
          },
          {
            title: 'Phường Thường Thạnh',
            code: 31198,
            value: 'phuong_thuong_thanh',
            division_type: 'phường',
            short_codename: 'thuong_thanh',
            parentId: 919,
          },
          {
            title: 'Phường Phú Thứ',
            code: 31201,
            value: 'phuong_phu_thu',
            division_type: 'phường',
            short_codename: 'phu_thu',
            parentId: 919,
          },
          {
            title: 'Phường Tân Phú',
            code: 31204,
            value: 'phuong_tan_phu',
            division_type: 'phường',
            short_codename: 'tan_phu',
            parentId: 919,
          },
        ],
        parentId: 92,
      },
      {
        title: 'Quận Thốt Nốt',
        code: 923,
        value: 'quan_thot_not',
        division_type: 'quận',
        short_codename: 'thot_not',
        children: [
          {
            title: 'Phường Thốt Nốt',
            code: 31207,
            value: 'phuong_thot_not',
            division_type: 'phường',
            short_codename: 'thot_not',
            parentId: 923,
          },
          {
            title: 'Phường Thới Thuận',
            code: 31210,
            value: 'phuong_thoi_thuan',
            division_type: 'phường',
            short_codename: 'thoi_thuan',
            parentId: 923,
          },
          {
            title: 'Phường Thuận An',
            code: 31212,
            value: 'phuong_thuan_an',
            division_type: 'phường',
            short_codename: 'thuan_an',
            parentId: 923,
          },
          {
            title: 'Phường Tân Lộc',
            code: 31213,
            value: 'phuong_tan_loc',
            division_type: 'phường',
            short_codename: 'tan_loc',
            parentId: 923,
          },
          {
            title: 'Phường Trung Nhứt',
            code: 31216,
            value: 'phuong_trung_nhut',
            division_type: 'phường',
            short_codename: 'trung_nhut',
            parentId: 923,
          },
          {
            title: 'Phường Thạnh Hoà',
            code: 31217,
            value: 'phuong_thanh_hoa',
            division_type: 'phường',
            short_codename: 'thanh_hoa',
            parentId: 923,
          },
          {
            title: 'Phường Trung Kiên',
            code: 31219,
            value: 'phuong_trung_kien',
            division_type: 'phường',
            short_codename: 'trung_kien',
            parentId: 923,
          },
          {
            title: 'Phường Tân Hưng',
            code: 31227,
            value: 'phuong_tan_hung',
            division_type: 'phường',
            short_codename: 'tan_hung',
            parentId: 923,
          },
          {
            title: 'Phường Thuận Hưng',
            code: 31228,
            value: 'phuong_thuan_hung',
            division_type: 'phường',
            short_codename: 'thuan_hung',
            parentId: 923,
          },
        ],
        parentId: 92,
      },
      {
        title: 'Huyện Vĩnh Thạnh',
        code: 924,
        value: 'huyen_vinh_thanh',
        division_type: 'huyện',
        short_codename: 'vinh_thanh',
        children: [
          {
            title: 'Xã Vĩnh Bình',
            code: 31211,
            value: 'xa_vinh_binh',
            division_type: 'xã',
            short_codename: 'vinh_binh',
            parentId: 924,
          },
          {
            title: 'Thị trấn Thanh An',
            code: 31231,
            value: 'thi_tran_thanh_an',
            division_type: 'thị trấn',
            short_codename: 'thanh_an',
            parentId: 924,
          },
          {
            title: 'Thị trấn Vĩnh Thạnh',
            code: 31232,
            value: 'thi_tran_vinh_thanh',
            division_type: 'thị trấn',
            short_codename: 'vinh_thanh',
            parentId: 924,
          },
          {
            title: 'Xã Thạnh Mỹ',
            code: 31234,
            value: 'xa_thanh_my',
            division_type: 'xã',
            short_codename: 'thanh_my',
            parentId: 924,
          },
          {
            title: 'Xã Vĩnh Trinh',
            code: 31237,
            value: 'xa_vinh_trinh',
            division_type: 'xã',
            short_codename: 'vinh_trinh',
            parentId: 924,
          },
          {
            title: 'Xã Thạnh An',
            code: 31240,
            value: 'xa_thanh_an',
            division_type: 'xã',
            short_codename: 'xa_thanh_an',
            parentId: 924,
          },
          {
            title: 'Xã Thạnh Tiến',
            code: 31241,
            value: 'xa_thanh_tien',
            division_type: 'xã',
            short_codename: 'thanh_tien',
            parentId: 924,
          },
          {
            title: 'Xã Thạnh Thắng',
            code: 31243,
            value: 'xa_thanh_thang',
            division_type: 'xã',
            short_codename: 'thanh_thang',
            parentId: 924,
          },
          {
            title: 'Xã Thạnh Lợi',
            code: 31244,
            value: 'xa_thanh_loi',
            division_type: 'xã',
            short_codename: 'thanh_loi',
            parentId: 924,
          },
          {
            title: 'Xã Thạnh Qưới',
            code: 31246,
            value: 'xa_thanh_quoi',
            division_type: 'xã',
            short_codename: 'thanh_quoi',
            parentId: 924,
          },
          {
            title: 'Xã Thạnh Lộc',
            code: 31252,
            value: 'xa_thanh_loc',
            division_type: 'xã',
            short_codename: 'thanh_loc',
            parentId: 924,
          },
        ],
        parentId: 92,
      },
      {
        title: 'Huyện Cờ Đỏ',
        code: 925,
        value: 'huyen_co_do',
        division_type: 'huyện',
        short_codename: 'co_do',
        children: [
          {
            title: 'Xã Trung An',
            code: 31222,
            value: 'xa_trung_an',
            division_type: 'xã',
            short_codename: 'trung_an',
            parentId: 925,
          },
          {
            title: 'Xã Trung Thạnh',
            code: 31225,
            value: 'xa_trung_thanh',
            division_type: 'xã',
            short_codename: 'trung_thanh',
            parentId: 925,
          },
          {
            title: 'Xã Thạnh Phú',
            code: 31249,
            value: 'xa_thanh_phu',
            division_type: 'xã',
            short_codename: 'thanh_phu',
            parentId: 925,
          },
          {
            title: 'Xã Trung Hưng',
            code: 31255,
            value: 'xa_trung_hung',
            division_type: 'xã',
            short_codename: 'trung_hung',
            parentId: 925,
          },
          {
            title: 'Thị trấn Cờ Đỏ',
            code: 31261,
            value: 'thi_tran_co_do',
            division_type: 'thị trấn',
            short_codename: 'co_do',
            parentId: 925,
          },
          {
            title: 'Xã Thới Hưng',
            code: 31264,
            value: 'xa_thoi_hung',
            division_type: 'xã',
            short_codename: 'thoi_hung',
            parentId: 925,
          },
          {
            title: 'Xã Đông Hiệp',
            code: 31273,
            value: 'xa_dong_hiep',
            division_type: 'xã',
            short_codename: 'dong_hiep',
            parentId: 925,
          },
          {
            title: 'Xã Đông Thắng',
            code: 31274,
            value: 'xa_dong_thang',
            division_type: 'xã',
            short_codename: 'dong_thang',
            parentId: 925,
          },
          {
            title: 'Xã Thới Đông',
            code: 31276,
            value: 'xa_thoi_dong',
            division_type: 'xã',
            short_codename: 'thoi_dong',
            parentId: 925,
          },
          {
            title: 'Xã Thới Xuân',
            code: 31277,
            value: 'xa_thoi_xuan',
            division_type: 'xã',
            short_codename: 'thoi_xuan',
            parentId: 925,
          },
        ],
        parentId: 92,
      },
      {
        title: 'Huyện Phong Điền',
        code: 926,
        value: 'huyen_phong_dien',
        division_type: 'huyện',
        short_codename: 'phong_dien',
        children: [
          {
            title: 'Thị trấn Phong Điền',
            code: 31299,
            value: 'thi_tran_phong_dien',
            division_type: 'thị trấn',
            short_codename: 'phong_dien',
            parentId: 926,
          },
          {
            title: 'Xã Nhơn Ái',
            code: 31300,
            value: 'xa_nhon_ai',
            division_type: 'xã',
            short_codename: 'nhon_ai',
            parentId: 926,
          },
          {
            title: 'Xã Giai Xuân',
            code: 31303,
            value: 'xa_giai_xuan',
            division_type: 'xã',
            short_codename: 'giai_xuan',
            parentId: 926,
          },
          {
            title: 'Xã Tân Thới',
            code: 31306,
            value: 'xa_tan_thoi',
            division_type: 'xã',
            short_codename: 'tan_thoi',
            parentId: 926,
          },
          {
            title: 'Xã Trường Long',
            code: 31309,
            value: 'xa_truong_long',
            division_type: 'xã',
            short_codename: 'truong_long',
            parentId: 926,
          },
          {
            title: 'Xã Mỹ Khánh',
            code: 31312,
            value: 'xa_my_khanh',
            division_type: 'xã',
            short_codename: 'my_khanh',
            parentId: 926,
          },
          {
            title: 'Xã Nhơn Nghĩa',
            code: 31315,
            value: 'xa_nhon_nghia',
            division_type: 'xã',
            short_codename: 'nhon_nghia',
            parentId: 926,
          },
        ],
        parentId: 92,
      },
      {
        title: 'Huyện Thới Lai',
        code: 927,
        value: 'huyen_thoi_lai',
        division_type: 'huyện',
        short_codename: 'thoi_lai',
        children: [
          {
            title: 'Thị trấn Thới Lai',
            code: 31258,
            value: 'thi_tran_thoi_lai',
            division_type: 'thị trấn',
            short_codename: 'thoi_lai',
            parentId: 927,
          },
          {
            title: 'Xã Thới Thạnh',
            code: 31267,
            value: 'xa_thoi_thanh',
            division_type: 'xã',
            short_codename: 'thoi_thanh',
            parentId: 927,
          },
          {
            title: 'Xã Tân Thạnh',
            code: 31268,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 927,
          },
          {
            title: 'Xã Xuân Thắng',
            code: 31270,
            value: 'xa_xuan_thang',
            division_type: 'xã',
            short_codename: 'xuan_thang',
            parentId: 927,
          },
          {
            title: 'Xã Đông Bình',
            code: 31279,
            value: 'xa_dong_binh',
            division_type: 'xã',
            short_codename: 'dong_binh',
            parentId: 927,
          },
          {
            title: 'Xã Đông Thuận',
            code: 31282,
            value: 'xa_dong_thuan',
            division_type: 'xã',
            short_codename: 'dong_thuan',
            parentId: 927,
          },
          {
            title: 'Xã Thới Tân',
            code: 31285,
            value: 'xa_thoi_tan',
            division_type: 'xã',
            short_codename: 'thoi_tan',
            parentId: 927,
          },
          {
            title: 'Xã Trường Thắng',
            code: 31286,
            value: 'xa_truong_thang',
            division_type: 'xã',
            short_codename: 'truong_thang',
            parentId: 927,
          },
          {
            title: 'Xã Định Môn',
            code: 31288,
            value: 'xa_dinh_mon',
            division_type: 'xã',
            short_codename: 'dinh_mon',
            parentId: 927,
          },
          {
            title: 'Xã Trường Thành',
            code: 31291,
            value: 'xa_truong_thanh',
            division_type: 'xã',
            short_codename: 'truong_thanh',
            parentId: 927,
          },
          {
            title: 'Xã Trường Xuân',
            code: 31294,
            value: 'xa_truong_xuan',
            division_type: 'xã',
            short_codename: 'truong_xuan',
            parentId: 927,
          },
          {
            title: 'Xã Trường Xuân A',
            code: 31297,
            value: 'xa_truong_xuan_a',
            division_type: 'xã',
            short_codename: 'truong_xuan_a',
            parentId: 927,
          },
          {
            title: 'Xã Trường Xuân B',
            code: 31298,
            value: 'xa_truong_xuan_b',
            division_type: 'xã',
            short_codename: 'truong_xuan_b',
            parentId: 927,
          },
        ],
        parentId: 92,
      },
    ],
  },
  {
    title: 'Tỉnh Hậu Giang',
    code: 93,
    value: 'tinh_hau_giang',
    division_type: 'tỉnh',
    phone_code: 293,
    children: [
      {
        title: 'Thành phố Vị Thanh',
        code: 930,
        value: 'thanh_pho_vi_thanh',
        division_type: 'thành phố',
        short_codename: 'vi_thanh',
        children: [
          {
            title: 'Phường I',
            code: 31318,
            value: 'phuong_i',
            division_type: 'phường',
            short_codename: 'i',
            parentId: 930,
          },
          {
            title: 'Phường III',
            code: 31321,
            value: 'phuong_iii',
            division_type: 'phường',
            short_codename: 'iii',
            parentId: 930,
          },
          {
            title: 'Phường IV',
            code: 31324,
            value: 'phuong_iv',
            division_type: 'phường',
            short_codename: 'iv',
            parentId: 930,
          },
          {
            title: 'Phường V',
            code: 31327,
            value: 'phuong_v',
            division_type: 'phường',
            short_codename: 'v',
            parentId: 930,
          },
          {
            title: 'Phường VII',
            code: 31330,
            value: 'phuong_vii',
            division_type: 'phường',
            short_codename: 'vii',
            parentId: 930,
          },
          {
            title: 'Xã Vị Tân',
            code: 31333,
            value: 'xa_vi_tan',
            division_type: 'xã',
            short_codename: 'vi_tan',
            parentId: 930,
          },
          {
            title: 'Xã Hoả Lựu',
            code: 31336,
            value: 'xa_hoa_luu',
            division_type: 'xã',
            short_codename: 'hoa_luu',
            parentId: 930,
          },
          {
            title: 'Xã Tân Tiến',
            code: 31338,
            value: 'xa_tan_tien',
            division_type: 'xã',
            short_codename: 'tan_tien',
            parentId: 930,
          },
          {
            title: 'Xã Hoả Tiến',
            code: 31339,
            value: 'xa_hoa_tien',
            division_type: 'xã',
            short_codename: 'hoa_tien',
            parentId: 930,
          },
        ],
        parentId: 93,
      },
      {
        title: 'Thành phố Ngã Bảy',
        code: 931,
        value: 'thanh_pho_nga_bay',
        division_type: 'thành phố',
        short_codename: 'nga_bay',
        children: [
          {
            title: 'Phường Ngã Bảy',
            code: 31340,
            value: 'phuong_nga_bay',
            division_type: 'phường',
            short_codename: 'nga_bay',
            parentId: 931,
          },
          {
            title: 'Phường Lái Hiếu',
            code: 31341,
            value: 'phuong_lai_hieu',
            division_type: 'phường',
            short_codename: 'lai_hieu',
            parentId: 931,
          },
          {
            title: 'Phường Hiệp Thành',
            code: 31343,
            value: 'phuong_hiep_thanh',
            division_type: 'phường',
            short_codename: 'hiep_thanh',
            parentId: 931,
          },
          {
            title: 'Phường Hiệp Lợi',
            code: 31344,
            value: 'phuong_hiep_loi',
            division_type: 'phường',
            short_codename: 'hiep_loi',
            parentId: 931,
          },
          {
            title: 'Xã Đại Thành',
            code: 31411,
            value: 'xa_dai_thanh',
            division_type: 'xã',
            short_codename: 'dai_thanh',
            parentId: 931,
          },
          {
            title: 'Xã Tân Thành',
            code: 31414,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 931,
          },
        ],
        parentId: 93,
      },
      {
        title: 'Huyện Châu Thành A',
        code: 932,
        value: 'huyen_chau_thanh_a',
        division_type: 'huyện',
        short_codename: 'chau_thanh_a',
        children: [
          {
            title: 'Thị trấn Một Ngàn',
            code: 31342,
            value: 'thi_tran_mot_ngan',
            division_type: 'thị trấn',
            short_codename: 'mot_ngan',
            parentId: 932,
          },
          {
            title: 'Xã Tân Hoà',
            code: 31345,
            value: 'xa_tan_hoa',
            division_type: 'xã',
            short_codename: 'tan_hoa',
            parentId: 932,
          },
          {
            title: 'Thị trấn Bảy Ngàn',
            code: 31346,
            value: 'thi_tran_bay_ngan',
            division_type: 'thị trấn',
            short_codename: 'bay_ngan',
            parentId: 932,
          },
          {
            title: 'Xã Trường Long Tây',
            code: 31348,
            value: 'xa_truong_long_tay',
            division_type: 'xã',
            short_codename: 'truong_long_tay',
            parentId: 932,
          },
          {
            title: 'Xã Trường Long A',
            code: 31351,
            value: 'xa_truong_long_a',
            division_type: 'xã',
            short_codename: 'truong_long_a',
            parentId: 932,
          },
          {
            title: 'Xã Nhơn Nghĩa A',
            code: 31357,
            value: 'xa_nhon_nghia_a',
            division_type: 'xã',
            short_codename: 'nhon_nghia_a',
            parentId: 932,
          },
          {
            title: 'Thị trấn Rạch Gòi',
            code: 31359,
            value: 'thi_tran_rach_goi',
            division_type: 'thị trấn',
            short_codename: 'rach_goi',
            parentId: 932,
          },
          {
            title: 'Xã Thạnh Xuân',
            code: 31360,
            value: 'xa_thanh_xuan',
            division_type: 'xã',
            short_codename: 'thanh_xuan',
            parentId: 932,
          },
          {
            title: 'Thị trấn Cái Tắc',
            code: 31362,
            value: 'thi_tran_cai_tac',
            division_type: 'thị trấn',
            short_codename: 'cai_tac',
            parentId: 932,
          },
          {
            title: 'Xã Tân Phú Thạnh',
            code: 31363,
            value: 'xa_tan_phu_thanh',
            division_type: 'xã',
            short_codename: 'tan_phu_thanh',
            parentId: 932,
          },
        ],
        parentId: 93,
      },
      {
        title: 'Huyện Châu Thành',
        code: 933,
        value: 'huyen_chau_thanh',
        division_type: 'huyện',
        short_codename: 'chau_thanh',
        children: [
          {
            title: 'Thị Trấn Ngã Sáu',
            code: 31366,
            value: 'thi_tran_nga_sau',
            division_type: 'thị trấn',
            short_codename: 'nga_sau',
            parentId: 933,
          },
          {
            title: 'Xã Đông Thạnh',
            code: 31369,
            value: 'xa_dong_thanh',
            division_type: 'xã',
            short_codename: 'dong_thanh',
            parentId: 933,
          },
          {
            title: 'Xã Đông Phú',
            code: 31375,
            value: 'xa_dong_phu',
            division_type: 'xã',
            short_codename: 'dong_phu',
            parentId: 933,
          },
          {
            title: 'Xã Phú Hữu',
            code: 31378,
            value: 'xa_phu_huu',
            division_type: 'xã',
            short_codename: 'phu_huu',
            parentId: 933,
          },
          {
            title: 'Xã Phú Tân',
            code: 31379,
            value: 'xa_phu_tan',
            division_type: 'xã',
            short_codename: 'phu_tan',
            parentId: 933,
          },
          {
            title: 'Thị trấn Mái Dầm',
            code: 31381,
            value: 'thi_tran_mai_dam',
            division_type: 'thị trấn',
            short_codename: 'mai_dam',
            parentId: 933,
          },
          {
            title: 'Xã Đông Phước',
            code: 31384,
            value: 'xa_dong_phuoc',
            division_type: 'xã',
            short_codename: 'dong_phuoc',
            parentId: 933,
          },
          {
            title: 'Xã Đông Phước A',
            code: 31387,
            value: 'xa_dong_phuoc_a',
            division_type: 'xã',
            short_codename: 'dong_phuoc_a',
            parentId: 933,
          },
        ],
        parentId: 93,
      },
      {
        title: 'Huyện Phụng Hiệp',
        code: 934,
        value: 'huyen_phung_hiep',
        division_type: 'huyện',
        short_codename: 'phung_hiep',
        children: [
          {
            title: 'Thị trấn Kinh Cùng',
            code: 31393,
            value: 'thi_tran_kinh_cung',
            division_type: 'thị trấn',
            short_codename: 'kinh_cung',
            parentId: 934,
          },
          {
            title: 'Thị trấn Cây Dương',
            code: 31396,
            value: 'thi_tran_cay_duong',
            division_type: 'thị trấn',
            short_codename: 'cay_duong',
            parentId: 934,
          },
          {
            title: 'Xã Tân Bình',
            code: 31399,
            value: 'xa_tan_binh',
            division_type: 'xã',
            short_codename: 'tan_binh',
            parentId: 934,
          },
          {
            title: 'Xã Bình Thành',
            code: 31402,
            value: 'xa_binh_thanh',
            division_type: 'xã',
            short_codename: 'binh_thanh',
            parentId: 934,
          },
          {
            title: 'Xã Thạnh Hòa',
            code: 31405,
            value: 'xa_thanh_hoa',
            division_type: 'xã',
            short_codename: 'thanh_hoa',
            parentId: 934,
          },
          {
            title: 'Xã Long Thạnh',
            code: 31408,
            value: 'xa_long_thanh',
            division_type: 'xã',
            short_codename: 'long_thanh',
            parentId: 934,
          },
          {
            title: 'Xã Phụng Hiệp',
            code: 31417,
            value: 'xa_phung_hiep',
            division_type: 'xã',
            short_codename: 'phung_hiep',
            parentId: 934,
          },
          {
            title: 'Xã Hòa Mỹ',
            code: 31420,
            value: 'xa_hoa_my',
            division_type: 'xã',
            short_codename: 'hoa_my',
            parentId: 934,
          },
          {
            title: 'Xã Hòa An',
            code: 31423,
            value: 'xa_hoa_an',
            division_type: 'xã',
            short_codename: 'hoa_an',
            parentId: 934,
          },
          {
            title: 'Xã Phương Bình',
            code: 31426,
            value: 'xa_phuong_binh',
            division_type: 'xã',
            short_codename: 'phuong_binh',
            parentId: 934,
          },
          {
            title: 'Xã Hiệp Hưng',
            code: 31429,
            value: 'xa_hiep_hung',
            division_type: 'xã',
            short_codename: 'hiep_hung',
            parentId: 934,
          },
          {
            title: 'Xã Tân Phước Hưng',
            code: 31432,
            value: 'xa_tan_phuoc_hung',
            division_type: 'xã',
            short_codename: 'tan_phuoc_hung',
            parentId: 934,
          },
          {
            title: 'Thị trấn Búng Tàu',
            code: 31433,
            value: 'thi_tran_bung_tau',
            division_type: 'thị trấn',
            short_codename: 'bung_tau',
            parentId: 934,
          },
          {
            title: 'Xã Phương Phú',
            code: 31435,
            value: 'xa_phuong_phu',
            division_type: 'xã',
            short_codename: 'phuong_phu',
            parentId: 934,
          },
          {
            title: 'Xã Tân Long',
            code: 31438,
            value: 'xa_tan_long',
            division_type: 'xã',
            short_codename: 'tan_long',
            parentId: 934,
          },
        ],
        parentId: 93,
      },
      {
        title: 'Huyện Vị Thuỷ',
        code: 935,
        value: 'huyen_vi_thuy',
        division_type: 'huyện',
        short_codename: 'vi_thuy',
        children: [
          {
            title: 'Thị trấn Nàng Mau',
            code: 31441,
            value: 'thi_tran_nang_mau',
            division_type: 'thị trấn',
            short_codename: 'nang_mau',
            parentId: 935,
          },
          {
            title: 'Xã Vị Trung',
            code: 31444,
            value: 'xa_vi_trung',
            division_type: 'xã',
            short_codename: 'vi_trung',
            parentId: 935,
          },
          {
            title: 'Xã Vị Thuỷ',
            code: 31447,
            value: 'xa_vi_thuy',
            division_type: 'xã',
            short_codename: 'vi_thuy',
            parentId: 935,
          },
          {
            title: 'Xã Vị Thắng',
            code: 31450,
            value: 'xa_vi_thang',
            division_type: 'xã',
            short_codename: 'vi_thang',
            parentId: 935,
          },
          {
            title: 'Xã Vĩnh Thuận Tây',
            code: 31453,
            value: 'xa_vinh_thuan_tay',
            division_type: 'xã',
            short_codename: 'vinh_thuan_tay',
            parentId: 935,
          },
          {
            title: 'Xã Vĩnh Trung',
            code: 31456,
            value: 'xa_vinh_trung',
            division_type: 'xã',
            short_codename: 'vinh_trung',
            parentId: 935,
          },
          {
            title: 'Xã Vĩnh Tường',
            code: 31459,
            value: 'xa_vinh_tuong',
            division_type: 'xã',
            short_codename: 'vinh_tuong',
            parentId: 935,
          },
          {
            title: 'Xã Vị Đông',
            code: 31462,
            value: 'xa_vi_dong',
            division_type: 'xã',
            short_codename: 'vi_dong',
            parentId: 935,
          },
          {
            title: 'Xã Vị Thanh',
            code: 31465,
            value: 'xa_vi_thanh',
            division_type: 'xã',
            short_codename: 'vi_thanh',
            parentId: 935,
          },
          {
            title: 'Xã Vị Bình',
            code: 31468,
            value: 'xa_vi_binh',
            division_type: 'xã',
            short_codename: 'vi_binh',
            parentId: 935,
          },
        ],
        parentId: 93,
      },
      {
        title: 'Huyện Long Mỹ',
        code: 936,
        value: 'huyen_long_my',
        division_type: 'huyện',
        short_codename: 'huyen_long_my',
        children: [
          {
            title: 'Xã Thuận Hưng',
            code: 31483,
            value: 'xa_thuan_hung',
            division_type: 'xã',
            short_codename: 'thuan_hung',
            parentId: 936,
          },
          {
            title: 'Xã Thuận Hòa',
            code: 31484,
            value: 'xa_thuan_hoa',
            division_type: 'xã',
            short_codename: 'thuan_hoa',
            parentId: 936,
          },
          {
            title: 'Xã Vĩnh Thuận Đông',
            code: 31486,
            value: 'xa_vinh_thuan_dong',
            division_type: 'xã',
            short_codename: 'vinh_thuan_dong',
            parentId: 936,
          },
          {
            title: 'Thị trấn Vĩnh Viễn',
            code: 31489,
            value: 'thi_tran_vinh_vien',
            division_type: 'thị trấn',
            short_codename: 'vinh_vien',
            parentId: 936,
          },
          {
            title: 'Xã Vĩnh Viễn A',
            code: 31490,
            value: 'xa_vinh_vien_a',
            division_type: 'xã',
            short_codename: 'vinh_vien_a',
            parentId: 936,
          },
          {
            title: 'Xã Lương Tâm',
            code: 31492,
            value: 'xa_luong_tam',
            division_type: 'xã',
            short_codename: 'luong_tam',
            parentId: 936,
          },
          {
            title: 'Xã Lương Nghĩa',
            code: 31493,
            value: 'xa_luong_nghia',
            division_type: 'xã',
            short_codename: 'luong_nghia',
            parentId: 936,
          },
          {
            title: 'Xã Xà Phiên',
            code: 31495,
            value: 'xa_xa_phien',
            division_type: 'xã',
            short_codename: 'xa_phien',
            parentId: 936,
          },
        ],
        parentId: 93,
      },
      {
        title: 'Thị xã Long Mỹ',
        code: 937,
        value: 'thi_xa_long_my',
        division_type: 'thị xã',
        short_codename: 'long_my',
        children: [
          {
            title: 'Phường Thuận An',
            code: 31471,
            value: 'phuong_thuan_an',
            division_type: 'phường',
            short_codename: 'thuan_an',
            parentId: 937,
          },
          {
            title: 'Phường Trà Lồng',
            code: 31472,
            value: 'phuong_tra_long',
            division_type: 'phường',
            short_codename: 'tra_long',
            parentId: 937,
          },
          {
            title: 'Phường Bình Thạnh',
            code: 31473,
            value: 'phuong_binh_thanh',
            division_type: 'phường',
            short_codename: 'binh_thanh',
            parentId: 937,
          },
          {
            title: 'Xã Long Bình',
            code: 31474,
            value: 'xa_long_binh',
            division_type: 'xã',
            short_codename: 'long_binh',
            parentId: 937,
          },
          {
            title: 'Phường Vĩnh Tường',
            code: 31475,
            value: 'phuong_vinh_tuong',
            division_type: 'phường',
            short_codename: 'vinh_tuong',
            parentId: 937,
          },
          {
            title: 'Xã Long Trị',
            code: 31477,
            value: 'xa_long_tri',
            division_type: 'xã',
            short_codename: 'long_tri',
            parentId: 937,
          },
          {
            title: 'Xã Long Trị A',
            code: 31478,
            value: 'xa_long_tri_a',
            division_type: 'xã',
            short_codename: 'long_tri_a',
            parentId: 937,
          },
          {
            title: 'Xã Long Phú',
            code: 31480,
            value: 'xa_long_phu',
            division_type: 'xã',
            short_codename: 'long_phu',
            parentId: 937,
          },
          {
            title: 'Xã Tân Phú',
            code: 31481,
            value: 'xa_tan_phu',
            division_type: 'xã',
            short_codename: 'tan_phu',
            parentId: 937,
          },
        ],
        parentId: 93,
      },
    ],
  },
  {
    title: 'Tỉnh Sóc Trăng',
    code: 94,
    value: 'tinh_soc_trang',
    division_type: 'tỉnh',
    phone_code: 299,
    children: [
      {
        title: 'Thành phố Sóc Trăng',
        code: 941,
        value: 'thanh_pho_soc_trang',
        division_type: 'thành phố',
        short_codename: 'soc_trang',
        children: [
          {
            title: 'Phường 5',
            code: 31498,
            value: 'phuong_5',
            division_type: 'phường',
            short_codename: 'phuong_5',
            parentId: 941,
          },
          {
            title: 'Phường 7',
            code: 31501,
            value: 'phuong_7',
            division_type: 'phường',
            short_codename: 'phuong_7',
            parentId: 941,
          },
          {
            title: 'Phường 8',
            code: 31504,
            value: 'phuong_8',
            division_type: 'phường',
            short_codename: 'phuong_8',
            parentId: 941,
          },
          {
            title: 'Phường 6',
            code: 31507,
            value: 'phuong_6',
            division_type: 'phường',
            short_codename: 'phuong_6',
            parentId: 941,
          },
          {
            title: 'Phường 2',
            code: 31510,
            value: 'phuong_2',
            division_type: 'phường',
            short_codename: 'phuong_2',
            parentId: 941,
          },
          {
            title: 'Phường 1',
            code: 31513,
            value: 'phuong_1',
            division_type: 'phường',
            short_codename: 'phuong_1',
            parentId: 941,
          },
          {
            title: 'Phường 4',
            code: 31516,
            value: 'phuong_4',
            division_type: 'phường',
            short_codename: 'phuong_4',
            parentId: 941,
          },
          {
            title: 'Phường 3',
            code: 31519,
            value: 'phuong_3',
            division_type: 'phường',
            short_codename: 'phuong_3',
            parentId: 941,
          },
          {
            title: 'Phường 9',
            code: 31522,
            value: 'phuong_9',
            division_type: 'phường',
            short_codename: 'phuong_9',
            parentId: 941,
          },
          {
            title: 'Phường 10',
            code: 31525,
            value: 'phuong_10',
            division_type: 'phường',
            short_codename: 'phuong_10',
            parentId: 941,
          },
        ],
        parentId: 94,
      },
      {
        title: 'Huyện Châu Thành',
        code: 942,
        value: 'huyen_chau_thanh',
        division_type: 'huyện',
        short_codename: 'chau_thanh',
        children: [
          {
            title: 'Thị trấn Châu Thành',
            code: 31569,
            value: 'thi_tran_chau_thanh',
            division_type: 'thị trấn',
            short_codename: 'chau_thanh',
            parentId: 942,
          },
          {
            title: 'Xã Hồ Đắc Kiện',
            code: 31570,
            value: 'xa_ho_dac_kien',
            division_type: 'xã',
            short_codename: 'ho_dac_kien',
            parentId: 942,
          },
          {
            title: 'Xã Phú Tâm',
            code: 31573,
            value: 'xa_phu_tam',
            division_type: 'xã',
            short_codename: 'phu_tam',
            parentId: 942,
          },
          {
            title: 'Xã Thuận Hòa',
            code: 31576,
            value: 'xa_thuan_hoa',
            division_type: 'xã',
            short_codename: 'thuan_hoa',
            parentId: 942,
          },
          {
            title: 'Xã Phú Tân',
            code: 31582,
            value: 'xa_phu_tan',
            division_type: 'xã',
            short_codename: 'phu_tan',
            parentId: 942,
          },
          {
            title: 'Xã Thiện Mỹ',
            code: 31585,
            value: 'xa_thien_my',
            division_type: 'xã',
            short_codename: 'thien_my',
            parentId: 942,
          },
          {
            title: 'Xã An Hiệp',
            code: 31594,
            value: 'xa_an_hiep',
            division_type: 'xã',
            short_codename: 'an_hiep',
            parentId: 942,
          },
          {
            title: 'Xã An Ninh',
            code: 31600,
            value: 'xa_an_ninh',
            division_type: 'xã',
            short_codename: 'an_ninh',
            parentId: 942,
          },
        ],
        parentId: 94,
      },
      {
        title: 'Huyện Kế Sách',
        code: 943,
        value: 'huyen_ke_sach',
        division_type: 'huyện',
        short_codename: 'ke_sach',
        children: [
          {
            title: 'Thị trấn Kế Sách',
            code: 31528,
            value: 'thi_tran_ke_sach',
            division_type: 'thị trấn',
            short_codename: 'ke_sach',
            parentId: 943,
          },
          {
            title: 'Thị trấn An Lạc Thôn',
            code: 31531,
            value: 'thi_tran_an_lac_thon',
            division_type: 'thị trấn',
            short_codename: 'an_lac_thon',
            parentId: 943,
          },
          {
            title: 'Xã Xuân Hòa',
            code: 31534,
            value: 'xa_xuan_hoa',
            division_type: 'xã',
            short_codename: 'xuan_hoa',
            parentId: 943,
          },
          {
            title: 'Xã Phong Nẫm',
            code: 31537,
            value: 'xa_phong_nam',
            division_type: 'xã',
            short_codename: 'phong_nam',
            parentId: 943,
          },
          {
            title: 'Xã An Lạc Tây',
            code: 31540,
            value: 'xa_an_lac_tay',
            division_type: 'xã',
            short_codename: 'an_lac_tay',
            parentId: 943,
          },
          {
            title: 'Xã Trinh Phú',
            code: 31543,
            value: 'xa_trinh_phu',
            division_type: 'xã',
            short_codename: 'trinh_phu',
            parentId: 943,
          },
          {
            title: 'Xã Ba Trinh',
            code: 31546,
            value: 'xa_ba_trinh',
            division_type: 'xã',
            short_codename: 'ba_trinh',
            parentId: 943,
          },
          {
            title: 'Xã Thới An Hội',
            code: 31549,
            value: 'xa_thoi_an_hoi',
            division_type: 'xã',
            short_codename: 'thoi_an_hoi',
            parentId: 943,
          },
          {
            title: 'Xã Nhơn Mỹ',
            code: 31552,
            value: 'xa_nhon_my',
            division_type: 'xã',
            short_codename: 'nhon_my',
            parentId: 943,
          },
          {
            title: 'Xã Kế Thành',
            code: 31555,
            value: 'xa_ke_thanh',
            division_type: 'xã',
            short_codename: 'ke_thanh',
            parentId: 943,
          },
          {
            title: 'Xã Kế An',
            code: 31558,
            value: 'xa_ke_an',
            division_type: 'xã',
            short_codename: 'ke_an',
            parentId: 943,
          },
          {
            title: 'Xã Đại Hải',
            code: 31561,
            value: 'xa_dai_hai',
            division_type: 'xã',
            short_codename: 'dai_hai',
            parentId: 943,
          },
          {
            title: 'Xã An Mỹ',
            code: 31564,
            value: 'xa_an_my',
            division_type: 'xã',
            short_codename: 'an_my',
            parentId: 943,
          },
        ],
        parentId: 94,
      },
      {
        title: 'Huyện Mỹ Tú',
        code: 944,
        value: 'huyen_my_tu',
        division_type: 'huyện',
        short_codename: 'my_tu',
        children: [
          {
            title: 'Thị trấn Huỳnh Hữu Nghĩa',
            code: 31567,
            value: 'thi_tran_huynh_huu_nghia',
            division_type: 'thị trấn',
            short_codename: 'huynh_huu_nghia',
            parentId: 944,
          },
          {
            title: 'Xã Long Hưng',
            code: 31579,
            value: 'xa_long_hung',
            division_type: 'xã',
            short_codename: 'long_hung',
            parentId: 944,
          },
          {
            title: 'Xã Hưng Phú',
            code: 31588,
            value: 'xa_hung_phu',
            division_type: 'xã',
            short_codename: 'hung_phu',
            parentId: 944,
          },
          {
            title: 'Xã Mỹ Hương',
            code: 31591,
            value: 'xa_my_huong',
            division_type: 'xã',
            short_codename: 'my_huong',
            parentId: 944,
          },
          {
            title: 'Xã Mỹ Tú',
            code: 31597,
            value: 'xa_my_tu',
            division_type: 'xã',
            short_codename: 'my_tu',
            parentId: 944,
          },
          {
            title: 'Xã Mỹ Phước',
            code: 31603,
            value: 'xa_my_phuoc',
            division_type: 'xã',
            short_codename: 'my_phuoc',
            parentId: 944,
          },
          {
            title: 'Xã Thuận Hưng',
            code: 31606,
            value: 'xa_thuan_hung',
            division_type: 'xã',
            short_codename: 'thuan_hung',
            parentId: 944,
          },
          {
            title: 'Xã Mỹ Thuận',
            code: 31609,
            value: 'xa_my_thuan',
            division_type: 'xã',
            short_codename: 'my_thuan',
            parentId: 944,
          },
          {
            title: 'Xã Phú Mỹ',
            code: 31612,
            value: 'xa_phu_my',
            division_type: 'xã',
            short_codename: 'phu_my',
            parentId: 944,
          },
        ],
        parentId: 94,
      },
      {
        title: 'Huyện Cù Lao Dung',
        code: 945,
        value: 'huyen_cu_lao_dung',
        division_type: 'huyện',
        short_codename: 'cu_lao_dung',
        children: [
          {
            title: 'Thị trấn Cù Lao Dung',
            code: 31615,
            value: 'thi_tran_cu_lao_dung',
            division_type: 'thị trấn',
            short_codename: 'cu_lao_dung',
            parentId: 945,
          },
          {
            title: 'Xã An Thạnh 1',
            code: 31618,
            value: 'xa_an_thanh_1',
            division_type: 'xã',
            short_codename: 'an_thanh_1',
            parentId: 945,
          },
          {
            title: 'Xã An Thạnh Tây',
            code: 31621,
            value: 'xa_an_thanh_tay',
            division_type: 'xã',
            short_codename: 'an_thanh_tay',
            parentId: 945,
          },
          {
            title: 'Xã An Thạnh Đông',
            code: 31624,
            value: 'xa_an_thanh_dong',
            division_type: 'xã',
            short_codename: 'an_thanh_dong',
            parentId: 945,
          },
          {
            title: 'Xã Đại Ân 1',
            code: 31627,
            value: 'xa_dai_an_1',
            division_type: 'xã',
            short_codename: 'dai_an_1',
            parentId: 945,
          },
          {
            title: 'Xã An Thạnh 2',
            code: 31630,
            value: 'xa_an_thanh_2',
            division_type: 'xã',
            short_codename: 'an_thanh_2',
            parentId: 945,
          },
          {
            title: 'Xã An Thạnh 3',
            code: 31633,
            value: 'xa_an_thanh_3',
            division_type: 'xã',
            short_codename: 'an_thanh_3',
            parentId: 945,
          },
          {
            title: 'Xã An Thạnh Nam',
            code: 31636,
            value: 'xa_an_thanh_nam',
            division_type: 'xã',
            short_codename: 'an_thanh_nam',
            parentId: 945,
          },
        ],
        parentId: 94,
      },
      {
        title: 'Huyện Long Phú',
        code: 946,
        value: 'huyen_long_phu',
        division_type: 'huyện',
        short_codename: 'long_phu',
        children: [
          {
            title: 'Thị trấn Long Phú',
            code: 31639,
            value: 'thi_tran_long_phu',
            division_type: 'thị trấn',
            short_codename: 'long_phu',
            parentId: 946,
          },
          {
            title: 'Xã Song Phụng',
            code: 31642,
            value: 'xa_song_phung',
            division_type: 'xã',
            short_codename: 'song_phung',
            parentId: 946,
          },
          {
            title: 'Thị trấn Đại Ngãi',
            code: 31645,
            value: 'thi_tran_dai_ngai',
            division_type: 'thị trấn',
            short_codename: 'dai_ngai',
            parentId: 946,
          },
          {
            title: 'Xã Hậu Thạnh',
            code: 31648,
            value: 'xa_hau_thanh',
            division_type: 'xã',
            short_codename: 'hau_thanh',
            parentId: 946,
          },
          {
            title: 'Xã Long Đức',
            code: 31651,
            value: 'xa_long_duc',
            division_type: 'xã',
            short_codename: 'long_duc',
            parentId: 946,
          },
          {
            title: 'Xã Trường Khánh',
            code: 31654,
            value: 'xa_truong_khanh',
            division_type: 'xã',
            short_codename: 'truong_khanh',
            parentId: 946,
          },
          {
            title: 'Xã Phú Hữu',
            code: 31657,
            value: 'xa_phu_huu',
            division_type: 'xã',
            short_codename: 'phu_huu',
            parentId: 946,
          },
          {
            title: 'Xã Tân Hưng',
            code: 31660,
            value: 'xa_tan_hung',
            division_type: 'xã',
            short_codename: 'tan_hung',
            parentId: 946,
          },
          {
            title: 'Xã Châu Khánh',
            code: 31663,
            value: 'xa_chau_khanh',
            division_type: 'xã',
            short_codename: 'chau_khanh',
            parentId: 946,
          },
          {
            title: 'Xã Tân Thạnh',
            code: 31666,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 946,
          },
          {
            title: 'Xã Long Phú',
            code: 31669,
            value: 'xa_long_phu',
            division_type: 'xã',
            short_codename: 'xa_long_phu',
            parentId: 946,
          },
        ],
        parentId: 94,
      },
      {
        title: 'Huyện Mỹ Xuyên',
        code: 947,
        value: 'huyen_my_xuyen',
        division_type: 'huyện',
        short_codename: 'my_xuyen',
        children: [
          {
            title: 'Thị trấn Mỹ Xuyên',
            code: 31684,
            value: 'thi_tran_my_xuyen',
            division_type: 'thị trấn',
            short_codename: 'my_xuyen',
            parentId: 947,
          },
          {
            title: 'Xã Đại Tâm',
            code: 31690,
            value: 'xa_dai_tam',
            division_type: 'xã',
            short_codename: 'dai_tam',
            parentId: 947,
          },
          {
            title: 'Xã Tham Đôn',
            code: 31693,
            value: 'xa_tham_don',
            division_type: 'xã',
            short_codename: 'tham_don',
            parentId: 947,
          },
          {
            title: 'Xã Thạnh Phú',
            code: 31708,
            value: 'xa_thanh_phu',
            division_type: 'xã',
            short_codename: 'thanh_phu',
            parentId: 947,
          },
          {
            title: 'Xã Ngọc Đông',
            code: 31711,
            value: 'xa_ngoc_dong',
            division_type: 'xã',
            short_codename: 'ngoc_dong',
            parentId: 947,
          },
          {
            title: 'Xã Thạnh Quới',
            code: 31714,
            value: 'xa_thanh_quoi',
            division_type: 'xã',
            short_codename: 'thanh_quoi',
            parentId: 947,
          },
          {
            title: 'Xã Hòa Tú 1',
            code: 31717,
            value: 'xa_hoa_tu_1',
            division_type: 'xã',
            short_codename: 'hoa_tu_1',
            parentId: 947,
          },
          {
            title: 'Xã Gia Hòa 1',
            code: 31720,
            value: 'xa_gia_hoa_1',
            division_type: 'xã',
            short_codename: 'gia_hoa_1',
            parentId: 947,
          },
          {
            title: 'Xã Ngọc Tố',
            code: 31723,
            value: 'xa_ngoc_to',
            division_type: 'xã',
            short_codename: 'ngoc_to',
            parentId: 947,
          },
          {
            title: 'Xã Gia Hòa 2',
            code: 31726,
            value: 'xa_gia_hoa_2',
            division_type: 'xã',
            short_codename: 'gia_hoa_2',
            parentId: 947,
          },
          {
            title: 'Xã Hòa Tú II',
            code: 31729,
            value: 'xa_hoa_tu_ii',
            division_type: 'xã',
            short_codename: 'hoa_tu_ii',
            parentId: 947,
          },
        ],
        parentId: 94,
      },
      {
        title: 'Thị xã Ngã Năm',
        code: 948,
        value: 'thi_xa_nga_nam',
        division_type: 'thị xã',
        short_codename: 'nga_nam',
        children: [
          {
            title: 'Phường 1',
            code: 31732,
            value: 'phuong_1',
            division_type: 'phường',
            short_codename: 'phuong_1',
            parentId: 948,
          },
          {
            title: 'Phường 2',
            code: 31735,
            value: 'phuong_2',
            division_type: 'phường',
            short_codename: 'phuong_2',
            parentId: 948,
          },
          {
            title: 'Xã Vĩnh Quới',
            code: 31738,
            value: 'xa_vinh_quoi',
            division_type: 'xã',
            short_codename: 'vinh_quoi',
            parentId: 948,
          },
          {
            title: 'Xã Tân Long',
            code: 31741,
            value: 'xa_tan_long',
            division_type: 'xã',
            short_codename: 'tan_long',
            parentId: 948,
          },
          {
            title: 'Xã Long Bình',
            code: 31744,
            value: 'xa_long_binh',
            division_type: 'xã',
            short_codename: 'long_binh',
            parentId: 948,
          },
          {
            title: 'Phường 3',
            code: 31747,
            value: 'phuong_3',
            division_type: 'phường',
            short_codename: 'phuong_3',
            parentId: 948,
          },
          {
            title: 'Xã Mỹ Bình',
            code: 31750,
            value: 'xa_my_binh',
            division_type: 'xã',
            short_codename: 'my_binh',
            parentId: 948,
          },
          {
            title: 'Xã Mỹ Quới',
            code: 31753,
            value: 'xa_my_quoi',
            division_type: 'xã',
            short_codename: 'my_quoi',
            parentId: 948,
          },
        ],
        parentId: 94,
      },
      {
        title: 'Huyện Thạnh Trị',
        code: 949,
        value: 'huyen_thanh_tri',
        division_type: 'huyện',
        short_codename: 'thanh_tri',
        children: [
          {
            title: 'Thị trấn Phú Lộc',
            code: 31756,
            value: 'thi_tran_phu_loc',
            division_type: 'thị trấn',
            short_codename: 'phu_loc',
            parentId: 949,
          },
          {
            title: 'Thị trấn Hưng Lợi',
            code: 31757,
            value: 'thi_tran_hung_loi',
            division_type: 'thị trấn',
            short_codename: 'hung_loi',
            parentId: 949,
          },
          {
            title: 'Xã Lâm Tân',
            code: 31759,
            value: 'xa_lam_tan',
            division_type: 'xã',
            short_codename: 'lam_tan',
            parentId: 949,
          },
          {
            title: 'Xã Thạnh Tân',
            code: 31762,
            value: 'xa_thanh_tan',
            division_type: 'xã',
            short_codename: 'thanh_tan',
            parentId: 949,
          },
          {
            title: 'Xã Lâm Kiết',
            code: 31765,
            value: 'xa_lam_kiet',
            division_type: 'xã',
            short_codename: 'lam_kiet',
            parentId: 949,
          },
          {
            title: 'Xã Tuân Tức',
            code: 31768,
            value: 'xa_tuan_tuc',
            division_type: 'xã',
            short_codename: 'tuan_tuc',
            parentId: 949,
          },
          {
            title: 'Xã Vĩnh Thành',
            code: 31771,
            value: 'xa_vinh_thanh',
            division_type: 'xã',
            short_codename: 'vinh_thanh',
            parentId: 949,
          },
          {
            title: 'Xã Thạnh Trị',
            code: 31774,
            value: 'xa_thanh_tri',
            division_type: 'xã',
            short_codename: 'thanh_tri',
            parentId: 949,
          },
          {
            title: 'Xã Vĩnh Lợi',
            code: 31777,
            value: 'xa_vinh_loi',
            division_type: 'xã',
            short_codename: 'vinh_loi',
            parentId: 949,
          },
          {
            title: 'Xã Châu Hưng',
            code: 31780,
            value: 'xa_chau_hung',
            division_type: 'xã',
            short_codename: 'chau_hung',
            parentId: 949,
          },
        ],
        parentId: 94,
      },
      {
        title: 'Thị xã Vĩnh Châu',
        code: 950,
        value: 'thi_xa_vinh_chau',
        division_type: 'thị xã',
        short_codename: 'vinh_chau',
        children: [
          {
            title: 'Phường 1',
            code: 31783,
            value: 'phuong_1',
            division_type: 'phường',
            short_codename: 'phuong_1',
            parentId: 950,
          },
          {
            title: 'Xã Hòa Đông',
            code: 31786,
            value: 'xa_hoa_dong',
            division_type: 'xã',
            short_codename: 'hoa_dong',
            parentId: 950,
          },
          {
            title: 'Phường Khánh Hòa',
            code: 31789,
            value: 'phuong_khanh_hoa',
            division_type: 'phường',
            short_codename: 'khanh_hoa',
            parentId: 950,
          },
          {
            title: 'Xã Vĩnh Hiệp',
            code: 31792,
            value: 'xa_vinh_hiep',
            division_type: 'xã',
            short_codename: 'vinh_hiep',
            parentId: 950,
          },
          {
            title: 'Xã Vĩnh Hải',
            code: 31795,
            value: 'xa_vinh_hai',
            division_type: 'xã',
            short_codename: 'vinh_hai',
            parentId: 950,
          },
          {
            title: 'Xã Lạc Hòa',
            code: 31798,
            value: 'xa_lac_hoa',
            division_type: 'xã',
            short_codename: 'lac_hoa',
            parentId: 950,
          },
          {
            title: 'Phường 2',
            code: 31801,
            value: 'phuong_2',
            division_type: 'phường',
            short_codename: 'phuong_2',
            parentId: 950,
          },
          {
            title: 'Phường Vĩnh Phước',
            code: 31804,
            value: 'phuong_vinh_phuoc',
            division_type: 'phường',
            short_codename: 'vinh_phuoc',
            parentId: 950,
          },
          {
            title: 'Xã Vĩnh Tân',
            code: 31807,
            value: 'xa_vinh_tan',
            division_type: 'xã',
            short_codename: 'vinh_tan',
            parentId: 950,
          },
          {
            title: 'Xã Lai Hòa',
            code: 31810,
            value: 'xa_lai_hoa',
            division_type: 'xã',
            short_codename: 'lai_hoa',
            parentId: 950,
          },
        ],
        parentId: 94,
      },
      {
        title: 'Huyện Trần Đề',
        code: 951,
        value: 'huyen_tran_de',
        division_type: 'huyện',
        short_codename: 'tran_de',
        children: [
          {
            title: 'Xã Đại Ân 2',
            code: 31672,
            value: 'xa_dai_an_2',
            division_type: 'xã',
            short_codename: 'dai_an_2',
            parentId: 951,
          },
          {
            title: 'Thị trấn Trần Đề',
            code: 31673,
            value: 'thi_tran_tran_de',
            division_type: 'thị trấn',
            short_codename: 'tran_de',
            parentId: 951,
          },
          {
            title: 'Xã Liêu Tú',
            code: 31675,
            value: 'xa_lieu_tu',
            division_type: 'xã',
            short_codename: 'lieu_tu',
            parentId: 951,
          },
          {
            title: 'Xã Lịch Hội Thượng',
            code: 31678,
            value: 'xa_lich_hoi_thuong',
            division_type: 'xã',
            short_codename: 'xa_lich_hoi_thuong',
            parentId: 951,
          },
          {
            title: 'Thị trấn Lịch Hội Thượng',
            code: 31679,
            value: 'thi_tran_lich_hoi_thuong',
            division_type: 'thị trấn',
            short_codename: 'lich_hoi_thuong',
            parentId: 951,
          },
          {
            title: 'Xã Trung Bình',
            code: 31681,
            value: 'xa_trung_binh',
            division_type: 'xã',
            short_codename: 'trung_binh',
            parentId: 951,
          },
          {
            title: 'Xã Tài Văn',
            code: 31687,
            value: 'xa_tai_van',
            division_type: 'xã',
            short_codename: 'tai_van',
            parentId: 951,
          },
          {
            title: 'Xã Viên An',
            code: 31696,
            value: 'xa_vien_an',
            division_type: 'xã',
            short_codename: 'vien_an',
            parentId: 951,
          },
          {
            title: 'Xã Thạnh Thới An',
            code: 31699,
            value: 'xa_thanh_thoi_an',
            division_type: 'xã',
            short_codename: 'thanh_thoi_an',
            parentId: 951,
          },
          {
            title: 'Xã Thạnh Thới Thuận',
            code: 31702,
            value: 'xa_thanh_thoi_thuan',
            division_type: 'xã',
            short_codename: 'thanh_thoi_thuan',
            parentId: 951,
          },
          {
            title: 'Xã Viên Bình',
            code: 31705,
            value: 'xa_vien_binh',
            division_type: 'xã',
            short_codename: 'vien_binh',
            parentId: 951,
          },
        ],
        parentId: 94,
      },
    ],
  },
  {
    title: 'Tỉnh Bạc Liêu',
    code: 95,
    value: 'tinh_bac_lieu',
    division_type: 'tỉnh',
    phone_code: 291,
    children: [
      {
        title: 'Thành phố Bạc Liêu',
        code: 954,
        value: 'thanh_pho_bac_lieu',
        division_type: 'thành phố',
        short_codename: 'bac_lieu',
        children: [
          {
            title: 'Phường 2',
            code: 31813,
            value: 'phuong_2',
            division_type: 'phường',
            short_codename: 'phuong_2',
            parentId: 954,
          },
          {
            title: 'Phường 3',
            code: 31816,
            value: 'phuong_3',
            division_type: 'phường',
            short_codename: 'phuong_3',
            parentId: 954,
          },
          {
            title: 'Phường 5',
            code: 31819,
            value: 'phuong_5',
            division_type: 'phường',
            short_codename: 'phuong_5',
            parentId: 954,
          },
          {
            title: 'Phường 7',
            code: 31822,
            value: 'phuong_7',
            division_type: 'phường',
            short_codename: 'phuong_7',
            parentId: 954,
          },
          {
            title: 'Phường 1',
            code: 31825,
            value: 'phuong_1',
            division_type: 'phường',
            short_codename: 'phuong_1',
            parentId: 954,
          },
          {
            title: 'Phường 8',
            code: 31828,
            value: 'phuong_8',
            division_type: 'phường',
            short_codename: 'phuong_8',
            parentId: 954,
          },
          {
            title: 'Phường Nhà Mát',
            code: 31831,
            value: 'phuong_nha_mat',
            division_type: 'phường',
            short_codename: 'nha_mat',
            parentId: 954,
          },
          {
            title: 'Xã Vĩnh Trạch',
            code: 31834,
            value: 'xa_vinh_trach',
            division_type: 'xã',
            short_codename: 'vinh_trach',
            parentId: 954,
          },
          {
            title: 'Xã Vĩnh Trạch Đông',
            code: 31837,
            value: 'xa_vinh_trach_dong',
            division_type: 'xã',
            short_codename: 'vinh_trach_dong',
            parentId: 954,
          },
          {
            title: 'Xã Hiệp Thành',
            code: 31840,
            value: 'xa_hiep_thanh',
            division_type: 'xã',
            short_codename: 'hiep_thanh',
            parentId: 954,
          },
        ],
        parentId: 95,
      },
      {
        title: 'Huyện Hồng Dân',
        code: 956,
        value: 'huyen_hong_dan',
        division_type: 'huyện',
        short_codename: 'hong_dan',
        children: [
          {
            title: 'Thị trấn Ngan Dừa',
            code: 31843,
            value: 'thi_tran_ngan_dua',
            division_type: 'thị trấn',
            short_codename: 'ngan_dua',
            parentId: 956,
          },
          {
            title: 'Xã Ninh Quới',
            code: 31846,
            value: 'xa_ninh_quoi',
            division_type: 'xã',
            short_codename: 'ninh_quoi',
            parentId: 956,
          },
          {
            title: 'Xã Ninh Quới A',
            code: 31849,
            value: 'xa_ninh_quoi_a',
            division_type: 'xã',
            short_codename: 'ninh_quoi_a',
            parentId: 956,
          },
          {
            title: 'Xã Ninh Hòa',
            code: 31852,
            value: 'xa_ninh_hoa',
            division_type: 'xã',
            short_codename: 'ninh_hoa',
            parentId: 956,
          },
          {
            title: 'Xã Lộc Ninh',
            code: 31855,
            value: 'xa_loc_ninh',
            division_type: 'xã',
            short_codename: 'loc_ninh',
            parentId: 956,
          },
          {
            title: 'Xã Vĩnh Lộc',
            code: 31858,
            value: 'xa_vinh_loc',
            division_type: 'xã',
            short_codename: 'vinh_loc',
            parentId: 956,
          },
          {
            title: 'Xã Vĩnh Lộc A',
            code: 31861,
            value: 'xa_vinh_loc_a',
            division_type: 'xã',
            short_codename: 'vinh_loc_a',
            parentId: 956,
          },
          {
            title: 'Xã Ninh Thạnh Lợi A',
            code: 31863,
            value: 'xa_ninh_thanh_loi_a',
            division_type: 'xã',
            short_codename: 'ninh_thanh_loi_a',
            parentId: 956,
          },
          {
            title: 'Xã Ninh Thạnh Lợi',
            code: 31864,
            value: 'xa_ninh_thanh_loi',
            division_type: 'xã',
            short_codename: 'ninh_thanh_loi',
            parentId: 956,
          },
        ],
        parentId: 95,
      },
      {
        title: 'Huyện Phước Long',
        code: 957,
        value: 'huyen_phuoc_long',
        division_type: 'huyện',
        short_codename: 'phuoc_long',
        children: [
          {
            title: 'Thị trấn Phước Long',
            code: 31867,
            value: 'thi_tran_phuoc_long',
            division_type: 'thị trấn',
            short_codename: 'phuoc_long',
            parentId: 957,
          },
          {
            title: 'Xã Vĩnh Phú Đông',
            code: 31870,
            value: 'xa_vinh_phu_dong',
            division_type: 'xã',
            short_codename: 'vinh_phu_dong',
            parentId: 957,
          },
          {
            title: 'Xã Vĩnh Phú Tây',
            code: 31873,
            value: 'xa_vinh_phu_tay',
            division_type: 'xã',
            short_codename: 'vinh_phu_tay',
            parentId: 957,
          },
          {
            title: 'Xã Phước Long',
            code: 31876,
            value: 'xa_phuoc_long',
            division_type: 'xã',
            short_codename: 'xa_phuoc_long',
            parentId: 957,
          },
          {
            title: 'Xã Hưng Phú',
            code: 31879,
            value: 'xa_hung_phu',
            division_type: 'xã',
            short_codename: 'hung_phu',
            parentId: 957,
          },
          {
            title: 'Xã Vĩnh Thanh',
            code: 31882,
            value: 'xa_vinh_thanh',
            division_type: 'xã',
            short_codename: 'vinh_thanh',
            parentId: 957,
          },
          {
            title: 'Xã Phong Thạnh Tây A',
            code: 31885,
            value: 'xa_phong_thanh_tay_a',
            division_type: 'xã',
            short_codename: 'phong_thanh_tay_a',
            parentId: 957,
          },
          {
            title: 'Xã Phong Thạnh Tây B',
            code: 31888,
            value: 'xa_phong_thanh_tay_b',
            division_type: 'xã',
            short_codename: 'phong_thanh_tay_b',
            parentId: 957,
          },
        ],
        parentId: 95,
      },
      {
        title: 'Huyện Vĩnh Lợi',
        code: 958,
        value: 'huyen_vinh_loi',
        division_type: 'huyện',
        short_codename: 'vinh_loi',
        children: [
          {
            title: 'Xã Vĩnh Hưng',
            code: 31894,
            value: 'xa_vinh_hung',
            division_type: 'xã',
            short_codename: 'vinh_hung',
            parentId: 958,
          },
          {
            title: 'Xã Vĩnh Hưng A',
            code: 31897,
            value: 'xa_vinh_hung_a',
            division_type: 'xã',
            short_codename: 'vinh_hung_a',
            parentId: 958,
          },
          {
            title: 'Thị trấn Châu Hưng',
            code: 31900,
            value: 'thi_tran_chau_hung',
            division_type: 'thị trấn',
            short_codename: 'chau_hung',
            parentId: 958,
          },
          {
            title: 'Xã Châu Hưng A',
            code: 31903,
            value: 'xa_chau_hung_a',
            division_type: 'xã',
            short_codename: 'chau_hung_a',
            parentId: 958,
          },
          {
            title: 'Xã Hưng Thành',
            code: 31906,
            value: 'xa_hung_thanh',
            division_type: 'xã',
            short_codename: 'hung_thanh',
            parentId: 958,
          },
          {
            title: 'Xã Hưng Hội',
            code: 31909,
            value: 'xa_hung_hoi',
            division_type: 'xã',
            short_codename: 'hung_hoi',
            parentId: 958,
          },
          {
            title: 'Xã Châu Thới',
            code: 31912,
            value: 'xa_chau_thoi',
            division_type: 'xã',
            short_codename: 'chau_thoi',
            parentId: 958,
          },
          {
            title: 'Xã Long Thạnh',
            code: 31921,
            value: 'xa_long_thanh',
            division_type: 'xã',
            short_codename: 'long_thanh',
            parentId: 958,
          },
        ],
        parentId: 95,
      },
      {
        title: 'Thị xã Giá Rai',
        code: 959,
        value: 'thi_xa_gia_rai',
        division_type: 'thị xã',
        short_codename: 'gia_rai',
        children: [
          {
            title: 'Phường 1',
            code: 31942,
            value: 'phuong_1',
            division_type: 'phường',
            short_codename: 'phuong_1',
            parentId: 959,
          },
          {
            title: 'Phường Hộ Phòng',
            code: 31945,
            value: 'phuong_ho_phong',
            division_type: 'phường',
            short_codename: 'ho_phong',
            parentId: 959,
          },
          {
            title: 'Xã Phong Thạnh Đông',
            code: 31948,
            value: 'xa_phong_thanh_dong',
            division_type: 'xã',
            short_codename: 'phong_thanh_dong',
            parentId: 959,
          },
          {
            title: 'Phường Láng Tròn',
            code: 31951,
            value: 'phuong_lang_tron',
            division_type: 'phường',
            short_codename: 'lang_tron',
            parentId: 959,
          },
          {
            title: 'Xã Phong Tân',
            code: 31954,
            value: 'xa_phong_tan',
            division_type: 'xã',
            short_codename: 'phong_tan',
            parentId: 959,
          },
          {
            title: 'Xã Tân Phong',
            code: 31957,
            value: 'xa_tan_phong',
            division_type: 'xã',
            short_codename: 'tan_phong',
            parentId: 959,
          },
          {
            title: 'Xã Phong Thạnh',
            code: 31960,
            value: 'xa_phong_thanh',
            division_type: 'xã',
            short_codename: 'phong_thanh',
            parentId: 959,
          },
          {
            title: 'Xã Phong Thạnh A',
            code: 31963,
            value: 'xa_phong_thanh_a',
            division_type: 'xã',
            short_codename: 'phong_thanh_a',
            parentId: 959,
          },
          {
            title: 'Xã Phong Thạnh Tây',
            code: 31966,
            value: 'xa_phong_thanh_tay',
            division_type: 'xã',
            short_codename: 'phong_thanh_tay',
            parentId: 959,
          },
          {
            title: 'Xã Tân Thạnh',
            code: 31969,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'tan_thanh',
            parentId: 959,
          },
        ],
        parentId: 95,
      },
      {
        title: 'Huyện Đông Hải',
        code: 960,
        value: 'huyen_dong_hai',
        division_type: 'huyện',
        short_codename: 'dong_hai',
        children: [
          {
            title: 'Thị trấn Gành Hào',
            code: 31972,
            value: 'thi_tran_ganh_hao',
            division_type: 'thị trấn',
            short_codename: 'ganh_hao',
            parentId: 960,
          },
          {
            title: 'Xã Long Điền Đông',
            code: 31975,
            value: 'xa_long_dien_dong',
            division_type: 'xã',
            short_codename: 'long_dien_dong',
            parentId: 960,
          },
          {
            title: 'Xã Long Điền Đông A',
            code: 31978,
            value: 'xa_long_dien_dong_a',
            division_type: 'xã',
            short_codename: 'long_dien_dong_a',
            parentId: 960,
          },
          {
            title: 'Xã Long Điền',
            code: 31981,
            value: 'xa_long_dien',
            division_type: 'xã',
            short_codename: 'long_dien',
            parentId: 960,
          },
          {
            title: 'Xã Long Điền Tây',
            code: 31984,
            value: 'xa_long_dien_tay',
            division_type: 'xã',
            short_codename: 'long_dien_tay',
            parentId: 960,
          },
          {
            title: 'Xã Điền Hải',
            code: 31985,
            value: 'xa_dien_hai',
            division_type: 'xã',
            short_codename: 'dien_hai',
            parentId: 960,
          },
          {
            title: 'Xã An Trạch',
            code: 31987,
            value: 'xa_an_trach',
            division_type: 'xã',
            short_codename: 'an_trach',
            parentId: 960,
          },
          {
            title: 'Xã An Trạch A',
            code: 31988,
            value: 'xa_an_trach_a',
            division_type: 'xã',
            short_codename: 'an_trach_a',
            parentId: 960,
          },
          {
            title: 'Xã An Phúc',
            code: 31990,
            value: 'xa_an_phuc',
            division_type: 'xã',
            short_codename: 'an_phuc',
            parentId: 960,
          },
          {
            title: 'Xã Định Thành',
            code: 31993,
            value: 'xa_dinh_thanh',
            division_type: 'xã',
            short_codename: 'dinh_thanh',
            parentId: 960,
          },
          {
            title: 'Xã Định Thành A',
            code: 31996,
            value: 'xa_dinh_thanh_a',
            division_type: 'xã',
            short_codename: 'dinh_thanh_a',
            parentId: 960,
          },
        ],
        parentId: 95,
      },
      {
        title: 'Huyện Hoà Bình',
        code: 961,
        value: 'huyen_hoa_binh',
        division_type: 'huyện',
        short_codename: 'hoa_binh',
        children: [
          {
            title: 'Thị trấn Hòa Bình',
            code: 31891,
            value: 'thi_tran_hoa_binh',
            division_type: 'thị trấn',
            short_codename: 'hoa_binh',
            parentId: 961,
          },
          {
            title: 'Xã Minh Diệu',
            code: 31915,
            value: 'xa_minh_dieu',
            division_type: 'xã',
            short_codename: 'minh_dieu',
            parentId: 961,
          },
          {
            title: 'Xã Vĩnh Bình',
            code: 31918,
            value: 'xa_vinh_binh',
            division_type: 'xã',
            short_codename: 'vinh_binh',
            parentId: 961,
          },
          {
            title: 'Xã Vĩnh Mỹ B',
            code: 31924,
            value: 'xa_vinh_my_b',
            division_type: 'xã',
            short_codename: 'vinh_my_b',
            parentId: 961,
          },
          {
            title: 'Xã Vĩnh Hậu',
            code: 31927,
            value: 'xa_vinh_hau',
            division_type: 'xã',
            short_codename: 'vinh_hau',
            parentId: 961,
          },
          {
            title: 'Xã Vĩnh Hậu A',
            code: 31930,
            value: 'xa_vinh_hau_a',
            division_type: 'xã',
            short_codename: 'vinh_hau_a',
            parentId: 961,
          },
          {
            title: 'Xã Vĩnh Mỹ A',
            code: 31933,
            value: 'xa_vinh_my_a',
            division_type: 'xã',
            short_codename: 'vinh_my_a',
            parentId: 961,
          },
          {
            title: 'Xã Vĩnh Thịnh',
            code: 31936,
            value: 'xa_vinh_thinh',
            division_type: 'xã',
            short_codename: 'vinh_thinh',
            parentId: 961,
          },
        ],
        parentId: 95,
      },
    ],
  },
  {
    title: 'Tỉnh Cà Mau',
    code: 96,
    value: 'tinh_ca_mau',
    division_type: 'tỉnh',
    phone_code: 290,
    children: [
      {
        title: 'Thành phố Cà Mau',
        code: 964,
        value: 'thanh_pho_ca_mau',
        division_type: 'thành phố',
        short_codename: 'ca_mau',
        children: [
          {
            title: 'Phường 9',
            code: 31999,
            value: 'phuong_9',
            division_type: 'phường',
            short_codename: 'phuong_9',
            parentId: 964,
          },
          {
            title: 'Phường 4',
            code: 32002,
            value: 'phuong_4',
            division_type: 'phường',
            short_codename: 'phuong_4',
            parentId: 964,
          },
          {
            title: 'Phường 1',
            code: 32005,
            value: 'phuong_1',
            division_type: 'phường',
            short_codename: 'phuong_1',
            parentId: 964,
          },
          {
            title: 'Phường 5',
            code: 32008,
            value: 'phuong_5',
            division_type: 'phường',
            short_codename: 'phuong_5',
            parentId: 964,
          },
          {
            title: 'Phường 2',
            code: 32011,
            value: 'phuong_2',
            division_type: 'phường',
            short_codename: 'phuong_2',
            parentId: 964,
          },
          {
            title: 'Phường 8',
            code: 32014,
            value: 'phuong_8',
            division_type: 'phường',
            short_codename: 'phuong_8',
            parentId: 964,
          },
          {
            title: 'Phường 6',
            code: 32017,
            value: 'phuong_6',
            division_type: 'phường',
            short_codename: 'phuong_6',
            parentId: 964,
          },
          {
            title: 'Phường 7',
            code: 32020,
            value: 'phuong_7',
            division_type: 'phường',
            short_codename: 'phuong_7',
            parentId: 964,
          },
          {
            title: 'Phường Tân Xuyên',
            code: 32022,
            value: 'phuong_tan_xuyen',
            division_type: 'phường',
            short_codename: 'tan_xuyen',
            parentId: 964,
          },
          {
            title: 'Xã An Xuyên',
            code: 32023,
            value: 'xa_an_xuyen',
            division_type: 'xã',
            short_codename: 'an_xuyen',
            parentId: 964,
          },
          {
            title: 'Phường Tân Thành',
            code: 32025,
            value: 'phuong_tan_thanh',
            division_type: 'phường',
            short_codename: 'tan_thanh',
            parentId: 964,
          },
          {
            title: 'Xã Tân Thành',
            code: 32026,
            value: 'xa_tan_thanh',
            division_type: 'xã',
            short_codename: 'xa_tan_thanh',
            parentId: 964,
          },
          {
            title: 'Xã Tắc Vân',
            code: 32029,
            value: 'xa_tac_van',
            division_type: 'xã',
            short_codename: 'tac_van',
            parentId: 964,
          },
          {
            title: 'Xã Lý Văn Lâm',
            code: 32032,
            value: 'xa_ly_van_lam',
            division_type: 'xã',
            short_codename: 'ly_van_lam',
            parentId: 964,
          },
          {
            title: 'Xã Định Bình',
            code: 32035,
            value: 'xa_dinh_binh',
            division_type: 'xã',
            short_codename: 'dinh_binh',
            parentId: 964,
          },
          {
            title: 'Xã Hòa Thành',
            code: 32038,
            value: 'xa_hoa_thanh',
            division_type: 'xã',
            short_codename: 'hoa_thanh',
            parentId: 964,
          },
          {
            title: 'Xã Hòa Tân',
            code: 32041,
            value: 'xa_hoa_tan',
            division_type: 'xã',
            short_codename: 'hoa_tan',
            parentId: 964,
          },
        ],
        parentId: 96,
      },
      {
        title: 'Huyện U Minh',
        code: 966,
        value: 'huyen_u_minh',
        division_type: 'huyện',
        short_codename: 'u_minh',
        children: [
          {
            title: 'Thị trấn U Minh',
            code: 32044,
            value: 'thi_tran_u_minh',
            division_type: 'thị trấn',
            short_codename: 'u_minh',
            parentId: 966,
          },
          {
            title: 'Xã Khánh Hòa',
            code: 32047,
            value: 'xa_khanh_hoa',
            division_type: 'xã',
            short_codename: 'khanh_hoa',
            parentId: 966,
          },
          {
            title: 'Xã Khánh Thuận',
            code: 32048,
            value: 'xa_khanh_thuan',
            division_type: 'xã',
            short_codename: 'khanh_thuan',
            parentId: 966,
          },
          {
            title: 'Xã Khánh Tiến',
            code: 32050,
            value: 'xa_khanh_tien',
            division_type: 'xã',
            short_codename: 'khanh_tien',
            parentId: 966,
          },
          {
            title: 'Xã Nguyễn Phích',
            code: 32053,
            value: 'xa_nguyen_phich',
            division_type: 'xã',
            short_codename: 'nguyen_phich',
            parentId: 966,
          },
          {
            title: 'Xã Khánh Lâm',
            code: 32056,
            value: 'xa_khanh_lam',
            division_type: 'xã',
            short_codename: 'khanh_lam',
            parentId: 966,
          },
          {
            title: 'Xã Khánh An',
            code: 32059,
            value: 'xa_khanh_an',
            division_type: 'xã',
            short_codename: 'khanh_an',
            parentId: 966,
          },
          {
            title: 'Xã Khánh Hội',
            code: 32062,
            value: 'xa_khanh_hoi',
            division_type: 'xã',
            short_codename: 'khanh_hoi',
            parentId: 966,
          },
        ],
        parentId: 96,
      },
      {
        title: 'Huyện Thới Bình',
        code: 967,
        value: 'huyen_thoi_binh',
        division_type: 'huyện',
        short_codename: 'thoi_binh',
        children: [
          {
            title: 'Thị trấn Thới Bình',
            code: 32065,
            value: 'thi_tran_thoi_binh',
            division_type: 'thị trấn',
            short_codename: 'thoi_binh',
            parentId: 967,
          },
          {
            title: 'Xã Biển Bạch',
            code: 32068,
            value: 'xa_bien_bach',
            division_type: 'xã',
            short_codename: 'bien_bach',
            parentId: 967,
          },
          {
            title: 'Xã Tân Bằng',
            code: 32069,
            value: 'xa_tan_bang',
            division_type: 'xã',
            short_codename: 'tan_bang',
            parentId: 967,
          },
          {
            title: 'Xã Trí Phải',
            code: 32071,
            value: 'xa_tri_phai',
            division_type: 'xã',
            short_codename: 'tri_phai',
            parentId: 967,
          },
          {
            title: 'Xã Trí Lực',
            code: 32072,
            value: 'xa_tri_luc',
            division_type: 'xã',
            short_codename: 'tri_luc',
            parentId: 967,
          },
          {
            title: 'Xã Biển Bạch Đông',
            code: 32074,
            value: 'xa_bien_bach_dong',
            division_type: 'xã',
            short_codename: 'bien_bach_dong',
            parentId: 967,
          },
          {
            title: 'Xã Thới Bình',
            code: 32077,
            value: 'xa_thoi_binh',
            division_type: 'xã',
            short_codename: 'xa_thoi_binh',
            parentId: 967,
          },
          {
            title: 'Xã Tân Phú',
            code: 32080,
            value: 'xa_tan_phu',
            division_type: 'xã',
            short_codename: 'tan_phu',
            parentId: 967,
          },
          {
            title: 'Xã Tân Lộc Bắc',
            code: 32083,
            value: 'xa_tan_loc_bac',
            division_type: 'xã',
            short_codename: 'tan_loc_bac',
            parentId: 967,
          },
          {
            title: 'Xã Tân Lộc',
            code: 32086,
            value: 'xa_tan_loc',
            division_type: 'xã',
            short_codename: 'tan_loc',
            parentId: 967,
          },
          {
            title: 'Xã Tân Lộc Đông',
            code: 32089,
            value: 'xa_tan_loc_dong',
            division_type: 'xã',
            short_codename: 'tan_loc_dong',
            parentId: 967,
          },
          {
            title: 'Xã Hồ Thị Kỷ',
            code: 32092,
            value: 'xa_ho_thi_ky',
            division_type: 'xã',
            short_codename: 'ho_thi_ky',
            parentId: 967,
          },
        ],
        parentId: 96,
      },
      {
        title: 'Huyện Trần Văn Thời',
        code: 968,
        value: 'huyen_tran_van_thoi',
        division_type: 'huyện',
        short_codename: 'tran_van_thoi',
        children: [
          {
            title: 'Thị trấn Trần Văn Thời',
            code: 32095,
            value: 'thi_tran_tran_van_thoi',
            division_type: 'thị trấn',
            short_codename: 'tran_van_thoi',
            parentId: 968,
          },
          {
            title: 'Thị trấn Sông Đốc',
            code: 32098,
            value: 'thi_tran_song_doc',
            division_type: 'thị trấn',
            short_codename: 'song_doc',
            parentId: 968,
          },
          {
            title: 'Xã Khánh Bình Tây Bắc',
            code: 32101,
            value: 'xa_khanh_binh_tay_bac',
            division_type: 'xã',
            short_codename: 'khanh_binh_tay_bac',
            parentId: 968,
          },
          {
            title: 'Xã Khánh Bình Tây',
            code: 32104,
            value: 'xa_khanh_binh_tay',
            division_type: 'xã',
            short_codename: 'khanh_binh_tay',
            parentId: 968,
          },
          {
            title: 'Xã Trần Hợi',
            code: 32107,
            value: 'xa_tran_hoi',
            division_type: 'xã',
            short_codename: 'tran_hoi',
            parentId: 968,
          },
          {
            title: 'Xã Khánh Lộc',
            code: 32108,
            value: 'xa_khanh_loc',
            division_type: 'xã',
            short_codename: 'khanh_loc',
            parentId: 968,
          },
          {
            title: 'Xã Khánh Bình',
            code: 32110,
            value: 'xa_khanh_binh',
            division_type: 'xã',
            short_codename: 'khanh_binh',
            parentId: 968,
          },
          {
            title: 'Xã Khánh Hưng',
            code: 32113,
            value: 'xa_khanh_hung',
            division_type: 'xã',
            short_codename: 'khanh_hung',
            parentId: 968,
          },
          {
            title: 'Xã Khánh Bình Đông',
            code: 32116,
            value: 'xa_khanh_binh_dong',
            division_type: 'xã',
            short_codename: 'khanh_binh_dong',
            parentId: 968,
          },
          {
            title: 'Xã Khánh Hải',
            code: 32119,
            value: 'xa_khanh_hai',
            division_type: 'xã',
            short_codename: 'khanh_hai',
            parentId: 968,
          },
          {
            title: 'Xã Lợi An',
            code: 32122,
            value: 'xa_loi_an',
            division_type: 'xã',
            short_codename: 'loi_an',
            parentId: 968,
          },
          {
            title: 'Xã Phong Điền',
            code: 32124,
            value: 'xa_phong_dien',
            division_type: 'xã',
            short_codename: 'phong_dien',
            parentId: 968,
          },
          {
            title: 'Xã Phong Lạc',
            code: 32125,
            value: 'xa_phong_lac',
            division_type: 'xã',
            short_codename: 'phong_lac',
            parentId: 968,
          },
        ],
        parentId: 96,
      },
      {
        title: 'Huyện Cái Nước',
        code: 969,
        value: 'huyen_cai_nuoc',
        division_type: 'huyện',
        short_codename: 'cai_nuoc',
        children: [
          {
            title: 'Thị trấn Cái Nước',
            code: 32128,
            value: 'thi_tran_cai_nuoc',
            division_type: 'thị trấn',
            short_codename: 'cai_nuoc',
            parentId: 969,
          },
          {
            title: 'Xã Thạnh Phú',
            code: 32130,
            value: 'xa_thanh_phu',
            division_type: 'xã',
            short_codename: 'thanh_phu',
            parentId: 969,
          },
          {
            title: 'Xã Lương Thế Trân',
            code: 32131,
            value: 'xa_luong_the_tran',
            division_type: 'xã',
            short_codename: 'luong_the_tran',
            parentId: 969,
          },
          {
            title: 'Xã Phú Hưng',
            code: 32134,
            value: 'xa_phu_hung',
            division_type: 'xã',
            short_codename: 'phu_hung',
            parentId: 969,
          },
          {
            title: 'Xã Tân Hưng',
            code: 32137,
            value: 'xa_tan_hung',
            division_type: 'xã',
            short_codename: 'tan_hung',
            parentId: 969,
          },
          {
            title: 'Xã Hưng Mỹ',
            code: 32140,
            value: 'xa_hung_my',
            division_type: 'xã',
            short_codename: 'hung_my',
            parentId: 969,
          },
          {
            title: 'Xã Hoà Mỹ',
            code: 32141,
            value: 'xa_hoa_my',
            division_type: 'xã',
            short_codename: 'hoa_my',
            parentId: 969,
          },
          {
            title: 'Xã Đông Hưng',
            code: 32142,
            value: 'xa_dong_hung',
            division_type: 'xã',
            short_codename: 'dong_hung',
            parentId: 969,
          },
          {
            title: 'Xã Đông Thới',
            code: 32143,
            value: 'xa_dong_thoi',
            division_type: 'xã',
            short_codename: 'dong_thoi',
            parentId: 969,
          },
          {
            title: 'Xã Tân Hưng Đông',
            code: 32146,
            value: 'xa_tan_hung_dong',
            division_type: 'xã',
            short_codename: 'tan_hung_dong',
            parentId: 969,
          },
          {
            title: 'Xã Trần Thới',
            code: 32149,
            value: 'xa_tran_thoi',
            division_type: 'xã',
            short_codename: 'tran_thoi',
            parentId: 969,
          },
        ],
        parentId: 96,
      },
      {
        title: 'Huyện Đầm Dơi',
        code: 970,
        value: 'huyen_dam_doi',
        division_type: 'huyện',
        short_codename: 'dam_doi',
        children: [
          {
            title: 'Thị trấn Đầm Dơi',
            code: 32152,
            value: 'thi_tran_dam_doi',
            division_type: 'thị trấn',
            short_codename: 'dam_doi',
            parentId: 970,
          },
          {
            title: 'Xã Tạ An Khương',
            code: 32155,
            value: 'xa_ta_an_khuong',
            division_type: 'xã',
            short_codename: 'ta_an_khuong',
            parentId: 970,
          },
          {
            title: 'Xã Tạ An Khương Đông',
            code: 32158,
            value: 'xa_ta_an_khuong_dong',
            division_type: 'xã',
            short_codename: 'ta_an_khuong_dong',
            parentId: 970,
          },
          {
            title: 'Xã Trần Phán',
            code: 32161,
            value: 'xa_tran_phan',
            division_type: 'xã',
            short_codename: 'tran_phan',
            parentId: 970,
          },
          {
            title: 'Xã Tân Trung',
            code: 32162,
            value: 'xa_tan_trung',
            division_type: 'xã',
            short_codename: 'tan_trung',
            parentId: 970,
          },
          {
            title: 'Xã Tân Đức',
            code: 32164,
            value: 'xa_tan_duc',
            division_type: 'xã',
            short_codename: 'tan_duc',
            parentId: 970,
          },
          {
            title: 'Xã Tân Thuận',
            code: 32167,
            value: 'xa_tan_thuan',
            division_type: 'xã',
            short_codename: 'tan_thuan',
            parentId: 970,
          },
          {
            title: 'Xã Tạ An Khương Nam',
            code: 32170,
            value: 'xa_ta_an_khuong_nam',
            division_type: 'xã',
            short_codename: 'ta_an_khuong_nam',
            parentId: 970,
          },
          {
            title: 'Xã Tân Duyệt',
            code: 32173,
            value: 'xa_tan_duyet',
            division_type: 'xã',
            short_codename: 'tan_duyet',
            parentId: 970,
          },
          {
            title: 'Xã Tân Dân',
            code: 32174,
            value: 'xa_tan_dan',
            division_type: 'xã',
            short_codename: 'tan_dan',
            parentId: 970,
          },
          {
            title: 'Xã Tân Tiến',
            code: 32176,
            value: 'xa_tan_tien',
            division_type: 'xã',
            short_codename: 'tan_tien',
            parentId: 970,
          },
          {
            title: 'Xã Quách Phẩm Bắc',
            code: 32179,
            value: 'xa_quach_pham_bac',
            division_type: 'xã',
            short_codename: 'quach_pham_bac',
            parentId: 970,
          },
          {
            title: 'Xã Quách Phẩm',
            code: 32182,
            value: 'xa_quach_pham',
            division_type: 'xã',
            short_codename: 'quach_pham',
            parentId: 970,
          },
          {
            title: 'Xã Thanh Tùng',
            code: 32185,
            value: 'xa_thanh_tung',
            division_type: 'xã',
            short_codename: 'thanh_tung',
            parentId: 970,
          },
          {
            title: 'Xã Ngọc Chánh',
            code: 32186,
            value: 'xa_ngoc_chanh',
            division_type: 'xã',
            short_codename: 'ngoc_chanh',
            parentId: 970,
          },
          {
            title: 'Xã Nguyễn Huân',
            code: 32188,
            value: 'xa_nguyen_huan',
            division_type: 'xã',
            short_codename: 'nguyen_huan',
            parentId: 970,
          },
        ],
        parentId: 96,
      },
      {
        title: 'Huyện Năm Căn',
        code: 971,
        value: 'huyen_nam_can',
        division_type: 'huyện',
        short_codename: 'nam_can',
        children: [
          {
            title: 'Thị Trấn Năm Căn',
            code: 32191,
            value: 'thi_tran_nam_can',
            division_type: 'thị trấn',
            short_codename: 'nam_can',
            parentId: 971,
          },
          {
            title: 'Xã Hàm Rồng',
            code: 32194,
            value: 'xa_ham_rong',
            division_type: 'xã',
            short_codename: 'ham_rong',
            parentId: 971,
          },
          {
            title: 'Xã Hiệp Tùng',
            code: 32197,
            value: 'xa_hiep_tung',
            division_type: 'xã',
            short_codename: 'hiep_tung',
            parentId: 971,
          },
          {
            title: 'Xã Đất Mới',
            code: 32200,
            value: 'xa_dat_moi',
            division_type: 'xã',
            short_codename: 'dat_moi',
            parentId: 971,
          },
          {
            title: 'Xã Lâm Hải',
            code: 32201,
            value: 'xa_lam_hai',
            division_type: 'xã',
            short_codename: 'lam_hai',
            parentId: 971,
          },
          {
            title: 'Xã Hàng Vịnh',
            code: 32203,
            value: 'xa_hang_vinh',
            division_type: 'xã',
            short_codename: 'hang_vinh',
            parentId: 971,
          },
          {
            title: 'Xã Tam Giang',
            code: 32206,
            value: 'xa_tam_giang',
            division_type: 'xã',
            short_codename: 'tam_giang',
            parentId: 971,
          },
          {
            title: 'Xã Tam Giang Đông',
            code: 32209,
            value: 'xa_tam_giang_dong',
            division_type: 'xã',
            short_codename: 'tam_giang_dong',
            parentId: 971,
          },
        ],
        parentId: 96,
      },
      {
        title: 'Huyện Phú Tân',
        code: 972,
        value: 'huyen_phu_tan',
        division_type: 'huyện',
        short_codename: 'phu_tan',
        children: [
          {
            title: 'Thị trấn Cái Đôi Vàm',
            code: 32212,
            value: 'thi_tran_cai_doi_vam',
            division_type: 'thị trấn',
            short_codename: 'cai_doi_vam',
            parentId: 972,
          },
          {
            title: 'Xã Phú Thuận',
            code: 32214,
            value: 'xa_phu_thuan',
            division_type: 'xã',
            short_codename: 'phu_thuan',
            parentId: 972,
          },
          {
            title: 'Xã Phú Mỹ',
            code: 32215,
            value: 'xa_phu_my',
            division_type: 'xã',
            short_codename: 'phu_my',
            parentId: 972,
          },
          {
            title: 'Xã Phú Tân',
            code: 32218,
            value: 'xa_phu_tan',
            division_type: 'xã',
            short_codename: 'phu_tan',
            parentId: 972,
          },
          {
            title: 'Xã Tân Hải',
            code: 32221,
            value: 'xa_tan_hai',
            division_type: 'xã',
            short_codename: 'tan_hai',
            parentId: 972,
          },
          {
            title: 'Xã Việt Thắng',
            code: 32224,
            value: 'xa_viet_thang',
            division_type: 'xã',
            short_codename: 'viet_thang',
            parentId: 972,
          },
          {
            title: 'Xã Tân Hưng Tây',
            code: 32227,
            value: 'xa_tan_hung_tay',
            division_type: 'xã',
            short_codename: 'tan_hung_tay',
            parentId: 972,
          },
          {
            title: 'Xã Rạch Chèo',
            code: 32228,
            value: 'xa_rach_cheo',
            division_type: 'xã',
            short_codename: 'rach_cheo',
            parentId: 972,
          },
          {
            title: 'Xã Nguyễn Việt Khái',
            code: 32230,
            value: 'xa_nguyen_viet_khai',
            division_type: 'xã',
            short_codename: 'nguyen_viet_khai',
            parentId: 972,
          },
        ],
        parentId: 96,
      },
      {
        title: 'Huyện Ngọc Hiển',
        code: 973,
        value: 'huyen_ngoc_hien',
        division_type: 'huyện',
        short_codename: 'ngoc_hien',
        children: [
          {
            title: 'Xã Tam Giang Tây',
            code: 32233,
            value: 'xa_tam_giang_tay',
            division_type: 'xã',
            short_codename: 'tam_giang_tay',
            parentId: 973,
          },
          {
            title: 'Xã Tân Ân Tây',
            code: 32236,
            value: 'xa_tan_an_tay',
            division_type: 'xã',
            short_codename: 'tan_an_tay',
            parentId: 973,
          },
          {
            title: 'Xã Viên An Đông',
            code: 32239,
            value: 'xa_vien_an_dong',
            division_type: 'xã',
            short_codename: 'vien_an_dong',
            parentId: 973,
          },
          {
            title: 'Xã Viên An',
            code: 32242,
            value: 'xa_vien_an',
            division_type: 'xã',
            short_codename: 'vien_an',
            parentId: 973,
          },
          {
            title: 'Thị trấn Rạch Gốc',
            code: 32244,
            value: 'thi_tran_rach_goc',
            division_type: 'thị trấn',
            short_codename: 'rach_goc',
            parentId: 973,
          },
          {
            title: 'Xã Tân Ân',
            code: 32245,
            value: 'xa_tan_an',
            division_type: 'xã',
            short_codename: 'tan_an',
            parentId: 973,
          },
          {
            title: 'Xã Đất Mũi',
            code: 32248,
            value: 'xa_dat_mui',
            division_type: 'xã',
            short_codename: 'dat_mui',
            parentId: 973,
          },
        ],
        parentId: 96,
      },
    ],
  },
]
