import { createContext, useMemo, useState } from 'react'

interface SomeProps {
  /* your interface */
}
const ThemeContext = createContext<SomeProps>({})
function ThemeProvider({ children }: any) {
  const [testState, setTestState] = useState<number>(1)
  const [select, setSelect] = useState<string>('638071da4b4b642c4acf8789')
  const [listFile, setListFile] = useState([])
  const [dataRespone, setDataRespone] = useState([])

  const value = useMemo(
    () => ({
      testState,
      setTestState,
      select,
      setSelect,
      listFile,
      setListFile,
      setDataRespone,
      dataRespone,
    }),
    [testState, select, dataRespone],
  )
  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}
export { ThemeContext, ThemeProvider }
