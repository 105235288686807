import { ResApi, ResApiErr } from '@src/configs/resApi.interface'
import { checkAuth, getStoredLang } from '@src/libs/localStorage'
import { MediaData, MediaQueryParams } from '@src/models'
import { notification } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import {
  createFolderMedia,
  createMedia,
  deleteFolderMediaByID,
  delMediaById,
  getFileFolderById,
  getFolderById,
  getListFolder,
  getListMedia,
  getMediaById,
  updateFolderMedia,
  updateMedia,
} from '../api'
import { MEDIA_KEY } from '../keys'

export const useQueryMedia = (params: MediaQueryParams) => {
  const accessToken = checkAuth()
  return useQuery(
    [MEDIA_KEY.LIST, params, getStoredLang()],
    () => getListMedia(params, accessToken),
    {
      retry: 2,
      keepPreviousData: true,
    },
  )
}

export const useCreateMedia = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((body: MediaData) => createMedia(body, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Create Success!' })
        queryClient.refetchQueries([MEDIA_KEY.LIST])
        queryClient.refetchQueries([MEDIA_KEY.DETAIL])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Create failure!' })
    },
  })
}

export const useMediaById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [MEDIA_KEY.DETAIL, id, getStoredLang()],
    () => getMediaById(id, accessToken),
    {
      enabled: !!(id && accessToken),
      retry: 1,
    },
  )
}

export const useUpdateMedia = (id: string) => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((body: MediaData) => updateMedia(id, body, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Update Success!' })
        queryClient.refetchQueries([MEDIA_KEY.LIST])
        queryClient.refetchQueries([MEDIA_KEY.DETAIL, id, getStoredLang()])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Update failure!' })
    },
  })
}

export const useDeleteMedia = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((id: string) => delMediaById(id, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Delete Success!' })
        queryClient.refetchQueries([MEDIA_KEY.LIST])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Delete failure!' })
    },
  })
}

/// folder

export const useQueryListFolder = (params: MediaQueryParams) => {
  const token = checkAuth()
  return useQuery([MEDIA_KEY.LIST_FOLDER, params, getStoredLang()], () =>
    getListFolder(params, token),
  )
}

export const useFolderById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [MEDIA_KEY.DETAIL, id, getStoredLang()],
    () => getFolderById(id, accessToken),
    {
      enabled: !!(id && accessToken),
      retry: 1,
    },
  )
}
export const useFileFolderById = (
  id: string,
  params: MediaQueryParams,
  reload?: boolean,
) => {
  const accessToken = checkAuth()
  return useQuery(
    [MEDIA_KEY.DETAIL, reload, id, params, getStoredLang()],
    () => getFileFolderById(id, accessToken, params),
    {
      enabled: !!(id && accessToken),
      retry: 1,
    },
  )
}

export const useCreateFolderMedia = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    (body: MediaData) => createFolderMedia(body, accessToken),
    {
      onSuccess: (data: ResApi) => {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Create Success!' })
          queryClient.invalidateQueries([MEDIA_KEY.LIST_FOLDER])
          queryClient.invalidateQueries([MEDIA_KEY.DETAIL])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Create failure!' })
      },
    },
  )
}

export const useDeleteFolderMedia = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation((id: string) => deleteFolderMediaByID(id, accessToken), {
    onSuccess: (data: ResApi) => {
      if (data?.statusCode === 200) {
        notification.success({ message: data.message || 'Delete Success!' })
        queryClient.invalidateQueries([MEDIA_KEY.LIST_FOLDER])

        queryClient.invalidateQueries([MEDIA_KEY.DETAIL])
      }
    },
    onError: (error: ResApiErr) => {
      notification.error({ message: error.message || 'Delete failure!' })
    },
  })
}
export const useUpdateFolderMedia = (id: string) => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    (body: MediaData) => updateFolderMedia(id, body, accessToken),
    {
      onSuccess: (data: ResApi) => {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Update Success!' })
          queryClient.refetchQueries([MEDIA_KEY.LIST])
          queryClient.refetchQueries([MEDIA_KEY.DETAIL])
          queryClient.invalidateQueries([MEDIA_KEY.LIST_FOLDER])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Update failure!' })
      },
    },
  )
}
