import { ResApi, ResApiErr } from '@src/configs/resApi.interface'
import { checkAuth, getStoredLang } from '@src/libs/localStorage'
import { IndustrialData, IndustrialQueryParams } from '@src/models'
import { notification } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import {
  createIndustrialProject,
  delIndustrialProjectById,
  getIndustrialProjectById,
  getListIndustrial,
  updateIndustrialProject,
} from '../api'
import { INDUSTRIAL_KEY } from '../keys'

export const useQueryIndustrial = (params: IndustrialQueryParams) =>
  useQuery(
    [INDUSTRIAL_KEY.LIST, params, getStoredLang()],
    () => getListIndustrial(params),
    {
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    },
  )

export const useCreateIndustrialProject = () => {
  const accessToken = checkAuth()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(
    (body: IndustrialData) => createIndustrialProject(body, accessToken),
    {
      onSuccess: (data: ResApi) => {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Create Success!' })
          queryClient.refetchQueries([INDUSTRIAL_KEY.LIST])
          navigate(`/industrial-project/${data?.data?._id}`)
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Create failure!' })
      },
    },
  )
}

export const useIndustrialProjectById = (id: string) => {
  const accessToken = checkAuth()
  return useQuery(
    [INDUSTRIAL_KEY.DETAIL, id, getStoredLang()],
    () => getIndustrialProjectById(id, accessToken),
    {
      enabled: !!(id && accessToken),
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    },
  )
}

export const useUpdateIndustrialProject = (id: string) => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    (body: IndustrialData) => updateIndustrialProject(id, body, accessToken),
    {
      onSuccess: (data: ResApi) => {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Update Success!' })
          queryClient.refetchQueries([INDUSTRIAL_KEY.LIST])
          queryClient.refetchQueries([
            INDUSTRIAL_KEY.DETAIL,
            id,
            getStoredLang(),
          ])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Update failure!' })
      },
    },
  )
}

export const useDeleteIndustrialProject = () => {
  const accessToken = checkAuth()
  const queryClient = useQueryClient()
  return useMutation(
    (id: string) => delIndustrialProjectById(id, accessToken),
    {
      onSuccess: (data: ResApi) => {
        if (data?.statusCode === 200) {
          notification.success({ message: data.message || 'Delete Success!' })
          queryClient.refetchQueries([INDUSTRIAL_KEY.LIST])
        }
      },
      onError: (error: ResApiErr) => {
        notification.error({ message: error.message || 'Delete failure!' })
      },
    },
  )
}
